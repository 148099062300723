import React, { useEffect, useRef, useMemo, useLayoutEffect } from 'react'
import ReactDOMServer from 'react-dom/server';

import { useState } from 'react'
import $ from 'jquery';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { Form, Table } from 'react-bootstrap';
import Select from "react-select";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'



function FrmQualityTestingEntry() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, UserId, FINANCIAL_SHORT_NAME, COMPANY_NAME } = configConstants;

    const { state } = useLocation();
    const { qualityTestingNo, keyForViewUpdate = 'add' } = state || {};

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;

    };

    const formatToDDMMYYYY = (date) => {
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
    };

    //useRef Hooks
    const comboDataAPiCall = useRef();

    // const combobox = useRef();
    const generateAutoNoAPiCall = useRef();
    const navigate = useNavigate();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const batchComboRef = useRef()
    const [isLoading, setIsLoading] = useState(false);

    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null)

    ///Shiftwise Machine Stoppage Entry Hooks
    const [txt_quality_testing_no, setQualityTestingNo] = useState("");
    const [cmb_batch_no, setBatchNo] = useState("");
    const [dt_quality_testing_date, setQualityTestingDate] = useState(today())
    const [quality_tester_id, setQualityTesterId] = useState(parseInt(UserId));
    const currentDate = new Date().toISOString().split('T')[0];

    //Options States for ComboBoxes
    const [batchNoOptions, setBatchNoOptions] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        let Path = `/Transactions/TCottonBalesManagement/QualityTestingListing`
        navigate(Path)
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true)
            await fillComboBox();
            if (qualityTestingNo === 0) {
                await generateQualityTestingNo()
            } else {
                await FnCheckUpdateRes()
            }
            setIsLoading(false)
        }
        loadDataOnload();
    }, []);

    const qualityTestEmptyObj = {
        batch_no: "",
        uhml: "",
        ul: "",
        mi: "",
        mic: "",
        str: "",
        rd: "",
        b_plus: "",
        total_neps: "",
        sc_n: "",
        sfc_n: "",
        trash: "",
        moisture: "",
        cg: "",
        quality_testing_status: ""
    }
    const [qualityTestingDetails, setQualityTestingDetails] = useState([]);

    const generateQualityTestingNo = async () => {
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_quality_testing_cotton_bales", "quality_testing_no", "", 'QT', "5");
        setQualityTestingNo(autoNoApiCall);
        return autoNoApiCall;
    };

    const fillComboBox = async () => {
        try {
            //Distinct lot no
            resetGlobalQuery();
            globalQuery.columns.push("DISTINCT batch_no");
            globalQuery.table = "sm_product_material_stock_new";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.conditions.push({ field: "quality_status", operator: "=", value: 'P', });
            globalQuery.orderBy = ['batch_no ASC']
            const lotApiData = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const lotOptions = [
                { value: '', label: 'Select' },
                ...lotApiData.map((grn) => ({ ...grn, batch_no: grn.batch_no, value: grn.batch_no, label: grn.batch_no })),
            ];
            setBatchNoOptions(lotOptions);


            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_name"]
            globalQuery.table = "cm_employee";
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            const getEmployeesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const getEmployees = [
                ...getEmployeesApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
            ];
            setEmployeeList(getEmployees);
        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
    }

    const comboOnChange = async (key) => {

        try {
            setIsLoading(true);
            switch (key) {
                case 'batchNo':
                    let selLotNo = batchComboRef.current.value
                    let detailsData = [...qualityTestingDetails];
                    let lotExists = detailsData.some((detail) => detail.batch_no === selLotNo);
                    if (lotExists) {
                        setErrMsg("Lot already Exists!..")
                        setShowErrorMsgModal(true)
                        return
                    }
                    if (selLotNo !== "") {
                        FnManageWastageDetailsTbl('add', qualityTestingDetails.length - 1)
                    }
                    break;
            }
        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
    }

    const FnCheckUpdateRes = async () => {
        try {
            setIsLoading(true)
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtCottonBalesQualityTesting/FnShowParticularRecordForUpdate/${parseInt(COMPANY_ID)}?quality_testing_no=${qualityTestingNo}`);
            const responce = await apiCall.json();
            let qualityTestRecords = responce.data;
            const formattedDate = new Date(qualityTestRecords[0].quality_testing_date).toISOString().split("T")[0];
            setQualityTestingDate(formattedDate)
            setQualityTesterId(qualityTestRecords[0].quality_tester_id);
            setQualityTestingNo(qualityTestRecords[0].quality_testing_no)
            setQualityTestingDetails(qualityTestRecords);
        } catch (error) {
            console.log("Error", error)
        } finally {
            setIsLoading(false)
        }
    }

    var validateWastageTbl = () => {

        try {
            let detailsData = [...qualityTestingDetails];
            let validation = true;

            for (let i = 0; i < detailsData.length; i++) {
                let data = detailsData[i];

                for (let key in data) {
                    let tabledataId = $("#" + key + `_${i}`);
                    if (tabledataId.is(':visible') && (['', 0, '0', null].includes(tabledataId.val()))) {
                        if (tabledataId.attr('type') === 'text') {
                            tabledataId.parent().attr('data-tip', 'Please Enter proper data...!');
                        } else if (tabledataId.is('select')) {
                            tabledataId.parent().attr('data-tip', 'Please Select atleast one...!');
                        } else if (tabledataId.attr('type') === 'time') {
                            tabledataId.parent().attr('data-tip', 'Please Select Time...!');
                        }

                        tabledataId.focus();
                        validation = false;
                        break;
                    }
                }
                if (!validation) {
                    break;
                }
            }

            return validation;
        } catch (error) {
            console.error("Validation error:", error);
            return false;
        }
    }

    const FnManageWastageDetailsTbl = (action, rowIndex) => {
        try {
            let detailsData = [...qualityTestingDetails];

            if (action === 'add') {
                if (validateWastageTbl() === true) {
                    setQualityTestingDetails([...detailsData, { ...qualityTestEmptyObj, batch_no: batchComboRef.current.value }]);
                }
            } else {
                detailsData.splice(rowIndex, 1);
                if (detailsData.length > 0) {
                    setQualityTestingDetails(detailsData);
                } else {
                    setQualityTestingDetails([]);
                }
            }
        } catch (error) {

        }
    }

    const handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const renderQualityTestingTable = useMemo(() => {
        return <div className='scrollable'>
            <Table className='erp_table' id="qualitytestingtableId" bordered>
                <thead className="erp_table_head table-secondary">
                    <tr>
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} style={{ width: "25px" }}>Action</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>Mill Lot No</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>UHML</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>ML</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>UI</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>MIC</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>STR</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>RD</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>B+</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>TOTAL NEPS</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>SC(N)</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>SFC(N)</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>TRASH</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>MOISTURE</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>CG</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>Quality Status</td>
                    </tr>
                </thead>
                <tbody>

                    {qualityTestingDetails.length > 0 ? (
                        qualityTestingDetails?.map((details, indexOfItem) => (
                            <tr key={indexOfItem} rowIndex={indexOfItem}>

                                <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                    <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => FnManageWastageDetailsTbl('remove', indexOfItem)} />
                                </td>
                                <td className={`erp_table_th`}>{details.batch_no}</td>

                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`uhml_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.uhml} onChange={(e) => updateQualityTestingDetails('uhml', details, e)}
                                    /> :
                                        details.uhml}
                                </td>

                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`mi_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.mi} onChange={(e) => updateQualityTestingDetails('mi', details, e)}
                                    /> :
                                        details.mi}
                                </td>


                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`ul_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.ul} onChange={(e) => updateQualityTestingDetails('ul', details, e)}
                                    /> :
                                        details.ul}
                                </td>

                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`mic_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.mic} onChange={(e) => updateQualityTestingDetails('mic', details, e)}
                                    /> :
                                        details.mic}
                                </td>

                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`str_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.str} onChange={(e) => updateQualityTestingDetails('str', details, e)}
                                    /> :
                                        details.str}
                                </td>

                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`rd_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.rd} onChange={(e) => updateQualityTestingDetails('rd', details, e)}
                                    /> :
                                        details.rd}
                                </td>

                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`b_plus_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.b_plus} onChange={(e) => updateQualityTestingDetails('b_plus', details, e)}
                                    /> :
                                        details.b_plus}
                                </td>

                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`total_neps_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.total_neps} onChange={(e) => updateQualityTestingDetails('total_neps', details, e)}
                                    /> :
                                        details.total_neps}
                                </td>


                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`sc_n_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.sc_n} onChange={(e) => updateQualityTestingDetails('sc_n', details, e)}
                                    /> :
                                        details.sc_n}
                                </td>


                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`sfc_n_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.sfc_n} onChange={(e) => updateQualityTestingDetails('sfc_n', details, e)}
                                    /> :
                                        details.sfc_n}
                                </td>


                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`trash_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.trash} onChange={(e) => updateQualityTestingDetails('trash', details, e)}
                                    /> :
                                        details.trash}
                                </td>


                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`moisture_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.moisture} onChange={(e) => updateQualityTestingDetails('moisture', details, e)}
                                    /> :
                                        details.moisture}
                                </td>

                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add" ? <input
                                        type="text" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`cg_${indexOfItem}`}
                                        style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                        value={details.cg} onChange={(e) => updateQualityTestingDetails('cg', details, e)}
                                    /> :
                                        details.cg}
                                </td>
                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {keyForViewUpdate === "add"|| keyForViewUpdate === "update"? <select id={`quality_testing_status_${indexOfItem}`} value={details.quality_testing_status} className="form-select form-select-md mb-0" Headers="quality_testing_status" onChange={(e) => { updateQualityTestingDetails('quality_testing_status', details, e); }}
                                        style={{ width: "100%" }} disabled={keyForViewUpdate === 'view'}>
                                        <option value="">Select</option>
                                        <option value="C">Consumable</option>
                                        <option value="I">Partial-Reject</option>
                                        <option value="R">Reject</option>
                                    </select> :
                                        details.quality_testing_status === "C" ? "Consumable" : details.quality_testing_status === "I" ? "Partially-Rejected" : "Rejected"}
                                </td>
                            </tr>
                        ))) : (
                        <tr>
                            <td className="erp_table_td" style={{}} colSpan="16">
                                {keyForViewUpdate === "add" ? "Select Lot No to Add Entry" : "No Records found..."}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table></div>

    }, [qualityTestingDetails]);

    const updateQualityTestingDetails = (columnName, currentMaterial, event) => {
        try {

            let detailsData = [...qualityTestingDetails];
            const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'));

            $("#" + event.target.id).parent().removeAttr('data-tip');

            let numericValue = event.target.value.replace(/[^0-9-.]/g, "");
            if (columnName !== "quality_testing_status") {
                currentMaterial[columnName] = numericValue;
            } else {
                currentMaterial[columnName] = event.target.value;
            }

            detailsData[arrayIndex] = currentMaterial;
            setQualityTestingDetails(detailsData);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const validateFormFields = () => {
        validate.current.validateFieldsOnChange('qualityTestingFormId');
    }


    const FnSaveWastageEntry = async () => {
        try {
            setIsLoading(true);
            if (qualityTestingDetails?.length === 0) {
                setErrMsg("Please add atleast One Lot")
                setShowErrorMsgModal(true)
                return
            }
            let validateFrom = await validate.current.validateForm('qualityTestingFormId');
            if (validateFrom === true) {
                if (validateWastageTbl() === true) {
                    let latestQualityTestingNo = txt_quality_testing_no;
                    const json = { 'PtCottonBalesQualityTestingData': [], 'commonIds': { 'company_id': COMPANY_ID } }
                    if (qualityTestingNo === 0) {
                        latestQualityTestingNo = await generateQualityTestingNo();
                    }
                    for (let index = 0; index < qualityTestingDetails.length; index++) {
                        let qualityTestingElement = qualityTestingDetails[index];
                        const qualityTestingObj = {
                            quality_testing_cotton_bales_transaction_id: ['view', 'update'].includes(keyForViewUpdate) ? qualityTestingElement.quality_testing_cotton_bales_transaction_id : 0,
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            quality_testing_no: txt_quality_testing_no,
                            quality_testing_date: dt_quality_testing_date,
                            pr_no: "PASHUPATI (17701-17800)",
                            batch_no: qualityTestingElement.batch_no,
                            uhml: qualityTestingElement.uhml,
                            ul: qualityTestingElement.ul,
                            mi: qualityTestingElement.mi,
                            mic: qualityTestingElement.mic,
                            str: qualityTestingElement.str,
                            b_plus: qualityTestingElement.b_plus,
                            rd: qualityTestingElement.rd,
                            total_neps: qualityTestingElement.total_neps,
                            sc_n: qualityTestingElement.sc_n,
                            sfc_n: qualityTestingElement.sfc_n,
                            trash: qualityTestingElement.trash,
                            moisture: qualityTestingElement.moisture,
                            cg: qualityTestingElement.cg,
                            quality_testing_status: qualityTestingElement.quality_testing_status,
                            quality_tester_id: quality_tester_id,
                            created_by: UserName,
                            modified_by: UserName
                        }
                        json.PtCottonBalesQualityTestingData.push(qualityTestingObj);
                    }

                    const formData = new FormData()
                    formData.append('PtCottonBalesQualityTesting', JSON.stringify(json))
                    const forwardData = {
                        method: 'POST',
                        body: formData,
                    }
                    const getWarpingDetails = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtCottonBalesQualityTesting/FnAddUpdateRecord`, forwardData)
                    const response = await getWarpingDetails.json();
                    if (response.success === 0) {
                        setErrMsg(response.error)
                        setShowErrorMsgModal(true)
                    } else {
                        setSuccMsg(response.message)
                        setShowSuccessMsgModal(true)
                    }
                }
            }

        } catch (error) {
            console.log("Error", error)
        } finally {
            setIsLoading(false);
        }
    }

    let printTimeout;

    const FnPrintIssueReportSheet = useReactToPrint({
        content: () => {
            // Hide pagination during print
            $('.erp_pagination').hide();
            // Temporarily adjust table styles and scrollable area
            debugger
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                document.querySelector('.scrollable').style.overflow = 'visible';
                // table.style.tableLayout = 'fixed';  
                // table.style.width = '100%'; 
            }

            const headerHtml = printOutHeader();

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml;

            // Append the main content (clone the table for printing)
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {

            printTimeout = setTimeout(() => {
                handlePrintCancel();  // Handle cancel
            }, 1000);
        },
        onAfterPrint: () => {
            // Show pagination again and restore original table styles
            $('.erp_pagination').show();
            document.querySelector('.scrollable').style.overflow = 'auto';  // Restore scrolling
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                // table.style.tableLayout = 'fixed';  // Restore original layout
            }
            clearTimeout(printTimeout);
        },
        documentTitle: `Quality Testing Report_${formatToDDMMYYYY(dt_quality_testing_date)}`
    });

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Quality Testing Report{" "}
                                {formatToDDMMYYYY(dt_quality_testing_date)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const handlePrint = async () => {
        try {
            FnPrintIssueReportSheet();
        } catch (error) {
            console.error('Error fetching data for print:', error);
        }
    };

    // Handle print cancel
    const handlePrintCancel = async () => {
        const scrollable = document.querySelector('.scrollable');
        const table = printComponentRef.current.querySelector('.erp_table');
        if (scrollable) {
            scrollable.style.overflow = 'auto';  // Restore scroll on cancel
        }
        if (table) {
            table.style.tableLayout = 'auto';
        }
    };

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <ValidateNumberDateInput ref={ValidateNumberDateInput} />
                <GenerateTAutoNo ref={generateAutoNoAPiCall} />
                <FrmValidations ref={validate} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/*.........................Shiftwise Machine Stoppage Entry............................... */}
                <div className="col-lg-12 col-12 erp_form_col_div">
                    <div className="col-lg-12 col-12 erp_form_col_div">
                        <div className="card mt-4">

                            <div className='card-header text-center py-0 '>
                                <label className='erp-form-label-lg text-center'>Quality Testing {keyForViewUpdate === 'view' ? '(View)' : keyForViewUpdate === 'update' ? '(Update)' : ''}</label>
                            </div>
                            {/* Card's Body Starts*/}
                            <div className="card-body p-0">
                                <form id='qualityTestingFormId'>

                                    <div className='row m-2'>
                                        <div className='col-sm-4 erp_form_col_div'>

                                            <div className='row'>
                                                <div className='col-sm-4 col-12'>
                                                    <Form.Label className="erp-form-label">Quality testing No & Date<span className="required">*</span></Form.Label>
                                                </div>
                                                <div className='col-sm-7 col-12'>
                                                    <div className="row">
                                                        <div className='col-12 col-md-7 pe-md-0'>
                                                            <Form.Control type="text" id="txt_gate_pass_no" className="erp_input_field" value={txt_quality_testing_no} optional='optional' disabled />
                                                        </div>
                                                        <div className="col-12 col-md-5 pt-md-0 pt-3">
                                                            <Form.Control type="date" id='dt_quality_testing_date'
                                                                className="erp_input_field"
                                                                value={dt_quality_testing_date}
                                                                disabled
                                                                onChange={e => {
                                                                    setQualityTestingDate(e.target.value);
                                                                    // validateFields();
                                                                }}
                                                                min={currentDate}
                                                            />
                                                            <MDTypography variant="button" id="error_dt_quality_testing_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                            </MDTypography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className='col-sm-4 erp_form_col_div'>
                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Quality Tester<span className="required">*</span></Form.Label>
                                                </div>
                                                <div className="col-sm-9 col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-8 ">
                                                            <Select
                                                                options={employeeList}
                                                                isDisabled={['view','update'].includes(keyForViewUpdate)}
                                                                inputId="quality_tester_id" // Provide the ID for the input box
                                                                value={employeeList.find(option => option.value === quality_tester_id) || null}
                                                                onChange={(selectedOpt) => {
                                                                    setQualityTesterId(selectedOpt.value);
                                                                }}
                                                                placeholder="Search for Quality Tester..."
                                                                className="form-search-custom"
                                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                                styles={{
                                                                    option: (provided, state) => ({
                                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                                    }),
                                                                    singleValue: (provided, state) => ({
                                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                                    }),
                                                                    input: (provided, state) => ({
                                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                                    })
                                                                }}
                                                            />
                                                            <MDTypography variant="button" id="error_cmb_qa_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                            </MDTypography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`col-sm-4 erp_form_col_div ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label">Lot No.<span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Select ref={batchComboRef}
                                                        options={batchNoOptions}
                                                        inputId="cmb_batch_no"
                                                        isDisabled={keyForViewUpdate === "add" ? false : true}
                                                        value={batchNoOptions.length > 0 ? batchNoOptions.find(option => option.value === cmb_batch_no) : null}
                                                        onChange={(selectedOpt) => {
                                                            batchComboRef.current = selectedOpt;
                                                            setBatchNo(selectedOpt.value);
                                                            comboOnChange('batchNo');
                                                            // validateFields();
                                                        }}
                                                        placeholder="Search for Lot No"
                                                        className="form-search-custom"
                                                        classNamePrefix="custom-select" // Add custom prefix for class names
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            })
                                                        }}
                                                    />
                                                    <MDTypography variant="button" id="error_cmb_batch_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </form>

                                <hr />

                                <div className='row p-3'>
                                    <div className="card-header mx-0 p-0 main_heding mb-0">
                                        <label className="erp-form-label-md-lg">Quality Testing Details</label>
                                    </div>
                                    <div className='col erp_form_col_div mt-2 p-2' ref={printComponentRef}>
                                        {renderQualityTestingTable}
                                    </div>

                                    <div className='row'>
                                        <div className='col'>
                                            <div className={`erp_frm_Btns  mt-2 mb-2 `}>
                                                <MDButton type="button" id="action_Button" className={`erp-gb-button ms-2 disable-button`} variant="button" onClick={() => handleCloseSuccessModal()} fontWeight="regular">Back</MDButton>
                                                <MDButton type="button" id="action_Button" className={`erp-gb-button ms-2 disable-button ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" onClick={() => FnSaveWastageEntry()} fontWeight="regular">Save</MDButton>
                                                <MDButton type="button" variant="button" fontWeight="regular" className={`erp-gb-button ms-2 disable-button ${keyForViewUpdate === 'add' ? 'd-none' : 'display'}`} onClick={(e) => { handlePrint() }}>Print</MDButton>
                                            </div >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </DashboardLayout>
        </>
    )
}


export default FrmQualityTestingEntry
