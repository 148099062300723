import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useReducer } from "react";
import $, { event } from "jquery";

import CottonBalesSearchBar from "components/MDSeacrhBarForCottonBales";

//File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmMBOMEntry from "FrmGeneric/MBOMFilter/FrmMBOMEntry";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import Datatable from "components/DataTable";
import html2pdf from 'html2pdf.js';
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import MailSentModal from "components/Modals/MailSentModal";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import DocumentF from "Features/Document";
import { FiDownload } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import PurchaseOrderInvoice from "FrmGeneric/Invoice/PurchaseOrderInvoice";


//React Bootstrap components
import Tooltip from "@mui/material/Tooltip";
import { Form, Button } from "react-bootstrap";
import { Accordion, Modal, Table } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";

//import components
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmCity from "FrmGeneric/MCity/FrmCity";

// React icons
import { MdDelete, MdModeEdit } from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ProductList from "FrmGeneric/ProductList";
import CommonParamterEntry from "Masters/MCommonParameter/CommonParamterEntry";
import FrmPaymentTermsEntry from "Masters/MPaymentTerms/FrmPaymentTermsEntry";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import { item } from "examples/Sidenav/styles/sidenavItem";
import RawCottonPurchaseSleep from "FrmGeneric/Invoice/RawCottonPurchaseSleep";
import { Padding } from "@mui/icons-material";
import FrmHSNSAC from "Masters/MHSN-SAC/FrmHSN-SAC";

// Import for the searchable combo box.
import Select from 'react-select';

//get gracedays for schedule date
let expectedScheduleGraceDays = 0;

function FrmCottonBalesPOEntry() {
    // debugger
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName, COMPANY_NAME, AFTER_DECIMAL_PLACES } = configConstants;
    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType, requestfor, modules_forms_id } = state || {};

    // UseRefs
    const navigate = useNavigate();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const importFile = useRef(null);
    const exlsExp = useRef();
    let poCurrencyComboRef = useRef(null)

    let expanded = false;
    const [isLoading, setIsLoading] = useState(false);
    const [importedData, setImportedData] = useState([]);
    const [isApprove, setIsApprove] = useState(false);
    const [isView, setIsView] = useState(false);

    const [openPODetailsAccord, setOpenPODetailsAccord] = useState(true);
    const [openGeneralAccord, setOpenGeneralAccord] = useState(true);
    // delete popup msg
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //Error Msg
    const handleCloseErrModal = () => {
        setShowErrorMsgModal(false)
    };
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState("");
    // Confirmation Modal fields
    const closeConfirmationModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    }
    // After approved mail sent confirmation modal
    const handleCloseMailSentModal = () => {
        setShowMailSentModal(false);
        moveToListing();
    }
    const [showMailSentModal, setShowMailSentModal] = useState(false);

    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)
    const [message, setMessage] = useState('')
    const [messageForConfirmation, setMessageForConfirmation] = useState('')
    let [HsnMaterial_id, setHsnMaterial_id] = useState('')
    // let hsnMaterialsNotRequired = [];
    const [hsnNotReqMats, setHSNNotReqMats] = useState([]);
    const FnCloseConfirmationModal = (key) => {

        switch (key) {
            case 'addHsn':
                setHSNNotReqMats((prevHsnNotReqMats) => [
                    ...prevHsnNotReqMats,
                    HsnMaterial_id,
                ]);

                // hsnMaterialsNotRequired.push(HsnMaterial_id);
                console.log("Yes material_id: ", HsnMaterial_id);
                // console.log("after added material id : ", hsnMaterialsNotRequired);

                break;
            case 'noHsn':

                console.log("No material_id: ", HsnMaterial_id);
                break;
            default:
                break;
        }
        setShowConfiemationModal(false);
    }


    const [showPrintPreviewIsOpen, setShowPreviewModalIsOpen] = useState(false)
    const [printPreviewContent, setPrintPreviewContent] = useState(null)
    const handleClosePrintPreview = () => { setShowPreviewModalIsOpen(false) }


    let po_status = '';
    let po_life = '';
    //for current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    const currentDate = new Date().toISOString().split('T')[0];
    //key for get opration
    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'approve':
                return '(Approval)';
            case 'update':
                return '(Modification)';
            case 'view':
                return '(View)';
            case 'copy':
                return '(Copy Purchase Order)';
            case 'delete':
                return '(Delete)';
            case 'cancel':
                return '(Cancel)';
            default:
                return '(Creation)';
        }
    }

    let fin_year = "";
    let PO_version = "";

    //combo options
    const [producttypeOptions, setProductTypeOptions] = useState([]);
    const [productCategoryOptions, setProductCategoryOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [indnetNosList, setIndentNosList] = useState([])
    const [CostCenterOptions, setCostCenterOptions] = useState([]);
    const [hsnCodesOptions, setHsnCodesOptions] = useState([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [ConsigneeOptions, setConsigneeOptions] = useState([]);
    const [supplierStateOptions, setSupplierStateOptions] = useState([]);
    const [supplierCityOpts, setSupplierCityOpts] = useState([]);
    const [ConsigneeStateOptions, setConsigneeStateOptions] = useState([]);
    const [ConsigneeCityOpts, setConsigneeCityOpts] = useState([]);
    const [expectedBranchOptions, setExpectedBranchOptions] = useState([]);
    const [expectedBranchStateOptions, setExpectedBranchStateOptions] = useState([]);
    const [expectedBranchCityOptions, setExpectedBranchCityOptions] = useState([]);
    const [approvedByOptions, setApprovedByOptions] = useState([]);
    const [conversionFactorOptions, setConversionFactorOptions] = useState([]);
    const [agents, setAgents] = useState([]);
    const [freightHSNOpts, setFreightHSNOpts] = useState([]);
    const [productUnitOptions, setProductUnitOptions] = useState([]);

    //Header Data form fields
    const [po_master_transaction_id, setPO_master_transactionID] = useState(0);
    const [cmb_product_type_id, setProductTypeID] = useState("");
    const [cmb_product_category_id, setProductCategoryId] = useState("");
    const [chek_po_life, setPO_life] = useState("C");
    const [chk_purchase_process, setPurchase_process] = useState("M")
    const [chk_po_creation_type, setPO_creation_type] = useState("M")
    const [txt_PO_no, setPOno] = useState(0);
    const [txt_po_version, setPOVersion] = useState(1);
    const [txt_po_date, setPODate] = useState(today);

    const [cmb_supplier_id, setSupplier_id] = useState(""); // supplier id means value of supp branch id
    const [cmb_supplier_state_id, setSupplier_state_id] = useState("");
    const [cmb_supplier_city_id, setSupplier_city_id] = useState("");
    const [cmb_Consignee_id, setConsigneeid] = useState("");
    const [cmb_Consignee_state_id, setConsignee_state_id] = useState("");
    const [cmb_Consignee_city_id, setConsignee_city_id] = useState("");

    const [cmb_currency_id, setCurrencyId] = useState(3);
    const [txt_currency_symbol, SetCurrency_symbol] = useState('₹');
    const [txt_currency_label, setCurrencyLbl] = useState();

    const [cmb_expected_branch_id, setExpected_branch_id] = useState(COMPANY_BRANCH_ID);
    const [cmb_expected_branch_state_id, setExpected_branch_state_id] = useState("");
    const [cmb_expected_branch_city_id, setExpected_branch_city_id] = useState("");
    const [cmb_department, setDepartmentId] = useState("")
    const [cmb_sub_department, setSubDepartmentId] = useState('')

    const [cmb_approved_by_id, setApproved_by_id] = useState(UserId);
    const [txt_approved_date, setApproved_date] = useState("");
    const [txt_schedule_date, setScheduleDate] = useState(today());



    // Raw cotton purchase fields
    const [txt_gate_pass_no, setGatePassNo] = useState("");
    const [dt_gate_pass_date, setGatePassDate] = useState(currentDate);
    const [txt_vehicale_no, setVehicaleNo] = useState("");
    const [cmb_vehicale_type, setVehicaleType] = useState("");
    const [txt_gross_weight, setGrossWeight] = useState("0");
    const [txt_tare_weight, setTareWeight] = useState("0");
    const [txt_variation_weight, setVariationWeight] = useState("0");
    const [txt_net_weight, setNetWeight] = useState("0");
    const [cmb_grader_id, setGraderId] = useState("")
    //******** Raw Cotton Purchase fields ************/
    const [chk_po_mail_sent_status, setPO_mail_sent_status] = useState("P");
    const [chk_PO_acceptance_status, setPO_acceptance_status] = useState("P");
    const [chk_po_status, setPO_status] = useState("P");
    const [txt_status_remark, setStatus_remark] = useState("");
    const [txt_remark, setRemark] = useState("");
    const [chk_po_isactive, setPOIsActive] = useState(true);
    const [txt_quotation_no, setQuotation_no] = useState("");
    const [dt_quotation_date, setQuotation_date] = useState("");
    const [goods_receipt_no, setGRN_no] = useState("")

    //Footer Data form fields
    const [txt_basic_total, setBasic_total] = useState(0)
    const [txt_freight_amount, setFreightAmount] = useState(0);
    const [chk_is_freight_taxable, SetIsFreightTaxable] = useState(false);
    const [cmb_freight_hsn_id, setFreightHSNId] = useState('')
    const [txt_packing_amount, setPackingAmount] = useState(0);
    const [txt_po_discount_percent, setPODiscountPercent] = useState(0);
    const [txt_po_discount_amount, setPODiscountAmount] = useState(0);
    const [txt_transportAmount, setTransportAmount] = useState(0);
    const [txt_cgst_total, setCgstTotal] = useState(0);
    const [txt_sgst_total, setSgstTotal] = useState(0);
    const [txt_igst_total, setIgstTotal] = useState(0);

    const [txt_round_off, setRoundOff] = useState(0);
    const [txt_grand_amount, setGrandTotal] = useState(0);
    const [txt_otherAmount, setOtherAmount] = useState(0);
    const [txt_taxableTotal, setTaxableTotal] = useState(0);

    const [cmb_agentId, setAgentId] = useState('');
    const [txt_agentPercent, setAgentPercent] = useState('0');
    const [chk_agentPaidStatus, setAgentPaidStatus] = useState('P');
    const [txt_other_term_con, setOther_term_con] = useState("");

    //For Print
    const [PurchaseOrderMasterJson, setPurchaseOrderMasterJson] = useState({});

    //Bom po Details filter
    const defaultPODeatails = []
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);


    let requiredCols = ['sr_no', 'product_type_id', 'product_type_name', 'cost_center_id', 'stock_weight', 'stock_quantity', 'product_id', 'product_code', 'product_name', 'product_tech_spect', 'product_hsn_sac_code', 'product_rm_quantity', 'product_rm_rate',
        'material_basic_amount', 'material_discount_percent', 'material_discount_amount',
        'material_taxable_amount', 'material_cgst_percent', 'material_cgst_total', 'material_sgst_percent',
        'material_sgst_total', 'material_igst_percent', 'material_igst_total', 'material_total_amount', 'product_rm_weight', 'material_freight_amount', 'material_schedule_date',
        'product_rm_approved_quantity', 'product_rm_approved_weight', 'product_rm_rejected_quantity', 'product_rm_rejected_weight', 'material_po_approval_remark'
        , 'department_id', 'indented_by_id', 'indent_date', 'indent_no', 'indent_version', 'approved_by_id', 'approved_date', 'product_std_weight', 'product_rm_conversion_factor', 'product_lead_time', 'product_unit_name', 'product_packing_name', 'product_unit_id',
        'product_packing_id', 'product_hsn_sac_code_id', 'product_hsn_sac_rate', 'purchase_order_item_status',
        'pree_closed_quantity', 'pree_closed_weight', 'purchase_return_quantity', 'purchase_return_weight', 'customer_id', 'customer_order_no', 'customer_order_Date',
        'pending_quantity', 'pending_weight', 'excess_quantity', 'excess_weight', 'production_issue_quantity', 'production_issue_weight',
        'production_issue_return_quantity', 'production_issue_return_weight', 'production_issue_rejection_quantity', 'production_issue_rejection_weight',
        'assembly_production_issue_quantity', 'assembly_production_issue_weight', 'sales_quantity', 'sales_weight',
        'sales_return_quantity', 'sales_return_weight', 'sales_rejection_quantity', 'sales_rejection_weight', 'transfer_issue_quantity',
        'transfer_issue_weight', 'transfer_receipt_quantity', 'transfer_receipt_weight', 'outsources_out_quantity', 'outsources_out_weight',
        'outsources_in_quantity', 'outsources_in_weight', 'outsources_rejection_quantity', 'outsources_rejection_weight', 'loan_receipt_quantity',
        'loan_receipt_weight', 'loan_issue_quantity', 'loan_issue_weight', 'cancel_quantity', 'cancel_weight', 'difference_quantity',
        'difference_weight',
    ];

    const [defaultExpecScheduleDate, setDefaultExpecScheduleDate] = useState('')

    //Add Schedules popup open 
    const [scheduleDataArray, setScheduleDataArray] = useState([]);
    const [showSChedules, setSchedules] = useState(false);
    const handleCloseSchedulesPopup = () => setSchedules(false)
    const [schedulerowcount, setSchedulerowcount] = useState(1);
    var scheduleRowCount = 1;
    var remainingSchduleQty = 0;

    // Customers contact details table data hooks
    const [suppContactDetails, setSuppContactDetails] = useState([]);
    const [suppContactDetailsCols, setSuppContactDetailsCols] = useState([]);

    // PO_terms Table Data
    const [POTermsdata, setPOTermsData] = useState([]);
    const [existingCommonTermsData, setExistingCommonTermsData] = useState([])

    //payment terms accordian
    const [paymentTermsData, setPaymentTermsData] = useState([]);
    const [existingPaymentTermsData, setExistingPaymentTermsData] = useState([])

    // Product Type Properties Table Data
    const [productTypePropertiesData, setProductTypePropertiesData] = useState([]);

    // Raw Cotton Deduction Amount 
    const [rawCottonPurchaseDeductionData, setRawCottonPurchaseDeductionData] = useState([])

    // For Taxation Summary.
    let taxationSummary = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));;
    // Taxation Table Data Hooks
    let [taxationSummaryData, setTaxationSummaryData] = useState([]);

    // Document Form
    let documentGroup = "Purchase Order";

    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = () => setShowDocumentForm(false);
    const viewDocumentForm = () => setShowDocumentForm(true);
    // doc list
    const [docData, setDocumentData] = useState([]);

    const [txt_po_packing_percent, setPOPackingPercent] = useState(0);
    const [txt_Freight_percent, setFreightPercent] = useState(0);

    // Render the visible accordions
    const renderAccordions = () => {
        return [
            renderPoDetailsAccordian(),
            renderPaymentTermsAccordian(),
            renderDocumentListAccordian(),
            renderGeneralFooterAccordian(),
        ];
    };


    useEffect(async () => {
        // debugger
        setIsLoading(true);
        try {
            await loadDefaultPoOrdData();
            FnSetFieldsByUserAccess();

            if (idList !== '') {
                await FnCheckUpdateResponce();
            }
            FnSetFieldsByUserAccess();
        } catch (error) {
            console.error(error);
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
        // Close indent order no dropdown 
        // $(document).on('mouseup', function (e) {
        //     var container = $("#indent-order-ul");
        //     if (!container.is(e.target) && container.has(e.target).length === 0) {
        //         container.hide();
        //     }
        // });
        // Close customer order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $(".erp_dropdown-content");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });


    }, [])

    $(document).on('click', '.remove_input_zero', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });

    //------------------------------------------------Add New Record start-------------------------------------
    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    const commonTermMstName = "Purchase Orders Technical Terms";
    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Product Type':
                return <FrmMProductTypeEntry />;
            case 'Add Department':
                return <FrmDepartmentEntry btn_disabled={true} departmentId={cmb_department} />
            case 'Add City':
                return <FrmCity />;
            case 'Common Parameter':
                return <CommonParamterEntry btn_disabled={true} master_name={commonTermMstName} />
            case 'Payment Terms':
                return <FrmPaymentTermsEntry btn_disabled={true} master_name={commonTermMstName} />
            case 'HSN Code':
                return <FrmHSNSAC />
                break;
            default:
                return null;
        }
    }

    // Show ADd record Modal
    const handleCloseRecModal = async () => {

        switch (modalHeaderName) {
            case 'Common Parameter':
                await FnShowPOTermsRecords();
                break;
            case 'Payment Terms':
                await FnShowPaymentTermsRecrds();
                break;
            case 'HSN Code':
                //get hsn codes list used view here because the view is mapped to itself only
                resetGlobalQuery();
                globalQuery.columns = ["field_name", "field_id", "hsn_sac_rate, hsn_sac_code"];
                globalQuery.table = "cmv_hsn_sac";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                const getHsnCode = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                const hsnCodeList = [
                    { value: '', label: 'Select', field_id: '', field_name: '' },
                    { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                    ...getHsnCode.map((hsnCode) => ({ ...hsnCode, value: hsnCode.field_id, label: hsnCode.field_name, product_rm_hsn_sac_rate: hsnCode.hsn_sac_rate, product_rm_hsn_sac_code: hsnCode.hsn_sac_code })),
                ];
                setHsnCodesOptions(hsnCodeList)
                break;

            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }
    //------------------------------------------------Add New Record End---------------------------------------------------

    // navigate to back listing page 
    const moveToListing = () => {
        let Path = '/Transactions/TPurchaseOrder/ManualPO/CottonBalesPO/FrmCottonBalesListing';
        navigate(Path);
    }
    //fn to get po auto no
    const GeneratePONo = async (product_typeShortName) => {
        const productTypeshortname = product_typeShortName === undefined ? $('#cmb_product_type_id option:selected').attr('shortname') : product_typeShortName;
        const productTypeId = $('#cmb_product_type_id option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_purchase_order_master", "purchase_order_no", productTypeshortname, "PO", "5", "purchase_order_type_id", productTypeId);
        if (keyForViewUpdate === '') {
            setPOno(autoNoApiCall);
        }
        return autoNoApiCall;
    };
    // Fn for getgracedays for scheduledate
    const FnGetExpectedScheduleGraceDate = (afterDays) => {
        let currentDate = new Date();
        const dtAfterGraceDays = new Date(currentDate);
        dtAfterGraceDays.setDate(currentDate.getDate() + afterDays);

        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //Fn for load default data of page
    const loadDefaultPoOrdData = async () => {
        debugger
        try {
            //Product Types
            resetGlobalQuery();
            globalQuery.columns.push("product_type_id AS field_id", "product_type_name AS field_name", "product_type_short_name");
            globalQuery.table = "sm_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getProductType = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setProductTypeOptions(getProductType);
            setProductTypeID(12);
            if (keyForViewUpdate === "") {
                await GeneratePONo();
            }

            resetGlobalQuery();
            globalQuery.columns.push("product_category2_id", "product_category2_name");
            globalQuery.table = "sm_product_category2";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: parseInt(12) });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const getProductCategoryTypeAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            if (getProductCategoryTypeAPICall.length > 0) {
                setProductCategoryOptions(getProductCategoryTypeAPICall);
                setProductCategoryId(2);
            }


            // get Currency list with default INR
            resetGlobalQuery();
            globalQuery.columns = ["currency_id, currency_name, currency_code, currency_symbol "];
            globalQuery.table = "fm_currency";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getCurrencyList => {
                    const currency = [
                        ...getCurrencyList.map((currency) => ({ ...currency, value: currency.currency_id, label: `${currency.currency_name} (${currency.currency_code})` })),
                    ];
                    let indianCurrency = getCurrencyList?.find((_) => _.currency_id === Number(cmb_currency_id)) || null;
                    poCurrencyComboRef.current.label = setCurrencyLbl(indianCurrency['currency_name']);
                    setCurrencyOptions(currency);
                });

            //Suppliers List
            resetGlobalQuery();
            globalQuery.columns.push("supp_branch_id AS field_id");
            globalQuery.columns.push("supplier_id");
            globalQuery.columns.push("supp_branch_name AS field_name");
            globalQuery.columns.push("supp_branch_state_id");
            globalQuery.columns.push("supp_branch_city_id");
            globalQuery.columns.push("supp_branch_gst_no");
            globalQuery.table = "cm_supplier_branch";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let getSupplierList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            const suppliers = [
                { value: '', label: 'Select', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                ...getSupplierList.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, supplierGST: supplier.supp_branch_gst_no, supplier_id: supplier.supplier_id })),
            ];
            setSupplierOptions(suppliers);


            //State Options
            resetGlobalQuery();
            globalQuery.columns.push("state_id AS field_id");
            globalQuery.columns.push("state_name AS field_name");
            globalQuery.table = "cm_state";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getConsigneeStateList => {
                    setConsigneeStateOptions(getConsigneeStateList)
                });

            //Employee List
            resetGlobalQuery();
            globalQuery.columns.push("user_id as field_id");
            globalQuery.columns.push("user_name as field_name");
            globalQuery.table = "amv_modules_forms_user_access";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: parseInt(modules_forms_id) });
            globalQuery.conditions.push({ field: "approve_access", operator: "=", value: 1 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getApproveEmpAccessList => {
                    setApprovedByOptions(getApproveEmpAccessList);
                    console.log("Approve Access Emp List: ", getApproveEmpAccessList);
                });




            resetGlobalQuery();
            globalQuery.columns.push("*");
            globalQuery.table = "sm_product_unit_conversion";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const getConversionFactorAPICallList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setConversionFactorOptions(getConversionFactorAPICallList);
            // resetGlobalQuery();
            // globalQuery.columns = ["field_name", "field_id"];
            // globalQuery.table = "amv_properties";
            // globalQuery.orderBy = ["property_id"];
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            // globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "RawCottonPurchaseDeductionsType" });
            // comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            //     .then(getDeductionTypes => {
            //         if (getDeductionTypes.length !== 0) {

            //             setRawCottonPurchaseDeductionData(() => {
            //                 return getDeductionTypes.map((item, index) => ({
            //                     ...item,
            //                     [`deduction${index + 1}_id`]: item.field_id,
            //                     [`deduction${index + 1}_name`]: item.field_name,
            //                     [`deduction${index + 1}_percent`]: 0,
            //                     [`deduction${index + 1}_amount`]: 0,

            //                     // deduction_id: item.field_id,
            //                     // deduction_name: item.field_name,
            //                     // deduction_percent: 0,
            //                     // deduction_amount: 0,
            //                 }));
            //             });
            //             console.log("Raw Cotton Deduction Types List: ", getDeductionTypes);
            //         } else {
            //             setRawCottonPurchaseDeductionData([])
            //         }
            //     });

            // State Options
            resetGlobalQuery();
            globalQuery.columns = ["state_name AS field_name", "state_id AS field_id", "state_code"];
            globalQuery.table = "cm_state";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getStates => {
                    setSupplierStateOptions(getStates);
                    setExpectedBranchStateOptions(getStates);
                });



            //get customer list
            comboDataAPiCall.current.fillMasterData("fmv_cost_center", "", "")
                .then(getCostCenters => {
                    const costCenterList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        ...getCostCenters.map((costCenter) => ({ ...costCenter, value: `${costCenter.field_id}`, label: costCenter.field_name })),
                    ];
                    setCostCenterOptions(costCenterList);
                    console.log("cost center List: ", costCenterList);
                });

            //get hsncode list from view because it mapped itself
            resetGlobalQuery();
            globalQuery.columns = ["field_name", "field_id", "hsn_sac_rate, hsn_sac_code"];
            globalQuery.table = "cmv_hsn_sac";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getHsnSacCode => {
                    const hsnCodeList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                        ...getHsnSacCode.map((hsnCode) => ({ ...hsnCode, value: hsnCode.field_id, label: hsnCode.field_name, product_rm_hsn_sac_rate: hsnCode.hsn_sac_rate, product_rm_hsn_sac_code: hsnCode.hsn_sac_code })),
                    ];
                    setHsnCodesOptions(hsnCodeList);
                    console.log("Hsn Code List: ", hsnCodeList);
                });

            //Get Company Branch List
            resetGlobalQuery();
            globalQuery.columns.push("company_branch_id");
            globalQuery.columns.push("company_branch_name");
            globalQuery.columns.push("branch_state_id");
            globalQuery.columns.push("branch_city_id");
            globalQuery.columns.push("is_sez");
            globalQuery.columns.push("branch_EmailId");
            globalQuery.table = "cm_company_branch";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setExpectedBranchOptions(exptBranchApiCall);
            if (exptBranchApiCall !== null) {
                const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + exptBranchApiCall[0].branch_state_id + "");
                setExpectedBranchCityOptions(cityApiCall);
                setExpected_branch_state_id(exptBranchApiCall[0].branch_state_id)
                setExpected_branch_city_id(exptBranchApiCall[0].branch_city_id)
            }

            // for agents List
            comboDataAPiCall.current.fillMasterData("cmv_agent", "", "")
                .then(getAgetlist => {
                    setAgents(getAgetlist);
                    console.log("Agent List: ", getAgetlist);
                });


            // comboDataAPiCall.current.fillMasterData('cmv_employee', '', '')
            //     .then(getEmployeeList => {
            //         setApprovedByOptions(getEmployeeList);
            //         console.log("Employee List: ", getEmployeeList);
            //     });



            resetGlobalQuery();//get hsn code for freight amount
            globalQuery.columns.push("hsn_sac_id");
            globalQuery.columns.push("hsn_sac_code");
            globalQuery.columns.push("hsn_sac_rate");
            globalQuery.table = "cmv_hsn_sac"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getHSNList => {
                    setFreightHSNOpts(getHSNList);
                    console.log("HSN List: ", getHSNList);
                });

            //get product units  
            resetGlobalQuery();//get hsn code for freight amount
            globalQuery.columns.push("*");
            globalQuery.table = "smv_product_unit"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getProductUnitList => {
                    setProductUnitOptions(getProductUnitList);
                    console.log("Unit List: ", getProductUnitList);
                });

            await FnLoadAccordionData()
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    };
    // fn for fields onchange action
    const comboOnChange = async (key, event) => {
        // debugger
        switch (key) {
            case "productType":
                var productTypeVal = document.getElementById("cmb_product_type_id").value;
                setProductTypeID(productTypeVal);
                if (productTypeVal !== '0' && productTypeVal !== "") {
                    $("#error_cmb_product_type_id").hide();
                    GeneratePONo();

                } else if (productTypeVal === '0') {
                    setProductTypeID(productTypeVal)
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Product Type')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setPOno("0");

                } else {
                    setPOno("0");
                }
                break;
            case "supplier":
                debugger
                let supplierTpVal = supplierComboRef.current.value || '';
                // setSupplier_id(supplierTpVal);
                if (supplierTpVal === '0') {
                    localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: 0, keyForViewUpdate: '' }))
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Supplier')
                    const newTab = window.open('/Masters/FrmSupplier', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (supplierTpVal != null && supplierTpVal != '' && supplierTpVal != '0') {
                    // let selectedOption = $('#cmb_supplier_id').find('option:selected');
                    // let selectedState = selectedOption.attr('suppl_state');
                    // let selectedCity = selectedOption.attr('suppl_city');
                    // setSupplier_state_id(selectedState);

                    let selectedState = supplierComboRef.current?.supp_branch_state_id;
                    let selectedCity = supplierComboRef.current?.supp_branch_city_id;
                    setSupplier_state_id(selectedState);
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + selectedState + "");
                    setSupplierCityOpts(cityApiCall);
                    setSupplier_city_id(selectedCity);
                    await FnGetSupplierContacts(parseInt(supplierTpVal));
                    setTimeout(() => {
                        if (po_details_data.length !== 0) {
                            updateTblCalsForSupplStateChange();
                        }
                    }, 200);
                    $("#error_cmb_supplier_id").hide();
                } else {
                    setSupplier_state_id('');
                    setSupplier_city_id('');
                }
                break;

            case "supplier_state":
                var supplierStateTpVal = document.getElementById("cmb_supplier_state_id").value;
                setSupplier_state_id(supplierStateTpVal)
                if (supplierStateTpVal !== "" && supplierStateTpVal !== "0") {
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + supplierStateTpVal + "");
                    setSupplierCityOpts(cityApiCall);
                    setSupplier_city_id('');
                    if (po_details_data.length !== 0) {
                        updateTblCalsForSupplStateChange();
                    }
                }
                else {
                    setSupplierCityOpts([]);
                }
                break;
            case "supplier_city":
                var suppliercityTpVal = document.getElementById("cmb_supplier_city_id").value;
                setSupplier_city_id(suppliercityTpVal)
                if (suppliercityTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                }
                break;



            case "Consignee":
                let ConsigneeTpVal = consigneeComboRef.current.value;
                // setConsigneeid(ConsigneeTpVal);

                if (ConsigneeTpVal != null && ConsigneeTpVal != '' & ConsigneeTpVal != '0') {
                    // let selectedOption = $('#cmb_Consignee_id').find('option:selected');
                    let selectedOption = consigneeComboRef.current
                    let selectedState = selectedOption.supp_branch_state_id
                    let selectedCity = selectedOption.supp_branch_city_id
                    setConsignee_state_id(selectedState);
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + selectedState + "");
                    setConsigneeCityOpts(cityApiCall);
                    console.log(cityApiCall)
                    setConsignee_city_id(selectedCity);
                } else if (ConsigneeTpVal === "0") {
                    localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: 0, keyForViewUpdate: '' }))
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Supplier')
                    const newTab = window.open('/Masters/FrmSupplier', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                else {
                    setConsignee_state_id('');
                    setConsignee_city_id('');
                }
                break;

            case "Consignee_city":
                var suppliercityTpVal = document.getElementById("cmb_Consignee_city_id").value;
                setConsignee_city_id(suppliercityTpVal)
                if (suppliercityTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                }
                break;
            case "company_city":
                var companycityTpVal = document.getElementById("cmb_expected_branch_city_id").value;
                if (companycityTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                }
                break;

            case "agent":
                var cmb_agentId = document.getElementById("cmb_agentId").value;

                if (cmb_agentId === '0') {
                    const newTab = window.open('/Masters/Agent', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                    setAgentId('')
                }
                break;

            case "po_status":

                break;
            default:
                break;
        }
    };
    const FnsetMaterialItemStatus = (statusVal) => {
        let copyOfPodetails = [];
        if (keyForViewUpdate === 'view') {
            if (statusVal === 'Z') {
                copyOfPodetails = po_details_data.map((item) => {
                    return {
                        ...item,
                        pree_closed_quantity: item.pending_quantity,
                        pree_closed_weight: item.pending_weight,
                        purchase_order_item_status: statusVal,
                    };
                });
                $("#save-btn").prop("disabled", false);
            } else {
                copyOfPodetails = po_details_data.map((item) => {
                    return {
                        ...item,
                        pree_closed_quantity: 0,
                        pree_closed_weight: 0,
                        purchase_order_item_status: statusVal,
                    };
                });
                $("#save-btn").prop("disabled", true);
            }

        } else {
            copyOfPodetails = po_details_data.map((item) => {
                return {
                    ...item,
                    purchase_order_item_status: statusVal,
                };
            });
        }
        FnUpdateTotalPODetailsArr(copyOfPodetails);
    }
    //Function for get contact ids,name & mobile nos of supplier
    const FnGetSupplierContacts = async (SelectedSupplierBranch) => {






        if (SelectedSupplierBranch !== '' || SelectedSupplierBranch !== '0') {
            resetGlobalQuery();
            globalQuery.columns = ["supplier_id", "supplier_branch_name", "supp_branch_id", "supplier_contact_id",
                "supp_contact_person", "supp_contact_no", "supp_alternate_contact", "supp_email_id", "supp_alternate_EmailId", "supp_designation"]

            globalQuery.table = "cmv_supplier_contacts";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "supp_branch_id", operator: "=", value: SelectedSupplierBranch });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

            if (getContactDtlsApiCall.length !== 0) {
                let column = [];
                let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                    if (colKey === 0) {
                        column.push({
                            Headers: <div className="col-1"><input type='checkbox' class="me-1" name="selectAllSuppContact" id="selectAllSuppContact" onClick={(e) => { toggleChkAllBoxes('selectAllSuppContact'); }} />Select</div>,
                            accessor: "Action",
                            Cell: row => (
                                <div className="text-center">
                                    <input type='checkbox' class="selectSuppContactPerson" name="selectSuppContactPerson" id={'selectSuppContactPerson_' + row.original.supplier_contact_id} contact_personId={row.original.supplier_contact_id} value={row.original.supplier_contact_id} />
                                </div>
                            ),
                        });
                    }
                    if (!columnHeads[colKey].includes('supplier_id') && !columnHeads[colKey].includes('supplier_contact_id') && !columnHeads[colKey].includes('supp_branch_id')) {
                        column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                    }
                }
                setSuppContactDetailsCols(column);
                setSuppContactDetails(getContactDtlsApiCall);

                // const supplierContactId = getContactDtlsApiCall[0].supplier_contact_id;
                // $('input[type="checkbox"][id="selectSuppContactPerson_' + supplierContactId + '"]').prop('checked', true);

                if (keyForViewUpdate === '') {  // or check specifically `if (ActionType() === '(Creation)')`
                    const supplierContactId = getContactDtlsApiCall[0].supplier_contact_id;
                    $(`#selectSuppContactPerson_${supplierContactId}`).prop('checked', true);
                }

                if (getContactDtlsApiCall.length === 1 && document.querySelector('.selectSuppContactPerson').checked) {
                    toggleChkAllBoxes('PartiallySuppContactSelection'); // Check all checkboxes by default
                }

                
            } else {
                setSuppContactDetailsCols([]);
                setSuppContactDetails([]);
            }
        }
        // Onchange event listener for the customer contact Selection
        $('.selectSuppContactPerson').on('change', function () {
            toggleChkAllBoxes('PartiallySuppContactSelection');
        });

    }

    const validateForm = (formId) => {
        var formObj = $("#" + formId);
        var inputObj;
        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === "text" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "select-one" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "textarea" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "date" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            }
        }
    };

    const validateFields = () => {

    };

    // to scroll suppliers contact ids box
    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('po-details-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#po-details-table').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('po-details-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    // -------------------------------- PO Details section start---------------------------------------------------------------------------
    // Fn to get BOM modal with items
    const viewBomFilterForm = async () => {
        $('#cmb_department').attr("optional", "optional");
        let checkIsValidate = await validate.current.validateForm("PO_header_formId");
        $('#cmb_department').removeAttr("optional");
        if (checkIsValidate === true) {
            sessionStorage.setItem('requiredCols', JSON.stringify(requiredCols));
            setShowBomFilterForm(true);
        }
    }
    // function for PO Details table
    const handleCloseBomFilterForm = async () => {


    };

    //fn for get stock quantity
    const FnUpdateStockAvailability = async (materialDetailsData, setInTable) => {
        let uniqueMaterialIds = [...new Set(materialDetailsData.map(material => material.product_rm_id))];

        // Get the available stock qty.
        resetGlobalQuery();
        globalQuery.columns = ["product_rm_id", "SUM(closing_balance_quantity)"]
        globalQuery.table = "smv_product_rm_stock_summary"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
        globalQuery.conditions.push({ field: "product_rm_id", operator: "IN", values: [...new Set(uniqueMaterialIds)] });
        let getAvlStockQty = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        // get the available stock-qty and set in details.
        let materialDetailsUpdatedData = materialDetailsData.map(material => {
            const stock = getAvlStockQty.find(stock => stock.product_rm_id === material.product_rm_id);
            return stock ? {
                ...material,
                available_stock_quantity: stock?.closing_balance_quantity || 0,
                available_stock_weight: stock?.closing_balance_weight || 0
            } : material;
        });
        return materialDetailsUpdatedData;
    }


    //Fn for get material rate form rate master for raw cotton
    const FnGetRawCottonRate = async (rowData) => {

        let materialRate = 0;
        resetGlobalQuery();
        globalQuery.columns.push('material_rate');
        globalQuery.table = "smv_product_current_rate";
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
        globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: rowData.product_id });
        const getMaterialRate = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        if (getMaterialRate.length !== 0) {
            materialRate = getMaterialRate[0].material_rate / 100;
        }
        const newMaterial = {
            ...rowData,
            product_rm_rate: materialRate,
            product_material_po_quantity_qunital: 0,
            product_rm_quantity: 0,
        }
        return newMaterial;
    }

    const FnupdateDetailsRowData = (rowData) => {

        const newMaterial = {
            ...rowData,
            material_schedule_date: txt_schedule_date,
            material_po_approval_remark: '',
            approved_date: "",
            customer_id: '',
            customer_order_no: '',
            customer_order_Date: '',
            purchase_order_item_status: 'P',
            remark: '',

            product_rm_id: rowData.product_id,
            product_material_code: rowData.product_code,
            product_rm_name: rowData.product_name,
            product_rm_tech_spect: rowData.product_tech_spect,
            product_rm_hsn_sac_code: rowData.product_hsn_sac_code,
            product_rm_weight: rowData.product_std_weight,
            lead_time: rowData.product_lead_time,
            product_rm_stock_unit_name: rowData.product_unit_name,
            product_rm_packing_name: rowData.product_packing_name,
            product_rm_stock_unit_id: rowData.product_unit_id,
            product_rm_packing_id: rowData.product_packing_id,
            product_rm_hsn_sac_code_id: rowData.product_hsn_sac_code_id,
            product_rm_hsn_sac_rate: rowData.product_hsn_sac_rate,
            product_rm_std_weight: rowData.product_std_weight,
            product_rm_quantity: 1,
            available_stock_quantity: rowData.stock_quantity,
            available_stock_weight: rowData.stock_weight

        }
        return newMaterial;

    }
    const categoryDisplay = () => {
        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }
    // //Fn to get customer order nos on customer change
    // const FngetCustOrdNos = async (object, creation_type) => {

    //     let customer_id = object.customer_id;
    //     let product_rm_id = object.product_rm_id;
    //     let selectedOrderValue = object.customer_order_no;
    //     var custOrdNoId = '';
    //     if (creation_type !== undefined && creation_type === 'A') {
    //         const indentNoAsId = object.indent_no.replace(/\//g, '_');
    //         custOrdNoId = document.getElementById("cmb_customer_order_no_" + `${indentNoAsId}` + "_" + product_rm_id + "_" + object.so_sr_no);
    //     } else {
    //         custOrdNoId = document.getElementById("cmb_customer_order_no_" + product_rm_id);
    //     }
    //     if (customer_id !== "" & customer_id !== "0") {
    //         resetGlobalQuery();
    //         globalQuery.columns.push("customer_order_no");
    //         globalQuery.columns.push("customer_order_date");
    //         globalQuery.table = "mtv_sales_order_master_trading_summary"
    //         globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    //         globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customer_id });
    //         globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //         const getCustomerOrdersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    //         getCustomerOrdersApiCall.forEach(item => {
    //             const optionElement = document.createElement('option');
    //             optionElement.value = item.customer_order_no;
    //             optionElement.setAttribute('ord_date', item.customer_order_Date);
    //             optionElement.text = item.customer_order_no;
    //             custOrdNoId.appendChild(optionElement);
    //         });
    //         custOrdNoId.value = selectedOrderValue;
    //     } else {
    //         while (custOrdNoId.options.length > 2) {
    //             custOrdNoId.remove(2); // Remove the second option (index 1).
    //         }
    //     }

    // }
    // Get indent nos


    const POBlankObj = {
        product_rm_quantity: 1, product_rm_weight: 1, product_rm_rate: 0, material_basic_amount: 0, material_discount_percent: 0, material_discount_amount: 0,
        material_taxable_amount: 0, material_cgst_percent: 0, material_cgst_total: 0, material_sgst_percent: 0, material_sgst_total: 0, material_igst_percent: 0, material_igst_total: 0, material_total_amount: 0, material_freight_amount: 0,
        material_schedule_date: today(), product_rm_conversion_factor: 0
    }


    // appendRow Triggered for Product Based Properties
    // Initialize useReducer
    const appendRowTriggered = useRef(false);
    let footerDiscountChange = useRef(false);
    const [distinctMaterialIds, setDistinctMaterailIds] = useState([]);

    const FnUpdatePODeatils = (state, action) => {
        switch (action.type) {
            case 'appendRow':
                appendRowTriggered.current = true; // when appending row for product based properties or tax summary
                return [...state, action.payload];

            case 'deleteRow':
                appendRowTriggered.current = true; // when appending row for product based properties or tax summary
                let productId = state[action.keys.index]['product_rm_name'];
                setProductTypePropertiesData((prevData) =>
                    prevData.filter((item) => item['product_material_name'] !== productId)
                );
                return state.filter((podata, i) => i !== action.keys.index);

            case 'updateRow':
                return state.map((podata, index) =>
                    index === action.index ? { ...podata, ...action.updatedPOData } : podata
                );

            case 'updateTotalData':
                return action.updatedData.length > 0 ? action.updatedData : [];

            default:
                return state; // Always return state for unknown actions
        }
    };

    const [po_details_data, actionPOdata] = useReducer(FnUpdatePODeatils, defaultPODeatails);

    useEffect(() => {
        debugger
        if (appendRowTriggered.current === true) {
            setIsLoading(true);
            const identicalMaterialIds = po_details_data.map(po => po.product_rm_id);
            fnGetProductProperties(identicalMaterialIds);

            FnGetTaxationSummary();
            calculateGrandTotal();
            appendRowTriggered.current = false;
            setIsLoading(false);
        }

    }, [po_details_data]);


    // Fn for grouped taxation table
    const FnGetTaxationSummary = () => {
        // debugger

        const distinctHSNCodes = [...new Set(po_details_data.map(po => po.product_rm_hsn_sac_code_id))];
        return summationOfDistinctCodes(distinctHSNCodes, po_details_data);

    }


    const handleSearch = useCallback((BalesData) => {
        debugger
        try {
            // if (distinctMaterialIds.includes(BalesData['product_rm_id'])) {
            //     setErrMsg(`${BalesData['product_rm_name']} already exists`);
            //     setShowErrorMsgModal(true);
            // } else {
            actionPOdata({ type: "appendRow", payload: { ...BalesData, ...POBlankObj } });
            // }

        } catch (error) {
            // Handle any error here
            console.error("Error in handleSearch:", error);
        }
    }, []);

    const FnUpdateTotalPODetailsArr = (latestUpdatedPOData) => {
        actionPOdata({
            type: 'updateTotalData',
            updatedData: latestUpdatedPOData
        });
    };


    // Utility function to check if a field is empty
    const validateField = (field, errorId, errorMessage) => {
        if ([0, '', undefined, null].includes(field)) {
            $(`#${errorId}`).text(errorMessage).show();
            return false;
        } else {
            $(`#${errorId}`).hide();
            return true;
        }
    };

    // Function to validate the Purchase Order (PO) form
    const FnValidatePoOrder = async () => {
        if (!validateField(cmb_supplier_id, "error_cmb_supplier_id", "Please Select Supplier...!")) return false;
        if (!validateField(cmb_supplier_state_id, "error_cmb_supplier_state_id", "Add State Against Supplier..!")) return false;
        if (!validateField(cmb_supplier_city_id, "error_cmb_supplier_city_id", "Please Select City...!")) return false;

        // Validate if at least one supplier contact person is selected
        let checkedCount = $('input[name="selectSuppContactPerson"]:checked').length;
        if (checkedCount === 0) {
            $("#error_supplier_contact_persons").text('Please Select At Least One Contact').show();
            return false;
        } else {
            $("#error_supplier_contact_persons").hide();
        }

        // If PO is in approval mode, validate approved by field
        if (keyForViewUpdate === 'approve') {
            if (!validateField(cmb_approved_by_id, "error_cmb_approved_by_id", "Select Approved By...!")) return false;
        }

        // Validate if at least one material is selected
        if (po_details_data?.length <= 0) {
            setErrMsg("Please Select At Least One Material");
            setShowErrorMsgModal(true);
            return false;
        } else {
            return FnValidatePODetailsTbl(); // Validate table details
        }
    };

    // Function to validate PO details table
    const FnValidatePODetailsTbl = () => {
        debugger
        let validation = true; // Assume validation is successful

        po_details_data.forEach((item) => {
            const productId = item['product_rm_id'];

            ['cost_center', 'product_rm_hsn_sac_code_id', 'product_rm_quantity', 'product_rm_rate'].forEach((key) => {
                if (item.hasOwnProperty(key)) {
                    const elementId = `${key}_${productId}`;
                    const element = document.getElementById(elementId);

                    if (element && isElementVisible(element)) {
                        const value = element.value || element.textContent || element.innerText;
                        if ([0, '0', '', undefined, null].includes(value)) {
                            element.parentElement.dataset.tip = 'Enter Proper Data...!';
                            element.focus();
                            validation = false; // Mark validation as failed
                        }
                    }
                }
            });
        });

        return validation;
    };

    // Function to check if an element is visible
    const isElementVisible = (el) => {
        return !!(el && el.offsetParent !== null);
    };



    const poTblRows = useMemo(() => {
        return po_details_data.map((poItem, index) => (
            <tr rowIndex={index} >
                <td className={`erp_table_td sticky-column  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`}>
                    <div style={{ display: "flex" }}>
                        <Tooltip title="Delete" placement="top">
                            <MDTypography className="erp-view-btn">
                                <MdDelete
                                    className="erp-delete-btn"
                                    onClick={() => { actionPOdata({ type: 'deleteRow', keys: { index } }); }}
                                />
                            </MDTypography>
                        </Tooltip>

                    </div>
                </td>
                <td className="erp_table_td text-end">{index + 1}</td>
                <td className="erp_table_td ">{poItem.product_rm_code}</td>
                <td className={`erp_table_td`}>{poItem.product_rm_name}</td>

                <>
                    <td className="erp_table_td ">
                        <Select
                            id={`cost_center_id_${poItem.product_rm_id}`}
                            value={CostCenterOptions.find(option => option.value === poItem.cost_center_id)}
                            onChange={(selectedOption) => updatePODetailsTblData(poItem, selectedOption, index, 'cost_center')}
                            options={CostCenterOptions}
                            isDisabled={true}
                            className="form-search-custom"
                            classNamePrefix="custom-select" // Add custom prefix for class names
                            styles={{
                                option: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px' // Adjust the font size as per your requirement
                                }),
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px' // Adjust the font size as per your requirement
                                }),
                                input: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px' // Adjust the font size as per your requirement
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    maxHeight: '150px', // Adjust height for scrollbar to appear
                                    overflowY: 'auto'   // Enable vertical scrollbar
                                }),
                                menuList: (provided, state) => ({
                                    ...provided,
                                    maxHeight: '150px', // Limit the menu list height
                                    overflowY: 'auto',  // Enable vertical scrolling
                                    paddingRight: '10px', // Ensures padding space for scrollbar
                                })
                            }}
                        />

                    </td>
                </>
                {/* } */}

                <td className="erp_table_td wide-td">
                    <Select
                        id={`product_rm_hsn_sac_code_id_${poItem.product_rm_id}`}
                        value={hsnCodesOptions.find(option => option.value === parseInt(poItem.product_rm_hsn_sac_code_id))}
                        onChange={(selectedOption) => updatePODetailsTblData(poItem, selectedOption, index, 'product_rm_hsn_sac_code_id')}
                        options={hsnCodesOptions}
                        isDisabled={(keyForViewUpdate !== '' && keyForViewUpdate !== 'update' && poItem.product_rm_hsn_sac_code_id !== '0')}
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        product_rm_id={`${poItem.product_rm_id}`}
                        Headers='product_rm_hsn_sac_code_id'
                        product_rm_name={`${poItem.product_rm_name}`}
                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                                fontSize: '12px' // Adjust the font size as per your requirement
                            }),
                            singleValue: (provided, state) => ({
                                ...provided,
                                fontSize: '12px' // Adjust the font size as per your requirement
                            }),
                            input: (provided, state) => ({
                                ...provided,
                                fontSize: '12px' // Adjust the font size as per your requirement
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                maxHeight: '150px', // Adjust height for scrollbar to appear
                                overflowY: 'auto'   // Enable vertical scrollbar
                            }),
                            menuList: (provided, state) => ({
                                ...provided,
                                maxHeight: '150px', // Limit the menu list height
                                overflowY: 'auto',  // Enable vertical scrolling
                                paddingRight: '10px', // Ensures padding space for scrollbar
                            })
                        }}
                    />
                </td>

                <td>
                    <select id={`product_rm_purchase_unit_id_${poItem.product_rm_id}`} className="form-select form-select-sm" value={poItem.product_rm_purchase_unit_id} Headers='product_rm_purchase_unit_id'
                        onChange={(e) => { updatePODetailsTblData(poItem, e, index); }} >
                        <option value=''>Select</option>
                        {productUnitOptions.length !== 0 ?
                            <>
                                {productUnitOptions?.map(unitItem => (
                                    <option value={unitItem.field_id} >{unitItem.field_name}</option>
                                ))}
                            </>
                            : ''
                        }
                    </select></td>

                <td className="erp_table_td text-end">{txt_currency_label}</td>

                <td className="erp_table_td ">
                    <input
                        type="text"
                        className="erp_input_field mb-0 text-end" Headers='product_rm_quantity'
                        id={`product_rm_quantity_${poItem.product_rm_id}`}
                        value={poItem.product_rm_quantity} // Use a function to get the input value based on propertyName
                        onChange={(e) => { updatePODetailsTblData(poItem, e, index, 'product_rm_quantity'); }}
                        onBlur={(e) => { updatePODetailsTblData(poItem, e, index); }}
                    /></td>
                <td className="erp_table_td text-end">{poItem.product_rm_weight}</td>
                {/* <td className="erp_table_td text-end">{poItem.available_stock_quantity}</td> */}

                <td className={`erp_table_td ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>
                    <input
                        type="text"
                        className="erp_input_field mb-0 text-end approveField "
                        Headers='product_rm_approved_quantity'
                        id={`product_rm_approved_quantity_${poItem.product_rm_id}`} disabled={!isApprove}
                        value={poItem.product_rm_approved_quantity} // Use a function to get the input value based on propertyName
                        onChange={(e) => { updatePODetailsTblData(poItem, e, index); }} onBlur={(e) => { updatePODetailsTblData(poItem, e, index); }}
                    /></td>
                <td className={`erp_table_td ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>
                    <input
                        as="textarea"
                        className="erp_input_field mb-0 approveField"
                        Headers='material_po_approval_remark'
                        id={`material_po_approval_remark_${poItem.product_rm_id}`} disabled={!isApprove}
                        value={poItem.material_po_approval_remark} maxLength={500} // Use a function to get the input value based on propertyName
                        onChange={(e) => { updatePODetailsTblData(poItem, e, index); }}
                    /></td>

                <td className="erp_table_td text-end">
                    <input
                        type="text"
                        className="erp_input_field mb-0 text-end" Headers='product_rm_rate'
                        id={`product_rm_rate_${poItem.product_rm_id}`}
                        value={poItem.product_rm_rate} // Use a function to get the input value based on propertyName
                        onChange={(e) => { updatePODetailsTblData(poItem, e, index); }}
                        onBlur={(e) => { updatePODetailsTblData(poItem, e, index); }}
                    />
                </td>
                <td className="erp_table_td text-end">{poItem.material_basic_amount}</td>
                <td className="erp_table_td ">
                    <input
                        type="text"
                        className="erp_input_field mb-0 text-end" Headers='material_discount_percent'
                        id={`material_discount_percent_${poItem.product_rm_id}`}
                        value={poItem.material_discount_percent} // Use a function to get the input value based on propertyName
                        onChange={(e) => { updatePODetailsTblData(poItem, e, index); }}
                    />
                </td>
                <td className="erp_table_td ">
                    <input
                        type="text"
                        className="erp_input_field mb-0 text-end" Headers='material_discount_amount'
                        id={`material_discount_amount_${poItem.product_rm_id}`}
                        value={poItem.material_discount_amount} // Use a function to get the input value based on propertyName
                        onChange={(e) => { updatePODetailsTblData(poItem, e, index); }}
                    />
                </td>
                <td className="erp_table_td text-end">{poItem.material_taxable_amount}</td>
                <td className="erp_table_td text-end">{poItem.material_cgst_percent}</td>
                <td className="erp_table_td text-end">{poItem.material_cgst_total}</td>
                <td className="erp_table_td text-end">{poItem.material_sgst_percent}</td>
                <td className="erp_table_td text-end">{poItem.material_sgst_total}</td>
                <td className="erp_table_td text-end">{poItem.material_igst_percent}</td>
                <td className="erp_table_td text-end">{poItem.material_igst_total}</td>
                <td className="erp_table_td text-end">{poItem.material_total_amount}</td>
                <td className="erp_table_td text-end">{poItem.material_freight_amount}</td>
                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(poItem.material_schedule_date)}</td>
                <td className={`erp_table_td ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>{validateNumberDateInput.current.formatDateToDDMMYYYY(poItem.approved_date)}</td>
                <td className="erp_table_td ">
                    <select id={`purchase_order_item_status_${poItem.product_rm_id}`} disabled={!isApprove} value={poItem.purchase_order_item_status} className="form-select form-select-sm approveField"
                        Headers='purchase_order_item_status' onChange={(e) => { updatePODetailsTblData(poItem, e, index); }} >
                        <option value="P">Pending</option>
                        <option value="A">Approved</option>
                        <option value="R">Rejected</option>
                        <option value="I">Partial Receipt</option>
                        <option value="C">Completed</option>
                        <option value="X">Canceled</option>
                        <option value="Z">PreeClosed</option>
                        <option value="B">Bill Booked</option>
                    </select>
                </td>
                <td className="erp_table_td text-end">{poItem.product_rm_std_weight}</td>
                <td>
                    <select id={`product_rm_stock_unit_id_${poItem.product_rm_id}`} className="form-select form-select-sm" value={poItem.product_rm_stock_unit_id} Headers='product_rm_stock_unit_id'
                        onChange={(e) => { updatePODetailsTblData(poItem, e, index); }} >
                        <option value=''>Select</option>
                        {productUnitOptions.length !== 0 ?
                            <>
                                {productUnitOptions?.map(unitItem => (
                                    <option value={unitItem.field_id} >{unitItem.field_name}</option>
                                ))}
                            </>
                            : ''
                        }
                    </select></td>


                <td className="erp_table_td text-end">{poItem.product_rm_conversion_factor}</td>
                <td className="erp_table_td text-end">{poItem.product_rm_hsn_sac_rate}</td>
            </tr>
        ));
    }, [po_details_data, txt_currency_label]);


    //Fn to render po details table
    const renderPODetailsTbl = useMemo(() => {
        return <>
            {po_details_data.length !== 0 ?
                <Table className="erp_table erp_table_scroll" id='po-details-table' responsive bordered striped>
                    <thead className="erp_table_head">
                        <tr>
                            <th className={`erp_table_th  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`}>Action</th>
                            <th className="erp_table_th">Sr No</th>
                            <th className="erp_table_th">Material Code</th>
                            <th className={`erp_table_th`} style={{ width: '100px', paddingRight: '120px' }}>Material Name</th>
                            <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Cost Center</th>
                            <th className="erp_table_th"> Hsn Sac Code</th>
                            <th className="erp_table_th">Purchase Unit Name</th>
                            <th className="erp_table_th">Currency</th>
                            <th className="erp_table_th">PO Quantity</th>
                            <th className="erp_table_th">PO Weight</th>
                            {/* <th className="erp_table_th">Stock Quantity</th> */}
                            <th className={`erp_table_th  ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>Approved Quantity</th>
                            <th className={`erp_table_th  ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>Approval Remark</th>
                            <th className="erp_table_th">Material Rate</th>
                            <th className="erp_table_th">Basic Amount</th>
                            <th className="erp_table_th">Discount %</th>
                            <th className="erp_table_th">Discount Amount</th>
                            <th className="erp_table_th">Taxable Amount</th>
                            <th className="erp_table_th">Cgst %</th>
                            <th className="erp_table_th">Cgst Total</th>
                            <th className="erp_table_th">Sgst %</th>
                            <th className="erp_table_th">Sgst Total</th>
                            <th className="erp_table_th">Igst %</th>
                            <th className="erp_table_th">Igst Total</th>
                            <th className="erp_table_th">Total Amount</th>
                            <th className="erp_table_th">Freight Amount</th>
                            <th className="erp_table_th">Schedule Date</th>
                            <th className={`erp_table_th ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>Approved Date</th>
                            <th className="erp_table_th">Material Item Status</th>
                            <th className="erp_table_th">Std Weight</th>
                            <th className="erp_table_th">Stock Unit Name</th>

                            <th className="erp_table_th">Conversion Factor</th>
                            <th className="erp_table_th">Hsn Sac Rate</th>
                        </tr>
                    </thead>

                    <tbody>
                        {poTblRows}
                    </tbody>
                </Table> : null
            }
        </>
    }, [po_details_data, txt_currency_label]);


    //function for update Details table data
    const updatePODetailsTblData = async (currentRowData, event, arrayIndex, param) => {
        debugger
        console.log('event : - ', event.value);
        let clickedColName;
        let focusedInputField;
        if (param === 'product_rm_hsn_sac_code_id') {
            clickedColName = 'product_rm_hsn_sac_code_id';
        } else if (param === 'cost_center') {
            clickedColName = 'cost_center_id';
        } else {
            clickedColName = event.target.getAttribute('Headers');               // Get the column name where event triggered.
            focusedInputField = document.querySelector('#' + event.target.id);
        }
        if (clickedColName === 'remark') {
            currentRowData[clickedColName] = event.target.value;

        } else if (clickedColName === 'material_schedule_date') {
            if (event.target.value !== '' || event.target.value !== undefined) {
                currentRowData[clickedColName] = event.target.value;
            } else {
                currentRowData[clickedColName] = '';
            }
        } else if (clickedColName === 'material_po_approval_remark') {
            if (validateText(event)) {
                currentRowData[clickedColName] = event.target.value;
            } else {
                currentRowData[clickedColName] = '';
            }
        } else if (clickedColName.includes('_status')) {
            currentRowData[clickedColName] = event.target.value;

        } else if (clickedColName.includes('product_rm_stock_unit_id')) {
            if (event.target.value !== '') {

                currentRowData[clickedColName] = event.target.value;
                delete focusedInputField.parentElement.dataset.tip;
            } else {
                currentRowData[clickedColName] = '';
                focusedInputField.parentElement.dataset.tip = 'Please Select Atleast One...!';
            }
        } else {
            // currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
            param === 'product_rm_hsn_sac_code_id' || param === 'cost_center' ? currentRowData[clickedColName] = event.value : currentRowData[clickedColName] = event.target.value

            if (currentRowData[clickedColName] === '') {
                focusedInputField.parentElement.dataset.tip = 'Please fill this field...!';
            }
            else {
                if (param !== 'product_rm_hsn_sac_code_id' && param !== 'cost_center') {
                    delete focusedInputField.parentElement.dataset.tip
                }

                if (keyForViewUpdate === 'approve') {
                    let product_rm_qty = currentRowData['product_rm_quantity']
                    let product_app_qty = currentRowData['product_rm_approved_quantity']
                    let product_rejct_qty = (product_rm_qty - product_app_qty);
                    let product_std_wgt = currentRowData['product_rm_std_weight']
                    let product_approved_wgt = product_app_qty * product_std_wgt;
                    let product_rejct_wgt = product_rejct_qty * product_std_wgt;

                    currentRowData['product_rm_approved_weight'] = product_approved_wgt.toFixed(2);
                    currentRowData['product_rm_rejected_weight'] = product_rejct_wgt.toFixed(2);
                    let poItemsQtyinp = document.querySelector('#' + event.target.id);

                    if (product_app_qty > product_rm_qty) {
                        focusedInputField.parentElement.dataset.tip = 'cannot exceed material qauntity...!';
                        return false;
                    }
                    if (product_rejct_qty > 0) {
                        currentRowData['product_rm_rejected_quantity'] = product_rejct_qty
                        if ($(`#material_po_approval_remark_${currentRowData.product_rm_id}`).val() === '') {
                            $(`#material_po_approval_remark_${currentRowData.product_rm_id}`).prop('disabled', false);
                            if (event._reactName === 'onBlur') {
                                $(`#material_po_approval_remark_${currentRowData.product_rm_id}`).focus();
                            }
                        }
                    } else {
                        currentRowData['product_rm_rejected_quantity'] = 0
                        delete focusedInputField.parentElement.dataset.tip;
                    }
                    // Update the schedules on the product_rm_approved_quantity basis. (On Approve Action)
                    // if (event._reactName === 'onBlur' && keyForViewUpdate === 'approve' && clickedColName === 'product_rm_approved_quantity' && scheduleDataArray.length !== 0) {

                    //     FnUpdateSchedulesOnQtyChng(scheduleDataArray, currentRowData, 'product_rm_approved_quantity');
                    // }

                } else if (clickedColName.includes('material_discount_percent')) {
                    if (chk_po_status === 'P' || chek_po_life === 'O') {
                        //  let material_discount_percent = parseFloat([...po_details_data].reduce((total, item) => total + parseFloat(item.material_discount_percent), 0));
                        if (currentRowData[clickedColName] > 100) {
                            currentRowData[clickedColName] = 0;
                            focusedInputField.parentElement.dataset.tip = 'cannot exceed Discount Percent (100%)...!';
                        } else if (currentRowData.product_rm_rate !== 0) {
                            const calculatedDiscountAmount = (event.target.value / 100) * currentRowData.material_basic_amount;
                            // Then set the discount amount and discount percentage.
                            currentRowData.material_discount_percent = event.target.value;
                            currentRowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(calculatedDiscountAmount, 4);
                            // currentRowData["material_discount_amount"] = 0;
                            delete focusedInputField.parentElement.dataset.tip;
                        } else {
                            currentRowData.material_discount_amount = 0;
                            currentRowData.material_discount_percent = 0;
                            focusedInputField.parentElement.dataset.tip = 'Please fill material rate...!';
                            setTimeout(function () {
                                delete focusedInputField.parentElement.dataset.tip;
                            }, 4000);
                        }
                    }
                } else if (clickedColName.includes('material_discount_amount')) {

                    if (chk_po_status === 'P' || chek_po_life === 'O') {
                        if (currentRowData.product_rm_rate !== 0 && currentRowData.product_rm_rate >= event.target.value) {
                            fnCalculateAmount(currentRowData);
                            const calculatedPercent = isNaN(event.target.value * 100 / currentRowData.material_basic_amount) ? 0 : parseFloat(event.target.value * 100) / currentRowData.material_basic_amount;
                            currentRowData.material_discount_amount = event.target.value; // Then set the discount amount and discount percentage.
                            currentRowData.material_discount_percent = validateNumberDateInput.current.decimalNumber(calculatedPercent, 2);
                            // currentRowData["material_discount_percent"] = 0;
                            delete focusedInputField.parentElement.dataset.tip;
                        } else {
                            currentRowData.material_discount_amount = 0;
                            currentRowData.material_discount_percent = 0;
                            focusedInputField.parentElement.dataset.tip = 'cannot exceed material rate...!';
                            setTimeout(function () {
                                delete focusedInputField.parentElement.dataset.tip;
                            }, 4000);
                        }
                    }
                } else if (['product_rm_quantity', 'product_rm_purchase_unit_id', 'product_rm_rate'].includes(clickedColName)) {
                    if (clickedColName === 'product_rm_quantity') { currentRowData['product_rm_quantity'] = parseFloat(event.target.value); currentRowData['product_rm_weight'] = parseFloat(currentRowData['product_rm_quantity'] * currentRowData['product_rm_std_weight']) };
                    if (clickedColName === 'product_rm_purchase_unit_id') { currentRowData['product_rm_purchase_unit_id'] = parseInt(event.target.value); currentRowData['material_discount_amount'] = 0; currentRowData['material_discount_percent'] = 0; };
                    if (clickedColName === 'product_rm_rate') { currentRowData['product_rm_rate'] = event.target.value; currentRowData['material_basic_amount'] = currentRowData['product_rm_quantity'] * parseFloat(currentRowData['product_rm_rate']) }
                    currentRowData[clickedColName] = event.target.value;
                    delete focusedInputField.parentElement.dataset.tip;
                    fnCalculateAmount(currentRowData);
                } else if (clickedColName.includes('product_material_po_quantity_qunital')) {
                    $('#error_txt_gross_weight').hide();
                    if (txt_net_weight <= '0' || txt_net_weight === "") {
                        $('#error_txt_gross_weight').text("Please enter value...!")
                        $('#error_txt_gross_weight').show();
                        currentRowData[clickedColName] = "0";
                    } else {
                        delete focusedInputField.parentElement.dataset.tip;
                        currentRowData[clickedColName] = event.target.value;
                        const Quintals = event.target.value;
                        const Kilograms = Quintals * 100;
                        currentRowData['product_rm_quantity'] = validateNumberDateInput.current.decimalNumber(Kilograms, 4);
                        delete focusedInputField.parentElement.nextSibling.dataset.tip;
                    }
                } else if (clickedColName.includes('product_rm_hsn_sac_code_id')) {
                    let hsnCodeValue = event.value;
                    currentRowData['hsn_sac_code'] = event.product_rm_hsn_sac_code;
                    let product_rm_hsn_sac_rate = event.product_rm_hsn_sac_rate;
                    let product_rm_hsn_sac_code = event.product_rm_hsn_sac_code;
                    document.getElementById(`product_rm_hsn_sac_code_id_${currentRowData.product_rm_id}`).removeAttribute('data-tip');

                    if (hsnCodeValue === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('HSN Code'); // CommonParamterEntry
                        setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                    }
                    if (hsnCodeValue !== '') {
                        currentRowData[clickedColName] = hsnCodeValue;
                        currentRowData['product_rm_hsn_sac_rate'] = product_rm_hsn_sac_rate;
                        currentRowData['product_rm_hsn_sac_code'] = product_rm_hsn_sac_code;
                        // delete focusedInputField.parentElement.dataset.tip;
                        document.getElementById(`product_rm_hsn_sac_code_id_${currentRowData.product_rm_id}`).removeAttribute('data-tip');

                    } else {
                        currentRowData[clickedColName] = '';
                        currentRowData['product_rm_hsn_sac_rate'] = '';
                        focusedInputField.parentElement.dataset.tip = 'Please Select Atleast One...!';
                    }
                } else if (clickedColName.includes('cost_center')) {

                    let costCenterValue = event.value;
                    document.getElementById(`cost_center_id_${currentRowData.product_rm_id}`).removeAttribute('data-tip');

                    if (costCenterValue !== '') {
                        currentRowData[clickedColName] = costCenterValue;
                        document.getElementById(`cost_center_id_${currentRowData.product_rm_id}`).removeAttribute('data-tip');
                    } else {
                        currentRowData[clickedColName] = '';
                        currentRowData['cost_center_id'] = '';
                        focusedInputField.parentElement.dataset.tip = 'Please Select Atleast One...!';
                    }
                }
                currentRowData = handleMaterialWiseCalculations(currentRowData);
            }
        }
        const tblRows = [...po_details_data]
        tblRows[arrayIndex] = currentRowData

        //It will Update the Purchase Order Details table
        const handleUpdate = () => { actionPOdata({ type: 'updateRow', index: arrayIndex, updatedPOData: currentRowData }); };
        handleUpdate();

        FnCalculateDiscSum();
        calculateGrandTotal();

    }

    const fnCalculateAmount = async (material) => {
        debugger
        let conversionOptions = [...conversionFactorOptions];
        let conversion_factor = conversionOptions.find(
            (_) => _.product_from_unit_id === 1 && _.product_to_unit_id === parseInt(material['product_rm_purchase_unit_id'])
        );

        material['product_rm_conversion_factor'] = conversion_factor ? conversion_factor['convsersion_factor'] : 1;

        material['material_basic_amount'] =
            parseFloat(material['product_rm_weight'] || 0) *
            parseFloat(material['product_rm_conversion_factor'] || 1) *
            parseFloat(material['product_rm_rate'] || 0);


        return material;
    }


    // Material Product Based Properties Starts
    const fnGetProductProperties = async (MaterialIds) => {
        try {
            // debugger
            setDistinctMaterailIds(MaterialIds);
            resetGlobalQuery();
            globalQuery.table = "sm_product_dynamic_parameters"
            globalQuery.columns = ['product_id', 'product_parameter_name',
                'product_parameter_calculation_type', 'product_parameter_from_value', 'product_parameter_to_value', 'remark']
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_id", operator: "IN", values: MaterialIds });

            const productBasedPropertiesAPICall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);

            productBasedPropertiesAPICall.map((properties) => {
                const matchingPO = po_details_data.find((po) => po.product_rm_id === properties.product_id);
                if (matchingPO) {
                    properties['product_material_name'] = matchingPO.product_rm_name;
                }
            });
            if (!productBasedPropertiesAPICall?.length) {
                setProductTypePropertiesData([]);
                return;
            }
            setProductTypePropertiesData(productBasedPropertiesAPICall);
            return productBasedPropertiesAPICall;
        } catch (error) {
            console.error("Error fetching product properties:", error);
            setProductTypePropertiesData([]);
        } finally {
        }
    };



    //Fn to render propertie details of material  table
    const renderProductPropertiesTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='productProperties-table-id' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className='erp_table_th'>Material Name</th>
                        <th className="erp_table_th">Parameter Name</th>
                        <th className="erp_table_th">Calculation Type</th>
                        <th className="erp_table_th">From Value</th>
                        <th className="erp_table_th">To Value</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {productTypePropertiesData.map((filter, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td ">{index + 1}</td>
                            <td className="erp_table_td ">{filter.product_material_name}</td>
                            <td className="erp_table_td ">{filter.product_parameter_name}</td>
                            <td className="erp_table_td ">{filter.product_parameter_calculation_type}</td>
                            <td className="erp_table_td "> {filter.product_parameter_from_value}</td>
                            <td className="erp_table_td  "> {filter.product_parameter_to_value} </td>
                            <td className="erp_table_td  ">{filter.remark}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [productTypePropertiesData]);


    //Taxation Summary

    // Fn render taxation table columns
    const renderPOTaxationSummaryTable = useMemo(() => {
        debugger
        return <>
            <Table className="erp_table pt-2" id='po-taxationSummary-table' bordered striped >
                <thead className="erp_table_head">
                    <tr>
                        {/* text-center */}
                        <th className="erp_table_th ">Sr. No</th>
                        <th className="erp_table_th" style={{ paddingRight: '50px' }}>HSN SAC Code</th>
                        <th className="erp_table_th">Taxable Amount</th>
                        <th className="erp_table_th">CGST Percent</th>
                        <th className="erp_table_th">CGST Total</th>
                        <th className="erp_table_th">SGST Percent</th>
                        <th className="erp_table_th">SGST Total</th>
                        <th className="erp_table_th">IGST Percent</th>
                        <th className="erp_table_th">IGST Total</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(taxationSummaryData) && taxationSummaryData.map((taxItem, index) => (
                        <tr key={index}>
                            <td className="erp_table_td text-end">{index + 1}</td>
                            <td className="erp_table_td">{taxItem.hsn_sac_code}</td>
                            <td className="erp_table_td text-end">{taxItem.material_taxable_amount}</td>
                            <td className="erp_table_td text-end">{taxItem.material_cgst_percent}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current?.decimalNumber(taxItem.material_cgst_total, 4)}</td>
                            <td className="erp_table_td text-end">{taxItem.material_sgst_percent}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current?.decimalNumber(taxItem.material_sgst_total, 4)}</td>
                            <td className="erp_table_td text-end">{taxItem.material_igst_percent}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current?.decimalNumber(taxItem.material_igst_total, 4)}</td>
                        </tr>
                    ))}
                </tbody>

            </Table>
        </>
    }, [taxationSummaryData]);


    useEffect(() => {
        if (footerDiscountChange.current === true) {
            calculateGrandTotal();
            footerDiscountChange.current = false;
        }
    }, [po_details_data]);

    useEffect(() => {
        reverseCalculationForDetailData();
    }, [txt_po_discount_amount, txt_po_discount_percent])

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForDetailData = () => {
        debugger
        // if (footerDiscountChange.current === true) {
        footerDiscountChange.current = true;
        let poDetailData = [...po_details_data];
        const discount_amount = parseFloat(txt_po_discount_amount)
        if (poDetailData.length !== 0) {
            let updatedData = poDetailData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                let discountAmount;
                let calculatedPercent;
                if (discountAmount !== 0) {
                    discountAmount = (obj.material_basic_amount / txt_basic_total) * discount_amount;
                    calculatedPercent = (discountAmount * 100) / obj.material_basic_amount;
                }
                // Create a new object with the updated properties
                return {
                    ...obj,
                    material_discount_amount: discountAmount === 0 ? 0 : validateNumberDateInput.current?.decimalNumber(JSON.stringify(discountAmount), 4),
                    material_discount_percent: calculatedPercent === 0 ? 0 : validateNumberDateInput.current?.decimalNumber(JSON.stringify(calculatedPercent), 2)
                };
            });
            updatedData = updatedData.map(handleMaterialWiseCalculations);

            FnUpdateTotalPODetailsArr(updatedData);
            // footerDiscountChange.current = false;
        }
        // }

    };

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForFrieghtAmt = () => {
        let poDetailData = [...po_details_data];
        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        if (poDetailData.length !== 0) {
            const updatedData = poDetailData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
                return {
                    ...obj,
                    material_freight_amount: validateNumberDateInput.current?.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                };
            });
            FnUpdateTotalPODetailsArr(updatedData)
            // sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedData));
        }
    }
    //fn for calculate details data onchange
    const handleMaterialWiseCalculations = (currentRowData) => {
        // debugger
        let suppl_stateval = parseInt($('#cmb_supplier_state_id').val());
        let supplierStateCode = parseInt($('#cmb_supplier_state_id option:selected').attr('stateCode'));
        let branchStateCode = parseInt($('#cmb_expected_branch_state_id option:selected').attr('stateCode'));
        if (!isNaN(suppl_stateval)) {

            let quantity = 0;
            if ((keyForViewUpdate === 'approve') || (keyForViewUpdate === 'view' && chk_po_status !== 'P')) {
                quantity = isNaN(parseFloat(currentRowData.product_rm_approved_quantity)) === NaN ? 0 : parseFloat(currentRowData.product_rm_approved_quantity);
            } else {
                quantity = isNaN(parseFloat(currentRowData.product_rm_quantity)) === NaN ? 0 : parseFloat(currentRowData.product_rm_quantity);
            }

            let material_rate = isNaN(parseFloat(currentRowData.product_rm_rate)) === NaN ? 0 : parseFloat(currentRowData.product_rm_rate);
            // let quantity = isNaN(parseInt(currentRowData.product_rm_quantity)) === NaN ? 0 : parseInt(currentRowData.product_rm_quantity);

            // const calculatedPercent = isNaN(currentRowData['material_discount_amount'] * 100 / currentRowData.material_basic_amount) ? 0 : parseFloat(currentRowData['material_discount_amount'] * 100) / currentRowData.material_basic_amount;
            // currentRowData.material_discount_amount = parseFloat(currentRowData['material_discount_amount']); // Then set the discount amount and discount percentage.
            // currentRowData.material_discount_percent = validateNumberDateInput.current?.decimalNumber(calculatedPercent, 2);

            let material_discount_amount = isNaN(parseFloat(currentRowData.material_discount_amount)) === NaN ? 0 : parseFloat(currentRowData.material_discount_amount);
            let hsn_code_rate = isNaN(parseFloat(currentRowData.product_rm_hsn_sac_rate)) === NaN ? 0 : parseFloat(currentRowData.product_rm_hsn_sac_rate);
            let std_weight = isNaN(parseFloat(currentRowData.product_rm_std_weight)) === NaN ? 0 : parseFloat(currentRowData.product_rm_std_weight);


            const optionselected = $('#cmb_expected_branch_id').find('option:selected');
            const is_sez = optionselected.attr('chk_issez');
            // console.log('rate: ' + material_rate, 'qnty: ' + quantity, 'disc: ' + discPercent, 'hsn_code_rate: ' + hsn_code_rate)

            let total_amount = 0;
            let cgst_sgst_per = 0;
            let cgst_amt = 0;
            let sgst_amt = 0;
            let igst_per = 0;
            let igst_amt = 0;

            const total_weight = currentRowData['product_rm_weight'];
            const material_basic_amount = currentRowData['material_basic_amount'];
            // const material_discount_amount = (material_basic_amount * discPercent) / 100;
            // const material_discount_amount = discAmt === 0 ? (material_basic_amount * discPercent) / 100 : discAmt;
            // const material_discount_percent = discPercent === 0 ? (discAmt / material_basic_amount) * 100 : discPercent;
            const material_taxable_amount = material_basic_amount - material_discount_amount;
            total_amount = material_taxable_amount;
            if (!isNaN(hsn_code_rate)) {//&& hsn_code_rate !== ""
                if (supplierStateCode === '99') {
                    cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
                    total_amount = material_taxable_amount;
                } else if (is_sez === 'true') {
                    cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
                    total_amount = material_taxable_amount;

                } else if (supplierStateCode === branchStateCode) { // for maharashtra means same state cal SGST,CGST.
                    if (!isNaN(material_rate) && material_rate !== "" && material_rate !== 0) {
                        cgst_sgst_per = hsn_code_rate / 2; //for gst per% cal
                        const gst_amt = (material_taxable_amount * hsn_code_rate) / 100; //for gst amount cal
                        cgst_amt = gst_amt / 2;
                        sgst_amt = gst_amt / 2;
                        total_amount = material_taxable_amount + gst_amt;
                    }

                } else {//for cal IGST for other states.
                    if (!isNaN(material_rate) && material_rate !== "" && material_rate !== 0) {
                        igst_per = hsn_code_rate;
                        igst_amt = (material_taxable_amount * hsn_code_rate) / 100;
                        total_amount = material_taxable_amount + igst_amt;
                    }
                }

                console.log('material_basic_amount: ' + material_basic_amount, 'material_discount_amount: ' + material_discount_amount, 'material_taxable_amount: ' + material_taxable_amount,);
                console.log('cgst_sgst_per: ' + cgst_sgst_per, 'cgst_amt: ' + cgst_amt, 'sgst_amt: ' + sgst_amt, 'igst_per: ' + igst_per, 'igst_amt: ' + igst_amt, 'total_amount: ' + total_amount,)

                currentRowData['material_basic_amount'] = validateNumberDateInput.current?.decimalNumber(material_basic_amount, 4);
                currentRowData['material_discount_amount'] = validateNumberDateInput.current?.decimalNumber(material_discount_amount, 4);
                currentRowData['material_taxable_amount'] = validateNumberDateInput.current?.decimalNumber(material_taxable_amount, 4);
                currentRowData['material_cgst_percent'] = validateNumberDateInput.current?.decimalNumber(cgst_sgst_per, 2);
                currentRowData['material_cgst_total'] = validateNumberDateInput.current?.decimalNumber(cgst_amt, 4);
                currentRowData['material_sgst_percent'] = validateNumberDateInput.current?.decimalNumber(cgst_sgst_per, 2);
                currentRowData['material_sgst_total'] = validateNumberDateInput.current?.decimalNumber(sgst_amt, 4);
                currentRowData['material_igst_percent'] = validateNumberDateInput.current?.decimalNumber(igst_per, 2);
                currentRowData['material_igst_total'] = validateNumberDateInput.current?.decimalNumber(igst_amt, 4);
                currentRowData['material_total_amount'] = validateNumberDateInput.current?.decimalNumber(total_amount, 4);
                currentRowData['product_rm_weight'] = validateNumberDateInput.current?.decimalNumber(total_weight, 4);
                // currentRowData['material_discount_percent'] = validateNumberDateInput.current.decimalNumber(material_discount_percent, 2);
            } else {
                currentRowData['material_basic_amount'] = validateNumberDateInput.current?.decimalNumber(material_basic_amount, 4);
                currentRowData['material_discount_amount'] = validateNumberDateInput.current?.decimalNumber(material_discount_amount, 4);
                currentRowData['material_taxable_amount'] = validateNumberDateInput.current?.decimalNumber(material_taxable_amount, 4);
                currentRowData['material_cgst_percent'] = validateNumberDateInput.current?.decimalNumber(cgst_sgst_per, 2);
                currentRowData['material_cgst_total'] = validateNumberDateInput.current?.decimalNumber(cgst_amt, 4);
                currentRowData['material_sgst_percent'] = validateNumberDateInput.current?.decimalNumber(cgst_sgst_per, 2);
                currentRowData['material_sgst_total'] = validateNumberDateInput.current?.decimalNumber(sgst_amt, 4);
                currentRowData['material_igst_percent'] = validateNumberDateInput.current?.decimalNumber(igst_per, 2);
                currentRowData['material_igst_total'] = validateNumberDateInput.current?.decimalNumber(igst_amt, 4);
                currentRowData['material_total_amount'] = validateNumberDateInput.current?.decimalNumber(total_amount, 4);
                currentRowData['product_rm_weight'] = validateNumberDateInput.current?.decimalNumber(total_weight, 4);
            }

        } else {
            $('#error_cmb_supplier_state_id').text('Please select atleast one...!');
            $('#error_cmb_supplier_state_id').show();
            $('#cmb_supplier_state_id').focus();
        }
        // FnGetTaxationSummary();
        currentRowData['material_schedule_date'] = currentRowData.material_schedule_date;
        return currentRowData;
    };
    //fn for calculations on change of supplier and company state
    const updateTblCalsForSupplStateChange = () => {
        setIsLoading(true);
        const detailsTblData = po_details_data;
        if (detailsTblData !== null) {
            let updatedTblData = [];
            for (let rowIndex = 0; rowIndex < detailsTblData.length; rowIndex++) {
                let updatedRowData = handleMaterialWiseCalculations(detailsTblData[rowIndex]);
                updatedTblData.push(updatedRowData);
            }
            FnUpdateTotalPODetailsArr(updatedTblData);
            calculateGrandTotal();
            //  FnGetTaxationSummary();
        }
        setIsLoading(false);
    }
    //fn for validate text input 
    const validateText = (event) => {
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '') {
            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter here...!';
            return false;
        }
        else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }

    const validatePoDetails = (event, inputType) => {
        const floatNumReg = /^\d{1,18}(?:\.\d{0,4})?$/;
        let isValidInput = false;
        let errorMsg = "";
        switch (inputType) {
            case "TextInput":
                if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
                    errorMsg = 'Please fill this field...!'
                    isValidInput = false
                } else {
                    isValidInput = true
                }
                break;
            case "FloatInput":
                if (event.target.value === '' || event.target.value === undefined) {
                    errorMsg = 'Please fill this field...!'
                    isValidInput = false
                } else if (!floatNumReg.test(event.target.value)) {
                    errorMsg = 'Please enter only numeric input...!...!'
                    isValidInput = false
                } else {
                    isValidInput = true
                }
                break;
            case "PercentageInput":
                if (event.target.value === '' || event.target.value === undefined) {
                    errorMsg = 'Please fill this field...!'
                    isValidInput = false
                } else if (event.target.value > 100) {
                    errorMsg = 'Percentage should not be greater than 100%...!'
                    isValidInput = false
                } else {
                    isValidInput = true
                }
                break;
            case "DateInput":
                if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
                    errorMsg = 'Please select the date...!'
                    isValidInput = false
                } else {
                    isValidInput = true
                }
                break;
            default:
                isValidInput = true;
                break;
        }
        let focusedInputField = document.querySelector('#' + event.target.id);
        if (!isValidInput) {
            focusedInputField.parentElement.dataset.tip = errorMsg;
            return false;
        } else {
            delete focusedInputField.parentElement.dataset.tip;
            return true;
        }
    }

    const validateNumericInputs = (event) => {
        // const floatNumReg = /^\d{1,18}(?:\.\d{0,4})?$/; || !floatNumReg.test(event.target.value)
        let tblInputField = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === undefined) {
            $("#error_" + event.target.id).show();
            $("#" + event.target.id).focus();
            $("#error_" + event.target.id).text("Please enter valid input...!");
            return false;
        } else {
            delete tblInputField.parentElement.dataset.tip;
            $("#error_" + event.target.id).hide();
            $("#error_" + event.target.id).text("");
            return true;
        }
    }


    //------------------------------------------- Taxation section End-----------------------------------------------------------------
    //------------------------------------------- PO Terms section Start-----------------------------------------------------------
    const FnShowPOTermsRecords = async () => {
        try {
            const MSTName = encodeURIComponent(commonTermMstName);
            // const poTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}/${COMPANY_ID}`)
            const poTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}`)

            const responce = await poTermsApiCall.json();
            if (responce.content.length !== 0) {
                const updatedContent = responce.content.map((commonTerm) => ({
                    ...commonTerm, common_parameters_expected_value: commonTerm.common_parameters_value,
                }))
                    .sort((a, b) => a.common_parameters_id - b.common_parameters_id);
                responce.content = updatedContent;

                const existingCommonData = existingCommonTermsData;
                let renderedCommonTermsData = responce.content;
                for (let existCommonCounter = 0; existCommonCounter < existingCommonData.length; existCommonCounter++) {
                    const existingCommonTermId = existingCommonData[existCommonCounter].purchase_order_terms_parameters_id;
                    const renderedCommonTerm = renderedCommonTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.common_parameters_id === existingCommonTermId);
                    if (renderedCommonTerm) {
                        const existingIndex = renderedCommonTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedCommonTermsData.splice(existingIndex, 1)[0];
                        renderedCommonTermsData.unshift(movedItem);
                        renderedCommonTermsData[0].common_parameters_expected_value = existingCommonData[existCommonCounter].purchase_order_terms_parameters_expected_value;
                    }
                }
                localStorage.setItem('purchaseOrdCommonTermsData', JSON.stringify(renderedCommonTermsData));
                setPOTermsData(renderedCommonTermsData);
                setTimeout(() => {
                    existingCommonData.forEach(function (existingCommonTerm) {  // mark the checked checkboxes for order common term.
                        $('#selectPOTerm_' + existingCommonTerm.purchase_order_terms_parameters_id).prop('checked', true);
                    });

                    if (keyForViewUpdate === "approve") {
                        $('table .approveField').removeAttr("disabled");
                    }

                }, 200);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    //table for common terms
    const renderPOTermsTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='po-commonTerms-table' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Action</th>
                        <th className="erp_table_th">Technical Parameters Master </th>
                        <th className="erp_table_th">Technical Parameters </th>
                        <th className="erp_table_th">Technical Parameters Value</th>
                        <th className="erp_table_th">Technical Prameters Expected Value</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        POTermsdata.map((poTerms, Index) =>
                            <tr rowIndex={Index}>

                                <td className="erp_table_td">
                                    <input type="checkbox" className="selectPOTerm approveField" name="selectPOTerm" value={poTerms.common_parameters_id}
                                        disabled={isView == false ? false : true} id={'selectPOTerm_' + poTerms.common_parameters_id} />
                                </td>
                                <td className="erp_table_td">{poTerms.common_parameters_master_name}</td>
                                <td className="erp_table_td">{poTerms.common_parameters_name}</td>
                                <td className="erp_table_td">{poTerms.common_parameters_value}</td>
                                <td className="erp_table_td">
                                    <input type="text" id={`po-terms-expected-Value-${poTerms.common_parameters_id}`}
                                        className="erp_input_field mb-0 approveField"
                                        value={poTerms.common_parameters_expected_value}
                                        onChange={(e) => { updatePOOrdCommonTermsTblData(poTerms, e); }}
                                        disabled={keyForViewUpdate === '' ? false : keyForViewUpdate === 'update' ? false : true}
                                        Headers='common_parameters_expected_value'
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [POTermsdata, isView]);
    // Onchange event listener for the POTerm Selection
    $('.selectPOTerm').on('change', function () {
        toggleChkAllBoxes('PartiallyPOTermsSelection');
    });
    //Fn For update po_term editable value
    const updatePOOrdCommonTermsTblData = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        const exptvalueId = document.getElementById(event.target.id);
        if (event.target.value === '') {
            exptvalueId.parentElement.dataset.tip = 'Please fill this field...!';
        } else {
            delete exptvalueId.parentElement.dataset.tip;
        }
        currentRowData[clickedColName] = event.target.value;            // Get the column name where event triggered.
        const poTerms_data = [...POTermsdata]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        poTerms_data[arrayIndex] = currentRowData
        setPOTermsData(poTerms_data);
    }
    //------------------------------------------- PO Terms section End -------------------------------------------------------------------

    //function to check All checkBoxes of po terms and payment terms
    function toggleChkAllBoxes(key) {

        switch (key) {

            case "selectAllPOTerms":
                $('.selectPOTerm').prop('checked', $('#selectAllPOTerms').is(":checked"));
                break;
            case 'PartiallyPOTermsSelection':
                $('#selectAllPOTerms').prop('checked', $('input:checkbox.selectPOTerm:checked').length == $('input:checkbox.selectPOTerm').length);
                break;
            case "selectAllPayTerms":
                $('.selectPayTerm').prop('checked', $('#selectAllPayTerms').is(":checked"));
                break;
            case 'PartiallyPayTermsSelection':
                $('#selectAllPayTerms').prop('checked', $('input:checkbox.selectPayTerm:checked').length == $('input:checkbox.selectPayTerm').length);
                break;
            // For customer contact
            case 'selectAllSuppContact':
                $('.selectSuppContactPerson').prop('checked', $('#selectAllSuppContact').is(":checked"));
                break;
            case 'PartiallySuppContactSelection':
                $('#selectAllSuppContact').prop('checked', $('input:checkbox.selectSuppContactPerson:checked').length === $('input:checkbox.selectSuppContactPerson').length);
                break;
        }
    }


    //---------------------------------- Payment Terms section Start -----------------------------------------------------------------------
    const FnShowPaymentTermsRecrds = async () => {
        try {

            // const pyTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/paymentterms/FnShowAllActiveRecords`)
            resetGlobalQuery();
            globalQuery.columns = ['*']
            globalQuery.table = "cmv_payment_terms";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["payment_terms_id"];
            const pyTermsApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            // const responce = await pyTermsApiCall;
            if (pyTermsApiCall.length !== 0 && pyTermsApiCall.length > 0) {

                const updatedContent = pyTermsApiCall.sort((a, b) => a.payment_terms_id - b.payment_terms_id);
                let existingPayTermsData = existingPaymentTermsData;
                // if (existingPayTermsData.length > 0) {
                let renderedPaymentTermsData = updatedContent;
                for (let existPaymentCounter = 0; existPaymentCounter < existingPayTermsData.length; existPaymentCounter++) {
                    const existingPaymentTermId = existingPayTermsData[existPaymentCounter].payment_terms_id;
                    const renderedPaymentTerm = renderedPaymentTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.payment_terms_id === existingPaymentTermId);
                    if (renderedPaymentTerm) {
                        const existingIndex = renderedPaymentTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedPaymentTermsData.splice(existingIndex, 1)[0];
                        renderedPaymentTermsData.unshift(movedItem);
                        renderedPaymentTermsData[0].payment_terms_Milestome = existingPayTermsData[existPaymentCounter].payment_terms_Milestome;
                        renderedPaymentTermsData[0].payment_percent = existingPayTermsData[existPaymentCounter].payment_percent;
                        renderedPaymentTermsData[0].payment_expected_value = existingPayTermsData[existPaymentCounter].payment_expected_value;
                        renderedPaymentTermsData[0].payment_expected_date = existingPayTermsData[existPaymentCounter].payment_expected_date;
                        renderedPaymentTermsData[0].payment_paid_transaction_id = existingPayTermsData[existPaymentCounter].payment_paid_transaction_id;
                        renderedPaymentTermsData[0].payment_paid_flag = existingPayTermsData[existPaymentCounter].payment_paid_flag;
                        renderedPaymentTermsData[0].payment_paid_date = existingPayTermsData[existPaymentCounter].payment_paid_date;
                    }
                    // Update Data if order is copied then set default data.
                    if (keyForViewUpdate === 'copy') {
                        renderedPaymentTermsData[0].payment_paid_transaction_id = '';
                        renderedPaymentTermsData[0].payment_paid_flag = 'P';
                        renderedPaymentTermsData[0].payment_paid_date = '';
                        renderedPaymentTermsData[0].payment_expected_date = defaultExpecScheduleDate
                    }
                }
                // localStorage.setItem('purchaseOrdPaymentTermsData', JSON.stringify(renderedPaymentTermsData));
                setPaymentTermsData(renderedPaymentTermsData);
                $('#selectPayTerm_1').prop('checked', true);
                // }
            } else {
                setPaymentTermsData([]);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
        // Onchange event listener for the POTerm Selection
        $('.selectPayTerm').on('change', function (e) {

            // if it is checked then focus the.
            let paymentTermId = $(this).attr("pmtTermId");
            if (this.checked) {
                if ($(`#payment_milestone_${paymentTermId}`).val() === '') {
                    $(`#payment_milestone_${paymentTermId}`).focus();

                } else if ($(`#payment_percent_${paymentTermId}`).val() === '') {
                    $(`#payment_percent_${paymentTermId}`).focus();

                } else if ($(`#payment_expected_date_${paymentTermId}`).val() === '') {
                    $(`#payment_expected_date_${paymentTermId}`).focus();

                }
                else if ($(`#payment_expect_val_${paymentTermId}`).val() === '') {
                    $(`#payment_expect_val_${paymentTermId}`).focus();
                }
            } else {
                let mileStoneField = document.querySelector('#payment_milestone_' + paymentTermId);
                let percentageField = document.querySelector('#payment_percent_' + paymentTermId);
                let dateField = document.querySelector('#payment_expected_date_' + paymentTermId);
                let paymentExptValField = document.querySelector('#payment_expect_val_' + paymentTermId);

                delete mileStoneField.parentElement.dataset.tip;
                delete percentageField.parentElement.dataset.tip;
                delete dateField.parentElement.dataset.tip;
                delete paymentExptValField.parentElement.dataset.tip;
            }
            toggleChkAllBoxes('PartiallyPayTermsSelection');
        });

    }
    //Fn for render payment terms static table 
    const renderPaymentTermsTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='po-paymentTerms-table' bordered striped >
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Action</th>
                        <th className="erp_table_th">Term Names</th>
                        <th className="erp_table_th">Days </th>
                        <th className="erp_table_th">Grace Days</th>
                        <th className="erp_table_th">Milestome</th>
                        <th className="erp_table_th">Payment Percent</th>
                        <th className="erp_table_th">Expected Value</th>
                        <th className="erp_table_th">Expected Date</th>
                        <th className="erp_table_th">Payment Paid</th>
                        <th className="erp_table_th">Payment Paid Transaction Id</th>
                        <th className="erp_table_th">Payment Paid Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        paymentTermsData.map((payTermItem, Index) =>
                            <tr payTermItemIndex={Index}>

                                <td className="erp_table_td">
                                    <input type="checkbox" name="selectPayTerm" className="selectPayTerm" pmtTermId={payTermItem.payment_terms_id}
                                        disabled={isView == false ? false : true} value={payTermItem.payment_terms_id} id={'selectPayTerm_' + payTermItem.payment_terms_id} />
                                </td>
                                <td className="erp_table_td">{payTermItem.payment_terms_name}</td>
                                <td className="erp_table_td text-end">{payTermItem.payment_terms_days}</td>
                                <td className="erp_table_td text-end">{payTermItem.payment_terms_grace_days}</td>
                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field mb-0" value={payTermItem.payment_terms_Milestome} pmtTermId={payTermItem.payment_terms_id} id={"payment_milestone_" + payTermItem.payment_terms_id} maxlength='255' onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        onBlur={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        Headers='payment_terms_Milestome' disabled={keyForViewUpdate === '' || keyForViewUpdate === "update" ? false : true} />
                                </td>
                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field mb-0 text-end" value={payTermItem.payment_percent} pmtTermId={payTermItem.payment_terms_id} id={"payment_percent_" + payTermItem.payment_terms_id} onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        onBlur={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        Headers='payment_percent' disabled={keyForViewUpdate === '' || keyForViewUpdate === "update" ? false : true} />
                                </td>
                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field mb-0 text-end" value={payTermItem.payment_expected_value} pmtTermId={payTermItem.payment_terms_id} id={"payment_expect_val_" + payTermItem.payment_terms_id} onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        Headers='payment_expected_value' disabled={keyForViewUpdate === '' || keyForViewUpdate === "update" ? false : true} />
                                </td>
                                <td className="erp_table_td">
                                    <input type="date" className="erp_input_field mb-0" value={payTermItem.payment_expected_date} pmtTermId={payTermItem.payment_terms_id} id={"payment_expected_date_" + payTermItem.payment_terms_id} onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        onBlur={(e) => { updatePmtTermsTblData(payTermItem, e); }} min={currentDate}
                                        Headers='payment_expected_date' disabled={keyForViewUpdate === '' || keyForViewUpdate === "update" ? false : true} />
                                </td>
                                <td className="erp_table_td ">
                                    <select id={`payment_paid_flag_${payTermItem.payment_terms_id}`} value={payTermItem.payment_paid_flag} className="form-select form-select-sm "
                                        Headers='payment_paid_flag' disabled onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }}  >
                                        <option value="P">Pending</option>
                                        <option value="T">Partail</option>
                                        <option value="R">Receipt</option>
                                    </select>
                                </td>
                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field mb-0 text-end" value={payTermItem.payment_paid_transaction_id} pmtTermId={payTermItem.payment_terms_id} id={"payment_paid_transaction_id_" + payTermItem.payment_terms_id}
                                        Headers='payment_paid_transaction_id' disabled />
                                </td>
                                <td className="erp_table_td">
                                    <input type="date" className="erp_input_field mb-0" value={payTermItem.payment_paid_date} pmtTermId={payTermItem.payment_terms_id} id={"payment_paid_date_" + payTermItem.payment_terms_id}
                                        Headers='payment_paid_date' disabled />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [paymentTermsData, isView]);
    const updatePmtTermsTblData = (currentRowData, event) => {

        const ischecked = event.target.parentElement.parentElement.firstChild.children.selectPayTerm.checked;
        if (ischecked) {
            let clickedColName = event.target.getAttribute('Headers');// Get the column name where event triggered.
            let focusedInputField = document.getElementById(event.target.id);
            if (event.target.value !== '') {
                if (!clickedColName.includes('_percent')) {
                    currentRowData[clickedColName] = event.target.value;
                    // delete focusedInputField.parentElement.dataset.tip;
                } else if (clickedColName.includes('_percent')) {
                    if (validateNumberDateInput.current.percentValidate(event.target.value)) {
                        currentRowData[clickedColName] = event.target.value;
                        // delete focusedInputField.parentElement.dataset.tip;
                    }
                } else {
                    currentRowData[clickedColName] = validateNumberDateInput.current?.decimalNumber(event.target.value, 4);
                    // delete focusedInputField.parentElement.dataset.tip;
                }

            } else {
                // if (!clickedColName.includes('payment_expected_value')) {
                //     focusedInputField.parentElement.dataset.tip = 'Please fill this field...!';
                // }
                currentRowData[clickedColName] = '';
            }
            const payTerms_data = [...paymentTermsData]
            const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            payTerms_data[arrayIndex] = currentRowData
            setPaymentTermsData(payTerms_data);
        }
    }
    //fn to validate payment terms inputs
    const validatePmtTerms = (event, inputType) => {
        let pmtTermId = event.target.getAttribute('pmttermid');
        if ($(`#selectPayTerm_${pmtTermId}`).is(":checked")) {

            const floatNumReg = /^\d{1,18}(?:\.\d{0,4})?$/;
            let isValidInput = false;
            let errorMsg = "";
            switch (inputType) {
                case "TextInput":
                    if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
                        errorMsg = 'Please fill this field...!'
                        isValidInput = false
                    } else {
                        isValidInput = true
                    }
                    break;
                case "PercentageInput":
                    if (event.target.value === '' || event.target.value === undefined) {
                        errorMsg = 'Please fill this field...!'
                        isValidInput = false
                    } else if (event.target.value > '100') {
                        errorMsg = 'Percentage should not be greater than 100%...!'
                        isValidInput = false
                    } else {
                        isValidInput = true
                    }
                    break;
                case "DateInput":
                    if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
                        errorMsg = 'Please select the date...!'
                        isValidInput = false
                    } else {
                        isValidInput = true
                    }
                    break;
                default:
                    isValidInput = true;
                    break;
            }

            let focusedInputField = document.querySelector('#' + event.target.id);
            if (!isValidInput) {
                focusedInputField.parentElement.dataset.tip = errorMsg;
                return false;
            } else {
                delete focusedInputField.parentElement.dataset.tip;
                return true;
            }
        } else {

            let mileStoneField = document.querySelector('#payment_milestone_' + pmtTermId);
            let percentageField = document.querySelector('#payment_percent_' + pmtTermId);
            let dateField = document.querySelector('#payment_expected_date_' + pmtTermId);


            delete mileStoneField.parentElement.dataset.tip;
            delete percentageField.parentElement.dataset.tip;
            delete dateField.parentElement.dataset.tip;

            return true;
        }
    }
    //----------------------------------------  Payment Terms Section End ----------------------------------------------------------

    const handleFooterDiscountPercentChange = async (e) => {
        let percent = parseFloat(e.target.value) || 0;

        // Set amount to zero if it's negative or less than zero
        if (percent < 0) {
            percent = 0;
        }
        const amount = (percent / 100) * txt_basic_total;
        setPODiscountAmount(validateNumberDateInput.current?.decimalNumber(JSON.stringify(amount), 4));
        setPODiscountPercent(percent);

        // setTimeout(() => {
        reverseCalculationForDetailData();
        // footerDiscountChange.current = false;
        // calculateGrandTotal();
        // }, 200);
    };

    const handleFooterDiscountAmountChange = async (e) => {
        debugger
        let amount = parseFloat(e.target.value) || 0;
        if (amount < 0) {
            amount = 0;
        }
        //const amount = parseFloat(e.target.value) || 0;
        const percent = (amount * 100) / txt_basic_total;
        setPODiscountPercent(validateNumberDateInput.current?.decimalNumber(percent, 2));
        setPODiscountAmount(amount);

        // setTimeout(() => {
        reverseCalculationForDetailData();
        // footerDiscountChange.current = false;
        // calculateGrandTotal();

        // }, 200);

    };


    const handleFooterPackingPercentChange = async (value) => {
        let taxableTotal = parseFloat(document.getElementById("txt_taxableTotal").value);

        let percent = parseFloat(value) || 0;
        if (percent < 0) {
            percent = 0;
        }
        const amount = (percent / 100) * taxableTotal;
        setPackingAmount(validateNumberDateInput.current?.decimalNumber(amount, 4));
        setPOPackingPercent(percent);
        setTimeout(() => {
            calculateGrandTotal();
        }, 200);
    };

    const handleFooterPackingAmountChange = async (value) => {
        let taxableTotal = parseFloat(document.getElementById("txt_taxableTotal").value);

        let amount = parseFloat(value) || 0;
        if (amount < 0) {
            amount = 0;
        }
        // const amount = parseFloat(e.target.value) || 0;
        const percent = (amount * 100) / taxableTotal;
        setPOPackingPercent(validateNumberDateInput.current?.decimalNumber(percent, 2));
        setPackingAmount(amount);

        setTimeout(() => {
            calculateGrandTotal();
        }, 200);

    };


    const handleFooterFreightPercentChange = async (value) => {
        let percent = parseFloat(value) || 0;
        let taxableTotal = parseFloat(document.getElementById("txt_taxableTotal").value);
        // Set amount to zero if it's negative or less than zero
        if (percent < 0) {
            percent = 0;
        }
        const amount = (percent / 100) * taxableTotal;
        setFreightAmount(validateNumberDateInput.current?.decimalNumber(amount, 4));
        setFreightPercent(percent);
        setTimeout(() => {
            calculateGrandTotal();
            reverseCalculationForFrieghtAmt();
        }, 200);
    };

    const handleFooterFreightAmountChange = async (value) => {
        let taxableTotal = parseFloat(document.getElementById("txt_taxableTotal").value);
        let amount = parseFloat(value) || 0;
        if (amount < 0) {
            amount = 0;
        }
        //const amount = parseFloat(e.target.value) || 0;
        const percent = (amount * 100) / taxableTotal;
        setFreightPercent(validateNumberDateInput.current?.decimalNumber(percent, 2));
        setFreightAmount(amount);

        setTimeout(() => {
            calculateGrandTotal();
            reverseCalculationForFrieghtAmt();
        }, 200);

    };

    // Fn for sum of discount percent and amount on material chabge
    const FnCalculateDiscSum = () => {
        let poDetailsData = po_details_data;
        let material_basic_totals = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_basic_amount), 0));
        let material_discount_amount = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_discount_amount), 0));
        let material_discount_percent = (material_discount_amount * 100) / material_basic_totals || 0;
        setPODiscountPercent(validateNumberDateInput.current?.decimalNumber(material_discount_percent, 2));
        setPODiscountAmount(validateNumberDateInput.current?.decimalNumber(material_discount_amount, 4));
    };

    const summationOfDistinctCodes = (distinctHSNCodes, data) => {
        debugger
        const taxationTblData = distinctHSNCodes.map(name => {
            // Filter original data for this specific name
            const filteredData = data.filter(po => po.product_rm_hsn_sac_code_id === name);

            // Sum up age & weight for the same name
            const material_taxable_amount = filteredData.reduce((sum, code) => sum + code.material_taxable_amount, 0);
            const material_cgst_percent = filteredData[0].material_cgst_percent;
            const material_cgst_total = filteredData.reduce((sum, code) => sum + code.material_cgst_total, 0);
            const material_sgst_percent = filteredData[0].material_sgst_percent;
            const material_sgst_total = filteredData.reduce((sum, code) => sum + code.material_sgst_total, 0);
            const material_igst_percent = filteredData[0].material_igst_percent;
            const material_igst_total = filteredData.reduce((sum, code) => sum + code.material_igst_total, 0);
            const material_total_amount = filteredData.reduce((sum, code) => sum + code.material_total_amount, 0);

            return {
                hsn_sac_code: filteredData[0]?.hsn_sac_code ?? filteredData[0]?.hsn_sac_description,
                product_rm_hsn_sac_code_id: filteredData[0]?.product_rm_hsn_sac_code_id || "",
                hsn_sac_rate: filteredData[0]?.hsn_sac_rate ?? filteredData[0]?.product_rm_hsn_sac_rate,
                product_material_id: filteredData[0]?.product_material_id ?? filteredData[0]?.product_rm_id ?? "",
                material_taxable_amount,
                material_cgst_percent,
                material_cgst_total,
                material_sgst_percent,
                material_sgst_total,
                material_igst_percent,
                material_igst_total,
                material_total_amount,
            };

        });
        setTaxationSummaryData(taxationTblData);
        return taxationTblData;

    }

    const calculateGrandTotal = () => {

        try {
            debugger

            let taxSummaryData = FnGetTaxationSummary();

            let poDetailsData = po_details_data;
            let supplier_state_id = parseInt($('#cmb_supplier_state_id').val());
            let exp_branch_state_id = parseInt($('#cmb_expected_branch_state_id').val());

            let is_freight_taxable = false;
            let freightTaxHSNIsSelected = '';
            var chk_is_freight_taxable_id = document.getElementById('chk_is_freight_taxable');
            if (typeof chk_is_freight_taxable_id !== 'undefined' && chk_is_freight_taxable_id !== null) {
                is_freight_taxable = chk_is_freight_taxable_id.checked;

                // let is_freight_taxable = document.getElementById('chk_is_freight_taxable').checked;
                freightTaxHSNIsSelected = $('#cmb_freight_hsn_id').val();
            }

            if (is_freight_taxable !== undefined) {
                if (is_freight_taxable && freightTaxHSNIsSelected === '') {
                    $('#error_cmb_freight_hsn_id').text('Please select the freight tax hsn code.');
                    $('#error_cmb_freight_hsn_id').show();
                    return false;
                } else if (!is_freight_taxable) {
                    $('#error_cmb_freight_hsn_id').hide();
                    setFreightHSNId('');
                } else if (is_freight_taxable && freightTaxHSNIsSelected !== '') {
                    $('#error_cmb_freight_hsn_id').hide();
                }
            }
            if (poDetailsData != null) {
                let basic_total = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_basic_amount), 0));
                let cgst_total = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_cgst_total), 0));
                let sgst_total = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_sgst_total), 0));
                let igst_total = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_igst_total), 0));
                let grandTotal = poDetailsData.reduce((total, item) => total + parseFloat(item.material_total_amount), 0);

                const discAmt = isNaN(parseFloat($('#txt_po_discount_amount').val())) === true ? 0 : parseFloat($('#txt_po_discount_amount').val())
                // const txt_transport_amount = isNaN(parseFloat($('#txt_transportAmount').val())) === true ? 0 : parseFloat($('#txt_transportAmount').val())
                const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) === true ? 0 : parseFloat($('#txt_freight_amount').val())
                const packingAmt = isNaN(parseFloat($('#txt_packing_amount').val())) === true ? 0 : parseFloat($('#txt_packing_amount').val())
                const txt_other_amount = isNaN(parseFloat($('#txt_otherAmount').val())) === true ? 0 : parseFloat($('#txt_otherAmount').val())

                const freightoptionselected = $('#cmb_freight_hsn_id').find('option:selected');
                const freight_HsnRate = isNaN(parseFloat(freightoptionselected.attr('hsn_rate'))) === true ? 0 : freightoptionselected.attr('hsn_rate');

                const taxableAmount = basic_total - discAmt;

                const Sezoptionselected = $('#cmb_expected_branch_id').find('option:selected');
                const is_sez = Sezoptionselected.attr('chk_issez');
                if (is_freight_taxable) {
                    let taxSummary = {
                        product_material_id: 'Freight',
                        hsn_sac_code: $('#cmb_freight_hsn_id option:selected').attr('lbl'),
                        product_rm_hsn_sac_code_id: parseInt($('#cmb_freight_hsn_id').val()),
                        hsn_sac_rate: parseFloat($('#cmb_freight_hsn_id option:selected').attr('hsn_rate')),
                        material_taxable_amount: freightAmt, material_cgst_percent: 0, material_cgst_total: 0,
                        material_sgst_percent: 0, material_sgst_total: 0, material_igst_percent: 0, material_igst_total: 0, material_total_amount: 0
                    };
                    let freightTaxVal = (freightAmt * freight_HsnRate) / 100;
                    if (supplier_state_id === '99') {
                        cgst_total += 0;
                        sgst_total += 0;
                        igst_total += 0;
                    } else if (is_sez === 'true') {
                        cgst_total += 0;
                        sgst_total += 0;
                        igst_total += 0;
                    } else if (supplier_state_id === exp_branch_state_id) {

                        // Set inter state tax totals
                        taxSummary['material_cgst_total'] = parseFloat(freightTaxVal / 2);
                        taxSummary['material_cgst_percent'] = parseFloat(freight_HsnRate / 2);
                        taxSummary['material_sgst_total'] = parseFloat(freightTaxVal / 2);
                        taxSummary['material_sgst_percent'] = parseFloat(freight_HsnRate / 2);
                        taxSummary['material_total_amount'] = parseFloat(freightAmt + freightTaxVal);

                        cgst_total += freightTaxVal / 2;
                        sgst_total += freightTaxVal / 2;
                    } else {
                        // Set outer state tax totals
                        taxSummary['material_igst_total'] = parseFloat(freightTaxVal);
                        taxSummary['material_igst_percent'] = parseFloat(freight_HsnRate);
                        taxSummary['material_total_amount'] = parseFloat(freightAmt + freightTaxVal);

                        igst_total += freightTaxVal;
                    }
                    if (taxSummaryData !== null) {
                        const existingFreightTaxationObj = taxSummaryData.findIndex((record) => record.product_material_id === "Freight");
                        if (existingFreightTaxationObj !== -1) {
                            taxSummaryData[existingFreightTaxationObj] = taxSummary;   // Replace the object in-place
                        } else {
                            taxSummaryData.push(taxSummary);                           // Push the new object if not found
                        }
                    }
                }
                // localStorage.setItem('taxationSummary', JSON.stringify(taxSummaryData));
                // grandTotal = taxableAmount + txt_transport_amount + freightAmt + packingAmt + txt_other_amount + cgst_total + sgst_total + igst_total - totalDeductionAmount;
                grandTotal = taxableAmount + freightAmt + packingAmt + txt_other_amount + cgst_total + sgst_total + igst_total;

                setBasic_total(validateNumberDateInput.current?.decimalNumber(basic_total, 4));
                setTaxableTotal(validateNumberDateInput.current?.decimalNumber(taxableAmount, 4));
                setCgstTotal(validateNumberDateInput.current?.decimalNumber(cgst_total, 4));
                setSgstTotal(validateNumberDateInput.current?.decimalNumber(sgst_total, 4));
                setIgstTotal(validateNumberDateInput.current?.decimalNumber(igst_total, 4));
                setGrandTotal(Math.round(grandTotal));
                let roundOffValue = (grandTotal - Math.floor(grandTotal));
                if (roundOffValue >= 0.50) {
                    roundOffValue = (roundOffValue - 1); // Subtract 1 to get negative round-off
                }
                setRoundOff((roundOffValue).toFixed(2));
            } else {
                setBasic_total(0);
                setTaxableTotal(0);
                setCgstTotal(0);
                setSgstTotal(0);
                setIgstTotal(0);
                setGrandTotal(0);
                setRoundOff(0);
            }

            const distinctHSNCodes = [...new Set(taxSummaryData.map(data => data.product_rm_hsn_sac_code_id))];
            summationOfDistinctCodes(distinctHSNCodes, taxSummaryData);

        } catch (error) {

        }
    }


    // Fn to add , update & approve PO 
    const addPO = async (po_status) => {

        debugger
        try {
            let povalidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                povalidate = await FnValidatePoOrder();
            }

            if (povalidate) {
                setIsLoading(true)
                let json = { 'TransHeaderFooterData': {}, 'TransDetailData': [], 'TransScheduleData': [], 'TransPOTermsData': [], 'TransPaymentTermsData': [], 'TransTaxSummaryTermsData': [], 'TransProductParametersData': [], 'commonIds': {} }

                let supplierContactPersonsIds = await FnGetSupplierContactPersonIds();
                let purchase_order_no = txt_PO_no
                if (keyForViewUpdate === "") {
                    purchase_order_no = await GeneratePONo();
                }
                // TransHeaderData
                let po_form_data = {
                    purchase_order_master_transaction_id: isApprove || chk_po_status === 'X' || chk_po_status === 'Z' && keyForViewUpdate === 'view' ? po_master_transaction_id : 0,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    purchase_order_type_id: cmb_product_type_id,
                    purchase_order_type: $('#cmb_product_type_id').find(":selected").text(),
                    product_category2_id: cmb_product_category_id,
                    product_category2_name: $("#product_category2_name option:selected").text(),
                    purchase_order_life: chek_po_life,
                    purchase_process_entry: chk_purchase_process,
                    purchase_order_creation_type: chk_po_creation_type,
                    purchase_order_no: purchase_order_no,
                    purchase_order_date: txt_po_date,
                    purchase_order_version: txt_po_version,
                    gate_pass_no: txt_gate_pass_no,
                    gate_pass_date: dt_gate_pass_date,
                    vehicale_no: txt_vehicale_no,
                    vehicale_type: cmb_vehicale_type,
                    grader_by_id: cmb_grader_id,
                    gross_weight: txt_gross_weight,
                    tare_weight: txt_tare_weight,
                    variation_weight: txt_variation_weight,
                    net_weight: txt_net_weight,
                    supplier_id: cmb_supplier_id,
                    supplier_state_id: cmb_supplier_state_id,
                    supplier_city_id: cmb_supplier_city_id,
                    supplier_contacts_ids: supplierContactPersonsIds,
                    expected_branch_id: cmb_expected_branch_id,
                    expected_branch_state_id: cmb_expected_branch_state_id,
                    expected_branch_city_id: cmb_expected_branch_city_id,
                    approved_by_id: cmb_approved_by_id,
                    approved_date: keyForViewUpdate === 'approve' ? txt_approved_date : "",
                    purchase_order_acceptance_status: chk_PO_acceptance_status,
                    purchase_order_mail_sent_status: chk_po_mail_sent_status,
                    purchase_order_status: po_status === 'R' ? po_status : chk_po_status,
                    status_remark: txt_status_remark,
                    remark: txt_remark,
                    is_active: po_status === 'X' ? false : chk_po_isactive,
                    expected_schedule_date: txt_schedule_date,
                    consignee_id: cmb_Consignee_id,
                    consignee_state_id: cmb_Consignee_state_id,
                    consignee_city_id: cmb_Consignee_city_id,
                    //footer data start
                    basic_total: txt_basic_total,
                    transport_amount: txt_transportAmount,
                    freight_amount: txt_freight_amount,
                    is_freight_taxable: chk_is_freight_taxable,
                    freight_hsn_code_id: cmb_freight_hsn_id,
                    packing_amount: txt_packing_amount,
                    po_discount_percent: txt_po_discount_percent,
                    po_discount_amount: txt_po_discount_amount,
                    other_amount: txt_otherAmount,
                    taxable_total: txt_taxableTotal,
                    cgst_total: txt_cgst_total,
                    sgst_total: txt_sgst_total,
                    igst_total: txt_igst_total,
                    roundoff: txt_round_off,
                    grand_total: txt_grand_amount,
                    agent_id: cmb_agentId,
                    agent_percent: txt_agentPercent,
                    agent_paid_status: chk_agentPaidStatus,
                    other_terms_conditions: txt_other_term_con,
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : '',
                    grn_status: 'P',
                    quotation_no: txt_quotation_no,
                    quotation_date: dt_quotation_date
                }
                // if product typw is Raw cotton purchase (Ginning)

                json.TransHeaderFooterData = po_form_data;

                let isValid = true;
                // Trans PO DetailsData
                for (let poDetailIndex = 0; poDetailIndex < po_details_data.length; poDetailIndex++) {

                    const poDetailDataElement = po_details_data[poDetailIndex];
                    // Check if product_rm_hsn_sac_code_id is null or 0 for add & update
                    if (keyForViewUpdate === '' || keyForViewUpdate === 'update') {
                        if (!poDetailDataElement.product_rm_hsn_sac_code_id || poDetailDataElement.product_rm_hsn_sac_code_id === "0") {
                            // You can add a class or modify the DOM element directly if needed
                            document.getElementById(`product_rm_hsn_sac_code_id_${poDetailDataElement.product_rm_id}`).classList.add('required-field');
                            document.getElementById(`product_rm_hsn_sac_code_id_${poDetailDataElement.product_rm_id}`).setAttribute('data-tip', 'Please select atleast one Profit Center');
                            setOpenPODetailsAccord(true)
                            setIsLoading(false)
                            return isValid = false; // Exit the loop if a validation fails
                        } else if (poDetailDataElement.product_rm_hsn_sac_code_id === 1 && !hsnNotReqMats.includes(poDetailDataElement.product_rm_id)) {
                            setHsnMaterial_id(poDetailDataElement.product_rm_id)
                            setMessageForConfirmation(`Do You Want to Select HSN Code for ${poDetailDataElement.product_rm_name} ...?`)
                            setShowConfiemationModal(true)
                            setOpenPODetailsAccord(true)
                            setIsLoading(false)
                            return isValid = false;
                        }
                    }
                    const detailRowProperty = {}
                    detailRowProperty['company_id'] = COMPANY_ID
                    detailRowProperty['company_branch_id'] = COMPANY_BRANCH_ID
                    detailRowProperty['financial_year'] = FINANCIAL_SHORT_NAME
                    detailRowProperty['purchase_order_type_id'] = cmb_product_type_id
                    detailRowProperty['purchase_order_type'] = $('#cmb_product_type_id').find(":selected").text();
                    detailRowProperty['purchase_order_no'] = purchase_order_no
                    detailRowProperty['purchase_order_date'] = txt_po_date;
                    detailRowProperty['currency_id'] = cmb_currency_id;
                    detailRowProperty['purchase_order_version'] = txt_po_version
                    detailRowProperty['indent_no'] = poDetailDataElement.indent_no === 0 ? '' : poDetailDataElement.indent_no
                    detailRowProperty['indent_date'] = poDetailDataElement.indent_date === 0 ? '' : poDetailDataElement.indent_date
                    detailRowProperty['indent_version'] = poDetailDataElement.indent_version === 0 ? '' : poDetailDataElement.indent_version
                    detailRowProperty['indented_by_id'] = poDetailDataElement.indented_by_id === 0 ? '' : poDetailDataElement.indented_by_id
                    detailRowProperty['product_material_id'] = poDetailDataElement.product_rm_id
                    detailRowProperty['product_material_print_name'] = poDetailDataElement.product_rm_name
                    detailRowProperty['product_material_tech_spect'] = poDetailDataElement.product_rm_tech_spect
                    detailRowProperty['lead_time'] = poDetailDataElement.lead_time
                    detailRowProperty['sr_no'] = poDetailIndex + 1
                    detailRowProperty['product_rm_purchase_unit_id'] = poDetailDataElement.product_rm_purchase_unit_id
                    detailRowProperty['product_material_unit_id'] = poDetailDataElement.product_rm_stock_unit_id
                    detailRowProperty['product_material_packing_id'] = poDetailDataElement.product_rm_packing_id
                    detailRowProperty['product_material_hsn_code_id'] = poDetailDataElement.product_rm_hsn_sac_code_id
                    detailRowProperty['product_material_po_quantity'] = poDetailDataElement.product_rm_quantity
                    detailRowProperty['product_material_conversion_factor'] = poDetailDataElement.product_rm_conversion_factor
                    detailRowProperty['product_material_po_weight'] = poDetailDataElement.product_rm_weight
                    detailRowProperty['product_material_po_approved_quantity'] = poDetailDataElement.product_rm_approved_quantity
                    detailRowProperty['product_material_po_approved_weight'] = poDetailDataElement.product_rm_approved_weight
                    detailRowProperty['product_material_po_rejected_quantity'] = poDetailDataElement.product_rm_rejected_quantity
                    detailRowProperty['product_material_po_rejected_weight'] = poDetailDataElement.product_rm_rejected_weight
                    detailRowProperty['customer_id'] = poDetailDataElement.customer_id
                    detailRowProperty['customer_order_no'] = poDetailDataElement.customer_order_no
                    detailRowProperty['customer_order_Date'] = poDetailDataElement.customer_order_Date
                    detailRowProperty['pree_closed_quantity'] = poDetailDataElement.pree_closed_quantity
                    detailRowProperty['pree_closed_weight'] = poDetailDataElement.pree_closed_weight
                    detailRowProperty['pre_closed_remark'] = poDetailDataElement.pre_closed_remark
                    detailRowProperty['pending_quantity'] = poDetailDataElement.product_rm_approved_quantity
                    detailRowProperty['pending_weight'] = poDetailDataElement.product_rm_approved_weight
                    detailRowProperty['purchase_return_quantity'] = poDetailDataElement.purchase_return_quantity
                    detailRowProperty['purchase_return_weight'] = poDetailDataElement.purchase_return_weight
                    detailRowProperty['material_rate'] = poDetailDataElement.product_rm_rate
                    detailRowProperty['material_basic_amount'] = poDetailDataElement.material_basic_amount
                    detailRowProperty['material_discount_percent'] = poDetailDataElement.material_discount_percent
                    detailRowProperty['material_discount_amount'] = poDetailDataElement.material_discount_amount
                    detailRowProperty['material_taxable_amount'] = poDetailDataElement.material_taxable_amount
                    detailRowProperty['material_cgst_percent'] = poDetailDataElement.material_cgst_percent
                    detailRowProperty['material_cgst_total'] = poDetailDataElement.material_cgst_total
                    detailRowProperty['material_sgst_percent'] = poDetailDataElement.material_sgst_percent
                    detailRowProperty['material_sgst_total'] = poDetailDataElement.material_sgst_total
                    detailRowProperty['material_igst_percent'] = poDetailDataElement.material_igst_percent
                    detailRowProperty['material_igst_total'] = poDetailDataElement.material_igst_total
                    detailRowProperty['material_total_amount'] = poDetailDataElement.material_total_amount
                    detailRowProperty['material_freight_amount'] = poDetailDataElement.material_freight_amount
                    detailRowProperty['material_schedule_date'] = poDetailDataElement.material_schedule_date
                    detailRowProperty['so_sr_no'] = poDetailDataElement.so_sr_no
                    detailRowProperty['purchase_order_item_status'] = po_status === 'R' ? po_status : poDetailDataElement.purchase_order_item_status
                    detailRowProperty['grn_item_status'] = 'P'
                    detailRowProperty['department_id'] = cmb_department
                    detailRowProperty['sub_department_id'] = cmb_sub_department
                    //display fields for only track
                    detailRowProperty['material_po_approval_remark'] = poDetailDataElement.material_po_approval_remark
                    detailRowProperty['approved_date'] = poDetailDataElement.approved_date
                    detailRowProperty['is_active'] = po_status === 'X' ? false : chk_po_isactive
                    detailRowProperty['approved_by_id'] = keyForViewUpdate === 'approve' ? cmb_approved_by_id : 0
                    detailRowProperty['created_by'] = UserName
                    detailRowProperty['modified_by'] = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                    detailRowProperty['cost_center_id'] = poDetailDataElement.cost_center_id === 0 ? '' : poDetailDataElement.cost_center_id
                    detailRowProperty['remark'] = poDetailDataElement.remark
                    if (isApprove === true || chk_po_status === 'Z' || chk_po_status === 'X') {
                        detailRowProperty['purchase_order_master_transaction_id'] = poDetailDataElement.purchase_order_master_transaction_id
                        detailRowProperty['purchase_order_details_transaction_id'] = poDetailDataElement.purchase_order_details_transaction_id !== undefined ? poDetailDataElement.purchase_order_details_transaction_id : 0;
                    } else {
                        detailRowProperty['purchase_order_master_transaction_id'] = 0;
                        detailRowProperty['purchase_order_details_transaction_id'] = 0;
                    }
                    // if product typw is Raw cotton purchase (Ginning)

                    detailRowProperty['department_id'] = 0; //default department selected ....
                    // detailRowProperty['sub_department'] = cmb_sub_department
                    detailRowProperty['product_material_po_approved_quantity'] = poDetailDataElement.product_rm_quantity
                    detailRowProperty['approved_date'] = keyForViewUpdate === 'approve' ? txt_approved_date : "";
                    // detailRowProperty['approved_by_id'] = UserId
                    detailRowProperty['material_schedule_date'] = ''
                    detailRowProperty['purchase_order_item_status'] = 'A'
                    detailRowProperty['godown_id'] = poDetailDataElement.godown_id
                    detailRowProperty['godown_section_id'] = poDetailDataElement.godown_section_id
                    detailRowProperty['godown_section_beans_id'] = poDetailDataElement.godown_section_beans_id
                    json.TransDetailData.push(detailRowProperty)
                }



                if (!isApprove) { // If user not come for approve then send whole data.
                    // taxationSummaryData
                    for (let index = 0; index < taxationSummaryData.length; index++) {
                        const element = taxationSummaryData[index];
                        const taxationSummarygroup = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            purchase_order_type_id: $('#cmb_product_type_id').val(),
                            purchase_order_type: $('#cmb_product_type_id').find(":selected").text(),
                            purchase_order_master_transaction_id: 0,
                            supplier_id: cmb_supplier_id,
                            supplier_state_id: cmb_supplier_state_id,
                            supplier_city_id: cmb_supplier_city_id,
                            supplier_contacts_ids: supplierContactPersonsIds,
                            expected_branch_id: cmb_expected_branch_id,
                            expected_branch_state_id: cmb_expected_branch_state_id,
                            expected_branch_city_id: cmb_expected_branch_city_id,
                            purchase_order_no: purchase_order_no,
                            purchase_order_date: txt_po_date,
                            purchase_order_version: txt_po_version,
                            product_material_id: element.product_material_id,
                            hsn_code_id: element?.product_rm_hsn_sac_code_id ?? element?.hsn_code_id,
                            hsn_sac_rate: element?.hsn_sac_rate ?? element?.product_rm_hsn_sac_rate,
                            summary_taxable_amount: element.material_taxable_amount,
                            summary_cgst_percent: element.material_cgst_percent,
                            summary_cgst_total: element.material_cgst_total,
                            summary_sgst_percent: element.material_sgst_percent,
                            summary_sgst_total: element.material_sgst_total,
                            summary_igst_percent: element.material_igst_percent,
                            summary_igst_total: element.material_igst_total,
                            summary_total_amount: element.material_total_amount,
                            created_by: UserName,
                            modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                        }
                        json.TransTaxSummaryTermsData.push(taxationSummarygroup)
                    }

                    // Trans Payment Terms Data
                    $("input:checkbox[name=selectPayTerm]:checked").each(function () {

                        let findPaymentTermsData = paymentTermsData.find(item => item.payment_terms_id === parseInt($(this).val()));
                        let payment_term_idVal = parseInt($(this).val());
                        const paymentTermData = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            purchase_order_master_transaction_id: po_master_transaction_id,
                            purchase_order_no: purchase_order_no,
                            purchase_order_date: txt_po_date,
                            purchase_order_version: txt_po_version,
                            customer_order_no: '',
                            customer_order_Date: '',
                            payment_terms_id: payment_term_idVal,
                            payment_terms_name: findPaymentTermsData.payment_terms_name,
                            payment_terms_days: findPaymentTermsData.payment_terms_days,
                            payment_terms_grace_days: findPaymentTermsData.payment_terms_grace_days,
                            payment_terms_Milestome: $('#payment_milestone_' + parseInt($(this).val())).val(),
                            payment_percent: $('#payment_percent_' + parseInt($(this).val())).val(),
                            payment_expected_value: $('#payment_expect_val_' + parseInt($(this).val())).val(),
                            payment_expected_date: $('#payment_expected_date_' + parseInt($(this).val())).val(),
                            payment_paid_flag: $('#payment_paid_flag_' + parseInt($(this).val())).val(),
                            created_by: UserName,
                            modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                        }
                        json.TransPaymentTermsData.push(paymentTermData);
                    });


                    // Trans Product Properties Data
                    for (let index = 0; index < productTypePropertiesData.length; index++) {
                        const parameterDetailsRow = productTypePropertiesData[index];
                        const parameterObj = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            purchase_order_master_transaction_id: po_master_transaction_id,
                            purchase_order_parameter_id: 0,
                            product_parameter_id: 0,
                            purchase_order_no: purchase_order_no,
                            purchase_order_date: txt_po_date,
                            purchase_order_version: txt_po_version,
                            product_id: parameterDetailsRow.product_id,
                            product_type_id: cmb_product_type_id,
                            product_parameter_name: parameterDetailsRow.product_parameter_name,
                            product_parameter_value: 0,
                            product_parameter_actual_value: parameterDetailsRow.product_parameter_actual_value,
                            product_parameter_sufix: parameterDetailsRow.product_parameter_sufix,
                            product_parameter_prefix: parameterDetailsRow.product_parameter_prefix,
                            product_parameter_calculation_type: parameterDetailsRow.product_parameter_calculation_type,
                            product_parameter_from_value: parameterDetailsRow.product_parameter_from_value,
                            product_parameter_to_value: parameterDetailsRow.product_parameter_to_value,
                            product_parameter_formula: parameterDetailsRow.product_parameter_formula,
                            remark: parameterDetailsRow.remark,
                            created_by: keyForViewUpdate === "" ? UserName : parameterDetailsRow.created_by,
                            modified_by: keyForViewUpdate === "update" ? UserName : null
                        }
                        json.TransProductParametersData.push(parameterObj)
                    }
                } else {

                    if (po_status === 'R') {
                        setScheduleDataArray(prevData => {
                            const updatedData = prevData.map(schedulerow => ({
                                ...schedulerow,
                                purchase_order_schedules_item_status: 'R',
                            }));

                            json.TransScheduleData.push(...updatedData);

                            return updatedData;
                        });
                    }
                    else {
                        json.TransDetailData.map(element => {
                            let currentMaterialSchedules = scheduleDataArray.map(schedule =>
                                schedule.product_material_id === element.product_material_id
                                    ? {
                                        ...schedule,
                                        purchase_order_master_transaction_id: po_master_transaction_id,
                                        purchase_order_version: txt_po_version,
                                        purchase_order_schedules_transaction_id: 0,
                                        purchase_order_schedules_item_status: element.purchase_order_item_status === 'R' ? 'R' : schedule.purchase_order_schedules_item_status
                                    }
                                    : null
                            ).filter(Boolean);
                            json.TransScheduleData.push(...currentMaterialSchedules);
                        });
                    }
                }
                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.purchase_order_no = purchase_order_no
                json.commonIds.purchase_order_date = txt_po_date
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                let isApproveOrPreClosed = 'AddUpdate';
                if (keyForViewUpdate === 'approve') {
                    isApproveOrPreClosed = 'approve';
                } else if (keyForViewUpdate === 'view' && chk_po_status === 'Z') {
                    isApproveOrPreClosed = 'preClosed';
                } else if (keyForViewUpdate === 'cancel') {
                    isApproveOrPreClosed = 'cancel';
                }
                console.log("json of PO New: ", json);
                const formData = new FormData();
                formData.append(`StPurchaseData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnAddUpdateRecord/${isApproveOrPreClosed}`, requestOptions);
                const responce = await apicall.json();
                if (responce.success === "0") {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = await comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    $("#save-btn").prop("disabled", true);
                    $("#reject-btn").prop("disabled", true);
                    setSuccMsg(responce.message)
                    if (responce.data.hasOwnProperty('purchase_order_version') && responce.data.hasOwnProperty('purchase_order_no')) {
                        setModalOrderDetails(`purchase Order No: ${responce.data.purchase_order_no} and Version: ${responce.data.purchase_order_version}`)
                    }
                    if (po_status !== 'R' && keyForViewUpdate === 'approve') {
                        setShowMailSentModal(true); //upto when email functionality get worked.
                        // setShowSuccessMsgModal(true);
                    } else {


                        const GRNResponce = fnAddGoodsReceiptNote(json, responce.purchaseOrderDetails);
                        if (GRNResponce.success === "0") {
                            setErrMsg(GRNResponce.error)
                            setShowErrorMsgModal(true)

                        } else {
                            const evitCache = await comboDataAPiCall.current.evitCache();
                            console.log(evitCache);
                            setShowSuccessMsgModal(true);
                        }



                    }
                    if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'reject') {
                        $('#viewdocument-id').removeAttr('disabled');
                    }
                }
                setIsLoading(false)
                console.log(json)
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    const generateGrnNo = async () => {
        // const productTypeshortname = $('#cmb_product_type_id option:selected').attr('shortname');
        const grn_no = await generateAutoNoAPiCall.current.generateTAutoNo("pt_goods_receipt_master", "goods_receipt_no", "", "GR", "5");
        return grn_no;
    }
    const fnAddGoodsReceiptNote = async (poJson, purchaseOrderDetails) => {
        try {

            const po_header_data = poJson.TransHeaderFooterData;
            const po_details_data = purchaseOrderDetails;
            const po_payment_terms_data = poJson.TransPaymentTermsData;

            let latestGrnNo = goods_receipt_no;
            let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransTaxSummaryData': [], 'TransPaymentTermsData': [], 'commonIds': {} }
            if (keyForViewUpdate === "")
                latestGrnNo = await generateGrnNo();


            const grnMasterData = {
                goods_receipt_master_transaction_id: 0,
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                financial_year: FINANCIAL_SHORT_NAME,
                goods_receipt_no: latestGrnNo,
                goods_receipt_date: po_header_data.purchase_order_date,
                goods_receipt_version: po_header_data.purchase_order_version,
                supplier_id: po_header_data.supplier_id,
                supplier_state_id: po_header_data.supplier_state_id,
                supplier_city_id: po_header_data.supplier_city_id,
                supplier_contacts_ids: po_header_data.supplier_contacts_ids,
                expected_branch_id: po_header_data.expected_branch_id,
                expected_branch_state_id: po_header_data.expected_branch_state_id,
                expected_branch_city_id: po_header_data.expected_branch_city_id,
                purchase_order_no: null,
                purchase_order_date: null,
                purchase_order_version: null,
                goods_receipt_type_id: po_header_data.purchase_order_type_id,
                goods_receipt_type: po_header_data.purchase_order_type,
                purchase_order_life: po_header_data.purchase_order_life,
                supplier_challan_no: latestGrnNo,
                supplier_challan_date: po_header_data.purchase_order_date,
                approved_by_id: po_header_data.approved_by_id,
                approved_date: po_header_data.approved_date,
                qa_by_id: po_header_data.approved_by_id,
                qa_date: po_header_data.approved_date,
                expected_schedule_date: null,
                basic_total: po_header_data.basic_total,
                transport_amount: po_header_data.transport_amount,
                freight_amount: po_header_data.freight_amount,
                is_freight_taxable: po_header_data.is_freight_taxable,
                freight_hsn_code_id: po_header_data.freight_hsn_code_id,
                packing_amount: po_header_data.packing_amount,
                goods_receipt_discount_percent: po_header_data.po_discount_percent,
                goods_receipt_discount_amount: po_header_data.po_discount_amount,
                other_amount: po_header_data.other_amount,
                taxable_total: po_header_data.taxable_total,
                cgst_total: po_header_data.cgst_total,
                sgst_total: po_header_data.sgst_total,
                igst_total: po_header_data.igst_total,
                grand_total: po_header_data.grand_total,
                roundoff: po_header_data.roundoff,
                agent_id: po_header_data.agent_id,
                agent_percent: po_header_data.agent_percent,
                agent_paid_status: po_header_data.agent_paid_status,
                goods_receipt_status: 'C',
                lr_no: "",
                lr_date: "",
                vehicle_no: po_header_data.vehicale_no,
                other_terms_conditions: po_header_data.other_terms_conditions,
                supplier_invoice_no: "",
                supplier_invoice_date: "",
                remark: po_header_data.remark,
                is_active: po_header_data.is_active,
                created_by: po_header_data.created_by,
                is_preeclosed: false,
                modified_by: po_header_data.modified_by
            }

            json.TransHeaderData = grnMasterData;


            for (let index = 0; index < po_details_data.length; index++) {
                const element = po_details_data[index];
                let jsonRecord = {}
                jsonRecord.goods_receipt_details_transaction_id = 0;
                jsonRecord.company_id = COMPANY_ID;
                jsonRecord.company_branch_id = COMPANY_BRANCH_ID;
                jsonRecord.financial_year = FINANCIAL_SHORT_NAME;
                jsonRecord.goods_receipt_no = latestGrnNo;
                jsonRecord.goods_receipt_date = element.purchase_order_date;
                jsonRecord.goods_receipt_version = element.purchase_order_version;
                jsonRecord.purchase_order_no = element.purchase_order_no;
                jsonRecord.purchase_order_date = element.purchase_order_date;
                jsonRecord.purchase_order_version = element.purchase_order_version;
                jsonRecord.customer_id = element.customer_id;
                jsonRecord.customer_order_no = element.customer_order_no;
                jsonRecord.customer_order_Date = element.customer_order_Date;
                jsonRecord.item_qa_by_id = UserId;
                jsonRecord.item_qa_date = currentDate;
                jsonRecord.goods_receipt_type_id = element.purchase_order_type_id;
                jsonRecord.goods_receipt_type = element.purchase_order_type;
                jsonRecord.batch_no = element.batch_no === undefined ? `${element.financial_year}/${latestGrnNo}/${element.purchase_order_date}` : element.batch_no;
                jsonRecord.product_material_id = element.product_material_id;
                jsonRecord.product_material_tech_spect = element.product_material_tech_spect;
                jsonRecord.lead_time = element.lead_time;
                jsonRecord.sr_no = element.sr_no;
                jsonRecord.product_material_unit_id = element.product_material_unit_id;
                jsonRecord.product_material_packing_id = element.product_material_packing_id;
                jsonRecord.product_material_hsn_code_id = element.product_material_hsn_code_id;
                jsonRecord.product_material_po_approved_quantity = element.product_material_po_approved_quantity;
                jsonRecord.product_material_po_approved_weight = element.product_material_po_approved_weight;
                jsonRecord.product_material_grn_quantity = element.product_material_po_approved_quantity;
                jsonRecord.product_material_grn_weight = element.product_material_po_approved_weight;
                jsonRecord.product_material_grn_accepted_quantity = element.product_material_po_approved_quantity;
                jsonRecord.product_material_grn_accepted_weight = element.product_material_po_approved_weight;
                jsonRecord.product_material_conversion_factor = element.product_material_conversion_factor;
                jsonRecord.product_material_grn_rejected_quantity = 0;
                jsonRecord.product_material_grn_rejected_weight = 0;
                jsonRecord.product_material_rejection_reason_id = "";
                jsonRecord.material_rate = element.material_rate;
                jsonRecord.material_basic_amount = element.material_basic_amount;
                jsonRecord.material_freight_amount = element.material_freight_amount;
                jsonRecord.material_discount_percent = element.material_discount_percent;
                jsonRecord.material_discount_amount = element.material_discount_amount;
                jsonRecord.material_taxable_amount = element.material_taxable_amount;
                jsonRecord.material_cgst_percent = element.material_cgst_percent;
                jsonRecord.material_cgst_total = element.material_cgst_total;
                jsonRecord.material_sgst_percent = element.material_sgst_percent;
                jsonRecord.material_sgst_total = element.material_sgst_total;
                jsonRecord.material_igst_percent = element.material_igst_percent;
                jsonRecord.material_igst_total = element.material_igst_total;
                jsonRecord.material_total_amount = element.material_total_amount;
                jsonRecord.material_schedule_date = element.material_schedule_date;
                jsonRecord.excess_quantity = 0;
                jsonRecord.excess_weight = 0;
                jsonRecord.product_material_prev_accepted_quantity = 0;
                jsonRecord.product_material_prev_accepted_weight = 0;
                jsonRecord.pree_closed_grn_quantity = 0;
                jsonRecord.pree_closed_grn_weight = 0;
                jsonRecord.department_id = element.department_id;
                jsonRecord.sub_department_id = element.sub_department_id;
                jsonRecord.grn_item_status = 'G';
                jsonRecord.remark = element.remark;
                jsonRecord.created_by = element.created_by;
                jsonRecord.modified_by = element.modified_by;
                jsonRecord.expiry_date = "";
                jsonRecord.purchase_order_details_transaction_id = element.purchase_order_details_transaction_id
                jsonRecord.godown_id = element.godown_id
                jsonRecord.godown_section_id = element.godown_section_id
                jsonRecord.godown_section_beans_id = element.godown_section_beans_id
                jsonRecord.product_material_prev_accepted_quantity = 0
                json.TransDetailData.push(jsonRecord);
            }


            for (let index = 0; index < po_payment_terms_data.length; index++) {
                const element = po_payment_terms_data[index];
                const payTermsData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    goods_receipt_no: latestGrnNo,
                    goods_receipt_date: element.purchase_order_date,
                    goods_receipt_version: element.purchase_order_version,
                    purchase_order_no: element.purchase_order_no,
                    purchase_order_date: element.purchase_order_date,
                    purchase_order_version: element.purchase_order_version,
                    payment_terms_id: element.payment_terms_id,
                    payment_terms_name: element.payment_terms_name,
                    payment_terms_days: element.payment_terms_days,
                    payment_terms_grace_days: element.payment_terms_grace_days,
                    payment_terms_Milestome: element.payment_terms_Milestome,
                    payment_percent: element.payment_percent,
                    payment_expected_value: element.payment_expected_value,
                    payment_expected_date: element.payment_expected_date,
                    payment_paid_flag: element.payment_paid_flag,
                    created_by: element.created_by,
                    modified_by: element.modified_by
                }
                json.TransPaymentTermsData.push(payTermsData)
            }


            //Common Ids
            json.commonIds.company_id = COMPANY_ID
            json.commonIds.company_branch_id = COMPANY_BRANCH_ID
            json.commonIds.goods_receipt_no = latestGrnNo
            json.commonIds.goods_receipt_version = txt_po_version
            json.commonIds.financial_year = FINANCIAL_SHORT_NAME

            console.log("GoodReceiptNoteData: ", json);
            const formData = new FormData();
            formData.append(`GoodReceiptNoteData`, JSON.stringify(json))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            let grnApproved = false;
            const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnAddUpdateRecord/${grnApproved}`, requestOptions)
            const response = await apicall.json();
            if (response.success === 1) {

                grnApproved = true;
                json.TransHeaderData = response.data;
                console.log("GoodReceiptNoteData Approved: ", json);
                const formData = new FormData();
                formData.append(`GoodReceiptNoteData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnAddUpdateRecord/${grnApproved}`, requestOptions)
                const approveResponse = await apicall.json();
            }

            return response;

        } catch (error) {
            console.log("error: ", error);
            navigator('/Error')
        }
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }
    //fn for send mail after approve
    const sendEmail = async () => {
        try {
            setIsLoading(true);
            setShowMailSentModal(false);
            $('#spinner_text').text('Sending Email...');
            const EmailData = await FngenerateMailJson();
            if (EmailData.toMailData.length === 0) {
                setErrMsg("Sorry, There are no supplier emails present to send an email to...!");
                setShowErrorMsgModal(true);
                setIsLoading(false);
                return false;
            }

            // Generate the sales-order PDF.
            let purchaseOrderContent = await printInvoice(false, false);
            const fileName = `${txt_PO_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
            const purchaseOrderContainer = document.createElement('div');
            purchaseOrderContainer.style.margin = '20px';
            purchaseOrderContainer.innerHTML = purchaseOrderContent;
            let worker = html2pdf().from(purchaseOrderContainer).set({
                margin: 0,
                filename: fileName,
                html2canvas: { scale: 2 },
                jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
            }).toPdf();

            let pages = [];
            pages.slice(1).forEach(function (page) {
                worker = worker.get('pdf').then(function (pdf) {
                    pdf.addPage();
                }).from(page).toContainer().toCanvas().toPdf();
            });

            worker = worker.output("datauristring").then(async function (pdf) {
                const preBlob = dataURItoBlob(pdf);
                const file = new File([preBlob], fileName, { type: 'application/pdf' });

                const formData = new FormData();
                formData.append(`EmailData`, JSON.stringify(EmailData))
                formData.append("purchaseOrderPDF", file);

                const requestOptions = { method: 'POST', body: formData };
                console.log('email requestOptions ', requestOptions);

                const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnSendEmail/${po_master_transaction_id}/${COMPANY_ID}`, requestOptions);
                const emailingApiCallResponse = await emailingApiCall.json();

                if (emailingApiCallResponse.success === "1") {
                    // Show success message.
                    setSuccMsg(emailingApiCallResponse.message);
                    setShowSuccessMsgModal(true);
                } else {
                    // Show error message.
                    setErrMsg(emailingApiCallResponse.message);
                    setShowErrorMsgModal(true);
                }
                setIsLoading(false);
            });
        } catch (error) {
            console.log('Error in email sending: ', error);
            navigate('/Error')
        }
    }
    // fn for generate mail parameters
    // const FngenerateMailJson = async () => {
    //     let EmailData = { 'EmailType': "Purchase Order Status Updatation", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
    //     const checkboxes = $('.selectSuppContactPerson:checked');
    //     checkboxes.each(function () {
    //         let selectedContact_id = parseInt($(this).val());
    //         const supplierContact = suppContactDetails.find(contactPerson => contactPerson.supplier_contact_id === selectedContact_id);
    //         if (supplierContact) {
    //             EmailData.toMailData.push(supplierContact.supp_email_id);
    //         }
    //     });
    //     EmailData.subject = `Purchase Order Approval Confirmation - Order ${txt_PO_no}`;    // Subject
    //     EmailData.parameters.push("Purchase Order Information");                            // Param0 It is a heading.
    //     EmailData.parameters.push($('#cmb_supplier_id').find(":selected").text());          // Param1
    //     EmailData.parameters.push(`${txt_PO_no}`);                                          // Param2
    //     EmailData.parameters.push($('#chk_po_status').find(":selected").text());            // Param3
    //     EmailData.parameters.push(validateNumberDateInput.current.formatDateToDDMMYYYY(`${txt_po_date}`));
    //     EmailData.parameters.push(`${txt_PO_no}`);                                        // Param4
    //     EmailData.parameters.push(COMPANY_NAME);              // Param6
    //     EmailData.parameters.push(sessionStorage.getItem('companyAddress'));                // Param7
    //     EmailData.parameters.push($('#cmb_approved_by_id').find(":selected").text());       // Param8
    //     console.log('emailData: ', EmailData);
    //     return EmailData;
    // }
    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const FngenerateMailJson = async () => {
        let EmailData = { 'EmailType': "Purchase Order Status Updatation", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [], 'alias': "store@pashupaticotspin.com" }
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            let selectedContact_id = parseInt($(this).val());
            const supplierContact = suppContactDetails.find(contactPerson => contactPerson.supplier_contact_id === selectedContact_id);
            if (supplierContact) {
                // Store only valid emails.
                if (isValidEmail(supplierContact?.supp_email_id)) {
                    EmailData.toMailData.push(supplierContact.supp_email_id);
                }
            }
        });

        EmailData.subject = `Purchase Order By ${COMPANY_NAME} - Order ${txt_PO_no}`;    // Subject
        EmailData.parameters.push("Purchase Order Information");                            // Param0 It is a heading.
        // EmailData.parameters.push($('#cmb_supplier_id').find(":selected").text());          // Param1
        EmailData.parameters.push(supplierComboRef.current.label);                          // Param1
        EmailData.parameters.push(`${txt_PO_no}`);                                          // Param2
        EmailData.parameters.push($('#chk_po_status').find(":selected").text());            // Param3
        EmailData.parameters.push(validateNumberDateInput.current.formatDateToDDMMYYYY(`${txt_po_date}`));
        EmailData.parameters.push(`${txt_PO_no}`);                                        // Param4
        EmailData.parameters.push(COMPANY_NAME);              // Param6
        EmailData.parameters.push(localStorage.getItem('companyAddress'));                // Param7
        EmailData.parameters.push($('#cmb_approved_by_id').find(":selected").text());       // Param8
        console.log('emailData: ', EmailData);
        return EmailData;
    }

    // Fn function for accept purchase order
    const acceptPurchaseOrder = async () => {
        setIsLoading(true)
        $('#spinner_text').text('Validating Order Details...');
        try {
            let POAcceptanceJson = {
                purchase_order_master_transaction_id: po_master_transaction_id,
                purchase_order_acceptance_status: 'A'
            }
            const formData = new FormData();
            formData.append(`POAcceptanceJson`, JSON.stringify(POAcceptanceJson))
            const requestOptions = { method: 'POST', body: formData };
            const acceptPurchaseOrderAPICall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnAcceptPurchaseOrder/${COMPANY_ID}`, requestOptions)
            const acceptPurchaseOrderAPICallResponse = await acceptPurchaseOrderAPICall.json();
            setIsLoading(false)
            if (acceptPurchaseOrderAPICallResponse.success === "1") {
                setSuccMsg(acceptPurchaseOrderAPICallResponse.message)
                setShowSuccessMsgModal(true);
            } else {
                setErrMsg(acceptPurchaseOrderAPICallResponse.error);
                setShowErrorMsgModal(true);
            }


        } catch (error) {
            console.log('Error in accepting Purchase order: ', error)
            navigate('/Error')
            $('#spinner_id').hide();
        }
    }

    const FnClearFormData = async () => {
        setPO_master_transactionID(0)
        setProductTypeID('')
        setPO_life('C')
        setPurchase_process('M')
        setPO_creation_type('M')
        setPOno(0)
        // await GeneratePONo();
        setPOVersion(1)
        setPODate(today)
        // setCustomerId('')
        // setCustomerOrderNo('')
        // setCustomerOrderDate('')
        setSupplier_id('')
        setSupplier_state_id('')
        setSupplier_city_id('')
        //Supplier contacts ids 
        setSuppContactDetails([])
        setSuppContactDetailsCols([])
        setExpected_branch_id(COMPANY_BRANCH_ID)
        setExpected_branch_state_id('')
        setExpected_branch_city_id('')
        setDepartmentId('')
        setSubDepartmentId('')
        setApproved_by_id('')
        setApproved_date('')
        setScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays))
        setPO_mail_sent_status('P')
        setPO_status('P')
        setStatus_remark('')
        //footer data reset pending
        setBasic_total(0)
        setTransportAmount(0)
        setFreightAmount(0)
        SetIsFreightTaxable(false)
        setPackingAmount(0)
        setPODiscountPercent(0)
        setPODiscountAmount(0)
        setOtherAmount(0)
        setTaxableTotal(0)
        setCgstTotal(0)
        setSgstTotal(0)
        setIgstTotal(0)
        setGrandTotal(0)
        setRoundOff(0)
        setAgentId('')
        setAgentPercent(0)
        setAgentPaidStatus('P')
        setPO_acceptance_status('P')
        setOther_term_con('')
        setRemark('')
        setPOIsActive(true)
        setQuotation_no('')
        setQuotation_date('')

        // ginning hrader form hooks
        setGatePassNo('')
        setGatePassDate('')
        setVehicaleNo('')
        setVehicaleType('')
        setGrossWeight(0)
        setTareWeight(0)
        setVariationWeight(0)
        setNetWeight(0)

        sessionStorage.removeItem('filteredMaterialData')
        localStorage.removeItem('purchaseOrdScheduleData')
        localStorage.removeItem('taxationSummary');
        taxationSummary = [];
        FnUpdateTotalPODetailsArr([])
        setProductTypePropertiesData([]);
        setScheduleDataArray([])
        setTaxationSummaryData([])
        setPaymentTermsData([])
        setPOTermsData([])
        if (chk_po_creation_type === 'A') {
            setIndentNosList([]);
            // setCheckedIndentNosLength(0)
        }
        setRawCottonPurchaseDeductionData([])
        await loadDefaultPoOrdData();
        $('#selectAllPOTerms').prop('checked', false);
        $('#selectAllPayTerms').prop('checked', false);
    }
    //fn for User Access Manage .
    const FnSetFieldsByUserAccess = () => {
        if (keyForViewUpdate != null) {
            setIsApprove(keyForViewUpdate === 'approve' ? true : false);
            switch (keyForViewUpdate) {
                case 'update':
                    $('input[name="chk_po_creation_type"]').prop('disabled', true);
                    $('#cmb_product_type_id').attr('disabled', true);
                    $('#txt_po_date').attr('disabled', true);
                    $('#save-btn').text('Update');
                    $('#print-btn-id').hide();
                    break;
                case 'view':
                    setIsView(true)
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("#save-btn").prop("disabled", true);
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('input[type="checkbox"]').prop('disabled', true);
                    $('#selectAllPOTerms, #selectAllPayTerms').prop("disabled", true);
                    // if ((po_status === 'A' || po_status === 'I') && po_life === 'O') {
                    //     $("form").find('select#chk_po_status').removeAttr("disabled");
                    // }
                    // // Disable specific options
                    // $("form").find('select#chk_po_status option').each(function () {
                    //     if ($(this).val() !== 'Z' && $(this).val() !== po_status) {// Assuming you want to disable options with values 'option2' and 'option3'
                    //         $(this).prop('disabled', true);
                    //     } else {
                    //         $(this).prop('disabled', false); // Enable the specific option
                    //     }
                    // });
                    $('#clear-btn-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    break;
                case 'approve':
                    setIsView(true)
                    $('input[type="checkbox"]').prop('readonly', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('table .approveField').removeAttr("disabled");
                    // $("form").find('select#chk_po_status').removeAttr("disabled");
                    $("form").find('select#cmb_approved_by_id').removeAttr("disabled");
                    $("form").find('input#txt_approved_date').removeAttr("disabled");
                    $("form").find('input#txt_approved_date').removeAttr("readonly");
                    $("form").find('textarea#txt_status_remark').removeAttr("disabled");
                    $('#save-btn').text('Approve');
                    // $("form").find('select#chk_po_status option').each(function () {
                    //     if ($(this).val() === 'Z' || $(this).val() === 'B') {// Assuming you want to disable options with values 'option2' and 'option3'
                    //         $(this).prop('disabled', true);
                    //     } else {
                    //         $(this).prop('disabled', false); // Enable the specific option
                    //     }
                    // });
                    $('#clear-btn-id').hide();
                    $('#print-btn-id').hide();
                    $('#viewdocument-id').hide();
                    break;
                case 'delete':
                    setIsView(true)
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("#save-btn").prop("disabled", true);
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('input[type="checkbox"]').prop('disabled', true);
                    $('#selectAllPOTerms, #selectAllPayTerms').prop("disabled", true);
                    // if ((po_status === 'A' || po_status === 'I') && po_life === 'O') {
                    //     $("form").find('select#chk_po_status').removeAttr("disabled");
                    // }
                    // // Disable specific options
                    // $("form").find('select#chk_po_status option').each(function () {
                    //     if ($(this).val() !== 'Z' && $(this).val() !== po_status) {// Assuming you want to disable options with values 'option2' and 'option3'
                    //         $(this).prop('disabled', true);
                    //     } else {
                    //         $(this).prop('disabled', false); // Enable the specific option
                    //     }
                    // });
                    $('#clear-btn-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    break;
                case 'cancel':
                    setIsView(true)
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("#save-btn").prop("disabled", true);
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('input[type="checkbox"]').prop('disabled', true);
                    $('#selectAllPOTerms, #selectAllPayTerms').prop("disabled", true);

                    $('#clear-btn-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    break;
                default: break;
            }
        }
    }
    const FnGetSupplierContactPersonIds = async () => {
        debugger
        let supplierContactPersonsIds = '';
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            supplierContactPersonsIds += $(this).val() + ":";
        });
        return supplierContactPersonsIds.replace(/:$/, '');
    }

    const FnSetExistingSupplierContactPersonIds = async (contactIds) => {
        const supplierContactPersonsIds = contactIds.split(':');
        supplierContactPersonsIds.forEach(function (contactId, index) {
            $('#selectSuppContactPerson_' + supplierContactPersonsIds[index]).prop('checked', true);
        });
        toggleChkAllBoxes('PartiallySuppContactSelection');
    }
    const FnCheckUpdateResponce = async (isTaxableAccordian) => {
        debugger
        let defaultExtpScheduleDate = FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays);
        setDefaultExpecScheduleDate(defaultExtpScheduleDate)
        try {
            ;
            if (idList !== '') {
                fin_year = idList.financial_year;
                PO_version = idList.purchase_order_version;
                let purchase_order_no;
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnShowAllDetailsAndMastermodelRecords/${PO_version}/${fin_year}/${COMPANY_ID}?purchase_order_no=${idList.purchase_order_no}`)
                const responce = await apiCall.json();
                console.log(responce);

                if (responce.PurchaseOrderMasterRecord != null) {
                    setPurchaseOrderMasterJson(responce.PurchaseOrderMasterRecord);
                    const transPOHeaderFooterData = responce.PurchaseOrderMasterRecord;
                    let creation_type = transPOHeaderFooterData.purchase_order_creation_type;
                    setPO_master_transactionID(transPOHeaderFooterData.purchase_order_master_transaction_id)
                    setPO_creation_type(creation_type)
                    if (creation_type === 'A') {
                        $('#add-material-id').hide();
                    } else {
                        $('#add-material-id').show();
                    }
                    setProductTypeID(transPOHeaderFooterData.purchase_order_type_id)
                    // const preLoadPropertiesData = await comboOnChange('productType')

                    purchase_order_no = transPOHeaderFooterData.purchase_order_no;
                    setPOVersion(transPOHeaderFooterData.purchase_order_version)
                    setPODate(transPOHeaderFooterData.purchase_order_date)
                    setSupplier_id(transPOHeaderFooterData.supplier_id)
                    setProductCategoryId(transPOHeaderFooterData.product_category2_id);
                    supplierComboRef.current.value = transPOHeaderFooterData.supplier_id;
                    supplierComboRef.current.label = transPOHeaderFooterData.supplier_name;
                    await comboOnChange('supplier')
                    setSupplier_state_id(transPOHeaderFooterData.supplier_state_id)
                    await comboOnChange("supplier_state")
                    setSupplier_city_id(transPOHeaderFooterData.supplier_city_id)
                    await FnSetExistingSupplierContactPersonIds(transPOHeaderFooterData.supplier_contacts_ids);
                    setPO_life(transPOHeaderFooterData.purchase_order_life)
                    setPurchase_process(transPOHeaderFooterData.purchase_process_entry)
                    // setPOIndentNo(transPOHeaderFooterData.indent_no)
                    // setPOIndentDate(transPOHeaderFooterData.indent_date)
                    // setPOIndentVersion(transPOHeaderFooterData.indent_version)
                    setExpected_branch_id(transPOHeaderFooterData.expected_branch_id)
                    setExpected_branch_state_id(transPOHeaderFooterData.expected_branch_state_id)
                    // await comboOnChange('expt_branchState')
                    setExpected_branch_city_id(transPOHeaderFooterData.expected_branch_city_id)
                    // setDepartmentId(responce.PurchaseOrderDetailsRecords[0].department_id)
                    // departmentComboRef.current.value = responce.PurchaseOrderDetailsRecords[0].department_id
                    // await comboOnChange('departmentId')
                    // setSubDepartmentId(responce.PurchaseOrderDetailsRecords[0].sub_department_id)
                    // subDepartmentComboRef.current.value = responce.PurchaseOrderDetailsRecords[0].sub_department_id
                    setScheduleDate(transPOHeaderFooterData.expected_schedule_date)
                    setPO_mail_sent_status(transPOHeaderFooterData.purchase_order_mail_sent_status)
                    setPO_acceptance_status(transPOHeaderFooterData.purchase_order_acceptance_status)
                    setStatus_remark(transPOHeaderFooterData.status_remark)
                    setRemark(transPOHeaderFooterData.remark)
                    setPOIsActive(transPOHeaderFooterData.is_active)
                    consigneeComboRef.current.value = transPOHeaderFooterData.consignee_id;
                    consigneeComboRef.current.label = transPOHeaderFooterData.consignee_name;
                    consigneeComboRef.current.supp_branch_state_id = transPOHeaderFooterData.consignee_state_id;
                    consigneeComboRef.current.supp_branch_city_id = transPOHeaderFooterData.consignee_city_id;
                    await comboOnChange('Consignee')
                    setConsigneeid(transPOHeaderFooterData.consignee_id)
                    setConsignee_state_id(transPOHeaderFooterData.consignee_state_id)
                    setConsignee_city_id(transPOHeaderFooterData.consignee_city_id)
                    //set footer data
                    setBasic_total(transPOHeaderFooterData.basic_total)
                    setFreightAmount(transPOHeaderFooterData.freight_amount)
                    SetIsFreightTaxable(transPOHeaderFooterData.is_freight_taxable)
                    setFreightHSNId(transPOHeaderFooterData.freight_hsn_code_id)
                    setPackingAmount(transPOHeaderFooterData.packing_amount)
                    setPODiscountPercent(transPOHeaderFooterData.po_discount_percent)
                    setPODiscountAmount(transPOHeaderFooterData.po_discount_amount)
                    setTransportAmount(transPOHeaderFooterData.transport_amount)
                    setCgstTotal(transPOHeaderFooterData.cgst_total)
                    setSgstTotal(transPOHeaderFooterData.sgst_total)
                    setIgstTotal(transPOHeaderFooterData.igst_total)
                    setRoundOff(transPOHeaderFooterData.roundoff)
                    setGrandTotal(transPOHeaderFooterData.grand_total)
                    setOtherAmount(transPOHeaderFooterData.other_amount)
                    setTaxableTotal(transPOHeaderFooterData.taxable_total)
                    setAgentId(transPOHeaderFooterData.agent_id)
                    setAgentPercent(transPOHeaderFooterData.agent_percent)
                    setAgentPaidStatus(transPOHeaderFooterData.agent_paid_status)
                    setOther_term_con(transPOHeaderFooterData.other_terms_conditions)
                    setGatePassNo(transPOHeaderFooterData.gate_pass_no)
                    setGatePassDate(transPOHeaderFooterData.gate_pass_date)
                    setVehicaleNo(transPOHeaderFooterData.vehicale_no)
                    setVehicaleType(transPOHeaderFooterData.vehicale_type)
                    setGrossWeight(transPOHeaderFooterData.gross_weight)
                    setTareWeight(transPOHeaderFooterData.tare_weight)
                    setNetWeight(transPOHeaderFooterData.net_weight)
                    setGraderId(transPOHeaderFooterData.grader_by_id)
                    setQuotation_no(transPOHeaderFooterData.quotation_no)
                    setQuotation_date(transPOHeaderFooterData.quotation_date)
                    setCurrencyId(responce.PurchaseOrderDetailsRecords[0].currency_id);
                    SetCurrency_symbol(responce.PurchaseOrderDetailsRecords[0].currency_symbol);
                    setCurrencyLbl(responce.PurchaseOrderDetailsRecords[0]['currency_name']);
                    let taxableTotal = parseFloat(transPOHeaderFooterData.taxable_total);
                    let amount = parseFloat(transPOHeaderFooterData.freight_amount);
                    setFreightPercent(validateNumberDateInput.current?.decimalNumber(((amount * 100) / taxableTotal), 2));

                    // await handleFooterFreightAmountChange(transPOHeaderFooterData.freight_amount);
                    // await handleFooterPackingAmountChange(transPOHeaderFooterData.packing_amount);
                    if (keyForViewUpdate === 'approve') {
                        setPO_status('A')
                        setApproved_by_id(transPOHeaderFooterData.approved_by_id)
                        setApproved_date(today)
                    } else if (keyForViewUpdate !== 'copy') {
                        if (keyForViewUpdate === 'cancel') {
                            setPO_status('X')
                        } else {
                            setPO_status(transPOHeaderFooterData.purchase_order_status)
                            po_status = transPOHeaderFooterData.purchase_order_status;
                        }
                        po_life = transPOHeaderFooterData.purchase_order_life;
                        setApproved_by_id(transPOHeaderFooterData.approved_by_id)
                        setApproved_date(transPOHeaderFooterData.approved_date)
                    }

                    if (keyForViewUpdate === 'copy') {
                        setPO_creation_type('M')
                        setPOVersion('1')
                        // setPOIndentNo('');

                        purchase_order_no = await GeneratePONo();
                        setPO_master_transactionID(0);
                        setPODate(today);
                        setPO_status('P');
                        setScheduleDate(defaultExtpScheduleDate);
                        setApproved_by_id('');
                        setApproved_date('');
                        setAgentPaidStatus('P');
                        setPO_acceptance_status('P');
                        setStatus_remark('');
                        setPO_mail_sent_status('P');
                        $('#add-material-id').show();
                    }
                    setPOno(purchase_order_no)


                    if (responce.PurchaseOrderTaxSummary) {
                        debugger
                        const taxSummary = responce.PurchaseOrderTaxSummary.map(object => ({
                            product_material_id: object.product_material_id,
                            so_sr_no: parseInt(object.so_sr_no, 10), // Ensure base 10 parsing
                            indent_no: object.indent_no,
                            hsn_sac_code: object.hsn_sac_code,
                            hsn_code_id: object.hsn_code_id,
                            hsn_sac_rate: object.hsn_sac_rate,
                            material_taxable_amount: object.summary_taxable_amount,
                            material_cgst_percent: object.summary_cgst_percent,
                            material_cgst_total: object.summary_cgst_total,
                            material_sgst_percent: object.summary_sgst_percent,
                            material_sgst_total: object.summary_sgst_total,
                            material_igst_percent: object.summary_igst_percent,
                            material_igst_total: object.summary_igst_total,
                            material_total_amount: object.summary_total_amount
                        }));

                        setTaxationSummaryData(taxSummary);
                    }

                    // Function to update keys dynamically 
                    const updateKeys = (object) => {

                        console.log('purchase details: ', object);
                        object.product_id = object.product_material_id;
                        object.prev_indent_quantity = object.product_material_po_quantity;
                        object.prev_indent_weight = object.product_material_po_weight;
                        // object.available_stock_quantity = object.purchase_order_type_id === '2' ? object.available_Rawmaterial_stock_quantity : object.available_stock_quantity;
                        // object.available_stock_weight = object.purchase_order_type_id === '2' ? object.available_Rawmaterial_stock_weight : object.available_stock_weight;
                        console.log('available_stock_quantity: ', object.available_stock_quantity);
                        console.log('available_stock_weight: ', object.available_stock_weight);
                        if (keyForViewUpdate !== 'copy') {
                            if (creation_type === 'A') {
                                // setselectIndentNo((prevOptions) => prevOptions + ':' + object.indent_no);
                                object.indent_nos = object.indent_no.split(':');
                            }
                        }

                        const updatedObject = {};
                        for (const key in object) {
                            if (key === 'product_material_code') {
                                updatedObject['product_rm_code'] = object[key]
                            }
                            if (key === 'product_material_id') {
                                updatedObject[`product_rm_id`] = object[key];

                            } else if (key === 'product_material_packing_id') {
                                updatedObject[`product_rm_packing_id`] = object[key];

                            } else if (key === 'product_material_po_quantity') {
                                updatedObject[`product_rm_quantity`] = object[key];

                            } else if (key === 'product_material_std_weight') {
                                updatedObject[`product_rm_std_weight`] = object[key];

                            } else if (key === 'product_material_conversion_factor') {
                                updatedObject[`product_rm_conversion_factor`] = object[key];

                            } else if (key === 'product_material_name') {
                                updatedObject[`product_rm_name`] = object[key];

                            } else if (key === 'product_material_tech_spect') {
                                updatedObject[`product_rm_tech_spect`] = object[key];

                            } else if (key === 'product_material_hsn_code_id') {
                                updatedObject[`product_rm_hsn_sac_code_id`] = object[key];

                            }
                            else if (key === 'hsn_sac_code') {
                                updatedObject[`product_rm_hsn_sac_code`] = object[key];

                            }
                            else if (key === 'hsn_sac_rate') {
                                updatedObject[`product_rm_hsn_sac_rate`] = object[key];

                            } else if (key === 'material_rate') {
                                updatedObject[`product_rm_rate`] = object[key];
                            }
                            else if (key === 'product_material_po_approved_quantity') {
                                updatedObject[`product_rm_approved_quantity`] = object[key];

                            } else if (key === 'product_material_po_approved_weight') {
                                updatedObject[`product_rm_approved_weight`] = object[key];
                            }
                            else if (key === 'product_material_po_rejected_quantity') {
                                updatedObject[`product_rm_rejected_quantity`] = object[key];

                            } else if (key === 'product_material_po_rejected_weight') {
                                updatedObject[`product_rm_rejected_weight`] = object[key];

                            } else if (key === 'product_material_unit_id') {
                                updatedObject[`product_rm_stock_unit_id`] = object[key];

                            } else if (key === 'product_material_stock_unit_name') {
                                updatedObject[`product_rm_stock_unit_name`] = object[key];

                            } else if (key === 'product_material_po_weight') {
                                updatedObject[`product_rm_weight`] = object[key];

                            } else if (key === 'product_packing_name') {
                                updatedObject[`product_rm_packing_name`] = object[key];
                            } else if (key === 'purchase_order_type_id') {
                                updatedObject[`product_type_id`] = object[key];
                            }

                            else {
                                updatedObject[`${key}`] = object[key];
                            }
                        }
                        return updatedObject;
                    };
                    if (responce.PurchaseOrderDetailsRecords != null) {

                        let PODetailsDataArray = responce.PurchaseOrderDetailsRecords.map(updateKeys);
                        // Function to handle updates based on the keyForViewUpdate
                        const updatePODetails = (keyForViewUpdate, updatedPODetailsDataArray, purchase_order_no, defaultExtpScheduleDate) => {
                            switch (keyForViewUpdate) {
                                case 'approve':
                                    return updatedPODetailsDataArray.map(rowData => ({
                                        ...rowData,
                                        approved_date: today(),
                                        purchase_order_item_status: 'A',
                                        product_rm_approved_quantity: rowData.product_rm_quantity,
                                        product_rm_approved_weight: rowData.product_rm_weight,
                                    }));

                                case 'cancel':
                                    return updatedPODetailsDataArray.map(rowData => ({
                                        ...rowData,
                                        purchase_order_item_status: 'X',
                                    }));

                                case 'copy':
                                    return updatedPODetailsDataArray.map(ordDetail => ({
                                        ...ordDetail,
                                        purchase_order_item_status: 'P',
                                        purchase_order_master_transaction_id: 0,
                                        purchase_order_details_transaction_id: 0,
                                        purchase_order_no: purchase_order_no,
                                        purchase_order_date: today(),
                                        purchase_order_version: 1,
                                        material_schedule_date: defaultExtpScheduleDate,
                                        indent_no: '',
                                        indent_date: '',
                                        indent_version: '',
                                        customer_id: '',
                                        customer_order_no: '',
                                        customer_order_Date: '',
                                        product_rm_approved_quantity: 0,
                                        product_rm_approved_weight: 0,
                                        material_po_approval_remark: '',
                                        approved_date: '',
                                    }));
                                case 'update':
                                    return updatedPODetailsDataArray.map(rowData => ({
                                        ...rowData,
                                        purchase_order_item_status: 'P',
                                    }));
                                    break;

                                default:
                                    // Handle unexpected keyForViewUpdate values if necessary
                                    return updatedPODetailsDataArray;
                            }
                        };

                        // Update PODetailsDataArray based on keyForViewUpdate
                        const updatedPODetailsDataArray = updatePODetails(keyForViewUpdate, PODetailsDataArray, purchase_order_no, defaultExtpScheduleDate);
                        FnUpdateTotalPODetailsArr(updatedPODetailsDataArray)
                    }

                    if (isApprove) {
                        $(`#product_rm_approved_quantity_${responce.PurchaseOrderDetailsRecords.product_rm_id}`).focus();
                    }

                    if (responce.PurchaseOrderProductParametersRecords.length !== 0) {
                        setProductTypePropertiesData(responce.PurchaseOrderProductParametersRecords);
                    }

                    if (responce.PurchaseOrderPaymentTerms) {

                        responce.PurchaseOrderPaymentTerms.forEach(function (existingPayTerm) {
                            $('#selectPayTerm_' + existingPayTerm.payment_terms_id).prop('checked', true);
                        });
                    }

                }

                console.log("Response for existing PO data for update: ", responce);
            }
        } catch (error) {
            console.log("error in getting existing PO data for update: ", error)
            navigate('/Error')
        }
    }



    const refreshData = async (key) => {
        switch (key) {
            case 'get_supplier':
                // get suppliers list with default state & city
                resetGlobalQuery();
                globalQuery.columns.push("supp_branch_id AS field_id");
                globalQuery.columns.push("supplier_id");
                globalQuery.columns.push("supp_branch_name AS field_name");
                globalQuery.columns.push("supp_branch_state_id");
                globalQuery.columns.push("supp_branch_city_id");
                globalQuery.columns.push("supp_branch_gst_no");
                globalQuery.table = "cm_supplier_branch";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const suppliersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                //  ** Changes for the Searchable Combo
                const suppliers = [
                    // { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                    ...suppliersApiCall.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, supplier_id: supplier.supplier_id })),
                ];
                setSupplierOptions(suppliers);
                break;

            case 'get_agent':
                // for agents List
                const agentApiCall = await comboDataAPiCall.current.fillMasterData("cmv_agent", "", "");
                setAgents(agentApiCall)
                break;
            case 'get_expectedBranch':
                //get expected branch list
                resetGlobalQuery();
                globalQuery.columns.push("company_branch_id");
                globalQuery.columns.push("company_branch_name");
                globalQuery.columns.push("branch_state_id");
                globalQuery.columns.push("branch_city_id");
                globalQuery.columns.push("is_sez");
                globalQuery.columns.push("branch_EmailId");
                globalQuery.table = "cmv_company_branch";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const exptBranchApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setExpectedBranchOptions(exptBranchApiCall);
                break;
            case 'get_cost_center':
                //get cost_center list
                const getCostCenters = await comboDataAPiCall.current.fillMasterData("fmv_cost_center", "", "");
                const costCenterList = [
                    { value: '', label: 'Select', field_id: '', field_name: '' },
                    ...getCostCenters.map((costCenter) => ({ ...costCenter, value: `${costCenter.field_id}`, label: costCenter.field_name })),
                ];
                setCostCenterOptions(costCenterList);
                break;

            case 'get_hsn_codes':
                //get hsn codes list
                resetGlobalQuery();
                globalQuery.columns = ["field_name", "field_id", "hsn_sac_rate", "hsn_sac_code"];
                globalQuery.table = "cmv_hsn_sac";
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                const getHsnCode = comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                const hsnCodeList = [
                    { value: '', label: 'Select', field_id: '', field_name: '' },
                    { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                    ...getHsnCode.map((hsnCode) => ({ ...hsnCode, value: hsnCode.field_id, label: hsnCode.field_name, product_rm_hsn_sac_rate: hsnCode.hsn_sac_rate, product_rm_hsn_sac_code: hsnCode.hsn_sac_code })),
                ];
                setHsnCodesOptions(hsnCodeList)

                break;
            default:
                break;
        }
    }


    // --------------------------------------- PO Printout generate Start ------------------------------------------------------------------

    const purchaseOrderInvoiceContent = {
        supplierDetails: {
            name: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            schedule_date: '',
            city: '',
        },

        context: 'Bales',

        consigneeDetails: {
            consigneename: '',
            consigneeaddress: '',
            consigneestate: '',
            consigneecontact: '',
            consigneecity: '',
        },

        customerDetails: {
            customer: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            email: ''
        },
        purchaseDetails: {
            purchase_order_no: '',
            purchase_order_date: '',
            purchase_order_version: '',
            purchase_order_type: '',
            purchase_order_status: '',
            approved_by_name: ''
        },

        ourdetails: {
            expected_branch_name: '',
            expected_schedule_date: '',
            expected_branch_city_name: '',
            expected_branch_state_name: '',
            expected_branch_phone_no: '',
        },

        footer: {
            cgst_words: '',
            sgst_words: '',
            igst_words: '',
            grand_total_words: '',

            basic_total: '',
            cgst_total: '',
            sgst_total: '',
            igst_total: '',
            taxable_total: '',
            grand_total: '',
            packing_amount: '',
            discount_percent: '',
            discount_amount: '',
            freight_amount: '',
            other_amount: '',
            remark: '',

        },
        rawCottonPurchaseDetails: {
            gate_pass_no: '',
            gate_pass_date: '',
            vehicale_type: '',
            vehicale_no: '',
            gross_weight: '',
            tare_weight: '',
            weight_difference: '',
            net_weight: '',
            grader_by_name: '',
            created_by_name: '',
        },

        items: [],
        paymentTerms: [],
        PurchaseOrderTerms: [],
        contactDetails: [],
        rawCottonDeductionData: [],
        scheduleDetails: [],

    };

    const printInvoice = async (openPrintModal, isWaterMark) => {
        debugger
        //Purchase details
        purchaseOrderInvoiceContent.purchaseDetails.purchase_order_no = PurchaseOrderMasterJson.purchase_order_no
        purchaseOrderInvoiceContent.purchaseDetails.purchase_order_date = validateNumberDateInput.current.formatDateToDDMMYYYY(PurchaseOrderMasterJson.purchase_order_date)
        purchaseOrderInvoiceContent.purchaseDetails.purchase_order_version = PurchaseOrderMasterJson.purchase_order_version
        purchaseOrderInvoiceContent.purchaseDetails.purchase_order_type = PurchaseOrderMasterJson.purchase_order_creation_type_desc
        // purchaseOrderInvoiceContent.purchaseDetails.purchase_iso_no = "ISO123456789";
        purchaseOrderInvoiceContent.purchaseDetails.purchase_order_status = PurchaseOrderMasterJson.purchase_order_status_desc
        purchaseOrderInvoiceContent.purchaseDetails.approved_by_name = PurchaseOrderMasterJson.approved_by

        // quotationDetails
        // purchaseOrderInvoiceContent.quotationDetails.quotation_no = PurchaseOrderMasterJson.quotation_no
        // purchaseOrderInvoiceContent.quotationDetails.quotation_date = validateNumberDateInput.current.formatDateToDDMMYYYY(PurchaseOrderMasterJson.quotation_date)

        //supplier details
        purchaseOrderInvoiceContent.supplierDetails.name = PurchaseOrderMasterJson.supplier_name
        purchaseOrderInvoiceContent.supplierDetails.address = PurchaseOrderMasterJson.supp_branch_address1
        purchaseOrderInvoiceContent.supplierDetails.state = PurchaseOrderMasterJson.state_name
        purchaseOrderInvoiceContent.supplierDetails.city = $("#cmb_supplier_city_id option:selected").text();

        const findSupplier = supplierOptions.find(supplier => supplier.field_id === PurchaseOrderMasterJson.supplier_id);
        if (findSupplier) {
            const suppBranchGstNo = findSupplier.supp_branch_gst_no;
            console.log(suppBranchGstNo);
            purchaseOrderInvoiceContent.supplierDetails.GSTIN = suppBranchGstNo === undefined || suppBranchGstNo === null ? "" : suppBranchGstNo
        } else {
            console.log('Supplier not found.');
        }
        purchaseOrderInvoiceContent.supplierDetails.contact = PurchaseOrderMasterJson.supp_branch_phone_no === undefined || PurchaseOrderMasterJson.supp_branch_phone_no === null ? "" : PurchaseOrderMasterJson.supp_branch_phone_no
        purchaseOrderInvoiceContent.supplierDetails.pan_no = PurchaseOrderMasterJson.supp_branch_pan_no === undefined || PurchaseOrderMasterJson.supp_branch_pan_no === null ? "" : PurchaseOrderMasterJson.supp_branch_pan_no
        purchaseOrderInvoiceContent.supplierDetails.schedule_date = validateNumberDateInput.current.formatDateToDDMMYYYY(PurchaseOrderMasterJson.expected_schedule_date)

        //consignee details 
        purchaseOrderInvoiceContent.consigneeDetails.consigneename = PurchaseOrderMasterJson.supplier_cosnignee_name === undefined || PurchaseOrderMasterJson.supplier_cosnignee_name === null ? "" : PurchaseOrderMasterJson.supplier_cosnignee_name
        purchaseOrderInvoiceContent.consigneeDetails.consigneeaddress = PurchaseOrderMasterJson.supp_consignee_address1 === undefined || PurchaseOrderMasterJson.supp_consignee_address1 === null ? "" : PurchaseOrderMasterJson.supp_consignee_address1
        purchaseOrderInvoiceContent.consigneeDetails.consigneestate = PurchaseOrderMasterJson.supp_consignee_state_name === undefined || PurchaseOrderMasterJson.supp_consignee_state_name === null ? "" : PurchaseOrderMasterJson.supp_consignee_state_name
        purchaseOrderInvoiceContent.consigneeDetails.consigneecity = PurchaseOrderMasterJson.supp_consignee_city_name === undefined || PurchaseOrderMasterJson.supp_consignee_city_name === null ? "" : PurchaseOrderMasterJson.supp_consignee_city_name

        //outDetails
        // purchaseOrderInvoiceContent.ourdetails.expected_branch_name = PurchaseOrderMasterJson.expected_branch_name
        // purchaseOrderInvoiceContent.ourdetails.expected_schedule_date = validateNumberDateInput.current.formatDateToDDMMYYYY(PurchaseOrderMasterJson.expected_schedule_date)
        // purchaseOrderInvoiceContent.ourdetails.expected_branch_state_name = PurchaseOrderMasterJson.expected_branch_state_name
        // purchaseOrderInvoiceContent.ourdetails.expected_branch_city_name = PurchaseOrderMasterJson.expected_branch_city_name
        // purchaseOrderInvoiceContent.ourdetails.expected_branch_phone_no = PurchaseOrderMasterJson.expected_branch_phone_no


        //Customer details
        purchaseOrderInvoiceContent.customerDetails.customer = PurchaseOrderMasterJson.customer_name === undefined || PurchaseOrderMasterJson.customer_name === null ? "" : PurchaseOrderMasterJson.customer_name
        purchaseOrderInvoiceContent.customerDetails.address = PurchaseOrderMasterJson.cust_branch_address1 === undefined || PurchaseOrderMasterJson.cust_branch_address1 === null ? "" : PurchaseOrderMasterJson.cust_branch_address1
        purchaseOrderInvoiceContent.customerDetails.state = PurchaseOrderMasterJson.customer_state_name === undefined || PurchaseOrderMasterJson.customer_state_name === null ? "" : PurchaseOrderMasterJson.customer_state_name
        purchaseOrderInvoiceContent.customerDetails.GSTIN = PurchaseOrderMasterJson.cust_branch_gst_no === undefined || PurchaseOrderMasterJson.cust_branch_gst_no === null ? "" : PurchaseOrderMasterJson.cust_branch_gst_no
        purchaseOrderInvoiceContent.customerDetails.pan_no = PurchaseOrderMasterJson.cust_branch_pan_no === undefined || PurchaseOrderMasterJson.cust_branch_pan_no === null ? "" : PurchaseOrderMasterJson.cust_branch_pan_no
        purchaseOrderInvoiceContent.customerDetails.contact = PurchaseOrderMasterJson.cust_branch_phone_no === undefined || PurchaseOrderMasterJson.cust_branch_phone_no === null ? "" : PurchaseOrderMasterJson.cust_branch_phone_no
        purchaseOrderInvoiceContent.customerDetails.email = PurchaseOrderMasterJson.customer_email === undefined || PurchaseOrderMasterJson.customer_email === null ? "" : PurchaseOrderMasterJson.customer_email

        //footer details    
        purchaseOrderInvoiceContent.footer.currency_code = po_details_data[0].currency_code
        purchaseOrderInvoiceContent.footer.basic_total = PurchaseOrderMasterJson.basic_total
        purchaseOrderInvoiceContent.footer.cgst_total = PurchaseOrderMasterJson.cgst_total
        purchaseOrderInvoiceContent.footer.sgst_total = PurchaseOrderMasterJson.sgst_total
        purchaseOrderInvoiceContent.footer.igst_total = PurchaseOrderMasterJson.igst_total
        purchaseOrderInvoiceContent.footer.taxable_total = PurchaseOrderMasterJson.taxable_total
        purchaseOrderInvoiceContent.footer.grand_total = PurchaseOrderMasterJson.grand_total
        purchaseOrderInvoiceContent.footer.round_off = PurchaseOrderMasterJson.roundoff
        purchaseOrderInvoiceContent.footer.packing_amount = PurchaseOrderMasterJson.packing_amount
        purchaseOrderInvoiceContent.footer.freight_amount = PurchaseOrderMasterJson.freight_amount
        purchaseOrderInvoiceContent.footer.currency_symbol = $("#txt_currency_symbol").val();
        purchaseOrderInvoiceContent.footer.currency_lable = txt_currency_label;

        purchaseOrderInvoiceContent.footer.discount_percent = PurchaseOrderMasterJson.po_discount_percent
        purchaseOrderInvoiceContent.footer.discount_amount = PurchaseOrderMasterJson.po_discount_amount
        purchaseOrderInvoiceContent.footer.other_amount = PurchaseOrderMasterJson.other_amount
        purchaseOrderInvoiceContent.footer.remark = PurchaseOrderMasterJson.remark

        // po material details
        for (let index = 0; index < po_details_data.length; index++) {
            const element = po_details_data[index];
            //PO Product Properties Data
            const materialProperties = productTypePropertiesData.filter((item) => item.product_id === element.product_rm_id);
            let cotton_description = "";
            if (materialProperties.length !== 0) {
                materialProperties.forEach((item) => {
                    cotton_description += `- ${item.product_parameter_name} : ${item.product_parameter_calculation_type} - (${item.product_parameter_from_value}${item.product_parameter_to_value ? ',' + item.product_parameter_to_value : ''}) `;
                })
            }
            let additional_info = element.remark == '' || element.remark == null ? '' : `(${element.remark})`;
            const detailsData = {
                sr_no: element.sr_no,
                product_material_code: element.product_material_code,
                material_name: element.product_material_print_name === undefined || element.product_material_print_name === null ? '' : `${element.product_material_print_name} ` + `${cotton_description} ` + `${additional_info}`,
                material_tech_spect: element.product_rm_tech_spect === undefined || element.product_rm_tech_spect === null ? '' : element.product_rm_tech_spect,
                hsn_code: element.product_material_hsn_sac_code,
                quantity: element.product_rm_quantity,
                weight: element.product_rm_weight,
                unit: element.product_rm_stock_unit_name,
                rate: parseFloat(element.product_rm_rate).toFixed(AFTER_DECIMAL_PLACES),
                discount_percent: element.material_discount_percent,
                material_basic_amount: parseFloat(element.material_basic_amount).toFixed(AFTER_DECIMAL_PLACES),
                cgst_percent: element.material_cgst_percent,
                sgst_percent: element.material_sgst_percent,
                igst_percent: element.material_igst_percent,
                material_total_amount: parseFloat(element.material_total_amount).toFixed(AFTER_DECIMAL_PLACES),
                department_name: element.department_name,
                product_material_type_name: element.product_material_type_name === undefined || element.product_material_type_name === null ? '' : element.product_material_type_name,
                product_make_name: element.product_make_name === undefined || element.product_make_name === null ? '' : element.product_make_name,
                product_material_grade_name: element.product_material_grade_name === undefined || element.product_material_grade_name === null ? '' : element.product_material_grade_name,
                company: element.company_name,
                company_branch: element.company_branch_name,
                address: element.company_address1,
                phone: localStorage.getItem('stores_contact_no'),
                email: localStorage.getItem('stores_email_id'),
                website: element.company_website,
                GSTIN: element.company_gst_no,
                pan_no: element.company_pan_no,
                state_name: element.company_state,
                company_pincode: element.company_pincode,
            }
            purchaseOrderInvoiceContent.items.push(detailsData)
        }
        //Supplier Contact Details
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            let selectedContact_id = parseInt($(this).val());
            const supplierContact = suppContactDetails.find(contactPerson => contactPerson.supplier_contact_id === selectedContact_id);
            if (supplierContact) {
                const contactData = {
                    supp_contact_person: supplierContact.supp_contact_person,
                    supp_contact_no: supplierContact.supp_contact_no,
                }
                purchaseOrderInvoiceContent.contactDetails.push(contactData)
            }
        });
        //PO Payment Terms
        for (let index = 0; index < existingPaymentTermsData.length; index++) {
            const element = existingPaymentTermsData[index];
            const detailsData = {
                sr_no: index + 1,
                payment_terms_name: element.payment_terms_name,
                payment_percent: element.payment_percent,

            }
            purchaseOrderInvoiceContent.paymentTerms.push(detailsData)
        }
        //PO common Terms
        for (let index = 0; index < existingCommonTermsData.length; index++) {
            const element = existingCommonTermsData[index];
            const detailsData = {
                purchase_order_terms_parameters_name: element.purchase_order_terms_parameters_name,
                purchase_order_terms_parameters_value: element.purchase_order_terms_parameters_value,
            }
            purchaseOrderInvoiceContent.PurchaseOrderTerms.push(detailsData)
        }

        //PO Raw cotton Deduction Data
        // for (let index = 0; index < scheduleDataArray.length; index++) {
        //     const scheduleItem = scheduleDataArray[index];

        //     const scheduleData = {
        //         sr_no: index + 1,
        //         product_material_code: scheduleItem.product_material_code,
        //         product_material_name: scheduleItem.product_material_name,
        //         schedule_quantity: scheduleItem.product_material_po_schedule_quantity,
        //         schedule_date: validateNumberDateInput.current.formatDateToDDMMYYYY(scheduleItem.expected_schedule_date),
        //         remark: scheduleItem.remark
        //     }
        //     purchaseOrderInvoiceContent.scheduleDetails.push(scheduleData)
        // }

        if (openPrintModal) {
            navigate('/Invoice', { state: { invoiceData: purchaseOrderInvoiceContent, navigationLink: '/Transactions/TPurchaseOrder/ManualPO/CottonBalesPO/FrmCottonBalesEntry', invoiceType: 'PO', title: 'Purchase Order', idList: idList, keyForViewUpdate: keyForViewUpdate, modules_forms_id: parseInt(modules_forms_id) } });

        } else {
            if (isWaterMark == true) {
                // return the html code.
                const renderedHtml = renderToString(<PurchaseOrderInvoice invoiceContent={purchaseOrderInvoiceContent} isWaterMark={true} />);

                setPrintPreviewContent(renderedHtml);
                setShowPreviewModalIsOpen(true);
            } else {
                return renderToString(<PurchaseOrderInvoice invoiceContent={purchaseOrderInvoiceContent} isWaterMark={false} />);
            }
        }

    };

    // --------------------------------------- PO Printout generate End ------------------------------------------------------------------
    // --------------------------------------- PO Import Start ------------------------------------------------------------------

    const onImportClick = () => {
        importFile.current.click();
    };

    const importedExcelFile = async (event) => {
        try {
            if (event.target.files && event.target.files.length > 0) {
                setIsLoading(true)
                var file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();

                console.log(fetchRes)
                if (fetchRes.success === 1) {
                    if (fetchRes.data.length !== 0) {
                        let creation_type = '';
                        // Set order details data.
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            if (isNaN(parseFloat(orderDetail.product_rm_quantity))) {
                                orderDetail.product_rm_quantity = 0;
                            }
                            return orderDetail;
                        });

                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                let comboVal;
                                if (fieldVal !== "" && fieldName === 'Po Creation Type' || fieldName === 'Po Life' || fieldName === 'Company city' || fieldName === 'Product Type' || fieldName === 'Supplier' || fieldName === 'Purchase Process' || fieldName === 'Supplier State' || fieldName === 'Company Branch' || fieldName === 'Department' || fieldName === 'Company Branch' || fieldName === 'Supplier City' || fieldName === 'Company Branch State' || fieldName === 'Mail Status') {
                                    if (fieldVal !== "") {
                                        const splitVal = fieldVal.split('(')
                                        comboVal = splitVal[1].replace(')', '')
                                    }
                                }
                                switch (fieldName) {
                                    case 'Po Creation Type':
                                        setPO_creation_type(comboVal)
                                        creation_type = comboVal;
                                        if (comboVal === 'A') {
                                            $('#add-material-id').hide();
                                        } else {
                                            $('#add-material-id').show();
                                        }
                                        break;
                                    case 'Po Life':
                                        setPO_life(comboVal)
                                        break;
                                    case 'Company city':
                                        setExpected_branch_city_id(comboVal)
                                        break;
                                    case 'Product Type':
                                        setProductTypeID(comboVal)
                                        comboOnChange('productType');
                                        break;
                                    case 'Supplier':
                                        setSupplier_id(comboVal)
                                        comboOnChange('supplier')
                                        break;
                                    case 'Purchase Process':
                                        setPurchase_process(comboVal)
                                        break;
                                    case 'Po Status':
                                        setPO_status("P")
                                        break;
                                    case 'Department':
                                        setDepartmentId(comboVal)
                                        break;
                                    case 'Company Branch':
                                        setExpected_branch_id(comboVal)
                                        comboOnChange('expt_branch')
                                        break;
                                    case 'Status Remark':
                                        setStatus_remark(fieldVal)
                                        break;
                                    case 'Mail Status':
                                        setPO_mail_sent_status("P")
                                        break;
                                }
                            }
                        }

                        // setPOVersion(fetchRes.Purchase_order_version)

                        let modifiedImportData = readedOrderDetailsFromExcel.map((ordDetail) => ({
                            ...ordDetail,
                            purchase_order_item_status: 'P',
                            product_rm_id: ordDetail.product_rm_id,
                            purchase_order_master_transaction_id: 0,
                            purchase_order_details_transaction_id: 0,
                            indent_nos: creation_type === 'A' ? ordDetail.indent_no.split(':') : null,
                            prev_indent_quantity: ordDetail.product_rm_quantity,
                            prev_indent_weight: ordDetail.product_rm_weight,
                        }))

                        modifiedImportData = modifiedImportData.map(handleMaterialWiseCalculations)

                        FnUpdateTotalPODetailsArr(modifiedImportData)
                        calculateGrandTotal();

                        categoryDisplay();

                        // if (creation_type === 'M') {
                        //     setTimeout(() => {
                        //         importedData.forEach((item) => {
                        //             if (item.customer_id !== null && item.customer_id !== '') {
                        //                 FngetCustOrdNos(item, creation_type)
                        //             }
                        //         });
                        //     }, 200);
                        // } else {
                        // importedData.forEach((item) => {
                        //     if (item.customer_id !== null && item.customer_id !== '') {
                        //         FnshowIndentCustOrdDt(item)
                        //         isCustCheckBoxChk(item.customer_id.split(':'), item.product_rm_id);
                        //     } else {
                        //         setCheckCustomerLenght(0);
                        //     }
                        // })

                        // importedData.forEach((item) => {

                        //     if (item.cost_center_id !== null && item.cost_center_id !== "") {
                        //         isCostCenterCheckBoxChk(item.cost_center_id.split(':'), item.product_rm_id);
                        //     } else {
                        //         setCheckCostCenterLenght(0);
                        //     }
                        // })
                        // }
                        setIsLoading(false)
                        return fetchRes;
                    }
                } else {
                    setErrMsg(fetchRes.error)
                    setIsLoading(false)
                    setShowErrorMsgModal(true)
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    // --------------------------------------- PO Import End ------------------------------------------------------------------

    // --------------------------------------- PO EXcelToExport Start ------------------------------------------------------------------
    let requiredColsForExport = ['sr_no', 'product_type_id', 'product_type_name', 'product_rm_id', 'product_rm_name', 'product_rm_tech_spect', 'product_rm_hsn_sac_code', 'product_rm_quantity', 'product_rm_rate',
        'material_basic_amount', 'material_discount_percent', 'material_discount_amount',
        'material_taxable_amount', 'material_cgst_percent', 'material_cgst_total', 'material_sgst_percent',
        'material_sgst_total', 'material_igst_percent', 'material_igst_total', 'material_total_amount', 'product_rm_weight', 'material_freight_amount', 'material_schedule_date',
        'product_rm_approved_quantity', 'product_rm_approved_weight', 'product_rm_rejected_quantity', 'product_rm_rejected_weight', 'material_po_approval_remark'
        , 'department_id', 'indented_by_id', 'indent_date', 'indent_no', 'indent_version', 'approved_by_id', 'approved_date', 'product_rm_std_weight', 'product_rm_conversion_factor', 'lead_time', 'product_rm_stock_unit_name', 'product_rm_packing_name', 'product_rm_stock_unit_id',
        'product_rm_packing_id', 'product_rm_hsn_sac_code_id', 'product_rm_hsn_sac_rate', 'purchase_order_item_status',
        'pree_closed_quantity', 'pree_closed_weight', 'purchase_return_quantity', 'purchase_return_weight', 'customer_id', 'customer_order_no', 'customer_order_Date',
        'pending_quantity', 'pending_weight', 'excess_quantity', 'excess_weight', 'production_issue_quantity', 'production_issue_weight',
        'production_issue_return_quantity', 'production_issue_return_weight', 'production_issue_rejection_quantity', 'production_issue_rejection_weight',
        'assembly_production_issue_quantity', 'assembly_production_issue_weight', 'sales_quantity', 'sales_weight',
        'sales_return_quantity', 'sales_return_weight', 'sales_rejection_quantity', 'sales_rejection_weight', 'transfer_issue_quantity',
        'transfer_issue_weight', 'transfer_receipt_quantity', 'transfer_receipt_weight', 'outsources_out_quantity', 'outsources_out_weight',
        'outsources_in_quantity', 'outsources_in_weight', 'outsources_rejection_quantity', 'outsources_rejection_weight', 'loan_receipt_quantity',
        'loan_receipt_weight', 'loan_issue_quantity', 'loan_issue_weight', 'cancel_quantity', 'cancel_weight', 'difference_quantity',
        'difference_weight',
    ];
    const ExportToExcel = () => {

        const catCount = sessionStorage.getItem('compRMCatCount')
        var counter = 1;
        if (po_details_data.length !== 0) {
            let selectedSuppId = supplierComboRef.current.value || '';

            var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [7, 8, 10] }   // [5, 6, 8] 
            jsonToExportExcel.filtrKeyValue['0'] = "Po Creation Type" + ' : ' + $('input[name="chk_po_creation_type"]:checked').attr("lbl") + "(" + chk_po_creation_type + ")";
            // jsonToExportExcel.filtrKeyValue['1'] = "Product Type" + ' : ' + $('#cmb_product_type_id').find(":selected").text() + "(" + cmb_product_type_id + ")";
            jsonToExportExcel.filtrKeyValue['1'] = "Purchase Order No" + ' : ' + txt_PO_no;
            jsonToExportExcel.filtrKeyValue['2'] = "Purchase Order Version" + ' : ' + txt_po_version;
            jsonToExportExcel.filtrKeyValue['3'] = "Purchase Order Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_po_date);
            // jsonToExportExcel.filtrKeyValue['4'] = "Supplier" + ' : ' + $('#cmb_supplier_id').find(":selected").text() + "(" + cmb_supplier_id + ")";
            jsonToExportExcel.filtrKeyValue['4'] = "Supplier" + ' : ' + (selectedSuppId !== "" && selectedSuppId !== '0' && selectedSuppId !== null ? supplierComboRef.current?.label + "(" + supplierComboRef.current?.value + ")" : '');
            jsonToExportExcel.filtrKeyValue['5'] = "Supplier State" + ' : ' + $('#cmb_supplier_state_id').find(":selected").text() + "(" + cmb_supplier_state_id + ")";
            jsonToExportExcel.filtrKeyValue['6'] = "Supplier City" + ' : ' + $('#cmb_supplier_city_id').find(":selected").text() + "(" + cmb_supplier_city_id + ")";
            jsonToExportExcel.filtrKeyValue['7'] = "Po Life" + ' : ' + $('input[name="chek_po_life"]:checked').attr("lbl") + "(" + chek_po_life + ")";
            jsonToExportExcel.filtrKeyValue['8'] = "Purchase Process" + ' : ' + $('input[name="chk_purchase_process"]:checked').attr("lbl") + "(" + chk_purchase_process + ")";
            jsonToExportExcel.filtrKeyValue['9'] = "Company Branch" + ' : ' + $('#cmb_expected_branch_id').find(":selected").text() + "(" + cmb_expected_branch_id + ")";
            jsonToExportExcel.filtrKeyValue['10'] = "Company Branch State" + ' : ' + $('#cmb_expected_branch_state_id').find(":selected").text() + "(" + cmb_expected_branch_state_id + ")";
            jsonToExportExcel.filtrKeyValue['11'] = "Company city" + ' : ' + $('#cmb_expected_branch_city_id').find(":selected").text() + "(" + cmb_expected_branch_city_id + ")";
            jsonToExportExcel.filtrKeyValue['12'] = "Approved By : " + (cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")");
            jsonToExportExcel.filtrKeyValue['13'] = "Department : " + $('#cmb_department').find(":selected").text() + "(" + cmb_department + ")";
            jsonToExportExcel.filtrKeyValue['14'] = "Approved Date : " + (txt_approved_date === null || txt_approved_date === '' ? "" : validateNumberDateInput.current.formatDateToDDMMYYYY(txt_approved_date));
            jsonToExportExcel.filtrKeyValue['15'] = "Expected Schedule Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_schedule_date);
            jsonToExportExcel.filtrKeyValue['16'] = "Po Status : " + $('#chk_po_status').find(":selected").text() + "(" + chk_po_status + ")";
            jsonToExportExcel.filtrKeyValue['17'] = "Status Remark" + ' : ' + txt_status_remark;
            jsonToExportExcel.filtrKeyValue['18'] = "Mail Status" + ' : ' + $('input[name="chk_po_mail_sent_status"]:checked').attr("lbl") + "(" + chk_po_mail_sent_status + ")";

            for (let col = 0; col < requiredColsForExport.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": requiredColsForExport[col], "accessor": requiredColsForExport[col] })
            }

            po_details_data.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    sr_no: Index + 1,
                    department_id: cmb_department,
                    // godown_name: $(`#godown_id_${orderDetail.product_rm_id}`).find(":selected").text(),
                    // godown_section_name: $(`#godown_section_id_${orderDetail.product_rm_id}`).find(":selected").text(),
                    // godown_section_beans_name: $(`#godown_section_beans_id_${orderDetail.product_rm_id}`).find(":selected").text(),
                };
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            });
            jsonToExportExcel['headings']['ReportName'] = "Purchase Order"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_PO_no + "@" + COMPANY_ID)
        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
    }
    // --------------------------------------- PO EXcelToExport End ------------------------------------------------------------------
    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async () => {


        await FnShowPaymentTermsRecrds();
        await showDocumentRecords();

        if (keyForViewUpdate === "view" || keyForViewUpdate === "approve" || keyForViewUpdate === "delete") {
            $('input[type="checkbox"]').prop('disabled', true);
        }
    }

    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    //--------------------------------- Document List Starts--------------------------------------------------

    // Fn for get document list
    const showDocumentRecords = async () => {
        debugger
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${documentGroup}&group_id=${txt_PO_no !== 0 ? txt_PO_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    const filteredData = resp.content.filter(item => 
                        !( keyForViewUpdate === "" || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve') ? item.is_active : resp.content
                     );
                     setDocumentData(filteredData);
                     }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">File Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">
                                    <MDTypography
                                        component="label"
                                        className="erp-form-label"
                                        variant="button"
                                        id="logoFile"
                                        fontWeight="regular"
                                        color="info"
                                        onClick={() => fetchDocument(docItem)}
                                        style={{ cursor: 'pointer', color: '#0000FF' }} // Change cursor to pointer to indicate clickable
                                        onMouseEnter={(e) => e.target.style.color = '#0000FF'} // Change color on hover
                                        onMouseLeave={(e) => e.target.style.color = '#0000FF'} // Restore original color when not hovering
                                    >
                                        {docItem.file_name}
                                    </MDTypography>
                                </td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);



    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {

            const formData = new FormData()
            const data = {
                document_group: docItem.document_group,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }
    //--------------------------------- Document List Ends------------------------------------------------------------------------------------------------
    //--------------------------------- Delete PO Starts------------------------------------------------------------------------------------------------

    async function deletePO() {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnDeleteRecord/${idList.purchase_order_version}/${COMPANY_ID}?purchase_order_no=${idList.purchase_order_no}&user_name=${UserName}`, method)
            const responce = await deleteApiCall.json();
            setShow(false)
            console.log("PO Transaction Deleted: ", responce);
            if (responce.success == '1') {
                setShow(false)
                moveToListing();
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }
    //--------------------------------- Delete PO Ends------------------------------------------------------------------------------------------------

    //--------------------------------- Material Properties Ends------------------------------------------------------------------------------------------------

    // po for NA product Type details accordian
    const renderPoDetailsAccordian = () => {
        return (
            <>
                <hr />
                <Accordion defaultActiveKey="1" activeKey={openPODetailsAccord ? '0' : null} >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={(e) => setOpenPODetailsAccord(!openPODetailsAccord)} className="erp-form-label-md">PO Details</Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex row">

                                <>
                                    <div className="col-md-4 col-12">
                                        <div className={`row ${(keyForViewUpdate === '' || keyForViewUpdate === 'copy' || keyForViewUpdate === 'update') && chk_po_creation_type === 'M' ? 'display' : 'd-none'}`}>
                                            <div className="col-md-10 col-12">
                                                <CottonBalesSearchBar searchKeyword={handleSearch} queryparams={true} distinctProductMaterialIds={distinctMaterialIds} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-md-2 col-12 `}>
                                        <MDButton type="button" id="export-btn" className={`erp-gb-button  erp_MLeft_btn ${(keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete') ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={ExportToExcel}>Export</MDButton>
                                        <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                        <MDButton type="button" id="import-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={onImportClick} >Import</MDButton>
                                    </div>
                                </>



                            </div>
                            <div className="erp-Mt-10">
                                {po_details_data.length !== 0 ?
                                    <>
                                        {renderPODetailsTbl}
                                    </> :
                                    null
                                }
                                <hr />
                                <Accordion defaultActiveKey="1" >
                                    <Accordion.Item eventKey="0" >
                                        <Accordion.Header className="erp-form-label-md">Product Based Properties</Accordion.Header>
                                        <Accordion.Body>
                                            {productTypePropertiesData.length !== 0 ? (<>
                                                <div className="table-responsive erp_table_scroll">
                                                    {renderProductPropertiesTbl}
                                                </div>
                                            </>
                                            ) : (
                                                <div className='row text-center'>
                                                    <div className="col-12">
                                                        <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                            No Records Found...!
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>


                                <Accordion defaultActiveKey="1" >
                                    <Accordion.Item eventKey="0" >
                                        <Accordion.Header className="erp-form-label-md">Taxation Summary</Accordion.Header>
                                        <Accordion.Body>
                                            {taxationSummaryData.length !== 0 ? (<>
                                                <div className="table-responsive erp_table_scroll">
                                                    {renderPOTaxationSummaryTable}
                                                </div>
                                            </>
                                            ) : (
                                                <div className='row text-center'>
                                                    <div className="col-12">
                                                        <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                            No Records Found...!
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>


                                {/* {keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ?
                                    <>
                                        <hr />
                                        <Accordion defaultActiveKey="1" >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="erp-form-label-md">Track Details</Accordion.Header>
                                                <Accordion.Body className="p-0">
                                                    <div className="table-responsive erp_table_scroll">
                                                        {renderPOTrackDetailsTbl}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </> : null
                                } */}

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>
        );
    }


    //------------------------------------------------------ Ginning Proccess starts (Raw Cotton Purchase)--------------------------------------------------------------

    //Fn to render RawCotton Purchase Deduction details table
    const renderRawCottonPurchaseDeductionTbl = useMemo(() => {
        return <>
            <Table className="erp_table" id='cotton_purchase_deduction_table_id' responsive bordered striped erp_table_scroll >
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Deduction Name</th>
                        <th className="erp_table_th">Percent (%)</th>
                        <th className="erp_table_th">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {rawCottonPurchaseDeductionData.map((deductionItem, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td">{deductionItem[`deduction${index + 1}_name`]}</td>
                            <td className="erp_table_td">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 " Headers='deduction_percent'
                                    id={`deduction_percent_${index}`}
                                    // value={deductionItem.deduction${index + 1}_percent} // Use a function to get the input value based on propertyName
                                    value={deductionItem[`deduction${index + 1}_percent`]} // Use a function to get the input value based on propertyName
                                    onChange={(e) => { upateDeductionRow(deductionItem, e); }}
                                />
                            </td>
                            <td className="erp_table_td">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0" Headers='deduction_amount'
                                    id={`deduction_amount_${index}`}
                                    value={deductionItem[`deduction${index + 1}_amount`]} // Use a function to get the input value based on propertyName
                                    onChange={(e) => { upateDeductionRow(deductionItem, e); }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [rawCottonPurchaseDeductionData, txt_basic_total]);

    const upateDeductionRow = (deductionRow, e) => {
        const arrayIndex = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
        let focusedInputField = document.querySelector('#' + e.target.id);
        let clickedColName = e.target.getAttribute('Headers');


        if (clickedColName === "deduction_percent") {
            const percent = parseFloat(e.target.value) || 0;
            if (txt_basic_total !== "" && txt_basic_total !== "0") {
                const amount = (percent / 100) * txt_basic_total;
                if (amount <= txt_basic_total) {
                    delete focusedInputField.parentElement.dataset.tip;
                    deductionRow[`deduction${arrayIndex + 1}_amount`] = validateNumberDateInput.current?.decimalNumber(amount, 4);
                    deductionRow[`deduction${arrayIndex + 1}_percent`] = validateNumberDateInput.current?.decimalNumber(percent, 2);
                } else {
                    focusedInputField.parentElement.dataset.tip = "Please enter valid input...!";
                }
            } else {
                deductionRow[`deduction${arrayIndex + 1}_percent`] = "0";
                deductionRow[`deduction${arrayIndex + 1}_amount`] = "0";
            }

        } else if (clickedColName === "deduction_amount") {
            const amount = parseFloat(e.target.value) || 0;
            if (txt_basic_total !== "" && txt_basic_total !== "0" && amount <= txt_basic_total) {
                delete focusedInputField.parentElement.dataset.tip;
                const percent = (amount * 100) / txt_basic_total;
                deductionRow[`deduction${arrayIndex + 1}_percent`] = validateNumberDateInput.current?.decimalNumber(percent, 2);
                deductionRow[`deduction${arrayIndex + 1}_amount`] = validateNumberDateInput.current?.decimalNumber(amount, 4);
            } else {
                deductionRow[`deduction${arrayIndex + 1}_percent`] = "0";
                deductionRow[`deduction${arrayIndex + 1}_amount`] = "0";
                focusedInputField.parentElement.dataset.tip = "Please enter valid input...!";
            }

        }
        const tblRows = [...rawCottonPurchaseDeductionData]
        tblRows[arrayIndex] = deductionRow
        setRawCottonPurchaseDeductionData(tblRows);                        // Set that updated data in table data hook;
        calculateGrandTotal();
    }


    const FnCalculateRawCottonSum = (onchangeQuantity, onchangeIndex) => {
        if (onchangeQuantity !== "" && onchangeQuantity !== "0") {
            if (po_details_data.length !== 0 && txt_net_weight !== 0) {
                let sum_material_quantity = parseFloat(po_details_data.reduce((total, item, index) => {
                    if (index === onchangeIndex) {
                        return total + parseFloat(onchangeQuantity);
                    }
                    return total + parseFloat(item.product_rm_quantity);
                }, 0));
                return sum_material_quantity;
            } else {
                return 0;
            }
        }
    }


    //---------------------------------------------------------------( Ginning Raw Cotton Purchase )----------------------------------------------------
    const renderGeneralFooterAccordian = () => {
        return (<>
            <hr />
            {/* footer Section Starts*/}
            <div className="row">
                <div className='main_heding text-start'>
                    <label className='erp-form-label-lg'>Purchase Order Totals:</label>
                </div>
                <form id="po_footer_formId">
                    <div className="row">
                        {/* //first column */}
                        <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Basic Total</Form.Label>
                                </div>
                                <div className='col'>
                                    <Form.Control type="text" id="txt_basic_total" className="erp_input_field text-end" value={txt_basic_total} disabled onChange={(e) => setBasic_total(e.target.value)} />
                                    <MDTypography variant="button" id="error_txt_basic_total" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className="row d-none">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Transport Amount</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_transportAmount' className="erp_input_field text-end" value={txt_transportAmount} maxLength={19} onChange={(e) => { setTransportAmount(validateNumberDateInput.current?.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }}
                                        onBlur={(e) => { setTransportAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }} />
                                    <MDTypography variant="button" id="error_txt_transportAmount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Freight Percent & Amount</Form.Label>
                                </div>
                                <div className="col">
                                    {/* <div className="row">
                                        <div className='col'> */}
                                    <Form.Control type="number" id="txt_Freight_percent" className="erp_input_field text-end" value={txt_Freight_percent}
                                        onChange={(e) => { if (e.target.value === '') { setFreightPercent(e.target.value); } if (validatePoDetails(e, 'PercentageInput')) { handleFooterFreightPercentChange(e.target.value); } }} />
                                    <MDTypography variant="button" id="error_txt_po_packing_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount} maxLength={19}
                                        onChange={(e) => { setFreightAmount(validateNumberDateInput.current?.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { handleFooterFreightAmountChange(e.target.value); } }} />
                                    <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                                {/* <div className="col-12 col-md-3">
                                            <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount} maxLength={19}
                                                onChange={(e) => { setFreightAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(); reverseCalculationForFrieghtAmt(); }} />
                                            <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div> */}
                                {/* </div>*/}
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label"></Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Check className="erp-form-label" type="checkbox" label={`IsTaxable`} id="chk_is_freight_taxable" checked={chk_is_freight_taxable} onChange={(e) => { SetIsFreightTaxable(e.target.checked); calculateGrandTotal(); }} />
                                </div>
                                <div className="col">
                                    <select id="cmb_freight_hsn_id" className="form-select form-select-sm" value={cmb_freight_hsn_id} disabled={chk_is_freight_taxable ? '' : 'disabled'} onChange={async (e) => { setFreightHSNId(e.target.value); calculateGrandTotal(); }} optional={chk_is_freight_taxable === false ? "optional" : ''} >
                                        <option value="">Select</option>
                                        {freightHSNOpts?.map(hsnCode => (
                                            <option value={hsnCode.hsn_sac_id} hsn_rate={hsnCode.hsn_sac_rate} lbl={hsnCode.hsn_sac_code}>{hsnCode.hsn_sac_code}</option>
                                        ))}
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_freight_hsn_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>


                            </div>
                            {/* </div> */}
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Insurance Percent & Amount</Form.Label>
                                </div>
                                <div className='col'>
                                    <Form.Control type="number" id="txt_po_packing_percent" className="erp_input_field text-end" value={txt_po_packing_percent}
                                        onChange={(e) => { if (e.target.value === '') { setPOPackingPercent(e.target.value); } if (validatePoDetails(e, 'PercentageInput')) { handleFooterPackingPercentChange(e.target.value); } }} />
                                    <MDTypography variant="button" id="error_txt_po_packing_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                                {/* <div className='col'>
                                    <Form.Control type="text" id="txt_packing_amount" className="erp_input_field text-end" value={txt_packing_amount} maxLength={19} onChange={(e) => { setPackingAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }}
                                        onBlur={(e) => { setPackingAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }} />
                                    <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div> */}
                                <div className='col'>
                                    <Form.Control type="text" id="txt_packing_amount" className="erp_input_field text-end" value={txt_packing_amount} maxLength={19} onChange={(e) => { setPackingAmount(validateNumberDateInput.current?.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { handleFooterPackingAmountChange(e.target.value); } }}
                                        onBlur={(e) => { setPackingAmount(validateNumberDateInput.current?.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) if (validateNumericInputs(e)) { handleFooterPackingAmountChange(e.target.value); } }} />
                                    <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Discount Percent & Amount</Form.Label>
                                </div>
                                <div className='col'>
                                    <Form.Control type="number" id="txt_po_discount_percent" className="erp_input_field text-end" value={txt_po_discount_percent}
                                        onChange={(e) => { if (e.target.value === '') { setPODiscountPercent(e.target.value); } if (validatePoDetails(e, 'PercentageInput')) { handleFooterDiscountPercentChange(e); } }} />
                                    <MDTypography variant="button" id="error_txt_po_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                                <div className='col'>
                                    <Form.Control type="text" id="txt_po_discount_amount" className="erp_input_field text-end" value={txt_po_discount_amount} maxLength={19}
                                        onChange={(e) => { setPODiscountAmount(validateNumberDateInput.current?.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { handleFooterDiscountAmountChange(e); } }} />
                                    <MDTypography variant="button" id="error_txt_po_discount_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Other Amount</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_otherAmount' className="erp_input_field text-end" value={txt_otherAmount} onChange={(e) => { setOtherAmount(validateNumberDateInput.current?.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }}
                                        onBlur={(e) => { setOtherAmount(validateNumberDateInput.current?.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }} />
                                    <MDTypography variant="button" id="error_txt_otherAmount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                        </div>
                        {/* //second column */}
                        <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">

                            <div className='row'>
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Taxable Total</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_taxableTotal' className="erp_input_field text-end" value={txt_taxableTotal} disabled onChange={(e) => { setTaxableTotal(validateNumberDateInput.current?.decimalNumber(e.target.value, 4)) }} />
                                    <MDTypography variant="button" id="error_txt_taxableTotal" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">CGST Total</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_cgst_total' className="erp_input_field text-end" value={txt_cgst_total} optional='optional' disabled onChange={(e) => setCgstTotal(validateNumberDateInput.current?.decimalNumber(e.target.value, 4))} />
                                    <MDTypography variant="button" id="error_txt_cgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">SGST Total </Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_sgst_total' className="erp_input_field text-end" value={txt_sgst_total} optional='optional' disabled onChange={(e) => setSgstTotal(validateNumberDateInput.current?.decimalNumber(e.target.value, 4))} />
                                    <MDTypography variant="button" id="error_txt_sgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">IGST Total</Form.Label>
                                </div>
                                <div className='col'>
                                    <Form.Control type="text" id='txt_igst_total' className="erp_input_field text-end" value={txt_igst_total} optional='optional' disabled onChange={(e) => setIgstTotal(validateNumberDateInput.current?.decimalNumber(e.target.value, 4))} />
                                    <MDTypography variant="button" id="error_txt_igst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Grand Total & Round Off</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_grand_amount' className="erp_input_field text-end" disabled value={txt_grand_amount} onChange={e => { setGrandTotal(e.target.value) }} />
                                    <MDTypography variant="button" id="error_txt_grand_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_round_off' className="erp_input_field text-end" disabled value={txt_round_off} onChange={e => { setRoundOff(validateNumberDateInput.current?.decimalNumber(e.target.value, 4)) }} />
                                    <MDTypography variant="button" id="error_txt_round_off" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 col-12">
                                    <Form.Label className="erp-form-label">Agent</Form.Label>
                                </div>
                                <div className="col-sm-7 col-10">
                                    <select id="cmb_agentId" className="form-select form-select-sm" value={cmb_agentId} onChange={(e) => { setAgentId(e.target.value); comboOnChange('agent'); validateFields(); }} >
                                        <option value="">Select</option>
                                        <option value="0">Add New Record+</option>
                                        {agents?.map(cmb_agentId => (
                                            <option value={cmb_agentId.field_id}>{cmb_agentId.field_name}</option>
                                        ))}
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_agentId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                                <div className="col-sm-1 col-2">
                                    <Tooltip title="Refresh" placement="top">
                                        <MDTypography className={`  ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                            <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_agent') } }} />
                                        </MDTypography>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Agent Percent & Paid Status</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id="txt_agentPercent" className="erp_input_field text-end" value={txt_agentPercent} maxLength={2}
                                        onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setAgentPercent(e.target.value); } }} />
                                    <MDTypography variant="button" id="error_txt_agentPercent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                                <div className='col'>
                                    <select id="chk_agentPaidStatus" className="form-select form-select-sm" value={chk_agentPaidStatus} onChange={(e) => { setAgentPaidStatus(e.target.value); validateFields(); }} >
                                        <option value="">select</option>
                                        <option value="P">Pending</option>
                                        <option value="A">Approved</option>
                                        <option value="C">Completed</option>
                                        <option value="X">Canceled</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* //Third column */}
                        <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">PO Acceptance Status</Form.Label>
                                </div>
                                <div className="col">
                                    <div className="erp_form_radio">
                                        <div className="fCheck"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="chk_po_mail_sent_status" checked={chk_PO_acceptance_status === "P"} onClick={() => { setPO_acceptance_status("P"); }} disabled /> </div>
                                        <div className="sCheck"> <Form.Check className="erp_radio_button" label="Approved" type="radio" lbl="Approved" value="A" name="chk_po_mail_sent_status" checked={chk_PO_acceptance_status === "A"} onClick={() => { setPO_acceptance_status("A"); }} disabled /> </div>
                                        <div className="sCheck"> <Form.Check className="erp_radio_button" label="Canceled" type="radio" lbl="Canceled" value="X" name="chk_po_mail_sent_status" checked={chk_PO_acceptance_status === "X"} onClick={() => { setPO_acceptance_status("X"); }} disabled /> </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Quotation No. / Reference PI No. & Date</Form.Label>
                                </div>
                                <div className='col'>
                                    <Form.Control type="text" id="txt_quotation_no" className="erp_input_field" value={txt_quotation_no} onChange={(e) => { setQuotation_no(e.target.value); validateFields(); }} optional='optional' />
                                    <MDTypography variant="button" id="error_txt_quotation_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>

                                <div className="col">
                                    <Form.Control type="date" id='dt_quotation_date' className="erp_input_field" value={dt_quotation_date} onChange={e => { setQuotation_date(e.target.value); }} optional='optional' />
                                    <MDTypography variant="button" id="error_dt_quotation_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Other Terms Conditions</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control as="textarea" id='txt_other_term_con' className="erp_input_field" value={txt_other_term_con} onChange={e => { setOther_term_con(e.target.value) }} optional='optional' maxLength={1000} />

                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Remark</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control as="textarea" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' maxLength={1000} />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">PO IsActive</Form.Label>
                                </div>
                                <div className="col">
                                    <div className="erp_form_radio">
                                        <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_po_isactive" checked={chk_po_isactive === true} onClick={() => { setPOIsActive(true); }} /> </div>
                                        <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="chk_po_isactive" checked={chk_po_isactive === false} onClick={() => { setPOIsActive(false); }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>);
    }

    // payment terms accordian
    const renderPaymentTermsAccordian = () => {
        return (
            <>
                <hr />
                <Accordion defaultActiveKey="0"  >
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="erp-form-label-md">Payment Terms</Accordion.Header>
                        <Accordion.Body>
                            <div className={`row py-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}>
                                <div className="col-12 col-sm-6">
                                    <input type='checkbox' class="selectAllPayTerms" id="selectAllPayTerms" onClick={(e) => toggleChkAllBoxes('selectAllPayTerms')} disabled={isView === false ? false : true} /> <label class="erp-form-label pb-1"> Select All </label>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <MDButton type="button" className={`erp-gb-button float-sm-end col-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                                        onClick={() => {
                                            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                            setHeaderName('Payment Terms'); // CommonParamterEntry
                                            setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                        }}>Add
                                    </MDButton>
                                </div>
                            </div>
                            <div className="table-responsive erp_table_scroll">
                                {renderPaymentTermsTable}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>
        );
    }


    const renderDocumentListAccordian = () => {
        return (
            <> {
                // keyForViewUpdate !== '' ?
                <> <hr />
                    <Accordion defaultActiveKey="0"  onSelect={FnLoadAccordionData}>
                        <Accordion.Item eventKey="1" >
                            <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                            <Accordion.Body>
                                {docData.length !== 0 ? (
                                    renderDocumentTable
                                ) : (
                                    <div className='row text-center'>
                                        <div className="col-12">
                                            <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                No Records Found...
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                // : null
            }
            </>
        );
    }


    // Po Contacts
    const memorizedSupplierContactTbl = useMemo(() => {
        return <Datatable data={suppContactDetails} columns={suppContactDetailsCols} />
    }, [suppContactDetails])


    // ------------------------ Changes for the Searchable Combos. --------------------------
    let supplierComboRef = useRef(null);

    let consigneeComboRef = useRef(null)
    let departmentComboRef = useRef(null)
    let subDepartmentComboRef = useRef(null)
    let indentNoComboRef = useRef(null);

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span id="spinner_text" className="text-dark">Loading...</span>
                            </div>
                        </div> :
                        ''}
                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'>
                                Purchase Order {'Cotton Bales'}{ActionType()}</label>
                        </div>
                        <form id="PO_header_formId">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className={` row`}>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Product Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={(e) => { setProductTypeID(e.target.value); comboOnChange('productType'); validateFields(); }} disabled>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {producttypeOptions.length !== 0 ?
                                                    <>
                                                        {producttypeOptions?.map(poTypeId => (
                                                            <option value={poTypeId.field_id} shortname={poTypeId.product_type_short_name}>{poTypeId.field_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className={` row`}>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Product Category<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_product_category_id" className="form-select form-select-sm" value={cmb_product_category_id} disabled>
                                                <option value="">Select</option>
                                                {productCategoryOptions.length !== 0 ?
                                                    <>
                                                        {productCategoryOptions?.map(poCategory => (
                                                            <option value={poCategory.product_category2_id} >{poCategory.product_category2_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_product_category_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">PO No,Version & Date</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <div className="row">
                                                <div className='col-12 col-md-6 pe-md-0'>
                                                    <Form.Control type="text" id="txt_PO_no" className="erp_input_field" value={txt_PO_no} optional='optional' disabled />

                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3">
                                                    <Form.Control type="text" id='txt_po_version' className="erp_input_field" value={txt_po_version} optional='optional' disabled />

                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='txt_po_date' className="erp_input_field" value={txt_po_date} onChange={e => { setPODate(e.target.value); validateFields(); }} />
                                                    <MDTypography variant="button" id="error_txt_po_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Supplier<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-7 col-10 mb-1'>
                                            <Select ref={supplierComboRef}
                                                options={supplierOptions}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_supplier_id" // Provide the ID for the input box
                                                value={supplierOptions.find(option => option.value === cmb_supplier_id)}
                                                onChange={(selectedOpt) => {
                                                    setSupplier_id(selectedOpt.value);
                                                    supplierComboRef.current = selectedOpt;
                                                    comboOnChange("supplier");
                                                    validateFields();
                                                }}
                                                placeholder="Search for a supplier..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_supplier_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`  ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_supplier') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Supplier State & City<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">

                                                    <select id="cmb_supplier_state_id" className="form-select form-select-sm" value={cmb_supplier_state_id} onChange={() => { comboOnChange("supplier_state"); validateFields(); }} disabled >
                                                        <option value="">Select</option>
                                                        {supplierStateOptions.length !== 0 ?
                                                            <>
                                                                {supplierStateOptions?.map(cmb_supplier_state_id => (
                                                                    <option value={cmb_supplier_state_id.field_id} stateCode={cmb_supplier_state_id.state_code}>{cmb_supplier_state_id.field_name}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>

                                                <div className='col-12 col-md-6 pt-md-0 pt-3 ps-md-1'>
                                                    <select id="cmb_supplier_city_id" className="form-select form-select-sm" value={cmb_supplier_city_id} onChange={(e) => { setSupplier_city_id(e.target.value); comboOnChange('supplier_city'); validateFields(); }} disabled>
                                                        <option value="">Select</option>
                                                        <option value="0">Add New Record+</option>
                                                        {supplierCityOpts.length !== 0 ?
                                                            <>
                                                                {supplierCityOpts?.map(cmb_supplier_city_id => (
                                                                    <option value={cmb_supplier_city_id.field_id}>{cmb_supplier_city_id.field_name}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_supplier_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Consignee</Form.Label>
                                        </div>

                                        <div className='col-sm-8 col-10'>
                                            {/* Searchable Combo Box for Consignee */}
                                            <Select ref={consigneeComboRef}
                                                options={supplierOptions}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_Consignee_id"
                                                value={supplierOptions.find(option => option.value === cmb_Consignee_id)}
                                                onChange={(selectedOpt) => {
                                                    setConsigneeid(selectedOpt.value);
                                                    consigneeComboRef.current = selectedOpt;
                                                    comboOnChange("Consignee");
                                                    validateFields();
                                                }}
                                                placeholder="Search for a Consignee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_Consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                        {/* <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`  ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_supplier') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div> */}

                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Consignee State & City</Form.Label>
                                        </div>
                                        {/* col-sm-9 col-12 */}

                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">

                                                    <select id="cmb_Consignee_state_id" className="form-select form-select-sm" value={cmb_Consignee_state_id} onChange={(e) => { setConsignee_state_id(e.target.value); validateFields(); }} optional='optional' disabled >
                                                        <option value="">Select</option>
                                                        {ConsigneeStateOptions.length !== 0 ?
                                                            <>
                                                                {ConsigneeStateOptions?.map(ConsigneeStateOptions => (
                                                                    <option value={ConsigneeStateOptions.field_id}>{ConsigneeStateOptions.field_name}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_Consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>

                                                <div className='col-12 col-md-6 pt-md-0 pt-3 ps-md-1'>
                                                    <select id="cmb_Consignee_city_id" className="form-select form-select-sm" value={cmb_Consignee_city_id} onChange={(e) => { setConsignee_city_id(e.target.value); comboOnChange('Consignee_city'); validateFields(); }} optional='optional' disabled>
                                                        <option value="">Select</option>
                                                        <option value="0">Add New Record+</option>
                                                        {ConsigneeCityOpts.length !== 0 ?
                                                            <>
                                                                {ConsigneeCityOpts?.map(consignee_city => (
                                                                    <option value={consignee_city.field_id}>{consignee_city.field_name}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_Consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">PO Amount Currency<span className="required">*</span></Form.Label>
                                        </div>

                                        <div className='col-sm-8 col-10'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Select ref={poCurrencyComboRef}
                                                        options={currencyOptions}
                                                        isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                        inputId="cmb_currency_id"
                                                        value={currencyOptions.find(option => option.value === cmb_currency_id)}
                                                        onChange={(selectedOpt) => {
                                                            setCurrencyId(selectedOpt.value);
                                                            SetCurrency_symbol(selectedOpt.currency_symbol)
                                                            setCurrencyLbl(selectedOpt.currency_name);
                                                            poCurrencyComboRef.current = selectedOpt;
                                                        }}
                                                        placeholder="Search for a Currency..."
                                                        className="form-search-custom"
                                                        classNamePrefix="custom-select"
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            })
                                                        }}
                                                    />
                                                    <MDTypography variant="button" id="error_cmb_currency_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className='col-12 col-md-6 pt-md-0 pt-3 ps-md-1'>
                                                    <Form.Control type="text" id='txt_currency_symbol' className="erp_input_field" value={txt_currency_symbol} optional='optional' disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className={` row `}>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Company Branch<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-7 col-10'>
                                            <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { comboOnChange("expt_branch"); validateFields(); }} >
                                                <option value="">Select</option>
                                                <option value="0">Add Record+</option>
                                                {expectedBranchOptions.length !== 0 ?
                                                    <>
                                                        {expectedBranchOptions?.map(cmb_expected_branch_id => (
                                                            <option value={cmb_expected_branch_id.company_branch_id} chk_isSez={`${cmb_expected_branch_id.is_sez}`}>{cmb_expected_branch_id.company_branch_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`  ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_expectedBranch') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div className={` row d-none`}>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Company Branch State<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">

                                            <select id="cmb_expected_branch_state_id" className="form-select form-select-sm" value={cmb_expected_branch_state_id} onChange={(e) => { comboOnChange("expt_branchState"); validateFields(); }} optional='optional' disabled>
                                                <option value="">Select</option>
                                                {expectedBranchStateOptions.length !== 0 ?
                                                    <>
                                                        {expectedBranchStateOptions?.map(cmb_expected_branch_state_id => (
                                                            <option value={cmb_expected_branch_state_id.field_id} stateCode={cmb_expected_branch_state_id.state_code}>{cmb_expected_branch_state_id.field_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_expected_branch_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className={` row d-none`}>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Company City</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_expected_branch_city_id" className="form-select form-select-sm" value={cmb_expected_branch_city_id} onChange={(e) => { setExpected_branch_city_id(e.target.value); comboOnChange('company_city'); validateFields(); }} optional='optional' disabled>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {expectedBranchCityOptions.length !== 0 ?
                                                    <>
                                                        {expectedBranchCityOptions?.map(cmb_expected_branch_city_id => (
                                                            <option value={cmb_expected_branch_city_id.field_id}>{cmb_expected_branch_city_id.field_name}</option>
                                                        ))}</>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_expected_branch_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>

                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Approved By <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} onChange={(e) => { setApproved_by_id(e.target.value); validateFields(); }} >
                                                <option value="">Select</option>
                                                {approvedByOptions.length !== 0 ?
                                                    <>
                                                        {approvedByOptions?.map(approved => (
                                                            <option value={approved.field_id}>{approved.field_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {((keyForViewUpdate === 'view' && chk_po_status === 'A' || chk_po_status === 'Z') || keyForViewUpdate === 'approve') ? (
                                        <>
                                            <div className='row'>
                                                <div className="col-sm-4">
                                                    <Form.Label className="erp-form-label">Approved Date </Form.Label>
                                                </div>
                                                <div className="col">
                                                    <Form.Control type="date" id='txt_approved_date' className="erp_input_field" value={txt_approved_date} onChange={e => { setApproved_date(e.target.value); }} optional='optional' disabled='disabled' />
                                                    <MDTypography variant="button" id="error_txt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>)
                                        : ''
                                    }

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">PO Status </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="chk_po_status" className="form-select form-select-sm " value={chk_po_status} onChange={(e) => { setPO_status(e.target.value); FnsetMaterialItemStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                                <option value="R">Rejected</option>
                                                <option value="I">Partial Reciept</option>
                                                <option value="C">Completed</option>
                                                <option value="X">Canceled</option>
                                                <option value="Z">PreeClosed</option>
                                                <option value="B">Bill Booked</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Status Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_status_remark' className="erp_input_field" value={txt_status_remark} onChange={e => setStatus_remark(e.target.value)} disabled={!isApprove} optional='optional' maxLength={1000} />
                                            <MDTypography variant="button" id="error_txt_status_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Accordion defaultActiveKey="0" className="mt-3 mt-lg-1">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="erp-form-label-md">Supplier's Contact Details (Select atleast one.)</Accordion.Header>
                                                <Accordion.Body className="p-0">
                                                    {
                                                        cmb_supplier_id !== '' && cmb_supplier_id !== '0'
                                                            ? <>
                                                                <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>
                                                                    <div className={` d-flex justify-content-end align-items-center`}>
                                                                        <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                            localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                            localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: supplierComboRef.current.supplier_id, keyForViewUpdate: 'update' }))
                                                                        }
                                                                        }>  <Link className={'text-light'} to={{ pathname: "/Masters/FrmSupplier" }} target="_blank" >Add</Link></MDButton>
                                                                        <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetSupplierContacts(cmb_supplier_id) }}>Refresh</MDButton>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    {suppContactDetails.length > 0
                                                        ? <>
                                                            <div className='row'>
                                                                <div className="col-12 erp_table_scroll">
                                                                    {memorizedSupplierContactTbl}
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <> <div className='row text-center'>
                                                            <div className="col-12">
                                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                                            </div>
                                                        </div></>
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion >
                                        <MDTypography variant="button" id="error_supplier_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </>


                {renderAccordions()}

                {/* Total Section Ends*/}
                <div className="erp_frm_Btns">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => addPO('A')} fontWeight="regular"  >Save</MDButton>
                    <MDButton type="button" id="reject-btn" className={`erp-gb-button erp_MLeft_btn ${isApprove ? 'display' : 'd-none'}`} variant="button" onClick={() => addPO('R')}>Rejected</MDButton>
                    <MDButton type="button" id="viewdocument-id" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={viewDocumentForm}>Upload Document</MDButton>&nbsp;
                    <MDButton type="button" id="clear-btn-id" className={`erp-gb-button erp_MLeft_btn  ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" onClick={FnClearFormData} fontWeight="regular">Clear</MDButton>
                    <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? chk_po_status !== 'P' && chk_po_status !== 'R' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id='print-btn-id' onClick={() => { printInvoice(true, false) }} disabled={chk_po_status !== "P" ? '' : 'disabled'} >Print<FiDownload className="erp-download-icon-btn" /></MDButton>
                    <MDButton className={`erp-gb-button erp_MLeft_btn  ${keyForViewUpdate !== '' ? chk_po_status === 'P' || keyForViewUpdate == 'approve' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id='printPreview-btn-id' onClick={() => { printInvoice(false, true) }} >Print Preview</MDButton>
                    {keyForViewUpdate === 'view' && chk_po_status === 'A' && chk_PO_acceptance_status !== 'A'
                        ? <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => acceptPurchaseOrder()}>Accept</MDButton>
                        : null
                    }
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'cancel' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => addPO('X')}>Cancelation</MDButton>
                </div >
                {/* ADD Filter modal */}
                <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={handleCloseBomFilterForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Product List</Modal.Title>
                        <span class="erp-modal-close"><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseBomFilterForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <ProductListProductTypeWise closeModal={handleCloseBomFilterForm} props={{ product_type_id: cmb_product_type_id }} />
                        </React.Fragment>
                    </Modal.Body>
                </Modal>

                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>

                    </Modal.Footer>
                </Modal >

                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_PO_no !== 0 ? txt_PO_no.replaceAll('/', '_') : null} document_group={documentGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='text-center'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Do you wish to delete this record ?</h6>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deletePO}>Delete</Button>
                    </Modal.Footer>

                </Modal>
                <Modal size="lg" show={showPrintPreviewIsOpen} onHide={handleClosePrintPreview} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Purchase Order Preview</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleClosePrintPreview}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: printPreviewContent }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleClosePrintPreview}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>


                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
                <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={() => sendEmail()} show={[showMailSentModal, succMsg, modalOrderDetails]} />
                {showConfirmationModal ?
                    <ConfirmationModal close={() => FnCloseConfirmationModal('addHsn')} confirmation={() => FnCloseConfirmationModal('noHsn')} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
                    : null
                }
            </DashboardLayout >
        </>
    )
}

export default FrmCottonBalesPOEntry
