import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Datatable from "components/DataTable";

// React icons
import { MdDelete, MdRefresh } from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import MaterialGatePassInvoice from 'FrmGeneric/Invoice/MaterialGatePassInvoice';
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { renderToString } from "react-dom/server";

// Import for the searchable combo box.
import Select from 'react-select';

function FrmMaterialGatePassEntry() {
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, UserId, UserName } = configConstants;


    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [openIssueDetailsAccord, setOpenIssueDetailsAccord] = useState(true);

    // For navigate
    const navigate = useNavigate();
    const navigator = useNavigate();

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    let supplierComboRef = useRef(null);
    const selectRefs = useRef([]);
    const [errorIndex, setErrorIndex] = useState(null);



    // for today date formatted
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];

    //form combo options
    const [productTypeOptions, setProducttypeOptions] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [suppAddress, setSuppAddress] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [category1Options, setCategory1Options] = useState([])
    const [category2Options, setCategory2Options] = useState([])
    const [cmb_checker_by_id, setCheckerById] = useState('')
    const [cmb_approved_by_id, setApprovedById] = useState(UserId)
    const [cmb_gate_pass_status, setGatePassStatus] = useState('P')
    const [cmb_approved_by_name, setApprovedByName] = useState(UserName)
    const [dt_approve_date, setApproveDate] = useState(todayDate())
    const [cmb_checker_by_name, setCheckerByName] = useState(UserName)

    //Form Fields
    const [cmb_product_type, setProductType] = useState('');
    const [cmb_product_category1_id, setProductCategory1Id] = useState('');
    const [cmb_product_category2_id, setProductCategory2Id] = useState('');
    const [cmb_product_type_name, setProductTypeName] = useState('');
    const [materialId, setMaterialId] = useState('');
    const [materialName, setMaterialName] = useState('');
    const [materialCode, setMaterialCode] = useState('');
    const [txt_gate_pass_no, setMaterialGatePassNo] = useState('')
    const [dt_gate_pass_date, setGatePassDate] = useState(todayDate)
    const [material_gate_pass_master_id, setTransferIssueMasterTransaction_id] = useState(idList.material_gate_pass_master_id);
    const [materialGatePassDetailsData, setMaterialGatePassDetailsData] = useState([])
    const [material_type, setMaterialType] = useState('RGP');
    const [txt_vehicle_no, setVehicleNo] = useState('');
    const [cmb_supplier_id, setSupplierId] = useState('');
    const [cmb_supplier_branch_id, setSupplierBranchId] = useState('');
    const [cmb_supplier_city_id, setSuppliersCityId] = useState('');
    const [cmb_supplier_state_id, setSuppliersStateId] = useState('');

    // supp contact details table data hooks
    const [SuppContactDetails, setSuppContactDetails] = useState([]);
    const [SuppContactDetailsCols, setSuppContactDetailsCols] = useState([]);


    //department and sub-dept 
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_subdepartment_id, setSubDepartmentId] = useState('');
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [subDepartmentOptions, setSubDepartmentOptions] = useState([]);
    const cmb_department_ref = useRef()
    const cmb_subdepartment_ref = useRef()


    let empApprovedComboRef = useRef();
    let empCheckerComboRef = useRef();
    let category1ComboRef = useRef();
    let category2ComboRef = useRef();

    const [actionType, setActionType] = useState("");
    const [actionLabel, setActionLabel] = useState("Save");

    //Add Schedules popup open 
    const [returnDataArray, setReturnDataArray] = useState([]);
    const [returnDataArraysummary, setReturnDataArraySummary] = useState([]);
    const [gatePassMasterDataForPrint, setGatePassMasterDataForPrint] = useState([]);


    const [showSChedules, setSchedules] = useState(false);
    const handleCloseReturnSummaryPopup = () => setSchedules(false)
    const [returnRowcount, setReturnRowcount] = useState(1);

    const [totalOutwardQuantity, setTotalOutwardQuantity] = useState(0);
    const [totalInwardQuantity, setTotalInwardQuantity] = useState(0);
    const [remainingQuantity, setRemainingQuantity] = useState(0);
    const [prev_inward_quantity, setTotalPreInwardQTY] = useState(0);
    const [cost_center_id, setCostCenterId] = useState('');
    const [costCenterOptions, setCostCenterOptions] = useState([]);



    useEffect(async () => {
        setIsLoading(true);
        try {
            await ActionType();
            removeSessions('onload');
            await fillCombos();
            if (idList !== '' || idList !== undefined) {
                await FnCheckUpdateResponse();
            }
            FnSetFieldsByUserAccess()
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [])
    // delete popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    const closeConfirmationModal = async () => {
        setShowSuccessMsgModal(false)
        moveToListing();
    };


    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case "update":
                setActionType("(Modification)");
                setActionLabel("Update");
                break;
            case "view":
                $('input[type="checkbox"]').prop('readonly', true);
                setActionType("(View)");
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;
            case "approve":
                setActionType("(Approve)");
                setActionLabel("Approve");
                setGatePassStatus("A")
                $('input[type="checkbox"]').prop('readonly', true);
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;
            default:
                setActionType("(Creation)");
                break;
        }
    };

    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillCombos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const prodTypeOpts = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            console.log(prodTypeOpts)
            const options = [
                { value: '', label: 'Select', short_name: "" },
                ...prodTypeOpts.map((prodType) => ({ ...prodType, value: prodType.field_id, label: prodType.field_name, short_name: prodType.product_type_short_name })),
            ];
            setProducttypeOptions(options)

            //For Supplier
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "city_name", "state_name", "supp_branch_city_id", "supp_branch_state_id", "supp_branch_address1", "supplier_id"]
            globalQuery.table = "cmv_supplier_branch";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.conditions.push({ field: "supp_branch_type", operator: "=", value: "Supplier" });
            const getSuppliers = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            //  ** Changes for the Searchable Combo
            const suppliers = [
                { value: '', label: 'Select', supp_branch_address1: '', supp_branch_state_id: '', supp_branch_city_id: '', state_name: '', city_name: '', },
                { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '' },
                ...getSuppliers.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, address: supplier.supp_branch_address1, supplierId: supplier.supplier_id })),
            ];
            setSupplierList(suppliers);
            const getStates = await comboDataAPiCall.current.fillMasterData("cmv_state", "", "")
            setStateList(getStates)

            resetGlobalQuery();
            globalQuery.columns.push("employee_id");
            globalQuery.columns.push("employee_name");
            globalQuery.table = "cm_employee";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            const requestedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const employeeOptions = [
                ...requestedbyIdApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
            ];
            setEmployeeOptions(employeeOptions);
            setIsLoading(false)

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name',]
            globalQuery.conditions = [
                { field: "department_type", operator: "=", value: "M" },
                { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] }
            ]
            globalQuery.table = "cmv_department";
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((deptOptions) => {
                const departmentOptions = [
                    { value: '', label: 'Select' },
                    ...deptOptions.map((department) => ({ ...department, value: department.field_id, label: department.field_name, })),
                ];
                setDepartmentOptions(departmentOptions);
            });


        }
        catch (error) {
            console.log("error: ", error)
        }
    }

    //fn to get po auto no
    const GenerateGatePassNo = async () => {
        const ptShortName = productTypeOptions.find((item) => item.value === parseInt($("#cmb_product_type").val())) || "";
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_material_gate_pass_master", "gate_pass_no", ptShortName.product_type_short_name, material_type, "5", "material_type", material_type);
        setMaterialGatePassNo(autoNoApiCall);
        return autoNoApiCall;
    };
    //-------------------------------------- Combo changes ------------------------------------------------------------
    const comboOnChange = async (key) => {

        switch (key) {
            case 'ApprovedBy':

                var employeeID = empApprovedComboRef.current?.employee_id ?? UserId;
                resetGlobalQuery();
                globalQuery.columns.push("employee_id");
                globalQuery.columns.push("employee_name");
                globalQuery.table = "cm_employee";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeID });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                const requestedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                const employeeOptions = [
                    ...requestedbyIdApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
                ];


                $("#error_cmb_approved_by_id").hide();
                if (employeeID !== "") {
                    setApprovedById(employeeOptions[0].employee_id);
                    setApprovedByName(employeeOptions[0].employee_name);
                    empApprovedComboRef.current.employee_id = employeeOptions[0].employee_id
                    empApprovedComboRef.current.employee_name = employeeOptions[0].employee_name
                } else {
                    setApprovedById('');
                    setApprovedByName('');
                }
                break;
            case 'CheckerBy':

                var employeeID = empCheckerComboRef.current?.employee_id ?? UserId;
                resetGlobalQuery();
                globalQuery.columns.push("employee_id");
                globalQuery.columns.push("employee_name");
                globalQuery.table = "cm_employee";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeID });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                const requestedbyIdApiCalls = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                const employeeOption = [
                    ...requestedbyIdApiCalls.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
                ];
                $("#error_cmb_checker_by_id").hide();
                if (employeeID !== "") {
                    setCheckerById(employeeOption[0].employee_id);
                    setCheckerByName(employeeOption[0].employee_name);
                    empCheckerComboRef.current.employee_id = employeeOption[0].employee_id
                    empCheckerComboRef.current.employee_name = employeeOption[0].employee_name
                } else {
                    setCheckerById('');
                    setCheckerByName('');

                }
                break;

            case 'Supplier':
                let supplierValue = supplierComboRef.current.value || '';
                if (supplierValue === "0") {
                    localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: 0, keyForViewUpdate: '', compType: 'Master' }))
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    const newTab = window.open('/Masters/FrmSupplier', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (supplierValue !== "0" && !isNaN(supplierValue)) {
                    setIsLoading(true);
                    let selectedState = supplierComboRef.current?.supp_branch_state_id;
                    let selectedCity = supplierComboRef.current?.supp_branch_city_id;
                    setSuppliersStateId(selectedState);
                    const getCities = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + selectedState + "");
                    setSuppAddress(supplierComboRef.current?.supp_branch_address1)
                    setCityList(getCities)
                    setSuppliersCityId(selectedCity);
                    await FnGetSupplierContacts(supplierValue);
                    $("#error_cmb_supplier_branch_id").hide();
                    setIsLoading(false);
                } else {
                    setSuppliersStateId('')
                    setSuppliersCityId('')
                    setSuppContactDetails([])
                    setSuppContactDetailsCols([])
                }
                break;

            case "SupplierState":
                var supplierStateTpVal = document.getElementById("cmb_supplier_state_id").value;
                setSuppliersStateId(supplierStateTpVal)
                if (supplierStateTpVal !== "" && supplierStateTpVal !== "0") {
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + supplierStateTpVal + "");
                    setCityList(cityApiCall);
                    setSuppliersCityId('');
                }
                else {
                    setCityList([]);
                }
                break;

            case 'cmb_product_type':
                $("#error_cmb_product_type").hide();
                let productTypeId = $('#cmb_product_type option:selected').val()
                let prodTypeName = $('#cmb_product_type option:selected').text()
                if (productTypeId !== "") {
                    setProductType(productTypeId);
                    setProductTypeName(prodTypeName);

                    resetGlobalQuery();
                    globalQuery.columns = ['product_category1_id', 'product_category1_name'];
                    globalQuery.table = "sm_product_category1";
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
                    const Category1List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    const category1Opts = [
                        { value: 0, label: 'Select' },
                        ...Category1List.map((category1) => ({ ...category1, value: category1.product_category1_id, label: `${category1.product_category1_name}` })),
                    ];
                    setCategory1Options(category1Opts);
                    setCategory2Options([])
                    setProductCategory2Id(0)
                } else {
                    setProductCategory1Id(0)
                    setCategory1Options([])
                    setProductCategory2Id(0)
                    setCategory2Options([])
                }
                if (keyForViewUpdate === "add") {
                    GenerateGatePassNo()
                }
                break;
            case 'category1':

                var category1Id = category1ComboRef.current.value
                $("#error_cmb_category1_id").hide();
                if (category1Id !== 0) {
                    resetGlobalQuery();
                    globalQuery.columns = ['product_category2_id', 'product_category2_name'];
                    globalQuery.table = "sm_product_category2";
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
                    const Category2List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    const category2Opts = [
                        { value: 0, label: 'Select' },
                        ...Category2List.map((category2) => ({ ...category2, value: category2.product_category2_id, label: `${category2.product_category2_name}` })),
                    ];
                    setCategory2Options(category2Opts);
                } else {
                    setProductCategory2Id(0)
                    setCategory2Options([])
                }
                break;

            case 'Department':

                $("#error_cmb_subdepartment_id").hide();
                $("#error_cmb_department_id").hide();
                const departmentId = cmb_department_ref.current.value;

                if (departmentId !== "") {
                    setDepartmentId(departmentId);
                    cmb_department_ref.current.field_id = departmentId

                    $('#error_department_group_id').hide();
                    try {
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name', 'parent_department_id', 'parent_department', 'cost_center_group']
                        globalQuery.conditions = [
                            { field: "department_type", operator: "=", value: "S" },
                            { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] }
                        ]
                        if (departmentId !== 'all') {
                            globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentId });
                        }
                        globalQuery.table = "cmv_department";
                        let deptOptions = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        const departmentOptions = [
                            { value: '', label: 'Select' },
                            ...deptOptions.map((department) => ({
                                ...department, value: department.field_id, label: department.field_name,
                                parent_department_id: department.parent_department_id, parent_department: department.parent_department, costCenterGroup: department.cost_center_group
                            })),
                        ];
                        setSubDepartmentOptions(departmentOptions);

                        setSubDepartmentId('');
                        setCostCenterId('');
                        return departmentOptions;
                    } catch (error) {
                        console.log('Error: ' + error);
                    }
                } else {
                    setSubDepartmentOptions([]);
                    setSubDepartmentId('');

                }

                break;

            case 'SubDepartment':

                const SubDepartmentval = cmb_subdepartment_ref.current.value;
                setSubDepartmentId(SubDepartmentval);

                //by Nilesh
                if (SubDepartmentval !== "" && SubDepartmentval !== "0") {
                    var costCenterGroup = cmb_subdepartment_ref.current.costCenterGroup
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name', "cost_center_group"];
                    globalQuery.table = "fmv_cost_center";
                    globalQuery.conditions.push({ field: "cost_center_group", operator: "=", value: costCenterGroup });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getCostCenterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    const costCenterOpts = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        ...getCostCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.cost_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.cost_center_short_name, cost_center_group: costCenter.cost_center_group })),
                    ];
                    setCostCenterOptions(costCenterOpts);

                }
                setCostCenterId('');


                $("#error_cmb_subdepartment_id").hide();
                $("#error_cmb_department_id").hide();

                break;

            default:
                break;
        }
    }




    //--------------------------------------------------------Start Details section----------------------------------------------------------------------

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData')
        }
        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialGatePassListing/reg' : `/Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialGatePassListing`;
        navigate(Path);
    }

    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });



    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------

    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------


    let requiredCols = ['product_material_name',
        'product_material_id',
        'product_code',
        'stock_quantity',
        'stock_weight',
        'product_material_std_weight',

    ];

    const fnSearchMaterialData = async () => {
        try {

            setIsLoading(true)
            const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
            if (getData.length !== 0) {
                let material_id = getData[0].product_material_id;
                setMaterialId(material_id)
                setMaterialGatePassDetailsData((prev) => {
                    return [
                        ...prev,
                        ...getData.map((item) => ({
                            ...item,
                            outward_quantity: 0,
                            outward_weight: 0,
                            inward_quantity: 0,
                            inward_weight: 0,
                            pending_inward_quantity: 0,
                            rate: 0,
                            gate_pass_item_status: 'P',
                            prev_inward_quantity: 0,
                        })),
                    ];
                });

                sessionStorage.removeItem('filteredMaterialData');
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    //    By Nilesh 
    const handleIssueDetailsInputChange = (currentRowData, event, fieldName = null) => {
        ;

        let enteredValue;
        let clickedColName;
        let arrayIndex;

        if (event && event.target) {
            // Standard input fields (text inputs)
            enteredValue = event.target.value;
            clickedColName = event.target.getAttribute('Headers');

            if (event.target.parentElement && event.target.parentElement.parentElement) {
                arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'), 10);
            }
        } else {
            enteredValue = event.value; // React-Select provides an object { value, label }
            clickedColName = fieldName; // Manually pass field name
        }

        if (!clickedColName) return;

        switch (clickedColName) {
            case 'outward_quantity':
                if (enteredValue !== "") {
                    currentRowData[clickedColName] = enteredValue;
                    currentRowData["pending_inward_quantity"] = parseFloat(enteredValue) - parseFloat(currentRowData.inward_quantity);
                } else {
                    currentRowData[clickedColName] = enteredValue;
                }
                break;

            case 'cost_center_id':
                currentRowData[clickedColName] = enteredValue;
                break;

            default:
                currentRowData[clickedColName] = enteredValue;
                break;
        }

        // Update state
        setMaterialGatePassDetailsData((prevArr) => {
            const updatedArr = [...prevArr];
            updatedArr[arrayIndex] = currentRowData;
            return updatedArr;
        });

        localStorage.setItem('purchaseOrdScheduleData', JSON.stringify(currentRowData));
    };




    const deleteRecord = (Index, action) => {
        setMaterialGatePassDetailsData((prevDetailsData) => prevDetailsData.filter((data, i) => i !== Index))
    }

    //details table
    const renderDetailsTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='gatepass-details-tbl' bordered striped>
                <thead className="erp_table_head">
                    <tr>

                        {(keyForViewUpdate === "add" || keyForViewUpdate === "update" || keyForViewUpdate === "return") && <th className="erp_table_th">Action</th>}
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Material Code</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Cost Center</th>
                        <th className="erp_table_th">Outward Quantity</th>
                        <th className="erp_table_th">Rate</th>
                        <th className="erp_table_th">Gate Pass Status</th>
                        <th className="erp_table_th">Purpose</th>
                        <th className="erp_table_th">Remark</th>

                    </tr>
                </thead>
                <tbody>
                    {materialGatePassDetailsData.map((issueItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            {(keyForViewUpdate === "add" || keyForViewUpdate === "update" || keyForViewUpdate === "return") &&
                                <td className="erp_table_td" style={{ verticalAlign: "middle" }} ><Tooltip title="Delete" placement="top">
                                    <MDTypography className={`erp-view-btn ${keyForViewUpdate === "add" || keyForViewUpdate === "update" ? '' : 'd-none'}`} >
                                        <MdDelete sty className={`erp-delete-btn`} style={{ display: "flex", margin: "auto" }} onClick={() => deleteRecord(Index)} />
                                    </MDTypography></Tooltip>
                                </td>}

                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className="erp_table_td "> {issueItem.product_code}</td>
                            <td className="erp_table_td "> {issueItem.product_material_name}</td>

                            <>
                                <td className="erp_table_td wide-td">
                                    <Select
                                        id={`cost_center_id_${issueItem.product_material_id}`}
                                        ref={(el) => {
                                            if (el) {
                                                selectRefs.current[Index] = {
                                                    selectRef: el,
                                                    options: costCenterOptions
                                                };
                                            }
                                        }}
                                        value={costCenterOptions.find(option => option.value === parseInt(issueItem.cost_center_id))}
                                        onChange={(selectedOption) => {
                                            handleIssueDetailsInputChange(issueItem, selectedOption, "cost_center_id");
                                            setErrorIndex(null); // Reset error if valid selection is made
                                        }}
                                        options={costCenterOptions}
                                        isDisabled={!(keyForViewUpdate === "add" || keyForViewUpdate === "update")}
                                        className={`form-search-custom ${errorIndex === Index ? "error-border" : ""}`}
                                        classNamePrefix="custom-select"
                                        styles={{
                                            option: (provided) => ({ ...provided, fontSize: "12px" }),
                                            singleValue: (provided) => ({ ...provided, fontSize: "12px" }),
                                            input: (provided) => ({ ...provided, fontSize: "12px" }),
                                        }}
                                    />
                                    {errorIndex === Index && <span className="error-message">Please select a cost center!</span>}
                                </td>
                            </>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <input type="text" id={`outward_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.outward_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='outward_quantity' />
                                    :
                                    issueItem.outward_quantity
                                }
                            </td>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <input type="text" id={`rate_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.rate} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='rate' />
                                    :
                                    issueItem.rate
                                }
                            </td>
                            <td className="erp_table_td ">
                                <select rowIndex={`${issueItem}`} id={`gate_pass_item_status_${issueItem.product_material_id}`} disabled class="form-select form-select-sm erp_input_field_table_txt m-0"
                                    onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} value={issueItem.gate_pass_item_status} >
                                    <option value="P">Pending</option>
                                    <option value="A">Approved</option>
                                </select>
                            </td>

                             {/* Nilesh */}

                             <td className="erp_table_td ">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <Form.Control as="textarea" id={`purpose${issueItem.product_material_id}`} value={issueItem.purpose}
                                        Headers="purpose" onInput={(e) => { handleIssueDetailsInputChange(issueItem, e) }}
                                        className="erp_input_field mb-0" maxLength="1000" />
                                    :
                                    issueItem.purpose
                                }
                            </td>

                            <td className="erp_table_td ">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <Form.Control as="textarea" id={`remark${issueItem.product_material_id}`} value={issueItem.remark}
                                        Headers="remark" onInput={(e) => { handleIssueDetailsInputChange(issueItem, e) }}
                                        className="erp_input_field mb-0" maxLength="1000" />
                                    :
                                    issueItem.remark
                                }
                            </td>

                        </tr>
                    )}
                </tbody>
            </Table>
        </>

    }, [materialGatePassDetailsData, costCenterOptions])

    //-------------------------------- validate before save ------------------------------------------------------

    const validateFields = async () => {

        let prodTypeId = $('#cmb_product_type option:selected').val();
        if (prodTypeId === "" || prodTypeId === "0") {
            $("#error_cmb_product_type").text("Please fill this field...!");
            $("#error_cmb_product_type").show();
            $("#cmb_product_type").focus();
            return false;
        }
        else {
            validate.current.validateFieldsOnChange('gate_pass_form_id')
        }


    }

    const FnValidateGatePass = async () => {

        let checkName = empCheckerComboRef.current.employee_id;
        let prodType = cmb_product_type;
        let departmentId = cmb_department_ref.current.value
        let subDepartmentId = cmb_subdepartment_ref.current.value
        let supplier = supplierComboRef.current.value;


        if (prodType === "" || prodType === "0") {
            $("#error_cmb_product_type").text("Please fill this field...!");
            $("#error_cmb_product_type").show();
            $("#cmb_product_type").focus();
            // productTypeComboRef.current.focus()
            return;
        }
        if (!departmentId || departmentId === "0") {
            $("#error_cmb_department_id").text("Please fill this field...!");
            $("#error_cmb_department_id").show();
            $("#cmb_department_id").focus();
            return;
        }
        if (!subDepartmentId || subDepartmentId === "0") {
            $("#error_cmb_subdepartment_id").text("Please fill this field...!");
            $("#error_cmb_subdepartment_id").show();
            $("#cmb_subdepartment_id").focus();
            return;
        }
        if (!checkName || checkName === "0") {
            $("#error_cmb_checker_by_id").text("Please fill this field...!");
            $("#error_cmb_checker_by_id").show();
            $("#cmb_checker_by_id").focus();
            // empCheckerComboRef.current.focus()
            return;
        }
        if (!supplier || supplier === "0") {
            $("#error_cmb_supplier_branch_id").text("Please fill this field...!");
            $("#error_cmb_supplier_branch_id").show();
            $("#cmb_supplier_branch_id").focus();
            return;
        }

        if ($('input:checkbox.selectSuppContactPerson:checked').length === 0) {
            $('#error_supplier_contact_persons').text('Please select atleast one contact person.');
            $('#error_txt_status_remark').focus();
            $('#error_supplier_contact_persons').show();
            setErrMsg('Please select atleast one contact person.');
            setShowErrorMsgModal(true);
            return;
        } else {
            $('#error_supplier_contact_persons').hide();
        }

        let headerDataIsValid = await validate.current.validateForm("gate_pass_form_id");
        if (!headerDataIsValid) { return false; }
        let issueDetailsIsValid = true;
        let addedIssuematerial = $('#gatepass-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add Atleast One Material Detail.');
            setShowErrorMsgModal(true);
            return issueDetailsIsValid = false;
        }
        else {
            for (let index = 0; index < materialGatePassDetailsData.length; index++) {
                if (!materialGatePassDetailsData[index].cost_center_id || materialGatePassDetailsData[index].cost_center_id === "") {
                   setErrMsg(`Select cost center for ${materialGatePassDetailsData[index]?.material_name}`)
                   setShowErrorMsgModal(true)
                   return issueDetailsIsValid = false;
                }
            };

            if(issueDetailsIsValid === true){ 
            const tableRows = $('#gatepass-details-tbl tbody tr');
            tableRows.each(function () {
                if (keyForViewUpdate === "add" || keyForViewUpdate === "update") {
                    const issueQty = parseFloat($(this).find('input[id^="outward_quantity_"]').val()) || 0;
                    if (isNaN(issueQty) || issueQty === "" || issueQty === "0" || issueQty === 0) {
                        $(this).find(`input[id^='outward_quantity_']`).parent().attr('data-tip', 'Quantity cannot be 0...!');
                        $(this).find(`input[id^='outward_quantity_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        return issueDetailsIsValid = false;
                    }
                }
            });
        }

            return issueDetailsIsValid;
        }
    }
    
    //-------------------------- Save Json ------------------------------------------------------------------

    const addMaterialGatePass = async () => {
        debugger

        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                let validateSelectBoxes = await FnValidateGatePass();
                checkIsValidate = validateSelectBoxes
            }

            if (checkIsValidate === true) {
                setIsLoading(true)
                let supplierContactPersonsIds = await FnGetSupplierContactPersonIds();
                let lastGatePassNo = txt_gate_pass_no
                if (keyForViewUpdate === "add") {
                    lastGatePassNo = await GenerateGatePassNo();
                }
                let json = { 'TransMaterialGatePassMasterDetails': {}, 'TransMaterialGatePassDetails': [], 'TransMaterialGatePassReturnSummary': [], 'commonIds': {} }

                const transferMasterFormData = {
                    material_gate_pass_master_id: keyForViewUpdate !== "add" ? material_gate_pass_master_id : 0,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    gate_pass_no: lastGatePassNo,
                    checker_by_id: cmb_checker_by_id,
                    financial_year: FINANCIAL_SHORT_NAME,
                    gate_pass_date: dt_gate_pass_date,
                    vehicle_no: txt_vehicle_no,
                    product_type_name: cmb_product_type_name,
                    product_type_id: cmb_product_type,
                    product_category1_id: cmb_product_category1_id,
                    product_category2_id: cmb_product_category2_id,
                    material_type: material_type,
                    supplier_branch_id: cmb_supplier_branch_id,
                    supplier_branch_state_id: cmb_supplier_state_id,
                    supplier_branch_city_id: cmb_supplier_city_id,
                    supplier_branch_contacts_id: supplierContactPersonsIds,
                    supplier_branch_address: suppAddress,
                    department_id: cmb_department_ref.current.value ?? cmb_department_id,
                    sub_department_id: cmb_subdepartment_ref.current.value ?? cmb_subdepartment_id,

                    return_status: "P",
                    checker_by_name: empCheckerComboRef.current.employee_name ?? UserName,
                    approved_by_id: keyForViewUpdate === "add" || keyForViewUpdate === "update"
                        ? null
                        : cmb_approved_by_id,
                    approved_by_name: keyForViewUpdate === "add" || keyForViewUpdate === "update"
                        ? null
                        : (empApprovedComboRef.current?.employee_name ?? cmb_approved_by_name),
                    gate_pass_status: cmb_gate_pass_status,
                    created_by: UserName,
                    created_on: todayDate(),
                    modified_by: UserName,
                    modified_on: todayDate(),
                    modified_by: idList !== "" ? UserName : null,

                }
                json.TransMaterialGatePassMasterDetails = transferMasterFormData;
                const materialIdSet = new Set();

                const filteredMaterialGatePassDetailsData = materialGatePassDetailsData.filter(row => row.gate_pass_item_status !== "RGP");

                for (let issueItem = 0; issueItem < filteredMaterialGatePassDetailsData.length; issueItem++) {
                    const gatePassDetailsRow = filteredMaterialGatePassDetailsData[issueItem];

                    const MaterialtransferRow = {};
                    MaterialtransferRow['material_gate_pass_details_id'] = keyForViewUpdate !== "add" ? gatePassDetailsRow.material_gate_pass_details_id : 0
                    MaterialtransferRow['material_gate_pass_master_id'] = keyForViewUpdate !== "add" ? material_gate_pass_master_id : 0
                    MaterialtransferRow['company_id'] = COMPANY_ID
                    MaterialtransferRow['company_branch_id'] = COMPANY_BRANCH_ID
                    MaterialtransferRow['financial_year'] = FINANCIAL_SHORT_NAME
                    MaterialtransferRow['gate_pass_no'] = lastGatePassNo
                    MaterialtransferRow['gate_pass_date'] = dt_gate_pass_date
                    MaterialtransferRow['product_material_id'] = gatePassDetailsRow.product_material_id
                    MaterialtransferRow['material_code'] = gatePassDetailsRow.product_code
                    MaterialtransferRow['material_name'] = gatePassDetailsRow.product_material_name
                    MaterialtransferRow['cost_center_id'] = gatePassDetailsRow.cost_center_id
                    MaterialtransferRow['outward_quantity'] = gatePassDetailsRow.outward_quantity
                    MaterialtransferRow['pending_inward_quantity'] = gatePassDetailsRow.pending_inward_quantity
                    MaterialtransferRow['outward_weight'] = gatePassDetailsRow.outward_weight
                    MaterialtransferRow['inward_quantity'] = gatePassDetailsRow.inward_quantity
                    MaterialtransferRow['inward_weight'] = gatePassDetailsRow.inward_weight
                    MaterialtransferRow['gate_pass_item_status'] = gatePassDetailsRow.gate_pass_item_status
                    MaterialtransferRow['return_item_status'] = "P"
                    MaterialtransferRow['product_type_id'] = cmb_product_type
                    MaterialtransferRow['product_type_name'] = cmb_product_type_name
                    MaterialtransferRow['rate'] = gatePassDetailsRow.rate
                    MaterialtransferRow['remark'] = gatePassDetailsRow.remark
                    MaterialtransferRow['purpose'] = gatePassDetailsRow.purpose
                    MaterialtransferRow['created_by'] = UserName
                    MaterialtransferRow['created_on'] = todayDate()
                    MaterialtransferRow['modified_by'] = UserName
                    MaterialtransferRow['modified_on'] = todayDate()

                    json.TransMaterialGatePassDetails.push(MaterialtransferRow);
                    if (gatePassDetailsRow.gate_pass_item_status !== "RGP" && gatePassDetailsRow.gate_pass_item_status !== "I" && gatePassDetailsRow.inward_quantity > 0) {
                        // Check if materialId already exists in returnDataArraysummary (which contains the materialId from previous rows)
                        const materialExistsInReturnSummary = returnDataArraysummary.some(summaryRow =>
                            summaryRow.product_material_id === gatePassDetailsRow.product_material_id
                        );

                        // If material is not found in returnDataArraysummary, add it to materialIdSet
                        if (!materialExistsInReturnSummary) {
                            materialIdSet.add(gatePassDetailsRow.product_material_id);
                        }
                    }
                }
                json.TransMaterialGatePassReturnSummary = returnDataArraysummary.map(item => ({
                    ...item,
                    gate_pass_item_status: 'RGP' // Add or overwrite the gate_pass_item_status field
                }));
                if (!json.TransMaterialGatePassReturnSummary) {
                    json.TransMaterialGatePassReturnSummary = [];
                }

                // Check and add missing entries in TransMaterialGatePassReturnSummary
                for (let issueItem = 0; issueItem < materialGatePassDetailsData.length; issueItem++) {
                    const gatePassDetailsRow = materialGatePassDetailsData[issueItem];

                    if (materialIdSet.has(gatePassDetailsRow.product_material_id)) {
                        const isPresent = json.TransMaterialGatePassReturnSummary.some(
                            (entry) => entry.product_material_id === gatePassDetailsRow.product_material_id && entry.inward_quantity === gatePassDetailsRow.inward_quantity
                        );

                        if (!isPresent) {

                            json.TransMaterialGatePassReturnSummary.push({
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                financial_year: FINANCIAL_SHORT_NAME,
                                material_gate_pass_master_id: material_gate_pass_master_id,
                                material_gate_pass_details_id: gatePassDetailsRow.material_gate_pass_details_id,
                                gate_pass_no: lastGatePassNo,
                                outward_quantity: parseFloat(gatePassDetailsRow.outward_quantity),
                                cost_center_id: parseFloat(gatePassDetailsRow.cost_center_id),
                                inward_quantity: parseFloat(gatePassDetailsRow.inward_quantity),
                                pending_inward_quantity: parseFloat(gatePassDetailsRow.pending_inward_quantity),
                                product_material_id: gatePassDetailsRow.product_material_id,
                                material_name: gatePassDetailsRow.product_material_name,
                                material_code: gatePassDetailsRow.product_code,
                                inward_remark: gatePassDetailsRow.remark,
                                inward_purpose: gatePassDetailsRow.purpose,
                                inward_date: dt_gate_pass_date,
                                created_by: UserName,
                                gate_pass_item_status: 'RGP',
                                modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                            });
                        }
                    }
                }
                // Common Ids

                json.commonIds.company_id = COMPANY_ID
                json.commonIds.keyForViewUpdate = keyForViewUpdate
                json.commonIds.transfer_no = keyForViewUpdate === 'add' ? lastGatePassNo : txt_gate_pass_no
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log('Material Gate Pass', json)

                const formData = new FormData();
                formData.append(`MaterialGatePassData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MaterialGatePass/FnAddUpdateRecord`, requestOptions);
                const responce = await apicall.json();

                console.log("response error: ", responce.data);
                if (responce.error === 0) {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {

                    setSuccMsg(responce.message);
                    setShowSuccessMsgModal(true);
                    if (responce.success == 1) {
                        setModalOrderDetails(` Gate Pass No: ${txt_gate_pass_no}`)
                    }
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }

    }


    // ------------------------------------------- set update data ----------------------------------------------------


    const FnCheckUpdateResponse = async () => {

        debugger
        setIsLoading(true)
        try {
            if (idList !== '') {
                setMaterialGatePassNo(idList.gate_pass_no)
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MaterialGatePass/FnShowParticularRecord/${parseInt(idList.material_gate_pass_master_id)}/${COMPANY_ID}`);
                const responce = await apiCall.json();
                console.log('Material gate pass  data: ', responce);

                let gatePassMasterdata = responce.MaterialGatePassMasterDetails;
                setGatePassMasterDataForPrint(responce.MaterialGatePassMasterDetails)

                if (responce.MaterialGatePassReturnSummary !== null && responce.MaterialGatePassReturnSummary !== "") {
                    setReturnDataArraySummary(responce.MaterialGatePassReturnSummary);
                }

                if (gatePassMasterdata !== null && gatePassMasterdata !== "") {
                    setMaterialGatePassNo(gatePassMasterdata.gate_pass_no)
                    setProductType(gatePassMasterdata.product_type_id)
                    setProductTypeName(gatePassMasterdata.product_type_name)
                    await comboOnChange("cmb_product_type")
                    setProductCategory1Id(gatePassMasterdata.product_category1_id)
                    category1ComboRef.current.value = gatePassMasterdata.product_category1_id
                    await comboOnChange('category1')
                    setProductCategory2Id(gatePassMasterdata.product_category2_id)
                    setDepartmentId(gatePassMasterdata.department_id);
                    cmb_department_ref.current.value = gatePassMasterdata.department_id;
                    let subDepartmentOptArr = await comboOnChange("Department")
                    setSubDepartmentId(gatePassMasterdata.sub_department_id);
                    cmb_subdepartment_ref.current.value = gatePassMasterdata.sub_department_id;
                    let costcenter = subDepartmentOptArr.find((subDep) => subDep.field_id === gatePassMasterdata.sub_department_id);
                    cmb_subdepartment_ref.current.costCenterGroup = costcenter.costCenterGroup;
                    await comboOnChange('SubDepartment');
                    setSupplierBranchId(gatePassMasterdata.supplier_branch_id)
                    supplierComboRef.current.value = gatePassMasterdata.supplier_branch_id;
                    supplierComboRef.current.label = gatePassMasterdata.supplier_name;
                    await comboOnChange('Supplier')
                    setSuppliersStateId(gatePassMasterdata.supplier_branch_state_id)
                    await comboOnChange("SupplierState")
                    setSuppliersCityId(gatePassMasterdata.supplier_branch_city_id)
                    setSuppAddress(gatePassMasterdata.supplier_branch_address)
                    await FnSetExistingSupplierContactPersonIds(gatePassMasterdata.supplier_branch_contacts_id);
                    setGatePassDate(gatePassMasterdata.gate_pass_date)
                    setMaterialType(gatePassMasterdata.material_type)
                    let gatePassStatus = '';

                    switch (keyForViewUpdate) {
                        case 'approve':
                            gatePassStatus = 'A'
                            break;

                        default:
                            gatePassStatus = gatePassMasterdata.gate_pass_status
                            break;
                    }
                    setCheckerById(gatePassMasterdata.checker_by_id)
                    setCheckerByName(gatePassMasterdata.checker_by_name)
                    empCheckerComboRef.current.employee_id = gatePassMasterdata.checker_by_id
                    empCheckerComboRef.current.employee_name = gatePassMasterdata.checker_by_name
                    if (keyForViewUpdate === "approve") {
                        setApprovedById(gatePassMasterdata.approved_by_id)
                        setApprovedByName(gatePassMasterdata.approved_by_name)
                        empApprovedComboRef.current.employee_id = gatePassMasterdata.approved_by_id ?? UserId
                        empApprovedComboRef.current.employee_name = gatePassMasterdata.approved_by_name
                        comboOnChange('ApprovedBy');
                    }
                    else if (keyForViewUpdate === "return" || keyForViewUpdate === "view") {
                        setApprovedById(gatePassMasterdata.approved_by_id)
                        setApprovedByName(gatePassMasterdata.approved_by_name)

                    }
                    setGatePassStatus(gatePassStatus)
                    setVehicleNo(gatePassMasterdata.vehicle_no)
                }

                if (responce.MaterialGatePassDetails !== null && responce.MaterialGatePassDetails !== "") {
                    const transformedData = responce.MaterialGatePassDetails.map((item) => ({
                        ...item,
                        product_code: item.material_code,
                        cost_center_id: item.cost_center_id,
                        product_material_name: item.material_name,
                        gate_pass_item_status: keyForViewUpdate === "approve" ? "A" : item.gate_pass_item_status,
                        pending_inward_quantity: item.outward_quantity - item.inward_quantity,
                        prev_inward_quantity: item.inward_quantity,
                        purpose: item.purpose,
                        inward_quantity:
                            keyForViewUpdate === "view"
                                ? item.inward_quantity // Show the actual value in view mode
                                : item.gate_pass_item_status === "I"
                                    ? 0 // Set to 0 if status is "I"
                                    : item.gate_pass_item_status === "A"
                                        ? item.outward_quantity // Set actual value if status is "A"
                                        : item.inward_quantity,

                    }));
                    setMaterialGatePassDetailsData(transformedData);
                }

            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
        finally {
            setIsLoading(false)
        }
    }

    const materialGatePassContent = {
        company: {
            company: '',
            company_branch: '',
        },
        materialDetails: {

        },
        header: {

        },
        footer: {

        },
        items: [],
    };

    const printIssueSlip = async (openPrintModal) => {
        let MaterialMasterData = gatePassMasterDataForPrint
        materialGatePassContent.materialDetails.gate_pass_no = MaterialMasterData.gate_pass_no
        materialGatePassContent.materialDetails.gate_pass_date = validateNumberDateInput.current.formatDateToDDMMYYYY(MaterialMasterData.gate_pass_date)
        materialGatePassContent.materialDetails.product_type_name = cmb_product_type_name

        //Company details
        materialGatePassContent.company.company = materialGatePassDetailsData[0].company_name
        materialGatePassContent.company.company_branch = materialGatePassDetailsData[0].company_branch_name

        materialGatePassContent.header.approved_date = dt_approve_date
        materialGatePassContent.header.material_type = MaterialMasterData.material_type_desc
        materialGatePassContent.header.vehicle_no = MaterialMasterData.vehicle_no
        materialGatePassContent.header.product_type_name = cmb_product_type_name
        materialGatePassContent.header.gate_pass_status = MaterialMasterData.gate_pass_status
        materialGatePassContent.header.approved_by_name = MaterialMasterData.approved_by_name
        materialGatePassContent.header.checker_by_name = MaterialMasterData.checker_by_name
        materialGatePassContent.header.supp_branch_name = MaterialMasterData.supp_branch_name
        materialGatePassContent.header.supp_contact_no = MaterialMasterData.supp_contact_no
        materialGatePassContent.header.state_name = MaterialMasterData.state_name
        materialGatePassContent.header.city_name = MaterialMasterData.city_name
        materialGatePassContent.header.supplier_branch_address = MaterialMasterData.supplier_branch_address
        materialGatePassContent.header.department_name = MaterialMasterData.department_name
        materialGatePassContent.header.sub_department_name = MaterialMasterData.sub_department_name

        for (let index = 0; index < materialGatePassDetailsData.length; index++) {
            const element = materialGatePassDetailsData[index];
            const detailsData = {
                sr_no: index + 1,
                financial_year: element.financial_year,
                company_id: element.company_id,
                company_branch_id: element.company_branch_id,
                product_material_id: element.product_material_id,
                material_code: element.material_code,
                material_name: element.material_name,
                outward_quantity: element.outward_quantity,
                cost_center_id: element.cost_center_id,
                cost_center_name: element.cost_center_name,
                outward_weight: element.outward_weight,
                inward_quantity: element.inward_quantity,
                inward_weight: element.inward_weight,
                pending_inward_quantity: element.pending_inward_quantity,
                gate_pass_item_status: element.gate_pass_item_status,
                product_type_id: element.product_type_id,
                product_type_name: element.product_type_name,
                rate: element.rate,
                remark: element.remark,
                purpose: element.purpose
            }
            materialGatePassContent.items.push(detailsData)
            console.log(detailsData);
        }

        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: materialGatePassContent, navigationLink: '/Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialGatePassEntry', invoiceType: 'MGP', title: 'Requisition/Issue', idList: idList, keyForViewUpdate: keyForViewUpdate } })
        } else {
            return renderToString(<MaterialGatePassInvoice invoiceContent={materialGatePassContent} />);
        }
    };

    const refreshData = async (key) => {

        switch (key) {
            case 'get_supplier':
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "supp_branch_state_id", "supp_branch_city_id",
                    "state_name", "city_name"
                ];
                globalQuery.table = "cmv_supplier_branch";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "supp_branch_type", operator: "=", value: "Supplier" });
                const suppliersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                const suppliers = [
                    { value: '', label: 'Select', supp_branch_state_id: '', supp_branch_city_id: '', state_name: '', city_name: '', },
                    { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '' },
                    ...suppliersApiCall.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, })),
                ];
                setSupplierList(suppliers);

                break;

            default:
                break;
        }
    }
    const renderSupplierContactTable = useMemo(() => {
        return <Datatable data={SuppContactDetails} columns={SuppContactDetailsCols} />
    }, [SuppContactDetails, SuppContactDetailsCols])

    function toggleChkAllBoxes(key) {
        switch (key) {
            case 'selectAllSuppContact':
                $('.selectSuppContactPerson').prop('checked', $('#selectAllSuppContact').is(":checked"));
                break;
            case 'PartiallySuppContactSelection':
                $('#selectAllSuppContact').prop('checked', $('input:checkbox.selectSuppContactPerson:checked').length === $('input:checkbox.selectSuppContactPerson').length);
                break;
        }
    }

    const FnSetFieldsByUserAccess = () => {
        if (keyForViewUpdate != null) {
            switch (keyForViewUpdate) {
                case 'view':
                    $('input[name="selectSuppContactPerson"]').prop('disabled', true);
                    $('input[name="selectAllSuppContact"]').prop('disabled', true);
                    break;
                case 'approve':
                    $('input[name="selectSuppContactPerson"]').prop('disabled', true);
                    $('input[name="selectAllSuppContact"]').prop('disabled', true);
                    break;
                default: break;
            }
        }
    }

    const FnGetSupplierContacts = async (SelectedSupplierBranch) => {

        if (SelectedSupplierBranch !== '' || SelectedSupplierBranch !== '0') {
            resetGlobalQuery();
            globalQuery.columns = ["supplier_id", "supplier_branch_name", "supp_branch_id", "supplier_contact_id",
                "supp_contact_person", "supp_contact_no", "supp_alternate_contact", "supp_email_id", "supp_alternate_EmailId", "supp_designation"]

            globalQuery.table = "cmv_supplier_contacts";
            globalQuery.conditions.push({ field: "supp_branch_id", operator: "=", value: SelectedSupplierBranch });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

            if (getContactDtlsApiCall.length !== 0) {
                let column = [];
                let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                    if (colKey === 0) {
                        column.push({
                            Headers: <div className="col-1"><input type='checkbox' class="me-1" name="selectAllSuppContact" id="selectAllSuppContact" onClick={(e) => { toggleChkAllBoxes("selectAllSuppContact"); }} />Select</div>,
                            accessor: "Action",
                            Cell: row => (
                                <div className="text-center">
                                    <input type='checkbox' class="selectSuppContactPerson" name="selectSuppContactPerson" id={'selectSuppContactPerson_' + row.original.supplier_contact_id} contact_personId={row.original.supplier_contact_id} value={row.original.supplier_contact_id} />
                                </div>
                            ),
                        });
                    }
                    if (!columnHeads[colKey].includes('supplier_id') && !columnHeads[colKey].includes('supplier_contact_id') && !columnHeads[colKey].includes('supp_branch_id')) {
                        column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                    }
                }
                setSuppContactDetailsCols(column);
                setSuppContactDetails(getContactDtlsApiCall);

                const supplierContactId = getContactDtlsApiCall[0].supplier_contact_id;
                setSupplierId(getContactDtlsApiCall[0].supplier_id)
                $('input[type="checkbox"][id="selectSuppContactPerson_' + supplierContactId + '"]').prop('checked', true);

                if (getContactDtlsApiCall.length === 1 && document.querySelector('.selectSuppContactPerson').checked) {
                    toggleChkAllBoxes('PartiallySuppContactSelection'); // Check all checkboxes by default
                }
            } else {
                setSuppContactDetailsCols([]);
                setSuppContactDetails([]);
            }
        }
        // Onchange event listener for the customer contact Selection
        $('.selectSuppContactPerson').on('change', function () {
            toggleChkAllBoxes('PartiallySuppContactSelection');
        });

    }

    const FnGetSupplierContactPersonIds = async () => {
        let supplierContactPersonsIds = '';
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            supplierContactPersonsIds += $(this).val() + ":";
        });
        return supplierContactPersonsIds.replace(/:$/, '');
    }

    const FnSetExistingSupplierContactPersonIds = async (contactIds) => {

        const supplierContactPersonsIds = contactIds.split(':');
        supplierContactPersonsIds.forEach(function (contactId, index) {
            $('#selectSuppContactPerson_' + supplierContactPersonsIds[index]).prop('checked', true);
        });
        toggleChkAllBoxes('PartiallySuppContactSelection');
    }


    // ------------------------ Changes for the Searchable Combos. --------------------------

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'> Material Gate Pass{actionType}</label>
                        </div>
                        <form id="gate_pass_form_id">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label"> Material Type<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check className="erp_radio_button" label="Returnable" type="radio" value="RGP" name="materialType"
                                                        checked={material_type === 'RGP'} defaultChecked onClick={() => { setMaterialType('RGP'); }} />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check className="erp_radio_button" label="Non-Returnable" value="NRGP" type="radio" name="materialType"
                                                        checked={material_type === 'NRGP'} onClick={() => { setMaterialType('NRGP'); }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Product Type<span className="required">*</span> </Form.Label>
                                        </div>

                                        <div className='col-sm-8 col-12'>
                                            <select id="cmb_product_type"
                                                className="form-select form-select-sm"
                                                value={cmb_product_type}
                                                placeholder="Search for Product Type"
                                                onChange={(e) => {
                                                    setProductType(e.target.value)
                                                    comboOnChange("cmb_product_type")
                                                    validateFields();
                                                }}
                                                disabled={!['add'].includes(keyForViewUpdate)}>
                                                {productTypeOptions.map((typeOpt) => <option shortName={typeOpt.product_type_short_name} value={typeOpt.value}>{typeOpt.label}</option>)}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_product_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Category 1:</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <Select
                                                ref={category1ComboRef}
                                                inputId="cmb_product_category1_id"
                                                value={category1Options.find(option => option.value === cmb_product_category1_id) || null}
                                                options={category1Options}
                                                isDisabled={keyForViewUpdate !== "add" && keyForViewUpdate !== "update"}
                                                onChange={(selectedOption) => {
                                                    setProductCategory1Id(selectedOption.value)
                                                    category1ComboRef.current = selectedOption;
                                                    comboOnChange('category1');
                                                }
                                                }
                                                placeholder="Search for Category1..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"

                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    })
                                                }}
                                            />

                                            <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Category 2:</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <Select
                                                ref={category2ComboRef}
                                                inputId="cmb_product_category2_id"
                                                value={category2Options.find(option => option.value === cmb_product_category2_id) || null}
                                                options={category2Options}
                                                isDisabled={keyForViewUpdate !== "add" && keyForViewUpdate !== "update"}
                                                onChange={(selectedOption) => {
                                                    setProductCategory2Id(selectedOption.value)
                                                    category2ComboRef.current = selectedOption;
                                                    comboOnChange('category2');
                                                }
                                                }
                                                placeholder="Search for Category2..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"

                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Gate Pass No & Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <div className="row">
                                                <div className='col-12 col-md-6 pe-md-0'>
                                                    <Form.Control type="text" id="txt_gate_pass_no" className="erp_input_field" value={txt_gate_pass_no} optional='optional' disabled />
                                                </div>

                                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_gate_pass_date'
                                                        className="erp_input_field" value={dt_gate_pass_date}
                                                        onChange={e => {
                                                            setGatePassDate(e.target.value);
                                                            validateFields();
                                                        }}
                                                        min={currentDate}
                                                        Disabled={keyForViewUpdate === "add" || keyForViewUpdate === "update" ? false : true}
                                                    />
                                                    <MDTypography variant="button" id="error_dt_gate_pass_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Select ref={cmb_department_ref}
                                                options={departmentOptions}
                                                inputId="cmb_department_id"
                                                value={departmentOptions.length > 0 ? departmentOptions.find(option => option.value === cmb_department_id) : null}
                                                onChange={(selectedOpt) => {
                                                    cmb_department_ref.current = selectedOpt;
                                                    setDepartmentId(selectedOpt.value);
                                                    comboOnChange('Department');
                                                    validateFields();

                                                }}
                                                placeholder="Search for a Department"
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row  mb-1'>

                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Sub-Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Select ref={cmb_subdepartment_ref}
                                                options={subDepartmentOptions}
                                                inputId="cmb_subdepartment_id" // Provide the ID for the input box
                                                value={subDepartmentOptions.length > 1 ? subDepartmentOptions.find(option => option.value === cmb_subdepartment_id) : null}
                                                onChange={(selectedOpt) => {
                                                    cmb_subdepartment_ref.current = selectedOpt;
                                                    setSubDepartmentId(selectedOpt.value);
                                                    comboOnChange("SubDepartment");
                                                    validateFields();
                                                }}
                                                placeholder="Search for a Sub-Department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_subdepartment_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {!(keyForViewUpdate === "add" || keyForViewUpdate === "update") && cmb_gate_pass_status === "A" &&
                                        <div className="row">
                                            <div className='col-sm-4 col-12'>
                                                <Form.Label className="erp-form-label">Approve Date<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-8 col-12">
                                                <Form.Control type="date" id='dt_gate_pass_date'
                                                    className="erp_input_field" value={dt_approve_date}
                                                    Disabled={true}
                                                />
                                                <MDTypography variant="button" id="error_dt_gate_pass_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>}
                                </div>
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Checker Name<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12 mb-1'>
                                            <Select ref={empCheckerComboRef}
                                                options={employeeOptions}
                                                isDisabled={!["add", "update"].includes(keyForViewUpdate)}
                                                inputId="cmb_checker_by_id" // Provide the ID for the input box
                                                value={employeeOptions.find(option => option.value == cmb_checker_by_id)}
                                                onChange={(selectedOpt) => {
                                                    setCheckerById(selectedOpt.value);
                                                    empCheckerComboRef.current = selectedOpt;
                                                    validateFields();
                                                    comboOnChange('CheckerBy');
                                                }}
                                                placeholder="Search for a employee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />

                                            <MDTypography variant="button" id="error_cmb_checker_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Gate Pass Status & Vehicle No.</Form.Label>
                                        </div>
                                        <div className="col-sm-4">
                                            <Form.Control as="select"
                                                id='cmb_gate_pass_status'
                                                className="erp_input_field"
                                                value={cmb_gate_pass_status}
                                                Disabled='true'
                                                style={{ paddingBottom: "0px" }}
                                                onChange={(e) => setGatePassStatus(e.target.value)}>
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                            </Form.Control>
                                            <MDTypography
                                                variant="button"
                                                id="error_cmb_gate_pass_status"
                                                className="erp_validation error-msg"
                                                fontWeight="regular"
                                                color="error"
                                                style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-4">
                                            <Form.Control type="text" id='txt_vehicle_no' className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); validateFields(); }} optional="optional" maxlength="500" />
                                            <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Supplier<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col mb-1'>
                                            <Select ref={supplierComboRef}
                                                options={supplierList}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_supplier_branch_id"
                                                value={supplierList.find(option => option.value === cmb_supplier_branch_id)}
                                                onChange={(selectedOpt) => {
                                                    setSupplierBranchId(selectedOpt.value);
                                                    supplierComboRef.current = selectedOpt;
                                                    comboOnChange("Supplier");
                                                    validateFields();
                                                }}
                                                placeholder="Search for a supplier..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_supplier_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {
                                            (keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve') &&
                                            <div className="col-sm-1 col-2">
                                                <Tooltip title="Refresh" placement="top">
                                                    <MDTypography>
                                                        <MdRefresh style={{ color: 'black' }} onClick={() => { refreshData('get_supplier') }} />
                                                    </MDTypography>
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Supplier State & City</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_supplier_state_id" className="form-select form-select-sm" value={cmb_supplier_state_id} optional='optional' disabled>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {stateList?.map(SupplierState => (
                                                    <option value={SupplierState.field_id}>{SupplierState.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                        <div className='col'>
                                            <select id="cmb_supplier_city_id" className="form-select form-select-sm" value={cmb_supplier_city_id} optional='optional' disabled >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {cityList?.map(Suppliercity => (
                                                    <option value={Suppliercity.field_id}>{Suppliercity.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Supplier Address</Form.Label>
                                        </div>
                                        <div className="col-sm-8">
                                            <Form.Control id='txt_supp_address' as="textarea" rows={2} maxLength="255" className="erp_txt_area" value={suppAddress} onChange={(e) => { setSuppAddress(e.target.value); validateFields(); }} disabled optional="optional" />
                                            <MDTypography variant="button" id="error_txt_supp_address" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>

                                    {!(keyForViewUpdate === "add" || keyForViewUpdate === "update") && cmb_gate_pass_status === "A" && (
                                        <div className='row'>
                                            <div className='col-sm-4 col-12'>
                                                <Form.Label className="erp-form-label">Approved By<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className='col-sm-8 col-10 mb-1'>
                                                <Select ref={empApprovedComboRef}
                                                    options={employeeOptions}
                                                    isDisabled={!['add'].includes(keyForViewUpdate)}
                                                    inputId="cmb_approved_by_id" // Provide the ID for the input box
                                                    value={employeeOptions.find(option => option.value == cmb_approved_by_id)}
                                                    onChange={(selectedOpt) => {
                                                        setApprovedById(selectedOpt.value);
                                                        empApprovedComboRef.current = selectedOpt;
                                                        validateFields();
                                                        comboOnChange('ApprovedBy');
                                                    }}
                                                    placeholder="Search for a employee..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select"
                                                    styles={{
                                                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>

                                        </div>
                                    )}

                                    <div className="row mt-1">
                                        <Accordion defaultActiveKey="1" >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="erp-form-label-md">Supplier's Contact Details</Accordion.Header>
                                                <Accordion.Body >
                                                    {
                                                        cmb_supplier_branch_id !== '' && cmb_supplier_branch_id !== "0"
                                                            ? <>
                                                                <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>
                                                                    <div className={` d-flex justify-content-end align-items-center`}>
                                                                        <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                            localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                            localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: cmb_supplier_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                        }
                                                                        }>  <Link className={'text-light'} to={{ pathname: "/Masters/FrmSupplier" }} target="_blank" >Add</Link></MDButton>
                                                                        <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetSupplierContacts(cmb_supplier_branch_id) }}>Refresh</MDButton>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    {SuppContactDetails.length !== 0 ?
                                                        <>
                                                            {renderSupplierContactTable}
                                                        </>
                                                        : <div className='row'>
                                                            <div className="col-12">
                                                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                                    No Records Found...
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion >
                                        <MDTypography variant="button" id="error_supplier_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openIssueDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={(e) => setOpenIssueDetailsAccord(!openIssueDetailsAccord)} className="erp-form-label-md"> Material Details</Accordion.Header>
                            <Accordion.Body>
                                <div className={`row ${keyForViewUpdate === "add" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>
                                    <div className="col-sm-4 col-12">
                                        <div className={`row`}>
                                            <div className="col-md-8 col-12">
                                                <SearchItemFilter getSearchData={fnSearchMaterialData} onChange={validateFields} formIdForValidate="gate_pass_form_id" requiredCols={requiredCols} company_id={COMPANY_ID} product_type_id={cmb_product_type} godownId={"2"} product_category1_id={cmb_product_category1_id} product_category2_id={cmb_product_category2_id} />
                                                <MDTypography variant="button" id="error_txt_product_material_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="erp-Mt-10" style={{ vh: "5rem" }}>
                                    {materialGatePassDetailsData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> :
                                        <div className='row  text-center'>
                                            <div className="col-12 ">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                </>
                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton
                        className={`erp-gb-button erp_MLeft_btn ${(keyForViewUpdate === 'view' && cmb_gate_pass_status === "A") ? '' : 'd-none'}`}
                        variant="button"
                        fontWeight="regular"
                        id="printbtn_id"
                        onClick={() => { printIssueSlip(true); }} >
                        Print <FiDownload className="erp-download-icon-btn" />
                    </MDButton>

                    <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate != 'view' ? 'display' : 'd-none'}`} variant="button" onClick={() => { addMaterialGatePass() }}
                        fontWeight="regular"> {actionLabel}</MDButton>
                </div >
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

            </DashboardLayout >
        </>
    )
}

export default FrmMaterialGatePassEntry;