import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import $, { type } from 'jquery';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { Form, Table, Tabs, Card } from 'react-bootstrap';
import Select from "react-select";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useReducer } from 'react';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import ExcelExport from "Features/Exports/ExcelExport";
import * as XLSX from "xlsx-js-style";
import ReactDOMServer from 'react-dom/server';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

function FrmBalesMixingChartEntry() {
    // debugger
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, UserName, UserId, FINANCIAL_SHORT_NAME } = configConstants;

    const { state } = useLocation();
    const { mixing_chart_no = '0', keyForViewUpdate } = state || {}

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const todayWordFormat = () => {
        const now = new Date();
        const month = now.toLocaleString('en-US', { month: 'short' }); // Get abbreviated month (e.g., "Feb")
        const year = String(now.getFullYear()).slice(-2); // Get last two digits of the year (e.g., "25" for 2025)
        return `${month}-${year}`;
    };
    const currentDate = new Date().toISOString().split('T')[0];

    const [isLoading, setIsLoading] = useState(false);
    // useRef Hooks
    const comboDataAPiCall = useRef(null);
    const lotComboRef = useRef(null);
    const navigate = useNavigate();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const plantIdRef = useRef(null);
    let calulationsForMXC = useRef(false);
    let updatedIndexOfMixingChart = useRef(0);
    const exlsExp = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);
    const printComponentRef = useRef();


    //useState Hooks for Mixing Chart
    const [cmb_lot_no, setLotNo] = useState();
    const [dt_mixing_chart_date, setMixingChartDt] = useState(today);
    const [txt_mixing_chart_no, setMixingChartNo] = useState(mixing_chart_no);
    const [cmb_unit_plant_id, setUnitPlantId] = useState();
    const [cmb_mixer_by_id, setMixerById] = useState();

    const [unitPlantOptions, setunitPlantOptions] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [lotNoOptions, setLotNoOptions] = useState([]);

    const [selectedLotNos, SetSelectedLotNo] = useState([]);

    // const [stockAgainstLotNo, setStockAgainstLotNosDetails] = useState([]);
    // const [stockLogsAgainstLotNo, setStockLogsAgainstLotNosDetails] = useState([]);
    const [mixingChartCalculations, setMixingChartCalculations] = useState([]);
    let headers = ["AVG", "MAX", "MIN", "RANGE", "CV", "WEIGHTAGE AVG"]

    let blankObjforStdVals = { 'max_uhml': 0, 'min_uhml': 0, 'max_ul': 0, 'min_ul': 0, 'max_mi': 0, 'min_mi': 0, 'max_mic': 0, 'min_mic': 0, 'max_str': 0, 'min_str': 0, 'max_rd': 0, 'min_rd': 0, 'max_b_plus': 0, 'min_b_plus': 0, 'max_total_neps': 0, 'min_total_neps': 0, 'max_sc_n': 0, 'min_sc_n': 0, 'max_sfc_n': 0, 'min_sfc_n': 0, 'max_trash': 0, 'min_trash': 0, 'max_moisture': 0, 'min_moisture': 0 }
    const [mixingchartstandardValues, setMixingChartStdVales] = useState({ ...blankObjforStdVals });

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        let Path = `/Transactions/TCottonBalesManagement/TBalesMixingChart/FrmBalesMixingChartListing`
        navigate(Path)
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true)
            await fillComboBox();
            if (mixing_chart_no === '') {
                await FnGetLastMixingNo()
            } else {
                await FnCheckUpdateResponse()
            }
            setIsLoading(false)
        }
        loadDataOnload();
    }, []);


    const fillComboBox = async () => {
        try {
            //Distinct lot no
            resetGlobalQuery();
            globalQuery.columns.push("DISTINCT batch_no");
            globalQuery.table = "sm_product_material_stock_new";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.conditions.push({ field: "closing_balance_quantity", operator: ">", value: 0 });
            globalQuery.conditions.push({ field: "quality_status", operator: "IN", values: ['C', 'I'] });
            globalQuery.orderBy = ['batch_no ASC']
            const lotApiData = await comboDataAPiCall.current?.fillFiltersCombo(globalQuery);
            const lotOptions = [
                { value: '', label: 'Select' },
                ...lotApiData.map((grn) => ({ ...grn, value: grn.batch_no, label: grn.batch_no })),
            ];
            setLotNoOptions(lotOptions);

            resetGlobalQuery();
            globalQuery.columns.push("plant_id", "plant_name", "plant_short_name");
            globalQuery.table = "cm_plant";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const plantNoApiData = await comboDataAPiCall.current?.fillFiltersCombo(globalQuery);
            setunitPlantOptions(plantNoApiData);

            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_name"]
            globalQuery.table = "cm_employee";
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const getEmployeesApiCall = await comboDataAPiCall.current?.fillFiltersCombo(globalQuery);
            const getEmployees = [
                { value: '', label: 'Select', },
                ...getEmployeesApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
            ];
            setEmployeeList(getEmployees);


            // Show Prev Mixing Chart Details
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "update") {
                let lotNos = [];
                const getStockData = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CottonBalesMixingChart/FnGetPrevMixingChartDetails/${parseInt(COMPANY_ID)}?mixing_chart_no=${mixing_chart_no}`);
                const responce = await getStockData.json();
                if (responce['MixingChartStandardValues']) {
                    setMixingChartStdVales(responce['MixingChartStandardValues']);
                }
                // let StockNewData = responce['StockNewData']; let StockLogData = responce['StockLogData'];
                // setStockAgainstLotNosDetails((prevData) => [...prevData, ...StockNewData]);
                // setStockLogsAgainstLotNosDetails((prevData) => [...prevData, ...StockLogData]);
                let updatedQtTestingData = responce['QualityTestingData'].map((data) => (
                    lotNos.push(data.batch_no) && {
                        ...data,
                        closing_balance_quantity: data['remaining_quantity'],
                        closing_balance_weight: data['remaining_weight'],
                        issue_quantity: 0,
                        issue_weight: 0,
                        actual_issue_weight: 0,
                        difference_weight: 0,
                        stock_quantity: 0,
                        stock_weight: 0
                    }
                ));
                SetSelectedLotNo(lotNos);
                calulationsForMXC.current = true;
                actionMixingChart({ type: 'updateTotData', updatedData: updatedQtTestingData });

            }
        } catch (error) {

        }
    }

    const FnCheckUpdateResponse = async () => {
        debugger
        try {
            setIsLoading(true)
            let MixingChartData;
            let responce;
            let lotNos = [];
            if (keyForViewUpdate === 'view') {
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CottonBalesMixingChart/FnShowParticularRecordForUpdate/${parseInt(COMPANY_ID)}?mixing_chart_no=${mixing_chart_no}`);
                responce = await apiCall.json();
                
                MixingChartData = responce['data'];
                calulationsForMXC.current = true;
                actionMixingChart({ type: 'updateTotData', updatedData: MixingChartData });
            } else if (keyForViewUpdate === 'update') {
                const getStockData = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CottonBalesMixingChart/FnGetPrevMixingChartDetails/${parseInt(COMPANY_ID)}?mixing_chart_no=${mixing_chart_no}`);
                responce = await getStockData.json();
                MixingChartData = responce['QualityTestingData'].map((data) => {
                    lotNos.push(data.batch_no);

                    let weight_per_bale = data['remaining_quantity'] > 0
                        ? data['remaining_weight'] / data['remaining_quantity']
                        : 0; // Avoid division by zero
                    let closing_balance_quantity = data['remaining_quantity'] + data['issue_quantity'];
                    let closing_balance_weight = closing_balance_quantity * weight_per_bale;
                    let stock_quantity = closing_balance_quantity - data['issue_quantity'];
                    let stock_weight = stock_quantity * weight_per_bale;
                    return {
                        ...data,
                        weight_per_bale,
                        closing_balance_quantity,
                        closing_balance_weight,
                        stock_quantity,
                        stock_weight
                    };
                });

                SetSelectedLotNo(lotNos);
                calulationsForMXC.current = true;
                actionMixingChart({ type: 'updateTotData', updatedData: MixingChartData });
            }
            if (responce['MixingChartStandardValues']) {
                setMixingChartStdVales(responce['MixingChartStandardValues']);
            }

            setMixingChartNo(MixingChartData[0]['mixing_chart_no']);
            setMixingChartDt(new Date(MixingChartData[0]['mixing_chart_date']).toISOString().split('T')[0]);
            setMixerById(MixingChartData[0]['mixing_by_id']);
            setUnitPlantId(MixingChartData[0]['plant_id']);
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }


    // Function to update Mixing Chart Details 
    const FnUodateMixingChart = (state, action) => {
        // debugger
        switch (action.type) {
            case "add":
                return [...state, action.newData];

            case "remove":
                // setStockAgainstLotNosDetails((prevData) => prevData.filter((data) => data.batch_no !== action.lot_no));
                // setStockLogsAgainstLotNosDetails((prevData) => prevData.filter((data) => data.batch_no !== action.lot_no));
                SetSelectedLotNo((prevData) => prevData.filter((lotNo) => lotNo !== action.lot_no));
                setMixingChartCalculations([]);
                calulationsForMXC.current = true;
                return state.filter((_, i) => i !== action.index);

            case "update":
                return state.map((item, i) =>
                    i === action.index ? { ...item, ...action.newData } : item
                );

            case "updateTotData":
                return action.updatedData.length > 0 ? action.updatedData : [];

            default:
                return state;
        }
    }

    let defaultMixingChartDetails = [];
    const [balesMixingChartDetails, actionMixingChart] = useReducer(FnUodateMixingChart, defaultMixingChartDetails);

    const renderBalesMixingChartTbl = useMemo(() => {
        return <Table className='erp_table' id="balesmixingchartTblId" ref={printComponentRef} hover bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <td className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} style={{ width: "25px" }}>Action</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Sr. No.</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Supplier<br></br>Batch No</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Lot No</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>UHML</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>ML</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>UI</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>MIC</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>STR</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>RD</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>B+</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>TOTAL<br /> NEPS</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>SC(N)</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>SFC(N)</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>TRASH</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>MOISTURE</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>CG</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Quality Status</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Closing <br /> Bal. Qty.</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Closing <br /> Bal. Wt.</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>Issue <br /> Quantity</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Issue <br /> Weight</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>Actual <br />Issue Wt.</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Difference <br /> Weight</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Stock <br /> Quantity</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Stock <br /> Weight</td>

                </tr>
            </thead>
            <tbody>
                <tr className="hidetr" style={{ height: '70px' }}>
                    <td className='erp_table_head erp_table_th' colSpan={keyForViewUpdate === 'view' ? "3" : "4"}>MIN <br></br> MAX</td>
                    <td className="erp_table_td">
                        {/* UHML */}
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_uhml"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_uhml}
                                onChange={(e) => updateMixingStandardValues(e, 'min_uhml')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_uhml"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_uhml}
                                onChange={(e) => updateMixingStandardValues(e, 'max_uhml')}
                            />
                        </div>
                    </td>
                    {/* MI */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_mi"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_mi}
                                onChange={(e) => updateMixingStandardValues(e, 'min_mi')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_mi"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_mi}
                                onChange={(e) => updateMixingStandardValues(e, 'max_mi')}
                            />
                        </div>
                    </td>

                    {/* UL */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_ul"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_ul}
                                onChange={(e) => updateMixingStandardValues(e, 'min_ul')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_ul"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_ul}
                                onChange={(e) => updateMixingStandardValues(e, 'max_ul')}
                            />
                        </div>
                    </td>

                    {/* MIC */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_mic"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_mic}
                                onChange={(e) => updateMixingStandardValues(e, 'min_mic')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_mic"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_mic}
                                onChange={(e) => updateMixingStandardValues(e, 'max_mic')}
                            />
                        </div>
                    </td>
                    {/* STR */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_str"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_str}
                                onChange={(e) => updateMixingStandardValues(e, 'min_str')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_str"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_str}
                                onChange={(e) => updateMixingStandardValues(e, 'max_str')}
                            />
                        </div>
                    </td>
                    {/* RD */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_rd"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_rd}
                                onChange={(e) => updateMixingStandardValues(e, 'min_rd')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_rd"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_rd}
                                onChange={(e) => updateMixingStandardValues(e, 'max_rd')}
                            />
                        </div>
                    </td>
                    {/* B PLUS */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_b_plus"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_b_plus}
                                onChange={(e) => updateMixingStandardValues(e, 'min_b_plus')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_b_plus"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_b_plus}
                                onChange={(e) => updateMixingStandardValues(e, 'max_b_plus')}
                            />
                        </div>
                    </td>
                    {/* TOTAL NEPS */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_total_neps"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_total_neps}
                                onChange={(e) => updateMixingStandardValues(e, 'min_total_neps')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_total_neps"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_total_neps}
                                onChange={(e) => updateMixingStandardValues(e, 'max_total_neps')}
                            />
                        </div>
                    </td>
                    {/* SC_N */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_sc_n"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_sc_n}
                                onChange={(e) => updateMixingStandardValues(e, 'min_sc_n')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_sc_n"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_sc_n}
                                onChange={(e) => updateMixingStandardValues(e, 'max_sc_n')}
                            />
                        </div>
                    </td>
                    {/* SFC_N */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_sfc_n"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_sfc_n}
                                onChange={(e) => updateMixingStandardValues(e, 'min_sfc_n')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_sfc_n"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_sfc_n}
                                onChange={(e) => updateMixingStandardValues(e, 'max_sfc_n')}
                            />
                        </div>
                    </td>
                    {/* TRASH */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_trash"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_trash}
                                onChange={(e) => updateMixingStandardValues(e, 'min_trash')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_trash"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_trash}
                                onChange={(e) => updateMixingStandardValues(e, 'max_trash')}
                            />
                        </div>
                    </td>
                    {/* MOISTURE */}
                    <td className="erp_table_td">
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="min_moisture"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.min_moisture}
                                onChange={(e) => updateMixingStandardValues(e, 'min_moisture')}
                            />
                            <input
                                type="text"
                                disabled={keyForViewUpdate === 'view'}
                                className="erp_input_field_table_txt text-end"
                                id="max_moisture"
                                style={{ width: "100%", backgroundColor: "#AFE1AF" }}
                                value={mixingchartstandardValues.max_moisture}
                                onChange={(e) => updateMixingStandardValues(e, 'max_moisture')}
                            />
                        </div>
                    </td>
                    <td className='erp_table_td' colSpan={"10"}></td>
                </tr>
                {balesMixingChartDetails.length > 0 ? (
                    balesMixingChartDetails?.map((details, indexOfItem) => (
                        <tr rowIndex={indexOfItem}>
                            <td className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                                <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => { actionMixingChart({ type: "remove", index: indexOfItem, lot_no: details.batch_no }) }} />
                            </td>
                            <td className="erp_table_td">{indexOfItem + 1}</td>
                            <td className="erp_table_td">{details.supplier_batch_no}</td>
                            <td className="erp_table_td">{details.batch_no}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.uhml < mixingchartstandardValues.min_uhml || details.uhml > mixingchartstandardValues.max_uhml) ? 'lightcoral' : '' }}>{details.uhml}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.mi < mixingchartstandardValues.min_mi || details.mi > mixingchartstandardValues.max_mi) ? 'lightcoral' : '' }}>{details.mi}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.ul < mixingchartstandardValues.min_ul || details.ul > mixingchartstandardValues.max_ul) ? 'lightcoral' : '' }}>{details.ul}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.mic < mixingchartstandardValues.min_mic || details.mic > mixingchartstandardValues.max_mic) ? 'lightcoral' : '' }}>{details.mic}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.str < mixingchartstandardValues.min_str || details.str > mixingchartstandardValues.max_str) ? 'lightcoral' : '' }}>{details.str}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.rd < mixingchartstandardValues.min_rd || details.rd > mixingchartstandardValues.max_rd) ? 'lightcoral' : '' }}>{details.rd}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.b_plus < mixingchartstandardValues.min_b_plus || details.b_plus > mixingchartstandardValues.max_b_plus) ? 'lightcoral' : '' }}>{details.b_plus}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.total_neps < mixingchartstandardValues.min_total_neps || details.total_neps > mixingchartstandardValues.max_total_neps) ? 'lightcoral' : '' }}>{details.total_neps}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.sc_n < mixingchartstandardValues.min_sc_n || details.sc_n > mixingchartstandardValues.max_sc_n) ? 'lightcoral' : '' }}>{details.sc_n}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.sfc_n < mixingchartstandardValues.min_sfc_n || details.sfc_n > mixingchartstandardValues.max_sfc_n) ? 'lightcoral' : '' }}>{details.sfc_n}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.trash < mixingchartstandardValues.min_trash || details.trash > mixingchartstandardValues.max_trash) ? 'lightcoral' : '' }}>{details.trash}</td>
                            <td className="erp_table_td" style={{ backgroundColor: (details.moisture < mixingchartstandardValues.min_moisture || details.moisture > mixingchartstandardValues.max_moisture) ? 'lightcoral' : '' }}>{details.moisture}</td>
                            <td className="erp_table_td">{details.cg}</td>
                            <td className="erp_table_td">{details.quality_testing_status === "C" ? "Consumable" : details.quality_testing_status === "I" ? "Partially-Rejected" : "Rejected"}</td>
                            <td className="erp_table_td">{details.closing_balance_quantity}</td>
                            <td className="erp_table_td">{details.closing_balance_weight}</td>
                            <td className="erp_table_td">
                                <input
                                    type="text" disabled={keyForViewUpdate === 'view'}
                                    className="erp_input_field_table_txt remove0 mb-0 text-end"
                                    id={`issue_quantity_${indexOfItem}`}
                                    style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                    value={details.issue_quantity} onChange={(e) => updateMixingChart('issue_quantity', details, e)}
                                />
                            </td>
                            <td className="erp_table_td">{details.issue_weight}</td>
                            <td className="erp_table_td">
                                <input
                                    type="text" disabled={keyForViewUpdate === 'view'}
                                    className="erp_input_field_table_txt remove0 mb-0 text-end"
                                    id={`actual_issue_weight_${indexOfItem}`}
                                    style={{ width: "100%", padding: "10px", backgroundColor: "#AFE1AF", border: "1px solid #ddd" }}
                                    value={details.actual_issue_weight} onChange={(e) => updateMixingChart('actual_issue_weight', details, e)}
                                /></td>
                            <td className="erp_table_td">{details.difference_weight}</td>
                            <td className="erp_table_td">{details.stock_quantity}</td>
                            <td className="erp_table_td">{details.stock_weight}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="erp_table_td" colSpan={keyForViewUpdate === 'view' ? "25" : "27"}>
                            {"No Records found..."}
                        </td>
                    </tr>
                )}

                {/* Add a gap row between the two sections */}
                <tr>
                    <td className="erp_table_td" colSpan="16"></td>
                    <td className="erp_table_head erp_table_th" colSpan={keyForViewUpdate === 'view' ? "1" : "2"}>Totals</td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['closing_balance_quantity']), 0)), 4)}</td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['closing_balance_weight']), 0)), 4)}</td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['issue_quantity']), 0)), 4)}</td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['issue_weight']), 0)), 4)}</td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['actual_issue_weight']), 0)), 4)}</td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['difference_weight']), 0)), 4)}</td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['stock_quantity']), 0)), 4)}</td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['stock_weight']), 0)), 4)}</td>
                </tr>

                {/* Mixing Chart Calculations */}
                {mixingChartCalculations.length > 0 ? mixingChartCalculations?.map((data, index) => (
                    <tr>
                        <td className="erp_table_head erp_table_th" colSpan={keyForViewUpdate === 'view' ? "3" : "4"}>{headers[index]}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.uhml, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.mi, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.ul, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.mic, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.str, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.rd, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.b_plus, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.total_neps, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.sc_n, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.sfc_n, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.trash, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.moisture, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th" colSpan={10}></td>
                    </tr>
                )) : ''}
            </tbody>
        </Table>
    }, [balesMixingChartDetails, mixingChartCalculations, mixingchartstandardValues]);

    const updateMixingChart = (column, rowData, event) => {
        // debugger;
        try {
            const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'), 10);
            let inputValue = parseFloat(event.target.value);

            if (isNaN(inputValue)) { inputValue = 0 }; // Prevents invalid calculations

            // Remove existing tooltip
            delete event.target.parentElement.dataset.tip;

            switch (column) {
                case 'issue_quantity': {
                    const closingQty = parseFloat(rowData['closing_balance_quantity']) || 0;
                    const closingWeight = parseFloat(rowData['closing_balance_weight']) || 0;

                    if (inputValue > closingQty) {
                        event.target.parentElement.dataset.tip = "Issue Qty. can't be greater than Closing Qty.";
                    } else {
                        rowData[column] = inputValue > 0 ? inputValue : 0;

                        // Prevent division by zero
                        rowData['weight_per_bale'] = closingQty !== 0 ? closingWeight / closingQty : 0;
                        rowData['issue_weight'] = rowData[column] * rowData['weight_per_bale'];
                        rowData['stock_quantity'] = closingQty - rowData[column];
                        rowData['stock_weight'] = rowData['stock_quantity'] * rowData['weight_per_bale'];
                        rowData['difference_weight'] = parseFloat(rowData['issue_weight']) - parseFloat(rowData['actual_issue_weight']);

                        updatedIndexOfMixingChart.current = arrayIndex;
                        calulationsForMXC.current = true;
                        actionMixingChart({ type: 'update', index: arrayIndex, newData: rowData });
                    }
                    break;
                }

                case 'actual_issue_weight': {
                    rowData[column] = inputValue;
                    rowData['difference_weight'] = (parseFloat(rowData['issue_weight']) || 0) - inputValue;

                    updatedIndexOfMixingChart.current = arrayIndex;
                    calulationsForMXC.current = true;
                    actionMixingChart({ type: 'update', index: arrayIndex, newData: rowData });
                    break;
                }
            }
        } catch (error) {
            console.error("Error in updateMixingChart:", error);
        }
    };

    const updateMixingStandardValues = (event, header) => {
        // debugger
        setMixingChartStdVales((prevData) => ({
            ...prevData, // Spread previous state
            [header]: event.target.value, // Update only the targeted field
        }));
    };



    const FnGetStockDataAgainstLotNo = async (lot_no) => {
        // debugger
        try {
            setIsLoading(true);
            let lotNos = selectedLotNos
            if (lotNos.includes(lot_no)) {
                return $("#error_cmb_lot_no").text("Select Another Lost No...!").show();
            }
            const getStockData = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CottonBalesMixingChart/FnGetStockDataAgainstLotNo/${lot_no}/${parseInt(COMPANY_ID)}`);
            const responce = await getStockData.json();

            lotNos.push(lot_no);
            SetSelectedLotNo(lotNos);

            // let StockNewData = responce['StockNewData']; let StockLogData = responce['StockLogData'];
            // setStockAgainstLotNosDetails((prevData) => [...prevData, ...StockNewData]);
            // setStockLogsAgainstLotNosDetails((prevData) => [...prevData, ...StockLogData]);

            calulationsForMXC.current = true;
            actionMixingChart({ type: 'add', newData: { ...responce['QualityTestingData'][0], issue_quantity: 0, issue_weight: 0, actual_issue_weight: 0, difference_weight: 0, stock_quantity: 0, stock_weight: 0 } });
            $("#error_cmb_lot_no").hide();
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    const FnGetLastMixingNo = async () => {
        // debugger
        try {
            setIsLoading(true);
            let plantShortName = plantIdRef.current?.plant_short_name || "";
            let mixingChartNo = "";
            let monthyearFormat = todayWordFormat();
            $("#error_cmb_plant_id").hide();
            const getLastMixingNo = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CottonBalesMixingChart/FnGetLastMixingCartNo/${parseInt(plantIdRef.current?.plant_id)}/${parseInt(COMPANY_ID)}`);
            const response = await getLastMixingNo.text();

            if (!response || response === "0") {
                mixingChartNo = `MXG/${plantShortName}/${todayWordFormat()}/1`;
            } else {
                const parts = response.split("/");
                const lastCounter = parseInt(parts[3]) || 0;
                mixingChartNo = parts[2] === monthyearFormat ? `MXG/${plantShortName}/${monthyearFormat}/${lastCounter + 1}` : `MXG/${plantShortName}/${monthyearFormat}/1`;
            }

            setMixingChartNo(mixingChartNo);
        } catch (error) {
            console.error("Error fetching last mixing number:", error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (calulationsForMXC.current === true && balesMixingChartDetails.length > 0) {
            const termsArray = ['uhml', 'ul', 'mi', 'mic', 'str', 'rd', 'b_plus', 'total_neps', 'sc_n', 'sfc_n', 'trash', 'moisture'];

            let calculationsOfMixingChart = [
                {}, // Averages
                {}, // Max values
                {}, // Min values
                {}, // Difference (max - min)
                {}, // Standard deviation / Mean
                {}  // Weighted sum
            ];

            let totalIssueQuantity = 0;

            // Calculate total issue quantity
            balesMixingChartDetails.forEach((obj) => {
                if (obj.issue_quantity) {
                    totalIssueQuantity += Number(obj.issue_quantity);
                }
            });

            // First pass: Compute sum, max, min, and weighted sum
            balesMixingChartDetails.forEach((obj) => {
                Object.keys(obj).forEach((key) => {
                    if (termsArray.includes(key)) {
                        if (!calculationsOfMixingChart[0][key]) {
                            calculationsOfMixingChart[0][key] = 0;
                            calculationsOfMixingChart[1][key] = Number.MIN_VALUE;
                            calculationsOfMixingChart[2][key] = Number.MAX_VALUE;
                            calculationsOfMixingChart[4][key] = 0; // Standard deviation placeholder
                            calculationsOfMixingChart[5][key] = 0; // Weighted sum
                        }

                        let value = Number(obj[key]);
                        let issueQuantity = Number(obj.issue_quantity) || 0;

                        // Sum for average
                        calculationsOfMixingChart[0][key] += value;
                        // Maximum
                        calculationsOfMixingChart[1][key] = Math.max(calculationsOfMixingChart[1][key], value);
                        // Minimum
                        calculationsOfMixingChart[2][key] = Math.min(calculationsOfMixingChart[2][key], value);
                        // Weighted sum
                        calculationsOfMixingChart[5][key] += value * issueQuantity;
                    }
                });
            });

            // Compute final values
            Object.keys(calculationsOfMixingChart[0]).forEach((key) => {
                let count = balesMixingChartDetails.length;
                let mean = count > 0 ? calculationsOfMixingChart[0][key] / count : 0;
                calculationsOfMixingChart[0][key] = mean; // Store average

                // Compute Variance for Standard Deviation
                let validValues = balesMixingChartDetails
                    .map((obj) => Number(obj[key]))
                    .filter((value) => !isNaN(value));

                let variance = validValues.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0);
                variance /= count > 1 ? (count - 1) : count; // Use (n-1) for sample SD

                calculationsOfMixingChart[4][key] = (Math.sqrt(variance) / mean) * 100; // Store standard deviation / mean

                // Difference calculation (max - min)
                calculationsOfMixingChart[3][key] = calculationsOfMixingChart[1][key] - calculationsOfMixingChart[2][key];

                // Weighted sum calculation
                calculationsOfMixingChart[5][key] = totalIssueQuantity > 0
                    ? calculationsOfMixingChart[5][key] / totalIssueQuantity
                    : 0;
            });

            setMixingChartCalculations(calculationsOfMixingChart);
            calulationsForMXC.current = false;
        }
    }, [balesMixingChartDetails]);


    // useEffect(() => {
    //     // debugger
    //     if (updatedIndexOfMixingChart.current !== undefined && balesMixingChartDetails.length > 0) {
    //         let mixingChartData = balesMixingChartDetails;
    //         let recentUpdatedIndex = parseInt(updatedIndexOfMixingChart.current);

    //         // Update Stock Log Data Transaction Id, transaction Dt. , Transaction Type, Transaction No. , Qty. , Wt. , Created by , Created On......
    //         setStockLogsAgainstLotNosDetails((prevData) =>
    //             prevData.map((item, index) =>
    //                 index === recentUpdatedIndex
    //                     ? {
    //                         ...item,
    //                         stock_log_transaction_id: 0,
    //                         transaction_date: dt_mixing_chart_date,
    //                         transaction_type: "Issue",
    //                         transaction_no: txt_mixing_chart_no,
    //                         quantity: mixingChartData[recentUpdatedIndex]['issue_quantity'],
    //                         weight: mixingChartData[recentUpdatedIndex]['issue_weight'],
    //                         created_by: UserName,
    //                         created_on: new Date().toISOString(),
    //                     }
    //                     : item
    //             )
    //         );
    //     }
    // }, [balesMixingChartDetails]);

    // Validations
    const FnvalidateMixingChart = () => {
        // debugger
        if (cmb_unit_plant_id === '' || cmb_unit_plant_id === 0 || cmb_unit_plant_id === null || cmb_unit_plant_id === undefined) {
            $("#error_cmb_plant_id").text("Select Plant...!").show();
            return false;
        } else {
            $("#error_cmb_plant_id").hide();
        }


        if (cmb_mixer_by_id === null || cmb_mixer_by_id === undefined || cmb_mixer_by_id === 0 || cmb_mixer_by_id === '0') {
            $("#error_cmb_qa_by_id").text('Select Mixer By...!').show();
            return false;
        } else {
            $("#error_cmb_qa_by_id").hide();
        }

        if (balesMixingChartDetails.length === 0) {
            setErrMsg(`Select Atleast One Lot No...!`);
            setShowErrorMsgModal(true);
            return false;
        }

        for (let index = 0; index < balesMixingChartDetails.length; index++) {
            if (balesMixingChartDetails[index].issue_quantity === '' || balesMixingChartDetails[index].issue_quantity === 0 || balesMixingChartDetails[index].issue_quantity === '0') {
                $(`#issue_quantity_${index}`).parent().attr('data-tip', "Enter Qty. here...!");
                return false;
            }
        }

        return true;
    };



    // save
    const FnSaveBalesMixingChart = async (issue_Flag) => {
        // debugger
        try {
            setIsLoading(true);
            if (FnvalidateMixingChart() === true) {
                const json = { 'MixingChartDetails': [], 'StockNewData': [], 'StockLogData': [], "MixingChartStandardValues": {} };

                json.MixingChartDetails = balesMixingChartDetails.map((chartData) => ({
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    mixing_chart_cotton_bales_transaction_id: ['view', 'update'].includes(keyForViewUpdate) ? chartData.mixing_chart_cotton_bales_transaction_id : 0,
                    mixing_chart_no: txt_mixing_chart_no,
                    quality_testing_no: chartData.quality_testing_no,
                    mixing_chart_date: dt_mixing_chart_date,
                    plant_id: cmb_unit_plant_id,
                    mixing_by_id: cmb_mixer_by_id,
                    pr_no: chartData.pr_no,
                    batch_no: chartData.batch_no,
                    supplier_batch_no: chartData.supplier_batch_no,
                    uhml: chartData.uhml,
                    ul: chartData.ul,
                    mi: chartData.mi,
                    mic: chartData.mic,
                    str: chartData.str,
                    rd: chartData.rd,
                    b_plus: chartData.b_plus,
                    total_neps: chartData.total_neps,
                    sc_n: chartData.sc_n,
                    sfc_n: chartData.sfc_n,
                    trash: chartData.trash,
                    moisture: chartData.moisture,
                    cg: chartData.cg,
                    quality_testing_status: chartData.quality_testing_status,
                    closing_balance_quantity: chartData.closing_balance_quantity,
                    closing_balance_weight: chartData.closing_balance_weight,
                    issue_quantity: chartData.issue_quantity,
                    issue_weight: chartData.issue_weight,
                    actual_issue_weight: chartData.actual_issue_weight,
                    difference_weight: chartData.difference_weight,
                    stock_quantity: chartData.stock_quantity,
                    stock_weight: chartData.stock_weight,
                    created_by: UserName,
                    created_on: new Date().toISOString(),
                    issue_flag: issue_Flag
                }));

                json.MixingChartStandardValues = {
                    ...mixingchartstandardValues,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    mixing_chart_standard_values_id: ['view', 'update'].includes(keyForViewUpdate) ? mixingchartstandardValues?.mixing_chart_standard_values_id : 0,
                    mixing_chart_no: txt_mixing_chart_no,
                    mixing_chart_date: dt_mixing_chart_date,
                    plant_id: cmb_unit_plant_id,
                    mixing_by_id: cmb_mixer_by_id,
                    created_by: UserName,
                    created_on: new Date().toISOString(),
                }

                // // If user clicks Issue Btn only Triggers this function
                // if (issue_Flag === 1) {
                //     json.StockLogData = stockLogsAgainstLotNo;

                //     // Update Stock Data Closing Balance Qty, Closing Balance Wt. , Prod. Issue Qty. , Prod. Issue Wt. , modified By, Modified on..........
                //     json.StockNewData = stockAgainstLotNo.map(({ quality_status, is_active, ...rest }, index) => ({
                //         ...rest,  // Keeps all properties except `quality_status` & `is_active`
                //         closing_balance_quantity: parseFloat(balesMixingChartDetails[index]['stock_quantity']),
                //         closing_balance_weight: parseFloat(balesMixingChartDetails[index]['stock_weight']),
                //         production_issue_quantity: (parseFloat(rest['production_issue_quantity']) || 0) + (parseFloat(balesMixingChartDetails[index]['issue_quantity']) || 0),
                //         production_issue_weight: (parseFloat(rest['production_issue_weight']) || 0) + (parseFloat(balesMixingChartDetails[index]['issue_weight']) || 0),
                //         modified_by: UserName,
                //         modified_on: new Date().toISOString(),
                //     }));
                // }

                const formData = new FormData()
                formData.append('MixingChartData', JSON.stringify(json))
                const forwardData = {
                    method: 'POST',
                    body: formData,
                }
                const WPOApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CottonBalesMixingChart/FnAddUpdateRecord`, forwardData)
                const responce = await WPOApiCall.json()
                if (responce.success === 0) {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }

            }
        } catch (error) {
            console.log('error:- ', error);
        } finally {
            setIsLoading(false)

        }
    }



    const FnExportToExcel = () => {
        const headers1 = [
            "Sr. No.", "Supplier Lot No", "Mill Lot No", "UHML", "ML", "UI", "MIC", "STR", "RD", "B+", "TOTAL NEPS",
            "SC(N)", "SFC(N)", "TRASH", "MOISTURE", "CG", "Quality Status",
            "Closing Bal. Qty.", "Closing Bal. Wt.", "Issue Quantity", "Issue Weight",
            "Stock Quantity", "Stock Weight"
        ];

        const headerStyle = {
            font: { bold: true },
            alignment: { horizontal: "center", vertical: "center", wrapText: true },
            border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } }
        };

        const cellStyle = {
            alignment: { horizontal: "center", vertical: "center" },
            border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } }
        };

        const titleStyle = {
            font: { bold: true, sz: 16 },
            alignment: { horizontal: "center", vertical: "center" },
            border: { bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } }
        };

        const customHeading = `MIXING REPORT - ${txt_mixing_chart_no.toUpperCase()} (${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_mixing_chart_date)})`;

        const worksheetData = [
            [{ v: customHeading, s: titleStyle }].concat(Array(21).fill(null)), // Title row centered
            Array(22).fill(null), // Blank row
            headers1.map(header => ({ v: header, s: headerStyle }))
        ];

        balesMixingChartDetails.forEach((details, index) => {
            worksheetData.push([
                { v: index + 1, s: cellStyle },
                { v: details.supplier_batch_no ?? "", s: cellStyle },
                { v: details.batch_no, s: cellStyle },
                { v: details.uhml, s: cellStyle },
                { v: details.mi, s: cellStyle },
                { v: details.ul, s: cellStyle },
                { v: details.mic, s: cellStyle },
                { v: details.str, s: cellStyle },
                { v: details.rd, s: cellStyle },
                { v: details.b_plus, s: cellStyle },
                { v: details.total_neps, s: cellStyle },
                { v: details.sc_n, s: cellStyle },
                { v: details.sfc_n, s: cellStyle },
                { v: details.trash, s: cellStyle },
                { v: details.moisture, s: cellStyle },
                { v: details.cg, s: cellStyle },
                { v: details.quality_testing_status ?? "", s: cellStyle },
                { v: details.closing_balance_quantity, s: cellStyle },
                { v: details.closing_balance_weight, s: cellStyle },
                { v: details.issue_quantity, s: cellStyle },
                { v: details.issue_weight ?? "", s: cellStyle },
                { v: details.stock_quantity, s: cellStyle },
                { v: details.stock_weight ?? "", s: cellStyle }
            ]);
        });

        // Adding total row
        worksheetData.push([
            { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle },
            { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle },
            { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle },
            { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle },
            { v: "Totals", s: { ...headerStyle, font: { bold: true } } },
            { v: validateNumberDateInput.current.decimalNumber(balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['closing_balance_quantity']), 0), 4), s: cellStyle },
            { v: validateNumberDateInput.current.decimalNumber(balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['closing_balance_weight']), 0), 4), s: cellStyle },
            { v: validateNumberDateInput.current.decimalNumber(balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['issue_quantity']), 0), 4), s: cellStyle },
            { v: validateNumberDateInput.current.decimalNumber(balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['issue_weight']), 0), 4), s: cellStyle },
            { v: validateNumberDateInput.current.decimalNumber(balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['stock_quantity']), 0), 4), s: cellStyle },
            { v: validateNumberDateInput.current.decimalNumber(balesMixingChartDetails.reduce((acc, data) => acc + parseFloat(data['stock_weight']), 0), 4), s: cellStyle }
        ]);

        // Adding two blank rows as space between tables
        worksheetData.push([]);

        const headers2 = headers

        mixingChartCalculations.forEach((data, index) => {
            worksheetData.push([
                null,
                { v: headers2[index], s: headerStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(data.uhml, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.mi, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.ul, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.mic, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.str, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.rd, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.b_plus, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.total_neps, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.sc_n, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.sfc_n, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.trash, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.moisture, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } }
            ]);
        });

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Merge the title row across all columns
        const mergeTitleRange = { s: { r: 0, c: 0 }, e: { r: 0, c: 21 } };
        if (!worksheet['!merges']) worksheet['!merges'] = [];
        worksheet['!merges'].push(mergeTitleRange);

        worksheet["!rows"] = [{ hpt: 22 }, { hpt: 22 }, { hpt: 30 }]
            .concat(worksheetData.slice(3, -1).map(() => ({ hpt: 21.5 })))
            .concat([{ hpt: 30 }]);        // Setting column widths
        worksheet['!cols'] = [{ wch: 7.5 }] // Sr. No.
            .concat({ wch: 13 }) // Supplier Lot No
            .concat({ wch: 13 }) // Mill Lot No
            .concat(Array(13).fill({ wch: 7.5 })) // Columns 4 to 16
            .concat(Array(7).fill({ wch: 12 })); // Columns 17 onwards

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Mixing Chart");

        XLSX.writeFile(workbook, `Mixing_Chart_${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_mixing_chart_date)}.xlsx`);
    };


    //******************************************* Print Functionality Starts ************************************************************* */

    // Mixing Chart Print & Excel Sheet
    const printMixingChart = async () => {
        // debugger
        try {
            FnPrintIssueReportSheet();
        } catch (error) {
            console.error('Error fetching data for print:', error);
        }
    }

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
            setIsPrinting(false);
        }
    }, [isPrinting]);

    useEffect(() => {
        if (isPrinting) {
            alert("Triggered")
            $('.hidetr').hide();
            $('.erp_pagination').hide();
        } else {
            $('.hidetr').show();
            $('.erp_pagination').show();
        }
    }, [isPrinting]);
    

    let printTimeout;  // Store timeout reference

    const FnPrintIssueReportSheet = useReactToPrint({
        content: () => {
            debugger
            // Hide pagination and specific table row during print
            $('.erp_pagination').hide();
            $('.hidetr').hide(); // Hides the row with class 'hidetr'
    
            // Temporarily adjust table styles and scrollable area
            if (printComponentRef.current) {
                const table = document.querySelector('.erp_table');
                table.style.tableLayout = 'auto';  // Make table expand naturally for print
                table.style.width = '100%';  // Ensure the table takes up the full width
            }
    
            const headerHtml = printOutHeader(); // Call function to get header HTML
    
            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML
    
            // Append the main content (clone the table for printing)
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);
    
            return contentWrapper;
        },
    
        onBeforePrint: () => {
            const style = document.createElement("style");
            style.innerHTML = `
                @media print {
                    @page {
                        size: landscape;
                    }
                }
            `;
            document.head.appendChild(style);
    
            printTimeout = setTimeout(() => {
                handlePrintCancel();  // Handle cancel
            }, 500);
        },
    
        onAfterPrint: () => {
            // Restore pagination and hidden row after printing
            $('.erp_pagination').show();
            $('.hidetr').show(); // Show the row again
    
            clearTimeout(printTimeout);
        },
    
        documentTitle: `Bales Mixing Chart`
    });
    
    // Handle print cancel
    const handlePrintCancel = async () => {
        console.log('Print was canceled.');
    
        try {
            // Reload paginated data or trigger any necessary actions
            console.log('Data reloaded after print cancel.');
        } catch (error) {
            console.error('Error fetching data after print cancel:', error);
        }
    
        // Re-enable scrollbars if printing is cancelled
        const scrollable = document.querySelector('.scrollable');
        const table = printComponentRef.current.querySelector('.erp_table');
        if (scrollable) {
            scrollable.style.overflow = 'auto';  // Restore scroll on cancel
        }
        if (table) {
            table.style.tableLayout = 'fixed';  // Restore original table layout
        }
    
        // Show hidden row after print cancel
        $('.hidetr').show();
    };
    

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Cotton Bales Mixing Chart Details
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    //******************************************* Print Functionality Ends ************************************************************* */





    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <FrmValidations ref={validate} />
                <ExcelExport ref={exlsExp} />


                <>
                    <style>
                        {`
                                @media print {
                                    @page {
                                        size: landscape;
                                    }
                                    
                                    .printable-content {
                                        width: 100%;
                                        transform: rotate(0deg);
                                    }
                                }
                            `}
                    </style>
                </>

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/*.........................Shiftwise Machine Stoppage Entry............................... */}
                <div className="col-lg-12 col-12 erp_form_col_div">
                    <div className="col-lg-12 col-12 erp_form_col_div">
                        <div className="card">

                            <div className='card-header text-center py-0'>
                                <label className='erp-form-label-lg text-center'>{`Bales Mixing Chart ${keyForViewUpdate === 'view' ? '(View)' : '(Add)'}`}</label>
                            </div>
                            {/* Card's Body Starts*/}
                            <div className="card-body p-0">
                                <form id='mixingChartFormId'>

                                    <div className='row p-1'>
                                        <div className='col-sm-5 erp_form_col_div'>

                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <Form.Label className="erp-form-label"> Plant Name <span className="required">*</span></Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_unit_plant_id} disabled={keyForViewUpdate === 'view'}
                                                        onChange={(e) => { setUnitPlantId(e.target.value); plantIdRef.current = unitPlantOptions.find((plant) => plant.plant_id === parseInt(e.target.value)); FnGetLastMixingNo() }}>
                                                        <option value="">Select</option>
                                                        {unitPlantOptions.length > 0 &&
                                                            unitPlantOptions.map((plant) => (
                                                                <option key={plant.plant_id} value={plant.plant_id}>
                                                                    {plant.plant_name}
                                                                </option>
                                                            ))}
                                                    </select>

                                                    <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className={`row ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label">Lot No.<span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Select ref={lotComboRef}
                                                        options={lotNoOptions}
                                                        value={lotNoOptions.length > 0 ? lotNoOptions.find(option => option.value === cmb_lot_no) : null}
                                                        onChange={(selectedOpt) => {
                                                            lotComboRef.current = selectedOpt;
                                                            setLotNo(selectedOpt.value);
                                                            FnGetStockDataAgainstLotNo(selectedOpt.value);
                                                            $("#error_cmb_lot_no").hide();
                                                        }}
                                                        placeholder="Search for Lot No"
                                                        className="form-search-custom"
                                                        classNamePrefix="custom-select" // Add custom prefix for class names
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            })
                                                        }}
                                                    />
                                                    <MDTypography variant="button" id="error_cmb_lot_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-1 erp_form_col_div'></div>

                                        <div className='col-sm-6 erp_form_col_div'>
                                            <div className='row'>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Mixing No & Date<span className="required">*</span></Form.Label>
                                                </div>
                                                <div className='col-sm-9 col-12'>
                                                    <div className="row">
                                                        <div className='col-12 col-md-8 pe-md-0'>
                                                            <Form.Control type="text" id="txt_mixing_chart_no" className="erp_input_field" value={txt_mixing_chart_no} disabled />
                                                        </div>
                                                        <div className="col-12 col-md-3 pt-md-0 pt-3">
                                                            <Form.Control type="date" id='dt_mixing_chart_date'
                                                                className="erp_input_field"
                                                                value={dt_mixing_chart_date}
                                                                disabled={true}
                                                                onChange={e => {
                                                                    setMixingChartDt(e.target.value);
                                                                    // validateFields();
                                                                }}
                                                                min={currentDate}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Mixing By <span className="required">*</span></Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Select
                                                        options={employeeList}
                                                        isDisabled={['view'].includes(keyForViewUpdate)}
                                                        value={employeeList.find(option => option.value === cmb_mixer_by_id) || null}
                                                        onChange={(selectedOpt) => {
                                                            setMixerById(selectedOpt.value);
                                                            $("#error_cmb_qa_by_id").hide();
                                                        }}
                                                        placeholder="Search Employee Here..."
                                                        className="form-search-custom"
                                                        classNamePrefix="custom-select" // Add custom prefix for class names
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                            })
                                                        }}
                                                    />
                                                    <MDTypography variant="button" id="error_cmb_qa_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </form>

                                <hr />

                                <div className='row p-1'>
                                    <div className="card-header py-0 ms-2 p-1 main_heding mb-0">
                                        <label className="erp-form-label-md-lg">Mixing Chart Details</label>
                                    </div>
                                    <div className='col erp_form_col_div mt-2 p-2'>
                                        {renderBalesMixingChartTbl}
                                    </div>

                                    <div className='row'>
                                        <div className='col'>
                                            <div className={`erp_frm_Btns  mt-2 mb-2 `}>
                                                <MDButton type="button" id="back_Button" className={`erp-gb-button ms-2`} variant="button" onClick={() => handleCloseSuccessModal()} fontWeight="regular">Back</MDButton>
                                                <MDButton type="button" id="save_Button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" onClick={() => FnSaveBalesMixingChart(0)} fontWeight="regular">Save</MDButton>
                                                <MDButton type="button" id="print_Button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'display' : 'd-none'}`} variant="button" onClick={() => printMixingChart()} fontWeight="regular">Print</MDButton>
                                                <MDButton type="button" variant="print_Button" fontWeight="regular" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'display' : 'd-none'}`} onClick={(e) => { FnExportToExcel() }}>Export Excel</MDButton>
                                            </div >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </DashboardLayout>
        </>
    )
}

export default FrmBalesMixingChartEntry
