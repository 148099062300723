import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $, { error } from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React icons
import { RxCrossCircled } from "react-icons/rx";
import { MdDelete } from "react-icons/md";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";

// Import for the searchable combo box.
import Select from 'react-select';

export default function FrmMaterialInternalTransferEntry() {
  const configConstants = ConfigConstants();
  const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName, DEPARTMENT_ID, } = configConstants;
  const { state } = useLocation();
  const { idList, keyForViewUpdate = '', compType } = state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [openMaterialtransferDetailsAccord, setOpenMaterialTransferDetailsAccord] = useState(true);
  let docGroup = "Internal Material Transfer"

  // For navigate
  const navigate = useNavigate();

  // UseRefs
  const validate = useRef();
  const comboDataAPiCall = useRef();
  const generateAutoNoAPiCall = useRef();
  const exlsExp = useRef();
  const validateNumberDateInput = useRef();
  const navigator = useNavigate();

  // for today date formatted
  const todayDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const currentDate = new Date().toISOString().split('T')[0];

  //form combo options
  const [materialtypeOptions, setmaterialtypeOptions] = useState([])
  const [fromDepartmentOptions, setFromDepartmentOptions] = useState([])
  const [fromSubDepartmentOptions, setFromSubDepartmentOptions] = useState([])
  const [toDepartmentOptions, setToDepartmentOptions] = useState([])
  const [toSubDepartmentOptions, setToSubDepartmentOptions] = useState([])
  const [transfedrByOptions, setTransferedByOptions] = useState([])

  //Form Fields
  const [materialId, setMaterialId] = useState('')
  const [txt_internal_transfer_no, setInternalTransferNo] = useState('')
  const [dt_transfer_date, setTransferDate] = useState(todayDate)
  const [inter_material_transfer_master_id, setInterMaterialTransferMasterId] = useState(0);
  const [cmb_transfered_by_id, setTransferedBy] = useState(UserId)
  const [cmb_material_type, setmaterialType] = useState('');
  const [cmb_from_department_id, setFromDepartmentId] = useState(keyForViewUpdate === "" ? DEPARTMENT_ID : '');
  const [cmb_to_department_id, setToDepartmentId] = useState('');
  const [cmb_from_sub_department_id, setFromSubDepartmentId] = useState('');
  const [cmb_to_sub_department_id, setToSubDepartmentId] = useState('');
  const [cmb_transfer_status, setTransferStatus] = useState('P');

  const [transferDetailsData, setTransferDetailsData] = useState([])
  const [showBomFilterForm, setShowBomFilterForm] = useState(false);


  useEffect(async () => {
    setIsLoading(true);
    try {
      FnSetFieldUserAccess();
      removeSessions('onload');
      await fillCombos();
      if (idList === '' || idList === undefined) {
        await GenerateTransferNo();
      } else {
        await FnCheckUpdateResponse();
      }
      FnSetFieldUserAccess();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [])
  // delete popup
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  //Error Msg
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');
  const [modalOrderDetails, setModalOrderDetails] = useState('')

  const closeConfirmationModal = async () => {
    setShowSuccessMsgModal(false)
    moveToListing();
  };
  const [modalHeaderName, setHeaderName] = useState('')

  // -------------------------------End Common terms -------------------------------------------------------------------------

  const fillCombos = async () => {
    try {

      resetGlobalQuery();
      globalQuery.columns.push("department_id");
      globalQuery.columns.push("department_name");
      globalQuery.table = "cm_department";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "department_type", operator: "=", value: "M" });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const get_departmentList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      const deptList = [
        { value: '', label: 'Select', },
        ...get_departmentList.map((department) => ({ ...department, value: department.department_id, label: department.department_name, })),
      ];
      setFromDepartmentOptions(deptList)
      setToDepartmentOptions(deptList)


      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "smv_product_type"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const prodTypeOpts = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      console.log(prodTypeOpts)
      const options = [
        { value: '', label: 'Select', },
        ...prodTypeOpts.map((prodType) => ({ ...prodType, value: prodType.field_id, label: prodType.field_name })),
      ];
      setmaterialtypeOptions(options)


      resetGlobalQuery();
      globalQuery.columns.push("employee_id");
      globalQuery.columns.push("employee_name");
      globalQuery.table = "cm_employee";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "employee_type_group", operator: "!=", value: "Workers" });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const transferedByIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      const getTransferedByOptions = [
        { value: '', label: 'Select', },
        ...transferedByIdApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
      ]
      setTransferedByOptions(getTransferedByOptions);

    } catch (error) {
      console.log("error: ", error)
    }
  }
  const FnSetFieldUserAccess = () => {
    if (keyForViewUpdate != '') {
      switch (keyForViewUpdate) {
        case 'view':
          $('input[type="checkbox"]').prop('readonly', true);
          $("input[type=radio]").attr('disabled', true);
          $("form").find("input,textarea,select").attr("disabled", "disabled");
          $("table").find("input,button,textarea,select").attr("disabled", "disabled");
          $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
          break;

        case 'delete':
          $('input[type="checkbox"]').prop('readonly', true);
          $("input[type=radio]").attr('disabled', true);
          $("form").find("input,textarea,select").attr("disabled", "disabled");
          $("table").find("input,button,textarea,select").attr("disabled", "disabled");
          $('#viewdocument-id').hide();
          $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
          break;
      }
    }
  }


  //fn to get po auto no
  const GenerateTransferNo = async () => {
    const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_inter_material_transfer_master", "inter_material_transfer_no", "", 'IT', "5");
    if (keyForViewUpdate === '') {
      setInternalTransferNo(autoNoApiCall);
    }
    return autoNoApiCall;
  };
  //-------------------------------------- Combo changes ------------------------------------------------------------
  const comboOnChange = async (key) => {

    switch (key) {

      case 'from_department':
        let fromDepartmentValue = fromDepartmentComboRef.current.value || '';
        setFromDepartmentId(fromDepartmentValue)
        setTransferDetailsData([])
        if (fromDepartmentValue !== "" && fromDepartmentValue !== "0") {
          resetGlobalQuery();
          globalQuery.columns.push("department_id");
          globalQuery.columns.push("department_name");
          globalQuery.columns.push("godown_id");
          globalQuery.columns.push("godown_section_id");
          globalQuery.columns.push("godown_section_beans_id");
          globalQuery.table = "cmv_department"
          globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
          globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: fromDepartmentValue });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
          const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          setFromSubDepartmentOptions(subDepartmentApiCall)
          setFromSubDepartmentId('')
        } else {
          setFromSubDepartmentOptions([])
          setFromSubDepartmentId('')
        }
        break;

      case 'fromSubDepartment':
        let fromSubDeptValue = document.getElementById('cmb_from_sub_department_id').value || '';
        setFromSubDepartmentId(fromSubDeptValue)
        setTransferDetailsData([])
        await comboOnChange('to_department')
        break;

      case 'to_department':
        let toDepartmentValue = toDepartmentComboRef.current.value || '';
        setToDepartmentId(toDepartmentValue)
        let fromSubDeptId = document.getElementById('cmb_from_sub_department_id').value || '';
        setTransferDetailsData([])
        if (toDepartmentValue !== "" && toDepartmentValue !== "0") {
          resetGlobalQuery();
          globalQuery.columns.push("department_id");
          globalQuery.columns.push("department_name");
          globalQuery.columns.push("godown_id");
          globalQuery.columns.push("godown_section_id");
          globalQuery.columns.push("godown_section_beans_id");
          globalQuery.table = "cmv_department"
          globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
          globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: toDepartmentValue });
          globalQuery.conditions.push({ field: "department_id", operator: "!=", value: fromSubDeptId });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
          const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          setToSubDepartmentOptions(subDepartmentApiCall)

        } else {
          setToSubDepartmentOptions([])
          setToSubDepartmentId('')
        }
        break;

      case 'toSubDepartment':
        setTransferDetailsData([])
        var tosubdepartmentIdVal = document.getElementById('cmb_to_sub_department_id').value;
        setToSubDepartmentId(tosubdepartmentIdVal)
        break;

      case 'cmb_material_type':
        setTransferDetailsData([])
        break;

      default:
        break;
    }
  }
  //--------------------------------------------------------Start Details section----------------------------------------------------------------------

  const handleCloseProductModalForm = () => { setShowBomFilterForm(false) };


  const removeSessions = (reloadData) => {
    sessionStorage.removeItem(`comboFilterData`)
    sessionStorage.removeItem('isComboFilterExist')
    sessionStorage.removeItem('smv_product_type');
    sessionStorage.removeItem('smv_product_material_type');
    sessionStorage.removeItem('smv_product_material_grade');
    sessionStorage.removeItem('smv_product_material_shape');
    if (reloadData === 'onload') {
      sessionStorage.removeItem('filteredMaterialData')
    }
    for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
      sessionStorage.removeItem('smv_product_category' + removeItemIndex);
      localStorage.removeItem('smv_product_category' + removeItemIndex);
      localStorage.removeItem('smv_product_category' + removeItemIndex);
      localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
    }

    localStorage.removeItem('smv_product_material_type');
    localStorage.removeItem('smv_product_material_grade');
    localStorage.removeItem('smv_product_material_shape');
    localStorage.removeItem('smv_product_type');

    localStorage.removeItem('smv_product_material_typeName');
    localStorage.removeItem('smv_product_material_gradeName');
    localStorage.removeItem('smv_product_material_shapeName');
    localStorage.removeItem('smv_product_typeName');
  }

  const FnClearFormFields = async () => {

    sessionStorage.removeItem('filteredMaterialData')
    setTransferDetailsData([])
    setToSubDepartmentOptions([])
    setFromSubDepartmentOptions([])
    // setToDepartmentOptions([])
    setFromDepartmentId('')
    setFromSubDepartmentId('')
    setToDepartmentId('')
    setToSubDepartmentId('')
    setMaterialId('')
    // fromDepartmentComboRef.current.value = "";
    // toDepartmentComboRef.current.value = "";  
    // transferedByComboRef.current.value = "";
    // transferedByComboRef.current.value = "";
  }

  const moveToListing = () => {
    const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/MaterialInternalTransfer/FrmMaterialInternalTransferListing/reg' : `/Transactions/TPurchaseOrder/MaterialInternalTransfer/FrmMaterialInternalTransferListing`;
    navigate(Path);
  }


  // $(document).on('click', '.remove_input_zero', function () {
  //   if ($(this).val() === "0") {
  //     $(this).val("")
  //   }
  // });
  // $(document).on('mouseup mousedown', function (e) {
  //   let inputBoxes = $(".remove_input_zero");
  //   inputBoxes.each(function () {
  //     if ($(this).val() === "") {
  //       $(this).val("0");
  //     }
  //   });
  // });

  //key for get opration
  const ActionType = () => {
    switch (keyForViewUpdate) {
      case 'approve':
        return '(Approval)';
      case 'update':
        return '(Modification)';
      case 'view':
        return '(View)';;
      case 'delete':
        return '(Delete)';
      default:
        return '(Creation)';
    }
  }

  const validateFields = () => {
    validate.current.validateFieldsOnChange('material_transfer_form_id')
  }

  let requiredCols = ['product_material_name', 'product_material_id', 'product_type_group', 'stock_quantity', 'stock_weight', 'product_unit_name', 'product_material_std_weight', 'godown_name', 'godown_section_name', 'godown_section_beans_name',
    'godown_id', 'godown_section_id', 'godown_section_beans_id', 'product_material_unit_id'];


  const FnGetStockDetails = async () => {
    const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
    try {
      setIsLoading(true)
      if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve') {

        const selectedOption = $("#cmb_from_sub_department_id").find(":selected");
        let selected_godown_id = selectedOption.attr("godown_id")

        resetGlobalQuery();
        globalQuery.columns = [
          'rmstock.stock_transaction_id',
          'rmstock.product_rm_name AS product_material_name',
          'rmstock.goods_receipt_no',
          'rmstock.supplier_id',
          'rmstock.customer_goods_receipt_no',
          'SUM(rmstock.closing_balance_quantity) AS closing_balance_quantity',
          'SUM(rmstock.closing_balance_weight) AS closing_balance_weight',
          'SUM(rmstock.closing_no_of_boxes) AS closing_no_of_boxes',
          'rmstock.product_rm_name As product_material_name',
          'rmstock.product_rm_code As product_code',
          'rmstock.product_rm_id As product_material_id',
          'rmstock.product_packing_name',
          'rmstock.weight_per_box_item As cone_per_wt',
          'rmstock.batch_rate As batch_rate',
          'rmstock.product_material_unit_name As product_unit_name',
          'rmstock.product_material_unit_id',
          'sm.actual_count',
          'rmstock.batch_no As batch_no',
          'rmstock.godown_id',
          'rmstock.godown_name',
          'rmstock.godown_section_id',
          'rmstock.godown_section_name',
          'rmstock.godown_section_beans_id',
          'rmstock.godown_section_beans_name',
        ];

        globalQuery.table = "smv_product_rm_stock_details rmstock";
        globalQuery.joins = [
          {
            "table": `sm_product_rm sm`,
            "type": "LEFT",
            "on": [
              {
                "left": "sm.product_rm_id",
                "right": "rmstock.product_rm_id",
              },

              {
                "left": "sm.is_delete",
                "right": "0"
              }
            ]
          },
        ];

        globalQuery.conditions = [
          { field: "rmstock.day_closed", operator: "=", value: 0 },
          { field: "rmstock.product_rm_id", operator: "=", value: getData[0].product_material_id },
          {
            field: "rmstock.godown_id",
            operator: "=",
            value: selected_godown_id,
          },];
        globalQuery.groupBy = [
          'rmstock.batch_no',
          'rmstock.product_rm_id',
          'rmstock.weight_per_box_item',
          'rmstock.supplier_id',
        ];

        // Make the API call to fill filters combo
        let getStockDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

        // Create a Set of stock_transaction_id from materialRequisitionDetails
        const existingTransactionIds = new Set(transferDetailsData.map(item => item.stock_transaction_id));

        // Filter getStockDetails to exclude those that match with materialRequisitionDetails
        const getExistingRequisitionDetails = getStockDetails.filter(item =>
          !existingTransactionIds.has(item.stock_transaction_id)
        );

        // Optionally, if you want to combine both existing and new data into materialRequisitionDetails:
        const combinedData = [...transferDetailsData, ...getExistingRequisitionDetails];
        const filteredDetails = combinedData.filter(item => item.closing_balance_weight > 0);


        // Update the state with the combined data
        setTransferDetailsData(filteredDetails);

      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleIssueDetailsInputChange = (currentRowData, event) => {
    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
    let clickedColName = event.target.getAttribute('Headers');
    let focusedInputField = document.querySelector('#' + event.target.id);
    let { closing_no_of_boxes, closing_balance_weight, closing_balance_quantity, cone_per_wt } = currentRowData;
    let enteredValue = event.target.value;
    if (isNaN(enteredValue)) {
      enteredValue = 0;
    } else if (enteredValue != 0 || enteredValue != "") {
      delete focusedInputField.parentElement.dataset.tip;
    }
    switch (clickedColName) {

      case 'inter_material_transfer_quantity': {
        const parsedValue = parseInt(enteredValue, 10);
        const parsedStockQuantity = parseInt(closing_balance_quantity, 10);
        const parsedStockWeight = parseFloat(closing_balance_weight, 10);
        const transferWeight = (cone_per_wt * enteredValue).toFixed(2);

        if (parsedValue === 0) {
          focusedInputField.parentElement.dataset.tip = `Cannot be 0...!`;
          currentRowData[clickedColName] = enteredValue;
          return false;
        }

        if (parsedValue > parsedStockQuantity) {
          focusedInputField.parentElement.dataset.tip = `Cannot be greater than ${closing_balance_quantity}...!`;
          return false;
        }

        currentRowData[clickedColName] = enteredValue;

        if (transferWeight > parsedStockWeight) {
          focusedInputField.parentElement.dataset.tip = `Transfer weight exceeds Stock weight...!`;
          return;
        }

        if (cone_per_wt * enteredValue === "") {
          currentRowData.inter_material_transfer_weight = "";
        } else {
          currentRowData.inter_material_transfer_weight = transferWeight;
          delete focusedInputField.parentElement.dataset.tip;
        }

        // if (parsedValue === 0) {  // 270 === 0
        //   focusedInputField.parentElement.dataset.tip = `Cannot be 0...!`;
        //   currentRowData[clickedColName] = enteredValue;
        //   return false;
        // } else if (parsedValue > parsedStockQuantity) { // 270 > 299
        //   focusedInputField.parentElement.dataset.tip = `Cannot be greater than ${closing_balance_quantity}...!`;
        //   return false;
        // } else {
        //   currentRowData[clickedColName] = enteredValue; //266
        //   if((cone_per_wt * enteredValue).toFixed(2) > parsedStockWeight) { //
        //     // currentRowData.inter_material_transfer_weight = (cone_per_wt * enteredValue).toFixed(4);
        //     focusedInputField.parentElement.dataset.tip= `Transfer weight exceeds Stock weight...!`
        //     return
        //   }else{
        //     if(cone_per_wt * enteredValue === ""){
        //       currentRowData.inter_material_transfer_weight = ""
        //     }
        //     else{
        //       currentRowData.inter_material_transfer_weight = (cone_per_wt * enteredValue).toFixed(2);
        //       delete focusedInputField.parentElement.dataset.tip;
        //     }
        //   }
        // }

        // else {
        //   if(closing_balance_weight > (enteredValue * cone_per_wt) ){
        //     focusedInputField.parentElement.dataset.tip = `Cannot be greater than ${closing_balance_weight}...!`;
        //   }
        //   currentRowData[clickedColName] = enteredValue;}

        setTransferDetailsData((prevArr) => [
          ...prevArr.filter(
            (material) =>
              material.material_transfer_master_id !== currentRowData.material_transfer_master_id
          ),
          currentRowData,
        ]);
        break;
      }

      case 'inter_material_transfer_weight':
        {
          const parsedValue = parseFloat(enteredValue, 10);
          const parsedStockWeight = parseFloat(closing_balance_weight, 10);
          if (parsedValue === 0 && parsedStockWeight !== 0) {
            focusedInputField.parentElement.dataset.tip = `Cannot be 0...!`;
            return false;
          } else if (parsedValue > parsedStockWeight) {
            focusedInputField.parentElement.dataset.tip = `Cannot be greater than ${closing_balance_weight}...!`;
            return false;
          } else {
            currentRowData[clickedColName] = enteredValue;
            currentRowData.inter_material_transfer_quantity = Math.ceil(enteredValue / currentRowData.cone_per_wt);
            delete focusedInputField.parentElement.dataset.tip;
          }
          // Update the material transfer details data
          setTransferDetailsData((prevArr) => [
            ...prevArr.filter(
              (material) =>
                material.material_transfer_master_id !== currentRowData.material_transfer_master_id
            ),
            currentRowData,
          ]);
          break;
        }

      case 'inter_material_transfer_boxes':
        if (enteredValue !== "") {
          const parsedValue = parseInt(enteredValue, 10);
          const parsedStockNoOfBoxes = parseInt(closing_no_of_boxes, 10);
          if (parsedValue === 0 && parsedStockNoOfBoxes !== 0) {
            focusedInputField.parentElement.dataset.tip = `Cannot be 0...!`;
            return false;
          } else if (parsedValue > parsedStockNoOfBoxes) {
            focusedInputField.parentElement.dataset.tip = `Cannot be greater than Closing Boxes...!`;
            return false;
          } else {
            currentRowData[clickedColName] = enteredValue;
            delete focusedInputField.parentElement.dataset.tip;
          }
        } else {
          currentRowData[clickedColName] = enteredValue;
          focusedInputField.parentElement.dataset.tip = `Please Enter Boxes...!`;
        }

        setTransferDetailsData((prevArr) => [
          ...prevArr.filter(
            (material) =>
              material.material_transfer_master_id !== currentRowData.material_transfer_master_id
          ),
          currentRowData,
        ]);
        break;

      case 'internal_material_transfer_remark':
        currentRowData[clickedColName] = event.target.value;
        setTransferDetailsData((prevArr) => [
          ...prevArr.filter(
            (material) =>
              material.material_transfer_master_id !== currentRowData.material_transfer_master_id
          ),
          currentRowData,
        ]);
        break;

      default:
        break;
    }
    const detailData = [...transferDetailsData]
    detailData[arrayIndex] = currentRowData;
    setTransferDetailsData(detailData);
  }

  const deleteRecord = (Index) => {
    setTransferDetailsData((prevDetailsData) => prevDetailsData.filter((data, i) => i !== Index))
  }

  //details table
  const renderDetailsTable = useMemo(() => {
    return <>
      <Table className="erp_table erp_table_scroll" id='transfer-details-tbl' responsive bordered striped>
        <thead className="erp_table_head">
          <tr>
            <th className={`erp_table_th  ${keyForViewUpdate === "" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>Action</th>
            <th className="erp_table_th">Sr.no</th>
            <th className="erp_table_th">Material Name</th>
            <th className="erp_table_th">Material Code</th>
            <th className="erp_table_th">Lot No</th>

            {keyForViewUpdate === "" || keyForViewUpdate === "update" ?
              <>
                <th className="erp_table_th">Stock Quantity</th>
                <th className="erp_table_th">Stock Weight</th>
                <th className="erp_table_th">Stock Boxes</th>
              </>
              : null}

            <th className="erp_table_th">Transfer Quantity</th>
            <th className="erp_table_th">Transfer Weight</th>
            <th className="erp_table_th">Transfer Boxes</th>

            <th className="erp_table_th">Return Remark</th>
            <th className="erp_table_th">Cone per Weight</th>
            <th className="erp_table_th">Material Unit</th>
          </tr>
        </thead>
        <tbody>
          {transferDetailsData.map((item, Index) =>
            <tr rowIndex={Index} className="sticky-column">
              {keyForViewUpdate === "" &&
                <td className="erp_table_td" style={{ verticalAlign: "middle" }} >
                  <Tooltip title="Delete" placement="top"><MDTypography className="erp-view-btn" >
                    <MdDelete sty className={`erp-delete-btn`} style={{ display: "flex", margin: "auto" }} onClick={() => deleteRecord(Index)} />
                  </MDTypography></Tooltip>
                </td>}
              <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
              <td className="erp_table_td "> {item.product_material_name} </td>
              <td className="erp_table_td "> {item.product_code} </td>
              <td className={`erp_table_td text-end sticky-column`}>{item.batch_no}</td>
              {keyForViewUpdate === "" || keyForViewUpdate === "update" ?
                <>
                  <td className="erp_table_td text-end">{item.closing_balance_quantity}</td>
                  <td className="erp_table_td text-end">{item.closing_balance_weight}</td>
                  <td className="erp_table_td text-end">{item.closing_no_of_boxes}</td>
                </>
                : null}
              <td className="erp_table_td text-end">
                {keyForViewUpdate === '' ?
                  <input type="text" id={`inter_material_transfer_quantity_${Index}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                    value={item.inter_material_transfer_quantity} onChange={(e) => { handleIssueDetailsInputChange(item, e); }} Headers='inter_material_transfer_quantity' />
                  :
                  item.inter_material_transfer_quantity
                }
              </td>
              <td className="erp_table_td text-end">
                {keyForViewUpdate === '' ?
                  <input type="text" id={`inter_material_transfer_weight_${Index}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                    value={item.inter_material_transfer_weight} onChange={(e) => { handleIssueDetailsInputChange(item, e); }} Headers='inter_material_transfer_weight' />
                  :
                  item.inter_material_transfer_weight
                }
              </td>
              <td className="erp_table_td text-end">
                {keyForViewUpdate === '' ?
                  <input type="text" id={`inter_material_transfer_boxes_${Index}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                    value={item.inter_material_transfer_boxes} onChange={(e) => { handleIssueDetailsInputChange(item, e); }} Headers='inter_material_transfer_boxes' />
                  :
                  item.inter_material_transfer_boxes
                }
              </td>
              <td className="erp_table_td text-end">
                {keyForViewUpdate === '' ?
                  <input type="text" id={`internal_material_transfer_remark_${Index}`} className="erp_input_field mb-0 issueField"
                    value={item.internal_material_transfer_remark} onChange={(e) => { handleIssueDetailsInputChange(item, e); }} Headers='internal_material_transfer_remark' />
                  :
                  item.internal_material_transfer_remark
                }
              </td>
              <td className={`erp_table_td text-end `}>{item.cone_per_wt}</td>
              <td className={`erp_table_td text-end `}>{item.product_unit_name}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>

  }, [transferDetailsData])


  //-------------------------------- validate before save ------------------------------------------------------
  const FnValidateInterTransfer = async () => {
    let headerDataIsValid = await validate.current.validateForm("material_transfer_form_id");
    if (!headerDataIsValid) { return false; }
    let transferDetailsIsValid = true;
    let addTransferMaterial = $('#transfer-details-tbl tbody tr').length;
    if (addTransferMaterial <= 0 && keyForViewUpdate !== 'view') {
      setErrMsg('Please Add Atleast One Material Detail.');
      setShowErrorMsgModal(true);
      transferDetailsIsValid = false;
      return false;
    }
    else if (2 <= addTransferMaterial && keyForViewUpdate !== 'view') {
      const seenMaterials = new Set();
      let hasDuplicate = false;
      for (let obj of transferDetailsData) {
        const key = `${obj.product_material_id}-${obj.product_code}`;
        if (seenMaterials.has(key)) {
          hasDuplicate = true;
          break;
        }
        seenMaterials.add(key);
      }
      if (hasDuplicate) {
        setErrMsg('Cannot transfer same material from different Lots');
        setShowErrorMsgModal(true);
        transferDetailsIsValid = false;
        return false;
      }
    }
    if (transferDetailsIsValid) {
      const tableRows = $('#transfer-details-tbl tbody tr');
      transferDetailsIsValid = true;
      tableRows.each(function () {
        if (keyForViewUpdate === "") {
          const transferQty = parseFloat($(this).find('input[id^="inter_material_transfer_quantity_"]').val());
          if (isNaN(transferQty) || transferQty === "") {
            $(this).find(`input[id^='inter_material_transfer_quantity_']`).parent().attr('data-tip', 'Please enter a valid Transfer Quantity...!');
            $(this).find(`input[id^='inter_material_transfer_quantity_']`).focus();
            setOpenMaterialTransferDetailsAccord(true)
            transferDetailsIsValid = false;
            return false;
          }

          const transferWt = parseFloat($(this).find('input[id^="inter_material_transfer_weight_"]').val());
          if (isNaN(transferWt) || transferWt === "") {
            $(this).find(`input[id^='inter_material_transfer_weight_']`).parent().attr('data-tip', 'Please enter a valid Transfer Weight...!');
            $(this).find(`input[id^='inter_material_transfer_weight_']`).focus();
            setOpenMaterialTransferDetailsAccord(true)
            transferDetailsIsValid = false;
            return false;
          }

          const transferBoxes = parseFloat($(this).find('input[id^="inter_material_transfer_boxes_"]').val());
          if (isNaN(transferBoxes) || transferBoxes === "") {
            $(this).find(`input[id^='inter_material_transfer_boxes_']`).parent().attr('data-tip', 'Please enter a valid No Of Boxes...!');
            $(this).find(`input[id^='inter_material_transfer_boxes_']`).focus();
            setOpenMaterialTransferDetailsAccord(true)
            transferDetailsIsValid = false;
            return false;
          }
        }
      });
      return transferDetailsIsValid;
    }
  }


  //-------------------------- Save Json ------------------------------------------------------------------

  const addMaterialTransferEntry = async (status) => {

    try {
      let checkIsValidate = true;
      if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
        checkIsValidate = await FnValidateInterTransfer();
      }
      if (checkIsValidate === true) {
        setIsLoading(true)
        let lastTransNo = txt_internal_transfer_no;
        if (keyForViewUpdate === "") {
          lastTransNo = await GenerateTransferNo();
        }
        let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': {} }
        const TransMasterFormData = {
          inter_material_transfer_master_id: keyForViewUpdate !== "" ? inter_material_transfer_master_id : 0,
          company_branch_id: COMPANY_BRANCH_ID,
          company_id: COMPANY_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          inter_material_transfer_no: lastTransNo,
          inter_material_transfer_date: dt_transfer_date,
          from_department_id: cmb_from_department_id,
          from_sub_department_id: cmb_from_sub_department_id,
          to_department_id: cmb_to_department_id,
          to_sub_department_id: cmb_to_sub_department_id,
          requested_by_id: cmb_transfered_by_id,
          inter_material_transfer_status: cmb_transfer_status,
          created_by: UserName,
          modified_by: idList !== "" ? UserName : null,
          product_type_id: cmb_material_type,
          product_type_name: materialTypeComboRef.current.label
        }
        json.TransHeaderData = TransMasterFormData;

        for (let issueItem = 0; issueItem < transferDetailsData.length; issueItem++) {
          const interTransferDetailsRow = transferDetailsData[issueItem];
          const TransferRow = {};
          TransferRow['batch_no'] = interTransferDetailsRow.batch_no;
          TransferRow['closing_balance_quantity'] = interTransferDetailsRow.closing_balance_quantity;
          TransferRow['closing_balance_weight'] = interTransferDetailsRow.closing_balance_weight;
          TransferRow['closing_no_of_boxes'] = interTransferDetailsRow.closing_no_of_boxes;
          TransferRow['company_branch_id'] = COMPANY_BRANCH_ID;
          TransferRow['company_id'] = COMPANY_ID;
          TransferRow['cone_per_wt'] = interTransferDetailsRow.cone_per_wt;
          TransferRow['financial_year'] = FINANCIAL_SHORT_NAME;
          TransferRow['inter_material_transfer_details_id'] = keyForViewUpdate !== "" ? interTransferDetailsRow.inter_material_transfer_details_id : 0;
          TransferRow['inter_material_transfer_no'] = lastTransNo;
          TransferRow['inter_material_transfer_date'] = dt_transfer_date;
          TransferRow['inter_material_transfer_master_id'] = keyForViewUpdate !== "" ? inter_material_transfer_master_id : 0;
          TransferRow['inter_material_transfer_boxes'] = interTransferDetailsRow.inter_material_transfer_boxes || 0;
          TransferRow['inter_material_transfer_quantity'] = interTransferDetailsRow.inter_material_transfer_quantity || 0;
          TransferRow['inter_material_transfer_weight'] = interTransferDetailsRow.inter_material_transfer_weight || 0;
          TransferRow['internal_material_transfer_remark'] = interTransferDetailsRow.internal_material_transfer_remark || '';
          TransferRow['product_material_id'] = interTransferDetailsRow.product_material_id;
          TransferRow['product_unit_id'] = interTransferDetailsRow.product_material_unit_id;
          TransferRow['product_unit_name'] = interTransferDetailsRow.product_unit_name;
          TransferRow['product_code'] = interTransferDetailsRow.product_code;
          TransferRow['goods_receipt_no'] = interTransferDetailsRow.goods_receipt_no;
          TransferRow['supplier_id'] = interTransferDetailsRow.supplier_id;
          const fromGoDown = $("#cmb_from_sub_department_id").find(":selected");
          TransferRow['from_godown_id'] = fromGoDown.attr("godown_id")
          TransferRow['from_godown_section_id'] = fromGoDown.attr("godown_section_id")
          TransferRow['from_godown_section_beans_id'] = fromGoDown.attr("godown_section_beans_id")
          let toGoDown = $("#cmb_to_sub_department_id").find(":selected");
          TransferRow['to_godown_id'] = toGoDown.attr("godown_id");
          TransferRow['to_godown_section_id'] = toGoDown.attr("godown_section_id")
          TransferRow['to_godown_section_beans_id'] = toGoDown.attr("godown_section_beans_id")
          TransferRow['created_by'] = keyForViewUpdate === "" ? UserName : interTransferDetailsRow.created_by;
          TransferRow['modified_by'] = keyForViewUpdate !== "" ? UserName : null;
          json.TransDetailData.push(TransferRow);
        }
        // Common Ids
        json.commonIds.company_id = COMPANY_ID
        json.commonIds.inter_material_transfer_no = keyForViewUpdate === '' ? lastTransNo : txt_internal_transfer_no
        json.commonIds.financial_year = FINANCIAL_SHORT_NAME
        console.log(`JSON Inter Transfer`, json);
        const formData = new FormData();
        formData.append(`InternalMaterialTransferDetails`, JSON.stringify(json))
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtInternalMaterialTransferDetails/FnAddUpdateRecord/add`, requestOptions)
        const responce = await apicall.json();
        console.log(responce)
        if (responce.success === "0") {
          $('#save-btn').prop("disable", true);
          $('#issue-btn').prop("disable", true);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          if (responce.data.hasOwnProperty('inter_material_transfer_no')) {
            setModalOrderDetails(`Int. Trans. No: ${responce.data.inter_material_transfer_no}`)
          }
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
        }
        setIsLoading(false)
      }
    } catch (error) {
      console.log("error: ", error)
    } finally {
      setIsLoading(false)
    }

  }
  // ------------------------------------------- set update data ----------------------------------------------------


  const FnCheckUpdateResponse = async () => {

    try {

      if (idList !== '') {
        setInternalTransferNo(idList.txt_internal_transfer_no)
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtInternalMaterialTransferDetails/FnShowAllDetailsAndMastermodelRecords/${idList.financial_year}/${COMPANY_ID}?inter_material_transfer_no=${idList.inter_material_transfer_no}`)
        const responce = await apiCall.json();
        console.log('Internal Transfer Data: ', responce);
        let InternalTransferMasterData = responce.internalTransferMasterRecords;
        let InternalTransferDetailsRecords = responce.internalTransferDetailsRecords
        if (InternalTransferMasterData !== null && InternalTransferMasterData !== "") {
          let transferStatus = InternalTransferMasterData.inter_material_transfer_status;
          setInterMaterialTransferMasterId(InternalTransferMasterData.inter_material_transfer_master_id)
          setInternalTransferNo(InternalTransferMasterData.inter_material_transfer_no)
          setTransferDate(InternalTransferMasterData.inter_material_transfer_date)
          setmaterialType(InternalTransferMasterData.product_type_id);
          setTransferStatus(InternalTransferMasterData.inter_material_transfer_status)
          fromDepartmentComboRef.current.value = InternalTransferMasterData.from_department_id
          setFromDepartmentId(InternalTransferMasterData.from_department_id)
          await comboOnChange("from_department")
          setFromSubDepartmentId(InternalTransferMasterData.from_sub_department_id)
          toDepartmentComboRef.current.value = InternalTransferMasterData.to_department_id
          setToDepartmentId(InternalTransferMasterData.to_department_id)
          await comboOnChange("to_department")
          setToSubDepartmentId(InternalTransferMasterData.to_sub_department_id)
          await comboOnChange("toSubDepartment")
          transferedByComboRef.current.value = InternalTransferMasterData.requested_by_id
          setTransferedBy(InternalTransferMasterData.requested_by_id)
          if (InternalTransferDetailsRecords != null && InternalTransferDetailsRecords != '') {
            setTransferDetailsData(InternalTransferDetailsRecords)
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // ------------------------ Changes for the Searchable Combos. --------------------------
  let fromDepartmentComboRef = useRef(null);
  let toDepartmentComboRef = useRef(null);
  let transferedByComboRef = useRef(null);
  let materialTypeComboRef = useRef(null);


  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <ExcelExport ref={exlsExp} />
      <GenerateTAutoNo ref={generateAutoNoAPiCall} />
      <FrmValidations ref={validate} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <DashboardLayout>
        <>
          {isLoading ?
            <div className="spinner-overlay"  >
              <div className="spinner-container">
                <CircularProgress color="primary" />
                <span>Loading...</span>
              </div>
            </div> :
            ''}

          <div className='card p-1'>
            <div className='card-header text-center py-0'>
              <label className='erp-form-label-lg main_heding'> Internal Material Transfer {ActionType()}</label>
            </div>
            <form id="material_transfer_form_id">
              <div className="row p-1">
                {/* //first column */}
                <div className="col-sm-6 erp_form_col_div">
                  <div className='row'>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">Int. Transfer No & Date</Form.Label>
                    </div>
                    <div className='col-sm-8 col-12'>
                      <div className="row">
                        <div className='col-12 col-md-6 pe-md-0'>
                          <Form.Control type="text" id="txt_internal_transfer_no" className="erp_input_field" value={txt_internal_transfer_no} optional='optional' disabled />
                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <Form.Control type="date" id='dt_transfer_date' className="erp_input_field" value={dt_transfer_date} onChange={e => { setTransferDate(e.target.value); validateFields(); }} min={currentDate} disabled />
                          <MDTypography variant="button" id="error_dt_transfer_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">Material Type<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col-sm-8 col-12'>
                      <Select
                        ref={materialTypeComboRef}
                        options={materialtypeOptions}
                        isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                        inputId="cmb_material_type" // Provide the ID for the input box
                        value={materialtypeOptions.find(option => option.value === cmb_material_type)}
                        onChange={(selectedOpt) => {
                          setmaterialType(selectedOpt.value)
                          materialTypeComboRef.current = selectedOpt;
                          comboOnChange('cmb_material_type')
                          validateFields()
                        }}
                        placeholder="Search for a Material Type..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        disabled={['view', 'approve'].includes(keyForViewUpdate)}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_material_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className={`row`}>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">From Department<span className="required">*</span></Form.Label>
                    </div>
                    <div className={`col-sm-8 col-10 mb-1`}>
                      <Select ref={fromDepartmentComboRef}
                        options={fromDepartmentOptions}
                        isDisabled={['view', 'approve', 'update'].includes(keyForViewUpdate)}
                        inputId="cmb_from_department_id"
                        value={fromDepartmentOptions.find(option => option.value === cmb_from_department_id) || null}
                        onChange={(selectedOpt) => {
                          setFromDepartmentId(selectedOpt.value);
                          fromDepartmentComboRef.current = selectedOpt;
                          comboOnChange('from_department');
                          validateFields();
                        }}
                        placeholder="Search for a department..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        styles={{
                          option: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_from_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <Form.Label className="erp-form-label">From Sub Department <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <select id="cmb_from_sub_department_id" className="form-select form-select-sm" value={cmb_from_sub_department_id} onChange={(event) => { setFromSubDepartmentId(event.target.value); comboOnChange('fromSubDepartment'); validateFields(); }}>
                        <option value="">Select</option>
                        {fromSubDepartmentOptions?.map(department => (
                          <option value={department.department_id} godown_id={department.godown_id} godown_section_id={department.godown_section_id} godown_section_beans_id={department.godown_section_beans_id}>{department.department_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_from_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                </div>
                {/* //second column */}
                <div className="col-sm-6 erp_form_col_div">
                  {/* <div className="row">
                    <div className="col-sm-3">
                      <Form.Label className="erp-form-label">Transfer Status</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <select id="cmb_transfer_status " className="form-select form-select-sm" value={cmb_transfer_status} onChange={(e) => { setTransferStatus(e.target.value); }} disabled >
                        <option value="P">Pending</option>
                        <option value="A">Approved</option>
                        <option value="C">Completed</option>
                        <option value="I">Partial Issue</option>
                        <option value="R">Received</option>
                        <option value="MI">Material Issue</option>
                        <option value="IR">Partial Received</option>
                      </select>
                    </div>
                  </div> */}
                  
                  <div className={`row`}>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">Transfered By <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col-sm-8 col-10 mb-1'>
                      <Select ref={transferedByComboRef}
                        options={transfedrByOptions}
                        isDisabled={true}
                        inputId="cmb_transfered_by_id" // Provide the ID for the input box
                        value={transfedrByOptions.find(option => option.value == cmb_transfered_by_id)}
                        // onChange={(selectedOpt) => {
                        //   transferedByComboRef.current = selectedOpt;
                        //   comboOnChange('transfered_by');
                        //   validateFields();
                        // }}
                        placeholder="Search for Transfered by..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        styles={{
                          option: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_transfered_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className={`row`}>
                    <div className='col-sm-3 col-12'>
                      <Form.Label className="erp-form-label">To Department<span className="required">*</span></Form.Label>
                    </div>
                    <div className={`col-sm-8 col-10 mb-1`}>
                      <Select ref={toDepartmentComboRef}
                        options={toDepartmentOptions}
                        isDisabled={['view', 'approve', 'update'].includes(keyForViewUpdate)}
                        inputId="cmb_to_department_id"
                        value={toDepartmentOptions.find(option => option.value === cmb_to_department_id) || null}
                        onChange={(selectedOpt) => {
                          setToDepartmentId(selectedOpt.value);
                          toDepartmentComboRef.current = selectedOpt;
                          comboOnChange('to_department');
                          validateFields();
                        }}
                        placeholder="Search for a department..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        styles={{
                          option: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_to_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <Form.Label className="erp-form-label">To Sub Department <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <select id="cmb_to_sub_department_id" className="form-select form-select-sm" value={cmb_to_sub_department_id} onChange={() => { comboOnChange('toSubDepartment'); validateFields(); }}>
                        <option value="">Select</option>
                        {toSubDepartmentOptions?.map(department => (
                          <option value={department.department_id} godown_id={department.godown_id} godown_section_id={department.godown_section_id} godown_section_beans_id={department.godown_section_beans_id}>{department.department_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_to_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>
          <hr />

          <Accordion defaultActiveKey="0" activeKey={openMaterialtransferDetailsAccord ? '0' : null}>
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={(e) => setOpenMaterialTransferDetailsAccord(!openMaterialtransferDetailsAccord)} className="erp-form-label-md">Material Details</Accordion.Header>
              <Accordion.Body>
                <div className={`row ${keyForViewUpdate === "" ? 'display' : 'd-none'}`}>
                  <div className="col-sm-4 col-12">
                    <div className={`row`}>
                      <div className="col-md-8 col-12">
                        <SearchItemFilter getSearchData={FnGetStockDetails} onChange={validateFields} formIdForValidate="material_transfer_form_id" requiredCols={requiredCols} company_id={COMPANY_ID} product_type_id={cmb_material_type} godownId={cmb_from_department_id == 23 && (cmb_from_sub_department_id == 62 || cmb_from_sub_department_id == 95) ? "7" : cmb_from_sub_department_id == 94 ? "6" : "2"} />
                        <MDTypography variant="button" id="error_txt_product_material_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="erp-Mt-10">
                  {transferDetailsData.length !== 0 ?
                    <>
                      {renderDetailsTable}
                    </> :
                    <div className='row  text-center'>
                      <div className="col-12 ">
                        <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                      </div>
                    </div>
                  }
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion >
        </>

        <div className="text-center mb-5">
          <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
          <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" onClick={() => { addMaterialTransferEntry('P') }}
            fontWeight="regular">Save</MDButton>
          <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" id='clearbutton-id' onClick={FnClearFormFields} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Clear</MDButton>
          <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>
        </div >

        <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
        {/* Delete Modal */}
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
          <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
          <Modal.Body className='erp_modal_body'>
            <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
            <h6>Are you sure?</h6>
            <div className="erp-form-label">Do you wish to delete this record ?</div>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <Button variant="success" className='erp-gb-button' onClick={handleClose}>
              Cancel
            </Button>&nbsp;
            {/* <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button> */}
          </Modal.Footer>
        </Modal>

      </DashboardLayout >
    </>
  )
}