import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

const WarpingBottomReturnSlip = React.forwardRef(({ invoiceContent }, ref) => {

    const data = invoiceContent.items
    const keyForViewUpdate = invoiceContent.materialDetails.keyForViewUpdate
    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row'>

            </div>
            <div className='row mt-4'>
                <div className='row mt-0'>
                    <div className='col text-center'>
                        <dt className="erp-invoice-print-label-md-lg"></dt>
                    </div>

                    <div className='col text-center'>
                        <dt className="erp-invoice-print-label-md-lg ps-4"></dt>
                    </div>

                </div>
            </div>

            <div className="row mt-0">
                <div className="col text-center me-2 d-flex flex-column align-items-center">
                    <dl className="mb-0">
                        <dd className="erp-invoice-print-label-md-lg" style={{ fontSize: '9px', marginBottom: '2px' }}>
                            {invoiceContent.header.return_by_name}
                        </dd>
                        <dt className="erp-invoice-print-label-md-lg">Returned By</dt>
                    </dl>
                </div>

                <div className="col text-center d-flex flex-column align-items-center">
                    <dl className="mb-0">
                        <dd className="erp-invoice-print-label-md-lg" style={{ fontSize: '9px', marginBottom: '2px' }}>
                            {invoiceContent.header.received_by_name}
                        </dd>
                        <dt className="erp-invoice-print-label-md-lg">Received By</dt>
                    </dl>
                </div>
            </div>

        </div>
    );

    return (
        <>

            <div className="row">

                <div className="col-12" >
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    <div className='bordered border-bottom-0 px-0'>
                                        <div className='row mb-0 p-1'>
                                            <div className="col-sm-2">
                                                <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                                            </div>
                                            <div className="col-sm-10">
                                                <div className='row'>
                                                    <div className="col-sm-9">
                                                        <div className='erp-invoice-print-label text-center'>
                                                            <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                                                        </div>

                                                        <div className='erp-invoice-print-label-lg text-center'>
                                                            Warping Bottom Return
                                                        </div>

                                                    </div>
                                                    {/* <div className="col-sm-3">
                                                        <span className='erp-invoice-print-label-md ps-4'>Copy</span><br />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Bottom Return No : {invoiceContent.materialDetails.bottom_return_no}</dt>
                                            </div>

                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}> Date : {invoiceContent.materialDetails.issue_return_date}</dt>
                                            </div>
                                        </div>
                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className="row p-0 m-0">
                                                <div className="col-sm-12">
                                                    <dl className="row mb-0 pb-0">
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">
                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Bottom Return Type : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.materialDetails.issue_type}</dd>

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Department : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.department_name}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Sub-Department  : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.sub_department_name}</dd>
{/* 
                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Returned By : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.return_by_name}</dd> */}

                                                            </dl>
                                                        </dl>
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">

                                                                {/* <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Received By </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.received_by_name}</dd> */}

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Received Date </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.received_date}</dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Remark : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.remark}</dd>

                                                                {/* <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Sub-Department : </dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.sub_department_name}</dd> */}
                                                            </dl>
                                                        </dl>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead className='erp_table_border'>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Sr.no</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Set No</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Count</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Party Name</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Supplier</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >SR.No</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >No.Of Cones</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >GR.WT</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >TR.WT </th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >NT.WT </th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Rec. GR.WT</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Rec. NT.WT</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Cone Type</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Box/Bora</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Godown Name</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Return Item Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {data.map((returnItem, Index) =>
                                                                <tr className={`tblBodyTrHt-${Index}`}>
                                                                        <td className="erp_invoice_table_td text-center">{Index + 1}</td>
                                                                        <td className="erp_invoice_table_td text-center">{returnItem.set_no}</td>
                                                                        <td className="erp_invoice_table_td text-center">{returnItem.product_rm_name}</td>
                                                                        <td className="erp_invoice_table_td ">{returnItem.customer_name}</td>
                                                                        <td className={`erp_invoice_table_td`}>{returnItem.supplier_name}</td>
                                                                        <td className={`erp_invoice_table_td text-end`}>{returnItem.sr_no} </td>
                                                                        <td className={`erp_invoice_table_td text-end`}>{returnItem.no_of_package}</td>
                                                                        <td className={`erp_invoice_table_td text-end`}>{returnItem.gross_weight}</td>
                                                                        <td className={`erp_invoice_table_td text-end`}>{returnItem.tare_weight}</td>
                                                                        <td className={`erp_invoice_table_td text-end`}>{returnItem.net_weight} </td>
                                                                        <td className={`erp_invoice_table_td text-end`}>{returnItem.actual_gross_weight}</td>
                                                                        <td className={`erp_invoice_table_td text-end`}>{returnItem.actual_net_weight}</td>
                                                                        <td className="erp_invoice_table_td">{returnItem.cone_type}  </td>
                                                                        <td className="erp_invoice_table_td">{returnItem.bora_box}  </td>
                                                                        <td className={`erp_invoice_table_td `}>{returnItem.godown_name}</td>
                                                                        <td className="erp_invoice_table_td">{returnItem.bottom_return_item_status_desc}  </td>
                                                                    </tr>
                                                                )}
                                                                 <tr>
                                                                    <td colSpan={18}>
                                                                        {invoiceFooter}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                
            </div >
            <style>
                {`
                .erp-invoice-print-label-md-lg,
                .erp_invoice_table_td,
                .erp-invoice-print-label {
                    line-height: 1.5;  
                }
                `}
            </style>
        </>
    );
})


export default WarpingBottomReturnSlip;

