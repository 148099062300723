import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from 'jquery';
import Tooltip from "@mui/material/Tooltip";
import Select from 'react-select';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { DatatableWithId } from "components/DataTable";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// File Imports
import ComboBox from "Features/ComboBox";
import DocumentF from "Features/Document";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmValidations from "FrmGeneric/FrmValidations";

// Import React icons
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMProductCategory1Entry from "Masters/MProductCategory1/FrmProductCategory1Entry";
import FrmHSNSAC from "Masters/MHSN-SAC/FrmHSN-SAC";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import FrmProductUnitEntry from "Masters/MProductUnit/FrmProductUnitEntry";
import FrmMProductPackingEntry from "Masters/MProductPacking/FrmMProductPackingEntry";
import { MdRefresh } from "react-icons/md";
import ConfigConstants from "assets/Constants/config-constant";
import { CircularProgress } from "@material-ui/core";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductMaterialShapeEntry from "Masters/MProductMaterialShape/FrmMProductMaterialShapeEntry";
import FrmMProductMaterialGradeEntry from "Masters/MProductMaterialGrade/FrmMProductMaterialGradeEntry";
import FrmMProductMaterialTypeEntry from "Masters/MProductMaterialType/FrmMProductMaterialTypeEntry";
import FrmMProductCategory2Entry from "Masters/MProductCategory2/FrmMProductCategory2Entry";
import FrmMProductCategory3Entry from "Masters/MProductCategory3/FrmMProductCategory3Entry";
import FrmMProductCategory4Entry from "Masters/MProductCategory4/FrmMProductCategory4Entry";
import FrmMProductCategory5Entry from "Masters/MProductCategory5/FrmMProductCategory5Entry";
import GenerateMaterialId from "FrmGeneric/GenerateMaterialId/GenerateMaterialId";
import FrmPropertyEntry from "Masters/MProperty/FrmPropertyEntry";
import DeleteModal from "components/Modals/DeleteModal";
import { RxCrossCircled } from "react-icons/rx";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";
import ProfitCenterEntry from "Masters/ProfitCenter/ProfitCenterEntry";
import FrmProductTypeDynamicControlsEntry from "Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsEntry";
import { item } from "examples/Sidenav/styles/sidenavItem";


export default function FrmMProductFGEntry({ goToNext, fg_id }) {

  // Call ConfigConstants to get the configuration constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_CATEGORY_COUNT } = configConstants;

  //In listing page we passed in navigate
  const { state } = useLocation();
  const { productFGId, productFGName, keyForViewUpdate, productTypeId, category1Id, compType, productId = 0 } = state || {}

  let categoryCount = "";

  let docGroup = "Finish Goods";



  // For navigate
  const navigate = useNavigate();
  const generateMaterialIdAPiCall = useRef();
  const comboBoxRef = useRef();
  const validationRef = useRef();
  const validatePercentage = useRef();

  // Combo Options for Product Finish Good Entry
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [productCategory1Options, setProductCategory1Options] = useState([])
  const [hsnSacCodeOptions, setProductHsnSacCodeOptions] = useState([])
  const [unitOptions, setProductUnitOptions] = useState([])
  const [productPackingOptions, setProductPackingOptions] = useState([])
  const [consumtionModeOptions, setProductConsumtionModeOptions] = useState([])
  const [materialOriginOptions, setProductMaterialOriginOptions] = useState([])
  const [countryOptions, setCountryOptions] = useState([])

  //  Form Fields for Product Finish Goods
  const [product_id, setProduct_id] = useState(productId);
  const [product_fg_id, setProductFgId] = useState(productFGId === 0 && fg_id !== 0 ? fg_id : productFGId)
  const [cmb_product_type_id, setproductTypeId] = useState('')
  const [cmb_product_category1_id, setProductcategory1Id] = useState('3');
  const [txt_product_fg_code, setFGCode] = useState('');
  const [txt_product_fg_name, setFGName] = useState(productFGName);
  const [txt_fg_short_name, setFGShortName] = useState('');
  const [txt_fg_print_name, setFGPrintName] = useState('');
  const [txt_fg_tech_spect, setFGTechSpect] = useState('');
  const [txt_fg_oem_part_code, setFGOemPartCode] = useState('');
  const [txt_fg_our_part_code, setFGOurPartCode] = useState('');
  const [txt_fg_item_sr_no, setFGItemSrNo] = useState(1);
  const [txt_fg_drawing_no, setFGDrawingNo] = useState('');
  const [txt_fg_model_no, setFGModelNo] = useState('');
  const [cmb_fg_hsn_sac_code_id, setFGHsnSacCodeId] = useState('');
  const [cmb_fg_purchase_unit_id, setFGPurchaseUnitId] = useState(6);
  const [cmb_fg_sales_unit_id, setFGSalesUnitId] = useState(6);
  const [cmb_fg_stock_unit_id, setFGStockUnitId] = useState(6);
  const [cmb_fg_packing_id, setFGPackingUnitId] = useState(1);
  const [txt_fg_bar_code, setFGBarCode] = useState('');
  const [file_fg_qr_code, setFGQrCode] = useState();
  const [file_fg_qr_codeName, setFGQrCodePath] = useState();
  const [cmb_consumption_mode, setConsumptionMode] = useState('All');
  const [cmb_origin_type, setOriginType] = useState('Indigenous');
  const [cmb_origin_country, setOriginCountry] = useState('India');
  const [txt_remarkForProductEntry, setRemarkForProductEntry] = useState('');
  const [txt_qa_remark, setQaRemark] = useState('');
  const [is_activeGen, setIsActiveGen] = useState(true);
  const [is_BOMApplicable, setBOMApplicable] = useState('No');


  const [cmb_godown_id, setgodownid] = useState('');
  const [cmb_godown_section_id, setgodownsectionid] = useState('')
  const [cmb_godown_section_beans_id, setgodownsectionbeansid] = useState('')

  //combo option
  const [godownidOptions, setgodownidOptions] = useState([])
  const [godownidSectionOptions, setgodownidSectionOptions] = useState([])
  const [godownsectionbeansidOptions, setgodownsectionbeansidOptions] = useState([])

  // Option Box
  const [category2Options, setCategory2Options] = useState([])
  const [category3Options, setCategory3Options] = useState([])
  const [category4Options, setCategory4Options] = useState([])
  const [category5Options, setCategory5Options] = useState([])
  const [pMaterialTypeOptions, setPMaterialTypeOptions] = useState([])
  const [pMaterialGradeOptions, setPMaterialGradeOptions] = useState([])
  const [pMaterialShapeOptions, setPMaterialShapeOptions] = useState([])
  const [pMaterialColorOptions, setPMaterialColorOptions] = useState([])
  const [pAlternateMaterialOptions, setPAlternateMaterialOptions] = useState([])

  //code for search
  const [productCat1Text, setProductCat1Text] = useState('');
  const [productCat2Text, setProductCat2Text] = useState('');
  const [productCat3Text, setProductCat3Text] = useState('');
  const [productCat4Text, setProductCat4Text] = useState('');
  const [productCat5Text, setProductCat5Text] = useState('');
  const [profit_center, setProfitCenterforRing] = useState('');

  // Technical Entry Form Fields
  const [product_fg_technical_id, setProductFGTechnicalId] = useState(0)
  const [txt_product_fg_technical_name, setPTechnivalName] = useState('');
  const [cmb_product_category2_id, setPCategory2Id] = useState('');
  const [cmb_product_category3_id, setPCategory3Id] = useState('');
  const [cmb_product_category4_id, setPCategory4Id] = useState('');
  const [cmb_product_category5_id, setPCategory5Id] = useState('');
  const [cmb_product_material_type_id, setPMaterialTypeId] = useState('');
  const [cmb_product_material_grade_id, setPMaterialGradeId] = useState('');
  const [cmb_product_material_shape_id, setPMaterialShapeId] = useState('');
  const [cmb_product_material_colour, setPMaterialColour] = useState('NA');
  const [cmb_product_alternate_fg_id, setPAlternateId] = useState('');
  const [txt_assembly_scrap_percent, setAssemblyScrapPercent] = useState(0);

  const [txt_glm, setGLM] = useState(0);
  const [txt_gsm, setGSM] = useState(0);

  //  Form Fields For Commercial Entry
  const [product_fg_commercial_id, setProductFGCommercialId] = useState(0)
  const [txt_product_fg_gross_weight, setPFGGrossWeight] = useState(0);
  const [txt_product_fg_net_weight, setPFGNetWeight] = useState(0);
  const [txt_product_fg_std_weight, setPFGStdWeight] = useState(0);
  const [txt_product_fg_volume, setPFGVolume] = useState(0);
  const [txt_product_fg_mrp, setPFGMrp] = useState(1);
  const [txt_product_fg_landed_price, setPFGLandedPrice] = useState(1);
  const [txt_product_fg_avg_price, setPFGAvgPrice] = useState(1);
  const [txt_product_fg_std_profit_percent, setPFGStdProfitPercent] = useState(0);
  const [txt_product_fg_std_discount_percent, setPFGDiscountPercent] = useState(0);
  const [txt_product_fg_moq, setPFGmoq] = useState(0);
  const [txt_product_fg_mov, setPFGmov] = useState(0);
  const [txt_product_fg_eoq, setPFGeoq] = useState(0);
  const [txt_product_fg_mpq, setPFGmpq] = useState(0);
  const [txt_product_fg_min_stock_level, setPFGMinStockLevel] = useState(0);
  const [txt_product_fg_max_stock_level, setPFGMaxStockLevel] = useState(0);
  const [txt_product_fg_reorder_stock_level, setPFGReorderStockLevel] = useState(0);
  const [txt_product_fg_depriciation_percent, setPFGDepricationPercent] = useState(0);
  const [txt_sales_tolerance_percent, setSalesTolerancePercent] = useState(0);
  const [is_testCertified, setIsTestCertified] = useState(false);
  const [txt_routing_code, setRoutingCode] = useState("");
  const [cmb_profit_center, setProfitCenter] = useState(0);
  const [cmb_cost_center, setCostCenter] = useState(0);
  const [profit_centerOptions, setProfitCenterOptions] = useState([]);
  const [cost_centerOptions, setCostCenterOptions] = useState([]);
  const [is_qaRequired, setIsQARequired] = useState(false);
  const [is_priceType, setIsPriceType] = useState('Fixed');
  const [is_abcAnalysis, setIsABCanalysis] = useState('C');

  //hooks of update||View data onclick of accordian
  const [supplierMappingData, setSupplierMappingData] = useState([]);
  const [processMappingData, setProcessMappingData] = useState([]);
  const [qaMappingData, setQAMappingData] = useState([]);
  const [customerMappingData, setCustomerMappingData] = useState([]);


  const [CostCenterCheckboxes, setCostCenterCheckboxes] = useState();
  const [totalSelectedCostCenterCheckboxes, setTotalSelectedCostCenterCheckboxes] = useState(1);
  // const [supplierGlCodes, setSupplierGLCodes] = useState('');
  let expandedCostCenterCombo = false;
  // Data & Columns For Supplier Entry
  const [dataForSuppEntry, setSuppData] = useState([]);
  const [columnsForSuppEntry, setColumns] = useState([]);

  // Data & Columns For Customer Entry
  const [dataForCust, setCustomerData] = useState([]);
  const [columnsForCust, setCustomerColumns] = useState([]);

  // Table Data Process
  const [dataForProcess, setProccessData] = useState([]);
  const [columnsForProcess, setProcessColumns] = useState([]);

  // Data & Cols For QA 
  const [dataQA, setQaMappingData] = useState([]);
  const [columnsQA, setQAColumns] = useState([]);


  //production Count
  // const [txt_Mixing, settxt_Mixing] = useState(0);
  const [txt_Count_Desc, settxt_Count_Desc] = useState('');
  const [txt_Count_name, settxt_Count_name] = useState('');
  const [txt_Actual_Count, settxt_Actual_Count] = useState(0);


  // Product Type Properties Table Data
  const [productTypePropertiesData, setProductTypePropertiesData] = useState([]);


  const [showAddRecModal, setShowAddRecModal] = useState(false);

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    const path = compType === 'Register' ? '/Masters/FinishGoodsListing/reg' : '/Masters/FinishGoodsListing';
    navigate(path);
  }
  // Success Msg HANDLING
  const handleCloseProdSuccessModal = () => {
    setShowProdSuccessMsgModal(false);

  }

  const [showProdSuccessMsgModal, setShowProdSuccessMsgModal] = useState(false);
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // Document Form
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const handleCloseDocumentForm = () => setShowDocumentForm(false);
  const viewDocumentForm = () => setShowDocumentForm(true);

  // production Count form
  const [showProductionForm, setShowProductionForm] = useState(false);

  const handleCloseProductionCountForm = async () => {
    clearFormFields();
    setShowProductionForm(false);

    // Map through the productTypePropertiesData and handle async operations
    const updatedData = await Promise.all(
      productTypePropertiesData.map(async (filter) => {
        if (filter.control_master === "xmv_spinning_prod_count") {
          resetGlobalQuery();
          globalQuery.columns = ["field_name", "field_id", "production_actual_count"];
          globalQuery.table = "xmv_spinning_prod_count";

          // Fetch the new data
          const masterList = await comboBoxRef.current.fillFiltersCombo(globalQuery);

          return {
            ...filter,
            newFilter: {
              ...filter.newFilter,
              dataArray: masterList, // Update dataArray with the fetched data
            },
          };
        }
        return filter;
      })
    );

    // Update the state with the modified data
    setProductTypePropertiesData(updatedData);
  };


  const viewProductionForm = () => setShowProductionForm(true);
  const clearFormFields = () => {
    // settxt_Mixing('');
    settxt_Count_Desc('');
    settxt_Count_name('');
    settxt_Actual_Count('');
  }

  // Show ADD record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Product Type':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("product_type_short_name");
        globalQuery.table = "smv_product_type"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        // globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: "FG" });
        globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["FG", "WV", "Tx", "YN"] });
        comboBoxRef.current.removeCatcheFillCombo(globalQuery).then((product_Types) => {
          product_Types = product_Types.sort((a, b) => { if (a.field_name < b.field_name) { return -1; } return 1; });
          setProductTypeOptions(product_Types)
        })

        break;
      case 'Product Category1':
        let Category1Val = document.getElementById('cmb_product_type_id').value;
        resetGlobalQuery();
        globalQuery.columns = ['field_id', 'field_name', 'product_category1_short_name'];
        globalQuery.table = "smv_product_category1";
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: Category1Val });
        const productCategory1ApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
        const productCat1Opts = [
          { value: '', label: 'Select', short_name: '' },
          { value: '0', label: 'Add New Record+', short_name: '' },
          ...productCategory1ApiCall.map(item => ({
            value: item.field_id,
            label: item.field_name,
            short_name: item.product_category1_short_name,
          }))
        ];
        setProductCategory1Options(productCat1Opts);
        break;

      case 'Product Category-2':
        comboOnChangeforSearch('productCategory1')
        break;

      case 'HSN-SAC':
        resetGlobalQuery();
        globalQuery.columns = ['field_id', 'field_name'];
        globalQuery.table = "cmv_hsn_sac";
        const HsnSacCodeApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
        const hsnsacOpts = [
          { value: '', label: 'Select', short_name: '' },
          { value: '0', label: 'Add New Record+', short_name: '' },
          ...HsnSacCodeApiCall.map(item => ({
            value: item.field_id,
            label: item.field_name,
          }))
        ];
        setProductHsnSacCodeOptions(hsnsacOpts)


        // comboBoxRef.current.fillMasterData("cmv_hsn_sac", "", "").then((HsnSacCodeApiCall) => {
        //   const updatedProducthsncodeOptions = updateKeysForSearchBox(HsnSacCodeApiCall);
        //   setProductHsnSacCodeOptions(updatedProducthsncodeOptions)
        //   // setProductHsnSacCodeOptions(HsnSacCodeApiCall)
        // })
        break;

      case 'Puchase Unit':
        comboBoxRef.current.fillMasterData("smv_product_unit", "", "").then((PuchaseUnitApiCall) => {
          setProductUnitOptions(PuchaseUnitApiCall)
        })
        break;

      case 'Sales Unit':
        comboBoxRef.current.fillMasterData("smv_product_unit", "", "").then((SalesUnitApiCall) => {
          setProductUnitOptions(SalesUnitApiCall)
        })
        break;

      case 'Stock Unit':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "smv_product_unit"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "remark", operator: "=", value: "Cotspin" });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        let productUnitApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
        setProductUnitOptions(productUnitApiCall)
        break;

      case 'Packing':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "smv_product_packing"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "remark", operator: "=", value: "Cotspin" });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        let productPackingApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
        setProductPackingOptions(productPackingApiCall);
        break;

      case 'Material Type':
        // Product Material Type
        comboBoxRef.current.fillMasterData("smv_product_material_type", "product_type_id", productTypeId).then((productMaterialList) => {
          setPMaterialTypeOptions(productMaterialList)
        })
        break;

      case 'Material Grade':
        comboOnChange('productMaterialType')
        break;

      case 'Material Shape':
        // Product Material Shape
        comboBoxRef.current.fillMasterData("smv_product_material_shape", "product_type_id", productTypeId).then((productMaterialShapeList) => {
          setPMaterialShapeOptions(productMaterialShapeList)
        })

        break;
      case 'Product Category-2':
        comboOnChange('productCategory1')
        break;
      case 'Product Category-3':
        comboOnChange('productCategory2')
        break;
      case 'Product Category-4':
        comboOnChange('productCategory3')
        break;
      case 'Product Category-5':
        comboOnChange('productCategory4')
        break;
      case 'Consumption Mode':
        comboOnChange('consumptionMode')
        break;
      // case 'Profit Center':
      //   try {
      //     resetGlobalQuery();
      //     globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
      //     globalQuery.table = "fmv_profit_center";
      //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      //     const getProfitCenterList = await comboBoxRef.current.removeCatcheFillCombo(globalQuery);
      //     const profitCenterOpts = [
      //       { value: '', label: 'Select', centerShortName: '' },
      //       { value: '0', label: 'Add New Record+', centerShortName: '' },
      //       ...getProfitCenterList.map((profitCenter) => ({ ...profitCenter, value: profitCenter.field_id, label: `[${profitCenter.profit_center_short_name}] ${profitCenter.field_name}`, centerShortName: profitCenter.cost_center_short_name, })),
      //     ];
      //     setProfitCenterOptions(profitCenterOpts)

      //   } catch (error) {
      //     console.log("error : ", error)
      //   }
      //   break;
      // case 'Cost Center':
      //   try {
      //     resetGlobalQuery();
      //     globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
      //     globalQuery.table = "fmv_cost_center";
      //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      //     const costCenterList = await comboBoxRef.current.removeCatcheFillCombo(globalQuery);
      //     const costCenterOpts = [
      //       { value: '', label: 'Select', centerShortName: '' },
      //       { value: '0', label: 'Add New Record+', centerShortName: '' },
      //       ...costCenterList.map((costcenter) => ({ ...costcenter, value: costcenter.field_id, label: `[${costcenter.cost_center_short_name}] ${costcenter.field_name}`, centerShortName: costcenter.cost_center_short_name, })),
      //     ];
      //     setCostCenterOptions(costCenterOpts);

      //   } catch (error) {
      //     console.log("error : ", error)
      //   }
      //   break;
      case 'Product Properties':
        fnGetProductTypeControls(cmb_product_type_id);
        break;
      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
  }


  const [modalHeaderName, setHeaderName] = useState('')
  // Loader
  const [isLoading, setIsLoading] = useState(false);
  const [parametersIsLoading, setParametersIsLoading] = useState(false);

  useEffect(async () => {
    // debugger
    setIsLoading(true);
    await fillComobos();
    if (product_fg_id !== 0 && product_fg_id !== undefined && productFGId !== "") {
      await FnCheckUpdateResponce();
    }
    setIsLoading(false);
    $(document).on('mouseup', function (e) {
      let container1 = $("#gl_code_ul");

      if (!container1.is(e.target) && container1.has(e.target).length === 0) {
        container1.hide();
      }
    });
  }, [])

  const excludedcustFields = [
    '_id', 'field_name', 'is_', '_on', '_by', 'company_', 'customer_type', 'customer_code', 'customer_short_name', 'nature_of_business',
    'customer_gl_codes', 'customer_history', 'cust_branch_short_name', 'cust_branch_address1', 'cust_branch_address2', 'cust_branch_pincode', 'branch_type', 'cust_branch_region',
    'cust_branch_linkedin_profile', 'cust_branch_twitter_profile', 'cust_branch_facebook_profile', 'cust_branch_gst_division', 'cust_branch_gst_range', 'cust_branch_pan_no',
    'cust_branch_udyog_adhar_no', 'cust_branch_vat_no', 'cust_branch_service_tax_no', 'cust_branch_excise_no', 'cust_branch_cst_no', 'cust_branch_bst_no', 'cust_branch_rating',
    'cust_branch_gl_codes', 'customer_username', 'customer_password', 'remark', 'active', 'deleted'
  ];

  const fillComobos = async () => {
    try {

      // const productCategory1ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category1", "product_type_id", "")
      // setProductCategory1Options(productCategory1ApiCall);

      // const productCategory2ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category2", "product_category1_id", "")
      // const updatedProductCategory2Options = updateKeysForSearchBox(productCategory2ApiCall);
      // setCategory2Options(updatedProductCategory2Options);
      //setCategory2Options(productCategory2ApiCall);

      // const productCat3ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category3", "product_category2_id", '')
      // const updatedProductCategory3Options = updateKeysForSearchBox(productCat3ApiCall);
      // setCategory3Options(updatedProductCategory3Options);
      //setCategory3Options(productCat3ApiCall)

      // const productCat4ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category4", "product_category3_id", '')
      // const updatedProductCategory4Options = updateKeysForSearchBox(productCat4ApiCall);
      // setCategory4Options(updatedProductCategory4Options);
      //  setCategory4Options(productCat4ApiCall)

      // const productCat5ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category5", "product_category4_id", '')
      // const updatedProductCategory5Options = updateKeysForSearchBox(productCat5ApiCall);
      // setCategory5Options(updatedProductCategory5Options);
      // setCategory5Options(productCat5ApiCall)

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.columns.push("product_type_short_name");
      globalQuery.table = "smv_product_type"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["FG", "WV", "Tx", "YN"] });
      comboBoxRef.current.fillFiltersCombo(globalQuery).then((product_Types) => {
        product_Types = product_Types.sort((a, b) => { if (a.field_name < b.field_name) { return -1; } return 1; });
        setProductTypeOptions(product_Types)
      })

      // const HsnSacCodeApiCall = await comboBoxRef.current.fillMasterData("cmv_hsn_sac", "", "")
      // setProductHsnSacCodeOptions(HsnSacCodeApiCall)

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_hsn_sac"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "hsn_sac_type", operator: "=", value: "HSN" });

      const HsnSacCodeApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
      const ProductHsnCodeOptions = [
        { value: '', label: 'Select', short_name: '' },
        { value: '0', label: 'Add New Record+', short_name: '' },
        ...HsnSacCodeApiCall.map(item => ({
          value: item.field_id,
          label: item.field_name,
        }))
      ];
      setProductHsnSacCodeOptions(ProductHsnCodeOptions);

      // comboBoxRef.current.fillFiltersCombo(globalQuery).then((HsnSacCodeApiCall) => {
      //   const updatedProducthsncodeOptions = updateKeysForSearchBox(HsnSacCodeApiCall);
      //   setProductHsnSacCodeOptions(updatedProducthsncodeOptions)
      //   // setProductHsnSacCodeOptions(HsnSacCodeApiCall)
      // })

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "smv_product_unit"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "remark", operator: "=", value: "Cotspin" });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      let productUnitApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
      setProductUnitOptions(productUnitApiCall)


      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "smv_product_packing"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "remark", operator: "=", value: "Cotspin" });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      let productPackingApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
      setProductPackingOptions(productPackingApiCall);


      comboBoxRef.current.fillComboBox("MaterialConsumptionMode").then((consumtionModeApiCall) => {
        setProductConsumtionModeOptions(consumtionModeApiCall)
      })

      comboBoxRef.current.fillComboBox("MaterialOrigin")
        .then((originApiCall) => {
          setProductMaterialOriginOptions(originApiCall)
        })

      comboBoxRef.current.fillMasterData("cmv_country", "", "").then((countryApiCall) => {
        setCountryOptions(countryApiCall)
      })

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_godown"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      var godownidApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery)
      if (godownidApiCall.length !== 0) {
        setgodownidOptions(godownidApiCall)
        if (keyForViewUpdate === "") {
          setgodownid(2);
          await comboOnChange('godown')
          await comboOnChange('godownsection')
        }
      }



      //Fillcombos for Tech. Entry starts here!!
      // get category count from sesssion
      // categoryCount = sessionStorage.getItem('compRMCatCount')

      for (let count = 2; count <= COMPANY_CATEGORY_COUNT; count++) {
        $('#product_category' + count + '_id').show()
      }

      // Category label display
      resetGlobalQuery();
      globalQuery.columns.push("property_value");
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'FGCategorySettings' });
      globalQuery.orderBy = ["property_id"];
      globalQuery.table = "amv_properties"
      var labelList = await comboBoxRef.current.fillFiltersCombo(globalQuery)

      // Display category labels
      if (labelList.length !== 0) {
        for (let count = 1; count < COMPANY_CATEGORY_COUNT; count++) {
          $('#cat' + count + 'Label').append(labelList[count].property_value)
        }
      } else {
        for (let count = 1; count <= COMPANY_CATEGORY_COUNT; count++) {
          $('#cat' + count + 'Label').append('FG Category ' + [count] + ' :')
        }
      }

      // Category 2 list

      // resetGlobalQuery();
      // globalQuery.columns.push("field_id");
      // globalQuery.columns.push("field_name");
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      // globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
      // globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
      // globalQuery.table = "smv_product_category2"
      // comboBoxRef.current.fillFiltersCombo(globalQuery).then((masterList2) => {
      //   setCategory2Options(masterList2)
      // })

      // Alternate FG ids
      resetGlobalQuery();
      globalQuery.columns.push("product_fg_name");
      globalQuery.columns.push("product_fg_id");
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
      globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: category1Id });
      globalQuery.table = "smv_product_fg_details"
      comboBoxRef.current.fillFiltersCombo(globalQuery).then((pAltrRMIds) => {
        setPAlternateMaterialOptions(pAltrRMIds)
      })

      // Product Material Color 
      comboBoxRef.current.fillComboBox("Colour").then((productMaterialColorList) => {
        setPMaterialColorOptions(productMaterialColorList)
      })

      if (productTypeId !== "" && productTypeId !== "0" && product_fg_id !== undefined) {
        await FnGetProductTypeDependentCombo(productTypeId);
      }

      //Costcenter List
      // resetGlobalQuery();
      // globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
      // globalQuery.table = "fmv_cost_center";
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // comboBoxRef.current.fillFiltersCombo(globalQuery)
      //   .then((getCostCenterList) => {
      //     setCostCenterOptions(getCostCenterList);
      //   })           
      // resetGlobalQuery();
      // globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
      // globalQuery.table = "fmv_cost_center";
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // const costCenterList = await comboBoxRef.current.removeCatcheFillCombo(globalQuery);
      // const costCenterOpts = [
      //   { value: '', label: 'Select', centerShortName: '' },
      //   { value: '0', label: 'Add New Record+', centerShortName: '' },
      //   ...costCenterList.map((costcenter) => ({ ...costcenter, value: costcenter.field_id, label: `[${costcenter.cost_center_short_name}] ${costcenter.field_name}`, centerShortName: costcenter.cost_center_short_name, })),
      // ];
      // setCostCenterOptions(costCenterOpts);

      // ///Profitcenter List
      // resetGlobalQuery();
      // globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
      // globalQuery.table = "fmv_profit_center";
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // comboBoxRef.current.fillFiltersCombo(globalQuery)
      //   .then((getProfitCenterList) => {
      //     const updatedProfitCenterOptions = updateKeysForSearchBoxProfitCenter(getProfitCenterList);
      //     setProfitCenterOptions(updatedProfitCenterOptions)
      //     //setProfitCenterOptions(getProfitCenterList);
      //   })

      // resetGlobalQuery();
      // globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
      // globalQuery.table = "fmv_profit_center";
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // const getProfitCenterList = await comboBoxRef.current.removeCatcheFillCombo(globalQuery);
      // const profitCenterOpts = [
      //   { value: '', label: 'Select', centerShortName: '' },
      //   { value: '0', label: 'Add New Record+', centerShortName: '' },
      //   ...getProfitCenterList.map((profitCenter) => ({ ...profitCenter, value: profitCenter.field_id, label: `[${profitCenter.profit_center_short_name}] ${profitCenter.field_name}`, centerShortName: profitCenter.profit_center_short_name, })),
      // ];
      // setProfitCenterOptions(profitCenterOpts)

    } catch (error) {
      console.log('error: ', error)
      navigate('/Error')
    }
  }

  const FnGenerateMaterialId = async (productTpVal, ptShortName) => {
    const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateCode("sm_product_fg", "product_fg_id", 'product_type_id', productTpVal, ptShortName, "5", "");
    setProductFgId(autoNoApiCall);
    return autoNoApiCall;
  }

  const FnGenerateTechSpecName = async (material_name, material_code) => {
    // debugger
    if (keyForViewUpdate === '') {
      let productTypeShortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName');
      let productTpText = productTypeShortName !== null ? productTypeShortName : "";
      let productCat1Text = category1ComboRef.current?.label == 'General' || category1ComboRef.current?.label == 'NA' || category1ComboRef.current?.label == undefined ? '' : category1ComboRef.current?.label;
      let productCat2Text = category2ComboRef.current?.label == 'General' || category2ComboRef.current?.label == 'NA' || category2ComboRef.current?.label == undefined ? '' : category2ComboRef.current?.label;
      // let productCat1Text = document.getElementById("cmb_product_category1_id").options[document.getElementById("cmb_product_category1_id").selectedIndex].text;
      // let productCat2Text = document.getElementById("cmb_product_category2_id").options[document.getElementById("cmb_product_category2_id").selectedIndex].text;
      // let productCat3Text = document.getElementById("cmb_product_category3_id").options[document.getElementById("cmb_product_category3_id").selectedIndex].text;
      // let productCat4Text = document.getElementById("cmb_product_category4_id").options[document.getElementById("cmb_product_category4_id").selectedIndex].text;
      // let productCat5Text = document.getElementById("cmb_product_category5_id").options[document.getElementById("cmb_product_category5_id").selectedIndex].text;
      // let productMaterialGradeText = document.getElementById("cmb_product_material_grade_id").options[document.getElementById("cmb_product_material_grade_id").selectedIndex].text;

      // let productCat1Text = document.getElementById("cmb_product_category1_id").options[document.getElementById("cmb_product_category1_id").selectedIndex].text;
      // productCat1Text = (productCat1Text == 'General' || productCat1Text == 'NA') ? '' : productCat1Text;

      // let productCat2Text = document.getElementById("cmb_product_category2_id").options[document.getElementById("cmb_product_category2_id").selectedIndex].text;
      //  productCat2Text = (productCat2Text == 'General' || productCat2Text == 'NA') ? '' : productCat2Text;

      // let productCat3Text = document.getElementById("cmb_product_category3_id").options[document.getElementById("cmb_product_category3_id").selectedIndex].text;
      // productCat3Text = (productCat3Text == 'General' || productCat3Text == 'NA') ? '' : productCat3Text;

      // let productCat4Text = document.getElementById("cmb_product_category4_id").options[document.getElementById("cmb_product_category4_id").selectedIndex].text;
      // productCat4Text = (productCat4Text == 'General' || productCat4Text == 'NA') ? '' : productCat4Text;

      // let productCat5Text = document.getElementById("cmb_product_category5_id").options[document.getElementById("cmb_product_category5_id").selectedIndex].text;
      // productCat5Text = (productCat5Text == 'General' || productCat5Text == 'NA') ? '' : productCat5Text;


      // setProductCat1Text((prevState) => {
      //   return (prevState === 'General' || prevState === 'NA') ? '' : prevState;
      // });

      // setProductCat2Text((prevState) => {
      //   return (prevState === 'General' || prevState === 'NA') ? '' : prevState;
      // });
      // setProductCat3Text((prevState) => {
      //   return (prevState === 'General' || prevState === 'NA') ? '' : prevState;
      // });

      // setProductCat4Text((prevState) => {
      //   return (prevState === 'General' || prevState === 'NA') ? '' : prevState;
      // });

      // setProductCat2Text((prevState) => {
      //   return (prevState === 'General' || prevState === 'NA') ? '' : prevState;
      // });


      let productMaterialGradeText = document.getElementById("cmb_product_material_grade_id").options[document.getElementById("cmb_product_material_grade_id").selectedIndex].text;
      productMaterialGradeText = (productMaterialGradeText == 'General' || productMaterialGradeText == 'NA') ? '' : productMaterialGradeText;

      let productMakeText = "";
      let material_code = document.getElementById("txt_fg_code").value;
      let material_name = document.getElementById("txt_fg_name").value;
      const autoTextApiCall = await generateMaterialIdAPiCall.current.GenerateTechnicalSpecName(productTpText, productCat1Text, productCat2Text, productCat3Text,
        productCat4Text, productCat5Text, productMakeText, productMaterialGradeText, material_code, material_name);
      setPTechnivalName(autoTextApiCall);
    }

  }

  //Fn for dependent on product Type
  const FnGetProductTypeDependentCombo = async (productTypeId) => {
    // Product Material Type
    var productMaterialList = await comboBoxRef.current.fillMasterData("smv_product_material_type", "product_type_id", productTypeId)
    setPMaterialTypeOptions(productMaterialList)

    // Product Material Shape
    var productMaterialShapeList = await comboBoxRef.current.fillMasterData("smv_product_material_shape", "product_type_id", productTypeId)
    setPMaterialShapeOptions(productMaterialShapeList)
  }

  const FnCheckUpdateResponce = async () => {
    try {
      // debugger
      //Update Response for Entry data
      const apiCallForEntryData = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnShowAllProductFgDetailsAndMasterRecords/${product_fg_id}/${COMPANY_ID}`)
      const updateRes = await apiCallForEntryData.json();
      debugger
      let generalEntryData = updateRes.ProductFgRecords;
      let technicalEntryData = updateRes.TechnicalRecords;
      let commercialEntryData = updateRes.CommercialRecords;
      let supplierMappingData = updateRes.SupplierRecords;
      let customerMappingData = updateRes.CustomerRecords;
      let processMappingData = updateRes.ProcessRecords;
      let qaMappingData = updateRes.QaMappingRecords;
      let propertiesData = updateRes.AdditionalParametersData
      setSupplierMappingData(supplierMappingData)
      setCustomerMappingData(customerMappingData)
      setProcessMappingData(processMappingData)
      setQAMappingData(qaMappingData)
      // if (additionalParameterData.length !== 0) {
      //   setAdditionalParamtersDetailsData(additionalParameterData)
      // } else {
      //   let parameterClearedData = [];
      //   parameterClearedData.push(additionalParametrsBlankObject);
      //   setAdditionalParamtersDetailsData(parameterClearedData);
      //   setRowCount(1);
      // }
      //Filling General Entry
      setproductTypeId(generalEntryData.product_type_id)
      // const preLoadPropertiesData = await comboOnChange('productType')
      setProductcategory1Id(generalEntryData.product_category1_id)
      setProductCat1Text(generalEntryData.product_type_name)
      // debugger
      const preLoadPropertiesData = await comboOnChangeforSearch('productType');
      await comboOnChangeforSearch('productCategory1', generalEntryData.product_category1_id)
      setProduct_id(generalEntryData.product_id)
      setProductFgId(generalEntryData.product_fg_id)
      setFGCode(generalEntryData.product_fg_code)
      setFGName(generalEntryData.product_fg_name)
      setFGShortName(generalEntryData.product_fg_short_name)
      setFGPrintName(generalEntryData.product_fg_print_name)
      setFGTechSpect(generalEntryData.product_fg_tech_spect)
      setFGOemPartCode(generalEntryData.product_fg_oem_part_code)
      setFGOurPartCode(generalEntryData.product_fg_our_part_code)
      setFGItemSrNo(generalEntryData.product_fg_item_sr_no)
      setFGDrawingNo(generalEntryData.product_fg_drawing_no)
      setFGModelNo(generalEntryData.product_fg_model_no)
      setFGHsnSacCodeId(generalEntryData.product_fg_hsn_sac_code_id)
      hsnsacComboRef.current.value = generalEntryData.product_fg_hsn_sac_code_id
      // hsnsacComboRef.current.label = generalEntryData.product_fg_hsn_sac_code

      setFGPurchaseUnitId(generalEntryData.product_fg_purchase_unit_id)
      setFGSalesUnitId(generalEntryData.product_fg_sales_unit_id)
      setFGStockUnitId(generalEntryData.product_fg_stock_unit_id)
      setFGPackingUnitId(generalEntryData.product_fg_packing_id)
      setFGBarCode(generalEntryData.product_fg_bar_code)
      setFGQrCodePath(generalEntryData.product_fg_qr_code)
      setConsumptionMode(generalEntryData.product_consumption_mode)
      setgodownid(generalEntryData.godown_id)
      await comboOnChange('godown')
      setgodownsectionid(generalEntryData.godown_section_id)
      await comboOnChange('godownsection')
      setgodownsectionbeansid(generalEntryData.godown_section_beans_id)
      setOriginType(generalEntryData.product_origin_type)
      setOriginCountry(generalEntryData.product_origin_country)
      setRemarkForProductEntry(generalEntryData.remark)
      setQaRemark(generalEntryData.qa_remark);
      setIsActiveGen(generalEntryData.is_active)
      setBOMApplicable(generalEntryData.bom_applicable)

      //Filling Technical Entry

      setProductFGTechnicalId(technicalEntryData.product_fg_technical_id)
      setPTechnivalName(technicalEntryData.product_fg_technical_name)
      // setProductcategory1Id(technicalEntryData.product_category1_id)

      // setTimeout(() => {
      //   setProductcategory1Id(generalEntryData.product_category1_id)
      //   // setProductCat1Text(generalEntryData.product_type_name)
      // }, 500);

      // if (technicalEntryData.product_category1_id !== 0) {
      //   await comboOnChangeforSearch('productCategory1')
      // }

      // setPCategory2Id(technicalEntryData.product_category2_id)
      // category2ComboRef.current.value = technicalEntryData.product_category2_id
      // category2ComboRef.current.lable = technicalEntryData.product_category2_name


      // if (technicalEntryData.product_category2_id !== 0) {
      //   await comboOnChangeforSearch('productCategory2', technicalEntryData.product_category2_id)
      // }

      // setPCategory3Id(technicalEntryData.product_category3_id)
      // if (technicalEntryData.product_category3_id !== 0) {
      //   await comboOnChangeforSearch('productCategory3')
      // }
      // setPCategory4Id(technicalEntryData.product_category4_id)
      // if (technicalEntryData.product_category4_id !== 0) {
      //   await comboOnChangeforSearch('productCategory4')
      // }
      // setPCategory5Id(technicalEntryData.product_category5_id)


      setPMaterialTypeId(technicalEntryData.product_material_type_id)
      if (technicalEntryData.product_material_type_id !== 0) {
        await comboOnChangeforSearch('productMaterialType')
      }
      setPMaterialGradeId(technicalEntryData.product_material_grade_id)
      setPMaterialShapeId(technicalEntryData.product_material_shape_id)
      setPMaterialColour(technicalEntryData.product_material_colour)
      setPAlternateId(technicalEntryData.product_alternate_fg_id)
      setAssemblyScrapPercent(technicalEntryData.assembly_scrap_percent)
      setGLM(technicalEntryData.product_glm);
      setGSM(technicalEntryData.product_gsm);

      //Filling Commercial Entry
      setProductFGCommercialId(commercialEntryData.product_fg_commercial_id)
      setPFGGrossWeight(commercialEntryData.product_fg_gross_weight)
      setPFGNetWeight(commercialEntryData.product_fg_net_weight)
      setPFGStdWeight(commercialEntryData.product_fg_std_weight)
      setPFGVolume(commercialEntryData.product_fg_volume)
      setPFGMrp(commercialEntryData.product_fg_mrp)
      setPFGLandedPrice(commercialEntryData.product_fg_landed_price)
      setPFGAvgPrice(commercialEntryData.product_fg_avg_price)
      setPFGStdProfitPercent(commercialEntryData.product_fg_std_profit_percent)
      setPFGDiscountPercent(commercialEntryData.product_fg_std_discount_percent)
      setPFGmoq(commercialEntryData.product_fg_moq)
      setPFGmov(commercialEntryData.product_fg_mov)
      setPFGeoq(commercialEntryData.product_fg_eoq)
      setPFGmpq(commercialEntryData.product_fg_mpq)
      setPFGMinStockLevel(commercialEntryData.product_fg_min_stock_level)
      setPFGMaxStockLevel(commercialEntryData.product_fg_max_stock_level)
      setPFGReorderStockLevel(commercialEntryData.product_fg_reorder_stock_level)
      setPFGDepricationPercent(commercialEntryData.product_fg_depriciation_percent)
      setSalesTolerancePercent(commercialEntryData.sales_tolerance_percent)
      setIsPriceType(commercialEntryData.product_fg_price_type)
      setIsABCanalysis(commercialEntryData.product_fg_abc_analysis)
      setIsQARequired(commercialEntryData.qa_required)
      setIsTestCertified(commercialEntryData.test_certificate_required)
      // setProfitCenter(commercialEntryData.profit_center_id)
      // profitCenterComboRef.current.value = commercialEntryData.profit_center_id
      // setCostCenter(parseInt(commercialEntryData.cost_center_id))

      // costCenterComboRef.current.value = commercialEntryData.cost_center_id
      // costCenterComboRef.current.label = commercialEntryData.cost_center_short_name
      // setRoutingCode(commercialEntryData.routing_code)
      // if (commercialEntryData.cost_center_id !== null && commercialEntryData.cost_center_id !== '') {
      //   setTotalSelectedCostCenterCheckboxes(commercialEntryData.cost_center_id.split(':').length);
      // }

      //Updating Properties CheckBoxes

      if (preLoadPropertiesData !== undefined && preLoadPropertiesData.length !== 0) {
        const updatedData = [...preLoadPropertiesData];
        const uniqueDynamicControlIDs = new Set(); // Create a Set to store unique dynamic control IDs
        $("input:checkbox[name=selectProperty]").each(function () {
          const checkboxValue = parseInt($(this).val());
          const orderNoIndex = propertiesData.findIndex((item) => item.product_type_dynamic_controls_id === checkboxValue);
          if (orderNoIndex !== -1 && !uniqueDynamicControlIDs.has(checkboxValue)) {
            $(this).prop('checked', true);
            const propertyData = propertiesData.find((item) => item.product_type_dynamic_controls_id === checkboxValue);
            if (propertyData) {
              const propertyObjIndex = updatedData.findIndex((item) => item.product_type_dynamic_controls_id === checkboxValue);
              // Update updatedData only if the dynamic control ID is unique
              uniqueDynamicControlIDs.add(checkboxValue); // Add dynamic control ID to the Set
              updatedData[propertyObjIndex] = {
                ...updatedData[propertyObjIndex],
                product_parameter_id: propertiesData[orderNoIndex].product_parameter_id,
                product_parameter_value: propertiesData[orderNoIndex].product_parameter_value,
                product_parameter_prefix: propertiesData[orderNoIndex].product_parameter_prefix,
                product_parameter_sufix: propertiesData[orderNoIndex].product_parameter_sufix,
                product_parameter_calculation_type: propertiesData[orderNoIndex].product_parameter_calculation_type,
                product_parameter_from_value: propertiesData[orderNoIndex].product_parameter_from_value,
                product_parameter_to_value: propertiesData[orderNoIndex].product_parameter_to_value,
                product_parameter_formula: propertiesData[orderNoIndex].product_parameter_formula,
                remark: propertiesData[orderNoIndex].remark,
              };
            }
          }
        });
        // Filter out undefined elements and duplicates
        const filteredUpdatedData = updatedData.filter(obj => obj !== undefined);
        console.log('filteredUpdatedData: ', filteredUpdatedData);
        setProductTypePropertiesData(filteredUpdatedData);
      }
      switch (keyForViewUpdate) {
        case 'update':
          $('#txt_fg_name').attr('readOnly', true)
          $('#txt_product_rm_technical_name').attr('readOnly', true)
          $('#cmb_product_type_id').attr('disabled', true)
          $('#txt_rm_short_name').attr('readOnly', true)
          // $('#txt_rm_short_name').attr('readOnly', true)
          // $('#txt_rm_short_name').attr('readOnly', true)
          $('#cmb_product_category1_id').attr('disabled', true)
          $('#cmb_product_category2_id').attr('readOnly', true)
          $('#cmb_product_category3_id').attr('readOnly', true)
          $('#cmb_product_category4_id').attr('readOnly', true)
          $('#cmb_product_category5_id').attr('readOnly', true)
          $('#btn_save').text("Update");
          break;

        case 'view':
          $("input[type=radio]").attr('disabled', true);
          $('input:checkbox[name=checkSupplier]').attr('disabled', true);
          $('input:checkbox[name=checkCustomer]').attr('disabled', true);
          $('input:checkbox[name=checkQaMapp]').attr('disabled', true);
          $('input:checkbox[name=checkProcess]').attr('disabled', true);
          await validationRef.current.readOnly('productFgFormId');
          // await validationRef.current.readOnly('technicalEntryFormId');
          await validationRef.current.readOnly('commercialEntryFormId');
          $('#btn_save').hide();
          break;

        case 'delete':
          $("input[type=radio]").attr('disabled', true);
          $('input:checkbox[name=checkSupplier]').attr('disabled', true);
          $('input:checkbox[name=checkCustomer]').attr('disabled', true);
          $('input:checkbox[name=checkQaMapp]').attr('disabled', true);
          $('input:checkbox[name=checkProcess]').attr('disabled', true);
          await validationRef.current.readOnly('productFgFormId');
          // await validationRef.current.readOnly('technicalEntryFormId');
          await validationRef.current.readOnly('commercialEntryFormId');
          $('#btn_save').hide();
          $('#upload_btn_id').hide();
          break;
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const FnGetMappingsData = async (updatAllRecordsKey) => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnShowAllRecords/${COMPANY_ID}/${updatAllRecordsKey}`)
      const response = await apiCall.json();
      let supplierRecords = response.SupplierAllRecords;
      let customerRecords = response.CustomerRecords;
      let processRecords = response.ProcessRecords;
      let qaParametersRecords = response.QaParametersAllRecords;
      if (supplierRecords !== undefined) {

        //Supplier Records
        if (supplierRecords.length > 0) {
          var suppColumn = [];
          var suppColumnHeads = Object.keys(supplierRecords[0]);
          console.log("column heads: ", suppColumnHeads)
          for (let colKey = 0; colKey < suppColumnHeads.length; colKey++) {
            if (colKey == 0) {
              suppColumn.push({
                Headers: "Action",
                accessor: "Action",
                Cell: row => (
                  <div style={{ display: "flex" }}>
                    <input type='checkbox' name="checkSupplier" id={'checkSupplierId_' + row.original.supplier_id} className="checkSupplier"
                      value={row.original.supplier_id} onClick={() => toggleQuotationTermsChkBoxes('PartiallySupplierSelection')}>
                    </input>
                  </div>
                ),
              });
            }
            if (!suppColumnHeads[colKey].includes('_id') && !suppColumnHeads[colKey].includes('field_name') && !suppColumnHeads[colKey].includes('is_') && !suppColumnHeads[colKey].includes('_on')
              && !suppColumnHeads[colKey].includes('_by') && !suppColumnHeads[colKey].includes('company_') && !suppColumnHeads[colKey].includes('supplier_type')
              && !suppColumnHeads[colKey].includes('supplier_short_name') && !suppColumnHeads[colKey].includes('supplier_sector') && !suppColumnHeads[colKey].includes('nature_of_business')
              && !suppColumnHeads[colKey].includes('supplier_gl_codes') && !suppColumnHeads[colKey].includes('supplier_history') && !suppColumnHeads[colKey].includes('supp_branch_short_name')
              && !suppColumnHeads[colKey].includes('supp_branch_address1') && !suppColumnHeads[colKey].includes('supp_branch_address2') && !suppColumnHeads[colKey].includes('supp_branch_pincode')
              && !suppColumnHeads[colKey].includes('district_name') && !suppColumnHeads[colKey].includes('country_name') && !suppColumnHeads[colKey].includes('supp_branch_region')
              && !suppColumnHeads[colKey].includes('supp_branch_linkedin_profile') && !suppColumnHeads[colKey].includes('supp_branch_twitter_profile') && !suppColumnHeads[colKey].includes('supp_branch_facebook_profile')
              && !suppColumnHeads[colKey].includes('supp_branch_gst_division') && !suppColumnHeads[colKey].includes('supp_branch_gst_range') && !suppColumnHeads[colKey].includes('supp_branch_pan_no')
              && !suppColumnHeads[colKey].includes('supp_branch_udyog_adhar_no') && !suppColumnHeads[colKey].includes('supp_branch_vat_no') && !suppColumnHeads[colKey].includes('supp_branch_service_tax_no')
              && !suppColumnHeads[colKey].includes('supp_branch_excise_no') && !suppColumnHeads[colKey].includes('supp_branch_cst_no') && !suppColumnHeads[colKey].includes('supp_branch_bst_no')
              && !suppColumnHeads[colKey].includes('supp_branch_rating') && !suppColumnHeads[colKey].includes('supp_branch_gl_codes') && !suppColumnHeads[colKey].includes('sez_name')
              && !suppColumnHeads[colKey].includes('supplier_username') && !suppColumnHeads[colKey].includes('supplier_password') && !suppColumnHeads[colKey].includes('remark') && !suppColumnHeads[colKey].includes('active')
              && !suppColumnHeads[colKey].includes('deleted')) {
              suppColumn.push({ Headers: suppColumnHeads[colKey], accessor: suppColumnHeads[colKey] });
            }
          }
          setColumns(suppColumn)
          setSuppData(supplierRecords)
        }
      }
      if (customerRecords !== undefined) {

        //Customer Records
        if (customerRecords.length > 0) {
          var custColumn = [];
          let custColumnHeads = Object.keys(customerRecords[0]);
          for (let colKey = 0; colKey < custColumnHeads.length; colKey++) {
            if (colKey == 0) {
              custColumn.push({
                Headers: "Action",
                accessor: "Action",
                Cell: row => (
                  <div style={{ display: "flex" }}>
                    <input type='checkbox' name="checkCustomer" id={'checkCustomerId_' + row.original.customer_id} className="checkCustomer"
                      value={row.original.customer_id} onClick={() => toggleQuotationTermsChkBoxes('PartiallyCustomerSelection')}>
                    </input>
                  </div>
                ),
              });
            }
            if (!excludedcustFields.some(field => custColumnHeads[colKey].includes(field))) {
              custColumn.push({ Headers: custColumnHeads[colKey], accessor: custColumnHeads[colKey] });
            }
          }
          setCustomerColumns(custColumn)
          setCustomerData(customerRecords)
        }
      }
      if (processRecords !== undefined) {


        //Process Records
        if (processRecords.length > 0) {
          var proccColumn = [];
          let proccColumnHeads = Object.keys(processRecords[0]);
          for (let colKey = 0; colKey < proccColumnHeads.length; colKey++) {
            if (colKey == 0) {
              proccColumn.push({
                Headers: "Action",
                accessor: "Action",
                Cell: row => (
                  <div style={{ display: "flex" }}>
                    <input type='checkbox' name="checkProcess" id={'checkProcessId_' + row.original.product_process_id} className="checkProcess"
                      value={row.original.product_process_id} onClick={() => toggleQuotationTermsChkBoxes('PartiallyProcessSelection')}>
                    </input>
                  </div>
                ),
              });
            }
            if (!proccColumnHeads[colKey].includes('_id') && !proccColumnHeads[colKey].includes('field_name') && !proccColumnHeads[colKey].includes('is_') && !proccColumnHeads[colKey].includes('_on') && !proccColumnHeads[colKey].includes('_by') && !proccColumnHeads[colKey].includes('company_') && !proccColumnHeads[colKey].includes('deleted') && !proccColumnHeads[colKey].includes('active') && !proccColumnHeads[colKey].includes('remark') && !proccColumnHeads[colKey].includes('product_process_std_production_hrs') && !proccColumnHeads[colKey].includes('product_process_std_scrap_percent')) {
              proccColumn.push({ Headers: proccColumnHeads[colKey], accessor: proccColumnHeads[colKey] });
            }
          }
          setProcessColumns(proccColumn)
          setProccessData(processRecords)
        }
      }
      if (qaParametersRecords !== undefined) {
        //QA Data
        if (qaParametersRecords.length > 0) {
          var qaMappColumn = [];
          let qaMappColumnHeads = Object.keys(qaParametersRecords[0]);

          for (let colKey = 0; colKey < qaMappColumnHeads.length; colKey++) {
            if (colKey == 0) {
              qaMappColumn.push({
                Headers: "Action",
                accessor: "Action",
                Cell: row => (
                  <div style={{ display: "flex" }}>
                    <input type='checkbox' name="checkQaMapp" id={'checkQaMappId_' + row.original.product_qa_parameters_id} className="checkQaMapp"
                      value={row.original.product_qa_parameters_id} onClick={() => toggleQuotationTermsChkBoxes('PartiallyQAmappingSelection')}>
                    </input>
                  </div>
                ),
              });
            }
            if (!qaMappColumnHeads[colKey].includes('_id') && !qaMappColumnHeads[colKey].includes('field_name') && !qaMappColumnHeads[colKey].includes('is_') && !qaMappColumnHeads[colKey].includes('_on') && !qaMappColumnHeads[colKey].includes('_by') && !qaMappColumnHeads[colKey].includes('company_') && !qaMappColumnHeads[colKey].includes('deleted') && !qaMappColumnHeads[colKey].includes('active') && !qaMappColumnHeads[colKey].includes('remark')) {
              qaMappColumn.push({ Headers: qaMappColumnHeads[colKey], accessor: qaMappColumnHeads[colKey] });
            }
          }
          setQAColumns(qaMappColumn)
          setQaMappingData(qaParametersRecords)
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  //Supplier Records
  const handleSupplierCheckbox = (supp_id) => {
    $('input:checkbox[name="checkSupplier"][value="' + supp_id + '"]').attr('checked', false);

  }

  //Customer Records
  const handleCustomerCheckbox = (supp_id) => {
    $('input:checkbox[name="checkCustomer"][value="' + supp_id + '"]').attr('checked', false);
  }

  //Process Records
  const handleProcessCheckbox = (procc_id) => {
    $('input:checkbox[name="checkProcess"][value="' + procc_id + '"]').attr('checked', false);

  }

  //QA Records
  const handleqaMappingCheckbox = (qaMapp_id) => {
    $('input:checkbox[name="checkQaMapp"][value="' + qaMapp_id + '"]').attr('checked', false);
  }



  // blank objects for combosearch 
  const blankComboObject = () => {
    const Opts = [
      { value: '', label: 'Select', short_name: '' },
      { value: '0', label: 'Add New Record+', short_name: '' },
    ];
    return Opts;
  }


  const comboOnChangeforSearch = async (key, selectedValue) => {
    try {
      // debugger
      const Options = blankComboObject();
      switch (key) {

        case 'productType':
          let productTpVal = document.getElementById('cmb_product_type_id').value;
          setproductTypeId(productTpVal)
          setProductCategory1Options(Options)
          setProductcategory1Id('')
          setCategory2Options(Options)
          setGLM(0);
          setGSM(0);
          FnGenerateTechSpecName();
          if (productTpVal === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Type')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({
                "padding-top": "0px"
              });
            }, 200)
          } else {
            if (productTpVal !== "") {
              setFGCode("")
              setFGName("")
              $('#error_cmb_product_type_id').hide();
              $('#propertiesSpinnerId').show();
              const shortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')
              localStorage.setItem('ptShortName', shortName)
              if (shortName === "YN" || shortName === "FF" || shortName === "GF") {
                $('#txt_fg_code').attr("disabled", true);
                $('#txt_fg_name').attr("disabled", true);
              } else if (shortName === "FG") {
                $('#txt_fg_code').attr("disabled", true);
              } else {
                $('#txt_fg_code').attr("disabled", false);
                $('#txt_fg_name').attr("disabled", false);
              }


              await FnGenerateMaterialId(productTpVal, shortName);
              await FnGetProductTypeDependentCombo(productTpVal);
              // resetGlobalQuery();
              // globalQuery.columns = ['field_id', 'field_name', 'product_category1_short_name'];
              // globalQuery.table = "smv_product_category1";
              // // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
              // globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
              // const productCategory1ApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
              // const productCat1Opts = [
              //   { value: '', label: 'Select', short_name: '' },
              //   { value: '0', label: 'Add New Record+', short_name: '' },
              //   ...productCategory1ApiCall.map(item => ({
              //     value: item.field_id,
              //     label: item.field_name,
              //     short_name: item.product_category1_short_name,
              //     // short_name: item.product_category1_short_name,
              //   }))
              // ];
              // setProductCategory1Options(productCat1Opts);
              //    setProductCategory1Options(productCategory1ApiCall)
              const returnProductPropertiesData = fnGetProductTypeControls(productTpVal);
              return returnProductPropertiesData;
            } else {
              setProductTypePropertiesData([])
            }
          }
          setTimeout(async () => {
            await FnGenerateTechSpecName();
          }, 200);
          break;

        // case 'productCategory1':
        //   var productCat1Val = selectedValue === undefined ? category1ComboRef.current.value : selectedValue
        //   setCategory2Options(Options)

        //   if (productCat1Val === '0') {
        //     setProductcategory1Id(productCat1Val)
        //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        //     setHeaderName('Product Category1')
        //     setShowAddRecModal(true)
        //     setTimeout(() => {
        //       $(".erp_top_Form").css({ "padding-top": "0px" });
        //     }, 200)
        //   } else {
        //     setProductcategory1Id(productCat1Val)
        //     $('#error_cmb_product_category1_id').hide();
        //     // Category 2 list
        //     resetGlobalQuery();
        //     globalQuery.columns = ["field_id", "field_name", "product_category2_short_name"];
        //     globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
        //     globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: productCat1Val });
        //     globalQuery.table = "smv_product_category2"
        //     var productCategory2ApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery)
        //     const productCat2Opts = [
        //       { value: '', label: 'Select', short_name: '' },
        //       { value: '0', label: 'Add New Record+', short_name: '' },
        //       ...productCategory2ApiCall.map(item => ({
        //         value: item.field_id,
        //         label: item.field_name,
        //         short_name: item.product_category2_short_name,
        //       }))
        //     ];
        //     console.log("masterList2: ", productCat2Opts)
        //     setCategory2Options(productCat2Opts)
        //     category2ComboRef.current = null;
        //     setPCategory2Id('')
        //     const shortNamefg = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')
        //     if (shortNamefg === "FG") {
        //       await FnGenerateMaterialCodefinishgood();
        //     }

        //   }
        //   setTimeout(async () => {
        //     await FnGenerateTechSpecName();
        //   }, 200);

        //   break;

        case 'hsnSacCode':
          var productHsnSacCode = selectedValue;
          if (productHsnSacCode === '0') {
            setFGHsnSacCodeId(productHsnSacCode)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('HSN-SAC')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          } else {
            setFGHsnSacCodeId(productHsnSacCode)
            // if (productCat1Val !== "") { $('#error_cmb_fg_hsn_sac_code_id').hide(); }
          }
          break;

        case 'productCategory2':
          var productCat2Val = selectedValue === undefined ? category2ComboRef.current.value : selectedValue
          setPCategory2Id(productCat2Val)
          if (productCat2Val === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category-2')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
          } else {
            if (productCat2Val !== "") {
              $(`#error_cmb_product_category2_id`).hide();

              // Product altr material list
              resetGlobalQuery();
              globalQuery.columns.push("product_fg_id");
              globalQuery.columns.push("product_fg_name");
              globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
              globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
              globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: document.getElementById('cmb_product_category1_id').value });
              globalQuery.conditions.push({ field: "product_fg_category2_id", operator: "=", value: productCat2Val });
              globalQuery.table = "smv_product_fg_details";

              var pAltrRMIds = await comboBoxRef.current.fillFiltersCombo(globalQuery)
              setPAlternateMaterialOptions(pAltrRMIds)
            }
          }

          const shortNamefg = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')
          if (shortNamefg === "FG") {
            await FnGenerateMaterialCodefinishgood();
          }

          setTimeout(async () => {
            await FnGenerateTechSpecName();
          }, 200);
          break;

        case 'productCategory3':
          var productCat3Val = selectedValue
          setPCategory3Id(productCat3Val)
          if (productCat3Val === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category-3')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
          } else {
            if (productCat3Val !== "") {
              // Product altr material list
              resetGlobalQuery();
              globalQuery.columns.push("product_fg_id");
              globalQuery.columns.push("product_fg_name");
              globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
              globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
              globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: document.getElementById('cmb_product_category1_id').value });
              globalQuery.conditions.push({ field: "product_fg_category2_id", operator: "=", value: $('#cmb_product_category2_id').val() });
              globalQuery.conditions.push({ field: "product_fg_category3_id", operator: "=", value: productCat3Val });
              globalQuery.table = "smv_product_fg_details";

              var pAltrRMIds = await comboBoxRef.current.fillFiltersCombo(globalQuery)
              setPAlternateMaterialOptions(pAltrRMIds)
            }
          }
          // setTimeout(async () => {
          //   await FnGenerateTechSpecName();
          // }, 200);

          break;

        case 'productCategory4':
          var productCat4Val = selectedValue
          setPCategory4Id(productCat4Val)
          if (productCat4Val === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category-4')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
          } else {
            if (productCat4Val !== "") {
              // // Product altr material list
              resetGlobalQuery();
              globalQuery.columns.push("product_fg_id");
              globalQuery.columns.push("product_fg_name");
              globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
              globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
              globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: document.getElementById('cmb_product_category1_id').value });
              globalQuery.conditions.push({ field: "product_fg_category2_id", operator: "=", value: $('#cmb_product_category2_id').val() });
              globalQuery.conditions.push({ field: "product_fg_category3_id", operator: "=", value: $('#cmb_product_category3_id').val() });
              globalQuery.conditions.push({ field: "product_fg_category4_id", operator: "=", value: productCat4Val });
              globalQuery.table = "smv_product_fg_details";

              var pAltrRMIds = await comboBoxRef.current.fillFiltersCombo(globalQuery)
              setPAlternateMaterialOptions(pAltrRMIds)
            }
          }
          setTimeout(async () => {
            await FnGenerateTechSpecName();
          }, 200);
          break;

        case 'productCategory5':
          var productCat5Val = selectedValue
          if (productCat5Val === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category-5')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
            await FnGenerateTechSpecName();
          } else {
            setPCategory5Id(productCat5Val)
          }
          // setTimeout(async () => {
          //   await FnGenerateTechSpecName();
          // }, 200);
          break;


        // case 'profit_center':
        //   const profitcenter = selectedValue;
        //   setProfitCenter(profitcenter)
        //   $('#error_cmb_profit_center').hide();
        //   if (profitcenter === '0') {
        //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        //     setHeaderName('Profit Center')
        //     setShowAddRecModal(true)
        //     setTimeout(() => {
        //       $(".erp_top_Form").eq(0).css("padding-top", "0px");
        //     }, 100)
        //   }
        //   FnCreateRoutingText()
        //   break;
        // case 'cost_center':
        //   const costcenter = selectedValue
        //   setCostCenter(costcenter)
        //   $('#error_cmb_cost_center').hide();
        //   $('#error_cmb_profit_center').hide();
        //   if (costcenter === '0') {
        //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        //     setHeaderName('Cost Center')
        //     setShowAddRecModal(true)
        //     setTimeout(() => {
        //       $(".erp_top_Form").eq(0).css("padding-top", "0px");
        //     }, 100)
        //   }
        //   FnCreateRoutingText()
        //   break;

      }

    } catch (error) {
      console.log(error);
      navigate('/Error')
    }
  }

  const comboOnChange = async (key) => {
    debugger
    switch (key) {
      // case 'productType':
      //   let productTpVal = document.getElementById('cmb_product_type_id').value;
      //   setproductTypeId(productTpVal)
      //   FnGenerateTechSpecName();
      //   if (productTpVal === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Product Type')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").css({
      //         "padding-top": "0px"
      //       });
      //     }, 200)
      //   } else {
      //     if (productTpVal !== "") {
      //       setFGCode("")
      //       setFGName("")
      //       $('#error_cmb_product_type_id').hide();
      //       $('#propertiesSpinnerId').show();
      //       const shortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')
      //       localStorage.setItem('ptShortName', shortName)
      //       if (shortName === "YN" || shortName === "FF" || shortName === "GF") {
      //         $('#txt_fg_code').attr("disabled", true);
      //       } else {
      //         $('#txt_fg_code').attr("disabled", false);
      //       }
      //       await FnGenerateMaterialId(productTpVal, shortName);
      //       await FnGetProductTypeDependentCombo(productTpVal);
      //       resetGlobalQuery();
      //       globalQuery.columns = ['field_id', 'field_name'];
      //       globalQuery.table = "smv_product_category1";
      //       globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      //       globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
      //       const productCategory1ApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
      //       const updatedProductCategory1Options = updateKeysForSearchBox(productCategory1ApiCall);
      //       setProductCategory1Options(updatedProductCategory1Options);
      //       //    setProductCategory1Options(productCategory1ApiCall)
      //       const returnProductPropertiesData = fnGetProductTypeControls(productTpVal);
      //       return returnProductPropertiesData;
      //     } else {
      //       setProductTypePropertiesData([])
      //     }
      //   }
      //   setTimeout(async () => {
      //     await FnGenerateTechSpecName();
      //   }, 200);
      //   break;




      // case 'productCategory1':
      //   var productCat1Val = document.getElementById('cmb_product_category1_id').value;
      //   if (productCat1Val === '0') {
      //     setProductcategory1Id(productCat1Val)
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Product Category1')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").css({ "padding-top": "0px" });
      //     }, 200)
      //   } else {
      //     setProductcategory1Id(productCat1Val)
      //   }
      //   setTimeout(async () => {
      //     await FnGenerateTechSpecName();
      //   }, 200);
      //   break;

      // case 'hsnSacCode':
      //   var productHsnSacCode = document.getElementById('cmb_fg_hsn_sac_code_id').value;
      //   if (productHsnSacCode === '0') {
      //     setFGHsnSacCodeId(productHsnSacCode)
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('HSN-SAC')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").css({ "padding-top": "0px" });
      //     }, 200)
      //   } else {
      //     setFGHsnSacCodeId(productHsnSacCode)
      //     // if (productCat1Val !== "") { $('#error_cmb_fg_hsn_sac_code_id').hide(); }
      //   }
      //   break;

      case 'puchaseUnit':
        var productPurchaseUnitId = document.getElementById('cmb_fg_purchase_unit_id').value;
        setFGPurchaseUnitId(productPurchaseUnitId)
        if (productPurchaseUnitId !== "") { $('#error_cmb_fg_purchase_unit_id').hide(); }

        if (productPurchaseUnitId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Puchase Unit')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
          }, 200)
        }
        break;

      case 'salesUnit':
        var productSalesUnitId = document.getElementById('cmb_fg_sales_unit_id').value;
        setFGSalesUnitId(productSalesUnitId)
        if (productSalesUnitId !== "") { $('#error_cmb_fg_sales_unit_id').hide(); }

        if (productSalesUnitId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Sales Unit')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
          }, 200)
        }
        break;

      case 'stockUnit':
        var productStockUnitId = document.getElementById('cmb_fg_stock_unit_id').value;
        setFGStockUnitId(productStockUnitId)
        if (productStockUnitId !== "") { $('#error_cmb_fg_stock_unit_id').hide(); }

        if (productStockUnitId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Stock Unit')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
          }, 200)
        }
        break;

      case 'packingId':
        var productPackingId = document.getElementById('cmb_fg_packing_id').value;
        setFGPackingUnitId(productPackingId)
        if (productPackingId !== "") { $('#error_cmb_3fg_packing_id').hide(); }
        if (productPackingId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Packing')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css("padding-top", "0px");
          }, 200)
        }
        break;

      case 'consumptionMode':
        var productConsumptionModeId = document.getElementById('cmb_consumption_mode').value;
        setConsumptionMode(productConsumptionModeId)
        if (productConsumptionModeId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Consumption Mode')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css("padding-top", "0px");
          }, 200)
        }
        if (productConsumptionModeId !== "") { $('#error_cmb_consumption_mode').hide(); }
        break;

      case 'materialOrigin':
        var productOriginTypeId = document.getElementById('cmb_origin_type').value;
        setOriginType(productOriginTypeId)
        if (productOriginTypeId !== "") { $('#error_cmb_origin_type').hide(); }
        break;

      case 'originCountry':
        var countryId = document.getElementById('cmb_origin_country').value;
        setOriginCountry(countryId)
        if (countryId !== "") { $('#error_cmb_origin_country').hide(); }
        break;
      case 'godown':
        var godownval = document.getElementById('cmb_godown_id').value;
        setgodownid(godownval)

        if (godownval === '0') {
          const newTab = window.open('/Masters/Godown', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        const godownidSectionApiCall = await comboBoxRef.current.fillMasterData("cmv_godown_section", "godown_id", godownval)
        setgodownsectionid('');
        setgodownidSectionOptions(godownidSectionApiCall)
        setgodownsectionbeansidOptions([])
        setgodownsectionbeansid('')
        //changes by tushar
        if (keyForViewUpdate === "") {
          if (godownval === "1") {
            setgodownsectionid(1);
          }
        }
        break;


      case 'godownsection':
        var godownsectionval = document.getElementById('cmb_godown_section_id').value;
        setgodownsectionid(godownsectionval)

        if (godownsectionval === '0') {
          const newTab = window.open('/Masters/Godown', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        const godownidSectionbeansApiCall = await comboBoxRef.current.fillMasterData("cmv_godown_section_beans", "godown_section_id", godownsectionval)
        setgodownsectionbeansidOptions(godownidSectionbeansApiCall)
        if (keyForViewUpdate === "") {
          if (godownsectionval !== 1) {
            setgodownsectionbeansid(1)
          }
        }

        break;

      case 'godownsectionbeans':
        var godownsectionbeansval = document.getElementById('cmb_godown_section_beans_id').value;
        setgodownsectionbeansid(godownsectionbeansval)

        if (godownsectionbeansval === '0') {
          const newTab = window.open('/Masters/Godown', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        break;
      // case 'productCategory2':
      //   var productCat2Val = document.getElementById('cmb_product_category2_id').value;
      //   setPCategory2Id(productCat2Val)
      //   if (productCat2Val === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Product Category-2')
      //     setShowAddRecModal(true)
      //     setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
      //   } else {
      //     if (productCat2Val !== "") {
      //       // Product altr material list
      //       resetGlobalQuery();
      //       globalQuery.columns.push("product_fg_id");
      //       globalQuery.columns.push("product_fg_name");
      //       globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      //       globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
      //       globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: document.getElementById('cmb_product_category1_id').value });
      //       globalQuery.conditions.push({ field: "product_fg_category2_id", operator: "=", value: productCat2Val });
      //       globalQuery.table = "smv_product_fg_details";

      //       var pAltrRMIds = await comboBoxRef.current.fillFiltersCombo(globalQuery)
      //       setPAlternateMaterialOptions(pAltrRMIds)
      //     }
      //   }
      //   setTimeout(async () => {
      //     await FnGenerateTechSpecName();
      //   }, 200);
      //   break;

      // case 'productCategory3':
      //   var productCat3Val = document.getElementById('cmb_product_category3_id').value;
      //   setPCategory3Id(productCat3Val)
      //   if (productCat3Val === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Product Category-3')
      //     setShowAddRecModal(true)
      //     setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
      //   } else {
      //     if (productCat3Val !== "") {
      //       // Product altr material list
      //       resetGlobalQuery();
      //       globalQuery.columns.push("product_fg_id");
      //       globalQuery.columns.push("product_fg_name");
      //       globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      //       globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
      //       globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: document.getElementById('cmb_product_category1_id').value });
      //       globalQuery.conditions.push({ field: "product_fg_category2_id", operator: "=", value: $('#cmb_product_category2_id').val() });
      //       globalQuery.conditions.push({ field: "product_fg_category3_id", operator: "=", value: productCat3Val });
      //       globalQuery.table = "smv_product_fg_details";

      //       var pAltrRMIds = await comboBoxRef.current.fillFiltersCombo(globalQuery)
      //       setPAlternateMaterialOptions(pAltrRMIds)
      //     }
      //   }
      //   setTimeout(async () => {
      //     await FnGenerateTechSpecName();
      //   }, 200);

      //   break;

      // case 'productCategory4':
      //   var productCat4Val = document.getElementById('cmb_product_category4_id').value;
      //   setPCategory4Id(productCat4Val)
      //   if (productCat4Val === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Product Category-4')
      //     setShowAddRecModal(true)
      //     setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
      //   } else {
      //     if (productCat4Val !== "") {
      //       // // Product altr material list
      //       resetGlobalQuery();
      //       globalQuery.columns.push("product_fg_id");
      //       globalQuery.columns.push("product_fg_name");
      //       globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      //       globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
      //       globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: document.getElementById('cmb_product_category1_id').value });
      //       globalQuery.conditions.push({ field: "product_fg_category2_id", operator: "=", value: $('#cmb_product_category2_id').val() });
      //       globalQuery.conditions.push({ field: "product_fg_category3_id", operator: "=", value: $('#cmb_product_category3_id').val() });
      //       globalQuery.conditions.push({ field: "product_fg_category4_id", operator: "=", value: productCat4Val });
      //       globalQuery.table = "smv_product_fg_details";

      //       var pAltrRMIds = await comboBoxRef.current.fillFiltersCombo(globalQuery)
      //       setPAlternateMaterialOptions(pAltrRMIds)
      //     }
      //   }
      //   setTimeout(async () => {
      //     await FnGenerateTechSpecName();
      //   }, 200);
      //   break;

      // case 'productCategory5':
      //   var productCat5Val = document.getElementById('cmb_product_category5_id').value;
      //   if (productCat5Val === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Product Category-5')
      //     setShowAddRecModal(true)
      //     setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
      //     await FnGenerateTechSpecName();
      //   } else {
      //     setPCategory5Id(productCat5Val)
      //   }
      //   setTimeout(async () => {
      //     await FnGenerateTechSpecName();
      //   }, 200);
      //   break;

      case 'productMaterialType':
        var productMaterialTypeVal = document.getElementById('cmb_product_material_type_id').value;
        setPMaterialTypeId(productMaterialTypeVal)
        setPMaterialGradeOptions([])
        if (productMaterialTypeVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Material Type')
          setShowAddRecModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        } else {
          if (productMaterialTypeVal !== "") {
            setPMaterialTypeId(productMaterialTypeVal)
            const productGradeApiCall = await comboBoxRef.current.fillMasterData("smv_product_material_grade", "product_material_type_id", productMaterialTypeVal)
            setPMaterialGradeOptions(productGradeApiCall)
          }
        }
        break;
      case 'productMaterialGrade':
        var productMaterialGradeVal = document.getElementById('cmb_product_material_grade_id').value;
        if (productMaterialGradeVal === '0') {
          setPMaterialGradeId(productMaterialGradeVal)
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Material Grade')
          setShowAddRecModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)

        } else {
          setPMaterialGradeId(productMaterialGradeVal)
        }
        await FnGenerateTechSpecName();
        break;

      case 'productMaterialShape':
        var productMaterialShape = document.getElementById('cmb_product_material_shape_id').value;
        setPMaterialShapeId(productMaterialShape)

        if (productMaterialShape === '0') {
          setPMaterialGradeId(productMaterialGradeVal)
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Material Shape')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css("padding-top", "0px");
          }, 100)
        }
        break;

      case 'productMaterialColor':
        var productMaterialColor = document.getElementById('cmb_product_material_colour').value;
        setPMaterialColour(productMaterialColor)
        break;
      case 'productAltrFG':
        var productAltrFGid = document.getElementById('cmb_product_alternate_FG_id').value;
        setPAlternateId(productAltrFGid)
        break;
      // case 'profit_center':
      //   const profitcenter = document.getElementById('cmb_profit_center').value;
      //   setProfitCenter(profitcenter)
      //   $('#error_cmb_profit_center').hide();
      //   if (profitcenter === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Profit Center')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").eq(0).css("padding-top", "0px");
      //     }, 100)
      //   }
      //   FnCreateRoutingText()
      //   break;
      // case 'cost_center':
      //   const costcenter = document.getElementById('cmb_cost_center').value;
      //   setCostCenter(costcenter)
      //   $('#error_cmb_cost_center').hide();
      //   $('#error_cmb_profit_center').hide();
      //   if (costcenter === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Cost Center')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").eq(0).css("padding-top", "0px");
      //     }, 100)
      //   }
      //   if (costcenter !== "" && costcenter !== "0") {
      //     if (cmb_profit_center !== "" && cmb_profit_center !== "0") {
      //       FnCreateRoutingText()
      //     } else {
      //       setCostCenter("")
      //       $('#error_cmb_profit_center').text("Please Select atleast one...!")
      //       $('#error_cmb_profit_center').show();
      //     }
      //   } else {
      //     FnCreateRoutingText()
      //   }
      //   break;

    }
  }

  // const FnCreateRoutingText = () => {
  //   let routingText = "";
  //   let selectedProfitCenter = profit_centerOptions.find(option => option.value === profit_center);
  //   let profitcenter = selectedProfitCenter ? selectedProfitCenter.centerShortName : "";
  //   //let costcenter = document.getElementById("cmb_cost_center").options[document.getElementById("cmb_cost_center").selectedIndex].getAttribute("centerShortName");
  //   if (profitcenter !== null) {
  //     routingText += `${profitcenter}`;
  //     // routingText += costcenter ? `-${costcenter}` : '';
  //   } else {
  //     routingText = "";
  //   }
  //   setRoutingCode(routingText);
  // };



  const FnCreateRoutingText = () => {
    // debugger
    let routingText = "";
    let selectedProfitCenter = profit_centerOptions.find(option => option.value === profitCenterComboRef.current.value);
    let profitcenterShortCode = selectedProfitCenter ? selectedProfitCenter.centerShortName : "";
    // let costcenter = document.getElementById("cmb_cost_center").options[document.getElementById("cmb_cost_center").selectedIndex].getAttribute("centerShortName");
    let selectedCostCenter = cost_centerOptions.find(option => option.value === costCenterComboRef.current.value);
    let costcenterShortCode = selectedCostCenter ? selectedCostCenter.centerShortName : "";
    // let costcenterheads = cost_centerHeadsOptions.find(option => option.value === cost_center_heads)?.centerShortName;

    routingText += `${profitcenterShortCode}`;
    routingText += costcenterShortCode ? profitcenterShortCode ? `-${costcenterShortCode}` : `${costcenterShortCode}` : '';
    // routingText += costcenterheads ? `-${costcenterheads}` : '';
    setRoutingCode(routingText);
  };











  // const FnCreateRoutingText = () => {
  //   debugger
  //   let routingText = "";

  //   //  let profitcenter = document.getElementById("cmb_profit_center").options[document.getElementById("cmb_profit_center").selectedIndex].getAttribute("centerShortName");
  //   let selectedProfitCenter = profit_centerOptions.find(option => option.value === profit_center);
  //   let profitcenter = selectedProfitCenter ? selectedProfitCenter.centerShortName : "";
  //   let costcenter = document.getElementById("cmb_cost_center").options[document.getElementById("cmb_cost_center").selectedIndex].getAttribute("centerShortName");

  //   if (profitcenter !== null) {
  //     routingText += `${profitcenter}`;
  //     routingText += costcenter !== null ? `-${costcenter}` : '';
  //   } else {
  //     routingText = "";
  //   }

  //   setRoutingCode(routingText)
  // }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry btn_disabled={true} />;
      case 'Product Category1':
        return <FrmMProductCategory1Entry btn_disabled={true} />;
      case 'Product Category-2':
        return <FrmMProductCategory2Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-3':
        return <FrmMProductCategory3Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-4':
        return <FrmMProductCategory4Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-5':
        return <FrmMProductCategory5Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'HSN-SAC':
        return <FrmHSNSAC btn_disabled={true} />
      case 'Puchase Unit':
        return <FrmProductUnitEntry closeAddModal={handleCloseRecModal} btn_disabled={true} />
      case 'Sales Unit':
        return <FrmProductUnitEntry closeAddModal={handleCloseRecModal} btn_disabled={true} />
      case 'Stock Unit':
        return <FrmProductUnitEntry closeAddModal={handleCloseRecModal} btn_disabled={true} />
      case 'Packing':
        return <FrmMProductPackingEntry handleCloseRecModal={handleCloseRecModal} btn_disabled={true} />
      case 'Material Type':
        return <FrmMProductMaterialTypeEntry btn_disabled={true} />
      case 'Material Grade':
        return <FrmMProductMaterialGradeEntry btn_disabled={true} />
      case 'Material Shape':
        return <FrmMProductMaterialShapeEntry btn_disabled={true} />
      case 'Consumption Mode':
        return <FrmPropertyEntry btn_disabled={true} />
      case 'Cost Center':
        return <MCostCenterEntry btn_disabled={true} />;
      case 'Profit Center':
        return <ProfitCenterEntry btn_disabled={true} />;
      case 'Product Properties':
        return <FrmProductTypeDynamicControlsEntry btn_disabled={true} for_product_type={cmb_product_type_id} />;
      default:
        return null;
    }
  }

  const FnAddFinishGood = async (functionType) => {
    debugger
    try {
      setIsLoading(true)


      var checkIsValidate = await validate();
      const isCheckboxValid = validateCheckboxesBeforeSave();
      // if (checkIsValidate) {
      if (checkIsValidate && isCheckboxValid) {
        var jsonData = {
          'ProductFgData': {}, 'TechnicalData': {}, 'CommercialData': {},
          'CustomerData': [], 'ProcessData': [], 'QaMappingData': [], 'SupplierData': [], 'AdditionalParametersData': [], 'commonIds': { 'company_id': COMPANY_ID, 'product_fg_id': product_fg_id, 'product_id': product_id },
          'saveKey': functionType
        }
        const formData = new FormData();
        // For General Entry 
        if (functionType === 'generalEntry' || functionType === 'allFGData') {
          const generalEntryDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_id: productFGId !== 0 ? product_id : 0,
            product_fg_id: product_fg_id,
            product_type_id: cmb_product_type_id,
            product_category1_id: cmb_product_category1_id,
            // product_fg_id: product_fg_id,
            product_fg_code: txt_product_fg_code,
            product_fg_name: txt_product_fg_name,
            product_fg_short_name: txt_fg_short_name,
            product_fg_print_name: txt_fg_print_name === "" ? txt_product_fg_name : txt_fg_print_name,
            product_fg_tech_spect: txt_fg_tech_spect,
            product_fg_oem_part_code: txt_fg_oem_part_code,
            product_fg_our_part_code: txt_fg_our_part_code,
            product_fg_item_sr_no: txt_fg_item_sr_no,
            product_fg_drawing_no: txt_fg_drawing_no,
            product_fg_model_no: txt_fg_model_no,
            product_fg_hsn_sac_code_id: cmb_fg_hsn_sac_code_id,
            product_fg_purchase_unit_id: cmb_fg_purchase_unit_id,
            product_fg_sales_unit_id: cmb_fg_sales_unit_id,
            product_fg_stock_unit_id: cmb_fg_stock_unit_id,
            product_fg_packing_id: cmb_fg_packing_id,
            product_fg_bar_code: txt_fg_bar_code !== "" ? txt_fg_bar_code : product_fg_id,
            product_consumption_mode: cmb_consumption_mode,
            godown_id: cmb_godown_id === null || cmb_godown_id === "" ? 1 : cmb_godown_id,
            godown_section_id: cmb_godown_section_id === null || cmb_godown_section_id === "" ? 1 : cmb_godown_section_id,
            godown_section_beans_id: cmb_godown_section_beans_id === null || cmb_godown_section_beans_id === "" ? 1 : cmb_godown_section_beans_id,
            product_origin_type: cmb_origin_type,
            product_origin_country: cmb_origin_country,
            remark: txt_remarkForProductEntry,
            qa_remark: txt_qa_remark,
            is_active: is_activeGen,
            bom_applicable: is_BOMApplicable,
            created_by: product_id === 0 ? UserName : null,
            modified_by: product_id !== 0 ? UserName : null,
          };
          jsonData.ProductFgData = generalEntryDATA;
          formData.append(`qrCodeFile`, file_fg_qr_code)
        }

        //For Technical Entry
        if (functionType === 'technicalEntry' || functionType === 'allFGData') {
          const technicalEntryDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_fg_id: product_fg_id,
            product_fg_technical_id: product_fg_technical_id,
            product_fg_technical_name: txt_product_fg_technical_name,
            product_category2_id: cmb_product_category2_id,
            product_category3_id: cmb_product_category3_id,
            product_category4_id: cmb_product_category4_id,
            product_category5_id: cmb_product_category5_id,
            product_material_type_id: cmb_product_material_type_id,
            product_material_grade_id: cmb_product_material_grade_id,
            product_material_shape_id: cmb_product_material_shape_id,
            product_material_colour: cmb_product_material_colour,
            product_alternate_fg_id: cmb_product_alternate_fg_id,
            product_glm: txt_glm,
            product_gsm: txt_gsm,
            assembly_scrap_percent: txt_assembly_scrap_percent,
            remark: txt_remarkForProductEntry,
            is_active: is_activeGen,
            created_by: product_id === 0 ? UserName : null,
            modified_by: product_id !== 0 ? UserName : null
          };
          jsonData.TechnicalData = technicalEntryDATA;
        }

        // For Commercial Entry
        if (functionType === 'commercialEntry' || functionType === 'allFGData') {
          const commercialEntryDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_fg_id: product_fg_id,
            product_fg_commercial_id: productFGId !== 0 ? 0 : product_fg_commercial_id,
            product_fg_gross_weight: txt_product_fg_gross_weight,
            product_fg_net_weight: txt_product_fg_net_weight,
            product_fg_std_weight: txt_product_fg_std_weight,
            product_fg_volume: txt_product_fg_volume,
            product_fg_mrp: txt_product_fg_mrp,
            product_fg_landed_price: txt_product_fg_landed_price,
            product_fg_avg_price: txt_product_fg_avg_price,
            product_fg_std_profit_percent: txt_product_fg_std_profit_percent,
            product_fg_std_discount_percent: txt_product_fg_std_discount_percent,
            product_fg_moq: txt_product_fg_moq,
            product_fg_mov: txt_product_fg_mov,
            product_fg_eoq: txt_product_fg_eoq,
            product_fg_mpq: txt_product_fg_mpq,
            product_fg_min_stock_level: txt_product_fg_min_stock_level,
            product_fg_max_stock_level: txt_product_fg_max_stock_level,
            product_fg_reorder_stock_level: txt_product_fg_reorder_stock_level,
            product_fg_depriciation_percent: txt_product_fg_depriciation_percent,
            sales_tolerance_percent: txt_sales_tolerance_percent,
            product_fg_price_type: is_priceType,
            product_fg_abc_analysis: is_abcAnalysis,
            qa_required: is_qaRequired,
            test_certificate_required: is_testCertified,
            profit_center_id: cmb_profit_center,
            cost_center_id: cmb_profit_center !== "" && cmb_profit_center !== "0" ? cmb_cost_center : "",
            routing_code: txt_routing_code,
            remark: txt_remarkForProductEntry,
            is_active: is_activeGen,
            created_by: product_id === 0 ? UserName : null,
            modified_by: product_id !== 0 ? UserName : null

          };
          jsonData.CommercialData = commercialEntryDATA;
        }

        if (functionType === 'allFGData') {
          // Use querySelectorAll to find all elements with the specified class name
          const checkboxes = document.querySelectorAll(`.selectProperty`);

          // Loop through all the elements to find the one with the matching parameterName
          checkboxes.forEach(checkbox => {
            if (checkbox.getAttribute('parameterName') === 'Sort Number') {
              // If found, set its checked property to true
              checkbox.checked = true;
            }
          });

          // Trans Properties Data Data
          $("input:checkbox[name=selectProperty]:checked").each(function () {
            let findPropertyData = productTypePropertiesData.find(item => item.product_type_dynamic_controls_id === parseInt($(this).val()));
            const propertyData = {
              company_id: COMPANY_ID,
              company_branch_id: COMPANY_BRANCH_ID,
              product_parameter_id: 0,
              product_type_id: cmb_product_type_id,
              product_id: product_fg_id,
              product_parameter_name: findPropertyData.control_name,
              product_parameter_value: findPropertyData.product_parameter_value,
              control_master: findPropertyData.newFilter.control_master,
              product_parameter_prefix: findPropertyData.product_parameter_prefix,
              product_parameter_sufix: findPropertyData.product_parameter_sufix,
              product_parameter_calculation_type: findPropertyData.product_parameter_calculation_type,
              product_parameter_from_value: findPropertyData.product_parameter_from_value,
              product_parameter_to_value: findPropertyData.product_parameter_to_value,
              control_master: findPropertyData.control_master,
              product_type_dynamic_controls_id: parseInt($(this).val()),
              // product_parameter_formula: findPropertyData.product_parameter_formula,
              remark: findPropertyData.remark,
              created_by: keyForViewUpdate === "" ? UserName : findPropertyData.created_by,
              modified_by: keyForViewUpdate === "update" ? UserName : null,
            }
            jsonData.AdditionalParametersData.push(propertyData);
          });
          // if (additionalParamtersDetailsData.product_parameter_name !== "") {
          //   for (let parameterItem = 0; parameterItem < additionalParamtersDetailsData.length; parameterItem++) {
          //     const parameterDetailsRow = additionalParamtersDetailsData[parameterItem];

          //     const parameterObj = {
          //       company_id: COMPANY_ID,
          //       company_branch_id: COMPANY_BRANCH_ID,
          //       product_parameter_id: parameterDetailsRow.product_parameter_id !== 0 ? parameterDetailsRow.product_parameter_id : 0,
          //       product_type_id: cmb_product_type_id,
          //       product_id: product_fg_id,
          //       product_parameter_name: parameterDetailsRow.product_parameter_name,
          //       product_parameter_prefix: parameterDetailsRow.product_parameter_prefix,
          //       product_parameter_calculation_type: parameterDetailsRow.product_parameter_calculation_type,
          //       product_parameter_from_value: parameterDetailsRow.product_parameter_from_value,
          //       product_parameter_to_value: parameterDetailsRow.product_parameter_to_value,
          //       product_parameter_formula: parameterDetailsRow.product_parameter_formula,
          //       remark: parameterDetailsRow.remark,
          //       created_by: keyForViewUpdate === "" ? UserName : parameterDetailsRow.created_by,
          //       modified_by: keyForViewUpdate === "update" ? UserName : null
          //     }
          //     jsonData.AdditionalParametersData.push(parameterObj)
          //   }
          // } else {
          //   jsonData.AdditionalParametersData = [];
          // }
        }

        //For Supplier Mapping Information
        if (functionType === 'supplierMapping' || functionType === 'allFGData') {
          $("input:checkbox[name=checkSupplier]:checked").each(function () {
            const supplierMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_fg_id: product_fg_id,
              supplier_id: $(this).val(),
              created_by: product_id === 0 ? UserName : null,
              modified_by: product_id !== 0 ? UserName : null
            }
            jsonData.SupplierData.push(supplierMapDATA)
          });
        }

        //Customer Mapping Information
        if (functionType === 'customerMapping' || functionType === 'allFGData') {
          $("input:checkbox[name=checkCustomer]:checked").each(function () {
            const customerMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_fg_id: product_fg_id,
              customer_id: $(this).val(),
              created_by: product_id === 0 ? UserName : null,
              modified_by: product_id !== 0 ? UserName : null
            }
            jsonData.CustomerData.push(customerMapDATA)
          });
        }

        // 
        //For Process Mapping Information
        if (functionType === 'processMapping' || functionType === 'allFGData') {
          $("input:checkbox[name=checkProcess]:checked").each(function () {
            const processMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_fg_id: product_fg_id,
              product_process_id: $(this).val(),
              created_by: product_id === 0 ? UserName : null,
              modified_by: product_id !== 0 ? UserName : null
            }
            jsonData.ProcessData.push(processMapDATA)
          });
        }

        //For QA Mapping Information
        //chnges by ujjwala on 10 /1/2024 start
        if (functionType === 'qaMapping' || functionType === 'allFGData') {
          $("input:checkbox[name=checkQaMapp]:checked").each(function () {
            let qaMappingObj = dataQA.find((item) => item.product_qa_parameters_id === parseInt(this.value))
            const qaMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_fg_id: product_fg_id,
              product_qa_parameters_id: $(this).val(),
              created_by: product_id === 0 ? UserName : null,
              modified_by: product_id !== 0 ? UserName : null,
              product_fg_qa_from_range: qaMappingObj.from_range,
              product_fg_qa_to_range: qaMappingObj.to_range,
              product_fg_qa_from_deviation_percent: qaMappingObj.from_deviation_percent,
              product_fg_qa_to_deviation_percent: qaMappingObj.to_deviation_percent,
            }
            jsonData.QaMappingData.push(qaMapDATA)
          });
        }

        //end by ujjwala 
        formData.append(`TransData`, JSON.stringify(jsonData))
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnAddUpdateRecord`, requestOptions)
        const response = await apicall.json();
        if (response.success !== 1) {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)
        } else {
          const { product_fg_id, product_fg_name, product_fg_qr_code } = response.data;
          // setProductFgId(product_fg_id)
          // setFGName(product_fg_name)
          // setFGQrCodePath(product_fg_qr_code)
          setSuccMsg(response.message)

          setShowSuccessMsgModal(true)
          $('#upload_btn_id').attr("disabled", false);
        }
      }
      setIsLoading(false)

    } catch (error) {
      console.log("error: ", error);
      navigate('/Error')
    }
  }

  const onFileUpload = (e) => {
    if (e.target.files) {
      var file = e.target.files[0];
      setFGQrCode(file);
    }
  }

  const fetchQrCode = async () => {
    try {
      const downloadImageApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnGetQRCode/${product_fg_id}`)
      const blob = await downloadImageApiCall.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `qrcode.png`,);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const percentValidate = (val, key) => {
    switch (key) {
      case 'AssemblyScrap':
        if (validatePercentage.current.percentValidate(val)) {
          setAssemblyScrapPercent(val)
        }
        break;


      case 'StandardProfit':
        if (validatePercentage.current.percentValidate(val)) {
          setPFGStdProfitPercent(val)
        }
        break;

      case 'PDiscountPercent':
        if (validatePercentage.current.percentValidate(val)) {
          setPFGDiscountPercent(val)
        }
        break;

      case 'PDeprecationPercent':
        if (validatePercentage.current.percentValidate(val)) {
          setPFGDepricationPercent(val)
        }
        break;

      case 'PTolerancePercent':
        if (validatePercentage.current.percentValidate(val)) {
          setSalesTolerancePercent(val)
        }
        break;
    }
  }

  // Validations
  const validate = async () => {
    // let categoryVal = cmb_product_category1_id || '';
    // if (categoryVal === "" || categoryVal === '0') {
    //   $("#error_cmb_product_category1_id").text("Please fill this Category 1...!");
    //   $("#error_cmb_product_category1_id").show();
    //   $("#cmb_product_category1_id").focus();
    //   return false;
    // }

    // let category2Val = cmb_product_category2_id || '';
    // if (category2Val === "" || category2Val === '0') {
    //   $("#error_cmb_product_category2_id").text("Please fill this Category 2...!");
    //   $("#error_cmb_product_category2_id").show();
    //   $("#cmb_product_category2_id").focus();
    //   return false;
    // }
    // let profitcenterVal = cmb_profit_center || '';
    // if (profitcenterVal === "" || profitcenterVal === '0') {
    //   $("#error_cmb_profit_center").text("Please fill Profit Center...!");
    //   $("#error_cmb_profit_center").show();
    //   $("#cmb_profit_center").focus();
    //   return false;
    // }
    let hsnVal = cmb_fg_hsn_sac_code_id || '';
    if (hsnVal === "" || hsnVal === '0') {
      $("#error_cmb_fg_hsn_sac_code_id").text("Please select HSN SAC...!");
      $("#error_cmb_fg_hsn_sac_code_id").show();
      $("#cmb_fg_hsn_sac_code_id		").focus();
      return false;
    }

    // let costcenterVal = cmb_cost_center || '';
    // if (costcenterVal === "" || costcenterVal === '0') {
    //   $("#error_cmb_cost_center").text("Please fill Cost Center...!");
    //   $("#error_cmb_cost_center").show();
    //   $("#cmb_cost_center").focus();
    //   return false;
    // }
    // Validate general entry form
    if (!(await validationRef.current.validateForm('productFgFormId'))) return false;

    // Validate technical entry form
    // if (!(await validationRef.current.validateForm('technicalEntryFormId'))) return false;

    // Validate commercial entry form
    if (!(await validationRef.current.validateForm('commercialEntryFormId'))) return false;




    let selectedProperties = $('#productProperties-table-id tbody tr .selectProperty:checked')
    let propertyIsValid = true;
    if (selectedProperties.length !== 0) {
      selectedProperties.each(function () {
        let currentTblRow = $(this.parentElement.parentElement)
        const id = this.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.children;
        const tagType = id[0].tagName.toLowerCase();
        let parameterValue = currentTblRow.find(`${tagType}[id^="product_parameter_value_"]`).val();
        if (parameterValue === '') {
          $(currentTblRow.find(`${tagType}[id^="product_parameter_value_"]`))[0].parentElement.dataset.tip = `${tagType === 'select' ? 'Please select atleast one...!' : 'Please enter input...!'}`;
          $(currentTblRow.find(`${tagType}[id^="product_parameter_value_"]`))[0].focus();
          return propertyIsValid = false;
        }
      });
      return propertyIsValid;
    }
    return true;
  };


  const validateFields = () => {
    validationRef.current.validateFieldsOnChange('productFgFormId');
    // validationRef.current.validateFieldsOnChange('technicalEntryFormId');
    validationRef.current.validateFieldsOnChange('commercialEntryFormId');
    // validationRef.current.validateFieldsOnChange('CountFormID')
  }

  const validateNo = (key) => {
    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{5}$/
    const regexNo1 = /^[0-9]*\.[0-9]{3}$/
    const regexNo2 = /^[0-9]*\.[0-9]{0}$/
    const alphabetRegex = /^[A-Za-z]*$/;
    var dot = ".";
    switch (key) {
      case 'PGrossWeight':
        var grossWtVal = $('#txt_product_rm_gross_weight').val();
        if (!regexNo.test(grossWtVal) && grossWtVal.length < 15 || grossWtVal.indexOf(dot) === 14) {
          if (numCheck.test(grossWtVal)) {
            setPFGGrossWeight(grossWtVal)
          }
        }
        break;

      case 'PNetWeight':
        var netWtVal = $('#txt_product_rm_net_weight').val();
        if (!regexNo.test(netWtVal) && netWtVal.length < 15 || netWtVal.indexOf(dot) === 14) {
          if (numCheck.test(netWtVal)) {
            setPFGNetWeight(netWtVal)
          }
        }
        break;

      case 'PStdWeight':
        var stdWtVal = $('#txt_product_rm_std_weight').val();
        if (!regexNo.test(stdWtVal) && stdWtVal.length < 15 || stdWtVal.indexOf(dot) === 14) {
          if (numCheck.test(stdWtVal)) {
            setPFGStdWeight(stdWtVal)
          }
        }
        break;

      case 'PVolume':
        var volumeVal = $('#txt_product_rm_volume').val();
        if (!regexNo.test(volumeVal) && volumeVal.length < 15 || volumeVal.indexOf(dot) === 14) {
          if (numCheck.test(volumeVal)) {
            setPFGVolume(volumeVal)
          }
        }
        break;

      case 'PMrp':
        var mrpVal = $('#txt_product_rm_mrp').val();
        if (!regexNo.test(mrpVal) && mrpVal.length < 15 || mrpVal.indexOf(dot) === 14) {
          if (numCheck.test(mrpVal)) {
            setPFGMrp(mrpVal)
          }
        }
        break;

      case 'PLandedPrice':
        var landedPriceVal = $('#txt_product_rm_landed_price').val();
        if (!regexNo1.test(landedPriceVal) && landedPriceVal.length < 17 || landedPriceVal.indexOf(dot) === 16) {
          if (numCheck.test(landedPriceVal)) {
            setPFGLandedPrice(landedPriceVal)
          }
        }
        break;

      case 'PAvgPrice':
        var avgPriceVal = $('#txt_product_rm_avg_price').val();
        if (!regexNo1.test(avgPriceVal) && avgPriceVal.length < 17 || avgPriceVal.indexOf(dot) === 16) {
          if (numCheck.test(avgPriceVal)) {
            setPFGAvgPrice(avgPriceVal)
          }
        }
        break;

      case 'PMoq':
        var moqVal = $('#txt_product_rm_moq').val();
        if (!regexNo1.test(moqVal) && moqVal.length < 17 || moqVal.indexOf(dot) === 16) {
          if (numCheck.test(moqVal)) {
            setPFGmoq(moqVal)
          }
        }
        break;

      case 'PMpq':
        var mpqVal = $('#txt_product_rm_mpq').val();
        if (!regexNo1.test(mpqVal) && mpqVal.length < 17 || mpqVal.indexOf(dot) === 16) {
          if (numCheck.test(mpqVal)) {
            setPFGmpq(mpqVal)
          }
        }
        break;

      case 'PMinStockLevel':
        var minStockLevelVal = $('#txt_product_rm_min_stock_level').val();
        if (!regexNo1.test(minStockLevelVal) && minStockLevelVal.length < 17 || minStockLevelVal.indexOf(dot) === 16) {
          if (numCheck.test(minStockLevelVal)) {
            setPFGMinStockLevel(minStockLevelVal)
          }
        }
        break;

      case 'PMaxStockLevel':
        var maxStockLevelVal = $('#txt_product_rm_max_stock_level').val();
        if (!regexNo1.test(maxStockLevelVal) && maxStockLevelVal.length < 17 || maxStockLevelVal.indexOf(dot) === 16) {
          if (numCheck.test(maxStockLevelVal)) {
            setPFGMaxStockLevel(maxStockLevelVal)
          }
        }
        break;

      case 'PReorderStockLevel':
        var reorderStockLevelVal = $('#txt_product_rm_reorder_stock_level').val();
        if (!regexNo1.test(reorderStockLevelVal) && reorderStockLevelVal.length < 17 || reorderStockLevelVal.indexOf(dot) === 16) {
          if (numCheck.test(reorderStockLevelVal)) {
            setPFGReorderStockLevel(reorderStockLevelVal)
          }
        }
        break;

      case 'PMov':
        var movVal = $('#txt_product_rm_mov').val();
        if (!regexNo1.test(movVal) && movVal.length < 17 || movVal.indexOf(dot) === 16) {
          if (numCheck.test(movVal)) {
            setPFGmov(movVal)
          }
        }
        break;

      case 'PEoq':
        var eoqVal = $('#txt_product_rm_eoq').val();
        if (!regexNo2.test(eoqVal)) {
          if (numCheck.test(eoqVal)) {
            setPFGeoq(eoqVal)
          }
        }
        break;

      case 'GLM':
        let glm = $('#txt_glm').val() || '';
        if (!regexNo1.test(glm) && glm.length < 17 || glm.indexOf(dot) === 16) {
          if (numCheck.test(glm)) {
            setGLM(glm)
          }
        }
        break;

      case 'GSM':
        let gsm = $('#txt_gsm').val() || '';
        if (!regexNo1.test(gsm) && gsm.length < 17 || gsm.indexOf(dot) === 16) {
          if (numCheck.test(gsm)) {
            setGSM(gsm)
          }
        }
        break;
      // case 'txt_Mixing':
      //   var txt_Mixing = $('#txt_Mixing').val();

      //   var txt_Count_Desc = $('#txt_Count_Desc').val().toUpperCase();
      //   if (!regexNo.test(txt_Mixing) && txt_Mixing.length <= 14 || txt_Mixing.indexOf(dot) === 14 && alphabetRegex.test(txt_Count_Desc)) {
      //     if (numCheck.test(txt_Mixing)) {
      //       settxt_Mixing(txt_Mixing)
      //       settxt_Count_name(txt_Count_Desc + txt_Mixing);
      //     }
      //   }
      //   break;

      case 'txt_Count_Desc':
        var txt_Count_Desc = $('#txt_Count_Desc').val().toUpperCase();
        if (alphabetRegex.test(txt_Count_Desc)) {
          settxt_Count_Desc(txt_Count_Desc)
          // settxt_Count_name(txt_Count_Desc);
        }
        break;
      case 'txt_Count_name':
        var txt_Count_name = $('#txt_Count_name').val().toUpperCase();

        settxt_Count_name(txt_Count_name);

        break;

      case 'txt_Actual_Count':
        var txt_Actual_Count = $('#txt_Actual_Count').val();
        settxt_Actual_Count(validatePercentage.current.decimalNumber((txt_Actual_Count), 4))

        break;

      default:
        break;
    }
  }

  const FnRefreshbtn = async (key) => {
    if (keyForViewUpdate === 'update' || keyForViewUpdate === '') {
      // 
      switch (key) {
        case 'godown_id':
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "cmv_godown"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

          comboBoxRef.current.fillFiltersCombo(globalQuery).then((godownidApiCall) => {
            setgodownidOptions(godownidApiCall);
          })

          setgodownid('');
          setgodownidSectionOptions([])
          setgodownsectionid('');
          setgodownsectionbeansidOptions([])
          setgodownsectionbeansid('')
          break;

        case 'godown_Section':
          await comboOnChange("godown");
          break;

        case 'godown_Section_Beans':
          await comboOnChange("godownsection");
          break;
        default:
          break;
      }
    }
  }


  // ---------------------------------------------------------------------------------------------

  //changes by tushar
  const handleAccordionSelect = async (eventKey) => {

    let checkedLength = 0;
    switch (eventKey) {
      case 'SupplierMapping':
        checkedLength = $("input:checkbox[name=checkSupplier]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("SupplierMapping");
          console.log("SupplierMapping");
        }
        if (supplierMappingData.length !== 0) {
          let supplierCheckbox = document.getElementsByName('checkSupplier');
          for (let i = 0; i < supplierMappingData.length; i++) {
            for (let checkbox of supplierCheckbox) {
              if (parseInt(checkbox.value) === supplierMappingData[i].supplier_id) {
                $('input:checkbox[name="checkSupplier"][value="' + supplierMappingData[i].supplier_id + '"]').attr('checked', true)
              }
            }
          }
        }
        break;

      case 'CustomerMapping':
        checkedLength = $("input:checkbox[name=checkCustomer]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("CustomerMapping");
          console.log("CustomerMapping");
        }
        //Updating Customer CheckBoxes
        if (customerMappingData.length !== 0) {
          let customerCheckbox = document.getElementsByName('checkCustomer');
          for (let i = 0; i < customerMappingData.length; i++) {
            for (let checkbox of customerCheckbox) {
              if (parseInt(checkbox.value) === customerMappingData[i].customer_id) {
                $('input:checkbox[name="checkCustomer"][value="' + customerMappingData[i].customer_id + '"]').attr('checked', true);
              }
            }
          }
        }
        break;

      case 'ProcessMapping':
        checkedLength = $("input:checkbox[name=checkProcess]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("ProcessRecords");
          console.log("ProcessMapping");
        }
        //Updating Process Records
        if (processMappingData.length !== 0) {
          let processCheckbox = document.getElementsByName('checkProcess');
          for (let i = 0; i < processMappingData.length; i++) {
            for (let checkbox of processCheckbox) {
              if (parseInt(checkbox.value) === processMappingData[i].product_process_id) {
                $('input:checkbox[name="checkProcess"][value="' + processMappingData[i].product_process_id + '"]').attr('checked', true);
              }
            }
          }
        }
        break;

      case 'QAMapping':
        checkedLength = $("input:checkbox[name=checkQaMapp]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("QaParameters");
        }
        //Updating QA Records
        if (qaMappingData.length !== 0) {
          let qaMappCheckbox = document.getElementsByName('checkQaMapp');
          for (let i = 0; i < qaMappingData.length; i++) {
            for (let checkbox of qaMappCheckbox) {
              if (parseInt(checkbox.value) === qaMappingData[i].product_qa_parameters_id) {
                $('input:checkbox[name="checkQaMapp"][value="' + qaMappingData[i].product_qa_parameters_id + '"]').attr('checked', true);
              }
            }
          }
        }
        console.log("QAMapping");
        break;

      default:
        console.log('Invalid accordion key');
    }
    if (keyForViewUpdate === "view") {
      $('input[type="checkbox"]').prop('disabled', true);
    }
  };


  const forwardToNxtPage = async () => {

    if (product_id !== 0) {
      const isValidate = await validate();
      if (isValidate) {
        if (is_BOMApplicable === "Yes") {
          resetGlobalQuery();
          globalQuery.columns.push("bom_applicable");
          globalQuery.table = "sm_product_fg"
          globalQuery.conditions.push({ field: "product_fg_id", operator: "=", value: product_fg_id });
          const IsBomApplicable = await comboBoxRef.current.removeCatcheFillCombo(globalQuery)
          if (IsBomApplicable[0].bom_applicable === "Yes") {
            goToNext(product_fg_id, txt_product_fg_name, document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName'), cmb_product_type_id);
          } else {
            setShowErrorMsgModal(true)
            setErrMsg(`${txt_product_fg_name} Finish Good Is Not BOM Applicable First Update Record..!`)
          }
        } else {
          setShowErrorMsgModal(true)
          setErrMsg(`${txt_product_fg_name} Finish Good Is Not BOM Applicable..!`)
        }
      }
    } else {
      const isValidate = await validate();
      if (isValidate) {
        setShowErrorMsgModal(true)
        setErrMsg(` Please Save ${txt_product_fg_name} First..!`)
      }

    }
  }
  // --------------------------------------delete -------------------------------------------------------
  // Popup Fields
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigate('/Masters/FinishGoodsListing')
  }

  const deleteshow = () => { setShow(true) }

  async function DeleteRecord() {

    try {
      const method = { method: 'DELETE' }
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnDeleteRecord/${product_fg_id}/${UserName}`, method)
      setShow(false)
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }


  // --------------------------------------delete -------------------------------------------------------

  function toggleQuotationTermsChkBoxes(key) {

    switch (key) {
      // For Supplier Mapping
      case "checkAllSupplier":
        $('.checkSupplier').prop('checked', $('#checkAllSupplier').is(":checked"));
        break;
      case 'PartiallySupplierSelection':
        $('#checkAllSupplier').prop('checked', $('input:checkbox.checkSupplier:checked').length === $('input:checkbox.checkSupplier').length);
        break;
      // For Process Mapping
      case 'checkAllProcess':
        $('.checkProcess').prop('checked', $('#checkAllProcess').is(":checked"));
        break;
      case 'PartiallyProcessSelection':
        $('#checkAllProcess').prop('checked', $('input:checkbox.checkProcess:checked').length === $('input:checkbox.checkProcess').length);
        break;
      // For QA Mapping
      case 'checkAllQa':
        $('.checkQaMapp').prop('checked', $('#checkAllQa').is(":checked"));
        break;
      case 'PartiallyQAmappingSelection':
        $('#checkAllQa').prop('checked', $('input:checkbox.checkQaMapp:checked').length === $('input:checkbox.checkQaMapp').length);
        break;
      // For customer Mapping
      case 'checkAllCustomer':
        $('.checkCustomer').prop('checked', $('#checkAllCustomer').is(":checked"));
        break;
      case 'PartiallyCustomerSelection':
        $('#checkAllCustomer').prop('checked', $('input:checkbox.checkCustomer:checked').length === $('input:checkbox.checkCustomer').length);
        break;
      default:
        break;
    }
  }
  //--------------------------------------check all checkboxes ----------------------------------------------------

  //-------------------------------------------------- Product Properties data Starts -----------------------------------------------------------------------------------------


  const fnGetProductTypeControls = async (product_type_id) => {
    try {
      $('#propertiesSpinnerId').show();
      resetGlobalQuery();
      globalQuery.columns.push("*");
      globalQuery.table = "sm_product_type_dynamic_controls"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: product_type_id });
      const getProductProperties = await comboBoxRef.current.removeCatcheFillCombo(globalQuery)
      console.log('getProductProperties:- ', getProductProperties);

      const modifyProductPropertiesData = [];
      if (getProductProperties.length !== 0) {
        for (let i = 0; i < getProductProperties.length; i++) {
          const propertiesItem = getProductProperties[i];
          let masterList;
          const initializeKeys = {
            control_master: propertiesItem.control_type === 'P' ? `amv_properties:${propertiesItem.control_master}` : propertiesItem.control_master,
            product_parameter_value: "",
            product_parameter_calculation_type: "",
            product_parameter_from_value: "",
            product_parameter_id: 0,
            product_parameter_prefix: "",
            product_parameter_sufix: "",
            product_parameter_to_value: "",
            remark: ""
          }
          const newFilter = {
            type: propertiesItem.control_type,
            dataArray: []    // new array for if there is combo 
          };
          if (propertiesItem.control_type === "P") {
            resetGlobalQuery();
            globalQuery.columns = ["field_name", "field_id", "property_value", "property_name_value"];
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: propertiesItem.control_master });
            globalQuery.orderBy = ["property_id"];
            globalQuery.table = "amv_properties"
            masterList = await comboBoxRef.current.fillFiltersCombo(globalQuery)
            newFilter.dataArray = masterList
          }
          if (propertiesItem.control_type === "C") {
            if (propertiesItem.control_master === "xmv_spinning_prod_count") {
              resetGlobalQuery();
              globalQuery.columns = ["field_name", "field_id", "production_actual_count"];
              // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
              globalQuery.table = "xmv_spinning_prod_count"
              masterList = await comboBoxRef.current.fillFiltersCombo(globalQuery)
              newFilter.dataArray = masterList
            } else if (propertiesItem.control_master === "smv_product_rm") {
              resetGlobalQuery();
              globalQuery.columns = ["field_name", "field_id", "actual_count"];
              globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: 12 });
              globalQuery.table = propertiesItem.control_master
              masterList = await comboBoxRef.current.fillFiltersCombo(globalQuery)
              newFilter.dataArray = masterList
            } else {
              resetGlobalQuery();
              globalQuery.columns = ["field_name", "field_id"];
              globalQuery.table = propertiesItem.control_master;
              masterList = await comboBoxRef.current.fillFiltersCombo(globalQuery)
              newFilter.dataArray = masterList

              // masterList = await comboBoxRef.current.fillMasterData(propertiesItem.control_master, "", "")
            }

            // newFilter.dataArray = masterList
          }
          modifyProductPropertiesData.push({ ...getProductProperties[i], ...initializeKeys, newFilter })
        }

        console.log('modifyProductPropertiesData: - ', modifyProductPropertiesData);


        if (modalHeaderName === "Product Properties") {
          setProductTypePropertiesData((prevArray) => {

            const newProperties = [
              ...prevArray,
              ...modifyProductPropertiesData.filter(item => !prevArray.some(prevItem => prevItem.product_type_dynamic_controls_id === item.product_type_dynamic_controls_id))
            ];
            return newProperties.sort((a, b) => a.display_sequence - b.display_sequence);;
          })
        } else {
          // .filter(acc => acc.is_display).sort((a, b) => a.display_sequence - b.display_sequence);
          modifyProductPropertiesData.sort((a, b) => a.display_sequence - b.display_sequence);
          setProductTypePropertiesData(modifyProductPropertiesData)
        }
        // setParametersIsLoading(false);
        $('#propertiesSpinnerId').hide();

      } else {
        setProductTypePropertiesData([])
      }
      return modifyProductPropertiesData;
    } catch (error) {
      console.error();
    } finally {
      setParametersIsLoading(false);
    }
  }

  //Fn to render bill book  details table
  const renderProductPropertiesTbl = useMemo(() => {
    return <>
      <div className="spinner-overlay" id={'propertiesSpinnerId'}>
        <div className="spinner-container">
          <CircularProgress color="primary" />
          <span id="spinner_text" className="text-dark">Loading Properties...</span>
        </div>
      </div>
      <Table className="erp_table" id='productProperties-table-id' responsive bordered striped erp_table_scroll>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Action</th>
            <th className='erp_table_th'>Sr. No.</th>
            <th className="erp_table_th">Parameter Name</th>
            <th className="erp_table_th">Parameter Value</th>
            <th className="erp_table_th">Prefix</th>
            <th className="erp_table_th">Sufix</th>
            <th className="erp_table_th">Calculation Type</th>
            <th className="erp_table_th">From Value</th>
            <th className="erp_table_th">To Value</th>
            <th className="erp_table_th">Remark</th>
          </tr>
        </thead>
        <tbody>
          {productTypePropertiesData.map((filter, index) => (
            <tr rowIndex={index} parameterName={filter.control_name}>
              <td className="erp_table_td">
                <input type="checkbox" className="selectProperty" name="selectProperty" parameterName={filter.control_name} value={filter.product_type_dynamic_controls_id} id={'selectProperty_' + filter.product_type_dynamic_controls_id}
                  disabled={keyForViewUpdate === "view"} onChange={(e) => { propertyCheckedchange(); }} />

              </td>
              <td className="erp_table_td ">{index + 1}</td>
              <td className="erp_table_td ">{filter.control_name} </td>

              {/* Values */}
              <td>
                {(filter.newFilter.type === 'C' || filter.newFilter.type === 'P') && (
                  <select id={`product_parameter_value_${index}`} className="form-select form-select-sm" value={filter.product_parameter_value}
                    onChange={(e) => {
                      updatePropertiesTblRows(filter, e); propertyCheckedchange();
                      if (e.target.value === "0") {
                        viewProductionForm(); // Call the function when "Add New Record+" is selected
                      }
                    }} Headers='product_parameter_value' disabled={keyForViewUpdate === "view"}>
                    <option value=''>Select</option>
                    {filter.newFilter.type === 'C' && (
                      <option value="0">Add New Record+</option>
                    )}


                    {filter.newFilter.dataArray.map((item, index) => (
                      <option key={index} value={item.field_id} property_name_value={filter.control_master === "smv_product_rm" ? `:${item.actual_count}` : item.property_name_value}>
                        {item.field_name}
                      </option>
                    ))}
                  </select>
                )}
                {filter.newFilter.type === 'T' && (
                  <input type="text" id={`product_parameter_value_${index}`} className='erp_input_field mb-0 ' value={filter.product_parameter_value} property_name_value={item.property_name_value}
                    onChange={(e) => { updatePropertiesTblRows(filter, e); propertyCheckedchange(); }} Headers='product_parameter_value' disabled={keyForViewUpdate === "view"} />

                )}
                {filter.newFilter.type === 'D' && (<>
                  <input type="date" id={`product_parameter_value_${index}`} className='erp_input_field mb-0 ' value={filter.product_parameter_value} property_name_value={item.property_name_value}
                    onChange={(e) => { updatePropertiesTblRows(filter, e); propertyCheckedchange(); }} Headers='product_parameter_value' disabled={keyForViewUpdate === "view"} />
                </>
                )}
              </td>
              <td className="erp_table_td ">
                <input
                  type="text"
                  className="erp_input_field mb-0" Headers='product_parameter_prefix'
                  id={`product_parameter_prefix_${index}`}
                  value={filter.product_parameter_prefix} maxLength={5}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} disabled={keyForViewUpdate === "view"} />
              </td>
              <td className="erp_table_td ">
                <input
                  type="text"
                  className="erp_input_field mb-0" Headers='product_parameter_sufix'
                  id={`product_parameter_sufix_${index}`}
                  value={filter.product_parameter_sufix} maxLength={5}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} disabled={keyForViewUpdate === "view"} />
              </td>
              <td className="erp_table_td ">
                <select id={`product_parameter_calculation_type_${index}`} className="form-select form-select-sm" value={filter.product_parameter_calculation_type}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} Headers='product_parameter_calculation_type' disabled={keyForViewUpdate === "view"}>
                  <option value=''>Select</option>
                  <option value='Equals'>Equals</option>
                  <option value='Less than'>Less than</option>
                  <option value='Greater than'>Greater than</option>
                  <option value='Between'>Between</option>
                </select>
              </td>
              <td className="erp_table_td ">
                <input type="text"
                  className="erp_input_field mb-0" Headers='product_parameter_from_value'
                  id={`product_parameter_from_value_${index}`}
                  value={filter.product_parameter_from_value}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} disabled={keyForViewUpdate === "view"} />
              </td>
              <td className="erp_table_td  ">
                <input
                  type="text"
                  className="erp_input_field mb-0 "
                  Headers="product_parameter_to_value"
                  id={`product_parameter_to_value_${index}`}
                  value={filter.product_parameter_to_value}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} disabled={keyForViewUpdate === "view"} />
              </td>
              <td className="erp_table_td  ">
                <input type="text" className="erp_input_field mb-0 "
                  Headers="remark" id={`remark_${index}`} value={filter.remark}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} disabled={keyForViewUpdate === "view"} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>

  }, [productTypePropertiesData]);

  // ----------------------------------------------------------------   Material code and name of Fabric --------------------------------------------------

  const FnGenerateMaterialCode = async (productTpVal, ptShortName) => {
    try {
      const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateMaterialCode("sm_product_fg", "product_fg_code", 'product_type_id', productTpVal, "3");
      return autoNoApiCall;
    } catch (error) {
      console.error();
    }
  };

  const FnGenerateMaterialCodefinishgood = async () => {
    let fieldNameLastTrnNo = 'product_fg_code'
    let trnNoAICodelen = 4;

    const selectedProductCat1 = productCategory1Options.find(productType => productType.value == category1ComboRef.current?.value) || {}
    const selectedProductCat1ShortName = selectedProductCat1.short_name || '';

    const selectedProductCat2 = category2Options.find(category2 => category2.value == category2ComboRef.current?.value) || {}
    const selectedProductCat2ShortName = selectedProductCat2.short_name || '';

    if (selectedProductCat1ShortName !== '' && selectedProductCat2ShortName !== '') {
      resetGlobalQuery();
      globalQuery.columns.push(`COALESCE(MAX(SUBSTRING(${fieldNameLastTrnNo}, LENGTH(${fieldNameLastTrnNo}) - ${trnNoAICodelen} + 1, ${trnNoAICodelen}))+1, 1) As nextAINo`);
      globalQuery.table = "smv_product_fg_technical"
      globalQuery.conditions = [
        // { field: "product_type_id", operator: "=", value: cmb_product_type_id },
        { field: "product_category1_id", operator: "=", value: selectedProductCat1.value },
        { field: "product_category2_id", operator: "=", value: selectedProductCat2.value },
        { field: "is_delete", operator: "=", value: 0 },
        // { field: "company_id", operator: "=", value: COMPANY_ID }
      ];
      const getProductfgCode = await comboBoxRef.current.fillFiltersCombo(globalQuery);
      if (getProductfgCode.length > 0) {
        let latestAINo = (getProductfgCode[0]?.nextAINo || '').toString();
        let reqZerosCount = trnNoAICodelen - latestAINo.length;
        let paddedAINo = latestAINo.padStart(reqZerosCount + latestAINo.length, '0');  // Prepend the required number of zeros
        let generatedMatCodeArr = [selectedProductCat1ShortName, selectedProductCat2ShortName, paddedAINo];
        setFGCode(generatedMatCodeArr.join(''))
        return generatedMatCodeArr.join('');
      } else {
        setFGCode('')
        return '';
      }
    } else {
      setFGCode('')
      return '';
    }
  }

  const generateFabricMaterialNameCode = async () => {
    debugger
    const selectedCounts = { WARP: [], WEFT: [] };
    let materialName = "";
    let materialCode = "";
    let epi = ""; let ppi = ""; let insertion = ""; let width = ""; let weave = "";
    let codeInsertion = "";
    let codeweave = "";
    let codeCount = "";

    $('#productProperties-table-id tbody tr').each(function (Index) {
      let tagType = "";
      let currentTblRow = $(this);
      const isChecked = currentTblRow.find('input[id^="selectProperty_"]').prop('checked');
      const checkboxValue = currentTblRow.find('input[id^="selectProperty_"]').val();
      // Define an array of strings to check for
      const stringsToCheck = ["WARP COUNT 1 [WC1]", "WARP COUNT 2 [WC2]", "WEFT COUNT 1 [FC1]", "WEFT COUNT 2 [FC2]", "WEFT COUNT 3 [FC3]", "WEFT COUNT 4 [FC4]"];
      const warpStrings = ["WARP COUNT 1 [WC1]", "WARP COUNT 2 [WC2]"];
      if (isChecked) {
        let element = document.getElementById(`product_parameter_value_${Index}`);
        if (element.tagName.toLowerCase() === 'select') {
          tagType = 'select';
          console.log('The element is a select box.');
        } else if (element.tagName.toLowerCase() === 'input') {
          tagType = 'input';
          console.log('The element is an input.');
        }
        let paraValue = currentTblRow.find(`${tagType}[id^="product_parameter_value_"]`).val();
        let parameterName = currentTblRow.find('input[id^="selectProperty_"]').attr('parameterName');
        console.log('paraValue:- ', paraValue, ' >-- parameterName:-- ', parameterName);

        // Check if parameterName includes any of the strings in the array
        const includesAny = stringsToCheck.some(str => parameterName.includes(str));
        // if (parameterName.includes("Warp Count 1") || parameterName.includes("Weft Count 1")) {
        if (includesAny) {
          // const countType = parameterName.includes("Warp Count 1" || "Warp Count 2") ? 'warp' : 'weft';
          const countType = warpStrings.some(str => parameterName.includes(str)) ? 'WARP' : 'WEFT';

          const countObject = findProductTypeProperty(parseInt(checkboxValue));

          if (countObject && paraValue !== "") selectedCounts[countType].push(countObject);
        }
        else if (parameterName.includes("EPI 1")) {
          epi = paraValue !== "" ? paraValue : epi; // Assign paraValue to epi only if paraValue is not empty
        } else if (parameterName.includes("PPI 1")) {
          ppi = paraValue !== "" ? `x${paraValue}` : ppi; // Assign paraValue to ppi only if paraValue is not empty
        } else if (parameterName.includes("Insertion")) {
          insertion = paraValue !== "" ? `*${paraValue}` : insertion; // Assign paraValue to ppi only if paraValue is not empty
          codeInsertion = paraValue !== "" ? `${paraValue}` : insertion;
        } else if (parameterName.includes("Width")) {
          width = paraValue !== "" ? `-${paraValue}"` : width; // Assign paraValue to ppi only if paraValue is not empty
          console.log('width:- ', width);

        } else if (parameterName.includes("WEAVE")) {
          // codeweave = paraValue !== "" ? currentTblRow.find(`${tagType}[id^="product_parameter_value_"] option:selected`).text() : codeweave;

          // console.log('codeweave:- ', codeweave, 'paraValue >-: ', paraValue, 'currentTblRow >-', currentTblRow.find(`${tagType}[id^="product_parameter_value_"] option:selected`).text());

          const weaveObject = findProductTypeProperty(parseInt(checkboxValue));
          if (weaveObject && paraValue !== "") {
            const weaveName = weaveObject.newFilter.dataArray.find(countItem => countItem.field_id === parseInt(paraValue));
            paraValue = weaveName ? weaveName.field_name : '';
            codeweave = weaveName ? weaveName.property_value : '';
          }
          weave = paraValue !== "" ? ` ${paraValue}` : weave; // Assign paraValue to ppi only if paraValue is not empty
        }
      }
    });

    const getCodeCount = (count) => {
      return count ? (count < 100 ? `0${count}` : count.toString()) : "";
    };

    if (selectedCounts.WARP.length > 0) {
      materialName += (selectedCounts.WARP.length === 1) ?
        `${getItemName(selectedCounts.WARP[0])}x` :
        `(${selectedCounts.WARP.map(item => getItemName(item)).join(" + ")})x`;
      codeCount = getCodeCount(getItemCount(selectedCounts.WARP[0]));
      materialCode = (selectedCounts.WARP.length === 1) ? "S" : "M";
    }
    if (selectedCounts.WEFT.length > 0) {
      materialName += (selectedCounts.WEFT.length === 1) ?
        `${getItemName(selectedCounts.WEFT[0])}/` :
        `(${selectedCounts.WEFT.map(item => getItemName(item)).join(" + ")})/`;
    }
    materialName += `${epi}${ppi}${insertion}${width}${weave}`;
    materialCode += `${codeCount}${codeweave}${codeInsertion}`;
    const shortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')
    materialCode += await FnGenerateMaterialCode(cmb_product_type_id, shortName);
    setFGName(materialName
      // + `(${materialCode})`
    )
    setFGCode(materialCode)
    FnGenerateTechSpecName(materialName, materialCode);
  };
  const findProductTypeProperty = (checkboxValue) => {
    return productTypePropertiesData.find(item => item.product_type_dynamic_controls_id === checkboxValue);
  };

  const getItemName = (item) => {
    if (item) {
      const countItem = item.newFilter.dataArray.find(countItem => countItem.field_id === item.product_parameter_value);
      return countItem?.field_name || '';
    } else {
      return '';
    }
  };

  const getItemCount = (item) => {
    if (item) {
      const countItem = item.newFilter.dataArray.find(countItem => countItem.field_id === item.product_parameter_value);
      return countItem?.actual_count || '';
    } else {
      return '';
    }
  };

  // const FnGenerateMaterialCode = async (productTpVal, ptShortName) => {
  //   const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateMaterialCode("sm_product_fg", "product_fg_id", 'product_type_id', productTpVal, "3");
  //   return autoNoApiCall;
  // }
  // ---------------------------------------------------------------- Fabric  Material code and name --------------------------------------------------
  // ---------------------------------------------------------------- Yarn  Material code and name --------------------------------------------------


  const generateYarnMaterialNameCode = () => {
    debugger
    let materialName = "Yarn";
    let materialCode = "";
    let selectedVarieties = [];
    $('#productProperties-table-id tbody tr').each(function (Index) {
      let tagType = "";
      let property_value = "";
      let currentTblRow = $(this);
      const isChecked = currentTblRow.find('input[id^="selectProperty_"]').prop('checked');
      const checkboxValue = currentTblRow.find('input[id^="selectProperty_"]').val();
      let property_name_value = "";
      let selectedText = "";
      if (isChecked) {
        let element = document.getElementById(`product_parameter_value_${Index}`);
        if (element.tagName.toLowerCase() === 'select') {
          tagType = 'select';
          property_name_value = currentTblRow.find(`${tagType}[id^="product_parameter_value_"] option:selected`).attr('property_name_value');
          selectedText = currentTblRow.find(`${tagType}[id^="product_parameter_value_"] option:selected`).text();
        } else if (element.tagName.toLowerCase() === 'input') {
          tagType = 'input';
          property_name_value = currentTblRow.find(`${tagType}[id^="product_parameter_value_"]`).attr('property_name_value');
        }
        if (property_name_value !== "" && property_name_value !== undefined) {
          let propertyNameValueArray = property_name_value.split(":");
          property_value = propertyNameValueArray[1];
        }

        let parameterName = currentTblRow.find('input[id^="selectProperty_"]').attr('parameterName');
        if (parameterName.includes("Yarn Type")) {
          materialCode += `${property_value}`;
        } else if (parameterName.includes("Fiber Type")) {
          materialCode += `${property_value}`;
        } else if (parameterName.includes("Process Type")) {
          materialCode += `${property_value}`;
        } else if (parameterName.includes("Sub Process Type")) {
          materialCode += `${property_value}`;
        } else if (parameterName.includes("Single Or Ply")) {
          materialCode += `${property_value}`;
        } else if (parameterName.includes("Knitting or Weaving")) {
          materialCode += `${property_value}`;
        } else if (parameterName.includes("Value Addition")) {
          materialCode += `${property_value}`;
        } else if (parameterName.includes("Value Added Special")) {
          materialCode += `${property_value}`;
        } else if (parameterName.includes("Yarn Variety")) {
          if (property_value !== "") selectedVarieties.push(property_value);
          if (selectedVarieties.length > 0 && property_value !== "") {
            materialCode += (selectedVarieties.length === 1) ?
              `${selectedVarieties[0]}` :
              `(${selectedVarieties.map(item => item).join(" + ")})`;
          }
          // materialCode += `${property_value}`;
        } else if (parameterName.includes("Blend % wise")) {
          materialCode += `${property_value}`;
        } else if (parameterName.includes("COUNT")) {
          materialCode += `${property_value}`;
        }
        if (parameterName !== "" && parameterName !== undefined) {
          // materialName += `-${parameterName}-${selectedText} !== 'Select' ? ${selectedText} : ${''}`
          materialName += `-${parameterName}-${selectedText !== 'Select' ? selectedText : ''}`;
        }
      }
    });
    setFGName(materialName + `(${materialCode})`)
    setFGCode(materialCode)
  }




  // ---------------------------------------------------------------- Yarn  Material code and name --------------------------------------------------

  const propertyCheckedchange = () => {
    debugger
    const shortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')
    if (shortName === "GF" || shortName === "FF") {
      generateFabricMaterialNameCode();
    } else if (shortName === "YN") {
      generateYarnMaterialNameCode();
    }

    if (['GF'].includes(shortName)) {
      // Make the calculations for the GLM & GSM.
      FnCalculateFabricFields();
    }
  }

  const FnCalculateFabricFields = () => {
    debugger;
    let tagType = "";
    let property_name_value = "";
    let warpActualCounts = [0, 0];
    let weftActualCounts = [0, 0, 0, 0];
    let warpCrimp = 0;
    let weftCrimp = 0;
    let warpWaste = 0;
    let weftWaste = 0;
    let widthPerInch = 0;
    let epi = [0, 0];
    let ppi = [0, 0, 0, 0];

    $('#productProperties-table-id tbody tr').each(function (index) {
      let currentTblRow = $(this);
      const isChecked = currentTblRow.find('input[id^="selectProperty_"]').prop('checked');
      if (!isChecked) return;

      let element = document.getElementById(`product_parameter_value_${index}`);
      tagType = element.tagName.toLowerCase();
      let paraValue = currentTblRow.find(`${tagType}[id^="product_parameter_value_"]`).val();
      let parameterName = currentTblRow.find('input[id^="selectProperty_"]').attr('parameterName');

      const getCountValue = () => {
        property_name_value = currentTblRow.find(`${tagType}[id^="product_parameter_value_"] option:selected`).attr('property_name_value') || '';
        return parseFloat(property_name_value.slice(property_name_value.lastIndexOf(':') + 1) || 0);
      };

      switch (true) {
        case /Warp Count 1/.test(parameterName):
          warpActualCounts[0] = getCountValue();
          break;
        case /Warp Count 2/.test(parameterName):
          warpActualCounts[1] = getCountValue();
          break;
        case /Weft Count 1/.test(parameterName):
          weftActualCounts[0] = getCountValue();
          break;
        case /Weft Count 2/.test(parameterName):
          weftActualCounts[1] = getCountValue();
          break;
        case /Weft Count 3/.test(parameterName):
          weftActualCounts[2] = getCountValue();
          break;
        case /Weft Count 4/.test(parameterName):
          weftActualCounts[3] = getCountValue();
          break;
        case /EPI 1/.test(parameterName):
          epi[0] = parseFloat(paraValue);
          break;
        case /EPI 2/.test(parameterName):
          epi[1] = parseFloat(paraValue);
          break;
        case /PPI 1/.test(parameterName):
          ppi[0] = parseFloat(paraValue);
          break;
        case /PPI 2/.test(parameterName):
          ppi[1] = parseFloat(paraValue);
          break;
        case /PPI 3/.test(parameterName):
          ppi[2] = parseFloat(paraValue);
          break;
        case /PPI 4/.test(parameterName):
          ppi[3] = parseFloat(paraValue);
          break;
        case /Warp Crimp/.test(parameterName):
          warpCrimp = parseFloat(paraValue);
          break;
        case /Weft Crimp/.test(parameterName):
          weftCrimp = parseFloat(paraValue);
          break;
        case /Warp Waste/.test(parameterName):
          warpWaste = parseFloat(paraValue);
          break;
        case /Weft Waste/.test(parameterName):
          weftWaste = parseFloat(paraValue);
          break;
        case /Width/.test(parameterName):
          widthPerInch = parseFloat(paraValue);
          break;
      }
    });

    const calculateYarnReq = (count, epiOrPpi, widthFactor, crimp, waste) => {
      return ((epiOrPpi * widthFactor + 24 * 0) * 1.093614 / 840 / 2.205 / count * 100 / (100 - (crimp + waste + 0))) * 1000 || 0;
    };

    const yarnReqWarp1 = calculateYarnReq(warpActualCounts[0], epi[0], widthPerInch, warpCrimp, warpWaste);
    const yarnReqWarp2 = calculateYarnReq(warpActualCounts[1], epi[1], widthPerInch, warpCrimp, warpWaste);
    const yarnReqWeft1 = calculateYarnReq(weftActualCounts[0], ppi[0], widthPerInch, weftCrimp, weftWaste);
    const yarnReqWeft2 = calculateYarnReq(weftActualCounts[1], ppi[1], widthPerInch, weftCrimp, weftWaste);
    const yarnReqWeft3 = calculateYarnReq(weftActualCounts[2], ppi[2], widthPerInch, weftCrimp, weftWaste);
    const yarnReqWeft4 = calculateYarnReq(weftActualCounts[3], ppi[3], widthPerInch, weftCrimp, weftWaste);

    const yarnReqs = [yarnReqWarp1, yarnReqWarp2, yarnReqWeft1, yarnReqWeft2, yarnReqWeft3, yarnReqWeft4];
    const totalYarnReqAll = yarnReqs.reduce((acc, req) => acc + (isNaN(req) || req === Infinity ? 0 : req), 0).toFixed(2);

    setGLM(totalYarnReqAll || 0);
    setGSM((totalYarnReqAll / widthPerInch * 39.37 || 0).toFixed(2));
  }

  // const updatePropertiesTblRows = (rowData, event) => {
  //   debugger
  //   const ischecked = event.target.parentElement.parentElement.firstChild.children.selectProperty.checked;
  //   const checkedVal = event.target.parentElement.parentElement.firstChild.children.selectProperty.value;
  //   const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
  //   // if (ischecked) {
  //   let clickedColName = event.target.getAttribute('Headers');
  //   let enteredValue = event.target.value;
  //   let actualValidation = document.querySelector(`#${event.target.id}`);
  //   switch (clickedColName) {
  //     case 'product_parameter_sufix':
  //     case 'product_parameter_prefix':
  //       rowData[clickedColName] = enteredValue.toUpperCase();
  //       break;
  //     case 'product_parameter_calculation_type':
  //     case 'product_parameter_from_value':
  //     case 'product_parameter_to_value':
  //     case 'product_parameter_formula':
  //     case 'remark':
  //       rowData[clickedColName] = event.target.value;
  //       break;
  //     case 'product_parameter_value':
  //       rowData[clickedColName] = event.target.value;
  //       if (enteredValue === "") {
  //         actualValidation.parentElement.dataset.tip = 'Please Select atleast One...!';
  //       } else {
  //         delete actualValidation.parentElement.dataset.tip;

  //       }
  //       // const shortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')
  //       // if (shortName === "GF" || shortName === "FF") {
  //       //   generateFabricMaterialNameCode();
  //       // } else if (shortName === "YN") {
  //       //   generateYarnMaterialNameCode();
  //       // }
  //       break;
  //   }
  //   const detailData = [...productTypePropertiesData]
  //   detailData[arrayIndex] = rowData
  //   setProductTypePropertiesData(detailData);
  //   // } else {
  //   //   setErrMsg("Please Select Checkbox First...!")
  //   //   setShowErrorMsgModal(true)
  //   // }
  // }
  const updatePropertiesTblRows = (rowData, event) => {
    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'));
    let clickedColName = event.target.getAttribute('Headers');
    let enteredValue = event.target.value;

    // Update the row data based on the column
    switch (clickedColName) {
      case 'product_parameter_sufix':
      case 'product_parameter_prefix':
      case 'product_parameter_calculation_type':
      case 'product_parameter_from_value':
      case 'product_parameter_to_value':
      case 'remark':
      case 'product_parameter_value':
        rowData[clickedColName] = enteredValue;
        break;
    }

    // Automatically check/uncheck the checkbox based on the entered value
    const checkbox = document.getElementById(`selectProperty_${rowData.product_type_dynamic_controls_id}`);
    const hasValue = rowData.product_parameter_prefix || rowData.product_parameter_sufix ||
      rowData.product_parameter_calculation_type || rowData.product_parameter_from_value ||
      rowData.product_parameter_to_value || (rowData.product_parameter_value && rowData.product_parameter_value !== "0") || rowData.remark;

    checkbox.checked = hasValue ? true : false;

    // Update the state with the modified row data
    const detailData = [...productTypePropertiesData];
    detailData[arrayIndex] = rowData;
    setProductTypePropertiesData(detailData);
  };

  // Onchange event listener for the POTerm Selection
  $('.selectProperty').on('change', function (e) {
    const rowIndex = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
    if (this.checked) {
      if ($(`#product_parameter_value_${rowIndex}`).val() === '') {
        $(`#product_parameter_value_${rowIndex}`).focus();
      }
    } else {
      let parameterValueId = document.querySelector('#product_parameter_value_' + rowIndex);
      delete parameterValueId.parentElement.dataset.tip;
    }
    toggleChkAllBoxes('PartiallyPropertiesSelection');
  });

  //function to check All checkBoxes of po terms and payment terms
  function toggleChkAllBoxes(key) {
    switch (key) {
      case "selectAllProperties":
        $('.selectProperty').prop('checked', $('#selectAllProperties').is(":checked"));
        break;
      case 'PartiallyPropertiesSelection':
        $('#selectAllProperties').prop('checked', $('input:checkbox.selectProperty:checked').length == $('input:checkbox.selectProperty').length);
        break;
    }
  }




  const validateCheckboxesBeforeSave = () => {

    let isValid = true;

    productTypePropertiesData.forEach((row, index) => {
      const isChecked = document.getElementById(`selectProperty_${row.product_type_dynamic_controls_id}`).checked;

      // Check if any value in the row is filled
      const hasValue = row.product_parameter_prefix || row.product_parameter_sufix || row.product_parameter_calculation_type ||
        row.product_parameter_from_value || row.product_parameter_to_value || (row.product_parameter_value && row.product_parameter_value !== "0") ||
        row.remark;
      if (hasValue && !isChecked) {
        isValid = false;
      }
    });

    if (!isValid) {
      setErrMsg("Please check the checkbox for Product Based Properties.");
      setShowErrorMsgModal(true);
    }

    return isValid; // Return the validation result
  };


  //-------------------------------------------------- Product Properties data Ends ------------------------------------------------------------------------------------

  // cost center changes by vishal

  const FnShowCostCenter = async () => {
    if (cost_centerOptions.length > 0) {
      await FnCreateCostCenterChks();

      // Assuming total_GlCode_chechboxes is a jQuery object containing the checkboxes
      if (keyForViewUpdate === '') {
        $('.Cost_center_checkboxes input[value="1"]').prop('checked', true);

      }

      // Also check the already checked checkboxes.
      if (cmb_cost_center !== '') {
        const costCenterIds = cmb_cost_center.split(':');
        costCenterIds.forEach(function (contactId, index) {
          $('#costCenterCheckBox_' + costCenterIds[index]).prop('checked', true);
        });
        FnToggleCheckBoxes('PartiallyCheckCostCenter');
      }


      let checkBoxesContainer = document.getElementById("gl_code_ul");
      if (!expandedCostCenterCombo) {

        checkBoxesContainer.style.display = "block";
        expandedCostCenterCombo = true;

      } else {

        checkBoxesContainer.style.display = "none";
        expandedCostCenterCombo = false;
      }

      $('.Cost_center_checkboxes').on('input blur', function (e) {
        FnGetSelectedCostCenterIds();
      });

    }
  }
  const FnToggleCheckBoxes = (checkboxType) => {
    switch (checkboxType) {
      case "CheckAllCostCenter":
        $('.costCenterCheckBox').prop('checked', $('#CheckAllCostCenter').is(":checked"));
        setTotalSelectedCostCenterCheckboxes($('input:checkbox.costCenterCheckBox:checked').length);
        // setCustomerGLCodes
        break;

      case 'PartiallyCheckCostCenter':
        $('#CheckAllCostCenter').prop('checked', $('input:checkbox.costCenterCheckBox:checked').length === $('input:checkbox.costCenterCheckBox').length);
        setTotalSelectedCostCenterCheckboxes($('input:checkbox.costCenterCheckBox:checked').length);
        break;

      default:
        break;
    }
  }

  const FnGetSelectedCostCenterIds = () => {
    let selectedGLCodes = '';
    const checkboxes = $('.costCenterCheckBox:checked');
    checkboxes.each(function () {
      if ($(this).val() !== '0' && $(this).val() !== '') {
        selectedGLCodes += $(this).val() + ":";
      }
    });
    setCostCenter(selectedGLCodes.replace(/:$/, ''));
    return selectedGLCodes.replace(/:$/, '');
  }


  const FnCreateCostCenterChks = async () => {
    if (cost_centerOptions.length !== 0) {
      let checkboxes = cost_centerOptions.map((item, index) => {
        if (index === 0) {
          return (
            <>
              <li className="item Cost_center_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" id="CheckAllCostCenter" value={index} className="erp_radio_button"
                    onChange={(event) => FnToggleCheckBoxes('CheckAllCostCenter')} {...(keyForViewUpdate === "view" ? { disabled: true } : {})} />
                </span>
                <span className="item-text">All</span>
              </li>
              <li className="item Cost_center_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" name="costCenterCheckBox" value={item.field_id} {...(keyForViewUpdate === "view" ? { disabled: true } : {})}
                    id={`costCenterCheckBox_${item.field_id}`} className="erp_radio_button costCenterCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckCostCenter')}
                  />
                </span>
                <span className="item-text">[{item.cost_center_short_name}]{item.field_name}</span>
              </li>
            </>
          );
        }
        return (
          <li className="item Cost_center_checkboxes">
            <span className="checkbox">
              <input type="checkbox" name="costCenterCheckBox" value={item.field_id} id={`costCenterCheckBox_${item.field_id}`} {...(keyForViewUpdate === "view" ? { disabled: true } : {})}
                className="erp_radio_button costCenterCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckCostCenter')}
              />
            </span>
            <span className="item-text">[{item.cost_center_short_name}]{item.field_name}</span>
          </li>
        );
      });
      setCostCenterCheckboxes(checkboxes);
    }
  }



  // const shortNames = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')



  //****************************************************code for live search ********************************************************************* */
  const updateKeysForSearchBoxProfitCenter = (apiData) => {
    const options = [
      { value: '', label: 'Select', centerShortName: 'false' },
      { value: '0', label: 'Add New Record+', centerShortName: 'false' },
      ...apiData.map(items => ({
        value: items.field_id,
        label: `[${items.profit_center_short_name}] ${items.field_name}`,
        centerShortName: items.profit_center_short_name,
      }))
    ];
    return options;
  }


  const updateKeysForSearchBox = (apiData) => {
    const options = [
      { value: '', label: 'Select' },
      { value: '0', label: 'Add New Record+' },
      ...apiData.map(items => ({
        value: items.field_id,
        label: items.field_name,

      }))
    ];
    return options;
  }



  // const options = [
  //   { value: ' ', label: 'Select', },
  //   { value: '0', label: 'Add New Record+', },

  //   ...productCategory1Options.map(productCategory1 => ({
  //     value: productCategory1.field_id,
  //     label: productCategory1.field_name,

  //   })),

  //   ...category2Options.map(category2 => ({
  //     value: category2.field_id,
  //     label: category2.field_name,

  //   })),

  //   // ...category3Options.map(category3 => ({
  //   //   value: category3.field_id,
  //   //   label: category3.field_name,

  //   // })),

  //   // ...category4Options.map(category4 => ({
  //   //   value: category4.field_id,
  //   //   label: category4.field_name,

  //   // })),

  //   // ...category5Options.map(category5 => ({
  //   //   value: category5.field_id,
  //   //   label: category5.field_name,

  //   // })),

  //   ...hsnSacCodeOptions.map(HsnSacCode => ({
  //     value: HsnSacCode.field_id,
  //     label: HsnSacCode.field_name,
  //   })),

  //   ...profit_centerOptions.map(profitCenter => ({
  //     value: profitCenter.field_id,
  //     label: `[${profitCenter.profit_center_short_name}] ${profitCenter.field_name}`,
  //     centerShortName: profitCenter.profit_center_short_name, // Use 'data' key to store custom data
  //   })),

  // ];


  const handleChange = async (selectedOption, selectId) => {
    switch (selectId) {
      case 'cmb_product_category1_id':
        if (cmb_product_category1_id !== '0' & cmb_product_category1_id !== '') {
          $('#error_cmb_product_category1_id').hide();
        }
        //setProductCategory1Id(selectedOption.value);
        setProductCat1Text(selectedOption.label);
        await comboOnChangeforSearch('productCategory1', selectedOption.value);
        break;

      case 'cmb_product_category2_id':
        if (cmb_product_category2_id !== '0' && cmb_product_category2_id !== '') {
          $(`#error_cmb_product_category2_id`).hide();
        }
        setPCategory2Id(selectedOption.value);
        setProductCat2Text(selectedOption.label);
        await comboOnChangeforSearch('productCategory2', selectedOption.value);
        break;

      // case 'cmb_product_category3_id':
      //   if (cmb_product_category3_id !== '0' && cmb_product_category3_id !== '') {
      //     $(`#error_cmb_product_category3_id`).hide();
      //   }
      //   setPCategory3Id(selectedOption.value);
      //   setProductCat3Text(selectedOption.label);
      //   await comboOnChangeforSearch('productCategory3', selectedOption.value);
      //   break;

      // case 'cmb_product_category4_id':
      //   if (cmb_product_category4_id !== '0' && cmb_product_category4_id !== '') {
      //     $(`#error_cmb_product_category4_id`).hide();
      //   }
      //   setPCategory4Id(selectedOption.value);
      //   setProductCat4Text(selectedOption.label);
      //   await comboOnChangeforSearch('productCategory4', selectedOption.value);
      //   break;

      // case 'cmb_product_category5_id':
      //   if (cmb_product_category5_id !== '0' && cmb_product_category5_id !== '') {
      //     $(`#error_cmb_product_category5_id`).hide();
      //   }
      //   setPCategory5Id(selectedOption.value);
      //   setProductCat5Text(selectedOption.label);
      //   await comboOnChangeforSearch('productCategory5', selectedOption.value);
      //   break;

      case 'cmb_fg_hsn_sac_code_id':
        debugger
        if (cmb_fg_hsn_sac_code_id !== '0' && cmb_fg_hsn_sac_code_id !== '') {
          $(`#error_cmb_fg_hsn_sac_code_id`).hide();
        }
        //setFgHsnSacCodeId(selectedOption.value);
        await comboOnChangeforSearch('hsnSacCode', selectedOption.value);
        break;

      case 'cmb_profit_center':
        if (cmb_profit_center !== '0' && cmb_profit_center !== '') {
          $(`#error_cmb_profit_center`).hide();
        }

        const profitCenterShortName = selectedOption?.centerShortName; // Use optional chaining to avoid errors
        if (profitCenterShortName) {
          setProfitCenterforRing(profitCenterShortName);
          setProfitCenter(selectedOption.value);
          await comboOnChangeforSearch('profit_center', selectedOption.value);
        }



      default:
        break;
    }
  };

  // userefs for search combo 
  let category1ComboRef = useRef(null);
  let category2ComboRef = useRef(null);
  let profitCenterComboRef = useRef(null);
  let costCenterComboRef = useRef(null);
  let hsnsacComboRef = useRef(null);

  const handleSubmit = async () => {
    debugger
    try {
      // setIsLoading(true)
      // const checkIsValidate = await validate.current.validateForm('CountFormID');
      if (!(await validationRef.current.validateForm('CountFormID'))) {
        return false;
      }

      // if (checkIsValidate === true) {
      let SpinningProdCountData = {
        TransHeaderData: {}, SpinningProdCountLevelData: [], 'commonIds': {
          'company_id': COMPANY_ID
        }
      }

      ///Master Data
      var activeValue = '';
      var active;
      activeValue = document.querySelector('input[name=isactive]:checked').value
      switch (activeValue) {
        case '1': active = true; break;
        case '0': active = false; break;
        default: break;
      }
      const data = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        created_by: UserName,
        production_count_id: 0,
        // modified_by: 0 === 0 ? null : UserName,
        production_mixing: 0,
        production_count_desc: txt_Count_Desc,
        production_count_name: txt_Count_name,
        production_actual_count: txt_Actual_Count,
        // count_actual_grams: txt_Actual_GMS,
        count_40s_conversion_factor: 0,

        count_ukg_conversion_factor: 0,
        count_weaving_cone_weight: 0,
        count_single_cone_weight: 0,
        count_tfo_cone_weight: 0,
        count_std_doffing_time: 0,
        count_std_doffing_loss_percent: 0,
        count_std_cop_contents_gms: 0,
        count_std_hard_waste_percent: 0,
        is_active: active,
      };
      SpinningProdCountData.TransHeaderData = data;

      const formData = new FormData();
      formData.append(`SpinningProdCountData`, (JSON.stringify(SpinningProdCountData)))
      const requestOptions = {
        method: 'POST',
        body: formData
      };
      const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmSpinningProdCount/FnAddUpdateRecord`, requestOptions)
      const responce = await apicall.json()
      console.log("response error: ", responce);
      if (responce.success === 0) {
        console.log("response error: ", responce.error);
        setErrMsg(responce.error)
        setShowErrorMsgModal(true)
      } else {

        setSuccMsg(responce.message)
        setShowProdSuccessMsgModal(true);
        clearFormFields();
      }
      // }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }

  };

  return (
    <>
      <GenerateMaterialId ref={generateMaterialIdAPiCall} />
      <ComboBox ref={comboBoxRef} />
      <FrmValidations ref={validationRef} />
      <ValidateNumberDateInput ref={validatePercentage} />

      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress color="primary" />
            <span id="spinner_text" className="text-dark">Loading...</span>
          </div>
        </div> :
        null}
      <form id="productFgFormId">

        <div className='row'>
          {/* first */}
          <div className='col-sm-4 erp_form_col_div'>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Product Type : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={(e) => comboOnChangeforSearch('productType')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {productTypeOptions?.map(productType => (
                    <option value={productType.field_id} ptShortName={productType.product_type_short_name}>{productType.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            {/* live search chnages by ujjwala on 2/5/2024  */}
            {/* <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Category 1 :</Form.Label>
              </div>
              <div className='col'>
                <Select
                  inputId="cmb_product_category1_id"
                  ref={category1ComboRef}
                  value={productCategory1Options.find(option => option.value == cmb_product_category1_id)}
                  options={productCategory1Options}
                  isDisabled={keyForViewUpdate === '' || keyForViewUpdate === 'Add' ? false : true}
                  onChange={(selectedOption) => {
                    setProductcategory1Id(selectedOption.value)
                    category1ComboRef.current = selectedOption;
                    handleChange(selectedOption, 'cmb_product_category1_id');
                  }}
                  placeholder="Search for Product Category 1..."
                  className="form-search-custom"
                  classNamePrefix="custom-select"
                  styles={{

                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    })

                  }}
                />

                <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            {/* <div className='row '>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Category 2 : </Form.Label>
              </div>
              <div className='col'>
                <Select ref={category2ComboRef}
                  inputId="cmb_product_category2_id"
                  value={category2Options.find(option => option.value === cmb_product_category2_id) || ''}
                  options={category2Options}
                  isDisabled={keyForViewUpdate === '' || keyForViewUpdate === 'Add' ? false : true}
                  onChange={(selectedOption) => {
                    setPCategory2Id(selectedOption.value);
                    category2ComboRef.current = selectedOption;
                    comboOnChangeforSearch('productCategory2', selectedOption.value);
                  }}
                  placeholder="Search for Product Category 2..."
                  className="form-search-custom"
                  classNamePrefix="custom-select"
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    })
                  }}
                />

                <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            {/* <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Product Category 1 : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_product_category1_id" className="form-select form-select-sm" value={cmb_product_category1_id} onChange={(e) => comboOnChange('productCategory1')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {productCategory1Options?.map(productCategory1 => (
                    <option value={productCategory1.field_id}>{productCategory1.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Material ID : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="product_fg_id" className="erp_input_field" value={product_fg_id} maxLength="255" disabled />
                <MDTypography variant="button" id="error_product_fg_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">FG Code :  </Form.Label>
              </div>

              <div className='col'>
                <Form.Control type="text" id="txt_fg_code" className="erp_input_field" value={txt_product_fg_code} onChange={e => { setFGCode(e.target.value); validateFields(); FnGenerateTechSpecName(); }} maxLength="255" disabled={keyForViewUpdate === '' || keyForViewUpdate === 'Add' ? false : true} />
                {/* disabled={shortNames === "GF" || shortNames === "FF"} */}
                <MDTypography variant="button" id="error_txt_rm_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">FG Name : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_fg_name" className="erp_input_field" value={txt_product_fg_name} onChange={e => { setFGName(e.target.value); validateFields(); FnGenerateTechSpecName(); }} maxLength="500" />
                <MDTypography variant="button" id="error_txt_rm_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Short Name : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_short_name" className="erp_input_field" value={txt_fg_short_name} onChange={e => { setFGShortName(e.target.value.toUpperCase()); validateFields() }} maxLength="50" optional='optional' />
                <MDTypography variant="button" id="error_txt_rm_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Print Name : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_print_name" className="erp_input_field" value={txt_fg_print_name} onChange={e => { setFGPrintName(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_print_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
          </div>

          {/* second */}


          <div className='col-sm-4 erp_form_col_div'>
            {/* <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Profit Center :</Form.Label>
              </div>
              <div className="col">
                <Select ref={profitCenterComboRef}
                  inputId="cmb_profit_center"
                  value={profit_centerOptions.find(option => option.value === cmb_profit_center || null)}
                  options={profit_centerOptions}
                  isDisabled={keyForViewUpdate === 'view' ? true : false}
                  onChange={(selectedOption) => {
                    setProfitCenter(selectedOption.value)
                    // handleChange(selectedOption, 'cmb_profit_center');
                    profitCenterComboRef.current = selectedOption;
                    comboOnChangeforSearch("profit_center", selectedOption.value)
                    validateFields();
                  }}
                  placeholder="Search for Profit Center..."
                  className="form-search-custom"
                  classNamePrefix="custom-select"


                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    })
                  }}
                />

                <MDTypography variant="button" id="error_cmb_profit_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            {/* <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Profit Center:</Form.Label>
              </div>
              <div className="col">
                <select size="sm" id="cmb_profit_center" value={cmb_profit_center} className="form-select form-select-sm erp_input_field" onChange={() => { comboOnChange('profit_center'); }} optional="optional" >
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {profit_centerOptions?.map(option => (
                    <option value={option.field_id} centerShortName={option.profit_center_short_name}>[{option.profit_center_short_name}] {option.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_profit_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            {/* <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Cost Center:</Form.Label>
              </div>
              <div className="col">
                <select size="sm" id="cmb_cost_center" value={cmb_cost_center} className="form-select form-select-sm erp_input_field" onChange={() => { comboOnChange('cost_center'); }} optional="optional" >
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {cost_centerOptions?.map(option => (
                    <option value={option.field_id} centerShortName={option.cost_center_short_name}>[{option.cost_center_short_name}] {option.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_cost_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}


            {/* <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Cost Center : <span className="required">*</span></Form.Label>
              </div>
              <div className="col">
                <Select ref={costCenterComboRef}
                  inputId="cmb_cost_center"
                  isDisabled={keyForViewUpdate === 'view' ? true : false}
                  value={cost_centerOptions.find(option => option.value === cmb_cost_center) || null}
                  options={cost_centerOptions}
                  onChange={(selectedOption) => {
                    // handleChange(selectedOption, 'cmb_cost_center');
                    setCostCenter(selectedOption.value)
                    costCenterComboRef.current = selectedOption;
                    comboOnChangeforSearch("cost_center", selectedOption.value)
                    validateFields();
                  }
                  }
                  placeholder="Search for Cost Center..."
                  className="form-search-custom"
                  classNamePrefix="custom-select"

                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    })
                  }}
                />

                <MDTypography variant="button" id="error_cmb_cost_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>*/}


            {/* <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Routing Code : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_routing_code" className="erp_input_field " value={txt_routing_code} optional="optional" />
              </div>
            </div> */}
            <div className='row d-none'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Item Sr No. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_item_sr_no" className="erp_input_field" value={txt_fg_item_sr_no} onChange={e => { setFGItemSrNo(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_item_sr_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row d-none'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Item Drawing No. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_drawing_no" className="erp_input_field" value={txt_fg_drawing_no} onChange={e => { setFGDrawingNo(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_drawing_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row d-none'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Model No. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_model_no" className="erp_input_field" value={txt_fg_model_no} onChange={e => { setFGModelNo(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_model_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">HSN-SAC Code : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Select
                  inputId="cmb_fg_hsn_sac_code_id"
                  ref={hsnsacComboRef}
                  value={hsnSacCodeOptions.find(option => option.value === cmb_fg_hsn_sac_code_id)}
                  isDisabled={keyForViewUpdate === 'view' ? true : false}
                  options={hsnSacCodeOptions}
                  onChange={(selectedOption) => handleChange(selectedOption, 'cmb_fg_hsn_sac_code_id')}
                  placeholder="Search for HSN-SAC Code..."
                  className="form-search-custom"
                  classNamePrefix="custom-select"

                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      fontSize: '12px'
                    })
                  }}
                />
                <MDTypography variant="button" id="error_cmb_fg_hsn_sac_code_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            {/* <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">HSN-SAC Code : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_hsn_sac_code_id" className="form-select form-select-sm" value={cmb_fg_hsn_sac_code_id} onChange={() => comboOnChange('hsnSacCode')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {hsnSacCodeOptions?.map(HsnSacCode => (
                    <option value={HsnSacCode.field_id}>{HsnSacCode.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_fg_hsn_sac_code_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            {/* <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Purchase Unit : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_purchase_unit_id" className="form-select form-select-sm" value={cmb_fg_purchase_unit_id} onChange={() => comboOnChange('puchaseUnit')} optional='optional'>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {unitOptions?.map(units => (
                    <option value={units.field_id}>{units.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_fg_purchase_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Sales Unit : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_sales_unit_id" className="form-select form-select-sm" value={cmb_fg_sales_unit_id} onChange={() => comboOnChange('salesUnit')} optional='optional'>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {unitOptions?.map(units => (
                    <option value={units.field_id}>{units.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_fg_sales_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Stock Unit : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_stock_unit_id" className="form-select form-select-sm" value={cmb_fg_stock_unit_id} onChange={() => { comboOnChange('stockUnit'); validateFields() }}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {unitOptions?.map(units => (
                    <option value={units.field_id}>{units.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_fg_stock_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Packing : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_packing_id" className="form-select form-select-sm" value={cmb_fg_packing_id} onChange={() => { comboOnChange('packingId'); validateFields() }}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {productPackingOptions?.map(productPackings => (
                    <option value={productPackings.field_id}>{productPackings.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_fg_packing_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Bar Code : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_bar_code" className="erp_input_field" value={txt_fg_bar_code} onChange={e => { setFGBarCode(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_bar_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">BOM Applicable : </Form.Label>
              </div>
              <div className="col">
                <div className="erp_form_radio">
                  <div className="fCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="Yes"
                      type="radio"
                      value="Yes" checked={is_BOMApplicable === 'Yes'} onClick={() => { setBOMApplicable('Yes'); }}
                      name="isBOMApplicable"
                      defaultChecked
                    />
                  </div>
                  <div className="sCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="No"
                      value="No" checked={is_BOMApplicable === 'No'} onClick={() => { setBOMApplicable('No'); }}
                      type="radio"
                      name="isBOMApplicable"
                    />
                  </div>
                </div>
              </div>
            </div> */}


          </div>

          {/* Third */}
          <div className='col-sm-4 erp_form_col_div'>

            <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">QR Code : </Form.Label>
              </div>
              <div className="col">
                <Form.Control type="file" id="file_rm_qr_code" className="erp_input_field" onChange={onFileUpload} optional="optional" accept="image/*" />
                <MDTypography component="label" className={`erp-form-label ${file_fg_qr_codeName !== '' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" color="info"  >
                  <Link to="#" onClick={fetchQrCode}>{file_fg_qr_codeName}</Link>

                </MDTypography>
                <MDTypography variant="button" id="error_file_rm_qr_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Form.Label className="erp-form-label">Godown  : </Form.Label>
              </div>
              <div className='col-sm-7 col-10'>
                <select id="cmb_godown_id" className="form-select form-select-sm" value={cmb_godown_id} onChange={(e) => { setgodownid(e.target.value); comboOnChange('godown'); }} optional='optional' >
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {godownidOptions?.map(godownid => (
                    <option value={godownid.field_id}>{godownid.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_godown_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
              <div className={`col-sm-1 col-2 ${keyForViewUpdate === 'delete' || keyForViewUpdate === 'view' ? 'd-none' : ' display'}`}>
                <Tooltip title="Refresh" placement="top">
                  <MDTypography>
                    <MdRefresh id="btn_refresh" onClick={() => FnRefreshbtn("godown_id")} style={{ color: 'black' }} />
                  </MDTypography>
                </Tooltip>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Form.Label className="erp-form-label">Godown Section : </Form.Label>
              </div>
              <div className='col-sm-7 col-10'>
                <select id="cmb_godown_section_id" className="form-select form-select-sm" value={cmb_godown_section_id} onChange={(e) => { setgodownsectionid(e.target.value); comboOnChange('godownsection'); }} optional='optional'>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {godownidSectionOptions?.map(godownsection => (
                    <option value={godownsection.field_id}>{godownsection.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_godown_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>

              <div className={`col-sm-1 col-2 ${keyForViewUpdate === 'delete' || keyForViewUpdate === 'view' ? 'd-none' : ' display'}`}>
                <Tooltip title="Refresh" placement="top">
                  <MDTypography>
                    <MdRefresh id="btn_refresh" onClick={() => FnRefreshbtn("godown_Section")} style={{ color: 'black' }} />
                  </MDTypography>
                </Tooltip>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Form.Label className="erp-form-label">Godown Sec. Beans : </Form.Label>
              </div>
              <div className='col-sm-7 col-10'>
                <select id="cmb_godown_section_beans_id" className="form-select form-select-sm" value={cmb_godown_section_beans_id} onChange={(e) => { setgodownsectionbeansid(e.target.value); comboOnChange('godownsectionbeans'); }} optional='optional' >
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {godownsectionbeansidOptions?.map(godownsectionbeans => (
                    <option value={godownsectionbeans.field_id}>{godownsectionbeans.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_godown_section_beans_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
              <div className={`col-sm-1 col-2 ${keyForViewUpdate === 'delete' || keyForViewUpdate === 'view' ? 'd-none' : ' display'}`}>
                <Tooltip title="Refresh" placement="top">
                  <MDTypography>
                    <MdRefresh id="btn_refresh" onClick={() => FnRefreshbtn("godown_Section_Beans")} style={{ color: 'black' }} />
                  </MDTypography>
                </Tooltip>
              </div>
            </div>

            {/* <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Remark : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control as="textarea" rows={1} id="txt_remarkForProductEntry" className="erp_input_field" value={txt_remarkForProductEntry} onChange={e => { setRemarkForProductEntry(e.target.value); validateFields() }} maxLength="255" optional="optional" />
                <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">QA Remark : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control as="textarea" rows={1} id="txt_qa_remark" className="erp_input_field" value={txt_qa_remark} onChange={e => { setQaRemark(e.target.value); validateFields() }} maxLength="255" optional="optional" />
                <MDTypography variant="button" id="error_txt_qa_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Is Active : </Form.Label>
              </div>
              <div className="col">
                <div className="erp_form_radio">
                  <div className="fCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="Yes"
                      type="radio"
                      value="true" checked={is_activeGen === true} onClick={() => { setIsActiveGen(true); }}
                      name="isProductFGActive"
                      defaultChecked
                    />
                  </div>
                  <div className="sCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="No"
                      value="false" checked={is_activeGen === false} onClick={() => { setIsActiveGen(false); }}
                      type="radio"
                      name="isProductFGActive"
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </form >
      <Accordion defaultActiveKey="0" className="mt-1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="erp-form-label-md">Product Based Properties</Accordion.Header>
          <Accordion.Body>
            <>
              <div className={`row py-1 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                <div className={`col-12 col-sm-6`}>
                  <input type='checkbox' class={`selectAllProperties  ${productTypePropertiesData.length !== 0 ? 'display' : 'd-none'}`} id="selectAllProperties" onClick={(e) => toggleChkAllBoxes('selectAllProperties')} /> <label class="erp-form-label pb-1"> Select All </label>
                </div>
                <div className={`col-12 col-sm-6 ${cmb_product_type_id === '' || cmb_product_type_id === '0' ? 'd-none' : 'display'}`}>
                  <MDButton type="button" className={`erp-gb-button float-sm-end col-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                    onClick={() => {
                      sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                      setHeaderName('Product Properties'); // CommonParamterEntry
                      setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                    }}>Add
                  </MDButton>
                </div>
              </div>
            </>
            {productTypePropertiesData.length !== 0 ? (
              <>
                {renderProductPropertiesTbl}
              </>
            ) : (
              <div className='row text-center'>
                <div className="col-12">
                  <span className="erp_validation text-center" fontWeight="regular" color="error">
                    {cmb_product_type_id === "" || cmb_product_type_id === "0" ? "Please Select Product Type...!" : "No records found..!"}
                  </span>
                </div>
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />
      <Accordion defaultActiveKey="0" style={{ display: 'none' }} >
        <Accordion.Item eventKey="TechnicalInformation">
          <Accordion.Header className="erp-form-label-md">Technical Information</Accordion.Header>
          <Accordion.Body>
            <form id="technicalEntryFormId">
              <div className='row'>
                <div className='col-sm-6 erp_form_col_div'>
                  {/* 
                  <div className='row d-none'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Category 2 : <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Select
                        inputId="cmb_product_category2_id"
                        value={category2Options.find(option => option.value === cmb_product_category2_id)}
                        options={category2Options}

                        onChange={(selectedOption) => handleChange(selectedOption, 'cmb_product_category2_id')}
                        placeholder="Search for Product Category 2..."
                        className="form-search-custom"
                        classNamePrefix="custom-select"
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          input: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          })
                        }}
                      />

                      <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>


                  <div className='row d-none'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Category 3 : <span className="required">*</span></Form.Label>
                    </div>

                    <div className='col'>
                      <Select
                        inputId="cmb_product_category3_id"
                        value={category3Options.find(option => option.value === cmb_product_category3_id)}
                        options={category3Options}
                        onChange={(selectedOption) => handleChange(selectedOption, 'cmb_product_category3_id')}
                        placeholder="Search for Product Category 3..."
                        classNamelassName="form-search-custom"
                        classNamePrefix="custom-select"
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          input: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cmb_product_category3_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row d-none'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Category 4 : <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Select
                        inputId="cmb_product_category4_id"
                        value={category4Options.find(option => option.value === cmb_product_category4_id)}
                        options={category4Options}
                        onChange={(selectedOption) => handleChange(selectedOption, 'cmb_product_category4_id')}
                        placeholder="Search for Product Category 4..."
                        className="form-search-custom"
                        classNamePrefix="custom-select"
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          input: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          })
                        }}
                      />

                      <MDTypography variant="button" id="error_cmb_product_category4_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}


                  {/* <div className='row d-none'>  
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Category 5 : <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Select
                        inputId="cmb_product_category5_id"
                        value={category5Options.find(option => option.value === cmb_product_category5_id)}
                        options={category5Options}
                        onChange={(selectedOption) => handleChange(selectedOption, 'cmb_product_category5_id')}
                        placeholder="Search for Product Category 5..."
                        className="form-search-custom"
                        classNamePrefix="custom-select"

                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          input: (provided, state) => ({
                            ...provided,
                            fontSize: '12px'
                          })
                        }}
                      />

                      <MDTypography variant="button" id="error_cmb_product_category5_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  {/* <div className='row' id="product_category2_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat1Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category2_id" className="form-select form-select-sm" value={cmb_product_category2_id} onChange={(e) => comboOnChange('productCategory2')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {category2Options?.map((category2) => (
                          <option value={category2.field_id}>{category2.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  {/* <div className='row' id="product_category3_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat2Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category3_id" className="form-select form-select-sm" value={cmb_product_category3_id} onChange={(e) => comboOnChange('productCategory3')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {category3Options?.map((category3) => (
                          <option value={category3.field_id}>{category3.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category3_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  {/* <div className='row' id="product_category4_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat3Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category4_id" className="form-select form-select-sm" value={cmb_product_category4_id} onChange={(e) => comboOnChange('productCategory4')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {category4Options?.map((category4) => (
                          <option value={category4.field_id}>{category4.field_name}</option>
                        ))}

                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category4_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  {/* <div className='row' id="product_category5_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat4Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category5_id" className="form-select form-select-sm" value={cmb_product_category5_id} onChange={(e) => comboOnChange('productCategory5')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {category5Options?.map(category5 => (
                          <option value={category5.field_id}>{category5.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category5_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Type : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_type_id" className="form-select form-select-sm" value={cmb_product_material_type_id} onChange={() => comboOnChange('productMaterialType')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialTypeOptions?.map(productMaterialType => (
                          <option value={productMaterialType.field_id}>{productMaterialType.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Grade : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_grade_id" className="form-select form-select-sm" value={cmb_product_material_grade_id} onChange={() => comboOnChange('productMaterialGrade')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialGradeOptions?.map(productMaterialGrade => (
                          <option value={productMaterialGrade.field_id}>{productMaterialGrade.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_grade_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Technical Name : <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_technical_name" className="erp_input_field" value={txt_product_fg_technical_name} onChange={e => { setPTechnivalName(e.target.value); validateFields() }} disabled maxLength="500" />
                      <MDTypography variant="button" id="error_txt_product_rm_technical_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Shape : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_shape_id" className="form-select form-select-sm" value={cmb_product_material_shape_id} onChange={() => comboOnChange('productMaterialShape')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialShapeOptions?.map(productMaterialShape => (
                          <option value={productMaterialShape.field_id}>{productMaterialShape.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_shape_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                </div>

                <div className='col-sm-6 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Color : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_colour" className="form-select form-select-sm" value={cmb_product_material_colour} onChange={() => comboOnChange('productMaterialColor')} optional='optional'>
                        <option value="">Select</option>
                        {pMaterialColorOptions?.map(productMaterialColor => (
                          <option value={productMaterialColor.field_name}>{productMaterialColor.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_colour" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Alternate FG : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_alternate_FG_id" className="form-select form-select-sm" value={cmb_product_alternate_fg_id} onChange={() => comboOnChange('productAltrFG')} optional='optional'>
                        <option value="">select</option>
                        {pAlternateMaterialOptions?.map(productAltrMaterial => (
                          <option value={productAltrMaterial.product_fg_id}>{productAltrMaterial.product_fg_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_alternate_FG_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">GLM</Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id="txt_glm" className="erp_input_field erp_align-right" value={txt_glm} onChange={e => { validateNo('GLM'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_glm" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} > </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label"> GSM</Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id="txt_gsm" className="erp_input_field erp_align-right" value={txt_gsm} onChange={e => { validateNo('GSM'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_gsm" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} > </MDTypography>
                    </div>
                  </div>

                  {/* <div className="row">
                      <div className="col-sm-4">
                          <Form.Label className="erp-form-label"> No. Of Ends</Form.Label>
                      </div>
                      <div className="col">
                          <Form.Control type="text" id="txt_no_of_ends" className="erp_input_field erp_align-right"  />
                          <MDTypography variant="button" id="error_txt_no_of_ends" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} > </MDTypography>
                      </div>
                  </div> 

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Assembly Scrap Percent(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_assembly_scrap_percent" className="erp_input_field text-end" value={txt_assembly_scrap_percent} onChange={e => { percentValidate(e.target.value, 'AssemblyScrap'); validateFields() }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_assembly_scrap_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Remark : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={1} id="txt_remarkForTechnicalEntry" className="erp_input_field" value={txt_remarkForTechnicalEntry} onChange={e => { setRemarkForTechnicalEntry(e.target.value); validateFields() }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Is Active : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_activeTech === true} onClick={() => { setIsActiveTech(true); }}
                            name="isTechnicalActive"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_activeTech === false} onClick={() => { setIsActiveTech(false); }}
                            type="radio"
                            name="isTechnicalActive"
                          />
                        </div>
                      </div>
                    </div>
                  </div>*/}

                </div>
              </div>
            </form>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <hr /> */}

      {/* Commercial Info Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>*/}
      {/* changes by tushar */}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="CommercialInformation">
          <Accordion.Header className="erp-form-label-md">Commercial Information</Accordion.Header>
          <Accordion.Body>
            <form id="commercialEntryFormId">
              <div className='row'>
                <div className='col-sm-6 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Gross Weight : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_gross_weight" className="erp_input_field erp_align-right" value={txt_product_fg_gross_weight} onChange={e => { validateNo('PGrossWeight') }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_gross_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Net Weight : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_net_weight" className="erp_input_field erp_align-right" value={txt_product_fg_net_weight} onChange={e => { validateNo('PNetWeight'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_net_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Std Weight : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_std_weight" className="erp_input_field erp_align-right" value={txt_product_fg_std_weight} onChange={e => { validateNo('PStdWeight'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_std_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Volume : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_volume" className="erp_input_field erp_align-right" value={txt_product_fg_volume} onChange={e => { validateNo('PVolume'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_volume" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Price : </Form.Label>{/* MAX.RETAIL PRICE */}
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_mrp" className="erp_input_field erp_align-right" value={txt_product_fg_mrp} onChange={e => { validateNo('PMrp'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_mrp" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Landed Price : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_landed_price" className="erp_input_field erp_align-right" value={txt_product_fg_landed_price} onChange={e => { validateNo('PLandedPrice'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_landed_price" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Average Price : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_avg_price" className="erp_input_field erp_align-right" value={txt_product_fg_avg_price} onChange={e => { validateNo('PAvgPrice'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_avg_price" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Standard Profit(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_product_rm_std_profit_percent" className="erp_input_field erp_align-right" value={txt_product_fg_std_profit_percent} onChange={e => { percentValidate(e.target.value, 'StandardProfit') }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_std_profit_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Standard Discount(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_product_rm_std_discount_percent" className="erp_input_field erp_align-right" value={txt_product_fg_std_discount_percent} onChange={e => { percentValidate(e.target.value, 'PDiscountPercent'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_std_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min.Order Quantity : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_moq" className="erp_input_field erp_align-right" value={txt_product_fg_moq} onChange={e => { validateNo('PMoq'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_moq" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min.Order Value : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_mov" className="erp_input_field erp_align-right" value={txt_product_fg_mov} onChange={e => { validateNo('PMov'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_mov" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Economical Order Quantity : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_eoq" className="erp_input_field erp_align-right" value={txt_product_fg_eoq} onChange={e => { validateNo('PEoq'); }} maxLength="10" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_eoq" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min.Production Quantity : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_mpq" className="erp_input_field erp_align-right" value={txt_product_fg_mpq} onChange={e => { validateNo('PMpq'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_mpq" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>

                <div className='col-sm-6 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min. Stock Level : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_min_stock_level" className="erp_input_field erp_align-right" value={txt_product_fg_min_stock_level} onChange={e => { validateNo('PMinStockLevel'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_min_stock_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Max. Stock Level : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_max_stock_level" className="erp_input_field erp_align-right" value={txt_product_fg_max_stock_level} onChange={e => { validateNo('PMaxStockLevel'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_max_stock_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Record Stock Level : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_reorder_stock_level" className="erp_input_field erp_align-right" value={txt_product_fg_reorder_stock_level} onChange={e => { validateNo('PReorderStockLevel'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_reorder_stock_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Deprecation Percent(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_product_rm_depriciation_percent" className="erp_input_field erp_align-right" value={txt_product_fg_depriciation_percent} onChange={e => { percentValidate(e.target.value, 'PDeprecationPercent'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_depriciation_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Sales Tolerance Percent(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_sales_tolerance_percent" className="erp_input_field erp_align-right" value={txt_sales_tolerance_percent} onChange={e => { percentValidate(e.target.value, 'PTolerancePercent'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_sales_tolerance_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Price Type : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Fixed"
                            type="radio"
                            value="F" checked={is_priceType === 'Fixed'} onClick={() => { setIsPriceType('Fixed'); }}
                            name="pPriceType"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Moving Average"
                            value="M" checked={is_priceType === 'Moving Average'} onClick={() => { setIsPriceType('Moving Average'); }}
                            type="radio"
                            name="pPriceType"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">ABC Analysis : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="A"
                            type="radio"
                            value="A" checked={is_abcAnalysis === 'A'} onClick={() => { setIsABCanalysis('A'); }}
                            name="pABCAnalysis"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="B"
                            value="B" checked={is_abcAnalysis === 'B'} onClick={() => { setIsABCanalysis('B'); }}
                            type="radio"
                            name="pABCAnalysis"
                          />
                        </div>

                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="C"
                            value="C" checked={is_abcAnalysis === 'C'} onClick={() => { setIsABCanalysis('C'); }}
                            type="radio"
                            name="pABCAnalysis"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">QA Required : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_qaRequired === true} onClick={() => { setIsQARequired(true); }}
                            name="isQaRequired"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_qaRequired === false} onClick={() => { setIsQARequired(false); }}
                            type="radio"
                            name="isQaRequired"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Test Certificate Required : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_testCertified === true} onClick={() => { setIsTestCertified(true); }}
                            name="isTestCertRequired"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_testCertified === false} onClick={() => { setIsTestCertified(false); }}
                            type="radio"
                            name="isTestCertRequired"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </form>
            {/* <Accordion defaultActiveKey="0" className="mt-1">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="erp-form-label-md">Additional Parameters</Accordion.Header>
                <Accordion.Body>
                  {renderAdditionalParametersTbl}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* Supplier Mapping Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}

      {/* changes by tushar */}
      {/* <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="SupplierMapping">
          <Accordion.Header className="erp-form-label-md">Supplier Mapping</Accordion.Header>
          <Accordion.Body>
            <div class="col pt-sm-1">
              <input type='checkbox' name="checkAllSupplier" id="checkAllSupplier"
                onClick={(e) => toggleQuotationTermsChkBoxes('checkAllSupplier')} /> <label class="erp-form-label pb-1"> Select All</label>
            </div>
            <div className=" row erp_table_scroll">
              <DatatableWithId data={dataForSuppEntry} columns={columnsForSuppEntry} />
            </div>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}

      {/* Customer Mapping Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>*/}
      {/* changes by tushar */}
      {/* <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="CustomerMapping">
          <Accordion.Header className="erp-form-label-md">Customer Mapping</Accordion.Header>
          <Accordion.Body>
            <div class="col pt-sm-1">
              <input type='checkbox' name="checkAllCustomer" id="checkAllCustomer"
                onClick={(e) => toggleQuotationTermsChkBoxes('checkAllCustomer')}
              /> <label class="erp-form-label pb-1"> Select All</label>
            </div>
            <div className="erp_table_scroll">
              <DatatableWithId data={dataForCust} columns={columnsForCust} />
            </div>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}

      {/* Process Mapping Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}
      {/* changes by tushar */}
      {/* <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="ProcessMapping">
          <Accordion.Header className="erp-form-label-md">Process Mapping</Accordion.Header>
          <Accordion.Body>
            <div class="col pt-sm-1">
              <input type='checkbox' name="checkAllProcess" id="checkAllProcess"
                onClick={(e) => toggleQuotationTermsChkBoxes('checkAllProcess')}
              /> <label class="erp-form-label pb-1"> Select All</label>
            </div>
            <div className="row">
              <div className="erp_table_scroll">
                <thead className="erp_table_head"></thead>
                <DatatableWithId data={dataForProcess} columns={columnsForProcess} />

              </div>
            </div>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}

      {/* QA Mapping Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}
      {/* changes by tushar */}
      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect} style={{ display: 'none' }}>
        <Accordion.Item eventKey="QAMapping">
          <Accordion.Header className="erp-form-label-md">QA Mapping</Accordion.Header>
          <Accordion.Body>
            <div class="col pt-sm-1">
              <input type='checkbox' name="checkAllQa" id="checkAllQa"
                onClick={(e) => toggleQuotationTermsChkBoxes('checkAllQa')}
              /> <label class="erp-form-label pb-1"> Check All QA Mapping</label>
            </div>
            <div className="row">
              <div className="erp_table_scroll">
                <DatatableWithId data={dataQA} columns={columnsQA} />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <hr /> */}
      <div className="text-center">
        <MDButton type="button" className="erp-gb-button"
          onClick={() => { const path = compType === 'Register' ? '/Masters/FinishGoodsListing/reg' : '/Masters/FinishGoodsListing'; navigate(path); }}
          variant="button" fontWeight="regular">Back</MDButton>
        <MDButton type="button" onClick={() => FnAddFinishGood('allFGData')} className={`erp-gb-button ms-2`} variant="button" id="btn_save"
          fontWeight="regular">save</MDButton>
        <Tooltip title={productId === 0 ? "Upload disabled" : ""} placement="top">
          <MDButton type="button" id="upload_btn_id" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" onClick={() => viewDocumentForm()}
            fontWeight="regular" disabled={productFGId === 0} >Upload Document</MDButton>
        </Tooltip>

        {/* <MDButton type="button" id='btn_next' onClick={() => forwardToNxtPage()} className="ms-2 erp-gb-button" variant="button" fontWeight="regular">next</MDButton> */}
        {/* //changes by tushar */}
        {/* <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => deleteshow()}>Delete</MDButton> */}
      </div>

      <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
      <SuccessModal handleCloseSuccessModal={() => handleCloseProdSuccessModal()} show={[showProdSuccessMsgModal, succMsg]} />

      <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      {/* Document modal */}
      {
        showDocumentForm ?
          <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
            </Modal.Header>
            <Modal.Body>
              <DocumentF group_id={product_fg_id} document_group={docGroup} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal> : null
      }

      {/* model for Production Count */}
      {
        showProductionForm ?
          <Modal size="lg" className='erp_document_Form' show={showProductionForm} onHide={handleCloseProductionCountForm} backdrop="static" keyboard={false} centered>
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>Production Count</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseProductionCountForm}></button></span>
            </Modal.Header>
            <Modal.Body>
              <form id="CountFormID">
                <div className="row erp_transporter_div">

                  {/* //first column */}
                  <div className="col-sm-6 erp_form_col_div">
                    {/* <div className="row">
                      <div className="col-sm-6">
                        <Form.Label className="erp-form-label">Production Mixing<span className="required">*</span></Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="text" id="txt_Mixing" className="erp_input_field alter_zero text-end" value={txt_Mixing} onInput={e => { validateNo('txt_Mixing'); validateFields() }} maxLength="19" />
                        <MDTypography variant="button" id="error_txt_Mixing" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Label className="erp-form-label">Count Name<span className="required">*</span></Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="text" id="txt_Count_name" className="erp_input_field alter_zero text-end" value={txt_Count_name} onInput={e => { validateNo('txt_Count_name'); validateFields(); }} maxLength="500" />
                        <MDTypography variant="button" id="error_txt_Count_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Label className="erp-form-label">Count Description</Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="text" id="txt_Count_Desc" className="erp_input_field alter_zero text-end" value={txt_Count_Desc} onInput={e => { validateNo('txt_Count_Desc'); validateFields(); }} optional='optional' maxLength="500" />
                        <MDTypography variant="button" id="error_txt_Count_Desc" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                  </div>

                  {/* //second column */}
                  <div className="col-sm-6 erp_form_col_div">



                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Label className="erp-form-label">Actual Count<span className="required">*</span></Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="text" id="txt_Actual_Count" className="erp_input_field alter_zero text-end" value={txt_Actual_Count} onChange={e => { validateNo('txt_Actual_Count'); validateFields(); }} maxLength="19" />
                        <MDTypography variant="button" id="error_txt_Actual_Count" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                      </div>
                      <div className="col">
                        <div className="erp_form_radio">
                          <div className="fCheck">
                            <Form.Check
                              className="erp_radio_button"
                              label="Yes"
                              type="radio"
                              value="1"
                              name="isactive"
                              defaultChecked
                            />
                          </div>
                          <div className="sCheck">
                            <Form.Check
                              className="erp_radio_button"
                              label="No"
                              value="0"
                              type="radio"
                              name="isactive"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/********* 3rd Column **************************** */}

                </div>

              </form>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex w-100 justify-content-center align-items-center">
                {/* <div className="text-center"> */}
                <MDButton type="submit" className="erp-gb-button" onClick={handleSubmit} variant="button"
                  fontWeight="regular">Save</MDButton>
                {/* </div> */}

                <Button variant="success" className="btn erp-gb-button" style={{ marginLeft: "7px" }} onClick={handleCloseProductionCountForm}>
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Modal> : null
      }


      {/* Add new Record Popup */}
      {
        showAddRecModal ?
          <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
            </Modal.Header>
            <Modal.Body className='erp_city_modal_body'>
              {displayRecordComponent()}
            </Modal.Body>
            <Modal.Footer>
              <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                fontWeight="regular">Close</MDButton>
            </Modal.Footer>
          </Modal > : null
      }


      {/* Delete Modal */}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
        <Modal.Body className='erp_modal_body'>
          <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
          <h6>Are you sure?</h6>
          <div className="erp-form-label">Do you wish to delete this record ?</div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button variant="success" className='erp-gb-button' onClick={handleClose}>
            Cancel
          </Button>&nbsp;
          <Button variant="danger" className='erp-gb-button' onClick={DeleteRecord}>Delete</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}
