import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { FiDownload } from "react-icons/fi";

// React icons
import { MdDelete } from "react-icons/md";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import WarpingBottomReturnSlip from 'FrmGeneric/Invoice/WarpingBottomReturnSlip';

// Import for the searchable combo box.
import Select from 'react-select';
import { renderToString } from "react-dom/server";

export default function FrmWarpingBottomReturnEntry() {
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName, DEPARTMENT_ID } = configConstants;

    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType, modules_forms_id, requestfor } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [IsView, setIsView] = useState(false)
    const [openBottomDetailsAccord, setOpenbottomDetailsAccord] = useState(true);

    // For navigate
    const navigate = useNavigate();

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    const setNoComboRef = useRef();
    let returnByComboRef = useRef(null);
    // for today date formatted
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //combo options
    const [materialtypeOptions, setIssueReturnTypeIdOptions] = useState([])
    const [departmentIdOPtions, setDepartmentIdOptions] = useState([])
    const [subDepartmentOPtions, setSubDepartmentOptions] = useState([])
    const [setNoOptions, setSetNoOptions] = useState([])
    const [lotNoOptions, setLotNoOptions] = useState([])
    const [returnOptions, setReturnOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])

    const [warpingBottomDetailsData, setWarpingBottomDetailsData] = useState([])
    const [cmb_set_no, setSetNo] = useState('');
    const navigator = useNavigate();


    //Form Fields
    const [warping_bottom_return_details_id, setBottomReturnMasterTransactionId] = useState(0);
    const [cmb_bottom_return_type_id, setBottomReturnTypeId] = useState('12');
    const [txt_bottom_return_no, setBottomReturnNo] = useState(0);
    const [cmb_department, setDepartmentId] = useState(keyForViewUpdate === "add" ? "23" : "");
    const [cmb_sub_department, setSubDepartmentId] = useState('')
    const [cmb_return_by, setReturnBy] = useState(UserId);
    const [cmb_received_by_id, setReceivedById] = useState(UserId);
    const [txt_remark, setRemark] = useState('');
    const [chk_isactive, setIsActive] = useState(true);
    const currentDate = new Date().toISOString().split('T')[0];
    const [dt_return_date, setReturnDate] = useState(todayDate);
    const [dt_received_date, setReceivedDate] = useState();


    useEffect(async () => {
        setIsLoading(true);
        try {
            FnSetFieldUserAccess();
            await fillComobos();
            if (idList !== "") {
                await FnCheckUpdateResponce();
            }
            // else{
            //      await generateReturnNo();
            // }

            FnSetFieldUserAccess();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
        // Close indent order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $("#indent-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
        // Close customer order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $(".erp_dropdown-content");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [])


    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)

    const [messageForConfirmation, setMessageForConfirmation] = useState(false)
    const closeConfirmationModal = async (key) => {
        switch (key) {
            case 'Exit':
                setShowSuccessMsgModal(false);
                break;
            default:
                break;
        }
        moveToListing();
    }

    const [showAddRecIndentIssueModal, setshowAddRecIndentIssueModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('');

    // Show ADD record Modals
    const closeRecordAddIndentIssueModal = async () => {
        switch (modalHeaderName) {
            case 'Requested By':
                comboOnChange('departmentId')
                break;
            case 'Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department";
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
                const departmentIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setDepartmentIdOptions(departmentIdApiCall)
                break;

            case 'Material Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    .then(getProductType => {
                        setIssueReturnTypeIdOptions(getProductType)
                        console.log("Product Type List: ", getProductType);
                    });

                break;
            case 'Sub Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department"
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: cmb_department });
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
                const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setSubDepartmentOptions(subDepartmentApiCall)
                break;

            default:
                break;
        }
        setshowAddRecIndentIssueModal(false);

    }



    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillComobos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getProductType => {
                    setIssueReturnTypeIdOptions(getProductType)
                });
            if (keyForViewUpdate === 'add') {
                setBottomReturnTypeId('12');
                // await comboOnChange('materialTypeId');
                await generateReturnNo();
            }

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_department";
            globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
            const getdepartmentId = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setDepartmentIdOptions(getdepartmentId)
            if (keyForViewUpdate === 'add') {
                await comboOnChange('departmentId');
            }

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getEmployee = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setEmployeeOptions(getEmployee)

            //get Return By Options
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"];
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            const returnbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const returnByOpts = [
                { value: '', label: 'Select', },
                ...returnbyIdApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
            ];
            setReturnOptions(returnByOpts);

            resetGlobalQuery();
            globalQuery.columns = [" Distinct(set_no) "];
            globalQuery.table = "xt_weaving_production_warping_bottom_details";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getMaterials => {
                    console.log(getMaterials);
                    const materialOpts = [
                        { value: '', label: 'Select', },
                        ...getMaterials.map((material) => ({ ...material, value: material.set_no, label: material.set_no })),
                    ];
                    setSetNoOptions(materialOpts)
                });

        } catch (error) {
            console.log("error: ", error)
        }
    }
    const FnSetFieldUserAccess = () => {
        if (keyForViewUpdate !== 'add') {
            var saveBtn = document.getElementById('save-btn');
            switch (keyForViewUpdate) {
                case 'view':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(View)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;

                case 'update':
                    $('#submain_heading').append('');
                    $('#cmb_bottom_return_type_id').attr('disabled', true);
                    $('#submain_heading').text('(Modification)');
                    $('#txt_indent_date').attr('disabled', true);
                    $('#cmb_customer_order_no').attr('disabled', false);
                    $('#cmb_customer').attr('disabled', true);
                    $('#clearbutton-id').hide();
                    saveBtn.textContent = 'Update';
                    break;

                case 'Receive':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Receive)');
                    saveBtn.textContent = 'Receive';
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("form").find('textarea#txt_remark').removeAttr("disabled");
                    $('table .approveField').removeAttr("disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    break;
            }
        }
    }


    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            if (idList !== '') {
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWarpingBottomReturnDetails/FnShowRawMaterialReturnDetails/${COMPANY_ID}/${keyForViewUpdate}?bottom_return_no=${idList.bottom_return_no}`)
                const response = await apiCall.json();
                debugger
                let getRawMaterialReturnData = response.cXtWarpingBottomReturnModel[0];

                if (getRawMaterialReturnData !== null && getRawMaterialReturnData !== "") {
                    setBottomReturnMasterTransactionId(getRawMaterialReturnData.warping_bottom_return_details_id)
                    setBottomReturnTypeId(getRawMaterialReturnData.bottom_return_type_id)
                    setBottomReturnNo(getRawMaterialReturnData.bottom_return_no)
                    setReturnDate(getRawMaterialReturnData.bottom_return_date)
                    setDepartmentId(getRawMaterialReturnData.department_id)
                    await comboOnChange('departmentId')
                    setSubDepartmentId(getRawMaterialReturnData.sub_department_id)
                    await comboOnChange('subDepartment');

                    setReturnBy(getRawMaterialReturnData.return_by_id)
                    setRemark(getRawMaterialReturnData.remark)
                    setIsActive(getRawMaterialReturnData.is_active)
                    setSetNo(getRawMaterialReturnData.set_no)
                    if (keyForViewUpdate === 'Receive') {
                        setReceivedById(UserId)
                        setReceivedDate(todayDate)
                    } else {
                        setReceivedById(getRawMaterialReturnData.received_by_id)
                        setReceivedDate(getRawMaterialReturnData.bottom_receipt_date);
                    }
                    if (keyForViewUpdate === 'Receive') {
                        const updatedData = response.cXtWarpingBottomReturnModel.map(item => ({
                            ...item,
                            bottom_return_item_status: 'R',// Update status to 'R'
                            actual_gross_weight: item.gross_weight,
                            actual_net_weight: item.net_weight
                        }));
                        setWarpingBottomDetailsData(updatedData);
                    } else {
                        setWarpingBottomDetailsData(response.cXtWarpingBottomReturnModel);
                    }

                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const comboOnChange = async (key) => {
        switch (key) {
            case 'departmentId':
                var departmentIdVal = document.getElementById('cmb_department').value;
                setDepartmentId(departmentIdVal)
                if (departmentIdVal === "0") {
                    setHeaderName('Department')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (departmentIdVal !== "" && departmentIdVal !== "0") {
                    resetGlobalQuery();
                    // globalQuery.columns.push("field_id");
                    // globalQuery.columns.push("field_name");
                    globalQuery.columns = ["field_id", "field_name", "godown_id"];
                    globalQuery.table = "cmv_department"
                    globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                    globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
                    globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
                    const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setSubDepartmentOptions(subDepartmentApiCall)
                    setSubDepartmentId('')
                    if (departmentIdVal !== "") {
                        $('#error_cmb_department').hide();
                    }
                } else {
                    setSubDepartmentOptions([])
                    setSubDepartmentId('')
                }
                break;
            case 'subDepartment':
                debugger
                var subdepartmentIdVal = document.getElementById('cmb_sub_department').value;
                setSubDepartmentId(subdepartmentIdVal)

                if (subdepartmentIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Sub Department')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (subdepartmentIdVal !== "" && subdepartmentIdVal !== "0") {
                    // load issue nos on sub-department  
                    if (subdepartmentIdVal !== "") {
                        $('#error_cmb_sub_department').hide();
                    }
                }
                break;

            case 'set_no':
                $("#error_cmb_set_no").hide();
                await FnShowBottomDetails();
                break;

            case 'return_by':
                var return_by = returnByComboRef.current.value;
                setReturnBy(return_by)
                if (return_by === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Requested By')
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (return_by !== "" && return_by !== "0") {
                    $('#error_cmb_return_by').hide();
                }
                break;

            case 'materialTypeId':
                var materialTypeId = document.getElementById('cmb_bottom_return_type_id').value;
                if (materialTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Material Type')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                setBottomReturnTypeId(materialTypeId)
                if (materialTypeId !== "0" && materialTypeId !== "") {
                    $('#error_cmb_indent_issue_return_type_id').hide();
                    await generateReturnNo();
                } else {
                    setBottomReturnNo("0")
                }
                break;

            default:
                break;
        }
    }

    const displayRecordIndentIssueComponent = () => {
        switch (modalHeaderName) {
            case 'Requested By':
                return <FrmMEmployeesEntry />;

            case 'Department':
                return <FrmDepartmentEntry />;

            case 'Material Type':
                return <FrmMProductTypeEntry />;

            case 'Sub Department':
                const props = { btn_disabled: true, departmentType: "S", parent_department: cmb_department, parentDepIs_disable: true, deptTypeIs_disable: true };
                return <FrmDepartmentEntry {...props} />;
            default:
                return null;
        }
    }

    const generateReturnNo = async () => {
        // const ptShortName = materialtypeOptions.find(item => item.field_id === parseInt($('#cmb_bottom_return_type_id').val()));
        // const productTypeId = $('#cmb_bottom_return_type_id option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("xt_warping_bottom_return_details", "bottom_return_no", "PRM", "WBR", "5", "bottom_return_type_id", "12");
        setBottomReturnNo(autoNoApiCall);
        return autoNoApiCall;
    };


    // Fn for show indent summary modal details
    const FnShowBottomDetails = async () => {
        debugger
        try {
            setIsLoading(true)
            let selected_setNo = setNoComboRef.current.value;
            if (selected_setNo !== "") {
                resetGlobalQuery();
                globalQuery.columns = ['btm.product_rm_id As product_material_id', 'btm.sr_no', 'btm.batch_no', 'btm.creel_no', 'btm.weight_per_pkg', 'btm.no_of_boxes',
                    'btm.goods_receipt_no', 'btm.cone_type_value', 'btm.cone_type', 'btm.package_type', 'btm.gross_weight', 'btm.no_of_package', 'btm.set_no',
                    'btm.tare_weight', 'btm.net_weight', 'btm.warping_production_code', 'btm.warping_bottom_details_production_date', 'btm.weaving_production_warping_bottom_details_id', 'btm.weaving_production_warping_master_id', 'plan.customer_id', 'warpm.supplier_id',
                    'sm.product_rm_name', 'cust.customer_name', 'sup.supp_branch_name As supplier_name', 'gdn.godown_id', 'gdn.godown_name'

                ];
                globalQuery.table = "xt_weaving_production_warping_bottom_details btm";
                globalQuery.conditions.push({ field: "btm.set_no", operator: "=", value: selected_setNo, });
                globalQuery.conditions.push({ field: "btm.material_status", operator: "=", value: "Pending" });
                globalQuery.conditions.push({ field: "btm.is_delete", operator: "=", value: 0, });

                globalQuery.joins = [
                    {
                        "table": `xt_warping_production_order plan`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "plan.set_no",
                                "right": "btm.set_no",
                            },

                            {
                                "left": "plan.is_delete",
                                "right": "0"
                            }
                        ]
                    },
                    {
                        "table": `sm_product_rm sm`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "sm.product_rm_id",
                                "right": "btm.product_rm_id",
                            },

                            {
                                "left": "sm.is_delete",
                                "right": "0"
                            }
                        ]
                    },
                    {
                        "table": `xt_weaving_production_warping_master warpm`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "warpm.set_no",
                                "right": "btm.set_no",
                            },

                            {
                                "left": "warpm.is_delete",
                                "right": "0"
                            }
                        ]
                    },
                    {
                        "table": `cm_customer cust`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "cust.customer_id",
                                "right": "plan.customer_id",
                            },

                            {
                                "left": "cust.is_delete",
                                "right": "0"
                            }
                        ]
                    },
                    {
                        "table": `cm_supplier_branch sup`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "sup.supp_branch_id",
                                "right": "warpm.supplier_id",
                            },

                            {
                                "left": "sup.is_delete",
                                "right": "0"
                            }
                        ]
                    },
                    {
                        "table": `cm_godown gdn`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "gdn.godown_id",
                                "right": "18",
                            },

                            {
                                "left": "gdn.is_delete",
                                "right": "0"
                            }
                        ]
                    },

                ];
                const issueDetailsList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                const transformedData = issueDetailsList.flatMap(item => {
                    if (item.cone_type.includes(",")) {
                        const [coneType, boraBox] = item.cone_type.split(",");
                        return [
                            { ...item, cone_type: coneType.trim(), bora_box: boraBox.trim() }
                        ];
                    } else {
                        return [{ ...item, bora_box: "" }];
                    }
                });

                const updatedData = transformedData.map(item => ({
                    ...item,
                    bottom_return_item_status: 'P'
                }));

                setWarpingBottomDetailsData(updatedData);
            } else {
                setWarpingBottomDetailsData([])
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    // Function to handle input changes and update state variables
    const FnOnChangeTblRow = async (currentRowData, event, arrayIndex, param) => {
        debugger
        let clickedColName;
        let issueItemsQtyinp;

        clickedColName = event.target.getAttribute('Headers');               // Get the column name where event triggered.
        issueItemsQtyinp = document.querySelector('#' + event.target.id);

        switch (clickedColName) {
            case 'bottom_return_item_status':
                if (event.target.value !== '') {
                    currentRowData[clickedColName] = event.target.value;
                    delete issueItemsQtyinp.parentElement.dataset.tip;
                }
                break;
            case 'actual_net_weight':
                currentRowData[clickedColName] = event.target.value;
                break;
            case 'actual_gross_weight':
                let actual_gr_wt = event.target.value;
                let gross_weight = currentRowData.gross_weight;
                if (event.target.value.endsWith(".") || event.target.value.endsWith(".0")) {
                    currentRowData['actual_gross_weight'] = actual_gr_wt;
                    currentRowData['actual_net_weight'] = actual_gr_wt - currentRowData['tare_weight'];

                } else {
                    // if (gross_weight >= actual_gr_wt) {
                    currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(actual_gr_wt, 4);
                    currentRowData['actual_net_weight'] = actual_gr_wt - currentRowData['tare_weight'];
                    delete issueItemsQtyinp.parentElement.dataset.tip;
                    // } else {
                    //     issueItemsQtyinp.parentElement.dataset.tip = `Received Gross Weight can't exceed return Gross Weight...!`;
                    //     currentRowData[clickedColName] = '';
                    // }
                }
                break;
            default:
                break;
        }

        const detailData = [...warpingBottomDetailsData]
        detailData[arrayIndex] = currentRowData
        setWarpingBottomDetailsData(detailData);

    };



    //-------------------------------------------End Details section --------------------------------------------------------------------------
    const validateFields = () => {
        validate.current.validateFieldsOnChange('bottom_return_form_id')
    }
    // ---------------------fn for save return entry ----------------------------------------------------------------
    const FnAddBottomReturnEntry = async () => {
        debugger
        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                checkIsValidate = await FnValidateBottomOrder();
            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                let lastIndentissueNo = txt_bottom_return_no;
                if (keyForViewUpdate === "add") {
                    lastIndentissueNo = await generateReturnNo();
                }

                let json = { 'TransWarpingBottomDetailData': [], 'commonIds': {} }

                for (let issueItem = 0; issueItem < warpingBottomDetailsData.length; issueItem++) {
                    const indentIssueDetailsRow = warpingBottomDetailsData[issueItem];

                    const indentReturnRow = {};
                    indentReturnRow.company_id = COMPANY_ID
                    indentReturnRow.bottom_return_no = lastIndentissueNo
                    indentReturnRow.bottom_return_date = dt_return_date
                    indentReturnRow.bottom_return_type_id = cmb_bottom_return_type_id
                    indentReturnRow.bottom_return_type = $('#cmb_bottom_return_type_id').find(":selected").text()
                    indentReturnRow.department_id = cmb_department
                    indentReturnRow.sub_department_id = cmb_sub_department
                    indentReturnRow.return_by_id = cmb_return_by
                    // indentReturnRow.received_by_id = keyForViewUpdate === "add" ? "0" : cmb_received_by_id
                    indentReturnRow.received_by_id = keyForViewUpdate === "add" ? "0" : cmb_received_by_id;
                    indentReturnRow.bottom_receipt_date = dt_received_date
                    indentReturnRow.remark = txt_remark
                    indentReturnRow.company_branch_id = COMPANY_BRANCH_ID
                    indentReturnRow.financial_year = FINANCIAL_SHORT_NAME
                    indentReturnRow.warping_bottom_return_details_id = keyForViewUpdate !== "add" ? indentIssueDetailsRow.warping_bottom_return_details_id : 0
                    indentReturnRow.product_material_id = indentIssueDetailsRow.product_material_id
                    indentReturnRow.no_of_package = indentIssueDetailsRow.no_of_package
                    indentReturnRow.gross_weight = indentIssueDetailsRow.gross_weight
                    indentReturnRow.net_weight = indentIssueDetailsRow.net_weight || 0
                    indentReturnRow.tare_weight = indentIssueDetailsRow?.tare_weight
                    indentReturnRow.bottom_return_item_status = indentIssueDetailsRow.bottom_return_item_status
                    indentReturnRow.godown_id = indentIssueDetailsRow?.godown_id
                    indentReturnRow.godown_section_id = "57"
                    indentReturnRow.godown_section_beans_id = "848"
                    indentReturnRow.warping_production_code = indentIssueDetailsRow?.warping_production_code
                    indentReturnRow.warping_bottom_details_production_date = indentIssueDetailsRow?.warping_bottom_details_production_date
                    indentReturnRow.weaving_production_warping_bottom_details_id = indentIssueDetailsRow?.weaving_production_warping_bottom_details_id
                    indentReturnRow.weaving_production_warping_master_id = indentIssueDetailsRow?.weaving_production_warping_master_id
                    indentReturnRow.batch_no = indentIssueDetailsRow?.batch_no
                    indentReturnRow.weight_per_pkg = indentIssueDetailsRow?.weight_per_pkg
                    indentReturnRow.supplier_id = indentIssueDetailsRow?.supplier_id
                    indentReturnRow.supplier_name = indentIssueDetailsRow?.supplier_name
                    indentReturnRow.customer_id = indentIssueDetailsRow?.customer_id
                    indentReturnRow.customer_name = indentIssueDetailsRow?.customer_name
                    indentReturnRow.goods_receipt_no = indentIssueDetailsRow?.goods_receipt_no
                    indentReturnRow.creel_no = indentIssueDetailsRow?.creel_no
                    indentReturnRow.set_no = indentIssueDetailsRow?.set_no
                    indentReturnRow.cone_type_value = indentIssueDetailsRow?.cone_type_value
                    indentReturnRow.cone_type = indentIssueDetailsRow?.cone_type
                    indentReturnRow.bora_box = indentIssueDetailsRow?.bora_box
                    indentReturnRow.package_type = indentIssueDetailsRow?.package_type
                    indentReturnRow.sr_no = indentIssueDetailsRow?.sr_no
                    indentReturnRow.no_of_boxes = indentIssueDetailsRow?.no_of_boxes || 0
                    indentReturnRow.actual_gross_weight = indentIssueDetailsRow?.actual_gross_weight || 0
                    indentReturnRow.actual_net_weight = indentIssueDetailsRow?.actual_net_weight || 0
                    indentReturnRow.created_by = UserName
                    indentReturnRow.modified_by = idList !== "" ? UserName : null
                    json.TransWarpingBottomDetailData.push(indentReturnRow);

                }
                console.log(json.TransWarpingBottomDetailData);

                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.bottom_return_no = keyForViewUpdate === 'add' ? lastIndentissueNo : txt_bottom_return_no
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                const formData = new FormData();
                formData.append(`bottomReturnDetailsData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const acceptFlag = keyForViewUpdate;

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWarpingBottomReturnDetails/FnAddUpdateRecord/${acceptFlag}`, requestOptions)
                const responce = await apicall.json();
                if (responce.success === 0) {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    if (responce.data.hasOwnProperty('bottom_return_no')) {
                        setModalOrderDetails(`Bottom Return No: ${responce.data.bottom_return_no}`)
                    }
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }
    }



    const FnValidateBottomOrder = async () => {
        let headerDataIsValid = await validate.current.validateForm("bottom_return_form_id");
        if (!headerDataIsValid) { return false; }

        if (cmb_bottom_return_type_id === "" || cmb_bottom_return_type_id === '0') {
            $("#error_cmb_bottom_return_type_id").text("Please fill this field...!");
            $("#error_cmb_bottom_return_type_id").show();
            $("#cmb_bottom_return_type_id").focus();
            return false;
        }
        let return_by = cmb_return_by || '';
        if (return_by === "" || return_by === '0') {
            $("#error_cmb_return_by").text("Please fill this field...!");
            $("#error_cmb_return_by").show();
            $("#cmb_return_by").focus();
            return false;
        }
        if ((cmb_set_no === "" || cmb_set_no === '0') && keyForViewUpdate === 'add') {
            $("#error_cmb_set_no").text("Please select Set no...!");
            $("#error_cmb_set_no").show();
            $("#cmb_set_no").focus();
            return false;
        }
        if (!warpingBottomDetailsData || warpingBottomDetailsData.length === 0) {
            setErrMsg("No records found in Warping Bottom Details!");
            setShowErrorMsgModal(true);
            return false;
        }
        // Validate issue details table when receiving
        if (keyForViewUpdate === "Receive") {
            let issueDetailsIsValid = true;
            $("#issue-details-tbl tbody tr").each(function () {
                const grossWeight = parseFloat($(this).find('input[id^="actual_gross_weight_"]').val()) || 0;

                if (grossWeight === 0) {
                    $(this).find(`input[id^='actual_gross_weight_']`).parent().attr('data-tip', 'Please Enter Received Gross Weight...!');
                    $(this).find(`input[id^='actual_gross_weight_']`).focus();
                    // setOpenIssueDetailsAccord(true);
                    issueDetailsIsValid = false;
                    return false; // Exit loop early
                }
            });

            if (!issueDetailsIsValid) return false;
        }

        return true;
    };



    const FnClearFormFields = async () => {
        // setBottomReturnTypeId('')
        // setBottomReturnNo('0')
        setDepartmentId(DEPARTMENT_ID);
        setSubDepartmentId('')
        setReceivedById('');
        setRemark('');
        setIsActive(true);
        setReturnBy('')
        setSetNo('');
        setWarpingBottomDetailsData([])
    }

    const moveToListing = () => {
        let Path = compType === 'Register' ? '/Transactions/TWarpingBottomReturn/FrmWarpingBottomReturnListing/reg' : `/Transactions/TWarpingBottomReturn/FrmWarpingBottomReturnListing`;
        Path = `${requestfor ? `${Path}/${requestfor}` : `${Path}`}`;
        navigate(Path);
    }

    $('body').on('click', '.RemoveTrBtn', function () {
        var $tr = $(this).closest('tr');
        $tr.remove();
    });

    $(document).on('click', '.erp_input_field.text-end.remove0', function () {
        if ($(this).val() === "0") {
            $(this).val("");
        }
    });

    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".erp_input_field.text-end.remove0");
        if (e.target.className !== "erp_input_field text-end remove0") {
            inputBoxes.each(function () {
                if ($(this).val() === "") {
                    $(this).val("0");
                }
            });
        }
    });

    $(document).on('click', '.remove_input_zero', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });

    // fn to remove material
    const removeMaterial = (indexToRemove, setStateCallback) => {
        if (indexToRemove === undefined || indexToRemove < 0 || indexToRemove >= warpingBottomDetailsData.length) {
            console.error("Invalid index provided.");
            return;
        }
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            // Create a new array excluding the element at indexToRemove
            const updatedDetailsData = warpingBottomDetailsData.filter((_, index) => index !== indexToRemove);

            // Update state using the provided callback
            setStateCallback(updatedDetailsData);
        } else {
            console.error("Cannot remove material in 'approve' or 'view' mode.");
        }
    }

    const renderDetailsTable = useMemo(() => {
        return <>
            {/* <Table className="erp_table table table-striped table-bordered erp_table_scroll" id='issue-details-tbl' responsive bordered striped style={{width:"100%"}}> */}
            <Table className="erp_table" id='issue-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate === "add" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Set No</th>
                        <th className="erp_table_th">Count</th>
                        <th className="erp_table_th">Party Name</th>
                        <th className="erp_table_th">Supplier</th>
                        <th className="erp_table_th">SR.No</th>
                        <th className="erp_table_th">No.Of Cones</th>
                        <th className="erp_table_th">GR.WT</th>
                        <th className="erp_table_th">TR.WT </th>
                        <th className="erp_table_th">NT.WT </th>
                        {
                            keyForViewUpdate === 'Receive' || keyForViewUpdate === 'view' ?
                                <>
                                    <th className={`erp_table_th`}>Rec. GR.WT</th>
                                    <th className={`erp_table_th`}>Rec. NT.WT</th>
                                </> : null
                        }
                        <th className="erp_table_th">Cone Type</th>
                        <th className="erp_table_th">Box/Bora</th>
                        <th className={`erp_table_th`} style={{ textAlign: 'center' }}> Godown Name</th>
                        <th className={`erp_table_th`} style={{ paddingRight: '40px' }} >Return Item Status</th>
                    </tr>
                </thead>
                <tbody>
                    {warpingBottomDetailsData.map((returnItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            <td className={`erp_table_td  ${keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}><div style={{ display: "flex" }}>
                                <Tooltip title="Delete" placement="top">
                                    <MDTypography className="erp-view-btn">
                                        <MdDelete className="erp-delete-btn" id={`delete_btn_${Index}`} onClick={() => removeMaterial(Index, setWarpingBottomDetailsData)} />
                                    </MDTypography>
                                </Tooltip>
                            </div>
                            </td>
                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className={`erp_table_td sticky-column`}>{returnItem.set_no}</td>
                            <td className={`erp_table_td sticky-column`}>{returnItem.product_rm_name}</td>
                            <td className={`erp_table_td sticky-column`}>{returnItem.customer_name}</td>
                            <td className={`erp_table_td text-end`}>{returnItem.supplier_name}</td>
                            <td className={`erp_table_td text-end`}>{returnItem.sr_no} </td>
                            <td className={`erp_table_td text-end`}>{returnItem.no_of_package}</td>
                            <td className={`erp_table_td text-end`}>{returnItem.gross_weight}</td>
                            <td className={`erp_table_td text-end sticky-column`}>{returnItem.tare_weight}</td>
                            <td className={`erp_table_td text-end`}>{returnItem.net_weight} </td>
                            {
                                keyForViewUpdate === 'Receive' || keyForViewUpdate === 'view' ? <>
                                    <td className="erp_table_td text-end">
                                        {keyForViewUpdate === 'Receive' ?
                                            <input type="text" id={`actual_gross_weight_${Index}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField" style={{ width: "100px" }}
                                                value={returnItem.actual_gross_weight} onChange={(e) => { FnOnChangeTblRow(returnItem, e, Index); }} Headers='actual_gross_weight' />
                                            :
                                            returnItem.actual_gross_weight
                                        }
                                    </td>
                                    {/* <td className="erp_table_td text-end">
                                        {keyForViewUpdate === 'Receive' ?
                                            <input type="text" id={`actual_net_weight_${Index}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                                value={returnItem.actual_net_weight} onChange={(e) => { FnOnChangeTblRow(returnItem, e, Index); }} Headers='actual_net_weight' />
                                            :
                                            returnItem.actual_net_weight
                                        }
                                    </td> */}

                                    <td className="erp_table_td text-end">{returnItem.actual_net_weight}  </td>
                                </> : null
                            }
                            <td className="erp_table_td">{returnItem.cone_type}  </td>
                            <td className="erp_table_td">{returnItem.bora_box}  </td>

                            <td className={`erp_table_td `}>
                                {returnItem.godown_name}
                            </td>

                            <td className="erp_table_td">
                                <select id={`bottom_return_item_status_${Index}`} disabled={keyForViewUpdate === 'add' || keyForViewUpdate === 'update'} value={returnItem.bottom_return_item_status}
                                    className="form-select form-select-sm mb-1" Headers='bottom_return_item_status' onChange={(e) => { FnOnChangeTblRow(returnItem, e, Index); }}>
                                    <option value="P">Pending</option>
                                    <option value="R">Received</option>
                                </select>
                            </td>

                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    }, [warpingBottomDetailsData, keyForViewUpdate])


    // ---------------------------------------------------------------------------------------------------------------------------
    const requisitionIssueContent = {
        company: {
            company: '',
            company_branch: '',
        },

        materialDetails: {
            issue_no: '',
            issue_date: '',
            issue_type: '',
        },
        header: {
            issue_group_type: '',
            department_name: '',
            sub_department_name: '',
            requisition_by_name: '',
            issued_by_name: '',
            approved_by_name: '',
            approved_date: '',
            requisition_date: '',
            remark: '',
        },
        footer: {
            requisition_by_name: '',
            issued_by_name: '',
            approved_by_name: '',
            sumofStockQuantity: '',
            sumIndentQuantity: '',
            sumofApprovedQuantity: '',
            sumofRejectedQuantity: '',
            sumofIssuedQuantity: '',

        },
        items: [],
    };
    const printIssueSlip = async (openPrintModal) => {
        try {
            setIsLoading(true);
            //Issue details
            requisitionIssueContent.materialDetails.keyForViewUpdate = keyForViewUpdate
            requisitionIssueContent.materialDetails.bottom_return_no = txt_bottom_return_no
            requisitionIssueContent.materialDetails.issue_return_date = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_return_date)
            requisitionIssueContent.materialDetails.issue_type = $('#cmb_bottom_return_type_id').find(":selected").text()
            requisitionIssueContent.materialDetails.received_date = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_received_date)
            //Company details
            requisitionIssueContent.company.company = warpingBottomDetailsData[0].company_name
            requisitionIssueContent.company.company_branch = warpingBottomDetailsData[0].company_branch_name
            // header Details
            // requisitionIssueContent.header.received_by_name = IndentIssueMasterRecord.received_by_name
            requisitionIssueContent.header.department_name = $('#cmb_department').find(":selected").text()
            requisitionIssueContent.header.sub_department_name = $('#cmb_sub_department').find(":selected").text()
            requisitionIssueContent.header.return_by_name = returnOptions.find((emp) => { return emp.value === cmb_return_by })?.label ?? ""
            requisitionIssueContent.header.received_by_name = $('#cmb_received_by_id').find(":selected").text()
            requisitionIssueContent.header.received_date = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_received_date)
            requisitionIssueContent.header.remark = txt_remark

            for (let index = 0; index < warpingBottomDetailsData.length; index++) {
                const element = warpingBottomDetailsData[index];
                requisitionIssueContent.items.push(element)
            }

            if (openPrintModal) {
                navigator('/Invoice', { state: { invoiceData: requisitionIssueContent, navigationLink: '/Transactions/TWarpingBottomReturn/FrmWarpingBottomReturnEntry', invoiceType: 'WBIR', title: 'Requisition/Issue', idList: idList, keyForViewUpdate: keyForViewUpdate, requestfor: requestfor } });
            } else {
                return renderToString(<WarpingBottomReturnSlip invoiceContent={requisitionIssueContent} />);
            }

        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }
    };

    //---------------------------------------------------------Print Functionality End----------------------------------------------

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'> Warping Bottom Return<span id="submain_heading">(Creation)</span></label>
                        </div>
                        <form id="bottom_return_form_id">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Bottom Return Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <select id="cmb_bottom_return_type_id" className="form-select form-select-sm" value={cmb_bottom_return_type_id} onChange={() => { comboOnChange('materialTypeId'); validateFields(); }} disabled >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {materialtypeOptions.length !== 0 ? (
                                                    <>
                                                        {materialtypeOptions.map(materialTypeId => (
                                                            <option key={materialTypeId.field_id} value={materialTypeId.field_id}>
                                                                {materialTypeId.field_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_bottom_return_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Bottom Return No & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-8 pe-md-0">
                                                    <Form.Control type="text" id="txt_bottom_return_no" className="erp_input_field" value={txt_bottom_return_no} disabled />
                                                    <MDTypography variant="button" id="error_txt_bottom_return_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>

                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_return_date' className="erp_input_field" value={dt_return_date} onChange={e => { setReturnDate(e.target.value); validateFields(); }} />
                                                    <MDTypography variant="button" id="error_dt_return_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_department" className="form-select form-select-sm" value={cmb_department} onChange={() => { comboOnChange('departmentId'); validateFields(); }} >
                                                <option value="">Select</option>
                                                {
                                                    departmentIdOPtions.length !== 0 ? (
                                                        <>
                                                            {departmentIdOPtions?.map(departmentId => (
                                                                <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                                            ))}
                                                        </>
                                                    ) : null
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Sub Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_sub_department" className="form-select form-select-sm" value={cmb_sub_department} onChange={() => { comboOnChange('subDepartment'); validateFields(); }}>
                                                <option value="">Select</option>
                                                {/* <option value="0">Add New Record+</option> */}
                                                {subDepartmentOPtions?.map(departmentId => (
                                                    <option value={departmentId.field_id} godown_id={departmentId.godown_id}>{departmentId.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sub_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>



                                </div>
                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    {keyForViewUpdate === 'add' ? <>
                                        <div className='row'>
                                            <div className='col-sm-3 col-12'>
                                                <Form.Label className="erp-form-label">Set No</Form.Label>
                                            </div>
                                            <div className={`col-sm-9 col-12`}>
                                                <Select ref={setNoComboRef}
                                                    options={setNoOptions}
                                                    isDisabled={['view', 'update', 'Receive'].includes(keyForViewUpdate)}
                                                    inputId="cmb_set_no" // Provide the ID for the input box
                                                    value={setNoOptions.find(option => option.value === cmb_set_no) || null}
                                                    onChange={(selectedOpt) => {
                                                        setSetNo(selectedOpt.value);
                                                        setNoComboRef.current = selectedOpt;
                                                        comboOnChange('set_no');
                                                        validateFields();
                                                    }}
                                                    placeholder="Search for Set No..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>


                                    </> : null}
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Return By<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className={`col-sm-5 col-10`}>
                                            <Select ref={returnByComboRef}
                                                options={returnOptions}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_return_by" // Provide the ID for the input box
                                                value={returnOptions.find(option => option.value === parseInt(cmb_return_by)) || null}
                                                onChange={(selectedOpt) => {
                                                    setReturnBy(selectedOpt.value);
                                                    returnByComboRef.current = selectedOpt;
                                                    // comboOnChange('return_by');
                                                    validateFields();
                                                }}
                                                placeholder="Search for Reuesition by..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_return_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>

                                    {(keyForViewUpdate === "Receive" || keyForViewUpdate === "view") ?
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Received By  </Form.Label>
                                                </div>
                                                <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                                    <select id="cmb_received_by_id" className="form-select form-select-sm " value={cmb_received_by_id} optional="optional"
                                                        onChange={() => {
                                                            validateFields();
                                                        }} disabled>
                                                        <option value="">Select</option>
                                                        {employeeOptions.length !== 0 ? (
                                                            <>
                                                                {employeeOptions?.map(acceptedById => (
                                                                    <option value={acceptedById.field_id}>{acceptedById.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_received_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                            <div className={`row`}>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Received Date</Form.Label>
                                                </div>
                                                <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                                    <Form.Control type="date" id='dt_received_date' className="erp_input_field" value={dt_received_date} onChange={e => { setReceivedDate(e.target.value); validateFields(); }} min={currentDate} optional='optional' />
                                                    <MDTypography variant="button" id="error_dt_received_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                    }



                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' maxLength={1000} />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openBottomDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={(e) => setOpenbottomDetailsAccord(!openBottomDetailsAccord)} className="erp-form-label-md"> Warping Bottom Details</Accordion.Header>
                            <Accordion.Body>

                                <div className="mt-10">
                                    {warpingBottomDetailsData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> :
                                        <div className='row  text-center'>
                                            <div className="col-12 ">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >


                </>

                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className="erp-gb-button ms-2" variant="button" onClick={() => { FnAddBottomReturnEntry() }}
                        fontWeight="regular">Save</MDButton>
                    <MDButton type="button" className="erp-gb-button ms-2" variant="button" id='clearbutton-id' onClick={FnClearFormFields} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Clear</MDButton>


                    <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" id="printbtn_id"
                        onClick={() => { printIssueSlip(true); }} >Print<FiDownload className="erp-download-icon-btn" /></MDButton>
                </div >


                <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, succMsg, modalOrderDetails, messageForConfirmation]} />
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal('Exit')} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                <Modal size="lg" show={showAddRecIndentIssueModal} onHide={closeRecordAddIndentIssueModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentIssueModal}></button></div>
                        </div>
                        {displayRecordIndentIssueComponent()}

                    </Modal.Body>
                </Modal >


            </DashboardLayout >
        </>
    )
}

