import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from 'react'
import ReactDOMServer from 'react-dom/server';
import { useReactToPrint } from 'react-to-print';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import MDTypography from 'components/MDTypography';
import Tooltip from "@mui/material/Tooltip";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";
import MDButton from "components/MDButton";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ExcelExport from "Features/Exports/ExcelExport";
import Select from 'react-select';
import MaterialLabelSearchBar from "FrmGeneric/MaterialLabelSearchBar";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx-js-style";

function FrmCottonBalesWeightageReport() {
    const configConstants = ConfigConstants();
    const { COMPANY_NAME, COMPANY_ID, AFTER_DECIMAL_PLACES, COMPANY_ADDRESS } = configConstants;

    const navigate = useNavigate();

    const [cmb_unit_plant_id, setUnitPlantId] = useState(0);

    const [unitPlantOptions, setunitPlantOptions] = useState([]);
    // const [mixing_chart_date, setMixingChartDt] = useState(today);

    //  const [txt_mixing_chart_no, setMixingChartNo] = useState(mixing_chart_no);

    useEffect(async () => {
        resetGlobalQuery();
        globalQuery.columns.push("plant_id", "plant_name", "plant_short_name");
        globalQuery.table = "cm_plant";
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        const plantNoApiData = await comboDataAPiCall.current?.fillFiltersCombo(globalQuery);
        setunitPlantOptions(plantNoApiData);
    }, []);

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split("T")[0];

    const [dt_from_date, setFromDt] = useState(today);
    const [dt_to_date, setToDt] = useState(today);

    //Ref Hooks
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();



    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
        // { label: "All", value: 0 },
    ]
    const [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[2].value);
    const [pageCount, setpageCount] = useState(0);
    const [PageCurrent, setcurrentPage] = useState(0);

    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);


    //data hook
    const [cottonBaleWeightageStockReportData, setCottonBaleWeightageStockReportData] = useState([])
    const [mixingChartCalculations, setMixingChartCalculations] = useState([]);
    let headers = ["AVG", "MAX", "MIN", "RANGE", "CV"];


    //Returning to Dashboard
    const goBack = () => { navigate("/Dashboard") }

    //Validating Dates
    function isValidDate(dateString) {
        // Check if date is in the format DD-MM-YYYY
        const regex = /^(?<day>0[1-9]|[12][0-9]|3[01])-(?<month>0[1-9]|1[0-2])-(?<year>\d{4})$/;
        const match = regex.exec(dateString);

        if (!match) {
            console.log("Date format is incorrect:", dateString);
            return false; // If the format is not correct
        }

        const day = Number(match.groups.day);
        const month = Number(match.groups.month);
        const year = Number(match.groups.year);

        // Check for leap year if it's February
        if (month === 2) {
            const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
            if (day > 29 || (day === 29 && !isLeapYear)) {
                console.log("Invalid day for February:", day);
                return false; // Invalid day for February in a leap year
            }
        }

        // Validate months with 30 days (April, June, September, November)
        if ([4, 6, 9, 11].includes(month) && day > 30) {
            console.log("Invalid day for month with 30 days:", dateString);
            return false; // These months cannot have more than 30 days
        }

        // Create a date object with the given input
        const date = new Date(year, month - 1, day);
        if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
            console.log("Invalid date object created:", dateString, date);
            return false; // The date is invalid (like 32nd day, or 13th month)
        }

        console.log("Date is valid:", dateString);
        return true;
    }

    function convertToDDMMYYYY(dateString) {
        // Assuming the input is in the format YYYY-MM-DD
        const [year, month, day] = dateString.split('-');
        // Return the date in DD-MM-YYYY format
        return `${day}-${month}-${year}`;
    }

    function FnChkIsValidFilters() {

        let dt_from_date = $("#dt_from_date").val();  // Expecting input in YYYY-MM-DD format
        let dt_to_date = $("#dt_to_date").val();      // Expecting input in YYYY-MM-DD format

        if (dt_to_date === '') {
            $('#error_dt_to_date').text("Invalid To Date").show();
            return false
        } else if (dt_from_date === '') {
            $('#error_dt_from_date').text("Invalid From Date").show();
            return false;
        }

        // Convert both dates to DD-MM-YYYY format for validation
        dt_from_date = convertToDDMMYYYY(dt_from_date);
        dt_to_date = convertToDDMMYYYY(dt_to_date);

        let isValid = true;

        // Validate From Date
        if (!isValidDate(dt_from_date)) {
            $('#error_dt_from_date').text("Invalid From Date").show();
            isValid = false;
        } else {
            $('#error_dt_from_date').hide();
        }

        // Validate To Date
        if (!isValidDate(dt_to_date)) {
            $('#error_dt_to_date').text("Invalid To Date").show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }

        // Convert back to YYYY-MM-DD format for accurate date comparison
        const fromDate = new Date(dt_from_date.split('-').reverse().join('-'));
        const toDate = new Date(dt_to_date.split('-').reverse().join('-'));

        // Check if From Date is greater than To Date
        if (fromDate > toDate) {
            $('#error_dt_to_date').text("To Date cannot be smaller than From Date").show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }

        return isValid;
    }

    // ****-------show data Function --------------***
    const FnShowCottonBaleData = async (page, pageSize) => {

        debugger
        try {
            let isValidFilters = FnChkIsValidFilters();
            if (!isValidFilters) { return false; }
            setIsLoading(true)
            let commonIds = {};
            // Common Ids 
            commonIds.plant_id = cmb_unit_plant_id;
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            console.log("commonIds", commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/FnGetBalesWeightageReport/${page}/${pageSize}`, requestOptions)
            // const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/FnGetBalesWeightageReport`, requestOptions)
            const responce = await apiCall.json();

            if (responce.CottonBalesWeightage.length > 0) {
                const total = responce.CottonBalesWeightage[0].total_count;
                setpageCount(parseInt(pageSize) !== 0 ? Math.ceil(total / pageSize) : 1);
                setCottonBaleWeightageStockReportData(responce.CottonBalesWeightage)
            } else {
                setCottonBaleWeightageStockReportData([])
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }



    const renderBalesMixingChartWtgTbl = useMemo(() => {
        return <Table className='erp_table' id="balesmixingchartTblId" hover bordered striped>
            <thead className="erp_table_head">
                <tr>

                    <td className="erp_table_th " style={{ width: "50px" }}>Sr. No.</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Mixing Chart No.</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Mixing Chart Dt.</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>UHML</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>ML</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>UI</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>MIC</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>STR</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>RD</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>B+</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>TOTAL<br /> NEPS</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>SC(N)</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>SFC(N)</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>TRASH</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>MOISTURE</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>Issued<br></br>Quantity</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>Actual Issued<br></br>Weight</td>
                </tr>
            </thead>
            <tbody>
                {
                    cottonBaleWeightageStockReportData?.map((data, indexOfItem) => (
                        <tr rowIndex={indexOfItem}>

                            <td className="erp_table_td">{indexOfItem + 1}</td>
                            <td className="erp_table_td">{data.mixing_chart_no}</td>
                            <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(data.mixing_chart_date)}</td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.uhml, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.mi, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.ul, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.mic, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.str, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.rd, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.b_plus, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.total_neps, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.sc_n, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.sfc_n, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.trash, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.moisture, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.total_issued_weight, 4)} </td>
                            <td className="erp_table_td">{validateNumberDateInput.current?.decimalNumber(data.total_actual_issue_weight, 4)} </td>
                        </tr>
                    ))
                }
                <tr><td className="erp_table_head erp_table_th" colSpan={15}> Totals </td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((cottonBaleWeightageStockReportData.reduce((acc, data) => acc + parseFloat(data['total_issued_weight']), 0)), 4)}</td>
                    <td className="erp_table_head erp_table_th" >{validateNumberDateInput.current?.decimalNumber((cottonBaleWeightageStockReportData.reduce((acc, data) => acc + parseFloat(data['total_actual_issue_weight']), 0)), 4)}</td>

                </tr>

                {/* Mixing Chart Calculations */}
                {mixingChartCalculations.length > 0 ? mixingChartCalculations?.map((data, index) => (
                    <tr>
                        <td className="erp_table_head erp_table_th" colSpan={'3'}>{headers[index]}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.uhml, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.mi, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.ul, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.mic, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.str, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.rd, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.b_plus, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.total_neps, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.sc_n, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.sfc_n, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.trash, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.moisture, 4)} {index === 4 ? "%" : ''}</td>
                        <td className="erp_table_head erp_table_th" colSpan={2}></td>
                    </tr>
                )) : ''}
            </tbody>
        </Table>
    }, [cottonBaleWeightageStockReportData, mixingChartCalculations]);


    useEffect(() => {
        if (cottonBaleWeightageStockReportData.length > 0) {
            const termsArray = ['uhml', 'ul', 'mi', 'mic', 'str', 'rd', 'b_plus', 'total_neps', 'sc_n', 'sfc_n', 'trash', 'moisture'];

            let calculationsOfMixingChart = [
                {}, // Averages
                {}, // Max values
                {}, // Min values
                {}, // Difference (max - min)
                {}, // Standard deviation / Mean

            ];

            let totalIssueQuantity = 0;

            // Calculate total issue quantity
            cottonBaleWeightageStockReportData.forEach((obj) => {
                if (obj.issue_quantity) {
                    totalIssueQuantity += Number(obj.issue_quantity);
                }
            });

            // First pass: Compute sum, max, min, and weighted sum
            cottonBaleWeightageStockReportData.forEach((obj) => {
                Object.keys(obj).forEach((key) => {
                    if (termsArray.includes(key)) {
                        if (!calculationsOfMixingChart[0][key]) {
                            calculationsOfMixingChart[0][key] = 0;
                            calculationsOfMixingChart[1][key] = Number.MIN_VALUE;
                            calculationsOfMixingChart[2][key] = Number.MAX_VALUE;
                            calculationsOfMixingChart[4][key] = 0; // Standard deviation placeholder

                        }

                        let value = Number(obj[key]);
                        let issueQuantity = Number(obj.issue_quantity) || 0;

                        // Sum for average
                        calculationsOfMixingChart[0][key] += value;
                        // Maximum
                        calculationsOfMixingChart[1][key] = Math.max(calculationsOfMixingChart[1][key], value);
                        // Minimum
                        calculationsOfMixingChart[2][key] = Math.min(calculationsOfMixingChart[2][key], value);

                    }
                });
            });

            // Compute final values
            Object.keys(calculationsOfMixingChart[0]).forEach((key) => {
                let count = cottonBaleWeightageStockReportData.length;
                let mean = count > 0 ? calculationsOfMixingChart[0][key] / count : 0;
                calculationsOfMixingChart[0][key] = mean; // Store average

                // Compute Variance for Standard Deviation
                let validValues = cottonBaleWeightageStockReportData
                    .map((obj) => Number(obj[key]))
                    .filter((value) => !isNaN(value));

                let variance = validValues.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0);
                variance /= count > 1 ? (count - 1) : count; // Use (n-1) for sample SD

                calculationsOfMixingChart[4][key] = (Math.sqrt(variance) / mean) * 100; // Store standard deviation / mean

                // Difference calculation (max - min)
                calculationsOfMixingChart[3][key] = calculationsOfMixingChart[1][key] - calculationsOfMixingChart[2][key];


            });

            setMixingChartCalculations(calculationsOfMixingChart);
            // calulationsForMXC.current = false;
        }
    }, [cottonBaleWeightageStockReportData]);


    // ********* ExportToExcel Functionalities Starts. ****************************************

    const FnExportToExcel = () => {
        debugger
        const headers1 = [
            "Sr. No.", "Mixing Chart No", "Mixing Chart Dt.", "UHML", "ML", "UI", "MIC", "STR", "RD", "B+", "TOTAL NEPS",
            "SC(N)", "SFC(N)", "TRASH", "MOISTURE", "Issued Quantity", "Actual Issued Weight"
        ];

        const headerStyle = {
            font: { bold: true },
            alignment: { horizontal: "center", vertical: "center", wrapText: true },
            border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } }
        };

        const cellStyle = {
            alignment: { horizontal: "center", vertical: "center" },
            border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } }
        };

        const titleStyle = {
            font: { bold: true, sz: 16 },
            alignment: { horizontal: "center", vertical: "center" },
            border: { bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } }
        };

        // const customHeading = `Cotton Bales Weightage Report - ${txt_mixing_chart_no.toUpperCase()} (${validateNumberDateInput.current.formatDateToDDMMYYYY(mixing_chart_date)})`;
        const customHeading = `Cotton Bales Weightage Report`;


        const worksheetData = [
            [{ v: customHeading, s: titleStyle }].concat(Array(21).fill(null)), // Title row centered
            Array(17).fill(null), // Blank row
            headers1.map(header => ({ v: header, s: headerStyle }))
        ];

        cottonBaleWeightageStockReportData.forEach((details, index) => {
            worksheetData.push([
                { v: index + 1, s: cellStyle },
                { v: details.mixing_chart_no ?? "", s: cellStyle },
                { v: `${validateNumberDateInput.current.formatDateToDDMMYYYY(details.mixing_chart_date)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.uhml, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.mi, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.ul, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.mic, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.str, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.rd, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.b_plus, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.total_neps, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.sc_n, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.sfc_n, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.trash, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.moisture, 2)}`, s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.total_issued_weight, 2)}` ?? "", s: cellStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(details.total_actual_issue_weight, 2)}`, s: cellStyle }
            ]);
        });

        // Adding total row
        worksheetData.push([
            { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle },
            { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle },
            { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle }, { v: "", s: cellStyle },
            { v: "", s: cellStyle }, { v: "", s: cellStyle },
            { v: "Totals", s: { ...headerStyle, font: { bold: true } } },
            { v: validateNumberDateInput.current.decimalNumber(cottonBaleWeightageStockReportData.reduce((acc, data) => acc + parseFloat(data['total_issued_weight']), 0), 4), s: cellStyle },
            { v: validateNumberDateInput.current.decimalNumber(cottonBaleWeightageStockReportData.reduce((acc, data) => acc + parseFloat(data['total_actual_issue_weight']), 0), 4), s: cellStyle }
        ]);

        // Adding two blank rows as space between tables
        worksheetData.push([]);

        const headers2 = headers

        mixingChartCalculations.forEach((data, index) => {
            worksheetData.push([
                null,
                null,
                { v: headers2[index], s: headerStyle },
                { v: `${validateNumberDateInput.current.decimalNumber(data.uhml, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.mi, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.ul, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.mic, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.str, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.rd, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.b_plus, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.total_neps, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.sc_n, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.sfc_n, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.trash, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } },
                { v: `${validateNumberDateInput.current.decimalNumber(data.moisture, 2)}${index === 4 ? "%" : ""}`, s: { ...cellStyle, font: { bold: true } } }
            ]);
        });

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Merge the title row across all columns
        const mergeTitleRange = { s: { r: 0, c: 0 }, e: { r: 0, c: 16 } };
        if (!worksheet['!merges']) worksheet['!merges'] = [];
        worksheet['!merges'].push(mergeTitleRange);

        worksheet["!rows"] = [{ hpt: 22 }, { hpt: 22 }, { hpt: 30 }]
            .concat(worksheetData.slice(3, -1).map(() => ({ hpt: 21.5 })))
            .concat([{ hpt: 30 }]);        // Setting column widths
        worksheet['!cols'] = [{ wch: 7.5 }] // Sr. No.
            .concat({ wch: 18 }) // Supplier Lot No
            .concat({ wch: 13 }) // Mill Lot No
            .concat(Array(13).fill({ wch: 7.5 })) // Columns 4 to 16
            .concat(Array(7).fill({ wch: 12 })); // Columns 17 onwards

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Mixing Chart");

        // XLSX.writeFile(workbook, `cotton_bottom_weightage_${validateNumberDateInput.current.formatDateToDDMMYYYY(mixing_chart_date)}.xlsx`);
        XLSX.writeFile(workbook, `cotton_bottom_weightage.xlsx`);

    };
    // ********* ExportToExcel Functionalities Ends. ****************************************

    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = async () => {
        try {
            // Fetch the data before printing
            await FnShowCottonBaleData(0, 1000);
            // Trigger the print after the data has been fetched
            FnPrintIssueReportSheet();
        } catch (error) {
            console.error('Error fetching data for print:', error);
        }
    };


    const handlePageCountClick = async () => {
        let count = document.getElementById("page_entries_id").value;
        setEntriesPerPage(count)
        setcurrentPage(0)
        await FnShowCottonBaleData(0, count);
    }

    const handlePageClick = async (pageNo) => {
        debugger
        let currentPage = pageNo.selected;
        setcurrentPage(currentPage);
        let offset = currentPage * entriesPerPage;
        await FnShowCottonBaleData(offset, entriesPerPage);
    }

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'>
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Bottom Stock Report
                                {
                                    `(${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_from_date)} to ${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_to_date)})`
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    let printTimeout;  // Store timeout reference

    const FnPrintIssueReportSheet = useReactToPrint({
        content: () => {
            $('.erp_pagination').hide()

            const headerHtml = printOutHeader();

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml;
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px';
                }
            }

            // Inject landscape print styles dynamically
            const style = document.createElement('style');
            style.innerHTML = `
                        @media print {
                            @page {
                                size: landscape;
                            }
                        }
                    `;
            document.head.appendChild(style);

            printTimeout = setTimeout(() => {
                handlePrintCancel();
            }, 500);
        },

        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            $('.erp_pagination').show();
            clearTimeout(printTimeout);
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: `Cotton Bales Weightage Stock Report_${`Raw_material_stock`}_(${dt_from_date}_to_${dt_to_date})`
    });


    const handlePrintCancel = async () => {
        console.log('Print was canceled.');

        try {
            await FnShowCottonBaleData(PageCurrent, entriesPerPage);
            console.log('Data reloaded after print cancel.');
        } catch (error) {
            console.error('Error fetching data after print cancel:', error);
        }
    };

    useEffect(() => {
        const dateInput = document.getElementById('dt_from_date');
        if (dateInput) {
            setFromDt(dateInput.value);
        }
    }, []);


    //**********************************Print Functionality Ends Here************************************************************ */


    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                {/* <ExcelExport ref={exlsExp} /> */}

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card mt-4'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Cotton Bales Weightage Report</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">

                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDt(e.target.value); }} />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDt(e.target.value); FnChkIsValidFilters(); }} max={currentDate} />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Plant Name </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_unit_plant_id}
                                            onChange={(e) => { setUnitPlantId(e.target.value); }}>
                                            <option disabled>Select</option>
                                            <option value={0}>All</option>
                                            {unitPlantOptions.length > 0 &&
                                                unitPlantOptions.map((plant) => (
                                                    <option key={plant.plant_id} value={plant.plant_id}>
                                                        {plant.plant_name}
                                                    </option>
                                                ))}
                                        </select>

                                        <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-2">
                                <div className="col text-center">
                                    <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowCottonBaleData(0, 1000); }} variant="button" fontWeight="regular">show Data</MDButton>
                                </div>
                            </div>

                        </div>

                        <hr />
                        <div className='row pt-1'>
                            <div className="card-header py-0 ms-2 p-1 main_heding mb-0">
                                <label className="erp-form-label-md-lg">Mixing Chart Weightage Details</label>
                            </div>
                            <div className='col erp_form_col_div mt-2 p-2'>
                                {/* {renderBalesMixingChartWtgTbl} */}

                                {
                                    cottonBaleWeightageStockReportData.length === 0
                                        ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                        :
                                        <>
                                            <hr />
                                            <div className="row py-1">
                                                <div className="col-sm-6 page_entries">

                                                </div>
                                                <div className="col-sm-6 text-end">
                                                    <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                        onClick={(e) => { FnExportToExcel(); }}
                                                    >Export Excel</MDButton>

                                                    <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                        onClick={(e) => { handlePrint(); }}
                                                    >Print</MDButton>
                                                </div>
                                            </div>
                                            <div className="row px-lg-2 d-block  mt-1">
                                                <div className="card p-0">

                                                    <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                        {renderBalesMixingChartWtgTbl}
                                                    </div>
                                                </div>
                                                {/*  {pageCount !== 1 ?
                                                                        <ReactPaginate
                                                                            className='erp_pagination'
                                                                            marginPagesDisplayed={2}
                                                                            pageRangeDisplayed={3}
                                                                            pageCount={pageCount}
                                                                            onPageChange={handlePageClick}
                                                                            containerClassName={"pagination justify-content-center"}
                                                                            pageClassName={"page-item"}
                                                                            pageLinkClassName={"page-link erp-gb-button"}
                                                                            previousClassName={"page-item"}
                                                                            previousLinkClassName={"page-link erp-gb-button"}
                                                                            nextClassName={"page-item"}
                                                                            nextLinkClassName={"page-link erp-gb-button"}
                                                                            breakClassName={"page-item"}
                                                                            breakLinkClassName={"page-link"}
                                                                            activeClassName={"active"} /> : null}*/}
                                            </div>
                                        </>
                                }

                                <hr />
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}
                </div>
                {/* Card Ends*/}


            </DashboardLayout >
        </>
    )
}
export default FrmCottonBalesWeightageReport