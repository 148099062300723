import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import { useReactToPrint } from 'react-to-print';
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Import for the DatePicker.
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ExcelExport from "Features/Exports/ExcelExport";

function DateWiseAttendance() {

    // Global Constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, UserId, DEPARTMENT_ID, USER_CODE } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    const validateNumberDateInput = useRef();
    const exlsExp = useRef();

    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    // ****** navigate
    const navigate = useNavigate();

    // combo's options hooks;
    const [employeeProps, setEmployeeProps] = new useState([]);
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);
    const [txt_employee_code, setEmployeeCode] = useState();
    const [userDetails, setUserDetails] = useState([]);
    // Input field's Hooks;
    const [dt_att_from, setAttFrom] = useState('');
    const [dt_att_to, setAttTo] = useState('');
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');
    const [contractorOptions, setContractorOption] = useState([]);
    const [cmb_contractor_id, setContractorId] = useState('');
    const cmb_contractor_id_ref = useRef()

    // Hooks For table;
    const [attendanceDtlTblData, setAttendanceDtlTblData] = useState([]);

    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillComobos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);

    const FnFillComobos = async () => {
        debugger
        try {
            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "property_group", operator: "=", value: 'S' });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    setEmployeeProps(rcvdEmplTypes);
                    let emplTypes = rcvdEmplTypes
                        .filter(prop => prop.properties_master_name === 'EmployeeTypeGroup')
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
                    setEmployeeTypesOpts(emplTypes);
                });

            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                });
            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code", "employee_type_group"];
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "employee_id", operator: "=", value: UserId });
            // if (DEPARTMENT_ID != 11) {
            //     globalQuery.conditions.push({ field: "reporting_to", operator: "=", value: UserId });
            // }
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let getEmployeeList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setUserDetails(getEmployeeList);
            // if (getEmployeeList.length > 0) {
            //     getEmployeeList = getEmployeeList?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
            //     getEmployeeList.unshift({ employee_id: '', value: 'All', label: 'All' });
            //     setEmployeeOpts(getEmployeeList);


            //     // if (DEPARTMENT_ID === 11) {
            //         setEmployeeId('All');
            //         employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
            //     // } else {
            //     //     setEmployeeId(UserId);
            //     //     setEmployeeCode(USER_CODE);
            //     //     employeeComboRef.current = { employee_id: UserId, value: UserId, label: 'All' };
            //     //     setEmployeeTypeId(getEmployeeList[1].employee_type_group)
            //     //     employeeTypesComboRef.current = getEmployeeList[1].employee_type_group;
            //     // }

            // } else {
            //     setEmployeeOpts([]);
            //     setEmployeeId('');
            //     employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
            // }

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"]
            globalQuery.table = "cmv_contractor"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: "0" });
            comboDataFunc.current.fillFiltersCombo(globalQuery).then((contractorList) => {
                // combobox.current.fillMasterData("cmv_contractor", "", "").then((contractorList) => {
                const contractors = [
                    { value: '', label: 'Select' },
                    ...contractorList.map((contractor) => ({ ...contractor, value: contractor.field_id, label: contractor.field_name, })),
                ];
                setContractorOption(contractors);
            })
        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    const FnCombosOnChange = async (comboName, comboVal) => {
        try {
            setIsLoading(true);

            switch (comboName) {
                case 'AttDateFrom':
                    const formattedFromDate = comboVal ? comboVal.toLocaleDateString('en-CA') : '';
                    if (formattedFromDate !== '') { document.getElementById('error_dt_att_from').style.display = 'none'; }
                    setAttFrom(formattedFromDate);
                    // If the start date is set after the end date, clear the end date
                    if (dt_att_to && new Date(formattedFromDate) > new Date(dt_att_to)) {
                        setAttTo('');
                    }
                    break;

                case 'AttDateTo':
                    const formattedToDate = comboVal ? comboVal.toLocaleDateString('en-CA') : '';
                    if (formattedToDate !== '') { document.getElementById('error_dt_att_to').style.display = 'none'; }
                    setAttTo(formattedToDate)
                    break;

                case "Department":
                    setEmployeeCode('');
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // remove the already selected sub-department
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    // await FnLoadEmployees();
                    await FnSetDefaultData();
                    break;

                case "SubDepartment":
                    setEmployeeCode('');
                    // await FnLoadEmployees();
                    await FnSetDefaultData();
                    break;
                case 'Contractor':
                    await FnSetDefaultData();
                    break;
                case "EmployeeType":
                    setEmployeeCode('');
                    // await FnLoadEmployees();
                    await FnSetDefaultData();
                    break;
                case 'EmployeeCode':
                    debugger
                    var serachemployeeCode = $('#txt_employee_code').val();
                    setEmployeeCode(serachemployeeCode);
                    let findEmployee = null;

                    if (serachemployeeCode.length >= 2) {
                        findEmployee = employeeOpts.find((employee) => {
                            return employee.employee_code === serachemployeeCode
                                || employee?.label?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                                || employee?.old_employee_code === serachemployeeCode
                            // || employee?.old_employee_code?.includes(serachemployeeCode)
                        });
                    }
                    if (findEmployee) {
                        setEmployeeId(findEmployee.employee_id);
                        employeeComboRef.current = {
                            employee_id: findEmployee.employee_id,
                            employee_code: findEmployee.employee_code,
                        };
                    } else {
                        setEmployeeId('');
                        employeeComboRef.current = {
                            employee_id: '',
                            employee_code: '',
                        };
                    }
                    break;
                case 'EmplCode':
                    // setEmployeeCode(employeeComboRef.current.employee_code);
                    if (employeeComboRef.current && employeeComboRef.current.employee_code) {
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    }

                    break;
                case 'Employee':
                    debugger
                    var employeeID = employeeComboRef.current?.employee_id;
                    if (employeeID !== "") {
                        // var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                        setEmployeeId(employeeComboRef.current.employee_id);
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    } else {
                        setEmployeeCode('');
                    }

                default:
                    break;
            }
            setIsLoading(false);

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    // const FnLoadEmployees = async () => {
    //     try {
    //         let selectedEmplType = employeeTypesComboRef.current?.field_name || null;
    //         let selectedDepartment = departmentComboRef.current?.department_id || null;
    //         let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;

    //         // Filters for load employee query.
    //         let filterConditions = [
    //             { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
    //             { field: "is_delete", operator: "=", value: 0 },
    //         ];

    //         if (selectedDepartment !== null && selectedDepartment !== '') {
    //             filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
    //         } else {
    //             let defaultDept = departmentOpts.find(department => department.value == 'All');
    //             setDepartmentId(defaultDept.value);
    //             departmentComboRef.current = defaultDept;
    //         }

    //         if (selectedSubDepartment !== null && selectedSubDepartment !== '') {
    //             filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
    //         } else {
    //             const subDepartmentList = allDepartmentsList.filter(department =>
    //                 (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
    //             );
    //             subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
    //             setSubDepartmentOpts(subDepartmentList);
    //             subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
    //             setSubDepartmentId('All');
    //         }

    //         if (selectedEmplType !== null && selectedEmplType !== 'All') {
    //             filterConditions.push({ field: "employee_type", operator: "=", value: selectedEmplType });
    //         } else {
    //             let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
    //             setEmployeeTypeId(defaultEmplType.value);
    //             employeeTypesComboRef.current = defaultEmplType;
    //             let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All").map(item => item?.field_name);
    //             filterConditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
    //         }

    //         resetGlobalQuery();
    //         globalQuery.columns = ["employee_id", "employee_code", "employee_name"];
    //         globalQuery.table = "cmv_employee"
    //         globalQuery.conditions = filterConditions;
    //         comboDataFunc.current.fillFiltersCombo(globalQuery)
    //             .then(rcvdEmpls => {
    //                 rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
    //                 rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
    //                 setEmployeeOpts(rcvdEmpls);
    //             });
    //         employeeComboRef.current = null;
    //         setEmployeeId('All');

    //     } catch (error) {
    //         console.log("error: ", error)
    //         navigate('/Error')
    //     }
    // }

    const FnSetDefaultData = async () => {
        try {
            // Filters for load employee query.
            let filterConditions = [
                { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "is_delete", operator: "=", value: 0 },
            ];
            let contractorId = cmb_contractor_id_ref.current.value;

            // First Set Default Department.
            let selectedDepartment = departmentComboRef.current?.department_id;
            if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
                filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
            } else if (selectedDepartment === '' || selectedDepartment === undefined || selectedDepartment === null) {
                let defaultDept = departmentOpts.find(department => department.value == 'All');
                setDepartmentId(defaultDept.value);
                departmentComboRef.current = defaultDept;

                // Also Load All Sub-Department
                const subDepartmentList = allDepartmentsList.filter(department => department.department_type === "S");
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
                selectedDepartment = '';
            }

            // Second Set Default Sub-Department.
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
            if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
                filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
            } else {
                const subDepartmentList = allDepartmentsList.filter(department =>
                    (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                );
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
            }

            // Third Set Default Employee Type.
            let selectedEmplType = employeeTypesComboRef.current.field_name;
            if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                filterConditions.push({ field: "employee_type_group", operator: "=", value: selectedEmplType });
            } else {
                let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                setEmployeeTypeId(defaultEmplType.value);
                employeeTypesComboRef.current = defaultEmplType;
                let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                    ?.map(item => item?.field_name)
                    ?.filter(id => id !== undefined && id !== null && id !== '');;
                filterConditions.push({ field: "employee_type_group", operator: "IN", values: emplTypes });
            }

            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
            globalQuery.table = "cmv_employee"
            debugger;

            if (DEPARTMENT_ID != 11 && UserId != 1 ) {
                filterConditions.push({ field: "reporting_to", operator: "=", value: UserId });
            }
                      
            // if (DEPARTMENT_ID != 11) {
            //     filterConditions.push({ field: "reporting_to", operator: "=", value: UserId });
            // }
            
            if (contractorId !== undefined && contractorId !== null && contractorId !== '') {
                filterConditions.push({ field: "contractor_id", operator: "=", value: contractorId });
            }
            filterConditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions = filterConditions;
            let getEmployeeList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            // if (getEmployeeList.length > 0) {
            getEmployeeList = getEmployeeList?.map(emp => ({ ...emp, value: emp.employee_id, label: `[${emp.old_employee_code}] [${emp.employee_code}] ${emp.employee_name}`}));
            getEmployeeList.unshift({ employee_id: '', value: 'All', label: 'All' });
            // setEmployeeOpts(getEmployeeList);
            const filteredUserDetails = userDetails.filter(user =>
                !getEmployeeList.some(emp => emp.employee_id === user.employee_id)
            );

            // Add userDetails to the employee options without duplicates
            const mergedEmployeeList = [
                ...getEmployeeList,
                ...filteredUserDetails.map(user => ({
                    ...user,
                    value: user.employee_id, // Assuming 'id' exists in userDetails
                    label: user.employee_name // Assuming 'name' exists in userDetails
                }))
            ];
            if (mergedEmployeeList.length > 0) {
                setEmployeeOpts(mergedEmployeeList);
                // if (DEPARTMENT_ID === 11) {
                setEmployeeId('All');
                employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
                // } else {
                //     setEmployeeId(UserId);
                //     setEmployeeCode(USER_CODE);
                //     employeeComboRef.current = { employee_id: UserId, value: UserId, label: 'All' };
                // }

            } else {
                setEmployeeOpts([]);
                setEmployeeId('');
                employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // ****-------Get Attendance Data Functions Starts--------------***
    const FnShowAttendanceData = async () => {
        debugger
        try {
            let isValidFilters = await FnChkIsValidFilters();
            if (!isValidFilters) { return false; }

            // *** Means Filters are valid then filter data as per the filters.
            setIsLoading(true);
            let filterConditions = [
                { field: "htatdl.att_date_time", operator: ">=", value: dt_att_from },
                { field: "htatdl.att_date_time", operator: "<=", value: dt_att_to },
                { field: "htatdl.company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "htatdl.is_delete", operator: "=", value: 0 },
            ];

            // *** Apply Filters Conditions;
            let selectedDepartment = departmentComboRef.current?.department_id;
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
            let selectedEmplType = employeeTypesComboRef.current.field_name;
            let selectedEmployee = employeeComboRef.current.employee_id;
            let contractorId = cmb_contractor_id_ref.current.value;

            // Check it selected one employee or not? 
            if (selectedEmployee !== undefined && selectedEmployee !== null && selectedEmployee !== '') {
                filterConditions.push({ field: "htatdl.employee_id", operator: "=", value: selectedEmployee });
                // filterConditions.push({ field: "empl.reporting_to", operator: "=", value: UserId });
            } else {
                // Means load data without employee filter.
                // If Valid Then set the default data if not selected any filter.
                await FnSetDefaultData();

                if (selectedEmployee === '') {
                    const employeeIds = employeeOpts
                        .filter(emp => emp.employee_id !== "") // Remove entries where employee_id is an empty string
                        .map(emp => emp.employee_id);

                    if (employeeIds.length === 1) {
                        // If only one employee_id, use "=" operator with a single value
                        filterConditions.push({
                            field: "empl.employee_id",
                            operator: "=",
                            value: employeeIds[0] // Single value, no need for join
                        });
                    } else if (employeeIds.length > 1) {
                        // If multiple employee_ids, use "IN" with a comma-separated string
                        filterConditions.push({
                            field: "empl.employee_id",
                            operator: "IN",
                            values: employeeIds.map(id => (isNaN(id) ? `"${id}"` : id)) // Enclose non-numeric IDs in quotes
                        });
                    }
                }
                if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
                    filterConditions.push({ field: "empl.department_id", operator: "=", value: selectedDepartment });
                }
                if (selectedEmplType === 'Workers' && contractorId !== undefined && contractorId !== null && contractorId !== '') {
                    filterConditions.push({ field: "empl.contractor_id", operator: "=", value: contractorId });
                }
                // if (DEPARTMENT_ID !== 11) {
                //     filterConditions.push({ field: "empl.employee_id", operator: "=", value: UserId });
                //     // filterConditions.push({ field: "empl.reporting_to", operator: "=", value: UserId });
                // }
                if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
                    filterConditions.push({ field: "empl.sub_department_id", operator: "=", value: selectedSubDepartment });
                }
                // if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                //     filterConditions.push({ field: "empl.employee_type", operator: "=", value: selectedEmplType });
                // }
                if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                    filterConditions.push({ field: "empl.employee_type_group", operator: "=", value: selectedEmplType });
                } else {
                    let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                    setEmployeeTypeId(defaultEmplType.value);
                    employeeTypesComboRef.current = defaultEmplType;
                    let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All").map(item => item?.field_name);
                    filterConditions.push({ field: "empl.employee_type_group", operator: "IN", values: emplTypes });
                }
            }

            resetGlobalQuery();
            globalQuery.columns = [
                'empl.employee_name', 'empl.department_name', 'htatdl.attendance_date',
                'htatdl.attendance_month', 'htatdl.attendance_year', 'htatdl.shift_scheduled', 'htatdl.shift_present', 'htatdl.job_type_id',
                'htatdl.job_type', 'htatdl.job_type_code_short_name', 'htatdl.in_time', 'htatdl.out_time', 'htatdl.att_date_time',
                'htatdl.working_minutes', 'htatdl.late_mark_flag', 'htatdl.early_go_flag', 'htatdl.gate_pass_flag',
                'htatdl.employee_code', 'empl.old_employee_code', 'htatdl.attendance_flag','mcorr.punch_type'
                // 'htatdl.week_off_present_flag', 'htatdl.night_shift_present_flag', 'htatdl.remark', 
                // 'htatdl.present_flag', 'htatdl.shift_id', 'htatdl.attendance_flag', 'htatdl.employee_type',
                // 'htatdl.company_id', 'htatdl.company_branch_id', 'htatdl.daily_attendance_id', 'htatdl.employee_id', 
            ];
            globalQuery.table = "ht_attendance_daily htatdl";
            globalQuery.conditions = filterConditions;
            globalQuery.joins = [
                {
                    "table": `cmv_employee empl`,
                    "type": "LEFT",
                    "on": [
                        { "left": "empl.employee_id", "right": "htatdl.employee_id", },
                        { "left": "empl.company_id", "right": "htatdl.company_id", },
                        { "left": "empl.is_active", "right": 1, },
                    ]
                },
                {
                    "table": `ht_misspunch_correction mcorr`,
                    "type": "LEFT",
                    "on": [
                        { "left": "mcorr.employee_code", "right": "htatdl.employee_code", },
                        { "left": "mcorr.company_id", "right": "htatdl.company_id", },
                        { "left": "mcorr.att_date_time", "right": "htatdl.att_date_time", },
                        { "left": "mcorr.is_delete", "right": 0, },
                    ]
                },
            ]
            globalQuery.orderBy = ['empl.department_id DESC', 'empl.sub_department_id DESC', 'empl.employee_code Asc', 'htatdl.att_date_time'];
            let getAttendanceData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            if (getAttendanceData.length > 0) {
                getAttendanceData = getAttendanceData.map((record, index) => ({
                    sr_no: index + 1,
                    ...record,
                }));

                setAttendanceDtlTblData(getAttendanceData);
                console.table(getAttendanceData);
            } else {
                setAttendanceDtlTblData([]);
            }

            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // From Attendace Record Create It's Extra Remark.
    const FnGetAttendanceExtraRemark = (attendanceRecord) => {
        let extraRemark = '';
        if (attendanceRecord) {
            if (attendanceRecord?.late_mark_flag === "Y") { extraRemark += "L/"; }
            if (attendanceRecord?.early_go_flag === "Y") { extraRemark += "E/"; }
            if (attendanceRecord?.gate_pass_flag === "Y") { extraRemark += "G/"; }

            // Remove the trailing '/'
            if (extraRemark.length > 0) { extraRemark = extraRemark.substring(0, extraRemark.length - 1); }
        }
        return extraRemark;
    }

    const FnRenderAttendanceTbl = useMemo(() => {
        if (attendanceDtlTblData.length === 0) { return null; }

        return (
            <>
                <style>
                    {`
                    @media print {
                        .print-content {
                            padding: 0;
                            box-sizing: border-box;
                        }
                        @page {
                            margin: 0;
                            size: A4;
                            margin: 2%;
                            border: 2px solid black;
                        }
                        html, body {
                            width: 100%;
                            height: 100%;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .erp_table {
                        font-size: 3px !important; /* Default font size */
                    }
                    .erp_table_th, .erp_table_td {
                        border: 1px solid black;
                        padding-top: 2px !important;
                        padding-bottom: 2px !important;
                    }
                `}
                </style>

                <Table className="erp_table" id='MonthlySalaryDtlsTbl' style={{ whiteSpace: 'normal' }} ref={printComponentRef}>
                    <thead className="erp_table_head thead-light text-center" style={{ whiteSpace: 'normal' }} >
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th">Sr. No.</th>
                            <th className="erp_table_th">Department</th>
                            <th className="erp_table_th">Employee Name</th>
                            <th className="erp_table_th">Attendance Date</th>

                            <th className="erp_table_th">In Time</th>
                            <th className="erp_table_th">Out Time</th>
                            <th className="erp_table_th">Worked Hrs.</th>

                            <th className="erp_table_th">Present Status</th>
                            <th className="erp_table_th">Job Type Code</th>
                            <th className="erp_table_th">Extra Remark</th>

                            <th className="erp_table_th">Employee Code</th>
                            <th className="erp_table_th">Punch Code</th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            attendanceDtlTblData?.map((attendanceRecord, index) => {
                                // // Logic for generating Extra Remark based on flags
                                // let extraRemark = '';
                                // if (attendanceRecord.late_mark_flag === "Y") { extraRemark += "L/"; }
                                // if (attendanceRecord.early_go_flag === "Y") { extraRemark += "E/"; }
                                // if (attendanceRecord.gate_pass_flag === "Y") { extraRemark += "G/"; }
                                // if (extraRemark.length > 0) { extraRemark = extraRemark.substring(0, extraRemark.length - 1);} // Remove the trailing '/'

                                return (
                                    <tr key={index} rowIndex={index}>
                                        <td className="erp_table_td">{index + 1}</td>
                                        <td className="erp_table_td">{attendanceRecord.department_name}</td>
                                        <td className="erp_table_td">{attendanceRecord.employee_name}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(attendanceRecord.att_date_time)}</td>

                                        {/* <td className="erp_table_td">{attendanceRecord.in_time}</td>
                                        <td className="erp_table_td">{attendanceRecord.out_time}</td> */}
                                        {/* <td className="erp_table_td" style={{
                                            backgroundColor: attendanceRecord.attendance_flag === "M" && attendanceRecord.in_time ? "#8B0000" : "inherit", // Dark red background for the entire cell
                                            color: attendanceRecord.attendance_flag === "M" && attendanceRecord.in_time ? "#FFFFFF" : "inherit" // Optional: Change text color to white for better contrast
                                        }}> */}
                                        <td className="erp_table_td" style={{
                                            backgroundColor: attendanceRecord.punch_type === "In" && attendanceRecord.attendance_flag === "M" && attendanceRecord.in_time ? "#8B0000" : "inherit", // Dark red background for the entire cell
                                            color: attendanceRecord.attendance_flag === "M" && attendanceRecord.punch_type === "In"&& attendanceRecord.in_time ? "#FFFFFF" : "inherit" // Optional: Change text color to white for better contrast
                                        }}>
                                            {attendanceRecord.in_time}
                                        </td>
                                        <td className="erp_table_td" style={{
                                            color: attendanceRecord.attendance_flag === "M"&&attendanceRecord.punch_type === "Out" && attendanceRecord.out_time ? "#FFFFFF" : "inherit",
                                            backgroundColor: attendanceRecord.attendance_flag === "M"&&attendanceRecord.punch_type === "Out" && attendanceRecord.out_time ? "#8B0000" : "inherit",
                                        }}>
                                            {attendanceRecord.out_time}
                                        </td>
                                        <td className="erp_table_td">{attendanceRecord.working_minutes}</td>

                                        <td className="erp_table_td text-center fw-bold">{attendanceRecord.job_type}</td>
                                        <td className="erp_table_td text-center fw-bold">{attendanceRecord.job_type_code_short_name}</td>
                                        {/* <td className="erp_table_td text-center fw-bold">{extraRemark}</td> */}
                                        <td className="erp_table_td text-center fw-bold">{FnGetAttendanceExtraRemark(attendanceRecord)}</td>

                                        <td className="erp_table_td">{attendanceRecord.employee_code}</td>
                                        <td className="erp_table_td">{attendanceRecord.old_employee_code}</td>

                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </>
        )
    }, [attendanceDtlTblData])

    // ***----------- Helping Functions.
    const goBack = () => {
        navigate("/Dashboard")
    }

    // Get Current DateTime
    const FnGetCurrentDateTime = () => {
        // Get the current date and time
        let currentDate = new Date();
        // Format the current date and time as "DD-MM-YYYY HH:mm:ss"
        let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        return formattedDateTime;
    }

    // ****-------Validation Functions Starts--------------***
    // Function for validate the fields at the time of button click;
    const FnChkIsValidFilters = async () => {
        if (dt_att_from === '') {
            $('#error_dt_att_from').text('Please select the attendance from date...!')
            $('#error_dt_att_from').show();
            return false;
        } else {
            $('#error_dt_att_from').hide();
        }

        if (dt_att_to === '') {
            $('#error_dt_att_to').text('Please select the attendance to date...!')
            $('#error_dt_att_to').show();
            return false;
        } else {
            $('#error_dt_att_to').hide();
        }
        return true;
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {
        setAttendanceDtlTblData([]);
        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(dt_att_to)) { $('#error_dt_att_to').hide(); }
        if (isEmpty(dt_att_from)) { $('#error_dt_att_from').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    }

    // ****-------Validation Functions Ends--------------***


    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintAttendanceSheet = useReactToPrint({
        content: () => {
            debugger
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: "Employee Attendance Register"
    });

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1 border-bottom border-dark'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Employee Attendance Register
                                {
                                    employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                        ` (${employeeTypesComboRef.current?.label})`
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <dl className="row pb-0 mb-0 px-1">
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Department:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {departmentComboRef.current?.label || ""}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>SubDepartment:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {subDepartmentComboRef.current?.label || ""}</dd>
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Employee Type:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {employeeTypesComboRef.current?.label || ""}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Contractor:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {cmb_contractor_id_ref.current.label || ""}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Employee:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {employeeComboRef.current?.label || ""}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>From Date:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {validateNumberDateInput.current.formatDateToDDMMYYYY(dt_att_from)}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>To Date:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {validateNumberDateInput.current.formatDateToDDMMYYYY(dt_att_to)}</dd>
                            </dl>
                        </div>
                    </dl>
                </div>
            </>
        );
    };
    // ********* PrintOut Functionalities Ends. ****************************************

    // ********* ExportToExcel Functionalities Starts. ****************************************
    const FnExportToExcel = async () => {
        debugger
        // Means No Data For Export. 
        if (attendanceDtlTblData.length === 0) { return null; }
        setIsLoading(true);

        // Preparing Data For ExportFile.
        let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [], 'keys': 'register' };

        let formattedFromDate = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_att_from)
        let formattedToDate = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_att_to)

        // Excel Headers;
        jsonToExportExcel['headings'] = {
            'ReportName': 'Employee Attendance Report',
            'CompanyName': COMPANY_NAME,
            'CompanyAddress': sessionStorage.getItem('companyAddress'),
        }

        // Applied Filters.
        jsonToExportExcel['filtrKeyValue'] = {
            '0': `Department: ${departmentComboRef.current?.label || ""}`,
            '1': `Sub-Department: ${subDepartmentComboRef.current?.label || ""}`,
            '2': `Employee Type: ${employeeTypesComboRef.current?.label || ""}`,
            '3': `Employee Name: ${employeeComboRef.current?.label || ""}`,
            '4': `From Date: ${formattedFromDate}`,
            '5': `To Date: ${formattedToDate}`,
            '6': `Generated On: ${FnGetCurrentDateTime()}`,
            '7': `Contractor: ${cmb_contractor_id_ref.current?.label || ""}`,
        };

        // Details Table Columns and Accessors.
        jsonToExportExcel['columns'] = [
            { "Headers": 'Sr. No.', "accessor": 'sr_no' },
            { "Headers": 'Department', "accessor": 'department_name' },
            { "Headers": 'Employee Name', "accessor": 'employee_name' },
            { "Headers": 'Attendance Date', "accessor": 'att_date_time' },
            { "Headers": 'In Time', "accessor": 'in_time' },
            { "Headers": 'Out Time', "accessor": 'out_time' },
            { "Headers": 'Worked Hrs.', "accessor": 'working_minutes' },
            { "Headers": 'Present Status', "accessor": 'job_type' },
            { "Headers": 'Job Type Code', "accessor": 'job_type_code_short_name' },
            { "Headers": 'Extra Remark', "accessor": 'extraRemark' },
            { "Headers": 'Employee Code', "accessor": 'employee_code' },
            { "Headers": 'Punch Code', "accessor": 'old_employee_code' },
            { "Headers": 'Attendace Flag', "accessor": 'attendance_flag' },
        ]
        // Data For Table.
        attendanceDtlTblData.map((attendanceRecord, Index) => {
            attendanceRecord.sr_no = Index + 1;
            attendanceRecord.extraRemark = FnGetAttendanceExtraRemark(attendanceRecord);
            // Set The Proper Formatted Date DD/MM/YYYY
            // attendanceRecord.att_date_time = validateNumberDateInput.current.formatDateToDDMMYYYY(attendanceRecord.att_date_time)
            jsonToExportExcel['allData'][Index] = attendanceRecord;
        });

        await exlsExp.current.excel(jsonToExportExcel, `Attendance_Report_From_${formattedFromDate.replace(/\//g, '_')}_To_${formattedToDate.replace(/\//g, '_')}`);
        setIsLoading(false);
    }

    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Employee Attendance Register</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> From Date <span className="required">*</span></Form.Label> </div>
                                    <div className='col'>
                                        <DatePicker
                                            selected={dt_att_from}
                                            id="dt_att_from"
                                            onChange={(date) => { FnCombosOnChange('AttDateFrom', date); FnValidateFields(); }}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="dd-mm-yyyy"
                                            className="erp_input_field optional"
                                            selectsStart
                                            startDate={dt_att_from ? new Date(dt_att_from) : null}
                                            endDate={dt_att_to ? new Date(dt_att_to) : null}
                                            maxDate={new Date()}
                                        />
                                        <MDTypography variant="button" id="error_dt_att_from" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> To Date <span className="required">*</span></Form.Label> </div>
                                    <div className='col'>
                                        <DatePicker
                                            selected={dt_att_to}
                                            id="dt_att_to"
                                            onChange={(date) => { FnCombosOnChange('AttDateTo', date); FnValidateFields(); }}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="dd-mm-yyyy"
                                            className="erp_input_field optional"
                                            selectsEnd
                                            startDate={dt_att_from ? new Date(dt_att_from) : null}
                                            endDate={dt_att_to ? new Date(dt_att_to) : null}
                                            minDate={dt_att_from ? new Date(dt_att_from) : null}
                                            maxDate={new Date()}
                                        />
                                        <MDTypography variant="button" id="error_dt_att_to" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                                <div className='row mt-1'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Contractor</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Select ref={cmb_contractor_id_ref}
                                            options={contractorOptions}
                                            inputId="cmb_contractor_id" // Provide the ID for the input box
                                            value={contractorOptions.length > 1 ? contractorOptions.find(option => option.value === cmb_contractor_id) : null}
                                            onChange={(selectedOpt) => {
                                                cmb_contractor_id_ref.current = selectedOpt;
                                                setContractorId(selectedOpt.value)
                                                FnCombosOnChange('Contractor')
                                            }}
                                            placeholder="Search for a contractor..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_contractor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>


                            </div>
                            {/* First Column Ends*/}


                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("SubDepartment");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}

                            {/* First Third Column Starts*/}
                            <div className="col-sm-5 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnCombosOnChange('EmployeeType');
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div> */}


                                    {/* Old combobox *}
                                     <div className="col-3">
                                        <Form.Control type="text" id="txt_employee_code" className="erp_input_field " value={txt_employee_code}
                                        onChange={(e) => { FnCombosOnChange('EmployeeCode'); }}
                                        onBlur={(e) => { FnCombosOnChange('EmplCode'); }}
                                        maxlength="255" />
                                        <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                        </div>
                                        <div className="col-sm-4"> 
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                        </div>
                                        <div className='col-12 col-sm-8'> */}

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Employee Name :<span className="required">*</span></Form.Label>
                                        </div>
                                    <div className="col">
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            // isDisabled={DEPARTMENT_ID != 11}
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                                FnCombosOnChange('Employee');
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Third Column Ends*/}
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center me-1`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                <MDButton type="button" id="show-data-btn" className="erp-gb-button" variant="button" fontWeight="regular"
                                    onClick={(e) => { FnShowAttendanceData(); }}
                                >Show Data</MDButton>
                            </div>
                        </div>

                        {/* Attendance Details Table */}
                        {
                            attendanceDtlTblData.length === 0
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                : <> <hr />

                                    <div className="row py-1">
                                        <div className="text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }} >Export Excel</MDButton>

                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                onClick={(e) => { FnPrintAttendanceSheet(); }} >Print Attendance</MDButton>
                                        </div>
                                    </div>

                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            <div className="card-header py-0 main_heding mb-0">
                                                <label className="erp-form-label-md-lg">Employee's Attendance Details</label>
                                            </div>
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                {FnRenderAttendanceTbl}
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                {
                                    attendanceDtlTblData.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2"
                                                onClick={(e) => { FnPrintAttendanceSheet(); }} >Print Attendance Report</MDButton>
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}

                </div>
                {/* Card Ends*/}

            </DashboardLayout>
        </>
    )
}
export default DateWiseAttendance;




