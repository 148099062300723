import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $, { error } from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";

import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React icons
import { MdDelete, MdModeEdit, MdRefresh } from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import Document from "Features/Document";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmMBOMEntry from "FrmGeneric/MBOMFilter/FrmMBOMEntry";
import ExcelExport from "Features/Exports/ExcelExport";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import ProductList from "FrmGeneric/ProductList";
import CommonParamterEntry from "Masters/MCommonParameter/CommonParamterEntry";
import { Link } from "react-router-dom";
import FrmMBOMRMFGSRFilter from "FrmGeneric/FrmMBOMRMFGSRFilter";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { renderToString } from "react-dom/server";
import PurchaseRequisition from "FrmGeneric/Invoice/PurchaseRequisition";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";

// Import for the searchable combo box.
import Select from 'react-select';

export default function FrmIndentMasterEntry(props) {
    // debugger
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName, COMPANY_NAME, DEPARTMENT_ID } = configConstants;

    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType, requestfor, modules_forms_id } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false)
    const [IsView, setIsView] = useState(false)
    let docGroup = "Indent"

    // Poterms Table Data
    const [POTermsdata, setPOTermsData] = useState([]);
    const [existingCommonTermsData, setExistingCommonTermsData] = useState([]);
    let commonTermMstName = "Purchase Orders Technical Terms";

    const [openIndentDetailsAccord, setOpenIndentDetailsAccord] = useState(true);
    // // Product Type Parameters Table Data
    // const [productTypeParametersData, setProductTypeParametersData] = useState([]);
    // const [existingProductTypeParametersData, setExistingProductTypeParametersData] = useState([]);

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    const navigator = useNavigate();

    let fin_year = "";
    let ind_version = "";

    const [indent_master_id, setIndentMasterId] = useState(0);

    //combo options
    const [customerOptions, setCustomerOptions] = useState([])
    const [departmentIdOPtions, setDepartmentIdOptions] = useState([])
    const [subDepartmentOPtions, setSubDepartmentOptions] = useState([])
    const [cost_centerOptions, setCostCenterOptions] = useState([]);
    const [indentedByIdOptions, setIndentedByIdOptions] = useState([])
    const [indenttypeOptions, setIndentTypeOptions] = useState([])
    const [customerOrdOpts, setCustomerOrdOpts] = useState([]);


    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)

    const [messageForConfirmation, setMessageForConfirmation] = useState(false)
    const closeConfirmationModal = async (key) => {
        switch (key) {
            case 'Export&Exit':
                await ExportToExcel();
                setShowConfiemationModal(false);
                break;
            case 'Exit':
                setShowSuccessMsgModal(false);
                break;
            default:
                break;
        }
        moveToListing();
    }

    // Succ Msg
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')
    const [productpropertisList, setProductProperties] = useState([]);
    const [stockDetails, setStockDetails] = useState([]);


    // Bom Filter 
    let requiredCols = ['product_id','product_material_code', 'product_name', 'product_tech_spect', 'product_std_weight', 'product_unit_name', 'indent_quantity', 'indent_weight',
        "product_material_approved_quantity", "product_material_approved_weight", "product_material_preclosed_quantity", "product_material_preclosed_weight", "product_material_rejected_quantity", "product_material_rejected_weight", "product_material_issue_quantity", "product_material_issue_weight",
        "approval_remark", "indent_item_status", 'product_material_grade_name', 'product_make_name', 'stock_quantity', 'stock_weight', "product_material_reserve_quantity",
        "product_material_reserve_weight", "product_material_receipt_quantity", "product_material_reserve_weight", "product_material_receipt_weight",
        "product_material_return_quantity", "product_material_return_weight", 'product_unit_id', 'product_lead_time',
    ];

    const [bomfilteredData, setBomfilteredData] = useState([])
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);
    // doc list
    const [docData, setDocumentData] = useState([]);

    const viewBomFilterForm = async () => {

        // let checkIsValidate = await validate.current.validateForm("indentFormId");
        if (cmb_indent_type !== "" && cmb_indent_type !== "0") {
            $('#error_cmb_indent_type').hide();
            sessionStorage.setItem('requiredCols', JSON.stringify(requiredCols));
            setShowBomFilterForm(true);
        } else {
            $('#error_cmb_indent_type').text('Please fill this field...!')
            $('#error_cmb_indent_type').show();
        }
    }

    //Add Schedules popup open 
    const [scheduleDataArray, setScheduleDataArray] = useState([]);
    const [showSChedules, setSchedules] = useState(false);
    const handleCloseSchedulesPopup = () => setSchedules(false)
    const [schedulerowcount, setSchedulerowcount] = useState(1);
    var scheduleRowCount = 1;
    var remainingSchduleQty = 0;

    // For navigate
    const navigate = useNavigate();

    const [indentMasterJsonPrint,setIndentMasterJsonPrint] = useState({});

    //Form Fields
    const [txt_indent_no, setIndentno] = useState("0");
    const [txt_indent_version, setIndentVersion] = useState(1);
    const [cmb_indent_type, setIndentType] = useState('');
    const [chk_indent_source, setIndentSource] = useState('I')
    const [cmb_customer, setCustomerId] = useState('');
    const [cmb_customer_order_no, setCustomerOrderNo] = useState('')
    const [customer_order_date, setCustomerOrderDate] = useState('');
    const [cmb_department, setDepartmentId] = useState(DEPARTMENT_ID);
    const [cmb_sub_department, setSubDepartmentId] = useState('')
    const [cmb_cost_center, setCostCenter] = useState("");
    const [cmb_indented_by, setIndentedById] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [chk_isactive, setIsActive] = useState(true);
    const [chk_indent_status, setIndentStatus] = useState("P");
    const [chk_indent_priority, setIndentPriority] = useState('L');
    const [category_1_id, setCategory1Id] = useState(1);
    const [category_2_id, setCategory2Id] = useState('');
    const [category1Options, setCategory1Options] = useState([]);
    const [category2Options, setCategory2Options] = useState([]);
    
    


    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = async () => {
        await showDocumentRecords();
        setShowDocumentForm(false)
    };
    const viewDocumentForm = () => {
        if (keyForViewUpdate !== 'view') {
            setShowDocumentForm(true);
        }
    }

    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];
    const [txt_indent_date, setIndentDate] = useState(todayDate);
    const [txt_schedule_date, setScheduleDate] = useState(todayDate);
    const [cmb_approved_by_id, setApproved_by_id] = useState("");
    const [approvedByOptions, setApprovedByOptions] = useState([]);
    const [txt_approved_date, setApproved_date] = useState("");



    // Show ADD record Modals
    const closeRecordAddIndentModal = async () => {
        switch (modalHeaderName) {
            case 'Indent Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    .then(getProductType => {
                        setIndentTypeOptions(getProductType)
                    });
                break;
            case 'Indent By':
                comboOnChange('departmentId')
                break;
            case 'Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department";
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
                globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
                const departmentIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                setDepartmentIdOptions(departmentIdApiCall)
                break;
            case 'Sub Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department"
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: cmb_department });
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
                const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setSubDepartmentOptions(subDepartmentApiCall)
                break;
            case 'Common Parameter':
                await FnShowPOTermsRecords();
                break;
            case 'Cost Center':
                try {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
                    globalQuery.table = "fmv_cost_center";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const profitCenterList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                    // setCostCenterOptions(profitCenterList);
                    //  ** Changes for the Searchable Combo
                    const costCenterOpts = [
                        { value: '', label: 'Select', centerShortName: '' },
                        { value: '0', label: 'Add New Record+', centerShortName: '' },
                        // ...profitCenterList.map(({ field_id, field_name, cost_center_short_name }) => ({ value: field_id, label: `[${cost_center_short_name}] ${field_name}`, centerShortName: cost_center_short_name })),
                        ...profitCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.cost_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.cost_center_short_name, })),
                    ];
                    setCostCenterOptions(costCenterOpts);

                } catch (error) {
                    console.log("error : ", error)
                }
                break;
            default:
                break;
        }
        setShowAddRecIndentModal(false);
        sessionStorage.removeItem('dataAddedByCombo')

    }
    // delete popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showCancel, setShowCancel]= useState(false);

    const [showAddRecIndentModal, setShowAddRecIndentModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    useEffect(() => {
        const  initializeData = async () =>{
            setIsLoading(true);
            try {
                FnSetFieldUserAccess();
                await fillCombos();
                removeSessions('onload');
                // await GenerateIndentNo();
                if (idList !== '') {
                    await FnCheckUpdateResponce();
                }
                FnSetFieldUserAccess();
            } catch (error) {
                console.error(error);
                navigate('/Error')
            } finally {
                setIsLoading(false);
            }
        };
        initializeData();
    }, [])

    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnDeleteRecord/${txt_indent_version}/${COMPANY_ID}?indent_no=${txt_indent_no}&user_name=${UserName}`, method)
            const responce = await deleteApiCall.json();
            console.log("indent entry: ", responce);
            if (responce.success == '1') {
                setShow(false)
                moveToListing();
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    const cancelRecords = async () => {
        try {
            
            const method = { method: 'POST' }
            const cancelApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnCancleRecord/${txt_indent_version}/${COMPANY_ID}?indent_no=${txt_indent_no}&user_name=${UserName}`, method)
            const responce = await cancelApiCall.json();
            console.log("Cancel entry: ", responce);
            if (responce.success == '1') {
                setShow(false)
                moveToListing();
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    
    const FnShowPOTermsRecords = async () => {
        try {
            const MSTName = encodeURIComponent(commonTermMstName);
            const poTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}`)
            const responce = await poTermsApiCall.json();
            if (responce.content.length !== 0) {
                const updatedContent = responce.content.map((commonTerm) => ({
                    ...commonTerm,
                    common_parameters_expected_value: commonTerm.common_parameters_value,
                    remark: '',
                }))
                    .sort((a, b) => a.common_parameters_id - b.common_parameters_id);
                responce.content = updatedContent;

                const existingCommonData = existingCommonTermsData;
                let renderedCommonTermsData = responce.content
                for (let existCommonCounter = 0; existCommonCounter < existingCommonData.length; existCommonCounter++) {
                    const existingCommonTermId = existingCommonData[existCommonCounter].common_parameters_id;
                    const renderedCommonTerm = renderedCommonTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.common_parameters_id === existingCommonTermId);
                    if (renderedCommonTerm) {
                        const existingIndex = renderedCommonTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedCommonTermsData.splice(existingIndex, 1)[0];
                        renderedCommonTermsData.unshift(movedItem);
                        renderedCommonTermsData[0].common_parameters_expected_value = existingCommonData[existCommonCounter].common_parameters_expected_value;
                    }
                }
                setPOTermsData(renderedCommonTermsData)
                localStorage.setItem('purchaseOrdCommonTermsData', JSON.stringify(renderedCommonTermsData));
                setTimeout(() => {
                    existingCommonTermsData.forEach(function (existingCommonTerm) {  // mark the checked checkboxes for order common term.
                        $('#selectPOTerm_' + existingCommonTerm.common_parameters_id).prop('checked', true);
                    });
                }, 200);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    //table for common terms
    const renderPOTermsTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='po-commonTerms-table' responsive bordered>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Action</th>
                        <th className="erp_table_th">Common Parameters Master </th>
                        <th className="erp_table_th">Common Parameters </th>
                        <th className="erp_table_th">Common Parameters Value</th>
                        <th className="erp_table_th">Common Prameters Expected Value</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        POTermsdata.map((poTerms, Index) =>
                            <tr rowIndex={Index}>

                                <td className="erp_table_td">
                                    <input type="checkbox" className="selectPOTerm" name="selectPOTerm" value={poTerms.common_parameters_id} id={'selectPOTerm_' + poTerms.common_parameters_id} />
                                </td>
                                <td className="erp_table_td">{poTerms.common_parameters_master_name}</td>
                                <td className="erp_table_td">{poTerms.common_parameters_name}</td>
                                <td className="erp_table_td">{poTerms.common_parameters_value}</td>
                                <td className="erp_table_td">
                                    <input type="text" id={`common_parameters_expected_value_${poTerms.common_parameters_id}`}
                                        className="erp_input_field mb-0"
                                        value={poTerms.common_parameters_expected_value}
                                        onChange={(e) => { updatePOOrdCommonTermsTblData(poTerms, e); }}
                                        disabled={IsView}
                                        Headers='common_parameters_expected_value'
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [POTermsdata, IsView]);
    // Onchange event listener for the POTerm Selection
    $('.selectPOTerm').on('change', function () {
        checkBoxesSelection('PartiallyPOTermsSelection');
    });
    const updatePOOrdCommonTermsTblData = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        const exptvalueId = document.getElementById(event.target.id);
        if (event.target.value === '') {
            exptvalueId.parentElement.dataset.tip = 'Please fill this field...!';
        } else {
            delete exptvalueId.parentElement.dataset.tip;
        }
        currentRowData[clickedColName] = event.target.value;            // Get the column name where event triggered.
        const poTermsData = [...POTermsdata]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        poTermsData[arrayIndex] = currentRowData
        setPOTermsData(poTermsData);
    }
    function checkBoxesSelection(key) {
        switch (key) {
            // For Common terms 
            case "selectAllPOTerms":
                $('.selectPOTerm').prop('checked', $('#selectAllPOTerms').is(":checked"));
                break;
            case 'PartiallyPOTermsSelection':
                $('#selectAllPOTerms').prop('checked', $('input:checkbox.selectPOTerm:checked').length == $('input:checkbox.selectPOTerm').length);
                break;
            // For Product type Parameters
            // case "selectAllParameter":
            //     $('.selectParameter').prop('checked', $('#selectAllParameter').is(":checked"));
            //     break;
            // case 'PartiallyParameterSelection':
            //     $('#selectAllParameter').prop('checked', $('input:checkbox.selectParameter:checked').length == $('input:checkbox.selectParameter').length);
            //     break;

            default:
                break;
        }
    }
    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillCombos = async () => {
        try {
            comboDataAPiCall.current.fillMasterData("cmv_customer", "", "")
                .then(getCustomers => {
                    // setCustomerOptions(getCustomers);
                    //  ** Changes for the Searchable Combo
                    const options = [
                        { value: '', label: 'Select', },
                        { value: '0', label: 'Add New Record+' },
                        ...getCustomers.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                    ];
                    setCustomerOptions(options);
                    console.log("Customers List: ", getCustomers);
                });

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_department";
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
            globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
            const getDepartment = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setDepartmentIdOptions(getDepartment)
            if (keyForViewUpdate === '') {
                comboOnChange('departmentId')
            }
            // comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            //     .then(getDepartment => {
            //         setDepartmentIdOptions(getDepartment)
            //         console.log("Department List: ", getDepartment);
            //     });


            // comboDataAPiCall.current.fillMasterData("cmv_employee", "", "")
            //     .then(getEmployee => {
            //         setApprovedByOptions(getEmployee)
            //         console.log("Employee List: ", getEmployee);
            //     });
            resetGlobalQuery();
            globalQuery.columns.push("user_id as field_id");
            globalQuery.columns.push("user_name as field_name");
            globalQuery.table = "amv_modules_forms_user_access";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: modules_forms_id });
            globalQuery.conditions.push({ field: "approve_access", operator: "=", value: 1 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getApproveEmpAccessList => {
                    setApprovedByOptions(getApproveEmpAccessList);
                    console.log("Approve Access Emp List: ", getApproveEmpAccessList);
                });

            // Costcenter List
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
            globalQuery.table = "fmv_cost_center";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then((getCostCenterList) => {
                    // setCostCenterOptions(getCostCenterList);
                    //  ** Changes for the Searchable Combo
                    const costCenterOpts = [
                        { value: '', label: 'Select', centerShortName: '' },
                        // { value: '0', label: 'Add New Record+', centerShortName: '' },
                        ...getCostCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.cost_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.cost_center_short_name, })),
                        // ...getCostCenterList.map(({ field_id, field_name, cost_center_short_name }) => ({ value: field_id, label: `[${cost_center_short_name}] ${field_name}`, centerShortName: cost_center_short_name })),
                    ];
                    setCostCenterOptions(costCenterOpts);
                })

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const getProductType = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setIndentTypeOptions(getProductType)

            if (requestfor !== "NA") {
                if (getProductType.length !== 0) {
                    let productType = getProductType.find((item) => item.product_type_short_name === requestfor);
                    
                    if (productType) {
                        setIndentType(productType.field_id);
                        await GenerateIndentNo(productType.product_type_short_name);
                    }
                }
            }
            //get Requisition by name on load
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            const identedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const indentedByOpts = [
                { value: '', label: 'Select', },
                ...identedbyIdApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
            ];
            setIndentedByIdOptions(indentedByOpts);


             resetGlobalQuery();
                        globalQuery.columns.push("field_id");
                        globalQuery.columns.push("field_name");
                        globalQuery.columns.push("product_type_short_name");
                        globalQuery.table = "smv_product_category1";
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            
                        comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                            .then(getProductType => {
                                setCategory1Options(getProductType)
                            });
            
                        resetGlobalQuery();
                        globalQuery.columns.push("field_id");
                        globalQuery.columns.push("field_name");
                        globalQuery.columns.push("product_type_short_name");
                        globalQuery.table = "smv_product_category2";
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: 12 });
                        comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                            .then(getProductType => {
                                setCategory2Options(getProductType)
                            });
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    const FnSetFieldUserAccess = () => {
        if (keyForViewUpdate != '') {
            var saveBtn = document.getElementById('save-btn');
            setIsApprove(keyForViewUpdate === 'approve' ? true : false);
            // $('#printbtn_id').hide();
            switch (keyForViewUpdate) {
                case 'view':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(View)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;
                case 'approve':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Approval)');
                    saveBtn.textContent = 'Approve';
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $("form").find('textarea#txt_remark').removeAttr("disabled");
                    $("form").find('input#txt_approved_date').removeAttr("disabled");
                    $("form").find('input#txt_approved_date').removeAttr("readonly");
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#chk_indent_status').attr('disabled', false);
                    // if (chk_indent_source === 'S') {
                    //     $('#add-material').attr('disabled', 'disabled');
                    // }
                    $('table .approveField').removeAttr("disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;
                case 'update':
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Modification)');
                    $('#cmb_indent_type').attr('disabled', true);
                    $('#txt_indent_date').attr('disabled', true);
                    $('input[name="chk_indent_source"]').prop('disabled', true);
                    $('#cmb_customer_order_no').attr('disabled', false);
                    $('#cmb_customer').attr('disabled', true);
                    if (chk_indent_source !== 'S') {
                        $('#cmb_customer_order_no').attr('disabled', true);
                    }
                    setIsApprove(false)
                    $('#clearbutton-id').hide();
                    saveBtn.textContent = 'Update';
                    break;
                case 'delete':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Delete)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;
                case 'cancel':
                    
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Cancel)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;
                default : break;
            }
        }
    }


    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            if (idList !== '') {
                fin_year = idList.financial_year;
                ind_version = idList.indent_version;
                setIndentno(idList.indent_no)
                setIndentMasterId(idList.indent_master_id)

                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnShowAllDetailsAndMastermodelRecords/${ind_version}/${fin_year}/${COMPANY_ID}?indent_no=${idList.indent_no}`)
                const responce = await apiCall.json();
                console.log('indent order data: ', responce);
                let indentMasterData = responce.IndentMasterRecord;
                if (indentMasterData !== null && indentMasterData !== "") {
                    setIndentMasterJsonPrint(indentMasterData);
                    let indnet_source = indentMasterData.indent_source;
                    setIndentno(indentMasterData.indent_no);
                    setIndentVersion(indentMasterData.indent_version);
                    setIndentType(indentMasterData.indent_type_id);
                    setIndentSource(indentMasterData.indent_source);
                    setCustomerId(indentMasterData.customer_id);
                    setCategory2Id(indentMasterData.category_2);
                    if (indentMasterData.indent_source === 'S') {
                        await comboOnChange('customer');
                        setCustomerOrderNo(indentMasterData.customer_order_no);
                        setCustomerOrderDate(indentMasterData.customer_order_date);
                    }
                    setScheduleDate(indentMasterData.expected_schedule_date)
                    setIndentDate(indentMasterData.indent_date);
                    setDepartmentId(indentMasterData.department_id);
                    await comboOnChange('departmentId');
                    setSubDepartmentId(indentMasterData.sub_department_id)
                    await comboOnChange('subDepartment');
                    setIndentedById(indentMasterData.indented_by_id);
                    setIndentStatus(indentMasterData.indent_status);
                    setIndentPriority(indentMasterData.indent_priority)
                    setRemark(indentMasterData.remark);
                    setApproved_by_id(indentMasterData.approved_by_id)
                    setApproved_date(indentMasterData.approved_date)
                    setIsActive(indentMasterData.is_active);
                    if (keyForViewUpdate === 'approve') {
                        setIndentStatus('A');
                        setApproved_by_id(UserId)
                        setApproved_date(todayDate)
                    }else if(keyForViewUpdate === 'cancel'){
                        setIndentStatus('X');
                    }

                    // Function to update keys dynamically
                    const updateKeys = (object) => { 
                        object.prev_indent_quantity = object.product_material_quantity;
                        object.prev_indent_weight = object.product_material_weight;
                        // object.product_material_stock_quantity = object.indent_type_id !== 2 ? object.product_material_stock_quantity : object.product_Rawmaterial_stock_quantity;
                        // object.product_material_stock_weight = object.indent_type_id !== 2 ? object.product_material_stock_weight : object.product_Rawmaterial_stock_weight;
                        object.product_material_stock_quantity = object.product_material_stock_quantity;
                        object.product_material_stock_weight = object.product_material_stock_weight;

                        const updatedObject = {};
                        for (const key in object) {
                            if (key === 'product_material_quantity') {
                                updatedObject[`indent_quantity`] = object[key];
                            } else if (key === 'product_material_weight') {
                                updatedObject[`indent_weight`] = object[key];
                            } else if (key === 'product_rm_name') {
                                updatedObject[`product_name`] = object[key];
                            } else if (key === 'product_rm_tech_spect') {
                                updatedObject[`product_tech_spect`] = object[key];
                            } else if (key === 'product_rm_stock_unit_name') {
                                updatedObject[`product_unit_name`] = object[key];
                            } else if (key === 'product_material_stock_quantity') {
                                updatedObject[`stock_quantity`] = object[key];
                            } else if (key === 'product_material_stock_weight') {
                                updatedObject[`stock_weight`] = object[key];
                            } else if (key === 'lead_time') {
                                updatedObject[`product_lead_time`] = object[key];
                            } else if (key === 'product_rm_std_weight') {
                                updatedObject[`product_std_weight`] = object[key];
                            } else if (key === 'product_material_unit_id') {
                                updatedObject[`product_unit_id`] = object[key];
                            }
                            else if (key === 'product_material_id') {
                                updatedObject[`product_id`] = object[key];
                            }
                            else {
                                updatedObject[`${key}`] = object[key];
                            }
                        }
                        return updatedObject;
                    };
                    let productIds = [];
                    if (responce.IndentDetailsRecords != null) {

                        const updatedIndentDetailsDataArray = responce.IndentDetailsRecords.map(updateKeys);
                        let updateWithIndentItemStatus = [];
                        if (keyForViewUpdate === 'approve') {
                            updateWithIndentItemStatus = updatedIndentDetailsDataArray.map(function (rowData) {
                                return {
                                    ...rowData,
                                    indent_item_status: 'A',
                                    product_material_approved_quantity: rowData.indent_quantity,
                                    product_material_approved_weight: rowData.indent_weight,
                                };
                            });
                        } else if (keyForViewUpdate === 'update') {
                            updateWithIndentItemStatus = updatedIndentDetailsDataArray.map(function (rowData) {
                                return {
                                    ...rowData,
                                    prev_indent_quantity: rowData.indent_quantity,
                                    prev_indent_weight: rowData.indent_weight
                                };
                            });
                        }else if (keyForViewUpdate === 'cancel') {
                            updateWithIndentItemStatus = updatedIndentDetailsDataArray.map(function (rowData) {
                                return {
                                    ...rowData,
                                    indent_item_status: 'X',
                                    prev_indent_quantity: rowData.indent_quantity,
                                    prev_indent_weight: rowData.indent_weight
                                };
                            });
                        }
                        setBomfilteredData(updateWithIndentItemStatus.length === 0 ? updatedIndentDetailsDataArray : updateWithIndentItemStatus)
                        productIds = updatedIndentDetailsDataArray.map(item => item.product_id);
                        sessionStorage.setItem('filteredMaterialData', JSON.stringify(updateWithIndentItemStatus.length === 0 ? updatedIndentDetailsDataArray : updateWithIndentItemStatus))
                        $('.showData').show();
                    }
                    if (requestfor === "PRM") {
                        if (keyForViewUpdate === 'update') {
                            getStockDetails(productIds);
                        }
                        getpropertiesData(productIds);
                    }

                    if (responce.IndentSchedules !== null && responce.IndentSchedules.length > 0) {

                        const existingpoOrdSchedules = responce.IndentSchedules;
                        const updatedSchedulesData = [];

                        const materialWiseSchedules = {};
                        existingpoOrdSchedules.forEach((item) => {
                            let key = '';
                            if (indnet_source === 'S') {
                                key = `${item.product_material_id}:${item.so_sr_no}`;
                            } else {
                                key = `${item.product_material_id}:`;
                            }
                            if (!materialWiseSchedules[key]) {
                                materialWiseSchedules[key] = [];
                            }
                            materialWiseSchedules[key].push(item);
                        });
                        // Looping for set the id for schedule
                        for (const key of Object.keys(materialWiseSchedules)) {
                            let [rm_id, so_sr_no] = '';
                            if (indnet_source === 'S') {
                                [rm_id, so_sr_no] = key.split(':');
                            } else {
                                [rm_id] = key.split(':');
                            }
                            const materialGroup = materialWiseSchedules[key];
                            materialGroup.forEach((schedule, scheduleCounter) => {
                                if (indnet_source === 'S') {
                                    schedule.id = `${rm_id}:${so_sr_no}:${scheduleCounter}`;
                                } else {
                                    schedule.id = `${rm_id}:${scheduleCounter}`;
                                }
                                if (keyForViewUpdate === 'approve') {
                                    schedule.indent_item_status = 'A';
                                    schedule.indent_item_status_desc = 'Aprroved';
                                    schedule.indent_schedule_id = 0;
                                }else if(keyForViewUpdate === 'cancel') {
                                    schedule.indent_item_status = 'X';
                                    schedule.indent_item_status_desc = 'Canceled';
                                    schedule.indent_schedule_id = 0;
                                } else if(keyForViewUpdate === 'preClose') {
                                    schedule.indent_item_status = 'Z';
                                    schedule.indent_item_status_desc = 'PreCloed';
                                    schedule.indent_schedule_id = 0;
                                }
                                schedule.modified_by = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel' || keyForViewUpdate === "preClose" ? UserName : '';
                                updatedSchedulesData.push(schedule);
                            });
                        }
                        setScheduleDataArray(updatedSchedulesData)
                        localStorage.setItem('indentScheduleData', JSON.stringify(updatedSchedulesData));
                    }

                    // Function to update Product type parameters 
                    // if (responce.ProductTypeParametersData !== null) {
                    //     setExistingProductTypeParametersData(responce.ProductTypeParametersData);
                    // }
                    // Function to update IndentPO Terms 
                    if (responce.IndentPOTerms !== null) {
                        setExistingCommonTermsData(responce.IndentPOTerms);
                    }
                }
            }
            } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const checkPOTerms = (responce) => {
        responce.IndentPOTerms.forEach(item => {
            const element = item.common_parameters_id;
            $(`#${element}`).prop('checked', true);
            $(`#po-terms-expected-Value-${element}`).val(item.common_parameters_expected_value);
        });
    }
    const comboOnChange = async (key) => {

        switch (key) {
            case 'customer':
                let customerVal = customerComboRef.current.value || '';
                // let customerVal = document.getElementById("cmb_customer").value;
                // setCustomerId(customerVal);
                if (customerVal === '0') {
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                } else if (customerVal !== "" && customerVal !== '0') {
                    resetGlobalQuery();
                    globalQuery.columns.push("customer_order_no");
                    globalQuery.columns.push("customer_order_Date");
                    globalQuery.table = "mtv_sales_order_master_trading_summary"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.orderBy = ["sales_order_master_transaction_id desc"];

                    let getCustomerOrdersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                    if (requestfor === "YN") {
                        const options = [
                            { value: '', label: 'Select', },
                            { value: '0', label: 'Add New Record+' },
                            ...getCustomerOrdersApiCall.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                        ];
                        setCustomerOrdOpts(options);
                        console.log("customer_order_no List: ", getCustomerOrdersApiCall);
                    } else {
                        setCustomerOrdOpts(getCustomerOrdersApiCall);
                    }
                    setCustomerOrderNo('');
                    setCustomerOrderDate('');
                    setBomfilteredData([])
                    setScheduleDataArray([])
                    sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
                    localStorage.setItem('indentScheduleData', JSON.stringify([]));
                    $("#error_cmb_customer").hide();
                } else {
                    setCustomerOrdOpts([]);
                    setCustomerOrderNo('');
                    setCustomerOrderDate('');
                    setBomfilteredData([])
                    setScheduleDataArray([])
                    sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
                    localStorage.setItem('indentScheduleData', JSON.stringify([]));

                }
                break;

            case 'CustomerOrder':

                if ($('#cmb_customer_order_no').val() != null && $('#cmb_customer_order_no').val() != '') {
                    let selectedOption = $('#cmb_customer_order_no').find('option:selected');
                    let selectedOrderDate = selectedOption.attr('ord_date');
                    setCustomerOrderDate(selectedOrderDate);
                    let customer_ordNo = $('#cmb_customer_order_no').val();
                    let customerVal = customerComboRef.current.value || '';
                    if (customerVal !== "" && customerVal !== '0') {
                        // if ($('#cmb_customer').val() != "") {

                        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterTrading/FnShowParticularRecords/${COMPANY_ID}?customer_order_no=${customer_ordNo}`)
                        const responce = await apicall.json();
                        const CustNo = responce.content;

                        if (CustNo.length !== 0) {
                            const poDetailsData = groupCustomerProducts(CustNo)

                            setBomfilteredData(poDetailsData);                        // Set that updated data in table data hook;
                            sessionStorage.setItem('filteredMaterialData', JSON.stringify(poDetailsData));
                            setScheduleDataArray([]);
                            localStorage.setItem('indentScheduleData', JSON.stringify([]));
                            $('.showData').show();
                        }
                    }
                } else {
                    setCustomerOrderDate('');
                    $('.showData').hide();
                    setBomfilteredData([]);
                    setProductProperties([]);
                }
                break;
            case 'CustomerOrderYN':
                if ($('#cmb_customer_order_no').val() != null && $('#cmb_customer_order_no').val() != '') {
                    let selectedOption = $('#cmb_customer_order_no').find('option:selected');
                    let selectedOrderDate = selectedOption.attr('ord_date');
                    setCustomerOrderDate(selectedOrderDate);
                } else {
                    setCustomerOrderDate('');
                }
                break;
            case 'departmentId':
                var departmentIdVal = document.getElementById('cmb_department').value;
                setDepartmentId(departmentIdVal)
                if (departmentIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Department')
                    setShowAddRecIndentModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (departmentIdVal !== "" && departmentIdVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id");
                    globalQuery.columns.push("field_name");
                    globalQuery.columns.push("cost_center_group");
                    globalQuery.table = "cmv_department"
                    globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                    globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
                    globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
                    const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    const subDeptOpts = [
                        ...subDepartmentApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name, cost_center_group: empl.cost_center_group  })),
                    ];
                    setSubDepartmentOptions(subDeptOpts)
                    // setIndentedByIdOptions([])
                    // setIndentedById('')
                    setSubDepartmentId('')
                    if (departmentIdVal !== "") {
                        $('#error_cmb_department').hide();
                    }
                } else {
                    setSubDepartmentOptions([])
                    // setIndentedByIdOptions([])
                    // setIndentedById('')
                    setSubDepartmentId('')
                }
                break;
            case 'subDepartment':
                debugger
                var subdepartmentIdVal = document.getElementById('cmb_sub_department').value;
                setSubDepartmentId(subdepartmentIdVal)
                if (subdepartmentIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Sub Department')
                    setShowAddRecIndentModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }

                var costCenterGroup = $("#cmb_sub_department option:selected").attr("cost_center_group");
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name',"cost_center_group"];
                        globalQuery.table = "fmv_cost_center";
                        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "cost_center_group", operator: "=", value: costCenterGroup });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                            .then((getCostCenterList) => {
                                
                                const costCenterOpts = [
                                    { value: '', label: 'Select', centerShortName: '' },
                                    ...getCostCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.cost_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.cost_center_short_name, })),
                                ];
                                setCostCenterOptions(costCenterOpts);
                            })
                if (subdepartmentIdVal !== "" && subdepartmentIdVal !== "0") {
                    // resetGlobalQuery();
                    // globalQuery.columns.push("field_id");
                    // globalQuery.columns.push("field_name");
                    // globalQuery.table = "cmv_employee";
                    // // globalQuery.conditions.push({ field: "department_id", operator: "=", value: document.getElementById('cmb_department').value });
                    // // globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentIdVal }); //comment bcoz prashant sir told not load on departments wise 2-08-24 14.25
                    // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    // // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    // globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                    // const identedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    // const indentedByOpts = [
                    //     { value: '', label: 'Select', },
                    //     { value: '0', label: 'Add New Record+', },
                    //     ...identedbyIdApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
                    // ];
                    // setIndentedByIdOptions(indentedByOpts);
                    // setIndentedById('')
                    // resetGlobalQuery();
                    // globalQuery.columns.push("field_id");
                    // globalQuery.columns.push("field_name");
                    // globalQuery.table = "cmv_employee";
                    // // globalQuery.conditions.push({ field: "department_id", operator: "=", value: document.getElementById('cmb_department').value });
                    // // globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentIdVal }); //comment bcoz prashant sir told not load on departments wise 2-08-24 14.25
                    // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    // // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    // globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                    // const identedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    // const indentedByOpts = [
                    //     { value: '', label: 'Select', },
                    //     { value: '0', label: 'Add New Record+', },
                    //     ...identedbyIdApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
                    // ];
                    // setIndentedByIdOptions(indentedByOpts);
                    // setIndentedById('')
                    if (departmentIdVal !== "") {
                        $('#error_cmb_sub_department').hide();
                    }
                }
                // else {
                //     setIndentedByIdOptions([])
                //     setIndentedById('')
                // }
                break;
            case 'identedById':
                let indentedBYIdVal = intentedByComboRef.current.value || '';
                // var indentedBYIdVal = document.getElementById('cmb_indented_by').value;
                // setIndentedById(indentedBYIdVal)
                if (indentedBYIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Indent By')
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (indentedBYIdVal !== "" && indentedBYIdVal !== "0") {
                    $('#error_cmb_indented_by').hide();
                }
                break;
            case 'indentTypeId':
                var indentTypeId = document.getElementById('cmb_indent_type').value;
                if (indentTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Indent Type')
                    setShowAddRecIndentModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                setIndentType(indentTypeId)
                if (indentTypeId !== "0" && indentTypeId !== "") {
                    $('#error_cmb_indent_type').hide();
                    await GenerateIndentNo();
                    setBomfilteredData([])
                    setScheduleDataArray([])
                    sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
                    localStorage.setItem('indentScheduleData', JSON.stringify([]));
                } else {
                    setIndentno("0")
                }
                break;
            // case 'other_source':
            case 'SO_source':
                sessionStorage.removeItem('filteredMaterialData')
                localStorage.removeItem('indentScheduleData')
                setBomfilteredData([])
                setScheduleDataArray([])
                setProductProperties([])
                break;
            case 'Internal_source':
                setCustomerId('')
                setCustomerOrderNo("")
                setCustomerOrderDate('')
                sessionStorage.removeItem('filteredMaterialData')
                localStorage.removeItem('indentScheduleData')
                setBomfilteredData([])
                setProductProperties([])
                setScheduleDataArray([])
                break;
            case 'cost_center':
                let costcenter = costCenterComboRef.current.value || '';
                // const costcenter = document.getElementById('cmb_cost_center').value;
                setCostCenter(costcenter)
                $('#error_cmb_cost_center').hide();
                if (costcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Cost Center')
                    setShowAddRecIndentModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(0).css("padding-top", "0px");
                    }, 200)
                }
                break;

            case 'category2':
                sessionStorage.removeItem('filteredMaterialData')
                localStorage.removeItem('indentScheduleData')
                setBomfilteredData([]);
                setProductProperties([]);
                setScheduleDataArray([]);
                break;
            default:
                break;
        }
    }

    const groupCustomerProducts = (custDetailsData) => {

        custDetailsData = custDetailsData.map(item => ({
            ...item,
            product_id: item.product_material_id,
            indent_quantity: item.material_quantity,
            indent_weight: item.material_weight,
            product_name: item.product_material_name,
            product_tech_spect: item.product_material_tech_spect,
            product_std_weight: item.product_material_std_weight,
            product_unit_name: item.product_material_stock_unit_name,
            product_unit_id: item.product_material_unit_id,
            product_make_name: item.product_material_make_name,

        }));

        const eixtingCumulativeData = [];
        const finalMergedArray = custDetailsData
        try {
            // Use reduce to group data by HSN rate
            const groupedData = finalMergedArray.reduce((acc, item) => {
                const key = item.product_id; // Group by product_id
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});

            // Convert the grouped data object into an array of arrays
            const groupedDataArray = Object.values(groupedData);
            console.log('groupedDataArray: ', groupedDataArray);

            groupedDataArray.forEach(group => {
                const {
                    product_id,
                    product_name,
                    indent_quantity,
                    indent_weight,
                    customer_order_no,
                    customer_order_Date,
                    product_unit_id,
                    product_std_weight,
                    product_unit_name,
                    product_tech_spect,
                    so_sr_no,
                    product_make_name,
                    product_material_grade_name
                } = group[0];

                const product_rm_quantity = group.reduce((sum, item) => sum + parseFloat(item.indent_quantity), 0);
                const product_rm_weight = group.reduce((total, item) => total + parseFloat(item.indent_weight), 0);

                const concatenated_sales_ord_id = group.reduce((concatenated, item) => {
                    const itemString = `${item.sales_order_details_transaction_id}`;
                    return concatenated + itemString + ':'; // Use ';' as a separator
                }, '');

                const sales_order_details_transaction_id = concatenated_sales_ord_id.slice(0, -1);


                let groupObject = {};
                let matchingPoItemIndex = -1;
                // Iterate through the filteredIndentDetailsData array
                // group.forEach(filteredItem => {

                groupObject = createGroupObject( // If no matching item is found, add the filtered item to cumulativeData

                    product_id,
                    product_name,
                    indent_quantity,
                    indent_weight,
                    customer_order_no,
                    customer_order_Date,
                    txt_schedule_date,
                    product_unit_id,
                    product_std_weight,
                    product_unit_name,
                    product_tech_spect,
                    sales_order_details_transaction_id,
                    so_sr_no,
                    product_make_name,
                    product_material_grade_name,
                    product_rm_quantity,
                    product_rm_weight,


                );
                eixtingCumulativeData.push(groupObject);
                // }
                // });
            });
            console.log("cumulativeData: ", eixtingCumulativeData);
            return eixtingCumulativeData;
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }
    function createGroupObject(

        product_id,
        product_name,
        indent_quantity,
        indent_weight,
        customer_order_no,
        customer_order_Date,
        txt_schedule_date,
        product_unit_id,
        product_std_weight,
        product_unit_name,
        product_tech_spect,
        sales_order_details_transaction_id,
        so_sr_no,
        product_make_name,
        product_material_grade_name,
        product_rm_quantity,
        product_rm_weight,
    ) {
        return {
            product_id,
            product_name,
            indent_quantity: product_rm_quantity,
            indent_weight: product_rm_weight,
            customer_order_no,
            customer_order_Date,
            material_schedule_date: txt_schedule_date,
            product_rm_approved_quantity: 0,
            product_rm_approved_weight: 0,
            product_rm_rejected_quantity: 0,
            product_rm_rejected_weight: 0,
            approval_remark: '',
            approved_by_id: '',
            approved_date: '',
            product_lead_time: 0,
            product_unit_id: product_unit_id,
            indent_item_status: 'P',
            product_std_weight,
            product_unit_name,
            product_tech_spect,
            sales_order_details_transaction_id,
            so_sr_no,
            stock_weight: 0,
            stock_quantity: 0,
            product_material_reserve_quantity: '0',
            product_material_reserve_weight: '0',
            product_material_approved_quantity: 0,
            product_material_approved_weight: 0,
            product_material_rejected_quantity: '0',
            product_material_rejected_weight: '0',
            product_material_receipt_quantity: '0',
            product_material_receipt_weight: '0',
            product_material_return_quantity: '0',
            product_material_return_weight: '0',
            product_material_issue_quantity: '0',
            product_material_issue_quantity: '0',
            product_make_name,
            product_material_grade_name,
            prev_indent_quantity: product_rm_quantity,
            prev_indent_weight: product_rm_weight
        };
    }
    const displayRecordIndentComponent = () => {
        switch (modalHeaderName) {
            case 'Indent Type':
                return <FrmMProductTypeEntry />;
            case 'Indent By':
                return <FrmMEmployeesEntry />;
            case 'Department':
                return <FrmDepartmentEntry />;
            case 'Sub Department':
                const props = { btn_disabled: true, departmentType: "S", parent_department: cmb_department, parentDepIs_disable: true, deptTypeIs_disable: true };
                return <FrmDepartmentEntry {...props} />;
            case 'Common Parameter':
                return <CommonParamterEntry btn_disabled={true} master_name={commonTermMstName} />
            case 'Cost Center':
                return <MCostCenterEntry btn_disabled={true} />;
            default:
                return null;
        }
    }
    const GenerateIndentNo = async (product_typeShortName) => {
        const ptShortName = indenttypeOptions.find(item => item.field_id === parseInt($('#cmb_indent_type').val()));
        const productTypeId = $('#cmb_indent_type option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("st_indent_master", "indent_no", product_typeShortName === undefined ? ptShortName.product_type_short_name : product_typeShortName,
            "PR", "5", "indent_type_id", productTypeId);
        setIndentno(autoNoApiCall);
        return autoNoApiCall;
    };

    //--------------------------------------------------------Start Details section----------------------------------------------------------------------
    const handleCloseBomFilterForm = () => {

        let productIds = [];

        setShowBomFilterForm(false)
        const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
        if (getData.length !== 0) {
            setBomfilteredData((prevArray) => {
                // Filter prevArray to keep only items that are also in getData.
                const filteredArray = prevArray.filter(prevItem => {
                    return getData.some(item => item.product_id === prevItem.product_id);
                });

                const updatedArray = [
                    ...filteredArray,
                    ...getData.filter(item => !prevArray.some(prevItem => prevItem.product_id === item.product_id)).map((item) => {
                        return {
                            ...item,
                            indent_quantity: 1,
                            indent_weight: item.product_std_weight,
                            product_tech_spect: item.product_tech_spect,
                            approval_remark: "",
                            indent_item_status: 'P',
                            so_sr_no: 0,
                            cost_center_id: '',
                        };
                    })
                ];
                sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedArray));
                let materialScheduleTblRows = JSON.parse(localStorage.getItem('indentScheduleData')) === null ? [] : JSON.parse(localStorage.getItem('indentScheduleData'));
                if (materialScheduleTblRows.length > 0) {
                    // const updatedMaterialSchedules = materialScheduleTblRows.filter(item => item.product_material_id !== row.product_id);
                    const updatedMaterialSchedules = materialScheduleTblRows.filter(item => updatedArray.some(detailsItem => detailsItem.product_id === item.product_material_id))
                    setScheduleDataArray(updatedMaterialSchedules);
                    localStorage.setItem('indentScheduleData', JSON.stringify(updatedMaterialSchedules));
                }
                productIds = updatedArray.map(item => item.product_id);

                return updatedArray;
            });

            sessionStorage.setItem('isComboFilterExist', false)
            // Remove Bom Filter Session
            var LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`))
            console.log('LocalArr: ', LocalArr)
            if (LocalArr != null) {
                for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                    localStorage.removeItem(LocalArr[localArrIndex])
                }
            }

            if (requestfor === "PRM") {
                getStockDetails(productIds);
                getpropertiesData(productIds);
            }

        } else {
            setBomfilteredData([])
            setScheduleDataArray([])
            localStorage.setItem('indentScheduleData', JSON.stringify([]));
            sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
        }
        removeSessions();
        setTimeout(() => { categoryDisplay(); }, 300);
    };

    const getStockDetails = async (productIds) => {
        try {
            setIsLoading(true)
            if (requestfor === "PRM") {
                for (const productId of productIds) {
                    // Check if the product material id is already in stock Details
                    const isStockDetails = stockDetails.some(item => item.product_rm_id === productId);
                    if (!isStockDetails) {
                        resetGlobalQuery();
                        globalQuery.columns.push("*");
                        globalQuery.table = "smv_product_rm_stock_details";
                        globalQuery.conditions = [];
                        globalQuery.conditions.push({ field: "product_rm_id", operator: "=", value: productId }); // Use each product_id individually
                        globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [COMPANY_ID] });
                        globalQuery.conditions.push({ field: "day_closed", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "closing_balance_quantity", operator: ">", value: 0 });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });


                        const newStockDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                        // If control_type !== "C", append the new data to the existing array
                        setStockDetails(prevStockDetails => [
                            // ...prevStockDetails,
                            ...prevStockDetails.filter(prop => prop.product_rm_id !== newStockDetails.product_rm_id),

                            ...newStockDetails
                        ]);
                        console.log("New stock details added successfully:", newStockDetails);

                    }

                }
            }
        } catch (error) {
            console.error("Error fetching Stock Details", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getpropertiesData = async (productIds) => {
        if (requestfor === "PRM") {
            for (const productId of productIds) {
                // Check if the productId is already in productProperties
                const isProductIdInProperties = productpropertisList.some(item => item.product_id === productId);
                if (!isProductIdInProperties) {
                    resetGlobalQuery();
                    globalQuery.columns.push("*");
                    globalQuery.table = "smv_product_dynamic_parameters";
                    globalQuery.conditions = [];
                    globalQuery.conditions.push({ field: "product_id", operator: "=", value: productId }); // Use each product_id individually
                    globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [COMPANY_ID] });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                    try {
                        const newProductDynamicParameters = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                        // Check for control_type === "C"
                        const hasControlTypeC = newProductDynamicParameters.some(item => item.control_type === "C");
                        if (hasControlTypeC) {
                            for (const param of newProductDynamicParameters) {
                                if (param.control_master === "xmv_spinning_prod_count" && param.control_type === "C") {
                                    resetGlobalQuery();
                                    globalQuery.columns = ["field_name", "field_id", "production_actual_count"];
                                    globalQuery.table = "xmv_spinning_prod_count";
                                    globalQuery.conditions = [];
                                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                                    globalQuery.conditions.push({ field: "field_id", operator: "=", value: param.product_parameter_value });

                                    const masterData = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                                    console.log("param :", masterData);
                                    // Map through the returned master data and set the field_name as product_parameter_value_name
                                    // const updatedProductProperties = newProductDynamicParameters.map(param => {
                                    //     const matchingMasterData = masterData.find(master => master.field_id === parseInt(param.product_parameter_value));
                                    const updatedProductProperties = newProductDynamicParameters.map(param => {
                                        const fieldId = parseInt(param.product_parameter_value, 10); // Convert to integer
                                        const matchingMasterData = masterData.find(master => master.field_id === fieldId);

                                        return {
                                            ...param,
                                            product_parameter_value_name: matchingMasterData ? matchingMasterData.field_name : param.product_parameter_value_name,
                                            ...matchingMasterData
                                        };
                                    });

                                    console.log("updatedProductProperties :", updatedProductProperties);

                                    // Set the updated properties
                                    setProductProperties(prevProperties => [
                                        ...prevProperties.filter(prop => prop.product_id !== param.product_id),
                                        ...updatedProductProperties
                                    ]);
                                }
                            }
                        } else {
                            // If control_type !== "C", append the new data to the existing array
                            setProductProperties(prevProperties => [
                                ...prevProperties,
                                ...newProductDynamicParameters
                            ]);
                        }
                    } catch (error) {
                        console.error("Error fetching product properties:", error);
                    }
                }
            }
        }
    };

    // Function to handle input changes and update state variables
    const handleInputChange = (currentRowData, event) => {
        console.log('currentRowData:- ', currentRowData);
        console.log('event:- ', event);
        let clickedColName = event.target.getAttribute('Headers');               // Get the column name where event triggered.
        let enteredValue = parseInt(event.target.value);
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (clickedColName === 'cost_center_id') {
            if (event.target.value !== '') {
                currentRowData[clickedColName] = event.target.value;
                delete indentItemsQtyinp.parentElement.dataset.tip;
                if (event.target.value === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Cost Center')
                    setShowAddRecIndentModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(0).css("padding-top", "0px");
                    }, 200)
                }
            } else {
                indentItemsQtyinp.parentElement.dataset.tip = 'Please select cost center...!';
                currentRowData[clickedColName] = '';
            }
        }
        else if (clickedColName === 'approval_remark') {

            if (event.target.value !== '') {
                currentRowData[clickedColName] = event.target.value;
                delete indentItemsQtyinp.parentElement.dataset.tip;
            } else {
                currentRowData[clickedColName] = '';
                indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid approval remark...!';
            }
        } else if (clickedColName === 'remark') {
            currentRowData[clickedColName] = event.target.value;
        }
        else if (clickedColName === 'indent_item_status') {
            currentRowData[clickedColName] = event.target.value;
        } else if (validateMaterialDetails(event)) {                      // if valid then update that value and go for calculation.
            let material_qty = currentRowData['indent_quantity']
            if (keyForViewUpdate === 'approve') {
                let product_app_qty = enteredValue
                let product_rejct_qty = (material_qty - product_app_qty);
                let product_std_wgt = currentRowData['product_std_weight']
                let product_approved_wgt = product_app_qty * product_std_wgt;
                let product_rejct_wgt = product_rejct_qty * product_std_wgt;

                currentRowData['product_material_approved_weight'] = validateNumberDateInput.current.decimalNumber(product_approved_wgt, 4)
                currentRowData['product_material_rejected_weight'] = validateNumberDateInput.current.decimalNumber(product_rejct_wgt, 4)
                let indentItemsQtyinp = document.querySelector('#' + event.target.id);

                if (product_app_qty > material_qty) {
                    indentItemsQtyinp.parentElement.dataset.tip = `Approve qauntity not be greater than ${material_qty}...!`;
                    return false;
                } else {
                    currentRowData[clickedColName] = enteredValue;
                    // Update the schedules on the product_material_approved_quantity basis. (On Approve Action)
                    if (event._reactName === 'onBlur' && keyForViewUpdate === 'approve' && clickedColName === 'product_material_approved_quantity' && scheduleDataArray.length !== 0) {
                        FnUpdateSchedulesOnQtyChng(scheduleDataArray, currentRowData, 'product_material_approved_quantity');
                    }
                }

                if (product_rejct_qty > 0) {
                    currentRowData['product_material_rejected_quantity'] = product_rejct_qty
                    var $rejectionRemark = $(`#approval_remark_${currentRowData.product_id}`);
                    if ($rejectionRemark.val() === "") {
                        $rejectionRemark.prop('disabled', false);
                        if (event._reactName === 'onBlur') {
                            $rejectionRemark.focus();
                        }

                    }
                } else {
                    currentRowData['product_material_rejected_quantity'] = 0
                    delete indentItemsQtyinp.parentElement.dataset.tip;
                }
            } else if (clickedColName === 'product_material_preclosed_quantity') {
                
                let preclosed_quantity = event.target.value;
                if(preclosed_quantity >  currentRowData.product_material_approved_quantity){
                    preclosed_quantity == 0 ? currentRowData.indent_item_status = "A": currentRowData.indent_item_status = "Z";
                    indentItemsQtyinp.parentElement.dataset.tip = `Preclose qauntity not be greater than ${currentRowData.product_material_approved_quantity}...!`;
                   return false;
               }else{
                currentRowData[clickedColName] = preclosed_quantity;  
                preclosed_quantity == 0 ? currentRowData.indent_item_status = "A": currentRowData.indent_item_status = "Z";
                delete indentItemsQtyinp.parentElement.dataset.tip;
               }
            }else if (enteredValue > 0) {
                if (chk_indent_source === 'S' && requestfor !== 'YN' && requestfor !== 'PRM') {
                    const cust_materialqty = currentRowData['prev_indent_quantity']
                    if (enteredValue <= cust_materialqty) {
                        currentRowData[clickedColName] = enteredValue;
                        delete indentItemsQtyinp.parentElement.dataset.tip;
                    } else {
                        currentRowData[clickedColName] = '';
                        indentItemsQtyinp.parentElement.dataset.tip = `Please enter less than ${cust_materialqty} ...!`;
                    }

                } else {
                    currentRowData[clickedColName] = enteredValue;
                }
                const indentQty = currentRowData['indent_quantity']
                let product_std_wgt = currentRowData['product_std_weight']
                let product_material_wgt = indentQty * product_std_wgt;
                currentRowData['indent_weight'] = validateNumberDateInput.current.decimalNumber(product_material_wgt, 4);
                // Update the schedules on the indent_quantity basis. (On Edit Action)
                if (event._reactName === 'onBlur' && keyForViewUpdate === 'update' && clickedColName === 'indent_quantity' && scheduleDataArray.length !== 0) {
                    FnUpdateSchedulesOnQtyChng(scheduleDataArray, currentRowData, 'indent_quantity');
                }
            }
        }
        else {
            currentRowData[clickedColName] = '';
        }
        const detailData = [...bomfilteredData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        detailData[arrayIndex] = currentRowData
        setBomfilteredData(detailData);                        // Set that updated data in table data hook;
        sessionStorage.setItem('filteredMaterialData', JSON.stringify(detailData)); // Set that updated data in session.
    };

    const handleScheduleData = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentRowData[clickedColName] = event.target.value;            // Get the column name where event triggered.
        const indentschedule_data = [...scheduleDataArray]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        indentschedule_data[arrayIndex] = currentRowData
        setScheduleDataArray(indentschedule_data);
    }
    // Function to handle input changes and update state variables
    const validateMaterialDetails = (event) => {
        const numCheck = /^\d{0,18}(?:\.\d{0,4})?$/;

        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === null || !numCheck.test(event.target.value)) {

            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid input...!';

            return false;
        } else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }

    const FnUpdateSchedulesOnQtyChng = (allSchedulesData, currentMaterial, dependsOnQty) => {
        const qtyToSchedule = parseFloat(currentMaterial[dependsOnQty] || 0);
        let currentTotal = 0;
        const updatedSchedulesData = allSchedulesData.reduce((accumulator, schedule) => {
            if (schedule.product_material_id === currentMaterial.product_id) {
                schedule.product_material_schedule_quantity = parseFloat(schedule.product_material_schedule_quantity || 0)
                currentTotal += schedule.product_material_schedule_quantity;
                accumulator.currentMaterialSchedules.push(schedule);
            } else {
                accumulator.otherMaterialSchedules.push(schedule);
            }
            return accumulator;
        }, { currentMaterialSchedules: [], otherMaterialSchedules: [] });

        if (Math.abs(currentTotal - qtyToSchedule) < Number.EPSILON) {
            console.log("No need to update the schedules quantities.");
        } else if (currentTotal < qtyToSchedule) {
            const excessQuantity = qtyToSchedule - currentTotal;
            updatedSchedulesData.currentMaterialSchedules[updatedSchedulesData.currentMaterialSchedules.length - 1].product_material_schedule_quantity += excessQuantity;
        } else {
            let remaining = currentTotal - qtyToSchedule;
            for (let scheduleIndex = updatedSchedulesData.currentMaterialSchedules.length - 1; scheduleIndex >= 0; scheduleIndex--) {
                if (updatedSchedulesData.currentMaterialSchedules[scheduleIndex].product_material_schedule_quantity <= remaining) {
                    remaining -= updatedSchedulesData.currentMaterialSchedules[scheduleIndex].product_material_schedule_quantity;
                    updatedSchedulesData.currentMaterialSchedules[scheduleIndex].product_material_schedule_quantity = 0;
                } else {
                    updatedSchedulesData.currentMaterialSchedules[scheduleIndex].product_material_schedule_quantity -= remaining;
                    break;
                }
            }
        }
        // Update product_material_schedule_weight based on updated quantity needed this to update details
        updatedSchedulesData.currentMaterialSchedules.forEach(schedule => {
            let updatedWt = schedule.product_material_schedule_quantity * parseFloat(currentMaterial['product_std_weight'] || 0);
            schedule.product_material_schedule_weight = validateNumberDateInput.current.decimalNumber(updatedWt, 2).toString();
            schedule.material_quantity = currentMaterial['indent_quantity'];
            schedule.material_weight = currentMaterial['indent_weight'];
        });
        const filteredCurrentMaterialSchedules = updatedSchedulesData.currentMaterialSchedules.filter(item => item.product_material_schedule_quantity > 0);
        const finalSchedulesData = filteredCurrentMaterialSchedules.concat(updatedSchedulesData.otherMaterialSchedules);
        setScheduleDataArray(finalSchedulesData);
        localStorage.setItem('indentScheduleData', JSON.stringify(finalSchedulesData));
    }


    // fn to remove material
    const removeMaterial = (row, e) => {
        
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            const detailData = [...bomfilteredData]
            const findElementIndex = detailData.findIndex(record => record.product_id === row.product_id);
            if (findElementIndex !== -1) { detailData.splice(findElementIndex, 1); }
            setBomfilteredData(detailData);
            if (requestfor === "PRM") {
                setProductProperties(prevProperties => {
                    // Filter out the product with the matching product_id
                    return prevProperties.filter(property => property.product_id !== row.product_id);
                });
                setStockDetails(prevStock => {
                    // Filter out the product with the matching product_id
                    return prevStock.filter(stock => stock.product_rm_id !== row.product_id);
                });
            }
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(detailData));
            let materialScheduleTblRows = JSON.parse(localStorage.getItem('indentScheduleData')) === null ? [] : JSON.parse(localStorage.getItem('indentScheduleData'));
            if (materialScheduleTblRows.length > 0) {
                const updatedMaterialSchedules = materialScheduleTblRows.filter(item => item.product_material_id !== row.product_id);
                setScheduleDataArray(updatedMaterialSchedules);
                localStorage.setItem('indentScheduleData', JSON.stringify(updatedMaterialSchedules));
            }
        }
    }
    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('indent-details-tbl');
        var rows = table.getElementsByTagName('tr');

        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#indent-details-tbl').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('indent-details-tbl');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    //-------------------------------------------End Details section --------------------------------------------------------------------------

    // ---------------------------------------------------------Start Schedule -----------------------------------------------------------------------------------------
    const addNewScheduleRow = () => {
        setSchedulerowcount(schedulerowcount + 1)
        addScheduleTblRow(schedulerowcount + 1);
    }

    const openSchedules = (indentItem) => {
        if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete') {
            let product_name = indentItem.product_name;
            let sales_ord_id = indentItem.sales_order_details_transaction_id;

            let product_id = indentItem.product_id;
            let indent_quantity = indentItem.indent_quantity;
            let product_unit_name = indentItem.product_unit_name;
            let product_unit_id = indentItem.product_unit_id;
            let std_weight = indentItem.product_std_weight;
            let so_srNo = indentItem.so_sr_no;
            let approve_qty = indentItem.product_material_approved_quantity;
            let schCount = 1;
            setSchedules(true)
            setTimeout(() => {
                $('#productMatName').append(product_name);
                $('#productMatName').attr('product_id', product_id);
                $('#productMatName').attr('sales_ord_id', sales_ord_id);
                $('#soSrNo').append(so_srNo);
                $('#soSrNo').attr('soSrNo', so_srNo);
                if (keyForViewUpdate !== 'approve') {
                    $('#productMatQty').append(indent_quantity);
                } else {
                    $('#productMatQty').append(approve_qty);
                }
                $('#productMatQty').attr('material_quantity', indent_quantity);
                $('#productMatStdWeight').append(std_weight);
                const avgWt = parseFloat(std_weight) * parseInt(indent_quantity);
                $('#productMatTotalWeight').append(avgWt.toFixed(2));
                $('#productMatUnit').append(product_unit_name);
                $('#productMatUnit').attr('product_unit_id', product_unit_id);

                let updatedScheduleDataArray = JSON.parse(localStorage.getItem('indentScheduleData'));

                console.log(...scheduleDataArray)
                let filteredSchedule = [];
                if (updatedScheduleDataArray !== null) {
                    filteredSchedule = updatedScheduleDataArray.filter(item => {
                        return item.product_material_id === product_id;

                    });
                }

                if (filteredSchedule.length !== 0) {
                    filteredSchedule.forEach(item => {
                        addScheduleTblRow(schCount);
                        $('#scheduleQty-' + schCount).val(item.product_material_schedule_quantity)
                        $('#scheduleWt-' + schCount).val(item.product_material_schedule_weight)
                        $('#scheduleDate-' + schCount).val(item.expected_schedule_date)
                        $('#mtRecptQty-' + schCount).val(item.product_material_receipt_quantity)
                        $('#mtRecptWt-' + schCount).val(item.product_material_receipt_weight)
                        $('#mtRecptDate-' + schCount).val(item.product_material_receipt_date)
                        $('#schedulrRemark-' + schCount).val(item.remark)
                        schCount += 1;
                        console.log(item);
                    });
                } else {
                    remainingSchduleQty = indent_quantity;
                    addScheduleTblRow(schCount);
                    schCount += 1;
                }
                setSchedulerowcount(schCount)
            }, 300)
        }
    }

    const addScheduleTblRow = (rowCount) => {
        if (rowCount !== 1) {
            let newRow = $(`<tr role="row" scheduleTrCount="${rowCount}" id="RMSchedulesEntry-tr-${rowCount}"></tr>`);
            let secondLastRow = $("#scheduleTable tr").eq(-1);
            newRow.insertBefore(secondLastRow);
            $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append('<span class="RemoveTrBtn"  id="RemoveTrBtn-' + rowCount + '" name=' + rowCount + ' value=' + rowCount + '><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="erp_trRemove_icon"  height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-miterlimit="10" stroke-width="32" d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"></path><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M336 256H176"></path></svg></span>'))
        }
        const materialStdWt = parseFloat($('#productMatStdWeight').text().trim());
        const averageWt = materialStdWt * remainingSchduleQty;
        const createTd2 = $('<input type="number" rowIndex="' + rowCount + '" class="scheduleQty" id="scheduleQty-' + rowCount + '" value="' + remainingSchduleQty + '"/>').addClass("erp_input_field mb-0")
        const createTd3 = $('<input type="text" rowIndex="' + rowCount + '" id="scheduleWt-' + rowCount + '" value="' + averageWt.toFixed(2) + '" maxLength="50"/>').addClass("erp_input_field mb-0")
        const createTd4 = $('<input type="date" rowIndex="' + rowCount + '" id="scheduleDate-' + rowCount + '" value="' + txt_schedule_date + '" />').addClass("erp_input_field mb-0")
        const createTd5 = $('<input type="number" rowIndex="' + rowCount + '" id="mtRecptQty-' + rowCount + '" maxLength="50"/>').addClass("erp_input_field mb-0")
        const createTd6 = $('<input type="text" rowIndex="' + rowCount + '" id="mtRecptWt-' + rowCount + '" maxLength="50"/>').addClass("erp_input_field mb-0")
        const createTd7 = $('<input type="date" rowIndex="' + rowCount + '" id="mtRecptDate-' + rowCount + '" value="" maxLength="50"/>').addClass("erp_input_field mb-0")
        const createTd8 = $(`<select rowIndex='${rowCount}' id='scheduleStatus-${rowCount}' disabled='disabled' class="form-select form-select-sm">
        <option value="P">Pending</option> <option value="A">Approved</option>
       <option value="I">Partial receipt</option> <option value="R">Rejected</option> 
        <option value="C">Completed</option>  <option value="X">Canceled</option><option value="Z">PreClosed</option>
    </select>`);
        const createTd9 = $('<textarea type="text" rowIndex="' + rowCount + '" rowIndex="' + rowCount + '" id="schedulrRemark-' + rowCount + '" maxLength="255" ></textarea>').addClass("erp_input_field_table_txt mb-0")
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd2));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd3));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd4));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd5));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd6));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd7));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd8));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd9));

        const datePicker = document.getElementById('scheduleDate-' + rowCount);
        datePicker.min = todayDate();
        const datePicker2 = document.getElementById('mtRecptDate-' + rowCount);
        datePicker2.min = todayDate();
        $('#scheduleTable tbody').on('click', '.RemoveTrBtn', function () {
            var $tr = $(this).closest('tr');
            $tr.remove();
        });
        $('.scheduleQty').on('input', function (e) {
            if (validateMaterialDetails(e)) {
                $(this).val(parseInt($(this).val(), 10));
                let rowIndex = $(this).attr("rowIndex");
                const materialStdWt = parseFloat($('#productMatStdWeight').text().trim());
                const averageWt = materialStdWt * parseFloat($(this).val().trim());
                $(`#scheduleWt-${rowIndex}`).val(averageWt.toFixed(4));
            } else {
                $(this).focus();
            }
        });
    }
    function schedule() {

        let checkIsValidated = validateSchedules();
        if (checkIsValidated === true) {
            const table = document.getElementById('scheduleTable');
            const tbody = table.querySelector('tbody');
            const trElements = tbody.querySelectorAll('tr');
            let soSrNo = parseInt($('#soSrNo').attr('soSrNo'));
            let product_id = $('#productMatName').attr('product_id');
            let sales_ord_id = parseInt($('#productMatName').attr('sales_ord_id'));

            let updatedScheduleDataArray = [];
            updatedScheduleDataArray = [...scheduleDataArray].filter(item => {
                return item.product_material_id !== product_id;
            });

            for (let count = 0; count < trElements.length; count++) {
                let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');
                const scheduleData = {
                    id: isNaN(sales_ord_id) || sales_ord_id === '' ? product_id + ':' + count : parseInt(sales_ord_id) + ':' + product_id + ':' + parseInt(soSrNo) + ":" + count,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    indent_master_id: isApprove ? indent_master_id : 0,
                    product_rm_name: $('#productMatName').text(),
                    indent_no: txt_indent_no,
                    indent_date: txt_indent_date,
                    indent_version: txt_indent_version,
                    sales_order_details_transaction_id: sales_ord_id,
                    so_sr_no: isNaN(soSrNo) ? 0 : parseInt(soSrNo),
                    customer_order_no: cmb_customer_order_no === '' ? 'NA' : cmb_customer_order_no,
                    product_material_id: product_id,
                    product_material_unit_id: parseInt($('#productMatUnit').attr('product_unit_id')),
                    product_material_schedule_quantity: $('#scheduleQty-' + scheduleTrCount).val(),
                    product_material_schedule_weight: $('#scheduleWt-' + scheduleTrCount).val(),
                    material_weight: $('#productMatTotalWeight').text(),
                    material_quantity: $('#productMatQty').text(),
                    expected_schedule_date: $('#scheduleDate-' + scheduleTrCount).val(),
                    product_material_receipt_quantity: $('#mtRecptQty-' + scheduleTrCount).val(),
                    product_material_receipt_weight: $('#mtRecptWt-' + scheduleTrCount).val(),
                    material_receipt_date: $('#mtRecptDate-' + scheduleTrCount).val(),
                    indent_item_status: chk_indent_status,
                    remark: $('#schedulrRemark-' + scheduleTrCount).val(),
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' ? UserName : ''
                }
                // Add the new data to the updatedScheduleDataArray
                updatedScheduleDataArray.push(scheduleData);
            }
            setScheduleDataArray(updatedScheduleDataArray);
            localStorage.setItem('indentScheduleData', JSON.stringify(updatedScheduleDataArray));
            setSchedules(false)
        }
        console.log(checkIsValidated)
    }
    const deleteSchedules = (idToRemove) => {

        if (keyForViewUpdate !== 'view') {
            let scheduleData = JSON.parse(localStorage.getItem('indentScheduleData')) === null ? [] : JSON.parse(localStorage.getItem('indentScheduleData'));
            const filteredScheduleData = scheduleData.filter(item => item.id !== idToRemove);
            setScheduleDataArray(filteredScheduleData);
            localStorage.setItem('indentScheduleData', JSON.stringify(filteredScheduleData));

        }
    }
    const validateSchedules = () => {
        let result = true;
        const table = document.getElementById('scheduleTable');
        const tbody = table.querySelector('tbody');
        const trElements = tbody.querySelectorAll('tr');
        let addedQty = 0;

        for (let count = 0; count < trElements.length; count++) {
            let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');

            let scheduleQty = $('#scheduleQty-' + scheduleTrCount).val();
            let scheduleQtyinp = document.querySelector('#scheduleQty-' + scheduleTrCount);
            addedQty += parseInt(scheduleQty);
            if (scheduleQty === '' || (parseInt(scheduleQty) === 0 || parseInt(scheduleQty) < 0)) {
                scheduleQtyinp.parentElement.dataset.tip = 'Please enter valid quantity!...';
                return false;
            } else { delete scheduleQtyinp.parentElement.dataset.tip; }

            const scheduleDate = $('#scheduleDate-' + scheduleTrCount).val();
            const scheduleDateinp = document.querySelector('#scheduleDate-' + scheduleTrCount);

            if (scheduleDate === '') {
                scheduleDateinp.parentElement.dataset.tip = 'Please enter schedule date!...';
                return false;
            } else { delete scheduleDateinp.parentElement.dataset.tip; }


        }
        if (addedQty > parseInt($('#productMatQty').text())) {
            setErrMsg('Schedule quantity not more than Total quantity  ' + parseInt($('#productMatQty').text()) + ' !...')
            setShowErrorMsgModal(true)
            return false;
        } else if (addedQty < parseInt($('#productMatQty').text())) {
            setErrMsg('Schedule quantity not less than Product material quantity ' + parseInt($('#productMatQty').text()) + ' !...')
            setShowErrorMsgModal(true)
            return false;
        }

        return result;
    }
    //table for common terms
    const renderScheduleTable = useMemo(() => {
        
        return <>
            <Table className="erp_table erp_table_scroll" id='po-schedule-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Indent No</th>
                        <th className="erp_table_th">Indent Date</th>
                        <th className="erp_table_th">Indent Version</th>
                        <th className="erp_table_th">Schedule Quantity</th>
                        <th className="erp_table_th">Schedule Weight</th>
                        <th className="erp_table_th">Expected Schedule Date</th>
                        <th className="erp_table_th">Receipt Quantity</th>
                        <th className="erp_table_th">Receipt Weight</th>
                        <th className="erp_table_th">Indent Item Status</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        scheduleDataArray.map((scheduleItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className={`erp_table_td  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}><div style={{ display: "flex" }}>
                                    <Tooltip title="Delete" placement="top">
                                        <MDTypography className="erp-view-btn">
                                            <MdDelete className="erp-delete-btn" id={scheduleItem.id} onClick={() => deleteSchedules(scheduleItem.id)} />
                                        </MDTypography>
                                    </Tooltip>
                                </div>
                                </td>

                                <td className="erp_table_td"> {scheduleItem.product_rm_name} </td>
                                <td className="erp_table_td  ">{scheduleItem.indent_no}</td>
                                <td className="erp_table_td  ">{validateNumberDateInput.current.formatDateToDDMMYYYY(scheduleItem.indent_date)}</td>
                                <td className="erp_table_td text-end">{scheduleItem.indent_version}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_schedule_quantity}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_schedule_weight}</td>
                                <td className="erp_table_td  ">{validateNumberDateInput.current.formatDateToDDMMYYYY(scheduleItem.expected_schedule_date)}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_receipt_quantity}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_receipt_weight}</td>
                                <td> <select id={`#indent_item_status${scheduleItem.product_material_id}`} disabled={(keyForViewUpdate !== 'approve')}
                                    className="form-select form-select-sm approveField" Headers='indent_item_status' value={scheduleItem.indent_item_status}
                                    onChange={(e) => handleScheduleData(scheduleItem, e)} >
                                    <option value="P">Pending</option>
                                    <option value="A">Approved</option>
                                    <option value="R">Rejected</option>
                                    <option value="C">Completed</option>
                                    <option value="X">Canceled</option>
                                    <option value="Z">PreClosed</option>
                                    {/* <option value="I">Partial Issue</option> 
                                     <option value="O">Purchse Order Genreated</option>
                                    <option value="POA">Purchse Order Approved</option>
                                    <option value="G">Goods Receipts</option>
                                    <option value="IG">Partial Goods Receipts</option>
                                    <option value="MI">Material Issue</option> */}
                                </select></td>
                                <td className="erp_table_td">
                                    <input
                                        type="textarea"
                                        className="erp_input_field mb-0 "
                                        id={`${`remark`}_${scheduleItem.product_material_id}`} disabled={true}
                                        value={scheduleItem.remark} maxLength={500} // Use a function to get the input value based on propertyName

                                    />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [scheduleDataArray]);

    //------------------------------------------------------End Schedule ----------------------------------------------------------------------------------------------------------
    const validateFields = () => {
        var formObj = $('#indentFormId');
        var inputObj;

        if(category_2_id==='' && requestfor === "PRM"){
                        $("#error_category_2_id").text("Please fill this field...!");
                        $("#error_category_2_id").show();
                        $("#error_category_2_id").focus();
                        return false;
        }

        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            }
        }

    }

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData')
            localStorage.removeItem('indentScheduleData')
        }
        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const categoryDisplay = () => {

        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }

    const ExportToExcel = async () => {

        const catCount = sessionStorage.getItem('compRMCatCount')
        var counter = 1;
        if (bomfilteredData.length !== 0) {
            let customerVal = customerComboRef.current.value || '';
            let indentedBy = intentedByComboRef.current.value || '';

            var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [5] }

            let customerOrdDate = validateNumberDateInput.current.formatDateToDDMMYYYY(customer_order_date);

            jsonToExportExcel.filtrKeyValue['0'] = "Indent No : " + txt_indent_no;
            jsonToExportExcel.filtrKeyValue['1'] = "Indent Version : " + txt_indent_version;
            jsonToExportExcel.filtrKeyValue['2'] = "Indent Source : " + $('input[name="chk_indent_source"]:checked').attr("lbl") + "(" + chk_indent_source + ")";
            // jsonToExportExcel.filtrKeyValue['3'] = "Customer : " + (cmb_customer !== '' && cmb_customer !== '0' && cmb_customer !== null ? $('#cmb_customer').find(":selected").text() + "(" + cmb_customer + ")" : '');
            jsonToExportExcel.filtrKeyValue['3'] = "Customer : " + (customerVal !== "" && customerVal !== '0' && customerVal !== null ? customerComboRef.current?.label + "(" + customerComboRef.current?.value + ")" : '');
            jsonToExportExcel.filtrKeyValue['4'] = "Customer Order No. : " + (cmb_customer_order_no === '' ? '' : cmb_customer_order_no + "(" + cmb_customer_order_no + ")");
            jsonToExportExcel.filtrKeyValue['5'] = "Customer Order Date : " + (customer_order_date === '' ? '' : customerOrdDate + "(" + customerOrdDate + ")")
            jsonToExportExcel.filtrKeyValue['6'] = "Department : " + (cmb_department !== '' && cmb_department !== '0' && cmb_department !== null ? $('#cmb_department').find(":selected").text() + "(" + cmb_department + ")" : "");
            // jsonToExportExcel.filtrKeyValue['7'] = "Indented By : " + (cmb_indented_by !== '' && cmb_indented_by !== '0' && cmb_indented_by !== null ? $('#cmb_indented_by').find(":selected").text() + "(" + cmb_indented_by + ")" : "");
            jsonToExportExcel.filtrKeyValue['7'] = "Indented By : " + (indentedBy !== '' && indentedBy !== '0' && indentedBy !== null ? intentedByComboRef.current?.label + "(" + intentedByComboRef.current?.value + ")" : "");
            jsonToExportExcel.filtrKeyValue['8'] = "Indent Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_indent_date);
            jsonToExportExcel.filtrKeyValue['9'] = "Approved By : " + (cmb_approved_by_id !== '0' && cmb_approved_by_id !== '' && cmb_approved_by_id !== null && cmb_approved_by_id !== undefined ? $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")" : '');
            jsonToExportExcel.filtrKeyValue['10'] = "Approved Date : " + (txt_approved_date !== '' && txt_approved_date !== undefined ? validateNumberDateInput.current.formatDateToDDMMYYYY(txt_approved_date) : "");
            jsonToExportExcel.filtrKeyValue['11'] = "Expected Schedule Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_schedule_date);
            jsonToExportExcel.filtrKeyValue['12'] = "Indent Status : " + $('#chk_indent_status').find(":selected").text() + "(" + chk_indent_status + ")";
            jsonToExportExcel.filtrKeyValue['13'] = "Remark : " + txt_remark;
            jsonToExportExcel.filtrKeyValue['14'] = "Active : " + $("input[name='chk_isactive']:checked").attr("lbl") + "(" + chk_isactive + ")";


            // set columns name.
            for (let col = 0; col < requiredCols.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": requiredCols[col], "accessor": requiredCols[col] })
            }
            bomfilteredData.map((orderDetail, Index) => {
                orderDetail.sr_no = Index + 1;
                jsonToExportExcel['allData'][Index] = orderDetail;
            });

            jsonToExportExcel['headings']['ReportName'] = "Requisition Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_indent_no + "@" + COMPANY_ID)
        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }

    }

    // --------------------------------------- indent Import Start ------------------------------------------------------------------

    const onImportClick = () => {
        importFile.current.click();
    };

    const importedExcelFile = async (event) => {

        try {
            if (event.target.files) {
                setIsLoading(true)
                var file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();

                console.log(fetchRes)
                if (fetchRes.success === '1') {
                    if (fetchRes.data.length !== 0) {
                        let creation_type = '';
                        let indent_no = "";
                        let order_no = "";

                        // Set order details data.
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            if (isNaN(parseFloat(orderDetail.indent_quantity))) {
                                orderDetail.indent_quantity = 0;
                            }
                            let calculatedWt = parseFloat(orderDetail.indent_quantity) * parseFloat(orderDetail.product_std_weight);
                            orderDetail.indent_weight = validateNumberDateInput.current.decimalNumber(calculatedWt.toString(), 4);
                            return orderDetail;
                        });
                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                let comboVal;

                                if (fieldVal !== "" && (fieldName === 'Indented By' || fieldName === 'Customer Order No.' || fieldName === 'Customer Order Date' || fieldName === 'Indent Status' || fieldName === 'Indent Source' || fieldName === 'Department' || fieldName === 'Customer')) {
                                    if (fieldVal !== "") {
                                        const splitVal = fieldVal.split('(')
                                        comboVal = splitVal[1].replace(')', '')
                                    }
                                }
                                switch (fieldName) {

                                    case 'Indent Source':
                                        setIndentSource(comboVal)
                                        creation_type = comboVal;
                                        if (comboVal === 'S') {
                                            $('#add-material').prop('disabled', true);
                                        }
                                        break;
                                    case 'Customer':
                                        setCustomerId(comboVal)
                                        await comboOnChange("customer")
                                        setCustomerOrderNo(order_no)
                                        await comboOnChange('CustomerOrder')
                                        break;
                                    case 'Customer Order No.':
                                        order_no = comboVal;
                                        setCustomerOrderNo(comboVal)
                                        break;
                                    case 'Customer Order Date':
                                        setCustomerOrderDate(comboVal)
                                        break;
                                    // case 'Indent Date':
                                    //     setIndentDate(fieldVal)
                                    //     break;
                                    case 'Department':
                                        setDepartmentId(comboVal)
                                        await comboOnChange('departmentId')
                                        break;

                                    case 'Indent Status':
                                        setIndentStatus(comboVal)
                                        break;
                                    case 'Remark':
                                        setRemark(fieldVal)
                                        break;
                                }
                            }
                        }
                        setBomfilteredData(() => {
                            let modifiedImportData = [
                                ...readedOrderDetailsFromExcel.map((ordDetail) => ({
                                    ...ordDetail,
                                    indent_item_status: 'P',
                                    indent_master_id: 0,
                                    indent_version: 1,
                                    indent_no: indent_no,
                                    prev_indent_quantity: ordDetail.indent_quantity,
                                    prev_indent_weight: ordDetail.indent_weight,
                                }))
                            ]
                            sessionStorage.setItem('filteredMaterialData', JSON.stringify(modifiedImportData))
                            return [...modifiedImportData];
                        })
                        setScheduleDataArray([])
                        localStorage.setItem('indentScheduleData', JSON.stringify([]))
                        categoryDisplay();
                        $(".showData").show()
                        setIsLoading(false)
                        return fetchRes;
                    }
                } else {
                    setErrMsg(fetchRes.error)
                    setIsLoading(false)
                    setShowErrorMsgModal(true)
                }


            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    // --------------------------------------- PO Import End ------------------------------------------------------------------
    // ---------------------fn for save indent ----------------------------------------------------------------
    const addIndent = async (indentStatus) => {
        debugger

        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                checkIsValidate = await FnValidateIndentOrder();
            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                const lastIndentNo = await GenerateIndentNo();
                let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransScheduleData': [], 'TransProductTypeParametersData': [], 'TransPOTermsData': [], 'commonIds': {} }
                // Trans DetailData
                for (let bomDetailIndex = 0; bomDetailIndex < bomfilteredData.length; bomDetailIndex++) {

                    var crJson = {}
                    const bomfilteredDataElement = bomfilteredData[bomDetailIndex];
                    if (isApprove) {
                        crJson['indent_details_id'] = bomfilteredDataElement.indent_details_id !== undefined ? bomfilteredDataElement.indent_details_id : 0;
                        crJson['indent_master_id'] = indent_master_id;
                    }
                    crJson['company_id'] = COMPANY_ID
                    crJson['company_branch_id'] = COMPANY_BRANCH_ID
                    crJson['indent_no'] = keyForViewUpdate === '' ? lastIndentNo : txt_indent_no
                    crJson['indent_version'] = txt_indent_version
                    crJson['indent_date'] = txt_indent_date
                    crJson['financial_year'] = FINANCIAL_SHORT_NAME
                    crJson['customer_order_no'] = cmb_customer_order_no === '' || cmb_customer_order_no === 0 ? 'NA' : cmb_customer_order_no
                    crJson['so_sr_no'] = bomfilteredDataElement.so_sr_no === undefined || bomfilteredDataElement.so_sr_no === null ? 0 : bomfilteredDataElement.so_sr_no
                    crJson['sales_order_details_transaction_id'] = bomfilteredDataElement.sales_order_details_transaction_id === undefined || bomfilteredDataElement.sales_order_details_transaction_id === null ? 0 : bomfilteredDataElement.sales_order_details_transaction_id
                    crJson['product_material_id'] = bomfilteredDataElement.product_id
                    crJson['product_material_quantity'] = bomfilteredDataElement.indent_quantity
                    crJson['product_material_weight'] = bomfilteredDataElement.indent_weight
                    crJson['product_material_unit_id'] = bomfilteredDataElement.product_unit_id
                    crJson['product_material_stock_quantity'] = bomfilteredDataElement.stock_quantity
                    crJson['product_material_stock_weight'] = bomfilteredDataElement.stock_weight
                    crJson['product_material_reserve_quantity'] = bomfilteredDataElement.product_material_reserve_quantity
                    crJson['product_material_reserve_weight'] = bomfilteredDataElement.product_material_reserve_weight
                    crJson['product_material_approved_quantity'] = bomfilteredDataElement.product_material_approved_quantity
                    crJson['product_material_approved_weight'] = bomfilteredDataElement.product_material_approved_weight
                    crJson['product_material_rejected_quantity'] = bomfilteredDataElement.product_material_rejected_quantity
                    crJson['product_material_rejected_weight'] = bomfilteredDataElement.product_material_rejected_weight
                    crJson['product_material_receipt_quantity'] = bomfilteredDataElement.product_material_receipt_quantity
                    crJson['product_material_receipt_weight'] = bomfilteredDataElement.product_material_receipt_weight
                    crJson['product_material_return_quantity'] = bomfilteredDataElement.product_material_return_weight
                    crJson['product_material_return_weight'] = bomfilteredDataElement.product_material_return_weight
                    crJson['product_material_preclosed_quantity'] = bomfilteredDataElement.product_material_preclosed_quantity
                    crJson['product_material_preclosed_weight'] = bomfilteredDataElement.product_material_preclosed_weight
                    crJson['lead_time'] = bomfilteredDataElement.product_lead_time
                    crJson['approval_remark'] = bomfilteredDataElement.approval_remark
                    crJson['remark'] = bomfilteredDataElement.remark
                    crJson['indent_item_status'] = indentStatus === 'R' ? indentStatus : bomfilteredDataElement.indent_item_status
                    crJson['po_item_status'] = 'P'
                    crJson['grn_item_status'] = 'P'
                    crJson['issue_item_status'] = 'P'
                    crJson['created_by'] = UserName
                    crJson['cost_center_id'] = bomfilteredDataElement.cost_center_id
                    crJson['modified_by'] = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                    json.TransDetailData.push(crJson)
                }
                // TransHeaderData
                const indentMasterFormData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    indent_master_id: isApprove ? indent_master_id : 0,
                    indent_no: keyForViewUpdate === '' ? lastIndentNo : txt_indent_no,
                    indent_version: txt_indent_version,
                    indent_type_id: cmb_indent_type,
                    indent_type: $('#cmb_indent_type').find(":selected").text(),
                    indent_source: chk_indent_source,
                    customer_id: cmb_customer,
                    customer_order_no: cmb_customer_order_no,
                    customer_order_Date: customer_order_date,
                    indent_date: txt_indent_date,
                    department_id: cmb_department,
                    sub_department_id: cmb_sub_department,
                    cost_center_id: cmb_cost_center,
                    indented_by_id: cmb_indented_by,
                    indent_status: indentStatus === 'R' ? indentStatus : chk_indent_status,
                    po_status: 'P',
                    grn_status: 'P',
                    issue_status: 'P',
                    indent_transaction_type: "M",
                    is_active: chk_isactive,
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : '',
                    approved_by_id: isApprove ? cmb_approved_by_id : '',
                    approved_date: isApprove ? txt_approved_date : '',
                    expected_schedule_date: txt_schedule_date,
                    remark: txt_remark,
                    indent_priority: chk_indent_priority,
                    category_1: requestfor === 'PRM' ? category_1_id : 0,
                    category_2: requestfor === 'PRM' ? category_2_id : 0
                      
                }
                json.TransHeaderData = indentMasterFormData;
                if (!isApprove) {
                    // Trans Schedule Data
                    if (keyForViewUpdate === '') {
                        json.TransScheduleData = scheduleDataArray.map(function (schedulerow) {// have to update PO no while add new entry
                            return {
                                ...schedulerow,
                                indent_no: lastIndentNo,
                            };
                        });
                    } else {
                        json.TransScheduleData = scheduleDataArray
                    }
                    for (let index = 0; index < json.TransDetailData.length; index++) {

                        const element = json.TransDetailData[index];
                        let scData = json.TransScheduleData.find(item => {
                            return item.product_material_id === element.product_material_id;
                        });

                        if (scData === undefined) {
                            const scheduleData = {
                                company_id: COMPANY_ID,
                                indent_master_id: isApprove ? indent_master_id : 0,
                                company_branch_id: COMPANY_BRANCH_ID,
                                so_sr_no: element.so_sr_no === null || element.so_sr_no === undefined ? 0 : element.so_sr_no,
                                sales_order_details_transaction_id: element.sales_order_details_transaction_id === null || element.sales_order_details_transaction_id === undefined ? 0 : element.sales_order_details_transaction_id,
                                customer_order_no: cmb_customer_order_no === '' || cmb_customer_order_no === 0 ? 'NA' : cmb_customer_order_no,
                                indent_no: keyForViewUpdate === '' ? lastIndentNo : txt_indent_no,
                                indent_date: txt_indent_date,
                                indent_version: txt_indent_version,
                                product_material_id: element.product_material_id,
                                product_material_unit_id: element.product_material_unit_id,
                                product_material_schedule_quantity: element.product_material_quantity,
                                product_material_schedule_weight: element.product_material_weight,
                                expected_schedule_date: txt_schedule_date,
                                product_material_receipt_quantity: 0,
                                product_material_receipt_weight: 0,
                                material_receipt_date: "",
                                created_by: UserName,
                                modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : '',
                                indent_item_status: element.indentStatus === 'R' ? indentStatus : element.indent_item_status,
                                material_quantity: element.product_material_quantity,
                                material_weight: element.product_material_weight
                            }
                            json.TransScheduleData.push(scheduleData)
                        }
                    }

                    // Trans PO Terms Data
                    $("input:checkbox[name=selectPOTerm]:checked").each(function () {
                        let findPoTermsData = POTermsdata.find(item => item.common_parameters_id === parseInt($(this).val()));

                        const poTermsData = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            indent_master_id: isApprove ? indent_master_id : 0,
                            indent_no: keyForViewUpdate === '' ? lastIndentNo : txt_indent_no,
                            indent_version: txt_indent_version,
                            common_parameters_id: parseInt($(this).val()),
                            common_parameters_name: findPoTermsData.common_parameters_name,
                            common_parameters_value: findPoTermsData.common_parameters_value,
                            common_parameters_expected_value: $("#common_parameters_expected_value_" + $(this).val()).val() === "" ? findPoTermsData.common_parameters_value : $("#common_parameters_expected_value_" + $(this).val()).val(),
                            created_by: UserName,
                            modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                        }
                        json.TransPOTermsData.push(poTermsData);
                    });

                    if (json.TransPOTermsData.length === 0) {
                        json.TransPOTermsData = existingCommonTermsData.map((po_term) => {
                            po_term.indent_no = keyForViewUpdate === 'copy' ? lastIndentNo : po_term.indent_no;
                            po_term.indent_version = keyForViewUpdate === 'copy' ? 1 : po_term.indent_version;
                            po_term.created_by = keyForViewUpdate === 'copy' ? UserName : po_term.created_by;
                            po_term.modified_by = keyForViewUpdate === 'copy' ? UserName : po_term.modified_by;
                            po_term.indent_master_id = 0;
                            return po_term;
                        })
                    }

                }
                else {

                    if (indentStatus === 'R') {
                        setScheduleDataArray(prevData => {
                            const updatedData = prevData.map(schedulerow => ({
                                ...schedulerow,
                                indent_item_status: 'R',
                            }));

                            json.TransScheduleData.push(...updatedData);

                            return updatedData;
                        });
                    }
                    else {
                        json.TransDetailData.map(element => {
                            let currentMaterialSchedules = scheduleDataArray.map(schedule =>
                                schedule.product_material_id === element.product_material_id
                                    ? {
                                        ...schedule,
                                        indent_master_id: indent_master_id,
                                        indent_version: txt_indent_version,
                                        indent_schedule_id: 0,
                                        indent_item_status: element.indent_item_status === 'R' ? 'R' : schedule.indent_item_status
                                    }
                                    : null
                            ).filter(Boolean);
                            json.TransScheduleData.push(...currentMaterialSchedules);
                        });
                    }
                }
                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.indent_no = keyForViewUpdate === '' ? lastIndentNo : txt_indent_no
                json.commonIds.indent_date = txt_indent_date
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME

                const formData = new FormData();
                formData.append(`StIndentData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnAddUpdateRecord/${isApprove}`, requestOptions)
                const responce = await apicall.json();

                if (responce.success === "0") {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    const evitCache = await comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    if (responce.data.hasOwnProperty('indent_no') && responce.data.hasOwnProperty('indent_version')) {
                        setModalOrderDetails(`Indent No: ${responce.data.indent_no} and Version: ${responce.data.indent_version}`)
                    }
                    // if (indent_master_id === 0) {
                    //     setMessageForConfirmation('')
                    //     setShowConfiemationModal(true);
                    // } else {
                    setIndentMasterId(responce.data.indent_master_id)
                    setIndentno(responce.data.indent_no)
                    setIndentDate(responce.data.indent_date)
                    setIndentVersion(responce.data.indent_version)
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                    // }
                }
                setIsLoading(false)
                console.log(json)
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnValidateIndentOrder = async () => {
        debugger
        // Validating header footer forms data.
        let headerDataIsValid = await validate.current.validateForm("indentFormId");
        $("#error_cmb_customer_order_no").hide();
        // Check combo values for customer combo
        let customerVal = cmb_customer || '';
        if (chk_indent_source === 'S' && (customerVal === "" || customerVal === '0')) {
            $("#error_cmb_customer").text("Please fill this field...!");
            $("#error_cmb_customer").show();
            $("#cmb_customer").focus();
            return false;
        }
        let customerOrderNo = cmb_customer_order_no || '';
        if (requestfor === "PRM" && chk_indent_source === 'S' && (customerOrderNo === "" || customerOrderNo === '0')) {
            $("#error_cmb_customer_order_no").text("Please Select Customer Order No...!");
            $("#error_cmb_customer_order_no").show();
            $("#cmb_customer_order_no").focus();
            return false;
        }

        // let costCenter = cmb_cost_center || '';
        // if (costCenter === "" || costCenter === '0') {
        //     $("#error_cmb_cost_center").text("Please fill this field...!");
        //     $("#error_cmb_cost_center").show();
        //     $("#cmb_cost_center").focus();
        //     return false;
        // }

        let indentedBy = cmb_indented_by || '';
        if (indentedBy === "" || indentedBy === '0') {
            $("#error_cmb_indented_by").text("Please fill this field...!");
            $("#error_cmb_indented_by").show();
            $("#cmb_indented_by").focus();
            return false;
        }

        if (!headerDataIsValid) { return false; }
        let indentDetailsIsValid = false;
        let addedMaterials = $('#indent-details-tbl tbody tr').length;
        if (addedMaterials <= 0 && keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one material.');
            setShowErrorMsgModal(true);
            return indentDetailsIsValid = false;
        } else {
            $('#indent-details-tbl tbody tr').each(function () {
                let currentTblRow = $(this);

                let materialQty = parseFloat(currentTblRow.find('input[id^="indent_quantity_"]').val());
                let materialApproveQty = parseFloat(currentTblRow.find('input[id^="product_material_approved_quantity_"]').val());
                let materialRemark = currentTblRow.find('input[id^="approval_remark_"]').val();
                const cost_center = parseInt(currentTblRow.find('select[id^="cost_center_id_"]').val());

                if (keyForViewUpdate === 'approve') {
                    if (isNaN(materialApproveQty) || materialApproveQty <= 0) {
                        $(currentTblRow.find('input[id^="product_material_approved_quantity_"]'))[0].parentElement.dataset.tip = 'Product Quantity should not be a zero or blank on approval...!';
                        $(currentTblRow.find('input[id^="product_material_approved_quantity_"]'))[0].focus();
                        setOpenIndentDetailsAccord(true)
                        return indentDetailsIsValid = false;
                    } else if (materialQty !== materialApproveQty) {
                        if (materialRemark === "") {
                            $(currentTblRow.find('input[id^="approval_remark_"]'))[0].parentElement.dataset.tip = 'approval remark should not be blank on approval...!';
                            $(currentTblRow.find('input[id^="approval_remark_"]'))[0].focus();
                            setOpenIndentDetailsAccord(true)
                            return indentDetailsIsValid = false;
                        }
                    }
                } else {

                    if (isNaN(cost_center) || cost_center === "" || cost_center === 0) {
                        $(currentTblRow.find('select[id^="cost_center_id_"]'))[0].parentElement.dataset.tip = 'Please Select Cost Center..!';
                        $(currentTblRow.find(`select[id^='cost_center_id_']`))[0].focus();
                        setOpenIndentDetailsAccord(true)
                        return indentDetailsIsValid = false;
                    }
                    if (isNaN(materialQty) || materialQty <= 0 || materialQty === '') {
                        $(currentTblRow.find('input[id^="indent_quantity_"]'))[0].parentElement.dataset.tip = 'Material quantity should not be a zero or blank...!';
                        $(currentTblRow.find('input[id^="indent_quantity_"]'))[0].focus();
                        setOpenIndentDetailsAccord(true)
                        return indentDetailsIsValid = false;
                    }
                    // }
                }
                indentDetailsIsValid = true;
            });

            if (keyForViewUpdate !== 'view' && indentDetailsIsValid) {

                let ScheduleIsvalid = false;
                let scheduledata = JSON.parse(localStorage.getItem('indentScheduleData'));
                if (scheduledata != null) {
                    const groupedData = scheduledata.reduce((result, item) => {
                        const { sales_order_details_transaction_id, product_material_id, product_material_schedule_quantity, so_sr_no } = item;
                        const key = chk_indent_source === 'S'
                            ? `${item.sales_order_details_transaction_id}:${item.product_material_id}:${item.so_sr_no}`
                            : `${product_material_id}-${so_sr_no}`;
                        if (!result[key]) {
                            result[key] = {
                                sales_order_details_transaction_id: chk_indent_source === 'S' ? sales_order_details_transaction_id : undefined,
                                product_material_id,
                                so_sr_no,
                                total_product_material_po_schedule_quantity: 0,
                            };
                        }
                        result[key].total_product_material_po_schedule_quantity += parseInt(product_material_schedule_quantity);
                        return result;
                    }, {});
                    let groupedArray = Object.values(groupedData);
                    console.log(groupedArray);
                    for (let detailItem = 0; detailItem < groupedArray.length; detailItem++) {
                        let groupedElement = groupedArray[detailItem];
                        let isDetailpressent = false;
                        isDetailpressent = bomfilteredData.find(item => item.product_id === groupedElement.product_material_id)
                        if (isDetailpressent) {
                            if (keyForViewUpdate !== 'approve') {
                                if (parseInt(isDetailpressent.indent_quantity) !== parseInt(groupedElement.total_product_material_po_schedule_quantity)) {
                                    setErrMsg(`The schedule quantity for ${isDetailpressent.product_name} is not properly allocated...! (Total Quanity is "${isDetailpressent.indent_quantity}" and you are scheduling "${groupedElement.total_product_material_po_schedule_quantity}")`)
                                    setShowErrorMsgModal(true)
                                    ScheduleIsvalid = false;
                                    return ScheduleIsvalid = false;
                                }
                                ScheduleIsvalid = true;
                            } else {
                                if (parseInt(isDetailpressent.product_material_approved_quantity) !== parseInt(groupedElement.total_product_material_po_schedule_quantity)) {
                                    const existingSchedule = scheduledata.find(item => (item.product_material_id === groupedElement.product_material_id && item.so_sr_no === groupedElement.so_sr_no));
                                    if (existingSchedule) {
                                        // Update the existingSchedule with your changes here
                                        const updatedData = { ...existingSchedule, product_material_schedule_quantity: isDetailpressent.product_material_approved_quantity, product_material_schedule_weight: isDetailpressent.product_material_approved_weight };
                                        const index = scheduledata.findIndex(item => item.product_material_id === existingSchedule.product_material_id && item.so_sr_no === existingSchedule.so_sr_no);
                                        if (index !== -1) {
                                            // Create a new array with the updated element at the found index
                                            const updatedScheduleData = [
                                                ...scheduledata.slice(0, index), // Elements before the updated element
                                                updatedData, // Updated element
                                                ...scheduledata.slice(index + 1), // Elements after the updated element
                                            ];
                                            // Set the updated scheduledata to your state or hook
                                            localStorage.setItem('indentScheduleData', JSON.stringify(updatedScheduleData));
                                            setScheduleDataArray(updatedScheduleData);
                                        }
                                    }
                                    ScheduleIsvalid = true;
                                }
                                ScheduleIsvalid = true;
                            }
                        }
                    }
                }
                // POcommon terms validations
                let PoCommonTermsIsValid = true;
                if (indentDetailsIsValid) {
                    PoCommonTermsIsValid = true;
                    let selectedCommonTerms = $('#po-commonTerms-table tbody tr .selectPOTerm:checked')
                    selectedCommonTerms.each(function () {
                        let currentTblRow = $(this.parentElement.parentElement.parentElement)
                        let expectedValue = currentTblRow.find('input[id^="common_parameters_expected_value_"]').val();
                        if (expectedValue === '') {
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].parentElement.dataset.tip = 'Please enter valid common parameters expected value...!';
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].focus();
                            setOpenIndentDetailsAccord(true)
                            return PoCommonTermsIsValid = false;
                        }
                    });
                    return PoCommonTermsIsValid;
                }
                return indentDetailsIsValid === true && PoCommonTermsIsValid === true;
            } else if (keyForViewUpdate === 'approve' && indentDetailsIsValid) {
                return true;
            } else {
                return keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && indentDetailsIsValid
            }

        }
    }

    const FnClearFormFields = async () => {
        if (requestfor !== "NA") {
            await GenerateIndentNo();
        } else {
            setIndentno(0)
            setIndentType('');
        }
        setIndentVersion(1);
        setIndentSource('I')
        setCustomerId('');
        setCustomerOrderNo('')
        // setDepartmentId('');
        setSubDepartmentId('')
        setIndentedById('');
        setIndentStatus("P");
        setRemark('');
        setIsActive(true);
        setIndentDate(todayDate);
        setCustomerOrderDate('');
        // sessionStorage.removeItem('indent_no')
        sessionStorage.removeItem('filteredMaterialData')
        localStorage.removeItem('indentScheduleData')
        setBomfilteredData([])
        setScheduleDataArray([])
        await FnShowPOTermsRecords();
        const bankChkBoxes = document.querySelectorAll('input[name=checkPOTerms]:checked');
        for (var checkbox of bankChkBoxes) {
            checkbox.checked = false;
        }
    }

    const moveToListing = () => {
        debugger
        sessionStorage.removeItem('filteredMaterialData')
        localStorage.removeItem('indentScheduleData')
        localStorage.removeItem('purchaseOrdCommonTermsData')
        // let Path = '';

        // if ( category_2_id === "2"){
        //     Path =  `/Transactions/TPurchaseOrder/Indent/CottonBalesIndentListing`;
        // }
        // else {
        //     Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/Indent/IndentListing/reg' : `/Transactions/TPurchaseOrder/Indent/IndentListing`;
        // }
        // Path = `${requestfor ? `${Path}/${requestfor}` : `${Path}`}`;
        // Path = `${Path}`;
        // console.log(Path);
        // navigate(Path);
        navigate(-1);

    }

    const refreshData = async (key) => {

        switch (key) {
            case 'get_employee':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_employee"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                const getEmployeesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                const indentedByOpts = [
                    { value: '', label: 'Select', },
                    { value: '0', label: 'Add New Record+', },
                    ...getEmployeesApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
                ];
                setIndentedByIdOptions(indentedByOpts);
                break;
            case 'get_customer':
                const customerOrderIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_customer", "", "");
                const options = [
                    { value: '', label: 'Select' },
                    { value: '0', label: 'Add New Record+' },
                    ...customerOrderIdApiCall.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                ];
                setCustomerOptions(options);
                break;
            default:
                break;
        }
    }

    $('body').on('click', '.RemoveTrBtn', function () {
        var $tr = $(this).closest('tr');
        $tr.remove();
    });


    const renderDetailsTable = useMemo(() => {
        return <>
            <div style={{ overflowX: "hidden", flex: 0 }}>
                <Table className="erp_table erp_table_scroll" id='indent-details-tbl' responsive bordered striped>
                    <thead className="erp_table_head">
                        <tr>
                            <th className={`erp_table_th ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>Actions</th>
                            <th className="erp_table_th">Sr.no</th>
                            <th className="erp_table_th" style={{ width: "auto" }}>Material Code</th>
                            <th className="erp_table_th" style={{ width: "auto" }}>Material Name</th>
                            <th className="erp_table_th">Tech. Spect.</th>
                            <th className="erp_table_th">Cost Center</th>
                            <th className="erp_table_th">Material Unit</th>
                            <th className="erp_table_th">
                                {requestfor === "PRM" ? "Cones In Stock" : "Stock Quantity"}
                            </th>
                            <th className="erp_table_th">Stock Weight</th>
                            <th className={`erp_table_th`}>Indent Quantity</th>
                            <th className={`erp_table_th`}>Approved Quantity</th>
                            <th className="erp_table_th">Rejected Quantity</th>
                            {keyForViewUpdate === 'preclose' && keyForViewUpdate === 'view' ? <th className="erp_table_th">Preclose Quantity</th> : ''}
                            <th className={`erp_table_th`}> Approval remark </th>
                            {((keyForViewUpdate === 'view' && (chk_indent_status === 'G' || chk_indent_status === 'IG')) || chk_indent_status === 'G' || chk_indent_status === 'IG') ? (
                                <th className="erp_table_th">GRN Accepted Quantity</th>
                            ) : null}
                            <th className="erp_table_th">Issue Quantity</th>
                            <th className="erp_table_th" style={{ paddingRight: '40px' }}>Remark</th>
                            <th className={`erp_table_th`} style={{ width: "auto" }}>Indent Item Status</th>
                            <th className="erp_table_th">Material Grade</th>
                            <th className="erp_table_th">Material Make</th>
                            <th className="erp_table_th">Receipt quantity</th>
                            <th className="erp_table_th">Reserve quantity</th>
                            <th className="erp_table_th">Return quantity</th>
                            <th className="erp_table_th">Material weight</th>
                            <th className="erp_table_th">Indent weight</th>
                            <th className="erp_table_th">Approved Weight</th>
                            <th className="erp_table_th">Rejected Weight</th>
                            {keyForViewUpdate === 'preclose' && keyForViewUpdate === 'view'  ? <th className="erp_table_th">Preclose Weight</th> : ''}
                            <th className="erp_table_th">Issue Weight</th>
                            <th className="erp_table_th">Receipt weight</th>
                            <th className="erp_table_th">Reserve weight</th>
                            <th className="erp_table_th">Return weight</th>
                            <th className="erp_table_th">Lead time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bomfilteredData.map((indentItem, Index) =>
                            <tr rowindex={Index} className="sticky-column">

                                <td className={`erp_table_td sticky-column  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>
                                    <div style={{ display: 'flex' }}>
                                        <div className={`${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`}>
                                            <Tooltip title="Delete" placement="top">
                                                <MDTypography className="erp-view-btn">
                                                    <MdDelete className="erp-delete-btn" id="erp-delete-btn-id" rowindex={Index} onClick={(e) => removeMaterial(indentItem, e)} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                        <Tooltip title="Schedule" placement="top">
                                            <MDTypography className="erp-schedule-btn">
                                                <AiOutlineSchedule className="erp-schedule-btn" onClick={() => {
                                                    openSchedules(indentItem);
                                                }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </td>
                                <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                                <td className={`erp_table_td sticky-column`}>{indentItem.product_material_code}</td>
                                <td className={`erp_table_td sticky-column`}>{indentItem.product_name}</td>
                                <td className="erp_table_td sticky-column">{indentItem.product_tech_spect}</td>
                                <td className="erp_table_td">
                                    <select style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}
                                        id={`cost_center_id_${indentItem.product_id}`}
                                        disabled={keyForViewUpdate !== "" && keyForViewUpdate !== "update"}
                                        value={indentItem.cost_center_id}
                                        className="form-select form-select-sm mb-1"
                                        Headers='cost_center_id'
                                        onChange={(e) => { handleInputChange(indentItem, e); }}
                                    >
                                        {cost_centerOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td className="erp_table_td scrollable-table">{indentItem.product_unit_name}</td>
                                <td className="erp_table_td text-end scrollable-table">{indentItem.stock_quantity}</td>
                                <td className="erp_table_td text-end scrollable-table">{indentItem.stock_weight}</td>

                                <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                    <input type="text"
                                        id={`indent_quantity_${indentItem.product_id}`} className="erp_input_field mb-0 text-end" value={indentItem.indent_quantity}
                                        onChange={(e) => { handleInputChange(indentItem, e); }} onBlur={(e) => { handleInputChange(indentItem, e); }} disabled={isApprove ? true : false} Headers='indent_quantity'
                                    />
                                </td>
                                <td className={`erp_table_td ${keyForViewUpdate === 'approve' ? '' : "text-end"}`}>
                                    {keyForViewUpdate === 'approve' ?
                                        <input type="text" id={`product_material_approved_quantity_${indentItem.product_id}`} className="erp_input_field mb-0 approveField text-end"
                                            value={indentItem.product_material_approved_quantity} onChange={(e) => { handleInputChange(indentItem, e); }} onBlur={(e) => { handleInputChange(indentItem, e); }}
                                            disabled={true} Headers='product_material_approved_quantity'
                                        />
                                        :
                                        indentItem.product_material_approved_quantity
                                    } 
                                </td>
                                <td className="erp_table_td text-end">{indentItem.product_material_rejected_quantity}</td>
                                {keyForViewUpdate === 'preclose' && keyForViewUpdate === 'view' ?
                                <>
                                <td className={`erp_table_td ${keyForViewUpdate === 'preClose' ? '' : "text-end"}`}>
                                    <input type="text"
                                        id={`product_material_preclosed_quantity_${indentItem.product_id}`} className="erp_input_field mb-0 text-end" value={indentItem.product_material_preclosed_quantity}
                                        onChange={(e) => { handleInputChange(indentItem, e); }} onBlur={(e) => { handleInputChange(indentItem, e); }} disabled={keyForViewUpdate === 'preClose' ? false : true} Headers='product_material_preclosed_quantity'
                                    />
                                </td>
                                </> : ''}
                                
                                <td className="erp_table_td">
                                    {keyForViewUpdate === 'approve'
                                        ? <input type="text" id={`approval_remark_${indentItem.product_id}`} className="erp_input_field mb-0"
                                            value={indentItem.approval_remark} onChange={(e) => { handleInputChange(indentItem, e); }} Headers='approval_remark'
                                        /> : indentItem.approval_remark
                                    }
                                </td>
                                {((keyForViewUpdate === 'view' && (chk_indent_status === 'G' || chk_indent_status === 'IG')) || chk_indent_status === 'G' || chk_indent_status === 'IG') ? (
                                    <td className="erp_table_td text-end">
                                        {indentItem.product_material_grn_accepted_quantity}
                                    </td>
                                ) : null}
                                <td className="erp_table_td text-end">{indentItem.product_material_issue_quantity}</td>
                                <td className="erp_table_td">
                                    {chk_indent_status === 'P'
                                        ? <textarea type="text" id={`remark_${indentItem.product_id}`} className="erp_input_field mb-0"
                                            value={indentItem.remark} onChange={(e) => { handleInputChange(indentItem, e); }} Headers='remark'
                                        /> : indentItem.remark
                                    }
                                </td>
                                <td className="erp_table_td">
                                    <select id={`indent_item_status_${indentItem.product_id}`} disabled value={indentItem.indent_item_status}
                                        className="form-select form-select-sm approveField" Headers='indent_item_status' onChange={(e) => { handleInputChange(indentItem, e); }}>
                                        <option value="P">Pending</option>
                                        <option value="A">Approved</option>
                                        <option value="R">Rejected</option>
                                        <option value="C">Completed</option>
                                        <option value="X">Canceled</option>
                                        <option value="Z">PreClosed</option>
                                    </select></td>
                                <td className="erp_table_td">{indentItem.product_material_grade_name}</td>
                                <td className="erp_table_td">{indentItem.product_make_name}</td>
                                <td className="erp_table_td text-end">{indentItem.product_material_receipt_quantity}</td>
                                <td className="erp_table_td text-end">{indentItem.product_material_reserve_quantity}</td>
                                <td className="erp_table_td text-end">{indentItem.product_material_return_quantity} </td>
                                <td className="erp_table_td text-end">{indentItem.product_std_weight} </td>
                                <td className={`erp_table_td text-end `}>{validateNumberDateInput.current.decimalNumber(indentItem.indent_weight, 4)}</td>
                                <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(indentItem.product_material_approved_weight, 4)}</td>
                                <td className="erp_table_td text-end">{indentItem.product_material_rejected_weight}</td>
                                {keyForViewUpdate === 'preclose' && keyForViewUpdate === 'view' ?
                                <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(indentItem.product_material_preclosed_weight, 4)}</td> : ''}
                                <td className="erp_table_td text-end">{indentItem.product_material_issue_weight}</td>
                                <td className="erp_table_td text-end">{indentItem.product_material_receipt_weight}</td>
                                <td className="erp_table_td text-end">{indentItem.product_material_reserve_weight}</td>
                                <td className="erp_table_td text-end">{indentItem.product_material_return_weight} </td>
                                <td className="erp_table_td text-end">{indentItem.product_lead_time}</td>

                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    }, [bomfilteredData, isApprove, keyForViewUpdate, scheduleDataArray,cost_centerOptions])


    //Raw material Product Properties Table
    const renderProductPropertisDetailsTbl = useMemo(() => {
        return (
            <div style={{ overflowX: "hidden", flex: 0 }}>
                <div style={{ maxHeight: "300px", overflowY: "auto" }}> {/* Adjust the maxHeight as needed */}
                    <Table className="erp_table" id='po-commonTerms-table' responsive bordered>
                        <thead className="erp_table_head">
                            <tr>
                                <th className="erp_table_th">Sr.no</th>
                                <th className="erp_table_th">Material Name</th>
                                <th className="erp_table_th">Material Code</th>
                                <th className="erp_table_th">Parameter Name</th>
                                <th className="erp_table_th">Parameter Value</th>
                                <th className="erp_table_th">Prefix</th>
                                <th className="erp_table_th">Sufix</th>
                                <th className="erp_table_th">Calculation Type</th>
                                <th className="erp_table_th">From Value</th>
                                <th className="erp_table_th">To Value</th>
                                <th className="erp_table_th">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productpropertisList.map((propertyItem, Index) =>
                                <tr rowindex={Index} key={Index}>
                                    <td className="erp_table_td text-end">{Index + 1}</td>
                                    <td className="erp_table_td">{propertyItem.product_material_name}</td>
                                    <td className="erp_table_td">{propertyItem.product_material_code}</td>
                                    <td className="erp_table_td">{propertyItem.product_parameter_name}</td>
                                    <td className="erp_table_td">{propertyItem.product_parameter_value_name}</td>
                                    <td className="erp_table_td">{propertyItem.product_parameter_prefix}</td>
                                    <td className="erp_table_td text-end">{propertyItem.product_parameter_sufix}</td>
                                    <td className="erp_table_td text-end">{propertyItem.product_parameter_calculation_type}</td>
                                    <td className="erp_table_td">{propertyItem.product_parameter_from_value}</td>
                                    <td className="erp_table_td text-end">{propertyItem.product_parameter_to_value}</td>
                                    <td className="erp_table_td text-end">{propertyItem.remark}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }, [productpropertisList]);

    //Raw material stock details Table
    const renderStockDetailsTbl = useMemo(() => {
        return (
            <div style={{ overflowX: "hidden", flex: 0 }}>
                <div style={{ maxHeight: "300px", overflowY: "auto" }}> {/* Adjust the maxHeight as needed */}
                    <Table className="erp_table" id='po-commonTerms-table' responsive bordered>
                        <thead className="erp_table_head">
                            <tr>
                                <th className="erp_table_th">Sr.no</th>
                                <th className="erp_table_th">Material Name</th>
                                <th className="erp_table_th">Material Code</th>
                                {/* <th className="erp_table_th">Count</th> */}
                                <th className="erp_table_th">Lot No.</th>
                                <th className="erp_table_th">Party Name</th>
                                <th className="erp_table_th">Stock Wt.</th>
                                <th className="erp_table_th">Cones In Stock	</th>
                                <th className="erp_table_th">Cone Per Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stockDetails.map((propertyItem, Index) =>
                                <tr rowindex={Index} key={Index}>
                                    <td className="erp_table_td text-end">{Index + 1}</td>
                                    <td className="erp_table_td">{propertyItem.product_rm_name}</td>
                                    <td className="erp_table_td">{propertyItem.product_rm_code}</td>
                                    {/* <td className="erp_table_td">{propertyItem.supplier_name}</td> */}
                                    <td className="erp_table_td">{propertyItem.batch_no}</td>
                                    <td className="erp_table_td">{propertyItem.supplier_name}</td>
                                    <td className="erp_table_td text-end">{propertyItem.closing_balance_weight}</td>
                                    <td className="erp_table_td text-end">{propertyItem.closing_balance_quantity}</td>
                                    <td className="erp_table_td">{propertyItem.weight_per_box_item}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }, [stockDetails]);

    //---------------------------------------------------------Print Functionality Starts--------------------------------------------------------
    //Purchase Requisition Print Start
    const purchaseRequisitionContent = {
        company: {
            company: '',
            company_branch: '',
        },
        customerdetails: {
            name: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
        },
        customerDetails: {
            customer: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            email: ''
        },
        indentdetails: {
            indent_no: '',
            indent_date: '',
            indent_version: '',
            indent_type: '',
        },
        header: {
            indent_source: '',
            department_name: '',
            sub_department_name: '',
            indented_by: '',
            customer_name: '',
            customer_order_no: '',
            customer_order_date: '',
            approved_by_name: '',
            approved_date: '',
            remark: '',
        },
        footer: {
            indented_by_name: '',
            approved_by_name: '',

            sumofStockQuantity: '',
            sumIndentQuantity: '',
            sumofApprovedQuantity: '',
            sumofRejectedQuantity: '',

        },
        items: [],
    };
    const printInvoice = async (openPrintModal) => {

        // Define purchaseRequisitionContent within the printInvoice function
        // fin_year = idList.financial_year;
        // ind_version = idList.indent_version;
        // const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnShowAllDetailsAndMastermodelRecords/${ind_version}/${fin_year}/${COMPANY_ID}?indent_no=${idList.indent_no}`)
        // const response = await apiCall.json();
        // console.log("Response : ", response);
        setIsLoading(true)
        let indentMaterMasterData = indentMasterJsonPrint;
        let indentMaterDetailsData = bomfilteredData;


        //indent details
        purchaseRequisitionContent.indentdetails.indent_no = txt_indent_no
        purchaseRequisitionContent.indentdetails.indent_date = validateNumberDateInput.current.formatDateToDDMMYYYY(indentMaterMasterData.indent_date)
        purchaseRequisitionContent.indentdetails.indent_version = txt_indent_version
        // purchaseRequisitionContent.indentdetails.indent_type =  $('#cmb_indent_type').find(":selected").text()


        //Company details
        purchaseRequisitionContent.company.company = indentMaterMasterData.company_name
        purchaseRequisitionContent.company.company_branch = indentMaterMasterData.company_branch_name

        //Customer details
        purchaseRequisitionContent.customerDetails.customer = indentMaterMasterData.customer_name
        purchaseRequisitionContent.customerDetails.address = indentMaterMasterData.cust_branch_address1
        purchaseRequisitionContent.customerDetails.state = indentMaterMasterData.customer_state_name
        purchaseRequisitionContent.customerDetails.contact = indentMaterMasterData.cust_branch_phone_no
        purchaseRequisitionContent.customerDetails.email = indentMaterMasterData.cust_branch_EmailId

        // header Details
        purchaseRequisitionContent.header.indent_source = indentMaterMasterData.indent_source_desc
        purchaseRequisitionContent.header.department_name = indentMaterMasterData.department_name
        purchaseRequisitionContent.header.sub_department_name = indentMaterMasterData.sub_department_name
        purchaseRequisitionContent.header.indented_by = indentMaterMasterData.indented_by_name
        purchaseRequisitionContent.header.customer_name = indentMaterMasterData.customer_name
        purchaseRequisitionContent.header.customer_order_no = indentMaterMasterData.customer_order_no
        purchaseRequisitionContent.header.customer_order_date = validateNumberDateInput.current.formatDateToDDMMYYYY(indentMaterMasterData.customer_order_date)
        purchaseRequisitionContent.header.approved_by_name = indentMaterMasterData.approved_by_name
        purchaseRequisitionContent.header.approved_date = validateNumberDateInput.current.formatDateToDDMMYYYY(indentMaterMasterData.approved_date)
        purchaseRequisitionContent.header.remark = indentMaterMasterData.remark

        //footer details
        purchaseRequisitionContent.footer.indented_by_name = indentMaterMasterData.indented_by_name
        purchaseRequisitionContent.footer.approved_by_name = indentMaterMasterData.approved_by_name

        let sumStockQuantity = 0;
        let sumIndentQuantity = 0;
        let sumofApprovedQuantity = 0;
        let sumofRejectedQuantity = 0;

        for (let index = 0; index < indentMaterDetailsData.length; index++) {
            const element = indentMaterDetailsData[index];

            // Add product_material_stock_quantity to the sum
            sumStockQuantity += element.stock_quantity;
            sumIndentQuantity += element.indent_quantity;
            sumofApprovedQuantity += element.product_material_approved_quantity;
            sumofRejectedQuantity += element.product_material_rejected_quantity;

            const detailsData = {
                sr_no: index + 1,
                product_material_code: element.product_material_code === undefined || element.product_material_code === null ? "" : element.product_material_code,
                product_rm_name: (element.remark !== undefined && element.remark !== null && element.remark !== '') ?
                    `${element.product_name} ${element.remark}` :
                    (element.product_name === undefined || element.product_name === null ? "" : element.product_name),
                material_tech_spect: element.product_tech_spect === undefined || element.product_tech_spect === null ? "" : element.product_tech_spect,
                product_parent_fg_name: element.product_parent_fg_name === undefined || element.product_parent_fg_name === null ? "" : element.product_parent_fg_name,
                product_rm_oem_part_code: element.product_rm_oem_part_code === undefined || element.product_rm_oem_part_code === null ? "" : element.product_rm_oem_part_code,
                product_rm_our_part_code: element.product_rm_our_part_code === undefined || element.product_rm_our_part_code === null ? "" : element.product_rm_our_part_code,
                product_make_name: element.product_make_name === 'NA' || element.product_make_name === undefined || element.product_make_name === null ? "" : element.product_make_name,
                product_material_grade_name: element.product_material_grade_name === 'NA' || element.product_material_grade_name === undefined || element.product_material_grade_name === null ? "" : element.product_material_grade_name,
                product_material_stock_quantity: element.stock_quantity,
                lead_time: element.product_lead_time,
                unit: element.product_unit_name,
                indent_quantity: element.indent_quantity,
                product_material_approved_quantity: element.product_material_approved_quantity,
                product_material_rejected_quantity: element.product_material_rejected_quantity,
            }
            purchaseRequisitionContent.items.push(detailsData)
            console.log(detailsData);
        }

        // Assign calculated values to footer
        purchaseRequisitionContent.footer.sumofStockQuantity = sumStockQuantity;
        purchaseRequisitionContent.footer.sumIndentQuantity = sumIndentQuantity;
        purchaseRequisitionContent.footer.sumofApprovedQuantity = sumofApprovedQuantity;
        purchaseRequisitionContent.footer.sumofRejectedQuantity = sumofRejectedQuantity;

        console.log('Sum of product_material_stock_quantity:', sumStockQuantity);
        console.log('Sum of sumIndentQuantity:', sumIndentQuantity);
        console.log('Sum of sumofApprovedQuantity:', sumofApprovedQuantity);
        console.log('Sum of sumofRejectedQuantity:', sumofRejectedQuantity);

        if (openPrintModal) {
            if (parseFloat(indentMaterDetailsData.length) <= 10) {
                navigator('/Invoice', { state: { invoiceData: purchaseRequisitionContent, navigationLink: '/Transactions/TPurchaseOrder/Indent/FrmIndentMasterEntry', invoiceType: 'IPR', title: 'PurchaseRequisition', idList: idList, keyForViewUpdate: keyForViewUpdate, requestfor: requestfor } });
            } else {
                navigator('/Invoice', { state: { invoiceData: purchaseRequisitionContent, navigationLink: '/Transactions/TPurchaseOrder/Indent/FrmIndentMasterEntry', invoiceType: 'PR', title: 'PurchaseRequisition', idList: idList, keyForViewUpdate: keyForViewUpdate, requestfor: requestfor } });
            }

        } else {
            // return the html code.
            return renderToString(<PurchaseRequisition invoiceContent={purchaseRequisitionContent} />);
        }
        setIsLoading(false)
    };

    //---------------------------------------------------------Print Functionality End----------------------------------------------


    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {

        switch (eventKey) {
            case 'purchaseOrderTerms':
                const checkedPOLength = $("input:checkbox[name=selectPOTerm]:checked").length;
                if (checkedPOLength === 0) {
                    await FnShowPOTermsRecords();
                }
                break;
            case 'documentList':
                await showDocumentRecords();
                break;
            default:
                break;
        }
        if (keyForViewUpdate === "view" || keyForViewUpdate === "approve" || keyForViewUpdate === "delete") {
            $('input[type="checkbox"]').prop('disabled', true);
        }
    }
    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    // Fn for get document list
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_indent_no !== 0 ? txt_indent_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    const filteredData = resp.content.filter(item => 
                        !( keyForViewUpdate === "" || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve') ? item.is_active : resp.content
                     );
                     setDocumentData(filteredData);
                     }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">File Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">
                                    <MDTypography
                                        component="label"
                                        className="erp-form-label"
                                        variant="button"
                                        id="logoFile"
                                        fontWeight="regular"
                                        color="info"
                                        onClick={() => fetchDocument(docItem)}
                                        style={{ cursor: 'pointer', color: '#0000FF' }} // Change cursor to pointer to indicate clickable
                                        onMouseEnter={(e) => e.target.style.color = '#0000FF'} // Change color on hover
                                        onMouseLeave={(e) => e.target.style.color = '#0000FF'} // Restore original color when not hovering
                                    >
                                        {docItem.file_name}
                                    </MDTypography>
                                </td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);

    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {

            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }
    //key for get Request type header
    const requestTypeHeading = () => {
        switch (requestfor) {
            case 'YN':
                return '(Cotton Yarn)';
            case 'RC':
                return '(Raw Cotton)';
            case 'BL':
                return '(Cotton Bales)';
            case 'GF':
                return '(Greige Fabric)';
            case 'FF':
                return '(Finish Fabric)';
            case 'RM':
                return '(General Stores & Spares)';
            case 'PRM':
                return '(Raw Material)';
            default:
                return '';
        }
    }

        let group_id = txt_indent_no.replace(/\//g, "_");
        const memorizedDocumentComponent = useMemo(() => {
            return <>
                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <Document group_id={group_id} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        }, [showDocumentForm])
    
    let customerComboRef = useRef(null);
    let costCenterComboRef = useRef(null);
    let intentedByComboRef = useRef(null);

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'>
                                Indent{requestTypeHeading()}<span id="submain_heading">(Creation)</span></label>
                        </div>
                        <form id="indentFormId">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Indent Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_indent_type" className="form-select form-select-sm" value={cmb_indent_type} onChange={() => { comboOnChange('indentTypeId'); validateFields(); }} disabled={keyForViewUpdate === '' && requestfor === 'NA' ? false : true} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {indenttypeOptions.length !== 0 ? (
                                                    <>
                                                        {indenttypeOptions.map(indentTypeId => (
                                                            <option key={indentTypeId.field_id} value={indentTypeId.field_id}>
                                                                {indentTypeId.field_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_indent_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className={`row ${requestfor !== 'RM' ? 'display' : 'd-none'}`}>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Category 1<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-3 col-12'>
                                            <select id="category_1_id" className="form-select form-select-sm" value={category_1_id} onChange={(e) => setCategory1Id(e.target.value)} disabled optional={requestfor !== 'RM' ? '' : 'optional'} >
                                                {/* <option value="">Select</option>
                                                <option value="0">Add New Record+</option> */}
                                                {category1Options.length !== 0 ? (
                                                    <>
                                                        {category1Options.map(materialTypeId => (
                                                            <option key={materialTypeId.field_id} value={materialTypeId.field_id}>
                                                                {materialTypeId.field_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_material_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Category 2<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-3 col-12'>
                                            <select id="category_2_id" className="form-select form-select-sm" value={category_2_id} onChange={(e) => {setCategory2Id(e.target.value); validateFields();  comboOnChange('category2');}} optional={requestfor !== 'RM' ? '' : 'optional'}>
                                                <option value="">Select</option>
                                                {/*  <option value="0">Add New Record+</option> */}
                                                {category2Options.length !== 0 ? (
                                                    <>
                                                        {category2Options.map(materialTypeId => (
                                                            <option key={materialTypeId.field_id} value={materialTypeId.field_id}>  {materialTypeId.field_name} </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_category_2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Indent No, Version & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_indent_no" className="erp_input_field" value={txt_indent_no} disabled />
                                                    <MDTypography variant="button" id="error_txt_indent_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0">
                                                    <Form.Control type="text" id='txt_indent_version' className="erp_input_field text-end" value={txt_indent_version} disabled />
                                                    <MDTypography variant="button" id="error_txt_indent_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='txt_indent_date' className="erp_input_field" value={txt_indent_date} onChange={e => { setIndentDate(e.target.value); validateFields(); }} min={currentDate} />
                                                    <MDTypography variant="button" id="error_txt_indent_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Indent Source <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check
                                                        className="erp_radio_button"
                                                        label="Internal"
                                                        type="radio"
                                                        lbl="Internal"
                                                        value="I"
                                                        name="chk_indent_source"
                                                        checked={chk_indent_source === "I"}
                                                        onChange={() => { setIndentSource("I"); comboOnChange('Internal_source') }}
                                                    />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check
                                                        className="erp_radio_button"
                                                        label="So Based"
                                                        type="radio"
                                                        lbl="So Based"
                                                        value="S"
                                                        name="chk_indent_source"
                                                        checked={chk_indent_source === "S"}
                                                        onChange={() => {
                                                            setIndentSource("S"); comboOnChange('SO_source')
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row`}>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Customer {chk_indent_source === 'S' ? <span className="required">*</span> : null}</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10 mb-1'>
                                            <Select ref={customerComboRef}
                                                options={customerOptions}
                                                isDisabled={['view', 'approve', 'update'].includes(keyForViewUpdate) || chk_indent_source !== 'S'}
                                                inputId="cmb_customer" // Provide the ID for the input box
                                                value={customerOptions.find(option => option.value === cmb_customer)}
                                                onChange={(selectedOpt) => {
                                                    setCustomerId(selectedOpt.value);
                                                    customerComboRef.current = selectedOpt;
                                                    comboOnChange('customer');
                                                    validateFields();
                                                }}
                                                placeholder="Search for a customer..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                // {...(chk_indent_source !== 'S' && { optional: 'optional' })} 
                                                disabled={['view', 'approve'].includes(keyForViewUpdate) || chk_indent_source !== 'S'}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_customer" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className={`col-sm-1 col-2 ${chk_indent_source !== 'S' ? "d-none" : 'display'}`}>
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_customer') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {/* <div className='row'> */}
                                    {/* <div className="col-md-6 col-12"> */}
                                    <div className={`row ${chk_indent_source === 'S' ? 'display' : 'd-none'}`}>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Customer Order & Order on</Form.Label>
                                        </div>
                                        {/* <div className='col-sm-8 col-10 mb-1'> */}
                                        <div className="col-sm-6 col-12">
                                            {keyForViewUpdate === '' ?
                                                <select id="cmb_customer_order_no" className="form-select form-select-sm" value={cmb_customer_order_no} onChange={(e) => { setCustomerOrderNo(e.target.value); requestfor === "YN" || requestfor === "PRM" ? comboOnChange('CustomerOrderYN') : comboOnChange('CustomerOrder') }} optional='optional' disabled={chk_indent_source !== 'S'}>
                                                    <option value="">Select</option>
                                                    {customerOrdOpts.length !== 0 ?
                                                        <>
                                                            {customerOrdOpts?.map(order => (
                                                                <option value={order.customer_order_no} ord_date={order.customer_order_Date}  >{order.customer_order_no}</option>
                                                            ))}
                                                        </>
                                                        : ''
                                                    }
                                                </select>
                                                :
                                                <Form.Control type="text" id='cmb_customer_order_no' className="erp_input_field" value={cmb_customer_order_no} optional='optional' disabled />
                                            }
                                            <MDTypography variant="button" id="error_cmb_customer_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className="col-12 col-md-3 pt-md-0 pt-3">
                                            <Form.Control type="date" id='customer_order_date' className="erp_input_field" value={customer_order_date} optional='optional' disabled />
                                            <MDTypography variant="button" id="error_customer_order_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    {/* </div> */}
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Schedule Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id='txt_schedule_date' className="erp_input_field" value={txt_schedule_date} onChange={(e) => { setScheduleDate(e.target.value); validateFields(); }} min={currentDate} />
                                            <MDTypography variant="button" id="error_txt_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {((keyForViewUpdate === 'view' && chk_indent_status !== 'P') || keyForViewUpdate === 'approve') ? (
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Approved By</Form.Label>
                                                </div>
                                                <div className='col'>
                                                    <select
                                                        id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id}
                                                        onChange={(e) => { setApproved_by_id(e.target.value); }} optional='optional' disabled='disabled'
                                                    >
                                                        <option value="">Select</option>
                                                        {approvedByOptions.length !== 0 ?
                                                            <>
                                                                {approvedByOptions?.map(cmb_approved_by_id => (
                                                                    <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography
                                                        variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}
                                                    >
                                                    </MDTypography>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-sm-3">
                                                    <Form.Label className="erp-form-label">Approved Date </Form.Label>
                                                </div>
                                                <div className="col">
                                                    <Form.Control type="date" id='txt_approved_date' className="erp_input_field" value={txt_approved_date} onChange={e => { setApproved_date(e.target.value); }} optional='optional' disabled='disabled' />
                                                    <MDTypography variant="button" id="error_txt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                    ) : null
                                    }
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Indent Priority<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check
                                                        className="erp_radio_button"
                                                        label="Low-Priority"
                                                        type="radio"
                                                        lbl="Low-Priority"
                                                        value="L"
                                                        name="chk_indent_priority"
                                                        checked={chk_indent_priority === "L"}
                                                        onChange={() => { setIndentPriority("L") }}
                                                    />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check
                                                        className="erp_radio_button"
                                                        label="High-Priority"
                                                        type="radio"
                                                        lbl="High-Priority"
                                                        value="H"
                                                        name="chk_indent_priority"
                                                        checked={chk_indent_priority === "H"}
                                                        onChange={() => { setIndentPriority("H") }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_department" className="form-select form-select-sm" value={cmb_department} onChange={() => { comboOnChange('departmentId'); validateFields(); }} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {
                                                    departmentIdOPtions.length !== 0 ? (
                                                        <>
                                                            {departmentIdOPtions?.map(departmentId => (
                                                                <option value={departmentId.field_id}>{departmentId.field_name}</option>

                                                            ))}
                                                        </>
                                                    ) : null
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Sub Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_sub_department" className="form-select form-select-sm" value={cmb_sub_department} onChange={() => { comboOnChange('subDepartment'); validateFields(); }}>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {subDepartmentOPtions?.map(departmentId => (
                                                    <option cost_center_group={departmentId.cost_center_group} value={departmentId.field_id}>{departmentId.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sub_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Indented By <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10 mb-1'>
                                            {/* <select id="cmb_indented_by" className="form-select form-select-sm" value={cmb_indented_by} onChange={() => { comboOnChange('identedById'); validateFields(); }}>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {indentedByIdOptions.length !== 0 ? (
                                                    <>
                                                        {indentedByIdOptions?.map(identedById => (
                                                            <option value={identedById.field_id}>{identedById.field_name}</option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select> */}

                                            <Select ref={intentedByComboRef}
                                                options={indentedByIdOptions}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_indented_by" // Provide the ID for the input box
                                                value={indentedByIdOptions.find(option => option.value === cmb_indented_by)}
                                                onChange={(selectedOpt) => {
                                                    setIndentedById(selectedOpt.value);
                                                    intentedByComboRef.current = selectedOpt;
                                                    comboOnChange('identedById');
                                                    validateFields();
                                                }}
                                                placeholder="Search for indented by..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_indented_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={` ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_employee') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Indent Status </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="chk_indent_status" className="form-select form-select-sm" value={chk_indent_status} onChange={(e) => { setIndentStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                                <option value="R">Rejected</option>
                                                <option value="C">Completed</option>
                                                <option value="X">Canceled</option>
                                                <option value="Z">PreClosed</option>
                                                {/* <option value="I">Partial Issue</option> */}
                                                {/* <option value="O">Purchse Order Genreated</option> */}
                                                {/* <option value="POA">Purchse Order Approved</option> */}
                                                {/* <option value="G">Goods Receipts</option> */}
                                                {/* <option value="IG">Partial Goods Receipts</option> */}
                                                {/* <option value="MI">Material Issue</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' maxLength={1000} />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Active</Form.Label>
                                        </div>
                                        <div className="col">

                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" lbl="Yes" type="radio" value="true" name="chk_isactive" checked={chk_isactive === true} onClick={() => { setIsActive(true); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" lbl="No" type="radio" value="false" name="chk_isactive" checked={chk_isactive === false} onClick={() => { setIsActive(false); }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openIndentDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={(e) => setOpenIndentDetailsAccord(!openIndentDetailsAccord)} className="erp-form-label-md">Indent Details</Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <div className="col-md-4 col-12">
                                        {/* <div className={`row ${(keyForViewUpdate === '' || keyForViewUpdate === 'update') && (chk_indent_source === 'I' || requestfor === 'YN') ? 'display' : 'd-none'}`}> */}
                                        <div className={`row ${(keyForViewUpdate === '' || keyForViewUpdate === 'update') && ((chk_indent_source === 'I' || requestfor === 'YN' || (requestfor === 'PRM'))) ? 'display' : 'd-none'}`}>
                                            <div className="col-md-8 col-12">
                                                {/* <SearchItemFilter getSearchData={handleCloseBomFilterForm} formIdForValidate="" requiredCols={requiredCols} product_type_id={requestfor === 'YN' ? '2' : cmb_indent_type} product_category2_id={requestfor === 'YN' ? '5' : 0} /> */}
                                                <SearchItemFilter getSearchData={handleCloseBomFilterForm} formIdForValidate="" requiredCols={requiredCols} product_type_id={requestfor === 'YN' ? '2' : requestfor === 'PRM' ? '12' : cmb_indent_type} product_category2_id={requestfor === 'YN' || requestfor === 'PRM' ? category_2_id : 0}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <MDButton type="button" className="erp-gb-button" variant="button" id="add-material"
                                                    fontWeight="regular" onClick={viewBomFilterForm}
                                                    //  disabled={isApprove === true || (chk_indent_source === 'S' && requestfor !== 'YN') ? true : false}
                                                    disabled={isApprove === true || (chk_indent_source === 'S' && (requestfor !== 'YN' && requestfor !== 'PRM')) ? true : false}
                                                >Add Materials</MDButton>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-8 col-12  align-self-end pt-1">
                                        <div className='d-flex justify-content-end'>
                                            <MDButton type="button" id="export_btn_id" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`} variant="button" onClick={ExportToExcel}
                                                fontWeight="regular" >Export</MDButton>
                                            <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                            <MDButton type="button" id="import-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== '' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={onImportClick} disabled={keyForViewUpdate === "" ? '' : 'disabled'}>Import</MDButton>
                                        </div>
                                    </div>
                                </div>
                                <div className="erp-Mt-10">
                                    {bomfilteredData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> : ""
                                    }

                                    {stockDetails.length !== 0 && bomfilteredData.length !== 0 ?
                                      
                                        <>
                                            <hr />
                                            <Accordion defaultActiveKey="0" >
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="erp-form-label-md">Stock Details</Accordion.Header>
                                                    <Accordion.Body className="p-0">
                                                        {renderStockDetailsTbl}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </> : ""
                                    }
                                    {productpropertisList.length !== 0 && bomfilteredData.length !== 0 ?
                                        // <>
                                        //     {renderProductPropertisDetailsTbl}
                                        // </> : ""
                                        <>
                                            <hr />
                                            <Accordion defaultActiveKey="0" >
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="erp-form-label-md">Product Based Properties</Accordion.Header>
                                                    <Accordion.Body className="p-0">
                                                        {renderProductPropertisDetailsTbl}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </> : ""
                                    }
                                    {scheduleDataArray.length !== 0 ?
                                        <>
                                            <hr />
                                            <Accordion defaultActiveKey="1" >
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header className="erp-form-label-md">Material Schedules</Accordion.Header>
                                                    <Accordion.Body className="p-0">
                                                        {renderScheduleTable}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </> : ""

                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >
                    {/* <hr />
                    <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                        <Accordion.Item eventKey="productTypeParameters">
                            <Accordion.Header className="erp-form-label-md">Product Type Extra Parameters</Accordion.Header>
                            <Accordion.Body>
                                <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                    <div className="col-12 col-sm-6">
                                        <input type='checkbox' class="" id="selectAllParameter" onClick={(e) => checkBoxesSelection('selectAllParameter')} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} /><label class="erp-form-label p-1 me-2"> Select All </label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <MDButton type="button" className={`erp-gb-button float-sm-end col-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                                            onClick={() => {
                                                sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                setHeaderName('Common Parameter'); // CommonParamterEntry
                                                setShowAddRecIndentModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                            }}>Add
                                        </MDButton>
                                    </div>
                                </div>
                                {renderProductTypeParametersTable}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion> */}
                    <hr />
                    <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                        <Accordion.Item eventKey="purchaseOrderTerms">
                            <Accordion.Header className="erp-form-label-md">Purchase Order Terms</Accordion.Header>
                            <Accordion.Body>

                                <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                    <div className="col-12 col-sm-6">
                                        <input type='checkbox' class="" id="selectAllPOTerms" onClick={(e) => checkBoxesSelection('selectAllPOTerms')} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} /><label class="erp-form-label p-1 me-2"> Select All </label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <MDButton type="button" className={`erp-gb-button float-sm-end col-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                                            onClick={() => {
                                                sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                setHeaderName('Common Parameter'); // CommonParamterEntry
                                                setShowAddRecIndentModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                            }}>Add
                                        </MDButton>
                                    </div>
                                </div>
                                {renderPOTermsTable}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <hr />
                    <Accordion defaultActiveKey={docData.length > 0 ? "1" : "0"} onSelect={FnLoadAccordionData}>
                        <Accordion.Item eventKey="documentList">
                            <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                            <Accordion.Body>
                                <div className={`table-responsive ${docData.length > 4 ? 'erp_table_scroll' : ''}`}>
                                    {docData.length !== 0 ? (
                                        renderDocumentTable
                                    ) : (null)}
                                </div>
                                {docData.length === 0
                                    ? <div className='row'>
                                        <div className="col-12 text-center">
                                            <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                No Records Found...
                                            </span>
                                        </div>
                                    </div>
                                    : null
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
                <div className="erp_frm_Btns">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => { addIndent(!isApprove ? 'ADD' : 'A') }}
                        fontWeight="regular">Save</MDButton>
                    <MDButton type="button" id="reject-btn" className={`erp-gb-button erp_MLeft_btn ${isApprove ? 'display' : 'd-none'}`}
                        variant="button" onClick={() => addIndent('R')}>Rejected</MDButton>                        

                    <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? chk_indent_status !== 'P' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id="printbtn_id"
                        onClick={() => { printInvoice(true); }} disabled={chk_indent_status !== "P" ? '' : 'disabled'} >Print<FiDownload className="erp-download-icon-btn" /></MDButton>
                    <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" id='clearbutton-id' onClick={FnClearFormFields} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Clear</MDButton>
                    <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view'  ? 'd-none' : 'display'}`} onClick={() => setShowDocumentForm(true)} variant="button" fontWeight="regular" >Upload Document</MDButton>&nbsp;
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'cancel' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShowCancel(true)}>Canceled</MDButton>
                </div >

                {/* ADD Filter modal */}
                <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={handleCloseBomFilterForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header >
                        <Modal.Title className='erp_modal_title '>Product List</Modal.Title>
                        <span ><button type="button" className="erp-modal-close btn-close btn btn-sm" aria-label="Close" onClick={handleCloseBomFilterForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <ProductListProductTypeWise closeModal={handleCloseBomFilterForm} props={{ product_type_id: cmb_indent_type }} />
                        </React.Fragment>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" className='erp_document_Form' show={showSChedules} onHide={handleCloseSchedulesPopup} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Add Schedules</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSchedulesPopup}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <dl className="row mb-0">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-3 erp-form-label-md-lg">Material Name:</dt>
                                    <dd className="col-sm-12 col-md-9 erp-form-label" id='productMatName'></dd>
                                </dl>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg"> Total Quantity:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatQty'></dd>
                                </dl>
                            </div>

                        </dl>
                        <dl className="row mb-0">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-6 col-md-3 erp-form-label-md-lg"> Material Unit:</dt>
                                    <dd className="col-sm-6 col-md-9 erp-form-label" id='productMatUnit'></dd>  </dl>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">Total Weight:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatTotalWeight'></dd>
                                </dl>
                            </div>
                        </dl>
                        <dl className="row mb-0">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-md-3 col-sm-12 erp-form-label-md-lg">Std. Weight:</dt>
                                    <dd className="col-md-9 col-sm-12  erp-form-label" id='productMatStdWeight'></dd>
                                </dl>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">SO SrNo:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='soSrNo'></dd>
                                </dl>
                            </div>
                        </dl>
                        <div className='row table-responsive'>
                            <Table className="erp_table" id="scheduleTable" bordered striped >
                                <thead className='erp_table_head erp_table_th'>
                                    <tr>
                                        <th className="col-2">Action</th>
                                        <th>Schedule Quantity</th>
                                        <th>Schedule Weight</th>
                                        <th>Expected Schedule Date</th>
                                        <th>Material Receipt Quantity</th>
                                        <th>Material Receipt Weight</th>
                                        <th>Material Receipt Date</th>
                                        <th>Schedule Item Status</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup">
                                    <tr scheduleTrCount={scheduleRowCount} id="RMSchedulesEntry-tr-1">
                                        <td style={{ width: '100px' }}><IoRemoveCircleOutline className='erp_trRemove_icon' /> <IoAddCircleOutline className='erp_trAdd_icon disableClass' onClick={() => addNewScheduleRow()} /></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='erp-modal-footer'>
                        <MDButton type="button" className="erp-gb-button" variant="button"
                            fontWeight="regular" onClick={() => schedule()}>Schedule</MDButton>
                    </Modal.Footer>
                </Modal>
                <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, succMsg, modalOrderDetails, messageForConfirmation]} />
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal('Exit')} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
                {memorizedDocumentComponent}
                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecIndentModal} onHide={closeRecordAddIndentModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordIndentComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={closeRecordAddIndentModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>
                    </Modal.Footer>
                </Modal >

                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                {/* Cancel Modal */}
                <Modal show={showCancel} onHide={()=> setShowCancel(false)} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={()=> setShowCancel(false)}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to Cancel Indent?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={()=> setShowCancel(false)}>
                            No
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={cancelRecords}>Yes, Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </DashboardLayout >
        </>
    )
}

