import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Datatable from "components/DataTable";

// React icons
import { MdDelete, MdRefresh } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import { AiOutlineSchedule } from "react-icons/ai";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";


//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo"
import ExcelExport from "Features/Exports/ExcelExport";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ReturnMaterialGatePass from 'FrmGeneric/Invoice/ReturnMaterialGatePass';
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { renderToString } from "react-dom/server";

// Import for the searchable combo box.
import Select from 'react-select';

function FrmMaterialReturnGatePassEntry() {
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName } = configConstants;


    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [openIssueDetailsAccord, setOpenIssueDetailsAccord] = useState(true);
    let docGroup = "Return Material Gate Pass"

    // For navigate
    const navigate = useNavigate();
    const navigator = useNavigate();

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    let supplierComboRef = useRef(null);


    // for today date formatted
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];

    //form combo options
    const [productTypeOptions, setProducttypeOptions] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [suppAddress, setSuppAddress] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [cmb_checker_by_id, setCheckerById] = useState()
    const [cmb_approved_by_id, setApprovedById] = useState(UserId)
    const [cmb_gate_pass_status, setGatePassStatus] = useState('P')
    const [cmb_approved_by_name, setApprovedByName] = useState(UserName)
    const [dt_approve_date, setApproveDate] = useState(todayDate())
    const [cmb_checker_by_name, setCheckerByName] = useState(UserName)
    const [gatePassNOopt, setGatePassNoOpt] = useState([])
    const [cmb_gate_PassNo, setGatePassNo] = useState([])

    //Form Fields
    const [cmb_product_type, setProductType] = useState('');
    const [cmb_product_type_name, setProductTypeName] = useState('');
    const [materialId, setMaterialId] = useState('');
    const [materialName, setMaterialName] = useState('');
    const [materialCode, setMaterialCode] = useState('');
    const [txt_return_gate_pass_no, setMaterialReturnGatePassNo] = useState('')
    const [challan_no, setMaterialReturnGatePassChallanNo] = useState('')
    const [dt_gate_pass_date, setGatePassDate] = useState(todayDate)
    const [challan_date, setGatePassChallanDate] = useState('')
    const [material_return_gate_pass_master_id, setTransferIssueMasterTransaction_id] = useState(idList.material_return_gate_pass_master_id);
    const [cmb_from_company_id, setFromCompanyId] = useState();
    const [materialGatePassDetailsData, setMaterialGatePassDetailsData] = useState([])
    const [material_type, setMaterialType] = useState('RGP');
    const [txt_vehicle_no, setVehicleNo] = useState('');
    const [cmb_supplier_branch_id, setSupplierBranchId] = useState('');
    const [cmb_supplier_id, setSupplierId] = useState('');
    const [cmb_supplier_city_id, setSuppliersCityId] = useState('');
    const [cmb_supplier_state_id, setSuppliersStateId] = useState('');

    // supp contact details table data hooks
    const [SuppContactDetails, setSuppContactDetails] = useState([]);
    const [SuppContactDetailsCols, setSuppContactDetailsCols] = useState([]);
    const [masterDataForPrint, setDataForPrint] = useState([]);

    let empApprovedComboRef = useRef();
    let empCheckerComboRef = useRef();
    let gatePassNoComboRef = useRef(null);


    const [actionType, setActionType] = useState("");
    const [actionLabel, setActionLabel] = useState("Save");

    //Add Schedules popup open 
    const [returnDataArray, setReturnDataArray] = useState([]);
    const [returnDataArraysummary, setReturnDataArraySummary] = useState([]);


    const [showSChedules, setSchedules] = useState(false);
    const handleCloseReturnSummaryPopup = () => setSchedules(false)
    const [returnRowcount, setReturnRowcount] = useState(1);

    const [totalOutwardQuantity, setTotalOutwardQuantity] = useState(0);
    const [totalInwardQuantity, setTotalInwardQuantity] = useState(0);
    const [remainingQuantity, setRemainingQuantity] = useState(0);
    const [prev_inward_quantity, setTotalPreInwardQTY] = useState(0);

    //department and sub-dept 
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_subdepartment_id, setSubDepartmentId] = useState('');
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [subDepartmentOptions, setSubDepartmentOptions] = useState([]);
    const cmb_department_ref = useRef()
    const cmb_subdepartment_ref = useRef()


    useEffect(async () => {
        debugger
        setIsLoading(true);
        try {
            await ActionType();
            removeSessions('onload');
            await fillCombos();
            if (idList !== '' || idList !== undefined) {
                await FnCheckUpdateResponse();
            }
            FnSetFieldsByUserAccess()
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [])
    // delete popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    const closeConfirmationModal = async () => {
        setShowSuccessMsgModal(false)
        moveToListing();
    };


    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case "update":
                setActionType("(Modification)");
                setActionLabel("Update");
                break;
            case "view":
                $('input[type="checkbox"]').prop('readonly', true);
                setActionType("(View)");
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;
            case "approve":
                setActionType("(Approve)");
                setActionLabel("Approve");
                setGatePassStatus("A")
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;
            case "return":
                setActionType("(Return)");
                setActionLabel("Return");
                setGatePassStatus("R")
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,select").attr("disabled", "disabled");
                $("table").find("input,button,select").attr("disabled", "disabled");
                break;
            default:
                setActionType("(Creation)");
                break;
        }
    };

    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillCombos = async () => {
        
        try {
            console.log(`COMPANYID:${COMPANY_ID}`)
            setFromCompanyId(parseInt(COMPANY_ID))
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const prodTypeOpts = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            console.log(prodTypeOpts)
            const options = [
                { value: '', label: 'Select', short_name: "" },
                ...prodTypeOpts.map((prodType) => ({ ...prodType, value: prodType.field_id, label: prodType.field_name, short_name: prodType.product_type_short_name })),
            ];
            setProducttypeOptions(options)

            //For Supplier
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "city_name", "state_name", "supp_branch_city_id", "supp_branch_state_id", "supp_branch_address1","supplier_id"]
            globalQuery.table = "cmv_supplier_branch";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.conditions.push({ field: "supp_branch_type", operator: "=", value: "Supplier" });
            const getSuppliers = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            //  ** Changes for the Searchable Combo
            const suppliers = [
                { value: '', label: 'Select', supp_branch_address1: '', supp_branch_state_id: '', supp_branch_city_id: '', state_name: '', city_name: '', },
                { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '' },
                ...getSuppliers.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, address: supplier.supp_branch_address1,supplierId:supplier.supplier_id })),
            ];
            setSupplierList(suppliers);
            const getStates = await comboDataAPiCall.current.fillMasterData("cmv_state", "", "")
            setStateList(getStates)

            resetGlobalQuery();
            globalQuery.columns.push("employee_id");
            globalQuery.columns.push("employee_name");
            globalQuery.table = "cm_employee";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            const requestedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const employeeOptions = [
                ...requestedbyIdApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
            ];
            setEmployeeOptions(employeeOptions);
            setIsLoading(false)
            comboOnChange('ApprovedBy');

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name',]
            globalQuery.conditions = [
                { field: "department_type", operator: "=", value: "M" },
                { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] },
            ]
            globalQuery.table = "cmv_department";
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((deptOptions) => {
                const departmentOptions = [
                    { value: '', label: 'Select' },
                    { value: 'all', label: 'All' },
                    { value: '0', label: 'Add New Record+' },
                    ...deptOptions.map((department) => ({ ...department, value: department.field_id, label: department.field_name, })),
                ];
                setDepartmentOptions(departmentOptions);
            });



        }
        catch (error) {
            console.log("error: ", error)
        }
    }

    //fn to get po auto no
    const GenerateGatePassNo = async () => {
        const ptShortName = productTypeOptions.find((item) => item.value === parseInt($("#cmb_product_type").val()));
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_material_return_gate_pass_master", "return_gate_pass_no", ptShortName.product_type_short_name, 'RGRN', "5");
        setMaterialReturnGatePassNo(autoNoApiCall);
        return autoNoApiCall;
    };
    //-------------------------------------- Combo changes ------------------------------------------------------------
    const comboOnChange = async (key) => {

        switch (key) {
            case 'ApprovedBy':
                debugger
                var employeeID = empApprovedComboRef.current?.employee_id ?? UserId;
                resetGlobalQuery();
                globalQuery.columns.push("employee_id");
                globalQuery.columns.push("employee_name");
                globalQuery.table = "cm_employee";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeID });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                const requestedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                const employeeOptions = [
                    ...requestedbyIdApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
                ];

                $("#error_cmb_approved_by_id").hide();
                if (employeeID !== "") {
                    setApprovedById(employeeOptions[0].employee_id);
                    setApprovedByName(employeeOptions[0].employee_name);


                } else {
                    setApprovedById('');
                    setApprovedByName('');
                }
                break;

            case 'CheckerBy':

                var employeeID = empCheckerComboRef.current?.employee_id ?? UserId;
                resetGlobalQuery();
                globalQuery.columns.push("employee_id");
                globalQuery.columns.push("employee_name");
                globalQuery.table = "cm_employee";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeID });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                const requestedbyIdApiCalls = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                const employeeOption = [
                    ...requestedbyIdApiCalls.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
                ];
                $("#error_cmb_checker_by_id").hide();
                if (employeeID !== "") {
                    setCheckerById(employeeOption[0].employee_id);
                    setCheckerByName(employeeOption[0].employee_name);
                    empCheckerComboRef.current.employee_id = employeeOption[0].employee_id
                    empCheckerComboRef.current.employee_name = employeeOption[0].employee_name
                } else {
                    setCheckerById('');
                    setCheckerByName('');

                }
                break;

            case 'Supplier':
                debugger
                setGatePassNoOpt([]);

                let product_type_id = $('#cmb_product_type option:selected').val();
                let supplierValue = supplierComboRef.current.value || '';
                if (supplierValue === "0") {
                    localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: 0, keyForViewUpdate: '', compType: 'Master' }))
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    const newTab = window.open('/Masters/FrmSupplier', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }

                if (keyForViewUpdate === 'add') {
                    resetGlobalQuery();
                    globalQuery.columns = ["DISTINCT gate_pass_no", "material_gate_pass_master_id"]
                    globalQuery.table = "ptv_material_gate_pass_master"
                    globalQuery.conditions.push({ field: "supplier_branch_id", operator: "=", value: supplierComboRef.current.value });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "gate_pass_status", operator: "=", value: 'A' });
                    globalQuery.conditions.push({ field: "material_type", operator: "=", value: 'RGP' });
                    globalQuery.conditions.push({ field: "return_status", operator: "IN", values: ['P', 'I'] });
                    globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: product_type_id });

                    globalQuery.orderBy = ["material_gate_pass_master_id desc"];
                    const getGatePassNos = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                    const gatePassList = [
                        { value: '', label: 'Select', },
                        ...getGatePassNos.map((poNo) => ({ ...poNo, value: poNo.material_gate_pass_master_id, label: poNo.gate_pass_no })),
                    ];
                    setGatePassNoOpt(gatePassList)

                }


                if (supplierValue !== "0" && !isNaN(supplierValue)) {
                    setIsLoading(true);
                    let selectedState = supplierComboRef.current?.supp_branch_state_id;
                    let selectedCity = supplierComboRef.current?.supp_branch_city_id;
                    setSuppliersStateId(selectedState);
                    const getCities = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + selectedState + "");
                    setSuppAddress(supplierComboRef.current?.supp_branch_address1)
                    setCityList(getCities)
                    setSuppliersCityId(selectedCity);
                    await FnGetSupplierContacts(supplierValue);
                    $("#error_cmb_supplier_branch_id").hide();
                    setIsLoading(false);
                } else {
                    setSuppliersStateId('')
                    setSuppliersCityId('')
                    setSuppContactDetails([])
                    setSuppContactDetailsCols([])
                }
                break;

            case "SupplierState":
                var supplierStateTpVal = document.getElementById("cmb_supplier_state_id").value;
                setSuppliersStateId(supplierStateTpVal)
                if (supplierStateTpVal !== "" && supplierStateTpVal !== "0") {
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + supplierStateTpVal + "");
                    setCityList(cityApiCall);
                    setSuppliersCityId('');

                }
                else {
                    setCityList([]);
                }
                break;

            case 'cmb_product_type':
                setGatePassNoOpt([]);
                setSuppliersStateId('')
                setSuppliersCityId('')
                setSuppContactDetails([])
                setSuppContactDetailsCols([])
                setSupplierBranchId('');
                setSuppAddress('');
                setSuppContactDetails([]);
                $("#error_cmb_product_type").hide();
                let productTypeId = $('#cmb_product_type option:selected').val()
                let prodTypeName = $('#cmb_product_type option:selected').text()
                if (productTypeId !== "") {
                    setProductType(productTypeId);
                    setProductTypeName(prodTypeName);
                }
                if (keyForViewUpdate === "add") {
                    GenerateGatePassNo()
                }

                break;
            case 'Department':
                debugger
                const departmentId = cmb_department_ref.current.value;

                if (departmentId !== "") {
                    setDepartmentId(departmentId);
                    cmb_department_ref.current.field_id = departmentId

                    $('#error_department_group_id').hide();
                    try {
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name', 'parent_department_id', 'parent_department']
                        globalQuery.conditions = [
                            { field: "department_type", operator: "=", value: "S" },
                            { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] },
                        ]
                        if (departmentId !== 'all') {
                            globalQuery.conditions.push({
                                field: "parent_department_id",
                                operator: "=",
                                value: departmentId
                            });
                        }
                        globalQuery.table = "cmv_department";
                        comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((deptOptions) => {
                            const departmentOptions = [
                                { value: '', label: 'Select' },
                                { value: 'all', label: 'All' },
                                { value: '0', label: 'Add New Record+' },
                                ...deptOptions.map((department) => ({
                                    ...department, value: department.field_id, label: department.field_name,
                                    parent_department_id: department.parent_department_id, parent_department: department.parent_department
                                })),
                            ];
                            setSubDepartmentOptions(departmentOptions);
                        });
                        setSubDepartmentId('');
                    } catch (error) {
                        console.log('Error: ' + error);
                    }
                } else {
                    setSubDepartmentOptions([]);
                    setSubDepartmentId('');

                }

                break;

            case 'SubDepartment':
                const SubDepartmentval = cmb_subdepartment_ref.current.value;

                break;

            default:
                break;
        }
    }

    const FnValidateReturnGatePass = async () => {

        let checkName = cmb_checker_by_name;
        let prodType = cmb_product_type;
        let supplier = supplierComboRef.current.value;
        let gatePassNo = gatePassNoComboRef.current.value

        if (prodType === "" || prodType === "0") {
            $("#error_cmb_product_type").text("Please fill this field...!");
            $("#error_cmb_product_type").show();
            $("#cmb_product_type").focus();
            return false;
        }
        if (!supplier || supplier === "0") {
            $("#error_cmb_supplier_branch_id").text("Please fill this field...!");
            $("#error_cmb_supplier_branch_id").show();
            $("#cmb_supplier_branch_id").focus();
            return false;
        }
        if (!gatePassNo || gatePassNo == 0) {
            $("#error_cmb_gate_PassNo").text("Please fill this field...!");
            $("#error_cmb_gate_PassNo").show();
            $("#cmb_gate_PassNo").focus();
            return false;
        }else{
            $("#error_cmb_gate_PassNo").hide();
        }
        if (checkName === "" || checkName === "0") {
            $("#error_cmb_checker_by_id").text("Please fill this field...!");
            $("#error_cmb_checker_by_id").show();
            $("#cmb_checker_by_id").focus();
            return false;
        }
        return true; // Explicitly return true here

    }
    const showGatePassDetails = async (material_gate_pass_master_id) => {
        debugger
        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                let validateSelectBoxes = await FnValidateReturnGatePass();
                checkIsValidate = validateSelectBoxes
            }
            if (material_gate_pass_master_id !== '' && checkIsValidate === true) {
                setIsLoading(true)
                setMaterialGatePassDetailsData([])
                setReturnDataArraySummary([])

                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MaterialGatePass/FnShowParticularReturnInwardRecord/${parseInt(material_gate_pass_master_id)}/${COMPANY_ID}`);
                const responce = await apiCall.json();
                console.log('Material gate pass  data: ', responce);
                let masterData = responce.MaterialGatePassMasterDetails
                cmb_department_ref.current.value = masterData.department_id;
                setDepartmentId(masterData.department_id);
                await comboOnChange("Department")
                setSubDepartmentId(masterData.sub_department_id);
                cmb_subdepartment_ref.current.value = masterData.sub_department_id;

                if (responce.MaterialGatePassReturnSummary !== null && responce.MaterialGatePassReturnSummary !== "") {
                    setReturnDataArraySummary(responce.MaterialGatePassReturnSummary);
                }
                if (responce.MaterialGatePassDetails !== null && responce.MaterialGatePassDetails !== "") {
                    const transformedData = responce.MaterialGatePassDetails.map((item) => ({
                        ...item,
                        product_code: item.material_code,
                        product_material_name: item.material_name,
                        gate_pass_item_status: keyForViewUpdate === "approve" ? "A" : item.gate_pass_item_status,
                        pending_inward_quantity: item.outward_quantity - item.inward_quantity,
                        prev_inward_quantity: item.inward_quantity,

                        inward_quantity:
                            keyForViewUpdate === "view"
                                ? item.inward_quantity // Show the actual value in view mode
                                : item.return_item_status === "I"
                                    ? 0 // Set to 0 if status is "I"
                                    : item.return_item_status === "P"
                                        ? 0 // Set actual value if status is "A"
                                        : item.inward_quantity,

                    }));
                    setMaterialGatePassDetailsData(transformedData);
                }

            } else {
                setReturnDataArraySummary([])
                setMaterialGatePassDetailsData([])

            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }


    //--------------------------------------------------------Start Details section----------------------------------------------------------------------

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData')
        }
        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/MaterialReturnGatePass/FrmMaterialReturnGatePassListing/reg' : `/Transactions/TPurchaseOrder/MaterialReturnGatePass/FrmMaterialReturnGatePassListing`;
        navigate(Path);
    }

    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });

    const handleIssueDetailsInputChange = (currentRowData, event) => {
        debugger

        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'), 10);
        const focusedInputField = document.querySelector(`#${event.target.id}`);
        const clickedColName = event.target.getAttribute('Headers');
        let { stock_quantity, product_material_std_weight, stock_weight } = currentRowData;
        let enteredValue = event.target.value;

        // Ensure entered value is numeric or default to 0
        switch (clickedColName) {
            case 'outward_quantity':

                if (enteredValue !== "") {
                    currentRowData[clickedColName] = enteredValue;
                    delete focusedInputField.parentElement.dataset.tip;
                    currentRowData["pending_inward_quantity"] = parseFloat(enteredValue) - parseFloat(currentRowData.inward_quantity)

                } else {
                    currentRowData[clickedColName] = enteredValue;
                    focusedInputField.parentElement.dataset.tip = `Please Enter Quantity...!`;
                }
                break;


            case 'inward_quantity':
                if (keyForViewUpdate === "add") {
                    // Validate inward_quantity is not greater than outward_quantity
                    const outwardQuantity = parseFloat(currentRowData.outward_quantity || 0);
                    const prevReturnQTY = parseFloat(currentRowData.prev_inward_quantity || 0);
                    const inwardQuantity = parseFloat(enteredValue || 0);
                    delete focusedInputField.parentElement.dataset.tip;

                    if ((outwardQuantity - prevReturnQTY) < inwardQuantity) {
                        focusedInputField.parentElement.dataset.tip = `Inward Quantity cannot be greater than Remaining Quantity (${outwardQuantity - prevReturnQTY})...!`;
                        return false; // Stop execution if validation fails
                    }
                    if (inwardQuantity > outwardQuantity) {
                        focusedInputField.parentElement.dataset.tip = `Inward Quantity cannot be greater than Outward Quantity (${outwardQuantity})...!`;
                        return false; // Stop execution if validation fails
                    }
                    delete focusedInputField.parentElement.dataset.tip;
                    // If validation passes, update the current row data
                    currentRowData[clickedColName] = parseFloat(enteredValue) || 0;
                    currentRowData["pending_inward_quantity"] = parseFloat(currentRowData.outward_quantity) - (prevReturnQTY + parseFloat(enteredValue) || 0)

                }
                break;
            case 'outward_weight':
            case 'inward_weight':
            case 'rate':
                {
                    currentRowData[clickedColName] = enteredValue;
                    break;
                }
            case 'remark': {
                currentRowData[clickedColName] = event.target.value;
                break;
            }
            case 'gate_pass_item_status': {
                currentRowData[clickedColName] = event.target.value;
                break;
            }
            case "pending_inward_quantity":
                currentRowData[clickedColName] = parseFloat(currentRowData.outward_quantity) - parseFloat(currentRowData.inward_quantity)
                break;
            default:
                return; // Exit if the column name doesn't match any case
        }

        // Update the material transfer details data
        setMaterialGatePassDetailsData((prevArr) => {
            const updatedArr = [...prevArr];
            updatedArr[arrayIndex] = currentRowData;
            return updatedArr;
        });

        localStorage.setItem('purchaseOrdScheduleData', JSON.stringify(currentRowData));
    };


    const deleteRecord = (Index, action) => {
        setMaterialGatePassDetailsData((prevDetailsData) => prevDetailsData.filter((data, i) => i !== Index))
    }

    //details table
    const renderDetailsTable = useMemo(() => {
        debugger
        return <>
            <Table className="erp_table" id='gatepass-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>

                        {(keyForViewUpdate === "add" || keyForViewUpdate === "update" || keyForViewUpdate === "return") && <th className="erp_table_th">Action</th>}
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Material Code</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Cost Center</th>
                        <th className="erp_table_th">Outward Quantity</th>
                        <th className="erp_table_th">Prev.Return Inward Qty.</th>

                        <th className="erp_table_th">Inward Quantity</th>

                        <th className="erp_table_th">Pending Quantity</th>
                        <th className="erp_table_th" style={{width:"10%"}}>Rate</th>

                        <th className="erp_table_th">Gate Pass Status</th>
                        <th className="erp_table_th">Purpose</th>
                        <th className="erp_table_th">Remark</th>

                    </tr>
                </thead>
                <tbody>
                    {materialGatePassDetailsData.map((issueItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            {(keyForViewUpdate === "add" || keyForViewUpdate === "update" || keyForViewUpdate === "return") &&
                                <td className="erp_table_td" style={{ verticalAlign: "middle" }} ><Tooltip title="Delete" placement="top">
                                    <MDTypography className={`erp-view-btn ${keyForViewUpdate === "add" || keyForViewUpdate === "update" ? '' : 'd-none'}`} >
                                        <MdDelete sty className={`erp-delete-btn`} style={{ display: "flex", margin: "auto" }} onClick={() => deleteRecord(Index)} />
                                    </MDTypography></Tooltip>
                                </td>}

                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className="erp_table_td "> {issueItem.product_code}</td>
                            <td className="erp_table_td "> {issueItem.product_material_name}</td>
                            <td className="erp_table_td "> {issueItem.cost_center_name}</td>

                            <td className="erp_table_td text-end"> {issueItem.outward_quantity}</td>

                            <td className="erp_table_td text-end"> {issueItem.prev_inward_quantity}</td>

                            <td className="erp_table_td text-end">
                                <input
                                    type="text"
                                    id={`inward_quantity_${issueItem.product_material_id}`}
                                    className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                    value={issueItem.inward_quantity}
                                    onChange={(e) => handleIssueDetailsInputChange(issueItem, e)}
                                    headers="inward_quantity"
                                />
                            </td>


                            <td className="erp_table_td text-end "> {issueItem.pending_inward_quantity}</td>
                            <td className="erp_table_td text-end "> {issueItem.rate}</td>


                            <td className="erp_table_td ">
                                <select rowIndex={`${issueItem}`} id={`gate_pass_item_status_${issueItem.product_material_id}`} disabled class="form-select form-select-sm erp_input_field_table_txt m-0"
                                    onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} value={issueItem.return_item_status} >
                                    <option value="P">Pending</option>
                                    <option value="R">Return</option>
                                    <option value="I">Partial-Return</option>
                                </select>
                            </td>
                            <td className="erp_table_td text-end "> {issueItem.purpose}</td>
                            <td className="erp_table_td ">
                                {issueItem.remark}
                            </td>

                        </tr>
                    )}
                </tbody>
            </Table>
        </>

    }, [materialGatePassDetailsData])

    //-------------------------------- validate before save ------------------------------------------------------

    const validateFields = async () => {

        let prodTypeId = $('#cmb_product_type option:selected').val();
        if (prodTypeId === "" || prodTypeId === "0") {
            $("#error_cmb_product_type").text("Please fill this field...!");
            $("#error_cmb_product_type").show();
            $("#cmb_product_type").focus();
            return false;
        }
        else {
            validate.current.validateFieldsOnChange('gate_pass_form_id')
        }
    }

    const FnValidateGatePass = async () => {
        debugger

        let checkName = empCheckerComboRef.current.employee_id;
        let prodType = cmb_product_type;
        let supplier = supplierComboRef.current.value;
        let gatePassNo = gatePassNoComboRef.current.value

        if (prodType === "" || prodType === "0") {
            $("#error_cmb_product_type").text("Please fill this field...!");
            $("#error_cmb_product_type").show();
            $("#cmb_product_type").focus();
            // productTypeComboRef.current.focus()
            return;
        }
        if (!supplier || supplier === "0") {
            $("#error_cmb_supplier_branch_id").text("Please fill this field...!");
            $("#error_cmb_supplier_branch_id").show();
            $("#cmb_supplier_branch_id").focus();
            return;
        }
        if (!gatePassNo || gatePassNo === "0") {
            $("#error_cmb_gate_PassNo").text("Please fill this field...!");
            $("#error_cmb_gate_PassNo").show();
            $("#cmb_gate_PassNo").focus();
            return;
        }
        if(!checkName || checkName === "0") {
            $("#error_cmb_checker_by_id").text("Please fill this field...!");
            $("#error_cmb_checker_by_id").show();
            $("#cmb_checker_by_id").focus();
            // empCheckerComboRef.current.focus()
            return;
        }

        let headerDataIsValid = await validate.current.validateForm("gate_pass_form_id");
        if (!headerDataIsValid) { return false; }
        let issueDetailsIsValid = true;
        let addedIssuematerial = $('#gatepass-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add Atleast One Material Detail.');
            setShowErrorMsgModal(true);
            return issueDetailsIsValid = false;
        }
        else {
            const tableRows = $('#gatepass-details-tbl tbody tr');
            issueDetailsIsValid = true;
            tableRows.each(function () {
                if (keyForViewUpdate === "add") {
                    const issueQty = parseFloat($(this).find('input[id^="inward_quantity_"]').val()) || 0;
                    if (isNaN(issueQty) || issueQty === "" || issueQty === "0" || issueQty === 0) {
                        $(this).find(`input[id^='inward_quantity_']`).parent().attr('data-tip', 'Quantity cannot be 0...!');
                        $(this).find(`input[id^='inward_quantity_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
            });
            return issueDetailsIsValid;
        }
    }
    const FnValidateReturnSummary = async () => {
        let isValid = true; // Initialize isValid to true
        if (returnDataArraysummary.length === 0) {
            return true;
        }
        // Create a map to store the sum of inward_quantity by product_material_id for returnDataArraysummary
        const returnDataSummaryMap = returnDataArraysummary.reduce((acc, row) => {
            if (!acc[row.product_material_id]) {
                acc[row.product_material_id] = 0;
            }
            acc[row.product_material_id] += row.inward_quantity;
            return acc;
        }, {});

        // Iterate over materialGatePassDetailsData to check the inward_quantity
        materialGatePassDetailsData.forEach(gatePassDetailsRow => {
            const materialId = gatePassDetailsRow.product_material_id;
            const materialname = gatePassDetailsRow.product_material_name;
            const inwardQuantityInGatePass = gatePassDetailsRow.inward_quantity;

            // Check if the inward_quantity for this materialId exceeds the sum in returnDataArraysummary
            const inwardQuantityInReturnSummary = returnDataSummaryMap[materialId] || 0;

            if (inwardQuantityInGatePass > inwardQuantityInReturnSummary) {
                isValid = false; // If validation fails, set isValid to false
                setErrMsg(`Inward Quantity for material Name ${materialname} exceeds the Inward Return Quantity..!`);
                setShowErrorMsgModal(true);
            }

            const hasValidQuantity = materialGatePassDetailsData.some(
                gatePassDetailsRow => gatePassDetailsRow.gate_pass_item_status !== "R" && gatePassDetailsRow.inward_quantity > 0
            );

            const hasInvalidQuantity = materialGatePassDetailsData.some(
                gatePassDetailsRow => gatePassDetailsRow.gate_pass_item_status !== "R" && gatePassDetailsRow.inward_quantity === 0
            );

            if (hasInvalidQuantity && !hasValidQuantity) {
                isValid = false;
                setErrMsg("Please Add the Inward Quantity In Material Summary");
                setShowErrorMsgModal(true);
            }

        });

        return isValid; // Return the validation result
    };





    //-------------------------- Save Json ------------------------------------------------------------------

    const addMaterialGatePass = async () => {
        debugger
        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                let validateSelectBoxes = await FnValidateGatePass();
                checkIsValidate = validateSelectBoxes
            }
            if (keyForViewUpdate === "return") {
                let validateReturnSummary = await FnValidateReturnSummary();
                checkIsValidate = validateReturnSummary; // Assign the validation result to checkIsValidate

            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                let supplierContactPersonsIds = await FnGetSupplierContactPersonIds();
                let lastGatePassNo = txt_return_gate_pass_no
                if (keyForViewUpdate === "add") {
                    lastGatePassNo = await GenerateGatePassNo();
                }
                let json = { 'TransMaterialGatePassMasterDetails': {}, 'TransMaterialGatePassDetails': [], 'TransMaterialGatePassReturnSummary': [], 'commonIds': {} }
                debugger
                const transferMasterFormData = {
                    material_return_gate_pass_master_id: keyForViewUpdate !== "add" ? material_return_gate_pass_master_id : 0,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    return_gate_pass_no: lastGatePassNo,
                    gate_pass_no: gatePassNoComboRef.current.label,
                    checker_by_id: cmb_checker_by_id,
                    financial_year: FINANCIAL_SHORT_NAME,
                    return_gate_pass_date: dt_gate_pass_date,
                    vehicle_no: txt_vehicle_no,
                    product_type_name: cmb_product_type_name,
                    product_type_id: cmb_product_type,
                    material_type: material_type,
                    supplier_branch_id: cmb_supplier_branch_id,
                    supplier_branch_state_id: cmb_supplier_state_id,
                    supplier_branch_city_id: cmb_supplier_city_id,
                    supplier_branch_contacts_id: supplierContactPersonsIds,
                    supplier_branch_address: suppAddress,
                    checker_by_name: empCheckerComboRef.current.employee_name ?? UserName,
                    returned_by_id: cmb_approved_by_id,
                    returned_by_name: empApprovedComboRef.current?.employee_name ?? cmb_approved_by_name,
                    return_status: cmb_gate_pass_status,
                    department_id: cmb_department_ref.current.value ?? cmb_department_id,
                    sub_department_id: cmb_subdepartment_ref.current.value ?? cmb_subdepartment_id,
                    created_by: UserName,
                    created_on: todayDate(),
                    modified_by: UserName,
                    modified_on: todayDate(),
                    modified_by: idList !== "" ? UserName : null,
                    challan_no: challan_no,
                    challan_date: challan_date

                }
                json.TransMaterialGatePassMasterDetails = transferMasterFormData;
                const materialIdSet = new Set();

                const filteredMaterialGatePassDetailsData = materialGatePassDetailsData.filter(row => row.gate_pass_item_status !== "R");

                for (let issueItem = 0; issueItem < filteredMaterialGatePassDetailsData.length; issueItem++) {

                    const gatePassDetailsRow = filteredMaterialGatePassDetailsData[issueItem];

                    const MaterialtransferRow = {};
                    MaterialtransferRow['material_return_gate_pass_details_id'] = keyForViewUpdate !== "add" ? gatePassDetailsRow.material_return_gate_pass_details_id : 0
                    MaterialtransferRow['material_return_gate_pass_master_id'] = keyForViewUpdate !== "add" ? material_return_gate_pass_master_id : 0
                    MaterialtransferRow['material_gate_pass_details_id'] = gatePassDetailsRow.material_gate_pass_details_id
                    MaterialtransferRow['company_id'] = COMPANY_ID
                    MaterialtransferRow['company_branch_id'] = COMPANY_BRANCH_ID
                    MaterialtransferRow['financial_year'] = FINANCIAL_SHORT_NAME
                    MaterialtransferRow['return_gate_pass_no'] = lastGatePassNo
                    MaterialtransferRow['return_gate_pass_date'] = dt_gate_pass_date
                    MaterialtransferRow['product_material_id'] = gatePassDetailsRow.product_material_id
                    MaterialtransferRow['material_code'] = gatePassDetailsRow.product_code
                    MaterialtransferRow['material_name'] = gatePassDetailsRow.product_material_name
                    MaterialtransferRow['outward_quantity'] = gatePassDetailsRow.outward_quantity
                    MaterialtransferRow['pending_inward_quantity'] = gatePassDetailsRow.pending_inward_quantity
                    MaterialtransferRow['outward_weight'] = gatePassDetailsRow.outward_weight
                    MaterialtransferRow['inward_quantity'] = gatePassDetailsRow.inward_quantity
                    MaterialtransferRow['inward_weight'] = gatePassDetailsRow.inward_weight
                    MaterialtransferRow['return_item_status'] = gatePassDetailsRow.return_item_status
                    MaterialtransferRow['product_type_id'] = cmb_product_type
                    MaterialtransferRow['product_type_name'] = cmb_product_type_name
                    MaterialtransferRow['rate'] = gatePassDetailsRow.rate
                    MaterialtransferRow['purpose'] = gatePassDetailsRow.purpose
                    MaterialtransferRow['remark'] = gatePassDetailsRow.remark
                    MaterialtransferRow['created_by'] = UserName
                    MaterialtransferRow['created_on'] = todayDate()
                    MaterialtransferRow['modified_by'] = UserName
                    MaterialtransferRow['modified_on'] = todayDate()
                    MaterialtransferRow['cost_center_id'] = gatePassDetailsRow.cost_center_id                    
                    json.TransMaterialGatePassDetails.push(MaterialtransferRow);
                  
                }
               

                // Check and add missing entries in TransMaterialGatePassReturnSummary
                for (let issueItem = 0; issueItem < materialGatePassDetailsData.length; issueItem++) {
                    const gatePassDetailsRow = materialGatePassDetailsData[issueItem];

                    json.TransMaterialGatePassReturnSummary.push({
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        financial_year: FINANCIAL_SHORT_NAME,
                        material_gate_pass_details_id: gatePassDetailsRow.material_gate_pass_details_id,
                        material_return_gate_pass_master_id: material_return_gate_pass_master_id,
                        material_return_gate_pass_details_id: gatePassDetailsRow.material_return_gate_pass_details_id,
                        gate_pass_no: gatePassNoComboRef.current.label,
                        return_gate_pass_no: lastGatePassNo,
                        outward_quantity: parseFloat(gatePassDetailsRow.outward_quantity),
                        cost_center_id: parseFloat(gatePassDetailsRow.cost_center_id),
                        inward_quantity: parseFloat(gatePassDetailsRow.inward_quantity),
                        pending_inward_quantity: parseFloat(gatePassDetailsRow.pending_inward_quantity),
                        product_material_id: gatePassDetailsRow.product_material_id,
                        material_name: gatePassDetailsRow.product_material_name,
                        material_code: gatePassDetailsRow.product_code,
                        inward_remark: gatePassDetailsRow.remark,
                        purpose: gatePassDetailsRow.purpose,
                        inward_date: dt_gate_pass_date,
                        created_by: UserName,
                        gate_pass_item_status: 'R',
                        modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                    });
                }

                json.commonIds.company_id = COMPANY_ID
                json.commonIds.keyForViewUpdate = keyForViewUpdate
                json.commonIds.return_gate_pass_no = keyForViewUpdate === 'add' ? lastGatePassNo : txt_return_gate_pass_no
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log('Material Gate Pass', json)

                const formData = new FormData();
                formData.append(`MaterialReturnGatePassData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MaterialGatePass/FnAddUpdateReturnRecord`, requestOptions);
                const responce = await apicall.json();

                console.log("response error: ", responce.data);
                if (responce.error === 0) {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {

                    setSuccMsg(responce.message);
                    setShowSuccessMsgModal(true);
                    if (responce.success == 1) {
                        setModalOrderDetails(` Gate Pass No: ${txt_return_gate_pass_no}`)
                    }
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }

    }


    // ------------------------------------------- set update data ----------------------------------------------------


    const FnCheckUpdateResponse = async () => {
        debugger
        setIsLoading(true)
        try {
            if (idList !== 'add') {
                setMaterialReturnGatePassNo(idList.gate_pass_no)
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MaterialGatePass/FnShowParticularReturnRecord/${parseInt(idList.material_return_gate_pass_master_id)}/${COMPANY_ID}`);
                const responce = await apiCall.json();
                console.log('Material gate pass  data: ', responce);

                let gatePassMasterdata = responce.MaterialGatePassMasterDetails;
                if (responce.MaterialGatePassReturnSummary !== null && responce.MaterialGatePassReturnSummary !== "") {
                    setReturnDataArraySummary(responce.MaterialGatePassReturnSummary);
                }
                setDataForPrint(gatePassMasterdata);

                if (gatePassMasterdata !== null && gatePassMasterdata !== "") {
                    setMaterialReturnGatePassNo(gatePassMasterdata.return_gate_pass_no)
                    setProductType(gatePassMasterdata.product_type_id)
                    setProductTypeName(gatePassMasterdata.product_type_name)
                    setSupplierBranchId(gatePassMasterdata.supplier_branch_id)
                    supplierComboRef.current.value = gatePassMasterdata.supplier_branch_id;
                    supplierComboRef.current.label = gatePassMasterdata.supplier_name;
                    await comboOnChange('Supplier')
                    setSuppliersStateId(gatePassMasterdata.supplier_branch_state_id)
                    await comboOnChange("SupplierState")
                    setSuppliersCityId(gatePassMasterdata.supplier_branch_city_id)
                    setSuppAddress(gatePassMasterdata.supplier_branch_address)
                    await FnSetExistingSupplierContactPersonIds(gatePassMasterdata.supplier_branch_contacts_id);
                    setGatePassDate(gatePassMasterdata.gate_pass_date)
                    setMaterialType(gatePassMasterdata.material_type)

                    setMaterialReturnGatePassChallanNo(gatePassMasterdata.challan_no)
                    setGatePassChallanDate(gatePassMasterdata.challan_date)

                    const gatePassList = [
                        { value: '', label: 'Select', },
                        { value: gatePassMasterdata.material_gate_pass_master_id, label: gatePassMasterdata.gate_pass_no }
                    ];
                    setGatePassNoOpt(gatePassList)
                    setGatePassNo(gatePassMasterdata.material_gate_pass_master_id)
                    gatePassNoComboRef.current.value = gatePassMasterdata.material_gate_pass_master_id;

                    cmb_department_ref.current.value = gatePassMasterdata.department_id;
                    setDepartmentId(gatePassMasterdata.department_id);
                    await comboOnChange("Department")
                    setSubDepartmentId(gatePassMasterdata.sub_department_id);
                    cmb_subdepartment_ref.current.value = gatePassMasterdata.sub_department_id;

                    setGatePassStatus(gatePassMasterdata.return_status)
                    setCheckerById(gatePassMasterdata.checker_by_id)
                    setCheckerByName(gatePassMasterdata.checker_by_name)
                    empCheckerComboRef.current.employee_id = gatePassMasterdata.checker_by_id
                    empCheckerComboRef.current.employee_name = gatePassMasterdata.checker_by_name
                    comboOnChange('ApprovedBy');
                    setApprovedById(gatePassMasterdata.returned_by_id)
                    setApprovedByName(gatePassMasterdata.returned_by_name)
                    empApprovedComboRef.current.employee_id = gatePassMasterdata.returned_by_id ?? UserId
                    empApprovedComboRef.current.employee_name = gatePassMasterdata.returned_by_name
                    setVehicleNo(gatePassMasterdata.vehicle_no)
                }

                if (responce.MaterialGatePassDetails !== null && responce.MaterialGatePassDetails !== "") {
                    const transformedData = responce.MaterialGatePassDetails.map((item) => ({
                        ...item,
                        product_code: item.material_code,
                        product_material_name: item.material_name,
                        cost_center_name: item.cost_center_name,
                        gate_pass_item_status: keyForViewUpdate === "approve" ? "A" : item.gate_pass_item_status,
                        pending_inward_quantity: item.pending_inward_quantity,
                        prev_inward_quantity: item.inward_quantity,

                        inward_quantity:
                            keyForViewUpdate === "view"
                                ? item.inward_quantity // Show the actual value in view mode
                                : item.gate_pass_item_status === "I"
                                    ? 0 // Set to 0 if status is "I"
                                    : item.gate_pass_item_status === "A"
                                        ? item.outward_quantity // Set actual value if status is "A"
                                        : item.inward_quantity,

                    }));
                    setMaterialGatePassDetailsData(transformedData);
                }

            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
        finally {
            setIsLoading(false)
        }
    }

    const materialGatePassContent = {
        company: {
            company: '',
            company_branch: '',
        },
        materialDetails: {

        },
        header: {

        },
        footer: {

        },
        items: [],
    };

    const printIssueSlip = async (openPrintModal) => {
        let MaterialMasterData = masterDataForPrint;
                                                               
        materialGatePassContent.materialDetails.gate_pass_no = MaterialMasterData.gate_pass_no
        materialGatePassContent.materialDetails.gate_pass_date = validateNumberDateInput.current.formatDateToDDMMYYYY(MaterialMasterData.return_gate_pass_date)
        materialGatePassContent.materialDetails.product_type_name = cmb_product_type_name

        //Company details
        materialGatePassContent.company.company = materialGatePassDetailsData[0].company_name
        materialGatePassContent.company.company_branch = materialGatePassDetailsData[0].company_branch_name

        materialGatePassContent.header.approved_date = dt_approve_date
        materialGatePassContent.header.material_type = MaterialMasterData.material_type_desc
        materialGatePassContent.header.vehicle_no = MaterialMasterData.vehicle_no
        materialGatePassContent.header.product_type_name = cmb_product_type_name
        materialGatePassContent.header.gate_pass_status = MaterialMasterData.gate_pass_status
        materialGatePassContent.header.service_provider_name = MaterialMasterData.service_provider_name
        materialGatePassContent.header.service_provider_add = MaterialMasterData.service_provider_add
        materialGatePassContent.header.approved_by_name = MaterialMasterData.returned_by_name
        materialGatePassContent.header.checker_by_name = MaterialMasterData.checker_by_name

        materialGatePassContent.header.supp_branch_name = MaterialMasterData.supp_branch_name
        materialGatePassContent.header.supp_contact_no = MaterialMasterData.supp_contact_no
        materialGatePassContent.header.state_name = MaterialMasterData.state_name
        materialGatePassContent.header.city_name = MaterialMasterData.city_name
        materialGatePassContent.header.supplier_branch_address = MaterialMasterData.supplier_branch_address
        materialGatePassContent.header.department_name = MaterialMasterData.department_name
        materialGatePassContent.header.sub_department_name = MaterialMasterData.sub_department_name
        materialGatePassContent.header.challan_no = MaterialMasterData.challan_no
        materialGatePassContent.header.challan_date = MaterialMasterData.challan_date


        for (let index = 0; index < materialGatePassDetailsData.length; index++) {
            const element = materialGatePassDetailsData[index];
            const detailsData = {
                sr_no: index + 1,
                financial_year: element.financial_year,
                company_id: element.company_id,
                company_branch_id: element.company_branch_id,
                product_material_id: element.product_material_id,
                material_code: element.material_code,
                material_name: element.material_name,
                outward_quantity: element.outward_quantity,
                outward_weight: element.outward_weight,
                cost_center_name: element.cost_center_name,
                inward_quantity: element.inward_quantity,
                inward_weight: element.inward_weight,
                pending_inward_quantity: element.pending_inward_quantity,
                gate_pass_item_status: element.gate_pass_item_status,
                product_type_id: element.product_type_id,
                product_type_name: element.product_type_name,
                rate: element.rate,
                remark: element.remark,
                purpose: element.purpose,
                
            }
            materialGatePassContent.items.push(detailsData)
            console.log(detailsData);
        }


        if (openPrintModal) {
            debugger
            navigator('/Invoice', { state: { invoiceData: materialGatePassContent, navigationLink: '/Transactions/TPurchaseOrder/MaterialReturnGatePass/FrmMaterialReturnGatePassEntry', invoiceType: 'RMGP', title: 'Requisition/Issue', idList: idList, keyForViewUpdate: keyForViewUpdate } })
        } else {
            return renderToString(<ReturnMaterialGatePass invoiceContent={materialGatePassContent} />);
        }
    };


    const rendeReturnSummaryTbl = useMemo(() => {

        return <>
            <Table className="erp_table " id='po-schedule-table' bordered striped >
                <thead className="erp_table_head">
                    {returnDataArraysummary?.length > 0 && (
                        <>
                            <tr>
                                <th className="erp_table_th">Material Name</th>
                                <th className="erp_table_th">Outward Quantity</th>
                                <th className="erp_table_th">Inward Quantity</th>
                                <th className="erp_table_th">Pending Quantity</th>
                                <th className="erp_table_th">Inward Date</th>
                                <th className="erp_table_th">Purpose</th>
                                <th className="erp_table_th">Remark</th>

                            </tr>
                        </>
                    )}
                </thead>
                <tbody>
                    {
                        returnDataArraysummary?.length > 0 &&
                        returnDataArraysummary.map((scheduleItem, Index) => (
                            <tr rowIndex={Index} key={scheduleItem.id}>
                                <td className="erp_table_td">{scheduleItem.material_name}</td>
                                <td className="erp_table_td">{scheduleItem.outward_quantity}</td>
                                <td className="erp_table_td">{scheduleItem.inward_quantity}</td>
                                <td className="erp_table_td">{scheduleItem.pending_inward_quantity}</td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(scheduleItem.inward_date)}</td>
                                <td className="erp_table_td">{scheduleItem.purpose}</td>
                                <td className="erp_table_td">{scheduleItem.inward_remark}</td>
                            </tr>
                        ))
                    }

                </tbody>
            </Table>
        </>

    }, [returnDataArraysummary]);
    const handleScheduleData = (currentRowData, event) => {

        const clickedColName = event.target.getAttribute("Headers");
        const enteredValue = event.target.value;
        const focusedInputField = document.querySelector(`#${event.target.id}`);

        const arrayIndex = parseInt(
            event.target.parentElement.parentElement.getAttribute("rowIndex")
        );
        delete focusedInputField.parentElement.dataset.tip;
        // Update the field based on column name
        switch (clickedColName) {
            case "outward_quantity":
                currentRowData[clickedColName] = enteredValue || "";
                currentRowData.pending_inward_quantity = enteredValue
                    ? parseFloat(enteredValue) - parseFloat(currentRowData.inward_quantity || 0)
                    : 0;
                break;

            case "inward_quantity":
                const outwardQuantity = parseFloat(currentRowData.outward_quantity || 0);
                const inwardQuantity = parseFloat(enteredValue || 0);
                if (inwardQuantity > outwardQuantity) {
                    event.target.parentElement.dataset.tip = `Inward Quantity cannot be greater than Outward Quantity (${outwardQuantity})!`;
                    return; // Stop execution if validation fails
                }
                delete focusedInputField.parentElement.dataset.tip;
                currentRowData[clickedColName] = enteredValue;
                currentRowData.pending_inward_quantity = outwardQuantity - (inwardQuantity + parseFloat(prev_inward_quantity))
                break;

            case "inward_date":
            case "inward_remark":
                currentRowData[clickedColName] = enteredValue;
                break;

            default:
                return; // Exit if no matching case
        }


        setReturnDataArray(prevState => {
            const updatedData = prevState.map(item => {
                if (item.id === currentRowData.id) {
                    // Handle numeric and date values properly
                    if (clickedColName === "inward_quantity" || clickedColName === "outward_quantity") {
                        item[clickedColName] = parseFloat(enteredValue) || 0;
                    } else if (clickedColName === "inward_date") {
                        item[clickedColName] = enteredValue; // Store date as string
                    } else {
                        item[clickedColName] = enteredValue;
                    }
                }
                return { ...item };
            });

            // Calculate total inward & outward quantity
            const totalInward = updatedData.reduce((sum, item) => sum + (parseFloat(item.inward_quantity) || 0), 0);
            const totalOutward = updatedData.reduce((sum, item) => sum + (parseFloat(item.outward_quantity) || 0), 0);

            setTotalInwardQuantity(totalInward);
            setTotalOutwardQuantity(parseFloat(currentRowData['outward_quantity']))
            setRemainingQuantity(parseFloat(currentRowData['outward_quantity']) - (totalInward));

            return updatedData;
        });
    };

    const addReturnRow = () => {
        setReturnDataArray((prevReturn) => [
            ...prevReturn,
            {
                id: returnRowcount,
                product_material_id: materialId,
                product_material_code: materialCode,
                product_material_name: materialName,
                outward_quantity: totalOutwardQuantity,
                inward_quantity: 0,
                inward_date: '',
                inward_remark: '',

            },
        ]);
        setReturnRowcount(returnRowcount + 1);
    };

    const removeReturnRow = (id) => {
        setReturnDataArray((prevReturn) => prevReturn.filter((schedule) => schedule.id !== id));
    };

    function addReturnMaterial() {

        let checkIsValidated = validateReturnSummary();
        if (checkIsValidated === true) {
            // Ensure the table exists in the DOM before accessing it
            const table = document.getElementById('scheduleTable');
            const tbody = table.querySelector('tbody');
            const trElements = tbody.querySelectorAll('tr');
            let updatedpurchaseOrdSummary = [];
            // Ensure updatedScheduleDataArray is a copy of the existing data
            let updatedReturnSummaryData = [...returnDataArray];

            // Loop over the rows and gather updated data
            for (let count = 0; count < trElements.length; count++) {
                const product_material_id = materialId;
                const material_gate_pass_details_id = updatedReturnSummaryData[count]['material_gate_pass_details_id'] ||
                    updatedReturnSummaryData.find(item => item.product_material_id === product_material_id)?.material_gate_pass_details_id;
                const outward_quantity = updatedReturnSummaryData[count]['outward_quantity'];
                const inward_quantity = updatedReturnSummaryData[count]['inward_quantity'];
                const inward_date = updatedReturnSummaryData[count]['inward_date'];
                const inward_remark = updatedReturnSummaryData[count]['inward_remark'];
                const pending_inward_quantity = parseFloat(outward_quantity) - parseFloat(inward_quantity)

                const returnData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    material_gate_pass_details_id: material_gate_pass_details_id,
                    gate_pass_no: gatePassNoComboRef.current.label,
                    return_gate_pass_no: txt_return_gate_pass_no,
                    outward_quantity: parseFloat(outward_quantity),
                    inward_quantity: parseFloat(inward_quantity),
                    pending_inward_quantity: parseFloat(pending_inward_quantity),
                    product_material_id: product_material_id,
                    material_name: materialName,
                    material_code: materialCode,
                    inward_remark: inward_remark,
                    inward_date: inward_date,
                    created_by: UserName,

                    modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                }

                // Add the new data to the updatedScheduleDataArray
                updatedpurchaseOrdSummary.push(returnData);
            }

            // Update the state with the updated array
            setReturnDataArraySummary((prevReturn) => {
                // Check if the updated data already exists, if not, append it
                const newData = updatedpurchaseOrdSummary.filter(item =>
                    !prevReturn.some(existingItem => existingItem.product_material_id === item.product_material_id && existingItem.inward_date === item.inward_date)  // Assuming each item has a unique 'id'
                );

                return [...prevReturn, ...newData];
            });


            setMaterialGatePassDetailsData(prevData => {
                return prevData.map(item => {
                    if (item.product_material_id === materialId) {
                        return {
                            ...item,
                            inward_quantity: parseFloat(totalInwardQuantity - prev_inward_quantity),
                            pending_inward_quantity: parseFloat(remainingQuantity),  // Update inward_quantity with the new value
                        };
                    }
                    return item;
                });
            });
            localStorage.setItem('purchaseOrdScheduleData', JSON.stringify(updatedpurchaseOrdSummary));

            // Optionally, reset the schedules (if needed)
            setSchedules(false);
        }

        console.log(checkIsValidated);
    }

    const validateReturnSummary = () => {
        let result = true;
        let addedQty = 0;
        let errorMessage = '';

        // Loop through each schedule item and validate
        for (let scheduleItem of returnDataArray) {
            // Check if outward_quantity is a valid number
            if (!scheduleItem.outward_quantity || isNaN(scheduleItem.outward_quantity) || parseInt(scheduleItem.outward_quantity) <= 0) {
                errorMessage = `Outward Quantity for ${scheduleItem.product_material_name} is invalid or missing.`;
                result = false;
                break;
            }

            // Check if inward_quantity is a valid number
            if (!scheduleItem.inward_quantity || isNaN(scheduleItem.inward_quantity) || parseInt(scheduleItem.inward_quantity) <= 0) {
                errorMessage = `Inward Quantity for ${scheduleItem.product_material_name} is invalid or missing.`;
                result = false;
                break;
            }

            // Check if inward_date is valid (date format check)
            if (!scheduleItem.inward_date || isNaN(Date.parse(scheduleItem.inward_date))) {
                errorMessage = `Inward Date for ${scheduleItem.product_material_name} is invalid.`;
                result = false;
                break;
            }

            // Add any custom validation logic here (e.g., inward_quantity should not exceed outward_quantity)
            if (parseFloat(scheduleItem.inward_quantity) > parseFloat(scheduleItem.outward_quantity)) {
                errorMessage = `Inward Quantity for ${scheduleItem.product_material_name} cannot exceed Outward Quantity.`;
                result = false;
                break;
            }

            // Accumulate the quantity for total comparison
            addedQty += parseInt(scheduleItem.inward_quantity);
        }

        // Check if total scheduled quantity is valid
        if (result) {

            const totalQuantity = totalOutwardQuantity;

            if (totalOutwardQuantity < addedQty) {
                errorMessage = `Inward Quantity cannot be greater than Remaining Quantity (${totalOutwardQuantity - prev_inward_quantity})...!`;
                result = false; // Stop execution if validation fails
            }
            if (addedQty > totalQuantity) {
                errorMessage = `Inward quantity cannot be more than Outward Quantity of ${totalQuantity}.`;
                result = false;
            }
        }

        // Show error message if validation fails
        if (!result) {
            setErrMsg(errorMessage);
            setShowErrorMsgModal(true); // Trigger the error modal to show the message
        }

        return result;
    };

    const refreshData = async (key) => {

        switch (key) {
            case 'get_supplier':
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "supp_branch_state_id", "supp_branch_city_id",
                    "state_name", "city_name"
                ];
                globalQuery.table = "cmv_supplier_branch";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "supp_branch_type", operator: "=", value: "Supplier" });
                const suppliersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                const suppliers = [
                    { value: '', label: 'Select', supp_branch_state_id: '', supp_branch_city_id: '', state_name: '', city_name: '', },
                    { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '' },
                    ...suppliersApiCall.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, })),
                ];
                setSupplierList(suppliers);

                break;

            default:
                break;
        }
    }
    const renderSupplierContactTable = useMemo(() => {
        return <Datatable data={SuppContactDetails} columns={SuppContactDetailsCols} />
    }, [SuppContactDetails, SuppContactDetailsCols])

    function toggleChkAllBoxes(key) {
        switch (key) {
            case 'selectAllSuppContact':
                $('.selectSuppContactPerson').prop('checked', $('#selectAllSuppContact').is(":checked"));
                break;
            case 'PartiallySuppContactSelection':
                $('#selectAllSuppContact').prop('checked', $('input:checkbox.selectSuppContactPerson:checked').length === $('input:checkbox.selectSuppContactPerson').length);
                break;
        }
    }

    const FnSetFieldsByUserAccess = () => {
        if (keyForViewUpdate != null) {
            switch (keyForViewUpdate) {
                case 'view':
                    $('input[name="selectSuppContactPerson"]').prop('disabled', true);
                    $('input[name="selectAllSuppContact"]').prop('disabled', true);
                    break;
                case 'approve':
                    $('input[name="selectSuppContactPerson"]').prop('disabled', true);
                    $('input[name="selectAllSuppContact"]').prop('disabled', true);
                    break;
                default: break;
            }
        }
    }

    const FnGetSupplierContacts = async (SelectedSupplierBranch) => {

        if (SelectedSupplierBranch !== '' || SelectedSupplierBranch !== '0') {
            resetGlobalQuery();
            globalQuery.columns = ["supplier_id", "supplier_branch_name", "supp_branch_id", "supplier_contact_id",
                "supp_contact_person", "supp_contact_no", "supp_alternate_contact", "supp_email_id", "supp_alternate_EmailId", "supp_designation"]

            globalQuery.table = "cmv_supplier_contacts";
            globalQuery.conditions.push({ field: "supp_branch_id", operator: "=", value: SelectedSupplierBranch });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

            if (getContactDtlsApiCall.length !== 0) {
                let column = [];
                let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                    if (colKey === 0) {
                        column.push({
                            Headers: <div className="col-1"><input type='checkbox' class="me-1" name="selectAllSuppContact" id="selectAllSuppContact" onClick={(e) => { toggleChkAllBoxes("selectAllSuppContact"); }} />Select</div>,
                            accessor: "Action",
                            Cell: row => (
                                <div className="text-center">
                                    <input type='checkbox' class="selectSuppContactPerson" name="selectSuppContactPerson" id={'selectSuppContactPerson_' + row.original.supplier_contact_id} contact_personId={row.original.supplier_contact_id} value={row.original.supplier_contact_id} />
                                </div>
                            ),
                        });
                    }
                    if (!columnHeads[colKey].includes('supplier_id') && !columnHeads[colKey].includes('supplier_contact_id') && !columnHeads[colKey].includes('supp_branch_id')) {
                        column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                    }
                }
                setSuppContactDetailsCols(column);
                setSuppContactDetails(getContactDtlsApiCall);

                const supplierContactId = getContactDtlsApiCall[0].supplier_contact_id;
                setSupplierId(getContactDtlsApiCall[0].supplier_id)
                $('input[type="checkbox"][id="selectSuppContactPerson_' + supplierContactId + '"]').prop('checked', true);

                if (getContactDtlsApiCall.length === 1 && document.querySelector('.selectSuppContactPerson').checked) {
                    toggleChkAllBoxes('PartiallySuppContactSelection'); // Check all checkboxes by default
                }
            } else {
                setSuppContactDetailsCols([]);
                setSuppContactDetails([]);
            }
        }
        // Onchange event listener for the customer contact Selection
        $('.selectSuppContactPerson').on('change', function () {
            toggleChkAllBoxes('PartiallySuppContactSelection');
        });

    }

    const FnGetSupplierContactPersonIds = async () => {
        let supplierContactPersonsIds = '';
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            supplierContactPersonsIds += $(this).val() + ":";
        });
        return supplierContactPersonsIds.replace(/:$/, '');
    }

    const FnSetExistingSupplierContactPersonIds = async (contactIds) => {
        const supplierContactPersonsIds = contactIds.split(':');
        supplierContactPersonsIds.forEach(function (contactId, index) {
            $('#selectSuppContactPerson_' + supplierContactPersonsIds[index]).prop('checked', true);
        });
        toggleChkAllBoxes('PartiallySuppContactSelection');
    }


    // ------------------------ Changes for the Searchable Combos. --------------------------

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'>Return Material Gate Pass{actionType}</label>
                        </div>
                        <form id="gate_pass_form_id">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Product Type<span className="required">*</span> </Form.Label>
                                        </div>

                                        <div className='col-sm-8 col-12'>
                                            <select id="cmb_product_type"
                                                className="form-select form-select-sm"
                                                value={cmb_product_type}
                                                placeholder="Search for Product Type"
                                                onChange={(e) => {
                                                    setProductType(e.target.value)
                                                    comboOnChange("cmb_product_type")
                                                    validateFields();
                                                }}
                                                disabled={!['add'].includes(keyForViewUpdate)}>
                                                {productTypeOptions.map((typeOpt) => <option shortName={typeOpt.product_type_short_name} value={typeOpt.value}>{typeOpt.label}</option>)}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_product_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Return Gate Pass No & Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <div className="row">
                                                <div className='col-12 col-md-6 pe-md-0'>
                                                    <Form.Control type="text" id="txt_return_gate_pass_no" className="erp_input_field" value={txt_return_gate_pass_no} optional='optional' disabled />
                                                </div>

                                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_gate_pass_date'
                                                        className="erp_input_field" value={dt_gate_pass_date}
                                                        onChange={e => {
                                                            setGatePassDate(e.target.value);
                                                            validateFields();
                                                        }}
                                                        min={currentDate}
                                                        Disabled={keyForViewUpdate === "add" || keyForViewUpdate === "update" ? false : true}
                                                    />
                                                    <MDTypography variant="button" id="error_dt_gate_pass_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Supplier <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col mb-1'>
                                            <Select ref={supplierComboRef}
                                                options={supplierList}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_supplier_branch_id" // Provide the ID for the input box
                                                value={supplierList.find(option => option.value === cmb_supplier_branch_id)}
                                                onChange={(selectedOpt) => {
                                                    setSupplierBranchId(selectedOpt.value);
                                                    supplierComboRef.current = selectedOpt;
                                                    comboOnChange("Supplier");
                                                    validateFields();
                                                }}
                                                placeholder="Search for a supplier..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_supplier_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { refreshData('get_supplier') }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Supplier State & city</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_supplier_state_id" className="form-select form-select-sm" value={cmb_supplier_state_id} optional='optional' disabled>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {stateList?.map(SupplierState => (
                                                    <option value={SupplierState.field_id}>{SupplierState.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                        <div className='col'>
                                            <select id="cmb_supplier_city_id" className="form-select form-select-sm" value={cmb_supplier_city_id} optional='optional' disabled >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {cityList?.map(Suppliercity => (
                                                    <option value={Suppliercity.field_id}>{Suppliercity.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Supplier Address</Form.Label>
                                        </div>
                                        <div className="col-sm-8">
                                            <Form.Control as="textarea" rows={5} maxlength="500" id='txt_supp_address' className="erp_input_field" value={suppAddress} onChange={(e) => { setSuppAddress(e.target.value); validateFields(); }} disabled optional="optional" />
                                            <MDTypography variant="button" id="error_txt_supp_address" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Gate Pass No.<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-8 mt-1">
                                            <Select ref={gatePassNoComboRef}
                                                options={gatePassNOopt}
                                                isDisabled={!['add'].includes(keyForViewUpdate)}
                                                inputId="cmb_gate_PassNo" // Provide the ID for the input box
                                                value={gatePassNOopt.find(option => option.value === cmb_gate_PassNo) || null}
                                                onChange={(selectedOpt) => {
                                                    setGatePassNo(selectedOpt.value);
                                                    gatePassNoComboRef.current = selectedOpt;
                                                    showGatePassDetails(selectedOpt.value);
                                                }}
                                                placeholder="Search for Gate Pass No..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_gate_PassNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                   


                                    {!(keyForViewUpdate === "add" || keyForViewUpdate === "update") && cmb_gate_pass_status === "A" &&
                                        <div className="row">
                                            <div className='col-sm-4 col-12'>
                                                <Form.Label className="erp-form-label">Approve Date<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-8 col-12">
                                                <Form.Control type="date" id='dt_gate_pass_date'
                                                    className="erp_input_field" value={dt_approve_date}
                                                    Disabled={true}
                                                />
                                                <MDTypography variant="button" id="error_dt_gate_pass_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>}
                                </div>
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label"> Challan No & Date</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <div className="row">
                                                <div className='col-12 col-md-6 pe-md-0'>
                                                    <Form.Control type="text" id="challan_no" className="erp_input_field"  placeholder="Enter Challan No." value={challan_no} onChange={e => setMaterialReturnGatePassChallanNo(e.target.value)} optional='optional' />
                                                </div>

                                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='challan_date' className="erp_input_field" value={challan_date}
                                                        onChange={e => {
                                                            setGatePassChallanDate(e.target.value);   validateFields();
                                                        }}
                                                        max={currentDate}
                                                        Disabled={keyForViewUpdate === "add" || keyForViewUpdate === "update" ? false : true}
                                                    />
                                                    <MDTypography variant="button" id="error_dt_gate_pass_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Select ref={cmb_department_ref}
                                                options={departmentOptions}
                                                isDisabled={true}
                                                inputId="cmb_department_id"
                                                value={departmentOptions.length > 0 ? departmentOptions.find(option => option.value === cmb_department_id) : null}
                                                onChange={(selectedOpt) => {
                                                    cmb_department_ref.current = selectedOpt;
                                                    setDepartmentId(selectedOpt.value);
                                                    comboOnChange('Department');
                                                    validateFields();

                                                }}
                                                placeholder="Search for a Department"
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row  mb-1'>

                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Sub-Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Select ref={cmb_subdepartment_ref}
                                                options={subDepartmentOptions}
                                                isDisabled={true}
                                                inputId="cmb_subdepartment_id" // Provide the ID for the input box
                                                value={subDepartmentOptions.length > 1 ? subDepartmentOptions.find(option => option.value === cmb_subdepartment_id) : null}
                                                onChange={(selectedOpt) => {
                                                    cmb_subdepartment_ref.current = selectedOpt;
                                                    setSubDepartmentId(selectedOpt.value);
                                                    comboOnChange("SubDepartment");
                                                    validateFields();
                                                }}
                                                placeholder="Search for a Sub-Department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_subdepartment_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Return Status & Vehicle No.</Form.Label>
                                        </div>
                                        <div className="col-sm-4">
                                            <Form.Control as="select"
                                                id='cmb_gate_pass_status'
                                                className="erp_input_field"
                                                value={cmb_gate_pass_status}
                                                Disabled='true'
                                                style={{ paddingBottom: "0px" }}
                                                onChange={(e) => setGatePassStatus(e.target.value)}>
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                                <option value="I">Partial-Return</option>
                                                <option value="R">Return</option>
                                            </Form.Control>
                                            <MDTypography
                                                variant="button"
                                                id="error_cmb_gate_pass_status"
                                                className="erp_validation error-msg"
                                                fontWeight="regular"
                                                color="error"
                                                style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                        <div className="col-sm-4">
                                            <Form.Control type="text" id='txt_vehicle_no' className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); validateFields(); }} optional="optional" maxlength="500" />
                                            <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Checker Name<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12 mb-1'>
                                            <Select ref={empCheckerComboRef}
                                                options={employeeOptions}
                                                isDisabled={!["add", "update"].includes(keyForViewUpdate)}
                                                inputId="cmb_checker_by_id" // Provide the ID for the input box
                                                value={employeeOptions.find(option => option.value == cmb_checker_by_id)}
                                                onChange={(selectedOpt) => {
                                                    setCheckerById(selectedOpt.value);
                                                    empCheckerComboRef.current = selectedOpt;
                                                    validateFields();
                                                    comboOnChange('CheckerBy');
                                                }}
                                                placeholder="Search for a employee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />

                                            <MDTypography variant="button" id="error_cmb_checker_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                    {/* {(keyForViewUpdate === "add" || keyForViewUpdate === "view") && ( */}
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Returned By<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10 mb-1'>
                                            <Select ref={empApprovedComboRef}
                                                options={employeeOptions}
                                                isDisabled={true}
                                                inputId="cmb_approved_by_id" // Provide the ID for the input box
                                                value={employeeOptions.find(option => option.value == cmb_approved_by_id)}
                                                onChange={(selectedOpt) => {
                                                    setApprovedById(selectedOpt.value);
                                                    empApprovedComboRef.current = selectedOpt;
                                                    validateFields();
                                                    comboOnChange('ApprovedBy');
                                                }}
                                                placeholder="Search for a employee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                    {/* )} */}

                                    <div className="row mt-1">
                                        <Accordion defaultActiveKey="1" >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="erp-form-label-md">Supplier's Contact Details</Accordion.Header>
                                                <Accordion.Body >
                                                    {
                                                        cmb_supplier_branch_id !== '' && cmb_supplier_branch_id !== "0"
                                                            ? <>
                                                                <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>
                                                                    <div className={` d-flex justify-content-end align-items-center`}>
                                                                        <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                            localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                            localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: cmb_supplier_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                        }
                                                                        }>  <Link className={'text-light'} to={{ pathname: "/Masters/FrmSupplier" }} target="_blank" >Add</Link></MDButton>
                                                                        <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetSupplierContacts(cmb_supplier_branch_id) }}>Refresh</MDButton>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    {SuppContactDetails.length !== 0 ?
                                                        <>
                                                            {renderSupplierContactTable}
                                                        </>
                                                        : <div className='row'>
                                                            <div className="col-12">
                                                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                                    No Records Found...
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion >
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openIssueDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={(e) => setOpenIssueDetailsAccord(!openIssueDetailsAccord)} className="erp-form-label-md"> Return Material Details</Accordion.Header>
                            <Accordion.Body >
                                <div className="table-responsive erp_table_scroll">
                                    {materialGatePassDetailsData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> :
                                        <div className='row  text-center'>
                                            <div className="col-12 ">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                    {returnDataArraysummary?.length > 0 ? (
                        <>
                            <hr />
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="purchaseOrderSchedules">
                                    <Accordion.Header className="erp-form-label-md">Material Return Summary</Accordion.Header>
                                    <Accordion.Body >
                                        <div className="table-responsive erp_table_scroll">
                                            {rendeReturnSummaryTbl}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                    ) : null}

                </>
                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton className={`erp-gb-button erp_MLeft_btn ${(keyForViewUpdate !== 'add' && keyForViewUpdate !== 'update') ? '' : 'd-none'}`}
                        variant="button"
                        fontWeight="regular"
                        id="printbtn_id"
                        onClick={() => { printIssueSlip(true); }} >
                        Print <FiDownload className="erp-download-icon-btn" />
                    </MDButton>

                    <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate != 'view' ? 'display' : 'd-none'}`} variant="button" onClick={() => { addMaterialGatePass() }}
                        fontWeight="regular"> {actionLabel}</MDButton>

                </div >

                <Modal
                    size="lg"
                    className="erp_document_Form"
                    show={showSChedules}
                    onHide={handleCloseReturnSummaryPopup}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title className="erp_modal_title">Add Material Details</Modal.Title>
                        <button
                            type="button"
                            className="erp-modal-close btn-close"
                            aria-label="Close"
                            onClick={handleCloseReturnSummaryPopup}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mb-0">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-3 erp-form-label-md-lg">Material Name:</dt>
                                    <dd className="col-sm-12 col-md-9 erp-form-label" id='productMatName'>{materialName}</dd>
                                </dl>
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-3 erp-form-label-md-lg">Prev.InWard QTY:</dt>
                                    <dd className="col-sm-12 col-md-9 erp-form-label">{prev_inward_quantity}</dd>
                                </dl>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg"> Outward Quantity:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatQty'>{totalOutwardQuantity}</dd>
                                </dl>

                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">Inward Quantity:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatTotalWeight'>{totalInwardQuantity}</dd>
                                </dl>

                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">Pending Quantity:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatTotalWeight'>{remainingQuantity}</dd>
                                </dl>

                            </div>


                        </div>

                        <div className="row table-responsive">
                            <Table className="erp_table" id='scheduleTable' bordered striped >
                                <thead className="erp_table_head">
                                    <tr>
                                        <th className={`erp_table_th  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>Action</th>
                                        <th className="erp_table_th">Outward Quantity</th>
                                        <th className="erp_table_th">Inward Quantity</th>
                                        <th className="erp_table_th">Pending Quantity</th>
                                        <th className="erp_table_th">Inward Date</th>
                                        <th className="erp_table_th">Remark</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        returnDataArray.map((scheduleItem, Index) => (
                                            <tr rowIndex={Index} key={scheduleItem.id}>
                                                <td
                                                    className={`erp_table_td ${keyForViewUpdate !== 'view' &&
                                                        keyForViewUpdate !== 'approve' &&
                                                        keyForViewUpdate !== 'delete'
                                                        ? 'display'
                                                        : 'd-none'
                                                        }`}
                                                    disabled={scheduleItem.gate_pass_item_status === "R"}
                                                >
                                                    <td style={{ width: '100px' }}><IoRemoveCircleOutline className={`erp_trRemove_icon ${scheduleItem.gate_pass_item_status === "R" ? 'd-none' : ''}`} onClick={() => removeReturnRow(scheduleItem.id)} /> <IoAddCircleOutline className='erp_trAdd_icon disableClass' onClick={() => addReturnRow()} /></td>
                                                </td>
                                                <td className="erp_table_td">{scheduleItem.outward_quantity}</td>

                                                <td className="erp_table_td text-end">
                                                    <input
                                                        type="text"
                                                        id={`inward_quantity_return_${scheduleItem.product_material_id}`}
                                                        className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                                        value={scheduleItem.inward_quantity || ""}
                                                        onChange={(e) => handleScheduleData(scheduleItem, e)}
                                                        Headers="inward_quantity"
                                                        disabled={scheduleItem.gate_pass_item_status === "R"}
                                                    />
                                                </td>
                                                <td className="erp_table_td text-end">
                                                    {scheduleItem.pending_inward_quantity}
                                                </td>
                                                <td className="erp_table_td text-end">
                                                    <input
                                                        type="date"
                                                        id={`inward_date_return_${scheduleItem.product_material_id}`}
                                                        className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                                        value={scheduleItem.inward_date || ""}
                                                        onChange={(e) => handleScheduleData(scheduleItem, e)}
                                                        Headers="inward_date"
                                                        disabled={scheduleItem.gate_pass_item_status === "R"}
                                                    />
                                                </td>
                                                <td className="erp_table_td">
                                                    <input
                                                        type="textarea"
                                                        id={`inward_remark_return_${scheduleItem.product_material_id}`}
                                                        className="erp_input_field mb-0"
                                                        value={scheduleItem.inward_remark || ""}
                                                        onChange={(e) => handleScheduleData(scheduleItem, e)}
                                                        Headers="inward_remark"
                                                        maxLength={500}
                                                        disabled={scheduleItem.gate_pass_item_status === "R"}

                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>

                            </Table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='erp-modal-footer'>
                        <MDButton type="button" className="erp-gb-button" variant="button"
                            fontWeight="regular" onClick={() => addReturnMaterial()}>Return</MDButton>
                    </Modal.Footer>
                </Modal>
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
            </DashboardLayout >
        </>
    )
}

export default FrmMaterialReturnGatePassEntry;