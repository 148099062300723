import React, { useMemo } from 'react'
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

const WarpingOrderPlan = React.forwardRef(({ warpingProdPlanOrderContent }) => {
    debugger

    let companyDetails = warpingProdPlanOrderContent.company;
    let warpingTransData = warpingProdPlanOrderContent.warpingTransData;
    let warpingDetailsData = warpingProdPlanOrderContent.warpingDetailsData;
    let warpingStockDetails = warpingProdPlanOrderContent.warpingStockDetails;
    let warpingMaterialsPropertiesData = warpingProdPlanOrderContent.warpingMaterialsPropertiesData;
    console.log('warpingMaterialsPropertiesData:- ', warpingMaterialsPropertiesData);
    let warpingMaterialsPropertiesColumns = warpingProdPlanOrderContent.warpingMaterialsPropertiesColumns;
    // let warpingSalesOrderData = warpingProdPlanOrderContent.warpingSalesData;
    let warpingBeamDetails = warpingProdPlanOrderContent.warpingBeamDetails;

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                    <div className="col-sm-1 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="165" height="auto" mt={1} />
                    </div>
                    <div className="col-sm-11 transformed mt-1">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {companyDetails.company_name}
                        </div>

                        <div className='erp-invoice-print-label-lg text-center'>
                            Warping Production Plan
                        </div>
                    </div>
                </div>

                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-5'>
                        <dt className='erp-invoice-print-label-lg' style={{ fontSize: '11px' }}> Product Name : {warpingTransData.productMaterialName} </dt>
                    </div>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-lg text-center' style={{ fontSize: '11px' }}>Set No : {warpingTransData.setNo}</dt>
                    </div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-lg text-end' style={{ fontSize: '11px' }}>Date : {warpingTransData.date}</dt>
                    </div>
                </div>

                <div className='row border-bottom border-top border-dark p-0 m-0'>
                    <div className='row'>
                        <div className='col-sm-4'>
                            {/* <div className='row'>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg '> Date :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.date} </dt>
                            </div> */}

                            {/* <div className='row'>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg '> Production Material :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.productMaterial} </dt>
                            </div> */}

                            <div className='row'>
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg '>Warping Order No :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.orderNo} </dt>
                            </div>

                            <div className='row'>
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg '> Customer Order No :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.customer_order_no} </dt>
                            </div>
                            <div className='row'>
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg '> Sales Order No :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.sales_order_no} </dt>
                            </div>

                        </div>


                        <div className='col-sm-4'>

                            <div className='row'>
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg '> Sales Order Date:</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.sales_order_date} </dt>
                            </div>

                            <div className='row'>
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg '> Schedule Quantity :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.scheduleQuantity} </dt>
                            </div>

                            <div className='row'>
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg '> Order Mtr :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.order_quantity} </dt>
                            </div>

                        </div>

                        <div className='col-sm-4'>
                            <div className='row'>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg '> Sort No :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.sortNo} </dt>
                            </div>

                            <div className='row'>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg '> Creels :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.creels} </dt>
                            </div>

                            <div className='row'>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg '> Set Length :</dt>
                                <dt className='col-sm-auto erp-invoice-print-label-md-lg '>{warpingTransData.setLength} </dt>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const scheduleProductionDetails = () => {
        // Calculate totals for each column
        const totals = warpingBeamDetails.reduce((acc, creelDetail) => {
            acc.conePerCreel += parseFloat(creelDetail.no_of_cones || 0);
            acc.creelLength += parseFloat(creelDetail.creel_length || 0);
            acc.beamPerCreel += parseFloat(creelDetail.no_of_beams || 0);
            acc.creelWeight += parseFloat(creelDetail.creel_weight || 0);
            acc.setLength += parseFloat(creelDetail.set_length || 0);
            acc.actualNetWt += parseFloat(creelDetail.actual_net_wt || 0);
            acc.calculatedBottom += parseFloat(creelDetail.calculated_bottom || 0);
            return acc;
        }, {
            conePerCreel: 0,
            creelLength: 0,
            beamPerCreel: 0,
            creelWeight: 0,
            setLength: 0,
            actualNetWt: 0,
            calculatedBottom: 0
        });

        return <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='warpingDetailsTable' >
            {warpingBeamDetails.length !== 0 && (
                <thead className='tblHeaderContentHt'>
                    <tr>
                        <th className="erp_invoice_table_th text-center col-1" >Creel Name</th>
                        <th className="erp_invoice_table_th text-center col-1" >Count</th>
                        <th className="erp_invoice_table_th text-center col-1" >Cone/Creel</th>
                        <th className="erp_invoice_table_th text-center col-1" >Creel Length</th>
                        <th className="erp_invoice_table_th text-center col-1" >Beem/Creel</th>
                        <th className="erp_invoice_table_th text-center col-1" >Weight/Package</th>
                        <th className="erp_invoice_table_th text-center col-1" >Creel Weight</th>
                        <th className="erp_invoice_table_th col-1">Set Length</th>
                        <th className="erp_invoice_table_th col-1" >Actual Net Wt.</th>
                        <th className="erp_invoice_table_th col-1" >Calculated Bottom</th>
                    </tr>
                </thead>
            )}

            <tbody id='warpingbeamdetailstableId'>
                {
                    warpingBeamDetails.map((warpingbeamdetail, index) =>
                        <>
                            <tr className={`tblBodyTrHt-${index}`}>
                                <td className="erp_invoice_table_td">{warpingbeamdetail.creel_no}</td>
                                <td className="erp_invoice_table_td" >{warpingbeamdetail.production_count_name}</td>
                                <td className="erp_invoice_table_td text-center">{warpingbeamdetail.no_of_cones}</td>
                                <td className="erp_invoice_table_td text-end">{warpingbeamdetail.creel_length}</td>
                                <td className="erp_invoice_table_td text-end">{warpingbeamdetail.no_of_beams}</td>
                                <td className="erp_invoice_table_td text-end">{warpingbeamdetail.cone_per_wt}</td>
                                <td className="erp_invoice_table_td text-end">{warpingbeamdetail.creel_weight}</td>
                                <td className="erp_invoice_table_td text-end">{warpingbeamdetail.set_length} </td>
                                <td className="erp_invoice_table_td text-end"> {warpingbeamdetail.actual_net_wt}  </td>
                                <td className="erp_invoice_table_td text-end"> {warpingbeamdetail.calculated_bottom} </td>
                            </tr>
                        </>
                    )
                }
                {/* Total row */}
                <tr>
                    <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>Totals</td>
                    <td className="erp_invoice_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}></td>
                    <td className="erp_invoice_table_td text-end" style={{ fontWeight: 'bold' }}>{totals.conePerCreel.toFixed(2)}</td>
                    <td className="erp_invoice_table_td text-end" style={{ fontWeight: 'bold' }}>{totals.creelLength.toFixed(2)}</td>
                    <td className="erp_invoice_table_td text-end" style={{ fontWeight: 'bold' }}>{totals.beamPerCreel.toFixed(2)}</td>
                    <td className="erp_invoice_table_td"></td>
                    <td className="erp_invoice_table_td text-end" style={{ fontWeight: 'bold' }}>{totals.creelWeight.toFixed(2)}</td>
                    <td className="erp_invoice_table_td text-end" style={{ fontWeight: 'bold' }}>{totals.setLength.toFixed(2)}</td>
                    <td className="erp_invoice_table_td text-end" style={{ fontWeight: 'bold' }}>{totals.actualNetWt.toFixed(2)}</td>
                    <td className="erp_invoice_table_td text-end" style={{ fontWeight: 'bold' }}>{totals.calculatedBottom.toFixed(2)}</td>
                </tr>
            </tbody>
        </table>
    }

    return (
        <>
            <style>
                {`
                   @media print {
                     @page {
                        size: landscape; /* Changes to landscape orientation */
                    }
                 }
               `}
            </style>


            {/* <style>
                {`
                    @media print {
                                    #warpingDetailsTable {
                                            width: 100%;
                                    }
                    }
                `}
            </style> */}


            <div className=''>
                <div className="row p-2">
                    <div className="col-12">
                        <div className="container-invoice py-2">
                            <div id="content">

                                <div className="invoice p-0">
                                    {/*---------------------Warping Details Data---------------*/}
                                    <div className="row headerContentHt">
                                        {invoiceHeader}
                                    </div>

                                    {/* Sales Order Data */}
                                    {warpingMaterialsPropertiesData.length !== 0 ? <>
                                        <div className='row border border-top-0 border-bottom-0'>
                                            <label className="erp-form-label-md-lg">Product Properties </label>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">

                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='productBasedPropertiesTable' >
                                                            <thead className="tblHeaderContentHt">
                                                                <tr>
                                                                    {warpingMaterialsPropertiesColumns.map((column, index) => (
                                                                        <th className="erp_invoice_table_th" key={index}>{column}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody id='warpingdetailstableId'>
                                                                <tr >
                                                                    {warpingMaterialsPropertiesColumns.map((column, colIndex) => (
                                                                        <td key={colIndex} className="erp_invoice_table_td">
                                                                            {warpingMaterialsPropertiesData.map((row, rowIndex) => (
                                                                                <div key={rowIndex}>{row[column]}</div>
                                                                            ))}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : null}



                                    {/*----------- ----------Warping Details--------------- */}
                                    <div className='row border border-top-0 border-bottom-0'>
                                        <label className="erp-form-label-md-lg">Warping Details </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 px-0" >
                                            <div className="row p-0">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='warpingDetailsTable' >
                                                        {warpingDetailsData.length !== 0 && (
                                                            <thead className='tblHeaderContentHt'>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center col-1" >Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center col-1" >Material Code</th>
                                                                    <th className="erp_invoice_table_th text-center col-2" >Material Name</th>
                                                                    <th className="erp_invoice_table_th text-center col-1" >Count</th>
                                                                    {/* <th className="erp_invoice_table_th text-center col-1" >Stock <br></br>Weight</th>
                                                                    <th className="erp_invoice_table_th text-center col-1" >Stock <br></br> Quantity</th> */}
                                                                    <th className="erp_invoice_table_th text-center col-1" >Weight Per <br></br> Cone</th>
                                                                    <th className="erp_invoice_table_th text-center col-1" >No Of <br></br> Cones</th>
                                                                    <th className="erp_invoice_table_th text-center col-1" >Warping <br></br> Quantity(Kg)</th>
                                                                </tr>
                                                            </thead>
                                                        )}

                                                        <tbody id='warpingdetailstableId'>
                                                            {warpingDetailsData.map((warpingdetail, index) =>
                                                                <>
                                                                    <tr className={`tblBodyTrHt-${index}`}>
                                                                        <td className="erp_invoice_table_td">{index + 1}</td>
                                                                        <td className="erp_invoice_table_td text-end" >{warpingdetail.product_material_code}</td>
                                                                        <td className="erp_invoice_table_td" style={{ width: '45%' }}>{warpingdetail.product_material_name}</td>
                                                                        <td className="erp_invoice_table_td">{warpingdetail.production_actual_count}</td>
                                                                        {/* <td className="erp_invoice_table_td text-end">{warpingdetail.closing_balance_quantity}</td>
                                                                            <td className="erp_invoice_table_td text-end">{warpingdetail.closing_balance_weight}</td> */}
                                                                        <td className="erp_invoice_table_td text-end">{warpingdetail.cone_per_wt}</td>
                                                                        <td className="erp_invoice_table_td text-end">{warpingdetail.no_of_cones}</td>
                                                                        <td className="erp_invoice_table_td text-end">{warpingdetail.product_material_quantity}</td>
                                                                    </tr>
                                                                </>
                                                            )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* -------------- {Stock Details} ------------*/}
                                    {warpingStockDetails.length !== 0 ? <>
                                        <div className='row border border-dark'>
                                            <label className="erp-form-label-md-lg"> Raw Material Requisition </label>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='stockDetailsTable' >
                                                            {warpingStockDetails.length !== 0 && (
                                                                <thead className='tblHeaderContentHt'>
                                                                    <tr>
                                                                        <th className="erp_invoice_table_th text-center col-1">Sr No.</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Material Id</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Customer Name</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Supplier Name</th>
                                                                        <th className="erp_invoice_table_th text-center col-3" >Receipt No.</th>
                                                                        <th className="erp_invoice_table_th text-center col-3">Indent No</th>
                                                                        {/* <th className="erp_invoice_table_th text-center col-1" >Packing Name</th> */}
                                                                        <th className="erp_invoice_table_th text-center col-1" rowSpan={2}>Weight Per <br></br> Cone</th>
                                                                        <th className="erp_invoice_table_th text-center" rowSpan={2}>Stock <br></br> Weight</th>
                                                                        <th className="erp_invoice_table_th text-center" rowSpan={2}>Stock <br></br> Quantity</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowSpan={2}>No. Of<br></br> Cones</th>
                                                                        <th className="erp_invoice_table_th text-center" rowSpan={2}>Warping <br></br> Quantity(Kg)</th>
                                                                    </tr>
                                                                </thead>
                                                            )}

                                                            <tbody id='warpingdetailstableId'>
                                                                {
                                                                    warpingStockDetails.map((stockDetail, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td">{index + 1}</td>
                                                                                <td className="erp_invoice_table_td text-start">{stockDetail.product_material_code}</td>
                                                                                <td className="erp_invoice_table_td text-end">{stockDetail.customer_name}</td>
                                                                                <td className="erp_invoice_table_td text-end">{stockDetail.supplier_name}</td>
                                                                                <td className="erp_invoice_table_td text-start">{stockDetail.goods_receipt_no}</td>
                                                                                <td className="erp_invoice_table_td text-start">{stockDetail.indent_no}</td>
                                                                                {/* <td className="erp_invoice_table_td">{stockDetail.product_packing_name}</td> */}
                                                                                <td className="erp_invoice_table_td text-end">{stockDetail.cone_per_wt}</td>
                                                                                <td className="erp_invoice_table_td text-end">{stockDetail.closing_balance_weight}</td>
                                                                                <td className="erp_invoice_table_td text-end">{stockDetail.closing_balance_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{stockDetail.no_of_cones}</td>
                                                                                <td className="erp_invoice_table_td text-end">{stockDetail.warping_quantity}</td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : null}


                                    {/*----------- ----------Warping Order Beam Details--------------- */}
                                    <div className='row border border-top-0 border-bottom-0'>
                                        <label className="erp-form-label-md-lg"> Schedule Production Details </label>

                                    </div>
                                    <div className="row">
                                        <div className="col-12 px-0" >
                                            <div className="row p-0">
                                                <div className="table-responsive">
                                                    {scheduleProductionDetails()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
export default WarpingOrderPlan