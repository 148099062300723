import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from 'react'
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import MDTypography from 'components/MDTypography';
import ComboBox from 'Features/ComboBox';
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";
import MDButton from "components/MDButton";



function FrmCottonBalesDayWiseStockIssueReport() {
    const configConstants = ConfigConstants();


    const navigate = useNavigate();

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split("T")[0];

    const [dt_from_date, setFromDt] = useState(today);
    const [dt_to_date, setToDt] = useState(today);

    //Ref Hooks
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);


    //data hook
    const [cottonBaleWeightageStockReportData, setCottonBalesDailyIssueReport] = useState([]);
    const [headerDates, setHeaderDates] = useState({});

    //Returning to Dashboard
    const goBack = () => { navigate("/Dashboard") }

    //Validating Dates
    function isValidDate(dateString) {
        // Check if date is in the format DD-MM-YYYY
        const regex = /^(?<day>0[1-9]|[12][0-9]|3[01])-(?<month>0[1-9]|1[0-2])-(?<year>\d{4})$/;
        const match = regex.exec(dateString);

        if (!match) {
            console.log("Date format is incorrect:", dateString);
            return false; // If the format is not correct
        }

        const day = Number(match.groups.day);
        const month = Number(match.groups.month);
        const year = Number(match.groups.year);

        // Check for leap year if it's February
        if (month === 2) {
            const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
            if (day > 29 || (day === 29 && !isLeapYear)) {
                console.log("Invalid day for February:", day);
                return false; // Invalid day for February in a leap year
            }
        }

        // Validate months with 30 days (April, June, September, November)
        if ([4, 6, 9, 11].includes(month) && day > 30) {
            console.log("Invalid day for month with 30 days:", dateString);
            return false; // These months cannot have more than 30 days
        }

        // Create a date object with the given input
        const date = new Date(year, month - 1, day);
        if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
            console.log("Invalid date object created:", dateString, date);
            return false; // The date is invalid (like 32nd day, or 13th month)
        }

        console.log("Date is valid:", dateString);
        return true;
    }

    function convertToDDMMYYYY(dateString) {
        // Assuming the input is in the format YYYY-MM-DD
        const [year, month, day] = dateString.split('-');
        // Return the date in DD-MM-YYYY format
        return `${day}-${month}-${year}`;
    }

    function FnChkIsValidFilters() {

        let dt_from_date = $("#dt_from_date").val();  // Expecting input in YYYY-MM-DD format
        let dt_to_date = $("#dt_to_date").val();      // Expecting input in YYYY-MM-DD format

        if (dt_to_date === '') {
            $('#error_dt_to_date').text("Invalid To Date").show();
            return false
        } else if (dt_from_date === '') {
            $('#error_dt_from_date').text("Invalid From Date").show();
            return false;
        }

        // Convert both dates to DD-MM-YYYY format for validation
        dt_from_date = convertToDDMMYYYY(dt_from_date);
        dt_to_date = convertToDDMMYYYY(dt_to_date);

        let isValid = true;

        // Validate From Date
        if (!isValidDate(dt_from_date)) {
            $('#error_dt_from_date').text("Invalid From Date").show();
            isValid = false;
        } else {
            $('#error_dt_from_date').hide();
        }

        // Validate To Date
        if (!isValidDate(dt_to_date)) {
            $('#error_dt_to_date').text("Invalid To Date").show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }

        // Convert back to YYYY-MM-DD format for accurate date comparison
        const fromDate = new Date(dt_from_date.split('-').reverse().join('-'));
        const toDate = new Date(dt_to_date.split('-').reverse().join('-'));

        // Check if From Date is greater than To Date
        if (fromDate > toDate) {
            $('#error_dt_to_date').text("To Date cannot be smaller than From Date").show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }

        return isValid;
    }


    function generateDateRange(fromDate, toDate) {
        const dateObj = {};
        const [fromYear, fromMonth, fromDay] = fromDate.split("-").map(Number);
        const [toYear, toMonth, toDay] = toDate.split("-").map(Number);

        let startDate = new Date(fromYear, fromMonth - 1, fromDay);
        let endDate = new Date(toYear, toMonth - 1, toDay);

        if (startDate > endDate) return {}; // Return empty if range is invalid

        while (startDate <= endDate) {
            // Format date as dd-mm-yyyy
            const formattedDate = startDate.toLocaleDateString("en-GB").replace(/\//g, '-');
            dateObj[formattedDate] = 0; // Default value

            startDate.setDate(startDate.getDate() + 1); // Move to the next day
        }
        return dateObj;
    }





    // ****-------show data Function --------------***
    const FnShowCottonBaleData = async () => {

        try {
            let isValidFilters = FnChkIsValidFilters();
            if (!isValidFilters) { return false; }
            setIsLoading(true)

            let emptyDateObject = generateDateRange(dt_from_date, dt_to_date);
            setHeaderDates(emptyDateObject)
            let commonIds = {};
            // Common Ids 
            // commonIds.plant_id = cmb_unit_plant_id;
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            console.log("commonIds", commonIds);
            const formData = new FormData();
            formData.append(`commonIdsObj`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/FnGetBalesDailyIssueReport`, requestOptions)
            const responce = await apiCall.json();
            debugger
            let dailyBalesIssueData = responce.DailyBalesIssueData;
            let openingBalanceData = responce.OpeningBalanceAgainstLotNos;

            let dailyBalesMap = new Map();

            // Store all issues in a map for quick lookup
            dailyBalesIssueData.forEach((data) => {
                if (!dailyBalesMap.has(data.batch_no)) {
                    dailyBalesMap.set(data.batch_no, []);
                }
                dailyBalesMap.get(data.batch_no).push(data);
            });

            let finalDailyIssueData = openingBalanceData.map((issueData) => {
                let emptyObj = { ...issueData };
                let total_quantity_issued = issueData['total_issue_quantity'] || 0; // Initialize with current issueData value

                let matchingEntries = dailyBalesMap.get(issueData.batch_no) || [];

                matchingEntries.forEach((data) => {
                    let formattedDate = validateNumberDateInput.current.formatDateToDDMMYYYY(data.transaction_date);
                    emptyObj[formattedDate] = data.total_issue_quantity;

                    // Accumulate total issue quantity
                    total_quantity_issued += data.total_issue_quantity;
                });

                emptyObj['total_quantity_issued'] = total_quantity_issued; // Assign total sum

                return emptyObj;
            });


            console.log(finalDailyIssueData);

            setCottonBalesDailyIssueReport(finalDailyIssueData);

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }



    const renderBalesDailyIssueReport = useMemo(() => {
        return <Table className='erp_table' id="balesdailyIssueReportTblId" responsive hover bordered striped>
            <thead className="erp_table_head">
                <tr>

                    <td className="erp_table_th " style={{ width: "50px" }}>Sr. No.</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Batch No.</td>
                    <td className="erp_table_th " style={{ width: "50px" }}>Opening<br></br>Balance</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>Issue<br></br>Quantity</td>
                    <td className="erp_table_th " style={{ width: "75px" }}>Closing<br></br>Balance</td>
                    {/* Auto Headers For Date */}
                    {Object.keys(headerDates).map((dt) => (
                        <td key={dt} className="erp_table_th" style={{ width: "75px" }}>
                            {dt}
                        </td>
                    ))}


                </tr>
            </thead>
            <tbody>
                {cottonBaleWeightageStockReportData.length > 0 ? (
                    cottonBaleWeightageStockReportData?.map((data, indexOfItem) => (
                        <tr rowIndex={indexOfItem}>

                            <td className="erp_table_head erp_table_th">{indexOfItem + 1}</td>
                            <td className="erp_table_head erp_table_th">{data.batch_no}</td>
                            <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber(data.opening_balance)}</td>
                            <td className="erp_table_head erp_table_th">{validateNumberDateInput.current.decimalNumber(data.total_quantity_issued, 4)} </td>
                            <td className="erp_table_head erp_table_th">{validateNumberDateInput.current?.decimalNumber((data.closing_balance), 4)} </td>
                            {/* Auto Headers For Date */}
                            {Object.keys(headerDates).map((dt) => (
                                <td key={dt} className="erp_table_head erp_table_th" style={{ width: "75px" }}>
                                    {data[dt]}
                                </td>
                            ))}

                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="erp_table_td" colSpan={15}>
                            {"No Records found..."}
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    }, [cottonBaleWeightageStockReportData]);

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                {/* <ExcelExport ref={exlsExp} /> */}

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card mt-4'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Cotton Bales Daily Issue Report</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">

                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDt(e.target.value); }} />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDt(e.target.value); FnChkIsValidFilters(); }} max={currentDate} />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Plant Name </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_unit_plant_id}
                                            onChange={(e) => { setUnitPlantId(e.target.value); }}>
                                            <option value={0}>Select</option>
                                            {unitPlantOptions.length > 0 &&
                                                unitPlantOptions.map((plant) => (
                                                    <option key={plant.plant_id} value={plant.plant_id}>
                                                        {plant.plant_name}
                                                    </option>
                                                ))}
                                        </select>

                                        <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row justify-content-center mt-2">
                                <div className="col text-center">
                                    <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowCottonBaleData(); }} variant="button" fontWeight="regular">show Data</MDButton>
                                </div>
                            </div>

                        </div>

                        <hr />
                        <div className='row pt-1'>
                            <div className="card-header py-0 ms-2 p-1 main_heding mb-0">
                                <label className="erp-form-label-md-lg">Bales Daily Issue Report</label>
                            </div>
                            <div className='col erp_form_col_div mt-2 p-2'>
                                {renderBalesDailyIssueReport}
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}
                </div>
                {/* Card Ends*/}


            </DashboardLayout >
        </>
    )
}

export default FrmCottonBalesDayWiseStockIssueReport
