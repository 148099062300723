import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";
import WaterMark from "components/WaterMark";
import $ from "jquery";


const GoodReceiptNote = React.forwardRef(({ invoiceContent, isWaterMark }, ref) => {
  debugger
  const data = invoiceContent.items
  if (invoiceContent?.context === "Bales") { $(".bales").hide() }
  // const configConstants = ConfigConstants();
  // const { AFTER_DECIMAL_PLACES } = configConstants;
  let AFTER_DECIMAL_PLACES = 2;

  // Converting Currency to International Standard.
  const numberToWords = require("number-to-words");
  const grn_currency_code = invoiceContent.footer.grn_currency_code;
  // Currency mapping with major (main unit) and minor (subunit)
  const currencyMap = {
    "INR": { major: "Rupee", minor: "Paisa" },
    "AFN": { major: "Afghani", minor: "Pul" },
    "ARS": { major: "Peso", minor: "Centavo" },
    "AWG": { major: "Guilder", minor: "Cent" },
    "AUD": { major: "Dollar", minor: "Cent" },
    "AZN": { major: "New Manat", minor: "Qəpik" },
    "BSD": { major: "Dollar", minor: "Cent" },
    "BBD": { major: "Dollar", minor: "Cent" },
    "BYR": { major: "Ruble", minor: "Kopek" },
    "EUR": { major: "Euro", minor: "Cent" },
    "BZD": { major: "Dollar", minor: "Cent" },
    "BMD": { major: "Dollar", minor: "Cent" },
    "BOB": { major: "Boliviano", minor: "Centavo" },
    "BAM": { major: "Convertible Marka", minor: "Fening" },
    "BWP": { major: "Pula", minor: "Thebe" },
    "BGN": { major: "Lev", minor: "Stotinka" },
    "BRL": { major: "Real", minor: "Centavo" },
    "GBP": { major: "Pound", minor: "Pence" },
    "BND": { major: "Dollar", minor: "Cent" },
    "KHR": { major: "Riel", minor: "Sen" },
    "CAD": { major: "Dollar", minor: "Cent" },
    "KYD": { major: "Dollar", minor: "Cent" },
    "CLP": { major: "Peso", minor: "Centavo" },
    "CNY": { major: "Yuan Renminbi", minor: "Fen" },
    "COP": { major: "Peso", minor: "Centavo" },
    "CRC": { major: "Colón", minor: "Céntimo" },
    "HRK": { major: "Kuna", minor: "Lipa" },
    "CUP": { major: "Peso", minor: "Centavo" },
    "CZK": { major: "Koruna", minor: "Haléř" },
    "DKK": { major: "Krone", minor: "Øre" },
    "DOP": { major: "Peso", minor: "Centavo" },
    "XCD": { major: "Dollar", minor: "Cent" },
    "EGP": { major: "Pound", minor: "Piastre" },
    "SVC": { major: "Colón", minor: "Centavo" },
    "FKP": { major: "Pound", minor: "Pence" },
    "FJD": { major: "Dollar", minor: "Cent" },
    "GHC": { major: "Cedi", minor: "Pesewa" },
    "GIP": { major: "Pound", minor: "Pence" },
    "GTQ": { major: "Quetzal", minor: "Centavo" },
    "GGP": { major: "Pound", minor: "Pence" },
    "GYD": { major: "Dollar", minor: "Cent" },
    "HNL": { major: "Lempira", minor: "Centavo" },
    "HKD": { major: "Dollar", minor: "Cent" },
    "HUF": { major: "Forint", minor: "Fillér" },
    "ISK": { major: "Krona", minor: "Aur" },
    "IDR": { major: "Rupiah", minor: "Sen" },
    "IRR": { major: "Rial", minor: "Dinar" },
    "ILS": { major: "New Shekel", minor: "Agora" },
    "JMD": { major: "Dollar", minor: "Cent" },
    "JPY": { major: "Yen", minor: "Sen" },
    "KZT": { major: "Tenge", minor: "Tiyn" },
    "KPW": { major: "Won", minor: "Jeon" },
    "KRW": { major: "Won", minor: "Jeon" },
    "KGS": { major: "Som", minor: "Tyiyn" },
    "LAK": { major: "Kip", minor: "Att" },
    "LVL": { major: "Lats", minor: "Santims" },
    "LBP": { major: "Pound", minor: "Piastre" },
    "LRD": { major: "Dollar", minor: "Cent" },
    "CHF": { major: "Franc", minor: "Rappen" },
    "LTL": { major: "Litas", minor: "Centas" },
    "MKD": { major: "Denar", minor: "Deni" },
    "MYR": { major: "Ringgit", minor: "Sen" },
    "MUR": { major: "Rupee", minor: "Cent" },
    "MXN": { major: "Peso", minor: "Centavo" },
    "MNT": { major: "Tugrik", minor: "Möngö" },
    "MZN": { major: "Metical", minor: "Centavo" },
    "NAD": { major: "Dollar", minor: "Cent" },
    "NPR": { major: "Rupee", minor: "Paisa" },
    "ANG": { major: "Guilder", minor: "Cent" },
    "NZD": { major: "Dollar", minor: "Cent" },
    "NIO": { major: "Cordoba", minor: "Centavo" },
    "NGN": { major: "Naira", minor: "Kobo" },
    "NOK": { major: "Krone", minor: "Øre" },
    "OMR": { major: "Rial", minor: "Baisa" },
    "PKR": { major: "Rupee", minor: "Paisa" },
    "PAB": { major: "Balboa", minor: "Centésimo" },
    "PYG": { major: "Guarani", minor: "Céntimo" },
    "PEN": { major: "Nuevo Sol", minor: "Céntimo" },
    "PHP": { major: "Peso", minor: "Centavo" },
    "PLN": { major: "Zloty", minor: "Grosz" },
    "QAR": { major: "Rial", minor: "Dirham" },
    "RON": { major: "New Leu", minor: "Ban" },
    "RUB": { major: "Ruble", minor: "Kopek" },
    "SAR": { major: "Riyal", minor: "Halala" },
    "SGD": { major: "Dollar", minor: "Cent" },
    "ZAR": { major: "Rand", minor: "Cent" },
    "SEK": { major: "Krona", minor: "Öre" },
    "THB": { major: "Baht", minor: "Satang" },
    "TRY": { major: "Lira", minor: "Kurus" },
    "UAH": { major: "Hryvnia", minor: "Kopek" },
    "USD": { major: "Dollar", minor: "Cent" },
    "VND": { major: "Dong", minor: "Hao" }
    // Add more as needed
  };

  // Convert INR to Indian number system format
  function convertINR(amount) {
    let words = "";
    let crore = Math.floor(amount / 10000000);
    let lakh = Math.floor((amount % 10000000) / 100000);
    let thousand = Math.floor((amount % 100000) / 1000);
    let hundred = Math.floor((amount % 1000) / 100);
    let remainder = amount % 100;

    if (crore) words += `${numberToWords.toWords(crore)} crore `;
    if (lakh) words += `${numberToWords.toWords(lakh)} lakh `;
    if (thousand) words += `${numberToWords.toWords(thousand)} thousand `;
    if (hundred) words += `${numberToWords.toWords(hundred)} hundred `;
    if (remainder) words += `${numberToWords.toWords(remainder)} `;

    return words.trim() + " rupees";
  }

  // Convert amount to words based on currency
  function convertCurrencyToWords(amount, currency) {
    let details = currencyMap[currency] || { major: "Currency", minor: "Cents" };

    let integerPart = Math.floor(amount);
    let decimalPart = Math.round((amount % 1) * 100);

    let words = currency === "INR"
      ? convertINR(integerPart)
      : numberToWords.toWords(integerPart) + ` ${details.major}`;

    if (decimalPart) {
      words += ` and ${numberToWords.toWords(decimalPart)} ${details.minor}`;
    }
    words = words.charAt(0).toUpperCase() + words.slice(1);

    return words + " only";
  }


  const cgstTotal = invoiceContent.footer.cgst_total;
  const sgstTotal = invoiceContent.footer.sgst_total;
  const igstTotal = invoiceContent.footer.igst_total;
  const basicTotal = invoiceContent.footer.basic_total;
  const taxable_total = invoiceContent.footer.taxable_total
  const grand_total = invoiceContent.footer.grand_total
  const round_off = invoiceContent.footer.round_off
  console.log(invoiceContent.supplierDetails.is_sez);



  const itemData = extractItemData(invoiceContent.items);
  function extractItemData(items) {
    // Extract relevant data from the first item in the array
    if (items.length > 0) {
      const firstItem = items[0];
      return {
        purchase_order_no: firstItem.purchase_order_no,
        purchase_order_date: firstItem.purchase_order_date,
        purchase_order_version: firstItem.purchase_order_version,
        indent_no: firstItem.indent_no
      };
    }
    // Return default values or handle the case where the array is empty
    return {
      purchase_order_date: 0,
      purchase_order_no: 0,
      purchase_order_version: 0,

    };
  }

  let paymentTermsList = null
  if (invoiceContent.paymentTerms && invoiceContent.paymentTerms.length > 0) {
    paymentTermsList = invoiceContent.paymentTerms.map(paymentTerm => paymentTerm.payment_terms_name).join(', ');
  }

  const invoiceHeader = (
    <>
      <div className='bordered border-bottom-0 px-0'>
        <div className='row mb-0 p-2'>
          <div className="col-sm-3">
            <img src={DakshabhiLogo} alt="master card" width="150" height="auto" mt={1} />
          </div>
          <div className="col-sm-5">
            <div className='erp-invoice-print-label text-center ps-2'>
              <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
              <span className='erp-invoice-print-label-md'>({invoiceContent.company.company_branch})</span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className='erp-invoice-print-label-lg mt-2 text-center'>
              <span className={`erp-invoice-print-label-lg ps-0 ${invoiceContent?.context === 'Bales' ? 'display' : 'd-none'}`}>Cotton Bales</span><br />
              <span className='erp-invoice-print-label-lg ps-0'>Goods Receipt Note-(GRN)</span><br />
            </div>
          </div>
        </div>

        <div className='row border-top border-dark p-0 m-0'>
          <div className='col-sm-6'>
            {/* <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> GRN No. & version : {invoiceContent.grnDetails.goods_receipt_no} &  {invoiceContent.grnDetails.goods_receipt_version}</dt> */}
            <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> GRN No. : {invoiceContent.grnDetails.goods_receipt_no}</dt>
          </div>
          <div className='col-sm-6'>
            <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}>GRN Date : {invoiceContent.grnDetails.goods_receipt_date}</dt>
          </div>
        </div>
        <div className='row border-bottom border-top border-dark p-0 m-0'>
          <div className="row p-0 m-0">
            <div className="col-sm-6">
              <dl className="row pb-0 mb-0 d-flex align-items-cente">
                {invoiceContent.company.sales_type !== "" && (
                  <>
                    <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Sales Type:</dt>
                    <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.company.sales_type}</dd>
                  </>
                )}


                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Supplier Name:</dt>
                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }} > {invoiceContent.supplierDetails.name}</dd>

                {/* <dl className="row mb-0 mt-0">
                  <div className="col-sm-6">
                    <dt className='col-sm-6 erp-invoice-print-label-md-lg'>State: <span class="erp-invoice-print-label">{invoiceContent.supplierDetails.state}</span></dt>
                  </div>
                  <div className="col-sm-6">
                    <dt className='col-sm-6 erp-invoice-print-label-md-lg'>City: <span class="erp-invoice-print-label"> {invoiceContent.supplierDetails.City}</span></dt>
                  </div>
                </dl> */}

                {/* <dl className="row pb-0 mb-0">
                  <dt class="col-1 erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}>State:</dt>
                  <dd class="col-sm-10 p-0 m-0">
                    <dl class="row m-0 p-0">
                      <dt class="col-sm-4 erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}> {invoiceContent.supplierDetails.state}</dt>
                      <dd class="col-1 erp-invoice-print-label-md-lg ps-2 pr-0 mr-0" style={{ fontSize: '9px' }}>City:</dd>
                      <dt class="col-sm-3 erp-invoice-print-label-md-lg pr-0 mr-0 " style={{ fontSize: '9px' }}>{invoiceContent.supplierDetails.City}</dt>
                    </dl>
                  </dd>
                </dl> */}

                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Address:</dt>
                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.supplierDetails.address}</dd> */}

                {/* <dl className="row mb-0 mt-0">
                  <div className="col-sm-12 d-flex justify-content-between">
                    <div className="col-sm-6 d-flex justify-content-between">
                      <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>
                        Email:<span className="erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}>{invoiceContent.supplierDetails.Email}</span>
                      </dt>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-between">
                      <dt className='erp-invoice-print-label-md-lg ps-4' style={{ fontSize: '9px' }}>
                         Contact No: <span className="erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}>{invoiceContent.supplierDetails.contact}</span>
                      </dt>
                    </div>
                  </div>
                </dl> */}
                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }} >Supplier GST No :</dt>
                <dd className='col-sm-8 erp-invoice-print-label-md-lg ' style={{ fontSize: '9px' }}>{invoiceContent.supplierDetails.supp_branch_gst_no}</dd>

                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }} >Purchase Order No :</dt>
                <dd className='col-sm-8 erp-invoice-print-label-md-lg ' style={{ fontSize: '9px' }}>{itemData.purchase_order_no}({itemData.purchase_order_date})</dd>


                {
                  invoiceContent?.context === "Bales" ? <>
                    <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Mill Lot No. & Godown:</dt>
                    <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.balesData.batch_no} & {invoiceContent.balesData.godown_name}</dd>
                  </> : null
                }

                {itemData.indent_no ? (
                  <>
                    <dt className={`col-sm-4 erp-invoice-print-label-md-lg ${invoiceContent?.context === "Bales" ? 'd-none' : 'display'}`} style={{ fontSize: '9px' }}>
                      Indent No :
                    </dt>
                    <dd className={`col-sm-8 erp-invoice-print-label-md-lg ${invoiceContent?.context === "Bales" ? 'd-none' : 'display'}`} style={{ fontSize: '9px' }}>
                      {itemData.indent_no}
                    </dd>
                  </>
                ) : null
                }
                {invoiceContent?.context === "Bales" && !!invoiceContent?.balesData?.lr_no && (
                  <>
                    <dt className="col-sm-4 erp-invoice-print-label-md-lg" style={{ fontSize: "9px" }}>
                      L.R. No & Date:
                    </dt>
                    <dd className="col-sm-8 erp-invoice-print-label-md-lg" style={{ fontSize: "9px" }}>
                      {invoiceContent.balesData.lr_no} {invoiceContent?.balesData?.lr_date ? '&' : ''} {invoiceContent.balesData.lr_date}
                    </dd>
                  </>
                )}




                {/* < dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>QA By Name:</dt>
                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.supplierDetails.qa_by_name} ({invoiceContent.supplierDetails.qa_date})</dd> */}


              </dl>

            </div>

            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Supplier Challan No:</dt>
                <dd className='col-sm-8 erp-invoice-print-label-md-lg ps-2' style={{ fontSize: '9px' }}>{invoiceContent.supplierDetails.supplier_challan_no} ({invoiceContent.supplierDetails.supplier_challan_date})</dd>


                <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Supplier Invoice No. & Date:</dt>
                {invoiceContent.supplierDetails.supplier_invoice_no != "" ?
                  <dd className='col-sm-7 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.supplierDetails.supplier_invoice_no} & {invoiceContent.supplierDetails.supplier_invoice_date}</dd>
                  : (
                    <dd className='col-sm-7 erp-invoice-print-label'> - </dd>
                  )
                }
                <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Agent Name:</dt>
                <dd className='col-sm-7 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.supplierDetails.agent_name}</dd>

                {invoiceContent.supplierDetails.ev_bill_no != '' ?
                  <>
                    <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>E-Way Bill no. & Date:</dt>
                    <dd className='col-sm-7 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.supplierDetails.ev_bill_no} & {invoiceContent.supplierDetails.ev_bill_date}</dd>
                  </>
                  : (
                    null
                  )
                }

                {/* <dl className="row mb-0 mt-0">
                  <dt className="col-sm-5 erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}>Payment Terms:</dt>
                  <dd className="col-sm-7 erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}>
                    {paymentTermsList !== null ? (
                      <span className="erp-invoice-print-label-md-lg ps-2" style={{ fontSize: '9px' }}>{paymentTermsList}</span>
                    ) : (
                      "nil"
                    )}
                  </dd>
                </dl> */}

                {/* <dt className="col-sm-5 erp-invoice-print-label-md-lg">Payment Terms:</dt>
                {paymentTermsList !== null ? (
                  <dd className="col-sm-7">
                    <dl className="row ">
                      <dd className="col-sm-12 erp-invoice-print-label p-2">
                        {paymentTermsList}
                      </dd>
                    </dl>
                  </dd>
                ) : (
                  <dd className="col-sm-7 erp-invoice-print-label-md-lg">nil</dd>
                )} */}
                {/* <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Is Sez:</dt>
                <dd className='col-sm-7 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.supplierDetails.is_sez}</dd> */}
              </dl>
            </div>
          </div>
        </div>
        <div className='row p-0 m-0'>
          <span className='erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> Dear Sir</span>
          <span className='erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>We received material from you as follows.</span>
        </div>

      </div>

      <hr className='m-0 p-0 hr-line' />
    </>
  )

  const invoiceFooter = (
    <div className="invoice-footer">
      <div className='row mt-2'>
        <div className='col-sm-3'>
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.grnDetails.receivers_name}</dt>
          </div>
          <div className='align-bottom-center mt-3'>
            <dt className="erp-invoice-print-label-md-lg">Receiver Signature</dt>
          </div>
        </div>
        <div className='col-sm-3 offset-sm-6'> {/* This offsets the third column */}
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.company.company}</dt>
          </div>
          <div className='align-bottom-center mt-3'>
            <dt className="erp-invoice-print-label-md-lg">Authorised Signatory</dt>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* <div className="container"> */}

      <div className="row">
        <div className="col-12">
          <div className="container-invoice">
            {isWaterMark ? (
              <>
                {" "}
                <WaterMark text="Preview Only" opacity={0.9} />{" "}
                {/* Add watermark here */}
              </>
            ) : null}
            <div id="content">
              <div className="invoice p-0">

                {/* <!--BILL HEADER--> */}
                <div className="row" style={{ padding: '0px 15px 0px' }}>
                  {invoiceHeader}
                </div>

                {/* <!--PARCEL DETAILS--> */}
                <div className="row" style={{ padding: '0px 15px 15px' }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 px-0" >
                        <div className="row p-0">
                          <div className="table-responsive">
                            {/* <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' > */}
                            <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" style={{ tableLayout: 'fixed', width: '100%' }}>
                              <thead>
                                {invoiceContent?.context === "Bales" ?
                                  // For Cotton Bales
                                  <>
                                    <tr>
                                      <th className="erp_invoice_table_th text-center">Sr No.</th>
                                      <th className="erp_invoice_table_th text-center">Material Code</th>
                                      <th className="erp_invoice_table_th text-center" colSpan={2}>Material Name (Technical Spect.)</th>
                                      <th className="erp_invoice_table_th text-center col-1">Accepted Qty.</th>
                                      <th className="erp_invoice_table_th text-center col-1">Accepted Wt.</th>
                                      <th className="erp_invoice_table_th text-center">Unit</th>
                                      <th className="erp_invoice_table_th text-center">Rate</th>

                                      {/* <!-- Taxes Extra Column with Nested Headers --> */}
                                      <th className="erp_invoice_table_th text-center" colSpan={3}>
                                        Taxes Extra
                                        <div className="d-flex justify-content-between">
                                          <span style={{ fontSize: '8px', width: '33%' }}>CGST %</span>
                                          <span style={{ fontSize: '8px', width: '33%' }}>SGST %</span>
                                          <span style={{ fontSize: '8px', width: '33%' }}>IGST %</span>
                                        </div>
                                      </th>

                                      <th className="erp_invoice_table_th text-center">Item Total</th>
                                    </tr>


                                  </>
                                  :

                                  // Other than Cotton Bales
                                  <>
                                    <tr>
                                      <th className="erp_invoice_table_th text-center" rowspan={2} style={{ width: '40px' }}  >Sr No.</th>
                                      <th className="erp_invoice_table_th text-center" rowspan={2} style={{ width: '40px' }}  >Material Code</th>
                                      <th className="erp_invoice_table_th text-center" style={{ width: '140px' }} rowspan={2} >Material Name (Technical Spect.)</th>
                                      <th className="erp_invoice_table_th text-center col-2 mb-2" style={{ width: '100px' }} rowspan={2} >Godown Section Beans Name.</th>
                                      <th className="erp_invoice_table_th text-center col-1 mb-2" style={{ width: '50px' }} rowspan={2}>GRN Qty.</th>
                                      <th className="erp_invoice_table_th text-center col-1 mb-2" style={{ width: '50px' }} rowspan={2} >Accepted Qty.</th>
                                      <th className="erp_invoice_table_th text-center col-1 mb-2" style={{ width: '50px' }} rowspan={2} >Rejected Qty.</th>
                                      <th className="erp_invoice_table_th text-center mb-2" style={{ width: '30px' }} rowspan={2}>Unit</th>
                                      <th className="erp_invoice_table_th text-center mb-2 col-1" style={{ width: '30px' }} rowspan={2} >Rate</th>
                                      <th className="erp_invoice_table_th text-center " style={{ width: '100px' }} colSpan={3}>Taxes Extra</th>
                                      <th className="erp_invoice_table_th text-center col-1" style={{ width: '30px' }} rowspan={2} >Item Total</th>
                                    </tr>
                                    <tr>
                                      <th className="erp_invoice_table_th text-center-md-lg" style={{ fontSize: '8px', width: '60px' }}>CGST %</th>
                                      <th className="erp_invoice_table_th text-center-md-lg" style={{ fontSize: '8px', width: '60px' }}>SGST %</th>
                                      <th className="erp_invoice_table_th text-center-md-lg" style={{ fontSize: '8px', width: '60px' }}>IGST %</th>
                                    </tr>
                                  </>

                                }

                              </thead>
                              <tbody id='detailstableId'>
                                {
                                  data.map((item, index) =>
                                    <>
                                      {invoiceContent?.context === "Bales" ?
                                        // For Cotton Bales
                                        <>
                                          <tr className={`tblBodyTrHt-${index}`}>
                                            <td className="erp_invoice_table_td text-center">{index + 1}</td>
                                            <td className="erp_invoice_table_td text-center">{item.product_material_code}</td>
                                            {/* <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.material_name}<span>({item.material_tech_spect})</span></td> */}

                                            <td className="erp_invoice_table_td" style={{ width: '40%' }} colSpan={2}>{item.material_name}
                                              <span>
                                                {
                                                  (item.material_tech_spect === '' || item.material_tech_spect === "NA")
                                                    ? ''
                                                    : <>
                                                      ({
                                                        [item.material_tech_spect].filter(str => str !== "" && str !== "NA" && str !== null && str !== undefined).join(", ")})
                                                    </>
                                                }
                                              </span>
                                            </td>

                                            <td className="erp_invoice_table_td text-end">{item.grn_accepted_quantity}</td>
                                            <td className="erp_invoice_table_td text-end">{item.grn_accepted_weight}</td>
                                            <td className="erp_invoice_table_td">{item.unit}</td>
                                            <td className="erp_invoice_table_td text-end">{item.rate}</td>
                                            <td className="erp_invoice_table_td text-end">{item.cgst_percent}</td>
                                            <td className="erp_invoice_table_td text-end">{item.sgst_percent}</td>
                                            <td className="erp_invoice_table_td text-end">{item.igst_percent}</td>
                                            <td className="erp_invoice_table_td text-end">{item.basic_amount}</td>
                                          </tr>
                                        </>
                                        :

                                        // Other than Cotton Bales
                                        <>
                                          <tr className={`tblBodyTrHt-${index}`}>
                                            <td className="erp_invoice_table_td text-center">{index + 1}</td>
                                            <td className="erp_invoice_table_td text-center">{item.product_material_code}</td>
                                            {/* <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.material_name}<span>({item.material_tech_spect})</span></td> */}

                                            <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.material_name}
                                              <span>
                                                {
                                                  (item.material_tech_spect === '' || item.material_tech_spect === "NA")
                                                    ? ''
                                                    : <>
                                                      ({
                                                        [item.material_tech_spect].filter(str => str !== "" && str !== "NA" && str !== null && str !== undefined).join(", ")})
                                                    </>
                                                }
                                              </span>
                                            </td>

                                            <td className="erp_invoice_table_td ">{item.godown_section_beans_name}</td>
                                            <td className="erp_invoice_table_td text-end">{item.grn_quantity}</td>
                                            <td className="erp_invoice_table_td text-end">{item.grn_accepted_quantity}</td>
                                            <td className="erp_invoice_table_td text-end">{item.grn_rejected_quantity}</td>
                                            <td className="erp_invoice_table_td">{item.unit}</td>
                                            <td className="erp_invoice_table_td text-end">{item.rate}</td>
                                            <td className="erp_invoice_table_td text-end">{item.cgst_percent}</td>
                                            <td className="erp_invoice_table_td text-end">{item.sgst_percent}</td>
                                            <td className="erp_invoice_table_td text-end">{item.igst_percent}</td>
                                            <td className="erp_invoice_table_td text-end">{item.basic_amount}</td>
                                          </tr>
                                        </>}

                                      {
                                        data.length - 1 === index ? <>
                                          <tr id='footerTr'>
                                            <td className="erp_invoice_table_td" colSpan={8}>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Basic Total (in words):</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{convertCurrencyToWords(basicTotal, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">CGST(in words):</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{convertCurrencyToWords(cgstTotal, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">SGST(in words):</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{convertCurrencyToWords(sgstTotal, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">IGST(in words):</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{convertCurrencyToWords(igstTotal, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Taxable Total(in words):</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{convertCurrencyToWords(taxable_total, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Grand Total(in words):</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{convertCurrencyToWords(grand_total, grn_currency_code)}</dd>
                                              </div>
                                            </td>
                                            <td className="erp_invoice_table_td p-0 m-0" colSpan={4}>
                                              <table className="table p-0 m-0 table-borderless">
                                                <tbody>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Basic Amount {` ${invoiceContent.footer.grn_currency_symbol}`} :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.basic_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg ">CGST Total {` ${invoiceContent.footer.grn_currency_symbol}`} :</span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.cgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">SGST Total {` ${invoiceContent.footer.grn_currency_symbol}`} :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.sgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">IGST Total {` ${invoiceContent.footer.grn_currency_symbol}`} :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.igst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Freight Amount {` ${invoiceContent.footer.grn_currency_symbol}`} :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.freight_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Discount Amount {` ${invoiceContent.footer.grn_currency_symbol}`} :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.discount_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Discount (%) :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.discount_percent).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Taxable Total {`  ${invoiceContent.footer.grn_currency_symbol}`} :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.taxable_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className='border-0'>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Round Off: </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.round_off).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className='border-0'>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Grand Total {` ${invoiceContent.footer.grn_currency_symbol}`} : </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.grand_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td colSpan={12}>
                                              {/* Your invoice footer content */}
                                              {invoiceFooter}
                                            </td>
                                          </tr>

                                        </> : null
                                      }

                                    </>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                          {/* <div>
                              <span className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>ISO No. & GRN Version : {invoiceContent.grnDetails.purchase_iso_no} &  {invoiceContent.grnDetails.goods_receipt_version} </span>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
})


export default GoodReceiptNote;

