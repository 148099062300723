import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from 'jquery';
import { MdDelete } from "react-icons/md";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Table, Accordion, Modal, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import Tooltip from "@mui/material/Tooltip";
import FrmPaymentTermsEntry from "Masters/MPaymentTerms/FrmPaymentTermsEntry";
import FrmHSNSAC from "Masters/MHSN-SAC/FrmHSN-SAC";
import { renderToString } from "react-dom/server";
import { FiDownload } from "react-icons/fi";
import YarnSaleInvoice from "FrmGeneric/Invoice/YarnSaleInvoice";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";

// Import for the searchable combo box.
import Select from 'react-select';

export default function FrmGoodsReturnEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, UserId, SHORT_COMPANY, SHORT_FINANCIAL_YEAR } = configConstants;

    const { state } = useLocation();
    const { keyForViewUpdate, goodsReturnMasterId, compType, idList } = state || {};
    const [action_Label, setActionLabel] = useState('(Creation)');
    const [isLoading, setIsLoading] = useState(false);
    const [showAddUpdateBtn, setShowAddUpdateBtn] = useState(false)

    //For navigate
    const navigate = useNavigate();
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    //UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const navigator = useNavigate();
    // const issueNoComboRef = useRef();
    // const materialsComboRef = useRef();
    // const lotNoComboRef = useRef();
    //Data states
    const [yarnReturnDetailsData, setYarnReturnDetails] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [materialtypeOptions, setmaterialtypeOptions] = useState([])
    const [cmb_goods_return_status, setGoodReturnStatus] = useState("P");
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [cmb_approved_by_id, setApprovedById] = useState(UserId);
    const [dt_approved_date, setApproveDate] = useState("");
    const [setNoOptions, setSetNoOptions] = useState([])


    //combo states & options
    const [jobTypeOptions, setJobTypeArr] = useState([]);
    const [cmb_sales_job_type, setSalesJobType] = useState("");
    // const [cmb_supplier_id, setSupplier_id] = useState("");
    // const [grnOptions, setGRNOptions] = useState([]);
    // const [cmb_grn_no, setGRNno] = useState("");
    // const [cmb_lot_no, setLotNo] = useState('');
    // const [lotNoOptions, setLotNoOptions] = useState([])
    const [cmb_material_type, setmaterialType] = useState('');
    // const [materialOptions, setMaterialOptions] = useState([])
    const [hsnCodesOptions, setHsnCodesOptions] = useState([]);
    let [PmtTermsdata, setPmtTermsData] = useState([]);                                 // Payment terms Table Data Hooks
    let [PmtTermsdataResp, setPmtTermsDataResp] = useState([]);

    //Form Fields
    const [txt_goods_return_no, setGoodsReturnNo] = useState(0);
    // const [txt_goods_return_weight, setGoodsReturnWeight] = useState(0);
    // const [txt_goods_return_quantity, setGoodsReturnQuantity] = useState('');
    // const [txt_goods_return_boxes, setGoodsReturnBoxes] = useState('');
    const [txt_material_rate, setMaterialRate] = useState('');
    const [txt_goods_return_version, setGoodsReturnVersion] = useState(1);
    const [openYarnDetailsAccord, setOpenYarnDetailsAccord] = useState(true);

    const [txt_discount_percent, setDiscountPercent] = useState(0);
    const [txt_discount_amount, setDiscountAmt] = useState(0);
    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('');

    const [cmb_consignee_id, setConsigneeId] = useState('');
    const [cmb_consignee_state_id, setConsigneeState] = useState('');
    const [cmb_consignee_city_id, setConsigneeCity] = useState('');
    const [txt_invoice_no, setInvoiceRefNo] = useState('');
    const [txt_driver_name, setDriverName] = useState('');
    const [txt_transport_mode, setTransportMode] = useState('');
    const [txt_vehical_no, setVehicalNo] = useState('');
    const [txt_cust_branch_phone_no, setCustomerPhoneNo] = useState('');
    const [txt_cust_gst_no, setCustGStNO] = useState('');
    const [txt_cust_address, setCustAddress] = useState('');
    const [txt_consignee_branch_phone_no, setConsigneePhoneNo] = useState('');
    const [txt_consignee_gst_no, setConsigneeGStNO] = useState('');
    const [txt_consignee_address, setConsingeeAddress] = useState('');
    const [custContactDetails, setCustContactDetails] = useState([]);                   // Customers contact details table data hooks

    const [materialRequisitionDetails, setMaterialRequisitionDetails] = useState([]);
    const [yarnReturnMasterDataJson, setYarnReturnMasterDataJson] = useState({});


    // Taxation and Billing section form fields
    const [txt_basic_total, setBasicTotal] = useState(0);
    const [txt_transport_amount, setTransportAmt] = useState(0);
    const [chk_freight_isTaxable, setFreightIsTaxable] = useState(false);
    const [txt_freight_amount, setFreightAmt] = useState(0);
    const [cmb_freight_hsn_id, setFreightHSNId] = useState();
    const [txt_packing_amount, setPackingAmt] = useState(0);
    const [productUnitOpts, setProductUnitOpts] = useState([]);

    const [txt_other_amount, setOtherAmt] = useState(0);
    const [txt_cgst_percent, setCGSTPercent] = useState(0);
    const [txt_cgst_total, setCGSTTotal] = useState(0);
    const [txt_sgst_percent, setSGSTPercent] = useState(0);
    const [txt_sgst_total, setSGSTTotal] = useState(0);
    const [txt_igst_percent, setIGSTPercent] = useState(0);
    const [txt_igst_total, setIGSTTotal] = useState(0);
    const [txt_taxable_total, setTaxableTotal] = useState(0);
    const [txt_roundoff, setRoundOff] = useState(0);
    const [txt_grand_total, setGrandTotal] = useState(0);
    const [freightHSNOpts, setFreightHSNOpts] = useState([]);
    const [showWRFilterForm, setShowWRFilterForm] = useState(false);

    // delete popup
    // const [show, setShow] = useState(false);
    // const [issueNoOptions, setIssueNoOptions] = useState([]);
    // const [cmb_product_material_id, setProductMaterialId] = useState('');
    const [dt_goods_return_date, setGoodsReturnDate] = useState(todayDate);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [cmb_customer_state_id, setCustomerState] = useState('');
    const [cmb_customer_city_id, setCustomerCity] = useState('');
    const [stateOpts, setStateOpts] = useState([]);
    const [customerOpts, setCustomerOpts] = useState([]);
    const [customerCityOpts, setCustomerCityOpts] = useState([]);
    const [consigneeOpts, setConsigneeOpts] = useState([]);
    const [consigneeCityOpts, setConsigneeCityOpts] = useState([]);
    const [cmb_customer_id, setCustomerId] = useState(null);
    const [companyBranchesOpts, setCompanyBranchesOpts] = useState([]);
    const [companyBrancheCityOpts, setCompanyBrancheCityOpts] = useState([]);
    const [cmb_companyBranchId, setCompanyBranchId] = useState();
    const [cmb_companyBranchStateId, setCompanyBranchStateId] = useState();
    const [cmb_companyBranchCityId, setCompanyBranchCityId] = useState();
    const setNoComboRef = useRef();
    const [cmb_set_no, setSetNo] = useState('');
    const [warpingBottomDetailsData, setWarpingBottomDetailsData] = useState([])

    const [chk_yarn_type, setYarn_type] = useState("Fresh")
    const [godownID, SetGodownId] = useState('2');


    const [salesDispatchTypeOpt, setSaleDispatchTypeOpt] = useState([])
    const [dispatchHSNTaxTypeOpt, setDispatchHSNTaxTypeOpt] = useState([])
    const [dispatchSalesTypeOpt, setDispatchSalesTypeOpt] = useState([])
    const [dispatchVoucherTypeOpt, setDispatchVoucherTypeOpt] = useState([])
    const [cmb_sales_dispatch_type, setSalesDispatchType] = useState('');
    const [cmb_dispatch_hsnTax_type, setDispatchHsnTaxType] = useState('');
    const [cmb_dispatch_sales_type, setDispatchSalesType] = useState('');
    const [cmb_dispatch_voucher_type, setDispatchVoucherType] = useState('');

    let customerComboRef = useRef(null);
    let consigneeComboRef = useRef(null);
    let job_type_ref = useRef(null);
    let sales_dispatch_type_ref = useRef(null);
    let dispatch_tax_type_ref = useRef(null);
    let dispatch_sales_type_ref = useRef(null);
    let dispatch_voucher_type_ref = useRef(null);
    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    useEffect(async () => {
        setIsLoading(true);
        try {
            await fillComobos();
            ActionType()
            if (idList !== 0) {
                await FnCheckUpdateResponce();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
        // Close indent order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $("#indent-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
        // Close customer order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $(".erp_dropdown-content");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [])

    const currentDate = new Date();
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate('/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing')
        }
    }

    const AddMaterial = async () => {
        debugger
        if (cmb_customer_id && cmb_customer_id !== "0" && !isNaN(cmb_customer_id)) {
            $('#error_cmb_customer_id').hide();
            setShowWRFilterForm(true)
        } else {
            $('#error_cmb_customer_id').text('Please select customer ...!');
            $('#error_cmb_customer_id').show();
            $("#cmb_customer_id").focus();

        }

    }
    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_tech_spect', 'product_std_weight', 'product_unit_name', 'product_material_indent_quantity', 'product_material_indent_weight',
        "product_material_approved_quantity", "product_material_approved_weight", "product_material_rejected_quantity", "product_material_rejected_weight",
        "approval_remark", "issue_item_status", 'product_material_grade_name', 'product_make_name', 'stock_quantity', 'stock_weight', "product_material_reserve_quantity",
        "product_material_reserve_weight", "product_material_receipt_quantity", "product_material_reserve_weight", "product_material_receipt_weight", "product_type_id",
        "product_material_return_quantity", "product_material_return_weight", 'product_unit_id As product_material_unit_id', 'product_lead_time', 'godown_id', 'godown_section_id', 'godown_section_beans_id', 'godown_name', 'godown_section_name', 'godown_section_beans_name', 'product_category2_id', 'product_category1_id'
    ];

    const FnCloseStockModal = async () => {
        setShowWRFilterForm(false)
        sessionStorage.removeItem('filteredMaterialData')
        sessionStorage.removeItem('requiredCols')
        setMaterialRequisitionDetails([]);
    }
    const FnConsumeStock = () => {
        debugger
        try {
            const getConsumptionDetails = [...materialRequisitionDetails];

            // Filter records with warping_quantity > 0
            let filteredDetails = getConsumptionDetails.filter(item => item.warping_quantity > 0);
            // setMaterialRequisitionStock(filteredDetails);

            // const uniqueConePerWt = new Set(filteredDetails.map(item => item.cone_per_wt));
            // const uniqueBatchNO = new Set(filteredDetails.map(item => item.batch_no));
            // if (uniqueConePerWt.size > 1 || uniqueBatchNO.size > 1) {
            //     setErrMsg("Inconsistent Weight Per Cone or batch numbers found.");
            //     setShowErrorMsgModal(true);
            //     return false;
            // }

            const uniqueMaterialAndCone = new Set(
                filteredDetails.map(item => `${item.product_rm_id}-${item.cone_per_wt}`)
            );

            // Prepare the updated consumption details
            let updatedConsumptionDetails = filteredDetails.map(item => ({
                ...item,
                goods_return_weight: parseFloat(item.warping_quantity),
                goods_return_quantity: parseFloat(item.no_of_cones),
                // product_hsn_sac_code_id: "1",
                goods_return_rate: 0

            }));

            // Find relevant objects where product_material_indent_quantity > 0
            const relevantObjects = updatedConsumptionDetails.filter(item => item.goods_return_quantity > 0);

            // Add only unique entries based on goods_receipt_no
            // setYarnReturnDetails(prevData => {
            //     return [...prevData, ...relevantObjects];
            // });

            setYarnReturnDetails(prevData => {
                const existingEntries = new Set(prevData.map(item => `${item.product_rm_id}-${item.cone_per_wt}`));

                const uniqueObjects = relevantObjects.filter(item =>
                    !existingEntries.has(`${item.product_rm_id}-${item.cone_per_wt}`)
                );

                return [...prevData, ...uniqueObjects];
            });


            setShowWRFilterForm(false);

            sessionStorage.removeItem('filteredMaterialData');
            sessionStorage.removeItem('requiredCols');
            setMaterialRequisitionDetails([]);
            if (chk_yarn_type === "Bottom") {
                const uniqueProductRmIds = [...new Set(filteredDetails.map(item => item.product_rm_id))];

                resetGlobalQuery();
                globalQuery.columns = [" Distinct(set_no) ", "product_material_id", "batch_no"];
                globalQuery.table = "sm_warping_bottom_stock_details";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "stock_status", operator: "=", value: "A" });
                globalQuery.conditions.push({ field: "product_material_id", operator: "IN", values: [...new Set(uniqueProductRmIds)] });

                globalQuery.conditions.push({ field: "stock_status", operator: "=", value: "A" });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    .then(getMaterials => {
                        console.log(getMaterials);
                        const materialOpts = [
                            { value: '', label: 'Select', },
                            ...getMaterials.map((material) => ({ ...material, value: material.set_no, label: `${material.set_no} [${material.batch_no}]`, product_material_id: material.product_material_id })),
                        ];
                        setSetNoOptions(materialOpts)
                    });
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    const FnGetStockDetails = async () => {
        // debugger
        const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
        try {
            setIsLoading(true)
            if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve') {

                resetGlobalQuery();
                globalQuery.columns = [
                    'stock.stock_transaction_id',
                    'sm.product_rm_name AS product_material_name',
                    // 'ptv.indent_no',
                    'stock.goods_receipt_no',
                    'stock.customer_goods_receipt_no',
                    // 'stock.closing_balance_quantity',
                    // 'stock.closing_balance_weight',
                    'SUM(stock.closing_balance_quantity) AS closing_balance_quantity',
                    'SUM(stock.closing_balance_weight) AS closing_balance_weight',
                    'SUM(stock.closing_no_of_boxes) AS closing_no_of_boxes',
                    'stock.supplier_name',
                    'stock.supplier_id',
                    'stock.customer_name',
                    'stock.customer_id',
                    'sm.product_rm_code AS product_material_code',
                    'stock.product_rm_id',
                    'stock.product_packing_name',
                    'stock.weight_per_box_item As cone_per_wt',
                    'stock.product_material_unit_id',
                    'sm.actual_count',
                    'stock.batch_no As issue_batch_no',
                    // 'ptv.remark',

                    'stock.godown_id',
                    'stock.godown_section_id',
                    'stock.godown_section_beans_id',
                    'stock.godown_name',
                    'stock.godown_section_name',
                    'stock.godown_section_beans_name'
                ];

                globalQuery.table = "smv_product_rm_stock_details stock";
                globalQuery.joins = [
                    // {
                    //     table: "pt_goods_receipt_details ptv",
                    //     type: "left",
                    //     on: [
                    //         { left: "ptv.product_material_id", right: "stock.product_rm_id" },
                    //         { left: "ptv.goods_receipt_no", right: "stock.goods_receipt_no" },
                    //         {
                    //             "left": "ptv.is_delete",
                    //             "right": "0"
                    //         }
                    //     ]
                    // },


                    {
                        "table": `sm_product_rm sm`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "sm.product_rm_id",
                                "right": "stock.product_rm_id",
                            },

                            {
                                "left": "sm.is_delete",
                                "right": "0"
                            }
                        ]
                    },

                ];


                globalQuery.conditions = [
                    { field: "stock.day_closed", operator: "=", value: 0 },
                    { field: "stock.product_rm_id", operator: "=", value: getData[0].product_id },
                    // { field: "stock.godown_id", operator: "=", value: "2" },
                    { field: "stock.godown_id", operator: "=", value: godownID },
                ];
                globalQuery.groupBy = [
                    'stock.batch_no',
                    'stock.product_rm_id',
                    'stock.weight_per_box_item',
                    'stock.supplier_id',

                ];

                // Make the API call to fill filters combo
                let getStockDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                // Create a Set of stock_transaction_id from materialRequisitionDetails
                const existingTransactionIds = new Set(yarnReturnDetailsData.map(item => item.stock_transaction_id));

                const getExistingRequisitionDetails = getStockDetails.filter(item =>
                    !existingTransactionIds.has(item.stock_transaction_id) &&
                    item.closing_balance_quantity > 0
                );

                // Optionally, if you want to combine both existing and new data into materialRequisitionDetails:
                const combinedData = [...yarnReturnDetailsData, ...getExistingRequisitionDetails];

                console.log('combinedData:- ', combinedData);


                // Update the state with the combined data
                setMaterialRequisitionDetails(combinedData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    };

    const FnCalculateStockDetailTblRow = (event, rowData) => {
        try {
            setIsLoading(true)

            let clickedColName = event.target.getAttribute('Headers');
            let eventId = document.getElementById(event.target.id);
            let enteredValue = event.target.value;
            const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))


            if (isNaN(enteredValue)) {
                enteredValue = 0;
            }
            else if (enteredValue != 0 || enteredValue != "") {
                delete eventId.parentElement.dataset.tip;
            }

            if (clickedColName === 'warping_quantity') {
                // Update the specific entry in StockDetails
                if (enteredValue <= rowData.closing_balance_weight) {
                    // rowData[clickedColName] = enteredValue
                    rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(enteredValue.toString(), 4);

                    if (rowData['cone_per_wt'] !== 0 && rowData['cone_per_wt'] !== null) {
                        let NoOfCones = (Math.ceil(rowData.warping_quantity / rowData.cone_per_wt));
                        // let NoOfCones = rowData.warping_quantity / rowData.cone_per_wt;

                        if (!isNaN(NoOfCones)) {
                            rowData['no_of_cones'] = NoOfCones;
                        }
                    }
                    delete eventId.parentElement.dataset.tip;
                } else {
                    eventId.parentElement.dataset.tip = 'Enter Qty Less than (or) Equal to Stock Wt.';
                    return;
                }

            } else if (clickedColName === 'no_of_cones') {
                let productMaterialQty = (validateNumberDateInput.current.decimalNumber(enteredValue * rowData.cone_per_wt, 2));
                if (!isNaN(productMaterialQty) && productMaterialQty <= rowData['closing_balance_weight']) {
                    rowData[clickedColName] = enteredValue;
                    rowData.warping_quantity = productMaterialQty
                    delete eventId.parentElement.dataset.tip;
                    event.target.parentElement.removeAttribute('data-tip');

                } else {
                    event.target.parentElement.setAttribute('data-tip', 'Requisition quantity should be less than or equal to stock weight');
                    return;
                }

            }

            setMaterialRequisitionDetails((prevData) => {
                const newData = [...prevData];
                newData[arrayIndex] = rowData;
                return newData;
            });
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setIsLoading(false);
        }
    }
    const renderStockDetailsTable = useMemo(() => {
        return (
            <Table id="stockDetailsTblId" className="erp_table erp_table_scroll" style={{
                overflow: "auto",
                //  height: individualStockDetails.length <= 5 ? 'auto' : '225px',
            }} bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        {/* <th className="erp_table_th">Action</th> */}
                        <th className="erp_table_th" style={{ width: "100px" }}>Material Name</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Material Code</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Count</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Customer Name</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Supplier Name</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Lot No</th>
                        {/* <th className="erp_table_th" style={{ width: "100px" }}>Remark</th> */}
                        <th className="erp_table_th" style={{ width: "100px" }}>Stock Wt.</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Stock Qty.</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Weight Per Cone</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Req. No.Of Cones</th>
                        <th className="erp_table_th" style={{ width: "100px" }}>Req. Quantity (Kg.)</th>

                    </tr>
                </thead>

                <tbody>
                    {materialRequisitionDetails.length > 0 ? (
                        materialRequisitionDetails.map((stockDetail, indexOfItem) => (
                            <tr rowIndex={indexOfItem}>
                                {/* <td className="erp_table_td">
                                    <input type="checkbox" name="selectStockDetails" className="form-check-input selectStockDetails"
                                        id={'selectStockDetails_' + indexOfItem} defaultChecked={stockDetail.warping_quantity > 0}
                                        disabled={keyForViewUpdate === 'view'}
                                    />
                                </td> */}
                                <td className="erp_table_td" id={`product_material_name_${indexOfItem}`}>{stockDetail.product_material_name} </td>
                                <td className="erp_table_td" id={`product_material_code_${indexOfItem}`}>{stockDetail.product_material_code} </td>
                                <td className="erp_table_td" id={`production_count_name_${indexOfItem}`}>{stockDetail.actual_count} </td>
                                <td className="erp_table_td" id={`customer_name_${indexOfItem}`}>{stockDetail.customer_name} </td>
                                <td className="erp_table_td" id={`supplier_name_${indexOfItem}`}>{stockDetail.supplier_name} </td>
                                <td className="erp_table_td" id={`batch_no_${indexOfItem}`}>
                                    {stockDetail.issue_batch_no}
                                </td>
                                {/* <td className="erp_table_td" id={`remark_${indexOfItem}`}>
                                    {stockDetail.remark}
                                </td> */}
                                <td className="erp_table_td" id={`production_order_no_${indexOfItem}`}>
                                    {stockDetail.closing_balance_weight}
                                </td>

                                <td className="erp_table_td" id={`product_material_quantity_${indexOfItem}`}>
                                    {stockDetail.closing_balance_quantity}
                                </td>
                                <td className="erp_table_td" id={`cone_per_wt_${indexOfItem}`}>
                                    {stockDetail.cone_per_wt}
                                </td>
                                <td className="erp_table_td" id={`no_of_cones_${indexOfItem}`}>
                                    <input
                                        type="text"
                                        style={{ width: '78px', marginRight: '3px', backgroundColor: '#AFE1AF' }}
                                        className="erp_input_field_table_txt text-end mb-1"
                                        id={`no_of_cones_${indexOfItem}`}
                                        value={stockDetail.no_of_cones === undefined ? stockDetail.no_of_cones = 0 : stockDetail.no_of_cones}
                                        onChange={(e) => FnCalculateStockDetailTblRow(e, stockDetail)}
                                        onBlur={(e) => FnCalculateStockDetailTblRow(e, stockDetail)}
                                        Headers='no_of_cones'
                                        disabled={keyForViewUpdate === 'view'}
                                    />
                                </td>
                                <td className="erp_table_td">
                                    <input
                                        type="text" style={{ width: '100px', backgroundColor: '#AFE1AF' }}
                                        className="erp_input_field_table_txt mb-0 text-end "
                                        disabled={keyForViewUpdate === 'view'}
                                        // disabled={keyForViewUpdate !== 'Add'}
                                        id={`warping_quantity_${indexOfItem}`}
                                        onChange={(e) => FnCalculateStockDetailTblRow(e, stockDetail)}
                                        onBlur={(e) => FnCalculateStockDetailTblRow(e, stockDetail)}
                                        value={stockDetail.warping_quantity}
                                        Headers='warping_quantity'
                                    />
                                </td>

                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className="erp_table_td text-center" colSpan={17} >No Records Found..!</td>
                        </tr>
                    )}
                </tbody>
            </Table>

        );
    }, [materialRequisitionDetails, yarnReturnDetailsData]);

    // Function to handle input changes and update state variables
    const validateMaterialDetails = (event) => {
        const numCheck = /^\d{0,18}(?:\.\d{0,4})?$/;
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === null || !numCheck.test(event.target.value)) {
            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid input...!';
            return false;
        } else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }

    const clearAllDataTipsForMaterialRate = () => {
        const materialRateInputs = document.querySelectorAll(
            '.erp_table_td input[Headers="goods_return_rate"]'
        );
        materialRateInputs.forEach((input) => {
            delete input.parentElement.dataset.tip;
        });
    };


    const openAddRecordPopUp = () => {
        switch (modalHeaderName) {

            case 'Add Customer City':
            case 'Add Company Branch City':
            case 'Payment Terms':
                return <FrmPaymentTermsEntry btn_disabled={true} />

            case 'HSN Code':
                return <FrmHSNSAC />

            default:
                return null;
        }
    }
    const handleCloseAddRecModal = async () => {
        switch (modalHeaderName) {
            case 'Add Customer City':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_customer_state_id) });
                const getCustomerUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustomerCityOpts(getCustomerUpdatedCities);
                break;

            case 'Add Company Branch City':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_companyBranchStateId) });
                const getCompanyUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCompanyBrancheCityOpts(getCompanyUpdatedCities);
                break;

            case 'Payment Terms':
                await FnGetPaymentTerms();
                break;

            case 'HSN Code':
                //get hsn codes list
                resetGlobalQuery();
                globalQuery.columns = ["field_name", "field_id", "hsn_sac_rate"];
                globalQuery.table = "cmv_hsn_sac";
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                const getHsnCode = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                setHsnCodesOptions(getHsnCode)
                console.log("Updated Hsn Code List: ", getHsnCode);
                break;
            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)
    }
    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionLabel('(Update)')
                $("input[type=radio]").attr('disabled', true); 
                break;
            case 'view':
                setActionLabel('(View)')
                // await validate.current.readOnly("moduleFormId");
                $("input[type=radio]").attr('disabled', true);
                 break;
            case 'approve':
                setActionLabel('(Approve)')
                setGoodReturnStatus("A")
                $("input[type=radio]").attr('disabled', true);
                // await validate.current.readOnly("moduleFormId");
                break;
            default:
                setActionLabel('(Creation)')
                break;
        }
    };

    const fillComobos = async () => {
        debugger
        try {
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getProductType => {
                    setmaterialtypeOptions(getProductType)
                    console.log("Product Type List: ", getProductType);
                });
            if (keyForViewUpdate === '') {
                setmaterialType('12');
                // await generateGoodReturnsNo();
            }

            resetGlobalQuery();
            globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
            globalQuery.table = "am_properties"
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'salesAndDispatch' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let getSaleDispatchAPICall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            setSaleDispatchTypeOpt(getSaleDispatchAPICall);

            //supplier options
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("supplier_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("supp_branch_state_id");
            globalQuery.columns.push("supp_branch_city_id");
            globalQuery.columns.push("supp_branch_gst_no");
            globalQuery.table = "cmv_supplier_branch";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "supp_branch_type", operator: "=", value: "Supplier" });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getSupplierList => {
                    //  ** Changes for the Searchable Combo
                    const suppliers = [
                        { value: '', label: 'Select', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                        ...getSupplierList.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, supplierGST: supplier.supp_branch_gst_no, supplier_id: supplier.supplier_id })),
                    ];
                    console.log("suppliers details Info", suppliers);
                    setSupplierOptions(suppliers);
                    console.log("Supplier List: ", getSupplierList);
                });

            resetGlobalQuery();
            // globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
            globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];

            globalQuery.table = "am_properties"
            // globalQuery.conditions.push({ field: "property_master_id", operator: "=", value: 180 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'Sales Type' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then(getJobTypeAPICall => {
                setJobTypeArr(getJobTypeAPICall);
            })

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getEmployee = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setEmployeeOptions(getEmployee)


            resetGlobalQuery();
            globalQuery.columns = ["field_name", "field_id", "hsn_sac_rate"];
            globalQuery.table = "cmv_hsn_sac";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getHsnSacCode => {
                    setHsnCodesOptions(getHsnSacCode);
                    console.log("Hsn Code List: ", getHsnSacCode);
                });



            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'is_sez', 'cust_branch_type']
            globalQuery.table = "cmv_customer_summary"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(customerList => {
                    const customerComboList = [
                        { value: "", label: "Select" },
                        { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                        ...customerList.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name, is_sez: customer.is_sez })),
                    ];
                    setCustomerOpts(customerComboList);
                    setConsigneeOpts(customerComboList);
                    console.log(customerList)
                });

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'state_code'];
            globalQuery.table = "cmv_state"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(stateList => {
                    setStateOpts(stateList);
                });

            comboDataAPiCall.current.fillMasterData("smv_product_unit", "is_delete", "0")
                .then(unitList => {
                    setProductUnitOpts(unitList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['hsn_sac_id', 'hsn_sac_code', 'hsn_sac_rate'];
            globalQuery.table = "cmv_hsn_sac"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(hsnList => {
                    setFreightHSNOpts(hsnList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['company_branch_id', 'company_branch_name', 'branch_state_id', 'branch_city_id'];
            globalQuery.table = "cmv_company_branch"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            const getCompanyBranchesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setCompanyBranchesOpts(getCompanyBranchesApiCall);
            setCompanyBranchId(parseInt(COMPANY_BRANCH_ID));
            const branchInSession = getCompanyBranchesApiCall.find((branch) => branch.company_branch_id === parseInt(COMPANY_BRANCH_ID));
            if (branchInSession !== null) {
                setCompanyBranchStateId(branchInSession.branch_state_id)
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(branchInSession.branch_state_id) });
                const cityApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setCompanyBrancheCityOpts(cityApiCall);
                setCompanyBranchCityId(branchInSession.branch_city_id)
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }


    const comboOnChange = async (key) => {
        switch (key) {
            case 'materialTypeId':
                var materialTypeId = document.getElementById('cmb_material_type').value;
                if (materialTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                setmaterialType(materialTypeId)
                if (materialTypeId !== "0" && materialTypeId !== "") {
                    $('#error_cmb_material_type').hide();
                    // await generateGoodReturnsNo();
                } else {
                    setGoodsReturnNo("0")
                }
                break;



            case 'jobType':
                // setIsLoading(true);
                // debugger

                // const currentYear = new Date().getFullYear();
                // const prevYear = currentYear - 1;

                // const shortPrevYear = prevYear.toString().slice(-2);
                // const shortCurrentYear = currentYear.toString().slice(-2);

                // const formattedYears = `${shortPrevYear}-${shortCurrentYear}`;


                // let shortname = job_type_ref.current['property_group'];
                // let job_type_id = parseInt(job_type_ref.current['property_id']);
                // const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GoodsReturnsDetails/FnGenerateYarnSaleChallanNo/${parseInt(COMPANY_ID)}/${job_type_id}`);
                // let response = await apiCall.text();
                // let formattedCounter;
                // if (!response || response.trim() === "") {
                //     // If response is empty or null, start from "00001"
                //     formattedCounter = `${shortname}${"1".padStart(5, "0")}`;
                // } else {
                //     // Extract and increment the numeric part
                //     response = parseInt(response.split("/")[3].replace(/\D/g, ""), 10);
                //     formattedCounter = `${shortname}${(response + 1).toString().padStart(5, "0")}`;
                // }

                // // Set the Dispatch Challan Number
                // setGoodsReturnNo(`${SHORT_COMPANY}/${SHORT_FINANCIAL_YEAR}/PRM/${formattedCounter}`);


                // setIsLoading(false);
                break;

            case 'customer':
                $('#error_cmb_customer_state_id').hide();
                $('#error_cmb_customer_id').hide();
                setCustGStNO('');
                setCustAddress('');
                setCustomerPhoneNo('');
                let selectedCustomer = customerComboRef.current.value
                if (selectedCustomer === 0) {
                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) { newTab.focus(); }

                } else if (selectedCustomer !== "0" && !isNaN(selectedCustomer)) {
                    resetGlobalQuery();
                    globalQuery.columns = ['cust_branch_state_id', 'state_name', 'cust_branch_city_id', 'city_name', 'cust_branch_phone_no', 'cust_branch_gst_no', 'cust_branch_address1'];
                    globalQuery.table = "cmv_customer";
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                    const getCustomerDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    if (getCustomerDetailsApiCall.length > 0) {
                        setCustomerState(getCustomerDetailsApiCall[0].cust_branch_state_id);
                        await comboOnChange('CustomerState');
                        setCustomerCity(getCustomerDetailsApiCall[0].cust_branch_city_id);
                        setCustAddress(getCustomerDetailsApiCall[0].cust_branch_address1);
                        setCustGStNO(getCustomerDetailsApiCall[0].cust_branch_gst_no);
                        setCustomerPhoneNo(getCustomerDetailsApiCall[0].cust_branch_phone_no);
                    } else {
                        setCustomerState('');
                        setCustomerCity('');
                        setCustGStNO('');
                        setCustAddress('');
                        setCustomerPhoneNo('');
                        setCustomerCityOpts([]);
                    }
                } else {
                    setCustomerState('');
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                }
                break;
            case 'CustomerState':
                let customerState = parseInt($('#cmb_customer_state_id').val());
                if ($('#cmb_customer_state_id').val() === '0') {

                } else if (customerState != null && !isNaN(customerState)) {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name'];
                    globalQuery.table = "cmv_city"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(customerState) });
                    const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCustomerCityOpts(getCustomerCitiesApiCall);
                    if (getCustomerCitiesApiCall.length <= 0) { setCustomerCity(''); }
                    // updateTblCalculationStateChange();
                } else {
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                }
                break;

            case 'CustomerCity':
                if ($('#cmb_customer_city_id').val() === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Customer City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setCustomerCity('');
                }
                break;
            case 'Consignee':
                debugger
                setConsingeeAddress('');
                setConsigneeGStNO('');
                setConsigneePhoneNo('');
                let selectedConsigneeId = consigneeComboRef.current.value
                if (selectedConsigneeId === "0") {
                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                } else if (selectedConsigneeId !== "" && !isNaN(selectedConsigneeId)) {
                    resetGlobalQuery();
                    // globalQuery.columns.push("cust_branch_state_id");
                    // globalQuery.columns.push("cust_branch_city_id");
                    globalQuery.columns = ['cust_branch_state_id', 'cust_branch_city_id', 'city_name', 'cust_branch_phone_no', 'cust_branch_gst_no', 'cust_branch_address1'];
                    globalQuery.table = "cmv_customer"
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedConsigneeId });
                    const getConsineeDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    if (getConsineeDetailsApiCall.length > 0) {
                        setConsigneeState(getConsineeDetailsApiCall[0].cust_branch_state_id);
                        comboOnChange('ConsigneeState');
                        setConsigneeCity(getConsineeDetailsApiCall[0].cust_branch_city_id === 0 ? "" : getConsineeDetailsApiCall[0].cust_branch_city_id);
                        setConsingeeAddress(getConsineeDetailsApiCall[0].cust_branch_address1);
                        setConsigneeGStNO(getConsineeDetailsApiCall[0].cust_branch_gst_no);
                        setConsigneePhoneNo(getConsineeDetailsApiCall[0].cust_branch_phone_no);
                    } else {
                        setConsigneeState('');
                        setConsigneeCity('');
                        setConsingeeAddress('');
                        setConsigneeGStNO('');
                        setConsigneePhoneNo('');
                        setConsigneeCityOpts([]);
                    }
                } else {
                    setConsigneeState('');
                    setConsigneeCity('');
                    setConsigneeCityOpts([]);
                }
                break;

            case 'ConsigneeState':
                let consigneeState = parseInt($('#cmb_consignee_state_id').val());
                if (consigneeState != null && !isNaN(consigneeState)) {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name'];
                    globalQuery.table = "cmv_city"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(consigneeState) });
                    const getConsigneeCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setConsigneeCityOpts(getConsigneeCitiesApiCall);
                    if (getConsigneeCitiesApiCall.length <= 0) { setConsigneeCity(''); }
                } else {
                    setConsigneeCityOpts([]);
                    setConsigneeCity('');
                }
                break;

            case 'ConsigneeCity':
                if ($('#cmb_consignee_city_id').val() === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Consignee City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200);
                    setConsigneeCity('');
                }
                break;
            case 'set_no':
                $("#error_cmb_set_no").hide();
                await FnShowBottomDetails();
                break;

            case "fresh_yarn":
                setMaterialRequisitionDetails([]);
                setWarpingBottomDetailsData([]);
                setYarnReturnDetails([]);
                setSetNoOptions([]);
                SetGodownId("2");

                break;
            case "bottom_yarn":
                setMaterialRequisitionDetails([]);
                setYarnReturnDetails([]);
                setWarpingBottomDetailsData([]);
                setSetNoOptions([]);
                SetGodownId("18");
                break;
            case 'salesDispatchType':
                let salesDispatchType = sales_dispatch_type_ref.current?.['property_group'] || "";
                if (salesDispatchType) {
                    resetGlobalQuery();
                    globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
                    globalQuery.table = "am_properties"
                    globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchHSNTaxType' });
                    globalQuery.conditions.push({ field: "property_group", operator: "=", value: salesDispatchType });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    let getSaleDispatchTaxAPICall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                    setDispatchHSNTaxTypeOpt(getSaleDispatchTaxAPICall);

                    resetGlobalQuery();
                    globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
                    globalQuery.table = "am_properties"
                    globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchSalesType' });
                    globalQuery.conditions.push({ field: "property_group", operator: "=", value: salesDispatchType });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    let getDispatchSalesAPICall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                    setDispatchSalesTypeOpt(getDispatchSalesAPICall);


                    resetGlobalQuery();
                    globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group', 'remark'];
                    globalQuery.table = "am_properties"
                    globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchVoucherType' });
                    globalQuery.conditions.push({ field: "property_group", operator: "=", value: salesDispatchType });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    let getDispatchVoucherAPICall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                    setDispatchVoucherTypeOpt(getDispatchVoucherAPICall);
                    break;
                } else {
                    // Handle case where salesDispatchType is empty, undefined, or null
                    setDispatchHSNTaxTypeOpt([]);
                    setDispatchSalesTypeOpt([]);
                    setDispatchVoucherTypeOpt([]);
                }
            case 'salesDispatchVoucherType':
                let Remark = dispatch_voucher_type_ref.current?.['remark'];
                if (Remark && Remark !== "Select") {
                    const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_goods_return_master", "goods_return_no", "PRM", Remark, "5", "product_type_id", "12");
                    setGoodsReturnNo(autoNoApiCall);
                }
                break;
            default:
                break;
        }
    }


    // Fn for show indent summary modal details
    const FnShowBottomDetails = async () => {
        debugger
        try {
            setIsLoading(true)
            let selected_setNo = setNoComboRef.current.value;
            if (selected_setNo !== "") {
                resetGlobalQuery();
                globalQuery.columns = ['btm.product_material_id', 'btm.warping_bottom_stock_id', 'btm.sr_no', 'btm.batch_no', 'btm.creel_no', 'btm.weight_per_pkg', 'btm.no_of_boxes',
                    'btm.cone_type_value', 'btm.cone_type', 'btm.package_type', 'btm.gross_weight', 'btm.no_of_package', 'btm.set_no', 'btm.bottom_return_no',
                    'btm.tare_weight', 'btm.net_weight', 'btm.warping_production_code', 'btm.warping_bottom_details_production_date', 'btm.weaving_production_warping_bottom_details_id',
                    'btm.weaving_production_warping_master_id', 'btm.customer_name', 'btm.supplier_name',
                    'sm.product_rm_name As product_material_name', 'gdn.godown_id', 'gdn.godown_name',
                    // 'cust.customer_name', 'sup.supp_branch_name As supplier_name','plan.customer_id', 'warpm.supplier_id',

                ];
                globalQuery.table = "sm_warping_bottom_stock_details btm";
                globalQuery.conditions.push({ field: "btm.set_no", operator: "=", value: selected_setNo, });
                globalQuery.conditions.push({ field: "btm.stock_status", operator: "=", value: "A" });
                globalQuery.conditions.push({ field: "btm.is_delete", operator: "=", value: 0, });

                globalQuery.joins = [

                    {
                        "table": `sm_product_rm sm`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "sm.product_rm_id",
                                "right": "btm.product_material_id",
                            },

                            {
                                "left": "sm.is_delete",
                                "right": "0"
                            }
                        ]
                    },

                    {
                        "table": `cm_godown gdn`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "gdn.godown_id",
                                "right": "18",
                            },

                            {
                                "left": "gdn.is_delete",
                                "right": "0"
                            }
                        ]
                    },

                ];
                const issueDetailsList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                const transformedData = issueDetailsList.flatMap(item => {
                    if (item.cone_type.includes(",")) {
                        const [coneType, boraBox] = item.cone_type.split(",");
                        return [
                            { ...item, cone_type: coneType.trim(), bora_box: boraBox.trim() }
                        ];
                    } else {
                        return [{ ...item, bora_box: "" }];
                    }
                });

                const updatedData = transformedData.map(item => ({
                    ...item,
                    bottom_return_item_status: 'P'
                }));

                // setWarpingBottomDetailsData(updatedData);
                setWarpingBottomDetailsData(prevData => [...prevData, ...updatedData]);
            } else {
                setWarpingBottomDetailsData([])
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }
    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            setShowAddUpdateBtn(true)
            setIsLoading(true)
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GoodsReturnsDetails/FnShowParticularRecordForUpdate/${idList}/${COMPANY_ID}`)
            const response = await apiCall.json();

            let masterData = response.GoodReturnsMasterData
            let detailsData = response.GoodReturnsDetailsRecord
            setYarnReturnMasterDataJson(masterData)
            setYarnReturnDetails(detailsData)
            setWarpingBottomDetailsData(response.goodsReturnBottomDetails);
            setmaterialType(masterData.product_type_id)
            setYarn_type(masterData.yarn_type)
            setGoodsReturnNo(masterData.goods_return_no)
            setInvoiceRefNo(masterData.invoice_no)
            // setGRNno(masterData.goods_receipt_no)
            setGoodsReturnDate(masterData.goods_return_date)
            setGoodsReturnVersion(masterData.goods_version)
            // setSupplier_id(masterData.supplier_id)
            setTransportMode(masterData.transport_mode);
            // setSalesJobType(masterData.sales_type)
            resetGlobalQuery();
            // globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
            globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];

            globalQuery.table = "am_properties"
            // globalQuery.conditions.push({ field: "property_master_id", operator: "=", value: 180 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'Sales Type' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then(getJobTypeAPICall => {
                setJobTypeArr(getJobTypeAPICall);
            })
            setSalesJobType(masterData.sales_type_id)
            job_type_ref.current.property_id = masterData.sales_type_id
            job_type_ref.current.property_name = masterData.sales_type
            setGoodReturnStatus(masterData.goods_return_status)
            setBasicTotal(masterData.basic_total);
            setTransportAmt(masterData.transport_amount);
            setFreightAmt(masterData.freight_amount);
            setFreightHSNId(masterData.freight_hsn_code_id);
            setFreightIsTaxable(masterData.is_freight_taxable);
            setPackingAmt(masterData.packing_amount);
            setDiscountPercent(masterData.discount_percent);
            setDiscountAmt(masterData.discount_amount);
            setOtherAmt(masterData.other_amount);
            setTaxableTotal(masterData.taxable_total);
            setCGSTTotal(masterData.cgst_total);
            setSGSTTotal(masterData.sgst_total);
            setIGSTTotal(masterData.igst_total);
            setGrandTotal(masterData.grand_total);
            setRoundOff(masterData.roundoff);
            setCustomerId(masterData.customer_id);
            setCustomerState(masterData.customer_state_id);
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_city"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "state_id", operator: "=", value: masterData.customer_state_id });
            const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setCustomerCityOpts(getCustomerCitiesApiCall);
            setCustomerCity(masterData.customer_city_id);
            setConsigneeId(masterData.consignee_id);
            setConsigneeState(masterData.consignee_state_id);
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_city"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "state_id", operator: "=", value: masterData.consignee_state_id });
            const getConsigneeCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setConsigneeCityOpts(getConsigneeCitiesApiCall);
            setConsigneeCity(masterData.consignee_city_id);


            setConsigneeGStNO(masterData.consi_branch_gst_no);
            setConsigneePhoneNo(masterData.consi_branch_phone_no);
            setConsingeeAddress(masterData.consi_branch_address1);
            setCustAddress(masterData.cust_branch_address1);
            setCustGStNO(masterData.cust_branch_gst_no);
            setCustomerPhoneNo(masterData.cust_branch_phone_no);
            setDriverName(masterData.driver_name);
            setVehicalNo(masterData.vehical_no);


            sales_dispatch_type_ref.current = { property_group: masterData.sales_dispatch_type };
            setSalesDispatchType(masterData.sales_dispatch_type)
            setDispatchHsnTaxType(masterData.dispatch_hsnTax_type)
            setDispatchSalesType(masterData.dispatch_sales_type)
            setDispatchVoucherType(masterData.dispatch_voucher_type)
            await comboOnChange("salesDispatchType");



            if (keyForViewUpdate === 'approve') {
                setGoodReturnStatus("A")
                setApprovedById(UserId)
                setApproveDate(todayDate)
            } else {
                setApprovedById(masterData.approved_by_id)
                setApproveDate(masterData.approved_date)
            }
            await comboOnChange("supplier")
            setYarnReturnDetails(detailsData);


            // Set Sales order Payment terms data.
            if (response.goodsReturnsPaymentTerms !== null) {
                const existingYarnReturnPmtTermsData = response.goodsReturnsPaymentTerms;
                setPmtTermsDataResp(existingYarnReturnPmtTermsData);
            }
            //sales type both
            $('#nxtBtn').hide();


            switch (response.is_active) {
                case true:
                    document.querySelector('input[name="isSupplierActive"][value="1"]').checked = true;
                    break;
                case false:
                    document.querySelector('input[name="isSupplierActive"][value="0"]').checked = true;
                    break;
            }
            setIsLoading(false)

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnValidateSupplierCombo = () => {
        $("#error_cmb_supplier_id").hide();
        $("#error_cmb_grn_no").hide();
    }


    const FnValidateYarnReturn = async () => {
        debugger
        let headerDataIsValid = await validate.current.validateForm("GoodsReturnEntryFormId");
        if (!headerDataIsValid) { return false; }
        if (cmb_material_type === "") {
            $("#error_cmb_material_type").text("Please fill this field...!");
            $("#error_cmb_material_type").show();
            $("#cmb_material_type").focus();
            return false;
        }

        // Check combo values for customer combo
        if (cmb_material_type !== "" && cmb_sales_job_type === "") {
            $("#error_cmb_sales_job_type_id").text("Please fill this field...!");
            $("#error_cmb_sales_job_type_id").show();
            $("#cmb_sales_job_type_id").focus();
            return false;
        }
        if (cmb_sales_dispatch_type === "") {
            $("#error_cmb_sales_dispatch_type").text("Please fill this field...!");
            $("#error_cmb_sales_dispatch_type").show();
            $("#cmb_sales_dispatch_type").focus();
            return false;
        }
        if (cmb_dispatch_sales_type === "") {
            $("#error_cmb_dispatch_sales_type").text("Please fill this field...!");
            $("#error_cmb_dispatch_sales_type").show();
            $("#cmb_dispatch_sales_type").focus();
            return false;
        }

        if (cmb_dispatch_voucher_type === "") {
            $("#error_cmb_dispatch_voucher_type").text("Please fill this field...!");
            $("#error_cmb_dispatch_voucher_type").show();
            $("#cmb_dispatch_voucher_type").focus();
            return false;
        }

        let issueDetailsIsValid = false;
        let addedIssuematerial = $('#issue-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one Yarn Sale Detail.');
            setShowErrorMsgModal(true);
            return issueDetailsIsValid;
        } else {
            const tableRows = $('#issue-details-tbl tbody tr');
            issueDetailsIsValid = true;
            tableRows.each(function () {

                if (keyForViewUpdate === "" || keyForViewUpdate === "update") {
                    const Goods_return_quantity = parseFloat($(this).find('input[id^="goods_return_quantity_"]').val());
                    if (isNaN(Goods_return_quantity) || Goods_return_quantity === "" || Goods_return_quantity === 0) {
                        $(this).find(`input[id^='goods_return_quantity_']`).parent().attr('data-tip', 'Please enter a valid Quantity...!');
                        $(this).find(`input[id^='goods_return_quantity_']`).focus();
                        setOpenYarnDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;

                    }
                }
                if (keyForViewUpdate === "" || keyForViewUpdate === "update") {
                    const selectedHsnSacCode = $(this).find('select[id^="product_hsn_sac_code_id_"]').val(); // Get selected value
                    if (!selectedHsnSacCode || selectedHsnSacCode === "0") {
                        $(this).find(`select[id^='product_hsn_sac_code_id_']`).parent().attr('data-tip', 'Please select a valid HSN/SAC code...!');
                        $(this).find(`select[id^='product_hsn_sac_code_id_']`).focus();
                        setOpenYarnDetailsAccord(true);
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
                if (keyForViewUpdate === "" || keyForViewUpdate === "update") {


                    const Goods_return_rate = parseFloat($(this).find('input[id^="goods_return_rate_"]').val());
                    // const isJobWork = cmb_sales_job_type === "Job Work";
                    const isJobWork = job_type_ref.current.property_name === "Job Work";


                    if (isNaN(Goods_return_rate) || Goods_return_rate === "" || (!isJobWork && Goods_return_rate === 0)) {
                        const errorMessage = isJobWork
                            ? "Please enter a valid rate (0 or greater) for Job Work...!"
                            : "Please enter a valid rate greater than 0...!";

                        $(this).find(`input[id^='goods_return_rate_']`).parent().attr('data-tip', errorMessage);
                        $(this).find(`input[id^='goods_return_rate_']`).focus();
                        setOpenYarnDetailsAccord(true);
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
                if (keyForViewUpdate === "" || keyForViewUpdate === "update") {

                    const Goods_return_wt = parseFloat($(this).find('input[id^="goods_return_weight_"]').val());
                    if (isNaN(Goods_return_wt) || Goods_return_wt === "" || Goods_return_wt === 0) {
                        $(this).find(`input[id^='goods_return_weight_']`).parent().attr('data-tip', 'Please enter a valid weight...!');
                        $(this).find(`input[id^='goods_return_weight_']`).focus();
                        setOpenYarnDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
            });
            return issueDetailsIsValid;
        }
    }
    const validateWeightsBeforeSave = (warpingData, yarnData) => {
        return warpingData.reduce((isValid, warpItem) => {
            const yarnItem = yarnData.find(yarn => yarn.product_rm_id === warpItem.product_material_id);

            if (yarnItem) {
                // Sum all the gross weights for the same product_material_id
                const totalGrossWeight = warpingData
                    .filter(item => item.product_material_id === warpItem.product_material_id)
                    .reduce((sum, item) => sum + parseFloat(item.net_weight), 0);

                // Check if the total gross weight matches the goods return weight
                if (parseFloat(totalGrossWeight) !== parseFloat(yarnItem.goods_return_weight)) {
                    setErrMsg('Please select proper gross weight.');
                    setShowErrorMsgModal(true);
                    return false; // Return false if mismatch is found
                }
            }
            return isValid;
        }, true);
    };


    const FnAddUpdateRecord = async () => {
        debugger
        try {
            let checkIsValidate = true;

            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                checkIsValidate = await FnValidateYarnReturn();
                if (checkIsValidate) {
                    checkIsValidate = validateWeightsBeforeSave(warpingBottomDetailsData, yarnReturnDetailsData); // Second validation
                }
            }
            setIsLoading(true)
            let lastGoods_return_no = txt_goods_return_no;
            if (keyForViewUpdate === "") {
                lastGoods_return_no = await generateGoodReturnsNo();
            }

            if (checkIsValidate === true) {
                const json = { 'GoodsReturnMasterData': {}, 'GoodsReturnDetailData': [], 'TransPaymentTermsData': [], 'warpingbottomDetailsArray': [] }
                const masterData = {
                    goods_return_master_id: keyForViewUpdate === "" ? 0 : idList,
                    product_type_id: cmb_material_type,
                    goods_return_no: lastGoods_return_no,
                    goods_return_date: dt_goods_return_date,
                    // goods_receipt_no: cmb_grn_no,
                    yarn_type: chk_yarn_type,
                    goods_version: txt_goods_return_version,
                    invoice_no: txt_invoice_no,
                    sales_type: $('#cmb_sales_job_type_id').find(":selected").text().trim(),
                    sales_type_id: cmb_sales_job_type,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    approved_by_id: keyForViewUpdate === "approve" ? cmb_approved_by_id : '',
                    approved_date: keyForViewUpdate === "approve" ? dt_approved_date : '',
                    goods_return_status: keyForViewUpdate === "approve" ? "A" : "P",
                    is_active: true,
                    is_delete: false,//
                    created_by: UserName,
                    modified_by: idList !== "" ? UserName : null,

                    driver_name: txt_driver_name,
                    vehical_no: txt_vehical_no,
                    transport_mode: txt_transport_mode,
                    customer_state_id: cmb_customer_state_id,
                    customer_city_id: cmb_customer_city_id,
                    customer_id: cmb_customer_id,
                    consignee_id: cmb_consignee_id,
                    consignee_state_id: cmb_consignee_state_id,
                    consignee_city_id: cmb_consignee_city_id,
                    basic_total: txt_basic_total,
                    transport_amount: txt_transport_amount,
                    freight_amount: txt_freight_amount,
                    is_freight_taxable: chk_freight_isTaxable,
                    freight_hsn_code_id: cmb_freight_hsn_id,
                    packing_amount: txt_packing_amount,
                    discount_percent: txt_discount_percent,
                    discount_amount: txt_discount_amount,
                    other_amount: txt_other_amount,
                    cgst_percent: txt_cgst_percent,
                    cgst_total: txt_cgst_total,
                    sgst_percent: txt_sgst_percent,
                    sgst_total: txt_sgst_total,
                    igst_percent: txt_igst_percent,
                    igst_total: txt_igst_total,
                    taxable_total: txt_taxable_total,
                    roundoff: txt_roundoff,
                    grand_total: txt_grand_total,
                    sales_dispatch_type: cmb_sales_dispatch_type,
                    dispatch_hsnTax_type: cmb_dispatch_hsnTax_type,
                    dispatch_sales_type: cmb_dispatch_sales_type,
                    dispatch_voucher_type: cmb_dispatch_voucher_type,

                };

                for (let returnItem = 0; returnItem < yarnReturnDetailsData.length; returnItem++) {
                    const indentIssueDetailsRow = yarnReturnDetailsData[returnItem];

                    const indentReturnRow = {};
                    indentReturnRow.company_id = COMPANY_ID
                    indentReturnRow.company_branch_id = COMPANY_BRANCH_ID
                    indentReturnRow.financial_year = FINANCIAL_SHORT_NAME
                    indentReturnRow.goods_return_details_id = keyForViewUpdate !== "" ? indentIssueDetailsRow.goods_return_details_id : 0
                    indentReturnRow.product_rm_id = indentIssueDetailsRow.product_rm_id
                    indentReturnRow.goods_return_quantity = indentIssueDetailsRow.goods_return_quantity
                    indentReturnRow.goods_return_weight = indentIssueDetailsRow.goods_return_weight
                    indentReturnRow.goods_return_boxes = indentIssueDetailsRow.goods_return_boxes || 0
                    indentReturnRow.goods_return_rate = indentIssueDetailsRow?.goods_return_rate
                    indentReturnRow.goods_return_remark = indentIssueDetailsRow?.goods_return_remark
                    indentReturnRow.godown_id = indentIssueDetailsRow?.godown_id
                    indentReturnRow.godown_section_id = indentIssueDetailsRow?.godown_section_id
                    indentReturnRow.godown_section_beans_id = indentIssueDetailsRow?.godown_section_beans_id
                    indentReturnRow.issue_batch_no = indentIssueDetailsRow?.issue_batch_no
                    indentReturnRow.cone_per_wt = indentIssueDetailsRow?.cone_per_wt
                    indentReturnRow.goods_receipt_no = indentIssueDetailsRow?.goods_receipt_no
                    indentReturnRow.product_material_unit_id = indentIssueDetailsRow.product_material_unit_id || "1"
                    indentReturnRow.product_material_packing_id = indentIssueDetailsRow.product_material_packing_id || 0
                    indentReturnRow.product_hsn_sac_code_id = indentIssueDetailsRow?.product_hsn_sac_code_id
                    indentReturnRow.product_hsn_sac_rate = indentIssueDetailsRow?.product_hsn_sac_rate
                    indentReturnRow.material_basic_amount = indentIssueDetailsRow?.material_basic_amount
                    indentReturnRow.material_discount_percent = indentIssueDetailsRow?.material_discount_percent
                    indentReturnRow.material_discount_amount = indentIssueDetailsRow?.material_discount_amount
                    indentReturnRow.material_taxable_amount = indentIssueDetailsRow?.material_taxable_amount
                    indentReturnRow.material_cgst_percent = indentIssueDetailsRow?.material_cgst_percent
                    indentReturnRow.material_cgst_total = indentIssueDetailsRow?.material_cgst_total
                    indentReturnRow.material_sgst_percent = indentIssueDetailsRow?.material_sgst_percent
                    indentReturnRow.material_sgst_total = indentIssueDetailsRow?.material_sgst_total
                    indentReturnRow.material_igst_percent = indentIssueDetailsRow?.material_igst_percent
                    indentReturnRow.material_igst_total = indentIssueDetailsRow?.material_igst_total
                    indentReturnRow.material_freight_amount = indentIssueDetailsRow?.material_freight_amount
                    indentReturnRow.material_total_amount = indentIssueDetailsRow?.material_total_amount

                    json.GoodsReturnDetailData.push(indentReturnRow);

                }
                $("input:checkbox[name=selectPmtTerm]:checked").each(function () {
                    let findPaymentTermsData = PmtTermsdata.find(item => item.payment_terms_id === parseInt($(this).val()));
                    let payment_term_idVal = parseInt($(this).val());
                    const paymentTermsData = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        goods_return_no: lastGoods_return_no,
                        goods_return_date: dt_goods_return_date,
                        goods_version: txt_goods_return_version,
                        goods_return_details_id: 0,
                        payment_terms_id: payment_term_idVal,
                        payment_terms_name: findPaymentTermsData.payment_terms_name,
                        payment_terms_days: findPaymentTermsData.payment_terms_days,
                        payment_terms_grace_days: findPaymentTermsData.payment_terms_grace_days,
                        payment_terms_Milestome: $('#payment_milestone_' + payment_term_idVal).val(),
                        payment_percent: $('#payment_percent_' + payment_term_idVal).val(),
                        payment_expected_value: $('#payment_expected_value_' + payment_term_idVal).val(),
                        payment_expected_date: $('#payment_expected_date_' + payment_term_idVal).val(),
                        remark: findPaymentTermsData.remark,
                    }
                    json.TransPaymentTermsData.push(paymentTermsData);
                });

                json.GoodsReturnMasterData = masterData;

                for (let returnItem = 0; returnItem < warpingBottomDetailsData.length; returnItem++) {
                    const warpingBottomRow = warpingBottomDetailsData[returnItem];

                    const indentReturnRow = {};
                    indentReturnRow.company_id = COMPANY_ID
                    indentReturnRow.company_branch_id = COMPANY_BRANCH_ID
                    indentReturnRow.financial_year = FINANCIAL_SHORT_NAME
                    indentReturnRow.goods_return_no = lastGoods_return_no
                    indentReturnRow.bottom_return_no = warpingBottomRow.bottom_return_no
                    indentReturnRow.goods_return_bottom_date = dt_goods_return_date
                    indentReturnRow.goods_return_bottom_details_id = keyForViewUpdate !== "add" ? warpingBottomRow.goods_return_bottom_details_id : 0
                    indentReturnRow.product_material_id = warpingBottomRow.product_material_id
                    indentReturnRow.no_of_package = warpingBottomRow.no_of_package
                    indentReturnRow.gross_weight = warpingBottomRow.gross_weight
                    indentReturnRow.net_weight = warpingBottomRow.net_weight || 0
                    indentReturnRow.tare_weight = warpingBottomRow?.tare_weight
                    indentReturnRow.godown_id = warpingBottomRow?.godown_id
                    indentReturnRow.godown_name = warpingBottomRow?.godown_name
                    indentReturnRow.godown_section_id = "57"
                    indentReturnRow.godown_section_beans_id = "848"
                    indentReturnRow.product_material_name = warpingBottomRow?.product_material_name
                    indentReturnRow.goods_return_master_id = keyForViewUpdate !== "add" ? warpingBottomRow.goods_return_master_id : 0
                    indentReturnRow.batch_no = warpingBottomRow?.batch_no
                    indentReturnRow.weight_per_pkg = warpingBottomRow?.weight_per_pkg
                    indentReturnRow.supplier_id = warpingBottomRow?.supplier_id
                    indentReturnRow.supplier_name = warpingBottomRow?.supplier_name
                    indentReturnRow.customer_id = warpingBottomRow?.customer_id
                    indentReturnRow.customer_name = warpingBottomRow?.customer_name
                    indentReturnRow.creel_no = warpingBottomRow?.creel_no
                    indentReturnRow.set_no = warpingBottomRow?.set_no
                    indentReturnRow.cone_type_value = warpingBottomRow?.cone_type_value
                    indentReturnRow.cone_type = warpingBottomRow?.cone_type
                    indentReturnRow.bora_box = warpingBottomRow?.bora_box
                    indentReturnRow.package_type = warpingBottomRow?.package_type
                    indentReturnRow.sr_no = warpingBottomRow?.sr_no
                    indentReturnRow.no_of_boxes = warpingBottomRow?.no_of_boxes || 0
                    indentReturnRow.warping_bottom_stock_id = warpingBottomRow?.warping_bottom_stock_id || 0
                    indentReturnRow.created_by = UserName
                    indentReturnRow.modified_by = idList !== "" ? UserName : null

                    json.warpingbottomDetailsArray.push(indentReturnRow);

                }
                // json.GoodsReturnDetailData = detailsDataArray;
                const formData = new FormData()
                formData.append('GoodsReturnsDetails', JSON.stringify(json))
                const forwardData = {
                    method: 'POST',
                    body: formData,
                }
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GoodsReturnsDetails/FnAddUpdateRecord`, forwardData)
                const responce = await apiCall.json();
                console.log("response error: ", responce.data);
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    setSuccMsg(responce.message);
                    setShowSuccessMsgModal(true);
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }

    };

    const generateGoodReturnsNo = async () => {
        // const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_goods_return_master", "goods_return_no", "PRM", prefix, "5", "product_type_id", "12");
        // setGoodsReturnNo(autoNoApiCall);
        let autoNoApiCall = null;
        let Remark = dispatch_voucher_type_ref.current?.['remark'];
        if (Remark && Remark !== "Select") {
            autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_goods_return_master", "goods_return_no", "PRM", Remark, "5", "product_type_id", "12");
            setGoodsReturnNo(autoNoApiCall);
        }
        return autoNoApiCall;
    };

    // const editUpdateRecord = (index, action) => {
    //     switch (action) {
    //         case 'delete':
    //             setYarnReturnDetails((prevGrnDetails) => {
    //                 return prevGrnDetails.filter((grnD, i) => i !== index)
    //             })
    //             setWarpingBottomDetailsData
    //             break;
    //     }
    // }

    const editUpdateRecord = (index, action) => {
        debugger
        switch (action) {
            case 'delete':
                setYarnReturnDetails((prevGrnDetails) => {
                    const deletedItem = prevGrnDetails[index]; // Get the deleted item
                    const updatedGrnDetails = prevGrnDetails.filter((_, i) => i !== index);
                    if (chk_yarn_type === "Bottom") {
                        if (deletedItem) {
                            const { product_rm_id } = deletedItem;

                            setWarpingBottomDetailsData((prevWarpingData) => {
                                // Get the set_no associated with the product_material_id before filtering
                                const deletedItem = prevWarpingData.find((warpItem, i) => i === index);

                                if (!deletedItem) return prevWarpingData; // If no item found, return existing state

                                const { product_material_id, set_no } = deletedItem;

                                // Remove entry from WarpingBottomDetailsData
                                const updatedWarpingData = prevWarpingData.filter(
                                    (warpItem) => warpItem.product_material_id !== product_rm_id
                                );

                                if (updatedGrnDetails.length === 0) {
                                    setSetNoOptions([]);
                                } else {
                                    // Check if set_no still exists in the updated list
                                    const isSetNoStillPresent = updatedWarpingData.some(
                                        (warpItem) => warpItem.product_material_id === product_rm_id
                                    );

                                    if (!isSetNoStillPresent) {
                                        setSetNoOptions((prevSetNoOptions) =>
                                            prevSetNoOptions.filter((setOption) => setOption.product_material_id !== product_rm_id)
                                        );
                                    }
                                }

                                return updatedWarpingData;
                            });

                        }
                    }

                    return updatedGrnDetails;
                });
                break;
        }
    };



    const calculateGrandTotal = (yarnReturnDetailsData) => {
        debugger
        $('#error_cmb_freight_hsn_id').hide();
        let taxSummaryData = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
        let customerState = $('#cmb_customer_state_id').val();
        let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
        let companyBranchStateId = $('#cmb_companyBranchStateId').val();
        let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
        let freightTaxHSNIsSelected = $('#cmb_freight_hsn_id').val();

        if (freightIsTaxable && (freightTaxHSNIsSelected === '' || freightTaxHSNIsSelected === null)) {
            $('#cmb_freight_hsn_id').removeAttr('optional', 'optional');
            $('#error_cmb_freight_hsn_id').text('Please select one...!');
            $('#error_cmb_freight_hsn_id').show();
            return false;
        } else if (!freightIsTaxable) {
            setFreightHSNId('');
        }

        if (yarnReturnDetailsData !== null && yarnReturnDetailsData.length > 0) {
            let material_basic_total = yarnReturnDetailsData.reduce((total, item) => total + parseFloat(item.material_taxable_amount), 0);
            setBasicTotal(validateNumberDateInput.current.decimalNumber(material_basic_total.toString(), 4));

            let cgst_total = yarnReturnDetailsData.reduce((total, item) => total + parseFloat(item.material_cgst_total), 0);
            let sgst_total = yarnReturnDetailsData.reduce((total, item) => total + parseFloat(item.material_sgst_total), 0);
            let igst_total = yarnReturnDetailsData.reduce((total, item) => total + parseFloat(item.material_igst_total), 0);

            let discAmt = isNaN(parseFloat($('#txt_discount_amount').val())) === true ? 0 : parseFloat($('#txt_discount_amount').val())
            const transportAmt = isNaN(parseFloat($('#txt_transport_amount').val())) === true ? 0 : parseFloat($('#txt_transport_amount').val())
            const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) === true ? 0 : parseFloat($('#txt_freight_amount').val())
            const packingAmt = isNaN(parseFloat($('#txt_packing_amount').val())) === true ? 0 : parseFloat($('#txt_packing_amount').val())
            const otherAmt = isNaN(parseFloat($('#txt_other_amount').val())) === true ? 0 : parseFloat($('#txt_other_amount').val())

            const taxableAmount = material_basic_total - discAmt;
            setTaxableTotal(validateNumberDateInput.current.decimalNumber(taxableAmount.toString(), 4));

            if (freightIsTaxable) {
                let taxSummary = {
                    materialId: 'Freight', so_sr_no: 'Freight',
                    hsnCode: $('#cmb_freight_hsn_id option:selected').attr('lbl'),
                    hsn_code_id: $('#cmb_freight_hsn_id').val(),
                    hsnRate: $('#cmb_freight_hsn_id option:selected').attr('rate'),
                    taxableAmt: freightAmt, CGSTPercent: 0, CGSTAmt: 0,
                    SGSTPercent: 0, SGSTAmt: 0, IGSTPercent: 0, IGSTAmt: 0, totalAmt: 0
                };

                let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
                $('#cmb_freight_hsn_id').removeAttr('optional');
                let freightTaxRate = isNaN(parseFloat($('#cmb_freight_hsn_id option:selected').attr('rate'))) === true ? 0 : parseFloat($('#cmb_freight_hsn_id option:selected').attr('rate'));
                const freightTaxAmt = (freightAmt * freightTaxRate) / 100;

                if (customerIsSez === 'true') {
                    // If customer is sezzed
                    igst_total += 0;
                    cgst_total += 0;
                    sgst_total += 0;

                } else if (custStateCode === 99) {
                    // Set outer teritory tax totals
                    igst_total += 0;
                    cgst_total += 0;
                    sgst_total += 0;

                } else if (cmb_companyBranchStateId === customerState) {
                    // Set inter state tax totals
                    taxSummary['CGSTAmt'] = (freightTaxAmt / 2);
                    taxSummary['CGSTPercent'] = (freightTaxRate / 2);
                    taxSummary['SGSTAmt'] = (freightTaxAmt / 2);
                    taxSummary['SGSTPercent'] = (freightTaxRate / 2);
                    taxSummary['totalAmt'] = (freightAmt + freightTaxAmt);

                    cgst_total += freightTaxAmt / 2;
                    sgst_total += freightTaxAmt / 2;

                } else {
                    // Set outer state tax totals
                    taxSummary['IGSTAmt'] = (freightTaxAmt);
                    taxSummary['IGSTPercent'] = (freightTaxRate);
                    taxSummary['totalAmt'] = (freightAmt + freightTaxAmt);

                    igst_total += freightTaxAmt;
                }
                const existingFreightTaxationObj = taxSummaryData.findIndex((record) => record.materialId === "Freight" && record.so_sr_no === "Freight");
                if (existingFreightTaxationObj !== -1) {
                    taxSummaryData[existingFreightTaxationObj] = taxSummary;   // Replace the object in-place
                } else {
                    taxSummaryData.push(taxSummary);                           // Push the new object if not found
                }
            } else {
                $('#cmb_freight_hsn_id').attr('optional', 'optional');
                // if it is exist in the taxation summary object then remove it
                const existingFreightTaxationObj = taxSummaryData.findIndex((record) => record.materialId === "Freight" && record.so_sr_no === "Freight");
                if (existingFreightTaxationObj !== -1) { taxSummaryData.splice(existingFreightTaxationObj, 1); }
            }
            localStorage.setItem('taxationSummary', JSON.stringify(taxSummaryData));

            setCGSTTotal(validateNumberDateInput.current.decimalNumber((cgst_total).toString(), 4));
            setSGSTTotal(validateNumberDateInput.current.decimalNumber((sgst_total).toString(), 4));
            setIGSTTotal(validateNumberDateInput.current.decimalNumber((igst_total).toString(), 4))

            let grandTotal = taxableAmount + transportAmt + freightAmt + packingAmt + otherAmt + cgst_total + sgst_total + igst_total;
            setGrandTotal(Math.round(grandTotal))

            let roundOffValue = grandTotal - Math.floor(grandTotal);

            if (roundOffValue >= 0.50) {
                roundOffValue = roundOffValue - 1; // Subtract 1 to get negative round-off
            }
            setRoundOff((roundOffValue).toFixed(2))
        } else {
            setBasicTotal(0);
            setTransportAmt(0);
            setTaxableTotal(0);
            setGrandTotal(0);
            setRoundOff(0);
            setCGSTTotal(0);
            setSGSTTotal(0);
            setIGSTTotal(0);
        }
        // FnGetTaxationSummary();
    }
    const handleFooterDiscountPercentChange = async (e) => {

        let isValidPercentageInput = validatePercentageInput(e.target.value);

        if (isValidPercentageInput === true) {
            const percentInput = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4)
            const amount = (percentInput / 100) * txt_basic_total;
            setDiscountAmt((amount).toFixed(2));
            setDiscountPercent(percentInput);
            calculateGrandTotal(yarnReturnDetailsData);
            $('#error_txt_discount_percent').hide();

        } else {
            $('#error_txt_discount_percent').text(isValidPercentageInput);
            $('#error_txt_discount_percent').show();
        }

        if (e._reactName === 'onBlur' && e.target.value === '') {
            setDiscountPercent(0);
            setDiscountPercent(0);
            calculateGrandTotal(yarnReturnDetailsData);
            delete e.target.parentElement.dataset.tip;
        }
        setTimeout(() => {
            calculateGrandTotal(yarnReturnDetailsData);
            reverseCalculationForDetailData();
        }, 200);
    };


    const handleFooterDiscountAmountChange = async (e) => {
        const amount = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4)
        const percent = (amount * 100) / txt_basic_total;

        if (e._reactName === 'onBlur' && e.target.value === '') {
            setDiscountPercent(0);
            setDiscountAmt(0);
            delete e.target.parentElement.dataset.tip;
        } else {
            setDiscountPercent((percent).toFixed(2));
            setDiscountAmt(amount);
        }

        setTimeout(() => {
            calculateGrandTotal(yarnReturnDetailsData);
            reverseCalculationForDetailData();
        }, 200);
    };


    //-------------------------------------------End Details section --------------------------------------------------------------------------
    const validateFields = () => {
        validate.current.validateFieldsOnChange('GoodsReturnEntryFormId')
    }
    const reverseCalculationForDetailData = () => {
        let yarnReturnData = [...yarnReturnDetailsData];
        // const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const discount_amount = isNaN(parseFloat($('#txt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_discount_amount').val()))

        if (yarnReturnData.length !== 0) {
            const updatedData = yarnReturnData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                let discountAmount;
                let calculatedPercent;
                if (discountAmount !== 0) {
                    discountAmount = (obj.material_basic_amount / txt_basic_total) * discount_amount;
                    calculatedPercent = (discountAmount * 100) / obj.material_basic_amount;
                }
                // Create a new object with the updated properties
                return {
                    ...obj,
                    // material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                    material_discount_amount: discountAmount === 0 ? obj.material_discount_amount : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    material_discount_percent: calculatedPercent === 0 ? obj.material_discount_percent : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2)
                };
            });
            setYarnReturnDetails(updatedData)
        }
    }

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForFreightAmt = () => {
        let yarnReturnData = [...yarnReturnDetailsData];
        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        if (yarnReturnData.length !== 0) {
            const updatedData = yarnReturnData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
                return {
                    ...obj,
                    material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                };
            });
            setYarnReturnDetails(updatedData)
        }
    }

    // ------------------------ Changes for the Searchable Combos. --------------------------




    const renderDetailsTable = useMemo(() => {
        return <>
            <Table className="erp_table erp_table_scroll" id='issue-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate === "" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Sr No.</th>
                        <th className="erp_table_th">Material name</th>
                        <th className="erp_table_th" style={{ paddingRight: '70px' }}>Unit Name</th>

                        <th className="erp_table_th">Lot No.</th>
                        {(keyForViewUpdate === "" || keyForViewUpdate === 'update') && (
                            <>
                                <th className="erp_table_th">Stock quantity (cones)</th>
                                <th className="erp_table_th">Stock Wt. (Kg)</th>
                                <th className="erp_table_th">Stock no of boxes</th>
                            </>
                        )}
                        {/* <th className="erp_table_th">Stock quantity(cones)</th>
                        <th className="erp_table_th">Stock Wt.(Kg)</th>
                        <th className="erp_table_th">Stock no of boxes</th> */}
                        <th className={`erp_table_th`}>Yarn sale Quantity</th>
                        <th className="erp_table_th">Yarn sale Weight</th>
                        <th className="erp_table_th">No of Boxes</th>
                        <th className="erp_table_th">Material Rate</th>
                        <th className="erp_table_th">Discount %</th>
                        <th className="erp_table_th">Discount Amt.</th>
                        <th className="erp_table_th" style={{ width: "auto" }}>HSN Code</th>
                        <th className="erp_table_th">HSN %</th>
                        <th className="erp_table_th">Basic Amt.</th>
                        <th className="erp_table_th">Taxable Amt.</th>
                        <th className="erp_table_th">CGST %</th>
                        <th className="erp_table_th">CGST Amt.</th>
                        <th className="erp_table_th">SGST %</th>
                        <th className="erp_table_th">SGST Amt.</th>
                        <th className="erp_table_th">IGST %</th>
                        <th className="erp_table_th">IGST Amt.</th>
                        <th className="erp_table_th">Freight Amt.</th>
                        <th className="erp_table_th">Total Amt.</th>


                        <th className="erp_table_th">Yarn Sale Remark</th>
                        <th className="erp_table_th">Godown Name</th>
                        <th className="erp_table_th">Godown section name</th>
                        <th className="erp_table_th">Godown section beans name</th>
                    </tr>
                </thead>
                <tbody>
                    {yarnReturnDetailsData.map((docItem, Index) =>
                        <tr key={Index} className="sticky-column">
                            <td className={`erp_table_td  ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}><div style={{ display: "flex", justifyContent: "center" }}>
                                <Tooltip title="Delete" placement="top">
                                    <MDTypography className="erp-view-btn">
                                        <MdDelete className="erp-delete-btn" id={`delete_btn_${Index}`} onClick={() => editUpdateRecord(Index, 'delete')} />
                                    </MDTypography>
                                </Tooltip>
                            </div>
                            </td>
                            <td className="erp_table_td">{Index + 1}</td>
                            <td className="erp_table_td">{docItem.product_material_name}</td>
                            <td className={`erp_table_td`}>
                                {
                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                        ? <select id={`product_material_unit_id_${docItem.product_id}`} value={docItem.product_material_unit_id} className="form-select form-select-sm erp_input_field m-0"
                                            Headers="product_material_unit_id" onChange={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false}  >
                                            {
                                                productUnitOpts?.map(unit => (
                                                    <option value={unit.field_id} lbl={unit.field_name} >{unit.field_name}</option>
                                                ))
                                            }
                                        </select>
                                        : docItem.product_unit_name
                                }
                            </td>
                            <td className="erp_table_td">{docItem.issue_batch_no}</td>
                            {/* <td className="erp_table_td text-end">{docItem.closing_balance_quantity}</td>
                            <td className="erp_table_td text-end">{docItem.closing_balance_weight}</td>
                            <td className="erp_table_td text-end">{docItem.closing_no_of_boxes}</td> */}
                            {(keyForViewUpdate === "" || keyForViewUpdate === 'update') && (
                                <>
                                    <td className="erp_table_td text-end">{docItem.closing_balance_quantity}</td>
                                    <td className="erp_table_td text-end">{docItem.closing_balance_weight}</td>
                                    <td className="erp_table_td text-end">{docItem.closing_no_of_boxes}</td>
                                </>
                            )}


                            <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                {
                                    keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? docItem.goods_return_quantity
                                        : <input type="text" className="erp_input_field text-end mb-0" id={`goods_return_quantity_${docItem.product_id}`} value={docItem.goods_return_quantity} maxLength='19'
                                            Headers='goods_return_quantity' onChange={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} onBlur={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} />
                                }
                            </td>

                            <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                {
                                    keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? docItem.goods_return_weight
                                        : <input type="text" className="erp_input_field text-end mb-0" id={`goods_return_weight_${docItem.product_id}`} value={docItem.goods_return_weight} maxLength='19'
                                            Headers='goods_return_weight' onChange={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} onBlur={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} />
                                }
                            </td>
                            <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                {
                                    keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? docItem.goods_return_boxes
                                        : <input type="text" className="erp_input_field text-end mb-0" id={`goods_return_boxes_${docItem.product_id}`} value={docItem.goods_return_boxes} maxLength='19'
                                            Headers='goods_return_boxes' onChange={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} onBlur={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} />
                                }
                            </td>

                            <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                {
                                    keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? docItem.goods_return_rate
                                        : <input type="text" className="erp_input_field text-end mb-0" id={`goods_return_rate_${docItem.product_id}`} value={docItem.goods_return_rate} maxLength='19'
                                            Headers='goods_return_rate' onChange={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} onBlur={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} />
                                }
                            </td>

                            <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                {
                                    keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? docItem.material_discount_percent
                                        : <input type="text" className="erp_input_field text-end mb-0" id={`material_discount_percent_${docItem.product_id}`} value={docItem.material_discount_percent}
                                            Headers='material_discount_percent' onChange={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} onBlur={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} />
                                }
                            </td>

                            <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`} >
                                {
                                    keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? docItem.material_discount_amount
                                        : <input type="text" className="erp_input_field text-end mb-0" id={`material_discount_amount_${docItem.product_id}`} value={docItem.material_discount_amount}
                                            Headers='material_discount_amount' onChange={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} onBlur={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} />
                                }
                            </td>

                            {/* <td className="erp_table_td">{docItem.product_hsn_sac_code}</td> */}
                            <td className="erp_table_td">
                                <select id="product_hsn_sac_code_id_" className="form-select form-select-sm" style={{ width: "auto" }} value={docItem.product_hsn_sac_code_id} product_rm_id={`${docItem.product_id}`} Headers='product_hsn_sac_code_id'
                                    onChange={(e) => { updateYarnReturnDetailsTblData(docItem, e); }} disabled={((keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') && docItem.product_hsn_sac_code !== '0')} >
                                    <option value="">Select</option>
                                    <option value="0">Add New Record+</option>
                                    {hsnCodesOptions.length !== 0 ?
                                        <>
                                            {hsnCodesOptions?.map(cost => (
                                                <option value={cost.field_id} product_hsn_sac_rate={cost.hsn_sac_rate} product_hsn_sac_code={cost.field_name}>{cost.field_name}</option>
                                            ))}
                                        </>
                                        : ''
                                    }
                                </select>
                            </td>
                            <td className="erp_table_td text-end">{docItem.product_hsn_sac_rate}</td>
                            <td className="erp_table_td text-end">{docItem.material_basic_amount}</td>
                            <td className="erp_table_td text-end">{docItem.material_taxable_amount}</td>
                            <td className="erp_table_td text-end">{docItem.material_cgst_percent}</td>
                            <td className="erp_table_td text-end">{docItem.material_cgst_total}</td>
                            <td className="erp_table_td text-end">{docItem.material_sgst_percent}</td>
                            <td className="erp_table_td text-end">{docItem.material_sgst_total}</td>
                            <td className="erp_table_td text-end">{docItem.material_igst_percent}</td>
                            <td className="erp_table_td text-end">{docItem.material_igst_total}</td>
                            <td className="erp_table_td text-end">{docItem.material_freight_amount}</td>
                            <td className="erp_table_td text-end">{docItem.material_total_amount}</td>

                            <td className="erp_table_td">
                                {keyForViewUpdate === '' || keyForViewUpdate === 'update' ?

                                    <input type="text" id={`goods_return_remark_${docItem.product_id}`} className="erp_input_field mb-0 issueField"
                                        value={docItem.goods_return_remark} onChange={(e) => { updateYarnReturnDetailsTblData(docItem, e, Index); }} Headers='goods_return_remark' />
                                    :
                                    docItem.goods_return_remark
                                }
                            </td>

                            <td className="erp_table_td">{docItem.godown_name}</td>
                            <td className="erp_table_td">{docItem.godown_section_name}</td>
                            <td className="erp_table_td">{docItem.godown_section_beans_name}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    }, [yarnReturnDetailsData, keyForViewUpdate, hsnCodesOptions])

    // Calculate discount amount 
    const FnCalculateMaterialWiseDiscount = (currentMaterial) => {
        const { material_discount_percent, material_basic_amount, } = currentMaterial;
        const calculatedAmount = (material_discount_percent / 100) * material_basic_amount;
        currentMaterial.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
    }
    const FnMaterialWiseCalculations = (currentRowData) => {

        let discAmt = isNaN(parseFloat(currentRowData.material_discount_amount)) ? 0 : parseFloat(currentRowData.material_discount_amount);
        const material_taxable_amount = currentRowData.material_basic_amount - discAmt;
        let so_rate = material_taxable_amount;
        if (parseFloat(currentRowData.goods_return_quantity) !== 0 && !isNaN(parseFloat(currentRowData.goods_return_quantity))) {
            so_rate = parseFloat(material_taxable_amount) / parseFloat(currentRowData.goods_return_quantity)
        }
        currentRowData['material_taxable_amount'] = validateNumberDateInput.current.decimalNumber(material_taxable_amount.toString(), 4);
        currentRowData['so_rate'] = validateNumberDateInput.current.decimalNumber(so_rate.toString(), 4);
        FnCalculateMaterialTaxation(currentRowData);
        return currentRowData;
    };
    const FnCalculateMaterialTaxation = (currentMaterial) => {
        debugger
        let custState = parseInt($('#cmb_customer_state_id').val());
        let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
        let companyBranchStateId = parseInt($('#cmb_companyBranchStateId').val());
        let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
        let appliedHSNPercent = isNaN(parseFloat(currentMaterial.product_hsn_sac_rate)) === NaN ? 0 : parseFloat(currentMaterial.product_hsn_sac_rate);
        let materialTaxableAmt = isNaN(parseFloat(currentMaterial.material_taxable_amount)) ? 0 : parseFloat(currentMaterial.material_taxable_amount)

        if (!isNaN(custState)) {
            let cgst_percent = 0; let cgst_cost = 0; let sgst_percent = 0;
            let sgst_cost = 0; let igst_percent = 0; let igst_cost = 0;

            let material_total_amount = 0;
            if (custStateCode === 99) {
                cgst_percent = 0; cgst_cost = 0; sgst_percent = 0; sgst_cost = 0; igst_percent = 0; igst_cost = 0;
                material_total_amount = materialTaxableAmt

            } else if (customerIsSez === 'true') {
                cgst_percent = 0; cgst_cost = 0; sgst_percent = 0; sgst_cost = 0; igst_percent = 0; igst_cost = 0;
                material_total_amount = materialTaxableAmt

            } else if (cmb_companyBranchStateId === custState) {
                igst_percent = 0; igst_cost = 0;
                cgst_percent = appliedHSNPercent / 2;
                sgst_percent = appliedHSNPercent / 2;
                cgst_cost = (materialTaxableAmt * cgst_percent / 100)
                sgst_cost = (materialTaxableAmt * sgst_percent / 100)
                material_total_amount = materialTaxableAmt + cgst_cost + sgst_cost + igst_cost

            } else {
                sgst_percent = 0; cgst_percent = 0; cgst_cost = 0; sgst_cost = 0;
                igst_percent = appliedHSNPercent;
                igst_cost = (materialTaxableAmt * appliedHSNPercent) / 100;
                material_total_amount = materialTaxableAmt + igst_cost
            }

            currentMaterial['material_total_amount'] = validateNumberDateInput.current.decimalNumber((material_total_amount).toString(), 4);
            currentMaterial['material_cgst_percent'] = validateNumberDateInput.current.decimalNumber((cgst_percent).toString(), 4);
            currentMaterial['material_cgst_total'] = validateNumberDateInput.current.decimalNumber((cgst_cost).toString(), 4);
            currentMaterial['material_sgst_percent'] = validateNumberDateInput.current.decimalNumber((sgst_percent).toString(), 4);
            currentMaterial['material_sgst_total'] = validateNumberDateInput.current.decimalNumber((sgst_cost).toString(), 4);
            currentMaterial['material_igst_percent'] = validateNumberDateInput.current.decimalNumber((igst_percent).toString(), 4);
            currentMaterial['material_igst_total'] = validateNumberDateInput.current.decimalNumber((igst_cost).toString(), 4);

            // push data in taxation summary list.
            let taxSummary = {
                materialId: currentMaterial.product_id,
                so_sr_no: currentMaterial.so_sr_no,
                hsnCode: currentMaterial.product_hsn_sac_code,
                hsn_code_id: currentMaterial.product_hsn_sac_code_id,
                hsnRate: currentMaterial.product_hsn_sac_rate,
                taxableAmt: currentMaterial.material_taxable_amount,
                CGSTPercent: currentMaterial.material_cgst_percent,
                CGSTAmt: currentMaterial.material_cgst_total,
                SGSTPercent: currentMaterial.material_sgst_percent,
                SGSTAmt: currentMaterial.material_sgst_total,
                IGSTPercent: currentMaterial.material_igst_percent,
                IGSTAmt: currentMaterial.material_igst_total,
                totalAmt: currentMaterial.material_total_amount
            };

            let taxationSummary = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
            const materialIndex = taxationSummary.findIndex((item) => item.materialId === currentMaterial.product_id && item.so_sr_no === parseInt(currentMaterial.so_sr_no));
            if (materialIndex !== -1) {
                taxationSummary[materialIndex] = taxSummary;
            } else {
                taxationSummary.push(taxSummary);
            }
            localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));

        } else {
            $('#error_cmb_customer_state_id').text('Please select customer state...!');
            $('#error_cmb_customer_state_id').show();
            $('#error_cmb_customer_id').text('Please select customer ...!');
            $('#error_cmb_customer_id').show();
        }
        return currentMaterial;
    }
    // Fn for sum of discount percent and amount on material chabge
    const FnCalculateDiscSum = (updatedDetailsData) => {
        let material_basic_totals = parseFloat(updatedDetailsData.reduce((total, item) => total + parseFloat(item.material_basic_amount), 0));
        let material_discount_amount = parseFloat(updatedDetailsData.reduce((total, item) => total + parseFloat(item.material_discount_amount), 0));
        let material_discount_percent = (material_discount_amount * 100) / material_basic_totals;
        setDiscountPercent(validateNumberDateInput.current.decimalNumber((material_discount_percent).toString(), 2));
        setDiscountAmt(validateNumberDateInput.current.decimalNumber((material_discount_amount).toString(), 4));
    }
    const validatePercentageInput = (percentageInput) => {
        let percentageValidateMsg = '';
        percentageInput = validateNumberDateInput.current.decimalNumber((percentageInput), 4);

        if (percentageInput === '') {
            percentageValidateMsg = 'Please fill this field...!';
        } else if (parseFloat(percentageInput) > 100) {
            percentageValidateMsg = 'Percentage should not be greater than 100%...!';
        } else {
            return true;
        }
        return percentageValidateMsg;
    }
    const updateYarnReturnDetailsTblData = async (currentRowData, event) => {
        debugger
        let rowIndex = event.target.parentElement.parentElement.getAttribute('rowIndex');
        let clickedColName = event.target.getAttribute('Headers');
        const excludedColumnsInCalculation = ['product_unit_id', 'remark', 'material_schedule_date', 'sales_order_item_status', 'product_recommendation', 'special_remark', 'monogram_is_applicable', 'weft_color', 'material_style', 'material_style_value', 'material_style_abbrevation'];

        let updateCalculations = false;
        let inputValue = event.target.value;

        if (!excludedColumnsInCalculation.includes(clickedColName)) {
            inputValue = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0;
        }


        // Remove error msgs on input change.
        if (event._reactName === 'onChange') { delete event.target.parentElement.dataset.tip; }

        switch (clickedColName) {
            case 'goods_return_quantity':

                if (event.target.value > currentRowData['closing_balance_quantity']) {
                    event.target.parentElement.dataset.tip = 'Sales quantity cant exceed Stock quantity...!';
                    currentRowData[clickedColName] = '';
                } else {
                    let goods_return_weight = parseFloat(inputValue) * parseFloat(currentRowData.cone_per_wt);
                    currentRowData.goods_return_quantity = inputValue
                    currentRowData.goods_return_weight = validateNumberDateInput.current.decimalNumber(goods_return_weight.toString(), 4);
                    if (currentRowData.product_unit_name === "Kilo Gram") {
                        currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((currentRowData.goods_return_weight * parseFloat(currentRowData.goods_return_rate)).toString(), 4);
                    } else {
                        currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.goods_return_rate)).toString(), 4);

                    }

                    FnCalculateMaterialWiseDiscount(currentRowData);
                    updateCalculations = true;

                    currentRowData['goods_return_weight'] = validateNumberDateInput.current.decimalNumber(currentRowData.goods_return_weight, event, 4);
                }

                // let goods_return_weight = parseFloat(inputValue) * parseFloat(currentRowData.cone_per_wt);
                // currentRowData.goods_return_quantity = inputValue
                // currentRowData.goods_return_weight = validateNumberDateInput.current.decimalNumber(goods_return_weight.toString(), 4);
                // if (currentRowData.product_unit_name === "Kilo Gram") {
                //     currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((currentRowData.goods_return_weight * parseFloat(currentRowData.goods_return_rate)).toString(), 4);
                // } else {
                //     currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.goods_return_rate)).toString(), 4);

                // }

                // FnCalculateMaterialWiseDiscount(currentRowData);
                // updateCalculations = true;

                // currentRowData['goods_return_weight'] = validateNumberDateInput.current.decimalNumber(currentRowData.goods_return_weight, event, 4);
                break;

            case 'goods_return_rate':
                currentRowData.goods_return_rate = inputValue;
                if (currentRowData.product_unit_name === "Kilo Gram") {
                    currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((currentRowData.goods_return_weight * parseFloat(currentRowData.goods_return_rate)).toString(), 4);
                } else {
                    currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.goods_return_quantity)).toString(), 4);

                }

                FnCalculateMaterialWiseDiscount(currentRowData);
                updateCalculations = true;

                break;

            case 'material_discount_percent':
                let isValidPercentageInput = validatePercentageInput(inputValue);
                if (isValidPercentageInput === true) {
                    const calculatedDiscountAmount = (inputValue / 100) * currentRowData.material_basic_amount;
                    // Then set the discount amount and discount percentage.
                    currentRowData.material_discount_percent = inputValue;
                    currentRowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedDiscountAmount), 4);
                    delete event.target.parentElement.dataset.tip;
                } else {
                    currentRowData[clickedColName] = currentRowData.material_discount_percent;
                    event.target.parentElement.dataset.tip = isValidPercentageInput;
                }
                updateCalculations = true;
                break;

            case 'material_discount_amount':
                const calculatedPercent = (inputValue * 100) / currentRowData.material_basic_amount;
                // Then set the discount amount and discount percentage.
                currentRowData.material_discount_amount = inputValue;
                currentRowData.material_discount_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 4);
                updateCalculations = true;
                break;

            // Non Calculated Fields.


            case 'goods_return_weight':
                if (event.target.value.endsWith(".") || event.target.value.endsWith(".0")) {
                    currentRowData.goods_return_weight = event.target.value
                } else {
                    // currentRowData.goods_return_weight = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0;
                    // if (currentRowData.product_unit_name === "Kilo Gram") {
                    //     currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((currentRowData.goods_return_weight * parseFloat(currentRowData.goods_return_rate)).toString(), 4);
                    // } else {
                    //     currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((parseFloat(currentRowData.goods_return_rate) * parseFloat(currentRowData.goods_return_quantity)).toString(), 4);

                    // }
                    // FnCalculateMaterialWiseDiscount(currentRowData);
                    // updateCalculations = true;

                    if (event.target.value > currentRowData['closing_balance_weight']) {
                        event.target.parentElement.dataset.tip = 'Sales Weight cant exceed Stock Weight...!';
                        currentRowData[clickedColName] = '';
                    } else {
                        currentRowData.goods_return_weight = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0;
                        if (currentRowData.product_unit_name === "Kilo Gram") {
                            currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((currentRowData.goods_return_weight * parseFloat(currentRowData.goods_return_rate)).toString(), 4);
                        } else {
                            currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((parseFloat(currentRowData.goods_return_rate) * parseFloat(currentRowData.goods_return_quantity)).toString(), 4);

                        }
                        FnCalculateMaterialWiseDiscount(currentRowData);
                        updateCalculations = true;
                    }
                }
                break;

            case 'product_material_unit_id':
                currentRowData[clickedColName] = inputValue;
                currentRowData['product_unit_name'] = event.target.selectedOptions[0].getAttribute('lbl');
                break;

            case 'goods_return_remark':
                currentRowData[clickedColName] = event.target.value;
                break;
            case 'goods_return_boxes':
                if (event.target.value > currentRowData['closing_no_of_boxes']) {
                    event.target.parentElement.dataset.tip = 'sales No of Boxes cant exceed Stock No Of Boxes...!';
                    currentRowData[clickedColName] = '';
                } else {
                    currentRowData[clickedColName] = inputValue;
                }
                break;

            case 'product_hsn_sac_code_id':

                currentRowData[clickedColName] = event.target.value;
                let hsnCodeValue = event.target.value;
                let selectedOption = event.target.options[event.target.selectedIndex];
                let product_hsn_sac_rate = selectedOption.getAttribute('product_hsn_sac_rate');
                let product_hsn_sac_code = selectedOption.getAttribute('product_hsn_sac_code');
                if (hsnCodeValue === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('HSN Code'); // CommonParamterEntry
                    setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (hsnCodeValue !== '') {
                    currentRowData[clickedColName] = hsnCodeValue;
                    currentRowData['product_hsn_sac_rate'] = product_hsn_sac_rate;
                    currentRowData['product_hsn_sac_code'] = product_hsn_sac_code;
                    currentRowData['product_hsn_sac_code_id'] = hsnCodeValue;
                    delete event.target.parentElement.dataset.tip;
                } else {
                    currentRowData[clickedColName] = '';
                    currentRowData['product_hsn_sac_rate'] = '';
                    event.target.parentElement.dataset.tip = 'Please Select Atleast One...!';
                }
                FnCalculateMaterialWiseDiscount(currentRowData);
                updateCalculations = true;
                break;
            default:
                break;
        }

        // Calculate the other dependent Fields on changed field.
        if ((updateCalculations || event._reactName === 'onBlur') && !excludedColumnsInCalculation.includes(clickedColName)) {
            currentRowData = FnMaterialWiseCalculations(currentRowData);
            const updatedDetailsData = [...yarnReturnDetailsData]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            calculateGrandTotal(updatedDetailsData);
            setYarnReturnDetails(updatedDetailsData);

            // Update the footer Discount percentage and amount.
            FnCalculateDiscSum(updatedDetailsData);
        } else {
            const updatedDetailsData = [...yarnReturnDetailsData]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            setYarnReturnDetails(updatedDetailsData);
        }

    }

    const FnLoadAccordionData = async (eventKey) => {
        let checkedLength = 0;
        switch (eventKey) {
            case 'paymentTerms':
                $('#spinner_id').show();
                $('#spinner_text').text('Loading...');
                checkedLength = $("input:checkbox[name=selectPayTerm]:checked").length;
                if (checkedLength === 0) {
                    await FnGetPaymentTerms();
                }
                $('#spinner_id').hide();
                break;

            default:
                break;
        }
    }

    const FnGetPaymentTerms = async () => {
        try {
            const pyTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/paymentterms/FnShowAllActiveRecords`)
            const responce = await pyTermsApiCall.json();
            if (responce.data.content.length !== 0 && responce.data.content.length > 0) {
                const updatedContent = responce.data.content.map((paymentTerm) => ({
                    ...paymentTerm,
                    remark: '',
                })).sort((a, b) => a.payment_terms_id - b.payment_terms_id);
                responce.data.content = updatedContent;

                const existingPMTTermsData = [...PmtTermsdataResp];
                let renderedPMTTermsData = responce.data.content
                for (let existPMTCounter = 0; existPMTCounter < existingPMTTermsData.length; existPMTCounter++) {
                    const existingPMTTermId = existingPMTTermsData[existPMTCounter].payment_terms_id;
                    const renderedPMTTerm = renderedPMTTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.payment_terms_id === existingPMTTermId);
                    if (renderedPMTTerm) {
                        const existingIndex = renderedPMTTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedPMTTermsData.splice(existingIndex, 1)[0];
                        renderedPMTTermsData.unshift(movedItem);

                        renderedPMTTermsData[0].payment_terms_Milestome = existingPMTTermsData[existPMTCounter].payment_terms_Milestome;
                        renderedPMTTermsData[0].payment_expected_value = existingPMTTermsData[existPMTCounter].payment_expected_value;
                        renderedPMTTermsData[0].payment_expected_date = existingPMTTermsData[existPMTCounter].payment_expected_date;
                        renderedPMTTermsData[0].payment_percent = existingPMTTermsData[existPMTCounter].payment_percent;
                        renderedPMTTermsData[0].payment_receipt_flag = existingPMTTermsData[existPMTCounter].payment_receipt_flag;
                        renderedPMTTermsData[0].payment_receipt_transaction_id = existingPMTTermsData[existPMTCounter].payment_receipt_transaction_id;
                        renderedPMTTermsData[0].payment_receipt_date = existingPMTTermsData[existPMTCounter].payment_receipt_date;
                        renderedPMTTermsData[0].remark = existingPMTTermsData[existPMTCounter].remark;
                    }
                }
                setPmtTermsData(renderedPMTTermsData);

                //------------------------------------------------------
                setTimeout(() => {
                    if (PmtTermsdataResp.length === 0 && ['', 'copy'].includes(keyForViewUpdate)) {
                        const defaultPmtTermId = 1;
                        setPmtTermsData(prevData => {
                            return prevData.map(pmtTerm => {
                                if (pmtTerm.payment_terms_id == defaultPmtTermId) {
                                    $('#selectPmtTerm_' + defaultPmtTermId).prop('checked', true);
                                    return {
                                        ...pmtTerm,
                                        payment_expected_date: today(),
                                        payment_percent: 100,
                                    }; // Update the 'name' property for mtId = 5
                                }
                                return pmtTerm; // Return unchanged object if mtId is not 5
                            });
                        });

                    } else {
                        PmtTermsdataResp.forEach(function (existingPayTerm) {
                            $('#selectPmtTerm_' + existingPayTerm.payment_terms_id).prop('checked', true);
                        });
                    }
                }, 200);

            } else {
                setPmtTermsData([]);
            }

        } catch (error) {
            console.log("error in getting payment terms master data: ", error)
            navigate('/Error')
        }

        // Onchange event listener for the POTerm Selection
        $('.selectPmtTerm').on('change', function () {
            // if it is checked then focus the.
            let paymentTermId = $(this).attr("pmtTermId");
            if (this.checked) {
                if ($(`#payment_milestone_${paymentTermId}`).val() === '') {
                    $(`#payment_milestone_${paymentTermId}`).focus();

                } else if ($(`#payment_percent_${paymentTermId}`).val() === '') {
                    $(`#payment_percent_${paymentTermId}`).focus();

                } else if ($(`#payment_expected_value_${paymentTermId}`).val() === '') {
                    $(`#payment_expected_date_${paymentTermId}`).focus();

                }
            } else {
                let mileStoneField = document.querySelector('#payment_milestone_' + paymentTermId);
                delete mileStoneField.parentElement.dataset.tip;
            }
            checkBoxesSelection('PartiallyPmtTermsSelection');
        });
    }
    function checkBoxesSelection(key) {
        switch (key) {
            // For Payment terms
            case "selectAllPmtTerms":
                $('.selectPmtTerm').prop('checked', $('#selectAllPmtTerms').is(":checked"));
                break;
            case 'PartiallyPmtTermsSelection':
                $('#selectAllPmtTerms').prop('checked', $('input:checkbox.selectPmtTerm:checked').length === $('input:checkbox.selectPmtTerm').length);
                break;
            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            // For Common terms
            case 'selectAllCommonTerms':
                $('.selectCommonTerm').prop('checked', $('#selectAllCommonTerms').is(":checked"));
                break;
            case 'PartiallyCommonTermSelection':
                $('#selectAllCommonTerms').prop('checked', $('input:checkbox.selectCommonTerm:checked').length === $('input:checkbox.selectCommonTerm').length);
                break;
            default:
                break;
        }
    }

    const updatePmtTermsTblData = (currentPaymentTerm, e) => {
        let clickedColName = e.target.getAttribute('Headers');

        switch (clickedColName) {
            case 'payment_expected_value':
                currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4) || 0;
                delete e.target.parentElement.dataset.tip;
                break;

            case 'payment_percent':
                let isValidPercentageInput = validatePercentageInput(e.target.value);
                if (isValidPercentageInput === true) {
                    currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
                    delete e.target.parentElement.dataset.tip;
                } else {
                    const currentTblRow = e.target.parentElement.parentElement;
                    const checkbox = currentTblRow.querySelector('input[id^="selectPmtTerm_"]');
                    if (checkbox.checked) {
                        currentPaymentTerm[clickedColName] = currentPaymentTerm.payment_percent;
                        e.target.parentElement.dataset.tip = isValidPercentageInput;
                    } else {
                        currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
                        delete e.target.parentElement.dataset.tip;
                    }

                }
                break;

            case 'payment_terms_Milestome':
            case 'payment_expected_date':
            case 'remark':
                currentPaymentTerm[clickedColName] = e.target.value;
                delete e.target.parentElement.dataset.tip;
                break;
            default:
                break;
        }

        // update the Payment terms table data.
        const updatedPaymentTermsData = [...PmtTermsdata]
        const pmtTermIndexInArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedPaymentTermsData[pmtTermIndexInArray] = currentPaymentTerm;
        setPmtTermsData(updatedPaymentTermsData);
    }


    const renderPaymentTermsTable = useMemo(() => {
        return <>
            {
                PmtTermsdata.length > 0
                    ? <Table className="erp_table erp_table_scroll" id='yarnReturnPaymentTermsTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th`}>Action</th>
                                <th className="erp_table_th">Payment Term Name</th>
                                <th className="erp_table_th">Payment Term Days</th>
                                <th className="erp_table_th">Grace Days</th>
                                <th className={`erp_table_th`}>Payment Milestome</th>
                                <th className="erp_table_th">Payment Percentage</th>
                                <th className="erp_table_th">Payment Expected Value</th>
                                <th className="erp_table_th">Payment Expected Date</th>
                                <th className="erp_table_th">Payment Receipt Status</th>
                                <th className="erp_table_th">Payment Receipt Transaction Id</th>
                                <th className={`erp_table_th`}>Payment Receipt Date</th>
                                <th className="erp_table_th">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                PmtTermsdata?.map((paymentTerm, Index) =>
                                    <tr rowIndex={Index} paymentTermId={paymentTerm.payment_terms_id}>
                                        <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <input type="checkbox" name="selectPmtTerm" className="form-check-input selectPmtTerm" pmtTermId={paymentTerm.payment_terms_id} value={paymentTerm.payment_terms_id}
                                                    id={'selectPmtTerm_' + paymentTerm.payment_terms_id}                                                    {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                    {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                />
                                            </div>
                                        </td>
                                        <td className="erp_table_td">{paymentTerm.payment_terms_name}</td>
                                        <td className="erp_table_td text-end">{paymentTerm.payment_terms_days}</td>
                                        <td className="erp_table_td text-end">{paymentTerm.payment_terms_grace_days}</td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_terms_Milestome
                                                : <input type="text" className="erp_input_field mb-0" value={paymentTerm.payment_terms_Milestome} pmtTermId={paymentTerm.payment_terms_id} id={"payment_milestone_" + paymentTerm.payment_terms_id} maxlength='255'
                                                    Headers='payment_terms_Milestome' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_percent
                                                : <input type="text" className="erp_input_field mb-0 text-end" value={paymentTerm.payment_percent} pmtTermId={paymentTerm.payment_terms_id} id={"payment_percent_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_percent' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>


                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_expected_value
                                                : <input type="text" className="erp_input_field mb-0 text-end" value={paymentTerm.payment_expected_value} pmtTermId={paymentTerm.payment_terms_id} id={"payment_expected_value_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_expected_value' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                // ? paymentTerm.payment_expected_date
                                                ? validateNumberDateInput.current.formatDateToDDMMYYYY(paymentTerm.payment_expected_date)
                                                : <input type="date" className="erp_input_field mb-0" value={paymentTerm.payment_expected_date} min={currentDate} pmtTermId={paymentTerm.payment_terms_id} id={"payment_expected_date_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_expected_date' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            <select id={`payment_receipt_flag_${paymentTerm.payment_terms_id}`} Headers='payment_receipt_flag' disabled='disabled' value={paymentTerm.payment_receipt_flag} className="form-select form-select-sm erp_input_field_table_txt m-0">
                                                <option value="P">Pending</option>
                                                <option value="T">Partial</option>
                                                <option value="R">Receipt</option>
                                            </select>
                                        </td>

                                        <td className="erp_table_td">{paymentTerm.payment_receipt_transaction_id}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(paymentTerm.expected_schedule_date)}</td>

                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? paymentTerm.remark
                                                    : <textarea type="text" className="erp_input_field_table_txt mb-0" value={paymentTerm.remark} pmtTermId={paymentTerm.payment_terms_id} id={"payment_term_remark_" + paymentTerm.payment_terms_id}
                                                        Headers='remark' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }}>
                                                    </textarea>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ''
            }
        </>
    }, [PmtTermsdata]);

    // ********************************************* Sales Order PrintOut Functionality Starts.************************************************************
    //Sales Order Invoice Creation 
    const yarnSalesOrderInvoiceContent = {
        consigneeDetails: {
            name: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            consignee_city_name: '',
        },
        customerDetails: {
            customer: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            email: '',
            customer_city_name: '',

        },
        Ourdetails: {
            company_branch_name: '',
            company_address1: '',
            state_name: '',
            city_name: '',
        },
        yarnSaleDetails: {
            goods_return_no: '',
            sales_order_date: '',
            sales_quotation_no: '',
            sales_quotation_date: '',
            department_name: '',
            sales_order_version: '',
            sales_order_creation_type: '',
            customer_order_no: '',
            customer_order_Date: '',
        },

        footer: {
            cgst_words: '',
            sgst_words: '',
            igst_words: '',
            grand_total_words: '',

            basic_total: '',
            cgst_total: '',
            sgst_total: '',
            igst_total: '',
            taxable_total: '',
            grand_total: '',
            transport_amount: '',
            packing_amount: '',
            freight_amount: '',
            other_amount: '',
            discount_amount: '',
            other_terms_conditions: '',
        },

        items: [],
        paymentTerms: [],
        SOTerms: [],
        contactDetails: [],
        warpingbottomDetailsArray: [],
    };


    const printInvoice = async (openPrintModal) => {
        //Sales details
        debugger
        yarnSalesOrderInvoiceContent.yarnSaleDetails.goods_return_no = yarnReturnMasterDataJson.goods_return_no
        yarnSalesOrderInvoiceContent.yarnSaleDetails.goods_return_date = validateNumberDateInput.current.formatDateToDDMMYYYY(yarnReturnMasterDataJson.goods_return_date)
        yarnSalesOrderInvoiceContent.yarnSaleDetails.goods_version = yarnReturnMasterDataJson.goods_version
        yarnSalesOrderInvoiceContent.yarnSaleDetails.sales_type = $('#cmb_sales_job_type_id').find(":selected").text()
        yarnSalesOrderInvoiceContent.yarnSaleDetails.driver_name = yarnReturnMasterDataJson.driver_name
        yarnSalesOrderInvoiceContent.yarnSaleDetails.vehical_no = yarnReturnMasterDataJson.vehical_no
        yarnSalesOrderInvoiceContent.yarnSaleDetails.transport_mode = yarnReturnMasterDataJson.transport_mode
        yarnSalesOrderInvoiceContent.yarnSaleDetails.invoice_no = yarnReturnMasterDataJson.invoice_no
        yarnSalesOrderInvoiceContent.yarnSaleDetails.yarn_type = yarnReturnMasterDataJson.yarn_type;

        yarnSalesOrderInvoiceContent.yarnSaleDetails.sales_dispatch_type = yarnReturnMasterDataJson.sales_dispatch_type
        yarnSalesOrderInvoiceContent.yarnSaleDetails.dispatch_hsnTax_type = yarnReturnMasterDataJson.dispatch_hsnTax_type;
        yarnSalesOrderInvoiceContent.yarnSaleDetails.dispatch_sales_type = yarnReturnMasterDataJson.dispatch_sales_type
        yarnSalesOrderInvoiceContent.yarnSaleDetails.dispatch_voucher_type = yarnReturnMasterDataJson.dispatch_voucher_type;

        //Consignee details
        yarnSalesOrderInvoiceContent.consigneeDetails.name = yarnReturnMasterDataJson.consignee_name
        yarnSalesOrderInvoiceContent.consigneeDetails.address = yarnReturnMasterDataJson.consi_branch_address1
        yarnSalesOrderInvoiceContent.consigneeDetails.consi_state_name = yarnReturnMasterDataJson.consi_state_name
        yarnSalesOrderInvoiceContent.consigneeDetails.contact = yarnReturnMasterDataJson.consi_branch_phone_no
        yarnSalesOrderInvoiceContent.consigneeDetails.GSTIN = yarnReturnMasterDataJson.consi_branch_gst_no
        yarnSalesOrderInvoiceContent.consigneeDetails.consi_city_name = yarnReturnMasterDataJson.consi_city_name

        //Customer details
        yarnSalesOrderInvoiceContent.customerDetails.customer = yarnReturnMasterDataJson.customer_name
        yarnSalesOrderInvoiceContent.customerDetails.address = yarnReturnMasterDataJson.cust_branch_address1
        yarnSalesOrderInvoiceContent.customerDetails.cust_state_name = yarnReturnMasterDataJson.cust_state_name
        yarnSalesOrderInvoiceContent.customerDetails.contact = yarnReturnMasterDataJson.cust_branch_phone_no
        yarnSalesOrderInvoiceContent.customerDetails.GSTIN = yarnReturnMasterDataJson.cust_branch_gst_no
        yarnSalesOrderInvoiceContent.customerDetails.cust_city_name = yarnReturnMasterDataJson.cust_city_name

        yarnSalesOrderInvoiceContent.footer.basic_total = yarnReturnMasterDataJson.basic_total
        yarnSalesOrderInvoiceContent.footer.cgst_total = yarnReturnMasterDataJson.cgst_total
        yarnSalesOrderInvoiceContent.footer.sgst_total = yarnReturnMasterDataJson.sgst_total
        yarnSalesOrderInvoiceContent.footer.igst_total = yarnReturnMasterDataJson.igst_total
        yarnSalesOrderInvoiceContent.footer.taxable_total = yarnReturnMasterDataJson.taxable_total
        yarnSalesOrderInvoiceContent.footer.grand_total = yarnReturnMasterDataJson.grand_total
        yarnSalesOrderInvoiceContent.footer.transport_amount = yarnReturnMasterDataJson.transport_amount
        yarnSalesOrderInvoiceContent.footer.packing_amount = yarnReturnMasterDataJson.packing_amount
        yarnSalesOrderInvoiceContent.footer.freight_amount = yarnReturnMasterDataJson.freight_amount
        yarnSalesOrderInvoiceContent.footer.discount_amount = yarnReturnMasterDataJson.discount_amount
        yarnSalesOrderInvoiceContent.footer.other_amount = yarnReturnMasterDataJson.other_amount
        yarnSalesOrderInvoiceContent.footer.remark = yarnReturnMasterDataJson.remark
        yarnSalesOrderInvoiceContent.footer.other_terms_conditions = yarnReturnMasterDataJson.other_terms_conditions
        yarnSalesOrderInvoiceContent.footer.grn_currency_code = "INR"

        // Our Details
        yarnSalesOrderInvoiceContent.Ourdetails.company_branch_name = yarnReturnMasterDataJson.company_branch_name
        yarnSalesOrderInvoiceContent.Ourdetails.company_address1 = yarnReturnMasterDataJson.company_address1
        yarnSalesOrderInvoiceContent.Ourdetails.state_name = yarnReturnMasterDataJson.company_branch_state
        yarnSalesOrderInvoiceContent.Ourdetails.city_name = $("#cmb_companyBranchCityId option:selected").text();


        for (let index = 0; index < yarnReturnDetailsData.length; index++) {
            const element = yarnReturnDetailsData[index];

            const detailsData = {
                sr_no: index + 1,  // Set sr_no as index + 1,
                so_sr_no: element.so_sr_no,
                material_name: element.product_material_name,
                product_material_code: element.product_material_code,
                batch_no: element.issue_batch_no,

                // rate: element.material_rate,
                hsn_code: element.product_hsn_sac_code,
                rate: element.goods_return_rate,
                quantity: element.goods_return_quantity,
                weight: element.goods_return_weight,
                unit: element.product_unit_name,
                discount_percent: element.material_discount_percent,
                cgst_percent: element.material_cgst_percent,
                sgst_percent: element.material_sgst_percent,
                igst_percent: element.material_igst_percent,
                material_basic_amount: element.material_basic_amount,
                material_discount_amount: element.material_discount_amount,
                material_taxable_amount: element.material_taxable_amount,
                material_grade_name: element.product_material_grade_name,
                product_make_name: element.product_make_name,
                company: element.company_name,
                company_branch: element.company_branch_name,
                address: element.company_address1,
                phone: element.company_phone_no,
                cell: element.company_cell_no,
                email: element.company_EmailId,
                website: element.company_website,
                GSTIN: element.company_pan_no,
                pan_no: element.company_pan_no,
                state_name: element.company_state,
                company_pincode: element.company_pincode,

                company_branch_bank_name: element.company_branch_bank_name,
                company_branch_bank_account_no: element.company_branch_bank_account_no,
                company_branch_bank_ifsc_code: element.company_branch_bank_ifsc_code,

            }

            yarnSalesOrderInvoiceContent.items.push(detailsData)
        }

        for (let returnItem = 0; returnItem < warpingBottomDetailsData.length; returnItem++) {
            const warpingBottomRow = warpingBottomDetailsData[returnItem];

            const indentReturnRow = {};
            indentReturnRow.bottom_return_no = warpingBottomRow.bottom_return_no
            indentReturnRow.goods_return_bottom_date = warpingBottomRow.goods_return_bottom_date
            indentReturnRow.no_of_package = warpingBottomRow.no_of_package
            indentReturnRow.gross_weight = warpingBottomRow.gross_weight
            indentReturnRow.net_weight = warpingBottomRow.net_weight || 0
            indentReturnRow.tare_weight = warpingBottomRow?.tare_weight
            indentReturnRow.godown_name = warpingBottomRow?.godown_name
            indentReturnRow.product_material_name = warpingBottomRow?.product_material_name
            indentReturnRow.batch_no = warpingBottomRow?.batch_no
            indentReturnRow.weight_per_pkg = warpingBottomRow?.weight_per_pkg
            indentReturnRow.supplier_name = warpingBottomRow?.supplier_name
            indentReturnRow.customer_name = warpingBottomRow?.customer_name
            indentReturnRow.creel_no = warpingBottomRow?.creel_no
            indentReturnRow.set_no = warpingBottomRow?.set_no
            indentReturnRow.cone_type_value = warpingBottomRow?.cone_type_value
            indentReturnRow.cone_type = warpingBottomRow?.cone_type
            indentReturnRow.bora_box = warpingBottomRow?.bora_box
            indentReturnRow.package_type = warpingBottomRow?.package_type
            indentReturnRow.sr_no = warpingBottomRow?.sr_no

            yarnSalesOrderInvoiceContent.warpingbottomDetailsArray.push(indentReturnRow);

        }

        const checkboxes = $('.selectCustContactPerson:checked');
        console.log(checkboxes);
        checkboxes.each(function (index) {
            if (index === 0) {
                let selectedContact_id = parseInt($(this).attr('contact_personId'));
                const customerContactDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
                if (customerContactDetails) {
                    const contactdata = {
                        cust_contact_person: customerContactDetails.cust_contact_person,
                        cust_contact_no: customerContactDetails.cust_contact_no,

                    }
                    yarnSalesOrderInvoiceContent.contactDetails.push(contactdata);
                }
            }

        });

        for (let index = 0; index < PmtTermsdataResp.length; index++) {
            const element = PmtTermsdataResp[index];
            const paymentTermsData = {
                payment_terms_name: element.payment_terms_name,
                payment_terms_days: element.payment_terms_days,
            }
            yarnSalesOrderInvoiceContent.paymentTerms.push(paymentTermsData)
        }


        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: yarnSalesOrderInvoiceContent, navigationLink: '/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnEntry', invoiceType: 'YR', idList: idList, title: 'Yarn Sales Order', keyForViewUpdate: keyForViewUpdate, compType: compType } });
        } else {
            // return the html code.
            return renderToString(<YarnSaleInvoice invoiceContent={yarnSalesOrderInvoiceContent} />);
        }
    };
    // ********************************************* Sales Order PrintOut Functionality Ends.************************************************************
    //*********************Warping bottom stock * */



    const renderYarnBottomStockDetailsTable = useMemo(() => {
        return <>
            {/* <Table className="erp_table table table-striped table-bordered erp_table_scroll" id='issue-details-tbl' responsive bordered striped style={{width:"100%"}}> */}
            <Table className="erp_table" id='yarn-bottom-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate === "" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Set No</th>
                        <th className="erp_table_th">Count</th>
                        <th className="erp_table_th">Party Name</th>
                        <th className="erp_table_th">Supplier</th>
                        <th className="erp_table_th">SR.No</th>
                        <th className="erp_table_th">No.Of Cones</th>
                        <th className="erp_table_th">GR.WT</th>
                        <th className="erp_table_th">TR.WT </th>
                        <th className="erp_table_th">NT.WT </th>
                        {/* {
                            keyForViewUpdate === 'Receive' || keyForViewUpdate === 'view' ?
                                <>
                                    <th className={`erp_table_th`}>Rec. GR.WT</th>
                                    <th className={`erp_table_th`}>Rec. NT.WT</th>
                                </> : null
                        } */}
                        <th className="erp_table_th">Cone Type</th>
                        <th className="erp_table_th">Box/Bora</th>
                        <th className={`erp_table_th`} style={{ textAlign: 'center' }}> Godown Name</th>
                        {/* <th className={`erp_table_th`} style={{ paddingRight: '40px' }} >Return Item Status</th> */}
                    </tr>
                </thead>
                <tbody>
                    {warpingBottomDetailsData.map((returnItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            <td className={`erp_table_td  ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}><div style={{ display: "flex" }}>
                                <Tooltip title="Delete" placement="top">
                                    <MDTypography className="erp-view-btn">
                                        <MdDelete className="erp-delete-btn" id={`delete_btn_${Index}`} onClick={() => removeMaterial(Index, setWarpingBottomDetailsData)} />
                                    </MDTypography>
                                </Tooltip>
                            </div>
                            </td>
                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className={`erp_table_td sticky-column`}>{returnItem.set_no}</td>
                            <td className={`erp_table_td sticky-column`}>{returnItem.product_material_name}</td>
                            <td className={`erp_table_td sticky-column`}>{returnItem.customer_name}</td>
                            <td className={`erp_table_td`}>{returnItem.supplier_name}</td>
                            <td className={`erp_table_td text-end`}>{returnItem.sr_no} </td>
                            <td className={`erp_table_td text-end`}>{returnItem.no_of_package}</td>
                            <td className={`erp_table_td text-end`}>{returnItem.gross_weight}</td>
                            <td className={`erp_table_td text-end sticky-column`}>{returnItem.tare_weight}</td>
                            <td className={`erp_table_td text-end`}>{returnItem.net_weight} </td>

                            <td className="erp_table_td">{returnItem.cone_type}  </td>
                            <td className="erp_table_td">{returnItem.bora_box}  </td>

                            <td className={`erp_table_td `}>
                                {returnItem.godown_name}
                            </td>


                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    }, [warpingBottomDetailsData, keyForViewUpdate])

    // fn to remove material
    const removeMaterial = (indexToRemove, setStateCallback) => {
        if (indexToRemove === undefined || indexToRemove < 0 || indexToRemove >= warpingBottomDetailsData.length) {
            console.error("Invalid index provided.");
            return;
        }
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            // Create a new array excluding the element at indexToRemove
            const updatedDetailsData = warpingBottomDetailsData.filter((_, index) => index !== indexToRemove);

            // Update state using the provided callback
            setStateCallback(updatedDetailsData);
        } else {
            console.error("Cannot remove material in 'approve' or 'view' mode.");
        }
    }



    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}
                    {/* <form id="GoodsReturnEntryFormId"> */}
                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg text-center'>Yarn Sale {action_Label}</label>
                        </div>
                        <form id="GoodsReturnEntryFormId">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className={` row`}>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Yarn / Job Type <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Fresh Yarn" type="radio" lbl="Fresh Yarn" value="Fresh" name="chk_yarn_type" checked={chk_yarn_type === "Fresh"} onClick={() => { setYarn_type("Fresh"); comboOnChange('fresh_yarn') }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Bottom Yarn" type="radio" lbl="Bottom Yarn" value="Bottom" name="chk_yarn_type" checked={chk_yarn_type === "Bottom"} onClick={() => { setYarn_type("Bottom"); comboOnChange('bottom_yarn') }} /> </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <select id="cmb_sales_job_type_id"
                                                className="form-select form-select-sm"
                                                value={cmb_sales_job_type}
                                                ref={job_type_ref}
                                                onChange={(e) => { setSalesJobType(e.target.value); job_type_ref.current = jobTypeOptions.find((job) => parseInt(e.target.value) === job['property_id']); clearAllDataTipsForMaterialRate(); validateFields(); }}
                                                // optional='optional'
                                                disabled={['view', 'update', 'approve'].includes(keyForViewUpdate)}>
                                                <option value="" >Select</option>
                                                {jobTypeOptions?.map(jobtype => (
                                                    // <option value={jobtype.field_name}>{jobtype.field_name}</option>
                                                    <option value={jobtype.property_id} > {jobtype.property_name} </option>

                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sales_job_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Sales/Disp.Tax Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-4'>
                                            <select id="cmb_sales_dispatch_type" className="form-select form-select-sm" ref={sales_dispatch_type_ref} value={cmb_sales_dispatch_type} onChange={(e) => { setSalesDispatchType(e.target.value); sales_dispatch_type_ref.current = salesDispatchTypeOpt.find((job) => e.target.value === job['property_name']); comboOnChange('salesDispatchType'); validateFields(); }} disabled={keyForViewUpdate !== ""} >
                                                <option value="">Select</option>
                                                {salesDispatchTypeOpt.length !== 0 ? (
                                                    <>
                                                        {salesDispatchTypeOpt.map(dispatchType => (
                                                            <option value={dispatchType.property_name} > {dispatchType.property_name} </option>
                                                        ))}
                                                    </>
                                                ) : null}

                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sales_dispatch_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {/* <div className='col-sm-2'>
                                            <Form.Label className="erp-form-label">Disp. Tax Type </Form.Label>
                                        </div> */}
                                        <div className='col-sm-4'>
                                            <select id="cmb_dispatch_hsnTax_type" className="form-select form-select-sm" ref={dispatch_tax_type_ref} value={cmb_dispatch_hsnTax_type} onChange={(e) => {
                                                setDispatchHsnTaxType(e.target.value); dispatch_tax_type_ref.current = dispatchHSNTaxTypeOpt.find((job) => e.target.value === job['property_name']); comboOnChange('salesDispatchTaxType'); validateFields();
                                            }} disabled={keyForViewUpdate !== ""}>
                                                <option value="">Select</option>
                                                {dispatchHSNTaxTypeOpt.length !== 0 ? (
                                                    <>
                                                        {dispatchHSNTaxTypeOpt.map(hsnTaxType => (
                                                            <option value={hsnTaxType.property_name} > {hsnTaxType.property_name} </option>
                                                        ))}
                                                    </>
                                                ) : null}

                                            </select>
                                            <MDTypography variant="button" id="error_cmb_dispatch_hsnTax_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Sales/ Voucher Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-4'>
                                            <select id="cmb_dispatch_sales_type" className="form-select form-select-sm" ref={dispatch_sales_type_ref} value={cmb_dispatch_sales_type} onChange={(e) => { setDispatchSalesType(e.target.value); dispatch_sales_type_ref.current = dispatchSalesTypeOpt.find((job) => e.target.value === job['property_name']); validateFields(); }} disabled={keyForViewUpdate !== ""} >
                                                <option value="">Select</option>
                                                {/* <option value="0">Add New Record+</option> */}
                                                {dispatchSalesTypeOpt.length !== 0 ? (
                                                    <>
                                                        {dispatchSalesTypeOpt.map(salestype => (
                                                            <option value={salestype.property_name} > {salestype.property_name} </option>

                                                            // <option key={scheduleType.field_id} value={scheduleType.field_id} shortname={scheduleType.product_type_short_name} > {scheduleType.field_name} </option>
                                                        ))}
                                                    </>
                                                ) : null}

                                            </select>
                                            <MDTypography variant="button" id="error_cmb_dispatch_sales_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                        {/* <div className='col-sm-1'></div> */}

                                        {/* <div className='col-sm-2'>
                                            <Form.Label className="erp-form-label">Voucher Type<span className="required">*</span> </Form.Label>
                                        </div> */}
                                        <div className='col-sm-4'>
                                            <select id="cmb_dispatch_voucher_type" className="form-select form-select-sm" ref={dispatch_voucher_type_ref} value={cmb_dispatch_voucher_type} onChange={(e) => {
                                                setDispatchVoucherType(e.target.value); dispatch_voucher_type_ref.current = dispatchVoucherTypeOpt.find((job) => e.target.value === job['property_name']); comboOnChange('salesDispatchVoucherType'); validateFields();
                                            }} disabled={keyForViewUpdate !== ""}>
                                                <option value="">Select</option>
                                                {dispatchVoucherTypeOpt.length !== 0 ? (
                                                    <>
                                                        {dispatchVoucherTypeOpt.map(jobtype => (
                                                            <option value={jobtype.property_name} > {jobtype.property_name} </option>
                                                        ))}
                                                    </>
                                                ) : null}

                                            </select>
                                            <MDTypography variant="button" id="error_cmb_dispatch_voucher_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Sales Type<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <select id="cmb_sales_job_type_id"
                                                className="form-select form-select-sm"
                                                value={cmb_sales_job_type}
                                                ref={job_type_ref}
                                                onChange={(e) => { setSalesJobType(e.target.value); job_type_ref.current = jobTypeOptions.find((job) => parseInt(e.target.value) === job['property_id']); clearAllDataTipsForMaterialRate(); validateFields(); comboOnChange('jobType'); }}
                                                // optional='optional'
                                                disabled={['view', 'update', 'approve'].includes(keyForViewUpdate)}>
                                                <option value="" >Select</option>
                                                {jobTypeOptions?.map(jobtype => (
                                                    // <option value={jobtype.field_name}>{jobtype.field_name}</option>
                                                    <option value={jobtype.property_id} > {jobtype.property_name} </option>

                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sales_job_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Yarn Sales Type & Invoice Ref.No<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>

                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_material_type"
                                                        className="form-select form-select-sm"
                                                        // disabled={['view', 'update', 'approve'].includes(keyForViewUpdate)} 
                                                        value={cmb_material_type} disabled
                                                        onChange={() => { comboOnChange('materialTypeId'); validateFields(); }} >
                                                        <option value="">Select</option>
                                                        <option value="0">Add New Record+</option>
                                                        {materialtypeOptions.length !== 0 ? (
                                                            <>
                                                                {materialtypeOptions.map(materialTypeId => (
                                                                    <option key={materialTypeId.field_id} value={materialTypeId.field_id}>
                                                                        {materialTypeId.field_name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_material_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>

                                                {/* <div className="row"> */}
                                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                                    {/* <div className="col-12 col-md-8 pe-md-0"> */}
                                                    <Form.Control type="text" id='txt_invoice_no' className="erp_input_field" value={txt_invoice_no} onChange={(e) => { setInvoiceRefNo(e.target.value); validateFields(); }} optional="optional" maxlength="500" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"} />
                                                    <MDTypography variant="button" id="error_txt_invoice_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>

                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Yarn Sale No, Version & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_goods_return_no" className="erp_input_field" value={txt_goods_return_no} disabled />
                                                    <MDTypography variant="button" id="txt_goods_return_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0">
                                                    <Form.Control type="text" id='txt_goods_return_version' className="erp_input_field text-end" value={txt_goods_return_version} disabled />
                                                    <MDTypography variant="button" id="txt_goods_return_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_goods_return_date' className="erp_input_field" value={dt_goods_return_date}
                                                        disabled={['view', 'update', 'approve'].includes(keyForViewUpdate)}
                                                        onChange={e => { setGoodsReturnDate(e.target.value); validateFields(); }} min={currentDate} />
                                                    <MDTypography variant="button" id="dt_goods_return_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row mb-1'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Customer<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <Select
                                                options={customerOpts}
                                                inputId="cmb_customer_id" // Provide the ID for the input box
                                                value={customerOpts.find(option => option.value === cmb_customer_id)}
                                                // onChange={comboOnChange} // Update state with selected value
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                onChange={(selectedOpt) => {
                                                    setCustomerId(selectedOpt.value);
                                                    customerComboRef.current = selectedOpt ?? { value: "" };
                                                    comboOnChange("customer");
                                                    validateFields();
                                                    FnValidateSupplierCombo();

                                                }}

                                                placeholder="Search for a customer..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Customer State & City <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} onChange={(e) => { setCustomerState(e.target.value); comboOnChange('CustomerState'); validateFields(); }} disabled='disabled'  >
                                                        <option value="">Select</option>
                                                        {stateOpts?.map(state => (
                                                            <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} onChange={(e) => { setCustomerCity(e.target.value); comboOnChange('CustomerCity'); validateFields(); }} optional="optional" disabled='disabled'>
                                                        <option value="">Select</option>
                                                        <option value="0">Add New Record+</option>
                                                        {customerCityOpts?.map(city => (
                                                            <option value={city.field_id}>{city.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Customer Address</Form.Label>
                                        </div>
                                        <div className="col-sm-8">
                                            <Form.Control id='txt_cust_address' as="textarea" rows={2} maxLength="255" className="erp_txt_area" value={txt_cust_address} onChange={(e) => { setCustAddress(e.target.value); validateFields(); }} disabled optional="optional" />
                                            <MDTypography variant="button" id="error_txt_cust_address" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Cust. GSTIN & Phone No</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">

                                                <div className="col-12 col-md-7 pe-md-0">
                                                    <Form.Control type="text" id='txt_cust_gst_no' className="erp_input_field" value={txt_cust_gst_no} onChange={(e) => { setCustGStNO(e.target.value); validateFields(); }} disabled optional="optional" maxlength="500" />
                                                    <MDTypography variant="button" id="error_txt_cust_gst_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-5 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="text" id='txt_cust_branch_phone_no' className="erp_input_field" value={txt_cust_branch_phone_no} optional="optional" onChange={(e) => { setCustomerPhoneNo(e.target.value); validateFields(); }} disabled />
                                                    <MDTypography variant="button" id="error_cust_branch_phone_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 mt-3 mt-md-0'>
                                            <Form.Label className="erp-form-label">Company Branch <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_companyBranchId" className="form-select form-select-sm" value={cmb_companyBranchId} onChange={(e) => { if (e.target.value !== '0') { setCompanyBranchId(e.target.value) }; comboOnChange('CompanyBranch'); validateFields(); }}>
                                                <option value="" disabled>Select</option>
                                                {/* <option value="0">Add New Record+</option> */}
                                                {companyBranchesOpts?.map(branch => (
                                                    <option key={branch.company_branch_id} value={branch.company_branch_id}> {branch.company_branch_name} </option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_companyBranchId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Yarn Sale Status </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <select id="cmb_goods_return_status" className="form-select form-select-sm" value={cmb_goods_return_status} onChange={(e) => { setGoodReturnStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Consignee</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            {/* <select id="cmb_consignee_id" className="form-select form-select-sm" value={cmb_consignee_id} onChange={(e) => { setConsigneeId(e.target.value); FnComboOnChange('Consignee'); validateFields(); }} optional="optional"> */}
                                            <Select
                                                ref={consigneeComboRef}
                                                options={consigneeOpts}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_consignee_id" // Provide the ID for the input box
                                                value={consigneeOpts.find(option => option.value === cmb_consignee_id)}
                                                onChange={(selectedOpt) => {
                                                    setConsigneeId(selectedOpt.value);
                                                    consigneeComboRef.current = selectedOpt;
                                                    comboOnChange("Consignee");
                                                    // validateFields();
                                                }}
                                                placeholder="Search for a Consignee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {/* <div className='col-sm-1 col-2 refreshIconCol'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateConsignees'); }} />
                                        </div> */}
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Consignee State & City</Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_consignee_state_id" className="form-select form-select-sm" value={cmb_consignee_state_id} onChange={(e) => { setConsigneeState(e.target.value); comboOnChange('ConsigneeState'); validateFields(); }} optional="optional" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}>
                                                        <option value="">Select</option>
                                                        {stateOpts?.map(state => (
                                                            <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <select id="cmb_consignee_city_id" className="form-select form-select-sm" value={cmb_consignee_city_id} onChange={(e) => { setConsigneeCity(e.target.value); comboOnChange('ConsigneeCity'); }} optional="optional" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}>
                                                        <option value="">Select</option>
                                                        <option value="0">Add New Record+</option>
                                                        {consigneeCityOpts?.map(city => (
                                                            <option value={city.field_id}>{city.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Consignee Address</Form.Label>
                                        </div>
                                        <div className="col-sm-9">
                                            <Form.Control id='txt_consignee_address' as="textarea" rows={2} maxLength="255" className="erp_txt_area" value={txt_consignee_address} onChange={(e) => { setConsingeeAddress(e.target.value); validateFields(); }} disabled optional="optional" />
                                            <MDTypography variant="button" id="error_txt_consignee_address" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Consi. GSTIN & Phone No</Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">

                                                <div className="col-12 col-md-7 pe-md-0">
                                                    <Form.Control type="text" id='txt_consignee_gst_no' className="erp_input_field" value={txt_consignee_gst_no} onChange={(e) => { setConsigneeGStNO(e.target.value); validateFields(); }} optional="optional" maxlength="500" disabled />
                                                    <MDTypography variant="button" id="error_txt_consignee_gst_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-5 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="text" id='txt_consignee_branch_phone_no' className="erp_input_field" value={txt_consignee_branch_phone_no} optional="optional" onChange={(e) => { setConsigneePhoneNo(e.target.value); validateFields(); }} disabled />
                                                    <MDTypography variant="button" id="error_txt_consignee_branch_phone_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Transport Mode</Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">

                                                <div className="col-12 col-md-7 pe-md-0">
                                                    <Form.Control type="text" id='txt_transport_mode' className="erp_input_field" value={txt_transport_mode} onChange={(e) => { setTransportMode(e.target.value); validateFields(); }} optional="optional" maxlength="500" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"} />
                                                    <MDTypography variant="button" id="error_transport_mode" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Driver Name & vehical No</Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">

                                                <div className="col-12 col-md-7 pe-md-0">
                                                    <Form.Control type="text" id='txt_driver_name' className="erp_input_field" value={txt_driver_name} onChange={(e) => { setDriverName(e.target.value); validateFields(); }} optional="optional" maxlength="500" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"} />
                                                    <MDTypography variant="button" id="error_txt_driver_name" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-5 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="text" id='txt_vehical_no' className="erp_input_field" value={txt_vehical_no} optional="optional" onChange={(e) => { setVehicalNo(e.target.value); validateFields(); }} disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"} />
                                                    <MDTypography variant="button" id="error_txt_vehical_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    {cmb_goods_return_status === "A" ?
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Approved By / Date </Form.Label>
                                                </div>
                                                <div className="col-sm-9 col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-7 pe-md-0">
                                                            {/* <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}> */}
                                                            <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} optional="optional"
                                                                onChange={() => {
                                                                    validateFields();
                                                                }} disabled>
                                                                <option value="">Select</option>
                                                                {employeeOptions.length !== 0 ? (
                                                                    <>
                                                                        {employeeOptions?.map(acceptedById => (
                                                                            <option value={acceptedById.field_id}>{acceptedById.field_name}</option>
                                                                        ))}
                                                                    </>
                                                                ) : null}
                                                            </select>
                                                            <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                            </MDTypography>
                                                        </div>
                                                        {/* <div className={`row`}> */}
                                                        {/* <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Approved Date</Form.Label>
                                                </div> */}
                                                        <div className="col-12 col-md-5 pt-md-0 pt-3 ps-md-1">
                                                            <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={e => { setApproveDate(e.target.value); validateFields(); }} min={currentDate} optional='optional' disabled />
                                                            <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                            </MDTypography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div>   */}
                                            {/* <div className={`row`}>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Approved Date</Form.Label>
                                                </div>
                                                <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                                    <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={e => { setApproveDate(e.target.value); validateFields(); }} min={currentDate} optional='optional' disabled />
                                                    <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div> */}
                                        </>
                                        : null
                                    }


                                </div>
                            </div>
                        </form>


                        <Accordion defaultActiveKey="0" activeKey={openYarnDetailsAccord ? '0' : null}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header onClick={(e) => setOpenYarnDetailsAccord(!openYarnDetailsAccord)} className="erp-form-label-md"> Yarn Sale Details</Accordion.Header>
                                <Accordion.Body>

                                    <div className="row">
                                        {
                                            keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                ? <>
                                                    <div className="col-lg-5 col-md-3 col-12">
                                                        <MDButton
                                                            type="button"
                                                            className="erp-gb-button ms-2"
                                                            onClick={AddMaterial}
                                                            variant="button"
                                                            fontWeight="regular"
                                                        >
                                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? "Material List" : "Add Materials"}
                                                        </MDButton>
                                                    </div>
                                                </>
                                                : null
                                        }

                                    </div>

                                    {/* <div className="mt-10" > */}
                                    <div className="row py-1 py-md-3">
                                        {yarnReturnDetailsData.length !== 0 ?
                                            <>
                                                {renderDetailsTable}
                                            </> :
                                            <div className='row  text-center'>
                                                <div className="col-12 ">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion >
                        {chk_yarn_type === "Bottom" && (
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="purchaseOrderSchedules">
                                    <Accordion.Header className="erp-form-label-md">Yarn Bottom Stock Details</Accordion.Header>
                                    <Accordion.Body className="p-0">
                                        <div className="table-responsive erp_table_scroll">
                                            {["", "update"].includes(keyForViewUpdate) && (
                                                <div className='row' style={{ marginTop: "10px" }}>
                                                    <div className='col-sm-1 col-12' style={{ marginLeft: "10px" }}>
                                                        <Form.Label className="erp-form-label">Set No <span className="required">*</span></Form.Label>
                                                    </div>
                                                    <div className={`col-sm-3 col-12`}>
                                                        <Select ref={setNoComboRef}
                                                            options={setNoOptions}
                                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                            inputId="cmb_set_no" // Provide the ID for the input box
                                                            value={setNoOptions.find(option => option.value === cmb_set_no) || null}
                                                            onChange={(selectedOpt) => {
                                                                setSetNo(selectedOpt.value);
                                                                setNoComboRef.current = selectedOpt;
                                                                comboOnChange('set_no');
                                                                validateFields();
                                                            }}
                                                            placeholder="Search for Set No..."
                                                            className="form-search-custom"
                                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                                            styles={{
                                                                option: (provided, state) => ({
                                                                    ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                                }),
                                                                singleValue: (provided, state) => ({
                                                                    ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                                }),
                                                                input: (provided, state) => ({
                                                                    ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                                })
                                                            }}
                                                        />
                                                        <MDTypography variant="button" id="error_cmb_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row py-1 py-md-3">
                                                {renderYarnBottomStockDetailsTable}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )}
                        <Accordion defaultActiveKey="1" onSelect={FnLoadAccordionData} >
                            <Accordion.Item eventKey="paymentTerms">
                                <Accordion.Header className="erp-form-label-md">Payment Terms</Accordion.Header>
                                <Accordion.Body className="ps-1 pt-0">
                                    {
                                        PmtTermsdata.length > 0
                                            ? <>
                                                <div className={`row py-1 px-0 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                    <div className="col-12 col-sm-6">
                                                        {/* <input type='checkbox' class="" id="selectAllPmtTerms" onClick={(e) => checkBoxesSelection('selectAllPmtTerms')} /> <label class="erp-form-label pb-1"> Select All Payment Terms. </label> */}
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <MDButton type="button" className={`erp-gb-button float-sm-end col-1`} variant="button" fontWeight="regular"
                                                            onClick={() => {
                                                                sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                setHeaderName('Payment Terms'); // CommonParamterEntry
                                                                setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                                            }}>Add
                                                        </MDButton>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className={`table-responsive ${PmtTermsdata.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                        <>{renderPaymentTermsTable}</>
                                                    </div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion >

                    </div>
                    <div className="card">
                        <div className="card-header main_heding text-start py-1">
                            <label className='erp-form-label-lg'>Yarn Sales Totals</label>
                        </div>

                        <div className="card-body">
                            <form id="yarnSaleTotalsFrm">
                                <div className="row">
                                    {/* first column */}
                                    <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Basic Total</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_basic_total' className="erp_input_field text-end" value={txt_basic_total} readOnly />
                                                <MDTypography variant="button" id="error_txt_basic_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>



                                        <div className='row mb-md-3 mb-xl-0'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label">Freight Amt.</Form.Label>
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 col-xl-3">
                                                        <Form.Check className="erp-form-label" type="checkbox" label={`IsTaxable`} id="chk_freight_isTaxable" checked={chk_freight_isTaxable} onChange={async (e) => { setFreightIsTaxable(e.target.checked); validateFields(); calculateGrandTotal(yarnReturnDetailsData); }} optional="optional" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-3 pt-xl-0 pe-xl-0 me-xl-0">
                                                        <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount} maxLength='19' optional="optional" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                                            onChange={async (e) => { setFreightAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(yarnReturnDetailsData); }}
                                                            onBlur={async (e) => { setFreightAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4) === '' ? 0 : validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(yarnReturnDetailsData); reverseCalculationForFreightAmt(); }}
                                                        />
                                                        <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-xl-6 pt-md-0 pt-3 ps-xl-1">
                                                        <select id="cmb_freight_hsn_id" className="form-select form-select-sm" value={cmb_freight_hsn_id} onChange={async (e) => { setFreightHSNId(e.target.value); calculateGrandTotal(yarnReturnDetailsData); validateFields() }} disabled={chk_freight_isTaxable ? false : true} {...(chk_freight_isTaxable ? {} : { optional: 'optional' })}>
                                                            <option value="">Select</option>
                                                            {freightHSNOpts?.map(hsnCode => (
                                                                <option value={hsnCode.hsn_sac_id} rate={hsnCode.hsn_sac_rate} lbl={hsnCode.hsn_sac_code}>{hsnCode.hsn_sac_code}</option>
                                                            ))}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_freight_hsn_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className='row' style={{ display: "none" }}>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Discount % </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_discount_percent' className="erp_input_field  text-end" value={txt_discount_percent} optional="optional"
                                                    onChange={(e) => { handleFooterDiscountPercentChange(e); }} onBlur={(e) => { handleFooterDiscountPercentChange(e); }} />
                                                <MDTypography variant="button" id="error_txt_discount_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row' style={{ display: "none" }}>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Discount Amt.</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_discount_amount' className="erp_input_field text-end" value={txt_discount_amount} optional="optional"
                                                    onChange={(e) => { handleFooterDiscountAmountChange(e) }} onBlur={(e) => { handleFooterDiscountAmountChange(e) }} />
                                                <MDTypography variant="button" id="error_txt_discount_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Taxable Total</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_taxable_total' className="erp_input_field text-end" value={txt_taxable_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_taxable_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>

                                    {/* second column */}
                                    <div className="col-lg-3 col-md-6 col-12 erp_form_col_div">
                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Transport Amt.</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_transport_amount' className="erp_input_field text-end" value={txt_transport_amount} maxLength='19' optional="optional" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                                    onChange={(e) => { setTransportAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(yarnReturnDetailsData); }}
                                                    onBlur={(e) => { setTransportAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(yarnReturnDetailsData); }}
                                                />
                                                <MDTypography variant="button" id="error_txt_transport_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Packaging Amt. </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_packing_amount' className="erp_input_field text-end" value={txt_packing_amount} maxLength='19' optional="optional" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                                    onChange={(e) => { setPackingAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(yarnReturnDetailsData); }}
                                                    onBlur={(e) => { setPackingAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(yarnReturnDetailsData); }}
                                                />
                                                <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Other Amt. </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_other_amount' className="erp_input_field text-end" value={txt_other_amount} maxLength='19' optional="optional" disabled={keyForViewUpdate === "view" || keyForViewUpdate === "approve"}
                                                    onChange={(e) => { setOtherAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(yarnReturnDetailsData); }}
                                                    onBlur={(e) => { setOtherAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(yarnReturnDetailsData); }}
                                                />
                                                <MDTypography variant="button" id="error_txt_other_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                    </div>

                                    {/* third column */}
                                    <div className="col-lg-5 col-md-6 col-12 erp_form_col_div">

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-4 pe-0">
                                                <Form.Label className="erp-form-label"> CGST Total </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_cgst_total' className="erp_input_field text-end" value={txt_cgst_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_cgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-4 pe-0">
                                                <Form.Label className="erp-form-label"> SGST Total </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_sgst_total' className="erp_input_field text-end" value={txt_sgst_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_sgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-4 pe-0">
                                                <Form.Label className="erp-form-label"> IGST Total </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_igst_total' className="erp_input_field text-end" value={txt_igst_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_igst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label"> Grand Total & Round Off </Form.Label>
                                            </div>
                                            <div className="col-sm-8 col-12">
                                                <div className='row'>
                                                    <div className="col-12 col-md-6 pe-md-0">
                                                        <Form.Control type="text" id='txt_grand_total' className="erp_input_field text-end" value={txt_grand_total} readOnly maxLength='19' />
                                                        <MDTypography variant="button" id="error_txt_grand_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-md-6 pe-md-0">
                                                        <Form.Control type="text" id='txt_roundoff' className="erp_input_field text-end" value={txt_roundoff} readOnly maxLength='19' />
                                                        <MDTypography variant="button" id="error_txt_roundoff" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {/* Row End Billing */}
                            </form>
                        </div>
                    </div>
                    {/* Billing Section Ends*/}

                    <hr />
                    <div className='erp_frm_Btns'>
                        <MDButton type="button"
                            onClick={() => {
                                const path = compType === 'Register' ? '/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing/reg' : '/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing';
                                navigate(path);
                            }}
                            className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" >Back</MDButton> &nbsp;
                        <MDButton type="button" onClick={FnAddUpdateRecord} className={`erp-gb-button ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" >{keyForViewUpdate === "approve" ? "Approve" : keyForViewUpdate === "" ? "Save" : "Update"}
                        </MDButton>
                        {
                            keyForViewUpdate === 'view'
                                ? <>
                                    <MDButton className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => { printInvoice(true); }} >Print Yarn Sale <FiDownload className="erp-download-icon-btn" /></MDButton>
                                </>
                                : null
                        }
                    </div>
                    <Modal size="lg" className='erp_document_Form m-0' show={showWRFilterForm} onHide={FnCloseStockModal} backdrop="static" keyboard={false} centered>
                        <Modal.Header>
                            <Modal.Title className='erp_modal_title'>Yarn Stock Report</Modal.Title>
                            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseStockModal}></button></span>
                        </Modal.Header>
                        <Modal.Body>
                            <React.Fragment>
                                {isLoading && (
                                    <div className="spinner-overlay">
                                        <div className="spinner-container">
                                            <CircularProgress color="primary" />
                                            <span>Loading...</span>
                                        </div>
                                    </div>
                                )}
                                <div className='row d-flex justify-content-between'>
                                    <div className='col-sm-4'>
                                        {/* <label className="erp-form-label-md-lg mt-0">Material Against Order</label> */}
                                    </div>

                                    <div className="col-sm-5 mb-3">
                                        <div className={` ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}>
                                            {/* <div className="col-md-8 col-12"> */}
                                            <SearchItemFilter getSearchData={FnGetStockDetails} formIdForValidate="" requiredCols={requiredCols} godownId={godownID} isMainGodown={true} product_type_id={12} />
                                            {/* </div> */}
                                        </div>

                                    </div>

                                </div>
                                {!isLoading && renderStockDetailsTable}
                            </React.Fragment>
                        </Modal.Body>
                        <Modal.Footer className='erp-modal-footer'>
                            <Button variant="success" className={`erp-gb-button ${keyForViewUpdate === 'view' || materialRequisitionDetails.length === 0 ? 'd-none' : 'display'}`} onClick={FnConsumeStock}>
                                ok
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <Modal size="lg" show={showAddRecModal} onHide={handleCloseAddRecModal} backdrop="static" keyboard={false} centered >
                        <Modal.Body className='erp_city_modal_body'>
                            <div className='row'>
                                <div className='col-12 align-self-end'>
                                    <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseAddRecModal}></button>
                                </div>
                            </div>
                            {openAddRecordPopUp()}
                        </Modal.Body>
                    </Modal >
                    <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                    <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                    <hr />
                    {/* </form> */}
                </>
            </DashboardLayout >
        </>
    )
}