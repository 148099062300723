import React, { forwardRef, useState, useEffect, useRef } from 'react';
import Select from "react-select";
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";

const CottonBalesSearchBar = React.memo(forwardRef(({ searchKeyword, queryparams, distinctProductMaterialIds }, ref) => {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_CATEGORY_COUNT, FINANCIAL_SHORT_NAME, UserId } = configConstants;

    const [inputValue, setInputValue] = useState(''); // Initialize inputValue as an empty string
    const [cottonBaleOptions, setCottonBaleOptions] = useState([]);

    // Custom SingleValue component that renders nothing
    const CustomSingleValue = (props) => null;

    const debounceTimeout = useRef();
    const comboDataAPiCall = useRef();

    // Debounce effect to trigger search after typing stops
    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // If the input is empty, reset options
        if (inputValue.trim() === '') {
            setCottonBaleOptions([]); // Clear options when no input
            return;
        }

        debounceTimeout.current = setTimeout(() => {
            if (inputValue) {
                fetchSearchResults(inputValue); // Call API when typing stops
            }
        }, 200);

        return () => clearTimeout(debounceTimeout.current);
    }, [inputValue]); // Update debounce effect whenever inputValue changes


    //Check alphabet
    function containsAlphabet(str) {
        return /[a-zA-Z]/.test(str);
    }

    // Fetch search results from API
    const fetchSearchResults = async (searchValue) => {
        debugger
        try {
            resetGlobalQuery();
            globalQuery.columns.push("spr.*");
            globalQuery.table = "sm_product_rm spr";

            if (queryparams === true) {
                globalQuery.columns.push("sprc.product_rm_std_weight", "chs.hsn_sac_rate AS product_rm_hsn_sac_rate", "chs.hsn_sac_code", "sprc.cost_center_id");
                globalQuery.joins = [
                    {
                        "table": `sm_product_rm_commercial sprc`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "sprc.product_rm_id",
                                "right": "spr.product_rm_id",
                            },
                            {
                                "left": "sprc.is_delete",
                                "right": "0"
                            }
                        ]
                    },

                    {
                        "table": `cm_hsn_sac chs`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "chs.hsn_sac_id",
                                "right": "spr.product_rm_hsn_sac_code_id",
                            },
                            {
                                "left": "chs.is_delete",
                                "right": "0"
                            }
                        ]
                    },
                ];
            }
            // if (distinctProductMaterialIds.length > 0) {
            //     globalQuery.conditions.push({
            //         field: "spr.product_rm_id",
            //         operator: "NOT IN",
            //         values: distinctProductMaterialIds
            //     });
            // }
            globalQuery.conditions.push({ field: "spr.product_type_id", operator: "=", value: 12 });
            globalQuery.conditions.push({ field: "spr.company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "spr.is_delete", operator: "=", value: 0 });
            if (containsAlphabet(searchValue)) {
                globalQuery.conditions.push({ field: "spr.product_rm_name", operator: "like", value: `${searchValue}%` });
            }else{
                globalQuery.conditions.push({ field: "spr.product_rm_code", operator: "like", value: `${searchValue}%` });
            }
           
            // Early exit if searchValue is empty
            if (!searchValue.trim()) {
                setCottonBaleOptions([]);
                return;
            }

            const getCottonBaleApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const cottonBaleOptions = getCottonBaleApiCall.map((bale) => ({
                ...bale,
                value: bale.product_rm_code,
                label: `${"[" + bale.product_rm_code + "]"} ${bale.product_rm_name}`
            }));

            setCottonBaleOptions(cottonBaleOptions); // Update options
        } catch (error) {
            console.error("Error fetching search results:", error);
        } finally {

        }
    };



    // Handle option selection
    const handleBaleOptions = (selectedOption) => {
        if (searchKeyword) {
            searchKeyword(selectedOption); // Send selected option data to the parent
        }
        setCottonBaleOptions([]); // Optionally clear options after selection

    };

    // Allow parent component to access the input value
    useEffect(() => {
        if (ref) {
            ref.current = {
                getValue: () => inputValue,
                clear: () => setInputValue(''), // Clear the input value
            };
        }
    }, [inputValue, ref]);

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <Select
                options={cottonBaleOptions}
                onChange={handleBaleOptions} // Handle option selection
                onInputChange={(newInputValue) => setInputValue(newInputValue)} // Update input value on typing
                placeholder="Search Cotton Bale...."
                className="form-search-custom"
                classNamePrefix="custom-select"
                styles={{
                    option: (provided) => ({
                        ...provided,
                        fontSize: '12px',
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        fontSize: '12px',
                    }),
                    input: (provided) => ({
                        ...provided,
                        fontSize: '12px',
                    }),
                }}
                components={{ SingleValue: CustomSingleValue }} // Override SingleValue component
            />
        </>
    );
}));

export default CottonBalesSearchBar;
