import React, { useState, useRef, useEffect, useMemo, useLayoutEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $, { event } from 'jquery';
import html2pdf from 'html2pdf.js';
import Select from 'react-select';
// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button, Card } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React icons
import { MdDelete, MdRefresh } from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";

// PopUp Modal Display Components.
import Document from "Features/Document";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import { CircularProgress, fabClasses } from "@mui/material";
import MailSentModal from "components/Modals/MailSentModal";
import { FiDownload } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import SalesOrderInvoice from "FrmGeneric/Invoice/SalesOrderInvoice";
import ConfigConstants from "assets/Constants/config-constant";
import ProductList from "FrmGeneric/ProductList";
import CommonParamterEntry from "Masters/MCommonParameter/CommonParamterEntry";
import FrmPaymentTermsEntry from "Masters/MPaymentTerms/FrmPaymentTermsEntry";
import { Tooltip } from "@material-ui/core";
import { RxCrossCircled } from "react-icons/rx";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import FrmPropertyEntry from "Masters/MProperty/FrmPropertyEntry";
import FrmHSNSAC from "Masters/MHSN-SAC/FrmHSN-SAC";
import { MdModeEdit } from "react-icons/md";
import ConfirmationModal from "components/Modals/ConfirmationModal";

import { AiFillEye } from "react-icons/ai";
import Datatable from "components/DataTable";
import MaterialSearchBar from "FrmGeneric/MaterialSearchBar";

//For Invoice
// let originalContent = '';



function SalesOrderEntry() {
    // For upload documents
    const navigate = useNavigate();
    let docGroup = "Trading Sales Order";
    let commonTermMstName = 'Sales Orders Commercial Terms'

    let colorPropertyMasterName = 'Colour';
    let defaultColour = 'White';

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, COMPANY_NAME, FINANCIAL_SHORT_NAME, UserId, UserName } = configConstants;

    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType = "Transaction", deletionKey = false, requestfor, modules_forms_id } = state || {};
    let salesOrdReqData = idList

    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'approve':
                return '(Approval)';
            case 'update':
                return '(Modification)';
            case 'view':
                if (deletionKey === true) {
                    return '(Deletion)';
                } else {
                    return '(View)';
                }
            case 'copy':
                return '(Copy Sales Order)';
            default:
                return '(Creation)';
        }
    };
    const [isApprove, setIsApprove] = useState(false);

    // Bom Filter 
    let requiredColsForOpenFilter = ['product_type_id', 'product_type_name', 'product_id', 'product_name', 'product_tech_spect', 'product_unit_name', 'product_packing_name', 'product_hsn_sac_rate', 'product_hsn_sac_code',
        'product_std_weight', 'product_landed_price', 'product_moq', 'product_lead_time', 'product_mrp',
        'product_unit_id', 'product_packing_id', 'product_hsn_sac_code_id'
    ];

    // Raw material search Filter 
    let requiredColsForRMRequirement = ['product_type_id', 'product_type_name', 'product_id', 'product_name', 'product_tech_spect',
        'product_unit_id', 'product_packing_id', 'product_hsn_sac_code_id', 'stock_quantity', 'stock_weight', 'total_box_weight',
        'total_quantity_in_box', 'weight_per_box_item', 'cost_center_id'
    ];
    // For Taxation Summary.
    let taxationSummary = [];

    const currentDate = new Date().toISOString().split('T')[0];

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };


    //-----------upload file-------------
    const [docSuccMsg, setdocSuccMsg] = useState('');
    const [document_id, setDocument_id] = useState(0)
    const [uploadedDocData, setUploadedDocData] = useState([]);
    const [txt_sales_order_no, setSalesOrdNo] = useState("0");
    const [docFormHeading, setDocFormHeading] = useState('Document Information');
    //Insert Document Form Fields
    const [documentGroup, setDocumentGroup] = useState('');
    const [documentName, setDocumentName] = useState('');
    const [documentRegistrationNo, setDocumentRegistrationNo] = useState('');
    const [documentRegistrationDate, setDocumentRegistrationDate] = useState('');
    const [documentRenewalDate, setDocumentRenewalDate] = useState('');
    const [documentFile, setDocument] = useState('');
    const [remarks, setRemarks] = useState('');
    const [document_path, setDocumentPath] = useState('');
    const [is_actives, setIsActives] = useState(false);
    const [file_name, setFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // keys for update or view 
    const [docViewUpdateKey, setDocViewUpdateKey] = useState('');
    const [showSuccessMsgModals, setShowSuccessMsgModals] = useState(false);
    const [handleSuccessModal, setHandelSuccessModal] = useState(false);
    const handleCloseDocSuccessModals = () => {
        setShowSuccessMsgModals(false);
    };
    // Pagination Variables 
    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
    ]
    var [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[0].value);
    const [pageCount, setpageCount] = useState(0);
    const [PageCurrent, setcurrentPage] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    //React Table data & column fields
    const [docData, setDocumentData] = useState([]);
    const [columns, setColumns] = useState([]);
    var columnHeads;
    async function showAddDocumentForm() {
        clearDocFormFields();
        setDocument_id(0)
        setDocFormHeading('Document Information')
        setDocViewUpdateKey("")
        await validate.current.removeReadOnlyAttr('docFormId')
        $("input[type=radio]").attr('disabled', false);
    }
    const fetchComments = async (currentPage) => {

        try {
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_sales_order_no !== 0 ? txt_sales_order_no.replaceAll('/', '_') : null}&page=0&size=${entriesPerPage}`);
            const resp = await res.json();
            console.log("Responce when fetch comments : ", resp)
            const data = resp.content
            if (data.length === 0) {
                showAddDocumentForm();
            }
            // setDocumentData(data);
            setUploadedDocData(data);
            return data;

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    };

    useEffect(() => {
        const fetchInitOrderLayoutData = async () => {
            debugger
            // FnSetDefaultFormFields();
            $('#spinner_id').show();
            $('#spinner_text').text('Loading...');
            await FnSetFieldsByUserAccess();
            await FnInitOrderLayoutData();
            await showDocumentRecords();

            if (salesOrdReqData !== null) {
                await FnGetDataForUpdate();
            }
            // else {
            //     await FnGenerateSalesOrderNo(sales_order_type);    // For Without order-type
            // }
            await FnSetFieldsByUserAccess();
            $('#spinner_id').hide();
        };
        fetchInitOrderLayoutData()
    }, []);


    const handlePageClick = async (pageNo) => {
        let currentPage = pageNo.selected;
        setcurrentPage(currentPage);
        await fetchComments(currentPage);
    }


    const handleClick = () => {
        if (docViewUpdateKey === 'update') {
            updateDocument(document_path, documentFile.name);
        } else {
            addDocument();
        }
    };
    const handlePageCountClick = () => {
        var count = document.getElementById("page_entries_id").value;
        setEntriesPerPage(count)
        showDocumentRecords();
    }

    const infoForUpdate = async (document_id, key) => {
        try {
            setIsLoading(true)
            const docInfoApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecordForUpdate/${document_id}`)
            const responce = await docInfoApiCall.json();

            setDocumentGroup(responce.document_group)
            setDocumentName(responce.document_name)
            setDocumentRegistrationNo(responce.document_registration_no)
            setDocumentRegistrationDate(responce.document_registration_date)
            setDocumentRenewalDate(responce.document_renewal_date)
            setDocumentPath(responce.document_path)
            setRemark(responce.remark)
            setIsActive(responce.is_active)
            setFileName(responce.file_name)

            if (key === "view") {
                setDocFormHeading('View Document')
                setDocViewUpdateKey("view")
                $("input[type=radio]").attr('disabled', true);
                await validate.current.readOnly('docFormId')
                $("#documentName_id").prop('disabled', true);
            } else if (key === "update") {
                setDocViewUpdateKey("update")
                setDocFormHeading('Modify Document')
                await validate.current.removeReadOnlyAttr('docFormId')
                $("input[type=radio]").attr('disabled', false);
                $("#documentName_id").prop('disabled', true);

            }

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }

        setIsLoading(false)
    }

    const tempInfoForUpdate = async (data, key) => {

        try {
            setIsLoading(true)
            const responce = data;
            setDocumentGroup(responce.document_group)
            setDocumentName(responce.document_name)
            setDocumentRegistrationNo(responce.document_registration_no)
            setDocumentRegistrationDate(responce.document_registration_date)
            setDocumentRenewalDate(responce.document_renewal_date)
            setDocumentPath(responce.document_path)
            setRemark(responce.remark)
            setIsActive(responce.is_active)
            setFileName(responce.file_name)

            if (key === "view") {
                setDocFormHeading('View Document')
                setDocViewUpdateKey("view")
                $("input[type=radio]").attr('disabled', true);
                await validate.current.readOnly('docFormId')
                $("#documentName_id").prop('disabled', true);

            } else if (key === "update") {
                setDocViewUpdateKey("update")
                setDocFormHeading('Modify Document')
                await validate.current.removeReadOnlyAttr('docFormId')
                $("input[type=radio]").attr('disabled', false);
                $("#documentName_id").prop('disabled', true);

            }

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }

        setIsLoading(false)
    }
    const updateDocument = async (oldPath, newFileName) => {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                document_path: oldPath,
                newFileName: newFileName,
                // content: newContent
            })
        });

        const data = await response.json();
        if (data.success === "1") {
            console.log("Document updated successfully.");
        } else {
            console.error("Failed to update document:", data.message);
        }
    };



    function viewUpdateDelete(data, key) {
        var document_id = data.document_id
        setDocument_id(document_id)
        switch (key) {
            case 'delete': setShow(true); break;
            case 'update': infoForUpdate(document_id, "update"); break;
            case 'view': infoForUpdate(document_id, "view"); break;
            default: break;
        }
    }

    function tempViewUpdateDelete(data, key) {

        var document_id = data.document_id
        var document_path = data.document_path
        setDocument_id(document_id)
        switch (key) {
            case 'delete': deletetempRecords(document_path); break;
            case 'update': tempInfoForUpdate(data, "update"); break;
            case 'view': tempInfoForUpdate(data, "view"); break;
            default: break;
        }
    }


    const deletetempRecords = async (documentPath) => {

        const url = `${process.env.REACT_APP_BASE_URL}/api/Documents/delete`;
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                // Add any other headers if needed, e.g., authentication tokens
            },
            body: JSON.stringify({ path: documentPath }) // Include the path in the body of the request
        };

        try {
            const deleteApiCall = await fetch(url, requestOptions);
            const response = await deleteApiCall.json();

            if (response.success === '1') {
                // setShowDeleteModal(false);
                setdocSuccMsg(response.message)
                setShowSuccessMsgModals(true);
                setUploadedDocData(prevDocuments =>
                    prevDocuments.filter(doc => doc.document_path !== documentPath)
                );
            } else {
                console.error('Failed to delete record:', response.message || 'Unknown error');
            }
        } catch (error) {
            console.log("error: ", error);
        }
    }


    async function deleteDocument() {
        try {
            const method = { method: 'POST' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnDeleteRecord/${document_id}/${UserName}`, method)
            const responce = await deleteApiCall.json();
            if (responce.success === 1) {
                if (responce.data.document_id === document_id) {
                    clearDocFormFields()
                }
                fetchComments(PageCurrent);
                setShow(false)
            } else {
                setErrMsg(responce.error)
                setShowErrorMsgModal(true)
            }
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }

    }
    // Modal
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_sales_order_no !== 0 ? txt_sales_order_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    const filteredData = resp.content.filter(item => 
                        !( keyForViewUpdate === "" || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve') ? item.is_active : resp.content
                     );
                     setDocumentData(filteredData);
                     }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }
    const validateDocFormFields = () => {
        var formObj = $('#docFormId');
        var inputObj;

        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            }
        }
    }

    const addDocument = async () => {

        try {
            setIsLoading(true)
            // event.preventDefault(); // 👈️ prevent page refresh
            let isValid = await validate.current.validateForm("docFormId");

            if (isValid === false && documentFile === null) {
                isValid = true;
                $('#error_document').hide();
            }

            if (isValid) {
                const data = {
                    document_id: document_id,
                    company_id: COMPANY_ID,
                    group_id: txt_sales_order_no !== 0 ? txt_sales_order_no.replaceAll('/', '_') : null,
                    document_group: docGroup,
                    document_name: documentName,
                    document_registration_no: documentRegistrationNo,
                    document_registration_date: documentRegistrationDate,
                    document_renewal_date: documentRenewalDate,
                    remark: remarks,
                    is_active: is_actives,
                    created_by: UserName,
                    modified_by: document_id !== 0 ? UserName : null
                };
                const updatedata = {
                    document_path: document_path,
                    newFile: documentFile.name,

                };
                const formData = new FormData();

                formData.append(`file`, documentFile)
                formData.append(`data`, JSON.stringify(data))
                formData.append(`updatedata`, JSON.stringify(updatedata))

                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                // const responce = '';
                if (keyForViewUpdate === '') {
                    const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnAddUpdateTempRecord`, requestOptions)
                    const resp = await apicall.json();
                    if (resp.success === 0) {
                        console.log("response error: ", resp.error);
                        setErrMsg(resp.error)
                        setShowErrorMsgModal(true)
                    } else {
                        console.log("search", resp);
                        setdocSuccMsg(resp.message)
                        setShowSuccessMsgModals(true);
                        clearDocFormFields();
                        // setUploadedDocData(responce.data);
                        // setUploadedDocData(prevData => [...prevData,[responce.data]]);
                        // setUploadedDocData(prevData => [...prevData, ...resp.data]);
                        if (resp.data.length > 0) {

                            let column = [];
                            const total = resp.totalElements;
                            setpageCount(Math.ceil(total / entriesPerPage));
                            columnHeads = Object.keys(resp.data[0]);
                            console.log("Document Records: ", resp)
                            for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                                if (colKey === 0) {
                                    column.push({
                                        Headers: "Action", accessor: "Action",
                                        Cell: row => (
                                            <div style={{ display: "flex" }}>
                                                <MdModeEdit className="erp-edit-btn" onClick={e => tempViewUpdateDelete(row.original, 'update')} disabled={keyForViewUpdate === 'view' ? true : false} />
                                                <MdDelete className="erp-delete-btn" onClick={e => tempViewUpdateDelete(row.original, 'delete')} disabled={keyForViewUpdate === 'view' ? true : false} />
                                                <AiFillEye className="erp-view-btn" onClick={e => tempViewUpdateDelete(row.original, 'view')} disabled={keyForViewUpdate === 'view' ? true : false} />
                                            </div>
                                        ),
                                    });
                                }
                                if (!columnHeads[colKey].includes('_id'))
                                    column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });

                            }

                            // setUploadedDocData(resp.content);
                            // setUploadedDocData(prevData => [...prevData, ...resp.data]);
                            setUploadedDocData(prevData => {
                                // Filter out any entries with the same document_path
                                const filteredData = prevData.filter(
                                    item => !resp.data.some(newItem => newItem.document_path === item.document_path)
                                );

                                // Add the new data
                                return [...filteredData, ...resp.data];
                            });
                            setColumns(column);
                        }

                    }
                } else {
                    const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnAddUpdateRecord`, requestOptions)
                    const responce = await apicall.json();
                    if (responce.success === 0) {
                        console.log("response error: ", responce.error);
                        setErrMsg(responce.error)
                        setShowErrorMsgModal(true)
                    } else {
                        console.log("search", responce);
                        setdocSuccMsg(responce.message)
                        setShowSuccessMsgModals(true);
                        clearDocFormFields();
                        await showDocumentRecords();
                        // await FnShowDocumentRecordsToExport();

                    }
                }


            }
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
        setIsLoading(false)
    };
    const handleFileChange = (e) => {

        if (e.target.files) {
            var fileName = e.target.files[0];
            setDocument(fileName);
            $('#error_document').hide();
        }
    };


    const clearDocFormFields = async () => {
        setDocumentName('');
        setDocumentRegistrationNo('');
        setDocumentRegistrationDate('');
        setDocumentRenewalDate('');
        setDocument('');
        setRemark('');
        setFileName('')
        $('#document').val('')
        await validate.current.removeReadOnlyAttr('docFormId')
        $("input[type=radio]").attr('disabled', false);
        $("#documentName_id").prop('disabled', false);
    }
    //--------------------------------------------------
    // For today get date
    const FnFormattedDateForInputField = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const FnGetExpectedScheduleGraceDate = (afterDays) => {
        let currentDate = new Date();
        const dtAfterGraceDays = new Date(currentDate);
        dtAfterGraceDays.setDate(currentDate.getDate() + afterDays);

        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const todayDate = FnFormattedDateForInputField(new Date());

    // For navigate
    const navigator = useNavigate();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();

    // Combo box options.
    const [subDepartmentOPtions, setSubDepartmentOptions] = useState([])
    const [companyBranchesOpts, setCompanyBranchesOpts] = useState([]);
    const [companyBrancheCityOpts, setCompanyBrancheCityOpts] = useState([]);
    const [salesOrdTypesOpts, setSalesOrdTypesOpts] = useState([]);
    const [stateOpts, setStateOpts] = useState([]);
    const [customerOpts, setCustomerOpts] = useState([]);
    const [customerCityOpts, setCustomerCityOpts] = useState([]);
    const [consigneeOpts, setConsigneeOpts] = useState([]);
    const [consigneeCityOpts, setConsigneeCityOpts] = useState([]);
    const [quotationOpts, setQuotationOpts] = useState();
    const [deptOpts, setDeptOpts] = useState([]);
    const [supplierBranchOpts, setSupplierBranchOpts] = useState([]);


    const [approveByOpts, setApproveByOpts] = useState([]);
    const [freightHSNOpts, setFreightHSNOpts] = useState([]);
    const [agentOpts, setAgentOpts] = useState([]);
    const [productUnitOpts, setProductUnitOpts] = useState([]);
    const [colorOpts, setColorOpts] = useState([]);
    const [hsnCodesOptions, setHsnCodesOptions] = useState([]);
    // Header section form fields.
    const [cmb_companyBranchId, setCompanyBranchId] = useState();
    const [cmb_companyBranchStateId, setCompanyBranchStateId] = useState();
    const [cmb_companyBranchCityId, setCompanyBranchCityId] = useState();
    const [sales_order_master_transaction_id, setSalesOrderMasterTransactionId] = useState(0)
    const [rb_sales_order_creation_type, setSalesOrdCreationType] = useState("M");
    const [cmb_sales_order_type_id, setSalesOrderTypeId] = useState('');                  // Hardcoded company type id from am_properties.
    const [cmb_sales_job_type, setSalesOrderJob] = useState('');
    const [cmb_sales_type_id, setSalesTypeId] = useState('');
    const [cmb_supplier_branch_id, setSupplierBranchId] = useState('');
    const [txt_lot_no, setLotNo] = useState('');
    // const [cmb_sales_order_type_id, setSalesOrderTypeId] = useState(2);              // Hardcoded company type id from am_properties.
    // const [sales_order_type, setSalesOrderType] = useState('TD');                    // Hardcoded company type id from am_properties.
    const [txt_sales_order_version, setSalesOrdVer] = useState(1);
    const [dt_sales_order_date, setSalesOrdDate] = useState(todayDate);
    const [rb_sales_order_life, setSalesOrdLife] = useState('C');
    // customer details form fields
    const [cmb_customer_id, setCustomerId] = useState(null);

    // const [cmb_customer_order_no, setCustomerOrderNo] = useState();
    const [txt_customer_order_no, setCustomerOrderNo] = useState('');
    const [cmb_sales_quotation_no, setSalesQuotationNo] = useState('');
    const [dt_sales_quotation_date, setSalesQuotationDate] = useState('');
    const [dt_customer_order_Date, setCustomerOrdDate] = useState('');
    const [cmb_customer_state_id, setCustomerState] = useState('');
    const [cmb_customer_city_id, setCustomerCity] = useState('');
    // consignee details form fields
    const [cmb_consignee_id, setConsigneeId] = useState('');
    const [cmb_consignee_state_id, setConsigneeState] = useState('');
    const [cmb_consignee_city_id, setConsigneeCity] = useState('');
    // Sanction details form fields
    const [cmb_department_id, setDeptId] = useState('');
    const [cmb_sub_department, setSubDepartmentId] = useState('')
    const [cmb_approved_by_id, setApprovedById] = useState('');
    const [dt_approved_date, setApproveDate] = useState('');
    const [dt_overall_schedule_date, setOverAllScheduleDate] = useState(todayDate);
    const [txt_remark, setRemark] = useState('');
    const [rb_is_active, setIsActive] = useState('true');
    // Taxation and Billing section form fields
    const [txt_basic_total, setBasicTotal] = useState(0);
    const [txt_transport_amount, setTransportAmt] = useState(0);
    const [chk_freight_isTaxable, setFreightIsTaxable] = useState(false);
    const [txt_freight_amount, setFreightAmt] = useState(0);
    const [cmb_freight_hsn_id, setFreightHSNId] = useState();
    const [txt_packing_amount, setPackingAmt] = useState(0);
    const [txt_discount_percent, setDiscountPercent] = useState(0);
    const [txt_discount_amount, setDiscountAmt] = useState(0);
    const [txt_other_amount, setOtherAmt] = useState(0);
    const [txt_cgst_percent, setCGSTPercent] = useState(0);
    const [txt_cgst_total, setCGSTTotal] = useState(0);
    const [txt_sgst_percent, setSGSTPercent] = useState(0);
    const [txt_sgst_total, setSGSTTotal] = useState(0);
    const [txt_igst_percent, setIGSTPercent] = useState(0);
    const [txt_igst_total, setIGSTTotal] = useState(0);
    const [txt_taxable_total, setTaxableTotal] = useState(0);
    const [txt_roundoff, setRoundOff] = useState(0);
    const [txt_grand_total, setGrandTotal] = useState(0);
    const [cmb_agent_id, setAgentId] = useState();
    const [txt_agent_percent, setAgentPercent] = useState(0);
    const [cmb_agent_paid_status, setAgentPaidStatus] = useState('P');

    const [cmb_sales_order_status, setSalesOrdStatus] = useState('P');
    const [rb_sales_order_acceptance_status, setSalesOrdAcceptanceStatus] = useState('P');
    const [txt_status_remark, setStatusRemark] = useState('');
    const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
    const [rb_sales_order_mail_sent_status, setSalesOrderMailSentStatus] = useState('P');
    const [rb_preeclose_sales_order, setPreeCloseSalesOrder] = useState('0');

    // const [searchTerm, setSearchTerm] = useState('');
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    //const [selectedOption, setSelectedOption] = useState(null);
    // From am_company_settings.
    let expectedScheduleGraceDays = 0;

    // **** All Table Data Hooks
    const [departmentDetails, setDepartmentDetails] = useState([]);                   // Customers contact details table data hooks
    const [custContactDetails, setCustContactDetails] = useState([]);                   // Customers contact details table data hooks
    const [salesOrdDetailsData, setSalesOrdDetailsData] = useState([]);                 // For Stoe the material details of the sales order
    const [salesOrdDetailsTrackingData, setSalesOrdDetailsTrackingData] = useState([]); // Sales Order details tracking Table Data Hooks
    const [scheduleDataArray, setScheduleDataArray] = useState([]);                     // For the all schedules in accordion.
    let [PmtTermsdata, setPmtTermsData] = useState([]);                                 // Payment terms Table Data Hooks
    let [PmtTermsdataResp, setPmtTermsDataResp] = useState([]);                         // Updated by Fncheck Update Response Payment terms Table Data Hooks
    let [SOCommonTermsData, setSOCommonTermsData] = useState([]);                       // Common Terms for sales order Table Data Hooks   
    let [SOCommonTermsDataResp, setSOCommonTermsDataResp] = useState([]);               // Updated by Fncheck Update Response Common Terms for sales order Table Data Hooks   
    let [taxationSummaryData, setTaxationSummaryData] = useState([]);
    let [saudaShitSummaryData, setSaudaShitSummaryData] = useState([]);                   // Taxation Table Data Hooks
    const [rmRequirementData, setRmRequirementData] = useState([]);                     // Raw Material Requirement
    const [rawMaterialPropertiesData, setRawMaterialPropertiesData] = useState([]);
    const [rawMaterialPropertiesColumns, setRawMaterialPropertiesColumns] = useState([]);
    const [salesOrderTypeArr, setSalesOrderTypeArr] = useState([]);
    const [jobTypeArr, setJobTypeArr] = useState([]);

    // Product Type Properties Table Data
    //changes by tushar 
    const [productTypePropertiesData, setProductTypePropertiesData] = useState([]);

    // For print out
    const [salesOrdDetailsDataJson, setSalesOrdDetailsDataJson] = useState([]);
    const [salesOrderMasterDataJson, setSalesOrderMasterDataJson] = useState({});

    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('');

    //Error Msg Modal
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')
    const [checkAgentMail, SetChkAgentMail] = useState([]);


    // Succ Msg Modal
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    };
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Emailing Modal.
    const [showMailSentModal, setShowMailSentModal] = useState(false);
    // After approved mail sent confirmation modal
    const handleCloseMailSentModal = async () => {
        await sendEmail([]);
        setShowMailSentModal(false);
        moveToListing();
    }

    // Document Form Modal
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const viewDocumentForm = () => { setShowDocumentForm(true); }
    const handleCloseDocumentForm = async () => {
        setShowDocumentForm(false)
        await showDocumentRecords();
    };

    // Bom Filter Modal
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);
    const viewBomFilterForm = async () => {
        validateFields();
        let checkIsValidate = await validate.current.validateForm("salesOrderFormId");
        if (checkIsValidate === true && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            sessionStorage.setItem('requiredCols', JSON.stringify(requiredColsForOpenFilter));
            setShowBomFilterForm(true);
        }
    }
    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)
    const [message, setMessage] = useState('')
    const [messageForConfirmation, setMessageForConfirmation] = useState('')
    const closeConfirmationModal = (key) => {
        switch (key) {
            case 'createIndent':
                FnGenerateIndent();
                setShowConfiemationModal(false);
                break;
            case 'Exit':
                setShowConfiemationModal(false);
                moveToListing();
                break;
            default:
                break;
        }
    }
    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => setShowDeleteModal(false);

    const openAddRecordPopUp = () => {
        switch (modalHeaderName) {
            case 'Order Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;
            case 'Add Department':
                return <FrmDepartmentEntry btn_disabled={true} />
            case 'Add Customer City':
            case 'Add Company Branch City':
            case 'Add Consignee City':
                return <FrmCity btn_disabled={true} />;
            case 'Common Parameter':
                return <CommonParamterEntry btn_disabled={true} master_name={commonTermMstName} />;

            case 'Payment Terms':
                return <FrmPaymentTermsEntry btn_disabled={true} />

            case 'Weft Colour':
                return <FrmPropertyEntry btn_disabled={true} property_master_name={colorPropertyMasterName} />
            case 'HSN Code':
                return <FrmHSNSAC />
                break;
            default:
                return null;
        }
    }

    const handleCloseAddRecModal = async () => {
        switch (modalHeaderName) {
            case 'Order Type':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'product_type_short_name'];
                globalQuery.table = "smv_product_type";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const getOrderTypesUpdatedApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setSalesOrdTypesOpts(getOrderTypesUpdatedApiCall);
                break;

            case 'Add Customer City':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_customer_state_id) });
                const getCustomerUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustomerCityOpts(getCustomerUpdatedCities);
                break;

            case 'Add Company Branch City':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_companyBranchStateId) });
                const getCompanyUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCompanyBrancheCityOpts(getCompanyUpdatedCities);
                break;

            case 'Add Consignee City':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_consignee_state_id) });
                const getConsigneeUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setConsigneeCityOpts(getConsigneeUpdatedCities);
                break;

            case 'Add Department':
                // resetGlobalQuery();
                // globalQuery.columns = ['field_id', 'field_name'];
                // globalQuery.table = "cmv_department";
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // const getUpdatedDepartments = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                // setDeptOpts(getUpdatedDepartments);
                setDeptId('');
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department";
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
                globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
                const departmentIdApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                const departmentList = [
                    { value: '', label: 'Select', field_id: '', field_name: '' },
                    { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                    ...departmentIdApiCall.map((department) => ({ ...department, value: department.field_id, label: department.field_name, })),
                ];
                setDeptOpts(departmentList);
                break;

            case 'Common Parameter':
                await FnGetCommonSalesOrderTerms();
                break;

            case 'Payment Terms':
                await FnGetPaymentTerms();
                break;

            case 'Weft Colour':
                // Load the colour properties.
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
                globalQuery.table = "amv_properties"
                globalQuery.conditions.push({ field: "property_master_id", operator: "=", value: 25 });
                globalQuery.conditions.push({ field: "property_name", operator: "!=", value: 'NA' });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedColorProps = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setColorOpts(getUpdatedColorProps);
                break;
            case 'HSN Code':
                //get hsn codes list
                resetGlobalQuery();
                globalQuery.columns = ["field_name", "field_id", "hsn_sac_rate"];
                globalQuery.table = "cmv_hsn_sac";
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                const getHsnCode = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                setHsnCodesOptions(getHsnCode)
                console.log("Updated Hsn Code List: ", getHsnCode);
                break;
            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)
    }


    // const FnGetProductTypePropertiesColumns = (product_type_id) => {
    //     if (product_type_id !== '') {
    //         setRawMaterialPropertiesColumns(() => {
    //             return propertiesColumns.map((column) => ({
    //                 header: column,
    //                 width: '40px',  // Adjust the width as needed
    //             }));
    //         });
    //     }
    // }
    const FnInitOrderLayoutData = async () => {
        debugger
        try {
            // First get the grace days count from company settings.
            resetGlobalQuery();
            globalQuery.columns.push('so_schedule_grace_days');
            globalQuery.table = "am_company_settings"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getScheduleGraceDaysApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            // expectedScheduleGraceDays = getScheduleGraceDaysApiCall.length === 0 ? 0 : getScheduleGraceDaysApiCall[0].so_schedule_grace_days;
            // setOverAllScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays));
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getCompanySettings => {
                    expectedScheduleGraceDays = getCompanySettings.length === 0 ? 0 : getCompanySettings[0].so_schedule_grace_days;
                    setOverAllScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays));
                });


            resetGlobalQuery();
            globalQuery.columns = ["field_name", "field_id", "hsn_sac_rate"];
            globalQuery.table = "cmv_hsn_sac";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getHsnSacCode => {
                    setHsnCodesOptions(getHsnSacCode);
                    console.log("Hsn Code List: ", getHsnSacCode);
                });

            // Load the Sales Order properties.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "property_master_id", operator: "=", value: 180 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'Sales Order Type' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then(getSalesTypeAPICall => {
                setSalesOrderTypeArr(getSalesTypeAPICall);
            })


            // Load the Job Type properties.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "property_master_id", operator: "=", value: 180 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'Sales Type' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then(getJobTypeAPICall => {
                setJobTypeArr(getJobTypeAPICall);
            })


            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'product_type_short_name']
            globalQuery.table = "smv_product_type"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getSalesOrdTypesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setSalesOrdTypesOpts(getSalesOrdTypesApiCall);
            if (requestfor !== "NA") {
                if (getSalesOrdTypesApiCall.length !== 0) {
                    const productType = getSalesOrdTypesApiCall.find((item) => item.product_type_short_name === requestfor);
                    if (productType) {
                        setSalesOrderTypeId(productType.field_id);
                        await FnGenerateSalesOrderNo(productType.product_type_short_name);
                        // await FnGetProductTypePropertiesColumns();
                    }
                }
            }

            // comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            //     .then(productTypeList => {
            //         setSalesOrdTypesOpts(productTypeList);
            //     });


            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'is_sez', 'cust_branch_type']
            globalQuery.table = "cmv_customer_summary"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getCustomersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // setCustomerOpts(getCustomersApiCall);
            // const consignees = getCustomersApiCall.filter(customer => customer.cust_branch_type === "Consignee");
            // setConsigneeOpts(consignees);
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(customerList => {
                    const customerComboList = [
                        { value: "", label: "Select" },
                        { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                        ...customerList.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                    ];
                    setCustomerOpts(customerList);
                    // const consignees = customerList.filter(customer => customer.cust_branch_type === "Consignee");
                    setConsigneeOpts(customerComboList);
                    console.log(customerList)
                });
            // const getDeptsApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "is_delete", "0");
            // setDeptOpts(getDeptsApiCall);
            // const defaultDepartment = getDeptsApiCall.find(department => department.field_name === "Marketing & Proposals Department");
            // if (defaultDepartment) { // Set the default department.
            //     setDeptId(defaultDepartment.field_id);
            // }
            // comboDataAPiCall.current.fillMasterData("cmv_department", "is_delete", "0")
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_department";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
            globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getDepartmentList => {
                    const defaultDepartment = getDepartmentList.find(department => department.field_name === "Marketing & Exports Department");
                    if (defaultDepartment) { // Set the default department.
                        setDeptId(defaultDepartment.field_id);
                    }
                    const departmentList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        { value: '0', label: 'Add New Record+', field_id: '0', field_name: '' },
                        ...getDepartmentList.map((department) => ({ ...department, value: department.field_id, label: department.field_name })),
                    ];
                    setDeptOpts(departmentList);
                    // if(requestfor==="GF"){
                        setDeptId(23)
                        departmentComboRef.current.value = 23
                        FnComboOnChange('Department')
                    // }
                    console.log("Department List: ", departmentList);
                });


            resetGlobalQuery();
            globalQuery.columns.push("user_id as field_id");
            globalQuery.columns.push("user_name as field_name");
            globalQuery.table = "amv_modules_forms_user_access";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: modules_forms_id });
            globalQuery.conditions.push({ field: "approve_access", operator: "=", value: 1 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getApproveEmpAccessList => {
                    setApproveByOpts(getApproveEmpAccessList);
                    console.log("Approve Access Emp List: ", getApproveEmpAccessList);
                });

            // resetGlobalQuery();
            // globalQuery.columns = ['field_id', 'field_name'];
            // globalQuery.table = "cmv_employee"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // // const getEmployeeList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            // // setApproveByOpts(getEmployeeList)
            // comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            //     .then(employeeList => {
            //         setApproveByOpts(employeeList);
            //     });

            // const getProductUnitsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit ", "is_delete", "0");
            // setProductUnitOpts(getProductUnitsApiCall);
            comboDataAPiCall.current.fillMasterData("smv_product_unit", "is_delete", "0")
                .then(unitList => {
                    setProductUnitOpts(unitList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'state_code'];
            globalQuery.table = "cmv_state"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getStatesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // setStateOpts(getStatesApiCall);
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(stateList => {
                    setStateOpts(stateList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['agent_id', 'agent_name', 'agent_std_percent', 'agent_EmailId']
            globalQuery.table = "cm_agent";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            let agentList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            if (agentList.length > 0) {
                const agents = [
                    { value: '', label: 'Select', field_id: '', field_name: '' },
                    { value: '0', label: 'Add New Record+', field_id: '0', field_name: '' },
                    ...agentList.map((agent) => ({ ...agent, value: agent.agent_id, label: agent.agent_name })),
                ];

                setAgentOpts(agents);
                // Set the Default Agent as NA and load it's releated %.
                let defaultAgent = agentList.find(agent => agent.field_name === 'NA');
                if (defaultAgent) {
                    setAgentId(defaultAgent.field_id);
                    setAgentPercent(defaultAgent.agent_std_percent);
                }
            }



            resetGlobalQuery();
            globalQuery.columns = ['hsn_sac_id', 'hsn_sac_code', 'hsn_sac_rate'];
            globalQuery.table = "cmv_hsn_sac"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getFreightHSNCodesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // setFreightHSNOpts(getFreightHSNCodesApiCall);
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(hsnList => {
                    setFreightHSNOpts(hsnList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['company_branch_id', 'company_branch_name', 'branch_state_id', 'branch_city_id'];
            globalQuery.table = "cmv_company_branch"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            const getCompanyBranchesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setCompanyBranchesOpts(getCompanyBranchesApiCall);
            setCompanyBranchId(parseInt(COMPANY_BRANCH_ID));
            const branchInSession = getCompanyBranchesApiCall.find((branch) => branch.company_branch_id === parseInt(COMPANY_BRANCH_ID));
            if (branchInSession !== null) {
                setCompanyBranchStateId(branchInSession.branch_state_id)
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(branchInSession.branch_state_id) });
                const cityApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setCompanyBrancheCityOpts(cityApiCall);
                setCompanyBranchCityId(branchInSession.branch_city_id)
            }
            
            // For get the Payment-terms details.
            // await FnGetPaymentTerms();

            // For get the Sales Order common terms details.
            // await FnGetCommonSalesOrderTerms();

            // Load the colour properties.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
            globalQuery.table = "amv_properties"
            globalQuery.conditions.push({ field: "property_master_id", operator: "=", value: 25 });
            globalQuery.conditions.push({ field: "property_name", operator: "!=", value: 'NA' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(colours => {
                    setColorOpts(colours);
                });
            debugger
            if(keyForViewUpdate == 'approve'){
                // Load the colour properties.
                
                resetGlobalQuery();
                globalQuery.columns = [ 'property_value', 'property_master_id', 'property_id'];
                globalQuery.table = "amv_properties"
                globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DepartmentHeadMails' });
                globalQuery.conditions.push({ field: "property_group", operator: "=", value: 'SO' });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                const departmentHeadMails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                console.log("department head mail Details : " + departmentHeadMails);
                setDepartmentDetails(departmentHeadMails);   
              
            }

            resetGlobalQuery();
            globalQuery.columns = ['supp_branch_id', 'supp_branch_name']
            globalQuery.table = "cm_supplier_branch";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            let supplierList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            if (supplierList.length > 0) {
                const suppliers = [
                    { value: '', label: 'Select' },
                    ...supplierList.map((supp) => ({ ...supp, value: supp.supp_branch_id, label: supp.supp_branch_name })),
                ];
                setSupplierBranchOpts(suppliers);
            }


        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnSetFieldsByUserAccess = async () => {
        // Disable upload buttons.
        // $('#uploadDocBtn').attr('disabled', 'disabled');

        // User Access Manage.
        let userAccessKey = keyForViewUpdate;
        setIsApprove(userAccessKey === 'approve' ? true : false);
        switch (userAccessKey) {
            case 'update':
                $('#save-btn').text('Update');
                $('#clearFormBtn').attr('disabled', 'disabled');
                $('#uploadDocBtn').removeAttr('disabled');
                $('input[name="rb_sales_order_creation_type"]').prop('disabled', true);
                $('#cmb_sales_order_type_id').prop('disabled', true);
                $('#dt_sales_order_date').prop('disabled', true);
                $('#cmb_customer_id').prop('disabled', true);
                $('#cmb_companyBranchId').prop('disabled', true);
                // $('#txt_customer_order_no').prop('disabled', true);
                // $('#dt_customer_order_Date').prop('disabled', true);
                // $('#cmb_sales_quotation_no, #dt_sales_quotation_date').prop("disabled", true);
                break;

            case 'view':
                $('#save-btn').attr('disabled', 'disabled');
                $('#clearFormBtn').attr('disabled', 'disabled');
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('input[type="checkbox"]').prop('disabled', true);
                $('#selectAllPmtTerms, #selectAllCommonTerms').prop("disabled", true);
                $('#cmb_sales_quotation_no, #dt_sales_quotation_date').prop("disabled", true);
                break;

            case 'approve':
                $('#save-btn').text('Approve');
                $('#save-btn').attr('BtnType', 'approve');
                $('#clearFormBtn').attr('disabled', 'disabled');
                $(".document_btns").hide();
                // Input Fields
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                // $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('table .approveField').removeAttr("disabled");
                $('input[type="checkbox"]').prop('disabled', true);
                $('#selectAllPmtTerms, #selectAllCommonTerms').prop("disabled", true);
                $('#cmb_sales_quotation_no, #dt_sales_quotation_date').prop("disabled", true);
                $('#txt_status_remark').prop("disabled", false);
                // $('#cmb_sales_order_status').prop('disabled', false);
                $('input[name="rb_sales_order_acceptance_status"]').prop('disabled', true);
                $('input[name="rb_preeclose_sales_order"]').removeAttr("disabled");
                $('.MaterialWiseRemark').removeAttr('disabled');
                setApproveDate(todayDate);
                break;

            default:
                break;
        }
    }

    const FnGetDataForUpdate = async () => {
        debugger
        $('#spinner_id').hide();
        try {

            $('#spinner_id').show();
            $('#spinner_text').text('Loading...');

            let todayDateForInputField = todayDate;
            let defaultExpecScheduleDate = FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays);

            let sales_order_no;
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterTrading/FnShowAllDetailsAndMastermodelRecords/${salesOrdReqData.sales_order_version}/${salesOrdReqData.financial_year}/${COMPANY_ID}?sales_order_no=${salesOrdReqData.sales_order_no}`)
            const responce = await apiCall.json();
            setSalesOrdDetailsDataJson(responce.salesOrderDetailsRecords);

            if (responce.salesOrderMasterRecord !== null) {
                const existingSalesOrdMasterData = responce.salesOrderMasterRecord
                setSalesOrderMasterDataJson(existingSalesOrdMasterData)
                setSalesOrderTypeId(existingSalesOrdMasterData.sales_order_type_id);
                setSalesTypeId(parseInt(existingSalesOrdMasterData.sales_type));
                setSalesOrderJob(existingSalesOrdMasterData.job_type);
                setSalesOrderMasterTransactionId(existingSalesOrdMasterData.sales_order_master_transaction_id);
                setSalesOrdCreationType(existingSalesOrdMasterData.sales_order_creation_type);
                setSalesOrdNo(existingSalesOrdMasterData.sales_order_no);
                sales_order_no = existingSalesOrdMasterData.sales_order_no;
                setSalesOrdVer(existingSalesOrdMasterData.sales_order_version);
                setSalesOrdDate(existingSalesOrdMasterData.sales_order_date);
                setSalesOrdLife(existingSalesOrdMasterData.sales_order_life);

                if (keyForViewUpdate === 'approve') {
                    setSalesOrdStatus('A');
                } else {
                    setSalesOrdStatus(existingSalesOrdMasterData.sales_order_status);
                }
                // Setting customer's data.
                setCustomerId(existingSalesOrdMasterData.customer_id);
                // Get Customer's Contact data.
                let custContactData = await FnGetCustomersContactPersonsDetails(existingSalesOrdMasterData.customer_id);
                // Set the checked checkboxes first.
                const customerContactPersonsIds = existingSalesOrdMasterData.customer_contacts_ids.split(":").map(Number);
                const rearrangedContactsRows = [
                    ...custContactData.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                    ...custContactData.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
                ];
                setCustContactDetails(rearrangedContactsRows);

                setCustomerOrderNo(existingSalesOrdMasterData.customer_order_no);
                setCustomerOrdDate(existingSalesOrdMasterData.customer_order_Date);
                setCustomerState(existingSalesOrdMasterData.customer_state_id);
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name']
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: existingSalesOrdMasterData.customer_state_id });
                const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setCustomerCityOpts(getCustomerCitiesApiCall);
                setCustomerCity(existingSalesOrdMasterData.customer_city_id);
                customerContactPersonsIds.forEach(function (contactId, index) {
                    $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
                });
                if (existingSalesOrdMasterData.sales_order_creation_type === 'Q') {
                    await FnGetCustomerApprovedQuotations();
                }

                setDeptId(existingSalesOrdMasterData.department_id);
                departmentComboRef.current.value = existingSalesOrdMasterData.department_id;
                if (existingSalesOrdMasterData.department_id !== "" && existingSalesOrdMasterData.department_id !== undefined) {
                    await FnComboOnChange('Department');
                    setSubDepartmentId(existingSalesOrdMasterData.sub_department_id)
                    subDepartmentComboRef.current.value = existingSalesOrdMasterData.sub_department_id;
                }
                if (keyForViewUpdate === 'approve' && (existingSalesOrdMasterData.approved_by_id === 0 || existingSalesOrdMasterData.approved_by_id === null)) {
                    setApprovedById(parseInt(UserId));
                } else {
                    setApprovedById(existingSalesOrdMasterData.approved_by_id);
                }
                setApproveDate(existingSalesOrdMasterData.approved_date);
                setOverAllScheduleDate(existingSalesOrdMasterData.overall_schedule_date);

                // Setting Consignee's data.
                setConsigneeId(existingSalesOrdMasterData.consignee_id);
                setConsigneeState(existingSalesOrdMasterData.consignee_state_id);
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name']
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: existingSalesOrdMasterData.consignee_state_id });
                const getConsigneeCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setConsigneeCityOpts(getConsigneeCitiesApiCall);
                setConsigneeCity(existingSalesOrdMasterData.consignee_city_id);

                setRemark(existingSalesOrdMasterData.remark);
                setLotNo(existingSalesOrdMasterData.lot_no)
                setSupplierBranchId(existingSalesOrdMasterData.supplier_branch_id)

                setIsActive(existingSalesOrdMasterData.is_active.toString());
                setSalesQuotationDate(existingSalesOrdMasterData.sales_quotation_date);
                setSalesQuotationNo(existingSalesOrdMasterData.sales_quotation_no);

                // Footer section.
                setBasicTotal(existingSalesOrdMasterData.basic_total);
                setTransportAmt(existingSalesOrdMasterData.transport_amount);
                setFreightAmt(existingSalesOrdMasterData.freight_amount);
                setFreightHSNId(existingSalesOrdMasterData.freight_hsn_code_id);
                setFreightIsTaxable(existingSalesOrdMasterData.is_freight_taxable);
                setPackingAmt(existingSalesOrdMasterData.packing_amount);
                setDiscountPercent(existingSalesOrdMasterData.discount_percent);
                setDiscountAmt(existingSalesOrdMasterData.discount_amount);
                setOtherAmt(existingSalesOrdMasterData.other_amount);
                setTaxableTotal(existingSalesOrdMasterData.taxable_total);
                setCGSTTotal(existingSalesOrdMasterData.cgst_total);
                setSGSTTotal(existingSalesOrdMasterData.sgst_total);
                setIGSTTotal(existingSalesOrdMasterData.igst_total);
                setGrandTotal(existingSalesOrdMasterData.grand_total);
                setRoundOff(existingSalesOrdMasterData.roundoff);
                setAgentId(existingSalesOrdMasterData.agent_id);
                setAgentPercent(existingSalesOrdMasterData.agent_percent);
                setAgentPaidStatus(existingSalesOrdMasterData.agent_paid_status);
                setSalesOrdAcceptanceStatus(existingSalesOrdMasterData.sales_order_acceptance_status);
                setStatusRemark(existingSalesOrdMasterData.status_remark);
                setOtherTermsConditions(existingSalesOrdMasterData.other_terms_conditions);
                setSalesOrderMailSentStatus(existingSalesOrdMasterData.sales_order_mail_sent_status);
                setPreeCloseSalesOrder(existingSalesOrdMasterData.sales_order_status === 'Z' ? '1' : '0');

                if (keyForViewUpdate === 'copy') {
                    let SOTypeShortName = $('#cmb_sales_order_type_id option:selected').attr('shortName');
                    await FnGenerateSalesOrderNo(SOTypeShortName);
                    // sales_order_no = await FnGenerateSalesOrderNo(sales_order_type);     // For Without ProductType Based.
                    setSalesOrderMasterTransactionId(0);
                    setSalesOrdDate(todayDateForInputField);
                    setSalesOrdStatus('P');
                    // setCustomerOrderNo('');
                    // setCustomerOrdDate(todayDateForInputField);

                    setOverAllScheduleDate(defaultExpecScheduleDate);
                    setAgentPaidStatus('P');
                    setSalesOrdAcceptanceStatus('P');
                    setSalesOrderMailSentStatus('P');
                    setPreeCloseSalesOrder('0');
                    setApprovedById('');
                    setApproveDate('');
                    setStatusRemark('');
                }
                setSaudaShitSummaryData(responce.saudaShitDetails);
                // Set the Material Details.
                if (responce.salesOrderDetailsRecords !== null) {
                    // let taxationSummary = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
                    let taxationSummary = [];

                    // Function to update keys dynamically
                    const updateKeys = (object) => {
                        let taxSummary = {
                            materialId: object.product_material_id,
                            so_sr_no: parseInt(object.so_sr_no),
                            hsnCode: object.product_material_hsn_sac_code,
                            hsn_code_id: object.product_material_hsn_code_id,
                            hsnRate: object.product_material_hsn_sac_rate,
                            taxableAmt: object.material_taxable_amount,
                            CGSTPercent: object.material_cgst_percent,
                            CGSTAmt: object.material_cgst_total,
                            SGSTPercent: object.material_sgst_percent,
                            SGSTAmt: object.material_sgst_total,
                            IGSTPercent: object.material_igst_percent,
                            IGSTAmt: object.material_igst_total,
                            totalAmt: object.material_total_amount
                        }
                        const materialIndex = taxationSummary.findIndex((item) => item.materialId === object.product_id && parseInt(item.so_sr_no) === parseInt(object.so_sr_no));
                        if (materialIndex !== -1) {
                            taxationSummary[materialIndex] = taxSummary;    // Replace the object in-place
                        } else {
                            taxationSummary.push(taxSummary);               // Push the new object if not found
                        }
                        localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));

                        const updatedObject = { ...object };
                        updatedObject.product_id = updatedObject.product_material_id;
                        // updatedObject.product_type_short_name = updatedObject.product_type;
                        // updatedObject.product_type_name = updatedObject.product_type;
                        updatedObject.product_name = updatedObject.product_material_print_name;
                        updatedObject.product_tech_spect = updatedObject.product_material_tech_spect;
                        updatedObject.product_unit_id = updatedObject.product_material_unit_id;
                        updatedObject.product_unit_name = updatedObject.product_material_stock_unit_name;
                        updatedObject.product_packing_id = updatedObject.product_material_packing_id;
                        updatedObject.product_packing_name = updatedObject.product_material_packing_name;
                        updatedObject.product_hsn_sac_code_id = updatedObject.product_material_hsn_code_id;
                        updatedObject.product_hsn_sac_code = updatedObject.product_material_hsn_sac_code;
                        updatedObject.product_hsn_sac_rate = updatedObject.product_material_hsn_sac_rate;
                        updatedObject.product_std_weight = updatedObject.product_material_std_weight;
                        return updatedObject;
                    };

                    let updatedOrderDetailsData = responce.salesOrderDetailsRecords.map(updateKeys);

                    if (keyForViewUpdate === 'copy') {
                        // Update the data for copied sales order.
                        updatedOrderDetailsData = updatedOrderDetailsData.map((ordDetail) => ({
                            ...ordDetail,
                            sales_order_item_status: 'P',
                            sales_order_master_transaction_id: 0,
                            sales_order_details_transaction_id: 0,
                            sales_order_no: sales_order_no,
                            sales_order_date: todayDateForInputField,
                            sales_order_version: 1,
                            // customer_order_no: '',
                            // customer_order_Date: todayDateForInputField,
                            material_schedule_date: defaultExpecScheduleDate,
                        }));

                    } else if (keyForViewUpdate === 'approve') {
                        updatedOrderDetailsData = updatedOrderDetailsData.map((ordDetail) => ({
                            ...ordDetail,
                            sales_order_item_status: ordDetail.sales_order_item_status === 'P'
                                ? "A"
                                : ordDetail.sales_order_item_status,
                        }));
                    }
                    setSalesOrdDetailsData(updatedOrderDetailsData);
                }

                // Set the Tracking details.
                if (responce.salesOrderDetailsTrackingTrading !== null && responce.salesOrderDetailsTrackingTrading.length > 0) {
                    setSalesOrdDetailsTrackingData(keyForViewUpdate === 'copy' ? [] : responce.salesOrderDetailsTrackingTrading);
                }

                // Set Order Material Schedule data.
                if (responce.saleOrderSchedule !== null && responce.saleOrderSchedule.length > 0) {
                    const existingSalesOrdDetailsSchedules = responce.saleOrderSchedule;
                    const updatedSchedulesData = [];

                    const materialWiseSchedules = {};
                    existingSalesOrdDetailsSchedules.forEach((item) => {
                        const key = `${item.product_material_id}:${item.so_sr_no}`;
                        if (!materialWiseSchedules[key]) {
                            materialWiseSchedules[key] = [];
                        }
                        materialWiseSchedules[key].push(item);
                    });
                    // Looping for set the id for schedule
                    for (const key of Object.keys(materialWiseSchedules)) {
                        const [mat_id, so_sr_no] = key.split(':');
                        const materialGroup = materialWiseSchedules[key];
                        materialGroup.forEach((schedule, scheduleCounter) => {
                            schedule.id = `${mat_id}:${so_sr_no}:${scheduleCounter}`;
                            if (keyForViewUpdate === 'copy') {
                                //  //Update the keys for copied sales order.
                                schedule.sales_order_schedules_transaction_id = 0;
                                schedule.sales_order_master_transaction_id = 0;
                                schedule.sales_order_no = sales_order_no;
                                schedule.sales_order_date = todayDateForInputField;
                                schedule.sales_order_version = 1;
                                // schedule.customer_order_no = '';
                                // schedule.customer_order_Date = todayDateForInputField;
                                schedule.sales_order_schedules_trading_item_status = 'P';
                                schedule.sales_order_schedules_trading_item_status_desc = 'Pending';
                                schedule.expected_schedule_date = defaultExpecScheduleDate;
                                schedule.product_id = schedule.product_material_id
                                schedule.product_name = schedule.product_material_name

                            } else if (keyForViewUpdate === 'approve') {
                                schedule.sales_order_schedules_trading_item_status = schedule.sales_order_schedules_trading_item_status = 'P' ? 'A' : schedule.sales_order_schedules_trading_item_status;
                                schedule.sales_order_schedules_trading_item_status_desc = schedule.sales_order_schedules_trading_item_status = 'P' ? 'Aprroved' : schedule.sales_order_schedules_trading_item_status_desc;
                                schedule.product_id = schedule.product_material_id
                                schedule.product_name = schedule.product_material_name
                            } else {
                                schedule.sales_order_schedules_transaction_id = 0;
                                schedule.product_id = schedule.product_material_id
                                schedule.product_name = schedule.product_material_name
                            }
                            updatedSchedulesData.push(schedule);
                        });
                    }
                    setScheduleDataArray(updatedSchedulesData)
                    localStorage.setItem('salesOrderMaterialSchedules', JSON.stringify(responce.saleOrderSchedule));
                }

                // Set Sales order Payment terms data.
                if (responce.saleOrderPaymentTerms !== null) {
                    const existingSalesOrdPmtTermsData = responce.saleOrderPaymentTerms;
                    setPmtTermsDataResp(existingSalesOrdPmtTermsData);
                }

                // Set Sales order common terms data.
                if (responce.salesOrderTermTrading !== null) {
                    const existingCommonTermsData = responce.salesOrderTermTrading;
                    setSOCommonTermsDataResp(existingCommonTermsData);
                }

                //  Sets the taxation Summary
                if (responce.salesOrderTaxSummary !== null) {
                    setCompanyBranchId(responce.salesOrderTaxSummary[0].expected_branch_id)
                    setCompanyBranchStateId(responce.salesOrderTaxSummary[0].expected_branch_state_id)
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name']
                    globalQuery.table = "cmv_city"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "state_id", operator: "=", value: responce.salesOrderTaxSummary[0].expected_branch_state_id });
                    const cityApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCompanyBrancheCityOpts(cityApiCall);
                    setCompanyBranchCityId(responce.salesOrderTaxSummary[0].expected_branch_city_id);
                }


                // changes by tushar
                // if (responce.salesOrderProductDynamicParametersRecord.length !== 0) {
                //     debugger
                //     await fnGetProductProperties(responce.salesOrderProductDynamicParametersRecord);
                // }

                if (responce.salesOrderRawMaterialsRecords.length !== 0) {
                    let rawMaterialData = responce.salesOrderRawMaterialsRecords;
                    const countTypeOpts = [
                        { field_id: 'warp_count', field_name: 'Warp Count' },
                        { field_id: 'weft_count', field_name: 'Weft Count' }
                    ];

                    setRmRequirementData(() => {
                        return rawMaterialData.map((item) => ({
                            ...item,
                            countTypeOpts // Add countTypeOpts to each item
                        }));
                    });

                }

                setTimeout(() => {
                    calculateGrandTotal(responce.salesOrderDetailsRecords)
                }, 300);

            }
        } catch (error) {
            console.log("error in getting existing sales order data for update: ", error)
            navigate('/Error')
        } finally {
            $('#spinner_id').hide();
        }
    }

    const FnComboOnChangeForCustomer = async (key, selectedCustomerId) => {

        try {
            switch (key) {
                // Other cases
                case 'Customer':
                    let selectedCustomer = parseInt(selectedCustomerId);
                    if (selectedCustomer === 0) {
                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) { newTab.focus(); }

                    } else if (selectedCustomer !== "0" && !isNaN(selectedCustomer)) {
                        resetGlobalQuery();
                        globalQuery.columns = ['cust_branch_state_id', 'state_name', 'cust_branch_city_id', 'city_name'];
                        globalQuery.table = "cmv_customer";
                        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                        const getCustomerDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        if (getCustomerDetailsApiCall.length > 0) {
                            setCustomerState(getCustomerDetailsApiCall[0].cust_branch_state_id);
                            await FnComboOnChange('CustomerState');
                            setCustomerCity(getCustomerDetailsApiCall[0].cust_branch_city_id);
                        } else {
                            setCustomerState('');
                            setCustomerCity('');
                            setCustomerCityOpts([]);
                        }
                        await FnGetCustomersContactPersonsDetails(parseInt(selectedCustomer));

                        // Get the Quotations list.
                        if (rb_sales_order_creation_type === 'Q') {
                            await FnGetCustomerApprovedQuotations();
                        }

                        setCustomerOrderNo('')
                        setCustomerOrdDate('')
                    } else {
                        setCustomerOrderNo('')
                        setCustomerOrdDate('')
                        setCustomerState('');
                        setCustomerCity('');
                        setCustomerCityOpts([]);
                        setQuotationOpts([]);
                        setCustContactDetails([]);
                    }
                    break;

            }
        } catch (error) {
            console.log("Error: " + error)
            navigate('/Error')
        }
    }


    const FnComboOnChange = async (key) => {
        debugger
        try {
            switch (key) {
                case 'CreationType':
                    // Get the Quotations list.
                    await FnGetCustomerApprovedQuotations();
                    break;

                case 'SalesOrderStatus':
                    // Update the sales order status
                    break;

                case 'SalesOrderType':
                    let selectedSalesOrdType = $('#cmb_sales_order_type_id').val();
                    if (selectedSalesOrdType === "0") {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Order Type')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200);
                        setSalesOrderTypeId('')
                    } else if (selectedSalesOrdType !== "") {
                        setSalesOrderTypeId(selectedSalesOrdType);
                        const selectedSOType = salesOrdTypesOpts.find(orderType => orderType.field_id === parseInt(selectedSalesOrdType));
                        // setSalesOrderType(selectedSOType.product_type_short_name);
                        await FnGenerateSalesOrderNo(selectedSOType.product_type_short_name);
                    }
                    break;

                case 'CompanyBranch':
                    let companyBranchID = parseInt($('#cmb_companyBranchId').val());
                    if ($('#cmb_companyBranchId').val() === '0') {
                        const newTab = window.open('/Masters/Company', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (!isNaN(companyBranchID)) {
                        const selectedBranchObj = companyBranchesOpts.find((branch) => branch.company_branch_id === companyBranchID);
                        if (selectedBranchObj) {
                            setCompanyBranchStateId(selectedBranchObj.branch_state_id);
                            resetGlobalQuery();
                            globalQuery.columns = ['field_id', 'field_name'];
                            globalQuery.table = "cmv_city";
                            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                            globalQuery.conditions.push({ field: "state_id", operator: "=", value: selectedBranchObj.branch_state_id });
                            const cityApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                            setCompanyBrancheCityOpts(cityApiCall);
                            setCompanyBranchCityId(selectedBranchObj.branch_city_id)
                        }
                        updateTblCalculationStateChange();
                    }
                    break;

                case 'CompanyBranchState':
                    let selectedBranchState = parseInt($('#cmb_companyBranchStateId').val());
                    if (selectedBranchState !== "") {
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name'];
                        globalQuery.table = "cmv_city";
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(selectedBranchState) });
                        const getCompanyCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        setCompanyBrancheCityOpts(getCompanyCitiesApiCall);
                        if (getCompanyCitiesApiCall.length <= 0) { setCompanyBranchCityId(''); }
                        updateTblCalculationStateChange();
                    } else {
                        setCompanyBrancheCityOpts([]);
                    }
                    break;

                case 'CompanyBranchCity':
                    if ($('#cmb_companyBranchCityId').val() === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Company Branch City')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200);
                        setCompanyBranchCityId('');
                    }
                    break;

                case 'Customer':
                    let selectedCustomer = parseInt($('#cmb_customer_id').val());
                    if ($('#cmb_customer_id').val() === '0') {
                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) { newTab.focus(); }

                    } else if (selectedCustomer !== "0" && !isNaN(selectedCustomer)) {
                        resetGlobalQuery();
                        globalQuery.columns = ['cust_branch_state_id', 'state_name', 'cust_branch_city_id', 'city_name'];
                        globalQuery.table = "cmv_customer";
                        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                        const getCustomerDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        if (getCustomerDetailsApiCall.length > 0) {
                            setCustomerState(getCustomerDetailsApiCall[0].cust_branch_state_id);
                            await FnComboOnChange('CustomerState');
                            setCustomerCity(getCustomerDetailsApiCall[0].cust_branch_city_id);
                        } else {
                            setCustomerState('');
                            setCustomerCity('');
                            setCustomerCityOpts([]);
                        }
                        await FnGetCustomersContactPersonsDetails(parseInt(selectedCustomer));

                        // Get the Quotations list.
                        if (rb_sales_order_creation_type === 'Q') {
                            await FnGetCustomerApprovedQuotations();
                        }

                        setCustomerOrderNo('')
                        setCustomerOrdDate('')
                    } else {
                        setCustomerOrderNo('')
                        setCustomerOrdDate('')
                        setCustomerState('');
                        setCustomerCity('');
                        setCustomerCityOpts([]);
                        setQuotationOpts([]);
                        setCustContactDetails([]);
                    }
                    break;

                case 'CustomerState':
                    let customerState = parseInt($('#cmb_customer_state_id').val());
                    if ($('#cmb_customer_state_id').val() === '0') {

                    } else if (customerState != null && !isNaN(customerState)) {
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name'];
                        globalQuery.table = "cmv_city"
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(customerState) });
                        const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        setCustomerCityOpts(getCustomerCitiesApiCall);
                        if (getCustomerCitiesApiCall.length <= 0) { setCustomerCity(''); }
                        updateTblCalculationStateChange();

                    } else {
                        setCustomerCity('');
                        setCustomerCityOpts([]);
                    }
                    break;

                case 'CustomerCity':
                    if ($('#cmb_customer_city_id').val() === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Customer City')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200)
                        setCustomerCity('');
                    }
                    break;

                case 'CustomerOrderNo':
                    // Validation For DuplicateCustomer order number.
                    if (cmb_customer_id !== '' && txt_customer_order_no !== '') {
                        resetGlobalQuery();
                        globalQuery.columns = ['customer_order_no'];
                        globalQuery.table = "mt_sales_order_master_trading";
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: cmb_customer_id, });
                        globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: txt_customer_order_no.trim(), });
                        globalQuery.conditions.push({ field: "sales_order_master_transaction_id", operator: "!=", value: sales_order_master_transaction_id, });
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                        const checkCustomerOrderNoIsAlreadyExist = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                        if (checkCustomerOrderNoIsAlreadyExist.length !== 0) {
                            $('#error_txt_customer_order_no').text('This customer order no is already exist...!');
                            $('#error_txt_customer_order_no').show();
                        } else {
                            $('#error_txt_customer_order_no').hide();
                        }
                    }
                    break;

                case 'Consignee':

                    let selectedConsigneeId = consigneeComboRef.current.value
                    if (selectedConsigneeId === "0") {
                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (selectedConsigneeId !== "" && !isNaN(selectedConsigneeId)) {
                        resetGlobalQuery();
                        globalQuery.columns.push("cust_branch_state_id");
                        globalQuery.columns.push("cust_branch_city_id");
                        globalQuery.table = "cmv_customer"
                        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedConsigneeId });
                        const getConsineeDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        if (getConsineeDetailsApiCall.length > 0) {
                            setConsigneeState(getConsineeDetailsApiCall[0].cust_branch_state_id);
                            FnComboOnChange('ConsigneeState');
                            setConsigneeCity(getConsineeDetailsApiCall[0].cust_branch_city_id === 0 ? "" : getConsineeDetailsApiCall[0].cust_branch_city_id);
                        } else {
                            setConsigneeState('');
                            setConsigneeCity('');
                            setConsigneeCityOpts([]);
                        }
                    } else {
                        setConsigneeState('');
                        setConsigneeCity('');
                        setConsigneeCityOpts([]);
                    }
                    break;

                case 'ConsigneeState':
                    let consigneeState = parseInt($('#cmb_consignee_state_id').val());
                    if (consigneeState != null && !isNaN(consigneeState)) {
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name'];
                        globalQuery.table = "cmv_city"
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(consigneeState) });
                        const getConsigneeCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        setConsigneeCityOpts(getConsigneeCitiesApiCall);
                        if (getConsigneeCitiesApiCall.length <= 0) { setConsigneeCity(''); }
                    } else {
                        setConsigneeCityOpts([]);
                        setConsigneeCity('');
                    }
                    break;

                case 'ConsigneeCity':
                    if ($('#cmb_consignee_city_id').val() === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Consignee City')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200);
                        setConsigneeCity('');
                    }
                    break;

                case 'Department':

                    // let departmentIdVal = parseInt($('#cmb_department_id').val());
                    let departmentIdVal = departmentComboRef.current.value;
                    setDeptId(departmentIdVal)
                    if (departmentIdVal === "0") {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Department')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200);
                        setDeptId('');
                    } else if (departmentIdVal !== "" && !isNaN(departmentIdVal) !== "0") {
                        $('#error_cmb_department').hide();
                        if (departmentIdVal !== '') {
                            resetGlobalQuery();
                            globalQuery.columns.push("field_id");
                            globalQuery.columns.push("field_name");
                            globalQuery.table = "cmv_department";
                            globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                            globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
                            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID], });
                            globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
                            const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                            const subdepartmentList = [
                                { value: '', label: 'Select', field_id: '', field_name: '' },
                                { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                                ...subDepartmentApiCall.map((subdepartment) => ({ ...subdepartment, value: subdepartment.field_id, label: subdepartment.field_name, })),
                            ];
                            setSubDepartmentOptions(subdepartmentList)
                            if(requestfor==="GF"){
                                setSubDepartmentId(62)
                            }else{
                                setSubDepartmentId('')
                            }
                        } else {
                            setSubDepartmentOptions([])
                            setSubDepartmentId('')
                        }
                    } else {
                        setApproveByOpts([])
                    }
                    break;
                case 'subDepartment':
                    var subdepartmentIdVal = subDepartmentComboRef.current.value;
                    setSubDepartmentId(subdepartmentIdVal)
                    if (subdepartmentIdVal === "0") {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Sub Department')
                        setShowAddRecModal(true)
                        setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                    }

                    break;

                case 'Quotation':
                    if ($('#cmb_sales_quotation_no').val() != null && $('#cmb_sales_quotation_no').val() != '') {
                        let selectedQuotation = $('#cmb_sales_quotation_no').find('option:selected');
                        let quotationDate = selectedQuotation.attr('quotation_date');
                        setSalesQuotationDate(quotationDate);
                        await FnGetPaymentTerms();
                        // Now get the releated quotation data.
                        await FnGetOrderDetailsByQuotation();

                    } else {
                        setSalesQuotationNo('')
                        setSalesQuotationDate('')
                        setSalesOrdDetailsData([]);
                        calculateGrandTotal([]);
                        setScheduleDataArray([]);
                        setSalesOrdDetailsData([]);
                        taxationSummaryData = [];
                        localStorage.removeItem('taxationSummary');
                        setTaxationSummaryData([]);
                        FnCalculateDiscSum([]);
                    }
                    break;

                case 'Agent':
                    let selectedAgent = agentComboRef.current.value;
                    if (agentComboRef.current.value === "0") {
                        const newTab = window.open('/Masters/Agent', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (selectedAgent !== '0' && selectedAgent !== '') {
                        const selectedAgentData = agentOpts.find(agent => parseInt(agent.agent_id) === parseInt(selectedAgent));
                        if (selectedAgentData) {
                            setAgentPercent(selectedAgentData.agent_std_percent);
                        } else {
                            setAgentPercent(0);
                        }
                    } else {
                        setAgentPercent(0);
                    }
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log("Error: " + error)
            navigate('/Error')
        }
    }


    // For update the combobox data after add new record.
    const FnUpdateComboBoxData = async (key) => {
        if (keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') {
            return false;
        }
        switch (key) {
            case 'UpdateCustomers':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'is_sez', 'cust_branch_type'];
                globalQuery.table = "cmv_customer_summary"
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedCustomerData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setCustomerOpts(getUpdatedCustomerData);
                break;

            case 'UpdateConsignees':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'is_sez', 'cust_branch_type'];
                globalQuery.table = "cmv_customer_summary"
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });/
                // globalQuery.conditions.push({ field: "cust_branch_type", operator: "=", value: 'Consignee' });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedConsigneesData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                const customerComboList = [
                    { value: "", label: "Select" },
                    { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                    ...getUpdatedConsigneesData.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                ];
                setConsigneeOpts(customerComboList);
                break;

            case 'UpdateAgents':
                resetGlobalQuery();
                globalQuery.columns = ['agent_id', 'agent_name', 'agent_std_percent', 'agent_EmailId']
                globalQuery.table = "cm_agent"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedAgentData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)

                const agentList = [
                    { value: '', label: 'Select', field_id: '', field_name: '' },
                    { value: '0', label: 'Add New Record+', field_id: '0', field_name: '' },
                    ...getUpdatedAgentData.map((agent) => ({ ...agent, value: agent.agent_id, label: agent.agent_name })),
                ];

                setAgentOpts(agentList);
                break;
            case 'get_hsn_codes':
                //get hsn codes list
                resetGlobalQuery();
                globalQuery.columns = ["field_name", "field_id", "hsn_sac_rate"];
                globalQuery.table = "cmv_hsn_sac";
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                const getHsnCode = comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                setHsnCodesOptions(getHsnCode)

                break;

            default:
                break;
        }
    }

    // ********************************************************* Quotation Based Sales Order Functionality Starts. **********************************
    // For get the selected customer's approved quotations.
    const FnGetCustomerApprovedQuotations = async () => {
        let selectedCustomer = $('#cmb_customer_id').val();
        const orderCreationType = document.querySelector('input[name="rb_sales_order_creation_type"]:checked').value;
        if (orderCreationType === 'Q' && selectedCustomer !== '' && selectedCustomer !== '' && selectedCustomer !== null) {
            try {
                resetGlobalQuery();
                globalQuery.columns = ["quotation_no", "quotation_date", "financial_year", "quotation_version"];
                globalQuery.table = "mtv_sales_quotation_trading_summary";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(selectedCustomer) });
                globalQuery.conditions.push({ field: "quotation_status", operator: "=", value: 'A' });
                const getQuotationsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setQuotationOpts(getQuotationsApiCall);
            } catch (error) {
                console.log("Error in getting customer's approved quotations : ", error)
                navigate('/Error')
            }
        } else {
            setQuotationOpts([]);
            setSalesQuotationNo('');
            setSalesQuotationDate('');
        }
        setSalesOrdDetailsData([]);
        setScheduleDataArray([]);
        setProductTypePropertiesData([]);
        localStorage.removeItem('salesOrderMaterialSchedules');
        taxationSummaryData = [];
        localStorage.removeItem('taxationSummary');
        calculateGrandTotal([]);
    }

    // For get the customer's selected quotation.
    const FnGetOrderDetailsByQuotation = async () => {
        try {
            $('#spinner_id').show();
            $('#spinner_text').text('Loading...');
            let selectedQuotationNumber = $('#cmb_sales_quotation_no').val();
            let selectedQuotationData = quotationOpts.find(quotation => quotation.quotation_no === selectedQuotationNumber);

            if (selectedQuotationData) {
                let defaultGracedScheduleDate = FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays);
                selectedQuotationData['company_id'] = COMPANY_ID;
                selectedQuotationData['quotation_item_status'] = "A"
                //  // 1. Get the data from mtv_quotation_details using api.
                const formData = new FormData();
                formData.append(`QuotationInformation`, JSON.stringify(selectedQuotationData))
                const requestOptions = { method: 'POST', body: formData };
                const getOrderDetailsByQuotationApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtQuotationDetails/FnGetQuotationDetailsByItemStatus/${COMPANY_ID}`, requestOptions);
                const responce = await getOrderDetailsByQuotationApiCall.json();

                if (responce.hasOwnProperty('quotationDetailsData')) {
                    let quotationDetails = responce.quotationDetailsData;

                    //  // 2. Remove first old order details.
                    setScheduleDataArray([]);
                    setSalesOrdDetailsTrackingData([]);
                    localStorage.removeItem('salesOrderMaterialSchedules');
                    taxationSummaryData = [];
                    localStorage.removeItem('taxationSummary');


                    //  // 3. Update the details keys as per the mannual order details.
                    let updatedData = await Promise.all(quotationDetails?.map(async (quotationOrderDetail, index) => {
                        const {
                            product_material_name: product_name,
                            product_material_tech_spect: product_tech_spect,
                            product_material_unit_name: product_unit_name,
                            product_packing_name: product_packing_name,
                            product_material_hsn_sac_code: product_hsn_sac_code,
                            hsn_sac_percent: product_hsn_sac_rate,
                            product_material_std_weight: product_std_weight,
                            product_material_quotation_quantity: material_quantity,
                            product_material_quotation_weight: material_weight,
                            material_std_rate: quotation_rate,
                            material_std_rate: material_rate,
                            item_discount_percent: material_discount_percent,
                            item_discount_cost: material_discount_amount,
                            material_expected_schedule_date: material_schedule_date,
                            product_material_id: product_id,
                            product_material_unit_id: product_unit_id,
                            product_material_packing_id: product_packing_id,
                            hsn_sac_id: product_hsn_sac_code_id,
                            // product_type_short_name: product_type_short_name,
                            product_type_name: product_type,
                            ...rest
                        } = quotationOrderDetail;

                        let keysUpdatedOrderDetail = {
                            product_name, product_tech_spect, product_unit_name, product_packing_name, product_hsn_sac_rate,
                            product_hsn_sac_code, product_std_weight, material_quantity,
                            material_weight, quotation_rate, material_rate, material_discount_percent, material_discount_amount, material_schedule_date,
                            product_id, product_unit_id, product_packing_id, product_hsn_sac_code_id, product_type,
                            // product_type_short_name, 
                            ...rest
                        };

                        keysUpdatedOrderDetail.sr_no = index + 1;
                        keysUpdatedOrderDetail.so_sr_no = 1;
                        keysUpdatedOrderDetail.material_freight_amount = 0;
                        keysUpdatedOrderDetail.sales_order_item_status = "P";
                        keysUpdatedOrderDetail.remark = '';
                        // Extra Fields For the Fabric Sales Order;
                        keysUpdatedOrderDetail.product_recommendation = 'Normal';
                        keysUpdatedOrderDetail.special_remark = '';
                        keysUpdatedOrderDetail.monogram_is_applicable = 'false';
                        // keysUpdatedOrderDetail.weft_color = defaultColour;

                        // Check the material schedule-date
                        let matScheduleDateByQuotation = new Date(keysUpdatedOrderDetail.material_schedule_date);
                        let currentDate = new Date();
                        if (matScheduleDateByQuotation < currentDate || isNaN(matScheduleDateByQuotation)) {
                            // set the over_all_schedule_date.
                            keysUpdatedOrderDetail.material_schedule_date = dt_overall_schedule_date;

                            // if dt_overall_schedule_date is also before today then set grace date.
                            matScheduleDateByQuotation = new Date(keysUpdatedOrderDetail.material_schedule_date);
                            if (matScheduleDateByQuotation < currentDate || isNaN(matScheduleDateByQuotation)) {
                                keysUpdatedOrderDetail.material_schedule_date = defaultGracedScheduleDate;
                            }
                        }

                        // keysUpdatedOrderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber((keysUpdatedOrderDetail.material_rate * parseFloat(keysUpdatedOrderDetail.material_quantity)).toString(), 4);
                        if (keysUpdatedOrderDetail.product_unit_name === "Kilo Gram") {
                            keysUpdatedOrderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber((keysUpdatedOrderDetail.material_weight * parseFloat(keysUpdatedOrderDetail.material_rate)).toString(), 4);
                        } else {
                            keysUpdatedOrderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber((keysUpdatedOrderDetail.material_rate * parseFloat(keysUpdatedOrderDetail.material_quantity)).toString(), 4);

                        }

                        FnCalculateMaterialWiseDiscount(keysUpdatedOrderDetail);

                        keysUpdatedOrderDetail = FnMaterialWiseCalculations(keysUpdatedOrderDetail);      // Send For calculations.
                        return keysUpdatedOrderDetail;
                    }));
                    setSalesOrdDetailsData(updatedData);
                    calculateGrandTotal(updatedData);
                    FnCalculateDiscSum(updatedData);
                    let updatedArrayIds = updatedData.map((detailsItem) => detailsItem.product_id);
                    if (updatedArrayIds.length !== 0) {
                        const formattedIds = updatedArrayIds.map((id, index) => `${id}`);
                        resetGlobalQuery();
                        globalQuery.columns.push("*");
                        globalQuery.table = "smv_product_dynamic_parameters"
                        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "product_id", operator: "IN", values: formattedIds });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        const getProductProperties = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                        // await fnGetProductProperties(getProductProperties)
                        // comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                        //     .then((getProductProperties) => {
                        //         fnGetProductProperties(getProductProperties);
                        //     })
                    }

                    // Now set the payment terms as per quotation.
                    if (responce.hasOwnProperty('quotationPaymentTermsData')) {
                        // let renderedPmtTermsData = JSON.parse(localStorage.getItem('SalesOrdPmtTermsData'));
                        let renderedPmtTermsData = [...PmtTermsdata];
                        const existingQuotationPaymentTermsData = responce.quotationPaymentTermsData;
                        for (let existPmtCounter = 0; existPmtCounter < renderedPmtTermsData.length; existPmtCounter++) {
                            const renderedPmtTerm = renderedPmtTermsData[existPmtCounter];

                            // Set default data.
                            renderedPmtTerm.payment_terms_Milestome = '';
                            renderedPmtTerm.payment_percent = '';
                            renderedPmtTerm.payment_expected_value = '';
                            renderedPmtTerm.payment_expected_date = '';
                            renderedPmtTerm.payment_receipt_flag = 'P';
                            renderedPmtTerm.payment_receipt_transaction_id = '';
                            renderedPmtTerm.payment_receipt_date = '';
                            renderedPmtTerm.remark = '';

                            existingQuotationPaymentTermsData?.map(quotationBasedPmtTerm => {
                                if (renderedPmtTerm.payment_terms_id === quotationBasedPmtTerm.payment_terms_id) {
                                    // set data according to quotation payment terms.
                                    renderedPmtTerm.payment_terms_Milestome = quotationBasedPmtTerm.payment_terms_Milestome;
                                    renderedPmtTerm.payment_percent = quotationBasedPmtTerm.payment_percent;
                                    renderedPmtTerm.remark = quotationBasedPmtTerm.remark;
                                }
                            });
                            // update it in table data array.
                            renderedPmtTermsData[existPmtCounter] = renderedPmtTerm;
                        }
                        // localStorage.setItem('SalesOrdPmtTermsData', JSON.stringify(renderedPmtTermsData));
                        setPmtTermsData(renderedPmtTermsData);
                        // First un-check all checkbox.
                        $('.selectPmtTerm').prop('checked', false);

                        // mark the checked checkboxes for order payment term.
                        existingQuotationPaymentTermsData.forEach(function (existingPmtTerm) {
                            $('#selectPmtTerm_' + existingPmtTerm.payment_terms_id).prop('checked', true);
                        });
                    }
                }

            } else {
                // Clear the Details Section data.
                setSalesOrdDetailsData([]);
                calculateGrandTotal([]);
                setScheduleDataArray([]);
                localStorage.removeItem('salesOrderMaterialSchedules');
                taxationSummaryData = [];
                localStorage.removeItem('taxationSummary');
                // setQuotationOpts([]);
                setSalesQuotationNo('');
                setSalesQuotationDate('');
            }
        } catch (error) {
            console.log("Error in getting order details from quotation : ", error)
            navigate('/Error')
        } finally {
            $('#spinner_id').hide();
        }
    }
    // ********************************************************* Quotation Based Sales Order Functionality Starts. **********************************


    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        try {
            if (customerId !== null && customerId !== undefined && customerId !== '' && customerId !== NaN) {
                resetGlobalQuery();
                globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id", "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId"]
                globalQuery.table = "cmv_customer_contacts"
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustContactDetails(getContactDtlsApiCall);
                $('.selectCustContactPerson').prop('checked', false);
                $('#selectAllCustContact').prop('checked', false);
                return getContactDtlsApiCall;
            } else {
                $('.selectCustContactPerson').prop('checked', false);
                $('#selectAllCustContact').prop('checked', false);
                setCustContactDetails([]);
                return [];
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
        // Onchange event listener for the customer contact Selection
        $('.selectCustContactPerson').on('change', function () {
            checkBoxesSelection('PartiallyCustContactSelection');
        });
    }

    const FnGetCustomerContactPersonIds = () => {
        let customerContactPersonsIds = '';
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            customerContactPersonsIds += $(this).val() + ":";
        });
        return customerContactPersonsIds.replace(/:$/, '');
    }

    const FnCloseFilterModal = async () => {
        debugger
        $('#spinner_id').show();
        $('#spinner_text').text('Loading...');

        setShowBomFilterForm(false)
        let NewlyAddedDetailsData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];;
        let srNo = salesOrdDetailsData.length + 1;

        if (NewlyAddedDetailsData != null && NewlyAddedDetailsData != undefined) {
            sessionStorage.removeItem('filteredMaterialData');

            for (let newMaterialIndex = 0; newMaterialIndex < NewlyAddedDetailsData.length; newMaterialIndex++) {
                let newMaterial = NewlyAddedDetailsData[newMaterialIndex];
                const similarMaterials = salesOrdDetailsData.filter((existingMaterials) => existingMaterials.product_id === newMaterial.product_id);

                if (similarMaterials.length > 0) {
                    const existingSoSrNo = similarMaterials.map((material) => material.so_sr_no);
                    const maxSoSrNo = Math.max(...existingSoSrNo);
                    newMaterial.so_sr_no = maxSoSrNo + 1;
                } else {
                    newMaterial.so_sr_no = 1;
                }

                newMaterial.material_quantity = 1;
                // newMaterial.product_type = newMaterial.product_type_short_name
                newMaterial.product_type = newMaterial.product_type_name

                newMaterial.material_weight = validateNumberDateInput.current.decimalNumber((newMaterial.product_std_weight));
                newMaterial.material_rate = 0;
                newMaterial.quotation_rate = 0;
                newMaterial.material_discount_percent = 0;
                newMaterial.material_discount_amount = 0;
                newMaterial.material_basic_amount = 0;
                newMaterial.material_taxable_amount = 0;
                newMaterial.material_cgst_percent = 0;
                newMaterial.material_cgst_total = 0;
                newMaterial.material_sgst_percent = 0;
                newMaterial.material_sgst_total = 0;
                newMaterial.material_igst_percent = 0;
                newMaterial.material_igst_total = 0;
                newMaterial.material_freight_amount = 0;
                newMaterial.material_total_amount = 0;
                newMaterial.remark = '';
                newMaterial.sr_no = srNo;
                newMaterial.material_schedule_date = dt_overall_schedule_date;
                newMaterial.sales_order_item_status = 'P';

                // Extra Fields For the Fabric Sales Order;
                newMaterial.product_recommendation = 'Normal';
                newMaterial.special_remark = '';
                newMaterial.monogram_is_applicable = 'false';
                // newMaterial.weft_color = defaultColour;
                newMaterial.material_style = 'None';
                newMaterial.material_style_value = '';
                newMaterial.material_style_abbrevation = '';
                newMaterial.warp_crimp = 0;
                newMaterial.weft_crimp = 0;
                newMaterial.warp_waste = 0;
                newMaterial.weft_waste = 0;
                

                FnMaterialWiseCalculations(newMaterial);
                NewlyAddedDetailsData[newMaterialIndex] = newMaterial;
                srNo++;
            }
            if (requestfor !== "GF") {
                setSalesOrdDetailsData([...salesOrdDetailsData, ...NewlyAddedDetailsData]);
            }
            calculateGrandTotal([...salesOrdDetailsData, ...NewlyAddedDetailsData]);
            sessionStorage.setItem('isComboFilterExist', false)
            removeSessions();
            const updatedArray = [...salesOrdDetailsData, ...NewlyAddedDetailsData];
            let updatedArrayIds = updatedArray.map((detailsItem) => detailsItem.product_id);


            if (updatedArrayIds.length !== 0 && requestfor === "GF") {
                const formattedIds = updatedArrayIds.map((id) => `${id}`);
                resetGlobalQuery();

                globalQuery.columns.push("*");
                globalQuery.table = "smv_product_dynamic_parameters";
                globalQuery.conditions.push({ field: "product_id", operator: "IN", values: formattedIds });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                const getProductProperties = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                // await fnGetProductProperties(getProductProperties);

                const ProductRMId = [];
                if (getProductProperties && Array.isArray(getProductProperties)) {
                    // Map product parameters by product_id for quick lookup
                    const productParametersMap = getProductProperties.reduce((acc, property) => {
                        if (!acc[property.product_id]) {
                            acc[property.product_id] = {
                                product_material_code: property.product_material_code || '' // Add product_material_code here
                            };
                        }
                        acc[property.product_id][property.field_name.trim()] = property.product_parameter_value || '';
                        return acc;
                    }, {});

                    // List of fields to extract
                    const parameterFields = [
                        "WARP COUNT 1 [WC1]",
                        "WARP COUNT 2 [WC2]",
                        "WEFT COUNT 1 [FC1]",
                        "WEFT COUNT 2 [FC2]",
                        "WARP COUNT 3 [WC3]",
                        "WARP COUNT 4 [WC4]",
                        "WEFT COUNT 3 [FC3]",
                        "WEFT COUNT 4 [FC4]",
                        "EPI 1",
                        "PPI 1",
                        "WIDTH",
                    ];

                    const specificFields = [
                        "WARP COUNT 1 [WC1]",
                        "WARP COUNT 2 [WC2]",
                        "WEFT COUNT 1 [FC1]",
                        "WEFT COUNT 2 [FC2]",
                        "WARP COUNT 3 [WC3]",
                        "WARP COUNT 4 [WC4]",
                        "WEFT COUNT 3 [FC3]",
                        "WEFT COUNT 4 [FC4]",
                    ];

                    // Update NewlyAddedDetailsData with dynamic fields
                    NewlyAddedDetailsData.forEach((material) => {
                        const productProps = productParametersMap[material.product_id];
                        if (productProps) {
                            material.product_material_code = productProps.product_material_code || '';
                            parameterFields.forEach((field) => {
                                const key = field.toLowerCase().replace(/[^a-z0-9]/gi, '_'); // Normalize field name
                                const value = productProps[field] || ''; // Get the value or default to empty string
                                material[key] = value;
                                // Only push the values for specific fields to ProductRMId
                                if (specificFields.includes(field) && value) {
                                    ProductRMId.push(value);
                                }
                            });
                        }
                    });

                    // Prepare query for actual count
                    resetGlobalQuery();
                    globalQuery.columns.push("actual_count", "product_rm_id");
                    globalQuery.table = "sm_product_rm";
                    globalQuery.conditions.push({ field: "product_rm_id", operator: "IN", values: ProductRMId });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                    // Fetch actual count and product_rm_id
                    const productRmActualCount = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);

                    // Map actual counts by product_rm_id
                    const actualCountMap = productRmActualCount.reduce((acc, item) => {
                        if (item.product_rm_id && item.actual_count !== undefined) {
                            // Store actual_count for each product_rm_id
                            acc[item.product_rm_id] = item.actual_count;
                        }
                        return acc;
                    }, {});

                    // Ensure ProductRMId contains duplicates and fetch actual counts for each entry
                    ProductRMId.forEach((productRmId) => {
                        // If there's no actual count in the map, set it to null
                        if (actualCountMap[productRmId] === undefined) {
                            actualCountMap[productRmId] = null;
                        }
                    });

                    // Fields to update with actual count
                    const actualCountFields = [
                        "WARP COUNT 1 [WC1]",
                        "WARP COUNT 2 [WC2]",
                        "WEFT COUNT 1 [FC1]",
                        "WEFT COUNT 2 [FC2]",
                        "WARP COUNT 3 [WC3]",
                        "WARP COUNT 4 [WC4]",
                        "WEFT COUNT 3 [FC3]",
                        "WEFT COUNT 4 [FC4]",
                    ];

                    // Loop through NewlyAddedDetailsData and update with actual counts
                    NewlyAddedDetailsData.forEach((material) => {
                        const productProps = productParametersMap[material.product_id];
                        if (productProps) {
                            actualCountFields.forEach((field) => {

                                const productRmId = productProps[field] || ''; // Get the product_rm_id for the field

                                // Check if the product_rm_id exists in the map and update actual count
                                if (productRmId && actualCountMap[productRmId] !== undefined) {
                                    material[`${field.toLowerCase().replace(/[^a-z0-9]/gi, '_')}_actual_count`] = actualCountMap[productRmId];
                                }
                            });
                        }
                    });

                    // Step 4: Update sales order details with the modified NewlyAddedDetailsData
                    setSalesOrdDetailsData([...salesOrdDetailsData, ...NewlyAddedDetailsData]);
                }
            }


        }

        if (salesOrdDetailsData.length > 0) { $('#cmb_sales_order_type_id').attr('disabled', 'disabled'); }
        $('#spinner_id').hide();
    };



    const updateSalesOrdDetailsTblData = async (currentRowData, event) => {
        debugger
        let rowIndex = event.target.parentElement.parentElement.getAttribute('rowIndex');
        let clickedColName = event.target.getAttribute('Headers');
        const excludedColumnsInCalculation = ['product_unit_id', 'remark', 'material_schedule_date', 'sales_order_item_status', 'product_recommendation', 'special_remark', 'monogram_is_applicable', 'weft_color', 'material_style', 'material_style_value', 'material_style_abbrevation'];

        let updateCalculations = false;
        let inputValue = event.target.value;

        if (!excludedColumnsInCalculation.includes(clickedColName)) {
            inputValue = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0;
        }

        // Remove error msgs on input change.
        if (event._reactName === 'onChange') { delete event.target.parentElement.dataset.tip; }

        switch (clickedColName) {
            case 'material_quantity':
                let material_weight = parseFloat(inputValue) * parseFloat(currentRowData.product_std_weight);
                currentRowData.material_quantity = inputValue
                currentRowData.material_weight = validateNumberDateInput.current.decimalNumber(material_weight.toString(), 4);
                if (currentRowData.product_unit_name === "Kilo Gram") {
                    currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((currentRowData.material_weight * parseFloat(currentRowData.material_rate)).toString(), 4);
                } else {
                    currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.material_rate)).toString(), 4);

                }

                FnCalculateMaterialWiseDiscount(currentRowData);
                updateCalculations = true;
                calculateWarpReqPerKg();
                calculateWeftReqPerKg();
                currentRowData['material_weight'] = validateNumberDateInput.current.decimalNumber(FncalculateSaudaData(currentRowData, event), 4);
                break;

            case 'material_rate':
                currentRowData.material_rate = inputValue;
                if (rb_sales_order_creation_type !== 'Q') {
                    currentRowData.quotation_rate = inputValue;
                }
                // currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.material_quantity)).toString(), 4);
                if (currentRowData.product_unit_name === "Kilo Gram") {
                    currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((currentRowData.material_weight * parseFloat(currentRowData.material_rate)).toString(), 4);
                } else {
                    currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.material_quantity)).toString(), 4);

                }

                FnCalculateMaterialWiseDiscount(currentRowData);
                updateCalculations = true;
                break;

            case 'material_discount_percent':
                let isValidPercentageInput = validatePercentageInput(inputValue);
                if (isValidPercentageInput === true) {
                    const calculatedDiscountAmount = (inputValue / 100) * currentRowData.material_basic_amount;
                    // Then set the discount amount and discount percentage.
                    currentRowData.material_discount_percent = inputValue;
                    currentRowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedDiscountAmount), 4);
                    delete event.target.parentElement.dataset.tip;
                } else {
                    currentRowData[clickedColName] = currentRowData.material_discount_percent;
                    event.target.parentElement.dataset.tip = isValidPercentageInput;
                }
                updateCalculations = true;
                break;

            case 'material_discount_amount':
                const calculatedPercent = (inputValue * 100) / currentRowData.material_basic_amount;
                // Then set the discount amount and discount percentage.
                currentRowData.material_discount_amount = inputValue;
                currentRowData.material_discount_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 4);
                updateCalculations = true;
                break;

            // Non Calculated Fields.
            case 'sales_order_item_status':
                currentRowData[clickedColName] = inputValue;

                if (inputValue === 'Z') {
                    currentRowData.pree_closed_quantity = currentRowData.material_quantity;
                    currentRowData.pree_closed_weight = currentRowData.material_weight;
                } else {
                    currentRowData.pree_closed_quantity = 0;
                    currentRowData.pree_closed_weight = 0;
                }

                // Then update the schedules as per it.
                let materialScheduleTblRows = JSON.parse(localStorage.getItem('salesOrderMaterialSchedules')) === null ? [] : JSON.parse(localStorage.getItem('salesOrderMaterialSchedules'));
                // Find and update matching schedules
                let material_status_desc = event.target.options[event.target.selectedIndex].getAttribute('lbl');
                const updatedSchedules = materialScheduleTblRows.map(item => {
                    if (parseInt(item.so_sr_no) === parseInt(currentRowData.so_sr_no) && item.product_material_id === currentRowData.product_id) {
                        return {
                            ...item,
                            sales_order_schedules_trading_item_status: inputValue,
                            sales_order_schedules_trading_item_status_desc: material_status_desc,
                        };
                    }
                    return item;
                });
                setScheduleDataArray(updatedSchedules);
                localStorage.setItem('salesOrderMaterialSchedules', JSON.stringify(updatedSchedules));
                break;

            case 'material_weight':
                currentRowData.material_weight = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0;
                if (currentRowData.product_unit_name === "Kilo Gram") {
                    currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((currentRowData.material_weight * parseFloat(currentRowData.material_rate)).toString(), 4);
                } else {
                    currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((parseFloat(currentRowData.material_rate) * parseFloat(currentRowData.material_quantity)).toString(), 4);

                }
                FnCalculateMaterialWiseDiscount(currentRowData);
                updateCalculations = true;
                break;

            case 'product_unit_id':
                currentRowData[clickedColName] = inputValue;
                currentRowData['product_unit_name'] = event.target.selectedOptions[0].getAttribute('lbl');
                break;

            case 'material_schedule_date':
            case 'remark':
                currentRowData[clickedColName] = inputValue;
                break;

            // Extra fields for the fabric Sales Order;
            case 'product_recommendation':
                delete event.target.parentElement.dataset.tip;
                currentRowData[clickedColName] = inputValue;
                if (currentRowData?.product_recommendation === 'Normal') {
                    let specialRmkInputField = $(`#special_remark_${currentRowData.product_id}`)
                    delete specialRmkInputField[0].parentElement.dataset.tip;
                }
                break;
            case 'special_remark':
                delete event.target.parentElement.dataset.tip;
                currentRowData[clickedColName] = inputValue;
                break;
            case 'monogram_is_applicable':
                delete event.target.parentElement.dataset.tip;
                currentRowData[clickedColName] = inputValue === 'true' ? true : false;
                break;

            case 'weft_color':
                delete event.target.parentElement.dataset.tip;
                if (inputValue === '0') { // Means add new colour in properties.
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Weft Colour')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({ "padding-top": "0px" });
                    }, 200);
                } else {
                    currentRowData[clickedColName] = inputValue;
                }
                break;

            case 'material_style':
                currentRowData[clickedColName] = inputValue;
                if (inputValue === 'None') {
                    let currentRowHTMLElem = $('#salesOrdMaterialDtlsTbl tbody tr').eq(rowIndex);
                    let materialStyleElem = currentRowHTMLElem.find('input[id^="material_style_value_"]');
                    delete materialStyleElem[0].parentElement.dataset.tip;
                    let materialStyleAbbreviationElem = currentRowHTMLElem.find('input[id^="material_style_abbrevation_"]');
                    delete materialStyleAbbreviationElem[0].parentElement.dataset.tip;
                }


                break;
            case 'material_style_value':
                currentRowData[clickedColName] = inputValue;
                // if (currentRowData.material_style_abbrevation === '') {
                //     eventId_StyleAbbreviation.parentElement.dataset.tip = 'Please Enter Style Abbreviation..!';
                // }
                break;
            case 'material_style_abbrevation':
                currentRowData[clickedColName] = inputValue;
                // if (currentRowData.material_style_abbrevation !== '') {
                //     delete event.target.parentElement.dataset.tip;
                // }
                break;

            case 'product_hsn_sac_code_id':

                currentRowData[clickedColName] = event.target.value;
                let hsnCodeValue = event.target.value;
                let selectedOption = event.target.options[event.target.selectedIndex];
                let product_hsn_sac_rate = selectedOption.getAttribute('product_hsn_sac_rate');
                let product_hsn_sac_code = selectedOption.getAttribute('product_hsn_sac_code');
                if (hsnCodeValue === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('HSN Code'); // CommonParamterEntry
                    setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (hsnCodeValue !== '') {
                    currentRowData[clickedColName] = hsnCodeValue;
                    currentRowData['product_hsn_sac_rate'] = product_hsn_sac_rate;
                    currentRowData['product_hsn_sac_code'] = product_hsn_sac_code;
                    currentRowData['product_hsn_sac_code_id'] = hsnCodeValue;
                    delete event.target.parentElement.dataset.tip;
                } else {
                    currentRowData[clickedColName] = '';
                    currentRowData['product_hsn_sac_rate'] = '';
                    event.target.parentElement.dataset.tip = 'Please Select Atleast One...!';
                }
                FnCalculateMaterialWiseDiscount(currentRowData);
                updateCalculations = true;
                break;

            case 'warp_crimp':
                debugger
                let warp_crimp = validateNumberDateInput.current.percentValidate(JSON.stringify(parseFloat(event.target.value)));
                if (warp_crimp === true) {
                    let entValue = event.target.value.replace(/^0+/, '');
                    currentRowData[clickedColName] = entValue
                    delete event.target.parentElement.dataset.tip;
                    calculateWarpReqPerKg();
                    calculateWeftReqPerKg();

                    currentRowData['material_weight'] = validateNumberDateInput.current.decimalNumber(FncalculateSaudaData(currentRowData, event), 4);
                } else {
                    currentRowData[clickedColName] = '';
                }
                break;
            case 'weft_crimp':
                let weft_crimp = validateNumberDateInput.current.percentValidate(JSON.stringify(parseFloat(event.target.value)));
                if (weft_crimp === true) {
                    let entValue = event.target.value.replace(/^0+/, '');
                    currentRowData[clickedColName] = entValue
                    delete event.target.parentElement.dataset.tip;
                    calculateWeftReqPerKg();
                    calculateWarpReqPerKg();
                    currentRowData['material_weight'] = validateNumberDateInput.current.decimalNumber(FncalculateSaudaData(currentRowData, event), 4);
                } else {
                    currentRowData[clickedColName] = '';
                }
                break;
            case 'weft_waste':
                let weft_waste = validateNumberDateInput.current.percentValidate(JSON.stringify(parseFloat(event.target.value)));
                if (weft_waste === true) {
                    let entValue = event.target.value.replace(/^0+/, '');
                    currentRowData[clickedColName] = entValue
                    delete event.target.parentElement.dataset.tip;
                    calculateWeftReqPerKg();
                    calculateWarpReqPerKg();
                    currentRowData['material_weight'] = validateNumberDateInput.current.decimalNumber(FncalculateSaudaData(currentRowData, event), 4);
                } else {
                    currentRowData[clickedColName] = '';
                }
                break;
            case 'warp_waste':
                let warp_waste = validateNumberDateInput.current.percentValidate(JSON.stringify(parseFloat(event.target.value)));
                if (warp_waste === true) {
                    let entValue = event.target.value.replace(/^0+/, '');
                    currentRowData[clickedColName] = entValue
                    delete event.target.parentElement.dataset.tip;
                    calculateWarpReqPerKg();
                    calculateWeftReqPerKg();
                    currentRowData['material_weight'] = validateNumberDateInput.current.decimalNumber(FncalculateSaudaData(currentRowData, event), 4);
                } else {
                    currentRowData[clickedColName] = '';
                }
                break;
            default:
                break;
        }

        // Calculate the other dependent Fields on changed field.
        if ((updateCalculations || event._reactName === 'onBlur') && !excludedColumnsInCalculation.includes(clickedColName)) {
            currentRowData = FnMaterialWiseCalculations(currentRowData);
            const updatedDetailsData = [...salesOrdDetailsData]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            calculateGrandTotal(updatedDetailsData);
            setSalesOrdDetailsData(updatedDetailsData);

            // Update the footer Discount percentage and amount.
            FnCalculateDiscSum(updatedDetailsData);
        } else {
            const updatedDetailsData = [...salesOrdDetailsData]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            setSalesOrdDetailsData(updatedDetailsData);

            // Update the sales order master status.
            if (clickedColName === 'sales_order_item_status') {
                FnUpdateSalesOrderStatus(updatedDetailsData, scheduleDataArray, 'SalesOrderStatus');
            }
        }
        function calculateWarpReqPerKg() {
            const epi_1 = parseFloat(currentRowData["epi_1"]) || 0;
            const width = parseFloat(currentRowData["width"]) || 0;
            const warp_crimp = parseFloat(currentRowData["warp_crimp"]) || 0;
            const warp_waste = parseFloat(currentRowData["warp_waste"]) || 0;
            const warpCountKeys = Object.keys(currentRowData).filter((key) =>
                key.startsWith("warp_count_") && key.includes("__actual_count")
            );

            // Process each warp count key
            warpCountKeys.forEach((key) => {
                const warp_count = parseFloat(currentRowData[key]) || 0;

                if (epi_1 && width && warp_count && !isNaN(warp_crimp) && !isNaN(warp_waste)) {
                    // Calculate warp_req_per_kg using the formula
                    const warp_req_per_kg = ((epi_1 * width) / (1693 * warp_count)) * ((100 + warp_crimp) / 100) * ((100 + warp_waste) / 100);

                    // Extract the number part from the warp count (e.g., "warp_count_1") to construct a readable key
                    const countNumber = key.split('_')[2]; // This gets the "1" from "warp_count_1"

                    // Generate a new key for the result in the format "warp_count_X_warp_req_per_kg_X"
                    const resultKey = `warp_count_${countNumber}_warp_req_per_kg_${countNumber}`;

                    // Store the result in currentRowData under the new key
                    currentRowData[resultKey] = validateNumberDateInput.current.decimalNumber((warp_req_per_kg).toString(), 4);
                } else {
                    // If any data is invalid, store 0
                    const countNumber = key.split('_')[2]; // This gets the "1" from "warp_count_1"
                    const resultKey = `warp_count_${countNumber}_warp_req_per_kg_${countNumber}`;
                    currentRowData[resultKey] = 0;
                }
            });
        }

        function calculateWeftReqPerKg() {
            const ppi_1 = parseFloat(currentRowData["ppi_1"]) || 0;
            const width = parseFloat(currentRowData["width"]) || 0;
            const weft_crimp = parseFloat(currentRowData["weft_crimp"]) || 0;
            const weft_waste = parseFloat(currentRowData["weft_waste"]) || 0;

            // Find all weft_count keys dynamically
            const weftCountKeys = Object.keys(currentRowData).filter((key) =>
                key.startsWith("weft_count_") && key.includes("__actual_count")
            );

            // Process each weft count key
            weftCountKeys.forEach((key) => {
                const weft_count = parseFloat(currentRowData[key]) || 0;

                // Check if necessary values are valid
                if (ppi_1 && width && weft_count && !isNaN(weft_crimp) && !isNaN(weft_waste)) {
                    // Calculate weft_req_per_kg using the formula
                    const weft_req_per_kg = ((ppi_1 * width) / (1693 * weft_count)) * ((100 + weft_crimp) / 100) * ((100 + weft_waste) / 100);

                    // Extract the number part from the weft count (e.g., "weft_count_1") to construct a readable key
                    const countNumber = key.split('_')[2]; // This gets the "1" from "weft_count_1"

                    // Generate a new key for the result in the format "weft_count_X_weft_req_per_kg_X"
                    const resultKey = `weft_count_${countNumber}_weft_req_per_kg_${countNumber}`;

                    // Store the result in currentRowData under the new key
                    currentRowData[resultKey] = validateNumberDateInput.current.decimalNumber((weft_req_per_kg).toString(), 4);
                } else {
                    // If any data is invalid, store 0
                    const countNumber = key.split('_')[2]; // This gets the "1" from "weft_count_1"
                    const resultKey = `weft_count_${countNumber}_weft_req_per_kg_${countNumber}`;
                    currentRowData[resultKey] = 0;
                }
            });
        }


    }
    const FncalculateSaudaData = (saudaData, event) => {
        debugger
        // Assuming the rest of the code is as before
        const updatedDetailsData = [saudaData];
        const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'), 10);
        const mergeWarpAndWeftData = (data) => {
            debugger
            const mergedData = [];
            // Variables to store the current warp and weft data rows
            let currentWarp = {};
            let currentWeft = {};

            // Extract dynamic common values for warp and weft from the data
            const commonWarpData = {
                warp_crimp: data.warp_crimp || '0',   // Retrieve from data or default to '8'
                warp_waste: data.warp_waste || '0'    // Retrieve from data or default to '2'
            };

            const commonWeftData = {
                weft_crimp: data.weft_crimp || '0',   // Retrieve from data or default to '0'
                weft_waste: data.weft_waste || '0'    // Retrieve from data or default to '4'
            };

            // Iterate over the object keys (use Object.entries for key-value pairs)
            Object.entries(data).forEach(([key, value]) => {
                if (key.startsWith('warp_count_')) {
                    // Extract the warp count index dynamically (e.g., 1 for warp_count_1, 2 for warp_count_2, etc.)
                    const warpIndex = key.split('_')[2]; // Get the index of the warp count (1, 2, etc.)

                    // Initialize the currentWarp object if not already initialized for this count index
                    if (!currentWarp[warpIndex]) {
                        currentWarp[warpIndex] = {};
                    }

                    // Add the current key-value pair to the correct warp count object (e.g., warp_count_1, warp_count_2)
                    const baseWarpKey = key.split('__')[0]; // Get the base key (e.g., "warp_count_1")

                    // Assign the value to the appropriate warp object
                    currentWarp[warpIndex][baseWarpKey] = value;
                    // currentWarp[warpIndex]["product_id"] = value;

                    // Add dynamic common data (warp_crimp and warp_waste) to every warp count object
                    currentWarp[warpIndex].warp_crimp = commonWarpData.warp_crimp;
                    currentWarp[warpIndex].warp_waste = commonWarpData.warp_waste;
                    currentWarp[warpIndex].count_type = `warp_count_${warpIndex}`;

                } else if (key.startsWith('weft_count_')) {
                    // Handling weft related keys
                    const weftIndex = key.split('_')[2];  // e.g., "1" from weft_count_1
                    if (!currentWeft[weftIndex]) {
                        currentWeft[weftIndex] = {};
                    }

                    // Add the current key-value pair to the correct weft count object (e.g., weft_count_1)
                    const baseWeftKey = key.split('__')[0];

                    // Assign the value to the appropriate weft object
                    currentWeft[weftIndex][baseWeftKey] = value;
                    // Add dynamic common data (weft_crimp and weft_waste) to every weft count object
                    currentWeft[weftIndex].weft_crimp = commonWeftData.weft_crimp;
                    currentWeft[weftIndex].weft_waste = commonWeftData.weft_waste;
                    currentWeft[weftIndex].count_type = `weft_count_${weftIndex}`;


                }
            });

            // Now, push the warp data into the mergedData array
            Object.entries(currentWarp).forEach(([warpIndex, warpData]) => {
                warpData["product_id"] = data.product_id;
                warpData["material_quantity"] = data.material_quantity;
                warpData["product_material_code"] = data.product_material_code;
                mergedData.push(warpData); // Add the warp data (e.g., warp_count_1 data;)
            });

            // Now, push the weft data into the mergedData array
            Object.entries(currentWeft).forEach(([weftIndex, weftData]) => {
                weftData["product_id"] = data.product_id;
                weftData["material_quantity"] = data.material_quantity;
                weftData["product_material_code"] = data.product_material_code;

                mergedData.push(weftData); // Add the weft data (e.g., weft_count_1 data)
            });

            return mergedData;
        };

        // Assuming currentRowData is an object (not an array), pass it directly to the merge function
        const finalData = mergeWarpAndWeftData(saudaData);

        // Log the final merged data
        console.log("Final Merged Data:", finalData);

        // Update the specific row in the data array
        updatedDetailsData[itemArrayIndex] = finalData;
        const processedData = finalData.map((sauda) => {
            // Dynamically find the count field and its value
            const countFields = Object.keys(sauda).filter((key) =>
                key.includes("warp_count_") || key.includes("weft_count_")
            );
            const countField = countFields.find(
                (field) => sauda[field] !== undefined && sauda[field] !== null && sauda[field] !== ""
            );
            const count = countField ? sauda[countField] : "0";

            // Dynamically find the req_per_kg field and its value
            const reqPerKgFields = Object.keys(sauda).filter((key) =>
                key.includes("_warp_req_per_kg_") || key.includes("_weft_req_per_kg_")
            );
            const reqPerKgField = reqPerKgFields.find(
                (field) => sauda[field] !== undefined && sauda[field] !== null && sauda[field] !== ""
            );
            const req_warp_and_weft_per_kg = reqPerKgField ? sauda[reqPerKgField] : "0";

            // Get crimp and waste values, default to '--' if not present
            const warp_weft_crimp = sauda.warp_crimp || sauda.weft_crimp || "--";
            const warp_weft_waste = sauda.warp_waste || sauda.weft_waste || "--";

            // Retrieve the order quantity field (default to '--' if not present)
            const req_order_quantity = sauda.material_quantity * req_warp_and_weft_per_kg || "0";
            const product_id = sauda.product_id || "--";
            const count_type = sauda.count_type || "--";
            const product_material_code = sauda.product_material_code || "--";

            return {
                count,
                req_warp_and_weft_per_kg,
                warp_weft_crimp,
                warp_weft_waste,
                product_id,
                req_order_quantity,
                count_type,
                product_material_code
            };
        });
        // Set the final state or update as required
        let material_weight = processedData.reduce(
            (acc, data) => acc + parseFloat(data['req_order_quantity'] || 0),
            0
        );

        setSaudaShitSummaryData((prevData) => {
            const updatedData = [...prevData];

            processedData.forEach((newItem) => {
                // Ensure the record has a valid count value before proceeding
                const count = newItem.count;
                // const req_order_quantity = newItem.req_order_quantity;

                // Check if count is neither undefined nor 0
                // if (count && count !== "0" && req_order_quantity && req_order_quantity !== "0") {
                if (count !== undefined && count !== "0" && count !== 0) {
                    const existingIndex = updatedData.findIndex(
                        (oldItem) =>
                            oldItem.product_id === newItem.product_id &&
                            // oldItem.count === newItem.count &&
                            String(oldItem.count) === String(newItem.count) &&
                            oldItem.count_type === newItem.count_type &&
                            oldItem.rowIndex === newItem.rowIndex // Use a unique identifier
                    );

                    if (existingIndex !== -1) {
                        // If an entry exists, update it
                        updatedData[existingIndex] = newItem;
                    } else {
                        // If it doesn't exist, add it
                        updatedData.push(newItem);
                    }
                }
            });

            return updatedData;
        });
        // setSaudaShitSummaryData((prevData) => {
        //     debugger;
        //     const updatedData = [...prevData];

        //     processedData.forEach((newItem) => {
        //         const count = newItem.count;

        //         // Ensure count is valid (not undefined or "0")
        //         if (count !== undefined && count !== 0 && count !== "0") {
        //             const existingIndex = updatedData.findIndex((oldItem) => {
        //                 // Convert count values to string for comparison
        //                 // const oldItemCount = String(oldItem.count);
        //                 // const newItemCount = String(newItem.count);

        //                     oldItem.product_id === newItem.product_id &&
        //                     String(oldItem.count) === String(newItem.count)&& // Compare counts as strings
        //                     oldItem.count_type === newItem.count_type &&
        //                     oldItem.rowIndex === newItem.rowIndex &&
        //                     // (keyForViewUpdate !== "Add" ? oldItem.sales_order_sauda_sheet_id === newItem.sales_order_sauda_sheet_id
        //                     //     : true)

        //             });

        //             if (existingIndex !== -1) {
        //                 // Update existing entry with new values
        //                 updatedData[existingIndex] = {
        //                     ...updatedData[existingIndex],
        //                     ...newItem,
        //                 };
        //             } else {
        //                 // Add new entry
        //                 updatedData.push(newItem);
        //             }
        //         }
        //     });

        //     return updatedData;
        // });

        return material_weight;

    }

    // Fn for sum of discount percent and amount on material chabge
    const FnCalculateDiscSum = (updatedDetailsData) => {
        let material_basic_totals = parseFloat(updatedDetailsData.reduce((total, item) => total + parseFloat(item.material_basic_amount), 0));
        let material_discount_amount = parseFloat(updatedDetailsData.reduce((total, item) => total + parseFloat(item.material_discount_amount), 0));
        let material_discount_percent = (material_discount_amount * 100) / material_basic_totals;
        setDiscountPercent(validateNumberDateInput.current.decimalNumber((material_discount_percent).toString(), 2));
        setDiscountAmt(validateNumberDateInput.current.decimalNumber((material_discount_amount).toString(), 4));
    }

    const FnUpdateSalesOrderStatus = (details, schedules, whichStatus) => {
        switch (whichStatus) {
            case 'SalesOrderStatus':
                const statusWiseMaterialCount = details.reduce((counts, material) => {
                    const status = material.sales_order_item_status;
                    counts[status] = (counts[status] || 0) + 1;
                    return counts;
                }, {});

                const pendingMaterials = statusWiseMaterialCount.P === undefined ? 0 : statusWiseMaterialCount.P
                const approvedMaterials = statusWiseMaterialCount.A === undefined ? 0 : statusWiseMaterialCount.A
                const rejectedMaterial = statusWiseMaterialCount.R === undefined ? 0 : statusWiseMaterialCount.R

                if (pendingMaterials > 0) {
                    setSalesOrdStatus('P');
                } else if (approvedMaterials === details.length) {
                    setSalesOrdStatus('A');
                } else if (rejectedMaterial === details.length) {
                    setSalesOrdStatus('R');
                }
                break;

            case 'Material&SchedulesStatus':
                // update all material and schedules status.
                let salesOrderStatus = $('#cmb_sales_order_status').val();
                let salesOrderStatusDesc = $('#cmb_sales_order_status option:selected').attr('lbl');

                let orderIsPreeClosed = document.querySelector('input[name="rb_preeclose_sales_order"]:checked');
                if (orderIsPreeClosed.value === '1') {
                    salesOrderStatus = 'Z';
                    salesOrderStatusDesc = 'PreeClosed'
                };

                let updatedDetails = details?.map(detail => {
                    return {
                        ...detail,
                        // sales_order_item_status: salesOrderStatus,
                        sales_order_item_status: detail.sales_order_item_status === 'P'
                            ? salesOrderStatus
                            : detail.sales_order_item_status,
                    }
                });
                setSalesOrdDetailsData(updatedDetails);

                // Update Schedules Status.
                let updatedSchedules = schedules?.map(schedule => {
                    return {
                        ...schedule,
                        // sales_order_schedules_trading_item_status: salesOrderStatus,
                        // sales_order_schedules_trading_item_status_desc: salesOrderStatusDesc,
                        sales_order_schedules_trading_item_status: schedule.sales_order_schedules_trading_item_status === 'P'
                            ? salesOrderStatus
                            : schedule.sales_order_schedules_trading_item_status,

                        sales_order_schedules_trading_item_status: schedule.sales_order_schedules_trading_item_status === 'P'
                            ? salesOrderStatusDesc
                            : schedule.sales_order_schedules_trading_item_status,
                    };
                });
                setScheduleDataArray(updatedSchedules);
                localStorage.setItem('salesOrderMaterialSchedules', JSON.stringify(updatedSchedules));
                break;
        }
    }

    const validatePercentageInput = (percentageInput) => {
        let percentageValidateMsg = '';
        percentageInput = validateNumberDateInput.current.decimalNumber((percentageInput), 4);

        if (percentageInput === '') {
            percentageValidateMsg = 'Please fill this field...!';
        } else if (parseFloat(percentageInput) > 100) {
            percentageValidateMsg = 'Percentage should not be greater than 100%...!';
        } else {
            return true;
        }
        return percentageValidateMsg;
    }

    const handleSoSrNumberChange = async (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');

        if (clickedColName === 'so_sr_no') {
            if (event._reactName === 'onFocus') {
                // OnFocus add the lastSoSrNo Attribute on element. which is used in onBlur event.
                let initialSOSrNo = currentRowData.so_sr_no;
                event.target.setAttribute('lastSoSrNo', initialSOSrNo);
                return;

            } else if (event._reactName === 'onChange') {

                // First set it last srNo in input field.
                const isDuplicateSoSrNo = salesOrdDetailsData.find(orderItem => orderItem.product_id === currentRowData.product_id && orderItem.so_sr_no === parseInt(event.target.value));

                if (isDuplicateSoSrNo) {
                    event.target.parentElement.dataset.tip = `So_sr_no is duplicate for product ${currentRowData.product_name}...!`;
                } else if (event.target.value === '') {
                    event.target.parentElement.dataset.tip = `Please enter so_sr_no ${currentRowData.product_name}...!`;
                } else {
                    delete event.target.parentElement.dataset.tip;
                }

                if (!isNaN(parseInt(event.target.value, 10))) {
                    currentRowData[clickedColName] = parseInt(event.target.value, 10);
                } else {
                    currentRowData[clickedColName] = '';
                }

            } else if (event._reactName === 'onBlur') {
                // update the schedules and taxations objects.
                let initialSOSrNo = parseInt(event.target.getAttribute('lastSoSrNo'));
                let updatedSoSrNo = parseInt(event.target.value, 10);

                if (event.target.value === '' || isNaN(updatedSoSrNo)) {
                    currentRowData.so_sr_no = initialSOSrNo;
                } else {
                    const similarMaterialsBySoSrNo = salesOrdDetailsData.filter((material) => material.product_id === currentRowData.product_id && parseInt(material.so_sr_no) === parseInt(updatedSoSrNo));
                    if (similarMaterialsBySoSrNo.length > 1) {
                        currentRowData.so_sr_no = initialSOSrNo;
                    } else {
                        currentRowData.so_sr_no = updatedSoSrNo;
                    }
                }

                // Means initial SoSrNo is exist then update the schedules as per the new SoSrNo.
                if (!isNaN(initialSOSrNo) && initialSOSrNo !== updatedSoSrNo) {
                    // All existing schedules for all materials
                    let allExistingSchedules = JSON.parse(localStorage.getItem('salesOrderMaterialSchedules'));

                    if (allExistingSchedules !== null) {        // Means schedules are available.
                        let updatedShedules = [];
                        const { schedulesWithoutCurrentMat, schedulesToUpdate } = allExistingSchedules.reduce(
                            (result, schedule) => {
                                if (parseInt(schedule.so_sr_no) === parseInt(initialSOSrNo) && schedule.product_material_id === currentRowData.product_id) {
                                    result.schedulesToUpdate.push(schedule);
                                } else {
                                    result.schedulesWithoutCurrentMat.push(schedule);
                                }
                                return result;
                            },
                            { schedulesWithoutCurrentMat: [], schedulesToUpdate: [] }
                        );

                        if (schedulesToUpdate.length > 0) {
                            const updatedMaterialSchedules = schedulesToUpdate.map((schedule, index) => {
                                const updatedId = `${currentRowData.product_id}:${currentRowData.so_sr_no}:${index}`;
                                return {
                                    ...schedule, id: updatedId,
                                    so_sr_no: currentRowData.so_sr_no,
                                };
                            });
                            // merge the schedules existing and updated.
                            updatedShedules = schedulesWithoutCurrentMat.concat(updatedMaterialSchedules);
                        } else {
                            updatedShedules = allExistingSchedules;
                        }

                        updatedShedules.sort((material1, material2) => material1.product_material_id - material2.product_material_id);
                        setScheduleDataArray(updatedShedules);
                        localStorage.setItem('salesOrderMaterialSchedules', JSON.stringify(updatedShedules));
                    }

                    // Update the taxation summary data.
                    if (taxationSummary.length > 0) {
                        const { taxationSummaryWithoutCurrentMat, taxationSummaryWithCurrentMat } = taxationSummary.reduce(
                            (result, taxSummary) => {
                                if (parseInt(taxSummary.so_sr_no) === parseInt(initialSOSrNo) && taxSummary.materialId === currentRowData.product_id) {
                                    result.taxationSummaryWithCurrentMat.push(taxSummary);
                                } else {
                                    result.taxationSummaryWithoutCurrentMat.push(taxSummary);
                                }
                                return result;
                            },
                            { taxationSummaryWithoutCurrentMat: [], taxationSummaryWithCurrentMat: [] }
                        );

                        if (taxationSummaryWithCurrentMat.length > 0) {
                            const updatedMaterialTaxSummary = taxationSummaryWithCurrentMat.map((schedule, index) => {
                                return {
                                    ...schedule,
                                    so_sr_no: parseInt(currentRowData.so_sr_no),
                                };
                            });
                            // merge the taxation existing and updated.
                            taxationSummary = taxationSummaryWithoutCurrentMat.concat(updatedMaterialTaxSummary);
                            FnGetTaxationSummary();
                        }
                        localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));
                    }
                }
                delete event.target.parentElement.dataset.tip;
            }

            const updatedDetailsData = [...salesOrdDetailsData]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            setSalesOrdDetailsData(updatedDetailsData);
        }
    }

    const updateTblCalculationStateChange = () => {
        let updatedTblData = [];
        for (let rowIndex = 0; rowIndex < salesOrdDetailsData.length; rowIndex++) {
            let updatedRowData = FnCalculateMaterialTaxation(salesOrdDetailsData[rowIndex]);
            updatedTblData.push(updatedRowData);
        }
        setSalesOrdDetailsData(updatedTblData);
        calculateGrandTotal(updatedTblData);
    }

    // Calculate discount amount 
    const FnCalculateMaterialWiseDiscount = (currentMaterial) => {
        const { material_discount_percent, material_basic_amount, } = currentMaterial;
        const calculatedAmount = (material_discount_percent / 100) * material_basic_amount;
        currentMaterial.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
    }

    // const FnMaterialWiseCalculations = (currentRowData) => {
    //     let custState = parseInt($('#cmb_customer_state_id').val());
    //     let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
    //     let companyBranchStateId = parseInt($('#cmb_companyBranchStateId').val());
    //     let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');

    //     if (!isNaN(custState)) {
    //         let discAmt = isNaN(parseFloat(currentRowData.material_discount_amount)) ? 0 : parseFloat(currentRowData.material_discount_amount);
    //         let hsn_code_rate = isNaN(parseFloat(currentRowData.product_hsn_sac_rate)) ? 0 : parseFloat(currentRowData.product_hsn_sac_rate);

    //         let total_amount = 0; let cgst_sgst_per = 0; let cgst_amt = 0;
    //         let sgst_amt = 0; let igst_per = 0; let igst_amt = 0;

    //         const material_taxable_amount = currentRowData.material_basic_amount - discAmt;
    //         let so_rate = material_taxable_amount;
    //         if (parseFloat(currentRowData.material_quantity) !== 0 && !isNaN(parseFloat(currentRowData.material_quantity))) {
    //             so_rate = parseFloat(material_taxable_amount) / parseFloat(currentRowData.material_quantity)
    //         }
    //         // const so_rate = parseFloat(material_taxable_amount) / parseFloat(currentRowData.material_quantity);

    //         if (custStateCode === 99) {
    //             cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
    //             total_amount = material_taxable_amount;

    //         } else if (customerIsSez === 'true') {
    //             // If customer is sezzed
    //             cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
    //             total_amount = material_taxable_amount;

    //         } else if (companyBranchStateId === custState) {
    //             cgst_sgst_per = hsn_code_rate / 2;
    //             const gst_amt = (material_taxable_amount * hsn_code_rate) / 100;
    //             cgst_amt = gst_amt / 2;
    //             sgst_amt = gst_amt / 2;
    //             total_amount = material_taxable_amount + gst_amt;
    //             // remove the igst taxation
    //             igst_per = 0; igst_amt = 0;

    //         } else {
    //             igst_per = hsn_code_rate;
    //             igst_amt = (material_taxable_amount * hsn_code_rate) / 100;
    //             total_amount = material_taxable_amount + igst_amt;
    //             // remove the cgtst, sgst taxation
    //             cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0;
    //         }

    //         currentRowData['material_taxable_amount'] = validateNumberDateInput.current.decimalNumber(material_taxable_amount.toString(), 4);
    //         currentRowData['so_rate'] = validateNumberDateInput.current.decimalNumber(so_rate.toString(), 4);
    //         currentRowData['material_cgst_percent'] = validateNumberDateInput.current.decimalNumber(cgst_sgst_per.toString(), 4);
    //         currentRowData['material_cgst_total'] = validateNumberDateInput.current.decimalNumber(cgst_amt.toString(), 4);
    //         currentRowData['material_sgst_percent'] = validateNumberDateInput.current.decimalNumber(cgst_sgst_per.toString(), 4);
    //         currentRowData['material_sgst_total'] = validateNumberDateInput.current.decimalNumber(sgst_amt.toString(), 4);
    //         currentRowData['material_igst_percent'] = validateNumberDateInput.current.decimalNumber(igst_per.toString(), 4);
    //         currentRowData['material_igst_total'] = validateNumberDateInput.current.decimalNumber(igst_amt.toString(), 4);
    //         currentRowData['material_total_amount'] = validateNumberDateInput.current.decimalNumber(total_amount.toString(), 4);

    //         // push data in taxation summary list.
    //         let taxSummary = {
    //             materialId: currentRowData.product_id,
    //             so_sr_no: currentRowData.so_sr_no,
    //             hsnCode: currentRowData.product_hsn_sac_code,
    //             hsn_code_id: currentRowData.product_hsn_sac_code_id,
    //             hsnRate: currentRowData.product_hsn_sac_rate,
    //             taxableAmt: currentRowData.material_taxable_amount,
    //             CGSTPercent: currentRowData.material_cgst_percent,
    //             CGSTAmt: currentRowData.material_cgst_total,
    //             SGSTPercent: currentRowData.material_sgst_percent,
    //             SGSTAmt: currentRowData.material_sgst_total,
    //             IGSTPercent: currentRowData.material_igst_percent,
    //             IGSTAmt: currentRowData.material_igst_total,
    //             totalAmt: currentRowData.material_total_amount
    //         };

    //         let taxationSummary = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
    //         const materialIndex = taxationSummary.findIndex((item) => parseInt(item.materialId) === parseInt(currentRowData.product_id) && parseInt(item.so_sr_no) === parseInt(currentRowData.so_sr_no));
    //         if (materialIndex !== -1) {
    //             taxationSummary[materialIndex] = taxSummary;
    //         } else {
    //             taxationSummary.push(taxSummary);
    //         }
    //         localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));
    //         $('#error_cmb_customer_state_id').hide();
    //     } else {
    //         $('#error_cmb_customer_state_id').text('Please select customer state...!');
    //         $('#error_cmb_customer_state_id').show();
    //     }
    //     return currentRowData;
    // };
    const FnMaterialWiseCalculations = (currentRowData) => {

        let discAmt = isNaN(parseFloat(currentRowData.material_discount_amount)) ? 0 : parseFloat(currentRowData.material_discount_amount);
        const material_taxable_amount = currentRowData.material_basic_amount - discAmt;
        let so_rate = material_taxable_amount;
        if (parseFloat(currentRowData.material_quantity) !== 0 && !isNaN(parseFloat(currentRowData.material_quantity))) {
            so_rate = parseFloat(material_taxable_amount) / parseFloat(currentRowData.material_quantity)
        }
        currentRowData['material_taxable_amount'] = validateNumberDateInput.current.decimalNumber(material_taxable_amount.toString(), 4);
        currentRowData['so_rate'] = validateNumberDateInput.current.decimalNumber(so_rate.toString(), 4);
        FnCalculateMaterialTaxation(currentRowData);
        return currentRowData;
    };

    const FnCalculateMaterialTaxation = (currentMaterial) => {
        let custState = parseInt($('#cmb_customer_state_id').val());
        let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
        let companyBranchStateId = parseInt($('#cmb_companyBranchStateId').val());
        let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
        let appliedHSNPercent = isNaN(parseFloat(currentMaterial.product_hsn_sac_rate)) === NaN ? 0 : parseFloat(currentMaterial.product_hsn_sac_rate);
        let materialTaxableAmt = isNaN(parseFloat(currentMaterial.material_taxable_amount)) ? 0 : parseFloat(currentMaterial.material_taxable_amount)

        if (!isNaN(custState)) {
            let cgst_percent = 0; let cgst_cost = 0; let sgst_percent = 0;
            let sgst_cost = 0; let igst_percent = 0; let igst_cost = 0;

            let material_total_amount = 0;
            if (custStateCode === 99) {
                cgst_percent = 0; cgst_cost = 0; sgst_percent = 0; sgst_cost = 0; igst_percent = 0; igst_cost = 0;
                material_total_amount = materialTaxableAmt

            } else if (customerIsSez === 'true') {
                cgst_percent = 0; cgst_cost = 0; sgst_percent = 0; sgst_cost = 0; igst_percent = 0; igst_cost = 0;
                material_total_amount = materialTaxableAmt

            } else if (companyBranchStateId === custState) {
                igst_percent = 0; igst_cost = 0;
                cgst_percent = appliedHSNPercent / 2;
                sgst_percent = appliedHSNPercent / 2;
                cgst_cost = (materialTaxableAmt * cgst_percent / 100)
                sgst_cost = (materialTaxableAmt * sgst_percent / 100)
                material_total_amount = materialTaxableAmt + cgst_cost + sgst_cost + igst_cost

            } else {
                sgst_percent = 0; cgst_percent = 0; cgst_cost = 0; sgst_cost = 0;
                igst_percent = appliedHSNPercent;
                igst_cost = (materialTaxableAmt * appliedHSNPercent) / 100;
                material_total_amount = materialTaxableAmt + igst_cost
            }

            currentMaterial['material_total_amount'] = validateNumberDateInput.current.decimalNumber((material_total_amount).toString(), 4);
            currentMaterial['material_cgst_percent'] = validateNumberDateInput.current.decimalNumber((cgst_percent).toString(), 4);
            currentMaterial['material_cgst_total'] = validateNumberDateInput.current.decimalNumber((cgst_cost).toString(), 4);
            currentMaterial['material_sgst_percent'] = validateNumberDateInput.current.decimalNumber((sgst_percent).toString(), 4);
            currentMaterial['material_sgst_total'] = validateNumberDateInput.current.decimalNumber((sgst_cost).toString(), 4);
            currentMaterial['material_igst_percent'] = validateNumberDateInput.current.decimalNumber((igst_percent).toString(), 4);
            currentMaterial['material_igst_total'] = validateNumberDateInput.current.decimalNumber((igst_cost).toString(), 4);

            // currentRowData['material_cgst_percent'] = validateNumberDateInput.current.decimalNumber(cgst_sgst_per.toString(), 4);
            // currentRowData['material_cgst_total'] = validateNumberDateInput.current.decimalNumber(cgst_amt.toString(), 4);
            // currentRowData['material_sgst_percent'] = validateNumberDateInput.current.decimalNumber(cgst_sgst_per.toString(), 4);
            // currentRowData['material_sgst_total'] = validateNumberDateInput.current.decimalNumber(sgst_amt.toString(), 4);
            // currentRowData['material_igst_percent'] = validateNumberDateInput.current.decimalNumber(igst_per.toString(), 4);
            // currentRowData['material_igst_total'] = validateNumberDateInput.current.decimalNumber(igst_amt.toString(), 4);
            // currentRowData['material_total_amount'] = validateNumberDateInput.current.decimalNumber(total_amount.toString(), 4);

            // push data in taxation summary list.
            let taxSummary = {
                materialId: currentMaterial.product_id,
                so_sr_no: currentMaterial.so_sr_no,
                hsnCode: currentMaterial.product_hsn_sac_code,
                hsn_code_id: currentMaterial.product_hsn_sac_code_id,
                hsnRate: currentMaterial.product_hsn_sac_rate,
                taxableAmt: currentMaterial.material_taxable_amount,
                CGSTPercent: currentMaterial.material_cgst_percent,
                CGSTAmt: currentMaterial.material_cgst_total,
                SGSTPercent: currentMaterial.material_sgst_percent,
                SGSTAmt: currentMaterial.material_sgst_total,
                IGSTPercent: currentMaterial.material_igst_percent,
                IGSTAmt: currentMaterial.material_igst_total,
                totalAmt: currentMaterial.material_total_amount
            };

            let taxationSummary = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
            const materialIndex = taxationSummary.findIndex((item) => item.materialId === currentMaterial.product_id && item.so_sr_no === parseInt(currentMaterial.so_sr_no));
            if (materialIndex !== -1) {
                taxationSummary[materialIndex] = taxSummary;
            } else {
                taxationSummary.push(taxSummary);
            }
            localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));

        } else {
            $('#error_cmb_customer_state_id').text('Please select customer state...!');
            $('#error_cmb_customer_state_id').show();
        }
        return currentMaterial;
    }

    // Function to normalize hsnCode values for grouping this function remove the space for the set as a properObjKey
    function normalizeHsnCode(hsnCode) {
        return hsnCode.replace(/\s+/g, '').toUpperCase();
    }

    const FnGetTaxationSummary = () => {
        let taxSummaryData = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
        if (taxSummaryData === null || taxSummaryData.length === 0) {
            setTaxationSummaryData([])
            return null;
        }

        const groupedData = taxSummaryData.reduce((acc, item) => {
            const hsnCode = normalizeHsnCode(item.hsnCode);
            if (!acc[hsnCode]) { acc[hsnCode] = []; }
            acc[hsnCode].push(item);
            return acc;
        }, {});

        if (Object.keys(groupedData).length > 0) {
            const hsnCodesGrps = Object.keys(groupedData);
            let taxationTblData = [];
            for (let hsnType = 0; hsnType < hsnCodesGrps.length; hsnType++) {
                let particularHSNGrpList = groupedData[hsnCodesGrps[hsnType]]
                const groupTotalIGSTAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.IGSTAmt), 0);
                const groupTotalSGSTAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.SGSTAmt), 0);
                const groupTotalCGSTAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.CGSTAmt), 0);
                const groupTotalAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.totalAmt), 0);
                const groupTaxableAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.taxableAmt), 0);

                let groupData = {
                    hsn_sac_code: particularHSNGrpList[0].hsnCode,
                    hsn_id: particularHSNGrpList[0].hsn_code_id,
                    summary_taxable_amount: validateNumberDateInput.current.decimalNumber(groupTaxableAmt.toString(), 4),
                    summary_cgst_percent: particularHSNGrpList[0].CGSTPercent,
                    summary_cgst_total: validateNumberDateInput.current.decimalNumber(groupTotalCGSTAmt.toString(), 4),
                    summary_sgst_percent: particularHSNGrpList[0].SGSTPercent,
                    summary_sgst_total: validateNumberDateInput.current.decimalNumber(groupTotalSGSTAmt.toString(), 4),
                    summary_igst_percent: particularHSNGrpList[0].IGSTPercent,
                    summary_igst_total: validateNumberDateInput.current.decimalNumber(groupTotalIGSTAmt.toString(), 4),
                    summary_total_amount: validateNumberDateInput.current.decimalNumber(groupTotalAmt.toString(), 4),
                }
                taxationTblData.push(groupData);
            }
            // await FnSetTaxationSummaryColumns();
            setTaxationSummaryData(taxationTblData)
        } else {
            setTaxationSummaryData([])
        }
    }

    const handleFooterDiscountPercentChange = async (e) => {

        let isValidPercentageInput = validatePercentageInput(e.target.value);

        if (isValidPercentageInput === true) {
            const percentInput = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4)
            const amount = (percentInput / 100) * txt_basic_total;
            setDiscountAmt((amount).toFixed(2));
            setDiscountPercent(percentInput);
            calculateGrandTotal(salesOrdDetailsData);
            $('#error_txt_discount_percent').hide();

        } else {
            $('#error_txt_discount_percent').text(isValidPercentageInput);
            $('#error_txt_discount_percent').show();
        }

        if (e._reactName === 'onBlur' && e.target.value === '') {
            setDiscountPercent(0);
            setDiscountPercent(0);
            calculateGrandTotal(salesOrdDetailsData);
            delete e.target.parentElement.dataset.tip;
        }
        setTimeout(() => {
            calculateGrandTotal(salesOrdDetailsData);
            reverseCalculationForDetailData();
        }, 200);
    };

    const handleFooterDiscountAmountChange = async (e) => {
        const amount = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4)
        const percent = (amount * 100) / txt_basic_total;

        if (e._reactName === 'onBlur' && e.target.value === '') {
            setDiscountPercent(0);
            setDiscountAmt(0);
            delete e.target.parentElement.dataset.tip;
        } else {
            setDiscountPercent((percent).toFixed(2));
            setDiscountAmt(amount);
        }

        setTimeout(() => {
            calculateGrandTotal(salesOrdDetailsData);
            reverseCalculationForDetailData();
        }, 200);
    };

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForDetailData = () => {
        let salesOrderData = [...salesOrdDetailsData];
        // const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const discount_amount = isNaN(parseFloat($('#txt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_discount_amount').val()))

        if (salesOrderData.length !== 0) {
            const updatedData = salesOrderData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                // let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
                let discountAmount;
                let calculatedPercent;
                if (discountAmount !== 0) {
                    discountAmount = (obj.material_basic_amount / txt_basic_total) * discount_amount;
                    calculatedPercent = (discountAmount * 100) / obj.material_basic_amount;
                }
                // Create a new object with the updated properties
                return {
                    ...obj,
                    // material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                    material_discount_amount: discountAmount === 0 ? obj.material_discount_amount : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    material_discount_percent: calculatedPercent === 0 ? obj.material_discount_percent : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2)
                };
            });
            setSalesOrdDetailsData(updatedData)
        }
    }

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForFreightAmt = () => {
        let salesOrderData = [...salesOrdDetailsData];
        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        if (salesOrderData.length !== 0) {
            const updatedData = salesOrderData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
                return {
                    ...obj,
                    material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                };
            });
            setSalesOrdDetailsData(updatedData)
        }
    }

    const calculateGrandTotal = (salesOrdDetailsData) => {
        let taxSummaryData = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
        let customerState = $('#cmb_customer_state_id').val();
        let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
        let companyBranchStateId = $('#cmb_companyBranchStateId').val();
        let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
        let freightTaxHSNIsSelected = $('#cmb_freight_hsn_id').val();

        if (freightIsTaxable && (freightTaxHSNIsSelected === '' || freightTaxHSNIsSelected === null)) {
            $('#cmb_freight_hsn_id').removeAttr('optional', 'optional');
            $('#error_cmb_freight_hsn_id').text('Please select one...!');
            $('#error_cmb_freight_hsn_id').show();
            return false;
        } else if (!freightIsTaxable) {
            setFreightHSNId('');
        }

        if (salesOrdDetailsData !== null && salesOrdDetailsData.length > 0) {
            let material_basic_total = salesOrdDetailsData.reduce((total, item) => total + parseFloat(item.material_taxable_amount), 0);
            setBasicTotal(validateNumberDateInput.current.decimalNumber(material_basic_total.toString(), 4));

            let cgst_total = salesOrdDetailsData.reduce((total, item) => total + parseFloat(item.material_cgst_total), 0);
            let sgst_total = salesOrdDetailsData.reduce((total, item) => total + parseFloat(item.material_sgst_total), 0);
            let igst_total = salesOrdDetailsData.reduce((total, item) => total + parseFloat(item.material_igst_total), 0);

            let discAmt = isNaN(parseFloat($('#txt_discount_amount').val())) === true ? 0 : parseFloat($('#txt_discount_amount').val())
            const transportAmt = isNaN(parseFloat($('#txt_transport_amount').val())) === true ? 0 : parseFloat($('#txt_transport_amount').val())
            const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) === true ? 0 : parseFloat($('#txt_freight_amount').val())
            const packingAmt = isNaN(parseFloat($('#txt_packing_amount').val())) === true ? 0 : parseFloat($('#txt_packing_amount').val())
            const otherAmt = isNaN(parseFloat($('#txt_other_amount').val())) === true ? 0 : parseFloat($('#txt_other_amount').val())

            const taxableAmount = material_basic_total - discAmt;
            setTaxableTotal(validateNumberDateInput.current.decimalNumber(taxableAmount.toString(), 4));

            if (freightIsTaxable) {
                let taxSummary = {
                    materialId: 'Freight', so_sr_no: 'Freight',
                    hsnCode: $('#cmb_freight_hsn_id option:selected').attr('lbl'),
                    hsn_code_id: $('#cmb_freight_hsn_id').val(),
                    hsnRate: $('#cmb_freight_hsn_id option:selected').attr('rate'),
                    taxableAmt: freightAmt, CGSTPercent: 0, CGSTAmt: 0,
                    SGSTPercent: 0, SGSTAmt: 0, IGSTPercent: 0, IGSTAmt: 0, totalAmt: 0
                };

                let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
                $('#cmb_freight_hsn_id').removeAttr('optional');
                let freightTaxRate = isNaN(parseFloat($('#cmb_freight_hsn_id option:selected').attr('rate'))) === true ? 0 : parseFloat($('#cmb_freight_hsn_id option:selected').attr('rate'));
                const freightTaxAmt = (freightAmt * freightTaxRate) / 100;

                if (customerIsSez === 'true') {
                    // If customer is sezzed
                    igst_total += 0;
                    cgst_total += 0;
                    sgst_total += 0;

                } else if (custStateCode === 99) {
                    // Set outer teritory tax totals
                    igst_total += 0;
                    cgst_total += 0;
                    sgst_total += 0;

                } else if (companyBranchStateId === customerState) {
                    // Set inter state tax totals
                    taxSummary['CGSTAmt'] = (freightTaxAmt / 2);
                    taxSummary['CGSTPercent'] = (freightTaxRate / 2);
                    taxSummary['SGSTAmt'] = (freightTaxAmt / 2);
                    taxSummary['SGSTPercent'] = (freightTaxRate / 2);
                    taxSummary['totalAmt'] = (freightAmt + freightTaxAmt);

                    cgst_total += freightTaxAmt / 2;
                    sgst_total += freightTaxAmt / 2;

                } else {
                    // Set outer state tax totals
                    taxSummary['IGSTAmt'] = (freightTaxAmt);
                    taxSummary['IGSTPercent'] = (freightTaxRate);
                    taxSummary['totalAmt'] = (freightAmt + freightTaxAmt);

                    igst_total += freightTaxAmt;
                }
                const existingFreightTaxationObj = taxSummaryData.findIndex((record) => record.materialId === "Freight" && record.so_sr_no === "Freight");
                if (existingFreightTaxationObj !== -1) {
                    taxSummaryData[existingFreightTaxationObj] = taxSummary;   // Replace the object in-place
                } else {
                    taxSummaryData.push(taxSummary);                           // Push the new object if not found
                }
            } else {
                $('#cmb_freight_hsn_id').attr('optional', 'optional');
                // if it is exist in the taxation summary object then remove it
                const existingFreightTaxationObj = taxSummaryData.findIndex((record) => record.materialId === "Freight" && record.so_sr_no === "Freight");
                if (existingFreightTaxationObj !== -1) { taxSummaryData.splice(existingFreightTaxationObj, 1); }
            }
            localStorage.setItem('taxationSummary', JSON.stringify(taxSummaryData));

            setCGSTTotal(validateNumberDateInput.current.decimalNumber((cgst_total).toString(), 4));
            setSGSTTotal(validateNumberDateInput.current.decimalNumber((sgst_total).toString(), 4));
            setIGSTTotal(validateNumberDateInput.current.decimalNumber((igst_total).toString(), 4))

            let grandTotal = taxableAmount + transportAmt + freightAmt + packingAmt + otherAmt + cgst_total + sgst_total + igst_total;
            setGrandTotal(Math.round(grandTotal))

            let roundOffValue = grandTotal - Math.floor(grandTotal);

            if (roundOffValue >= 0.50) {
                roundOffValue = roundOffValue - 1; // Subtract 1 to get negative round-off
            }
            setRoundOff((roundOffValue).toFixed(2))
        } else {
            setBasicTotal(0);
            setTransportAmt(0);
            setTaxableTotal(0);
            setGrandTotal(0);
            setRoundOff(0);
            setCGSTTotal(0);
            setSGSTTotal(0);
            setIGSTTotal(0);
        }
        FnGetTaxationSummary();
    }

    const removeMaterial = async (currentOrderItem) => {

        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            $('#spinner_id').show();
            $('#spinner_text').text('Loading...');

            let detailTblRows = [...salesOrdDetailsData];      // Store all table rows original object in array.
            let similarMaterialsCountWithSameMatId = detailTblRows.filter(item => item.product_id === currentOrderItem.product_id).length;

            // Remove it from details.
            let rowIndexInDetailsTbl = detailTblRows.findIndex((record) => record.product_id === currentOrderItem.product_id && parseInt(record.so_sr_no) === parseInt(currentOrderItem.so_sr_no));
            if (rowIndexInDetailsTbl !== -1) {
                detailTblRows.splice(rowIndexInDetailsTbl, 1);
                detailTblRows.forEach((material, index) => {
                    material.sr_no = index + 1;
                });
                setSalesOrdDetailsData(detailTblRows);                            // Set that updated data in table data hook;
            }

            // Remove the entry for the matching product_id
            const updatedSaudaShitSummaryData = saudaShitSummaryData.filter(
                (record) => record.product_id !== currentOrderItem.product_id
            );

            // Update the state with the filtered array
            setSaudaShitSummaryData(updatedSaudaShitSummaryData);
            // Remove it from taxation summary.
            let taxSummaryData = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
            let deletedMaterialIndex = taxSummaryData.findIndex((record) => record.materialId === currentOrderItem.product_id && parseInt(record.so_sr_no) === parseInt(currentOrderItem.so_sr_no));
            if (deletedMaterialIndex !== -1) { taxSummaryData.splice(deletedMaterialIndex, 1); }
            localStorage.setItem('taxationSummary', JSON.stringify(taxSummaryData));

            // remove the schedules for that material
            let materialScheduleTblRows = JSON.parse(localStorage.getItem('salesOrderMaterialSchedules')) === null ? [] : JSON.parse(localStorage.getItem('salesOrderMaterialSchedules'));
            if (materialScheduleTblRows.length > 0) {
                const updatedMaterialSchedules = materialScheduleTblRows.filter(
                    item => parseInt(item.so_sr_no) !== parseInt(currentOrderItem.so_sr_no) || item.product_material_id !== currentOrderItem.product_id
                );
                setScheduleDataArray(updatedMaterialSchedules);
                localStorage.setItem('salesOrderMaterialSchedules', JSON.stringify(updatedMaterialSchedules));
            }
            //changes by tushar
            // if (productTypePropertiesData.length !== 0 && similarMaterialsCountWithSameMatId === 1) {
            //     let updatedMaterialProperties = [...productTypePropertiesData]
            //     updatedMaterialProperties = updatedMaterialProperties.filter(item => item.product_id !== currentOrderItem.product_id);
            //     setProductTypePropertiesData(updatedMaterialProperties);
            // }
            setTimeout(() => { calculateGrandTotal(detailTblRows); }, 100);

            $('#spinner_id').hide();
        }
    }

    const FnGenerateSalesOrderNo = async (sales_order_type) => {
        let soOrderTypeId = $('#cmb_sales_order_type_id').val();
        const getSalesOrderNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("mt_sales_order_master_trading", "sales_order_no", sales_order_type, 'SO', "5", "sales_order_type_id", soOrderTypeId);
        setSalesOrdNo(getSalesOrderNoApiCall);
        setSalesOrdVer(1);
        return getSalesOrderNoApiCall;
    };

    const removeSessions = async () => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');

        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const validateFields = () => {
        let formObj = $('#salesOrderFormId');
        let inputObj;
        for (let i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            }
        }

        formObj = $('#salesOrderTotalsFrm');
        for (let i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            }
        }
    }

    function scrollToTableRow() {
        let keywords = $("#txt-detail-data-highliter").val();
        if (keywords !== '') {
            let table = document.getElementById('salesOrdMaterialDtlsTbl');
            let tblRows = table.getElementsByTagName('tr');
            for (let trNum = 1; trNum < tblRows.length; trNum++) {
                let row = tblRows[trNum];
                if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                    let w = $(window);
                    let row = $('#salesOrdMaterialDtlsTbl').find('tr').eq(trNum);
                    if (row.length) {
                        w.scrollTop(row.offset().top - (w.height() / 2));
                    }
                    $(row).find('td').each(function () {
                        $(this).addClass('highlight');
                    });
                    removeOtherTrHighlightClass(trNum)
                    break;
                } else {
                    // $(row).find('td').each(function () { $(this).removeClass('highlight'); });
                    $("#salesOrdMaterialDtlsTbl td.highlight").removeClass("highlight");
                }
            }
        } else {
            $("#salesOrdMaterialDtlsTbl td.highlight").removeClass("highlight");
        }
    }

    const removeOtherTrHighlightClass = (rowNum) => {
        let table = document.getElementById('salesOrdMaterialDtlsTbl');
        let tblRows = table.getElementsByTagName('tr');
        for (let trNum = 1; trNum < tblRows.length; trNum++) {
            let row = tblRows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const moveToListing = () => {
        // Remove all sessions.
        sessionStorage.removeItem('keyForViewUpdate');
        sessionStorage.removeItem('SalesOrderDetails');
        sessionStorage.removeItem('bomfilteredData');
        sessionStorage.removeItem('orderDetailsData')
        localStorage.removeItem('SalesOrdPmtTermsData');
        localStorage.removeItem('salesOrderMaterialSchedules');
        localStorage.removeItem('SalesOrdCommonTermsData');
        localStorage.removeItem('taxationSummary');
        sessionStorage.removeItem('salesOrder_no');
        let Path = compType === 'Register' ? '/Transactions/TSalesOrder/SalesOrderListing/reg' : `/Transactions/TSalesOrder/SalesOrderListing`;
        //changes by tushar
        Path = `${requestfor ? `${Path}/${requestfor}` : `${Path}`}`;
        navigator(Path);

    }


    function checkBoxesSelection(key) {
        switch (key) {
            // For Payment terms
            case "selectAllPmtTerms":
                $('.selectPmtTerm').prop('checked', $('#selectAllPmtTerms').is(":checked"));
                break;
            case 'PartiallyPmtTermsSelection':
                $('#selectAllPmtTerms').prop('checked', $('input:checkbox.selectPmtTerm:checked').length === $('input:checkbox.selectPmtTerm').length);
                break;
            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            // For Common terms
            case 'selectAllCommonTerms':
                $('.selectCommonTerm').prop('checked', $('#selectAllCommonTerms').is(":checked"));
                break;
            case 'PartiallyCommonTermSelection':
                $('#selectAllCommonTerms').prop('checked', $('input:checkbox.selectCommonTerm:checked').length === $('input:checkbox.selectCommonTerm').length);
                break;
            default:
                break;
        }
    }

    const FnSetDefaultFormFields = async () => {
        // Header section form fields.
        setSalesOrdCreationType('M');
        // setSalesOrdNo(0);
        // setSalesOrdVer(1);
        setSalesOrdDate(todayDate);
        setSalesOrdLife('C');
        // customer details form fields
        setCustomerId('');
        setCustomerOrderNo('');
        setCustomerOrdDate('');
        setCustomerState('');
        setCustomerCityOpts([]);
        setCustomerCity('');

        setSalesQuotationNo('');
        setSalesQuotationDate('');

        // consignee details form fields
        setConsigneeId('');
        setConsigneeState('');
        setConsigneeCityOpts([]);
        setConsigneeCity('');
        // Sanction details form fields
        setDeptId('');
        // setApproveByOpts([]);
        setApprovedById('');
        setApproveDate('');
        setOverAllScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays));
        setRemark('');
        setIsActive('true');
        // Taxation and Billing section form fields
        setBasicTotal(0);
        setTransportAmt(0);
        setFreightAmt(0);
        setFreightIsTaxable(false);
        setFreightHSNId('');
        setPackingAmt(0);
        setDiscountPercent(0);
        setDiscountAmt(0);
        setOtherAmt(0);
        setCGSTPercent(0);
        setCGSTTotal(0);
        setSGSTPercent(0);
        setSGSTTotal(0);
        setIGSTPercent(0);
        setIGSTTotal(0);
        setTaxableTotal(0);
        setRoundOff(0);
        setGrandTotal(0);
        setAgentId('');
        setAgentPercent(0);
        setAgentPaidStatus('P');
        setSalesOrdStatus('P');
        setSalesOrdAcceptanceStatus('P');
        setStatusRemark('');
        setOtherTermsConditions('');
        setSalesOrderMailSentStatus('P');
        // Payment Term Details.
        $('#selectAllPmtTerms').prop('checked', false);
        $('.selectPmtTerm').prop('checked', false);
        $('#selectAllCommonTerms').prop('checked', false);
        $('.selectCommonTerm').prop('checked', false);

        // Customers contact details table data hooks
        setCustContactDetails([]);


        sessionStorage.removeItem('txt_sales_order_no')
        sessionStorage.removeItem('bomfilteredData')
        sessionStorage.removeItem('orderDetailsData')
        localStorage.removeItem('salesOrderMaterialSchedules');
        localStorage.removeItem('taxationSummary');

        setSalesOrdDetailsData([]);
        setRmRequirementData([])
        setScheduleDataArray([]);
        setProductTypePropertiesData([]);
        taxationSummary = [];
        setTaxationSummaryData([]);
        await FnGetPaymentTerms();
        await FnGetCommonSalesOrderTerms();
    }

    const acceptSalesOrder = async () => {
        $('#spinner_id').show();
        $('#spinner_text').text('Validating Order Details...');
        try {
            let customerSOAcceptanceJson = {
                sales_order_master_transaction_id: sales_order_master_transaction_id,
                sales_order_acceptance_status: 'A'
            }
            const formData = new FormData();
            formData.append(`customerSOAcceptanceJson`, JSON.stringify(customerSOAcceptanceJson))
            const requestOptions = { method: 'POST', body: formData };
            const acceptSalesOrderAPICall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterTrading/FnAcceptCustomerOrder/${COMPANY_ID}`, requestOptions)
            const acceptSalesOrderAPICallResponse = await acceptSalesOrderAPICall.json();
            $('#spinner_id').hide();

            if (acceptSalesOrderAPICallResponse.success === "1") {
                setSuccMsg(acceptSalesOrderAPICallResponse.message)
                setShowSuccessMsgModal(true);
            } else {
                setErrMsg(acceptSalesOrderAPICallResponse.error);
                setShowErrorMsgModal(true);
            }

        } catch (error) {
            console.log('Error in accepting sales order: ', error)
            $('#spinner_id').hide();
            navigate('/Error')
        }
    }

    const FnGenerateSalesOrder = async (e, ordStatus) => {
        debugger
        $('#spinner_id').show();
        $('#spinner_text').text('Validating Order Details...');
        try {
            let salesOrderIsValid;
            let clickedBtnType = e.target.getAttribute('BtnType')

            if (clickedBtnType !== 'save') {
                if ((clickedBtnType === 'reject' || rb_preeclose_sales_order === 'Z') && $('#txt_status_remark').val().trim() === '') {
                    $('#spinner_id').hide();
                    $('#error_txt_status_remark').text(`Please enter the remark for.`);
                    $('#error_txt_status_remark').show();
                    return false;
                } else if (clickedBtnType === 'approve' || clickedBtnType === 'reject') {
                    salesOrderIsValid = true;
                }
            } else if (clickedBtnType !== 'approve' && clickedBtnType !== 'reject') {
                salesOrderIsValid = await FnValidateSalesOrder();
            }

            if (salesOrderIsValid) {
                if (keyForViewUpdate === 'update') {
                    $('#spinner_text').text('Updating Sales Order Details...');
                } else if (keyForViewUpdate === 'approve') {
                    if (clickedBtnType === 'approve') {
                        $('#spinner_text').text('Approving Sales Order...');
                    } else if (clickedBtnType === 'reject') {
                        $('#spinner_text').text('Rejecting Sales Order...');
                    }
                } else {
                    $('#spinner_text').text('Generating Sales Order...');
                }

                let salesOrderNo = txt_sales_order_no
                if (keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve') {
                    let SOTypeShortName = $('#cmb_sales_order_type_id option:selected').attr('shortName');
                    salesOrderNo = await FnGenerateSalesOrderNo(SOTypeShortName);
                    // salesOrderNo = await FnGenerateSalesOrderNo(sales_order_type);       // For Without ProductType Based.
                }
                let customerContactIds = FnGetCustomerContactPersonIds();
                let json = { 'TransMasterData': {}, 'TransDetailData': [], 'TransDetailScheduleData': [], 'TransPaymentTermsData': [], 'commonIds': {}, 'TransSalesOrderTermsData': [], 'TaxSummaryData': [], 'TransProductParametersData': [], 'DocumentUpload': [], 'TransRawMaterialData': [], 'SaudaShitDetails': [] }

                let commonFieldsJson = {
                    company_id: parseInt(COMPANY_ID),
                    company_branch_id: parseInt(COMPANY_BRANCH_ID),
                    financial_year: FINANCIAL_SHORT_NAME,
                    sales_order_master_transaction_id: isApprove ? sales_order_master_transaction_id : 0,
                    sales_order_type_id: cmb_sales_order_type_id,

                    // sales_order_type: sales_order_type,             // For Without ProductType Based.
                    sales_order_type: $('#cmb_sales_order_type_id').find(":selected").text(),
                    sales_type: $("#cmb_sales_type_id option:selected").val(),
                    sales_order_no: salesOrderNo,
                    sales_order_version: txt_sales_order_version,
                    sales_order_date: dt_sales_order_date,

                    customer_id: cmb_customer_id,
                    customer_order_no: txt_customer_order_no === "" ? salesOrderNo : txt_customer_order_no,
                    customer_order_Date: dt_customer_order_Date === "" ? dt_sales_order_date : dt_customer_order_Date,

                    customer_state_id: cmb_customer_state_id,
                    customer_city_id: cmb_customer_city_id,
                    customer_contacts_ids: customerContactIds,

                    department_id: cmb_department_id,
                    sub_department_id: cmb_sub_department,
                    expected_branch_id: cmb_companyBranchId,
                    expected_branch_state_id: cmb_companyBranchStateId,
                    expected_branch_city_id: cmb_companyBranchCityId,
                    is_active: rb_is_active,
                    created_by: UserName
                };

                // mt_sales_order_master_trading table object
                const salesOrderMasterFormData = {
                    ...commonFieldsJson,    // set all common fields.
                    sales_order_creation_type: rb_sales_order_creation_type,
                    sales_order_life: rb_sales_order_life,
                    sales_quotation_no: cmb_sales_quotation_no,
                    sales_quotation_date: dt_sales_quotation_date,
                    consignee_id: cmb_consignee_id,
                    consignee_state_id: cmb_consignee_state_id,
                    consignee_city_id: cmb_consignee_city_id,
                    approved_by_id: cmb_approved_by_id,
                    approved_date: dt_approved_date,
                    overall_schedule_date: dt_overall_schedule_date,
                    remark: txt_remark,
                    basic_total: txt_basic_total,
                    job_type: cmb_sales_job_type,
                    transport_amount: txt_transport_amount,
                    freight_amount: txt_freight_amount,
                    is_freight_taxable: chk_freight_isTaxable,
                    freight_hsn_code_id: cmb_freight_hsn_id,
                    packing_amount: txt_packing_amount,
                    discount_percent: txt_discount_percent,
                    discount_amount: txt_discount_amount,
                    other_amount: txt_other_amount,
                    cgst_percent: txt_cgst_percent,
                    cgst_total: txt_cgst_total,
                    sgst_percent: txt_sgst_percent,
                    sgst_total: txt_sgst_total,
                    igst_percent: txt_igst_percent,
                    igst_total: txt_igst_total,
                    taxable_total: txt_taxable_total,
                    roundoff: txt_roundoff,
                    grand_total: txt_grand_total,
                    agent_id: cmb_agent_id,
                    agent_percent: txt_agent_percent,
                    agent_paid_status: cmb_agent_paid_status,
                    sales_order_status: rb_preeclose_sales_order === '1' ? 'Z' : keyForViewUpdate !== 'approve' ? 'P' : ordStatus,
                    sales_order_acceptance_status: rb_sales_order_acceptance_status,
                    status_remark: txt_status_remark,
                    other_terms_conditions: txt_other_terms_conditions,
                    sales_order_mail_sent_status: rb_sales_order_mail_sent_status,
                    supplier_branch_id:cmb_supplier_branch_id,
                    lot_no:txt_lot_no
                }
                // if (cmb_consignee_id === '' || cmb_consignee_id === undefined) {
                //     salesOrderMasterFormData['consignee_id'] = cmb_customer_id;
                //     salesOrderMasterFormData['consignee_state_id'] = cmb_customer_state_id;
                //     salesOrderMasterFormData['consignee_city_id'] = cmb_customer_city_id;
                // }

                if (keyForViewUpdate === 'approve') {
                    if (rb_preeclose_sales_order === '1') {
                        salesOrderMasterFormData['sales_order_status'] = 'Z'
                    } else if (clickedBtnType === 'reject') {
                        salesOrderMasterFormData['sales_order_status'] = 'R'
                    } else if (clickedBtnType === 'approve' && cmb_sales_order_status === 'A') {
                        salesOrderMasterFormData['sales_order_status'] = 'A'
                    } else {
                        salesOrderMasterFormData['sales_order_status'] = cmb_sales_order_status
                    }
                }
                json.TransMasterData = salesOrderMasterFormData;

                // JSON Objects Array For Details Data.
                let salesOrderMaterialsJSONArray = salesOrdDetailsData?.map((salesOrderMaterial, index) => ({
                    ...salesOrderMaterial,             // Add all challan-material's existing fields.
                    sales_order_details_transaction_id: salesOrderMaterial.sales_order_details_transaction_id !== undefined && isApprove ? salesOrderMaterial.sales_order_details_transaction_id : 0,
                    sr_no: index + 1,
                    product_material_id: salesOrderMaterial.product_id,
                    product_material_print_name: salesOrderMaterial.product_name,
                    product_material_tech_spect: salesOrderMaterial.product_tech_spect,
                    product_material_unit_id: salesOrderMaterial.product_unit_id,
                    product_material_packing_id: salesOrderMaterial.product_packing_id,
                    product_material_hsn_code_id: salesOrderMaterial.product_hsn_sac_code_id,
                    product_type_id: $('#cmb_sales_order_type_id').val(),
                    product_type: $('#cmb_sales_order_type_id option:selected').attr('lbl'),

                    sales_order_item_status: rb_preeclose_sales_order === '1' ? 'Z' : salesOrderMaterial.sales_order_item_status === 'P' && isApprove ? ordStatus : salesOrderMaterial.sales_order_item_status,
                    pree_closed_quantity: rb_preeclose_sales_order === '1' || salesOrderMaterial.sales_order_item_status === 'Z' ? salesOrderMaterial.material_quantity : 0,
                    pree_closed_weight: rb_preeclose_sales_order === '1' || salesOrderMaterial.sales_order_item_status === 'Z' ? salesOrderMaterial.material_weight : 0,

                    approved_by_id: isApprove && keyForViewUpdate !== 'copy' ? cmb_approved_by_id : '',
                    approved_date: isApprove && keyForViewUpdate !== 'copy' ? dt_approved_date : '',
                    created_by: UserName,
                    ...commonFieldsJson     // set all common fields.
                }));
                json.TransDetailData = salesOrderMaterialsJSONArray;


                // Trans Schedule Data     
                for (let index = 0; index < json.TransDetailData.length; index++) {
                    const element = json.TransDetailData[index];
                    let scData = scheduleDataArray.filter(item => item.product_material_id === element.product_material_id && parseInt(item.so_sr_no) === parseInt(element.so_sr_no));
                    if (scData.length === 0) {
                        const scheduleData = {
                            ...commonFieldsJson,     // set all common fields.
                            sales_order_details_transaction_id: 0,
                            so_sr_no: element.so_sr_no,
                            product_name: element.product_material_print_name,
                            product_type_id: element.product_type_id,
                            // product_type: element.product_type_short_name,
                            product_type: element.product_type,
                            product_material_id: element.product_material_id,
                            product_material_unit_id: element.product_material_unit_id,
                            material_quantity: element.material_quantity,
                            material_weight: element.material_weight,
                            product_material_schedule_quantity: element.material_quantity,
                            product_material_schedule_weight: element.material_weight,
                            expected_schedule_date: dt_overall_schedule_date,
                            sales_order_schedules_trading_item_status: rb_preeclose_sales_order === '1' ? 'Z' : element.sales_order_item_status === 'P' && isApprove ? ordStatus : element.sales_order_item_status,
                            product_material_issue_quantity: 0,
                            product_material_issue_weight: 0,
                            material_issue_date: "",
                        }
                        json.TransDetailScheduleData.push(scheduleData)
                    } else {
                        const updatedSchedules = scData.map(schedule => ({
                            ...schedule,
                            ...commonFieldsJson,     // set all common fields.
                            sales_order_details_transaction_id: 0,
                            product_type_id: element.product_type_id,
                            // product_type: element.product_type_short_name,
                            product_type: element.product_type,
                            sales_order_schedules_trading_item_status: rb_preeclose_sales_order === '1' ? 'Z' : element.sales_order_item_status === 'P' && isApprove ? ordStatus : element.sales_order_item_status,
                        }));
                        json.TransDetailScheduleData.push(...updatedSchedules);
                    }
                }
                setScheduleDataArray(json.TransDetailScheduleData);
                localStorage.setItem('salesOrderMaterialSchedules', JSON.stringify(json.TransDetailScheduleData));

                // If user not come for approve then send whole data.
                if (!isApprove) {
                    // Trans Payment Terms Data
                    $("input:checkbox[name=selectPmtTerm]:checked").each(function () {
                        let findPaymentTermsData = PmtTermsdata.find(item => item.payment_terms_id === parseInt($(this).val()));
                        let payment_term_idVal = parseInt($(this).val());
                        const paymentTermsData = {
                            ...commonFieldsJson,     // set all common fields.
                            sales_order_details_transaction_id: 0,
                            payment_terms_id: payment_term_idVal,
                            payment_terms_name: findPaymentTermsData.payment_terms_name,
                            payment_terms_days: findPaymentTermsData.payment_terms_days,
                            payment_terms_grace_days: findPaymentTermsData.payment_terms_grace_days,
                            payment_terms_Milestome: $('#payment_milestone_' + payment_term_idVal).val(),
                            payment_percent: $('#payment_percent_' + payment_term_idVal).val(),
                            payment_expected_value: $('#payment_expected_value_' + payment_term_idVal).val(),
                            payment_expected_date: $('#payment_expected_date_' + payment_term_idVal).val(),
                            remark: findPaymentTermsData.remark,
                        }
                        json.TransPaymentTermsData.push(paymentTermsData);
                    });
                    if (json.TransPaymentTermsData.length === 0) {
                        json.TransPaymentTermsData = PmtTermsdataResp.map((payment_term) => {
                            payment_term.sales_order_no = keyForViewUpdate === 'copy' ? salesOrderNo : payment_term.sales_order_no;
                            payment_term.sales_order_version = keyForViewUpdate === 'copy' ? 1 : payment_term.sales_order_version;
                            payment_term.sales_order_date = dt_sales_order_date;
                            payment_term.sales_order_master_transaction_id = 0;
                            payment_term.sales_order_payment_terms_transaction_id = 0;
                            payment_term.sales_order_details_transaction_id = 0
                            return payment_term;
                        })
                    }

                    // Trans Common Terms Data
                    $("input:checkbox[name=selectCommonTerm]:checked").each(function () {
                        let findPoTermsData = SOCommonTermsData.find(item => item.common_parameters_id === parseInt($(this).val()));
                        let pmtTermId = parseInt($(this).val());
                        const poTermsData = {
                            ...commonFieldsJson,    // set all common fields.
                            sales_order_details_transaction_id: 0,
                            sales_order_terms_parameters_id: pmtTermId,
                            sales_order_terms_parameters_name: findPoTermsData.common_parameters_name,
                            sales_order_terms_parameters_value: findPoTermsData.common_parameters_value,
                            sales_order_terms_parameters_expected_value: $("#common_parameters_expected_value_" + pmtTermId).val() === "" ? findPoTermsData.common_parameters_value : $("#common_parameters_expected_value_" + pmtTermId).val(),
                            remark: findPoTermsData.remark,
                        }
                        json.TransSalesOrderTermsData.push(poTermsData);
                    });
                    if (json.TransSalesOrderTermsData.length === 0) {
                        json.TransSalesOrderTermsData = SOCommonTermsDataResp.map((common_term) => {
                            common_term.sales_order_no = keyForViewUpdate === 'copy' ? salesOrderNo : common_term.sales_order_no;
                            common_term.sales_order_version = keyForViewUpdate === 'copy' ? 1 : common_term.sales_order_version;
                            common_term.sales_order_date = dt_sales_order_date;
                            common_term.sales_order_master_transaction_id = 0;
                            common_term.sales_order_terms_transaction_id = 0;
                            common_term.sales_order_details_transaction_id = 0
                            return common_term;
                        })
                    }

                    // taxationSummaryData collection
                    for (let index = 0; index < taxationSummaryData.length; index++) {
                        const element = taxationSummaryData[index];
                        const taxationSummarygroup = {
                            ...commonFieldsJson,    // set all common fields.
                            hsn_code_id: element.hsn_id,
                            summary_taxable_amount: element.summary_taxable_amount,
                            summary_cgst_percent: element.summary_cgst_percent,
                            summary_cgst_total: element.summary_cgst_total,
                            summary_sgst_percent: element.summary_sgst_percent,
                            summary_sgst_total: element.summary_sgst_total,
                            summary_igst_percent: element.summary_igst_percent,
                            summary_igst_total: element.summary_igst_total,
                            summary_total_amount: element.summary_total_amount,
                        }
                        json.TaxSummaryData.push(taxationSummarygroup)
                    }

                    // Trans Product Properties Data
                    for (let index = 0; index < productTypePropertiesData.length; index++) {
                        const parameterDetailsRow = productTypePropertiesData[index];
                        // if (parameterDetailsRow.product_parameter_name === "COUNT") {
                        //     let getIndex = json.TransDetailData.findIndex(item => item.product_material_id === parameterDetailsRow.product_id)
                        //     if (getIndex != -1) {
                        //         json.TransDetailData[getIndex].so_sr_no = parameterDetailsRow.product_parameter_value;
                        //     }
                        // }
                        const parameterObj = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            sales_order_parameter_id: 0,
                            sales_order_master_transaction_id: 0,
                            customer_order_no: txt_customer_order_no === "" ? salesOrderNo : txt_customer_order_no,
                            // sales_order_details_transaction_id :
                            product_parameter_id: parameterDetailsRow.product_parameter_id,
                            sales_order_no: salesOrderNo,
                            sales_order_date: dt_sales_order_date,
                            sales_order_version: txt_sales_order_version,
                            product_id: parameterDetailsRow.product_id,
                            product_type_id: cmb_sales_order_type_id,
                            so_sr_no: '',
                            product_parameter_name: parameterDetailsRow.product_parameter_name,
                            product_parameter_value: parameterDetailsRow.product_parameter_value,
                            product_parameter_actual_value: parameterDetailsRow.product_parameter_actual_value,
                            product_parameter_sufix: parameterDetailsRow.product_parameter_sufix,
                            product_parameter_prefix: parameterDetailsRow.product_parameter_prefix,
                            product_parameter_calculation_type: parameterDetailsRow.product_parameter_calculation_type,
                            product_parameter_from_value: parameterDetailsRow.product_parameter_from_value,
                            product_parameter_to_value: parameterDetailsRow.product_parameter_to_value,
                            product_parameter_formula: parameterDetailsRow.product_parameter_formula,
                            remark: parameterDetailsRow.remark,
                            created_by: keyForViewUpdate === "" ? UserName : parameterDetailsRow.created_by,
                            modified_by: keyForViewUpdate === "update" ? UserName : null
                        }
                        json.TransProductParametersData.push(parameterObj)
                    }
                    // JSON Objects Array For Details Data.
                    let rm_materialsJSONArray = rmRequirementData?.map((rm_material, index) => ({
                        mt_sales_order_rm_transaction_id: rm_material.mt_sales_order_rm_transaction_id !== undefined && isApprove ? rm_material.mt_sales_order_rm_transaction_id : 0,
                        product_material_id: rm_material.product_material_id,
                        product_material_unit_id: rm_material.product_material_unit_id,
                        material_quantity: rm_material.material_quantity,
                        weight_per_unit: rm_material.weight_per_unit,
                        material_weight: rm_material.material_weight,
                        count_type: rm_material.count_type,
                        remark: rm_material.remark,
                        product_type_id: rm_material.product_type_id,
                        product_type: rm_material.product_type_name,
                        modified_by: keyForViewUpdate === "update" ? UserName : null,
                        ...commonFieldsJson     // set all common fields.
                    }));
                    json.TransRawMaterialData = rm_materialsJSONArray;

                }

                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.sales_order_no = salesOrderNo
                json.commonIds.sales_order_date = dt_sales_order_date
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                json.commonIds.sales_order_master_transaction_id = sales_order_master_transaction_id
                json.DocumentUpload = uploadedDocData;
                debugger
                // Iterate over each entry in saudaShitSummaryData and set the fields
                saudaShitSummaryData.forEach(item => {
                    item.company_id = COMPANY_ID;
                    item.sales_order_sauda_sheet_id = 0;
                    item.sales_order_no = salesOrderNo;
                    item.financial_year = FINANCIAL_SHORT_NAME;
                });

                // Now you can assign this updated data to json.SaudaShitDetails
                json.SaudaShitDetails = saudaShitSummaryData;
                console.log(`Sales Order For Save JSON: `, json);


                const formData = new FormData();
                formData.append(`MtSalesOrderData`, JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterTrading/FnAddUpdateRecord/${isApprove}`, requestOptions)
                const responce = await apicall.json();

                console.log('Save Response: ', responce)
                setHandelSuccessModal('salesOrder')
                if (responce.success === "1") {
                    const evitCache = comboDataAPiCall.current.evitCache();
                    console.log(evitCache);

                    setSuccMsg(responce.message)
                    if (responce.data.hasOwnProperty('sales_order_version') && responce.data.hasOwnProperty('sales_order_no')) {
                        setModalOrderDetails(`Sales Order No: ${responce.data.sales_order_no} and Version: ${responce.data.sales_order_version}`)
                    }

                    setSalesOrderMasterTransactionId(responce.data.sales_order_master_transaction_id)
                    setSalesOrdVer(responce.data.sales_order_version);

                    // Enable upload document functionality.
                    $('#save-btn').attr('disabled', 'disabled');
                    $('#reject-btn').attr('disabled', 'disabled');
                    $('#clearFormBtn').attr('disabled', 'disabled');

                    if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'reject') {
                        $('#uploadDocBtn').removeAttr('disabled');
                    }

                    if (keyForViewUpdate === 'approve') {
                        debugger;
                        // Initialize an empty array to store mail types
                        let checkMailArr = [];

                        // Check if agent has a valid email
                        const selectedAgentData = agentOpts.find(agent => parseInt(agent.agent_id) === parseInt(cmb_agent_id));
                        if (selectedAgentData && selectedAgentData.agent_EmailId && selectedAgentData.agent_EmailId.trim() !== '') {
                            checkMailArr.push("A");
                        }

                        // Iterate through selected customer contact persons
                        const checkboxes = $('.selectCustContactPerson:checked');
                        console.log('Checkboxes selected:', checkboxes.length);

                        checkboxes.each(function () {
                            let selectedContact_id = parseInt($(this).attr('contact_personId'));
                            const customerContactPersonDetails = custContactDetails.find(
                                contactPerson => contactPerson.customer_contact_id === selectedContact_id
                            );
                            if (customerContactPersonDetails) {
                                // Only push "C" if the email is valid
                                if (isValidEmail(customerContactPersonDetails?.cust_email_id)) {
                                    checkMailArr.push("C");
                                }
                            }
                        });

                        // Log the final checkMailArr
                        console.log('Final checkMailArr:', checkMailArr);

                        // Update state
                        SetChkAgentMail(checkMailArr);
                        setShowMailSentModal(true);
                    }
                    else {
                        const updateData = {
                            sales_order_no: responce.data.sales_order_no,
                            sales_order_version: responce.data.sales_order_version,
                            financial_year: responce.data.financial_year
                        }

                        // sessionStorage.setItem('SalesOrderDetails', JSON.stringify(updateData));
                        // sessionStorage.setItem('keyForViewUpdate', 'update');

                        setShowSuccessMsgModal(true);
                        //Rathore Said Not to generate Indent
                        // if (keyForViewUpdate === "") {
                        //     setMessageForConfirmation("Do You Want to Generate Indent...?")
                        //     setShowConfiemationModal(true)
                        // } else {
                        //     setShowSuccessMsgModal(true);
                        // }
                    }

                } else {

                    setErrMsg(responce.error);
                    setShowErrorMsgModal(true);
                }
            }
        } catch (error) {

            console.log("error: ", error)
            navigate('/Error')
        } finally {
            $('#spinner_id').hide();
        }
    }

    function checkForDuplicates(data) {
        const seenCombinations = new Set();
        for (const item of data) {
            const combination = `${item.product_material_id}_${item.count_type}`;
            if (seenCombinations.has(combination)) {
                return false;
            }
            seenCombinations.add(combination);
        }
        return true;
    }

    const FnValidateSalesOrder = async () => {
        // Validating master forms data.
        let headerDataIsValid = await validate.current.validateForm("salesOrderFormId");

        // Check combo values for customer
        if (cmb_customer_id === '0' || cmb_customer_id === '') {
            $("#error_cmb_customer_id").text("Please fill this field...!");
            $("#error_cmb_customer_id").show();
            $("#cmb_customer_id").focus();
            return false;
        }
        if (cmb_department_id === '' || cmb_department_id === '0') {
            $('#error_cmb_department').text('Please select atleast one...');
            $('#error_cmb_department').show();

            return false;
        }
        if (cmb_sub_department === '' || cmb_sub_department === '0') {
            $('#error_cmb_sub_department').text('Please select atleast one...');
            $('#error_cmb_sub_department').show();

            return false;
        }


        let footerDataIsValidate = await validate.current.validateForm("salesOrderTotalsFrm");
        if (!headerDataIsValid || !footerDataIsValidate) { return false; }

        if (custContactDetails.length > 0) {
            if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                $('#cmb_customer_city_id').focus();

                $('#error_customer_contact_persons').show();
                setErrMsg('Please select atleast one contact person.');
                setShowErrorMsgModal(true);
                return false;
            } else {
                $('#error_customer_contact_persons').hide();
            }
        }

        // Validation For DuplicateCustomer order number.
        resetGlobalQuery();
        globalQuery.columns = ['customer_order_no'];
        globalQuery.table = "mt_sales_order_master_trading";
        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: cmb_customer_id, });
        globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: txt_customer_order_no.trim(), });
        globalQuery.conditions.push({ field: "sales_order_master_transaction_id", operator: "!=", value: sales_order_master_transaction_id, });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const checkCustomerOrderNoIsAlreadyExist = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        if (checkCustomerOrderNoIsAlreadyExist.length !== 0) {
            $('#error_txt_customer_order_no').text('This customer order no is already exist...!');
            $('#error_txt_customer_order_no').show();
            $('#txt_customer_order_no').focus();
            return false;
        } else {
            $('#error_txt_customer_order_no').hide();
        }


        // Check checkbox.
        let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
        if (freightIsTaxable && ($('#cmb_freight_hsn_id').val() === '' || $('#cmb_freight_hsn_id').val() === null)) {
            $('#cmb_freight_hsn_id').removeAttr('optional', 'optional');
            $('#error_cmb_freight_hsn_id').text('Please select one...!');
            $('#error_cmb_freight_hsn_id').show();
            return false;
        }


        let salesOrderDetailsIsValid = false;
        let addedMaterials = $('#salesOrdMaterialDtlsTbl tbody tr').length;
        // checked the materials are added or not?
        if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one material.');
            setShowErrorMsgModal(true);
            return salesOrderDetailsIsValid = false;

        } else {
            //  
            // Means materials are added.
            $('#salesOrdMaterialDtlsTbl tbody tr').each(function () {
                let currentTblRow = $(this);
                let materialRate = parseFloat(currentTblRow.find('input[id^="material_rate_"]').val());
                let materialQty = parseFloat(currentTblRow.find('input[id^="material_quantity_"]').val());
                let materialDiscPercent = parseFloat(currentTblRow.find('input[id^="material_discount_percent_"]').val());
                let materialScheduleDate = currentTblRow.find('input[id^="material_schedule_date_"]').val();
                let productRecommondation = currentTblRow.find('select[id^="product_recommendation_"]').val();
                let specialRemark = currentTblRow.find('textarea[id^="special_remark_"]').val();

                ///////Validating Style, style value & style abbrevation
                let materialStyle = currentTblRow.find('select[id^="material_style_"]').val();
                let materialStyleValue = currentTblRow.find('input[id^="material_style_value_"]').val();
                let materialStyleAbbreviation = currentTblRow.find('input[id^="material_style_abbrevation_"]').val();
                let warpCrimp = currentTblRow.find('input[id^="warp_crimp_"]').val();
                let warpWaste = currentTblRow.find('input[id^="warp_waste_"]').val();
                let weftCrimp = currentTblRow.find('input[id^="weft_crimp_"]').val();
                let weftWaste = currentTblRow.find('input[id^="weft_waste_"]').val();

                if (isNaN(materialQty) || materialQty <= 0 || materialQty === '') {
                    $(currentTblRow.find('input[id^="material_quantity_"]'))[0].parentElement.dataset.tip = 'Material quantity should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="material_quantity_"]'))[0].focus();
                    return salesOrderDetailsIsValid = false;

                } else if (isNaN(materialRate) || materialRate <= 0 || materialRate === '') {
                    $(currentTblRow.find('input[id^="material_rate_"]'))[0].parentElement.dataset.tip = 'Material rate should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="material_rate_"]'))[0].focus();
                    return salesOrderDetailsIsValid = false;

                } else if (isNaN(materialDiscPercent) || materialDiscPercent === '') {
                    $(currentTblRow.find('input[id^="material_discount_percent_"]'))[0].parentElement.dataset.tip = 'Material Discount Percentage should not be blank otherwise set ...!';
                    $(currentTblRow.find('input[id^="material_discount_percent_"]'))[0].focus();
                    return salesOrderDetailsIsValid = false;

                } else if (materialScheduleDate === '' || materialScheduleDate === null || materialScheduleDate === undefined) {
                    $(currentTblRow.find('input[id^="material_schedule_date_"]'))[0].parentElement.dataset.tip = 'Please select material schdule date...!';
                    $(currentTblRow.find('input[id^="material_schedule_date_"]'))[0].focus();
                    return salesOrderDetailsIsValid = false;

                } else if (['GF', 'FF'].includes(requestfor)) {
                    if (productRecommondation === 'Organic' && specialRemark === '') {
                        $(currentTblRow.find('textarea[id^="special_remark_"]'))[0].parentElement.dataset.tip = 'Please enter the special remark...!';
                        $(currentTblRow.find('textarea[id^="special_remark_"]'))[0].focus();
                        return salesOrderDetailsIsValid = false;

                    } else if (materialStyle === '') {
                        $(currentTblRow.find('select[id^="material_style_"]'))[0].parentElement.dataset.tip = 'Please Select the Style...!';
                        $(currentTblRow.find('select[id^="material_style_"]'))[0].focus();
                        return salesOrderDetailsIsValid = false;
                    }

                    else if (materialStyle !== 'None' && materialStyleValue === '') {
                        $(currentTblRow.find('input[id^="material_style_value_"]'))[0].parentElement.dataset.tip = 'Please Enter Style Value...!';
                        $(currentTblRow.find('input[id^="material_style_value_"]'))[0].focus();
                        return salesOrderDetailsIsValid = false;
                    }

                    else if (materialStyle !== 'None' && materialStyleAbbreviation === '') {
                        $(currentTblRow.find('input[id^="material_style_abbrevation_"]'))[0].parentElement.dataset.tip = 'Please Enter Style Abbreviation...!';
                        $(currentTblRow.find('input[id^="material_style_abbrevation_"]'))[0].focus();
                        return salesOrderDetailsIsValid = false;
                    }
                    else if ( warpCrimp === 0 || warpCrimp === '' || warpCrimp === null || warpCrimp===undefined) {
                        $(currentTblRow.find('input[id^="warp_crimp_"]'))[0].parentElement.dataset.tip = 'Warp crimp should not be a zero or blank...!';
                        $(currentTblRow.find('input[id^="warp_crimp_"]'))[0].focus();
                        return salesOrderDetailsIsValid = false;
                    }
                    else if ( warpWaste === 0 || warpWaste === '' || warpWaste === null || warpWaste===undefined) {
                        $(currentTblRow.find('input[id^="warp_waste_"]'))[0].parentElement.dataset.tip = 'Warp waste should not be a zero or blank...!';
                        $(currentTblRow.find('input[id^="warp_waste_"]'))[0].focus();
                        return salesOrderDetailsIsValid = false;
                    }
                    else if ( weftCrimp === 0 || weftCrimp === '' || weftCrimp === null || weftCrimp===undefined) {
                        $(currentTblRow.find('input[id^="weft_crimp_"]'))[0].parentElement.dataset.tip = 'Weft crimp should not be a zero or blank...!';
                        $(currentTblRow.find('input[id^="weft_crimp_"]'))[0].focus();
                        return salesOrderDetailsIsValid = false;
                    }
                    else if ( weftWaste === 0 || weftWaste === '' || weftWaste === null || weftWaste===undefined) {
                        $(currentTblRow.find('input[id^="weft_waste_"]'))[0].parentElement.dataset.tip = 'Weft waste should not be a zero or blank...!';
                        $(currentTblRow.find('input[id^="weft_waste_"]'))[0].focus();
                        return salesOrderDetailsIsValid = false;
                    }
                }
                salesOrderDetailsIsValid = true;
            });

            if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && salesOrderDetailsIsValid) {

                let SchedulesIsvalid = false;
                let scheduledata = JSON.parse(localStorage.getItem('salesOrderMaterialSchedules'));
                // let scheduledata = [...scheduleDataArray]
                if (scheduledata !== null) {

                    // groupedData will return the json array with mat_id, totalQuantity, 
                    const groupedData = scheduledata.reduce((result, item) => {
                        const key = `${item.product_material_id}:${item.so_sr_no}`;
                        const { product_material_id, so_sr_no, product_material_schedule_quantity } = item;
                        // First initiallize the object
                        if (!result[key]) {
                            result[key] = { product_material_id, so_sr_no, total_product_material_schedule_quantity: 0, };
                        }
                        result[key].total_product_material_schedule_quantity += parseFloat(product_material_schedule_quantity);
                        return result;
                    }, {});

                    let groupedArray = Object.values(groupedData);
                    // console.log(groupedArray);
                    for (let detailItem = 0; detailItem < groupedArray.length; detailItem++) {
                        let groupedElement = groupedArray[detailItem];
                        let isDetailpressent = salesOrdDetailsData.find(item => item.product_id === groupedElement.product_material_id && parseInt(item.so_sr_no) === parseInt(groupedElement.so_sr_no))
                        if (isDetailpressent) {
                            if (parseFloat(isDetailpressent.material_quantity) !== parseFloat(groupedElement.total_product_material_schedule_quantity)) {
                                setErrMsg(`The schedule quantity for ${isDetailpressent.product_name} and SO_sr_no : ${isDetailpressent.so_sr_no} is not properly allocated...! (Total Quanity is ${isDetailpressent.material_quantity} and are you scheduling ${groupedElement.total_product_material_schedule_quantity}.)`)
                                setShowErrorMsgModal(true)
                                return SchedulesIsvalid = false;
                            }
                        }
                    }
                }
                let rawMaterialRequirement = true;

                $('#raw-material-requirement-table tbody tr').each(function () {
                    let currentTblRow = $(this.parentElement.parentElement.parentElement)
                    let count_type = currentTblRow.find('input[id^="count_type_"]').val();
                    // let pmtPercent = parseFloat(currentTblRow.find('input[id^="payment_percent_"]').val());
                    // let pmtExpectedDate = currentTblRow.find('input[id^="payment_expected_date_"]').val();

                    if (count_type === '') {
                        $(currentTblRow.find('input[id^="count_type_"]'))[0].parentElement.dataset.tip = 'Please enter payment milestome...!';
                        $(currentTblRow.find('input[id^="count_type_"]'))[0].focus();
                        // setPaymentTermsAccordEventKey(0);
                        rawMaterialRequirement = false;
                    }
                    // else if (isNaN(pmtPercent) || pmtPercent === '') {
                    //     $(currentTblRow.find('input[id^="payment_percent_"]'))[0].parentElement.dataset.tip = 'Payment term Percentage should not be blank otherwise set ...!';
                    //     $(currentTblRow.find('input[id^="payment_percent_"]'))[0].focus();
                    //     // setPaymentTermsAccordEventKey(0);
                    //     rawMaterialRequirement = false;
                    // }
                    let rawMaterialRequirement = checkForDuplicates(rmRequirementData);
                    if (!rawMaterialRequirement) {
                        setErrMsg(' Materials with the same count type  are duplicates...!')
                        setShowErrorMsgModal(true)
                        rawMaterialRequirement = false;
                    }

                    return rawMaterialRequirement;
                });




                let salesOrderPmtTermsIsValid = true;
                // Payment term validations
                // let selectedPmtTerms = $('#salesOrdPaymentTermsTbl tbody tr .selectPmtTerm:checked')
                // 
                // selectedPmtTerms.each(function () {
                //     let currentTblRow = $(this.parentElement.parentElement.parentElement)
                //     let pmtMilestome = currentTblRow.find('input[id^="payment_milestone_"]').val();
                //     let pmtPercent = parseFloat(currentTblRow.find('input[id^="payment_percent_"]').val());
                //     let pmtExpectedDate = currentTblRow.find('input[id^="payment_expected_date_"]').val();

                //     if (pmtMilestome === '') {
                //         $(currentTblRow.find('input[id^="payment_milestone_"]'))[0].parentElement.dataset.tip = 'Please enter payment milestome...!';
                //         $(currentTblRow.find('input[id^="payment_milestone_"]'))[0].focus();
                //         // setPaymentTermsAccordEventKey(0);
                //         salesOrderPmtTermsIsValid = false;
                //     } else if (isNaN(pmtPercent) || pmtPercent === '') {
                //         $(currentTblRow.find('input[id^="payment_percent_"]'))[0].parentElement.dataset.tip = 'Payment term Percentage should not be blank otherwise set ...!';
                //         $(currentTblRow.find('input[id^="payment_percent_"]'))[0].focus();
                //         // setPaymentTermsAccordEventKey(0);
                //         salesOrderPmtTermsIsValid = false;
                //     } else if (pmtPercent > 100) {
                //         $(currentTblRow.find('input[id^="payment_percent_"]'))[0].parentElement.dataset.tip = 'Payment term Percentage should not be greater than 100 ...!';
                //         $(currentTblRow.find('input[id^="payment_percent_"]'))[0].focus();
                //         salesOrderPmtTermsIsValid = false;
                //     } else if (pmtExpectedDate === '' || pmtExpectedDate === null || pmtExpectedDate === undefined) {
                //         $(currentTblRow.find('input[id^="payment_expected_date_"]'))[0].parentElement.dataset.tip = 'Please select payment expected schdule date...!';
                //         $(currentTblRow.find('input[id^="payment_expected_date_"]'))[0].focus();
                //         // setPaymentTermsAccordEventKey(0);
                //         salesOrderPmtTermsIsValid = false;
                //     }
                //     return salesOrderPmtTermsIsValid;
                // });

                // Common terms validations
                let salesOrderCommonTermsIsValid = true;
                if (rawMaterialRequirement) {
                    salesOrderCommonTermsIsValid = true;
                    let selectedCommonTerms = $('#commonTermsTbl tbody tr .selectCommonTerm:checked')
                    selectedCommonTerms.each(function () {
                        let currentTblRow = $(this.parentElement.parentElement.parentElement)
                        let expectedValue = currentTblRow.find('input[id^="common_parameters_expected_value_"]').val();
                        if (expectedValue === '') {
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].parentElement.dataset.tip = 'Please enter valid common parameters expected value...!';
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].focus();
                            // setCommonTermsAccordEventKey(0);
                            return salesOrderCommonTermsIsValid = false;
                        }
                    });
                    return salesOrderCommonTermsIsValid;
                }

                return salesOrderDetailsIsValid === true && salesOrderPmtTermsIsValid === true && salesOrderCommonTermsIsValid === true;

            } else if (keyForViewUpdate === 'approve' && salesOrderDetailsIsValid) {
                return true;
            } else {
                return keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && salesOrderDetailsIsValid
                // return true;
            }
        }
    }

    // Render the Details table.
    const renderDetailsTable = useMemo(() => {
        debugger
        return <>
            {
                salesOrdDetailsData.length > 0
                    ? <Table className="erp_table erp_table_scroll" id='salesOrdMaterialDtlsTbl' bordered striped hover>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''}`}>Action</th>
                                <th className="erp_table_th">Sr No.</th>
                                <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Material Name</th>
                                {
                                    ['GF', 'FF'].includes(requestfor)
                                        ? <>
                                            <th className="erp_table_th">Style</th>
                                            <th className="erp_table_th">Style Value</th>
                                            <th className="erp_table_th">Style Abbreviation</th>
                                        </>
                                        : null
                                }
                                {/* Extra required fields for the Fabric Sales order  */}
                                {
                                    ['GF', 'FF'].includes(requestfor)
                                        ? <>
                                            <th className="erp_table_th">Product Recommondation</th>
                                            {/* <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Special Remark</th> */}
                                            <th className="erp_table_th">Monogram</th>
                                            {/* <th className="erp_table_th pe-3">Weft Color</th> */}
                                        </>
                                        : null
                                }
                                <th className="erp_table_th" style={{ paddingRight: '70px' }}>Unit Name</th>
                                <th className={`erp_table_th`}>Material Quantity</th>
                                <th className="erp_table_th">Material Weight</th>
                                <th className={`erp_table_th ${rb_sales_order_creation_type !== 'Q' ? 'd-none' : ''}`}>Quotation Rate</th>
                                <th className="erp_table_th">Material Rate</th>
                                <th className="erp_table_th" style={{ display: "none" }}>Discount %</th>
                                <th className="erp_table_th" style={{ display: "none" }}>Discount Amt.</th>
                                <th className="erp_table_th" style={{ width: "auto" }}>HSN Code</th>
                                <th className="erp_table_th">HSN %</th>
                                <th className="erp_table_th">Basic Amt.</th>
                                <th className="erp_table_th">Taxable Amt.</th>
                                <th className="erp_table_th">CGST %</th>
                                <th className="erp_table_th">CGST Amt.</th>
                                <th className="erp_table_th">SGST %</th>
                                <th className="erp_table_th">SGST Amt.</th>
                                <th className="erp_table_th">IGST %</th>
                                <th className="erp_table_th">IGST Amt.</th>
                                <th className="erp_table_th">Freight Amt.</th>
                                <th className="erp_table_th">Total Amt.</th>

                                {/* //column for the calculation for sauda  */}
                                {(requestfor === "GF") && (
                                    <>
                                        {salesOrdDetailsData?.some(
                                            (item) => item.warp_count_1__wc1_ !== "" && item.warp_count_1__wc1__actual_count !== 0
                                        ) && (
                                                <th className="erp_table_th">Warp Count 1</th>
                                            )}
                                        {salesOrdDetailsData?.some(
                                            (item) => item.warp_count_2__wc2_ !== "" && item.warp_count_2__wc2__actual_count !== 0
                                        ) && (
                                                <th className="erp_table_th">Warp Count 2</th>
                                            )}
                                        {salesOrdDetailsData?.some(
                                            (item) => item.warp_count_3__wc3_ !== "" && item.warp_count_3__wc3__actual_count !== 0
                                        ) && (
                                                <th className="erp_table_th">Warp Count 3</th>
                                            )}
                                        {salesOrdDetailsData?.some(
                                            (item) => item.warp_count_4__wc4_ !== "" && item.warp_count_4__wc4__actual_count !== 0
                                        ) && (
                                                <th className="erp_table_th">Warp Count 4</th>
                                            )}
                                        {salesOrdDetailsData?.some(
                                            (item) => item.weft_count_1__fc1_ !== "" && item.weft_count_1__fc1__actual_count !== 0
                                        ) && (
                                                <th className="erp_table_th">Weft Count 1</th>
                                            )}
                                        {salesOrdDetailsData?.some(
                                            (item) => item.weft_count_2__fc2_ !== "" && item.weft_count_2__fc2__actual_count !== 0
                                        ) && (
                                                <th className="erp_table_th">Weft Count 2</th>
                                            )}
                                        {salesOrdDetailsData?.some(
                                            (item) => item.weft_count_3__fc3_ !== "" && item.weft_count_3__fc3__actual_count !== 0
                                        ) && (
                                                <th className="erp_table_th">Weft Count 3</th>
                                            )}
                                        {salesOrdDetailsData?.some(
                                            (item) => item.weft_count_4__fc4_ !== "" && item.weft_count_4__fc4__actual_count !== 0
                                        ) && (
                                                <th className="erp_table_th">Weft Count 4</th>
                                            )}
                                    </>
                                )}

                                {(requestfor === "GF") && (
                                    <>
                                        <th className="erp_table_th">EPI</th>
                                        <th className="erp_table_th">PPI</th>
                                        <th className="erp_table_th">Width</th>
                                        <th className="erp_table_th">Warp Crimp %</th>
                                        <th className="erp_table_th">Weft Crimp %</th>
                                        <th className="erp_table_th">Warp Waste %</th>
                                        <th className="erp_table_th">Weft Waste %</th>

                                    </>
                                )}



                                <th className={`erp_table_th`} style={{ width: '100px', paddingRight: '100px' }}>Technical Specification</th>
                                {/* Extra required fields for the Fabric Sales order  */}
                                {
                                    ['GF', 'FF'].includes(requestfor)
                                        ? <>
                                            <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Special Remark</th>

                                        </>
                                        : null
                                }
                                <th className="erp_table_th">Material Schedule Date</th>
                                <th className="erp_table_th" style={{ paddingRight: '20px' }}>Material Status</th>
                                <th className="erp_table_th">SO Sr No.</th>
                                <th className="erp_table_th">Remark</th>
                                <td className={`erp_table_th`}>Product Type</td>
                                <th className="erp_table_th">Packing</th>
                                <th className="erp_table_th">Std. Weight</th>
                                <th className="erp_table_th">Material ID</th>
                                <th className="erp_table_th">Taxable Amt. Per Qty.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                salesOrdDetailsData?.map((salesOrderItem, Index) =>
                                    <tr rowIndex={Index} rowSrNo={salesOrderItem.sr_no} rowSoSrNo={salesOrderItem.so_sr_no} rowMaterialId={salesOrderItem.product_id}>
                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''}`}>
                                            <div style={{ display: "flex" }}>
                                                <MdDelete className="erp-delete-btn" onClick={() => removeMaterial(salesOrderItem)} />
                                                <AiOutlineSchedule className="erp-schedule-btn" onClick={() => openSchedules(salesOrderItem)} />
                                            </div>
                                        </td>
                                        <td className="erp_table_td">{salesOrderItem.sr_no}</td>
                                        <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{salesOrderItem.product_name}</td>
                                        {
                                            ['GF', 'FF'].includes(requestfor)
                                                ? <>
                                                    <td className="erp_table_td" >
                                                        <select
                                                            id={`material_style_${salesOrderItem.product_id}`} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                            style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }} value={salesOrderItem.material_style} headers="material_style" onChange={(e) => updateSalesOrdDetailsTblData(salesOrderItem, e)} disabled={['approve', 'view'].includes(keyForViewUpdate)}>
                                                            <option disabled>Select</option>
                                                            <option value="None">None</option>
                                                            <option value="Color">Color</option>
                                                            <option value="Weave">Weave</option>
                                                            <option value="Insertion">Insertion</option>
                                                            <option value="Weave+Insertion">Weave + Insertion</option>
                                                            <option value="Color+Weave">Color + Weave</option>
                                                            <option value="Color+Insertion">Color + Insertion</option>
                                                        </select>

                                                    </td>

                                                    <td className={`erp_table_td`}>
                                                        <input type="text" className="erp_input_field text-end mb-0" id={`material_style_value_${salesOrderItem.product_id}`} value={salesOrderItem.material_style_value} maxLength='19' style={{ width: '100px', display: 'table-cell', whiteSpace: 'break-spaces' }}
                                                            Headers='material_style_value' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} disabled={['approve', 'view'].includes(keyForViewUpdate) ? true : false} />
                                                    </td>

                                                    <td className={`erp_table_td`}>
                                                        <input type="text" className="erp_input_field text-end mb-0" id={`material_style_abbrevation_${salesOrderItem.product_id}`} value={salesOrderItem.material_style_abbrevation} maxLength='19'
                                                            Headers='material_style_abbrevation' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} disabled={['approve', 'view'].includes(keyForViewUpdate) ? true : false} />
                                                    </td>

                                                </>
                                                : null
                                        }
                                        {/* Extra required fields for the Fabric Sales order  */}
                                        {
                                            ['GF', 'FF'].includes(requestfor)
                                                ? <>
                                                    {/* <td className="erp_table_td">
                                                        <select id={`product_recommendation_${salesOrderItem.product_id}`} value={salesOrderItem.product_recommendation} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                            Headers="product_recommendation" onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} disabled={['approve', 'view'].includes(keyForViewUpdate) ? true : false}  >
                                                            <option value="Normal" lbl='Normal'>Normal</option>
                                                            <option value="Organic" lbl='Organic'>Organic</option>
                                                        </select>
                                                    </td> */}

                                                    <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                                        {
                                                            <Form.Control as="textarea" id={`product_recommendation_${salesOrderItem.product_id}`} value={salesOrderItem.product_recommendation}
                                                                Headers="product_recommendation" onInput={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e) }} className="erp_input_field mb-0" disabled={['approve', 'view'].includes(keyForViewUpdate) ? true : false} />

                                                        }
                                                    </td>
                                                    {/* < td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                                        {
                                                            !['approve', 'view'].includes(keyForViewUpdate)
                                                                ? <Form.Control as="textarea" id={`special_remark_${salesOrderItem.product_id}`} value={salesOrderItem.special_remark}
                                                                    Headers="special_remark" onInput={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e) }} className="erp_input_field mb-0" />
                                                                : salesOrderItem.special_remark
                                                        }
                                                    </td> */}

                                                    <td className="erp_table_td">
                                                        <select id={`monogram_is_applicable_${salesOrderItem.product_id}`} value={salesOrderItem.monogram_is_applicable} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                            Headers="monogram_is_applicable" onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} disabled={['approve', 'view'].includes(keyForViewUpdate) ? true : false}  >
                                                            <option value="true" lbl='Yes'>Yes</option>
                                                            <option value="false" lbl='No'>No</option>
                                                        </select>
                                                    </td>

                                                    {/* <td className="erp_table_td">
                                                        <select id={`weft_color_${salesOrderItem.product_id}`} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                            value={salesOrderItem.weft_color} Headers="weft_color" onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} disabled={['approve', 'view'].includes(keyForViewUpdate) ? true : false}>
                                                            <option value="0">Add New Record+</option>
                                                            {colorOpts?.map(color => (
                                                                <option value={color.field_name}>{color.field_name}</option>
                                                            ))}
                                                        </select>
                                                    </td> */}

                                                </>
                                                : null
                                        }
                                        <td className={`erp_table_td`}>
                                            {
                                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                    ? <select id={`product_unit_name_${salesOrderItem.product_id}`} value={salesOrderItem.product_unit_id} className="form-select form-select-sm erp_input_field m-0"
                                                        Headers="product_unit_id" onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false}  >
                                                        {
                                                            productUnitOpts?.map(unit => (
                                                                <option value={unit.field_id} lbl={unit.field_name} >{unit.field_name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    : salesOrderItem.product_unit_name
                                            }
                                        </td>

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.material_quantity
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`material_quantity_${salesOrderItem.product_id}`} value={salesOrderItem.material_quantity} maxLength='19'
                                                        Headers='material_quantity' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.material_weight
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`material_weight_${salesOrderItem.product_id}`} value={salesOrderItem.material_weight} maxLength='19'
                                                        Headers='material_weight' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>

                                        <td className={`erp_table_td text-end  ${rb_sales_order_creation_type !== 'Q' ? 'd-none' : ''}`}>{salesOrderItem.quotation_rate}</td>

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.material_rate
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`material_rate_${salesOrderItem.product_id}`} value={salesOrderItem.material_rate} maxLength='19'
                                                        Headers='material_rate' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`} style={{ display: "none" }}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.material_discount_percent
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`material_discount_percent_${salesOrderItem.product_id}`} value={salesOrderItem.material_discount_percent}
                                                        Headers='material_discount_percent' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`} style={{ display: "none" }}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.material_discount_amount
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`material_discount_amount_${salesOrderItem.product_id}`} value={salesOrderItem.material_discount_amount}
                                                        Headers='material_discount_amount' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>

                                        {/* <td className="erp_table_td">{salesOrderItem.product_hsn_sac_code}</td> */}
                                        <td className="erp_table_td">
                                            <select id="product_hsn_sac_code_id_" className="form-select form-select-sm" style={{ width: "auto" }} value={salesOrderItem.product_hsn_sac_code_id} product_rm_id={`${salesOrderItem.product_id}`} Headers='product_hsn_sac_code_id'
                                                onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} disabled={(keyForViewUpdate !== '' && salesOrderItem.product_hsn_sac_code !== '0')} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {hsnCodesOptions.length !== 0 ?
                                                    <>
                                                        {hsnCodesOptions?.map(cost => (
                                                            <option value={cost.field_id} product_hsn_sac_rate={cost.hsn_sac_rate} product_hsn_sac_code={cost.field_name}>{cost.field_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                        </td>
                                        <td className="erp_table_td text-end">{salesOrderItem.product_hsn_sac_rate}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_basic_amount}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_taxable_amount}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_cgst_percent}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_cgst_total}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_sgst_percent}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_sgst_total}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_igst_percent}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_igst_total}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_freight_amount}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_total_amount}</td>

                                        {/* //column for the calculation for sauda  */}
                                        {(requestfor === "GF") && (
                                            <>
                                                {salesOrdDetailsData?.some(
                                                    (item) =>
                                                        item.warp_count_1__wc1_ !== "" &&
                                                        item.warp_count_1__wc1__actual_count !== 0
                                                ) && (
                                                        <td className="erp_table_td text-end">
                                                            {salesOrderItem.warp_count_1__wc1__actual_count}
                                                        </td>
                                                    )}
                                                {salesOrdDetailsData?.some(
                                                    (item) =>
                                                        item.warp_count_2__wc2_ !== "" &&
                                                        item.warp_count_2__wc2__actual_count !== 0
                                                ) && (
                                                        <td className="erp_table_td text-end">
                                                            {salesOrderItem.warp_count_2__wc2__actual_count}
                                                        </td>
                                                    )}
                                                {salesOrdDetailsData?.some(
                                                    (item) =>
                                                        item.warp_count_3__wc3_ !== "" &&
                                                        item.warp_count_3__wc3__actual_count !== 0
                                                ) && (
                                                        <td className="erp_table_td text-end">
                                                            {salesOrderItem.warp_count_3__wc3__actual_count}
                                                        </td>
                                                    )}
                                                {salesOrdDetailsData?.some(
                                                    (item) =>
                                                        item.warp_count_4__wc4_ !== "" &&
                                                        item.warp_count_4__wc4__actual_count !== 0
                                                ) && (
                                                        <td className="erp_table_td text-end">
                                                            {salesOrderItem.warp_count_4__wc4__actual_count}
                                                        </td>
                                                    )}
                                                {salesOrdDetailsData?.some(
                                                    (item) =>
                                                        item.weft_count_1__fc1_ !== "" &&
                                                        item.weft_count_1__fc1__actual_count !== 0
                                                ) && (
                                                        <td className="erp_table_td text-end">
                                                            {salesOrderItem.weft_count_1__fc1__actual_count}
                                                        </td>
                                                    )}
                                                {salesOrdDetailsData?.some(
                                                    (item) =>
                                                        item.weft_count_2__fc2_ !== "" &&
                                                        item.weft_count_2__fc2__actual_count !== 0
                                                ) && (
                                                        <td className="erp_table_td text-end">
                                                            {salesOrderItem.weft_count_2__fc2__actual_count}
                                                        </td>
                                                    )}
                                                {salesOrdDetailsData?.some(
                                                    (item) =>
                                                        item.weft_count_3__fc3_ !== "" &&
                                                        item.weft_count_3__fc3__actual_count !== 0
                                                ) && (
                                                        <td className="erp_table_td text-end">
                                                            {salesOrderItem.weft_count_3__fc3__actual_count}
                                                        </td>
                                                    )}
                                                {salesOrdDetailsData?.some(
                                                    (item) =>
                                                        item.weft_count_4__fc4_ !== "" &&
                                                        item.weft_count_4__fc4__actual_count !== 0
                                                ) && (
                                                        <td className="erp_table_td text-end">
                                                            {salesOrderItem.weft_count_4__fc4__actual_count}
                                                        </td>
                                                    )}
                                            </>
                                        )}
                                        {(requestfor === "GF") && (
                                            <>
                                                <td className="erp_table_td text-end">{salesOrderItem.epi_1}</td>
                                                <td className="erp_table_td text-end">{salesOrderItem.ppi_1}</td>
                                                <td className="erp_table_td text-end">{salesOrderItem.width}</td>
                                                <td className="erp_table_td">
                                                    {
                                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                            ? salesOrderItem.warp_crimp
                                                            : <input type="text" className="erp_input_field mb-0" id={`warp_crimp_${salesOrderItem.product_id}`} value={salesOrderItem.warp_crimp}
                                                                Headers='warp_crimp' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                                    }
                                                </td>
                                                <td className="erp_table_td">
                                                    {
                                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                            ? salesOrderItem.weft_crimp
                                                            : <input type="text" className="erp_input_field mb-0" id={`weft_crimp_${salesOrderItem.product_id}`} value={salesOrderItem.weft_crimp}
                                                                Headers='weft_crimp' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                                    }
                                                </td>
                                                <td className="erp_table_td">
                                                    {
                                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                            ? salesOrderItem.warp_waste
                                                            : <input type="text" className="erp_input_field mb-0" id={`warp_waste_${salesOrderItem.product_id}`} value={salesOrderItem.warp_waste}
                                                                Headers='warp_waste' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                                    }
                                                </td>
                                                <td className="erp_table_td">
                                                    {
                                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                            ? salesOrderItem.weft_waste
                                                            : <input type="text" className="erp_input_field mb-0" id={`weft_waste_${salesOrderItem.product_id}`} value={salesOrderItem.weft_waste}
                                                                Headers='weft_waste' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                                    }
                                                </td>
                                            </>
                                        )}

                                        <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{salesOrderItem.product_tech_spect}</td>

                                        {/* Extra required fields for the Fabric Sales order  */}
                                        {
                                            ['GF', 'FF'].includes(requestfor)
                                                ? <>
                                                    <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                                        {
                                                            !['approve', 'view'].includes(keyForViewUpdate)
                                                                ? <Form.Control as="textarea" id={`special_remark_${salesOrderItem.product_id}`} value={salesOrderItem.special_remark}
                                                                    Headers="special_remark" onInput={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e) }} className="erp_input_field mb-0" />
                                                                : salesOrderItem.special_remark
                                                        }
                                                    </td>
                                                </>
                                                : null
                                        }
                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    // ? salesOrderItem.material_schedule_date
                                                    ? validateNumberDateInput.current.formatDateToDDMMYYYY(salesOrderItem.material_schedule_date)
                                                    : <input type="date" className="erp_input_field mb-0" id={`material_schedule_date_${salesOrderItem.product_id}`} value={salesOrderItem.material_schedule_date} Headers='material_schedule_date' min={currentDate}
                                                        onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            <select id={`sales_order_item_status_${salesOrderItem.product_id}`} value={salesOrderItem.sales_order_item_status} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                Headers="sales_order_item_status" onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} disabled={keyForViewUpdate !== 'approve' || rb_preeclose_sales_order == '1' ? true : false}  >
                                                <option value="P" lbl='Pending'>Pending</option>
                                                <option value="A" lbl='Approved'>Approved</option>
                                                <option value="R" lbl='Rejected'>Rejected</option>
                                                <option value="Z" lbl='PreeClosed'>PreeClosed</option>

                                                {
                                                    keyForViewUpdate === 'approve'
                                                        ? ''
                                                        : <><option value="I" lbl='Partial Issue'>Partial Issue</option>
                                                            <option value="C" lbl='Completed'>Completed</option>
                                                            <option value="X" lbl='Canceled'>Canceled</option>
                                                        </>
                                                }
                                            </select>
                                        </td>


                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.so_sr_no
                                                    : <input type="text" className="erp_input_field mb-0" id={`so_sr_no_${salesOrderItem.product_id}`} value={salesOrderItem.so_sr_no}
                                                        Headers='so_sr_no' onFocus={(e) => { handleSoSrNumberChange(salesOrderItem, e); }} onChange={(e) => { handleSoSrNumberChange(salesOrderItem, e); }} onBlur={(e) => { handleSoSrNumberChange(salesOrderItem, e); }} />
                                            }
                                        </td>
                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.remark
                                                    : <input type="text" className="erp_input_field_table_txt mb-0 MaterialWiseRemark" id={`remark_${salesOrderItem.product_id}`} value={salesOrderItem.remark} Headers='remark'
                                                        onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />

                                            }
                                        </td>
                                        <td className={`erp_table_td`}>{salesOrderItem.product_type}</td>
                                        <td className="erp_table_td">{salesOrderItem.product_packing_name}</td>


                                        <td className="erp_table_td text-end">{salesOrderItem.product_std_weight}</td>
                                        <td className="erp_table_td">{salesOrderItem.product_id}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.so_rate}</td>

                                    </tr>
                                )
                            }
                        </tbody>
                    </Table >
                    : ""
            }
        </>
    }, [salesOrdDetailsData, productTypePropertiesData, colorOpts, hsnCodesOptions]);

    const renderDetailsTrackingTbl = useMemo(() => {
        return <>
            {
                salesOrdDetailsTrackingData.length > 0
                    ? <Table className="erp_table" id='salesOrdMaterialSchedulesTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className="erp_table_th">SrNo</th>
                                <th className="erp_table_th">So SrNo</th>
                                <th className="erp_table_th">Product Name</th>
                                <th className="erp_table_th">Order Qty</th>
                                <th className="erp_table_th">Order Wght</th>
                                <th className="erp_table_th">Material Status</th>
                                <th className="erp_table_th">Pending Qty</th>
                                <th className="erp_table_th">Pending Wght</th>
                                <th className="erp_table_th">Opening Qty</th>
                                <th className="erp_table_th">Opening Wght</th>
                                <th className="erp_table_th">Reserve Qty</th>
                                <th className="erp_table_th">Reserve Wght</th>
                                <th className="erp_table_th">Excess Qty</th>
                                <th className="erp_table_th">Excess Wght</th>
                                <th className="erp_table_th">Pree Closed Qty</th>
                                <th className="erp_table_th">Pree Closed Wght</th>
                                <th className="erp_table_th">Purchase Qty</th>
                                <th className="erp_table_th">Purchase Wght</th>
                                <th className="erp_table_th">Purchase Return Qty</th>
                                <th className="erp_table_th">Purchase Return Wght</th>
                                <th className="erp_table_th">Purchase Rejection Qty</th>
                                <th className="erp_table_th">Purchase Rejection Wght</th>
                                <th className="erp_table_th">Jobcard Qty</th>
                                <th className="erp_table_th">Jobcard Wght</th>
                                <th className="erp_table_th">Production Issue Qty</th>
                                <th className="erp_table_th">Production Issue Wght</th>
                                <th className="erp_table_th">Production Issue Return Qty</th>
                                <th className="erp_table_th">Production Issue Return Wght</th>
                                <th className="erp_table_th">Production Issue Rejection Qty</th>
                                <th className="erp_table_th">Production Issue Rejection Wght</th>
                                <th className="erp_table_th">Production Qty</th>
                                <th className="erp_table_th">Production Wght</th>
                                <th className="erp_table_th">Production Return Qty</th>
                                <th className="erp_table_th">Production Return Wght</th>
                                <th className="erp_table_th">Production Rejection Qty</th>
                                <th className="erp_table_th">Production Rejection Wght</th>
                                <th className="erp_table_th">Assembly Production Issue Qty</th>
                                <th className="erp_table_th">Assembly Production Issue Wght</th>
                                <th className="erp_table_th">Sales Qty</th>
                                <th className="erp_table_th">Sales Wght</th>
                                <th className="erp_table_th">Sales Return Qty</th>
                                <th className="erp_table_th">Sales Return Wght</th>
                                <th className="erp_table_th">Sales Rejection Qty</th>
                                <th className="erp_table_th">Sales Rejection Wght</th>
                                <th className="erp_table_th">Transfer Issue Qty</th>
                                <th className="erp_table_th">Transfer Issue Wght</th>
                                <th className="erp_table_th">Transfer Receipt Qty</th>
                                <th className="erp_table_th">Transfer Receipt Wght</th>
                                <th className="erp_table_th">Outsources Out Qty</th>
                                <th className="erp_table_th">Outsources Out Wght</th>
                                <th className="erp_table_th">Outsources In Qty</th>
                                <th className="erp_table_th">Outsources In Wght</th>
                                <th className="erp_table_th">Outsources Rejection Qty</th>
                                <th className="erp_table_th">Outsources Rejection Wght</th>
                                <th className="erp_table_th">Loan Receipt Qty</th>
                                <th className="erp_table_th">Loan Receipt Wght</th>
                                <th className="erp_table_th">Loan Issue Qty</th>
                                <th className="erp_table_th">Loan Issue Wght</th>
                                <th className="erp_table_th">Cancel Qty</th>
                                <th className="erp_table_th">Cancel Wght</th>
                                <th className="erp_table_th">Difference Qty</th>
                                <th className="erp_table_th">Difference Wght</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                salesOrdDetailsTrackingData?.map((tackingData, Index) =>
                                    <tr rowIndex={Index} >
                                        <td className="erp_table_td text-end">{tackingData.sr_no}</td>
                                        <td className="erp_table_td text-end">{tackingData.so_sr_no}</td>
                                        <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{tackingData.product_material_name}</td>
                                        <td className="erp_table_td text-end">{tackingData.material_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.material_weight}</td>
                                        <td className="erp_table_td">{tackingData.sales_order_item_status_desc}</td>
                                        <td className="erp_table_td text-end">{tackingData.pending_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.pending_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.opening_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.opening_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.reserve_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.reserve_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.excess_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.excess_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.pree_closed_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.pree_closed_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_return_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_return_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.jobcard_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.jobcard_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_return_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_return_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_return_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_return_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.assembly_production_issue_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.assembly_production_issue_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_return_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_return_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.transfer_issue_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.transfer_issue_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.transfer_receipt_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.transfer_receipt_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_out_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_out_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_in_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_in_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.loan_receipt_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.loan_receipt_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.loan_issue_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.loan_issue_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.cancel_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.cancel_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.difference_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.difference_weight}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ''
            }
        </>
    }, [salesOrdDetailsTrackingData]);

    const renderPaymentTermsTable = useMemo(() => {
        return <>
            {
                PmtTermsdata.length > 0
                    ? <Table className="erp_table erp_table_scroll" id='salesOrdPaymentTermsTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th`}>Action</th>
                                <th className="erp_table_th">Payment Term Name</th>
                                <th className="erp_table_th">Payment Term Days</th>
                                <th className="erp_table_th">Grace Days</th>
                                <th className={`erp_table_th`}>Payment Milestome</th>
                                <th className="erp_table_th">Payment Percentage</th>
                                <th className="erp_table_th">Payment Expected Value</th>
                                <th className="erp_table_th">Payment Expected Date</th>
                                <th className="erp_table_th">Payment Receipt Status</th>
                                <th className="erp_table_th">Payment Receipt Transaction Id</th>
                                <th className={`erp_table_th`}>Payment Receipt Date</th>
                                <th className="erp_table_th">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                PmtTermsdata?.map((paymentTerm, Index) =>
                                    <tr rowIndex={Index} paymentTermId={paymentTerm.payment_terms_id}>
                                        <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <input type="checkbox" name="selectPmtTerm" className="form-check-input selectPmtTerm" pmtTermId={paymentTerm.payment_terms_id} value={paymentTerm.payment_terms_id}
                                                    id={'selectPmtTerm_' + paymentTerm.payment_terms_id}                                                    {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                    {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                />
                                            </div>
                                        </td>
                                        <td className="erp_table_td">{paymentTerm.payment_terms_name}</td>
                                        <td className="erp_table_td text-end">{paymentTerm.payment_terms_days}</td>
                                        <td className="erp_table_td text-end">{paymentTerm.payment_terms_grace_days}</td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_terms_Milestome
                                                : <input type="text" className="erp_input_field mb-0" value={paymentTerm.payment_terms_Milestome} pmtTermId={paymentTerm.payment_terms_id} id={"payment_milestone_" + paymentTerm.payment_terms_id} maxlength='255'
                                                    Headers='payment_terms_Milestome' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_percent
                                                : <input type="text" className="erp_input_field mb-0 text-end" value={paymentTerm.payment_percent} pmtTermId={paymentTerm.payment_terms_id} id={"payment_percent_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_percent' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>


                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_expected_value
                                                : <input type="text" className="erp_input_field mb-0 text-end" value={paymentTerm.payment_expected_value} pmtTermId={paymentTerm.payment_terms_id} id={"payment_expected_value_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_expected_value' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                // ? paymentTerm.payment_expected_date
                                                ? validateNumberDateInput.current.formatDateToDDMMYYYY(paymentTerm.payment_expected_date)
                                                : <input type="date" className="erp_input_field mb-0" value={paymentTerm.payment_expected_date} min={currentDate} pmtTermId={paymentTerm.payment_terms_id} id={"payment_expected_date_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_expected_date' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            <select id={`payment_receipt_flag_${paymentTerm.payment_terms_id}`} Headers='payment_receipt_flag' disabled='disabled' value={paymentTerm.payment_receipt_flag} className="form-select form-select-sm erp_input_field_table_txt m-0">
                                                <option value="P">Pending</option>
                                                <option value="T">Partial</option>
                                                <option value="R">Receipt</option>
                                            </select>
                                        </td>

                                        <td className="erp_table_td">{paymentTerm.payment_receipt_transaction_id}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(paymentTerm.expected_schedule_date)}</td>

                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? paymentTerm.remark
                                                    : <textarea type="text" className="erp_input_field_table_txt mb-0" value={paymentTerm.remark} pmtTermId={paymentTerm.payment_terms_id} id={"payment_term_remark_" + paymentTerm.payment_terms_id}
                                                        Headers='remark' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }}>
                                                    </textarea>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ''
            }
        </>
    }, [PmtTermsdata]);

    //changes realated sauda shit

    // const renderSoudaShitSummaryTable = useMemo(() => {
    //     return (
    //         <>
    //             {saudaShitSummaryData.length > 0 ? (
    //                 <Table className="erp_table" id="salesOrdTaxationSummaryTbl" bordered striped>
    //                     <thead className="erp_table_head">
    //                         <tr>
    //                             <th className="erp_table_th">Count Type</th>
    //                             <th className="erp_table_th">Count</th>
    //                             <th className="erp_table_th">Product Id</th>
    //                             <th className="erp_table_th">Warp/Weft Crimp(%)</th>
    //                             <th className="erp_table_th">Warp/Weft Waste(%)</th>
    //                             <th className="erp_table_th">Warp/Weft (per kg)</th>
    //                             <th className="erp_table_th">Req. Order Quantity</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {saudaShitSummaryData.map((sauda, index) => (
    //                             <tr key={index}>
    //                                 <td className="erp_table_td">{sauda.count_type}</td>
    //                                 <td className="erp_table_td">{sauda.count}</td>
    //                                 <td className="erp_table_td">{sauda.product_id}</td>
    //                                 <td className="erp_table_td text-end">{sauda.warp_weft_crimp}</td>
    //                                 <td className="erp_table_td text-end">{sauda.warp_weft_waste}</td>
    //                                 <td className="erp_table_td text-end">{sauda.req_warp_and_weft_per_kg}</td>
    //                                 <td className="erp_table_td text-end">{sauda.req_order_quantity}</td>
    //                             </tr>
    //                         ))}
    //                     </tbody>
    //                 </Table>
    //             ) : (
    //                 <div>No data available</div>
    //             )}
    //         </>
    //     );
    // }, [saudaShitSummaryData]);

    const renderSoudaShitSummaryTable = useMemo(() => {
        // Calculate totals grouped by `product_id`
        const totals = saudaShitSummaryData.reduce((acc, sauda) => {
            const { product_material_code, product_id, warp_weft_crimp, warp_weft_waste, req_warp_and_weft_per_kg, req_order_quantity, count_type } = sauda;

            if (!acc[product_id]) {
                acc[product_id] = {
                    warp_weft_crimp: 0,
                    warp_weft_waste: 0,
                    req_warp_and_weft_per_kg: 0,
                    req_order_quantity: 0,
                    weft_per_kg: 0,
                    warp_per_kg: 0,
                };
            }

            acc[product_id].warp_weft_crimp += parseFloat(warp_weft_crimp || 0);
            acc[product_id].warp_weft_waste += parseFloat(warp_weft_waste || 0);
            acc[product_id].req_warp_and_weft_per_kg += validateNumberDateInput.current.decimalNumber(req_warp_and_weft_per_kg.toString(), 4) || 0;
            acc[product_id].req_order_quantity += validateNumberDateInput.current.decimalNumber(req_order_quantity.toString(), 4) || 0;

            if (count_type.startsWith("warp_count")) {
                acc[product_id].warp_per_kg += req_warp_and_weft_per_kg || 0;
            } else if (count_type.startsWith("weft_count")) {
                acc[product_id].weft_per_kg += req_warp_and_weft_per_kg || 0;
            }

            return acc;
        }, {});

        // Calculate grand totals
        const grandTotal = {
            warp_weft_crimp: 0,
            warp_weft_waste: 0,
            req_warp_and_weft_per_kg: 0,
            req_order_quantity: 0,
            weft_per_kg: 0,
            warp_per_kg: 0,
        };

        Object.values(totals).forEach((total) => {
            grandTotal.warp_weft_crimp += total.warp_weft_crimp;
            grandTotal.warp_weft_waste += total.warp_weft_waste;
            grandTotal.req_warp_and_weft_per_kg += total.req_warp_and_weft_per_kg;
            grandTotal.req_order_quantity += total.req_order_quantity;
            grandTotal.weft_per_kg += total.weft_per_kg;
            grandTotal.warp_per_kg += total.warp_per_kg;
        });

        // Render rows with total row dynamically after each product group
        const rowsWithTotals = [];
        let lastProductId = null;

        saudaShitSummaryData.forEach((sauda, index) => {
            const { product_id } = sauda;

            // If product_id changes, add total row for the previous product_id
            if (lastProductId && lastProductId !== product_id) {
                const total = totals[lastProductId];
                rowsWithTotals.push(
                    <tr key={`total-${lastProductId}`} className="total-row">
                        <td className="erp_table_td" colSpan="3" style={{ fontWeight: "bold" }}>
                            Total
                        </td>
                        {/* <td className="erp_table_td"></td> */}
                        <td className="erp_table_td text-end">{total.warp_weft_crimp.toFixed(3)}</td>
                        <td className="erp_table_td text-end">{total.warp_weft_waste.toFixed(3)}</td>
                        <td className="erp_table_td text-end">
                            {/* {`Weft/kg = ${total.weft_per_kg.toFixed(2)}, Warp/kg = ${total.warp_per_kg.toFixed(2)}, Glm = ${(total.weft_per_kg + total.warp_per_kg).toFixed(2)}`} */}
                            {`Weft/kg = ${Number(total.weft_per_kg || 0).toFixed(3)}, Warp/kg = ${Number(total.warp_per_kg || 0).toFixed(3)}, Glm = ${(Number(total.weft_per_kg || 0) + Number(total.warp_per_kg || 0)).toFixed(3)}`}

                        </td>
                        <td className="erp_table_td text-end">{total.req_order_quantity.toFixed(3)}</td>
                    </tr>
                );
            }

            // Add the current row
            rowsWithTotals.push(
                <tr key={index}>
                    <td className="erp_table_td">{sauda.count_type}</td>
                    <td className="erp_table_td">{sauda.count}</td>
                    <td className="erp_table_td">{sauda.product_material_code}</td>
                    <td className="erp_table_td text-end">{sauda.warp_weft_crimp}</td>
                    <td className="erp_table_td text-end">{sauda.warp_weft_waste}</td>
                    {/* <td className="erp_table_td text-end">{sauda.req_warp_and_weft_per_kg}</td> */}
                    <td className="erp_table_td text-end">{parseFloat(sauda.req_warp_and_weft_per_kg).toFixed(3)}</td>

                    {/* <td className="erp_table_td text-end">{sauda.req_order_quantity}</td> */}
                    <td className="erp_table_td text-end">
                        {parseFloat(sauda.req_order_quantity).toFixed(3)}
                    </td>
                </tr>
            );

            // Update lastProductId
            lastProductId = product_id;
        });

        // Add total row for the last product_id
        if (lastProductId) {
            const total = totals[lastProductId];
            rowsWithTotals.push(
                <tr key={`total-${lastProductId}`} className="total-row">
                    <td className="erp_table_td" colSpan="3" style={{ fontWeight: "bold" }}>
                        Total
                    </td>

                    <td className="erp_table_td text-end">{Number(total.warp_weft_crimp || 0).toFixed(3)}</td>
                    <td className="erp_table_td text-end">{Number(total.warp_weft_waste || 0).toFixed(3)}</td>
                    <td className="erp_table_td text-end">
                        {`Weft/kg = ${Number(total.weft_per_kg || 0).toFixed(3)}, Warp/kg = ${Number(total.warp_per_kg || 0).toFixed(3)}, Glm = ${(Number(total.weft_per_kg || 0) + Number(total.warp_per_kg || 0)).toFixed(3)}`}
                    </td>
                    <td className="erp_table_td text-end">{Number(total.req_order_quantity || 0).toFixed(3)}</td>

                </tr>
            );
        }

        // Add grand total row
        rowsWithTotals.push(
            <tr key="grand-total" className="grand-total-row" style={{ fontWeight: "bold" }}>
                <td className="erp_table_td" colSpan="3" style={{ fontWeight: "bold" }} >
                    Grand Total
                </td>
                {/* <td className="erp_table_td"></td> */}
                <td className="erp_table_td text-end" style={{ fontWeight: "bold" }}>{grandTotal.warp_weft_crimp.toFixed(3)}</td>
                <td className="erp_table_td text-end" style={{ fontWeight: "bold" }}>{grandTotal.warp_weft_waste.toFixed(3)}</td>
                <td className="erp_table_td text-end" style={{ fontWeight: "bold" }}>
                    {/* {`Weft/kg = ${grandTotal.weft_per_kg.toFixed(2)}, Warp/kg = ${grandTotal.warp_per_kg.toFixed(2)}, Glm = ${(grandTotal.weft_per_kg + grandTotal.warp_per_kg).toFixed(2)}`} */}
                    {`Weft/kg = ${Number(grandTotal.weft_per_kg || 0).toFixed(3)}, Warp/kg = ${Number(grandTotal.warp_per_kg || 0).toFixed(3)}, Glm = ${(Number(grandTotal.weft_per_kg || 0) + Number(grandTotal.warp_per_kg || 0)).toFixed(3)}`}

                </td>
                <td className="erp_table_td text-end" style={{ fontWeight: "bold" }}>{grandTotal.req_order_quantity.toFixed(3)}</td>
            </tr>
        );

        return (
            <>
                {saudaShitSummaryData.length > 0 ? (
                    <Table className="erp_table" id="salesOrdTaxationSummaryTbl" bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className="erp_table_th">Count Type</th>
                                <th className="erp_table_th">Count</th>
                                <th className="erp_table_th">Sort No</th>
                                <th className="erp_table_th">Warp/Weft Crimp(%)</th>
                                <th className="erp_table_th">Warp/Weft Waste(%)</th>
                                <th className="erp_table_th">Warp/Weft (per kg)</th>
                                <th className="erp_table_th">Req. Order Quantity</th>
                            </tr>
                        </thead>
                        <tbody>{rowsWithTotals}</tbody>
                    </Table>
                ) : (
                    <div className='row text-center'>
                        <div className="col-12">
                            <span className="erp_validation text-center" fontWeight="regular" color="error">
                                No Records Found...!
                            </span>
                        </div>
                    </div>
                )}
            </>
        );
    }, [saudaShitSummaryData]);






    // Render the  Common terms table.
    const renderCommonTermsTable = useMemo(() => {
        return <>
            {
                SOCommonTermsData.length > 0
                    ? <Table className="erp_table" id='salesOrdCommonTermsTbl' bordered striped>
                        <thead className="erp_table_head">

                            <tr>
                                <th className={`erp_table_th`}>Action</th>
                                {/* <th className="erp_table_th">Common Parameters Master </th> */}
                                <th className="erp_table_th">Technical Parameters Term </th>
                                <th className="erp_table_th">Technical Parameters Value</th>
                                <th className="erp_table_th">Technical Prameters Expected Value</th>
                                <th className="erp_table_th">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                SOCommonTermsData?.map((commonTerm, Index) =>
                                    <tr rowIndex={Index} commonTermId={commonTerm.common_parameters_id}>
                                        <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <input type="checkbox" className="selectCommonTerm form-check-input" name="selectCommonTerm" value={commonTerm.common_parameters_id}
                                                    commonTermId={commonTerm.common_parameters_id} id={`selectCommonTerm_${commonTerm.common_parameters_id}`}
                                                    {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                />
                                            </div>
                                        </td>

                                        {/* <td className="erp_table_td">{commonTerm.common_parameters_master_name}</td> */}
                                        <td className="erp_table_td">{commonTerm.common_parameters_name}</td>
                                        <td className="erp_table_td">{commonTerm.common_parameters_value}</td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? commonTerm.common_parameters_expected_value
                                                : <input type="text" className="erp_input_field mb-0" id={`common_parameters_expected_value_${commonTerm.common_parameters_id}`}
                                                    Headers='common_parameters_expected_value' value={commonTerm.common_parameters_expected_value} onChange={(e) => { updateSalesOrdCommonTermsTblData(commonTerm, e) }}
                                                />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? commonTerm.remark
                                                    : <textarea type="text" className="erp_input_field mb-0" id={`remark_${commonTerm.common_parameters_id}`}
                                                        Headers='remark' value={commonTerm.remark} onChange={(e) => { updateSalesOrdCommonTermsTblData(commonTerm, e) }}></textarea>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [SOCommonTermsData]);

    // Render the  Taxation Summary table.
    const renderTaxationSummaryTable = useMemo(() => {
        return <>
            {
                taxationSummaryData.length > 0
                    ? <Table className="erp_table" id='salesOrdTaxationSummaryTbl' bordered striped>
                        <thead className="erp_table_head">

                            <tr>
                                <th className="erp_table_th">HSN Sac Code </th>
                                <th className="erp_table_th">Taxable Amount Total </th>
                                <th className="erp_table_th">CGST Percentage</th>
                                <th className="erp_table_th">CGST Amount Total</th>
                                <th className="erp_table_th">SGST Percentage</th>
                                <th className="erp_table_th">SGST Amount Total</th>
                                <th className="erp_table_th">IGST Percentage</th>
                                <th className="erp_table_th">IGST Amount Total</th>
                                <th className="erp_table_th">Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                taxationSummaryData?.map((taxSummary, Index) =>
                                    <tr rowIndex={Index} hsnId={taxSummary.common_parameters_id}>
                                        <td className="erp_table_td">{taxSummary.hsn_sac_code}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_taxable_amount}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_cgst_percent}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_cgst_total}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_sgst_percent}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_sgst_total}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_igst_percent}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_igst_total}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_total_amount}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [taxationSummaryData]);

    // ********************************************* Material Scheduling Functionality Starts.************************************************************
    const [showSchedule, setSchedules] = useState(false);
    const handleCloseSchedulesPopup = () => {
        setSchedules(false);
        setSchedulesTblDataForPopUpModal([]);
    }

    const [scheduleRowCounter, setScheduleRowCounter] = useState(1);
    const [schedulesTblDataForPopUpModal, setSchedulesTblDataForPopUpModal] = useState([]);
    const materialScheduleBlankObj = {
        product_id: '',
        product_name: '',
        material_quantity: 0,
        material_weight: 0,
        so_sr_no: 0,
        product_material_id: 0,
        product_material_unit_id: 0,
        product_material_schedule_quantity: 0,
        product_material_schedule_weight: 0,
        expected_schedule_date: dt_overall_schedule_date,
        product_material_issue_quantity: 0,
        product_material_issue_weight: 0,
        material_issue_date: '',
        sales_order_schedules_trading_item_status: 'P',
        sales_order_schedules_trading_item_status_desc: 'Pending',
        remark: '',
    }

    useLayoutEffect(() => {
        const existingSchedulesTblDataForPopUpModal = [...schedulesTblDataForPopUpModal]
        existingSchedulesTblDataForPopUpModal.push(materialScheduleBlankObj)
        setSchedulesTblDataForPopUpModal(existingSchedulesTblDataForPopUpModal)
    }, [scheduleRowCounter]);

    const openSchedules = (currentMaterial) => {
        let product_id = currentMaterial.product_id;
        let product_name = currentMaterial.product_name;
        let material_quantity = currentMaterial.material_quantity;
        let unit_id = currentMaterial.product_unit_id;
        let unit_name = currentMaterial.product_unit_name;
        let std_weight = currentMaterial.product_std_weight;
        let material_weight = currentMaterial.material_weight;
        let sheduleStatus = currentMaterial.sales_order_item_status;
        let so_srNo = currentMaterial.so_sr_no;

        if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve') {
            setSchedules(true);
            setTimeout(() => {
                $('#productMatName').append(product_name);
                $('#productMatName').attr('product_id', product_id);
                $('#soSrNo').append(so_srNo);
                $('#soSrNo').attr('soSrNo', so_srNo);
                $('#productMatUnit').append(unit_name);
                $('#productMatUnit').attr('product_material_unit_id', unit_id);
                $('#productMatStdWeight').append(std_weight);
                $('#productMatQty').append(material_quantity);
                $('#productMatQty').attr('material_quantity', material_quantity);
                $('#productMatTotalWeight').append(material_weight);
                $('#productMatTotalWeight').attr('material_weight', material_weight);

                let updatedScheduleDataArray = JSON.parse(localStorage.getItem('salesOrderMaterialSchedules'));
                let filteredSchedule = [];
                if (updatedScheduleDataArray !== null) {
                    filteredSchedule = updatedScheduleDataArray.filter(item => parseInt(item.so_sr_no) === parseInt(so_srNo) && item.product_material_id === product_id);
                }

                if (filteredSchedule.length !== 0) {
                    setSchedulesTblDataForPopUpModal(filteredSchedule);
                } else {
                    const existingSchedulesTblDataForPopUpModal = []
                    existingSchedulesTblDataForPopUpModal[0] = materialScheduleBlankObj;
                    existingSchedulesTblDataForPopUpModal[0].product_material_schedule_quantity = material_quantity
                    existingSchedulesTblDataForPopUpModal[0].product_material_schedule_weight = material_weight
                    existingSchedulesTblDataForPopUpModal[0].remark = ''
                    setSchedulesTblDataForPopUpModal(existingSchedulesTblDataForPopUpModal)
                }
            }, 100)
        }
    }

    const renderScheduleTblForPopupModal = useMemo(() => {
        return <> <Table className="erp_table" id="scheduleTable" bordered striped hover>
            <thead className='erp_table_head'>
                <tr>
                    <th className="erp_table_th" style={{ paddingRight: '10px' }}>Action</th>
                    <th className="erp_table_th">Schedule Quantity</th>
                    <th className="erp_table_th">Schedule Weight</th>
                    <th className="erp_table_th">Expected Schedule Date</th>
                    <th className="erp_table_th">Issue Quantity</th>
                    <th className="erp_table_th">Issue Weight</th>
                    <th className="erp_table_th">Issue Date</th>
                    <th className="erp_table_th">Material Shedule Status</th>
                    <th className="erp_table_th" style={{ paddingRight: '190px' }}>Remark</th>
                </tr>
            </thead>
            <tbody className="erp_table_td">
                {
                    schedulesTblDataForPopUpModal?.map((schedule, index) =>
                        <tr rowIndex={index} scheduleTrCount={index}>
                            <td>
                                <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => { FnRemoveScheduleRowForPopUpModal(index); }} />
                                <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => { setScheduleRowCounter(scheduleRowCounter + 1) }} />
                            </td>
                            <td className="erp_table_td ">
                                <Form.Control type="text" id={`scheduleQty-${index}`} className="erp_input_field text-end"
                                    value={schedule.product_material_schedule_quantity}
                                    Headers="product_material_schedule_quantity" maxLength='19'
                                    onInput={(e) => { FnUpdateScheduleTblDataForPopUpModal(schedule, e) }} />
                            </td>
                            <td className="erp_table_td ">
                                <Form.Control type="text" id={`scheduleWt-${index}`} className="erp_input_field text-end"
                                    value={schedule.product_material_schedule_weight}
                                    Headers="product_material_schedule_weight" maxLength='19'
                                    onInput={(e) => { FnUpdateScheduleTblDataForPopUpModal(schedule, e) }} />
                            </td>
                            <td className="erp_table_td ">
                                <Form.Control type="date" id={`scheduleDate-${index}`} className="erp_input_field"
                                    value={schedule.expected_schedule_date}
                                    Headers="expected_schedule_date" min={currentDate}
                                    onChange={(e) => { FnUpdateScheduleTblDataForPopUpModal(schedule, e); }} />
                            </td>
                            <td className="erp_table_td ">
                                <Form.Control type="text" id={`mtRecptQty-${index}`} className="erp_input_field text-end"
                                    value={schedule.product_material_issue_quantity} disabled='disabled'
                                    Headers="product_material_issue_quantity" maxLength='19'
                                    onInput={(e) => { FnUpdateScheduleTblDataForPopUpModal(schedule, e) }} />
                            </td>
                            <td className="erp_table_td ">
                                <Form.Control type="text" id={`mtRecptWt-${index}`} className="erp_input_field text-end"
                                    value={schedule.product_material_issue_weight} disabled='disabled'
                                    Headers="product_material_issue_weight" maxLength='19'
                                    onInput={(e) => { FnUpdateScheduleTblDataForPopUpModal(schedule, e) }} />
                            </td>
                            <td className="erp_table_td ">
                                <Form.Control type="date" id={`mtRecptDate-${index}`} className="erp_input_field"
                                    value={schedule.material_issue_date} disabled='disabled'
                                    Headers="material_issue_date"
                                    onChange={(e) => { FnUpdateScheduleTblDataForPopUpModal(schedule, e); }} />
                            </td>
                            <td className="erp_table_td ">
                                <select rowIndex={`${index}`} id={`mtScheduleStatus-${index}`} disabled='disabled' class="form-select form-select-sm erp_input_field_table_txt m-0">
                                    <option value="P">Pending</option>
                                    <option value="A">Approved</option>
                                    <option value="R">Rejected</option>
                                    <option value="I">Partial Issue</option>
                                    <option value="C">Completed</option>
                                    <option value="X">Canceled</option>
                                    <option value="O">PO</option>
                                    <option value="G">GRN</option>
                                </select>
                            </td>
                            <td className="erp_table_td ">
                                <Form.Control as="textarea" id={`remark-${index}`} value={schedule.remark}
                                    Headers="remark" onInput={(e) => { FnUpdateScheduleTblDataForPopUpModal(schedule, e) }}
                                    className="erp_input_field mb-0" maxLength="1000" />
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
        </>
    }, [schedulesTblDataForPopUpModal, scheduleRowCounter]);

    const FnRemoveScheduleRowForPopUpModal = (indexToRemove) => {
        if (indexToRemove !== 0) {
            const updatedTblData = schedulesTblDataForPopUpModal.filter((item, index) => index !== indexToRemove);
            setSchedulesTblDataForPopUpModal(updatedTblData);
        } else {
            const existingSchedulesTblDataForPopUpModal = [...schedulesTblDataForPopUpModal]
            existingSchedulesTblDataForPopUpModal[0] = { ...materialScheduleBlankObj }
            setSchedulesTblDataForPopUpModal(existingSchedulesTblDataForPopUpModal)
        }
    }

    const FnUpdateScheduleTblDataForPopUpModal = (currentSchedule, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        switch (clickedColName) {
            case 'product_material_schedule_quantity':
                if (event._reactName === 'onBlur' && event.target.value === '') {
                    currentSchedule[clickedColName] = 0;
                    currentSchedule['product_material_schedule_weight'] = 0;
                } else {
                    let materialStdWt = isNaN(parseFloat($('#productMatStdWeight').text().trim())) === NaN ? 0 : parseFloat($('#productMatStdWeight').text().trim());

                    currentSchedule[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4) || 0;
                    let material_weight = parseFloat(currentSchedule[clickedColName]) * parseFloat(materialStdWt);
                    currentSchedule['product_material_schedule_weight'] = validateNumberDateInput.current.decimalNumber(material_weight.toString(), 4);
                }

                break;

            case 'product_material_schedule_weight':
                // case 'product_material_issue_quantity':
                // case 'product_material_issue_weight':
                currentSchedule[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4) || 0;
                break;

            case 'expected_schedule_date':
            // case 'material_issue_date':
            case 'remark':
                currentSchedule[clickedColName] = event.target.value;
                break;

            default:
                break;
        }

        const updatedSchedulesTblDataForPopUpModal = [...schedulesTblDataForPopUpModal]
        const currentScheduleRowIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedSchedulesTblDataForPopUpModal[currentScheduleRowIndex] = currentSchedule;
        setSchedulesTblDataForPopUpModal(updatedSchedulesTblDataForPopUpModal)
    };


    const FnValidateSchedules = () => {
        let result = true;
        const table = document.getElementById('scheduleTable');
        const tbody = table.querySelector('tbody');
        const trElements = tbody.querySelectorAll('tr');
        let addedQty = 0;

        for (let count = 0; count < trElements.length; count++) {
            let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');
            let scheduleQty = $('#scheduleQty-' + scheduleTrCount).val();
            let scheduleQtyinp = document.querySelector('#scheduleQty-' + scheduleTrCount);
            addedQty += parseFloat(scheduleQty);
            if (scheduleQty === '' || (parseFloat(scheduleQty) === 0 || parseFloat(scheduleQty) < 0)) {
                scheduleQtyinp.parentElement.dataset.tip = 'Please enter valid quantity!...';
                return false;
            } else { delete scheduleQtyinp.parentElement.dataset.tip; }

            const scheduleDate = $('#scheduleDate-' + scheduleTrCount).val();
            const scheduleDateinp = document.querySelector('#scheduleDate-' + scheduleTrCount);
            if (scheduleDate === '') {
                scheduleDateinp.parentElement.dataset.tip = 'Please enter schedule date!...';
                return false;
            } else { delete scheduleDateinp.parentElement.dataset.tip; }
        }

        addedQty = validateNumberDateInput.current.decimalNumber((addedQty).toString(), 4);
        let totalQty = validateNumberDateInput.current.decimalNumber(($('#productMatQty').text()).toString(), 4);

        if (addedQty > totalQty) {
            setErrMsg('Schedule quantity not more than Product material quantity!...')
            setShowErrorMsgModal(true)
            return false;
        } else if (addedQty < totalQty) {
            setErrMsg('Schedule quantity not less than Product material quantity!...')
            setShowErrorMsgModal(true)
            return false;
        }
        return result;
    }

    function schedule() {
        let checkIsValidated = FnValidateSchedules();
        if (checkIsValidated === true) {
            const table = document.getElementById('scheduleTable');
            const tbody = table.querySelector('tbody');
            const trElements = tbody.querySelectorAll('tr');
            let updatedScheduleDataArray = [...scheduleDataArray];

            let soSrNo = parseInt($('#soSrNo').attr('soSrNo'));
            let materialId = $('#productMatName').attr('product_id');

            // This will check if already exist
            updatedScheduleDataArray = updatedScheduleDataArray.filter(
                item => parseInt(item.so_sr_no) !== parseInt(soSrNo) || item.product_material_id !== materialId
            );

            for (let count = 0; count < trElements.length; count++) {
                let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');
                const scheduleData = {
                    id: $('#productMatName').attr('product_id') + ':' + parseInt($('#soSrNo').attr('soSrNo')) + ":" + count,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    sales_order_master_transaction_id: 0,
                    sales_order_details_transaction_id: 0,
                    product_name: $('#productMatName').text(),
                    sales_order_no: $('#txt_sales_order_no').val(),
                    sales_order_date: $('#dt_sales_order_date').val(),
                    sales_order_version: $('#txt_sales_order_version').val(),
                    customer_order_no: txt_customer_order_no === "" ? txt_sales_order_no : txt_customer_order_no,
                    customer_order_Date: dt_customer_order_Date === "" ? dt_sales_order_date : dt_customer_order_Date,
                    material_quantity: $('#productMatQty').attr('material_quantity'),
                    material_weight: $('#productMatTotalWeight').attr('material_weight'),
                    product_type_id: $('#cmb_sales_order_type_id').val(),
                    product_type: $('#cmb_sales_order_type_id option:selected').attr('lbl'),
                    so_sr_no: parseInt($('#soSrNo').attr('soSrNo')),
                    product_material_id: $('#productMatName').attr('product_id'),
                    product_material_unit_id: $('#productMatUnit').attr('product_material_unit_id'),
                    product_material_schedule_quantity: parseFloat($('#scheduleQty-' + scheduleTrCount).val()),
                    product_material_schedule_weight: parseFloat($('#scheduleWt-' + scheduleTrCount).val()),
                    expected_schedule_date: $('#scheduleDate-' + scheduleTrCount).val(),
                    product_material_issue_quantity: $('#mtRecptQty-' + scheduleTrCount).val(),
                    product_material_issue_weight: $('#mtRecptWt-' + scheduleTrCount).val(),
                    material_issue_date: $('#mtRecptDate-' + scheduleTrCount).val(),
                    sales_order_schedules_trading_item_status: $('#mtScheduleStatus-' + scheduleTrCount).val(),
                    sales_order_schedules_trading_item_status_desc: $(`#mtScheduleStatus-${scheduleTrCount} option:selected`).text(),
                    remark: $('#remark-' + scheduleTrCount).val(),
                    created_by: UserName
                }

                // Add the new data to the updatedScheduleDataArray
                updatedScheduleDataArray.push(scheduleData);
            }

            updatedScheduleDataArray.sort((material1, material2) => material1.product_material_id - material2.product_material_id);
            setScheduleDataArray(updatedScheduleDataArray);
            setSchedulesTblDataForPopUpModal([]);
            localStorage.setItem('salesOrderMaterialSchedules', JSON.stringify(updatedScheduleDataArray));
            setSchedules(false)
        }
    }

    // For accordion table Render the Details schedules table.
    const renderSchedulesTable = useMemo(() => {
        return <>
            {
                scheduleDataArray.length > 0
                    ? <Table className="erp_table" id='salesOrdMaterialSchedulesTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th`}>Action</th>
                                <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Material Name</th>
                                <th className="erp_table_th">SO Sr No.</th>
                                <th className="erp_table_th">Sales Order No.</th>
                                <th className={`erp_table_th`}>Sales Order Date</th>
                                <th className="erp_table_th">Sales Order Version</th>
                                <th className="erp_table_th">Scheduled Quantity</th>
                                <th className="erp_table_th">Scheduled Weight</th>
                                <th className="erp_table_th">Expected Schedule Date</th>
                                <th className="erp_table_th">Scheduled Item Status</th>
                                <th className={`erp_table_th`}>Receipt Quanity</th>
                                <th className="erp_table_th">Receipt Weight</th>
                                <th className="erp_table_th">Receipt Date</th>
                                <th className="erp_table_th">Remark</th>
                                <th className="erp_table_th">Material ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                scheduleDataArray?.map((schedule, Index) =>
                                    <tr rowIndex={Index} scheduleMaterialId={schedule.product_material_id} scheduleMaterialSoSrNo={schedule.so_sr_no}>
                                        {/* ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''} */}
                                        <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <MdDelete className="erp-delete-btn" id={`schedule_${schedule.id}`} onClick={() => deleteSchedules(schedule.id)} />
                                            </div>
                                        </td>
                                        <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{schedule.product_name}</td>
                                        <td className="erp_table_td text-end">{schedule.so_sr_no}</td>
                                        <td className="erp_table_td">{schedule.sales_order_no}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(schedule.sales_order_date)}</td>
                                        <td className="erp_table_td text-end">{schedule.sales_order_version}</td>
                                        <td className="erp_table_td text-end">{schedule.product_material_schedule_quantity}</td>
                                        <td className="erp_table_td text-end">{schedule.product_material_schedule_weight}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(schedule.expected_schedule_date)}</td>
                                        <td className="erp_table_td">{schedule.sales_order_schedules_trading_item_status_desc}</td>

                                        <td className="erp_table_td text-end">{schedule.product_material_issue_quantity}</td>
                                        <td className="erp_table_td text-end">{schedule.product_material_issue_weight}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(schedule.material_issue_date)}</td>
                                        <td className="erp_table_td">{schedule.remark}</td>
                                        <td className="erp_table_td">{schedule.product_material_id}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ''
            }
        </>
    }, [scheduleDataArray, showSchedule]);

    // For accordion table.
    const deleteSchedules = (idToRemove) => {
        if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve') {
            // let updatedScheduleDataArray = [...scheduleDataArray]
            let scheduleData = JSON.parse(localStorage.getItem('salesOrderMaterialSchedules')) === null ? [] : JSON.parse(localStorage.getItem('salesOrderMaterialSchedules'));
            const { updateSchedulesData, scheduleToRemove } = scheduleData.reduce(
                (result, schedule) => {
                    if (schedule.id !== idToRemove) { result.updateSchedulesData.push(schedule); }
                    else { result.scheduleToRemove = schedule; }
                    return result;
                },
                { updateSchedulesData: [], scheduleToRemove: null }
            );

            const [materialId, materialSoSrNo] = idToRemove.split(':').map(Number);
            const simillarMaterialSchedule = updateSchedulesData.filter(item => item.so_sr_no === materialSoSrNo && item.product_material_id === materialId);
            if (simillarMaterialSchedule.length > 0) {
                let maxSoSrNo = simillarMaterialSchedule.reduce((max, schedule) => Math.max(max, schedule.so_sr_no), -Infinity);
                const lastScheduleForSimilarMat = updateSchedulesData.findIndex(item => item.product_material_id === materialId && item.so_sr_no === maxSoSrNo);
                if (lastScheduleForSimilarMat !== -1) {
                    // validateNumberDateInput.current.decimalNumber(e.target.value, 4)
                    let updatedQty = updateSchedulesData[lastScheduleForSimilarMat].product_material_schedule_quantity + scheduleToRemove.product_material_schedule_quantity;
                    let updatedWt = updateSchedulesData[lastScheduleForSimilarMat].product_material_schedule_weight + scheduleToRemove.product_material_schedule_weight;

                    updateSchedulesData[lastScheduleForSimilarMat].product_material_schedule_quantity = validateNumberDateInput.current.decimalNumber((updatedQty).toString(), 4)
                    updateSchedulesData[lastScheduleForSimilarMat].product_material_schedule_weight = validateNumberDateInput.current.decimalNumber((updatedWt).toString(), 4)
                }
            }
            setScheduleDataArray(updateSchedulesData)
            localStorage.setItem('salesOrderMaterialSchedules', JSON.stringify(updateSchedulesData));
        }
    }

    // *********************************************  Material Scheduling Functionality Starts.************************************************************

    // ********************************************* Export Imports Functionality Starts. *****************************************************************
    // Column Names for sales order table.
    let requiredOrderTblCols = ['sr_no', 'so_sr_no', 'product_type', 'material_name', 'material_technical_specification', 'material_unit_name', 'material_packing',
        'material_hsn_code', 'material_hsn_rate', 'material_std_weight', 'material_quantity', 'material_weight',
        'quotation_rate', 'material_rate', 'material_discount_percent', 'material_discount_amount', 'material_basic_amount', 'material_taxable_amount',
        'material_cgst_percent', 'material_cgst_total', 'material_sgst_percent', 'material_sgst_total', 'material_igst_percent', 'material_igst_total', 'material_freight_amount',
        'material_total_amount', 'material_schedule_date', 'sales_order_item_status', 'remark',
        'material_id', 'taxable_amount_per_qty', 'product_type_id', 'product_unit_id', 'product_packing_id', 'product_hsn_sac_code_id',
    ]

    const ExportToExcel = async () => {
        let headerFormIsValid = await validate.current.validateForm("salesOrderFormId");

        if (salesOrdDetailsData.length !== 0 && headerFormIsValid) {
            let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] } // , 25 for remark;

            let filtrKeyValue = {};

            filtrKeyValue['0'] = "Order Creation Type : " + $("input[name='rb_sales_order_creation_type']:checked").attr("lbl") + "(" + rb_sales_order_creation_type + ")"
            // filtrKeyValue['1'] = "Order Type : " + 'Trading' + "(" + cmb_sales_order_type_id + ")"
            filtrKeyValue['1'] = "Order Type : " + $('#cmb_sales_order_type_id').find(":selected").text() + "(" + cmb_sales_order_type_id + ")"
            filtrKeyValue['2'] = "Sales Order No. : " + txt_sales_order_no
            filtrKeyValue['3'] = "Order Version : " + txt_sales_order_version
            filtrKeyValue['4'] = "Order Date : " + (dt_sales_order_date !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(dt_sales_order_date) : '')
            filtrKeyValue['5'] = "Order Life : " + $("input[name='rb_sales_order_life']:checked").attr("lbl") + "(" + rb_sales_order_life + ")"
            filtrKeyValue['6'] = "Order Status : " + $('#cmb_sales_order_status').find(":selected").text() + "(" + cmb_sales_order_status + ")"
            filtrKeyValue['7'] = "Customer : " + $('#cmb_customer_id').find(":selected").text() + "(" + cmb_customer_id + ")"
            filtrKeyValue['8'] = "Customer Order No. : " + txt_customer_order_no
            filtrKeyValue['9'] = "Customer Order Date : " + (dt_customer_order_Date !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(dt_customer_order_Date) : dt_sales_order_date)
            filtrKeyValue['10'] = "Quotation No. : " + ($('#cmb_sales_quotation_no').find(":selected").text() !== 'Select' ? $('#cmb_sales_quotation_no').find(":selected").text() : '');
            filtrKeyValue['11'] = "Quotation Date : " + (dt_sales_quotation_date !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(dt_sales_quotation_date) : '')
            filtrKeyValue['12'] = "Company Branch : " + $('#cmb_companyBranchId').find(":selected").text() + "(" + cmb_companyBranchId + ")"
            filtrKeyValue['13'] = "Company State : " + $('#cmb_companyBranchStateId').find(":selected").text() + "(" + cmb_companyBranchStateId + ")"
            filtrKeyValue['14'] = "Company City : " + $('#cmb_companyBranchCityId').find(":selected").text() + "(" + cmb_companyBranchCityId + ")"
            filtrKeyValue['15'] = "Department : " + (cmb_department_id === '0' || cmb_department_id === '' || cmb_department_id === null ? '' : $('#cmb_department_id').find(":selected").text() + "(" + cmb_department_id + ")")
            filtrKeyValue['16'] = "Approved By : " + (cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")")
            filtrKeyValue['17'] = "Approved Date : " + (dt_approved_date === null ? '' : validateNumberDateInput.current.formatDateToDDMMYYYY(dt_approved_date))
            filtrKeyValue['18'] = "Expected Schedule Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_overall_schedule_date)
            filtrKeyValue['19'] = "Consignee : " + (cmb_consignee_id === '0' || cmb_consignee_id === '' ? '' : $('#cmb_consignee_id').find(":selected").text() + "(" + cmb_consignee_id + ")")
            filtrKeyValue['20'] = "Consignee State : " + (cmb_consignee_state_id === '0' || cmb_consignee_state_id === '' ? '' : $('#cmb_consignee_state_id').find(":selected").text() + "(" + cmb_consignee_state_id + ")")
            filtrKeyValue['21'] = "Consignee City : " + (cmb_consignee_city_id === '0' || cmb_consignee_city_id === '' ? '' : $('#cmb_consignee_city_id').find(":selected").text() + "(" + cmb_consignee_city_id + ")")
            filtrKeyValue['22'] = "Remark : " + txt_remark
            filtrKeyValue['23'] = "Order IsActive : " + $("input[name='rb_is_active']:checked").attr("lbl") + "(" + rb_is_active + ")"

            jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;

            const editableColumnNames = ['material_quantity', 'material_weight', 'material_rate', 'material_discount_percent', 'material_discount_amount'];
            // set columns name.
            for (let colIndex = 0; colIndex < requiredOrderTblCols.length; colIndex++) {
                jsonToExportExcel.columns.push({ "Headers": requiredOrderTblCols[colIndex], "accessor": requiredOrderTblCols[colIndex] })
                if (editableColumnNames.includes(requiredOrderTblCols[colIndex])) {
                    jsonToExportExcel.editable_cols.push(colIndex);
                }
            }

            // set table data.   // Create a new object with the updated key name
            salesOrdDetailsData.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    material_name: orderDetail.product_name,
                    material_technical_specification: orderDetail.product_tech_spect,
                    material_unit_name: orderDetail.product_unit_name,
                    material_packing: orderDetail.product_packing_name,
                    material_hsn_code: orderDetail.product_hsn_sac_code,
                    material_hsn_rate: orderDetail.product_hsn_sac_rate,
                    material_std_weight: orderDetail.product_std_weight,
                    material_id: orderDetail.product_id,
                    taxable_amount_per_qty: orderDetail.so_rate,
                };
                // keysConvertedOrderDetail['material_schedule_date'] = await validateNumberDateInput.current.formatDateToDDMMYYYY(orderDetail.material_schedule_date)
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            });

            jsonToExportExcel['headings']['ReportName'] = "Sales Order Trading Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_sales_order_no + "@" + COMPANY_ID)

        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
    }

    const onImportClick = () => {
        importFile.current.click();
    };

    const importedExcelFile = async (event) => {
        try {
            if (event.target.files) {
                $('#spinner_id').show();
                $('#spinner_text').text('Loading...');
                let file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterTrading/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();

                if (fetchRes.success === '1') {
                    const salesOrderNo = fetchRes.formFieldData.find(item => item.includes("Sales Order No."))?.split(":")[1]?.trim();
                    let comboNames = ['Order Creation Type', 'Order Type', 'Order Life', 'Order Status', 'Customer',
                        'Quotation No.', 'Company Branch', 'Department', 'Consignee', 'Consignee State', 'Consignee City', 'Order IsActive'
                    ]
                    if (fetchRes.data.length !== 0) {
                        localStorage.removeItem('taxationSummary')
                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];

                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                const dateParts = fieldVal.split("-");

                                let comboVal;
                                if (fieldVal !== "" && comboNames.includes(fieldName)) {
                                    let lastBracesVal = fieldVal.match(/\(([^)]*)\)\s*$/);
                                    comboVal = lastBracesVal[1];
                                }
                                switch (fieldName) {
                                    case 'Order Creation Type': setSalesOrdCreationType(comboVal); break;
                                    case 'Order Type':
                                        setSalesOrderTypeId(comboVal);
                                        let currentSOType = salesOrdTypesOpts.find((item) => item.field_id === parseInt(comboVal));
                                        await FnGenerateSalesOrderNo(currentSOType.product_type_short_name);
                                        break;
                                    // case 'Sales Order No.': setSalesOrdNo(fieldVal); break;
                                    // case 'Order Version': setSalesOrdVer(fieldVal); break;
                                    // case 'Order Date': setSalesOrdDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Order Life': setSalesOrdLife(comboVal); break;
                                    // case 'Order Status': setSalesOrdStatus(comboVal); break;
                                    case 'Customer':
                                        setCustomerId(comboVal);
                                        resetGlobalQuery();
                                        globalQuery.columns = ["cust_branch_state_id", "cust_branch_city_id"];
                                        globalQuery.table = "cmv_customer";
                                        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: comboVal });
                                        const getCustomerDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                                        if (getCustomerDetailsApiCall.length > 0) {
                                            setCustomerState(getCustomerDetailsApiCall[0].cust_branch_state_id);
                                            resetGlobalQuery();
                                            globalQuery.columns = ['field_id', 'field_name'];
                                            globalQuery.table = "cmv_city"
                                            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                                            globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(getCustomerDetailsApiCall[0].cust_branch_state_id) });
                                            const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                                            setCustomerCityOpts(getCustomerCitiesApiCall);
                                            setCustomerCity(getCustomerDetailsApiCall[0].cust_branch_city_id);
                                        }
                                        await FnGetCustomersContactPersonsDetails(parseInt(comboVal));
                                        break;
                                    case 'Customer Order No.': setCustomerOrderNo(fieldVal); break;
                                    case 'Customer Order Date':
                                        if (fieldVal !== '') {
                                            setCustomerOrdDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
                                        }
                                        break;
                                    case 'Quotation No.': setSalesQuotationNo(comboVal); break;
                                    case 'Quotation Date':
                                        if (fieldVal !== '') {
                                            setSalesQuotationDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
                                        }
                                        break;
                                    case 'Company Branch':
                                        setCompanyBranchId(comboVal);
                                        const selectedBranchObj = companyBranchesOpts.find((branch) => branch.company_branch_id === parseInt(comboVal));
                                        if (selectedBranchObj) {
                                            setCompanyBranchStateId(selectedBranchObj.branch_state_id);
                                            resetGlobalQuery();
                                            globalQuery.columns = ['field_id', 'field_name'];
                                            globalQuery.table = "cmv_city";
                                            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                                            globalQuery.conditions.push({ field: "state_id", operator: "=", value: selectedBranchObj.branch_state_id });
                                            const cityApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                                            setCompanyBrancheCityOpts(cityApiCall);
                                            setCompanyBranchCityId(selectedBranchObj.branch_city_id)
                                        } break;
                                    case 'Company State': break;
                                    case 'Company City': break;
                                    case 'Department': setDeptId(comboVal); break;
                                    case 'Approved By': break;
                                    case 'Approved Date': break;
                                    case 'Expected Schedule Date':
                                        if (fieldVal !== '') {
                                            setOverAllScheduleDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
                                        }
                                        break;
                                    case 'Consignee':
                                        setConsigneeId(comboVal);
                                        await FnComboOnChange('Consignee');
                                        break;
                                    case 'Consignee State':
                                        setConsigneeState(comboVal);
                                        resetGlobalQuery();
                                        globalQuery.columns = ['field_id', 'field_name'];
                                        globalQuery.table = "cmv_city";
                                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(comboVal) });
                                        const cityApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                                        setConsigneeCityOpts(cityApiCall);
                                        break;
                                    case 'Consignee City':
                                        setConsigneeCity(comboVal);
                                        break;
                                    case 'Remark': setRemark(fieldVal); break;
                                    case 'Order IsActive': setIsActive(comboVal); break;
                                    default: break;
                                }
                            }
                        }
                        if (rb_sales_order_creation_type === 'Q') {
                            await FnGetCustomerApprovedQuotations();
                        }
                        console.log('readedOrderDetailsFromExcel: ' + fetchRes.data);
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            // Keys Changes.
                            orderDetail.so_sr_no = parseInt(orderDetail.so_sr_no);
                            orderDetail.product_id = orderDetail.material_id;
                            orderDetail.product_name = orderDetail.material_name;
                            // orderDetail.product_type = orderDetail.product_type_short_name;
                            orderDetail.product_type = orderDetail.product_type;

                            orderDetail.product_std_weight = orderDetail.material_std_weight;
                            orderDetail.product_packing_name = orderDetail.material_packing;

                            orderDetail.product_tech_spect = orderDetail.material_technical_specification;
                            orderDetail.product_hsn_sac_code_id = parseInt(orderDetail.product_hsn_sac_code_id);
                            orderDetail.product_hsn_sac_code = orderDetail.material_hsn_code;
                            orderDetail.product_hsn_sac_rate = parseInt(orderDetail.material_hsn_rate);

                            // Check the validations for the numeric field.
                            // const editableColumnNames = ['material_quantity', 'material_weight', 'material_rate', 'material_discount_percent', 'material_discount_amount'];
                            orderDetail.material_quantity = isNaN(parseFloat(orderDetail.material_quantity)) ? 0 : parseFloat(orderDetail.material_quantity);
                            orderDetail.material_weight = isNaN(parseFloat(orderDetail.material_weight)) ? 0 : parseFloat(orderDetail.material_weight);
                            orderDetail.material_rate = isNaN(parseFloat(orderDetail.material_rate)) ? 0 : parseFloat(orderDetail.material_rate);
                            orderDetail.material_discount_percent = isNaN(parseFloat(orderDetail.material_discount_percent)) ? 0 : parseFloat(orderDetail.material_discount_percent);
                            orderDetail.material_discount_amount = isNaN(parseFloat(orderDetail.material_discount_amount)) ? 0 : parseFloat(orderDetail.material_discount_amount);

                            // Update calculations.
                            let calculatedWt = parseFloat(orderDetail.material_quantity) * parseFloat(orderDetail.product_std_weight);
                            orderDetail.material_weight = validateNumberDateInput.current.decimalNumber(calculatedWt.toString(), 4);
                            if (orderDetail.product_unit_name === "Kilo Gram") {
                                orderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber((orderDetail.material_weight * parseFloat(orderDetail.material_rate)).toString(), 4);
                            } else {
                                orderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber((orderDetail.material_rate * parseFloat(orderDetail.material_quantity)).toString(), 4);

                            }
                            // orderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber((orderDetail.material_rate * parseFloat(orderDetail.material_quantity)).toString(), 4);
                            const calculatedDiscountAmount = (orderDetail.material_discount_percent / 100) * orderDetail.material_basic_amount;
                            orderDetail.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedDiscountAmount), 4);

                            FnMaterialWiseCalculations(orderDetail);
                            return orderDetail;
                        });

                        console.log('readedOrderDetailsFromExcel after updatation: ' + readedOrderDetailsFromExcel);
                        // setBomfilteredData(readedOrderDetailsFromExcel);

                        setSalesOrdDetailsData(readedOrderDetailsFromExcel);
                        calculateGrandTotal(readedOrderDetailsFromExcel);
                        FnCalculateDiscSum(readedOrderDetailsFromExcel);

                    }
                }
                else {
                    setErrMsg(fetchRes.error)
                    setShowErrorMsgModal(true)
                }
            }
            $('#spinner_id').hide();
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // ********************************************* Export Imports Functionality Ends. *****************************************************************


    // *********************************************  Sales Order Emailing Functionality Starts.************************************************************
    const generateMailJson = async (check_send_mail) => {
        debugger
        let EmailData = { 'EmailType': "Sales Order Status Updatation", 'alias': "Sales Department", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
        
        if (check_send_mail.includes('D')) {
            const departmentHeadMailIds = departmentDetails.map((item) => item.property_value);
            departmentHeadMailIds.forEach((email) => {
                if (isValidEmail(email)) {
                    EmailData.toMailData.push(email);
                } 
            });
        }

        if (check_send_mail.includes('C')) {
            const checkboxes = $('.selectCustContactPerson:checked');
            checkboxes.each(function () {
                let selectedContact_id = parseInt($(this).attr('contact_personId'));
                const customerContactPersonDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
                if (customerContactPersonDetails) {
                    // Store only valid emails.
                    if (isValidEmail(customerContactPersonDetails?.cust_email_id)) {
                        EmailData.toMailData.push(customerContactPersonDetails.cust_email_id);
                    }
                }
            });
        }

        if (check_send_mail.includes('A')) {
            let agentDetails = agentOpts.find((agent) =>
                parseInt(agent.agent_id) === parseInt(cmb_agent_id)
            );
            if (agentDetails && agentDetails['agent_EmailId']) {
                EmailData.toMailData.push(agentDetails['agent_EmailId']);
            }
        }

        EmailData.subject = `Sales Order Approval Confirmation - Order ${txt_sales_order_no}`;      // Subject
        EmailData.parameters.push("Sales Order Information");                                       // Param0 It is a heading.
        EmailData.parameters.push($('#cmb_customer_id').find(":selected").text() || '');            // Param1
        EmailData.parameters.push(`${txt_sales_order_no}`);                                         // Param2
        EmailData.parameters.push($('#cmb_sales_order_status').find(":selected").text());           // Param3
        EmailData.parameters.push(`${dt_sales_order_date}`);                                        // Param4
        EmailData.parameters.push(`${txt_sales_order_no}`);                                         // Param4
        EmailData.parameters.push(COMPANY_NAME);
        // Param6
        // console.log('emailData: ', EmailData);
        return EmailData;
    }

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    const sendEmail = async (check_send_mail) => {
        debugger
        try {
            debugger
            let check_sendmail = 'D';
            check_send_mail.push(check_sendmail);
            $('#spinner_id').show();
            $('#spinner_text').text('Loading...');
            setShowMailSentModal(false);

            let emailContent = await generateMailJson(check_send_mail);
            if (emailContent.toMailData.length === 0) {
                setErrMsg("Sorry, There are no customer emails present to send an email to...!");
                setShowErrorMsgModal(true);
                $('#spinner_id').hide();
                $('#spinner_text').text('Loading...');
                return false;
            }

            // Generate the sales-order PDF.
            let salesOrderContent = await printInvoice(false);
            // Append the blank rows.
            // salesOrderContent = FnFormatPDFLayout(salesOrderContent);

            const fileName = `${txt_sales_order_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
            const salesOrderContainer = document.createElement('div');
            salesOrderContainer.style.margin = '20px';
            salesOrderContainer.innerHTML = salesOrderContent;
            let worker = html2pdf().from(salesOrderContainer).set({
                margin: 0,
                filename: fileName,
                html2canvas: { scale: 2 },
                jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
            }).toPdf();

            let pages = [];
            pages.slice(1).forEach(function (page) {
                worker = worker.get('pdf').then(function (pdf) {
                    pdf.addPage();
                }).from(page).toContainer().toCanvas().toPdf();
            });



            worker = worker.output("datauristring").then(async function (pdf) {

                const preBlob = dataURItoBlob(pdf);
                const file = new File([preBlob], fileName, { type: 'application/pdf' });

                const formData = new FormData();

                formData.append(`EmailData`, JSON.stringify(emailContent));



                formData.append("salesOrderPDF", file);
                $('#spinner_id').show();
                $('#spinner_text').text('Validating Order Details...');


                const requestOptions = { method: 'POST', body: formData };
                const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterTrading/FnSendEmail/${sales_order_master_transaction_id}/${COMPANY_ID}`, requestOptions);
                const emailingApiCallResponse = await emailingApiCall.json();

                if (emailingApiCallResponse.success === "1") {
                    // Show success message.
                    setSuccMsg(emailingApiCallResponse.message);
                    setShowSuccessMsgModal(true);
                } else {
                    // Show error message.
                    setErrMsg(emailingApiCallResponse.message);
                    setShowErrorMsgModal(true);
                }
            });


        } catch (error) {
            console.log('Error in email sending: ', error);
            navigate('/Error')
        } finally {
            $('#spinner_id').hide();
        }
    }

    const FnFormatPDFLayout = (pdfContenthtmlString) => {
        // Create a new DOMParser
        let parser = new DOMParser();

        // Parse the HTML string into a DOM document
        let doc = parser.parseFromString(pdfContenthtmlString, 'text/html');
        var pdfContent = doc.body.firstChild;

        // const pdfContentElem = React.createElement('div', { dangerouslySetInnerHTML: { __html: pdfContentHTMLString } });
        // // Render the React element into the DOM
        // const pdfContent = document.createElement('div');
        // ReactDOM.render(pdfContentElem, pdfContent);

        const footerTr = pdfContent.querySelector('#footerTr');
        let tbody = pdfContent.querySelector('#detailstableId');
        let firstRowCells = tbody.querySelector('tr').querySelectorAll('td');
        let rowTds = '';

        // Get all <tr> elements within the tbody
        const trs = tbody.getElementsByTagName('tr');
        // Calculate the total height
        let tbodyTotalHeight = 0;

        // Iterate over the cells of the first row
        firstRowCells.forEach(() => {
            rowTds += `<td></td>`
        });

        // Array to keep track of added rows
        const addedRows = [];
        let tempContentHeight = pdfContent.offsetHeight
        const totalpages = Math.ceil(tempContentHeight / 1062);
        const pageHeightNeeded = totalpages * 1062

        if (!(tempContentHeight < 1067) || tempContentHeight < 1050) {
            const rowsToAdd = Math.round(pageHeightNeeded - tempContentHeight)
            const tolerance = 10;
            for (let index = 0; index < Math.round(rowsToAdd / 20); index++) {
                tempContentHeight += 20;
                if (Math.abs(tempContentHeight - pageHeightNeeded) <= tolerance) {
                    break;
                }

                const newRow = document.createElement('tr');
                newRow.className = `addedTr-${index}`
                newRow.style.border = 'none'; // Remove border lines
                newRow.style.height = '20px'

                newRow.innerHTML = rowTds;

                // Append the new row to the current page
                footerTr.parentNode.insertBefore(newRow, footerTr);
                addedRows.push(newRow);
            }
        }

        let afterTbodyHt = 0
        // Check which row has more than 25pcx height
        for (let i = 0; i < trs.length; i++) {
            const className = trs[i].className;
            // Check if className contains "tblBodyTrHt"
            if (className.includes('tblBodyTrHt') && trs[i].offsetHeight > 25) {
                afterTbodyHt += trs[i].offsetHeight - 20;
            }
        }

        if (!(pdfContent.offsetHeight < 1050)) {
            let removeRows = Math.ceil((afterTbodyHt) / 20)
            let removeCount = 0
            for (let i = addedRows.length - 1; i >= 0; i--) {
                if (removeRows === removeCount || pdfContent.offsetHeight < pageHeightNeeded - 150) {
                    break;
                }
                addedRows[i].parentNode.removeChild(addedRows[i]);
                removeCount += 1
            }
        }

        // Clone the content node
        const printContentClone = pdfContent.cloneNode(true);
        const wrapperDiv = document.createElement('div');
        // wrapperDiv.classList.add('content');
        wrapperDiv.style.pageBreakAfter = 'always';
        wrapperDiv.appendChild(printContentClone);
        const contentWrapper = document.createElement('div');
        contentWrapper.appendChild(wrapperDiv.cloneNode(true));
        return contentWrapper.outerHTML;
    }

    // ********************************************* Sales Order Emailing Functionality Ends.***********************************************************

    // ********************************************* Load Data On Accordion Click Functionality Starts.************************************************************
    const FnLoadAccordionData = async (eventKey) => {
        let checkedLength = 0;
        switch (eventKey) {
            case 'CommonTermsAccord':
                $('#spinner_id').show();
                $('#spinner_text').text('Loading...');
                checkedLength = $("input:checkbox[name=checkQuotationTerms]:checked").length;
                if (checkedLength === 0) {
                    await FnGetCommonSalesOrderTerms();
                }
                $('#spinner_id').hide();
                break;

            case 'paymentTerms':
                $('#spinner_id').show();
                $('#spinner_text').text('Loading...');
                checkedLength = $("input:checkbox[name=selectPayTerm]:checked").length;
                if (checkedLength === 0) {
                    await FnGetPaymentTerms();
                }
                $('#spinner_id').hide();
                break;

            case 'documentList':
                $('#spinner_id').show();
                $('#spinner_text').text('Loading...');
                await showDocumentRecords();
                $('#spinner_id').hide();
                break;

            default:
                break;
        }
    }

    const FnGetPaymentTerms = async () => {
        try {
            const pyTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/paymentterms/FnShowAllActiveRecords`)
            const responce = await pyTermsApiCall.json();
            if (responce.data.content.length !== 0 && responce.data.content.length > 0) {
                const updatedContent = responce.data.content.map((paymentTerm) => ({
                    ...paymentTerm,
                    remark: '',
                })).sort((a, b) => a.payment_terms_id - b.payment_terms_id);
                responce.data.content = updatedContent;

                const existingPMTTermsData = [...PmtTermsdataResp];
                let renderedPMTTermsData = responce.data.content
                for (let existPMTCounter = 0; existPMTCounter < existingPMTTermsData.length; existPMTCounter++) {
                    const existingPMTTermId = existingPMTTermsData[existPMTCounter].payment_terms_id;
                    const renderedPMTTerm = renderedPMTTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.payment_terms_id === existingPMTTermId);
                    if (renderedPMTTerm) {
                        const existingIndex = renderedPMTTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedPMTTermsData.splice(existingIndex, 1)[0];
                        renderedPMTTermsData.unshift(movedItem);
                        // renderedPMTTermsData[0].payment_terms_Milestome = existingPMTTermsData[existPMTCounter].payment_terms_Milestome;
                        // renderedPMTTermsData[0].payment_percent = existingPMTTermsData[existPMTCounter].payment_percent;
                        // renderedPMTTermsData[0].remark = existingPMTTermsData[existPMTCounter].remark;
                        renderedPMTTermsData[0].payment_terms_Milestome = existingPMTTermsData[existPMTCounter].payment_terms_Milestome;
                        renderedPMTTermsData[0].payment_expected_value = existingPMTTermsData[existPMTCounter].payment_expected_value;
                        renderedPMTTermsData[0].payment_expected_date = existingPMTTermsData[existPMTCounter].payment_expected_date;
                        renderedPMTTermsData[0].payment_percent = existingPMTTermsData[existPMTCounter].payment_percent;
                        renderedPMTTermsData[0].payment_receipt_flag = existingPMTTermsData[existPMTCounter].payment_receipt_flag;
                        renderedPMTTermsData[0].payment_receipt_transaction_id = existingPMTTermsData[existPMTCounter].payment_receipt_transaction_id;
                        renderedPMTTermsData[0].payment_receipt_date = existingPMTTermsData[existPMTCounter].payment_receipt_date;
                        renderedPMTTermsData[0].remark = existingPMTTermsData[existPMTCounter].remark;
                    }
                }
                setPmtTermsData(renderedPMTTermsData);
                // setTimeout(() => {
                //     PmtTermsdataResp.forEach(function (existingPayTerm) {
                //         $('#selectPmtTerm_' + existingPayTerm.payment_terms_id).prop('checked', true);
                //     });
                // }, 200);

                //------------------------------------------------------
                setTimeout(() => {
                    if (PmtTermsdataResp.length === 0 && ['', 'copy'].includes(keyForViewUpdate)) {
                        const defaultPmtTermId = 1;
                        setPmtTermsData(prevData => {
                            return prevData.map(pmtTerm => {
                                if (pmtTerm.payment_terms_id == defaultPmtTermId) {
                                    $('#selectPmtTerm_' + defaultPmtTermId).prop('checked', true);
                                    return {
                                        ...pmtTerm,
                                        payment_expected_date: today(),
                                        payment_percent: 100,
                                    }; // Update the 'name' property for mtId = 5
                                }
                                return pmtTerm; // Return unchanged object if mtId is not 5
                            });
                        });

                    } else {
                        PmtTermsdataResp.forEach(function (existingPayTerm) {
                            $('#selectPmtTerm_' + existingPayTerm.payment_terms_id).prop('checked', true);
                        });
                    }
                }, 200);





            } else {
                setPmtTermsData([]);
            }

            // localStorage.setItem('SalesOrdPmtTermsData', JSON.stringify(updatedPmtTermsData));
        } catch (error) {
            console.log("error in getting payment terms master data: ", error)
            navigate('/Error')
        }

        // Onchange event listener for the POTerm Selection
        $('.selectPmtTerm').on('change', function () {
            // if it is checked then focus the.
            let paymentTermId = $(this).attr("pmtTermId");
            if (this.checked) {
                if ($(`#payment_milestone_${paymentTermId}`).val() === '') {
                    $(`#payment_milestone_${paymentTermId}`).focus();

                } else if ($(`#payment_percent_${paymentTermId}`).val() === '') {
                    $(`#payment_percent_${paymentTermId}`).focus();

                } else if ($(`#payment_expected_value_${paymentTermId}`).val() === '') {
                    $(`#payment_expected_date_${paymentTermId}`).focus();

                }
            } else {
                let mileStoneField = document.querySelector('#payment_milestone_' + paymentTermId);
                delete mileStoneField.parentElement.dataset.tip;
            }
            checkBoxesSelection('PartiallyPmtTermsSelection');
        });
    }

    const updatePmtTermsTblData = (currentPaymentTerm, e) => {
        let clickedColName = e.target.getAttribute('Headers');

        switch (clickedColName) {
            case 'payment_expected_value':
                currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4) || 0;
                delete e.target.parentElement.dataset.tip;
                break;

            case 'payment_percent':
                let isValidPercentageInput = validatePercentageInput(e.target.value);
                if (isValidPercentageInput === true) {
                    currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
                    delete e.target.parentElement.dataset.tip;
                } else {
                    const currentTblRow = e.target.parentElement.parentElement;
                    const checkbox = currentTblRow.querySelector('input[id^="selectPmtTerm_"]');
                    if (checkbox.checked) {
                        currentPaymentTerm[clickedColName] = currentPaymentTerm.payment_percent;
                        e.target.parentElement.dataset.tip = isValidPercentageInput;
                    } else {
                        currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
                        delete e.target.parentElement.dataset.tip;
                    }

                }
                break;

            case 'payment_terms_Milestome':
            case 'payment_expected_date':
            case 'remark':
                currentPaymentTerm[clickedColName] = e.target.value;
                delete e.target.parentElement.dataset.tip;
                break;
            default:
                break;
        }

        // update the Payment terms table data.
        const updatedPaymentTermsData = [...PmtTermsdata]
        const pmtTermIndexInArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedPaymentTermsData[pmtTermIndexInArray] = currentPaymentTerm;
        setPmtTermsData(updatedPaymentTermsData);
        // localStorage.setItem('SalesOrdPmtTermsData', JSON.stringify(updatedPaymentTermsData));
    }

    const FnGetCommonSalesOrderTerms = async () => {
        try {
            const MSTName = encodeURIComponent(commonTermMstName);
            const getQuotationCommonTerms = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}`)
            // const getQuotationCommonTerms = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}/${COMPANY_ID}`)
            const responce = await getQuotationCommonTerms.json();
            if (responce.content.length !== 0) {
                const updatedContent = responce.content.map((commonTerm) => ({
                    ...commonTerm,
                    common_parameters_expected_value: commonTerm.common_parameters_value,
                    remark: '',
                }))
                    .sort((a, b) => a.common_parameters_id - b.common_parameters_id);
                responce.content = updatedContent;

                const existingCommonTermsData = [...SOCommonTermsDataResp];
                let renderedCommonTermsData = responce.content
                for (let existCommonCounter = 0; existCommonCounter < existingCommonTermsData.length; existCommonCounter++) {
                    const existingCommonTermId = existingCommonTermsData[existCommonCounter].sales_order_terms_parameters_id;
                    const renderedCommonTerm = renderedCommonTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => parseInt(data.item.common_parameters_id) === parseInt(existingCommonTermId));
                    if (renderedCommonTerm) {
                        const existingIndex = renderedCommonTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedCommonTermsData.splice(existingIndex, 1)[0];
                        renderedCommonTermsData.unshift(movedItem);
                        renderedCommonTermsData[0].common_parameters_expected_value = existingCommonTermsData[existCommonCounter].sales_order_terms_parameters_expected_value;
                        renderedCommonTermsData[0].remark = existingCommonTermsData[existCommonCounter].remark;
                    }
                }
                setSOCommonTermsData(renderedCommonTermsData);
                setTimeout(() => {
                    existingCommonTermsData.forEach(function (existingCommonTerm) {
                        $('#selectCommonTerm_' + existingCommonTerm.sales_order_terms_parameters_id).prop('checked', true);
                    });
                }, 300);
            }
        } catch (error) {
            console.log("error: ", error)
        }
        // Onchange event listener for the POTerm Selection
        $('.selectCommonTerm').on('change', function () {
            checkBoxesSelection('PartiallyCommonTermSelection');
        });
    }

    const updateSalesOrdCommonTermsTblData = (currentCommmonTerm, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentCommmonTerm[clickedColName] = event.target.value;
        const updatedCommonTermsData = [...SOCommonTermsData];
        const commonTermIndexInArray = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedCommonTermsData[commonTermIndexInArray] = currentCommmonTerm;
        setSOCommonTermsData(updatedCommonTermsData);
        // localStorage.setItem('SalesOrdCommonTermsData', JSON.stringify(updatedCommonTermsData));
    }


    // ********************************************* Load Data On Accordion Click Functionality Ends.************************************************************


    // ********************************************* Sales Order Document Upload Functionality Starts. *****************************************************

    const excludedcustFields = ['is_delete', 'is_active', 'created_on', 'created_by', 'modified_on', 'modified_by', 'deleted_by',
        'deleted_on', 'document_id', 'company_id'];

    // const showDocumentRecords = async () => {
    //     try {
    //         if (COMPANY_ID !== null) {
    //             const res = await fetch(
    //                 `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_sales_order_no !== 0 ? txt_sales_order_no.replaceAll('/', '_') : null}`
    //             );
    //             const resp = await res.json();
    //             if (resp.content.length > 0) {
    //                 setUploadedDocData(resp.content);
    //             } else {
    //                 setUploadedDocData([]);
    //             }
    //         }
    //     } catch (error) {
    //         console.log("error: ", error)
    //     }
    // }

    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">File Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">
                                    <MDTypography
                                        component="label"
                                        className="erp-form-label"
                                        variant="button"
                                        id="logoFile"
                                        fontWeight="regular"
                                        color="info"
                                        onClick={() => fetchDocument(docItem)}
                                        style={{ cursor: 'pointer', color: '#0000FF' }} // Change cursor to pointer to indicate clickable
                                        onMouseEnter={(e) => e.target.style.color = '#0000FF'} // Change color on hover
                                        onMouseLeave={(e) => e.target.style.color = '#0000FF'} // Restore original color when not hovering
                                    >
                                        {docItem.file_name}
                                    </MDTypography>
                                </td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);

    // const fetchDocument = async (docItem) => {

    //     try {
    //         const formData = new FormData()
    //         const data = {
    //             document_group: docItem.documentGroup,
    //             group_id: docItem.group_id,
    //             document_path: docItem.document_path
    //         }
    //         formData.append(`getFile`, JSON.stringify(data))
    //         const requestOptions = { method: 'POST', body: formData };
    //         const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
    //         const blob = await getDocApiCall.blob()

    //         const url = window.URL.createObjectURL(new Blob([blob]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', `${docItem.file_name}`,);
    //         document.body.appendChild(link);

    //         link.click();
    //         link.parentNode.removeChild(link);
    //     } catch (error) {
    //         console.log("error: ", error)
    //     }
    // }

    const fetchDocument = async () => {
        try {
            const formData = new FormData()
            const data = {
                document_group: documentGroup,
                // group_id: group_id,
                group_id: txt_sales_order_no !== 0 ? txt_sales_order_no.replaceAll('/', '_') : null,
                document_path: document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }


    }
    // ********************************************* Sales Order Document Upload Functionality Ends. *****************************************************

    // --------------------------------------- Delete the transaction functionality starts.------------------------------------------------------------------
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterTrading/FnDeleteRecord/${salesOrdReqData.sales_order_version}/${COMPANY_ID}/${UserName}?sales_order_no=${salesOrdReqData.sales_order_no}`, method)
            const responce = await deleteApiCall.json();
            if (responce.success == '1') {
                setShowDeleteModal(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const deleteshow = () => {
        setShowDeleteModal(true)
    }
    // --------------------------------------- Delete the transaction functionality ends.------------------------------------------------------------------



    // ********************************************* Sales Order PrintOut Functionality Starts.************************************************************
    //Sales Order Invoice Creation 
    const SalesOrderInvoiceContent = {
        consigneeDetails: {
            name: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            consignee_city_name: '',
        },
        customerDetails: {
            customer: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            email: '',
            customer_city_name: '',

        },
        Ourdetails: {
            company_branch_name: '',
            company_address1: '',
            state_name: '',
            city_name: '',
        },
        salesOrderDetails: {
            sales_order_no: '',
            sales_order_date: '',
            sales_quotation_no: '',
            sales_quotation_date: '',
            department_name: '',
            sales_order_version: '',
            sales_order_creation_type: '',
            customer_order_no: '',
            customer_order_Date: '',
        },

        footer: {
            cgst_words: '',
            sgst_words: '',
            igst_words: '',
            grand_total_words: '',

            basic_total: '',
            cgst_total: '',
            sgst_total: '',
            igst_total: '',
            taxable_total: '',
            grand_total: '',
            transport_amount: '',
            packing_amount: '',
            freight_amount: '',
            other_amount: '',
            discount_amount: '',
            other_terms_conditions: '',
        },

        items: [],
        paymentTerms: [],
        SOTerms: [],
        contactDetails: [],
        scheduleDataArray: [],
    };


    const printInvoice = async (openPrintModal) => {
        //Sales details
        debugger
        SalesOrderInvoiceContent.salesOrderDetails.sales_order_no = salesOrderMasterDataJson.sales_order_no
        SalesOrderInvoiceContent.salesOrderDetails.sales_order_date = validateNumberDateInput.current.formatDateToDDMMYYYY(salesOrderMasterDataJson.sales_order_date)
        SalesOrderInvoiceContent.salesOrderDetails.sales_order_version = salesOrderMasterDataJson.sales_order_version
        SalesOrderInvoiceContent.salesOrderDetails.sales_type = $("#cmb_sales_type_id option:selected").text();
        SalesOrderInvoiceContent.salesOrderDetails.sales_order_creation_type = salesOrderMasterDataJson.sales_order_creation_type_desc
        SalesOrderInvoiceContent.salesOrderDetails.customer_order_no = salesOrderMasterDataJson.customer_order_no
        SalesOrderInvoiceContent.salesOrderDetails.customer_order_Date = validateNumberDateInput.current.formatDateToDDMMYYYY(salesOrderMasterDataJson.customer_order_Date)
        SalesOrderInvoiceContent.salesOrderDetails.department_name = salesOrderMasterDataJson.department_name
        // SalesOrderInvoiceContent.salesOrderDetails.agent_name = salesOrderMasterDataJson.department_name  
        SalesOrderInvoiceContent.salesOrderDetails.agent_name = agentOpts.find((agent) =>
            parseInt(agent.agent_id) === parseInt(cmb_agent_id)
        )?.field_name || null;

        SalesOrderInvoiceContent.salesOrderDetails.txt_agent_percent = txt_agent_percent;
        SalesOrderInvoiceContent.salesOrderDetails.order_type = $('#cmb_sales_order_type_id option:selected').attr('lbl');

        //Consignee details
        SalesOrderInvoiceContent.consigneeDetails.name = salesOrderMasterDataJson.consignee_name
        SalesOrderInvoiceContent.consigneeDetails.address = salesOrderMasterDataJson.consignee_address1
        SalesOrderInvoiceContent.consigneeDetails.state = salesOrderMasterDataJson.consignee_state_name
        SalesOrderInvoiceContent.consigneeDetails.contact = salesOrderMasterDataJson.consignee_phone_no
        SalesOrderInvoiceContent.consigneeDetails.consignee_city_name = salesOrderMasterDataJson.consignee_city_name

        //Customer details
        SalesOrderInvoiceContent.customerDetails.customer = salesOrderMasterDataJson.customer_name
        SalesOrderInvoiceContent.customerDetails.address = salesOrderMasterDataJson.cust_branch_address1
        SalesOrderInvoiceContent.customerDetails.state = salesOrderMasterDataJson.customer_state_name
        SalesOrderInvoiceContent.customerDetails.contact = salesOrderMasterDataJson.cust_branch_phone_no
        SalesOrderInvoiceContent.customerDetails.email = salesOrderMasterDataJson.customer_email
        SalesOrderInvoiceContent.customerDetails.customer_city_name = salesOrderMasterDataJson.customer_city_name

        SalesOrderInvoiceContent.footer.basic_total = salesOrderMasterDataJson.basic_total
        SalesOrderInvoiceContent.footer.cgst_total = salesOrderMasterDataJson.cgst_total
        SalesOrderInvoiceContent.footer.sgst_total = salesOrderMasterDataJson.sgst_total
        SalesOrderInvoiceContent.footer.igst_total = salesOrderMasterDataJson.igst_total
        SalesOrderInvoiceContent.footer.taxable_total = salesOrderMasterDataJson.taxable_total
        SalesOrderInvoiceContent.footer.grand_total = salesOrderMasterDataJson.grand_total
        SalesOrderInvoiceContent.footer.transport_amount = salesOrderMasterDataJson.transport_amount
        SalesOrderInvoiceContent.footer.packing_amount = salesOrderMasterDataJson.packing_amount
        SalesOrderInvoiceContent.footer.freight_amount = salesOrderMasterDataJson.freight_amount
        SalesOrderInvoiceContent.footer.discount_amount = salesOrderMasterDataJson.discount_amount
        SalesOrderInvoiceContent.footer.other_amount = salesOrderMasterDataJson.other_amount
        SalesOrderInvoiceContent.footer.remark = salesOrderMasterDataJson.remark
        SalesOrderInvoiceContent.footer.other_terms_conditions = salesOrderMasterDataJson.other_terms_conditions
        SalesOrderInvoiceContent.footer.grn_currency_code = "INR"

        // Our Details
        SalesOrderInvoiceContent.Ourdetails.company_branch_name = salesOrderMasterDataJson.company_branch_name
        SalesOrderInvoiceContent.Ourdetails.company_address1 = salesOrderMasterDataJson.company_address1
        SalesOrderInvoiceContent.Ourdetails.state_name = salesOrderMasterDataJson.company_branch_state
        SalesOrderInvoiceContent.Ourdetails.city_name = $("#cmb_companyBranchCityId option:selected").text();

        let newScheduleDataArray = scheduleDataArray.map((data, index) => {
            data['product_material_code'] = salesOrdDetailsDataJson[index]['product_material_code'];
            data['product_material_packing_name'] = salesOrdDetailsDataJson[index]['product_material_packing_name'];
            return data;
        })
        SalesOrderInvoiceContent.scheduleDataArray = newScheduleDataArray;
        console.log(salesOrdDetailsDataJson);

        for (let index = 0; index < salesOrdDetailsDataJson.length; index++) {
            const element = salesOrdDetailsDataJson[index];

            const detailsData = {
                sr_no: element.sr_no,
                so_sr_no: element.so_sr_no,
                material_name: element.product_material_print_name,
                material_tech_spect: element.product_material_tech_spect,
                product_material_code: element.product_material_code,

                // rate: element.material_rate,
                hsn_code: element.product_material_hsn_sac_code,
                rate: element.material_rate,
                // quantity: element.material_quantity,
                quantity: element.material_quantity,
                unit: element.product_material_stock_unit_name,
                discount_percent: element.material_discount_percent,
                cgst_percent: element.material_cgst_percent,
                sgst_percent: element.material_sgst_percent,
                igst_percent: element.material_igst_percent,
                material_basic_amount: element.material_basic_amount,
                material_grade_name: element.product_material_grade_name,
                product_make_name: element.product_make_name,
                company: element.company_name,
                company_branch: element.company_branch_name,
                address: element.company_address1,
                phone: element.company_phone_no,
                cell: element.company_cell_no,
                email: element.company_EmailId,
                website: element.company_website,
                GSTIN: element.company_pan_no,
                pan_no: element.company_pan_no,
                state_name: element.company_state,
                company_pincode: element.company_pincode,
            }

            SalesOrderInvoiceContent.items.push(detailsData)
        }

        const checkboxes = $('.selectCustContactPerson:checked');
        console.log(checkboxes);
        checkboxes.each(function (index) {
            if (index === 0) {
                let selectedContact_id = parseInt($(this).attr('contact_personId'));
                const customerContactDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
                if (customerContactDetails) {
                    const contactdata = {
                        cust_contact_person: customerContactDetails.cust_contact_person,
                        cust_contact_no: customerContactDetails.cust_contact_no,

                    }
                    SalesOrderInvoiceContent.contactDetails.push(contactdata);
                }
            }

        });

        // const customerContactDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
        // const checkboxesptm = $('.selectPmtTerm:checked');
        // console.log(checkboxesptm);
        // checkboxesptm.each(function (index) {
        //     // if (index === 0) {
        //     let selectedPaymentTerm_id = parseInt($(this).attr('pmtTermId'));
        //     const paymentTermDetails = PmtTermsdata.find(pmtTerms => pmtTerms.payment_terms_id === selectedPaymentTerm_id);
        //     if (paymentTermDetails) {
        //         const paymentTermsData = {
        //             payment_terms_name: paymentTermDetails.payment_terms_name,
        //             payment_terms_days: paymentTermDetails.payment_terms_days,

        //         }
        //         SalesOrderInvoiceContent.paymentTerms.push(paymentTermsData);
        //     }
        //     // }

        // });

        // const checkboxesCommonterm = $('.selectCommonTerm:checked');
        // console.log(checkboxesCommonterm);
        // checkboxesCommonterm.each(function (index) {
        //     // if (index === 0) {
        //     let selectedCommonTerm_id = parseInt($(this).attr('commonTermId'));
        //     const commonTermDetails = SOCommonTermsData.find(comTerms => comTerms.common_parameters_id === selectedCommonTerm_id);
        //     if (commonTermDetails) {
        //         const QuotationTermsData = {
        //             common_parameters_name: commonTermDetails.common_parameters_name,
        //             common_parameters_value: commonTermDetails.common_parameters_value,

        //         }
        //         SalesOrderInvoiceContent.SOTerms.push(QuotationTermsData);
        //     }
        //     // }

        // });


        for (let index = 0; index < PmtTermsdataResp.length; index++) {
            const element = PmtTermsdataResp[index];
            const paymentTermsData = {
                payment_terms_name: element.payment_terms_name,
                payment_terms_days: element.payment_terms_days,
            }
            SalesOrderInvoiceContent.paymentTerms.push(paymentTermsData)
        }
        for (let index = 0; index < SOCommonTermsDataResp.length; index++) {
            const element = SOCommonTermsDataResp[index];
            const QuotationTermsData = {
                common_parameters_name: element.sales_order_terms_parameters_name,
                common_parameters_value: element.sales_order_terms_parameters_value,
            }
            SalesOrderInvoiceContent.SOTerms.push(QuotationTermsData)
        }


        //         for (let index = 0; index < PmtTermsdata.length; index++) {
        //             const element = PmtTermsdata[index];
        //             const paymentTermsData = {
        //                 payment_terms_name: element.payment_terms_name,
        //                 payment_terms_days: element.payment_terms_days,
        //             }
        //             SalesOrderInvoiceContent.paymentTerms.push(paymentTermsData)
        //         }

        // for (let index = 0; index < SOCommonTermsData.length; index++) {
        //     
        //     const element = SOCommonTermsData[index];
        //     const QuotationTermsData = {
        //         common_parameters_name: element.common_parameters_name,
        //         common_parameters_value: element.common_parameters_value,
        //     }
        //     SalesOrderInvoiceContent.SOTerms.push(QuotationTermsData)
        // }

        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: SalesOrderInvoiceContent, navigationLink: '/Transactions/TSalesOrder/SalesOrderEntry', invoiceType: 'SO', title: 'Trading Sales Order', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });
        } else {
            // return the html code.
            return renderToString(<SalesOrderInvoice invoiceContent={SalesOrderInvoiceContent} />);
        }
    };
    // ********************************************* Sales Order PrintOut Functionality Ends.************************************************************
    //key for get Request type header
    //changes by tushar
    const requestTypeHeading = () => {
        switch (requestfor) {
            case 'YN':
                return '(Cotton Yarn)';
            case 'RC':
                return '(Raw Cotton)';
            case 'BL':
                return '(Cotton Bales)';
            case 'GF':
                return '(Greige Fabric)';
            case 'FF':
                return '(Finish Fabric)';
            case 'RM':
                return '(Raw Material)';
            case 'SB':
                return '(Sized Yarn)';
            default:
                return '';
        }
    }
    // ************************************************************************************************************************************************
    //--------------------------------- Material Properties Starts------------------------------------------------------------------------------------------------


    const fnGetProductProperties = async (getProductProperties) => {

        if (getProductProperties !== null && getProductProperties.length !== 0) {
            const modifyProductPropertiesData = [];
            for (let i = 0; i < getProductProperties.length; i++) {
                const propertiesItem = getProductProperties[i];
                let masterList;
                const initializeKeys = {
                    product_id: propertiesItem.product_id,
                    product_parameter_value: propertiesItem.product_parameter_value,
                    product_parameter_calculation_type: propertiesItem.product_parameter_calculation_type,
                    product_parameter_from_value: propertiesItem.product_parameter_from_value,
                    product_parameter_id: propertiesItem.product_parameter_id,
                    product_parameter_prefix: propertiesItem.product_parameter_prefix,
                    product_parameter_sufix: propertiesItem.product_parameter_sufix,
                    product_parameter_to_value: propertiesItem.product_parameter_to_value,
                    remark: propertiesItem.remark
                }
                const newFilter = {
                    type: propertiesItem.control_type,
                    dataArray: []    // new array for if there is combo 
                };
                if (propertiesItem.control_type === "P") {
                    resetGlobalQuery();
                    globalQuery.columns = ["field_name", "field_id"];
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: propertiesItem.control_master });
                    globalQuery.orderBy = ["property_id"];
                    globalQuery.table = "amv_properties";
                    masterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    newFilter.dataArray = masterList;
                }
                if (propertiesItem.control_type === "C") {
                    if (propertiesItem.control_master === "smv_product_rm") {
                        resetGlobalQuery();
                        globalQuery.columns = ["field_name", "field_id", "actual_count"];
                        globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: 12 });
                        globalQuery.table = propertiesItem.control_master
                        masterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                        newFilter.dataArray = masterList
                    } else {
                        resetGlobalQuery();
                        globalQuery.columns = ["field_name", "field_id"];
                        globalQuery.table = propertiesItem.control_master;
                        masterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                        newFilter.dataArray = masterList

                        // masterList = await comboBoxRef.current.fillMasterData(propertiesItem.control_master, "", "")
                    }
                }
                modifyProductPropertiesData.push({ ...getProductProperties[i], ...initializeKeys, newFilter })
            }
            setProductTypePropertiesData(modifyProductPropertiesData)
            return modifyProductPropertiesData;
        } else {
            setProductTypePropertiesData([])
        }
    }

    //Fn to render propertie details of material  table
    const renderProductPropertiesTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='productProperties-table-id' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className='erp_table_th' style={{ width: '100px', paddingRight: '100px' }}>Material Name</th>
                        <th className="erp_table_th">Parameter Name</th>
                        <th className="erp_table_th">Parameter Value</th>
                        <th className="erp_table_th">Prefix</th>
                        <th className="erp_table_th">Sufix</th>
                        <th className="erp_table_th">Calculation Type</th>
                        <th className="erp_table_th">From Value</th>
                        <th className="erp_table_th">To Value</th>
                        <th className="erp_table_th">Actual Value</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {productTypePropertiesData.map((filter, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td ">{index + 1}</td>
                            <td className="erp_table_td " style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{filter.product_material_name}</td>
                            <td className="erp_table_td ">{filter.product_parameter_name}</td>
                            <td className="erp_table_td ">
                                {filter.newFilter.type === 'C' || filter.newFilter.type === 'P' ? (
                                    <select id={`product_parameter_value_${index}`} className="form-select form-select-sm" value={filter.product_parameter_value} disabled>
                                        <option value=''>Select</option>
                                        {filter.newFilter.dataArray.map((item, index) => (
                                            <option key={index} value={item.field_id}>
                                                {item.field_name}
                                            </option>
                                        ))}
                                    </select>
                                ) : filter.newFilter.type === 'T' ? (<>
                                    {filter.product_parameter_value}
                                </>
                                ) : ""}
                            </td>
                            <td className="erp_table_td "> {filter.product_parameter_prefix}</td>
                            <td className="erp_table_td "> {filter.product_parameter_sufix}</td>
                            <td className="erp_table_td ">
                                <select id={`product_parameter_calculation_type_${index}`} className="form-select form-select-sm"
                                    value={filter.product_parameter_calculation_type} disabled>
                                    <option value=''>Select</option>
                                    <option value='Equals'>Equals</option>
                                    <option value='Less than'>Less than</option>
                                    <option value='Greater than'>Greater than</option>
                                    <option value='Between'>Between</option>
                                </select>
                            </td>
                            <td className="erp_table_td "> {filter.product_parameter_from_value}</td>
                            <td className="erp_table_td  "> {filter.product_parameter_to_value} </td>
                            <td className="erp_table_td  ">
                                <input type="text" className="erp_input_field mb-0 "
                                    Headers="product_parameter_actual_value" id={`product_parameter_actual_value_${index}`} value={filter.product_parameter_actual_value}
                                    onChange={(e) => { updatePropertiesTblRows(filter, e); }} />
                            </td>
                            <td className="erp_table_td  ">{filter.remark}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [productTypePropertiesData]);

    const updatePropertiesTblRows = (rowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        switch (clickedColName) {
            case 'product_parameter_actual_value':
            case 'remark':
                rowData[clickedColName] = event.target.value;
                break;
        }
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        const detailData = [...productTypePropertiesData]
        detailData[arrayIndex] = rowData
        setProductTypePropertiesData(detailData);
    }
    //--------------------------------- Material Properties Ends------------------------------------------------------------------------------------------------


    //*******************code for customer Live search  ********************************* */ 

    const options = [
        { value: '0', label: 'Add New Record+', is_sez: false },
        ...customerOpts.map(customer => ({
            value: customer.field_id,
            label: customer.field_name,
            is_sez: customer.is_sez
        })),

    ];

    const handleChange = (cmb_customer_id) => {
        if (cmb_customer_id !== '0' && cmb_customer_id !== '') {
            $('#error_cmb_customer_id').hide()
        }
        setCustomerId(cmb_customer_id.value);
        FnComboOnChangeForCustomer('Customer', cmb_customer_id.value);

    };
    let departmentComboRef = useRef(null)
    let subDepartmentComboRef = useRef(null)
    let consigneeComboRef = useRef(null);
    let agentComboRef = useRef(null);

    //*******************code for Raw material Requirement ********************************* */ 


    const FngetRawMaterialRequirement = async (selectedData) => {

        $('#spinner_id').show();
        $('#spinner_text').text('Loading...');
        console.log("Search material : ", selectedData);
        try {

            if (selectedData !== null) {
                let existingMaterial = [...rmRequirementData];
                let { product_id, product_name, product_type_id, stock_quantity, stock_weight, product_type_name,
                    total_box_weight, total_quantity_in_box, weight_per_box_item, product_unit_id, cost_center_id } = selectedData[0];

                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWarpingProductionOrder/FnGetProductBasedProperties/${product_id}`)
                const responce = await apiCall.json();
                const DynamicPropsList = responce.productDynamicPropsList;
                const propertiesColumns = responce.columns;
                console.log('productDynamicPropsList: ', DynamicPropsList, propertiesColumns);

                const countTypeOpts = [{ field_id: 'warp_count', field_name: 'Warp Count' },
                { field_id: 'weft_count', field_name: 'Weft Count' }];
                // Create an object with the selectedData values
                let productDetails = {
                    product_material_id: product_id,
                    product_material_name: product_name,
                    product_type_id,
                    product_type_name,
                    stock_quantity,
                    stock_weight,
                    material_quantity: stock_quantity,
                    weight_per_unit: weight_per_box_item,
                    material_weight: stock_quantity * weight_per_box_item,
                    count_type: '',
                    product_material_unit_id: product_unit_id,
                    countTypeOpts: countTypeOpts,
                    cost_center_id,

                };

                // DynamicPropsList.forEach(property => {
                //     // Assuming each property is an object with key-value pairs
                //     Object.assign(productDetails, property);
                // });
                // setRawMaterialPropertiesData(DynamicPropsList)


                // setRawMaterialPropertiesColumns(() => {
                //     return propertiesColumns.map((column) => ({
                //         header: column,
                //         width: '40px',  // Adjust the width as needed
                //     }));
                // });

                // Push the single merged productDetails object to existingMaterial
                existingMaterial.push(productDetails)
                console.log('existingMaterial: ', existingMaterial)

                setRmRequirementData(existingMaterial)

            }
        } catch (error) {
            console.log('error: ', error);
        }
        $('#spinner_id').hide();
    };
    const updateRawmaterialDetails = (event, currentRowData) => {

        let clickedColName = event.target.getAttribute('Headers');               // Get the column name where event triggered.
        let elementEvent = document.querySelector('#' + event.target.id);
        const rawRaterialData = [...rmRequirementData]
        let enterValue = event.target.value;

        switch (clickedColName) {
            case 'count_type':

                let foundMaterial = rawRaterialData.find((item) => { return item.product_material_id === currentRowData.product_material_id && item.count_type === enterValue });
                if (foundMaterial) {
                    elementEvent.parentElement.dataset.tip = `Material Already Added With Same Count Type ...!`;
                    currentRowData[clickedColName] = '';
                } else {
                    currentRowData[clickedColName] = enterValue;
                    delete elementEvent.parentElement.dataset.tip;
                }
                break;
            case 'material_quantity':

                if (enterValue === '') {
                    elementEvent.parentElement.dataset.tip = 'Please fill this field...!';
                    currentRowData[clickedColName] = '';
                }
                else {
                    let weight_per_unit = currentRowData['weight_per_unit'];
                    delete elementEvent.parentElement.dataset.tip;
                    currentRowData[clickedColName] = enterValue;
                    currentRowData['material_weight'] = validateNumberDateInput.current.decimalNumber(enterValue * weight_per_unit, 4);
                }
                break;
            case 'weight_per_unit':

                if (enterValue === '') {
                    elementEvent.parentElement.dataset.tip = 'Please fill this field...!';
                    currentRowData[clickedColName] = '';
                }
                else {
                    let materialQty = currentRowData['material_quantity'];
                    currentRowData[clickedColName] = enterValue;
                    currentRowData['material_weight'] = validateNumberDateInput.current.decimalNumber(enterValue * materialQty, 4);
                    delete elementEvent.parentElement.dataset.tip;
                }
                break;
            case 'remark':
                let remark = currentRowData['remark'];
                currentRowData[clickedColName] = enterValue;
                delete elementEvent.parentElement.dataset.tip;

                break;

            default:
                break;
        }

        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        rawRaterialData[arrayIndex] = currentRowData;
        setRmRequirementData(rawRaterialData);
    }
    // fn to remove material
    const removeRawMaterial = (indexToRemove, setStateCallback) => {
        if (indexToRemove === undefined || indexToRemove < 0 || indexToRemove >= rmRequirementData.length) {
            console.error("Invalid index provided.");
            return;
        }
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            // Create a new array excluding the element at indexToRemove
            const updatedDetailsData = rmRequirementData.filter((_, index) => index !== indexToRemove);

            // Update state using the provided callback
            setStateCallback(updatedDetailsData);
        } else {
            console.error("Cannot remove material in 'approve' or 'view' mode.");
        }
    }
    const renderRawMaterialRequairment = useMemo(() => {
        return <>
            {
                rmRequirementData.length !== 0 ?
                    <Table className="erp_table mt-2" id='raw-material-requirement-table' responsive bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th  ${keyForViewUpdate === "" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>Action</th>
                                <th className="erp_table_th" style={{ width: '40px' }}>Sr. No</th>
                                <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Material Name</th>
                                <th className="erp_table_th">Type</th>
                                <th className="erp_table_th">Stock Quantity</th>
                                <th className="erp_table_th">Stock Weight</th>
                                <th className="erp_table_th">No. of Cones</th>
                                <th className="erp_table_th">Cone Per Weight</th>
                                <th className="erp_table_th">Total Weight</th>
                                <th className="erp_table_th">Remark</th>
                                {/* {rawMaterialPropertiesColumns.map((column, index) => (
                                    <th
                                        key={index}
                                        className="erp_table_th"
                                    style={{ width: column.width, paddingRight: column.paddingRight || '0px' }}
                                    >
                                        {column.header}
                                    </th>
                                ))} */}
                            </tr>
                        </thead>
                        <tbody>
                            {rmRequirementData.map((taxItem, index) =>
                                <tr rowindex={index}>
                                    <td className={`erp_table_td  ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}><div style={{ display: "flex" }}>
                                        <Tooltip title="Delete" placement="top">
                                            <MDTypography className="erp-view-btn">
                                                <MdDelete className="erp-delete-btn" id={`delete_btn_${index}`} onClick={() => removeRawMaterial(index, setRmRequirementData)} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                    </td>
                                    <td className="erp_table_td">{index + 1}</td>
                                    <td className="erp_table_td">{taxItem.product_material_name}</td>
                                    <td className="erp_table_td">
                                        {keyForViewUpdate === "" || keyForViewUpdate === 'update' ? (
                                            <>
                                                <select id={`count_type_${index}`} className="form-select form-select-sm mb-1" value={taxItem.count_type} onChange={(e) => { updateRawmaterialDetails(e, taxItem) }}
                                                    Headers='count_type' >
                                                    <option value=''>Select</option>
                                                    {taxItem.countTypeOpts.map(countType => (
                                                        <option key={countType.field_id} value={countType.field_id} > {countType.field_name} </option>
                                                    ))}

                                                </select>
                                            </>
                                        ) :
                                            taxItem.count_type
                                        }
                                    </td>
                                    <td className="erp_table_td">{taxItem.stock_quantity}</td>
                                    <td className="erp_table_td">{taxItem.stock_weight}</td>
                                    <td className="erp_table_td">
                                        <input type="text" className="erp_input_field mb-0"
                                            Headers="material_quantity" id={`material_quantity_${index}`} value={taxItem.material_quantity}
                                            onChange={(e) => { updateRawmaterialDetails(e, taxItem); }} disabled={['approve', 'view', 'update'].includes(keyForViewUpdate) ? true : false} />
                                    </td>
                                    <td className="erp_table_td">
                                        <input type="text" className="erp_input_field mb-0 "
                                            Headers="weight_per_unit" id={`weight_per_unit_${index}`} value={taxItem.weight_per_unit}
                                            onChange={(e) => { updateRawmaterialDetails(e, taxItem); }} disabled={['approve', 'view', 'update'].includes(keyForViewUpdate) ? true : false} />
                                    </td>
                                    <td className="erp_table_td">{taxItem.material_weight}</td>
                                    {/* <td className="erp_table_td">{taxItem.Constant}</td>
                                    <td className="erp_table_td">{taxItem.COUNT}</td> */}
                                    <td className="erp_table_td ">
                                        <Form.Control as="textarea" id={`remark_${index}`} value={taxItem.remark}
                                            Headers="remark" onInput={(e) => { updateRawmaterialDetails(e, taxItem) }} disabled={['approve', 'view'].includes(keyForViewUpdate) ? true : false}
                                            className="erp_input_field mb-0" maxLength="1000" />
                                    </td>
                                </tr>
                            )
                            }
                        </tbody>
                    </Table>
                    : (
                        <div className='row text-center'>
                            <div className="col-12">
                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                    No Records Found...!
                                </span>
                            </div>
                        </div>
                    )
            }
        </>
    }, [rmRequirementData]);

    const GenerateIndentNo = async () => {
        let SOTypeShortName = $('#cmb_sales_order_type_id option:selected').attr('shortName');
        const soOrderTypeId = $('#cmb_sales_order_type_id option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("st_indent_master", "indent_no", 'PRM', "PR", "5", "indent_type_id", '12');
        return autoNoApiCall;
    };

    const FnValidateSalesOrderForm = async () => {
        // Validating master forms data.
        let headerDataIsValid = await validate.current.validateForm("salesOrderFormId");

        // Check combo values for customer
        if (cmb_customer_id === '0' || cmb_customer_id === '') {
            $("#error_cmb_customer_id").text("Please fill this field...!");
            $("#error_cmb_customer_id").show();
            $("#cmb_customer_id").focus();
            return false;
        }
        if (cmb_department_id === '' || cmb_department_id === '0') {
            $('#error_cmb_department').text('Please select atleast one...');
            $('#error_cmb_department').show();

            return false;
        }
        if (cmb_sub_department === '' || cmb_sub_department === '0') {
            $('#error_cmb_sub_department').text('Please select atleast one...');
            $('#error_cmb_sub_department').show();

            return false;
        }
        let footerDataIsValidate = await validate.current.validateForm("salesOrderTotalsFrm");
        if (!headerDataIsValid || !footerDataIsValidate) { return false; }

        let salesOrderDetailsIsValid = false;
        let addedMaterials = $('#raw-material-requirement-table tbody tr').length;
        // checked the materials are added or not?
        if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one Raw material.');
            setShowErrorMsgModal(true);
            return salesOrderDetailsIsValid = false;

        }
        return salesOrderDetailsIsValid = true;
    }


    // Fn to generate indent
    const FnGenerateIndent = async () => {
        try {
            let checkIsValidate = true;
            // if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
            checkIsValidate = await FnValidateSalesOrderForm();
            // }
            if (checkIsValidate === true) {
                setIsLoading(true)
                const lastIndentNo = await GenerateIndentNo();
                let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransScheduleData': [], 'TransProductTypeParametersData': [], 'TransPOTermsData': [], 'commonIds': {} }
                // Trans DetailData
                for (let materialIndex = 0; materialIndex < rmRequirementData.length; materialIndex++) {

                    var crJson = {}
                    const materialDataElement = rmRequirementData[materialIndex];
                    crJson['indent_details_id'] = 0;
                    crJson['indent_master_id'] = 0;
                    crJson['company_id'] = COMPANY_ID
                    crJson['company_branch_id'] = COMPANY_BRANCH_ID
                    crJson['indent_no'] = lastIndentNo
                    crJson['indent_version'] = '1'
                    crJson['indent_date'] = dt_sales_order_date
                    crJson['financial_year'] = FINANCIAL_SHORT_NAME
                    crJson['customer_order_no'] = txt_customer_order_no
                    // crJson['sales_order_details_transaction_id'] = materialDataElement.sales_order_details_transaction_id === undefined || materialDataElement.sales_order_details_transaction_id === null ? 0 : materialDataElement.sales_order_details_transaction_id
                    crJson['product_material_id'] = materialDataElement.product_material_id
                    crJson['product_material_quantity'] = materialDataElement.material_quantity
                    crJson['product_material_weight'] = materialDataElement.material_weight
                    crJson['product_material_unit_id'] = materialDataElement.product_material_unit_id
                    crJson['remark'] = materialDataElement.remark
                    crJson['indent_item_status'] = 'P'
                    crJson['created_by'] = UserName
                    crJson['cost_center_id'] = materialDataElement.cost_center_id
                    json.TransDetailData.push(crJson)
                }
                // TransHeaderData
                const indentMasterFormData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    indent_master_id: 0,
                    indent_no: lastIndentNo,
                    indent_version: '1',
                    indent_type_id: '12',
                    indent_type: 'Raw Material',
                    indent_source: 'S',
                    customer_id: cmb_customer_id,
                    customer_order_no: txt_customer_order_no,
                    customer_order_Date: dt_customer_order_Date,
                    indent_date: dt_sales_order_date,
                    department_id: cmb_department_id,
                    sub_department_id: cmb_sub_department,
                    indented_by_id: UserId,
                    indent_status: 'P',
                    indent_transaction_type: "M",
                    is_active: true,
                    created_by: UserName,
                    expected_schedule_date: dt_sales_order_date,
                }
                json.TransHeaderData = indentMasterFormData;
                if (!isApprove) {
                    for (let index = 0; index < rmRequirementData.length; index++) {
                        const element = rmRequirementData[index];
                        const scheduleData = {
                            company_id: COMPANY_ID,
                            indent_master_id: 0,
                            company_branch_id: COMPANY_BRANCH_ID,
                            // so_sr_no: element.so_sr_no === null || element.so_sr_no === undefined ? 0 : element.so_sr_no,
                            // sales_order_details_transaction_id: element.sales_order_details_transaction_id === null || element.sales_order_details_transaction_id === undefined ? 0 : element.sales_order_details_transaction_id,
                            customer_order_no: txt_customer_order_no,
                            indent_no: lastIndentNo,
                            indent_date: dt_sales_order_date,
                            indent_version: 1,
                            product_material_id: element.product_material_id,
                            product_material_unit_id: element.product_material_unit_id,
                            product_material_schedule_quantity: element.material_quantity,
                            product_material_schedule_weight: element.material_weight,
                            expected_schedule_date: dt_sales_order_date,
                            product_material_receipt_quantity: 0,
                            product_material_receipt_weight: 0,
                            material_receipt_date: "",
                            created_by: UserName,
                            indent_item_status: 'P',
                            material_quantity: element.material_quantity,
                            material_weight: element.material_weight
                        }

                        json.TransScheduleData.push(scheduleData)

                    }
                }
                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.indent_no = lastIndentNo
                json.commonIds.indent_date = dt_sales_order_date
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME

                const formData = new FormData();
                formData.append(`StIndentData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnAddUpdateRecord/${isApprove}`, requestOptions)
                const responce = await apicall.json();
                setHandelSuccessModal('indent')
                if (responce.success === "0") {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    setSuccMsg(responce.message)
                    if (responce.data.hasOwnProperty('indent_no') && responce.data.hasOwnProperty('indent_version')) {
                        setModalOrderDetails(`Indent No: ${responce.data.indent_no} and Version: ${responce.data.indent_version}`)
                    }
                    setShowSuccessMsgModal(true);
                }
                setIsLoading(false)
                console.log(json)
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }


    let group_id = txt_sales_order_no.replace(/\//g, "_");
    const memorizedDocumentComponent = useMemo(() => {
        return <>
            {/* Document modal */}
            <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <Document group_id={group_id} document_group={docGroup} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }, [showDocumentForm])


    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <ExcelExport ref={exlsExp} />
                <GenerateTAutoNo ref={generateAutoNoAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />


                {/* <div className="erp_top_Form pb-5" id="SalesOrderComp"> */}
                <>
                    <div className="spinner-overlay" id='spinner_id' style={{ display: "none" }}>
                        <div className="spinner-container">
                            <CircularProgress color="primary" className="text-primary" />
                            <span id="spinner_text" className="text-dark">Loading...</span>
                        </div>
                    </div>

                    <div className='card p-1'>
                        <div className='card-header text-center py-1'>
                            <label className='erp-form-label-lg text-center'>Sales Order {requestTypeHeading()} {ActionType()}</label>
                        </div>

                        <form id="salesOrderFormId">
                            <div className="row p-1">
                                {/* first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Order Creation Type <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Mannual" type="radio" lbl="Mannual" value="M" name="rb_sales_order_creation_type" checked={rb_sales_order_creation_type === "M"} onClick={(e) => { setSalesOrdCreationType('M'); FnComboOnChange('CreationType'); validateFields(); }} /> </div>
                                                {/* <div className="fCheck"> <Form.Check className="erp_radio_button" label="Quotation Based" type="radio" lbl="Quotation Based" value="Q" name="rb_sales_order_creation_type" checked={rb_sales_order_creation_type === "Q"} onClick={(e) => { setSalesOrdCreationType('Q'); FnComboOnChange('CreationType'); validateFields(); }} /> </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Order Type <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_sales_order_type_id" className="form-select form-select-sm" value={cmb_sales_order_type_id} onChange={() => { FnComboOnChange('SalesOrderType'); validateFields(); }} disabled={keyForViewUpdate === '' && requestfor === 'NA' ? false : true} >
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {salesOrdTypesOpts.length !== 0 ? (
                                                    <>
                                                        {salesOrdTypesOpts.map(orderType => (
                                                            <option key={orderType.field_id} value={orderType.field_id} lbl={orderType.field_name} shortName={`${orderType.product_type_short_name}`}> {orderType.field_name} </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sales_order_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {/* let SOTypeShortName = $('#cmb_sales_order_type_id option:selected').attr('shortName'); */}
                                    {($('#cmb_sales_order_type_id option:selected').attr('shortName') === 'FF' || $('#cmb_sales_order_type_id option:selected').attr('shortName') === 'GF' || $('#cmb_sales_order_type_id option:selected').attr('shortName') === 'SB') && (
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">Job Type </Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id="cmb_sales_job_type" className="form-select form-select-sm" value={cmb_sales_job_type} onChange={(e) => { setSalesOrderJob(e.target.value); validateFields(); }} optional='optional' >
                                                    <option value="" >Select</option>
                                                    {jobTypeArr?.map(jobtype => (
                                                        <option value={jobtype.field_name}>{jobtype.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_sales_job_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    )}

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Sales Type & Status <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_sales_type_id" className="form-select form-select-sm" value={cmb_sales_type_id} onChange={(e) => { setSalesTypeId(e.target.value); validateFields(); }} >
                                                        <option value="" disabled>Select</option>
                                                        {salesOrderTypeArr?.map(type => (
                                                            <option value={type.field_id}>{type.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_sales_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 ps-1">
                                                    <select id="cmb_sales_order_status" className="form-select form-select-sm" value={cmb_sales_order_status} onChange={(e) => { setSalesOrdStatus(e.target.value); validateFields(); }} disabled='disabled' >
                                                        <option value="P" lbl="Pending">Pending</option>
                                                        <option value="A" lbl="Approved">Approved</option>
                                                        <option value="R" lbl="Rejected">Rejected</option>
                                                        {
                                                            keyForViewUpdate === 'approve'
                                                                ? ''
                                                                : <><option value="I" lbl="Partial Issue">Partial Issue</option>
                                                                    <option value="C" lbl="Completed">Completed</option>
                                                                    <option value="X" lbl="Canceled">Canceled</option>
                                                                    <option value="Z" lbl="PreeClosed">PreeClosed</option>
                                                                </>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">SO No., Ver. & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_sales_order_no" className="erp_input_field" value={txt_sales_order_no} disabled />
                                                    <MDTypography variant="button" id="error_txt_sales_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pe-md-0 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="text" id='txt_sales_order_version' className="erp_input_field text-end" value={txt_sales_order_version} disabled />
                                                    <MDTypography variant="button" id="error_txt_sales_order_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="date" id='dt_sales_order_date' className="erp_input_field" value={dt_sales_order_date} onChange={(e) => { setSalesOrdDate(e.target.value); validateFields(); }} />
                                                    <MDTypography variant="button" id="error_dt_sales_order_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Order Life & Status <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <div className="erp_form_radio">
                                                        <div className="fCheck"> <Form.Check className="erp_radio_button" label="Closed" type="radio" lbl="Closed" value="C" name="rb_sales_order_life" checked={rb_sales_order_life === "C"} onClick={() => setSalesOrdLife("C")} /> </div>
                                                        <div className="sCheck ps-3"> <Form.Check className="erp_radio_button" label="Open" type="radio" lbl="Open" value="O" name="rb_sales_order_life" checked={rb_sales_order_life === "O"} onClick={() => setSalesOrdLife("O")} /> </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 ps-1">
                                                    <select id="cmb_sales_order_status" className="form-select form-select-sm" value={cmb_sales_order_status} onChange={(e) => { setSalesOrdStatus(e.target.value); validateFields(); }} disabled='disabled' >
                                                        <option value="P" lbl="Pending">Pending</option>
                                                        <option value="A" lbl="Approved">Approved</option>
                                                        <option value="R" lbl="Rejected">Rejected</option>
                                                        {
                                                            keyForViewUpdate === 'approve'
                                                                ? ''
                                                                : <><option value="I" lbl="Partial Issue">Partial Issue</option>
                                                                    <option value="C" lbl="Completed">Completed</option>
                                                                    <option value="X" lbl="Canceled">Canceled</option>
                                                                    <option value="Z" lbl="PreeClosed">PreeClosed</option>
                                                                </>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className='row mb-1'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Customer<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <Select
                                                options={options}
                                                inputId="cmb_customer_id" // Provide the ID for the input box
                                                value={options.find(option => option.value === cmb_customer_id)}
                                                onChange={handleChange} // Update state with selected value
                                                placeholder="Search for a customer..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                        <div className='col-sm-1 col-2 refreshIconCol'>
                                            <Tooltip title="Refresh" placement="right">
                                                <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateCustomers'); }} />
                                            </Tooltip>
                                        </div>
                                    </div>




                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Customer Order No. & Date</Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">

                                                <div className="col-12 col-md-8 pe-md-0">
                                                    <Form.Control type="text" id='txt_customer_order_no' className="erp_input_field" value={txt_customer_order_no} onChange={(e) => { setCustomerOrderNo(e.target.value); validateFields(); }} onBlur={(e) => { FnComboOnChange('CustomerOrderNo'); }} optional="optional" maxlength="500" />
                                                    <MDTypography variant="button" id="error_txt_customer_order_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="date" id='dt_customer_order_Date' className="erp_input_field" value={dt_customer_order_Date} optional="optional" onChange={(e) => { setCustomerOrdDate(e.target.value); validateFields(); }} />
                                                    <MDTypography variant="button" id="error_dt_customer_order_Date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Customer State & City <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} onChange={(e) => { setCustomerState(e.target.value); FnComboOnChange('CustomerState'); validateFields(); }} disabled='disabled'  >
                                                        <option value="">Select</option>
                                                        {stateOpts?.map(state => (
                                                            <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} onChange={(e) => { setCustomerCity(e.target.value); FnComboOnChange('CustomerCity'); validateFields(); }} optional="optional" disabled='disabled'>
                                                        <option value="">Select</option>
                                                        <option value="0">Add New Record+</option>
                                                        {customerCityOpts?.map(city => (
                                                            <option value={city.field_id}>{city.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Customer State <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} onChange={(e) => { setCustomerState(e.target.value); FnComboOnChange('CustomerState'); validateFields(); }} disabled='disabled'>
                                                <option value="" disabled>Select</option>
                                                {stateOpts?.map(state => (
                                                    <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Customer City</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} onChange={(e) => { setCustomerCity(e.target.value); FnComboOnChange('CustomerCity'); validateFields(); }} optional="optional" disabled='disabled'>
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {customerCityOpts?.map(city => (
                                                    <option value={city.field_id}>{city.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    <div className="row mt-3 mt-md-0">
                                        <Accordion defaultActiveKey="0" >
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="erp-form-label-md">Customer's Contact Details</Accordion.Header>
                                                <Accordion.Body className="p-0">
                                                    {
                                                        cmb_customer_id !== '' && cmb_customer_id !== '0'
                                                            ? <>
                                                                <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                                    <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                    }
                                                                    }  ><Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add</Link></MDButton>
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContactPersonsDetails(parseInt(cmb_customer_id)) }}>Refresh</MDButton>
                                                                </div>
                                                            </>
                                                            : null
                                                    }

                                                    {
                                                        custContactDetails.length > 0
                                                            ? <>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <Table className="erp_table erp_table_scroll" id='customerContactPersonsTable' responsive bordered striped>
                                                                            <thead className="erp_table_head">
                                                                                <tr>
                                                                                    <th className={`erp_table_th`}>
                                                                                        <div className="col-1">
                                                                                            <input type='checkbox' class="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { checkBoxesSelection('selectAllCustContact'); }} />
                                                                                            Select
                                                                                        </div>
                                                                                    </th>
                                                                                    <th className='erp_table_th'>Customer Branch</th>
                                                                                    <th className='erp_table_th'>Contact Person</th>
                                                                                    <th className='erp_table_th'>Contact No</th>
                                                                                    <th className={`erp_table_th `}>Email</th>
                                                                                    <th className={`erp_table_th`}>Alternate Email</th>
                                                                                    <th className={`erp_table_th`}>Alternate Contact No</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    custContactDetails.map((item, index) =>
                                                                                        <tr rowindex={index}>
                                                                                            <td className='erp_table_td'>
                                                                                                <input type='checkbox' className="form-check-input selectCustContactPerson" name="selectCustContactPerson"
                                                                                                    id={'selectCustContactPerson_' + item.customer_contact_id} contact_personId={item.customer_contact_id}
                                                                                                    value={item.customer_contact_id} onClick={() => checkBoxesSelection('PartiallyCustContactSelection')} />
                                                                                            </td>
                                                                                            <td className="erp_table_td">{item.customer_branch_name}</td>
                                                                                            <td className="erp_table_td">{item.cust_contact_person}</td>
                                                                                            <td className="erp_table_td"> {item.cust_contact_no} </td>
                                                                                            <td className={`erp_table_td`}>{item.cust_email_id}</td>
                                                                                            <td className={`erp_table_td`}>{item.cust_alternate_EmailId}</td>
                                                                                            <td className={`erp_table_td`}>{item.cust_alternate_contact}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : ""
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion >
                                        <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                {/* second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 mt-3 mt-md-0'>
                                            <Form.Label className="erp-form-label">Company Branch <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_companyBranchId" className="form-select form-select-sm" value={cmb_companyBranchId} onChange={(e) => { if (e.target.value !== '0') { setCompanyBranchId(e.target.value) }; FnComboOnChange('CompanyBranch'); validateFields(); }}>
                                                <option value="" disabled>Select</option>
                                                {/* <option value="0">Add New Record+</option> */}
                                                {companyBranchesOpts?.map(branch => (
                                                    <option key={branch.company_branch_id} value={branch.company_branch_id}> {branch.company_branch_name} </option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_companyBranchId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Company State <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_companyBranchStateId" className="form-select form-select-sm" value={cmb_companyBranchStateId} onChange={(e) => { setCompanyBranchStateId(e.target.value); FnComboOnChange('CompanyBranchState'); validateFields(); }} disabled="disabled">
                                                <option value="" disabled>Select</option>
                                                {stateOpts?.map(state => (
                                                    <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_companyBranchStateId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Company City <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_companyBranchCityId" className="form-select form-select-sm" value={cmb_companyBranchCityId} onChange={(e) => { setCompanyBranchCityId(e.target.value); FnComboOnChange('CompanyBranchCity'); validateFields(); }} disabled="disabled">
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {companyBrancheCityOpts?.map(city => (
                                                    <option value={city.field_id}>{city.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_companyBranchCityId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    <div className='row d-none'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Comp. Br. State & City</Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_companyBranchStateId" className="form-select form-select-sm" value={cmb_companyBranchStateId} onChange={(e) => { setCompanyBranchStateId(e.target.value); FnComboOnChange('CompanyBranchState'); validateFields(); }} disabled="disabled">
                                                        <option value="">Select</option>
                                                        {stateOpts?.map(state => (
                                                            <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_companyBranchStateId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <select id="cmb_companyBranchCityId" className="form-select form-select-sm" value={cmb_companyBranchCityId} onChange={(e) => { setCompanyBranchCityId(e.target.value); FnComboOnChange('CompanyBranchCity'); validateFields(); }} disabled="disabled">
                                                        <option value="">Select</option>
                                                        <option value="0">Add New Record+</option>
                                                        {companyBrancheCityOpts?.map(city => (
                                                            <option value={city.field_id}>{city.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_companyBranchCityId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Select ref={departmentComboRef}
                                                options={deptOpts}
                                                // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_department_id" // Provide the ID for the input box
                                                value={deptOpts.find(option => option.value === cmb_department_id) || null}
                                                // isDisabled={requestfor==="GF"}
                                                isDisabled
                                                onChange={(selectedOpt) => {
                                                    setDeptId(selectedOpt.value);
                                                    departmentComboRef.current = selectedOpt;
                                                    FnComboOnChange('Department');
                                                    validateFields();
                                                }}
                                                placeholder="Search for department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Sub Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Select ref={subDepartmentComboRef}
                                                options={subDepartmentOPtions}
                                                isDisabled={requestfor==="GF"}
                                                // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_sub_department" // Provide the ID for the input box
                                                value={subDepartmentOPtions.find(option => option.value === cmb_sub_department) || null}
                                                onChange={(selectedOpt) => {
                                                    setSubDepartmentId(selectedOpt.value);
                                                    subDepartmentComboRef.current = selectedOpt;
                                                    FnComboOnChange('subDepartment');
                                                    validateFields();
                                                }}
                                                placeholder="Search for sub-department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_sub_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                            ?

                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Apprv. By & Date <span className="required">*</span></Form.Label>
                                                </div>
                                                <div className="col-sm-9 col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-8 pe-md-0">
                                                            <select id="cmb_approved_by_id" className="form-select form-select-sm" value={cmb_approved_by_id} onChange={(e) => { setApprovedById(e.target.value); validateFields(); }} >
                                                                <option value="" disabled>Select</option>
                                                                <option value="0">Add New Record+</option>
                                                                {approveByOpts?.map(employee => (
                                                                    <option value={employee.field_id}>{employee.field_name}</option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                        <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                            <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={(e) => { setApproveDate(e.target.value); validateFields(); }} />
                                                            <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Expec. Sch. Date <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id='dt_overall_schedule_date' className="erp_input_field" value={dt_overall_schedule_date} onChange={(e) => { setOverAllScheduleDate(e.target.value); validateFields(); }} min={currentDate} />
                                            <MDTypography variant="button" id="error_dt_overall_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Consignee</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            {/* <select id="cmb_consignee_id" className="form-select form-select-sm" value={cmb_consignee_id} onChange={(e) => { setConsigneeId(e.target.value); FnComboOnChange('Consignee'); validateFields(); }} optional="optional"> */}
                                            <Select
                                                ref={consigneeComboRef}
                                                options={consigneeOpts}
                                                inputId="cmb_consignee_id" // Provide the ID for the input box
                                                value={consigneeOpts.find(option => option.value === cmb_consignee_id)}
                                                onChange={(selectedOpt) => {
                                                    setConsigneeId(selectedOpt.value);
                                                    consigneeComboRef.current = selectedOpt;
                                                    FnComboOnChange("Consignee");
                                                    // validateFields();
                                                }}
                                                placeholder="Search for a Consignee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className='col-sm-1 col-2 refreshIconCol'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateConsignees'); }} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Consignee State & City</Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_consignee_state_id" className="form-select form-select-sm" value={cmb_consignee_state_id} onChange={(e) => { setConsigneeState(e.target.value); FnComboOnChange('ConsigneeState'); validateFields(); }} optional="optional">
                                                        <option value="">Select</option>
                                                        {stateOpts?.map(state => (
                                                            <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <select id="cmb_consignee_city_id" className="form-select form-select-sm" value={cmb_consignee_city_id} onChange={(e) => { setConsigneeCity(e.target.value); FnComboOnChange('ConsigneeCity'); }} optional="optional">
                                                        <option value="">Select</option>
                                                        <option value="0">Add New Record+</option>
                                                        {consigneeCityOpts?.map(city => (
                                                            <option value={city.field_id}>{city.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Consignee State</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_consignee_state_id" className="form-select form-select-sm" value={cmb_consignee_state_id} onChange={(e) => { setConsigneeState(e.target.value); FnComboOnChange('ConsigneeState'); validateFields(); }} optional="optional">
                                                <option value="" disabled>Select</option>
                                                {stateOpts?.map(state => (
                                                    <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Consignee City</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_consignee_city_id" className="form-select form-select-sm" value={cmb_consignee_city_id} onChange={(e) => { setConsigneeCity(e.target.value); FnComboOnChange('ConsigneeCity'); }} optional="optional">
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {consigneeCityOpts?.map(city => (
                                                    <option value={city.field_id}>{city.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_txt_area" value={txt_remark} onInput={e => { setRemark(e.target.value); }} maxlength="1000" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Order IsActive</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="rb_is_active" checked={rb_is_active === "true"} onClick={() => setIsActive("true")} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="rb_is_active" checked={rb_is_active === "false"} onClick={() => setIsActive("false")} /> </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Supplier & Lot No.</Form.Label>
                                        </div>
                                        <div className='col-sm-9'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Select
                                                        options={supplierBranchOpts}
                                                        inputId="cmb_supplier_branch_id"
                                                        isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                        value={supplierBranchOpts.find(option => option.value === cmb_supplier_branch_id)}
                                                        onChange={(selectedOpt) => {
                                                            setSupplierBranchId(selectedOpt.value);
                                                            validateFields();
                                                        }}
                                                        placeholder="Search for a Supplier..."
                                                        className="form-search-custom"
                                                        classNamePrefix="custom-select" // Add custom prefix for class names
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            })
                                                        }}
                                                    />
                                                    <MDTypography variant="button" id="error_cmb_supplier_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_lot_no" className="erp_input_field" value={txt_lot_no} onInput={e => { setLotNo(e.target.value); validateFields(); }} maxlength="500" optional="optional" />
                                                    <MDTypography variant="button" id="error_txt_lot_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                    <hr />

                    <Accordion defaultActiveKey={'1'} id="orderDetailsAccordion">
                        <Accordion.Item eventKey={`1`} id="orderDetailsAccordionItem" >
                            <Accordion.Header id="orderDetailsAccordionHeader" className="erp-form-label-md">Sales Order Details</Accordion.Header>
                            <Accordion.Body id="orderDetailsAccordionBody">
                                <div className="row justify-content-between">
                                    <div className="col-lg-5 col-12">
                                        <div className="row">
                                            {
                                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && rb_sales_order_creation_type === 'M'
                                                    ? <>
                                                        <div className="col-sm-9">
                                                            <SearchItemFilter getSearchData={FnCloseFilterModal} formIdForValidate="salesOrderFormId" requiredCols={requiredColsForOpenFilter}
                                                                product_type_id={requestfor === 'SB' ? '12' : requestfor === 'GF' ? cmb_sales_order_type_id : 0} isMainGodown={true} godownId={'2'} />
                                                        </div>

                                                        <div className="col-md-3 col-12" style={{ display: "none" }}>
                                                            <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={viewBomFilterForm} >Add Materials</MDButton>
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                            {/* <div className="col-md-8 col-12">
                                                <div className="row">
                                                    <div className="col-12 col-sm-9 pe-md-0 pt-1">
                                                        <Form.Control type="text" id="txt-detail-data-highliter" className={`erp_input_field ${salesOrdDetailsData.length <= 0 ? 'd-none' : ""}`} />
                                                    </div>
                                                    <div className="col-12 col-sm-3 pt-2 pt-md-0">
                                                        <MDButton type="button" className={`erp-gb-button ${salesOrdDetailsData.length <= 0 ? 'd-none' : ""}`} variant="button" onClick={() => scrollToTableRow()} fontWeight="regular">Search</MDButton>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-5 col-12">
                                        <div className='row pt-md-1 pt-0 pt-lg-0'>
                                            <div className='col-sm-3 pt-lg-0 pt-1 px-0'>
                                                <Form.Label className="erp-form-label">Quotation No.& Date {
                                                    rb_sales_order_creation_type === 'Q'
                                                        ? <span className="required">*</span>
                                                        : ''}
                                                </Form.Label>
                                            </div>
                                            <div className="col-sm-9 col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-8 pe-md-0 ps-md-1">
                                                        <select id="cmb_sales_quotation_no" className="form-select form-select-sm" value={cmb_sales_quotation_no} onChange={(e) => { setSalesQuotationNo(e.target.value); FnComboOnChange('Quotation'); }} {...rb_sales_order_creation_type === 'M' ? { optional: 'optional', disabled: 'disabled' } : {}}>
                                                            <option value="">Select</option> 
                                                            {quotationOpts?.map(quotation => (
                                                                <option value={quotation.quotation_no} quotation_date={quotation.quotation_date}>{quotation.quotation_no}</option>
                                                            ))}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_sales_quotation_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-md-4 pt-md-0 pt-3 pe-1 pe-sm-0 ps-md-1">
                                                        <Form.Control type="date" id='dt_sales_quotation_date' className="erp_input_field" value={dt_sales_quotation_date} onChange={(e) => { setSalesQuotationDate(e.target.value); validateFields(); }} {...rb_sales_order_creation_type === 'M' ? { optional: 'optional' } : {}} disabled='disabled' />
                                                        <MDTypography variant="button" id="error_dt_sales_quotation_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div> */}

                                    <div className="col-lg-2 col-12">
                                        <div className='d-flex justify-content-end pt-3 pt-md-0'>
                                            <MDButton type="button" id="export-btn" variant="button" onClick={ExportToExcel} fontWeight="regular" className={`erp-gb-button float-end ms-2 ${keyForViewUpdate === 'view' || isApprove ? 'd-none' : 'display'}`} > Export </MDButton>
                                            {/* <MDButton type="button" id="import-btn" className={`erp-gb-button float-end ms-2 ${keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ? 'd-none' : 'display'}`} variant="button" onClick={onImportClick} fontWeight="regular" > Import </MDButton> */}
                                            {/* <MDButton type="button" id="export-btn" className={`erp-gb-button float-end ms-2 ${keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ? 'd-none' : 'display'}`} variant="button" onClick={ExportToExcel} fontWeight="regular" disabled={salesOrdDetailsData.length <= 0 ? 'disabled' : ''}>Export</MDButton>
                                            <MDButton type="button" id="import-btn" className={`erp-gb-button float-end ms-2 ${keyForViewUpdate === 'view' || isApprove ? 'd-none' : 'display'}`} variant="button" onClick={onImportClick} fontWeight="regular">Import</MDButton> */}
                                        </div>
                                    </div>

                                </div>
                                <div className="row py-1 py-md-3">
                                    {renderDetailsTable}
                                </div>
                                {/* Schedules nested accordion. */}
                                <Accordion defaultActiveKey='1' id="schedulesAccordion">
                                    <Accordion.Item eventKey='0' id="schedulesAccordionItem">
                                        <Accordion.Header id="schedulesAccordionHeader" className="erp-form-label-md">Product Schedules</Accordion.Header>
                                        <Accordion.Body id="schedulesAccordionBody">
                                            <div className="row">
                                                <div className={`table-responsive ${scheduleDataArray.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                    {scheduleDataArray.length > 0
                                                        ? <>{renderSchedulesTable}</>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                {/* Raw Material Requirement nested accordion. */}
                                {/* {requestfor === "GF" || requestfor === "FF" ?
                                    <>
                                        <hr />
                                        <Accordion defaultActiveKey="0" >
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="erp-form-label-md">Raw Material Requirement</Accordion.Header>
                                                <Accordion.Body>
                                                    {
                                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && rb_sales_order_creation_type === 'M'
                                                            ? <>
                                                                <div className="row justify-content-between mb-2">
                                                                    <div className="col-sm-6 col-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-6 col-12">
                                                                                <MaterialSearchBar getSearchData={FngetRawMaterialRequirement} formIdForValidate="" requiredCols={requiredColsForRMRequirement} product_type_id={12} godownId= {2} 
                                                                                // product_category1_id={1} product_category2_id={5} 
                                                                                />
                                                                            </div>
                                                                         
                                                                        </div>
                                                                    </div>
                                                                   
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    {renderRawMaterialRequairment}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion >
                                    </>
                                    :
                                    null
                                } */}

                                {/* Sales order items tracking accordion */}
                                {salesOrdDetailsTrackingData.length > 0
                                    ?
                                    <>
                                        <hr />
                                        <Accordion defaultActiveKey='1' id="soItemsTrackingAccordion">
                                            <Accordion.Item eventKey='0' id="soItemsTrackingAccordionItem">
                                                <Accordion.Header id="soItemsTrackingAccordionHeader" className="erp-form-label-md">Materials Tracking Details</Accordion.Header>
                                                <Accordion.Body id="soItemsTrackingAccordionBody">
                                                    <div className="row">
                                                        <div className={`table-responsive ${salesOrdDetailsTrackingData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                            <> {renderDetailsTrackingTbl}</>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </>
                                    : ''}


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                    <hr />
                    {(requestfor === "GF") && (
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="erp-form-label-md">Raw Material Requirement</Accordion.Header>
                                <Accordion.Body className="">
                                    <div className="row">
                                        <div className={`table-responsive ${saudaShitSummaryData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                            <>{renderSoudaShitSummaryTable}</>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    )}

                    <hr />

                    {/* <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="erp-form-label-md">Product Based Properties</Accordion.Header>
                            <Accordion.Body>
                                {productTypePropertiesData.length !== 0 ? (<>
                                    <div className="table-responsive erp_table_scroll">
                                        {renderProductPropertiesTbl}
                                    </div>
                                </>
                                ) : (
                                    <div className='row text-center'>
                                        <div className="col-12">
                                            <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                No Records Found...!
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <hr /> */}

                    <Accordion defaultActiveKey="1" onSelect={FnLoadAccordionData} >
                        <Accordion.Item eventKey="paymentTerms">
                            <Accordion.Header className="erp-form-label-md">Payment Terms</Accordion.Header>
                            <Accordion.Body className="ps-1 pt-0">
                                {
                                    PmtTermsdata.length > 0
                                        ? <>
                                            <div className={`row py-1 px-0 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                <div className="col-12 col-sm-6">
                                                    {/* <input type='checkbox' class="" id="selectAllPmtTerms" onClick={(e) => checkBoxesSelection('selectAllPmtTerms')} /> <label class="erp-form-label pb-1"> Select All Payment Terms. </label> */}
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <MDButton type="button" className={`erp-gb-button float-sm-end col-1`} variant="button" fontWeight="regular"
                                                        onClick={() => {
                                                            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                            setHeaderName('Payment Terms'); // CommonParamterEntry
                                                            setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                                        }}>Add
                                                    </MDButton>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className={`table-responsive ${PmtTermsdata.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                    <>{renderPaymentTermsTable}</>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                    <hr />
                    <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                        <Accordion.Item eventKey="CommonTermsAccord">
                            <Accordion.Header className="erp-form-label-md">Common Terms</Accordion.Header>
                            <Accordion.Body className="p-1 pt-0">
                                <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                    <div className="col-12 col-sm-6">
                                        <input type='checkbox' class="" id="selectAllCommonTerms" onClick={(e) => checkBoxesSelection('selectAllCommonTerms')} /> <label class="erp-form-label pb-1"> Select All. </label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <MDButton type="button" className={`erp-gb-button float-sm-end col-1`} variant="button" fontWeight="regular"
                                            onClick={() => {
                                                sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                setHeaderName('Common Parameter'); // CommonParamterEntry
                                                setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                            }}>Add
                                        </MDButton>
                                    </div>
                                </div>
                                <div className={`table-responsive ${SOCommonTermsData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                    <>{renderCommonTermsTable}</>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <hr />

                    <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey='1'>
                            <Accordion.Header className="erp-form-label-md">Taxation Summary</Accordion.Header>
                            <Accordion.Body className="">
                                <div className="row">
                                    <div className={`table-responsive ${taxationSummaryData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                        <>{renderTaxationSummaryTable}</>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >
                    <hr />

                      <Accordion defaultActiveKey={docData.length > 0 ? "1" : "0"} onSelect={FnLoadAccordionData}>
                        <Accordion.Item eventKey="documentList">
                            <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                            <Accordion.Body>
                                <div className={`table-responsive ${docData.length > 4 ? 'erp_table_scroll' : ''}`}>
                                    {docData.length !== 0 ? (
                                        renderDocumentTable
                                    ) : (null)}
                                </div>
                                {docData.length === 0
                                    ? <div className='row'>
                                        <div className="col-12 text-center">
                                            <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                No Records Found...
                                            </span>
                                        </div>
                                    </div>
                                    : null
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <hr/>

                    {/* Billing Section */}
                    <div className="card">
                        <div className="card-header main_heding text-start py-1">
                            <label className='erp-form-label-lg'>Sales Order Totals</label>
                        </div>

                        <div className="card-body">
                            <form id="salesOrderTotalsFrm">
                                <div className="row">
                                    {/* first column */}
                                    <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Basic Total</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_basic_total' className="erp_input_field text-end" value={txt_basic_total} readOnly />
                                                <MDTypography variant="button" id="error_txt_basic_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Transport Amt.</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_transport_amount' className="erp_input_field text-end" value={txt_transport_amount} maxLength='19' optional="optional"
                                                    onChange={(e) => { setTransportAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                    onBlur={(e) => { setTransportAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                />
                                                <MDTypography variant="button" id="error_txt_transport_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row mb-md-3 mb-xl-0'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label">Freight Amt.</Form.Label>
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 col-xl-3">
                                                        <Form.Check className="erp-form-label" type="checkbox" label={`IsTaxable`} id="chk_freight_isTaxable" checked={chk_freight_isTaxable} onChange={async (e) => { setFreightIsTaxable(e.target.checked); validateFields(); calculateGrandTotal(salesOrdDetailsData); }} optional="optional" />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-3 pt-xl-0 pe-xl-0 me-xl-0">
                                                        <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount} maxLength='19' optional="optional"
                                                            onChange={async (e) => { setFreightAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                            onBlur={async (e) => { setFreightAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4) === '' ? 0 : validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); reverseCalculationForFreightAmt(); }}
                                                        />
                                                        <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-xl-6 pt-md-0 pt-3 ps-xl-1">
                                                        <select id="cmb_freight_hsn_id" className="form-select form-select-sm" value={cmb_freight_hsn_id} onChange={async (e) => { setFreightHSNId(e.target.value); calculateGrandTotal(salesOrdDetailsData); validateFields() }} disabled={chk_freight_isTaxable ? false : true} {...(chk_freight_isTaxable ? {} : { optional: 'optional' })}>
                                                            <option value="">Select</option>
                                                            {freightHSNOpts?.map(hsnCode => (
                                                                <option value={hsnCode.hsn_sac_id} rate={hsnCode.hsn_sac_rate} lbl={hsnCode.hsn_sac_code}>{hsnCode.hsn_sac_code}</option>
                                                            ))}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_freight_hsn_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Packaging Amt. </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_packing_amount' className="erp_input_field text-end" value={txt_packing_amount} maxLength='19' optional="optional"
                                                    onChange={(e) => { setPackingAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                    onBlur={(e) => { setPackingAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                />
                                                <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row' style={{ display: "none" }}>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Discount % </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_discount_percent' className="erp_input_field  text-end" value={txt_discount_percent} optional="optional"
                                                    onChange={(e) => { handleFooterDiscountPercentChange(e); }} onBlur={(e) => { handleFooterDiscountPercentChange(e); }} />
                                                <MDTypography variant="button" id="error_txt_discount_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row' style={{ display: "none" }}>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Discount Amt.</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_discount_amount' className="erp_input_field text-end" value={txt_discount_amount} optional="optional"
                                                    onChange={(e) => { handleFooterDiscountAmountChange(e) }} onBlur={(e) => { handleFooterDiscountAmountChange(e) }} />
                                                <MDTypography variant="button" id="error_txt_discount_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Other Amt. </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_other_amount' className="erp_input_field text-end" value={txt_other_amount} maxLength='19' optional="optional"
                                                    onChange={(e) => { setOtherAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                    onBlur={(e) => { setOtherAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                />
                                                <MDTypography variant="button" id="error_txt_other_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Taxable Total</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_taxable_total' className="erp_input_field text-end" value={txt_taxable_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_taxable_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                    </div>

                                    {/* second column */}
                                    <div className="col-lg-3 col-md-6 col-12 erp_form_col_div">


                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-4 pe-0">
                                                <Form.Label className="erp-form-label"> CGST Total </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_cgst_total' className="erp_input_field text-end" value={txt_cgst_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_cgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-4 pe-0">
                                                <Form.Label className="erp-form-label"> SGST Total </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_sgst_total' className="erp_input_field text-end" value={txt_sgst_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_sgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-4 pe-0">
                                                <Form.Label className="erp-form-label"> IGST Total </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_igst_total' className="erp_input_field text-end" value={txt_igst_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_igst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-4 pe-0">
                                                <Form.Label className="erp-form-label"> Grand Total </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_grand_total' className="erp_input_field text-end" value={txt_grand_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_grand_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-4 pe-0">
                                                <Form.Label className="erp-form-label"> Round Off </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_roundoff' className="erp_input_field text-end" value={txt_roundoff} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_roundoff" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3 col-lg-4 pe-0">
                                                <Form.Label className="erp-form-label">Mail Send Status</Form.Label>
                                            </div>
                                            <div className="col">
                                                <div className="erp_form_radio">
                                                    <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="rb_sales_order_mail_sent_status" checked={rb_sales_order_mail_sent_status === "P"} onClick={(e) => { setSalesOrderMailSentStatus('P'); }} disabled='disabled' optional="optional" /> </div>
                                                    <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="Sent" type="radio" lbl="Sent" value="S" name="rb_sales_order_mail_sent_status" checked={rb_sales_order_mail_sent_status === "S"} onClick={(e) => { setSalesOrderMailSentStatus('S') }} disabled='disabled' optional="optional" /> </div>
                                                    <div className="fCheck"> <Form.Check className="erp_radio_button" label="Canceled" type="radio" lbl="Failed" value="F" name="rb_sales_order_mail_sent_status" checked={rb_sales_order_mail_sent_status === "F"} onClick={(e) => { setSalesOrderMailSentStatus('F') }} disabled='disabled' optional="optional" /> </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className='row'>
                                            <div className='col-sm-3 col-12'>
                                                <Form.Label className="erp-form-label">Agents</Form.Label>
                                            </div>
                                            <div className='col-sm-8 col-10'>
                                                <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); FnComboOnChange('Agent') }} optional="optional" >
                                                    <option value="" disabled>Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {agentOpts?.map(agent => (
                                                        <option value={agent.field_id} lbl={agent.field_name}>{agent.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className='col-sm-1 col-2'>
                                                <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateAgents'); }} />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 pe-0">
                                                <Form.Label className="erp-form-label"> Agent's %  </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_agent_percent' className="erp_input_field text-end" value={txt_agent_percent} onChange={(e) => { setAgentPercent(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }} maxLength='5' optional="optional" />
                                                <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div> */}

                                        {/* <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">Agent's Paid Status</Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id="cmb_agent_paid_status" className="form-select form-select-sm" value={cmb_agent_paid_status} onChange={(e) => { setAgentPaidStatus(e.target.value); }} optional="optional"  >
                                                    <option value="P" lbl="Pending">Pending</option>
                                                    <option value="A" lbl="Approved">Approved</option>
                                                    <option value="C" lbl="Completed">Completed</option>
                                                    <option value="X" lbl="Canceled">Canceled</option>
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_sales_order_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div> */}

                                    </div>

                                    {/* third column */}
                                    <div className="col-lg-5 col-md-6 col-12 erp_form_col_div">

                                        <div className='row'>
                                            <div className='col-sm-3 col-12'>
                                                <Form.Label className="erp-form-label">Agent & Percentage</Form.Label>
                                            </div>
                                            <div className='col-sm-9 col-12'>
                                                <div className="row">
                                                    <div className="col-12 col-md-8 pe-md-0">
                                                        <Select
                                                            options={agentOpts}
                                                            inputId="cmb_agent_id" // Provide the ID for the input box
                                                            value={agentOpts.find(option => option.value === cmb_agent_id)}

                                                            onChange={(selectedOpt) => {
                                                                setAgentId(selectedOpt.value);
                                                                agentComboRef.current = selectedOpt;
                                                                FnComboOnChange('Agent')
                                                                // validateFields();
                                                            }
                                                            } // Update state with selected value
                                                            placeholder="Search for a agent..."
                                                            className="form-search-custom"
                                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                                            styles={{
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                                }),
                                                                singleValue: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                                }),
                                                                input: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                                })
                                                            }}
                                                        />
                                                        <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-10 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                        <Form.Control type="text" id='txt_agent_percent' className="erp_input_field text-end" value={txt_agent_percent} onChange={(e) => { setAgentPercent(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }} maxLength='5' optional="optional" />
                                                        <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-2 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1 text-center refreshIconCol">
                                                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateAgents'); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-3 col-12'>
                                                <Form.Label className="erp-form-label">Agent's Paid Status</Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id="cmb_agent_paid_status" className="form-select form-select-sm" value={cmb_agent_paid_status} onChange={(e) => { setAgentPaidStatus(e.target.value); }} optional="optional"  >
                                                    <option value="P" lbl="Pending">Pending</option>
                                                    <option value="A" lbl="Approved">Approved</option>
                                                    <option value="C" lbl="Completed">Completed</option>
                                                    <option value="X" lbl="Canceled">Canceled</option>
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_sales_order_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3 col-12">
                                                <Form.Label className="erp-form-label">Ord. Accept Status <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-3 col-12">
                                                <div className="erp_form_radio">
                                                    <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="rb_sales_order_acceptance_status" checked={rb_sales_order_acceptance_status === "P"} onClick={(e) => { setSalesOrdAcceptanceStatus('P'); }} disabled={!isApprove} /> </div>
                                                    <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="Aprroved" type="radio" lbl="Aprroved" value="A" name="rb_sales_order_acceptance_status" checked={rb_sales_order_acceptance_status === "A"} onClick={(e) => { setSalesOrdAcceptanceStatus('A') }} disabled={!isApprove} /> </div>
                                                    <div className="fCheck"> <Form.Check className="erp_radio_button" label="Canceled" type="radio" lbl="Canceled" value="X" name="rb_sales_order_acceptance_status" checked={rb_sales_order_acceptance_status === "X"} onClick={(e) => { setSalesOrdAcceptanceStatus('X') }} disabled={!isApprove} /> </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3">
                                                <Form.Label className="erp-form-label">Status Remark
                                                    {
                                                        ['R', 'C', 'Z'].includes(cmb_sales_order_status) || rb_preeclose_sales_order === '1' ? <span className="required">*</span> : ""
                                                    }
                                                </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control as="textarea" rows={1} id="txt_status_remark" className="erp_txt_area" value={txt_status_remark} onInput={e => { setStatusRemark(e.target.value); validateFields(); }} maxlength="1000" placeholder="Reason of canceled, Rejection, Preeclosed."
                                                    {...(['R', 'C', 'Z'].includes(cmb_sales_order_status) || rb_preeclose_sales_order === '1' ? {} : { optional: 'optional', disabled: true })}
                                                // disabled={!isApprove || ['R', 'C', 'Z'].includes(cmb_sales_order_status)  || rb_preeclose_sales_order === '0' ? true : false}
                                                // optional={['R', 'C', 'Z'].includes(cmb_sales_order_status) || rb_preeclose_sales_order === '1' ? '' : 'optional'} 
                                                />
                                                <MDTypography variant="button" id="error_txt_status_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3">
                                                <Form.Label className="erp-form-label">Other Conditions</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control as="textarea" rows={1} id="txt_other_terms_conditions" className="erp_txt_area" value={txt_other_terms_conditions} onInput={e => { setOtherTermsConditions(e.target.value); }} maxlength="1000" optional="optional" />
                                                <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                            </div>
                                        </div>




                                        <div className="row">
                                            <div className="col-sm-3">
                                                <Form.Label className="erp-form-label">Pree-Closed Sales Order</Form.Label>
                                            </div>
                                            <div className="col">
                                                <div className="erp_form_radio">
                                                    <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="1" name="rb_preeclose_sales_order" checked={rb_preeclose_sales_order === "1"} onChange={(e) => { setPreeCloseSalesOrder('1'); }} optional="optional" /> </div>
                                                    <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="0" name="rb_preeclose_sales_order" checked={rb_preeclose_sales_order === "0"} onChange={(e) => { setPreeCloseSalesOrder('0'); }} optional="optional" /> </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {/* Row End Billing */}
                            </form>
                        </div>
                    </div>
                    {/* Billing Section Ends*/}

                    <hr />
                    <div className="text-center mb-5">
                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>

                        <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />

                        <MDButton type="button" className={`erp-gb-button ms-2 ${deletionKey === true ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => deleteshow()}> Delete </MDButton>


                        <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" BtnType='save' onClick={(e) => FnGenerateSalesOrder(e, 'A')}
                            fontWeight="regular">Save</MDButton>

                        {
                            keyForViewUpdate === 'approve' && !deletionKey
                                ? <>
                                    <MDButton type="button" id="reject-btn" className="erp-gb-button erp_MLeft_btn" variant="button" BtnType='reject' onClick={(e) => FnGenerateSalesOrder(e, 'R')} fontWeight="regular">Reject</MDButton>
                                </>
                                : ""
                        }

                        {
                            keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update'
                                ? <>
                                    <MDButton type="button" id="clearFormBtn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={e => { FnSetDefaultFormFields(); }} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'disabled' : ''} >Clear</MDButton>
                                </>
                                : null
                        }
                         <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view'  ? 'd-none' : 'display'}`} onClick={() => setShowDocumentForm(true)} variant="button" fontWeight="regular" >Upload Document</MDButton>&nbsp;


                        {
                            keyForViewUpdate === 'view' && cmb_sales_order_status !== 'P' && !deletionKey
                                ? <>
                                    <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => acceptSalesOrder()}>Accept</MDButton>
                                    <MDButton className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => { printInvoice(true); }} >Print Sales Order<FiDownload className="erp-download-icon-btn" /></MDButton>
                                </>
                                : null
                        }

                    </div >
                </>
                {/* </div> */}

                {/* ADD Filter modal */}
                <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={FnCloseFilterModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Products List</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseFilterModal}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <ProductListProductTypeWise closeModal={FnCloseFilterModal} props={{ product_type_id: cmb_sales_order_type_id }} />
                            {/* <ProductList closeModal={FnCloseFilterModal} props={{ selectedProduct: ['smv_product_rm_summary', 'smv_product_fg_summary'] }} /> */}
                            {/* <FrmMBOMEntry closeModal={FnCloseFilterModal} ptSelected={{ product_type: cmb_sales_order_type_id, master_val: 'smv_product_type' }} /> */}
                        </React.Fragment>
                    </Modal.Body>
                </Modal>

                {/* Modal for schedule quantity */}
                {/* {renderSchedulesModal} */}
                <Modal size="lg" className='erp_document_Form' show={showSchedule} onHide={handleCloseSchedulesPopup} backdrop="static" keyboard={false} centered>
                    <Modal.Header className="py-2">
                        <Modal.Title className='erp_modal_title'>Add Schedules</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSchedulesPopup}></button></span>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <dl className="row mb-0">
                            <div className="col-md-6">
                                <dl className="row mb-0">
                                    <dt className="col-sm-3 erp-form-label-md-lg">Material Name:</dt>
                                    <dd className="col-sm-9 erp-form-label" id='productMatName'></dd>
                                </dl>
                            </div>
                            <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">SO SrNo:</dt>
                                    <dd className="col-sm-8 erp-form-label" id='soSrNo'></dd>
                                </dl>
                            </div>

                        </dl>

                        <dl className="row mb-0">
                            <div className="col-md-6">
                                <dl className="row mb-0">
                                    <dt className="col-sm-3 erp-form-label-md-lg"> Material Unit:</dt>
                                    <dd className="col-sm-9 erp-form-label" id='productMatUnit'></dd>
                                </dl>
                            </div>

                            <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Std. Weight:</dt>
                                    <dd className="col-sm-8 erp-form-label" id='productMatStdWeight'></dd>
                                </dl>
                            </div>
                        </dl>

                        <dl className="row mt-0">
                            <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-3 erp-form-label-md-lg"> Total Quantity:</dt>
                                    <dd className="col-sm-9 erp-form-label" id='productMatQty'></dd>
                                </dl>
                            </div>
                            <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Total Weight:</dt>
                                    <dd className="col-sm-8 erp-form-label" id='productMatTotalWeight'></dd>
                                </dl>
                            </div>
                        </dl>

                        <div className='row table-responsive erp_table_scroll my-3'>
                            {renderScheduleTblForPopupModal}
                            {/* <Table className="erp_table" id="scheduleTable" bordered striped >
                            <thead className='erp_table_head erp_table_th'>
                                <tr>
                                    <th>Action</th>
                                    <th>Schedule Quantity</th>
                                    <th>Schedule Weight</th>
                                    <th>Expected Schedule Date</th>
                                    <th>Receipt Quantity</th>
                                    <th>Receipt Weight</th>
                                    <th>Receipt Date</th>
                                    <th>Material Shedule Status</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>

                            <tbody role="rowgroup">
                                <tr scheduleTrCount='1' id="RMSchedulesEntry-tr-1">
                                    <td style={{ width: '100px' }}><IoRemoveCircleOutline className='erp_trRemove_icon' /> <IoAddCircleOutline className='erp_trAdd_icon disableClass' onClick={() => addNewScheduleRow()} /></td>
                                </tr>
                            </tbody>
                        </Table> */}
                        </div>

                    </Modal.Body>
                    <Modal.Footer className='erp-modal-footer'>
                        <MDButton type="button" className="erp-gb-button" variant="button"
                            fontWeight="regular" onClick={() => schedule()}>Schedule</MDButton>
                    </Modal.Footer>
                </Modal>

                {/* Document modal */}
                {memorizedDocumentComponent}

                {/* Generic Response Messaage Modals */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
                {showConfirmationModal ?
                    <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('createIndent')} show={[showConfirmationModal, succMsg, modalOrderDetails, messageForConfirmation]} />
                    : null
                }

                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecModal} onHide={handleCloseAddRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'>
                                <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseAddRecModal}></button>
                            </div>
                        </div>
                        {openAddRecordPopUp()}
                    </Modal.Body>
                </Modal >

                {/* Delete Modal */}
                <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>
                <SuccessModal handleCloseSuccessModal={() => handleCloseDocSuccessModals()} show={[showSuccessMsgModals, docSuccMsg]} />
                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='text-center'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Do you wish to delete this record ?</h6>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='btn erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='btn erp-gb-button' onClick={deleteDocument}>Delete</Button>
                    </Modal.Footer>

                </Modal>

                {/* Send Email On Approval Modal */}
                <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={sendEmail}
                    show={[showMailSentModal, succMsg, modalOrderDetails, checkAgentMail]} />
            </DashboardLayout >
        </>
    )
}

export default SalesOrderEntry;