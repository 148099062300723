import React from "react";
import DakshabhiLogo from "assets/images/DakshabhiLogo.png";
import ConfigConstants from "assets/Constants/config-constant";
import WaterMark from "components/WaterMark";
import $ from "jquery";

let PurchaseOrderTermstable = null;

const PurchaseOrderInvoice = React.forwardRef(

  ({ invoiceContent, isWaterMark, ref }) => {
    debugger
    // const configConstants = ConfigConstants();
    // const { AFTER_DECIMAL_PLACES } = configConstants;

    if (invoiceContent.context === "Bales") { $(".bales").hide(); }
    let AFTER_DECIMAL_PLACES = 2;
    const data = invoiceContent.items;
    const scheduleData = invoiceContent.scheduleDetails;
    const squareBorder = {
      border: "2px solid #000",
      // padding: '10px',
    };


    // Converting Currency to International Standard.
    const numberToWords = require("number-to-words");
    const currency_code = invoiceContent.footer.currency_code;
    // Currency mapping with major (main unit) and minor (subunit)
    const currencyMap = {
      "INR": { major: "Rupee", minor: "Paisa" },
      "AFN": { major: "Afghani", minor: "Pul" },
      "ARS": { major: "Peso", minor: "Centavo" },
      "AWG": { major: "Guilder", minor: "Cent" },
      "AUD": { major: "Dollar", minor: "Cent" },
      "AZN": { major: "New Manat", minor: "Qəpik" },
      "BSD": { major: "Dollar", minor: "Cent" },
      "BBD": { major: "Dollar", minor: "Cent" },
      "BYR": { major: "Ruble", minor: "Kopek" },
      "EUR": { major: "Euro", minor: "Cent" },
      "BZD": { major: "Dollar", minor: "Cent" },
      "BMD": { major: "Dollar", minor: "Cent" },
      "BOB": { major: "Boliviano", minor: "Centavo" },
      "BAM": { major: "Convertible Marka", minor: "Fening" },
      "BWP": { major: "Pula", minor: "Thebe" },
      "BGN": { major: "Lev", minor: "Stotinka" },
      "BRL": { major: "Real", minor: "Centavo" },
      "GBP": { major: "Pound", minor: "Pence" },
      "BND": { major: "Dollar", minor: "Cent" },
      "KHR": { major: "Riel", minor: "Sen" },
      "CAD": { major: "Dollar", minor: "Cent" },
      "KYD": { major: "Dollar", minor: "Cent" },
      "CLP": { major: "Peso", minor: "Centavo" },
      "CNY": { major: "Yuan Renminbi", minor: "Fen" },
      "COP": { major: "Peso", minor: "Centavo" },
      "CRC": { major: "Colón", minor: "Céntimo" },
      "HRK": { major: "Kuna", minor: "Lipa" },
      "CUP": { major: "Peso", minor: "Centavo" },
      "CZK": { major: "Koruna", minor: "Haléř" },
      "DKK": { major: "Krone", minor: "Øre" },
      "DOP": { major: "Peso", minor: "Centavo" },
      "XCD": { major: "Dollar", minor: "Cent" },
      "EGP": { major: "Pound", minor: "Piastre" },
      "SVC": { major: "Colón", minor: "Centavo" },
      "FKP": { major: "Pound", minor: "Pence" },
      "FJD": { major: "Dollar", minor: "Cent" },
      "GHC": { major: "Cedi", minor: "Pesewa" },
      "GIP": { major: "Pound", minor: "Pence" },
      "GTQ": { major: "Quetzal", minor: "Centavo" },
      "GGP": { major: "Pound", minor: "Pence" },
      "GYD": { major: "Dollar", minor: "Cent" },
      "HNL": { major: "Lempira", minor: "Centavo" },
      "HKD": { major: "Dollar", minor: "Cent" },
      "HUF": { major: "Forint", minor: "Fillér" },
      "ISK": { major: "Krona", minor: "Aur" },
      "IDR": { major: "Rupiah", minor: "Sen" },
      "IRR": { major: "Rial", minor: "Dinar" },
      "ILS": { major: "New Shekel", minor: "Agora" },
      "JMD": { major: "Dollar", minor: "Cent" },
      "JPY": { major: "Yen", minor: "Sen" },
      "KZT": { major: "Tenge", minor: "Tiyn" },
      "KPW": { major: "Won", minor: "Jeon" },
      "KRW": { major: "Won", minor: "Jeon" },
      "KGS": { major: "Som", minor: "Tyiyn" },
      "LAK": { major: "Kip", minor: "Att" },
      "LVL": { major: "Lats", minor: "Santims" },
      "LBP": { major: "Pound", minor: "Piastre" },
      "LRD": { major: "Dollar", minor: "Cent" },
      "CHF": { major: "Franc", minor: "Rappen" },
      "LTL": { major: "Litas", minor: "Centas" },
      "MKD": { major: "Denar", minor: "Deni" },
      "MYR": { major: "Ringgit", minor: "Sen" },
      "MUR": { major: "Rupee", minor: "Cent" },
      "MXN": { major: "Peso", minor: "Centavo" },
      "MNT": { major: "Tugrik", minor: "Möngö" },
      "MZN": { major: "Metical", minor: "Centavo" },
      "NAD": { major: "Dollar", minor: "Cent" },
      "NPR": { major: "Rupee", minor: "Paisa" },
      "ANG": { major: "Guilder", minor: "Cent" },
      "NZD": { major: "Dollar", minor: "Cent" },
      "NIO": { major: "Cordoba", minor: "Centavo" },
      "NGN": { major: "Naira", minor: "Kobo" },
      "NOK": { major: "Krone", minor: "Øre" },
      "OMR": { major: "Rial", minor: "Baisa" },
      "PKR": { major: "Rupee", minor: "Paisa" },
      "PAB": { major: "Balboa", minor: "Centésimo" },
      "PYG": { major: "Guarani", minor: "Céntimo" },
      "PEN": { major: "Nuevo Sol", minor: "Céntimo" },
      "PHP": { major: "Peso", minor: "Centavo" },
      "PLN": { major: "Zloty", minor: "Grosz" },
      "QAR": { major: "Rial", minor: "Dirham" },
      "RON": { major: "New Leu", minor: "Ban" },
      "RUB": { major: "Ruble", minor: "Kopek" },
      "SAR": { major: "Riyal", minor: "Halala" },
      "SGD": { major: "Dollar", minor: "Cent" },
      "ZAR": { major: "Rand", minor: "Cent" },
      "SEK": { major: "Krona", minor: "Öre" },
      "THB": { major: "Baht", minor: "Satang" },
      "TRY": { major: "Lira", minor: "Kurus" },
      "UAH": { major: "Hryvnia", minor: "Kopek" },
      "USD": { major: "Dollar", minor: "Cent" },
      "VND": { major: "Dong", minor: "Hao" }
      // Add more as needed
    };

    // Convert INR to Indian number system format
    function convertINR(amount) {
      let words = "";
      let crore = Math.floor(amount / 10000000);
      let lakh = Math.floor((amount % 10000000) / 100000);
      let thousand = Math.floor((amount % 100000) / 1000);
      let hundred = Math.floor((amount % 1000) / 100);
      let remainder = amount % 100;

      if (crore) words += `${numberToWords.toWords(crore)} crore `;
      if (lakh) words += `${numberToWords.toWords(lakh)} lakh `;
      if (thousand) words += `${numberToWords.toWords(thousand)} thousand `;
      if (hundred) words += `${numberToWords.toWords(hundred)} hundred `;
      if (remainder) words += `${numberToWords.toWords(remainder)} `;

      return words.trim() + " rupees";
    }

    function convertCurrencyToWords(amount, currency) {
      let details = currencyMap[currency] || { major: "Currency", minor: "Cents" };
  
      let integerPart = Math.floor(amount);
      let decimalPart = Math.round((amount % 1) * 100);
  
      let words = currency === "INR" 
          ? convertINR(integerPart) 
          : numberToWords.toWords(integerPart) + ` ${details.major}`;
  
      if (decimalPart) {
          words += ` and ${numberToWords.toWords(decimalPart)} ${details.minor}`;
      }
  
      words = words.charAt(0).toUpperCase() + words.slice(1);
  
      return words + " only";
  }
  



    const cgstTotal = invoiceContent.footer.cgst_total;
    const sgstTotal = invoiceContent.footer.sgst_total;
    const igstTotal = invoiceContent.footer.igst_total;
    const basicTotal = invoiceContent.footer.basic_total;
    const taxable_total = invoiceContent.footer.taxable_total;
    const grand_total = invoiceContent.footer.grand_total;
    const round_off = invoiceContent.footer.round_off;

    // check Payment Terms name each element in the Payment Terms array
    let paymentTermsList = null;
    if (invoiceContent?.paymentTerms && invoiceContent?.paymentTerms?.length > 0) {
      paymentTermsList = invoiceContent.paymentTerms;
    }

    // Iterate over each element in the PurchaseOrderTerms array
    const PurchaseOrderTerms = invoiceContent.PurchaseOrderTerms;
    PurchaseOrderTerms?.forEach((POTerms) => {
      const {
        purchase_order_terms_parameters_name,
        purchase_order_terms_parameters_value,
      } = POTerms;
      // Now you can use purchase_order_terms_parameters_name
      console.log(
        "purchase_order_terms_parameters_name:",
        purchase_order_terms_parameters_name
      );
    });

    const contactDetails = invoiceContent.contactDetails;
    // Iterate over each element in the contactDetails array
    contactDetails.forEach((contact) => {
      const { supp_contact_person, supp_contact_no } = contact;
      // Now you can use cust_contact_person and cust_contact_no
      console.log("Contact Person:", supp_contact_person);
      console.log("Contact Number:", supp_contact_no);
    });

    const itemData = extractItemData(invoiceContent.items);
    function extractItemData(items) {
      // Extract relevant data from the first item in the array
      if (items?.length > 0) {
        const firstItem = items[0];
        return {
          department_name: firstItem.department_name,
          company: firstItem.company,
          company_branch: firstItem.company_branch,
          address: firstItem.address,
          phone: firstItem.phone,
          cell: firstItem.cell,
          email: firstItem.email,
          website: firstItem.website,
          GSTIN: firstItem.GSTIN,
          pan_no: firstItem.pan_no,
          state_name: firstItem.state_name,
          company_pincode: firstItem.company_pincode,
        };
      }
      // Return default values or handle the case where the array is empty
      return {
        department_name: 0,
        company: 0,
        company_branch: 0,
        address: 0,
        phone: 0,
        cell: 0,
        email: 0,
        website: 0,
        GSTIN: 0,
        pan_no: 0,
        state_name: 0,
        company_pincode: 0,
      };
    }

    const invoiceHeader = (
      <>
        <div className="bordered border-bottom-0 px-0">
          <div className="row mb-0 p-2">
            <div className="col-sm-3 text-center text-sm-start">
              <img
                src={DakshabhiLogo}
                alt="master card"
                width="150"
                height="70"
                mt={1}
              />
            </div>
            <div className="col-sm-6 ps-3">
              <div className="erp-invoice-print-label-lg text-center">
                {itemData.company}
              </div>
              <div className="row">
                <div className="text-center">
                  <div className="erp-invoice-print-label text-center">
                    <div className="erp-invoice-print-label-md-lg">
                      <span className="erp-invoice-print-label-md-lg">
                        {itemData.address}
                        <br />
                        {/* ({itemData.company_branch}) {itemData.state_name}-{itemData.company_pincode}  */}
                      </span>
                    </div>

                    <div className="erp-invoice-print-label-md-lg">
                      <span className="erp-invoice-print-label-md-lg">
                        {" "}
                        Email: {itemData.email} || Phone: {itemData.phone}{" "}
                      </span>
                    </div>
                    <div className="erp-invoice-print-label-md-lg">
                      <span className="erp-invoice-print-label-md-lg">
                        GSTN No: {itemData.GSTIN} || PAN No:{itemData.pan_no}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="row py-4">
                <div className="erp-invoice-print-label-lg me-3 ps-0 text-center">
                  Purchase Order{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="row border-top border-dark p-0 m-0">
            <div className="col-sm-5">
              <dt
                className="erp-invoice-print-label-md-lg"
                style={{ fontSize: "10px" }}
              >
                {" "}
                Purchase Order No.:
                {invoiceContent.purchaseDetails.purchase_order_no}{" "}
              </dt>
            </div>
            <div className="col-sm-4">
              <dt
                className="erp-invoice-print-label-md-lg"
                style={{ fontSize: "10px" }}
              >
                {" "}
                Purchase Order Type & Status:
                {invoiceContent.purchaseDetails.purchase_order_type} /{" "}
                {invoiceContent.purchaseDetails.purchase_order_status}
              </dt>
            </div>
            <div className="col-sm-3">
              <dt
                className="erp-invoice-print-label-md-lg text-end"
                style={{ fontSize: "10px" }}
              >
                Purchase Order Date:
                {invoiceContent.purchaseDetails.purchase_order_date}
              </dt>
            </div>
          </div>
          <div className="row  border-top border-dark p-0 m-0">
            <div className="row p-0 m-0">
              <dt className="col-sm-6 erp-invoice-print-label-md-lg">
                Supplier Details:
              </dt>
              <dt className="col-sm-6 erp-invoice-print-label-md-lg">
                Supplier Contact Details:
              </dt>
              <div className="col-sm-6">
                <dl className="row pb-0 mb-0">
                  <dt className="col-sm-2 erp-invoice-print-label-md-lg">
                    Name:
                  </dt>
                  <dd className="col-sm-10 erp-invoice-print-label-md-lg">
                    {" "}
                    {invoiceContent.supplierDetails.name}
                  </dd>

                  <dt className="col-sm-2 erp-invoice-print-label-md-lg">
                    Address:
                  </dt>
                  <dd className="col-sm-10 erp-invoice-print-label-md-lg">
                    {" "}
                    {invoiceContent.supplierDetails.address}
                  </dd>

                  <dl className="row mb-0 mt-0">
                    <div className="col-sm-6">
                      <dt className="erp-invoice-print-label-md-lg">
                        State:{" "}
                        <span class="erp-invoice-print-label-md-lg">
                          {" "}
                          {invoiceContent.supplierDetails.state}
                        </span>
                      </dt>
                    </div>
                    <div className="col-sm-6">
                      <dt className="erp-invoice-print-label-md-lg">
                        City:{" "}
                        <span class="erp-invoice-print-label-md-lg">
                          {" "}
                          {invoiceContent.supplierDetails.city}
                        </span>
                      </dt>
                    </div>
                  </dl>
                </dl>
              </div>
              <div className="col-sm-6">
                <dl className="row pb-0 mb-0">
                  <dd className="col-sm-10 m-0 p-0">
                    <dl className="row m-0 p-0">
                      <dl className="col-sm-6 mb-0 pb-0">
                        <dt className="erp-invoice-print-label-md-lg">
                          Person Name:
                        </dt>
                        {/* This will only display once */}
                      </dl>
                      <dl className="col-sm-6 mb-0 pb-0">
                        <dt className="erp-invoice-print-label-md-lg">
                          Contact No:
                        </dt>
                        {/* This will only display once */}
                      </dl>
                    </dl>
                    {contactDetails.map((contactData, index) => (
                      <dl className="row m-0 p-0" key={index}>
                        <dl className="col-sm-6 mb-0 pb-0">
                          <dd className="erp-invoice-print-label-md-lg">
                            {contactData.supp_contact_person}
                          </dd>
                        </dl>
                        <dl className="col-sm-6 mb-0 pb-0">
                          <dd className="erp-invoice-print-label-md-lg">
                            {contactData.supp_contact_no}
                          </dd>
                        </dl>
                      </dl>
                    ))}
                  </dd>
                </dl>
                <dl className="row pb-0 mb-0">
                  <dt className="col-sm-5 erp-invoice-print-label-md-lg">
                    GST NO:
                  </dt>
                  <dd className="col-sm-7 erp-invoice-print-label-md-lg">
                    {" "}
                    {invoiceContent.supplierDetails.GSTIN}{" "}
                  </dd>
                </dl>
                <dl className="row pb-0 mb-0 bales">
                  <dt className="col-sm-5 erp-invoice-print-label-md-lg">
                    Quotation No. & Date:
                  </dt>
                  <dd className="col-sm-7 erp-invoice-print-label-md-lg">
                    {" "}
                    {invoiceContent?.quotationDetails?.quotation_no} (
                    {invoiceContent?.quotationDetails?.quotation_date})
                  </dd>
                </dl>
              </div>
            </div>

            {/* <div className='row  border-top border-dark p-0 m-0'>

            <div className="row p-0 m-0">
              <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Bill To Details:</dt>
              <dt className="col-sm-6 erp-invoice-print-label-md-lg">Shipped To Details:</dt>

              <div className="col-sm-6">


                <dl className="row pb-0 mb-0">
                  <dt className='col-sm-2 erp-invoice-print-label-md-lg'>Name:</dt>  
                  <dd className='col-sm-10 erp-invoice-print-label'> {invoiceContent.supplierDetails.name}</dd>

                  <dt className='col-sm-2 erp-invoice-print-label-md-lg'>Address:</dt>
                  <dd className='col-sm-10 erp-invoice-print-label'> {invoiceContent.supplierDetails.address}</dd>

                  <dl className="row mb-0 mt-0">
                    <div className="col-sm-6">
                      <dt className='erp-invoice-print-label-md-lg'>State: <span class="erp-invoice-print-label"> {invoiceContent.supplierDetails.state}</span></dt>
                    </div>
                    <div className="col-sm-6">
                      <dt className='erp-invoice-print-label-md-lg'>City: <span class="erp-invoice-print-label"> {invoiceContent.supplierDetails.city}</span></dt>
                    </div>
                  </dl>
                </dl>
              </div>

              <div className="col-sm-6">
                <dl className="row pb-0 mb-0">
                  <div className='col-sm-10 erp-invoice-print-label-md-lg'>
                    {invoiceContent.consigneeDetails.consigneename}
                  </div>
                  <div className='col-sm-10 erp-invoice-print-label'>
                    {invoiceContent.consigneeDetails.consigneeaddress}
                  </div>

                  <dl className="row mb-0 mt-0">
                    <div className="col-sm-6">
                      <dt className='erp-invoice-print-label-md-lg'>State: <span class="erp-invoice-print-label"> {invoiceContent.consigneeDetails.consigneestate}</span></dt>
                    </div>
                    <div className="col-sm-6">
                      <dt className='erp-invoice-print-label-md-lg'>City: <span class="erp-invoice-print-label"> {invoiceContent.consigneeDetails.consigneecity}</span></dt>
                    </div>
                  </dl>


                </dl>
              </div>

            </div>
          </div> */}

            {invoiceContent?.ourdetails &&
              Object.values(invoiceContent.ourdetails).every(value => value && value.toString().trim() !== "" && value.toString().trim() !== null && value.toString().trim() !== undefined) && (
                <div className="row border-top border-dark p-0 m-0">
                  <dt className="erp-invoice-print-label-md-lg">Our Details :</dt>
                  <dl className="row pb-0 mb-0">
                    <div className="col-sm-6">
                      <dl className="row pb-0 mb-0">
                        <dt className="col-sm-3 erp-invoice-print-label-md-lg">Branch Name :</dt>
                        <dd className="col-sm-9 erp-invoice-print-label-md-lg">
                          {invoiceContent.ourdetails?.expected_branch_name}
                        </dd>

                        <dt className="col-sm-3 erp-invoice-print-label-md-lg">State :</dt>
                        <dd className="col-sm-9 erp-invoice-print-label-md-lg">
                          {invoiceContent.ourdetails?.expected_branch_state_name}
                        </dd>

                        <dt className="col-sm-3 erp-invoice-print-label-md-lg">Contact No :</dt>
                        <dd className="col-sm-9 erp-invoice-print-label-md-lg">
                          {invoiceContent.ourdetails?.expected_branch_phone_no}
                        </dd>
                      </dl>
                    </div>
                    <div className="col-sm-6">
                      <dl className="row pb-0 mb-0">
                        <dt className="col-sm-3 erp-invoice-print-label-md-lg">Department :</dt>
                        <dd className="col-sm-9 erp-invoice-print-label-md-lg">
                          {itemData?.department_name}
                        </dd>

                        <dt className="col-sm-3 erp-invoice-print-label-md-lg">City :</dt>
                        <dd className="col-sm-9 erp-invoice-print-label-md-lg">
                          {invoiceContent.ourdetails?.expected_branch_city_name}
                        </dd>

                        <dt className="col-sm-3 erp-invoice-print-label-md-lg">Schedule Date :</dt>
                        <dd className="col-sm-9 erp-invoice-print-label-md-lg">
                          {invoiceContent.ourdetails?.expected_schedule_date}
                        </dd>
                      </dl>
                    </div>
                  </dl>
                </div>
              )}


          </div>
        </div>
      </>
    );

    const invoiceFooter = (
      <div className="">
        <div className="row p-0 m-0 border">
          <div className="col-sm-3">
            <div className="text-center">
              <dt className="erp-invoice-print-label-md-lg">
                For {invoiceContent.supplierDetails.name}
              </dt>
            </div>
            <div className="align-bottom-center mt-3">
              <dt className="erp-invoice-print-label-md-lg">PO Accepted By</dt>
            </div>
          </div>
          <div className="col-sm-3 offset-sm-6">
            {" "}
            {/* This offsets the third column */}
            <div className="text-center">
              <dt className="erp-invoice-print-label-md-lg">
                For {itemData.company}
              </dt>
            </div>
            <div className="align-bottom-center mt-3">
              <dt className="erp-invoice-print-label-md-lg">
                {invoiceContent.purchaseDetails.approved_by_name}
              </dt>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {/* <div className="container"> */}
        <div className="row">
          <div className="col-12">
            <div className="container-invoice">
              {isWaterMark ? (
                <>
                  {" "}
                  <WaterMark text="Preview Only" opacity={0.9} />{" "}
                  {/* Add watermark here */}
                </>
              ) : null}
              <div id="content">
                <div className="invoice p-0">
                  {/* <!--BILL HEADER--> */}
                  <div
                    className="row headerContentHt"
                    style={{ padding: "0px 12px 0px" }}
                  >
                    {invoiceHeader}
                  </div>
                  {/* <!--PARCEL DETAILS--> */}
                  {/* <div className="row" style={{ padding: "0px 15px 15px" }}>
                    <div className="col-12 px-0"> */}
                  {/* <div className="row">
                        <div className="col-12 px-0"> */}
                  <div className="row p-0">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered border border-dark m-0 border-end-0 border-start-0"
                        id="invoiceTable"
                      >
                        {data?.length !== 0 && (
                          <thead className="tblHeaderContentHt">
                            <tr>
                              <th
                                className="erp_invoice_table_th text-center"
                                rowspan={2}
                              >
                                Sr No.
                              </th>
                              <th
                                className="erp_invoice_table_th text-center"
                                rowspan={2}
                              >
                                Material Code
                              </th>
                              <th
                                className="erp_invoice_table_th text-center col-4"
                                rowspan={2}
                              >
                                Material Name(Technical Spect,Make
                                Name,Grade Name)
                              </th>

                              { }
                              <th
                                className="erp_invoice_table_th text-center col-1"
                                rowspan={2}
                              >
                                Hsn Code
                              </th>

                              {invoiceContent.context === "Bales" ?
                                <th
                                  className="erp_invoice_table_th text-center col-1"
                                  rowspan={2}
                                >
                                  Weight
                                </th>
                                : ''}

                              <th
                                className="erp_invoice_table_th text-center col-1"
                                rowspan={2}
                              >
                                Qty.
                              </th>
                              <th
                                className="erp_invoice_table_th text-center col-1"
                                rowspan={2}
                              >
                                Unit
                              </th>
                              <th
                                className="erp_invoice_table_th text-center col-1"
                                rowspan={2}
                              >
                                Rate.
                              </th>
                              <th
                                className="erp_invoice_table_th text-center"
                                rowspan={2}
                              >
                                Disc %
                              </th>
                              <th
                                className="erp_invoice_table_th text-center col-1"
                                colSpan={3}
                              >
                                Taxes Extra
                              </th>
                              <th
                                className="erp_invoice_table_th text-center col-1"
                                rowspan={2}
                              >
                                Item Total
                              </th>
                            </tr>
                            <tr>
                              <th className="erp_invoice_table_th text-center text-center">
                                CGST %
                              </th>
                              <th className="erp_invoice_table_th text-center text-center">
                                SGST %
                              </th>
                              <th className="erp_invoice_table_th text-center text-center">
                                IGST %
                              </th>
                            </tr>
                          </thead>
                        )}

                        <tbody id="detailstableId">
                          {data.map((item, index) => (
                            <>
                              <tr className={`tblBodyTrHt-${index}`}>
                                <td className="erp_invoice_table_td text-center">
                                  {item.sr_no}
                                </td>
                                <td className="erp_invoice_table_td text-center">
                                  {item.product_material_code}
                                </td>

                                <td
                                  className="erp_invoice_table_td"
                                  style={{ width: "40%" }}
                                >
                                  {item.material_name}
                                  <span>
                                    {(item.material_tech_spect === "" || item.material_tech_spect === "NA") &&
                                      (item.product_make_name === "" || item.product_make_name === "NA") &&
                                      (item.product_material_grade_name === "" || item.product_material_grade_name === "NA") ? (""
                                    ) : (
                                      <>
                                        (
                                        {[
                                          item.material_tech_spect,
                                          item.product_make_name,
                                          item.product_material_grade_name,
                                        ].filter((str) => str !== "" && str !== "NA" && str !== "General" && str !== null && str !== undefined).join(", ")})
                                      </>
                                    )}
                                  </span>
                                </td>
                                <td className="erp_invoice_table_td">
                                  {item.hsn_code}
                                </td>

                                {invoiceContent.context === "Bales" ?
                                  <td className="erp_invoice_table_td text-end">
                                    {item.weight}
                                  </td>
                                  : ''}

                                <td className="erp_invoice_table_td text-end">
                                  {item.quantity}
                                </td>
                                <td className="erp_invoice_table_td">
                                  {item.unit}
                                </td>
                                <td className="erp_invoice_table_td text-end">
                                  {item.rate}
                                </td>
                                <td className="erp_invoice_table_td text-end">
                                  {item.discount_percent}
                                </td>
                                <td className="erp_invoice_table_td text-end">
                                  {item.cgst_percent}
                                </td>
                                <td className="erp_invoice_table_td text-end">
                                  {item.sgst_percent}
                                </td>
                                <td className="erp_invoice_table_td text-end">
                                  {item.igst_percent}
                                </td>
                                <td className="erp_invoice_table_td text-end">
                                  {item.material_basic_amount}
                                </td>
                              </tr>
                              {data?.length - 1 === index ? (
                                <>
                                  <tr
                                    id="footerTr"
                                    className="footerContentHt"
                                  >
                                    <td
                                      className="erp_invoice_table_td"
                                      colSpan={6}
                                    >
                                      <div className="row mt-1 m-0 p-0">
                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">
                                          Basic Total :
                                        </dt>
                                        <dd className="col-sm-8 erp-invoice-print-label">
                                          {convertCurrencyToWords(basicTotal, currency_code)}
                                        </dd>
                                      </div>
                                      <div className="row  mt-1 m-0 p-0">
                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">
                                          CGST(in words):
                                        </dt>
                                        <dd className="col-sm-8 erp-invoice-print-label">
                                          {convertCurrencyToWords(cgstTotal, currency_code)}
                                        </dd>
                                      </div>
                                      <div className="row  mt-1 m-0 p-0">
                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">
                                          SGST(in words):
                                        </dt>
                                        <dd className="col-sm-8 erp-invoice-print-label">
                                          {convertCurrencyToWords(sgstTotal, currency_code)}
                                        </dd>
                                      </div>
                                      <div className="row  mt-1 m-0 p-0">
                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">
                                          IGST(in words):
                                        </dt>
                                        <dd className="col-sm-8 erp-invoice-print-label">
                                          {convertCurrencyToWords(igstTotal, currency_code)}
                                        </dd>
                                      </div>
                                      <div className="row  mt-1 m-0 p-0">
                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">
                                          Taxable Total:
                                        </dt>
                                        <dd className="col-sm-8 erp-invoice-print-label">
                                         { convertCurrencyToWords(taxable_total, currency_code) }
                                        </dd>
                                      </div>
                                      <div className="row  mt-1 m-0 p-0">
                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">
                                          Grand Total :
                                        </dt>
                                        <dd className="col-sm-8 erp-invoice-print-label">
                                        {convertCurrencyToWords(grand_total, currency_code)}
                                        </dd>
                                      </div>
                                    </td>
                                    <td
                                      className="erp_invoice_table_td p-0 m-0"
                                      colSpan={6}
                                    >
                                      <table className="table p-0 m-0 table-borderless">
                                        <tbody>
                                          <tr className="border-bottom border-dark">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                Basic Total {`${invoiceContent.footer.currency_symbol}`} :
                                              </span>{" "}
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .basic_total
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-bottom border-dark">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg ">
                                                CGST Total {`${invoiceContent.footer.currency_symbol}`} :
                                              </span>
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .cgst_total
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-bottom border-dark">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                SGST Total {`${invoiceContent.footer.currency_symbol}`} :
                                              </span>{" "}
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .sgst_total
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-bottom border-dark">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                IGST Total {`${invoiceContent.footer.currency_symbol}`} :
                                              </span>{" "}
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .igst_total
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-bottom border-dark">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                Taxable Total {`${invoiceContent.footer.currency_symbol}`} :
                                              </span>{" "}
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .taxable_total
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-0">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                Packing Amount {`${invoiceContent.footer.currency_symbol}`}:{" "}
                                              </span>
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .packing_amount
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-0">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                Discount Amount {`${invoiceContent.footer.currency_symbol}`}:{" "}
                                              </span>
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .discount_amount
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-0">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                Freight Amount {`${invoiceContent.footer.currency_symbol}`}:{" "}
                                              </span>
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .freight_amount
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-0">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                Other Amount {`${invoiceContent.footer.currency_symbol}`}:{" "}
                                              </span>
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .other_amount
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-0">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                Round Off:{" "}
                                              </span>
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .round_off
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                          <tr className="border-0">
                                            <td className="pt-0 pb-0">
                                              <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                Grand Total {`${invoiceContent.footer.currency_symbol}`}:{" "}
                                              </span>
                                            </td>
                                            <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">
                                              {parseFloat(
                                                invoiceContent.footer
                                                  .grand_total
                                              ).toFixed(
                                                AFTER_DECIMAL_PLACES
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                              ) : null}
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row p-0 ">
                    <div className="">
                      {scheduleData?.length !== 0 ? (
                        <>
                          <div className="row  p-0">
                            <div className="col-12">
                              <dl className="row p-0 m-0 border-left border-right">
                                <dt
                                  className="col-sm-4"
                                  style={{ fontSize: "11px" }}
                                >
                                  Material Schedules:{" "}
                                </dt>
                              </dl>
                            </div>
                          </div>
                          <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id="invoiceTable">
                            <thead className="tblHeaderContentHt">
                              <tr>
                                <th
                                  className="erp_invoice_table_th text-center col-1"
                                  rowspan={2}
                                >
                                  Sr No.
                                </th>
                                <th
                                  className="erp_invoice_table_th text-center"
                                  rowspan={2}
                                >
                                  Material Code
                                </th>
                                <th
                                  className="erp_invoice_table_th text-center col-4"
                                  rowspan={2}
                                >
                                  Material Name
                                </th>
                                <th
                                  className="erp_invoice_table_th text-center col-2"
                                  rowspan={2}
                                >
                                  Schedule Quantity
                                </th>
                                <th
                                  className="erp_invoice_table_th text-center col-2"
                                  rowspan={2}
                                >
                                  Schedule Date
                                </th>
                                <th
                                  className="erp_invoice_table_th text-center col-3"
                                  rowspan={2}
                                >
                                  Remark
                                </th>
                              </tr>
                            </thead>

                            <tbody id="detailstableId">
                              {scheduleData.map(
                                (item, index) => (
                                  <>
                                    <React.Fragment
                                      key={index}
                                    >
                                      <tr
                                        className={`tblBodyTrHt-${index}`}
                                      >
                                        <td className="erp_invoice_table_td text-end">
                                          {item.sr_no}
                                        </td>
                                        <td className="erp_invoice_table_td text-center">
                                          {item.product_material_code}
                                        </td>
                                        <td className="erp_invoice_table_td">
                                          {
                                            item.product_material_name
                                          }
                                        </td>
                                        <td className="erp_invoice_table_td text-end">
                                          {
                                            item.schedule_quantity
                                          }
                                        </td>
                                        <td className="erp_invoice_table_td ">
                                          {
                                            item.schedule_date
                                          }
                                        </td>
                                        <td className="erp_invoice_table_td ">
                                          {item.remark}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  </>
                                )
                              )}
                            </tbody>
                          </table>
                        </>
                      ) : null}

                    </div>
                  </div>

                  {['', null, 0, undefined].includes(paymentTermsList?.length) ? '' :

                    <div className="row p-0 ">
                      <div className=" pOrderContent">
                        <dl className="row p-0 m-0 border-left border-right">
                          <dt
                            className="col-sm-4"
                            style={{ fontSize: "11px" }}
                          >
                            Payment Terms:{" "}
                          </dt>
                        </dl>
                        {/*new table for payment terms*/}
                        {paymentTermsList?.length !== 0 ? (
                          <table
                            className="table table-bordered border border-dark m-0 border-end-0 border-start-0"
                            id="invoiceTable"
                          >
                            <thead className="tblHeaderContentHt">
                              <tr>
                                <th
                                  className="erp_invoice_table_th text-center col-1"
                                  rowspan={2}
                                >
                                  Sr No.
                                </th>
                                <th
                                  className="erp_invoice_table_th text-center col-3"
                                  rowspan={2}
                                >
                                  Payment Terms Name
                                </th>
                                <th
                                  className="erp_invoice_table_th text-center col-2"
                                  rowspan={2}
                                >
                                  Payment Terms %
                                </th>
                              </tr>
                            </thead>
                            <tbody id="detailstableId">
                              {paymentTermsList.map(
                                (item, index) => (
                                  <>
                                    <React.Fragment
                                      key={index}
                                    >
                                      <tr
                                        className={`tblBodyTrHt-${index}`}
                                      >
                                        <td className="erp_invoice_table_td text-end">
                                          {item.sr_no}
                                        </td>
                                        <td className="erp_invoice_table_td">
                                          {
                                            item.payment_terms_name
                                          }
                                        </td>
                                        <td className="erp_invoice_table_td text-end">
                                          {
                                            item.payment_percent
                                          }
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  </>
                                )
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <span>NIL</span>
                        )}

                      </div>
                    </div>
                  }


                  <div className="row p-0 ">
                    <div className="  m-0 ">
                      <div className="row m-0 p-0 border-left border-right">
                        <dt className="col-sm-2 erp-invoice-print-label-md-lg">
                          Remark:
                        </dt>
                        <dt className="col-sm-8 erp-invoice-print-label">
                          {invoiceContent.footer.remark}
                        </dt>
                      </div>

                      {/* <dl className="row p-0 m-0 mt-1">
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">PO Payment Terms: </dt>
                                                {paymentTermsList !== null ? (
                                                  <dd className="col-sm-6 m-0 p-0">
                                                    <dl className="row m-0 p-0" key={index}>
                                                      <dd className="col-sm-8 erp-invoice-print-label">
                                                        <dt className="erp-invoice-print-label">{paymentTermsList}</dt>
                                                      </dd>
                                                    </dl>
                                                  </dd>
                                                ) : (
                                                  <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                )}
                                              </dl> */}
                      {/* <dl className="row p-0 m-0 mt-1">
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">PO Terms Name: </dt>
                                                {PurchaseOrderTerms.length > 0 ? (
                                                  <dd className="col-sm-6 m-0 p-0">
                                                    {PurchaseOrderTerms.map((PurchaseOrderTerm, index) => (
                                                      <dl className="row m-0 p-0" key={index}>
                                                        <dd className="col-sm-8 erp-invoice-print-label">
                                                          {PurchaseOrderTerm.purchase_order_terms_parameters_name !== undefined ? ` ${PurchaseOrderTerm.purchase_order_terms_parameters_name}` : ''}
                                                        </dd>
                                                      </dl>
                                                    ))}
                                                  </dd>
                                                ) : (
                                                  <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                )}
                                              </dl> */}

                      <dl className="row p-0 m-0 border-left border-right">
                        <dt
                          className="erp-invoice-print-label-md-lg"
                          style={{ fontSize: "10px" }}
                        >
                          {" "}
                          Terms & Conditions :{" "}
                        </dt>
                        <dt
                          className="erp-invoice-print-label-md-lg"
                          style={{ fontSize: "10px" }}
                        >
                          - Please confirm Billing or
                          shipping address before
                          dispatch{" "}
                        </dt>
                        <dt
                          className="erp-invoice-print-label-md-lg"
                          style={{ fontSize: "10px" }}
                        >
                          - SUBJECT TO AHMEDABAD
                          JURIDICTION ONLY{" "}
                        </dt>
                      </dl>
                    </div>
                  </div>
                  <div className="row p-0">
                    {invoiceFooter}
                  </div>
                </div>
                {/* <div>
                              <span className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>ISO No. & Purchase Order Version : {invoiceContent.purchaseDetails.purchase_iso_no} & {invoiceContent.purchaseDetails.purchase_order_version} </span>
                            </div> */}
                {/* </div>
                    </div> */}
                {/* </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);
export default PurchaseOrderInvoice;
