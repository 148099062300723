import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button, InputGroup } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Datatable from "components/DataTable";

// React icons
import { MdDelete } from "react-icons/md";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import MaterialGatePassInvoice from 'FrmGeneric/Invoice/MaterialGatePassInvoice';
import { renderToString } from "react-dom/server";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// Import for the searchable combo box.
import Select from 'react-select';

function FrmMaterialReturnToStoresEntry() {
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, UserId, UserName } = configConstants;


    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    let docGroup = "Material Return To Stores"

    // For navigate
    const navigate = useNavigate();
    const navigator = useNavigate();

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    // let supplierComboRef = useRef(null);
    const typeaheadRef = useRef();
    const selectRefs = useRef([]);
    const [errorIndex, setErrorIndex] = useState(null);



    // for today date formatted
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];

    //form combo options
    const [productTypeOptions, setProducttypeOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [category1Options, setCategory1Options] = useState([])
    const [category2Options, setCategory2Options] = useState([])
    const [issueNoOptions, setIssueNoOptions] = useState([]);
    const [searchOptions, setOptions] = useState([]);

    const [cmb_return_by_id, setReturnById] = useState('')
    const [cmb_approved_by_id, setApprovedById] = useState(UserId)
    const [dt_received_on, setDateReceivedOn] = useState(todayDate)
    const [cmb_return_status, setReturnStatus] = useState('P')
    const [cmb_approved_by_name, setApprovedByName] = useState(UserName)
    const [txt_checker_by_name, setReturnByName] = useState(UserName)
    const [txt_remark, setRemark] = useState('');
    const [cost_center_id, setCostCenterId] = useState('');

    //Form Fields
    const [cmb_product_type, setProductType] = useState('2');
    const [cmb_product_category1_id, setProductCategory1Id] = useState(0);
    const [cmb_product_category2_id, setProductCategory2Id] = useState(0);
    const [cmb_product_type_name, setProductTypeName] = useState('');
    const [txt_material_return_no, setMaterialReturnNo] = useState(0)
    const [dt_material_return_date, setMaterialReturnDate] = useState(todayDate)
    const [issue_return_master_transaction_id] = useState(idList.issue_return_master_transaction_id);
    const [material_type, setMaterialType] = useState('UR');
    const [dt_issue_date, setIssueDate] = useState();
    const [cmb_issue_no, setIssueNo] = useState('');
    //department and sub-dept 
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_subdepartment_id, setSubDepartmentId] = useState('');
    const [unitOpts, setUnitOpts] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [subDepartmentOptions, setSubDepartmentOptions] = useState([]);
    // const [costCenterOptions, setCostCenterOptions] = useState([]);

    // refs
    const cmb_department_ref = useRef();
    const cmb_subdepartment_ref = useRef();
    const issueNoComboRef = useRef();
    let empApprovedComboRef = useRef();
    let returnByComboRef = useRef();
    let category1ComboRef = useRef();
    let category2ComboRef = useRef();

    const [actionType, setActionType] = useState("");
    const [actionLabel, setActionLabel] = useState("Save");

    //Add Schedules popup open 
    const [returnDataArraysummary, setReturnDataArraySummary] = useState([]);
    const [materialReturnMasterDataForPrint, setMaterialReturnMasterDataForPrint] = useState([]);

    const [materialReturnDetailsData, setMaterialReturnDetailsData] = useState([])
    const [issueDetailsData, setIssueDetailsData] = useState([]);




    useEffect(async () => {
        setIsLoading(true);
        try {
            await ActionType();
            await fillCombos();
            if (idList !== '' || idList !== undefined) {
                await FnCheckUpdateResponse();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        if (keyForViewUpdate === "add" && cmb_product_category1_id != 0) {
            GenerateGatePassNo();
        }
    }, [cmb_product_category1_id, cmb_product_category2_id, material_type])

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    const closeConfirmationModal = async () => {
        setShowSuccessMsgModal(false)
        moveToListing();
    };

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case "update":
                // setIsApprove(false);
                setActionType("(Modification)");
                setActionLabel("Update");
                break;
            case "view":
                // setIsApprove(false);
                $('input[type="checkbox"]').prop('readonly', true);
                setActionType("(View)");
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;
            case "receive":
                setActionType("(Receive)");
                setActionLabel("Receive");
                setReturnStatus("R")
                $('input[type="checkbox"]').prop('readonly', true);
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;
            default:
                setActionType("(Creation)");
                break;
        }
    };

    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillCombos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ["product_type_id as field_id", "product_type_name as field_name", "product_type_short_name"]
            globalQuery.table = "sm_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM"] });
            const prodTypeOpts = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            console.log(prodTypeOpts)
            const options = [
                { value: '', label: 'Select', short_name: "" },
                ...prodTypeOpts.map((prodType) => ({ ...prodType, value: prodType.field_id, label: prodType.field_name, short_name: prodType.product_type_short_name })),
            ];
            setProducttypeOptions(options)

            comboOnChange("cmb_product_type");

            resetGlobalQuery();
            globalQuery.columns.push("employee_id");
            globalQuery.columns.push("employee_name");
            globalQuery.table = "cm_employee";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            const requestedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const employeeOptions = [
                ...requestedbyIdApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
            ];
            setEmployeeOptions(employeeOptions);
            setIsLoading(false)

            resetGlobalQuery();
            globalQuery.columns = ['department_id as field_id', 'department_name as field_name',]
            globalQuery.conditions = [
                { field: "department_type", operator: "=", value: "M" },
                { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] }
            ]
            globalQuery.table = "cm_department";
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((deptOptions) => {
                const departmentOptions = [
                    { value: '', label: 'Select' },
                    ...deptOptions.map((department) => ({ ...department, value: department.field_id, label: department.field_name, })),
                ];
                setDepartmentOptions(departmentOptions);
            });

            resetGlobalQuery();
            globalQuery.columns.push("product_unit_name");
            globalQuery.columns.push("product_unit_id");
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.table = "sm_product_unit";
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((unitOpts) => {
                setUnitOpts(unitOpts);
            });

        }
        catch (error) {
            console.log("error: ", error)
        }
    }

    //fn to get po auto no
    const GenerateGatePassNo = async () => {
        debugger
        const ptShortName = productTypeOptions.find((item) => item.value === parseInt(cmb_product_type)) || "";
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("st_indent_material_issue_return_master", "issue_return_no", ptShortName.product_type_short_name, material_type, "5");
        setMaterialReturnNo(autoNoApiCall);
        return autoNoApiCall;
    };
    //-------------------------------------- Combo changes ------------------------------------------------------------
    const comboOnChange = async (key) => {

        switch (key) {

            case 'cmb_product_type':
                $("#error_cmb_product_type").hide();
                let prodTypeName = $('#cmb_product_type option:selected').text()
                if (cmb_product_type !== "") {
                    setProductTypeName(prodTypeName);
                    resetGlobalQuery();
                    globalQuery.columns = ['product_category1_id', 'product_category1_name'];
                    globalQuery.table = "sm_product_category1";
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: cmb_product_type });
                    const Category1List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    const category1Opts = [
                        { value: 0, label: 'Select' },
                        ...Category1List.map((category1) => ({ ...category1, value: category1.product_category1_id, label: `${category1.product_category1_name}` })),
                    ];
                    setCategory1Options(category1Opts);
                    setCategory2Options([])
                    setProductCategory2Id(0)
                } else {
                    setProductCategory1Id(0)
                    setCategory1Options([])
                    setProductCategory2Id(0)
                    setCategory2Options([])
                }
                break;

            case 'ApprovedBy':
                var employeeID = empApprovedComboRef.current?.employee_id ?? UserId;
                resetGlobalQuery();
                globalQuery.columns.push("employee_id");
                globalQuery.columns.push("employee_name");
                globalQuery.table = "cm_employee";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeID });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                const requestedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                const employeeOptions = [
                    ...requestedbyIdApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
                ];

                $("#error_cmb_approved_by_id").hide();
                if (employeeID !== "") {
                    setApprovedById(employeeOptions[0].employee_id);
                    setApprovedByName(employeeOptions[0].employee_name);
                    empApprovedComboRef.current.employee_id = employeeOptions[0].employee_id
                    empApprovedComboRef.current.employee_name = employeeOptions[0].employee_name

                } else {
                    setApprovedById('');
                    setApprovedByName('');
                }
                break;

            case 'returnBy':

                var employeeID = returnByComboRef.current?.employee_id ?? UserId;
                resetGlobalQuery();
                globalQuery.columns.push("employee_id");
                globalQuery.columns.push("employee_name");
                globalQuery.table = "cm_employee";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeID });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                const requestedbyIdApiCalls = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                const employeeOption = [
                    ...requestedbyIdApiCalls.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
                ];
                $("#error_cmb_return_by_id").hide();
                if (employeeID !== "") {
                    setReturnById(employeeOption[0].employee_id);
                    setReturnByName(employeeOption[0].employee_name);
                    returnByComboRef.current.employee_id = employeeOption[0].employee_id
                    returnByComboRef.current.employee_name = employeeOption[0].employee_name
                } else {
                    setReturnById('');
                    setReturnByName('');

                }
                break;
            case 'issue_no':
                debugger
                let selected_issue_no = issueNoComboRef.current.value;
                setMaterialReturnDetailsData([])
                if (selected_issue_no !== "") {
                    $('#error_cmb_issue_no').hide();
                    const issueObj = issueNoOptions.find(item => item.value === selected_issue_no);
                    if (issueObj) {
                        setIssueDate(issueObj.issue_date);
                    }
                    await showIssueDetailsDetails(selected_issue_no);
                } else {
                    setMaterialReturnDetailsData([])
                    setIssueDetailsData([]);
                }
                break;

            case 'category1':

                var category1Id = category1ComboRef.current.value
                $("#error_cmb_product_category1_id").hide();
                if (category1Id !== 0) {
                    resetGlobalQuery();
                    globalQuery.columns = ['product_category2_id', 'product_category2_name'];
                    globalQuery.table = "sm_product_category2";
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
                    const Category2List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    const category2Opts = [
                        { value: 0, label: 'Select' },
                        ...Category2List.map((category2) => ({ ...category2, value: category2.product_category2_id, label: `${category2.product_category2_name}` })),
                    ];
                    setCategory2Options(category2Opts);
                    setProductCategory2Id(0)
                    setDepartmentId("")
                    setSubDepartmentOptions([])
                    setIssueNoOptions([])
                    setIssueDetailsData([])
                    setIssueNo("")
                    setMaterialReturnDetailsData([])
                } else {
                    setProductCategory2Id(0)
                    setCategory2Options([])
                    setDepartmentId("")
                    setSubDepartmentOptions([])
                    setIssueDetailsData([])
                    setIssueNoOptions([])
                    setIssueNo("")
                    setMaterialReturnDetailsData([])
                }
                break;

            case 'Department':
                $("#error_cmb_subdepartment_id").hide();
                $("#error_cmb_department_id").hide();
                const departmentId = cmb_department_ref.current.value;

                if (departmentId !== "") {
                    setDepartmentId(departmentId);
                    cmb_department_ref.current.field_id = departmentId
                    try {
                        resetGlobalQuery();
                        globalQuery.columns = ['department_id as field_id', 'department_name as field_name', 'parent_department_id', 'cost_center_group']
                        globalQuery.conditions = [
                            { field: "department_type", operator: "=", value: "S" },
                            { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] }
                        ]
                        globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentId });
                        globalQuery.table = "cm_department";
                        let deptOptions = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        const departmentOptions = [
                            { value: '', label: 'Select' },
                            ...deptOptions.map((department) => ({
                                ...department, value: department.field_id, label: department.field_name,
                                parent_department_id: department.parent_department_id, costCenterGroup: department.cost_center_group
                            })),
                        ];
                        setSubDepartmentOptions(departmentOptions);
                        setSubDepartmentId('');
                        setIssueNoOptions([])
                        setIssueNo("")
                        setMaterialReturnDetailsData([])
                        setCostCenterId('');
                        return departmentOptions;
                    } catch (error) {
                        console.log('Error: ' + error);
                    }
                } else {
                    setSubDepartmentOptions([]);
                    setSubDepartmentId('');
                    setIssueNoOptions([])
                    setIssueNo("")
                }

                break;

            case 'SubDepartment':
                
                const SubDepartmentval = cmb_subdepartment_ref.current.value;
                setSubDepartmentId(SubDepartmentval);
                setIssueNoOptions([])
                setIssueNo("")
                setIssueDetailsData([])
                setMaterialReturnDetailsData([])
                setCostCenterId('');
                issueNoComboRef.current.value = ""

                //by Nilesh
                if (SubDepartmentval !== "" && SubDepartmentval !== "0") {

                    // var costCenterGroup = cmb_subdepartment_ref.current.costCenterGroup
                    // resetGlobalQuery();
                    // globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name', "cost_center_group"];
                    // globalQuery.table = "fmv_cost_center";
                    // globalQuery.conditions.push({ field: "cost_center_group", operator: "=", value: costCenterGroup });
                    // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    // const getCostCenterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    // const costCenterOpts = [
                    //     { value: '', label: 'Select', field_id: '', field_name: '' },
                    //     ...getCostCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.cost_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.cost_center_short_name, cost_center_group: costCenter.cost_center_group })),
                    // ];
                    // setCostCenterOptions(costCenterOpts);
                    // setCostCenterId('');

                    $("#error_cmb_subdepartment_id").hide();
                    $("#error_cmb_department_id").hide();

                    // GET ISSUE NO's 
                    resetGlobalQuery();
                    globalQuery.columns = ["issue_no", "issue_date"];
                    globalQuery.table = "st_indent_material_issue_master";
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "indent_issue_type_id", operator: "=", value: 2 });
                    globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: SubDepartmentval });
                    globalQuery.conditions.push({ field: "issue_status", operator: "IN", values: ['MI', 'I'] });
                    
                    const getIssueNos = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    debugger
                    const issueNoOpts = [
                        { value: '', label: 'Select', },
                        ...getIssueNos.sort((a, b) => b.issue_no - a.issue_no).map((issueNo) => ({
                            ...issueNo,
                            value: issueNo.issue_no,
                            label: `${issueNo.issue_no}`,
                            issue_date: issueNo.issue_date,
                        })),
                    ];
                    setIssueNoOptions(issueNoOpts)
                   return issueNoOpts;
                }
                break;
            default:
                break;
        }
    }
    //--------------------------------------------------------Start Details section----------------------------------------------------------------------

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/MaterialReturnToStore/MaterialReturnToStoresListing/reg' : `/Transactions/TPurchaseOrder/MaterialReturnToStore/MaterialReturnToStoresListing`;
        navigate(Path);
    }

    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });
    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------

    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    // fn for get search results
    const FnSearchOnChange = (searchText) => {
        debugger
        if(FnValidateGatePass(true)){
        try {
            if (searchText.trim() !== "") {
                const lowercasedSearchText = searchText.toLowerCase();
                const filteredList = issueDetailsData.filter(item =>
                    item.product_material_name.toLowerCase().includes(lowercasedSearchText) || item.product_material_code.toLowerCase().includes(lowercasedSearchText)
                );
                if (filteredList.length > 0) {
                    console.log('MaterialSearchData: ', filteredList);
                    // set options data for suggestions
                    let uniqueIds = new Set();
                    let optionsData = filteredList
                        .filter(item => {
                            if (uniqueIds.has(item.product_material_id)) {
                                return false; // Skip if the ID is already present
                            }
                            uniqueIds.add(item.product_material_id);
                            return true;
                        })
                        .map(item => ({
                            name: `${item.product_material_code === null || item.product_material_code === '' ? "" : `[${item.product_material_code}] `}${item.product_material_name}`,
                            value: item.product_material_id,
                            product_material_id: item.product_material_id
                        }));

                    setOptions(optionsData)
                } else {
                    console.log('productData else : ', filteredList);
                    setOptions([])
                }
            }
        } catch (error) {
            console.log("Error On search material: ", error);
        }
    }        
}

    // get matreial data on select
    const selectMaterial = (productId) => {
        debugger
        setIsLoading(true);
        if (productId !== undefined) {
            setMaterialReturnDetailsData((prevArray) => {
                const existingMaterialIds = prevArray.map((item) => item.product_material_id);

                const newItems = issueDetailsData.filter(
                    (item) => !existingMaterialIds.includes(item.product_material_id) && item.product_material_id===productId
                );
                return [...prevArray, ...newItems];
            });
        }
        setIsLoading(false);
    }

    const showIssueDetailsDetails = async (issue_no) => {
        let IssueDetailsData = [];
        if (issue_no !== "" && issue_no !== "0") {
            resetGlobalQuery()
            globalQuery.columns = [
                "sm.product_rm_code as product_material_code",
                "mi.product_material_id",
                "sm.product_rm_name as product_material_name",
                'mi.material_batch_rate',
                "gd.godown_section_beans_name",
                "sm.godown_id",
                "sm.godown_section_id",
                "sm.godown_section_beans_id",
                "sm.product_rm_stock_unit_id as product_material_unit_id",
                "u.product_unit_name",
                "mi.product_category1_id",
                "mi.product_category2_id",
                "0 as product_material_issue_return_quantity",
                "0 as product_material_issue_return_weight",
                "0 as product_material_issue_return_boxes",
                "0 as product_material_receipt_quantity",
                "0 as product_material_receipt_weight",
                "0 as product_material_receipt_boxes"
                
            ];
            globalQuery.table = "st_indent_material_issue_details mi"
            globalQuery.joins.push({
                "table": "sm_product_rm sm",
                "type": "LEFT",
                "on": [
                    {
                        "left": "sm.product_rm_id",
                        "right": "mi.product_material_id"
                    },
                    {
                        "left": "sm.is_delete",
                        "right": '0'
                    },
                ]
            })
            globalQuery.joins.push({
                "table": "cm_godown_section_beans gd",
                "type": "LEFT",
                "on": [
                    {
                        "left": "gd.godown_id",
                        "right": "sm.godown_id"
                    },
                    {
                        "left": "gd.godown_section_id",
                        "right": "sm.godown_section_id"
                    },
                    {
                        "left": "gd.godown_section_beans_id",
                        "right": "sm.godown_section_beans_id"
                    },
                    {
                        "left": "gd.is_delete",
                        "right": '0'
                    },
                ]
            })
            globalQuery.joins.push({
                "table": "sm_product_unit u",
                "type": "LEFT",
                "on": [
                    {
                        "left": "u.product_unit_id",
                        "right": "sm.product_rm_stock_unit_id"
                    },
                    {
                        "left": "u.is_delete",
                        "right": '0'
                    },
                ]
            })
            globalQuery.conditions.push({ field: "mi.is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "mi.issue_no", operator: "=", value: issue_no });
            IssueDetailsData = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            console.log("issuedetails data here : ", IssueDetailsData);
            setIssueDetailsData(IssueDetailsData)
        }
    }

    //    By Nilesh 
    const handleIssueDetailsInputChange = (currentRowData, event, fieldName = null) => {

        let enteredValue;
        let clickedColName;
        let arrayIndex;
        let issueItemsQtyinp;


        if (event && event.target) {
            // Standard input fields (text inputs)
            enteredValue = event.target.value;
            clickedColName = event.target.getAttribute('Headers');
            issueItemsQtyinp = document.querySelector('#' + event.target.id);

            if (event.target.parentElement && event.target.parentElement.parentElement) {
                arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'), 10);
            }
        } else {
            // React-Select case
            enteredValue = event.value; // React-Select provides an object { value, label }
            clickedColName = fieldName; // Manually pass field name
        }

        if (!clickedColName) return;

        switch (clickedColName) {
            case 'product_material_issue_return_quantity':
                currentRowData[clickedColName] = enteredValue;
                break;

            case 'product_material_receipt_quantity':
            if(enteredValue > currentRowData.product_material_issue_return_quantity){
                    issueItemsQtyinp.parentElement.dataset.tip = `Receive Quantity can't exceed Return Quantity...!`;
                }
                else{
                    currentRowData[clickedColName] = enteredValue;
                    delete issueItemsQtyinp.parentElement.dataset.tip;
                }
                break;

            default:
                currentRowData[clickedColName] = enteredValue;
                break;
        }

        // Update state
        setMaterialReturnDetailsData((prevArr) => {
            const updatedArr = [...prevArr];
            updatedArr[arrayIndex] = currentRowData;
            return updatedArr;
        });
    };




    const deleteRecord = (Index, action) => {
        setMaterialReturnDetailsData((prevDetailsData) => prevDetailsData.filter((data, i) => i !== Index))
    }

    //details table
    const renderDetailsTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='materialreturn-details-tbl' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        {(keyForViewUpdate === "add" || keyForViewUpdate === "update") && <th className="erp_table_th">Action</th>}
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Material Code</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Return Quantity</th>
                        {/* <th className="erp_table_th">Cost Center</th> */}
                        <th className="erp_table_th">Material Unit</th>
                        <th className="erp_table_th">Godown(Location)</th>
                        <th className="erp_table_th">Material Rate</th>
                        <th className="erp_table_th">Return Status</th>
                        <th className="erp_table_th">Remark</th>
                        {(keyForViewUpdate==="receive" || (keyForViewUpdate==="view" && cmb_return_status === "R")) && <th className="erp_table_th">Receive Quantity</th>}
                    </tr>
                </thead>
                <tbody>
                    {materialReturnDetailsData.map((issueItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            {(keyForViewUpdate === "add" || keyForViewUpdate === "update") &&
                                <td className="erp_table_td" style={{ verticalAlign: "middle" }} ><Tooltip title="Delete" placement="top">
                                    <MDTypography className={`erp-view-btn ${keyForViewUpdate === "add" || keyForViewUpdate === "update" ? '' : 'd-none'}`} >
                                        <MdDelete sty className={`erp-delete-btn`} style={{ display: "flex", margin: "auto" }} onClick={() => deleteRecord(Index)} />
                                    </MDTypography></Tooltip>
                                </td>}

                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className="erp_table_td "> {issueItem.product_material_code}</td>
                            <td className="erp_table_td "> {issueItem.product_material_name}</td>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <input type="text" id={`product_material_issue_return_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.product_material_issue_return_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='product_material_issue_return_quantity' />
                                    :
                                    issueItem.product_material_issue_return_quantity
                                }
                                </td>
                            <td className="erp_table_td ">
                                {unitOpts.find((unit)=> unit.product_unit_id === issueItem.product_material_unit_id)?.product_unit_name}
                            </td>
                            <td className="erp_table_td ">
                                {issueItem.godown_section_beans_name}
                            </td>
                            <td className="erp_table_td text-end">
                                {issueItem.material_batch_rate}
                            </td>
                            <td className="erp_table_td ">
                            {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ? <select rowIndex={`${issueItem}`} id={`issue_return_item_status_${issueItem.product_material_id}`} disabled class="form-select form-select-sm erp_input_field_table_txt m-0"
                                    onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} value={issueItem.issue_return_item_status} >
                                    <option value="P">Pending</option>
                                    <option value="R">Returned</option>
                                </select>: keyForViewUpdate === "receive" || (keyForViewUpdate === "view" && cmb_return_status === "R")  ? "Returned" : "Pending" }
                            </td>
                            <td className="erp_table_td ">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <Form.Control as="textarea" id={`remark_${issueItem.product_material_id}`} value={issueItem.issue_return_remark}
                                        Headers="issue_return_remark" onInput={(e) => { handleIssueDetailsInputChange(issueItem, e) }}
                                        className="erp_input_field mb-0" maxLength="1000" />
                                    :
                                    issueItem.issue_return_remark
                                }
                            </td>
                            {(keyForViewUpdate==="receive" || (keyForViewUpdate==="view" && cmb_return_status === "R")) && <td className="erp_table_td text-end">
                                {keyForViewUpdate === 'receive' ?
                                    <input type="text" id={`product_material_receipt_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.product_material_receipt_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='product_material_receipt_quantity' />
                                    :
                                    issueItem.product_material_receipt_quantity
                                }
                            </td>}
                        </tr>
                    )}
                </tbody>
            </Table>
        </>

    }, [materialReturnDetailsData, 
        // costCenterOptions
    ])

    //-------------------------------- validate before save ------------------------------------------------------

    const validateFields = async () => {

        let prodTypeId = $('#cmb_product_type option:selected').val();
        if (prodTypeId === "" || prodTypeId === "0") {
            $("#error_cmb_product_type").text("Please fill this field...!");
            $("#error_cmb_product_type").show();
            $("#cmb_product_type").focus();
            return false;
        }
        else {
            validate.current.validateFieldsOnChange('material_return_form_id')
        }


    }

    const FnValidateGatePass = async (validateForSearch) => {
        
        let prodType = cmb_product_type;
        let category1Id = category1ComboRef.current.value;
        let checkName = returnByComboRef.current.employee_id;
        let departmentId = cmb_department_ref.current.value
        let subDepartmentId = cmb_subdepartment_ref.current.value
        let issueNo = issueNoComboRef.current.value
        
        if (prodType === "" || prodType === "0") {
            $("#error_cmb_product_type").text("Please fill this field...!");
            $("#error_cmb_product_type").show();
            $("#cmb_product_type").focus();
            return;
        }
        if (category1Id == undefined || category1Id === "" || category1Id == "0") {
            $("#error_cmb_product_category1_id").text("Please fill this field...!");
            $("#error_cmb_product_category1_id").show();
            $("#error_cmb_product_category1_id").focus();
            return;
        }

        let headerDataIsValid = await validate.current.validateForm("material_return_form_id");
        if (!headerDataIsValid) { return false; }

        if (!departmentId || departmentId === "0") {
            $("#error_cmb_department_id").text("Please fill this field...!");
            $("#error_cmb_department_id").show();
            $("#cmb_department_id").focus();
            return;
        }
        if (!subDepartmentId || subDepartmentId === "0") {
            $("#error_cmb_subdepartment_id").text("Please fill this field...!");
            $("#error_cmb_subdepartment_id").show();
            $("#cmb_subdepartment_id").focus();
            return;
        }
        if (!issueNo || issueNo === "") {
            $("#error_cmb_issue_no").text("Please Select Issue No...!");
            $("#error_cmb_issue_no").show();
            $("#cmb_issue_no").focus();
            return;
        }
        if ((!checkName || checkName === "0")&& !validateForSearch) {
            $("#error_cmb_return_by_id").text("Please fill this field...!");
            $("#error_cmb_return_by_id").show();
            $("#cmb_return_by_id").focus();
            return;
        }

        // Avoid below validation for search material filter
        if(!validateForSearch){
        let issueDetailsIsValid = true;
        let addedIssuematerial = $('#materialreturn-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add Atleast One Material Detail.');
            setShowErrorMsgModal(true);
            return issueDetailsIsValid = false;
        }
        else {

            if (issueDetailsIsValid === true) {
                const tableRows = $('#materialreturn-details-tbl tbody tr');
                tableRows.each(function () {
                    if (keyForViewUpdate === "add" || keyForViewUpdate === "update") {
                        const issueQty = parseFloat($(this).find('input[id^="product_material_issue_return_quantity_"]').val()) || 0;
                        if (isNaN(issueQty) || issueQty === "" || issueQty === "0" || issueQty === 0) {
                            $(this).find(`input[id^='product_material_issue_return_quantity_']`).parent().attr('data-tip', 'Quantity cannot be 0...!');
                            $(this).find(`input[id^='product_material_issue_return_quantity_']`).focus();
                            return issueDetailsIsValid = false;
                        }
                    }else if(keyForViewUpdate==="receive"){
                        const receivedQty = parseFloat($(this).find('input[id^="product_material_receipt_quantity_"]').val()) || 0;
                        if (isNaN(receivedQty) || receivedQty === "" || receivedQty === "0" || receivedQty === 0) {
                            $(this).find(`input[id^='product_material_receipt_quantity_']`).parent().attr('data-tip', 'Receive Quantity cannot be 0...!');
                            $(this).find(`input[id^='product_material_receipt_quantity_']`).focus();
                            return issueDetailsIsValid = false;
                        }
                    }
                });
            }

            return issueDetailsIsValid;
        }
    }

    }
    //-------------------------- Save Json ------------------------------------------------------------------

    const addMaterialGatePass = async () => {
        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                let validateSelectBoxes = await FnValidateGatePass();
                checkIsValidate = validateSelectBoxes
            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                let lastGatePassNo = txt_material_return_no
                if (keyForViewUpdate === "add") {
                    debugger
                    lastGatePassNo = await GenerateGatePassNo();
                }
                let json = { 'TransHeaderData': {}, 
                'TransDetailData': [], 
                'commonIds': {} }
                debugger
                const transferMasterFormData = {
                    issue_return_master_transaction_id: keyForViewUpdate !== "add" ? issue_return_master_transaction_id : 0,
                    material_type: material_type,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    issue_return_no: lastGatePassNo,
                    issue_return_date: dt_material_return_date,
                    indent_issue_return_type_id:cmb_product_type,
                    indent_issue_return_type: cmb_product_type_name,
                    department_id: cmb_department_ref.current.value ?? cmb_department_id,
                    sub_department_id: cmb_subdepartment_ref.current.value ?? cmb_subdepartment_id,
                    issue_no: cmb_issue_no,
                    return_by_id: cmb_return_by_id,
                    issue_return_status: keyForViewUpdate === "receive" || material_type==="UR" ? "R" :"P",
                    received_by_id: keyForViewUpdate === "add" || keyForViewUpdate === "update"
                        ? null
                        : cmb_approved_by_id, // change to received by id and date...!!!
                    received_date:keyForViewUpdate === "add" || keyForViewUpdate === "update"
                        ? null
                        : dt_received_on,
                    remark: txt_remark,
                    product_category1_id: cmb_product_category1_id,
                    product_category2_id: cmb_product_category2_id,
                    created_by: UserName,
                    created_on: todayDate(),
                    modified_by: UserName,
                    modified_on: todayDate(),
                    modified_by: idList !== "" ? UserName : null,
                }
                json.TransHeaderData = transferMasterFormData;
                const materialIdSet = new Set();

                const filteredrawMaterialDetailsRecordsData = materialReturnDetailsData.filter(row => row.issue_return_item_status !== "UR");

                for (let issueItem = 0; issueItem < filteredrawMaterialDetailsRecordsData.length; issueItem++) {
                    const materialReturnDetailsRow = filteredrawMaterialDetailsRecordsData[issueItem];

                    const MaterialtransferRow = {};
                    MaterialtransferRow['issue_return_details_transaction_id'] = keyForViewUpdate !== "add" ? materialReturnDetailsRow.issue_return_details_transaction_id : 0
                    MaterialtransferRow['issue_return_master_transaction_id'] = keyForViewUpdate !== "add" ? issue_return_master_transaction_id : 0
                    MaterialtransferRow['company_id'] = COMPANY_ID
                    MaterialtransferRow['company_branch_id'] = COMPANY_BRANCH_ID
                    MaterialtransferRow['financial_year'] = FINANCIAL_SHORT_NAME
                    MaterialtransferRow['product_material_id'] = materialReturnDetailsRow.product_material_id
                    MaterialtransferRow['product_material_unit_id'] = materialReturnDetailsRow.product_material_unit_id
                    MaterialtransferRow['product_material_issue_return_quantity'] = materialReturnDetailsRow.product_material_issue_return_quantity
                    MaterialtransferRow['product_material_issue_return_weight'] = 0
                    MaterialtransferRow['product_material_issue_return_boxes'] = 0
                    MaterialtransferRow['product_material_receipt_quantity'] = keyForViewUpdate === "receive" ? materialReturnDetailsRow.product_material_receipt_quantity :  0
                    MaterialtransferRow['product_material_receipt_weight'] = 0
                    MaterialtransferRow['product_material_receipt_boxes'] = 0
                    MaterialtransferRow['issue_return_item_status'] = keyForViewUpdate === "receive" || material_type==="UR" ? "R" : "P"
                    MaterialtransferRow['godown_id'] = materialReturnDetailsRow.godown_id
                    MaterialtransferRow['godown_section_id'] = materialReturnDetailsRow.godown_section_id
                    MaterialtransferRow['godown_section_beans_id'] = materialReturnDetailsRow.godown_section_beans_id
                    MaterialtransferRow['issue_return_remark'] = materialReturnDetailsRow.issue_return_remark
                    MaterialtransferRow['product_category1_id'] = cmb_product_category1_id
                    MaterialtransferRow['product_category2_id'] = cmb_product_category2_id
                    MaterialtransferRow['material_batch_rate'] = materialReturnDetailsRow.material_batch_rate
                    MaterialtransferRow['created_by'] = UserName
                    MaterialtransferRow['created_on'] = todayDate()
                    MaterialtransferRow['modified_by'] = UserName
                    MaterialtransferRow['modified_on'] = todayDate()

                    json.TransDetailData.push(MaterialtransferRow);
                }
                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.keyForViewUpdate = keyForViewUpdate
                json.commonIds.transfer_no = keyForViewUpdate === 'add' ? lastGatePassNo : txt_material_return_no
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log('Material Return To Stores', json)

                const formData = new FormData();
                formData.append(`MaterialReturnToStoreData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StRawMaterialReturnMaster/FnAddUpdateRecordForRMReturn/`, requestOptions);
                const responce = await apicall.json();

                console.log("response error: ", responce.data);
                if (responce.error === 0) {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {

                    setSuccMsg(responce.message);
                    setShowSuccessMsgModal(true);
                    if (responce.success == 1) {
                        setModalOrderDetails(` Material Return No: ${txt_material_return_no}`)
                    }
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }

    }
    // ------------------------------------------- set update data ----------------------------------------------------


    const FnCheckUpdateResponse = async () => {
        setIsLoading(true)
        try {
            if (idList !== '') {
                debugger
                setMaterialReturnNo(idList.issue_return_no)
                const formData = new FormData();
                formData.append(`issue_return_no` , idList.issue_return_no)
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StRawMaterialReturnMaster/FnShowParticularReturnRecord/${parseInt(COMPANY_ID)}?issue_return_no=${idList.issue_return_no}`);
                const responce = await apiCall.json();
                console.log(`MaterialReturnResponce`,responce)
                let materialReturnMasterData  = responce.rawMaterialReturnMasterRecord;
                setMaterialReturnMasterDataForPrint(responce.rawMaterialDetailsRecords)

                if (responce.rawMaterialReturnMasterRecord !== null && responce.rawMaterialReturnMasterRecord !== "") {
                    setReturnDataArraySummary(responce.rawMaterialReturnMasterRecord);
                }

                if (materialReturnMasterData !== null && materialReturnMasterData !== "") {
                    setMaterialReturnNo(materialReturnMasterData.issue_return_no)
                    setProductType(materialReturnMasterData.indent_issue_return_type_id)
                    setProductTypeName(materialReturnMasterData.product_type_name)
                    await comboOnChange("cmb_product_type")
                    setProductCategory1Id(materialReturnMasterData.product_category1_id)
                    category1ComboRef.current.value = materialReturnMasterData.product_category1_id
                    await comboOnChange('category1')
                    setProductCategory2Id(materialReturnMasterData.product_category2_id)
                    category2ComboRef.current.value = materialReturnMasterData.product_category2_id
                    setDepartmentId(materialReturnMasterData.department_id);
                    cmb_department_ref.current.value = materialReturnMasterData.department_id;
                    let subDepartmentOptArr = await comboOnChange("Department")
                    setSubDepartmentId(materialReturnMasterData.sub_department_id);
                    cmb_subdepartment_ref.current.value = materialReturnMasterData.sub_department_id;
                    // let costcenter = subDepartmentOptArr.find((subDep) => subDep.field_id === materialReturnMasterData.sub_department_id);
                    // cmb_subdepartment_ref.current.costCenterGroup = costcenter.costCenterGroup;
                    let issueNoOpts = await comboOnChange('SubDepartment');
                    setIssueNo(materialReturnMasterData.issue_no)
                    issueNoComboRef.current.value = materialReturnMasterData.issue_no
                    let issueObj =  issueNoOpts.find((item) => item.value === materialReturnMasterData.issue_no)
                    setIssueDate(issueObj.issue_date)
                    // await showIssueDetailsDetails(selected_issue_no);
                    // await comboOnChange('issue_no');
                    setMaterialReturnDate(materialReturnMasterData.issue_return_date)
                    setMaterialType(materialReturnMasterData.material_type)
                    let materialReturnStatus = '';

                    switch (keyForViewUpdate) {
                        case 'receive':
                            materialReturnStatus = 'R'
                            break;

                        default:
                            materialReturnStatus = materialReturnMasterData.issue_return_status
                            break;
                    }
                    setRemark(materialReturnMasterData.remark)
                    setReturnById(materialReturnMasterData.return_by_id)
                    returnByComboRef.current.employee_id = materialReturnMasterData.return_by_id
                    returnByComboRef.current.employee_name = materialReturnMasterData.return_by_name

                    setReturnStatus(materialReturnStatus)
                }
                debugger
                if (responce.rawMaterialDetailsRecords !== null && responce.rawMaterialDetailsRecords !== "") {
                    const transformedData = responce.rawMaterialDetailsRecords.map((item) => ({
                        ...item,
                        product_material_code: item.product_material_id,
                        product_material_name: item.product_material_name,
                        product_material_issue_return_quantity: item.product_material_issue_return_quantity,
                        product_unit_name: item.product_unit_name,
                        godown_name: item.godown_section_beans_name,
                        issue_return_item_status: item.keyForViewUpdate==="receive"?"R":"P",
                        issue_return_remark: item.issue_return_remark,
                        product_material_receipt_quantity:  item.product_material_receipt_quantity,
                    }));
                    setMaterialReturnDetailsData(transformedData);
                }

            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
        finally {
            setIsLoading(false)
        }
    }

    const materialGatePassContent = {
        company: {
            company: '',
            company_branch: '',
        },
        materialDetails: {

        },
        header: {

        },
        footer: {

        },
        items: [],
    };

    const printIssueSlip = async (openPrintModal) => {
        let MaterialMasterData = materialReturnMasterDataForPrint
        materialGatePassContent.materialDetails.issue_return_no = MaterialMasterData.issue_return_no
        materialGatePassContent.materialDetails.gate_pass_date = validateNumberDateInput.current.formatDateToDDMMYYYY(MaterialMasterData.gate_pass_date)
        materialGatePassContent.materialDetails.product_type_name = cmb_product_type_name

        //Company details
        materialGatePassContent.company.company = materialReturnDetailsData[0].company_name
        materialGatePassContent.company.company_branch = materialReturnDetailsData[0].company_branch_name

        materialGatePassContent.header.material_type = MaterialMasterData.material_type_desc
        materialGatePassContent.header.vehicle_no = MaterialMasterData.vehicle_no
        materialGatePassContent.header.product_type_name = cmb_product_type_name
        materialGatePassContent.header.gate_pass_status = MaterialMasterData.gate_pass_status
        materialGatePassContent.header.approved_by_name = MaterialMasterData.approved_by_name
        materialGatePassContent.header.checker_by_name = MaterialMasterData.checker_by_name

        materialGatePassContent.header.supp_branch_name = MaterialMasterData.supp_branch_name
        materialGatePassContent.header.supp_contact_no = MaterialMasterData.supp_contact_no
        materialGatePassContent.header.state_name = MaterialMasterData.state_name
        materialGatePassContent.header.city_name = MaterialMasterData.city_name
        materialGatePassContent.header.supplier_branch_address = MaterialMasterData.supplier_branch_address
        materialGatePassContent.header.department_name = MaterialMasterData.department_name
        materialGatePassContent.header.sub_department_name = MaterialMasterData.sub_department_name

        for (let index = 0; index < materialReturnDetailsData.length; index++) {
            const element = materialReturnDetailsData[index];
            const detailsData = {
                sr_no: index + 1,
                financial_year: element.financial_year,
                company_id: element.company_id,
                company_branch_id: element.company_branch_id,
                product_material_id: element.product_material_id,
                material_code: element.material_code,
                material_name: element.material_name,
                outward_quantity: element.outward_quantity,
                cost_center_id: element.cost_center_id,
                cost_center_name: element.cost_center_name,
                outward_weight: element.outward_weight,
                inward_quantity: element.inward_quantity,
                inward_weight: element.inward_weight,
                pending_inward_quantity: element.pending_inward_quantity,
                issue_return_item_status: element.issue_return_item_status,
                product_type_id: element.product_type_id,
                product_type_name: element.product_type_name,
                rate: element.rate,
                remark: element.remark,
                purpose: element.purpose
            }
            materialGatePassContent.items.push(detailsData)
            console.log(detailsData);
        }

        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: materialGatePassContent, navigationLink: '/Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialReturnToStoresEntry', invoiceType: 'MGP', title: 'Requisition/Issue', idList: idList, keyForViewUpdate: keyForViewUpdate } })
        } else {
            return renderToString(<MaterialGatePassInvoice invoiceContent={materialGatePassContent} />);
        }
    };



    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'> Material Return To Stores {actionType}</label>
                        </div>
                        <form id="material_return_form_id">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className="row">
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label"> Material Type<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check className="erp_radio_button" label="Used/Replaced" type="radio" value="UR" name="materialType"
                                                        checked={material_type === 'UR'} defaultChecked onClick={() => { setMaterialType('UR'); }} />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check className="erp_radio_button" label="Un-Used" value="UU" type="radio" name="materialType"
                                                        checked={material_type === 'UU'} onClick={() => { setMaterialType('UU'); }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-2 col-12'>
                                            <Form.Label className="erp-form-label">Product Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-3 col-12'>
                                            <select id="cmb_product_type"
                                                className="form-select form-select-sm"
                                                value={cmb_product_type}
                                                placeholder="Search for Product Type"
                                                // onChange={(e) => {
                                                //     setProductType(e.target.value)
                                                //     comboOnChange("cmb_product_type")
                                                //     validateFields();
                                                // }}
                                                disabled={true}>
                                                {productTypeOptions.map((typeOpt) => <option shortName={typeOpt.product_type_short_name} value={typeOpt.value}>{typeOpt.label}</option>)}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_product_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Category 1<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-4 col-12'>
                                            <Select
                                                ref={category1ComboRef}
                                                inputId="cmb_product_category1_id"
                                                value={category1Options.find(option => option.value === cmb_product_category1_id) || null}
                                                options={category1Options}
                                                isDisabled={keyForViewUpdate !== "add" && keyForViewUpdate !== "update"}
                                                onChange={(selectedOption) => {
                                                    setProductCategory1Id(selectedOption.value)
                                                    category1ComboRef.current = selectedOption;
                                                    comboOnChange('category1');
                                                }}
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-2">
                                            <Form.Label className="erp-form-label">Category 2</Form.Label>
                                        </div>
                                        <div className='col-sm-3 col-12'>
                                            <Select
                                                ref={category2ComboRef}
                                                inputId="cmb_product_category2_id"
                                                value={category2Options.find(option => option.value === cmb_product_category2_id) || null}
                                                options={category2Options}
                                                isDisabled={keyForViewUpdate !== "add" && keyForViewUpdate !== "update"}
                                                onChange={(selectedOption) => {
                                                    setProductCategory2Id(selectedOption.value)
                                                    category2ComboRef.current = selectedOption;
                                                    comboOnChange('category2');
                                                }
                                                }
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"

                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Material Return No & Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-12 col-sm-4 pe-md-0'>
                                            <Form.Control type="text" id="txt_material_return_no" className="erp_input_field" value={txt_material_return_no} optional='optional' disabled />
                                        </div>
                                        <div className="col-12 col-sm-5 pt-md-0 pt-3">
                                            <Form.Control type="date" id='dt_material_return_date'
                                                className="erp_input_field" value={dt_material_return_date}
                                                onChange={e => {
                                                    setMaterialReturnDate(e.target.value);
                                                    validateFields();
                                                }}
                                                min={currentDate}
                                                Disabled={keyForViewUpdate === "add" || keyForViewUpdate === "update" ? false : true}
                                            />
                                            <MDTypography variant="button" id="error_dt_material_return_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Select ref={cmb_department_ref}
                                                options={departmentOptions}
                                                inputId="cmb_department_id"
                                                isDisabled={keyForViewUpdate !== "add" && keyForViewUpdate !== "update"}
                                                value={departmentOptions.length > 0 ? departmentOptions.find(option => option.value === cmb_department_id) : null}
                                                onChange={(selectedOpt) => {
                                                    cmb_department_ref.current = selectedOpt;
                                                    setDepartmentId(selectedOpt.value);
                                                    comboOnChange('Department');
                                                    validateFields();

                                                }}
                                                placeholder="Search for a Department"
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Sub-Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Select ref={cmb_subdepartment_ref}
                                                options={subDepartmentOptions}
                                                isDisabled={keyForViewUpdate !== "add" && keyForViewUpdate !== "update"}
                                                inputId="cmb_subdepartment_id" // Provide the ID for the input box
                                                value={subDepartmentOptions.length > 1 ? subDepartmentOptions.find(option => option.value === cmb_subdepartment_id) : null}
                                                onChange={(selectedOpt) => {
                                                    cmb_subdepartment_ref.current = selectedOpt;
                                                    setSubDepartmentId(selectedOpt.value);
                                                    comboOnChange("SubDepartment");
                                                    validateFields();
                                                }}
                                                placeholder="Search for a Sub-Department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_subdepartment_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>

                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Issue No.<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className={`col-sm-5 col-10`}>
                                            <Select ref={issueNoComboRef}
                                                options={issueNoOptions}
                                                isDisabled={['view', 'update','receive'].includes(keyForViewUpdate)}
                                                inputId="cmb_issue_no" // Provide the ID for the input box
                                                value={issueNoOptions.find(option => option.value === cmb_issue_no) || null}
                                                onChange={(selectedOpt) => {
                                                    setIssueNo(selectedOpt.value);
                                                    issueNoComboRef.current = selectedOpt;
                                                    comboOnChange('issue_no');
                                                    validateFields();
                                                }}
                                                placeholder="Search for Issue No..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_issue_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className={`col-sm-4 col-10`}>
                                            <Form.Control type="date" id='dt_issue_date' className="erp_input_field" value={dt_issue_date} disabled optional='optional' />
                                            <MDTypography variant="button" id="error_dt_issue_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Return By & Status<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-5 col-12 mb-1'>
                                            <Select ref={returnByComboRef}
                                                options={employeeOptions}
                                                isDisabled={!["add", "update"].includes(keyForViewUpdate)}
                                                inputId="cmb_return_by_id" // Provide the ID for the input box
                                                value={employeeOptions.find(option => option.value == cmb_return_by_id)}
                                                onChange={(selectedOpt) => {
                                                    setReturnById(selectedOpt.value);
                                                    returnByComboRef.current = selectedOpt;
                                                    validateFields();
                                                    comboOnChange('returnBy');
                                                }}
                                                placeholder="Search for a employee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_return_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {/* <div className="col-sm-2">
                                            <Form.Label className="erp-form-label">Return  </Form.Label>
                                        </div> */}
                                        <div className='col-sm-4 col-12'>
                                            <select id="cmb_return_status " className="form-select form-select-sm" value={cmb_return_status} onChange={(e) => { setReturnStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="R">Returned</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_remark' rows={1} className="erp_txt_area" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' maxLength={1000} />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {(keyForViewUpdate==="receive" || (keyForViewUpdate==="view" && cmb_return_status === "R")) && (
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">Received By & Date<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className='col-sm-5'>
                                                <Select ref={empApprovedComboRef}
                                                    options={employeeOptions}
                                                    isDisabled={['receive','view'].includes(keyForViewUpdate)}
                                                    inputId="cmb_approved_by_id" // Provide the ID for the input box
                                                    value={employeeOptions.find(option => option.value == cmb_approved_by_id)}
                                                    onChange={(selectedOpt) => {
                                                        setApprovedById(selectedOpt.value);
                                                        empApprovedComboRef.current = selectedOpt;
                                                        validateFields();
                                                        comboOnChange('ApprovedBy');
                                                    }}
                                                    placeholder="Search for a employee..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select"
                                                    styles={{
                                                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className={`col`}>
                                            <Form.Control type="date" id='dt_received_on' className="erp_input_field" value={dt_received_on} disabled optional='optional' />
                                            <MDTypography variant="button" id="dt_received_on" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="erp-form-label-md"> Material Details</Accordion.Header>
                            <Accordion.Body>
                                <div className={`row ${keyForViewUpdate === "add" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>
                                    <div className="col-md-4 col-12">
                                        <Form.Group >
                                            <InputGroup className="mt-1">
                                                <Typeahead
                                                    className="custom-typeahead-class"
                                                    id="serch-input-id"
                                                    labelKey={(option) => `${option.name}`}
                                                    placeholder="Search item..."
                                                    onChange={(selected) => {
                                                        if (selected.length > 0) {
                                                            const selectedItem = selected[0];
                                                            selectMaterial(selectedItem.product_material_id); // Example: Assuming product_id is the unique identifier
                                                        }
                                                    }}
                                                    onInputChange={(input) => {
                                                        FnSearchOnChange(input)
                                                    }}
                                                    options={searchOptions || []}
                                                    filterBy={() => true}
                                                    minLength={2}
                                                    ref={typeaheadRef}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="erp-Mt-10" style={{ vh: "5rem" }}>
                                    {materialReturnDetailsData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> :
                                        <div className='row  text-center'>
                                            <div className="col-12 ">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                </>
                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton
                        className={`erp-gb-button erp_MLeft_btn ${(keyForViewUpdate === 'view' && cmb_return_status === "A") ? '' : 'd-none'}`}
                        variant="button"
                        fontWeight="regular"
                        id="printbtn_id"
                        onClick={() => { printIssueSlip(true); }} >
                        Print <FiDownload className="erp-download-icon-btn" />
                    </MDButton>

                    <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate != 'view' ? 'display' : 'd-none'}`} variant="button" onClick={() => { addMaterialGatePass() }}
                        fontWeight="regular"> {actionLabel}</MDButton>
                </div >
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

            </DashboardLayout >
        </>
    )
}

export default FrmMaterialReturnToStoresEntry;