// Login
import Login from "Authentication/Login";
// Invoice Template

//HelpCenter from FrmGeneric/Help/helpCenter
import HelpCenter from "FrmGeneric/Help/helpCenter";
import WeavingSizingHelp from "Help/HWeavingProduction/WeavingSizingHelp";

import InvoiceTemplate from "FrmGeneric/InvoiceTemplate";
// Error 500
import Error_500 from "components/Errors/Error_500";
import Netcheck from "components/NetworkCheck/Netcheck";
// Dashboard
import DashBoard from "DashBoard";
// Company
import FrmMCompanyList from "Masters/MCompany/FrmMComapnyList";
import FrmMComapny from "Masters/MCompany/FrmCompany";
// Financial Year
import FrmFinancialYearListing from "Masters/MFinancialYear/FrmFinancialYearEntry";
import FrmFinancialYearEntry from "Masters/MFinancialYear/FrmFinancialYearEntry";
// Banks
import FrmMBankList from "Masters/MBanks/FrmMBankList";
import FrmMBankEntry from "Masters/MBanks/FrmBank/components/FrmMBankEntry";
// Departments
import FrmMDepartmentList from "Masters/MDepartment/FrmMDepartmentList";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
// Designations
import FrmMDesignationList from "Masters/MDesignation/FrmMDesingnationList";
import FrmDesignation from "Masters/MDesignation/FrmDesignation";
// Employees
import FrmMEmployeeList from "Masters/MEmployee/FrmMEmployeesList";
import MEmployeeEntry from "Masters/MEmployee/MEmployeeEntry";
import MEmployeePrint from "Masters/MEmployee/MEmployeePrint";
import MEmployeeWorkerPrint from "Masters/MEmployee/MEmployeeWorkerPrint";
import FrmPayrollSettingEntry from "./Masters/MPayrollSetting/FrmPayrollSettingEntry"
import FrmPayrollSettingListing from "./Masters/MPayrollSetting/FrmPayrollSettingListing";

// Customers
import FrmMCustomerList from "Masters/MCustomer/FrmMCustomerList";
import FrmCustomerEntry from "Masters/MCustomer/FrmCustomerEntry";
// Suppliers
import FrmSpplierList from "Masters/MSupplier/FrmSupplierList";
import FrmSupplier from "Masters/MSupplier/FrmSupplier";
// Agents
import FrmAgentList from "Masters/MAgent/FrmAgentList";
import FrmMAgent from "Masters/MAgent/FrmMAgent";
// Contractors
import FrmMContractorList from "Masters/MContractor/FrmMContractorList";
import FrmContractorEntry from "Masters/MContractor/FrmContractorEntry";
// Transporter
import FrmMTransporterList from "Masters/MTransporter/FrmMTransporterList";
import FrmTransporter from "Masters/MTransporter/FrmTransporter";
// City
import FrmCityList from "Masters/MCity/FrmCityList";
import FrmCity from "FrmGeneric/MCity/FrmCity";

// Destinations
import FrmMDestinationList from "Masters/MDestination/FrmMDestinationList";
import FrmDestinationEntry from "Masters/MDestination/FrmDestinationEntry";
// Godown
import FrmGodownList from "Masters/MGodown/FrmGodownList";
import MGodownEntry from "Masters/MGodown/MGodownEntry";
// HSN-SAC Codes
import FrmMHSNSACList from "Masters/MHSN-SAC/FrmMHSN-SACList";
import FrmHSNSAC from "Masters/MHSN-SAC/FrmHSN-SAC";
// Payment Schedules
import FrmPaymentScheduleListing from "Masters/MPaymentSchedule/FrmPaymentScheduleListing";
import FrmPaymentScheduleEntry from "Masters/MPaymentSchedule/FrmPaymentScheduleEntry";
// Payments Terms
import FrmPaymentTemrsListing from "Masters/MPaymentTerms/FrmPaymentTermsListing";
import FrmPaymentTermsEntry from "Masters/MPaymentTerms/FrmPaymentTermsEntry";
// Tax Types
import FrmMTaxtypeList from "Masters/MTaxtype/FrmMTaxtypeListing";
import FrmMTaxtypeEntry from "Masters/MTaxtype/FrmMTaxtypeEntry";
// Taxations
import FrmMTaxationList from "Masters/MTaxation/FrmMTaxationList";
import FrmTaxationEntry from "Masters/MTaxation/FrmTaxationEntry";
// Shifts
import FrmShiftListing from "Masters/MShift/FrmShiftListing";
import FrmShiftEntry from "Masters/MShift/FrmShiftEntry";
// Holidays
import FrmHolidayListing from "Masters/MHoliday/FrmHolidayListing";
import FrmHolidayEntry from "Masters/MHoliday/FrmHolidayEntry"
// Product-Raw Materials
import FrmMProductRawMaterialList from "Masters/MProductRawMaterial/FrmMProductRawMaterialList";
import FrmMProductRawMaterial from "Masters/MProductRawMaterial/FrmMProductRawMaterial";
// Product Unit Conversions
import FrmproductUnitConversionListing from "Masters/MProductUnitConversion/FrmproductUnitConversionListing";
import FrmproductUnitConversionEntry from "Masters/MProductUnitConversion/FrmproductUnitConversionEntry";
// Product- Services
import FrmMServiceListing from "Masters/MService/FrmMServiceListing";
import FrmMServiceEntry from "Masters/MService/FrmMServiceEntry"
// Product Category 1
import FrmMProductCategory1Listing from "Masters/MProductCategory1/FrmProductCategory1Listing";
import FrmMProductCategory1Entry from "Masters/MProductCategory1/FrmProductCategory1Entry"
// Product Category 2
import FrmMProductCategory2Listing from "Masters/MProductCategory2/FrmMProductCategory2Listing";
import FrmMProductCategory2Entry from "Masters/MProductCategory2/FrmMProductCategory2Entry"
// Product Unit
import FrmProductUnitListing from "Masters/MProductUnit/FrmProductUnitListing";
import FrmProductUnitEntry from "Masters/MProductUnit/FrmProductUnitEntry";
// Product Packing
import FrmMProductPackingListing from "Masters/MProductPacking/FrmMProductPackingListing";
import FrmMProductPackingEntry from "Masters/MProductPacking/FrmMProductPackingEntry";
// Product Makes
import FrmMProductMakeListing from "Masters/MProductMake/FrmMProductMakeListing";
import FrmMProductMakeEntry from "Masters/MProductMake/FrmMProductMakeEntry";
// Product Grades
import FrmMProductMaterialGradeListing from "Masters/MProductMaterialGrade/FrmMProductMaterialGradeListing";
import FrmMProductMaterialGradeEntry from "Masters/MProductMaterialGrade/FrmMProductMaterialGradeEntry";
// Properties
import FrmPropertyListing from "Masters/MProperty/FrmPropertyListing";
import FrmPropertyEntry from "Masters/MProperty/FrmPropertyEntry";
// Communication Templates
import FrmCommunicationsMasterList from "Masters/MCommunicationsMaster/FrmCommunicationsMasterList";
import FrmCommunicationsMasterEntry from "Masters/MCommunicationsMaster/FrmCommunicationsMasterEntry";
// Common Parameters
import CommonParamterListing from "Masters/MCommonParameter/CommonParamterListing";
import CommonParamterEntry from "Masters/MCommonParameter/CommonParamterEntry"
// Modules
import ModuleFormListing from "Masters/MAMModuleForm/ModuleFormListing";
import ModuleFormEntry from "Masters/MAMModuleForm/ModuleFormEntry";
// Cost Centers
import MCostCenterListing from "Masters/MFMCostCenter/MCostCenterListing/Index";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";
// Profit Centers
import ProfitCenterListing from "Masters/ProfitCenter/ProfitCenterListing";
import ProfitCenterEntry from "Masters/ProfitCenter/ProfitCenterEntry";
// Schedules
import MFMScheduleLedgerListing from "Masters/MFMScheduleLedger/MFMScheduleLedgerListing";
import MFMScheduleLedgerEntry from "Masters/MFMScheduleLedger/MFMScheduleLedgerEntry";
// Generals
import MFMGeneralLedgerListing from "Masters/MFMGeneralLedger/MFMGeneralLedgerListing";
import MFMGeneralLedgerEntry from "Masters/MFMGeneralLedger/MFMGeneralLedgerEntry";
// Plants
import FrmPlantlist from "Masters/MPlant/FrmPlantList";
import FrmPlantEntry from "Masters/MPlant/FrmPlantEntry";
// Machines
import FrmMachineListing from "Masters/MMachine/FrmMachinelisting";
import FrmMachineEntry from "Masters/MMachine/FrmMachineEntry";
// Production Process
import MFrmProductionProcessListing from "Masters/MProductionProcess/MFrmProductionProcessListing";
import MFrmProductionProcessEntry from "Masters/MProductionProcess/MFrmProductionProcessEntry";
// Spinning Plan
import FrmMProductionSpinningPlanParameterListing from "Masters/MXMProductionSpinningPlanParameter/FrmMProductionSpinningPlanParameterListing";
import FrmMProductionSpinningPlanParameterEntry from "Masters/MXMProductionSpinningPlanParameter/FrmMProductionSpinningPlanParameterEntry";
// Production Stopage Reasons
import MFrmProductionStoppageReasonsListing from "Masters/MProductionStoppageReasons/MFrmProductionStoppageReasonsListing";
import MFrmProductionStoppageReasonsEntry from "Masters/MProductionStoppageReasons/MFrmProductionStoppageReasonsEntry";
// Production Wastage Types
import MFrmProductionWastageTypesListing from "Masters/MProductionWastageType/MFrmProductionWastageTypesListing";
import MFrmProductionWastageTypesEntry from "Masters/MProductionWastageType/MFrmProductionWastageTypesEntry";
// Spinning Counts
import FrmCountListing from "Masters/MCount/FrmCountListing";
import FrmCountEntry from "Masters/MCount/FrmCountEntry";
// Maintainance Tasks Master
import FrmMaintenanceTaskMasterListing from "Masters/MmaintenanceTaskMaster/FrmMaintenanceTaskMasterListing";
import FrmMaintenanceTaskMasterEntry from "Masters/MmaintenanceTaskMaster/FrmMaintenanceTaskMasterEntry";
// Maintainance Tasks Activity
import MmaintenanceTaskActivityListing from "Masters/MaintenanceTaskActivity/MmaintenanceTaskActivityListing";
import MmaintenanceTaskActivityEntry from "Masters/MaintenanceTaskActivity/MmaintenanceTaskActivityEntry";
// Electrical Meters
import MFrmProductionElectricalMeterListing from "Masters/MProductionElectricalMeter/MFrmProductionElectricalMeterListing";
import MFrmProductionElectricalMeterEntry from "Masters/MProductionElectricalMeter/MFrmProductionElectricalMeterEntry";
// Earning Heads
import FrmMHMEarningHeadsListing from "Masters/MHMEarningHeads/FrmMHMEarningHeadsListing";
import FrmMHMEarningHeadsEntry from "Masters/MHMEarningHeads/FrmMHMEarningHeadsEntry"
// Deductions Heads
import FrmMHMDeductionHeadsListing from "Masters/MHMDeductionHeads/FrmMHMDeductionHeadsListing";
import FrmMHMDeductionHeadsEntry from "Masters/MHMDeductionHeads/FrmMHMDeductionHeadsEntry"
// Product Type
import FrmMProductTypeListing from "Masters/MProductType/FrmProductTypeListing";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
// Product- Finish Goods
import FrmMProductFGList from "Masters/MProductFG/FrmMProductFGList";
import FrmMProductFG from "Masters/MProductFG/FrmMProductFG"
// Product Category 3
import FrmMProductCategory3Listing from "Masters/MProductCategory3/FrmMProductCategory3Listing";
import FrmMProductCategory3Entry from "Masters/MProductCategory3/FrmMProductCategory3Entry";
// Product Category 4
import FrmMProductCategory4Listing from "Masters/MProductCategory4/FrmMProductCategory4Listing";
import FrmMProductCategory4Entry from "Masters/MProductCategory4/FrmMProductCategory4Entry";
// Product Category 5
import FrmMProductCategory5Listing from "Masters/MProductCategory5/FrmMProductCategory5Listing";
import FrmMProductCategory5Entry from "Masters/MProductCategory5/FrmMProductCategory5Entry";
// Product Process
import FrmMProductProcessList from "Masters/MProductProcess/FrmMProductProcessList";
import FrmProductProcessEntry from "Masters/MProductProcess/FrmProductProcessEntry";
// Product QA Parameters
import FrmMProductQaParametersListing from "Masters/MProductQaParameter/FrmProductQaParameterListing";
import FrmMProductQaParametersEntry from "Masters/MProductQaParameter/FrmMProductQaParameterEntry";
// Product Rejection Parameters
import FrmMProductRejectionParametersListing from "Masters/MProductRejectionParameters/FrmMProductRejectionParametersListing";
import FrmMProductRejectionParametersEntry from "Masters/MProductRejectionParameters/FrmMProductRejectionParametersEntry";
// Product Measure Parameters
import FrmMProductMaterialMeasureParametersListing from "Masters/MProductMaterialMeasureParameter/FrmMProductMaterialMeasureParameterListing";
import FrmMProductMaterialMeasureParametersEntry from "Masters/MProductMaterialMeasureParameter/FrmMProductMaterialMeasureParameterEntry";
// Product Shape
import FrmMProductMaterialShapeListing from "Masters/MProductMaterialShape/FrmMProductMaterialShapeListing";
import FrmMProductMaterialShapeEntry from "Masters/MProductMaterialShape/FrmMProductMaterialShapeEntry";
// Employee Type
import MEmployeeTypeListing from "Masters/MEmployeeType/MEmployeeTypeListing";

// Production Section
import FrmProductionSectionListing from "Masters/MProductionSection/FrmProductionSectionListing/Index";
import FrmProductionSectionEntry from "Masters/MProductionSection/FrmProductionSectionEntry";
// Production Sub Section
import FrmProductionSubSectionListing from "Masters/MProductionSubSection/FrmProductionSubSectionListing";
import FrmProductionSubSectionEntry from "Masters/MProductionSubSection/FrmProductionSubSectionEntry";
// Spin Plan
import FrmSpinPlanListing from "Masters/MProductionSpinPlan/MProductionSpinPlanListing";
import FrmSpinPlanEntry from "Masters/MProductionSpinPlan/MProductionSpinPlanEntry";
// Manual Attendance
import FrmManualAttendanceEntry from "Masters/MManualAttendance/FrmManualAttendanceEntry";

// Purchase Requisition
import IndentListing from "Transactions/TPurchaseOrder/Indent/IndentListing";

import PendingIndentListing from "Transactions/TPurchaseOrder/PendingIndentReports";

import FrmIndentMasterEntry from "Transactions/TPurchaseOrder/Indent/IndentEntry/Components/FrmIndentMasterEntry";
// PurchaPurchase Requisition (BOM)
import FrmPurchaseRequisitionListing from "Transactions/TPurchaseOrder/PurchaseRequisition/FrmPurchaseRequisitionListing";
import FrmPurchaseRequisitionEntry from "Transactions/TPurchaseOrder/PurchaseRequisition/PurchaseRequisitionEntry/Components/FrmPurchaseRequisitionEntry";
// Purchase Order (Trading)
import POListing from "Transactions/TPurchaseOrder/ManualPO/POListing";
import FrmPurchaseOrderEntry from "Transactions/TPurchaseOrder/ManualPO/FrmPurchaseOrderEntry";
// Goods Receipt Notes
import FrmGoodsReceiptNoteListing from "Transactions/TPurchaseOrder/GoodsReceiptNote/FrmGoodsReceiptNoteListing";
import FrmGoodsReceiptNoteEntry from "Transactions/TPurchaseOrder/GoodsReceiptNote/FrmGoodsReceiptNoteEntry";
//Goods Return / Sale Purchase
import FrmGoodsReturnEntry from "Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnEntry";
import FrmGoodsReturnListing from "Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing"
// Customer Material Reciept
import FrmTCustomerRecieptListing from "Transactions/TCustomerReciept/FrmTCustomerRecieptListing";
import FrmTCustomerRecieptEntry from "Transactions/TCustomerReciept/FrmTCustomerRecieptEntry/Index";
// Purchase Bill Book(Auto)
import BillBookListing from "Transactions/TPurchaseOrder/TBillBook/BillBookListing";
import FrmBillBookEntry from "Transactions/TPurchaseOrder/TBillBook/FrmBillBookEntry";
// Purchase Bill Book(Manual)
import FrmManualBillBookListing from "Transactions/TPurchaseOrder/TManualBillBook/FrmManualBillBookListing";
import FrmManualBillBookEnrty from "Transactions/TPurchaseOrder/TManualBillBook/FrmManualBillBookEnrty";
// Purchase Payment Supplier
import FrmPurchasePaymentSupplierListing from "Transactions/TPurchaseOrder/TPurchasePaymentSupplier/FrmPurchasePaymentSupplierListing";
import FrmPurchasePaymentSupplierEntry from "Transactions/TPurchaseOrder/TPurchasePaymentSupplier/FrmPurchasePaymentSupplierEntry";
// Enquiry Management (Trading)
import FrmEnquiryListing from "Transactions/TEnquiryManagement/FrmEnquiryListing";
import FrmEnquiryTradingEntry from "Transactions/TEnquiryManagement/FrmEnquiryTradingEntry";
// Enquiry Management (Service)
import FrmEnquiryServiceListing from "Transactions/TEnquiryServiceManagement/FrmEnquiryServiceListing";
import FrmEnquiryServiceEntry from "Transactions/TEnquiryServiceManagement/FrmEnquiryServiceEntry";
// Enquiry Management (Project)
import FrmEnquiryProjectListing from "Transactions/TEnquiryProjectsManagement/FrmEnquiryProjectListing";
import FrmEnquiryProjectEntry from "Transactions/TEnquiryProjectsManagement/FrmEnquiryProjectEntry";
// Quotation (Trading)
import FrmQuotationListing from "Transactions/TQuotation/FrmQuotationListing";
import FrmQuotationMasterEntry from "Transactions/TQuotation/FrmQuotationMasterEntry";
// Quotation (Service)
import FrmQuotationServiceListing from "Transactions/TQuotationService/FrmQuotationServiceListing";
import FrmQuotationServiceEntry from "Transactions/TQuotationService/FrmQuotationServiceEntry";
// Quotation (Projects)
import FrmQuotationProjectListing from "Transactions/TQuotationProjects/FrmQuotationProjectListing";
import FrmQuotationProjectsEntry from "Transactions/TQuotationProjects/FrmQuotationProjectsEntry";
// Sales Order (Trading)
import SalesOrderListing from "Transactions/TSalesOrder/SalesOrderListing";
import SalesOrderEntry from "Transactions/TSalesOrder/SalesOrderEntry";
// Sales Order (Services)
import SOServiceListing from "Transactions/TSalesOrder/Services/SOServiceListing";
import SOServiceEntry from "Transactions/TSalesOrder/Services/SOServiceEntry";
// For Service Scheduling
import TServicePlanningListing from "Transactions/TServicePlanning/TServicePlanningListing";
import TServicePlanningEntry from "Transactions/TServicePlanning/TServicePlanningEntry";

// Sales Order (Projects)
import SalesOrderProjectsListing from "Transactions/TSalesOrder/Projects/SalesOrderProjectsListing";
import SalesOrderProjectEntry from "Transactions/TSalesOrder/Projects/SalesOrderProjectEntry";
// Work Order (Trading)
import FrmWorkOrderListing from "Transactions/TWorkOrderTrading/FrmWorkOrderListing";

// Dispatch Note (Trading)
import TDispatchNoteListing from "Transactions/TDispatchNote/TDispatchNoteListing";
import TDispatchNoteEntry from "Transactions/TDispatchNote/TDispatchNoteEntry";
// Dispatch Note (Projects)
import FrmDispatchNoteProjectsListing from "Transactions/TDispatchNoteProjects/FrmDispatchNoteProjectsListing";

// Dispatch Challan (Trading)
import TDispatchChallanListing from "Transactions/TDispatchChallan/TDispatchChallanListing";
import TDispatchChallanEntry from "Transactions/TDispatchChallan/TDispatchChallanEntry";

// Dispatch Challan (Trading)
import TFabricDispatchChallanListing from "Transactions/TFabricDispatchChallan/TFabricDispatchChallanListing";
import TFabricDispatchChallanEntry from "Transactions/TFabricDispatchChallan/TFabricDispatchChallanEntry";

// Dispatch Challan (Projects)
import FrmDispatchChallanProjectsListing from "Transactions/TDispatchChallanProjects/FrmDispatchChallanProjectsListing";

// Dispatch Challan (Work Order)
import FrmDispatchChallanWorkOrderListing from "Transactions/TDispatchChallanWorkOrder/FrmDispatchChallanWorkOrderListing";

// Sales Invoice (Trading)
import FrmSalesInvoiceListing from "Transactions/TSalesInvoice/FrmSalesInvoiceListing";
import FrmSalesInvoiceEntry from "Transactions/TSalesInvoice/FrmSalesInvoiceEntry";
// Sales Invoice (Projects)
import FrmSalesInvoiceProjectsListing from "Transactions/TSalesInvoiceProjects/FrmSalesInvoiceProjectsListing";

// Sales Invoice (Job Work)
import FrmSalesInvoiceJobWorkListing from "Transactions/TSalesInvoiceJobWork/FrmSalesInvoiceJobWorkListing";

// Sales Invoice (Services)
import FrmSalesInvoiceServicesListing from "Transactions/TSalesInvoiceServices/FrmSalesInvoiceServicesListing";

// Customer Sales Receipt
import FrmCustomerSalesReceiptListing from "Transactions/TPurchaseOrder/TPurchasePaymentCustomer/FrmCustomerSalesReceiptListing";
import FrmCustomerSalesReceiptEntry from "Transactions/TPurchaseOrder/TPurchasePaymentCustomer/FrmCustomerSalesReceiptEntry";
import FrmIndentMaterialIssueListing from "Transactions/TIndentMaterialIssue/FrmIndentMaterialIssueListing";
import FrmIndentMaterialIssueEntry from "Transactions/TIndentMaterialIssue/FrmIndentMaterialIssueEntry";
import FrmProfessionalTaxationEntry from "Masters/MProfessionalTaxation/FrmProfessionalTaxationEntry";
import FrmProductionListing from "Transactions/TProduction/FrmProductionListing";
import FrmProductionEntry from "Transactions/TProduction/FrmProductionEntry";
import FrmMCodificationEntry from "Masters/MCodification/FrmMCodificationEntry";
import MCodificationListing from "Masters/MCodification/MCodificationList";
import FrmJobTypeListing from "Masters/MJobType/FrmJobTypeListing";
import FrmJobTypeEntry from "Masters/MJobType/FrmJobTypeEntry";
import FrmLeavesEntry from "Masters/MLeaves/FrmLeavesEntry";

import FrmLoomProduction from "Transactions/TLoomProductaion/FrmLoomProduction";


import TCustomerMaterialIssueListing from "Transactions/TCustomerMaterialIssue/TCustomerMaterialIssueListing";
import TCustomerMaterialIssueEntry from "Transactions/TCustomerMaterialIssue/TCustomerMaterialIssueEntry";

import ProductionRegister from "Masters/ProductionRegister";

import FrmWeavingProductionPlanEntry from "Masters/MWeavingProductionPlanMaster/FrmWeavingProductionPlanEntry";
import FrmWeavingProductionPlanListing from "Masters/MWeavingProductionPlanMaster/FrmWeavingProductionPlanListing";

// bank List form
import FrmBankListListing from "Masters/MBankList/FrmBankListListing";
import FrmBankListEntry from "Masters/MBankList/FrmBankListEntry";
// Leave Application
import FrmLeavesApplicationListing from "./Masters/MLeavesApplication/FrmLeavesApplicationListing";
import FrmLeavesApplicationEntry from "./Masters/MLeavesApplication/FrmLeavesApplicationEntry";
// Comp. Leave Request Form
import CompLeaveRequestForm from "./Transactions/THRPayroll/CompLeaveRequestForm";

// Attendance Device
import FrmAttendanceDeviceListing from "Masters/MAttendanceDevice/FrmAttendanceDeviceListing";
import FrmAttendanceDeviceEntry from "Masters/MAttendanceDevice/FrmAttendanceDeviceEntry";
// Leave Type 
import FrmLeaveTypeEntry from "Masters/MLeaveType/FrmLeaveTypeEntry";
import FrmLeaveTypeListing from "Masters/MLeaveType/FrmLeaveTypeListing";

//Warping Production Plan
import FrmWarpingProductionPlanListing from "Masters/MWarpingProductionPlan/FrmWarpingProductionPlanListing";
import FrmWarpingProdPlanEntry from "Masters/MWarpingProductionPlan/FrmWarpingProductionPlanEntry";
// Customer Material Return
import TCustomerMaterialReturnListing from "Transactions/TCustomerMaterialReturn/TCustomerMaterialReturnListing";
import TCustomerMaterialReturnEntry from "Transactions/TCustomerMaterialReturn/TCustomerMaterialReturnEntry";

import FrmWarpingProductionListing from "Transactions/TWarpingProduction/FrmWarpingProductionListing";
import FrmWarpingProductionEntry from "Transactions/TWarpingProduction/FrmWarpingProductionEntry";

// For Service Activity Master
import FrmMServiceActivityListing from "Masters/MServiceActivity/MServiceActivityListing";
import FrmMServiceActivityEntry from "Masters/MServiceActivity/MServiceActivityEntry";
import FrmProductTypeDynamicControlsListing from "Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsListing";
import FrmProductTypeDynamicControlsEntry from "Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsEntry";

// Sizing Production
import FrmTSizingProductionEntry from "Transactions/TSizingProduction/FrmTSizingProductionEntry";
import FrmTSizingProductionListing from "Transactions/TSizingProduction/FrmTSizingProductionListing";
import FrmRateEntry from "Masters/MRate/FrmRateEntry";
// Customer Stock
import MProductFgCustomerStock from "Masters/MStock/MProductFgCustomerStock";
import MProductFgStockCustomer from "Masters/MStock/MProductFgStockCustomer";

//Stock 
import FrmMSMVProductFgStocklisting from "Masters/MSMVProductFgStock/FrmMSMVProductFgStocklisting";
import FrmMSMVProductRmStockListing from "Masters/MSMVProductRmStock/FrmMSMVProductRmStockListing";
import MSmGeneralStoresAndSparesListing from "Masters/MSmGeneralStoresAndSparesReg/MSmGeneralStoresAndSparesListing";

// Product Material Type
import FrmMProductMaterialTypeEntry from "Masters/MProductMaterialType/FrmMProductMaterialTypeEntry";
import FrmMProductMaterialTypeListing from "Masters/MProductMaterialType/FrmMProductMaterialTypelList";
import FrmProductionReportEntry from "Transactions/TProductionReport/FrmProductionReportEntry";
import FrmWeeklyOffListing from "Masters/MWeeklyOff/FrmWeeklyOffListing";
import FrmLoomProductionListing from "Transactions/TLoomProductaion/FrmLoomProductionListing";
import FrmTWeavingInspectionListing from "Transactions/TWeavingInspection/FrmTWeavingInspectionListing";

import FrmStoppageEntry from "Transactions/TWeavingStoppageWastageProduction/FrmStoppageEntry";
import FrmStoppageProductionListing from "Transactions/TWeavingStoppageWastageProduction/FrmStoppageListing";
import FrmWastageEntry from "Transactions/TWeavingStoppageWastageProduction/FrmWastageEntry";
import FrmWastageListing from "Transactions/TWeavingStoppageWastageProduction/FrmWastageListing";

import FrmTWeavingInspectionEntry from "Transactions/TWeavingInspection/FrmTWeavingInspectionEntry";
import FrmCostCenterHeadsListing from "Masters/MCostCenterHeads/FrmCostCenterHeadsListing";
import FrmCostCenterHeadsEntry from "Masters/MCostCenterHeads/FrmCostCenterHeadsEntry";
import FrmYarnPackingMasterEntry from "Masters/MYarnPackingMaster/FrmYarnPackingMasterEntry";
import FrmYarnPackingListing from "Masters/MYarnPackingMaster/FrmYarnPackingListing";
import FrmPackingMaterialListing from "Masters/MPackingMaterial/FrmPackingMaterialListing";
import FrmPackingMaterialEntry from "Masters/MPackingMaterial/FrmPackingMaterialEntry";

// E-invoicing & E-Way billing 
import FrmEInvoicingAndEWayBillingEntry from "Transactions/TSalesInvoice/E-InvoicingAndE-WayBilling/FrmEInvoicingAndEWayBillingEntry";
import MFrmProductionLotEntry from "Masters/MProductionLot/MFrmProductionLotEntry";
import MFrmProductionLotListing from "Masters/MProductionLot/MFrmProductionLotListing";
import FrmEmployeeGradeListing from "Masters/MEmployeeGrade/FrmEmployeeGradeListing";
import FrmEmployeeGrade from "Masters/MEmployeeGrade/FrmEmployeeGrade";
import FrmJobAssignEntry from "Masters/MJobAssign/FrmJobAssignEntry";
import FrmJobAssignListing from "Masters/MJobAssign/FrmJobAssignListing";
import FrmStockAdjustmentlisting from "Masters/MStockAdjustment/MStockAdjustmentListing";
import FrmStockAdjustmentEntry from "Masters/MStockAdjustment/MStockAdjustmentEntry";
import FrmShiftRosterListing from "Masters/MShiftRoster/MShiftRosterListing";
import FrmShiftRosterEntry from "Masters/MShiftRoster/MShiftRosterEntry";
import TFabricDispatchNoteEntry from "Transactions/TFabricDispatchNote/TDispatchNoteEntry";
import TFabricDispatchNoteListing from "Transactions/TFabricDispatchNote/TDispatchNoteListing";
import TSizedYarnDispatchNoteEntry from "Transactions/TSizedYarnDispatchNote/TDispatchNoteEntry";
import TSizedYarnDispatchNoteListing from "Transactions/TSizedYarnDispatchNote/TDispatchNoteListing";
import FrmGatePassListing from "Masters/MGatePass/MGatePassListing";
import FrmGatePassEntry from "Masters/MGatePass/MGatePassEntry";
import TYarnDispatchChallanEntry from "Transactions/TYarnDispatchChallan/TYarnDispatchChallanEntry";
import TYarnDispatchChallanListing from "Transactions/TYarnDispatchChallan/TYarnDispatchChallanListing";
import FrmMaterialStockReportListing from "Masters/MMaterialStockReport/FrmMaterialStockReportListing";
import FrmMaterialRequisitionLIsting from "Transactions/TMaterialRequisition/FrmMaterialRequisitionLIsting";
import FrmMaterialRequisitionEntry from "Transactions/TMaterialRequisition/FrmMaterialRequisitionEntry";
import MUserPasswordChange from "Masters/MUserPasswordChange";
import FrmDailyAttendanceEntry from "Masters/MDailyAttendanceProcess/FrmDailyAttendanceEntry";
import FrmMaterialLoanRequisitionEntry from "Transactions/TPurchaseOrder/MaterialLoanRequisition/FrmMaterialLoanRequisitionEntry";
import FrmMaterialLoanRequisitionList from "Transactions/TPurchaseOrder/MaterialLoanRequisition/FrmMaterialLoanRequisitionList";
import FrmMaterialTransferRequisitionEntry from "Transactions/TPurchaseOrder/MaterialTransferRequisition/FrmMaterialTransferRequisitionEntry";
import FrmMaterialTransferRequisitionListing from "Transactions/TPurchaseOrder/MaterialTransferRequisition/FrmMaterialTransferRequisitionListing";
import FrmMaterialInternalTransferEntry from "Transactions/TPurchaseOrder/MaterialInternalTransfer/FrmMaterialInternalTransferEntry";
import FrmMaterialInternalTransferListing from "Transactions/TPurchaseOrder/MaterialInternalTransfer/FrmMaterialInternalTransferListing"
// Employee Monthly Deduction Uploads
import FrmMonthlyDeductionEntry from "Transactions/THRPayroll/TMonthlyDeductionUpload/FrmMonthlyDeductionEntry";
import FrmMonthlyDeductionListing from "Transactions/THRPayroll/TMonthlyDeductionUpload/FrmMonthlyDeductionListing";

// Salary Processing Frms for HR/Payroll
import TFrmSalaryProcessing from "Transactions/THRPayroll/TSalaryProcessing";
import TFrmSalaryProcessingNew from "Transactions/THRPayroll/TSalaryProcessingNew";

// Monthly Salary Corrections
import FrmSalaryCorrectionsEntry from "Transactions/THRPayroll/TSalaryCorrections/FrmSalaryCorrectionsEntry";
import FrmSalaryCorrectionsListing from "Transactions/THRPayroll/TSalaryCorrections/FrmSalaryCorrectionsListing";

// Salary Reports Registers.
import MonthlySalaryRegister from "Transactions/THRPayroll/SalaryRegisters/MonthlySalaryRegister"
import DepartmentWiseSalaryRegister from "Transactions/THRPayroll/SalaryRegisters/DepartmentWiseSalaryRegister"
import WorkerMonthlySalaryRegister from "Transactions/THRPayroll/SalaryRegisters/WorkerMonthlySalaryRegister"
import WorkerMonthlySalaryRegisterNew from "Transactions/THRPayroll/SalaryRegisters/WorkerMonthlySalaryRegisterNew"
import WorkerDepartmentWiseSalaryRegister from "Transactions/THRPayroll/SalaryRegisters/WorkerDepartmentWiseSalaryRegister"
import SalaryBankListRegister from 'Transactions/THRPayroll/SalaryRegisters/SalaryBankListRegister'
import PFChallanRegister from 'Transactions/THRPayroll/SalaryRegisters/PFChallanRegister'
import SalaryEarningRegister from 'Transactions/THRPayroll/SalaryRegisters/SalaryEarningRegister'
import SalaryDeductionRegister from 'Transactions/THRPayroll/SalaryRegisters/SalaryDeductionRegister'
import SalarySlipsRegister from 'Transactions/THRPayroll/SalaryRegisters/SalarySlipsRegister'
import ProfessionalTaxChallanRegister from 'Transactions/THRPayroll/SalaryRegisters/ProfessionalTaxChallanRegister'


// Shift management
import TFrmDaillyShiftManagement from "Transactions/THRPayroll/TDaillyShiftManagement"
import TFrmDaillyShiftManagementNew from "Transactions/THRPayroll/TDailyShiftManagementNew"

// Dummy Shift Roaster.
import TShiftRosterNew from 'Transactions/THRPayroll/TShiftRosterNew';

// Attendance Reports Registers.
import MonthlyAttendance from "Transactions/THRPayroll/AttendanceRegisters/MonthlyAttendance"
import DateWiseAttendance from "Transactions/THRPayroll/AttendanceRegisters/DateWiseAttendance";
import DateWiseAttendanceNew from "Transactions/THRPayroll/AttendanceRegisters/DateWiseAttendanceNew";
import ImmediatePunchReport from "./Transactions/THRPayroll/AttendanceRegisters/ImmediatePunchReport";
import AttendanceDevicePunch from "./Transactions/THRPayroll/AttendanceRegisters/AttendanceDevicePunch";
import ManualAttendanceRegister from "./Transactions/THRPayroll/AttendanceRegisters/ManualAttendanceRegister";

import MonthlyAttendanceSummary from "./Transactions/THRPayroll/AttendanceRegisters/MonthlyAttendanceSummary";
import MissedPunchReport from "./Transactions/THRPayroll/AttendanceRegisters/MissedPunchReport";
import DepartmentWiseMaterialUtilization from "./Transactions/TPurchaseOrder/DepartmentWiseMaterialUtilization";
import LeaveRegister from "./Transactions/THRPayroll/AttendanceRegisters/LeaveRegister";

import FrmTMaterialBeanCard from "./Transactions/TMaterialBeanCard";
import ShortLeaveRegister from "./Transactions/THRPayroll/AttendanceRegisters/ShortLeaveRegister";
import LateMarkAndEarlyGoRegister from "./Transactions/THRPayroll/AttendanceRegisters/LateMarkAndEarlyGoRegister";
import ShiftScheduleRegister from "./Transactions/THRPayroll/AttendanceRegisters/ShiftScheduleRegister";
import DateWiseAttendanceWorkers from "./Transactions/THRPayroll/AttendanceRegisters/DateWiseAttendanceWorkers";
import MonthlyAttendanceWorkerReg from "./Transactions/THRPayroll/AttendanceRegisters/MonthlyAttendanceWorkerReg";
// import DepartmentWiseAttendanceReg from "./Transactions/THRPayroll/AttendanceRegisters/DepartmentWiseAttendanceReg";
// Sales Order Stock Transfer
import FrmSalesOrderStockTransferEntry from "./Transactions/TSalesOrderStockTransfer/FrmSalesOrderStockTransferEntry";
import FrmSalesOrderStockTransferListing from "./Transactions/TSalesOrderStockTransfer/FrmSalesOrderStockTransferListing";
import MonthlyAttendanceSummaryWorkers from "./Transactions/THRPayroll/AttendanceRegisters/MonthlyAttendanceSummaryWorkers";
//Salary Rules 
import FrmSalaryRulesEntry from "Masters/MSalaryRules/FrmSalaryRulesEntry";
import FrmSalaryRulesListing from "Masters/MSalaryRules/FrmSalaryRulesListing";

import FrmMRawMaterialProducts from "./Masters/MRawMaterialProducts/FrmMRawMaterialProducts";
import FrmMRawMaterialProductsListing from "./Masters/MRawMaterialProducts/FrmMRawMaterialProductsListing";

//Daily Production Report
import FrmWeavingDailyProductionReportListing from "./Transactions/TWeavingDailyProductionReport/TWeavingDailyProductionReportListing";
import FrmWeavingDailyProductionReportEntry from "./Transactions/TWeavingDailyProductionReport/TWeavingDailyProductionReportEntry";

//Daily Dispatch Challan Report
import FrmDailyDispatchChallanReportListing from "./Transactions/TDailyDispatchChallanReport/TDailyDispatchChallanReportListing";
import FrmDailyDispatchChallanReportEntry from "./Transactions/TDailyDispatchChallanReport/TDailyDipatchChallanReportEntry";

//Purchase Reports
import FrmIssueReportEntry from "./Transactions/TPurchaseReports/IssueReport";
import FrmStockAgingReport from "./Transactions/TPurchaseReports/StockAgingReport";
import StorePurchaseStockReport from "./Transactions/TPurchaseReports/StorePurchaseStockReport";
import RawMaterialStockReport from "./Transactions/TPurchaseReports/RawMaterialStockReport"
import YarnClosingBalanceReport from "./Transactions/TPurchaseReports/YarnClosingBalanceReport"
import OutstandingMaterialsForPOReport from "./Transactions/TPurchaseReports/OutstandingMaterialsForPOReport";
import FrmFabricStockReport from "./Transactions/TPurchaseReports/FabricStockReport";
import FrmCottonBalesStockReport from "./Transactions/TPurchaseReports/CottonBalesStockReport";

import ContractorCommissionReg from "./Transactions/THRPayroll/SalaryRegisters/ContractorCommissionReg";
import FrmContractorCommissionEntry from "./Masters/MContractorCommission/FrmContractorCommissionEntry";
import FrmContractorCommissionList from "./Masters/MContractorCommission/FrmContractorCommissionList";
import FrmCompOffLeaveList from "./Masters/MCompOffLeaveRequest/FrmCompOffLeaveList";
import FrmCompOffLeaveEntry from "./Masters/MCompOffLeaveRequest/FrmCompOffLeaveEntry";
import DptWiseManPowerReg from "./Transactions/THRPayroll/AttendanceRegisters/DptWiseManPowerReg";
import Reset from "Authentication/Reset"
import DailyManPowerRegister from "./Transactions/THRPayroll/AttendanceRegisters/DailyManPowerRegister";
import FrmRawMaterialRequisitionEntry from "./Transactions/TRawMaterialRequisition/FrmRawMaterialRequisitionEntry";
import FrmRawMaterialRequisitionListing from "./Transactions/TRawMaterialRequisition/FrmRawMaterialRequisitionListing";

// Return forms
import FrmRawMaterialReturnEntry from "./Transactions/TRawMaterialReturn/FrmRawMaterialReturnEntry";
import FrmRawMaterialReturnListing from "./Transactions/TRawMaterialReturn/FrmRawMaterialReturnListing";
// warping bottom return
import FrmWarpingBottomReturnEntry from "./Transactions/TWarpingBottomReturn/FrmWarpingBottomReturnEntry";
import FrmWarpingBottomReturnListing from "./Transactions/TWarpingBottomReturn/FrmWarpingBottomReturnListing";
//Beam Inwards
import FrmBeamInwardsEntry from "./Masters/MBeamInwards/MBeamInwardsEntry";
import FrmBeamInwardsListing from "./Masters/MBeamInwards/MBeamInwardsListing";
// Beam issue slip
import FrmTBeamIssueSlipEntry from "./Transactions/TBeamIssueSlip/TBeamIssueSlipEntry";
import FrmTBeamIssueSlipListing from "./Transactions/TBeamIssueSlip/TBeamIssueSlipListing";


//Sized Beam Stock Report
import FrmSizedBeamsReport from "./Transactions/TPurchaseReports/SizedBeamStockReport";


//Sizing Production Report
import FrmSizingProductionReport from "./Transactions/TSizingProduction/FrmTSizingProductionReport";

//official travel form
import FrmBusiessTravelListing from "./Masters/MBusinessTravelApplication/FrmBusiessTravelListing";
import FrmBusinessTravelEntry from "./Masters/MBusinessTravelApplication/FrmBusinessTravelEntry";

// City
import FrmDistrict from "Masters/MDistrict/FrmDistrict";
import FrmDistrictList from "Masters/MDistrict/FrmDistrictList";

//standard Loss Master
import FrmStandardLossListing from "Masters/MStandardLoss/StandardLossListing";
import FrmStandardLossEntry from "Masters/MStandardLoss/StandardLossEntry";


import FrmMaterialGatePassEntry from "Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialGatePassEntry";
import FrmMaterialGatePassListing from "Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialGatePassListing";

import FrmMaterialReturnGatePassEntry from "Transactions/TPurchaseOrder/MaterialReturnGatePass/FrmMaterialReturnGatePassEntry";
import FrmMaterialReturnGatePassListing from "Transactions/TPurchaseOrder/MaterialReturnGatePass/FrmMaterialReturnGatePassListing";

//fabric Strock Register Report
// import FrmFabricStockReport from "./Transactions/TPurchaseReports/FabricStockReport";

//Fabric Production Report
import FrmFabricProductionReport from "./Transactions/TPurchaseReports/FabricProductionReport";


//Spinning

import FrmMRawCottonBaleEntry from "./Masters/MRawMaterialProducts/MRawCottonBale/FrmMRawCottonBaleEntry";
import FrmMRawCottonBaleListing from "./Masters/MRawMaterialProducts/MRawCottonBale/FrmMRawCottonBaleListing";

import FrmGoodsReceiptNoteCottonBalesEntry from "Transactions/TPurchaseOrder/GoodsReceiptNote/FrmGoodsReceiptNoteCottonBalesEntry";
import FrmGoodsReceiptNoteCottonBalesListing from "Transactions/TPurchaseOrder/GoodsReceiptNote/FrmGoodsReceiptNoteCottonBalesListing";


//Cotton Bales Purchase Order
import FrmCottonBalesPOEntry from "Transactions/TPurchaseOrder/ManualPO/CottonBalesPO/FrmCottonBalesEntry";
import FrmCottonBalesPOListing from "Transactions/TPurchaseOrder/ManualPO/CottonBalesPO/FrmCottonBalesListing"

// Coton Bales Quality Testing
import FrmQualityTestingEntry from "Transactions/TCottonBalesManagement/TQualityTesting/FrmQualityTestingEntry";
import FrmQualityTestingListing from "Transactions/TCottonBalesManagement/TQualityTesting/FrmQualityTestingListing";

// Cotton Bales Mixing Chart
import FrmBalesMixingChartEntry from "Transactions/TCottonBalesManagement/TBalesMixingChart/FrmBalesMixingChartEntry";
import FrmBalesMixingChartListing from "Transactions/TCottonBalesManagement/TBalesMixingChart/FrmBalesMixingChartListing";

import FrmMaterialReturnToStoresEntry from "Transactions/TPurchaseOrder/MaterialReturnToStore/FrmMaterialReturnToStoresEntry";
import MaterialReturnToStoresListing from "Transactions/TPurchaseOrder/MaterialReturnToStore/MaterialReturnToStoresListing";

//Cotton Bales Weightage Report
import FrmCottonBalesWeightageReport from "Transactions/TPurchaseReports/CottonBalesWeightageReport";


//Cotton Bales Daily Issue Report
import FrmCottonBalesDayWiseStockIssueReport from "Transactions/TPurchaseReports/CottonBalesDayWiseStockIssueReport";

import FrmBottomStockRegister from "Transactions/TPurchaseReports/BottomStockReport";

import CottonBalesIndentListing from "Transactions/TPurchaseOrder/Indent/CottonBalesIndentListing";

const routes = [
    {
        route: "/login",
        component: <Login />,
        header: false,
        footer: true,
        protected: true
    }, {
        route: "/Authentication/Reset",
        component: <Reset />,
        header: false,
        footer: true,
        protected: true
    },

    {
        route: "/Invoice",
        component: <InvoiceTemplate />,
        header: false,
        footer: false,
        protected: true
    },
    {
        route: "/Error",
        component: <Error_500 />,
        header: false,
        footer: false,
        protected: true
    },
    {
        route: "/DashBoard",
        component: <DashBoard />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Company",
        route: "/Masters/CompanyListing",
        component: <FrmMCompanyList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Company",
        component: <FrmMComapny />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Financial Year",
        route: "/Masters/MFinancialYear/FrmFinancialYearListing",
        component: <FrmFinancialYearListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MFinancialYear/FrmFinancialYearEntry",
        component: <FrmFinancialYearEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Banks",
        route: "/Masters/BankListing",
        component: <FrmMBankList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MBanks/FrmBank/FrmMBankEntry",
        component: <FrmMBankEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Departments",
        route: "/Masters/DepartmentListing",
        component: <FrmMDepartmentList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Department",
        component: <FrmDepartmentEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // {
    //     type: "Masters",
    //     name: "Designations",
    //     route: "/Masters/MDesignation/FrmMDesingnationList",
    //     component: <FrmMDesignationList />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    // {
    //     route: "/Masters/Designation",
    //     component: <FrmDesignation />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    //employee grade
    {
        type: "Masters",
        name: "Employee Grade",
        route: "/Masters/MEmployeeGrade/FrmEmployeeGradeListing",
        component: <FrmEmployeeGradeListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MEmployeeGrade/FrmEmployeeGrade",
        component: <FrmEmployeeGrade />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Employees",
        route: "/Masters/EmployeesListing",
        component: <FrmMEmployeeList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Employees",
        component: <MEmployeeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MEmployee/MEmployeePrint",
        component: <MEmployeePrint />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MEmployee/MEmployeeWorkerPrint",
        component: <MEmployeeWorkerPrint />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Payroll Settings",
        route: "/Masters/MPayrollSetting/FrmPayrollSettingEntry",
        component: <FrmPayrollSettingEntry />,
        header: true,
        footer: true,
        protected: false,
    },
    {
        type: "Masters",
        name: "Payroll Settings",
        route: "/Masters/MPayrollSetting/FrmPayrollSettingListing",
        component: <FrmPayrollSettingListing />,
        header: true,
        footer: true,
        protected: false,
    },

    {
        type: "Masters",
        name: "Customers",
        route: "/Masters/CustomerListing",
        component: <FrmMCustomerList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Customer",
        component: <FrmCustomerEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Suppliers",
        route: "/Masters/SupplierListing",
        component: <FrmSpplierList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmSupplier",
        component: <FrmSupplier />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Agents",
        route: "/Masters/AgentListing",
        component: <FrmAgentList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Agent",
        component: <FrmMAgent />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Contractors",
        route: "/Masters/ContractorListing",
        component: <FrmMContractorList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Contractor",
        component: <FrmContractorEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Transporter",
        route: "/Masters/TransporterListing",
        component: <FrmMTransporterList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Transporter",
        component: <FrmTransporter />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "City",
        route: "/Masters/CityListing",
        component: <FrmCityList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/City",
        component: <FrmCity />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Destinations",
        route: "/Masters/DestinationListing",
        component: <FrmMDestinationList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Destination",
        component: <FrmDestinationEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Godown",
        route: "/Masters/GodownListing",
        component: <FrmGodownList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Godown",
        component: <MGodownEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "HSN-SAC Codes",
        route: "/Masters/HSNSACListing",
        component: <FrmMHSNSACList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/HSNSAC",
        component: <FrmHSNSAC />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Payment Schedules",
        route: "/Masters/FrmPaymentScheduleListing",
        component: <FrmPaymentScheduleListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmPaymentScheduleEntry",
        component: <FrmPaymentScheduleEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Payments Terms",
        route: "FrmPaymentTemrsListing",
        component: <FrmPaymentTemrsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmPaymentTermsEntry",
        component: <FrmPaymentTermsEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Tax Types",
        route: "/Masters/TaxtypeListing",
        component: <FrmMTaxtypeList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Taxtype",
        component: <FrmMTaxtypeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Taxations",
        route: "/Masters/TaxationListing",
        component: <FrmMTaxationList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Taxation",
        component: <FrmTaxationEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Shifts",
        route: "Masters/MShift/FrmShiftListing",
        component: <FrmShiftListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmShiftEntry",
        component: <FrmShiftEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Holidays",
        route: "/Masters/MHoliday/FrmHolidayListing",
        component: <FrmHolidayListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MHoliday/FrmHolidayEntry",
        component: <FrmHolidayEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // {
    //     type: "Masters",
    //     name: "Product-Raw Materials",
    //     route: "Masters/MProductRawMaterial/FrmMProductRawMaterialList",
    //     component: <FrmMProductRawMaterialList />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    // {
    //     route: "/Masters/RawMaterial",
    //     component: <FrmMProductRawMaterial />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    {
        type: "Masters",
        // name: "Materials",
        name: "General Stores & Spares",
        route: "/Masters/Material/FrmMaterialList/:requestfor",
        component: <FrmMProductRawMaterialList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Material",
        component: <FrmMProductRawMaterial />,
        header: true,
        footer: true,
        protected: false
    },
    //product Raw material new
    {
        type: "Masters",
        name: "Raw Materials",
        route: "/Masters/MRawMaterialProducts/FrmMRawMaterialProductsListing/:requestfor",
        component: <FrmMRawMaterialProductsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MRawMaterialProducts/FrmMRawMaterialProducts",
        component: <FrmMRawMaterialProducts />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "Masters",
        name: "Product Unit Conversions",
        route: "Masters/MProductUnitConversion/FrmproductUnitConversionListing",
        component: <FrmproductUnitConversionListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmproductUnitConversionEntry",
        component: <FrmproductUnitConversionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product- Services",
        route: "Masters/MService/FrmMServiceListing",
        component: <FrmMServiceListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MService/FrmMServiceEntry",
        component: <FrmMServiceEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Category 1",
        route: "Masters/MProductCategory1/FrmProductCategory1Listing",
        component: <FrmMProductCategory1Listing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductCategory1Master",
        component: <FrmMProductCategory1Entry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Category 2",
        route: "Masters/MProductCategory2/FrmMProductCategory2Listing",
        component: <FrmMProductCategory2Listing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductCategory2Master",
        component: <FrmMProductCategory2Entry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Unit",
        route: "Masters/MProductUnit/FrmProductUnitListing",
        component: <FrmProductUnitListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmProductUnitEntry",
        component: <FrmProductUnitEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Unit Conversions",
        route: "Masters/MProductUnitConversion/FrmproductUnitConversionListing",
        component: <FrmproductUnitConversionListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmproductUnitConversionEntry",
        component: <FrmproductUnitConversionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Packing",
        route: "Masters/MProductPacking/FrmMProductPackingListing",
        component: <FrmMProductPackingListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductPacking",
        component: <FrmMProductPackingEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Makes",
        route: "Masters/MProductMake/FrmMProductMakeListing",
        component: <FrmMProductMakeListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductMake",
        component: <FrmMProductMakeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Grades",
        route: "Masters/MProductMaterialGrade/FrmMProductMaterialGradeListing",
        component: <FrmMProductMaterialGradeListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductMaterialGrade",
        component: <FrmMProductMaterialGradeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Company Settings",
        route: "/Masters/CompanyListing",
        component: <FrmMCompanyList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Company",
        component: <FrmMComapny />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Properties",
        route: "/Masters/FrmPropertyListing",
        component: <FrmPropertyListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmPropertyEntry",
        component: <FrmPropertyEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Communication Templates",
        route: "/Masters/MCommunicationsMaster/FrmCommunicationsMasterList",
        component: <FrmCommunicationsMasterList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MCommunicationsMaster/FrmCommunicationsMasterEntry",
        component: <FrmCommunicationsMasterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Common Parameters",
        route: "/Masters/MCommonParameter/CommonParamterListing",
        component: <CommonParamterListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MCommonParameter/CommonParamterEntry",
        component: <CommonParamterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Modules",
        route: "/Masters/ModuleFormListing",
        component: <ModuleFormListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ModuleFormEntry",
        component: <ModuleFormEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Financial Year",
        route: "/Masters/MFinancialYear/FrmFinancialYearListing",
        component: <FrmFinancialYearListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MFinancialYear/FrmFinancialYearEntry",
        component: <FrmFinancialYearEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Cost Centers",
        route: "/Masters/MCostCenterListing",
        component: <MCostCenterListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MCostCenterEntry",
        component: <MCostCenterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Profit Centers",
        route: "/Masters/ProfitCenterListing",
        component: <ProfitCenterListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProfitCenterEntry",
        component: <ProfitCenterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "HSN-SAC Codes",
        route: "/Masters/HSNSACListing",
        component: <FrmMHSNSACList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/HSNSAC",
        component: <FrmHSNSAC />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Payment Schedules",
        route: "/Masters/FrmPaymentScheduleListing",
        component: <FrmPaymentScheduleListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmPaymentScheduleEntry",
        component: <FrmPaymentScheduleEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Payments Terms",
        route: "/Masters/FrmPaymentTermsListing",
        component: <FrmPaymentTemrsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmPaymentTermsEntry",
        component: <FrmPaymentTermsEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Tax Types",
        route: "/Masters/TaxtypeListing",
        component: <FrmMTaxtypeList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Taxtype",
        component: <FrmMTaxtypeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Taxations",
        route: "/Masters/TaxationListing",
        component: <FrmMTaxationList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Taxation",
        component: <FrmTaxationEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Schedules",
        route: "/Masters/MFMScheduleLedgerListing",
        component: <MFMScheduleLedgerListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MFMScheduleLedgerEntry",
        component: <MFMScheduleLedgerEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Generals",
        route: "/Masters/MFMGeneralLedgerListing",
        component: <MFMGeneralLedgerListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MFMGeneralLedgerEntry",
        component: <MFMGeneralLedgerEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Plants",
        route: "/Masters/FrmPlantlist",
        component: <FrmPlantlist />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmPlantEntry",
        component: <FrmPlantEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Machines",
        route: "/Masters/FrmMachineListing",
        component: <FrmMachineListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmMachineEntry",
        component: <FrmMachineEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Shifts",
        route: "/Masters/FrmShiftListing",
        component: <FrmShiftListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmShiftEntry",
        component: <FrmShiftEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Production Holidays",
        route: "/Masters/MHoliday/FrmHolidayListing",
        component: <FrmHolidayListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MHoliday/FrmHolidayEntry",
        component: <FrmHolidayEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Production Process",
        route: "/Masters/MProductionProcess/MFrmProductionProcessListing",
        component: <MFrmProductionProcessListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MProductionProcess/MFrmProductionProcessEntry",
        component: <MFrmProductionProcessEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Spinning Plan",
        route: "/Masters/MXMProductionSpinningPlanParameter/FrmMProductionSpinningPlanParameterListing",
        component: <FrmMProductionSpinningPlanParameterListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MXMProductionSpinningPlanParameter/FrmMProductionSpinningPlanParameterEntry",
        component: <FrmMProductionSpinningPlanParameterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Production Stopage Reasons",
        route: "/Masters/MProductionStoppageReasons/MFrmProductionStoppageReasonsListing",
        component: <MFrmProductionStoppageReasonsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MProductionStoppageReasons/MFrmProductionStoppageReasonsEntry",
        component: <MFrmProductionStoppageReasonsEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Production Wastage Types",
        route: "/Masters/MProductionWastageType/MFrmProductionWastageTypesListing",
        component: <MFrmProductionWastageTypesListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MProductionWastageType/MFrmProductionWastageTypesEntry",
        component: <MFrmProductionWastageTypesEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Spinning Counts",
        route: "/Masters/MCount/FrmCountListing",
        component: <FrmCountListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MCount/FrmCountEntry",
        component: <FrmCountEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Maintainance Tasks Master",
        route: "/Masters/MmaintenanceTaskMaster/FrmMaintenanceTaskMasterListing",
        component: <FrmMaintenanceTaskMasterListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MmaintenanceTaskMaster/FrmMaintenanceTaskMasterEntry",
        component: <FrmMaintenanceTaskMasterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Maintainance Tasks Activity",
        route: "/Masters/MmaintenanceTaskActivity/MmaintenanceTaskActivityListing",
        component: <MmaintenanceTaskActivityListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MaintenanceTaskActivity/MmaintenanceTaskActivityEntry",
        component: <MmaintenanceTaskActivityEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Electrical Meters",
        route: "/Masters/MProductionElectricalMeter/MFrmProductionElectricalMeterListing",
        component: <MFrmProductionElectricalMeterListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MProductionElectricalMeter/MFrmProductionElectricalMeterEntry",
        component: <MFrmProductionElectricalMeterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Departments",
        route: "/Masters/DepartmentListing",
        component: <FrmMDepartmentList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Department",
        component: <FrmDepartmentEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Designations",
        route: "/Masters/DesignationListing",
        component: <FrmMDesignationList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Designation",
        component: <FrmDesignation />,
        header: true,
        footer: true,
        protected: false
    },
    // {
    //     route: "/Masters/Destination",
    //     component: <FrmDestinationEntry />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    {
        type: "Masters",
        name: "Holidays",
        route: "/Masters/MHoliday/FrmHolidayListing",
        component: <FrmHolidayListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MHoliday/FrmHolidayEntry",
        component: <FrmHolidayEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Employees",
        route: "/Masters/EmployeesListing",
        component: <FrmMEmployeeList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/Employees",
        component: <MEmployeeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Shifts",
        route: "/Masters/FrmShiftListing",
        component: <FrmShiftListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmShiftEntry",
        component: <FrmShiftEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Earning Heads",
        route: "/Masters/MHMEarningHeads/MHMEarningHeadsListing",
        component: <FrmMHMEarningHeadsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MHMEarningHeads/FrmMHMEarningHeadsEntry",
        component: <FrmMHMEarningHeadsEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Deductions Heads",
        route: "/Masters/MHMDeductionHeads/FrmMHMDeductionHeadsListing",
        component: <FrmMHMDeductionHeadsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MHMDeductionHeads/FrmMHMDeductionHeadsEntry",
        component: <FrmMHMDeductionHeadsEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "City",
        route: "/Masters/CityListing",
        component: <FrmCityList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/City",
        component: <FrmCity />,
        header: true,
        footer: true,
        protected: false
    },
    // {
    //     type: "Masters",
    //     name: "Destinations",
    //     route: "/Masters/DesignationListing",
    //     component: <FrmMDesignationList />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    // {
    //     route: "/Masters/Destination",
    //     component: <FrmDestinationEntry />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    {
        type: "Masters",
        name: "Product Type",
        route: "/Masters/ProductTypeListing",
        component: <FrmMProductTypeListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductType",
        component: <FrmMProductTypeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // {
    //     type: "Masters",
    //     name: "Product-Raw Materials",
    //     route: "/Masters/RawMaterialListing",
    //     component: <FrmMProductRawMaterialList />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    // {
    //     route: "/Masters/RawMaterial",
    //     component: <FrmMProductRawMaterial />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    {
        type: "Masters",
        name: "Product- Finish Goods",
        route: "/Masters/FinishGoodsListing",
        component: <FrmMProductFGList />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "Masters",
        name: "Product Type Dynamic Controls",
        route: "/Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsListing",
        component: <FrmProductTypeDynamicControlsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsEntry",
        component: <FrmProductTypeDynamicControlsEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FinishGoods",
        component: <FrmMProductFG />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product- Services",
        route: "/Masters/FrmMServiceListing",
        component: <FrmMServiceListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MService/FrmMServiceEntry",
        component: <FrmMServiceEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Category 1",
        route: "/Masters/ProductCategory1Listing",
        component: <FrmMProductCategory1Listing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductCategory1Master",
        component: <FrmMProductCategory1Entry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Category 2",
        route: "/Masters/ProductCategory2Listing",
        component: <FrmMProductCategory2Listing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductCategory2Master",
        component: <FrmMProductCategory2Entry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Category 3",
        route: "/Masters/ProductCategory3Listing",
        component: <FrmMProductCategory3Listing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductCategory3Master",
        component: <FrmMProductCategory3Entry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Category 4",
        route: "/Masters/ProductCategory4Listing",
        component: <FrmMProductCategory4Listing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductCategory4Master",
        component: <FrmMProductCategory4Entry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Category 5",
        route: "/Masters/ProductCategory5Listing",
        component: <FrmMProductCategory5Listing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductCategory5Master",
        component: <FrmMProductCategory5Entry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Unit",
        route: "/Masters/FrmProductUnitListing",
        component: <FrmProductUnitListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmProductUnitEntry",
        component: <FrmProductUnitEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Unit Conversions",
        route: "/Masters/FrmproductUnitConversionListing",
        component: <FrmproductUnitConversionListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmproductUnitConversionEntry",
        component: <FrmproductUnitConversionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Packing",
        route: "/Masters/ProductPackingListing",
        component: <FrmMProductPackingListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductPacking",
        component: <FrmMProductPackingEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Makes",
        route: "/Masters/ProductMakeListing",
        component: <FrmMProductMakeListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductMake",
        component: <FrmMProductMakeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Grades",
        route: "/Masters/ProductMaterialGradeListing",
        component: <FrmMProductMaterialGradeListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductMaterialGrade",
        component: <FrmMProductMaterialGradeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Process",
        route: "/Masters/FrmMProductProcessListing",
        component: <FrmMProductProcessList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmProductProcessEntry",
        component: <FrmProductProcessEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product QA Parameters",
        route: "/Masters/ProductQaParametersListing",
        component: <FrmMProductQaParametersListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductQaParameters",
        component: <FrmMProductQaParametersEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Rejection Parameters",
        route: "/Masters/ProductRejectionParametersListing",
        component: <FrmMProductRejectionParametersListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductRejectionParameters",
        component: <FrmMProductRejectionParametersEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Measure Parameters",
        route: "/Masters/ProductMaterialMeasureParametersListing",
        component: <FrmMProductMaterialMeasureParametersListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductMaterialMeasureParameters",
        component: <FrmMProductMaterialMeasureParametersEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Product Shape",
        route: "/Masters/ProductMaterialMeasureShapeListing",
        component: <FrmMProductMaterialShapeListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/FrmMProductMaterialShapeEntry",
        component: <FrmMProductMaterialShapeEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Employee Type",
        route: "/Masters/MEmployeeType/MEmployeeTypeListing",
        component: <MEmployeeTypeListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Production Section",
        route: "/Masters/MProductionSection/FrmProductionSectionListing",
        component: <FrmProductionSectionListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MProductionSection/FrmProductionSectionEntry",
        component: <FrmProductionSectionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Production Sub Section",
        route: "/Masters/MProductionSubSection/FrmProductionSubSectionListing",
        component: <FrmProductionSubSectionListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MProductionSubSection/FrmProductionSubSectionEntry",
        component: <FrmProductionSubSectionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Spin Plan",
        route: "/Masters/MProductionSpinPlan/MProductionSpinPlanListing",
        component: <FrmSpinPlanListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MProductionSpinPlan/MProductionSpinPlanEntry",
        component: <FrmSpinPlanEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Manual Attendance",
        route: "/Masters/MManualAttendance/FrmManualAttendanceEntry",
        component: <FrmManualAttendanceEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // Job Type Master
    {
        type: "Masters",
        name: "Job Type",
        key: "Job Type",
        route: "/Masters/MJobType/FrmJobTypeListing",
        component: <FrmJobTypeListing />,
        noCollapse: true,
    },
    {
        name: "Job Type",
        route: "/Masters/MJobType/FrmJobTypeEntry",
        component: <FrmJobTypeEntry />,
    },

    //Leaves master
    {
        type: "Masters",
        name: "Leaves",
        key: "Leaves",
        route: "/Masters/MLeaves/FrmLeavesEntry",
        component: <FrmLeavesEntry />,
    },

    //DailyAttendance process master
    {
        type: "Masters",
        name: "DailyAttendance Process",
        key: "DailyAttendance Process",
        route: "/Masters/MDailyAttendanceProcess/FrmDailyAttendanceEntry",
        component: <FrmDailyAttendanceEntry />,
        noCollapse: true,
    },
    // Leves Application
    {
        type: "masters",
        name: "Leaves Application",
        key: "Leaves Application",
        route: "/Masters/MLeavesApplication/FrmLeavesApplicationListing",
        component: <FrmLeavesApplicationListing />,
        noCollapse: true,
    },
    {
        name: "Leaves Application",
        route: "/Masters/MLeavesApplication/FrmLeavesApplicationEntry",
        component: <FrmLeavesApplicationEntry />,
    },

    // Comp Leave Request Form
    {
        type: "masters",
        name: "Comp. Leave Request Form",
        key: "Comp. Leave Request Form",
        route: "/Transactions/THRPayroll/CompLeaveRequestForm",
        component: <CompLeaveRequestForm />,
        noCollapse: true,
    },

    // Attendance Device Master
    {
        type: "masters",
        name: "Attendance Device",
        key: "Attendance Device",
        route: "/Masters/MAttendanceDevice/FrmAttendanceDeviceListing",
        component: <FrmAttendanceDeviceListing />,
        noCollapse: true,
    },
    {
        name: "Attendance Device",
        route: "/Masters/MAttendanceDevice/FrmAttendanceDeviceEntry",
        component: <FrmAttendanceDeviceEntry />,
    },

    // Leave Type Master
    {
        type: "masters",
        name: "Leave Type",
        key: "Leave Type",
        route: "/Masters/MLeaveType/FrmLeaveTypeListing",
        component: <FrmLeaveTypeListing />,
        noCollapse: true,
    },
    {
        name: "Leave Type",
        route: "/Masters/MLeaveType/FrmLeaveTypeEntry",
        component: <FrmLeaveTypeEntry />,
    },

    // Bank List Master
    {
        type: "Masters",
        name: "Bank List",
        route: "/Masters/MBankList/FrmBankListListing",
        component: <FrmBankListListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        name: "Bank List",
        route: "/Masters/MBankList/FrmBankListEntry",
        component: <FrmBankListEntry />,
    },
    {
        type: "Purchase",
        name: "Indent",
        route: "/Transactions/TPurchaseOrder/Indent/IndentListing/:requestfor",
        component: <IndentListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/Indent/FrmIndentMasterEntry",
        component: <FrmIndentMasterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Purchase",
        name: "PurchaPurchase Requisition (BOM)",
        route: "/Transactions/TPurchaseOrder/PurchaseRequisition/FrmPurchaseRequisitionListing/:requestfor",
        component: <FrmPurchaseRequisitionListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/PurchaseRequisition/PurchaseEntry/",
        component: <FrmPurchaseRequisitionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Purchase",
        name: "Purchase Order (Trading)",
        route: "/Transactions/TPurchaseOrder/ManualPO/POListing/:requestfor",
        component: <POListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/ManualPO/FrmPurchaseOrderEntry",
        component: <FrmPurchaseOrderEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Purchase",
        name: "Goods Receipt Notes",
        route: "/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteListing/:requestfor",
        component: <FrmGoodsReceiptNoteListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteEntry",
        component: <FrmGoodsReceiptNoteEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Purchase",
        name: "Goods Return",
        route: "/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing",
        component: <FrmGoodsReturnListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Purchase",
        route: "/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnEntry",
        component: <FrmGoodsReturnEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Purchase",
        name: "Customer Material Reciept",
        route: "/Transactions/TCustomerReciept/FrmTCustomerRecieptListing",
        component: <FrmTCustomerRecieptListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TCustomerReciept/FrmTCustomerRecieptEntry",
        component: <FrmTCustomerRecieptEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Purchase",
        name: "Purchase Bill Book(Auto)",
        route: "/Transactions/TPurchaseOrder/TBillBook/BillBookListing/:requestfor",
        component: <BillBookListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/TBillBook/FrmBillBookEntry",
        component: <FrmBillBookEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Purchase",
        name: "Purchase Bill Book(Manual)",
        route: "/Transactions/TPurchaseOrder/TManualBillBook/FrmManualBillBookListing/:requestfor",
        component: <FrmManualBillBookListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/TManualBillBook/FrmManualBillBookEnrty",
        component: <FrmManualBillBookEnrty />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Purchase",
        name: "Purchase Payment Supplier",
        route: "/Transactions/TPurchaseOrder/TPurchasePaymentSupplier/FrmPurchasePaymentSupplierListing/:requestfor",
        component: <FrmPurchasePaymentSupplierListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/TPurchasePaymentSupplier/FrmPurchasePaymentSupplierEntry",
        component: <FrmPurchasePaymentSupplierEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Enquiry Management (Trading)",
        route: "/Transactions/TEnquiryManagement/FrmEnquiryListing",
        component: <FrmEnquiryListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TEnquiryManagement/FrmEnquiryTradingEntry",
        component: <FrmEnquiryTradingEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Enquiry Management (Service)",
        route: "/Transactions/TEnquiryServiceManagement/FrmEnquiryServiceListing",
        component: <FrmEnquiryServiceListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TEnquiryServiceManagement/FrmEnquiryServiceEntry",
        component: <FrmEnquiryServiceEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Enquiry Management (Project)",
        route: "/Transactions/TEnquiryProjectsManagement/FrmEnquiryProjectListing",
        component: <FrmEnquiryProjectListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TEnquiryProjectsManagement/FrmEnquiryProjectEntry",
        component: <FrmEnquiryProjectEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Quotation (Trading)",
        route: "/Transactions/TQuotation/FrmQuotationListing",
        component: <FrmQuotationListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TQuotation/FrmQuotationMasterEntry",
        component: <FrmQuotationMasterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Quotation (Service)",
        route: "/Transactions/TQuotationService/FrmQuotationServiceListing",
        component: <FrmQuotationServiceListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TQuotationService/FrmQuotationServiceEntry",
        component: <FrmQuotationServiceEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Quotation (Projects)",
        route: "/Transactions/TQuotationProjects/FrmQuotationProjectListing",
        component: <FrmQuotationProjectListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TQuotationProjects/FrmQuotationProjectsEntry",
        component: <FrmQuotationProjectsEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Sales Order (Trading)",
        route: "/Transactions/TSalesOrder/SalesOrderListing/:requestfor",
        component: <SalesOrderListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TSalesOrder/SalesOrderEntry",
        component: <SalesOrderEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Sales Order (Services)",
        route: "/Transactions/TSalesOrder/Services/SOServiceListing",
        component: <SOServiceListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TSalesOrder/Services/SOServiceEntry",
        component: <SOServiceEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Sales Order (Projects)",
        route: "/Transactions/TSalesOrder/Projects/SalesOrderProjectsListing",
        component: <SalesOrderProjectsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TSalesOrder/Projects/SalesOrderProjectEntry",
        component: <SalesOrderProjectEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Work Order (Trading)",
        route: "/Transactions/TWorkOrder/Trading/WorkOrderListing",
        component: <FrmWorkOrderListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Dispatch Advisory (Trading)",
        route: "/Transactions/TDispatchNote/TDispatchNoteListing/:requestfor",
        component: <TDispatchNoteListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TDispatchNote/TDispatchNoteEntry",
        component: <TDispatchNoteEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // Fabric Dispatch Note.
    {
        type: "Sales",
        name: "Dispatch Advisory (Fabric)",
        route: "/Transactions/TDispatchNote/TFabricDispatchNoteListing",
        component: <TFabricDispatchNoteListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TDispatchNote/TFabricDispatchNoteEntry",
        component: <TFabricDispatchNoteEntry />,
        header: true,
        footer: true,
        protected: false
    },
    //Sized Yarn Dispatch Note.
    {
        type: "Sales",
        name: "Dispatch Challan (Sized Yarn)",
        route: "/Transactions/TDispatchNote/TSizedYarnDispatchNoteListing",
        component: <TSizedYarnDispatchNoteListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TDispatchNote/TSizedYarnDispatchNoteEntry",
        component: <TSizedYarnDispatchNoteEntry />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "Sales",
        name: "Dispatch Note (Projects)",
        route: "/Transactions/TDispatchNoteProjects/FrmDispatchNoteProjectsListing",
        component: <FrmDispatchNoteProjectsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Dispatch Challan (Trading)",
        route: "/Transactions/TDispatchChallan/TDispatchChallanListing/:requestfor",
        component: <TDispatchChallanListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TDispatchChallan/TDispatchChallanEntry",
        component: <TDispatchChallanEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Dispatch Challan (Fabric)",
        route: "/Transactions/TFabricDispatchChallan/TFabricDispatchChallanListing",
        component: <TFabricDispatchChallanListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TFabricDispatchChallan/TFabricDispatchChallanEntry",
        component: <TFabricDispatchChallanEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Dispatch Challan (Projects)",
        route: "/Transactions/TDispatchChallan/FrmDispatchChallanProjectsListing",
        component: <FrmDispatchChallanProjectsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Dispatch Challan (Work Order)",
        route: "/Transactions/TDispatchChallan/TDispatchChallanWorkOrderListing",
        component: <FrmDispatchChallanWorkOrderListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Sales Invoice (Trading)",
        route: "/Transactions/SalesInvoiceListing",
        component: <FrmSalesInvoiceListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/InvoiceCreationEntry",
        component: <FrmSalesInvoiceEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Sales Invoice (Projects)",
        route: "/Transactions/SalesInvoiceProjectsListing",
        component: <FrmSalesInvoiceProjectsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Sales Invoice (Job Work)",
        route: "/Transactions/SalesInvoiceJobWorkListing",
        component: <FrmSalesInvoiceJobWorkListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Sales Invoice (Services)",
        route: "/Transactions/SalesInvoiceServicesListing",
        component: <FrmSalesInvoiceServicesListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Sales",
        name: "Customer Sales Receipt",
        route: "/Transactions/TPurchaseOrder/TPurchasePaymentCustomer/FrmCustomerSalesReceiptListing",
        component: <FrmCustomerSalesReceiptListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/TPurchasePaymentCustomer/FrmCustomerSalesReceiptEntry",
        component: <FrmCustomerSalesReceiptEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // {
    //     type: "purchase",
    //     name: "Indent Material Issue",
    //     route: "/Transactions/TIndentMaterialIssue/FrmIndentMaterialIssueListing",
    //     component: <FrmIndentMaterialIssueListing />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    // {
    //     route: "/Transactions/TIndentMaterialIssue/FrmIndentMaterialIssueEntry",
    //     component: <FrmIndentMaterialIssueEntry />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    {
        type: "Masters",
        name: "Professional Taxation",
        route: "/Masters/MProfessionalTaxation/FrmProfessionalTaxationEntry",
        component: <FrmProfessionalTaxationEntry />,
        header: true,
        footer: true,
        protected: false
    },

    //production transaction
    {
        type: "Production",
        name: "Production",
        key: "Production",
        route: "/Transactions/TProduction/FrmProductionListing",
        component: <FrmProductionListing />,
        noCollapse: true,
    },
    {
        name: "Production",
        route: "/Transactions/TProduction/FrmProductionEntry",
        component: <FrmProductionEntry />,
    },


    // Codification Master
    {
        type: "Masters",
        name: "Codification",
        route: "/Masters/MCodification/MCodificationList",
        component: <MCodificationListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        name: "Production",
        route: "/Masters/MCodification/FrmMCodificationEntry",
        component: <FrmMCodificationEntry />,
    },

    //Loom Production

    {
        type: "Production",
        name: "Loom Production",
        key: "Production",
        route: "/Transactions/TLoomProductaion/FrmLoomProductionListing",
        component: <FrmLoomProductionListing />,
        noCollapse: true,
    },
    {
        name: "Loom Production",
        route: "/Transactions/TLoomProductaion/FrmLoomProduction",
        component: <FrmLoomProduction />,

    },
    // M WearingProductionPlan master
    {

        route: "/Masters/MWeavingProductionPlanMaster/FrmWeavingProductionPlanEntry",
        component: <FrmWeavingProductionPlanEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {

        type: "Masters",
        name: "Production Sub Section",
        route: "/Masters/MWeavingProductionPlanMaster/FrmWeavingProductionPlanListing",
        component: <FrmWeavingProductionPlanListing />,
        header: true,
        footer: true,
        protected: false
    },
    // Customer Material Issue
    {
        type: "purchase",
        name: "Customer Material Issue",
        route: "/Transactions/TCustomerMaterialIssue/TCustomerMaterialIssueListing",
        component: <TCustomerMaterialIssueListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TCustomerMaterialIssue/TCustomerMaterialIssueEntry",
        component: <TCustomerMaterialIssueEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // Product Register 
    {
        type: "Masters",
        name: "Production Register",
        key: "Production Register",
        route: "Masters/ProductionRegister",
        component: <ProductionRegister />,
        header: true,
        footer: true,
        protected: false
    },

    // Master Warping Production plan
    {
        type: "Masters",
        name: "Warping Production Order(Plan)",
        route: "/Masters/MWarpingProductionPlan/FrmWarpingProductionPlanListing",
        component: <FrmWarpingProductionPlanListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MWarpingProductionPlan/FrmWarpingProductionPlanEntry",
        component: <FrmWarpingProdPlanEntry />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "Purchase",
        name: "Customer Material Return",
        route: "/Transactions/TCustomerMaterialReturn/TCustomerMaterialReturnListing",
        component: <TCustomerMaterialReturnListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TCustomerMaterialReturn/TCustomerMaterialReturnEntry",
        component: <TCustomerMaterialReturnEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // For Service Planning
    {
        type: "Sales",
        name: "Service Planning",
        route: "/Transactions/TServicePlanning/ServicePlanningListing",
        component: <TServicePlanningListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TServicePlanning/ServicePlanningEntry",
        component: <TServicePlanningEntry />,
        header: true,
        footer: true,
        protected: false
    },

    // For Service Activity Master
    {
        type: "Masters",
        name: "Service Activity",
        route: "/Masters/ServiceActivityListing",
        component: <FrmMServiceActivityListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ServiceActivity",
        component: <FrmMServiceActivityEntry />,
        header: true,
        footer: true,
        protected: false
    },
    //TWarpingProduction
    {
        type: "Production",
        name: "Warping Production",
        key: "Production",
        route: "/Transactions/TWarpingProduction/FrmWarpingProductionListing",
        component: <FrmWarpingProductionListing />,
        noCollapse: true,

    },
    {
        name: "Warping Production",
        route: "/Transactions/TWarpingProduction/FrmWarpingProductionEntry",
        component: <FrmWarpingProductionEntry />,

    },


    // Weaving Inspection
    {
        route: "/Transactions/TWeavingInspection/FrmTWeavingInspectionEntry",
        component: <FrmTWeavingInspectionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Production",
        name: "Weaving Inspection",
        route: "/Transactions/TWeavingInspection/FrmTWeavingInspectionListing",
        component: <FrmTWeavingInspectionListing />,
        header: true,
        footer: true,
        protected: false
    },
    // Weaving Stoppage Wastage Production
    {
        name: "Weaving Stoppage Entry",
        route: "/Transactions/TWeavingStoppageWastageProduction/FrmStoppageEntry",
        component: <FrmStoppageEntry />,
        header: true,
        footer: true,
        protected: false
    },

    {
        name: "Weaving Stoppage Listing",
        route: "/Transactions/TWeavingStoppageWastageProduction/FrmStoppageListing/:requestfor",
        component: <FrmStoppageProductionListing />,
        header: true,
        footer: true,
        protected: false
    },

    {
        name: "Weaving Wastage",
        route: "/Transactions/TWeavingStoppageWastageProduction/FrmWastageEntry",
        component: <FrmWastageEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        name: "Weaving Wastage",
        route: "/Transactions/TWeavingStoppageWastageProduction/FrmWastageListing/:requestfor",
        component: <FrmWastageListing />,
        header: true,
        footer: true,
        protected: false
    },


    //  Sizing Production
    {
        type: "Production",
        name: "Sizing Production",
        route: "/Transactions/TSizingProduction/FrmTSizingProductionListing",
        component: <FrmTSizingProductionListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MProductionLot/MFrmProductionLotEntry",
        component: <MFrmProductionLotEntry />,
        header: true,
        footer: true,
        protected: false
    },
    //  production lot
    {
        type: "Production",
        name: "Production Lot",
        route: "/Masters/MProductionLot/MFrmProductionLotListing",
        component: <MFrmProductionLotListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TSizingProduction/FrmTSizingProductionEntry",
        component: <FrmTSizingProductionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Rate",
        route: "/Masters/MRate/FrmRateEntry",
        component: <FrmRateEntry />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "Masters",
        name: "Product Material Type",
        route: "/Masters/ProductMaterialTypeListing",
        component: <FrmMProductMaterialTypeListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/ProductMaterialType",
        component: <FrmMProductMaterialTypeEntry />,
        header: true,
        footer: true,
        protected: false
    },

    // E-Invoicing And E-way Billing
    {
        name: "E-Invoicing And E-way Billing",
        route: '/Transactions/EInvoicingAndEWayBillingEntry',
        component: <FrmEInvoicingAndEWayBillingEntry />
    },

    {
        type: "Masters",
        name: "Dailly Shift Management",
        route: "/ShiftMangement",
        component: <TFrmDaillyShiftManagement />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "Masters",
        name: "Dailly Shift Management",
        route: "/ShiftMangementNew",
        component: <TFrmDaillyShiftManagementNew />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: 'Transactions',
        name: 'Shift Roaster New',
        route: '/TShiftRosterNew',
        component: <TShiftRosterNew />,
        header: true,
        footer: true,
        protected: false
    },


    // -----------------------------------------Registers-----------------------------------------------------------

    {
        type: "registers",
        name: "Companies Register",
        key: "Registers",

        route: "/Masters/CompanyListing/reg",
        component: <FrmMCompanyList compType='Register' />,
        noCollapse: true,
    },

    {
        type: "registers",
        name: "Customers Register",
        key: "Customers",

        route: "/Masters/CustomerListing/reg",
        component: <FrmMCustomerList compType='Register' />,
        noCollapse: true,
    },


    // bank 
    {
        type: "registers",
        name: "Bank Register",
        key: "Bank",
        route: "/Masters/BankListing/reg",
        component: <FrmMBankList compType='Register' />,
        noCollapse: true,
    },

    // Transporter
    {
        type: "registers",
        name: "Transporter Register",
        key: "Transporter",

        route: "/Masters/TransporterListing/reg",
        component: <FrmMTransporterList compType='Register' />,
        noCollapse: true,
    },
    //comman Paramitar Master
    {
        type: "registers",
        name: "Common Parameter Register",
        key: "CommonParameter",

        route: "/Masters/MCommonParameter/CommonParamterListing/reg",
        component: <CommonParamterListing compType='Register' />,
        noCollapse: true,
    },



    // Contractor
    {
        type: "registers",
        name: "Contractors Register",
        key: "Contractors",

        route: "/Masters/ContractorListing/reg",
        component: <FrmMContractorList compType='Register' />,
        noCollapse: true,
    },

    //department
    {
        type: "registers",
        name: "Department Register",
        key: "Department",

        route: "/Masters/DepartmentListing/reg",
        component: <FrmMDepartmentList compType='Register' />,
        noCollapse: true,
    },
    // Designation 
    {
        type: "registers",
        name: "Designation Register",
        key: "Designation",

        route: "/Masters/DesignationListing/reg",
        component: <FrmMDesignationList compType='Register' />,
        noCollapse: true,
    },

    // City 
    {
        type: "registers",
        name: "City Register",
        key: "City",

        route: "/Masters/CityListing/reg",
        component: <FrmCityList compType='Register' />,
        noCollapse: true,
    },

    // Supplier Masters
    {
        type: "registers",
        name: "Supplier Register",
        key: "Supplier",

        route: "/Masters/SupplierListing/reg",
        component: <FrmSpplierList compType='Register' />,
        noCollapse: true,
    },

    // Destination
    {
        type: "registers",
        name: "Destination Register",
        key: "Destination",

        route: "/Masters/DestinationListing/reg",
        component: <FrmMDestinationList compType='Register' />,
        noCollapse: true,
    },

    // Product Raw Material
    // {
    //     type: "registers",
    //     name: "RawMaterial Register",
    //     key: "RawMaterial",

    //     route: "/Masters/RawMaterialListing/reg",
    //     component: <FrmMProductRawMaterialList compType='Register' />,
    //     noCollapse: true,
    // },
    {
        type: "registers",
        name: "Material Register",
        key: "Materials",
        route: "/Masters/MaterialListing/reg",
        component: <FrmMProductRawMaterialList compType='Register' />,
        noCollapse: true,
    },
    // Agent 

    {
        type: "registers",
        name: "Agent Register",
        key: "Agent",

        route: "/Masters/AgentListing/reg",
        component: <FrmAgentList compType='Register' />,
        noCollapse: true,
    },

    // ProductFGMaster
    {
        type: "registers",
        name: "Finish Goods Register",
        key: "ProductFG",

        route: "/Masters/FinishGoodsListing/reg",
        component: <FrmMProductFGList compType='Register' />,
        noCollapse: true,
    },

    // Taxation
    {
        type: "registers",
        name: "Taxation Register",
        key: "Taxation",

        route: "/Masters/TaxationListing/reg",
        component: <FrmMTaxationList compType='Register' />,
        noCollapse: true,
    },

    // Taxtype 
    {
        type: "registers",
        name: "Taxtype Register",
        key: "Taxtype",

        route: "/Masters/TaxtypeListing/reg",
        component: <FrmMTaxtypeList compType='Register' />,
        noCollapse: true,
    },
    // HSN-SAC
    {
        type: "registers",
        name: "HSN-SAC Register",
        key: "HSN-SAC",

        route: "/Masters/HSNSACListing/reg",
        component: <FrmMHSNSACList compType='Register' />,
        noCollapse: true,
    },

    // Product Type Master
    {
        type: "registers",
        name: "ProductType Register",
        key: "ProductType",

        route: "/Masters/ProductTypeListing/reg",
        component: <FrmMProductTypeListing compType='Register' />,
        noCollapse: true,
    },

    //Employees 

    {
        type: "registers",
        name: "Employees Register",
        key: "Employees",

        route: "/Masters/EmployeesListing/reg",
        component: <FrmMEmployeeList compType='Register' />,
        noCollapse: true,
    },

    // Product Category1 

    {
        type: "registers",
        name: "Product Category-1 Register",
        key: "ProductCategory1",

        route: "/Masters/ProductCategory1Listing/reg",
        component: <FrmMProductCategory1Listing compType='Register' />,
        noCollapse: true,
    },

    // ProductCategory2 Type
    {
        type: "registers",
        name: "Product Category-2 Register",
        key: "ProductCategory2",

        route: "/Masters/ProductCategory2Listing/reg",
        component: <FrmMProductCategory2Listing compType='Register' />,
        noCollapse: true,
    },

    // ProductCategory3 Type 
    {
        type: "registers",
        name: "Product Category-3 Register",
        key: "ProductCategory3",

        route: "/Masters/ProductCategory3Listing/reg",
        component: <FrmMProductCategory3Listing compType='Register' />,
        noCollapse: true,
    },

    // ProductCategory4 Type 
    {
        type: "registers",
        name: "Product Category-4 Register",
        key: "ProductCategory4",

        route: "/Masters/ProductCategory4Listing/reg",
        component: <FrmMProductCategory4Listing compType='Register' />,
        noCollapse: true,
    },

    // ProductCategory5 Type 
    {
        type: "registers",
        name: "Product Category-5 Register",
        key: "ProductCategory5",

        route: "/Masters/ProductCategory5Listing/reg",
        component: <FrmMProductCategory5Listing compType='Register' />,
        noCollapse: true,
    },

    // Godown
    {
        type: "registers",
        name: "Godown Register",
        key: "Godown",

        route: "/Masters/GodownListing/reg",
        component: <FrmGodownList compType='Register' />,
        noCollapse: true,
    },

    // Product Make 
    {
        type: "registers",
        name: "Product Make Register",
        key: "ProductMake",

        route: "/Masters/ProductMakeListing/reg",
        component: <FrmMProductMakeListing compType='Register' />,
        noCollapse: true,
    },

    // Product Material Grade
    {
        type: "registers",
        name: "Product Material Grade Register",
        key: "ProductMaterialGrade",

        route: "/Masters/ProductMaterialGradeListing/reg",
        component: <FrmMProductMaterialGradeListing compType='Register' />,
        noCollapse: true,
    },
    // Product Material Type
    {
        type: "registers",
        name: "Product Material Type Register",
        key: "ProductMaterialType",

        route: "/Masters/ProductMaterialTypeListing/reg",
        component: <FrmMProductMaterialTypeListing compType='Register' />,
        noCollapse: true,
    },

    // Product Packing
    {
        type: "registers",
        name: "Product Packing Register",
        key: "ProductPacking",

        route: "/Masters/ProductPackingListing/reg",
        component: <FrmMProductPackingListing compType='Register' />,
        noCollapse: true,
    },
    // Product Qa Parameters
    {
        type: "registers",
        name: "Product Qa Parameters Register",
        key: "ProductQaParameters",

        route: "/Masters/ProductQaParametersListing/reg",
        component: <FrmMProductQaParametersListing compType='Register' />,
        noCollapse: true,
    },

    // Product Rejection Parameters
    {
        type: "registers",
        name: "Product Rejection Parameters Register",
        key: "ProductRejectionParameters",

        route: "/Masters/ProductRejectionParametersListing/reg",
        component: <FrmMProductRejectionParametersListing compType='Register' />,
        noCollapse: true,
    },

    // Product Material Measure  Parameters 
    {
        type: "registers",
        name: "Product Material Measure Parameters Register",
        key: "ProductMaterialMeasureParameters",

        route: "/Masters/ProductMaterialMeasureParametersListing/reg",
        component: <FrmMProductMaterialMeasureParametersListing compType='Register' />,
        noCollapse: true,
    },

    // Product Material Shape 
    {
        type: "registers",
        name: "Product Material Shape Register",
        key: "ProductMaterialShape",

        route: "/Masters/ProductMaterialMeasureShapeListing/reg",
        component: <FrmMProductMaterialShapeListing compType='Register' />,
        noCollapse: true,
    },

    // Product Process 
    {
        type: "registers",
        name: "Product Process Register",
        key: "ProductProcess",

        route: "/Masters/FrmMProductProcessListing/reg",
        component: <FrmMProductProcessList compType='Register' />,
        noCollapse: true,
    },

    // Product Unit 
    {
        type: "registers",
        name: "Product Unit Register",
        key: "ProductUnit",

        route: "/Masters/FrmProductUnitListing/reg",
        component: <FrmProductUnitListing compType='Register' />,
        noCollapse: true,
    },

    // Product Unit Conversion 
    {
        type: "registers",
        name: "Product Unit Conversion Register",
        key: "ProductUnitConversion",

        route: "/Masters/FrmproductUnitConversionListing/reg",
        component: <FrmproductUnitConversionListing compType='Register' />,
        noCollapse: true,
    },

    // Property
    {
        type: "registers",
        name: "Property Register",
        key: "PropertyMaster",

        route: "/Masters/FrmPropertyListing/reg",
        component: <FrmPropertyListing compType='Register' />,
        noCollapse: true,
    },

    //Shift 
    {
        type: "registers",
        name: "Shift Register",
        key: "Shift",

        route: "/Masters/FrmShiftListing/reg",
        component: <FrmShiftListing compType='Register' />,
        noCollapse: true,
    },
    //cost center
    {
        type: "registers",
        name: "Cost Center Register",
        key: "CostCenter",

        route: "/Masters/MCostCenterListing/reg",
        component: <MCostCenterListing compType='Register' />,
        noCollapse: true,
    },
    //profit center
    {
        type: "registers",
        name: "Profit Center Register",
        key: "ProfitCenter",

        route: "/Masters/ProfitCenterListing/reg",
        component: <ProfitCenterListing compType='Register' />,
        noCollapse: true,
    },

    //ScheduleLedger
    {
        type: "registers",
        name: "Schedule Ledger Register",
        key: "ScheduleLedgerMaster",

        route: "/Masters/MFMScheduleLedgerListing/reg",
        component: <MFMScheduleLedgerListing compType='Register' />,
        noCollapse: true,
    },

    //General ScheduleLedger
    {
        type: "registers",
        name: "General Ledger Register",
        key: "GeneralLedgerMaster",

        route: "/Masters/MFMGeneralLedgerListing/reg",
        component: <MFMGeneralLedgerListing compType='Register' />,
        noCollapse: true,
    },
    //module form
    // {
    //   type: "registers",
    //   name: "Module Form Register",
    //   key: "ModuleForm",
    //   
    //   route: "/Masters/ModuleFormListing/reg",
    //   component: <ModuleFormListing compType='Register'/>,
    //   noCollapse: true,
    // },

    //service 
    {
        type: "registers",
        name: "Service Register",
        key: "Service",

        route: "/Masters/FrmMServiceListing/reg",
        component: <FrmMServiceListing compType='Register' />,
        noCollapse: true,
    },

    //PaymentTerms
    {
        type: "registers",
        name: "Payment Terms Register",
        key: "PaymentTerms",

        route: "/Masters/FrmPaymentTermsListing/reg",
        component: <FrmPaymentTemrsListing compType='Register' />,
        noCollapse: true,
    },

    //MPaymentSchedule
    {
        type: "registers",
        name: "Payment Schedule Register",
        key: "PaymentSchedule",

        route: "/Masters/FrmPaymentScheduleListing/reg",
        component: <FrmPaymentScheduleListing compType='Register' />,
        noCollapse: true,
    },

    // Machine
    {
        type: "registers",
        name: "Machine Register",
        key: "Machine Master",

        route: "/Masters/FrmMachineListing/reg",
        component: <FrmMachineListing compType='Register' />,
        noCollapse: true,
    },
    //Plant
    {
        type: "registers",
        name: "Plant Register",
        key: "Plant Master",

        route: "/Masters/FrmPlantlist/reg",
        component: <FrmPlantlist compType='Register' />,
        noCollapse: true,
    },

    //bank Listing 
    {
        type: "registers",
        name: "Bank Listing  Register",
        key: "Bank Listing ",

        route: "/Masters/MBankList/FrmBankListListing/reg",
        component: <FrmBankListListing compType='Register' />,
        noCollapse: true,
    },
    // Job Type Master
    {
        type: "registers",
        name: "Job Type Register",
        key: "Job Type",

        route: "/Masters/MJobType/FrmJobTypeListing/reg",
        component: <FrmJobTypeListing compType='Register' />,
        noCollapse: true,
    },
    //Templates Communications Master
    {
        type: "registers",
        name: "Templates Communications Register",
        key: "Templates Communications",

        route: "/Masters/MCommunicationsMaster/FrmCommunicationsMasterList/reg",
        component: <FrmCommunicationsMasterList compType='Register' />,
        noCollapse: true,
    },

    // hm_earning_heads 
    {
        type: "registers",
        name: "Earning Heads Register",
        key: "Earning Heads",
        route: "/Masters/MHMEarningHeads/MHMEarningHeadsListing/reg",
        component: <FrmMHMEarningHeadsListing compType='Register' />,
        noCollapse: true,
    },

    //Production Count Master
    {
        type: "registers",
        name: "Production Count Register",
        key: "Production Count",

        route: "/Masters/MCount/FrmCountListing/reg",
        component: <FrmCountListing compType='Register' />,
        noCollapse: true,
    },

    //Production Holiday 
    {
        type: "registers",
        name: "Production Holiday Register",
        key: "Production Holiday",

        route: "/Masters/MHoliday/FrmHolidayListing/reg",
        component: <FrmHolidayListing compType='Register' />,
        noCollapse: true,
    },

    // hm_Deduction_heads
    {
        type: "registers",
        name: "Deduction Heads Register",
        key: "Deduction Heads ",

        route: "/Masters/MHMDeductionHeads/FrmMHMDeductionHeadsListing/reg",
        component: <FrmMHMDeductionHeadsListing compType='Register' />,
        noCollapse: true,
    },

    //Production Process 
    {
        type: "registers",
        name: "Production Process Register",
        key: "Production Process",

        route: "/Masters/MProductionProcess/MFrmProductionProcessListing/reg",
        component: <MFrmProductionProcessListing compType='Register' />,
        noCollapse: true,
    },

    //Production Stoppage Reasons 
    {
        type: "registers",
        name: "Production Stoppage Reasons Register",
        key: "Production Stoppage Reasons",

        route: "/Masters/MProductionStoppageReasons/MFrmProductionStoppageReasonsListing/reg",
        component: <MFrmProductionStoppageReasonsListing compType='Register' />,
        noCollapse: true,
    },
    //Production Wastage Master
    {
        type: "registers",
        name: "Production Wastage Types Register",
        key: "Production Wastage Types",

        route: "/Masters/MProductionWastageType/MFrmProductionWastageTypesListing/reg",
        component: <MFrmProductionWastageTypesListing compType='Register' />,
        noCollapse: true,
    },

    //Production Electrical Meter Entry 
    {
        type: "registers",
        name: "Production Electrical Meter Register",
        key: "Production Electrical Meter",

        route: "/Masters/MProductionElectricalMeter/MFrmProductionElectricalMeterListing/reg",
        component: <MFrmProductionElectricalMeterListing compType='Register' />,
        noCollapse: true,
    },
    // production spinning plan parameter 
    {
        type: "registers",
        name: "Production Spinning Plan Parameter Register",
        key: "Production Spinning Plan Parameter ",

        route: "/Masters/MXMProductionSpinningPlanParameter/FrmMProductionSpinningPlanParameterListing/reg",
        component: <FrmMProductionSpinningPlanParameterListing compType='Register' />,
        noCollapse: true,
    },

    //Maintance task activity
    {
        type: "registers",
        name: "Maintenance Task Activity Register",
        key: "Maintenance Task Activity",

        route: "/Masters/MmaintenanceTaskActivity/MmaintenanceTaskActivityListing/reg",
        component: <MmaintenanceTaskActivityListing compType='Register' />,
        noCollapse: true,
    },

    //Maintance master and details
    {
        type: "registers",
        name: "Maintenance Task Master Register",
        key: "Maintenance Task Master",

        route: "/Masters/MmaintenanceTaskMaster/FrmMaintenanceTaskMasterListing/reg",
        component: <FrmMaintenanceTaskMasterListing compType='Register' />,
        noCollapse: true,
    },

    // Sales 
    //Enquiry trading Management
    {
        type: "registers",
        name: "Enquiry Management (Trading) Register",
        key: "EnquiryManagement",

        route: "/Transactions/TEnquiryManagement/FrmEnquiryListing/reg",
        component: <FrmEnquiryListing compType='Register' />,
        noCollapse: true,
    },

    //Enquiry Servises
    {
        type: "registers",
        name: "Enquiry Management (Service) Register",
        key: "EnquiryServiceManagement",

        route: "/Transactions/TEnquiryServiceManagement/FrmEnquiryServiceListing/reg",
        component: <FrmEnquiryServiceListing compType='Register' />,
        noCollapse: true,
    },

    //Quotation 
    {
        type: "registers",
        name: "Quotation (Trading) Register",
        key: "Quotation",
        route: "/Transactions/TQuotation/FrmQuotationListing/reg",
        component: <FrmQuotationListing compType='Register' />,
        noCollapse: true,
    },

    //Quotation Servises
    {
        type: "registers",
        name: "Quotation (Service) Register",
        key: "QuotationService",

        route: "/Transactions/TQuotationService/FrmQuotationServiceListing/reg",
        component: <FrmQuotationServiceListing compType='Register' />,
        noCollapse: true,
    },

    // Sales Order Trading.
    {
        type: "registers",
        name: "Sales Order Register",
        key: "Sales Order",

        route: "/Transactions/TSalesOrder/SalesOrderListing/reg/:requestfor",
        component: <SalesOrderListing compType='Register' />,
        noCollapse: true,
    },
    // Sales Order Service.
    {
        type: "registers",
        name: "Sales Order (Services) Register",
        key: "Sales Order Services",

        route: "/Transactions/TSalesOrder/Services/SOServiceListing/reg",
        component: <SOServiceListing compType='Register' />,
        noCollapse: true,
    },

    //Dispatch Note
    {
        type: "registers",
        name: "Dispatch Advisory (Trading) Register",
        key: "DispatchNote",

        route: "/Transactions/TDispatchNote/TDispatchNoteListing/reg",
        component: <TDispatchNoteListing compType='Register' />,
        noCollapse: true,
    },

    // Dispatch-Challan Trading.
    {
        type: "registers",
        name: "Dispatch Challan (Trading) Register",
        key: "Dispatch Challan",

        route: "/Transactions/TDispatchChallan/TDispatchChallanListing/reg",
        component: <TDispatchChallanListing compType='Register' />,
        noCollapse: true,
    },

    // SalesInvoice
    {
        type: "registers",
        name: "Sales Invoice (Trading) Register",
        key: "Sales Invoice",

        route: "/Transactions/SalesInvoiceListing/reg",
        component: <FrmSalesInvoiceListing compType='Register' />,
        noCollapse: true,
    },

    // purchase payment Customer Transition
    {
        type: "registers",
        name: "Customer Sales Receipt Register",
        key: "customerSalesReceipt",

        route: "/Transactions/TPurchaseOrder/TPurchasePaymentCustomer/FrmCustomerSalesReceiptListing/reg",
        component: <FrmCustomerSalesReceiptListing compType='Register' />,
        noCollapse: true,
    },

    // Goods Receipt Notes
    {
        type: "registers",
        name: "Goods Receipt Notes Register",
        key: "Goods Receipt Notes",

        route: "/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteListing/reg/:requestfor",
        component: <FrmGoodsReceiptNoteListing compType='Register' />,
        noCollapse: true,
    },

    //Purchase Bill Book Auto
    {
        type: "registers",
        name: "Purchase Bill Book(Auto) Register",
        key: "BillBook",

        route: "/Transactions/TPurchaseOrder/TBillBook/BillBookListing/reg",
        component: <BillBookListing compType='Register' />,
        noCollapse: true,
    },

    // Manual Bill Book
    {
        type: "registers",
        name: "Purchase Bill Book(Manual) Register",
        key: "ManualBillBook",

        route: "/Transactions/TPurchaseOrder/TManualBillBook/FrmManualBillBookListing/reg",
        component: <FrmManualBillBookListing compType='Register' />,
        noCollapse: true,
    },

    // Purchase Payment 
    {
        type: "registers",
        name: "Purchase Payment Supplier Register",
        key: "PurchasePaymentSupplier",

        route: "/Transactions/TPurchaseOrder/TPurchasePaymentSupplier/FrmPurchasePaymentSupplierListng/reg",
        component: <FrmPurchasePaymentSupplierListing compType='Register' />,
        noCollapse: true,
    },

    // smv_product_fg_stock listing
    {
        type: "registers",
        name: "Finish Goods Stock Register",
        key: "Product finish goods Stock",

        route: "/Masters/MSMVProductFgStock/FrmMSMVProductFgStocklisting",
        component: <FrmMSMVProductFgStocklisting />,
        noCollapse: true,
    },

    // // Product Rm Stock listing
    {
        type: "registers",
        name: "Raw Material Stock Register",
        key: "Product Raw Material Stock",

        route: "/Masters/MSMVProductRmStock/FrmMSMVProductRmStockListing",
        component: <FrmMSMVProductRmStockListing />,
        noCollapse: true,
    },

    // General Stores & Spares listing
    {
        type: "registers",
        name: "General Stores & Spares",
        key: "General Stores & Spares",
        route: "/masters/generalstoresandspares/register",
        component: <MSmGeneralStoresAndSparesListing />,
        noCollapse: true,
    },


    //Purchase Requisition 
    {
        type: "registers",
        name: "Indent Register",
        key: "Indent Register",

        route: "/Transactions/TPurchaseOrder/Indent/IndentListing/reg/",
        component: <IndentListing compType='Register' />,
        noCollapse: true,
    },

    {
        type: "registers",
        name: "Pending Indent Register",
        key: "Pending Indent",
        route: "/Transactions/TPurchaseOrder/PendingIndentReports/reg/:requestfor",
        component: <PendingIndentListing compType='Register' />,
        noCollapse: true,
    },


    //Purchase Requisition (BOM)
    {
        type: "registers",
        name: "Purchase Requisition (BOM) Register",
        key: "Good Reciept Notes",

        route: "/Transactions/TPurchaseOrder/PurchaseRequisition/FrmPurchaseRequisitionListing/reg",
        component: <FrmPurchaseRequisitionListing compType='Register' />,
        noCollapse: true,

    },

    //Transfer Requisition (Inter-Unit Transfer)
    {
        type: "registers",
        name: "Inter-Unit Transfer",
        key: "Inter-Unit Transfer",
        route: "/Transactions/TPurchaseOrder/MaterialTransferRequisition/FrmMaterialTransferRequisitionListing/reg",
        component: <FrmMaterialTransferRequisitionListing compType='Register' />,
        noCollapse: true,
    },

    //Manual PO Transition
    {
        type: "registers",
        name: "Purchase Order (Trading) Register",
        key: "PurchaseOrder",

        route: "/Transactions/TPurchaseOrder/ManualPO/POListing/reg/:requestfor",
        component: <POListing compType='Register' />,
        noCollapse: true,
    },

    //  Below registers by shivanjali
    {
        type: "registers",
        name: "Schedules Register",
        route: "/Masters/MFMScheduleLedgerListing/reg",
        component: <MFMScheduleLedgerListing compType='Register' />,
        noCollapse: true,
    },

    {
        type: "registers",
        name: "Generals Register",
        route: "/Masters/MFMGeneralLedgerListing/reg",
        component: <MFMGeneralLedgerListing compType='Register' />,
        noCollapse: true,
    },
    {
        type: "registers",
        name: "Modules Register",
        route: "/Masters/ModuleFormListing/reg",
        component: <ModuleFormListing compType='Register' />,
        noCollapse: true,
    },
    //Production Holiday 
    {
        type: "registers",
        name: "Production Holiday Register",
        key: "Production Holiday",
        route: "/Masters/MHoliday/FrmHolidayListing/reg",
        component: <FrmHolidayListing compType='Register' />,
        noCollapse: true,
    },

    // Attendance Device Master
    {
        type: "registers",
        name: "Attendance Device Register",
        key: "Attendance Device",
        route: "/Masters/MAttendanceDevice/FrmAttendanceDeviceListing/reg",
        component: <FrmAttendanceDeviceListing compType='Register' />,
        noCollapse: true,
    },

    {
        type: "registers",
        name: "Departments Register",
        key: "Departments",
        route: "/Masters/DepartmentListing/reg",
        component: <FrmMDepartmentList compType='Register' />,
        noCollapse: true,
    },

    {
        type: "registers",
        name: "Customer Material Reciept Register",
        route: "/Transactions/TCustomerReciept/FrmTCustomerRecieptListing/reg",
        component: <FrmTCustomerRecieptListing compType='Register' />,
        noCollapse: true,
    },


    {
        type: "registers",
        name: "Customer Material Issue Register",
        route: "/Transactions/TCustomerMaterialIssue/TCustomerMaterialIssueListing/reg",
        component: <TCustomerMaterialIssueListing compType='Register' />,
        noCollapse: true,
    },

    // {
    //     type: "registers",
    //     name: "Indent Material Issue Register",
    //     route: "/Transactions/TIndentMaterialIssue/FrmIndentMaterialIssueListing/reg",
    //     component: <FrmIndentMaterialIssueListing compType='Register' />,
    //     noCollapse: true,
    // },
    {
        type: "registers",
        name: "Product Finish Good Customer Register",
        route: "/Masters/MStock/MProductFgCustomerStock/reg",
        component: <MProductFgCustomerStock compType='Register' />,
        noCollapse: true,
    },
    {
        type: "registers",
        name: "Product Finish Good Stock Customer Register",
        route: "/Masters/MStock/MProductFgStockCustomer/reg",
        component: <MProductFgStockCustomer compType='Register' />,
        noCollapse: true,
    },
    {
        type: "registers",
        name: "Warping Production Register",
        route: "/Transactions/TWarpingProduction/FrmWarpingProductionListing/reg",
        component: <FrmWarpingProductionListing compType='Register' />,
        noCollapse: true,

    },
    {

        type: "registers",
        name: "Weaving Production Plan Register",
        route: "/Masters/MWeavingProductionPlanMaster/FrmWeavingProductionPlanListing/reg",
        component: <FrmWeavingProductionPlanListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "registers",
        name: "Product Type Dynamic Controls Register",
        route: "/Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsListing/reg",
        component: <FrmProductTypeDynamicControlsListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "registers",
        name: "Spinning Plan Register",
        route: "/Masters/MXMProductionSpinningPlanParameter/FrmMProductionSpinningPlanParameterListing/reg",
        component: <FrmMProductionSpinningPlanParameterListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "registers",
        name: "Service Planning Register",
        route: "/Transactions/TServicePlanning/ServicePlanningListing/reg",
        component: <TServicePlanningListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "registers",
        name: "Codification Register",
        route: "/Masters/MCodification/MCodificationList/reg",
        component: <MCodificationListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "registers",
        name: "Sizing Production Register",
        route: "/Transactions/TSizingProduction/FrmTSizingProductionListing/reg",
        component: <FrmTSizingProductionListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "registers",
        name: "Production Section Register",
        route: "/Masters/MProductionSection/FrmProductionSectionListing/reg",
        component: <FrmProductionSectionListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "registers",
        name: "Production Sub Section Register",
        route: "/Masters/MProductionSubSection/FrmProductionSubSectionListing/reg",
        component: <FrmProductionSubSectionListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "registers",
        name: "Service Activity Register",
        route: "/Masters/ServiceActivityListing/reg",
        component: <FrmMServiceActivityListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "registers",
        name: "WeeklyOff Register",
        route: "/Masters/MWeeklyOff/FrmWeeklyOffListing/reg",
        component: <FrmWeeklyOffListing compType='Register' />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "registers",
        name: "Loom Production Register",
        route: "/Transactions/TLoomProductaion/FrmLoomProductionListing/reg",
        component: <FrmLoomProductionListing compType='Register' />,
        header: true,
        footer: true,
        protected: false

    },
    {
        type: "registers",
        name: "Production Wastage Types",
        route: "/Masters/MProductionWastageType/MFrmProductionWastageTypesListing/reg",
        component: <MFrmProductionWastageTypesListing compType='Register' />,
        header: true,
        footer: true,
        protected: false

    },
    {
        type: "registers",
        name: "Production Stoppage Reasons",
        route: "/Masters/MProductionStoppageReasons/MFrmProductionStoppageReasonsListing/reg",
        component: <MFrmProductionStoppageReasonsListing compType='Register' />,
        header: true,
        footer: true,
        protected: false

    },

    // For Production Report
    {
        type: "Production",
        name: "Production Report",
        route: "/Transactions/TProductionReport/FrmProductionReportEntry",
        component: <FrmProductionReportEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Cost Centers",
        route: "/Masters/MCostCenterHeads/FrmCostCenterHeadsListing",
        component: <FrmCostCenterHeadsListing />,
        header: true,
        footer: true,
        protected: false
    },


    {
        route: "/Masters/MCostCenterHeads/FrmCostCenterHeadsEntry",
        component: <FrmCostCenterHeadsEntry />,
        header: true,
        footer: true,
        protected: false
    },

    //Yarn Packing Master
    {
        type: "Masters",
        name: "Yarn Packing Master",
        route: "/Masters/MYarnPackingMaster/FrmYarnPackingListing",
        component: <FrmYarnPackingListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MYarnPackingMaster/FrmYarnPackingMasterEntry",
        component: <FrmYarnPackingMasterEntry />,
        header: true,
        footer: true,
        protected: false
    },
    //Packing Material Master
    {
        type: "Masters",
        name: "Packing Material Master",
        route: "/Masters/MPackingMaterial/FrmPackingMaterialListing",
        component: <FrmPackingMaterialListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MPackingMaterial/FrmPackingMaterialEntry",
        component: <FrmPackingMaterialEntry />,
        header: true,
        footer: true,
        protected: false

    },

    //Job Assign
    {
        type: "Masters",
        name: "Job Assign Master",
        route: "Masters/MJobAssign/FrmJobAssignListing",
        component: <FrmJobAssignListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "Masters/MJobAssign/FrmJobAssignEntry",
        component: <FrmJobAssignEntry />,
        header: true,
        footer: true,
        protected: false

    },

    //Stock Adjustment
    {
        type: "Masters",
        name: "Stock Adjustment Master",
        route: "Masters/MStockAdjustment/MStockAdjustmentListing",
        component: <FrmStockAdjustmentlisting />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "Masters/MStockAdjustment/MStockAdjustmentEntry",
        component: <FrmStockAdjustmentEntry />,
        header: true,
        footer: true,
        protected: false

    },

    //Shift Roster
    // {
    //     type: "Masters",
    //     name: "Shift Roster Master",
    //     route: "Masters/MShiftRoster/MShiftRosterListing",
    //     component: <FrmShiftRosterListing />,
    //     header: true,
    //     footer: true,
    //     protected: false
    // },
    {
        type: "Masters",
        name: "Shift Roster Master",
        route: "Masters/MShiftRoster/MShiftRosterEntry",
        component: <FrmShiftRosterEntry />,
        header: true,
        footer: true,
        protected: false

    },


    //Gate Pass
    {
        type: "Masters",
        name: "Gate Pass Master",
        route: "Masters/MGatePass/MGatePassListing",
        component: <FrmGatePassListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "Masters/MGatePass/MGatePassEntry",
        component: <FrmGatePassEntry />,
        header: true,
        footer: true,
        protected: false

    },



    {
        type: "Sales",
        name: "Dispatch Challan (Yarn)",
        route: "/Transactions/TYarnDispatchChallan/TYarnDispatchChallanListing",
        component: <TYarnDispatchChallanListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TYarnDispatchChallan/TYarnDispatchChallanEntry",
        component: <TYarnDispatchChallanEntry />,
        header: true,
        footer: true,
        protected: false
    },

    // Product Register 
    {
        type: "Masters",
        name: "Material Stock Report",
        key: "Material Stock Report",
        route: "Masters/MMaterialStockReport/FrmMaterialStockReportListing",
        component: <FrmMaterialStockReportListing />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "purchase",
        name: "Material Requisition",
        route: "/Transactions/TMaterialRequisition/FrmMaterialRequisitionLIsting/:requestfor",
        component: <FrmMaterialRequisitionLIsting />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TMaterialRequisition/FrmMaterialRequisitionEntry",
        component: <FrmMaterialRequisitionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // Raw material Requisition Slip
    {
        type: "purchase",
        name: "Raw Material Requisition",
        route: "/Transactions/TRawMaterialRequisition/FrmRawMaterialRequisitionListing/:requestfor",
        component: <FrmRawMaterialRequisitionListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TRawMaterialRequisition/FrmRawMaterialRequisitionEntry",
        component: <FrmRawMaterialRequisitionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Password Reset",
        route: "/Masters/MUserPasswordChange",
        component: <MUserPasswordChange />,
        header: true,
        footer: true,
        protected: false
    },

    // Raw material return 
    {
        type: "purchase",
        name: "Raw Material Return",
        route: "/Transactions/RawMaterialReturnListing",
        component: <FrmRawMaterialReturnListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/RawMaterialReturnEntry",
        component: <FrmRawMaterialReturnEntry />,
        header: true,
        footer: true,
        protected: false
    },

    // warping botoom return
    {
        type: "purchase",
        name: "Warping Bottom Return",
        route: "/Transactions/TWarpingBottomReturn/FrmWarpingBottomReturnListing",
        component: <FrmWarpingBottomReturnListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TWarpingBottomReturn/FrmWarpingBottomReturnEntry",
        component: <FrmWarpingBottomReturnEntry />,
        header: true,
        footer: true,
        protected: false
    },

    // Employee Monthly Deduction
    {
        type: "Transactions",
        name: "Monthly Deduction",
        route: "/FrmMonthlyDeductionListing",
        component: <FrmMonthlyDeductionListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/FrmMonthlyDeductionEntry",
        component: <FrmMonthlyDeductionEntry />,
        header: true,
        footer: true,
        protected: false
    },

    // Salary Processing Frm
    {
        type: "Transactions",
        name: "Salary Processing",
        key: "Salary Processing",
        route: "/HRPayroll/TSalaryProcessing",
        component: <TFrmSalaryProcessing />,
        noCollapse: true,
    },
    {
        type: "Transactions",
        name: "Salary Processing",
        key: "Salary Processing",
        route: "/HRPayroll/TSalaryProcessingNew",
        component: <TFrmSalaryProcessingNew />,
        noCollapse: true,
    },

    // Salary Corrections Frm
    {
        type: "Transactions",
        name: "Salary Corrections Listing",
        route: "/HRPayroll/FrmSalaryCorrectionsListing",
        component: <FrmSalaryCorrectionsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/HRPayroll/FrmSalaryCorrectionsEntry",
        component: <FrmSalaryCorrectionsEntry />,
        header: true,
        footer: true,
        protected: false
    },

    // Sales order stock transfer
    {
        type: "Transactions",
        name: "Sales Order Stock Transfer",
        route: "/salesorder/stocktransferlisting",
        component: <FrmSalesOrderStockTransferListing />,
        header: true,
        footer: true,
        protected: false
    },

    {
        route: "/salesorder/stocktransfer",
        component: <FrmSalesOrderStockTransferEntry />,
        header: true,
        footer: true,
        protected: false
    },



    // ** Monthly Salary Register.
    {
        type: "registers",
        key: "Registers",
        name: "Monthly Salary Register",
        route: "/HRPayroll/MonthlySalary/reg",
        component: <MonthlySalaryRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },
    // ** Worker Monthly Salary Register.
    {
        type: "registers",
        key: "Registers",
        name: "Worker Monthly Salary Register",
        route: "/HRPayroll/WorkerMonthlySalary/reg",
        component: <WorkerMonthlySalaryRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    {
        type: "registers",
        key: "Registers",
        name: "Worker Monthly Salary Register",
        route: "/HRPayroll/WorkerMonthlySalaryNew/reg",
        component: <WorkerMonthlySalaryRegisterNew compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Immediate Punch Report.
    {
        type: "registers",
        key: "Registers",
        name: "Immediate Punch Report",
        route: "/THRPayroll/Registers/ImmediatePunchReport",
        component: <ImmediatePunchReport compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // Attendance Device Punch Report
    {
        type: "registers",
        key: "Registers",
        name: "Attendance Device Punch",
        route: "/THRPayroll/Registers/AttendanceDevicePunchReport",
        component: <AttendanceDevicePunch compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Monthly Attendance Summary (Staffs).
    {
        type: "registers",
        key: "Registers",
        name: "Monthly Attendance",
        route: "/HRPayroll/MonthlyAttendanceSummary/reg",
        component: <MonthlyAttendanceSummary compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Monthly Attendance Summary (Workers).
    {
        type: "registers",
        key: "Registers",
        name: "Monthly Attendance",
        route: "/HRPayroll/MonthlyAttendanceSummaryWorkers/reg",
        component: <MonthlyAttendanceSummaryWorkers compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** missed Punch Report.
    {
        type: "registers",
        key: "Registers",
        name: "Missed Punch Report",
        route: "/Transactions/THRPayroll/AttendanceRegisters/MissedPunchReport/reg",
        component: <MissedPunchReport compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },
    // ** Manual Attendance Registers.
    {
        type: "registers",
        key: "Registers",
        name: "Manual Attendance Register",
        route: "/THRPayroll/AttendanceRegisters/ManualAttendanceRegister/reg",
        component: <ManualAttendanceRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },
    // ** Leave Application Registers.
    {
        type: "registers",
        key: "Registers",
        name: "Leave Register",
        route: "/THRPayroll/AttendanceRegisters/LeaveRegister/reg",
        component: <LeaveRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Short Leave Registers.
    {
        type: "registers",
        key: "Registers",
        name: "Short Leave Register",
        route: "/THRPayroll/AttendanceRegisters/ShortLeaveRegister/reg",
        component: <ShortLeaveRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** LateMark And EarlyGo Register.
    {
        type: "registers",
        key: "Registers",
        name: "Late_mark And Early_go Register",
        route: "/THRPayroll/AttendanceRegisters/LateMarkAndEarlyGoRegister/reg",
        component: <LateMarkAndEarlyGoRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** ShiftShcedule Register.
    {
        type: "registers",
        key: "Registers",
        name: "Shift Schedule Register",
        route: "/THRPayroll/AttendanceRegisters/ShiftScheduleRegister/reg",
        component: <ShiftScheduleRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },


    // contractor Commission Master
    {
        type: "Masters",
        name: "Contractor Commission",
        key: "Contractor Commission",
        route: "/Masters/MContractorCommission/FrmContractorCommissionList",
        component: <FrmContractorCommissionList />,
        noCollapse: true,
    },
    {
        name: "Contractor Commission",
        route: "/Masters/MContractorCommission/FrmContractorCommissionEntry",
        component: <FrmContractorCommissionEntry />,
    },


    // ** Contractor Commission Register.
    {
        type: "registers",
        key: "Registers",
        name: "Contractor Commission Register",
        route: "/THRPayroll/ContractorCommissionReg/reg",
        component: <ContractorCommissionReg compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },
    // CompOff Leave Request Master
    {
        type: "Masters",
        name: "CompOff Leave Request",
        key: "CompOff Leave Request",
        route: "/Masters/MCompOffLeaveRequest/FrmCompOffLeaveList",
        component: <FrmCompOffLeaveList />,
        noCollapse: true,
    },
    {
        name: "CompOff Leave Request",
        route: "/Masters/MCompOffLeaveRequest/FrmCompOffLeaveEntry",
        component: <FrmCompOffLeaveEntry />,
    },

    // ** DepartmentWise ManPower Register.
    {
        type: "registers",
        key: "Registers",
        name: "DepartmentWise ManPower Register",
        route: "THRPayroll/AttendanceRegisters/DptWiseManPowerReg/reg",
        component: <DptWiseManPowerReg compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Daily  ManPower Register.
    {
        type: "registers",
        key: "Registers",
        name: "Daily Manpower Register",
        route: "/THRPayroll/AttendanceRegisters/DailyManPowerRegister/reg",
        component: <DailyManPowerRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },
    // ** Department Wise Registers.
    // {
    //     type: "registers",
    //     key: "Registers",
    //     name: "Department Wise Attendance",
    //     route: "/THRPayroll/AttendanceRegisters/DepartmentWiseAttendanceReg/reg",
    //     component: <DepartmentWiseAttendanceReg compType='Register' />,
    //     header: true,
    //     footer: true,
    //     protected: false,
    // },
    // ** DepartmentWise Salary Register Staff.
    {
        type: "registers",
        key: "Registers",
        name: "DepartmentWise Salary Register",
        route: "/HRPayroll/DepartmentWiseSalary/reg",
        component: <DepartmentWiseSalaryRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Worker DepartmentWise Salary Register.
    {
        type: "registers",
        key: "Registers",
        name: "DepartmentWise Worker Salary Register",
        route: "/HRPayroll/WorkerDepartmentWiseSalary/reg",
        component: <WorkerDepartmentWiseSalaryRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Salary Bank Register.
    {
        type: "registers",
        key: "Registers",
        name: "Salary Bank List Register",
        route: "/HRPayroll/SalaryBankListRegister/reg",
        component: <SalaryBankListRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** PF Challan Register.
    {
        type: "registers",
        key: "Registers",
        name: "PF Challan Register",
        route: "/HRPayroll/PFChallanRegister/reg",
        component: <PFChallanRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Professional Tax Challan Register.
    {
        type: "registers",
        key: "Registers",
        name: "Professional Tax Challan Register",
        route: "/HRPayroll/ProfessionalTaxChallanRegister/reg",
        component: <ProfessionalTaxChallanRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Salary Earning Register.
    {
        type: "registers",
        key: "Registers",
        name: "Salary Earning Register",
        route: "/HRPayroll/SalaryEarningRegister/reg",
        component: <SalaryEarningRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Salary Deduction Register.
    {
        type: "registers",
        key: "Registers",
        name: "Salary Deduction Register",
        route: "/HRPayroll/SalaryDeductionRegister/reg",
        component: <SalaryDeductionRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Salary Slips Register.
    {
        type: "registers",
        key: "Registers",
        name: "Salary Slips Register",
        route: "/HRPayroll/SalarySlipsRegister/reg",
        component: <SalarySlipsRegister compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Monthly Attendance Register staffs.
    {
        type: "registers",
        key: "Registers",
        name: "Monthly Attendance",
        route: "/HRPayroll/MonthlyAttendance/reg",
        component: <MonthlyAttendance compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Monthly Attendance Register workers.
    {
        type: "registers",
        key: "Registers",
        name: "Monthly Attendance",
        route: "/HRPayroll/MonthlyAttendanceWorkerReg/reg",
        component: <MonthlyAttendanceWorkerReg compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** DateWise Attendance Register staffs.
    {
        type: "registers",
        key: "Registers",
        name: "Attendance",
        route: "/HRPayroll/Attendance/reg",
        component: <DateWiseAttendance compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    {
        type: "registers",
        key: "Registers",
        name: "Attendance",
        route: "/HRPayroll/Attendancenew/reg",
        component: <DateWiseAttendanceNew compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },


    // ** DateWise Attendance Register Workers.
    {
        type: "registers",
        key: "Registers",
        name: "Attendance",
        route: "/HRPayroll/DateWiseAttendanceWorkers/reg",
        component: <DateWiseAttendanceWorkers compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    {
        type: "purchase",
        name: "Material Loan",
        route: "/Transactions/TPurchaseOrder/MaterialLoanRequisition/FrmMaterialLoanRequisitionList",
        component: <FrmMaterialLoanRequisitionList />,
        header: true,
        footer: true,
        protected: false
    },

    {
        route: "/Transactions/TPurchaseOrder/MaterialLoanRequisition/FrmMaterialLoanRequisitionEntry",
        component: <FrmMaterialLoanRequisitionEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // Internal (Dept to Dept ) Material Transfer
    {
        type: "purchase",
        name: "Internal Material Transfer",
        route: "/Transactions/TPurchaseOrder/MaterialInternalTransfer/FrmMaterialInternalTransferListing",
        component: <FrmMaterialInternalTransferListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/MaterialInternalTransfer/FrmMaterialInternalTransferEntry",
        component: <FrmMaterialInternalTransferEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // Inter Unit transfer
    {
        type: "purchase",
        name: "Inter-Unit Transfer",
        route: "/Transactions/TPurchaseOrder/MaterialTransferRequisition/FrmMaterialTransferRequisitionListing",
        component: <FrmMaterialTransferRequisitionListing />,
        header: true,
        footer: true,
        protected: false
    },

    {
        route: "/Transactions/TPurchaseOrder/MaterialTransferRequisition/FrmMaterialTransferRequisitionEntry",
        component: <FrmMaterialTransferRequisitionEntry />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "registers",
        key: "Registers",
        name: "Material Requisition Register",
        route: "/Transactions/TMaterialRequisition/FrmMaterialRequisitionLIsting/reg",
        component: <FrmMaterialRequisitionLIsting compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },


    // Material Gate Pass
    {
        type: "purchase",
        name: "Material Gate Pass",
        route: "/Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialGatePassListing",
        component: <FrmMaterialGatePassListing />,
        header: true,
        footer: true,
        protected: false
    },

    {
        route: "/Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialGatePassEntry",
        component: <FrmMaterialGatePassEntry />,
        header: true,
        footer: true,
        protected: false
    },


    // Material Return Gate Pass
    {
        type: "purchase",
        name: "Return Material Gate Pass",
        route: "/Transactions/TPurchaseOrder/MaterialReturnGatePass/FrmMaterialReturnGatePassListing",
        component: <FrmMaterialReturnGatePassListing />,
        header: true,
        footer: true,
        protected: false
    },

    {
        route: "/Transactions/TPurchaseOrder/MaterialReturnGatePass/FrmMaterialReturnGatePassEntry",
        component: <FrmMaterialReturnGatePassEntry />,
        header: true,
        footer: true,
        protected: false
    },

    //  Material Bean Card.
    {
        type: "registers",
        key: "Registers",
        name: "Material Bean Card",
        route: "/Transactions/TMaterialBeanCard/reg",
        component: <FrmTMaterialBeanCard compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },
    // DepartmentWiseMaterial Utilization Register.
    {
        type: "registers",
        key: "Registers",
        name: "DepartmentWise Material Utilization",
        route: "/Transactions/TPurchaseOrder/DepartmentWiseMaterialUtilization/reg",
        component: <DepartmentWiseMaterialUtilization compType='Register' />,
        header: true,
        footer: true,
        protected: false,
    },

    // ** Salary Rules Master
    {
        type: "Masters",
        name: "Salary Rules",
        route: "/Masters/MSalaryRules/FrmSalaryRulesListing",
        component: <FrmSalaryRulesListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        type: "Masters",
        name: "Salary Rules",
        route: "/Masters/MSalaryRules/FrmSalaryRulesEntry",
        component: <FrmSalaryRulesEntry />,
        header: true,
        footer: true,
        protected: false,
    },
    // Help Forms
    {
        route: "/FrmGeneric/Help/helpCenter",
        component: <HelpCenter />,
        header: true,
        footer: true,
        protected: false,
    },
    {
        type: "Help",
        name: "Warping/Sizing",
        route: "/Help/HWeavingProduction/WeavingSizingHelp",
        component: <WeavingSizingHelp />,
        header: true,
        footer: true,
        protected: false,
    },


    //  Weaving Daily Production Report
    {
        type: "Production",
        name: "Weaving Daily Production Report",
        route: "/Transactions/TWeavingDailyProductionReport/TWeavingDailyProductionReportListing",
        component: <FrmWeavingDailyProductionReportListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TWeavingDailyProductionReport/TWeavingDailyProductionReportEntry",
        component: <FrmWeavingDailyProductionReportEntry />,
        header: true,
        footer: true,
        protected: false
    },

    //Daily Dispatch Challan Report
    {
        type: "Production",
        name: "Daily Dispatch Challan Report",
        route: "/Transactions/TDailyDispatchChallanReport/TDailyDispatchChallanReportListing",
        component: <FrmDailyDispatchChallanReportListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TDailyDispatchChallanReport/TDailyDipatchChallanReportEntry",
        component: <FrmDailyDispatchChallanReportEntry />,
        header: true,
        footer: true,
        protected: false
    },


    //Issue Report
    {
        type: "Registers",
        name: "Issue Report(General Stores & Purchase)",
        route: "/Transactions/TPurchaseReports/IssueReport/:requestfor",
        component: <FrmIssueReportEntry />,
        header: true,
        footer: true,
        protected: false
    },

    //Stock Aging Report
    {
        type: "Registers",
        name: "Stock Aging Report",
        route: "/Transactions/TPurchaseReports/StockAgingReport",
        component: <FrmStockAgingReport />,
        header: true,
        footer: true,
        protected: false
    },
    //Stock Store Report
    {
        type: "Registers",
        name: "Store Stock Report",
        route: "Transactions/TPurchaseReports/StorePurchaseStockReport",
        component: <StorePurchaseStockReport />,
        header: true,
        footer: true,
        protected: false
    },
    //Stock Store Report
    {
        type: "Registers",
        name: "Fabric Stock Report",
        route: "Transactions/TPurchaseReports/FabricStockReport",
        component: <FrmFabricStockReport />,
        header: true,
        footer: true,
        protected: false
    },

    //Raw Material Stock Report
    {
        type: "Registers",
        name: "Raw Material Stock Report",
        route: "Transactions/TPurchaseReports/RawMaterialStockReport",
        component: <RawMaterialStockReport />,
        header: true,
        footer: true,
        protected: false
    },


    //Sized Beam Stock Report
    {
        type: "Registers",
        name: "Sized Beam Stock Report",
        route: "Transactions/TPurchaseReports/SizedBeamStockReport",
        component: <FrmSizedBeamsReport />,
        header: true,
        footer: true,
        protected: false
    },


    //Yarn Closing Balance Report / Yarn Stock Report
    {
        type: "Registers",
        name: "Yarn Closing Balance Report",
        route: "Transactions/TPurchaseReports/YarnClosingBalanceReport",
        component: <YarnClosingBalanceReport />,
        header: true,
        footer: true,
        protected: false
    },
    //Purchase Outstanding materials Report
    {
        type: "Registers",
        name: "Outstanding materials Report",
        route: "Transactions/TPurchaseReports/OutstandingMaterialsForPOReport",
        component: <OutstandingMaterialsForPOReport />,
        header: true,
        footer: true,
        protected: false
    },

    //Beam Inwards
    {
        type: "Masters",
        name: "Beam Inwards",
        route: "/Masters/MBeamInwards/MBeamInwardsListing",
        component: <FrmBeamInwardsListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MBeamInwards/MBeamInwardsEntry",
        component: <FrmBeamInwardsEntry />,
        header: true,
        footer: true,
        protected: false
    },

    // Beam Issue Slip
    {
        type: "Production",
        name: "Beam Issue Slip",
        route: "/Transactions/TBeamIssueSlip/TBeamIssueSlipListing",
        component: <FrmTBeamIssueSlipListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TBeamIssueSlip/TBeamIssueSlipEntry",
        component: <FrmTBeamIssueSlipEntry />,
        header: true,
        footer: true,
        protected: false
    },

    {
        route: "/NetworkCheck",
        component: <Netcheck />,
        header: false,
        footer: false,
        protected: true
    },


    //Sizing Production Report
    {
        type: "Production",
        name: "Sizing Production Report",
        route: "Transactions/TSizingProduction/FrmTSizingProductionReport",
        component: <FrmSizingProductionReport />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "masters",
        name: "Business Travel",
        key: "Business Travel",
        route: "/Masters/MBusinessTravelApplication/FrmBusiessTravelListing",
        component: <FrmBusiessTravelListing />,
        noCollapse: true,
    },
    {
        name: "Business Travel",
        route: "/Masters/MBusinessTravelApplication/FrmBusinessTravelEntry",
        component: <FrmBusinessTravelEntry />,
    },


    {
        type: "Masters",
        name: "District",
        route: "Masters/MDistrict/FrmDistrictList",
        component: <FrmDistrictList />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "Masters/MDistrict/FrmDistrict",
        component: <FrmDistrict />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "Masters",
        name: "Standard Loss",
        route: "/Masters/MStandardLoss/StandardLossListing",
        component: <FrmStandardLossListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MStandardLoss/StandardLossEntry",
        component: <FrmStandardLossEntry />,
        header: true,
        footer: true,
        protected: false
    },


    //Fabric Production Report 
    {
        type: "Registers",
        name: "Fabric Production Report",
        route: "/Transactions/TPurchaseReports/FabricProductionReport",
        component: <FrmFabricProductionReport />,
        header: true,
        footer: true,
        protected: false
    },

    //Spinning - Cotton Bales Routes

    //Cotton Bales (Raw Material)
    {
        type: "Masters",
        name: "Cotton Bales",
        route: "/Masters/MRawCottonBale/FrmMRawCottonBaleListing",
        component: <FrmMRawCottonBaleListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Masters/MRawCottonBale/FrmMRawCottonBaleEntry",
        component: <FrmMRawCottonBaleEntry />,
        header: true,
        footer: true,
        protected: false
    },

    //Cotton Bales (Purchase Order)
    {
        type: "Purchase",
        name: "Cotton Bales PO",
        route: "/Transactions/TPurchaseOrder/ManualPO/CottonBalesPO/FrmCottonBalesListing",
        component: <FrmCottonBalesPOListing />,
        header: true,
        footer: true,
        protected: false
    },

    {
        route: "/Transactions/TPurchaseOrder/ManualPO/CottonBalesPO/FrmCottonBalesEntry",
        component: <FrmCottonBalesPOEntry />,
        header: true,
        footer: true,
        protected: false
    },
    //Cotton Bales (Goods Receipt Note)

    {
        type: "Purchase",
        name: "Goods Receipt Notes",
        route: "/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteCottonBalesListing",
        component: <FrmGoodsReceiptNoteCottonBalesListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteCottonBalesEntry",
        component: <FrmGoodsReceiptNoteCottonBalesEntry />,
        header: true,
        footer: true,
        protected: false
    },
    // Cotton Bales Stock Report
    {
        type: "Registers",
        name: "Cotton Bales Stock Report",
        route: "/Transactions/TPurchaseReports/CottonBalesStockReport",
        component: <FrmCottonBalesStockReport />,
        header: true,
        footer: true,
        protected: false
    },


    // Cotton Bales Quality Testing
    {
        type: "Transactions",
        name: "Quality Testing",
        route: "/Transactions/TCottonBalesManagement/QualityTestingEntry",
        component: <FrmQualityTestingEntry />,
        header: true,
        footer: true,
        protected: false
    },

    {
        name: "Quality Testing Listing",
        route: "/Transactions/TCottonBalesManagement/QualityTestingListing",
        component: <FrmQualityTestingListing />,
        header: true,
        footer: true,
        protected: false
    },


    //Cotton Bales Mixing Chart
    {
        type: "Transactions",
        name: "Bales Mixing Chart",
        route: "/Transactions/TCottonBalesManagement/TBalesMixingChart/FrmBalesMixingChartListing",
        component: <FrmBalesMixingChartListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TCottonBalesManagement/TBalesMixingChart/FrmBalesMixingChartEntry",
        component: <FrmBalesMixingChartEntry />,
        header: true,
        footer: true,
        protected: false
    },

    //Material Return to Stores
    {
        type: "Transactions",
        name: "Material Return to Stores",
        route: "/Transactions/TPurchaseOrder/MaterialReturnToStore/MaterialReturnToStoresListing",
        component: <MaterialReturnToStoresListing />,
        header: true,
        footer: true,
        protected: false
    },
    {
        route: "/Transactions/TPurchaseOrder/MaterialReturnToStore/FrmMaterialReturnToStoresEntry",
        component: <FrmMaterialReturnToStoresEntry />,
        header: true,
        footer: true,
        protected: false
    },

    // Cotton Bales WEIGHTAGE Report
    {
        type: "Registers",
        name: "Cotton Bales Weightage Report",
        route: "/Transactions/TPurchaseReports/CottonBalesWeightageReport",
        component: <FrmCottonBalesWeightageReport />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "Registers",
        name: "Bottom Stock Register",
        route: "Transactions/TPurchaseReports/BottomStockReport",
        component: <FrmBottomStockRegister />,
        header: true,
        footer: true,
        protected: false
    },


    // Cotton Bales Daily STock Issue Report
    {
        type: "Registers",
        name: "Cotton Bales Daily Stock Issue Report",
        route: "/Transactions/TPurchaseReports/CottonBalesDayWiseStockIssueReport",
        component: <FrmCottonBalesDayWiseStockIssueReport />,
        header: true,
        footer: true,
        protected: false
    },

    {
        type: "Purchase",
        name: "Cotton Bales Indent",
        route: "/Transactions/TPurchaseOrder/Indent/CottonBalesIndentListing/:requestfor",
        component: <CottonBalesIndentListing />,
        header: true,
        footer: true,
        protected: false
    },

];


export default routes;