import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from 'jquery';
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Select from 'react-select';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button, InputGroup } from "react-bootstrap";
import { RxCrossCircled } from "react-icons/rx";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { BiNews } from "react-icons/bi";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMProductCategory1Entry from "Masters/MProductCategory1/FrmProductCategory1Entry";
import FrmHSNSAC from "Masters/MHSN-SAC/FrmHSN-SAC";
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import FrmProductUnitEntry from "Masters/MProductUnit/FrmProductUnitEntry";
import FrmMProductCategory2Entry from "Masters/MProductCategory2/FrmMProductCategory2Entry";
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import { CircularProgress } from "@material-ui/core";
import GenerateMaterialId from "FrmGeneric/GenerateMaterialId/GenerateMaterialId";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";
import ProfitCenterEntry from "Masters/ProfitCenter/ProfitCenterEntry";
import FrmProductTypeDynamicControlsEntry from "Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsEntry";
import FrmTMaterialBeanCard from "Transactions/TMaterialBeanCard";

function FrmMRawCottonBaleEntry() {
  const configConstants = ConfigConstants();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_CATEGORY_COUNT, FINANCIAL_SHORT_NAME, UserId } = configConstants;
  const { productRmId = 0, keyForViewUpdate = 'Add', productTypeId = 12, productRmName, category1Id, compType = 'Masters', productId = 0 } = state || {}
  let docGroup = "Cotton Bales";

  // useRef hooks
  var validationRef = useRef();
  const comboDataAPiCall = useRef();
  var percentValidateRef = useRef();
  const generateMaterialIdAPiCall = useRef();

  // Combo Options
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [hsnSacCodeOptions, setProductHsnSacCodeOptions] = useState([])
  const [unitOptions, setProductUnitOptions] = useState([])
  const [profit_centerOptions, setProfitCenterOptions] = useState([]);
  const [cost_centerOptions, setCostCenterOptions] = useState([]);
  const [cost_centerHeadsOptions, setCostCenterHeadsOptions] = useState([]);
  const [category2Options, setCategory2Options] = useState([])

  //  Form Fields for General Entry
  const [actionType, setActionType] = useState('')
  const [product_id, setProduct_id] = useState(productId);
  const [product_rm_id, setProductRmId] = useState(productRmId);
  const [cmb_product_type_id, setProductTypeId] = useState(productTypeId)
  const [txt_rm_code, setRmCode] = useState('');
  const [txt_rm_name, setRmName] = useState(productRmName);
  const [txt_rm_short_name, setRmShortName] = useState('');
  const [cmb_rm_hsn_sac_code_id, setRmHsnSacCodeId] = useState('');
  const [cmb_rm_purchase_unit_id, setRmPurchaseUnitId] = useState(6);
  const [cmb_rm_sales_unit_id, setRmSalesUnitId] = useState(6);
  const [cmb_rm_stock_unit_id, setRmStockUnitId] = useState(6);
  const [txt_opening_qty, setCurrentStock] = useState(0);
  const [txt_opening_weight, setStockWeight] = useState(0);
  const [txt_remarkforGeneralEntry, setRemarkforGeneralEntry] = useState('');
  const [is_activeGen, setIsActiveGen] = useState(true);

  const [cmb_product_category2_id, setPCategory2Id] = useState(2);
  const [txt_product_rm_technical_name, setPTechnivalName] = useState('');
  const [product_rm_technical_id, setProductRmTechnicalId] = useState(0)

  // Product Type Properties Table Data
  const [productTypePropertiesData, setProductTypePropertiesData] = useState([]);

  //  Form Fields for Commercial Entry
  const [product_rm_commercial_id, setProductRmCommercialId] = useState(0)
  const [txt_routing_code, setRoutingCode] = useState("");
  const [txt_product_rm_std_weight, setStandardWeight] = useState(1);
  const [cmb_profit_center, setProfitCenter] = useState("");
  const [cmb_cost_center, setCostCenter] = useState("");
  const [cmb_cost_center_heads, setCostCenterHeads] = useState("");
  const [cost_center_heads, setCostCenterHeadsforRing] = useState('');

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  //For Delete Operation
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    moveToListing()
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // Loader
  const [isLoading, setIsLoading] = useState(false);

  const [showMaterialBeanCard, setShowMaterialBeanCard] = useState(false)
  const handleCloseMaterialBeanCard = () => setShowMaterialBeanCard(false);

  const clearFormFields = () => {
    // settxt_Mixing('');
  }
  // production Count form
  const [showProductionForm, setShowProductionForm] = useState(false);

  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        break;
      case 'view':
        setActionType('(View)');
        break;
      case 'delete':
        setActionType('(Delete)');
        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Product Type':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("product_type_short_name");
        globalQuery.columns.push("product_type_group");
        globalQuery.columns.push("group_code");
        globalQuery.table = "smv_product_type"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

        globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: 'PRM' });
        let product_Types = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        product_Types = product_Types.sort((a, b) => { if (a.field_name < b.field_name) { return -1; } return 1; });
        setProductTypeOptions(product_Types)
        break;

      case 'Product Category1':
        comboOnChangeforSearch('productType')
        break;

      case 'HSN-SAC':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "cmv_hsn_sac"
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const HsnSacCodeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        const updatedProducthsncodeOptions = updateKeysForSearchBox(HsnSacCodeApiCall);
        setProductHsnSacCodeOptions(updatedProducthsncodeOptions)
        break;

      case 'Purchase Unit':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "smv_product_unit"
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const PurchaseUnitApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setProductUnitOptions(PurchaseUnitApiCall)
        break;

      case 'Sales Unit':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "smv_product_unit"
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const SalesUnitApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setProductUnitOptions(SalesUnitApiCall)

        break;

      case 'Stock Unit':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "smv_product_unit"
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const StockUnitApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setProductUnitOptions(StockUnitApiCall)

        break;

      case 'Profit Center':
        try {
          resetGlobalQuery();
          globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
          globalQuery.table = "fmv_profit_center";
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          const getProfitCenterList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
          // const updatedProfitCenterOptions = updateKeysForSearchBoxProfitCenter(profitCenterList);
          const profitCenterOpts = [
            { value: '', label: 'Select', centerShortName: '' },
            { value: '0', label: 'Add New Record+', centerShortName: '' },
            ...getProfitCenterList.map((profitCenter) => ({ ...profitCenter, value: profitCenter.field_id, label: `[${profitCenter.profit_center_short_name}] ${profitCenter.field_name}`, centerShortName: profitCenter.cost_center_short_name, })),
          ];
          setProfitCenterOptions(profitCenterOpts)

        } catch (error) {
          console.log("error : ", error)
        }
        break;

      case 'Cost Center':
        try {
          resetGlobalQuery();
          globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
          globalQuery.table = "fmv_cost_center";
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          const costCenterList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
          // const updatedCostCenterOptions = updateKeysForSearchBox(costCenterList);
          const costCenterOpts = [
            { value: '', label: 'Select', centerShortName: '' },
            { value: '0', label: 'Add New Record+', centerShortName: '' },
            ...costCenterList.map((costcenter) => ({ ...costcenter, value: costcenter.field_id, label: `[${costcenter.cost_center_short_name}] ${costcenter.field_name}`, centerShortName: costcenter.cost_center_short_name, })),
          ];
          setCostCenterOptions(costCenterOpts);

        } catch (error) {
          console.log("error : ", error)
        }
        break;

      case 'Cost Center Heads':
        try {
          resetGlobalQuery();
          globalQuery.columns = ['field_id', 'field_name', 'cost_center_heads_short_name'];
          globalQuery.table = "fmv_cost_center_heads";
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          const costCenterheadsList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
          const updatedCostCenterHeadsOptions = updateKeysForSearchBoxCostCenterHeads(costCenterheadsList);
          setCostCenterHeadsOptions(updatedCostCenterHeadsOptions)

        } catch (error) {
          console.log("error : ", error)
        }
        break;

      case 'Product Properties':
        fnGetProductProperties(cmb_product_type_id, cmb_product_category2_id);
        break;

      default:
        break;

    }

    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  // Success Msg HANDLING
  const handleCloseProdSuccessModal = () => {
    setShowProdSuccessMsgModal(false);
  }
  const [showProdSuccessMsgModal, setShowProdSuccessMsgModal] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    ActionType()
    await fillCombos();
    if (productRmId !== 0 && productRmId !== undefined) {
      await FnCheckUpdateResponceforGeneralEntry();
    }
    setIsLoading(false);
  }, [])

    ;
  const fillCombos = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.columns.push("product_type_short_name");
      globalQuery.columns.push("product_type_group");
      globalQuery.columns.push("group_code");

      globalQuery.table = "smv_product_type"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: 'PRM' });

      comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        .then((getProductTypes) => {
          getProductTypes = getProductTypes.sort((a, b) => { if (a.field_name < b.field_name) { return -1; } return 1; });
          setProductTypeOptions(getProductTypes)
          if (keyForViewUpdate === "" || keyForViewUpdate === "Add") {
            setProductTypeId(12)
            comboOnChangeforSearch('productType');
          }
        })


      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_hsn_sac"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "hsn_sac_type", operator: "=", value: "HSN" });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        .then((getHsnList) => {
          const updatedProducthsncodeOptions = updateKeysForSearchBox(getHsnList);
          setProductHsnSacCodeOptions(updatedProducthsncodeOptions)
        })

      // Product Unit options
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "smv_product_unit"
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const getUnitList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      setProductUnitOptions(getUnitList)

      // Category label display
      resetGlobalQuery();
      globalQuery.columns.push("property_value");
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'RMCategorySettings' });
      globalQuery.table = "amv_properties"
      var labelList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      // Display category labels
      if (labelList.length !== 0) {
        for (let count = 1; count < COMPANY_CATEGORY_COUNT; count++) {
          $('#cat' + count + 'Label').append(labelList[count].property_value)
        }
      } else {
        for (let count = 1; count <= COMPANY_CATEGORY_COUNT; count++) {
          $('#cat' + count + 'Label').append('RM Category ' + [count] + ' :')
          $('#cat' + count + 'Label').append('RM Category ' + [count] + ' :')
        }
      }

      // Category 2 list (Cotton Indegenous)
      resetGlobalQuery();
      //product_category1_id = 1 and product_type_id =12 
      globalQuery.columns = ["product_category2_id", "product_category2_name", "product_category2_short_name"];
      globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: 12 });
      globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: 1 });
      globalQuery.table = "smv_product_category2"
      var productCategory2ApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      const productCat2Opts = [
        ...productCategory2ApiCall.map(item => ({
          value: item.product_category2_id,
          label: item.product_category2_name,
          short_name: item.product_category2_short_name,
        }))
      ];
      setCategory2Options(productCat2Opts)
      category2ComboRef.current = productCat2Opts.find((prdCat) => prdCat.value == cmb_product_category2_id);
      comboOnChangeforSearch('productCategory2', cmb_product_category2_id);

      //Costcenter List
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
      globalQuery.table = "fmv_cost_center";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      let getCostCenterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

      const getCostCenterOpts = [
        { value: '', label: 'Select', centerShortName: '' },
        { value: '0', label: 'Add New Record+', centerShortName: '' },
        ...getCostCenterList.map((costcenter) => ({ ...costcenter, value: costcenter.field_id, label: `[${costcenter.cost_center_short_name}] ${costcenter.field_name}`, centerShortName: costcenter.cost_center_short_name, })),
      ];
      setCostCenterOptions(getCostCenterOpts)
      costCenterComboRef.current.value = 7
      setCostCenter(parseInt(7))
      await comboOnChangeforSearch("cost_center",7)

      //Costcenter heads List 
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'cost_center_heads_short_name'];
      globalQuery.table = "fmv_cost_center_heads";
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const costCenterheadsList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
      const updatedCostCenterHeadsOptions = updateKeysForSearchBoxCostCenterHeads(costCenterheadsList);
      setCostCenterHeadsOptions(updatedCostCenterHeadsOptions)

      ///Profitcenter List
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
      globalQuery.table = "fmv_profit_center";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        .then((getProfitCenterList) => {
          const profitCenterOpts = [
            { value: '', label: 'Select', centerShortName: '' },
            { value: '0', label: 'Add New Record+', centerShortName: '' },
            ...getProfitCenterList.map((profitCenter) => ({ ...profitCenter, value: profitCenter.field_id, label: `[${profitCenter.profit_center_short_name}] ${profitCenter.field_name}`, centerShortName: profitCenter.profit_center_short_name, })),
          ];
          setProfitCenterOptions(profitCenterOpts)
        })

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const FnCheckUpdateResponceforGeneralEntry = async () => {
    try {
      debugger
      const apiCallPromise = fetch(`${process.env.REACT_APP_BASE_URL}/api/productRm/FnShowAllProductRmSummaryDetailsAndMasterRecords/${product_rm_id}/${COMPANY_ID}`)
        .then(response => {
          console.timeEnd("apiCallPromise"); // Log time when response is received
          return response.json();
        });

      const preLoadPropertiesDataPromise = comboOnChangeforSearch('productCategory2', 2)
        .then(result => {
          console.timeEnd("preLoadPropertiesDataPromise"); // Log time when result is received
          return result;
        });

      const [updateRes, preLoadPropertiesData] = await Promise.all([apiCallPromise, preLoadPropertiesDataPromise]);

      let generalEntryData = updateRes.ProductRmDetailsRecords;
      let technicalEntryData = updateRes.ProductRmmaterialTechnicalRecords;
      let commercialEntryData = updateRes.ProductRmmaterialCommercialRecords;
      let propertiesData = updateRes.AdditionalParametersData

      // General Entry Data
      let { product_id, product_rm_code,
        product_rm_name, product_rm_short_name, product_rm_hsn_sac_code_id,
        product_rm_purchase_unit_id, product_rm_sales_unit_id, product_rm_stock_unit_id, opening_qty, opening_weight, remark, is_active } = generalEntryData;
      setProduct_id(product_id)
      setRmCode(product_rm_code)
      setRmName(product_rm_name)
      setRmShortName(product_rm_short_name)
      setRmHsnSacCodeId(product_rm_hsn_sac_code_id)
      setRmPurchaseUnitId(product_rm_purchase_unit_id)
      setRmSalesUnitId(product_rm_sales_unit_id)
      setRmStockUnitId(product_rm_stock_unit_id)
      setCurrentStock(opening_qty)
      setStockWeight(opening_weight)
      setRemarkforGeneralEntry(remark)
      setIsActiveGen(is_active)

      // Commercial Data Entry
      let { product_rm_commercial_id, profit_center_id, cost_center_id, cost_center_heads_id, routing_code, product_rm_std_weight } = commercialEntryData;
      setProductRmCommercialId(product_rm_commercial_id)
      setStandardWeight(product_rm_std_weight);
      setProfitCenter(profit_center_id)
      costCenterComboRef.current.value = parseInt(cost_center_id);
      setCostCenter(parseInt(cost_center_id))
      setCostCenterHeads(cost_center_heads_id)
      setRoutingCode(routing_code)

      //Technical Entry Data
      let { product_category2_id, product_rm_technical_id, product_rm_technical_name } = technicalEntryData;
      setPCategory2Id(product_category2_id)
      setProductRmTechnicalId(product_rm_technical_id)
      setPTechnivalName(product_rm_technical_name)

      //Properties Data
      if (product_category2_id !== 0) {
        setPCategory2Id(parseInt(product_category2_id))
        category2ComboRef.current.value = parseInt(product_category2_id);
        await comboOnChangeforSearch('productCategory2', product_category2_id)
      }

      // Pre Load Properties
      if (preLoadPropertiesData !== undefined && preLoadPropertiesData.length !== 0) {

        const updatedData = [...preLoadPropertiesData];
        const uniqueDynamicControlIDs = new Set(); // Create a Set to store unique dynamic control IDs
        $("input:checkbox[name=selectProperty]").each(function () {
          const checkboxValue = parseInt($(this).val());
          const orderNoIndex = propertiesData.findIndex((item) => item.product_type_dynamic_controls_id === checkboxValue);
          if (orderNoIndex !== -1 && !uniqueDynamicControlIDs.has(checkboxValue)) {
            $(this).prop('checked', true);
            const propertyData = propertiesData.find((item) => item.product_type_dynamic_controls_id === checkboxValue);
            if (propertyData) {
              const propertyObjIndex = updatedData.findIndex((item) => item.product_type_dynamic_controls_id === checkboxValue);
              // Update updatedData only if the dynamic control ID is unique
              uniqueDynamicControlIDs.add(checkboxValue); // Add dynamic control ID to the Set
              updatedData[propertyObjIndex] = {
                ...updatedData[propertyObjIndex],
                product_parameter_id: propertiesData[orderNoIndex].product_parameter_id,
                product_parameter_calculation_type: propertiesData[orderNoIndex].product_parameter_calculation_type,
                product_parameter_from_value: propertiesData[orderNoIndex].product_parameter_from_value,
                product_parameter_to_value: propertiesData[orderNoIndex].product_parameter_to_value,
                product_parameter_formula: propertiesData[orderNoIndex].product_parameter_formula,
                remark: propertiesData[orderNoIndex].remark,
              };
            }
          }
        });

        // Filter out undefined elements and duplicates
        const filteredUpdatedData = updatedData.filter(obj => obj !== undefined);
        setProductTypePropertiesData(filteredUpdatedData);

        // Check if all checkboxes are selected
        const allChecked = $("input:checkbox[name=selectProperty]").length === $("input:checkbox[name=selectProperty]:checked").length;
        $("#selectAllProperties").prop('checked', allChecked); // Update the "Select All" checkbox
      }

      switch (keyForViewUpdate) {
        case 'update':
          $('#product_rm_id').attr('readOnly', true)
          // $('#txt_opening_qty, #txt_opening_weight').attr('disabled', true);
          $('#txt_rm_name').attr('readOnly', true)
          $('#txt_product_rm_technical_name').attr('readOnly', true)
          $('#cmb_product_type_id').attr('disabled', true)
          $('#cmb_product_category2_id').attr('disabled', true)
          $('#txt_product_rm_technical_name').attr('readOnly', true)
          $('#txt_rm_short_name').attr('readOnly', true)
          // $('#btn_save').text("Update");
          break;

        case 'view':
          $("#btn_update").attr('disabled', true);

          $("input[type=radio]").attr('disabled', true);
          $('#btn_save').hide();
          await addReadOnlyAttr();
          break;

        case 'delete':
          $('#btn_save').hide();
          $('#btn_doc_upload').hide();
          break;
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }


  const FnGenerateMaterialId = async (productTpVal, ptShortName) => {
    const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateCode("sm_product_rm", "product_rm_id", 'product_type_id', productTpVal, ptShortName, "5", "");
    setProductRmId(autoNoApiCall);
    return autoNoApiCall;
  }

  const FnGenerateMaterialCode = async () => {

    let fieldNameLastTrnNo = 'product_rm_code'
    let trnNoAICodelen = 4;   // means last no 0001

    const selectedProductCat2 = category2ComboRef.current
    const selectedProductCat2ShortName = selectedProductCat2.short_name || '';

    if (selectedProductCat2ShortName !== '') {
      resetGlobalQuery();
      globalQuery.columns.push(`COALESCE(MAX(SUBSTRING(${fieldNameLastTrnNo}, LENGTH(${fieldNameLastTrnNo}) - ${trnNoAICodelen} + 1, ${trnNoAICodelen}))+1, 1) As nextAINo`);
      globalQuery.table = "smv_product_rm_summary"
      globalQuery.conditions = [
        // { field: "product_type_id", operator: "=", value: cmb_product_type_id },
        { field: "product_category1_id", operator: "=", value: 1 },
        { field: "product_category2_id", operator: "=", value: selectedProductCat2.value },
        { field: "is_delete", operator: "=", value: 0 },
        // { field: "company_id", operator: "=", value: COMPANY_ID }
      ];
      const getProductRmCode = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      if (getProductRmCode.length > 0) {
        let latestAINo = (getProductRmCode[0]?.nextAINo || '').toString();
        let reqZerosCount = trnNoAICodelen - latestAINo.length;
        let paddedAINo = latestAINo.padStart(reqZerosCount + latestAINo.length, '0');  // Prepend the required number of zeros
        let generatedMatCodeArr = ["01", selectedProductCat2ShortName, paddedAINo]; // 01 for Raw Material
        setRmCode(generatedMatCodeArr.join(''))
        return generatedMatCodeArr.join('');
      } else {
        setRmCode('')
        return '';
      }
    } else {
      setRmCode('')
      return '';
    }
  }

  const FnGenerateTechSpecName = async () => {

    if (keyForViewUpdate === '') {
      let productTypeShortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName');
      let productTpText = productTypeShortName !== null && productTypeShortName !== undefined ? productTypeShortName : "";
      let productCat2Text = category2ComboRef.current?.label;
      let material_code = document.getElementById("txt_rm_code").value;
      let material_name = document.getElementById("txt_rm_name").value;
      const autoTextApiCall = await generateMaterialIdAPiCall.current.GenerateTechnicalSpecName(productTpText, productCat2Text, material_code, material_name);
      setPTechnivalName(autoTextApiCall);
    }

  }

  // blank objects for combosearch 
  const blankComboObject = () => {
    const Opts = [
      { value: '', label: 'Select', short_name: '' },
      { value: '0', label: 'Add New Record+', short_name: '' },
    ];
    return Opts;
  }

  const comboOnChangeforSearch = async (key, selectedValue) => {
    try {

      // Set options for ProductCategory1
      const Options = blankComboObject();
      switch (key) {

        case 'productType':

          var productTpVal = document.getElementById('cmb_product_type_id').value;
          setCategory2Options(Options)
          setProductTypePropertiesData([])
          setProductTypeId(productTpVal)
          if (productTpVal === '0') {
            setProductTypeId(productTpVal)
            setProductRmId(0)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Type')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({
                "padding-top": "0px"
              });
            }, 200)
          } else {
            if (productTpVal !== "") {
              $('#error_cmb_product_type_id').hide();
              const shortName = document.getElementById("cmb_product_type_id").selectedOptions[0].getAttribute('ptShortName');
              localStorage.setItem('ptShortName', shortName)
              if (productRmId === 0 || productRmId === undefined) {
                await FnGenerateMaterialId(productTpVal, shortName);
              }

              setPCategory2Id('');
              category2ComboRef.current.value = '';

            } else {
              setProductRmId(0)
              setCategory2Options(Options)
            }

          }
          break;

        case 'hsnSacCode':
          var productHsnSacCode = selectedValue
          if (productHsnSacCode === '0') {
            setRmHsnSacCodeId(productHsnSacCode)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('HSN-SAC')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          } else {
            setRmHsnSacCodeId(productHsnSacCode)
            if (selectedValue !== "") { $('#error_cmb_rm_hsn_sac_code_id').hide(); }
          }
          break;


        case 'productCategory2':
          let productCat2Val = selectedValue;
          setPCategory2Id(productCat2Val)
          if (productCat2Val === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category-2')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
          } else {

            if (keyForViewUpdate === "") {
              await FnGenerateMaterialCode();
            }

            var productTpValue = document.getElementById('cmb_product_type_id').value;
            const returnProductPropertiesData = fnGetProductProperties(productTpValue, productCat2Val);
            return returnProductPropertiesData;
          }

          break;

        case 'profit_center':
          const profitcenter = selectedValue
          setProfitCenter(profitcenter)
          $('#error_cmb_profit_center').hide();
          if (profitcenter === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Profit Center')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }
          FnCreateRoutingText()
          break;

        case 'cost_center':
          const costcenter = selectedValue
          setCostCenter(costcenter)
          $('#error_cmb_cost_center').hide();
          $('#error_cmb_profit_center').hide();
          if (costcenter === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Cost Center')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }

          FnCreateRoutingText()
          // }
          break;


        case 'cost_center_heads':
          const costcenterheads = selectedValue;
          setCostCenterHeads(costcenterheads)
          $('#error_cmb_cost_center_heads').hide();
          $('#error_cmb_profit_center').hide();
          if (costcenterheads === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Cost Center')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }
          if (costcenterheads !== "" && costcenterheads !== "0") {
            if (cmb_profit_center !== "" && cmb_profit_center !== "0") {
              FnCreateRoutingText()
            } else {
              setCostCenterHeads("")
              $('#error_cmb_profit_center').text("Please Select atleast one...!")
              $('#error_cmb_profit_center').show();
            }
          } else {
            FnCreateRoutingText()
          }
          break;

      }

    } catch (error) {
      console.log("error: ", error);
      navigate('/Error')
    }
  }

  const updateKeysForSearchBoxCostCenterHeads = (apiData) => {
    const options = [
      { value: '', label: 'Select' },
      { value: '0', label: 'Add New Record+' },
      ...apiData.map(items => ({
        value: items.field_id,
        label: `[${items.cost_center_heads_short_name}] ${items.field_name}`,
        centerShortName: items.cost_center_heads_short_name,
      }))
    ];
    return options;
  }

  const updateKeysForSearchBox = (apiData) => {
    const options = [
      { value: '', label: 'Select' },
      { value: '0', label: 'Add New Record+' },
      ...apiData.map(items => ({
        value: items.field_id,
        label: items.field_name,

      }))
    ];
    return options;
  }



  const comboOnChange = async (key) => {
    try {
      switch (key) {
        case 'purchaseUnit':
          var productPurchaseUnitId = document.getElementById('cmb_rm_purchase_unit_id').value;
          setRmPurchaseUnitId(productPurchaseUnitId)
          if (productPurchaseUnitId !== "") { $('#error_cmb_rm_purchase_unit_id').hide(); }

          if (productPurchaseUnitId === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Purchase Unit')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          }
          break;

        case 'salesUnit':
          var productSalesUnitId = document.getElementById('cmb_rm_sales_unit_id').value;
          setRmSalesUnitId(productSalesUnitId)
          if (productSalesUnitId !== "") { $('#error_cmb_rm_sales_unit_id').hide(); }
          if (productSalesUnitId === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Sales Unit')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          }

          break;

        case 'stockUnit':
          var productStockUnitId = document.getElementById('cmb_rm_stock_unit_id').value;
          setRmStockUnitId(productStockUnitId)
          if (productStockUnitId !== "") { $('#error_cmb_rm_stock_unit_id').hide(); }

          if (productStockUnitId === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Stock Unit')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          }
          break;

        case 'cost_center':
          debugger
          // const costcenter = document.getElementById('cmb_cost_center').value;
          const costcenter = costCenterComboRef.current.value;
          setCostCenter(costcenter)
          $('#error_cmb_cost_center').hide();
          $('#error_cmb_profit_center').hide();
          if (costcenter === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Cost Center')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }
          if (costcenter !== "" && costcenter !== "0") {
            if (cmb_profit_center !== "" && cmb_profit_center !== "0") {
              FnCreateRoutingText()
            } else {
              setCostCenter("")
              $('#error_cmb_profit_center').text("Please Select Profit center...!")
              $('#error_cmb_profit_center').show();
            }
          } else {
            FnCreateRoutingText()
          }
          break;
      }

    } catch (error) {
      console.log("error: ", error);
      navigate('/Error')
    }
  }
  //chnges by ujjwala for search on 2/5/2024
  const FnCreateRoutingText = () => {

    let routingText = "";
    let selectedProfitCenter = profit_centerOptions.find(option => option.value === profitCenterComboRef.current.value);
    let profitcenterShortCode = selectedProfitCenter ? selectedProfitCenter.centerShortName : "";
    // let costcenter = document.getElementById("cmb_cost_center").options[document.getElementById("cmb_cost_center").selectedIndex].getAttribute("centerShortName");
    let selectedCostCenter = cost_centerOptions.find(option => option.value === costCenterComboRef.current.value);
    let costcenterShortCode = selectedCostCenter ? selectedCostCenter.centerShortName : "";
    let costcenterheads = cost_centerHeadsOptions.find(option => option.value === cost_center_heads)?.centerShortName;

    routingText += `${profitcenterShortCode}`;
    routingText += costcenterShortCode ? profitcenterShortCode ? `-${costcenterShortCode}` : `${costcenterShortCode}` : '';
    routingText += costcenterheads ? `-${costcenterheads}` : '';
    setRoutingCode(routingText);
  };

  const validateCheckboxesBeforeSave = () => {
    ;
    let isValid = true;

    productTypePropertiesData.forEach((row, index) => {
      const isChecked = document.getElementById(`selectProperty_${row.product_type_dynamic_controls_id}`).checked;

      // Check if any value in the row is filled
      const hasValue = row.product_parameter_calculation_type ||
        row.product_parameter_from_value || row.product_parameter_to_value ||
        row.remark;
      if (hasValue && !isChecked) {
        isValid = false;
      }
    });

    if (!isValid) {
      setErrMsg("Please check the checkbox for Product Based Properties.");
      setShowErrorMsgModal(true);
    }

    return isValid; // Return the validation result
  };

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry btn_disabled={true} />;

      case 'Product Category1':
        return <FrmMProductCategory1Entry product_type={cmb_product_type_id} btn_disabled={true} />;

      case 'Product Category-2':
        return <FrmMProductCategory2Entry product_type={cmb_product_type_id} btn_disabled={true} />

      case 'HSN-SAC':
        return <FrmHSNSAC btn_disabled={true} />

      case 'Purchase Unit':
        return <FrmProductUnitEntry closeAddModal={handleCloseRecModal} btn_disabled={true} />

      case 'Sales Unit':
        return <FrmProductUnitEntry closeAddModal={handleCloseRecModal} btn_disabled={true} />

      case 'Stock Unit':
        return <FrmProductUnitEntry closeAddModal={handleCloseRecModal} btn_disabled={true} />

      case 'Cost Center':
        return <MCostCenterEntry btn_disabled={true} />;

      case 'Profit Center':
        return <ProfitCenterEntry btn_disabled={true} />;

      case 'Product Properties':
        return <FrmProductTypeDynamicControlsEntry btn_disabled={true} />;

      default:
        return null;
    }
  }


  const handleGeneralEntryData = async (functionType) => {
    try {
      setIsLoading(true)
      let checkIsValidate = await validate();
      let lastMaterialId = product_rm_id;

      var productTpVal = document.getElementById('cmb_product_type_id').value;
      const shortName = document.getElementById("cmb_product_type_id").selectedOptions[0].getAttribute('ptShortName');
      localStorage.setItem('ptShortName', shortName)
      if (productRmId === 0 || productRmId === undefined) {
        lastMaterialId = await FnGenerateMaterialId(productTpVal, shortName);
      }
      var selectedOption = $('#cmb_product_type_id').find(":selected");
      var ptGroupAttributeValue = selectedOption.attr("ptGroup");

      let jsonTotalData = {
        'ProductRmData': {}, 'ProductRmTechnicalData': {}, 'ProductRmCommercialData': {},
        'ProductRmSupplierData': [], 'ProductRmProcessData': [], 'ProductQaMappingData': [], 'AdditionalParametersData': [], 'commonIds': {
          'company_id': COMPANY_ID, 'product_rm_id': lastMaterialId, 'product_id': productRmId !== 0 ? product_id : 0,
          'financial_year': FINANCIAL_SHORT_NAME,
          'product_type_group': ptGroupAttributeValue,
          'product_rm_std_weight': "",   //txt_product_rm_std_weight
          'product_category2_id': cmb_product_category2_id,
        }
        , 'saveKey': functionType
      }
      const isCheckboxValid = validateCheckboxesBeforeSave();
      // if (checkIsValidate) {
      if (checkIsValidate && isCheckboxValid) {
        // if (checkIsValidate === true) {
        const formData = new FormData();
        // For General Entry 
        if (functionType === 'generalEntry' || functionType === 'allRawMaterialData') {

          const generalEntryDATA = {
            product_id: productRmId !== 0 ? product_id : 0,
            product_category1_id: 1,
            product_type_id: cmb_product_type_id,
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_rm_id: lastMaterialId,
            product_rm_code: txt_rm_code,
            product_rm_name: txt_rm_name,
            product_rm_short_name: txt_rm_short_name,
            product_rm_hsn_sac_code_id: cmb_rm_hsn_sac_code_id,
            product_rm_purchase_unit_id: cmb_rm_purchase_unit_id,
            product_rm_sales_unit_id: cmb_rm_sales_unit_id,
            product_rm_stock_unit_id: cmb_rm_stock_unit_id,
            opening_qty: txt_opening_qty == "" ? 0 : txt_opening_qty,
            opening_weight: txt_opening_weight == "" ? 0 : txt_opening_weight,
            remark: txt_remarkforGeneralEntry,
            created_by: productRmId === 0 ? UserName : null,
            modified_by: productRmId !== 0 ? UserName : null,
            is_active: is_activeGen,
            // actual_count: "", 
            // product_rm_print_name: "",
            // product_rm_item_sr_no: "",
            // product_rm_tech_spect: "",
            // product_rm_oem_part_code: "",
            // product_rm_our_part_code: "",
            // product_rm_drawing_no: "",
            // product_rm_model_no: "",
            product_rm_packing_id: 1,
            product_rm_bar_code: lastMaterialId,
            product_rm_qr_code: "",
            product_consumption_mode: "",  // Not Null = v (true)
            godown_id: 0,
            godown_section_id: 0,
            godown_section_beans_id: 0,
            product_origin_type: "Indigenous",
            product_origin_country: "India",   // cmb_origin_country,
            bom_applicable: "No"
          };
          jsonTotalData.ProductRmData = generalEntryDATA;
          formData.append(`qrCodeFile`, "") //file_rm_qr_code
        }

        //For Technical Entry
        if (functionType === 'technicalEntry' || functionType === 'allRawMaterialData') {
          const technicalEntryDATA = {
            product_rm_technical_id: product_rm_technical_id,
            product_rm_technical_name: txt_product_rm_technical_name,
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_rm_id: lastMaterialId,
            product_category2_id: cmb_product_category2_id,
            remark: txt_remarkforGeneralEntry, // null
            is_active: is_activeGen,
            created_by: productRmId === 0 ? UserName : null,
            modified_by: productRmId !== 0 ? UserName : null,
            product_category3_id: 0,   //cmb_product_category3_id,
            product_category4_id: 0,   //cmb_product_category4_id,
            product_category5_id: 0,   // cmb_product_category5_id,
            product_make_id: 0,   // cmb_product_make_id,
            product_material_type_id: 0,   //cmb_product_material_type_id,
            product_material_grade_id: 0,   // cmb_product_material_grade_id,
            product_material_shape_id: 0,   // cmb_product_material_shape_id,
            product_material_colour: 0,   //cmb_product_material_colour,
            product_alternate_rm_id: "0",   //cmb_product_alternate_rm_id,
            assembly_scrap_percent: 0   // txt_assembly_scrap_percent,
          };
          jsonTotalData.ProductRmTechnicalData = technicalEntryDATA;
        }

        // For Commercial Entry
        if (functionType === 'commercialEntry' || functionType === 'allRawMaterialData') {
          const commercialEntryDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_rm_commercial_id: productRmId !== 0 ? 0 : product_rm_commercial_id,
            product_rm_id: lastMaterialId,
            cost_center_id: cmb_cost_center,
            profit_center_id: cmb_profit_center,
            cost_center_heads_id: cmb_profit_center !== "" && cmb_profit_center !== "0" ? cmb_cost_center_heads : "",
            routing_code: txt_routing_code,
            remark: txt_remarkforGeneralEntry,
            purchase_tolerance_percent: 0,
            is_active: is_activeGen,
            created_by: productRmId === 0 ? UserName : null,
            modified_by: productRmId !== null ? UserName : null,
            product_rm_gross_weight: 0,   //txt_product_rm_gross_weight,
            product_rm_net_weight: 0,   //txt_product_rm_net_weight,
            product_rm_std_weight: parseInt(txt_product_rm_std_weight),   //txt_product_rm_std_weight,
            product_rm_volume: 0,   //txt_product_rm_volume,
            product_rm_mrp: 0,   //txt_product_rm_mrp,
            product_rm_landed_price: 0,   //txt_product_rm_landed_price,
            product_rm_avg_price: 0,   //txt_product_rm_avg_price,
            product_rm_std_profit_percent: 0,   //txt_product_rm_std_profit_percent,
            product_rm_std_discount_percent: 0,   //txt_product_rm_std_discount_percent,
            lead_time: 1,   //txt_lead_time,
            product_rm_moq: 0,   //txt_product_rm_moq,
            product_rm_mov: 0,   //txt_product_rm_mov,
            product_rm_eoq: 0,   //txt_product_rm_eoq,
            product_rm_mpq: 0,   //txt_product_rm_mpq,
            product_rm_min_stock_level: 0,   // txt_product_rm_min_stock_level,
            product_rm_max_stock_level: 0,   //txt_product_rm_max_stock_level,
            product_rm_reorder_stock_level: 0,   //txt_product_rm_reorder_stock_level,
            product_rm_depriciation_percent: 0,   //txt_product_rm_depriciation_percent,
            product_rm_price_type: '',   //is_priceType,
            product_rm_abc_analysis: "",   //is_abcAnalysis,
            qa_required: 0,   //is_qaRequired,
            test_certificate_required: 0   //is_testCertified,

          };
          jsonTotalData.ProductRmCommercialData = commercialEntryDATA;
        }
        if (functionType === 'allRawMaterialData') {

          // Trans Properties Data Data
          $("input:checkbox[name=selectProperty]:checked").each(function () {

            let findPropertyData = productTypePropertiesData.find(item => item.product_type_dynamic_controls_id === parseInt($(this).val()));
            const propertyData = {
              company_id: COMPANY_ID,
              company_branch_id: COMPANY_BRANCH_ID,
              product_parameter_id: 0,
              product_type_id: cmb_product_type_id,
              product_id: lastMaterialId,
              product_parameter_name: findPropertyData.control_name,
              // product_parameter_value: findPropertyData.product_parameter_value,
              control_master: findPropertyData.newFilter.control_master,
              // product_parameter_prefix: findPropertyData.product_parameter_prefix,
              // product_parameter_sufix: findPropertyData.product_parameter_sufix,
              product_parameter_calculation_type: findPropertyData.product_parameter_calculation_type,
              product_parameter_from_value: findPropertyData.product_parameter_from_value,
              product_parameter_to_value: findPropertyData.product_parameter_to_value,
              control_master: findPropertyData.control_master,
              product_type_dynamic_controls_id: parseInt($(this).val()),
              // product_parameter_formula: findPropertyData.product_parameter_formula,
              remark: findPropertyData.remark,
              created_by: keyForViewUpdate === "" ? UserName : findPropertyData.created_by,
              modified_by: keyForViewUpdate === "update" ? UserName : null,
            }
            jsonTotalData.AdditionalParametersData.push(propertyData);
          });
        }

        formData.append(`TransData`, JSON.stringify(jsonTotalData))

        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productRm/FnAddUpdateRecord`, requestOptions)
        const response = await apicall.json();
        if (response.success !== 1) {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)
        } else {
          if ((keyForViewUpdate === '' || keyForViewUpdate === 'add') && txt_opening_qty > 0) {
            setTimeout(() => {
              saveStockAdjustmentData(jsonTotalData.commonIds);
            }, 100);
          }
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          const { product_id, product_rm_id, product_rm_name, product_rm_qr_code } = response.data;
          setProduct_id(product_id)
          setProductRmId(product_rm_id)
          setRmName(product_rm_name)
          // setRmQrCodePath(product_rm_qr_code)
          setSuccMsg(response.message)
          setShowSuccessMsgModal(true)
        }

      }
      setIsLoading(false)

    } catch (error) {
      console.log("error: ", error);
      navigate('/Error')
    }
  }


  const saveStockAdjustmentData = async (commonId) => {
    try {
      //Current date
      const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
      }

      let { product_rm_id, product_type_group, product_rm_std_weight, product_category2_id } = commonId;

      let stockAdjustmentDetailsJson = {
        StockAdjustmentMaster: {}, StockAdjustmentDetails: [], KeyForAddUpdateStock: false,
        'commonIds': {
          'company_id': COMPANY_ID, stock_adjustment_transaction_id: 0, 'product_type_group': product_type_group,
          'product_rm_std_weight': product_rm_std_weight,
          'product_category2_id': product_category2_id,
        }
      }

      ///Stock Production Header Data
      const stockAdjustmentHeaderData = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        financial_year: FINANCIAL_SHORT_NAME,
        stock_adjustment_transaction_id: 0,
        stock_date: today(),
        adjustment_by: UserId,
        adjustment_reason: '',
        approved_by: UserId,
        created_by: UserName,
        modified_by: UserName
      }
      stockAdjustmentDetailsJson.StockAdjustmentMaster = stockAdjustmentHeaderData;

      let jsonStockAdjustmentDetails = {};
      jsonStockAdjustmentDetails.company_id = COMPANY_ID;
      jsonStockAdjustmentDetails.company_branch_id = COMPANY_BRANCH_ID;
      jsonStockAdjustmentDetails.financial_year = FINANCIAL_SHORT_NAME;
      jsonStockAdjustmentDetails.goods_receipt_no = "Opening Balance";
      jsonStockAdjustmentDetails.stock_adjustment_details_transaction_id = 0;
      jsonStockAdjustmentDetails.stock_adjustment_transaction_id = 0;
      jsonStockAdjustmentDetails.product_material_id = product_rm_id;
      jsonStockAdjustmentDetails.stock_adjustment_type = "Add";
      jsonStockAdjustmentDetails.stock_quantity = 0;
      jsonStockAdjustmentDetails.stock_weight = 0;
      jsonStockAdjustmentDetails.opening_quantity = 0;
      jsonStockAdjustmentDetails.opening_weight = 0;
      jsonStockAdjustmentDetails.stock_adjustment_quantity = txt_opening_qty;
      jsonStockAdjustmentDetails.stock_adjustment_weight = txt_opening_weight;
      jsonStockAdjustmentDetails.closing_balance_quantity = txt_opening_qty;
      jsonStockAdjustmentDetails.closing_balance_weight = txt_opening_weight;
      jsonStockAdjustmentDetails.stock_date = today();
      jsonStockAdjustmentDetails.created_by = UserName;
      jsonStockAdjustmentDetails.modified_by = UserName;
      jsonStockAdjustmentDetails.product_type_id = cmb_product_type_id;

      stockAdjustmentDetailsJson.StockAdjustmentDetails.push(jsonStockAdjustmentDetails);


      const formData = new FormData();
      formData.append(`requestStockAdjustment`, JSON.stringify(stockAdjustmentDetailsJson))
      const requestOptions = {
        method: 'POST',
        body: formData
      };

      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductStockAdjustment/FnAddUpdateRecord`, requestOptions)
    } catch (error) {
      console.log(error);

    }
  }

  async function DeleteRecord() {
    try {
      const method = { method: 'POST' }
      const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productRm/FnDeleteRecord/${product_rm_id}/${UserName}`, method)
      const responce = await deleteApiCall.json();
      if (responce.success == '1') {
        setShow(false)
        moveToListing();
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  // navigate to back listing page 
  const moveToListing = () => {
    let Path = '/Masters/MRawCottonBale/FrmMRawCottonBaleListing';
    navigate(Path);
  }

  const deleteshow = () => {
    setShow(true)
  }

  // Validations
  const validate = async () => {

    let hsnVal = cmb_rm_hsn_sac_code_id || '';
    if (hsnVal === "" || hsnVal === '0') {
      $("#error_cmb_rm_hsn_sac_code_id").text("Please select HSN SAC...!");
      $("#error_cmb_rm_hsn_sac_code_id").show();
      $("#cmb_rm_hsn_sac_code_id").focus();
      return false;
    }

    // Validate general entry form
    if (!(await validationRef.current.validateForm('generalEntryFormId'))) return false;

    let selectedProperties = $('#productProperties-table-id tbody tr .selectProperty:checked');
    let propertyIsValid = true;

    if (selectedProperties.length !== 0) {
      selectedProperties.each(function () {
        let currentTblRow = $(this.parentElement.parentElement);
        let selCalcType = currentTblRow.find('select[id^="product_parameter_calculation_type_"]')
        let fromValueInput = currentTblRow.find('input[id^="product_parameter_from_value_"]');
        let toValueInput = currentTblRow.find('input[id^="product_parameter_to_value_"]');

        let calcTypeValue = selCalcType.val()
        let fromValue = fromValueInput.val();
        let toValue = toValueInput.val();

        if (calcTypeValue === '') {
          selCalcType[0].parentElement.dataset.tip = 'Please Select calculation Type...!';
          selCalcType[0].focus();
          propertyIsValid = false;
          return false; // Exit the loop early
        } else {
          delete selCalcType[0].parentElement.dataset.tip;
        }
        if (fromValue === '') {
          fromValueInput[0].parentElement.dataset.tip = 'Please fill this field...!';
          fromValueInput[0].focus();
          propertyIsValid = false;
          return false; // Exit the loop early
        } else {
          delete fromValueInput[0].parentElement.dataset.tip;
        }
        if (toValue === '' && calcTypeValue === "Between") {
          toValueInput[0].parentElement.dataset.tip = 'Please fill this field...!';
          toValueInput[0].focus();
          propertyIsValid = false;
          return false; // Exit the loop early
        } else {
          delete toValueInput[0].parentElement.dataset.tip;
        }
        if (toValue !== '' && calcTypeValue !== "Between") {
          toValueInput[0].parentElement.dataset.tip = `Cannot enter To Value for ${calcTypeValue}`;
          selCalcType[0].focus();
          propertyIsValid = false;
          return false; // Exit the loop early
        } else {
          delete toValueInput[0].parentElement.dataset.tip;
        }
      });

      return propertyIsValid;
    }
    return true;
  };


  const validateFields = () => {

    validationRef.current.validateFieldsOnChange('generalEntryFormId');
  }

  const addReadOnlyAttr = async () => {
    await validationRef.current.readOnly('generalEntryFormId');
  }

  const validateNo = (key) => {
    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{5}$/
    const regexNo1 = /^[0-9]*\.[0-9]{3}$/
    const regexNo2 = /^[0-9]*\.[0-9]{0}$/
    const alphabetRegex = /^[A-Za-z]*$/;
    var dot = '.';
    switch (key) {
      case 'txt_opening_weight':
        var opening_weight = $('#txt_opening_weight').val();
        if (!regexNo.test(opening_weight) && opening_weight.length < 15 || opening_weight.indexOf(dot) === 14) {
          if (numCheck.test(opening_weight)) {
            setStockWeight(opening_weight)
          }
        }
        break;

      case 'txt_opening_qty':
        var opening_qty = $('#txt_opening_qty').val();
        let req_OpeningQty = percentValidateRef.current.decimalNumber(opening_qty, 4)
        setCurrentStock(req_OpeningQty);
        break;

    }
  }


  //-------------------------------------------------- Product Properties data Starts -----------------------------------------------------------------------------------------


  const fnGetProductProperties = async (product_type_id, product_category_id) => {

    resetGlobalQuery();
    globalQuery.columns.push("*");
    globalQuery.table = "sm_product_type_dynamic_controls"
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: product_type_id });
    globalQuery.conditions.push({ field: "product_category_id", operator: "=", value: product_category_id });
    const getProductProperties = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
    const modifyProductPropertiesData = [];
    if (getProductProperties.length !== 0) {
      for (let i = 0; i < getProductProperties.length; i++) {
        const propertiesItem = getProductProperties[i];
        let masterList;
        const initializeKeys = {
          control_master: propertiesItem.control_type === 'P' ? `amv_properties:${propertiesItem.control_master}` : propertiesItem.control_master,
          product_parameter_calculation_type: "",
          product_parameter_from_value: "",
          product_parameter_id: 0,
          product_parameter_to_value: "",
          remark: ""
        }
        const newFilter = {
          // id: propertiesItem.id,
          type: propertiesItem.control_type,
          dataArray: []    // new array for if there is combo 
        };
        if (propertiesItem.control_type === "P") {
          resetGlobalQuery();
          globalQuery.columns = ["field_name", "field_id", "property_value", "property_name_value"];
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: propertiesItem.control_master });
          globalQuery.orderBy = ["property_id"];
          globalQuery.table = "amv_properties"
          masterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
          newFilter.dataArray = masterList
        }
        if (propertiesItem.control_type === "C") {
          // masterList = await comboDataAPiCall.current.fillMasterData(propertiesItem.control_master, "", "")
          if (propertiesItem.control_master === "xmv_spinning_prod_count") {
            resetGlobalQuery();
            globalQuery.columns = ["field_name", "field_id", "production_actual_count"];
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.table = "xmv_spinning_prod_count"
            masterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            newFilter.dataArray = masterList
          } else {
            masterList = await comboDataAPiCall.current.fillMasterData(propertiesItem.control_master, "", "")
          }
          newFilter.dataArray = masterList
        }
        modifyProductPropertiesData.push({ ...getProductProperties[i], ...initializeKeys, newFilter })
      }
      if (modalHeaderName === "Product Properties") {
        setProductTypePropertiesData((prevArray) => {

          const newProperties = [
            ...prevArray,
            ...modifyProductPropertiesData.filter(item => !prevArray.some(prevItem => prevItem.product_type_dynamic_controls_id === item.product_type_dynamic_controls_id))
          ];
          return newProperties;
        })
      } else {
        setProductTypePropertiesData(modifyProductPropertiesData)
      }
    } else {
      setProductTypePropertiesData([])

    }
    return modifyProductPropertiesData;
  }

  const renderProductPropertiesTbl = useMemo(() => {
    return <>
      <Table className="erp_table" id='productProperties-table-id' responsive bordered striped erp_table_scroll>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Action</th>
            <th className='erp_table_th'>Sr. No.</th>
            <th className="erp_table_th">Parameter Name</th>
            <th className="erp_table_th">Calculation Type</th>
            <th className="erp_table_th">From Value</th>
            <th className="erp_table_th">To Value</th>
            <th className="erp_table_th">Remark</th>
          </tr>
        </thead>
        <tbody>
          {productTypePropertiesData.map((filter, index) => (
            <tr rowIndex={index}>
              <td className="erp_table_td">
                <input type="checkbox" className="selectProperty" name="selectProperty" parameterName={filter.control_name} value={filter.product_type_dynamic_controls_id} id={'selectProperty_' + filter.product_type_dynamic_controls_id}
                  disabled={keyForViewUpdate === "view"} />
              </td>
              <td className="erp_table_td ">{index + 1}</td>
              <td className="erp_table_td ">{filter.control_name} </td>

              {/* Values */}

              <td className="erp_table_td ">
                <select id={`product_parameter_calculation_type_${index}`} className="form-select form-select-sm" value={filter.product_parameter_calculation_type}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} Headers='product_parameter_calculation_type'
                  disabled={keyForViewUpdate === "view"}
                >
                  <option value=''>Select</option>
                  <option value='Equals'>Equals</option>
                  <option value='Less than'>Less than</option>
                  <option value='Greater than'>Greater than</option>
                  <option value='Between'>Between</option>
                </select>
              </td>
              <td className="erp_table_td ">
                <input type="text"
                  className="erp_input_field mb-0" Headers='product_parameter_from_value'
                  id={`product_parameter_from_value_${index}`}
                  value={filter.product_parameter_from_value} disabled={keyForViewUpdate === "view"}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} />
              </td>

              <td className="erp_table_td  ">
                <input
                  type="text"
                  className="erp_input_field mb-0 "
                  Headers="product_parameter_to_value"
                  id={`product_parameter_to_value_${index}`}
                  value={filter.product_parameter_to_value} disabled={keyForViewUpdate === "view"}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} />
              </td>
              <td className="erp_table_td  ">
                <input type="text" className="erp_input_field mb-0 "
                  Headers="remark" id={`remark_${index}`} value={filter.remark} disabled={keyForViewUpdate === "view"}
                  onChange={(e) => { updatePropertiesTblRows(filter, e); }} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  }, [productTypePropertiesData]);

  const updatePropertiesTblRows = (rowData, event) => {

    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'));
    let selCalcType = $(`select[id^="product_parameter_calculation_type_${arrayIndex}"]`)
    let fromValue = $(`input[id^="product_parameter_from_value_${arrayIndex}"]`).val();
    let toValueInput = $(`input[id^="product_parameter_to_value_${arrayIndex}"]`);
    let calcTypeValue = selCalcType.val()
    let toValue = toValueInput.val();

    if (calcTypeValue === "" && (fromValue !== "" || toValue !== "")) {
      selCalcType.parent().attr("data-tip", "Please Select calculation Type...!");
    } else {
      selCalcType.parent().removeAttr("data-tip");
    }
    if (calcTypeValue !== "Between" && toValue === "") {
      toValueInput.parent().removeAttr("data-tip");
    }
    let clickedColName = event.target.getAttribute('Headers');
    let enteredValue = event.target.value;

    // Update the row data based on the column
    switch (clickedColName) {
      case 'product_parameter_calculation_type':
      case 'product_parameter_from_value':
      case 'product_parameter_to_value':
      case 'remark':
        rowData[clickedColName] = enteredValue;
        break;
    }

    // Automatically check/uncheck the checkbox based on the entered value
    const checkbox = document.getElementById(`selectProperty_${rowData.product_type_dynamic_controls_id}`);
    const hasValue =
      rowData.product_parameter_calculation_type || rowData.product_parameter_from_value ||
      rowData.product_parameter_to_value || rowData.remark;

    checkbox.checked = hasValue ? true : false;

    // Update the state with the modified row data
    const detailData = [...productTypePropertiesData];
    detailData[arrayIndex] = rowData;
    setProductTypePropertiesData(detailData);
  };
  // Onchange event listener for the POTerm Selection
  $('.selectProperty').on('change', function (e) {
    const rowIndex = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))

    toggleChkAllBoxes('PartiallyPropertiesSelection');
  });

  //function to check All checkBoxes of po terms and payment terms
  function toggleChkAllBoxes(key) {

    switch (key) {
      case "selectAllProperties":
        $('.selectProperty').prop('checked', $('#selectAllProperties').is(":checked"));
        break;
      case 'PartiallyPropertiesSelection':
        $('#selectAllProperties').prop('checked', $('input:checkbox.selectProperty:checked').length == $('input:checkbox.selectProperty').length);
        break;
    }
  }

  const options = [
    { value: ' ', label: 'Select', },
    { value: '0', label: 'Add New Record+', },

    ...category2Options.map(category2 => ({
      value: category2.field_id,
      label: category2.field_name,

    })),

    ...hsnSacCodeOptions.map(HsnSacCode => ({
      value: HsnSacCode.field_id,
      label: HsnSacCode.field_name,
    })),

    ...profit_centerOptions.map(profitCenter => ({
      value: profitCenter.field_id,
      label: `[${profitCenter.profit_center_short_name}] ${profitCenter.field_name}`,
      centerShortName: profitCenter.profit_center_short_name,
    })),


    ...cost_centerHeadsOptions.map(costCenterHeads => ({
      value: costCenterHeads.field_id,
      label: `[${costCenterHeads.cost_center_heads_short_name}] ${costCenterHeads.field_name}`,
      centerShortName: costCenterHeads.cost_center_heads_short_name,
    })),

  ];


  const handleChange = async (selectedOption, selectId) => {
    switch (selectId) {

      case 'cmb_rm_hsn_sac_code_id':
        if (cmb_rm_hsn_sac_code_id !== '0' && cmb_rm_hsn_sac_code_id !== '') {
          $(`#error_cmb_rm_hsn_sac_code_id`).hide();
        }
        setRmHsnSacCodeId(selectedOption.value);
        await comboOnChangeforSearch('hsnSacCode', selectedOption.value);
        break;

      default:
        break;
    }
  };

  // userefs for search combo 
  let category2ComboRef = useRef(null);
  let profitCenterComboRef = useRef(null);
  let costCenterComboRef = useRef(null);
  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <FrmValidations ref={validationRef} />
      <ValidateNumberDateInput ref={percentValidateRef} />
      <GenerateMaterialId ref={generateMaterialIdAPiCall} />
      <DashboardLayout>
        <div className='main_heding'>
          <label className='erp-form-label-lg main_heding'>Raw Material (Cotton Bales) {actionType}</label>
        </div>

        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span id="spinner_text" className="text-dark">Loading...</span>
            </div>
          </div> :
          null}
        <form id="generalEntryFormId">
          <div className='row'>
            {/* first */}
            <div className='col-sm-4 erp_form_col_div'>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Product Type : <span className="required">*</span></Form.Label>
                </div>
                <div className='col'>
                  <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => comboOnChangeforSearch('productType')} disabled="disabled">
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {productTypeOptions?.map(productType => (
                      <option value={productType.field_id} ptShortName={productType.product_type_short_name}
                        ptGroup={productType.product_type_group}>{productType.field_name}</option>
                    ))}
                  </select>
                  <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row '>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Product Category 1 : <span className="required">*</span></Form.Label>
                </div>
                <div className='col'>
                  <Select ref={category2ComboRef}
                    inputId="cmb_product_category2_id"
                    value={category2Options.find(option => option.value === cmb_product_category2_id) || ''}
                    options={category2Options}
                    // isDisabled={keyForViewUpdate === '' || keyForViewUpdate === 'Add' ? false : true}
                    isDisabled={true}
                    onChange={(selectedOption) => {
                      setPCategory2Id(selectedOption.value)
                      category2ComboRef.current = selectedOption;
                      comboOnChangeforSearch('productCategory2', selectedOption.value);
                      validateFields();
                    }}
                    placeholder="Search for Product Category 1..."
                    className="form-search-custom"
                    classNamePrefix="custom-select"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      }),
                      input: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      })
                    }}
                  />

                  <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row d-none'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Material ID : <span className="required">*</span></Form.Label>
                </div>
                <div className='col'>
                  <Form.Control type="text" id="product_rm_id" className="erp_input_field" value={product_rm_id} maxLength="255" disabled="disabled" />
                  <MDTypography variant="button" id="error_product_rm_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Item Code : <span className="required">*</span></Form.Label>
                </div>
                <div className='col'>
                  <Form.Control type="text" id="txt_rm_code" className="erp_input_field"
                    value={txt_rm_code}
                    onChange={e => { setRmCode(e.target.value); validateFields(); }}
                    maxLength="255"
                    disabled />
                  <MDTypography variant="button" id="error_txt_rm_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Item Name : <span className="required">*</span></Form.Label>
                </div>
                <div className='col'>
                  <Form.Control as="textarea"
                    rows={2}
                    disabled={keyForViewUpdate !== ""}
                    className="erp_txt_area"
                    id="txt_rm_name"
                    value={txt_rm_name}
                    onChange={e => {
                      setRmName(e.target.value);
                      validateFields();
                      FnGenerateTechSpecName()
                    }}
                    maxLength="500" />
                  <MDTypography variant="button" id="error_txt_rm_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Short Name : </Form.Label>
                </div>
                <div className='col'>
                  <Form.Control type="text" id="txt_rm_short_name"
                    className="erp_input_field"
                    disabled={keyForViewUpdate !== ""}
                    value={txt_rm_short_name}
                    onChange={e => { setRmShortName(e.target.value.toUpperCase()); validateFields() }}
                    maxLength={50}
                    optional="optional" />
                  <MDTypography variant="button" id="error_txt_rm_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>



            </div>
            {/* second */}
            <div className='col-sm-4 erp_form_col_div'>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">HSN-SAC Code : <span className="required">*</span></Form.Label>
                </div>
                <div className='col'>
                  <Select
                    inputId="cmb_rm_hsn_sac_code_id"
                    value={hsnSacCodeOptions.find(option => option.value === cmb_rm_hsn_sac_code_id)}
                    options={hsnSacCodeOptions}
                    isDisabled={keyForViewUpdate === 'view' ? true : false}
                    onChange={(selectedOption) => handleChange(selectedOption, 'cmb_rm_hsn_sac_code_id')}
                    placeholder="Search for HSN-SAC Code..."
                    className="form-search-custom"
                    classNamePrefix="custom-select"

                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      }),
                      input: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      })
                    }}
                  />
                  <MDTypography variant="button" id="error_cmb_rm_hsn_sac_code_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Purchase Unit : </Form.Label>
                </div>
                <div className='col'>
                  <select id="cmb_rm_purchase_unit_id" className="form-select form-select-sm" value={cmb_rm_purchase_unit_id} onChange={() => comboOnChange('purchaseUnit')} optional='optional'>
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {unitOptions?.map(units => (
                      <option value={units.field_id}>{units.field_name}</option>
                    ))}
                  </select>
                  <MDTypography variant="button" id="error_cmb_rm_purchase_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Sales Unit : </Form.Label>
                </div>
                <div className='col'>
                  <select id="cmb_rm_sales_unit_id" className="form-select form-select-sm" value={cmb_rm_sales_unit_id} onChange={() => comboOnChange('salesUnit')} optional='optional'>
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {unitOptions?.map(units => (
                      <option value={units.field_id}>{units.field_name}</option>
                    ))}
                  </select>
                  <MDTypography variant="button" id="error_cmb_rm_sales_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Stock Unit : <span className="required">*</span></Form.Label>
                </div>
                <div className='col'>
                  <select id="cmb_rm_stock_unit_id" className="form-select form-select-sm" value={cmb_rm_stock_unit_id} onChange={() => comboOnChange('stockUnit')}>
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {unitOptions?.map(units => (
                      <option value={units.field_id}>{units.field_name}</option>
                    ))}
                  </select>
                  <MDTypography variant="button" id="error_cmb_rm_stock_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Profit Center :</Form.Label>
                </div>
                <div className="col">

                  <Select ref={profitCenterComboRef}
                    inputId="cmb_profit_center"
                    value={profit_centerOptions.find(option => option.value === cmb_profit_center || null)}
                    options={profit_centerOptions}
                    isDisabled={keyForViewUpdate === 'view' ? true : false}
                    onChange={(selectedOption) => {
                      setProfitCenter(selectedOption.value)
                      // handleChange(selectedOption, 'cmb_profit_center');
                      profitCenterComboRef.current = selectedOption;
                      comboOnChangeforSearch("profit_center", selectedOption.value)
                      validateFields();
                    }}
                    placeholder="Search for Profit Center..."
                    className="form-search-custom"
                    classNamePrefix="custom-select"


                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      }),
                      input: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      })
                    }}
                  />

                  <MDTypography variant="button" id="error_cmb_profit_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Cost Center:</Form.Label>
                </div>
                <div className="col">
                  <Select ref={costCenterComboRef}
                    inputId="cmb_cost_center"
                    isDisabled={keyForViewUpdate === 'view'}
                    value={cost_centerOptions.find(option => option.value === cmb_cost_center) || null}
                    options={cost_centerOptions}
                    onChange={(selectedOption) => {
                      // handleChange(selectedOption, 'cmb_cost_center');
                      setCostCenter(selectedOption.value)
                      costCenterComboRef.current = selectedOption;
                      comboOnChangeforSearch("cost_center", selectedOption.value)
                      validateFields();
                    }
                    }
                    placeholder="Search for Cost Center..."
                    className="form-search-custom"
                    classNamePrefix="custom-select"

                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      }),
                      input: (provided, state) => ({
                        ...provided,
                        fontSize: '12px'
                      })
                    }}
                  />

                  <MDTypography variant="button" id="error_cmb_cost_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

            </div>
            {/* Third */}
            <div className='col-sm-4 erp_form_col_div'>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Routing Code : </Form.Label>
                </div>
                <div className='col'>
                  <Form.Control disabled={keyForViewUpdate !== ""} type="text" id="txt_routing_code" className="erp_input_field " value={txt_routing_code} optional="optional" />
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Standard Weight : </Form.Label>
                </div>
                <div className='col'>
                  <Form.Control disabled={['view'].includes(keyForViewUpdate)} type="text" id="txt_product_rm_std_weight" className="erp_input_field " value={txt_product_rm_std_weight} onChange={(e) => setStandardWeight(e.target.value)} />
                </div>
              </div>

              <div className='row d-none'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Current Stock :</Form.Label>
                </div>
                <div className={productRmId == 0 ? 'col-sm-8' : 'col-sm-7'}>
                  <Form.Control type="text" id="txt_opening_qty" className="erp_input_field erp_align-right" value={txt_opening_qty} onChange={(e) => { validateNo('txt_opening_qty') }} optional="optional" disabled={keyForViewUpdate === "" ? false : true} />
                  <MDTypography variant="button" id="error_txt_opening_qty" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
                <div className='col-sm-1'>
                  <BiNews className={`${productRmId == '0' ? 'd-none' : 'display'}`} onClick={e => setShowMaterialBeanCard(true)} style={{ transform: 'translate(-4px, -4px)' }} />
                </div>
              </div>

              <div className='row d-none'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Stock Weight:</Form.Label>
                </div>
                <div className='col-sm-8'>
                  <Form.Control type="text" id="txt_opening_weight" className="erp_input_field erp_align-right" value={txt_opening_weight} onChange={e => { validateNo('txt_opening_weight') }} optional="optional" disabled={keyForViewUpdate === "" ? false : true} />
                  <MDTypography variant="button" id="error_txt_opening_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Remark : </Form.Label>
                </div>
                <div className='col'>
                  <Form.Control as="textarea" rows={3} id="txt_remark_general_entry" className="erp_txt_area" value={txt_remarkforGeneralEntry} onChange={e => { setRemarkforGeneralEntry(e.target.value); validateFields() }} maxLength="255" optional="optional" />
                  <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

            </div>
          </div>
        </form>

        <hr />

        <Accordion defaultActiveKey="0" className="mt-1" >
          <Accordion.Item eventKey="1">
            <Accordion.Header className="erp-form-label-md">Product Based Properties</Accordion.Header>
            <Accordion.Body>
              <>
                <div className={`row py-1 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} >
                  <div className={`col-12 col-sm-6`}>
                    <input type='checkbox' class={`selectAllProperties  ${productTypePropertiesData.length !== 0 ? 'display' : 'd-none'}`} id="selectAllProperties" onClick={(e) => toggleChkAllBoxes('selectAllProperties')} disabled={keyForViewUpdate === "view"} /> <label class={`erp-form-label pb-1   ${productTypePropertiesData.length !== 0 ? 'display' : 'd-none'}`}  > Select All </label>
                  </div>
                  <div className={`col-12 col-sm-6 ${cmb_product_type_id === '' || cmb_product_type_id === '0' ? 'd-none' : 'display'}`}>
                    <MDButton type="button" className={`erp-gb-button float-sm-end col-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                      onClick={() => {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Product Properties'); // CommonParamterEntry
                        setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                      }}>Add
                    </MDButton>
                  </div>
                </div>
                {productTypePropertiesData.length !== 0 ?
                  renderProductPropertiesTbl :
                  <div className='row text-center'>
                    <div className="col-12">
                      <span className="erp_validation text-center" fontWeight="regular" color="error">
                        {cmb_product_type_id === "" || cmb_product_type_id === "0" ? "Please Select Product Type...!" : "No records found..!"}
                      </span>
                    </div>
                  </div>}
              </>

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>


        <div className="erp_frm_Btns">

          <MDButton type="button" className="erp-gb-button text-center" id='btn_home'
            onClick={() => {
              let Path = '/Masters/MRawCottonBale/FrmMRawCottonBaleListing';
              navigate(Path);
            }}
            variant="button" fontWeight="regular">Back</MDButton>

          <MDButton type="button" id="btn_save" className={`erp-gb-button ms-2 ${keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`} variant="button"
            fontWeight="regular" onClick={() => handleGeneralEntryData('allRawMaterialData')} >{product_id !== 0 ? 'Update' : 'Save'}</MDButton>

        </div>

        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
        <SuccessModal handleCloseSuccessModal={() => handleCloseProdSuccessModal()} show={[showProdSuccessMsgModal, succMsg]} />

        {/* Add new Record Popup */}
        {
          showAddRecModal ? <>
            <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
              <Modal.Body className='erp_city_modal_body'>
                <div className='row'>
                  <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                </div>
                {displayRecordComponent()}
              </Modal.Body>
            </Modal >
          </> : null
        }

        {/* Delete Modal */}
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
          <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
          <Modal.Body className='erp_modal_body'>
            <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
            <h6>Are you sure?</h6>
            <div className="erp-form-label">Do you wish to delete this record ?</div>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <Button variant="success" className='erp-gb-button' onClick={handleClose}>
              Cancel
            </Button>&nbsp;
            <Button variant="danger" className='erp-gb-button' onClick={DeleteRecord}>Delete</Button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" className='erp_document_Form' show={showMaterialBeanCard} onHide={handleCloseMaterialBeanCard} backdrop="static" keyboard={false} centered>
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>Material Bean Card</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseMaterialBeanCard}></button></span>
          </Modal.Header>
          <Modal.Body>
            <FrmTMaterialBeanCard materialID={productRmId} btn_disabled={true} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" className="btn erp-gb-button" onClick={handleCloseMaterialBeanCard}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </DashboardLayout>
    </>
  )
}
export default FrmMRawCottonBaleEntry;