import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";

const SalesOrderInvoice = React.forwardRef(({ invoiceContent }, ref) => {
  debugger
  // const configConstants = ConfigConstants();
  // const { AFTER_DECIMAL_PLACES } = configConstants;/
  let AFTER_DECIMAL_PLACES = 2;

  const scheduledata = invoiceContent.scheduleDataArray

  const data = invoiceContent.items


    // Converting Currency to International Standard.
    const numberToWords = require("number-to-words");
    const grn_currency_code = invoiceContent.footer.grn_currency_code;
    // const grn_currency_code = "INR";

    // Currency mapping with major (main unit) and minor (subunit)
    const currencyMap = {
      "INR": { major: "Rupee", minor: "Paisa" },
      "AFN": { major: "Afghani", minor: "Pul" },
      "ARS": { major: "Peso", minor: "Centavo" },
      "AWG": { major: "Guilder", minor: "Cent" },
      "AUD": { major: "Dollar", minor: "Cent" },
      "AZN": { major: "New Manat", minor: "Qəpik" },
      "BSD": { major: "Dollar", minor: "Cent" },
      "BBD": { major: "Dollar", minor: "Cent" },
      "BYR": { major: "Ruble", minor: "Kopek" },
      "EUR": { major: "Euro", minor: "Cent" },
      "BZD": { major: "Dollar", minor: "Cent" },
      "BMD": { major: "Dollar", minor: "Cent" },
      "BOB": { major: "Boliviano", minor: "Centavo" },
      "BAM": { major: "Convertible Marka", minor: "Fening" },
      "BWP": { major: "Pula", minor: "Thebe" },
      "BGN": { major: "Lev", minor: "Stotinka" },
      "BRL": { major: "Real", minor: "Centavo" },
      "GBP": { major: "Pound", minor: "Pence" },
      "BND": { major: "Dollar", minor: "Cent" },
      "KHR": { major: "Riel", minor: "Sen" },
      "CAD": { major: "Dollar", minor: "Cent" },
      "KYD": { major: "Dollar", minor: "Cent" },
      "CLP": { major: "Peso", minor: "Centavo" },
      "CNY": { major: "Yuan Renminbi", minor: "Fen" },
      "COP": { major: "Peso", minor: "Centavo" },
      "CRC": { major: "Colón", minor: "Céntimo" },
      "HRK": { major: "Kuna", minor: "Lipa" },
      "CUP": { major: "Peso", minor: "Centavo" },
      "CZK": { major: "Koruna", minor: "Haléř" },
      "DKK": { major: "Krone", minor: "Øre" },
      "DOP": { major: "Peso", minor: "Centavo" },
      "XCD": { major: "Dollar", minor: "Cent" },
      "EGP": { major: "Pound", minor: "Piastre" },
      "SVC": { major: "Colón", minor: "Centavo" },
      "FKP": { major: "Pound", minor: "Pence" },
      "FJD": { major: "Dollar", minor: "Cent" },
      "GHC": { major: "Cedi", minor: "Pesewa" },
      "GIP": { major: "Pound", minor: "Pence" },
      "GTQ": { major: "Quetzal", minor: "Centavo" },
      "GGP": { major: "Pound", minor: "Pence" },
      "GYD": { major: "Dollar", minor: "Cent" },
      "HNL": { major: "Lempira", minor: "Centavo" },
      "HKD": { major: "Dollar", minor: "Cent" },
      "HUF": { major: "Forint", minor: "Fillér" },
      "ISK": { major: "Krona", minor: "Aur" },
      "IDR": { major: "Rupiah", minor: "Sen" },
      "IRR": { major: "Rial", minor: "Dinar" },
      "ILS": { major: "New Shekel", minor: "Agora" },
      "JMD": { major: "Dollar", minor: "Cent" },
      "JPY": { major: "Yen", minor: "Sen" },
      "KZT": { major: "Tenge", minor: "Tiyn" },
      "KPW": { major: "Won", minor: "Jeon" },
      "KRW": { major: "Won", minor: "Jeon" },
      "KGS": { major: "Som", minor: "Tyiyn" },
      "LAK": { major: "Kip", minor: "Att" },
      "LVL": { major: "Lats", minor: "Santims" },
      "LBP": { major: "Pound", minor: "Piastre" },
      "LRD": { major: "Dollar", minor: "Cent" },
      "CHF": { major: "Franc", minor: "Rappen" },
      "LTL": { major: "Litas", minor: "Centas" },
      "MKD": { major: "Denar", minor: "Deni" },
      "MYR": { major: "Ringgit", minor: "Sen" },
      "MUR": { major: "Rupee", minor: "Cent" },
      "MXN": { major: "Peso", minor: "Centavo" },
      "MNT": { major: "Tugrik", minor: "Möngö" },
      "MZN": { major: "Metical", minor: "Centavo" },
      "NAD": { major: "Dollar", minor: "Cent" },
      "NPR": { major: "Rupee", minor: "Paisa" },
      "ANG": { major: "Guilder", minor: "Cent" },
      "NZD": { major: "Dollar", minor: "Cent" },
      "NIO": { major: "Cordoba", minor: "Centavo" },
      "NGN": { major: "Naira", minor: "Kobo" },
      "NOK": { major: "Krone", minor: "Øre" },
      "OMR": { major: "Rial", minor: "Baisa" },
      "PKR": { major: "Rupee", minor: "Paisa" },
      "PAB": { major: "Balboa", minor: "Centésimo" },
      "PYG": { major: "Guarani", minor: "Céntimo" },
      "PEN": { major: "Nuevo Sol", minor: "Céntimo" },
      "PHP": { major: "Peso", minor: "Centavo" },
      "PLN": { major: "Zloty", minor: "Grosz" },
      "QAR": { major: "Rial", minor: "Dirham" },
      "RON": { major: "New Leu", minor: "Ban" },
      "RUB": { major: "Ruble", minor: "Kopek" },
      "SAR": { major: "Riyal", minor: "Halala" },
      "SGD": { major: "Dollar", minor: "Cent" },
      "ZAR": { major: "Rand", minor: "Cent" },
      "SEK": { major: "Krona", minor: "Öre" },
      "THB": { major: "Baht", minor: "Satang" },
      "TRY": { major: "Lira", minor: "Kurus" },
      "UAH": { major: "Hryvnia", minor: "Kopek" },
      "USD": { major: "Dollar", minor: "Cent" },
      "VND": { major: "Dong", minor: "Hao" }
      // Add more as needed
    };
  
    // Convert INR to Indian number system format
    function convertINR(amount) {
      let words = "";
      let crore = Math.floor(amount / 10000000);
      let lakh = Math.floor((amount % 10000000) / 100000);
      let thousand = Math.floor((amount % 100000) / 1000);
      let hundred = Math.floor((amount % 1000) / 100);
      let remainder = amount % 100;
  
      if (crore) words += `${numberToWords.toWords(crore)} crore `;
      if (lakh) words += `${numberToWords.toWords(lakh)} lakh `;
      if (thousand) words += `${numberToWords.toWords(thousand)} thousand `;
      if (hundred) words += `${numberToWords.toWords(hundred)} hundred `;
      if (remainder) words += `${numberToWords.toWords(remainder)} `;
  
      return words.trim() + " rupees";
    }
  
    // Convert amount to words based on currency
    function convertCurrencyToWords(amount, currency) {
      let details = currencyMap[currency] || { major: "Currency", minor: "Cents" };
  
      let integerPart = Math.floor(amount);
      let decimalPart = Math.round((amount % 1) * 100);
  
      let words = currency === "INR"
        ? convertINR(integerPart)
        : numberToWords.toWords(integerPart) + ` ${details.major}`;
  
      if (decimalPart) {
        words += ` and ${numberToWords.toWords(decimalPart)} ${details.minor}`;
      }
      words = words.charAt(0).toUpperCase() + words.slice(1);
  
      return words + " only";
    }
    

  const cgstTotal = invoiceContent.footer.cgst_total;
  const sgstTotal = invoiceContent.footer.sgst_total;
  const igstTotal = invoiceContent.footer.igst_total;
  const basicTotal = invoiceContent.footer.basic_total;
  const taxable_total = invoiceContent.footer.taxable_total
  const grand_total = invoiceContent.footer.grand_total


  // check Payment Terms name each element in the Payment Terms array
  let paymentTermsList = null
  if (invoiceContent.paymentTerms && invoiceContent.paymentTerms.length > 0) {
    paymentTermsList = invoiceContent.paymentTerms.map(paymentTerm => paymentTerm.payment_terms_name).join(', ');
  }

  // Iterate over each element in the Sales Order Terms array
  const SOTerms = invoiceContent.SOTerms;
  debugger
  SOTerms.forEach(SOTerms => {
    const { common_parameters_name, common_parameters_value } = SOTerms;
    // Now you can use common_parameters_name
    console.log('parameters_name :', common_parameters_name);
  });

  const contactDetails = invoiceContent.contactDetails;
  // Iterate over each element in the contactDetails array
  contactDetails.forEach(contact => {
    const { cust_contact_person, cust_contact_no } = contact;
    // Now you can use cust_contact_person and cust_contact_no
    console.log('Contact Person:', cust_contact_person);
    console.log('Contact Number:', cust_contact_no);
  });


  const formatDate = (dateString) => {
    if (!dateString) return ''; // Check if dateString is undefined or null
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };
  const itemData = extractItemData(invoiceContent.items);
  function extractItemData(items) {
    debugger
    // Extract relevant data from the first item in the array
    if (items.length > 0) {
      const firstItem = items[0];
      return {
        company: firstItem.company,
        company_branch: firstItem.company_branch,
        address: firstItem.address,
        phone: firstItem.phone,
        cell: firstItem.cell,
        email: firstItem.email,
        website: firstItem.website,
        GSTIN: firstItem.GSTIN,
        pan_no: firstItem.pan_no,
        state_name: firstItem.state_name,
        company_pincode: firstItem.company_pincode,
      };
    }
    // Return default values or handle the case where the array is empty
    return {
      company: 0,
      company_branch: 0,
      address: 0,
      phone: 0,
      cell: 0,
      email: 0,
      website: 0,
      GSTIN: 0,
      pan_no: 0,
      state_name: 0,
      company_pincode: 0,
    };
  }

  const invoiceHeader = (
    <div className='bordered border-bottom-0 px-0'>
      <div className='row mb-0 p-2'>
        <div className="col-sm-3 text-center text-sm-start">
          <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
        </div>
        <div className="col-sm-9 transformed">
          <div className='erp-invoice-print-label-lg text-center'>
            {itemData.company}
          </div>
          <div className="row">
            <div className="text-center">
              <div className='erp-invoice-print-label text-center'>
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'>{itemData.address} ({itemData.company_branch}) {itemData.state_name}-{itemData.company_pincode} </span>
                </div>

                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'>WebSite: <a href={itemData.website}>{itemData.website}</a> || Email: {itemData.email} ||  Phone: {itemData.phone} || Cell:{itemData.cell} </span>
                </div>
                {/* <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'> Phone: {itemData.phone} || Cell:{itemData.cell} </span>
                </div> */}
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'>GSTN No: {itemData.GSTIN} || PAN No:{itemData.pan_no} </span>
                </div>
                <div className='erp-invoice-print-label-lg'> Sales Order({invoiceContent.salesOrderDetails.order_type}) </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row border-top border-dark p-0 m-0'>
        <div className='col-sm-6'>
          <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
            <dt>Sales Order No. & version: {invoiceContent.salesOrderDetails.sales_order_no} & {invoiceContent.salesOrderDetails.sales_order_version}</dt>
          </dl>
          {invoiceContent.salesOrderDetails.sales_order_creation_type != 'Mannual' ?
            <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
              <dt>Sales Quotation No.: {invoiceContent.salesOrderDetails.sales_order_creation_type === 'Mannual' ? '-' : invoiceContent.salesOrderDetails.sales_quotation_no}</dt>
            </dl>
            : null
          }
        </div>
        {/* <div className='col-sm-3'>
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Sales Order Type: {invoiceContent.salesOrderDetails.sales_order_creation_type}</dt>
           
          </dl>

          {invoiceContent.salesOrderDetails.sales_order_creation_type != 'Mannual' ?
            <dl className="erp-invoice-print-label-md-lg">
              <dt>Sales Quotation Date: {invoiceContent.salesOrderDetails.sales_order_creation_type === 'Mannual' ? '-' : invoiceContent.salesOrderDetails.sales_quotation_date}</dt>
            </dl>
            : null
          }
        </div> */}
        <div className='col-sm-3'>
          <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
            {/* <dt>Department: {invoiceContent.salesOrderDetails.department_name}</dt> */}
            <dt>Sales Order Date: {invoiceContent.salesOrderDetails.sales_order_date}</dt>
          </dl>
        </div>
        <div className='col-sm-3'>
          <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
            {/* <dt>Department: {invoiceContent.salesOrderDetails.department_name}</dt> */}
            <dt>Agent: ({invoiceContent.salesOrderDetails.txt_agent_percent} %)</dt>
          </dl>
        </div>
      </div>
      <div className='row p-0 m-0'>
        <div className='col-sm-6'>
          <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
            <dt>Sales Type: {invoiceContent.salesOrderDetails.sales_type}</dt>
          </dl>
        </div>
        <div className='col-sm-3'>
          {/* <dl className="erp-invoice-print-label-md-lg">
            <dt>Sales Quotation Date: {invoiceContent.salesOrderDetails.sales_quotation_date}</dt>
          </dl> */}

          <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
            <dt>Customer Order Date: {invoiceContent.salesOrderDetails.customer_order_Date}</dt>
          </dl>
        </div>

        <div className='col-sm-3'>
          <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
            {/* <dt>Department: {invoiceContent.salesOrderDetails.department_name}</dt> */}
            <dt>Agent Name: {invoiceContent.salesOrderDetails.agent_name}</dt>
          </dl>
        </div>
      </div>

      <div className='row border-bottom border-dark p-0 m-0'>
        <div className='col-sm-6'>
          <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
            <dt>Customer Order No: {invoiceContent.salesOrderDetails.customer_order_no}</dt>
          </dl>
        </div>
      </div>


      <div className="row p-0 m-0">
        <div className="col-sm-6">
          <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: "10px" }}>Bill To Details:</dt>
          <div className='erp-invoice-print-label-md-lg'>
            {invoiceContent.customerDetails.customer}
          </div>
          <div className="erp-invoice-print-label-md-lg">
            {invoiceContent.customerDetails.address}
          </div>
          <dl className="row pb-0 mb-0">
            {/* <dt className='col-sm-2 erp-invoice-print-label-md-lg'>State:</dt>
            <dd className='col-sm-10 erp-invoice-print-label'> {invoiceContent.customerDetails.state}</dd> */}
            <dt class="col-sm-2 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>State:</dt>
            <dd class="col-sm-10 m-0 p-0">
              <dl class="row m-0 p-0">
                <dt class="col-sm-4 erp-invoice-print-label-md-lg"> {invoiceContent.customerDetails.state}</dt>
                <dd class="col-sm-2 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>City:</dd>
                <dt class="col-sm-3 erp-invoice-print-label-md-lg">{invoiceContent.customerDetails.customer_city_name}</dt>
              </dl>
            </dd>
          </dl>

          <dl className="row pb-0 mb-0">
            <dt className="col-sm-4 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Contact Details:</dt>
            <dd className="col-sm-10 m-0 p-0">
              <dl className="row m-0 p-0">
                <dl className="col-sm-7 mb-0 pb-0">
                  <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Person Name:</dt>

                </dl>
                <dl className="col-sm-5 mb-0 pb-0">
                  <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Contact No:</dt>

                </dl>
              </dl>
              {contactDetails.map((contactData, index) => (
                <dl className="row m-0 p-0" key={index}>
                  <dl className="col-sm-7 mb-0 pb-0">
                    <dd className="erp-invoice-print-label-md-lg">{contactData.cust_contact_person}</dd>
                  </dl>
                  <dl className="col-sm-5 mb-0 pb-0">
                    <dd className="erp-invoice-print-label-md-lg">{contactData.cust_contact_no}</dd>
                  </dl>
                </dl>
              ))}
            </dd>
          </dl>
          {/* <dl className="row pb-0 mb-0">
            <dt className="col-sm-4 erp-invoice-print-label-md-lg"style={{fontSize:"10px"}} >Contact Details:</dt>
            <dd className="col-sm-12 m-0 p-0">
              {contactDetails.map((contactData, index) => (
                <dl className="row m-0 p-0" key={index}>
                  <dl className="col-sm-7 mb-0 pb-0">
                    <dt className="erp-invoice-print-label-md-lg" style={{fontSize:"10px"}}>
                      Person Name: <span className="erp-invoice-print-label-md-lg">{contactData.cust_contact_person}</span>
                      {index < contactDetails.length - 1 ? ',' : ''}
                    </dt>
                  </dl>
                  <dl className="col-sm-5 mb-0 pb-0">
                    <dt className="erp-invoice-print-label-md-lg" style={{fontSize:"10px"}}>
                      Contact No: <span className="erp-invoice-print-label-md-lg">{contactData.cust_contact_no}</span>
                      {index < contactDetails.length - 1 ? ',' : ''}
                    </dt>
                  </dl>
                </dl>
              ))}
            </dd>
          </dl> */}


        </div>
        <div className="col-sm-6">
          <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: "10px" }}>Shipped To Details:</dt>
          <div className='erp-invoice-print-label-md-lg'>
            {invoiceContent.consigneeDetails.name}
          </div>
          <div className="erp-invoice-print-label-md-lg">
            {invoiceContent.consigneeDetails.address}
          </div>
          <dl className="row pb-0 mb-0">
            <dt class="col-sm-2 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>State:</dt>
            <dd class="col-sm-10">
              <dl class="row m-0 p-0">
                <dt class="col-sm-4 erp-invoice-print-label-md-lg"> {invoiceContent.consigneeDetails.state}</dt>
                <dd class="col-sm-2 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>City:</dd>
                <dt class="col-sm-3 erp-invoice-print-label-md-lg">{invoiceContent.consigneeDetails.consignee_city_name}</dt>
              </dl>
            </dd>
          </dl>
        </div>
      </div>

      {/* <div className="row border-top border-dark p-0 m-0">
        <div className="col-sm-6">
          <dt className='erp-invoice-print-label-md-lg'>Our Details:</dt>
          <dl className="row m-0 p-0">
            <dd class="col-sm-3 erp-invoice-print-label-md-lg p-0">Branch Name:</dd>
            <dt class="col-sm-9  erp-invoice-print-label">{invoiceContent.Ourdetails.company_branch}</dt>
          </dl>
          <dl className="row pb-0 mb-0">
            <dt class="col-sm-2 erp-invoice-print-label-md-lg">State:</dt>
            <dd class="col-sm-10 m-0 p-0">
              <dl class="row m-0 p-0">
                <dt class="col-sm-4  erp-invoice-print-label"> {invoiceContent.Ourdetails.state_name}</dt>
                <dd class="col-sm-2 erp-invoice-print-label-md-lg">City:</dd>
                <dt class="col-sm-3 erp-invoice-print-label">{invoiceContent.Ourdetails.city_name}</dt>
              </dl>
            </dd>
          </dl>
        </div>
        <div className="col-sm-6">
          <dl className="row m-0 p-0 mt-3">
            <dd class="col-sm-3 erp-invoice-print-label-md-lg p-0">Department:</dd>
            <dt class="col-sm-9  erp-invoice-print-label">{invoiceContent.salesOrderDetails.department_name}</dt>
          </dl>
        </div>
      </div> */}

    </div>
  )

  const invoiceFooter = (
    <div className="invoice-footer">
      <div className='row'>
        <div className='col-sm-6'>
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>For {invoiceContent.customerDetails.customer}</dt>
          </div>
          <div className='align-bottom-center mt-3'>
            <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Buyer's Signature</dt>
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>For {itemData.company}</dt>
          </div>
          <div className='align-bottom-center mt-3'>
            <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Authorised Signatory</dt>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* <div className="container"> */}

      <div className="row">
        <div className="col-12">
          <div className="container-invoice">
            <div id="content">
              <div className="invoice p-0">

                {/* <!--BILL HEADER--> */}
                <div className="row" style={{ padding: '0px 15px 0px' }}>
                  {invoiceHeader}
                </div>
                {/* <!--PARCEL DETAILS--> */}
                <div className="row" style={{ padding: '0px 15px 15px' }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 px-0" >
                        <div className="row p-0">
                          <div className="table-responsive">
                            <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                              {data.length !== 0 && (
                                <thead>
                                  <tr>
                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Sr No.</th>
                                    {/* <th className="erp_invoice_table_th text-center col-4" rowspan={2}>Material Name(Technical Spect.,Make Name,Grade Name)</th> */}
                                    <th className="erp_invoice_table_th text-center col-4" rowspan={2}>Item</th>

                                    {/* <th className="erp_invoice_table_th text-center col" rowspan={2}>So SrNo.</th> */}
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>HSN Code</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Rate</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Qty.</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Unit</th>
                                    {/* <th className="erp_invoice_table_th text-center" style={{ display: "none" }} rowspan={2}>Disc %</th> */}
                                    <th className="erp_invoice_table_th text-center col-1" colSpan={3}>Taxes Extra</th>
                                    <th className="erp_invoice_table_th text-center col-2" rowspan={2}>Item Total</th>
                                  </tr>
                                  <tr  >
                                    <th className="erp_invoice_table_th text-center">CGST %</th>
                                    <th className="erp_invoice_table_th text-center">SGST %</th>
                                    <th className="erp_invoice_table_th text-center">IGST %</th>
                                  </tr>
                                </thead>
                              )}
                              <tbody id="detailstableId">
                                {
                                  data.map((item, index) =>
                                    <>
                                      <tr className={`tblBodyTrHt-${index}`}>
                                        <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                        <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.material_name}<span>({item.material_tech_spect},{item.product_make_name},{item.material_grade_name}, {item.product_material_code})</span></td>
                                        {/* <td className="erp_invoice_table_td text-end">{item.so_sr_no}</td> */}
                                        <td className="erp_invoice_table_td text-end">{item.hsn_code}</td>
                                        <td className="erp_invoice_table_td text-end">{item.rate}</td>
                                        <td className="erp_invoice_table_td text-end">{item.quantity}</td>
                                        <td className="erp_invoice_table_td">{item.unit}</td>
                                        {/* <td className="erp_invoice_table_td text-end" style={{ display: "none" }}>{item.discount_percent}</td> */}
                                        <td className="erp_invoice_table_td text-end">{item.cgst_percent}</td>
                                        <td className="erp_invoice_table_td text-end">{item.sgst_percent}</td>
                                        <td className="erp_invoice_table_td text-end">{item.igst_percent}</td>
                                        <td className="erp_invoice_table_td text-end">{item.material_basic_amount}</td>
                                      </tr>
                                      {
                                        data.length - 1 === index ? <>
                                          {/* Add blank rows to fill the space */}

                                          <tr id='footerTr'>
                                            <td className="erp_invoice_table_td" colSpan={6}>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Basic Total :</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg">{convertCurrencyToWords(basicTotal, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">CGST(in words):</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg">{convertCurrencyToWords(cgstTotal, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">SGST(in words):</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg">{convertCurrencyToWords(sgstTotal, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">IGST(in words):</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg">{convertCurrencyToWords(igstTotal, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Taxable Total:</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg">{convertCurrencyToWords(taxable_total, grn_currency_code)}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Grand Total :</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg">{convertCurrencyToWords(grand_total, grn_currency_code)}</dd>
                                              </div>
                                            </td>
                                            <td className="erp_invoice_table_td p-0 m-0" colSpan={6}>
                                              <table className="table p-0 m-0 table-borderless">
                                                <tbody>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Basic Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.basic_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg ">CGST Total :</span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.cgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">SGST Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.sgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">IGST Total:</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.igst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Packing Amount :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.packing_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Freight Amount :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.freight_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark" style={{ display: "none" }}>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Discount Amount</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.basic_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Taxable Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.taxable_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Other Amount:</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.other_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className=''>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Grand Total: </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.grand_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>


                                          {/* <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Product Schedule:</span> </td> */}
                                          {invoiceContent.salesOrderDetails.order_type === "Greige  Fabric" && (
                                            <tr>
                                              <td className="erp_invoice_table_td p-0 m-0" colSpan={20} style={{ borderLeft: 'none', borderRight: 'none' }}>

                                                <div className='row mt-1 m-0 p-0'>
                                                  <dt className="col-sm-12 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Product Schedule:</dt>
                                                </div>

                                                <table className="col-sm-12 table table-bordered  border-dark  border-end-0 border-start-0" style={{ borderLeft: 'none !important', borderRight: 'none !important' }}>
                                                  <thead>
                                                    <tr>
                                                      <th className="erp_invoice_table_th text-center" style={{ borderLeft: "none" }} rowSpan={2}>Sr No</th>
                                                      <th className="erp_invoice_table_th text-center col-5 " rowSpan={2}>Item</th>
                                                      <th className="erp_invoice_table_th text-center col-2 " rowSpan={2}>Packing</th>
                                                      <th className="erp_invoice_table_th text-center col-3" rowSpan={2}>Quantity</th>
                                                      <th className="erp_invoice_table_th text-center col-4" style={{ borderRight: "none" }} rowSpan={2}>Expected Schedule Date</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {scheduledata.length > 0 && scheduledata.map((item, index) => (
                                                      <tr key={index} colSpan={12} >
                                                        <td className="erp_invoice_table_td text-end" style={{ borderLeft: "none" }}>{index + 1}</td>
                                                        <td className="erp_invoice_table_td ">{item.product_material_code}</td>
                                                        <td className="erp_invoice_table_td ">{item.product_material_packing_name}</td>
                                                        <td className="erp_invoice_table_td text-end">{item.product_material_schedule_quantity}</td>
                                                        <td className="erp_invoice_table_td text-end" style={{ borderRight: "none" }}>{formatDate(item?.expected_schedule_date)}</td>
                                                      </tr>
                                                    ))}
                                                  </tbody>
                                                </table>

                                              </td>

                                            </tr>
                                          )}



                                          {/* <tr>
                                            <td colSpan={12}>
                                              <div className='row p-0 m-0'>
                                                <dt className="col-sm-1 erp-invoice-print-label-md-lg">Remark :</dt>
                                                <dd className="col-sm-4 erp-invoice-print-label"> {invoiceContent.footer.remark}</dd>

                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">Other Terms & Conditions:</dt>
                                                <dd className="col-sm-5 erp-invoice-print-label">{invoiceContent.footer.other_terms_conditions}</dd>
                                              </div>
                                              <dl className="row p-0 m-0 mt-1">
                                                {paymentTermsList !== null && (
                                                  <>
                                                    <dt className="col-sm-2 erp-invoice-print-label-md-lg">SO Payment Terms: </dt>
                                                    <dd className="col-sm-6 m-0 p-0">
                                                      {paymentTermsList !== null && (
                                                        <dl className="row m-0 p-0">
                                                          <div className="col-sm-8 mb-0 pb-0">
                                                            <dd className="erp-invoice-print-label">
                                                              <dt className="erp-invoice-print-label">{paymentTermsList}</dt>
                                                            </dd>
                                                          </div>
                                                        </dl>
                                                      )}
                                                    </dd>
                                                  </>
                                                )}
                                              </dl>

                                              <dl className="row p-0 m-0 mt-1">
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">SO Payment Terms: </dt>
                                                {paymentTermsList !== null ? (
                                                  <dd className="col-sm-6 m-0 p-0">
                                                    <dl className="row m-0 p-0" key={index}>
                                                      <dl className="col-sm-8 mb-0 pb-0">
                                                        <dd className="erp-invoice-print-label">
                                                          <dt className="erp-invoice-print-label">{paymentTermsList}</dt>
                                                        </dd>
                                                      </dl>
                                                    </dl>
                                                  </dd>
                                                ) : (
                                                  <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                )}
                                              </dl>
                                              <dl className="row p-0 m-0 mt-1">
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">SO Terms :</dt>
                                                {SOTerms.length > 0 ? (
                                                  <dd className="col-sm-6 m-0 p-0">
                                                    {SOTerms.map((SOTerms, index) => (
                                                      <dl className="row m-0 p-0" key={index}>
                                                        <dl className="col-sm-8 mb-0 pb-0">
                                                          <dd className="erp-invoice-print-label">
                                                            {SOTerms.common_parameters_name !== undefined ? ` ${SOTerms.common_parameters_name}` : ''}
                                                          </dd>
                                                        </dl>
                                                      </dl>
                                                    ))}
                                                  </dd>
                                                ) : (
                                                  <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                )}
                                              </dl>

                                            </td>
                                          </tr> */}



                                          <tr>
                                            <td colSpan={12}>
                                              <div className='row p-0 m-0'>
                                                <dl className="row p-0 m-0 mt-1">
                                                  {paymentTermsList !== null && (
                                                    <>
                                                      <dt className="col-sm-2 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>SO Payment Terms: </dt>
                                                      <dd className="col-sm-6 m-0 p-0">
                                                        {paymentTermsList !== null && (
                                                          <dl className="row m-0 p-0">
                                                            <div className="col-sm-8 mb-0 pb-0">
                                                              <dd className="erp-invoice-print-label">
                                                                <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>{paymentTermsList}</dt>
                                                              </dd>
                                                            </div>
                                                          </dl>
                                                        )}
                                                      </dd>
                                                    </>
                                                  )}
                                                </dl>
                                                {invoiceContent.salesOrderDetails.order_type === "Greige  Fabric" && (
                                                  <>
                                                    <div className='row'>
                                                      <dt className="col-sm-3 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Terms & Conditions :</dt>
                                                    </div>
                                                    <div className='row'>
                                                      <div className='col-md-12' style={{ display: 'flex' }}>
                                                        <span className="erp-invoice-print-label-md-lg">- GST AND OTHER GOVERNMENT CHARGES WILL BE ADDED AS PER GOVERNMENT RULES</span>
                                                        <span className="erp-invoice-print-label-md-lg" style={{ marginLeft: "56px" }}>- QUALITY PRODUCT AS PER AMERICAN 4 POINT SYSTEM</span>
                                                      </div>
                                                    </div>
                                                    <div className='row'>
                                                      <div className='col-md-12' style={{ display: 'flex' }}>
                                                        <span className="erp-invoice-print-label-md-lg">- PAYMENT AFTER THE AGREED DATE WILL ATTRACT INTEREST @ 15% PER ANNUM</span>
                                                        <span className="erp-invoice-print-label-md-lg" style={{ marginLeft: "82px" }}>- OUR LIABILITY IS LIMITED UP TO GREIGE FABRIC ONLY</span>
                                                      </div>
                                                    </div>
                                                    <div className='row'>
                                                      <div className='col-md-12' style={{ display: 'flex' }}>
                                                        <span className="erp-invoice-print-label-md-lg">- AS PER ARBITRATION CLAUSE(78-A) OF MASKATI KAPAD MAHAJAN AS PER TRADING RULE</span>
                                                        <span className="erp-invoice-print-label-md-lg" style={{ marginLeft: "40px" }}>- SUBJECT TO AHMADABAD JURISDICTION ONLY</span>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                                <dl className="row p-0 m-0 mt-1">
                                                  {SOTerms.length > 0 ? (
                                                    <>
                                                      <dt className="col-sm-2 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>SO Terms :</dt>
                                                      <dd className="col-sm-6 m-0 p-0">
                                                        {SOTerms.map((SOTerm, index) => (
                                                          <dl className="row m-0 p-0" key={index}>
                                                            <dl className="col-sm-8 mb-0 pb-0">
                                                              <dd className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
                                                                {SOTerm.common_parameters_name || ''}
                                                              </dd>
                                                            </dl>
                                                          </dl>
                                                        ))}
                                                      </dd>
                                                    </>
                                                  ) : null}
                                                </dl>
                                                <div className='row p-0 m-0'>
                                                  <dt className="col-sm-5 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Other Terms & Conditions:</dt>
                                                  <dd className="col-sm-7 erp-invoice-print-label" style={{ fontSize: "10px" }}>{invoiceContent.footer.other_terms_conditions}</dd>

                                                  <dt className="col-sm-5 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Remark :</dt>
                                                  <dd className="col-sm-7 erp-invoice-print-label" style={{ fontSize: "10px" }}> {invoiceContent.footer.remark}</dd>


                                                </div>
                                              </div>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td colSpan={12}>
                                              {/* Your invoice footer content */}
                                              {invoiceFooter}
                                            </td>
                                          </tr>

                                        </> : null
                                      }

                                    </>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >


    </>
  );
})

export default SalesOrderInvoice;
