import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";

import Datatable from "components/DataTable";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import ReactDOMServer from 'react-dom/server';
import ExcelExport from "Features/Exports/ExcelExport";


function MonthlyAttendance() {

    // Globally Constants.
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_NAME, FINANCIAL_SHORT_NAME, COMPANY_ADDRESS } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    // ****** Hook For Navigation
    const navigate = useNavigate();
    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let salaryMonthComboRef = useRef(null);
    let salaryYearComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);
    const exlsExp = useRef();

    const [allDepartmentsList, setAllDepartmentsList] = useState([]);
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);
    const [txt_employee_code, setEmployeeCode] = useState();

    // Input field's Hooks;
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('0');
    const [cmb_salary_month, setSalaryMonth] = useState('');
    const [cmb_salary_year, setSalaryYear] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');
    const [contractorOptions, setContractorOption] = useState([]);
    const [cmb_contractor_id, setContractorId] = useState('');
    const cmb_contractor_id_ref = useRef()

    // combo's options hooks;
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);

    // Hooks For table;
    const [attendanceDtlTblData, setAttendanceDtlTblData] = useState([]);
    const [attendanceDtlTblCols, setAttendanceDtlTblCols] = useState([]);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);


    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ]);

    // FINANCIAL_SHORT_NAME wants like 2024
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );

    // On Page Load.
    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillComobos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);
    const validateShiftManagementDetails = () => {
        if (cmb_employee_type_id === '') {
            let errorElement = document.getElementById("error_cmb_employee_type_id");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("cmb_employee_type_id").focus();
            return false;
        }

        return true;
    }

    const FnComboOnChange = async (comboName) => {
        debugger
        try {
            switch (comboName) {
                case "Department":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);

                    let selectedEmployeeTypes = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDpt = departmentComboRef.current?.department_id || 'All';
                    let contractorID = cmb_contractor_id_ref.current.value;
                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    // if (selectedEmployeeTypes && selectedEmployeeTypes !== 'All') {
                    //     globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmployeeTypes });
                    // }
                    if (contractorID !== undefined && contractorID !== null && contractorID !== '') {
                        globalQuery.conditions.push({ field: "contractor_id", operator: "=", value: contractorID });
                    }
                    if (selectedEmployeeTypes !== undefined && selectedEmployeeTypes !== null && selectedEmployeeTypes !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmployeeTypes });
                    } else {
                        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                        setEmployeeTypeId(defaultEmplType.value);
                        employeeTypesComboRef.current = defaultEmplType;
                        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                            ?.map(item => item?.field_name)
                            ?.filter(id => id !== undefined && id !== null && id !== '');;
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "IN", values: emplTypes });
                    }
                    if (selectedDpt && selectedDpt !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDpt });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(emp => ({ ...emp, value: emp.employee_id, label: `[${emp.old_employee_code}] [${emp.employee_code}] ${emp.employee_name}` }));
                            rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    // employeeComboRef.current = null;
                    setEmployeeId('');
                    // }
                    break;

                case "subDepartment":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    let selectedDepTS = departmentComboRef.current.department_id;
                    const subDepartmentLists = allDepartmentsList.filter(department =>
                        (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
                    );
                    subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentLists);

                    let selectedEmpType = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDpts = departmentComboRef.current?.department_id || 'All';
                    let selectedSubDPts = subDepartmentComboRef.current?.department_id || 'All';
                    let contractorIds = cmb_contractor_id_ref.current.value;

                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    // if (selectedEmpType && selectedEmpType !== 'All') {
                    //     globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmpType });
                    // }
                    if (contractorIds !== undefined && contractorIds !== null && contractorIds !== '') {
                        globalQuery.conditions.push({ field: "contractor_id", operator: "=", value: contractorIds });
                    }
                    if (selectedEmpType !== undefined && selectedEmpType !== null && selectedEmpType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmpType });
                    } else {
                        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                        setEmployeeTypeId(defaultEmplType.value);
                        employeeTypesComboRef.current = defaultEmplType;
                        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                            ?.map(item => item?.field_name)
                            ?.filter(id => id !== undefined && id !== null && id !== '');;
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "IN", values: emplTypes });
                    }
                    if (selectedDpts && selectedDpts !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDpts });
                    }
                    if (selectedSubDPts && selectedSubDPts !== 'All') {
                        globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDPts });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(emp => ({ ...emp, value: emp.employee_id, label: `[${emp.old_employee_code}] [${emp.employee_code}] ${emp.employee_name}` }));
                            rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    // employeeComboRef.current = null;
                    setEmployeeId('');

                    break;
                case "EmployeeType":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    let selectedEmployeeType = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDepartments = departmentComboRef.current?.department_id || 'All';
                    let selectedSubDepartment = subDepartmentComboRef.current?.department_id || 'All';
                    let contractorId = cmb_contractor_id_ref.current.value;

                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"

                    if (contractorId !== undefined && contractorId !== null && contractorId !== '') {
                        globalQuery.conditions.push({ field: "contractor_id", operator: "=", value: contractorId });
                    }
                    if (selectedEmployeeType !== undefined && selectedEmployeeType !== null && selectedEmployeeType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmployeeType });
                    } else {
                        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                        setEmployeeTypeId(defaultEmplType.value);
                        employeeTypesComboRef.current = defaultEmplType;
                        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                            ?.map(item => item?.field_name)
                            ?.filter(id => id !== undefined && id !== null && id !== '');;
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "IN", values: emplTypes });
                    }
                    if (selectedDepartments && selectedDepartments !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDepartments });
                    }
                    if (selectedSubDepartment && selectedSubDepartment !== 'All') {
                        globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(emp => ({ ...emp, value: emp.employee_id, label: `[${emp.old_employee_code}] [${emp.employee_code}] ${emp.employee_name}` }));
                            rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    // employeeComboRef.current = null;
                    setEmployeeId('');
                    break;
                case 'Contractor':
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    let selectedEmplType = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDep = departmentComboRef.current?.department_id || 'All';
                    let selectedSubDep = subDepartmentComboRef.current?.department_id || 'All';
                    let contractor = cmb_contractor_id_ref.current.value;

                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"

                    if (contractor !== undefined && contractor !== null && contractor !== '') {
                        globalQuery.conditions.push({ field: "contractor_id", operator: "=", value: contractor });
                    }
                    if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmplType });
                    } else {
                        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                        setEmployeeTypeId(defaultEmplType.value);
                        employeeTypesComboRef.current = defaultEmplType;
                        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                            ?.map(item => item?.field_name)
                            ?.filter(id => id !== undefined && id !== null && id !== '');;
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "IN", values: emplTypes });
                    }
                    if (selectedDep && selectedDep !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDep });
                    }
                    if (selectedSubDep && selectedSubDep !== 'All') {
                        globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDep });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(emp => ({ ...emp, value: emp.employee_id, label: `[${emp.old_employee_code}] [${emp.employee_code}] ${emp.employee_name}` }));
                            rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                            setEmployeeOpts(rcvdEmpls);
                        });
                    break;
                case 'EmployeeCode':
                    debugger
                    var serachemployeeCode = $('#txt_employee_code').val();
                    setEmployeeCode(serachemployeeCode);
                    let findEmployee = null;

                    if (serachemployeeCode.length >= 2) {
                        findEmployee = employeeOpts.find((employee) => {
                            return employee.employee_code === serachemployeeCode
                                || employee?.label?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                                || employee?.old_employee_code === serachemployeeCode
                            // || employee?.old_employee_code?.includes(serachemployeeCode)
                        });
                    }
                    if (findEmployee) {
                        setEmployeeId(findEmployee.employee_id);
                        employeeComboRef.current = {
                            employee_id: findEmployee.employee_id,
                            employee_code: findEmployee.employee_code,
                        };
                    } else {
                        setEmployeeId('');
                        employeeComboRef.current = {
                            employee_id: '',
                            employee_code: '',
                        };
                    }
                    break;
                case 'EmplCode':
                    // setEmployeeCode(employeeComboRef.current.employee_code);
                    if (employeeComboRef.current && employeeComboRef.current.employee_code) {
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    }

                    break;
                case 'Employee':
                    debugger
                    var employeeID = employeeComboRef.current?.employee_id;
                    if (employeeID !== "") {
                        // var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                        setEmployeeId(employeeComboRef.current.employee_id);
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    } else {
                        setEmployeeCode('');
                    }

                default:
                    break;
            }

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    const FnFillComobos = async () => {
        try {
            // Set Default Year.
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setSalaryYear(defaultYear.value);
            salaryYearComboRef.current = defaultYear;
            // Set Default Month.
            let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
            setSalaryMonth(defaultMonth.value);
            salaryMonthComboRef.current = defaultMonth;

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "property_value", operator: "=", value: 'S' });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    // setEmployeeProps(rcvdEmplTypes);
                    let emplTypes = rcvdEmplTypes
                        .filter(prop => prop.properties_master_name === 'EmployeeTypeGroup')
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
                    setEmployeeTypesOpts(emplTypes);
                });
            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                    // subDepartments.unshift({ value: 'All', label: 'All' });
                    // setSubDepartmentOpts(subDepartments);
                });
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"]
            globalQuery.table = "cmv_contractor"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: "0" });
            comboDataFunc.current.fillFiltersCombo(globalQuery).then((contractorList) => {
                // combobox.current.fillMasterData("cmv_contractor", "", "").then((contractorList) => {
                const contractors = [
                    { value: '', label: 'Select' },
                    ...contractorList.map((contractor) => ({ ...contractor, value: contractor.field_id, label: contractor.field_name, })),
                ];
                setContractorOption(contractors);
            })
        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {

        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(salaryYearComboRef?.current?.value || null)) { $('#error_cmb_salary_year').hide(); }
        if (isEmpty(salaryMonthComboRef.current?.value)) { $('#error_cmb_salary_month').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    }

    const goBack = () => {
        navigate("/Dashboard")
    }


    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintImmediatePunch = useReactToPrint({
        content: () => {
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            // Remove the print button from the cloned content

            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: "Monthly Punch Report(Staffs)"
    });
    function formatDate(date) {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    }


    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Monthly Punch Details
                                {
                                    employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                        ` (${employeeTypesComboRef.current?.label})`
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'></div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">Month : <span className="erp-invoice-print-label">{
                                salaryMonthComboRef.current?.label !== 'All' && salaryMonthComboRef.current?.label !== '' && (
                                    `${salaryMonthComboRef.current?.label}`
                                )
                            }</span></dt>

                        </div>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">
                                Year: <span className="erp-invoice-print-label">{salaryYearComboRef.current?.label === 'Select' || salaryYearComboRef.current?.label === undefined || salaryYearComboRef.current?.label === '' ? 'All' : salaryYearComboRef.current?.label}
                                </span></dt>

                        </div>
                    </div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">Employee Type : <span className="erp-invoice-print-label">{
                                employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                    `${employeeTypesComboRef.current?.label}`
                                )
                            }</span></dt>

                        </div>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">
                                Department Name: <span className="erp-invoice-print-label">{departmentComboRef.current?.label === 'Select' || departmentComboRef.current?.label === undefined || departmentComboRef.current?.label === '' ? 'All' : departmentComboRef.current?.label}
                                </span></dt>

                        </div>
                    </div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg"> Sub Department Name :<span className="erp-invoice-print-label"> {
                                `${subDepartmentComboRef.current?.label}`
                            }</span></dt>
                        </div>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">Employee Name :<span className="erp-invoice-print-label"> {
                                `${employeeComboRef.current?.employee_name}`
                            }</span></dt>
                        </div>
                    </div>
                </div>
            </>
        );
    };



    // ********* PrintOut Functionalities Ends. ****************************************


    const capitalizeStr = str => str.charAt(0).toUpperCase() + str.slice(1);
    const formatTableHeader = key => key.split('_').map(capitalizeStr).join(' ');
    const FnLoadAttendanceData = async () => {
        debugger
        try {
            setIsLoading(true)
            const validate = validateShiftManagementDetails();
            if (validate) {
                let selectedYear = salaryYearComboRef.current?.value || null;
                let selectedMonth = salaryMonthComboRef.current?.value || null;
                let selectedDepartment = departmentComboRef.current?.department_id || null;
                let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;
                let selectedEmplType = employeeTypesComboRef.current?.field_name || null;
                let selectedEmployee = employeeComboRef.current?.employee_id || null;

                if (selectedEmployee === null) {
                    FnComboOnChange("subDepartment");
                    setEmployeeId('All')
                }

                if (selectedDepartment === null) {
                    setDepartmentId('All')
                }

                if (selectedSubDepartment === null) {
                    setSubDepartmentId('All')
                }

                resetGlobalQuery();
                globalQuery.columns = [

                    "empl.old_employee_code AS punch_code",
                    "empl.employee_type",
                    "empl.employee_name",
                    "empldep.department_name",
                    "emplsubdep.department_name as sub_department_name",
                    "empl.skill_type",
                    "empl.job_type_rate",
                    "empl.shift_start_end_time",
                    "empl.halfday_hours",
                    "empl.fullday_hours",

                    ...Array.from({ length: 31 }, (_, i) => {
                        const dayIndex = i + 1;
                        return `
                            CASE 
                                -- If either in_time or out_time is '00:00', mark as zero hours worked
                                WHEN TIME(SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', 1)) = '00:00' 
                                    OR TIME(SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', -1)) = '00:00' THEN
                                    '00:00'
                                
                                -- Handle overnight shifts (when out_time < in_time)
                                WHEN TIME(SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', -1)) < TIME(SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', 1)) THEN
                                    TIME_FORMAT(
                                        ADDTIME(
                                            TIMEDIFF(
                                                SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', -1),
                                                SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', 1)
                                            ), 
                                            '24:00:00'
                                        ), 
                                        '%H:%i'
                                    )
                                
                                -- Normal case, calculate time difference between in_time and out_time
                                ELSE
                                    TIME_FORMAT(
                                        TIMEDIFF(
                                            SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', -1),
                                            SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', 1)
                                        ), 
                                        '%H:%i'
                                    )
                            END AS Day${dayIndex} `;
                    }),
                    // Calculate the number of present days based on valid in_time and out_time
                    `
                        (
                            ${Array.from({ length: 31 }, (_, i) => {
                        const dayIndex = i + 1;
                        return `
                                    CASE 
                                        -- Check if both in_time and out_time are valid (not '00:00')
                                        WHEN TIME(SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', 1)) != '00:00' 
                                            AND TIME(SUBSTRING_INDEX(htatm.in_out_time${dayIndex}, '/', -1)) != '00:00'
                                        THEN 1
                                        ELSE 0
                                    END
                                `;
                    }).join(" + ")}
                        ) AS valid_PresentDays
                        `,
                    "htatm.monthly_hours",
                    "htatm.month_days",
                    "htatm.company_id",
                    "htatm.company_branch_id",
                    "htatm.department_id",
                    "htatm.sub_department_id",
                    "htatm.employee_id",
                    // "htatm.employee_type",
                    "htatm.total_salary_days",
                    "htatm.attendance_month",
                    "htatm.attendance_year",
                    "htatm.attendance_process_id",
                    "empl.weeklyoff_name",
                    "htatm.week_off_days",
                    "htatm.process_date"
                ];
                // }

                globalQuery.table = "ht_attendance_monthly htatm";
                globalQuery.conditions.push({ field: "htatm.is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "htatm.company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "htatm.attendance_month", operator: "=", value: selectedMonth });  // Here pass the selected monthNumber
                globalQuery.conditions.push({ field: "htatm.attendance_year", operator: "=", value: selectedYear });
                // globalQuery.conditions.push({ field: "htatm.employee_type", operator: "=", value: selectedEmplType });
                // globalQuery.conditions.push({ field: "htatm.employee_id", operator: "=", value: selectedEmployee });

                // if (selectedEmplType && selectedEmplType !== 'All') {
                //     globalQuery.conditions.push({ field: "htatm.employee_type", operator: "=", value: selectedEmplType });
                // }
                if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.employee_type", operator: "=", value: selectedEmplType });
                } else {
                    let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                    setEmployeeTypeId(defaultEmplType.value);
                    employeeTypesComboRef.current = defaultEmplType;
                    let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All").map(item => item?.field_name);
                    globalQuery.conditions.push({ field: "htatm.employee_type", operator: "IN", values: emplTypes });
                }
                if (selectedEmployee && selectedEmployee !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.employee_id", operator: "=", value: selectedEmployee });
                } else {
                    if (selectedEmployee === null) {
                        const employeeIds = employeeOpts
                            .filter(emp => emp.employee_id !== "") // Remove entries where employee_id is an empty string
                            .map(emp => emp.employee_id);

                        if (employeeIds.length === 1) {
                            // If only one employee_id, use "=" operator with a single value
                            globalQuery.conditions.push({
                                field: "empl.employee_id",
                                operator: "=",
                                value: employeeIds[0] // Single value, no need for join
                            });
                        } else if (employeeIds.length > 1) {
                            // If multiple employee_ids, use "IN" with a comma-separated string
                            globalQuery.conditions.push({
                                field: "empl.employee_id",
                                operator: "IN",
                                values: employeeIds.map(id => (isNaN(id) ? `"${id}"` : id)) // Enclose non-numeric IDs in quotes
                            });
                        }
                    }
                }
                if (selectedDepartment && selectedDepartment !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.department_id", operator: "=", value: selectedDepartment });
                }
                if (selectedSubDepartment && selectedSubDepartment !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.sub_department_id", operator: "=", value: selectedSubDepartment });
                }

                globalQuery.orderBy = ['htatm.department_id DESC', 'htatm.sub_department_id DESC', 'htatm.employee_code Asc',];

                globalQuery.joins = [
                    {
                        "table": `cmv_employee empl`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "empl.employee_id",
                                "right": "htatm.employee_id",
                            },
                            {
                                "left": "empl.company_id",
                                "right": "htatm.company_id",
                            },
                        ]
                    },
                    {
                        "table": `cm_department empldep`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "empldep.department_id",
                                "right": "htatm.department_id",
                            },

                        ]
                    },
                    {
                        "table": `cm_department emplsubdep`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "emplsubdep.department_id",
                                "right": "htatm.sub_department_id",
                            },

                        ]
                    },
                    {
                        "table": `cm_employees_workprofile emplworkprof`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "emplworkprof.employee_id",
                                "right": "empl.employee_id",
                            },
                        ]
                    },
                    {
                        "table": `hm_weeklyoff wkOff`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "emplworkprof.weeklyoff",
                                "right": "wkOff.weeklyoff_id",
                            },
                        ]
                    },

                ]
                let getAttendanceData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                const excludedKeysToDisplay = ["company_id", "department_id", "sub_department_id", "attendance_process_id",
                    "process_date", "attendance_month", "attendance_year", "attendance_flag",
                    "company_id", "company_branch_id", "department_id", "sub_department_id", "employee_id",
                ];


                if (getAttendanceData.length > 0) {
                    getAttendanceData = getAttendanceData.map((record, index) => {
                        const leaves = Math.max(0, record.month_days - record.valid_PresentDays);
                        const { month_days, valid_PresentDays, ...rest } = record; // Exclude month_days and validPresentDays if not needed
                        return {
                            sr_no: index + 1,
                            ...rest,
                            Month_Day: record.month_days,
                            leaves,
                            // W_Days: record.valid_PresentDays // Ensure valid_PresentDaysi is included
                            W_Days: record.total_salary_days // Ensure valid_PresentDaysi is included

                        }; // Include leaves in the second-to-last position
                    });
                    getAttendanceData = getAttendanceData.map(record => {
                        const [start, end] = record.shift_start_end_time
                            ? record.shift_start_end_time.split('-')
                            : [null, null];

                        let shiftHours = 0;

                        if (start && end) {
                            const startTime = new Date(`1970-01-01T${start}Z`);
                            const endTime = new Date(`1970-01-01T${end}Z`);

                            // Handle night shift (e.g., 22:00-06:00)
                            if (endTime < startTime) {
                                shiftHours = ((endTime - startTime + 86400000) / 3600000); // Add 24 hours
                            } else {
                                shiftHours = ((endTime - startTime) / 3600000);
                            }
                        }
                        const selectedYear = salaryYearComboRef.current.label;  // e.g., "2024"
                        const selectedMonth = salaryMonthComboRef.current.value; // e.g., "02" (February)
                        const weeklyOffName = record.weeklyoff_name; // e.g., "Sunday"

                        const weeklyOffCount = countWeeklyOffs(parseInt(selectedYear), parseInt(selectedMonth), weeklyOffName);
                        if (record.employee_type === "Worker") {
                            excludedKeysToDisplay.push("weeklyoff_name");
                            excludedKeysToDisplay.push("week_off_days");
                            excludedKeysToDisplay.push("total_salary_days");
                        }else{
                            excludedKeysToDisplay.push("skill_type");
                            excludedKeysToDisplay.push("job_type_rate");
                            excludedKeysToDisplay.push("total_salary_days");
                        }
                        if (record.employee_type === "Semi-Staff") {
                            let totalSalaryDays = record.monthly_hours / shiftHours;
                            totalSalaryDays = Math.round(totalSalaryDays); // Rounds to the nearest whole number

                            let totalDays = record.Month_Day - weeklyOffCount;
                            // If totalSalaryDays exceeds totalDays, cap it at totalDays
                            if (totalSalaryDays >= totalDays) {
                                totalSalaryDays = totalDays;
                            }
                            return {
                                ...record,
                                monthly_hours: record.monthly_hours,
                                W_Days: totalSalaryDays
                            };

                        } else {
                            const expectedMonthlyHours = shiftHours * record.W_Days;
                            return {
                                ...record,
                                monthly_hours: Math.min(record.monthly_hours, expectedMonthlyHours)

                            };
                        }
                    });
                   
                    const headersArray = Object.keys(getAttendanceData[0])
                        .filter(key => !excludedKeysToDisplay.includes(key))
                        .map(key => ({
                            Headers: formatTableHeader(key),
                            accessor: key
                        }));

                    setAttendanceDtlTblCols(headersArray);
                    setAttendanceDtlTblData(getAttendanceData);
                    console.table(getAttendanceData);

                } else {
                    setAttendanceDtlTblCols([]);
                    setAttendanceDtlTblData([]);
                }
            }
        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    }
    function countWeeklyOffs(year, month, weeklyOffDay) {
        let count = 0;
        const daysInMonth = new Date(year, month, 0).getDate(); // Get total days in the month

        for (let day = 1; day <= daysInMonth; day++) {
            const currentDay = new Date(year, month - 1, day).toLocaleString('en-us', { weekday: 'long' });
            if (currentDay === weeklyOffDay) {
                count++;
            }
        }
        return count;
    }
    //-----------------------------------Export functionality -------------------
    const FnExport = async () => {

        try {
            setIsLoading(true)
            if (attendanceDtlTblData.length !== 0) {
                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

                let filtrKeyValue = {};


                filtrKeyValue['0'] = "Month : " + salaryMonthComboRef.current.label
                filtrKeyValue['1'] = "Year : " + salaryYearComboRef.current.label

                let departmentName = departmentComboRef.current?.label || '';
                if (departmentName === '' || departmentName === 0 || departmentName === undefined) {
                    departmentName = 'All';
                }

                filtrKeyValue['2'] = "Department Name : " + departmentName;
                let subDepartmentName = subDepartmentComboRef.current?.label || '';
                // let subDepartmentName = document.getElementById('cmb_sub_department_id').value;
                if (subDepartmentName === '' || subDepartmentName === 0 || subDepartmentName === undefined) {
                    subDepartmentName = 'All';
                }

                filtrKeyValue['3'] = "Sub Department Name : " + subDepartmentName;
                let employeeType = employeeTypesComboRef.current?.field_name || '';
                if (employeeType === '' || employeeType === 0 || employeeType === undefined) {
                    employeeType = 'All';
                }

                filtrKeyValue['4'] = "Employee Type : " + employeeType;
                let employeeName = employeeComboRef.current?.employee_name || '';
                // let employeeName = document.getElementById('cmb_employee_id').value;
                if (employeeName === '' || employeeName === 0 || employeeName === undefined) {
                    employeeName = 'All';
                }

                filtrKeyValue['5'] = "Employee Name : " + employeeName;
                let contractorId = cmb_contractor_id_ref.current.label || "";
                if (contractorId !== '') {
                    filtrKeyValue['6'] = "Contractor Name : " + contractorId;
                }


                jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;
                jsonToExportExcel['columns'] = attendanceDtlTblCols;
                attendanceDtlTblData.map((punchDetails, Index) => {
                    jsonToExportExcel['allData'][Index] = punchDetails;
                });


                jsonToExportExcel['headings']['ReportName'] = "Monthly Punch Report"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = COMPANY_ADDRESS

                await exlsExp.current.excel(jsonToExportExcel, 'Monthly_Attendance' + '_For_' + salaryMonthComboRef.current.label + '-' + salaryYearComboRef.current.label)
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }

    // Component View Return
    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}


                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Monthly Attendance</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">

                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year <span className="required">*</span></Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryYearComboRef}
                                            options={yearOpts}
                                            inputId="cmb_salary_year"
                                            value={yearOpts.find(option => option.value == cmb_salary_year) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryYear(selectedOpt.value);
                                                salaryYearComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a year..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month <span className="required">*</span></Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryMonthComboRef}
                                            options={monthOpts}
                                            inputId="cmb_salary_month"
                                            value={monthOpts.find(option => option.value == cmb_salary_month) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryMonth(selectedOpt.value);
                                                salaryMonthComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a month..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                                <div className='row mt-1'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Contractor</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Select ref={cmb_contractor_id_ref}
                                            options={contractorOptions}
                                            inputId="cmb_contractor_id" // Provide the ID for the input box
                                            value={contractorOptions.length > 1 ? contractorOptions.find(option => option.value === cmb_contractor_id) : null}
                                            onChange={(selectedOpt) => {
                                                cmb_contractor_id_ref.current = selectedOpt;
                                                setContractorId(selectedOpt.value)
                                                FnComboOnChange('Contractor')
                                            }}
                                            placeholder="Search for a contractor..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_contractor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>
                            </div>
                            {/* First Column Ends*/}


                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnComboOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnComboOnChange("subDepartment");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}

                            {/* First Third Column Starts*/}
                            <div className="col-sm-5 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnComboOnChange('EmployeeType');
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Employee Name :<span className="required">*</span></Form.Label>
                                    </div>
                                    {/* <div className="col-3">
                                        <Form.Control type="text" id="txt_employee_code" className="erp_input_field " value={txt_employee_code}
                                            onChange={(e) => { FnComboOnChange('EmployeeCode'); }}
                                            onBlur={(e) => { FnComboOnChange('EmplCode'); }}
                                            maxlength="255" />
                                        <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div> */}
                                    {/* <div className="col-sm-4"> 
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'> */}
                                    <div className="col">
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                                FnComboOnChange('Employee');
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Third Column Ends*/}
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="save-btn" className="erp-gb-button" variant="button" fontWeight="regular" disabled={employeeOpts.length === 1}
                                    onClick={(e) => { FnLoadAttendanceData(); }}
                                >Show</MDButton>
                                <MDButton type="button" className="ms-2 erp-gb-button" onClick={FnExport} variant="button" fontWeight="regular" disabled={attendanceDtlTblData.length === 0}>Export To Excel</MDButton>

                            </div>
                        </div>

                        <hr />

                        <div className="row px-lg-2 d-block">
                            <div className="col-lg-12 col-12 erp_form_col_div">
                                <div className="card">
                                    <div className="card-header py-0 main_heding mb-0">
                                        <label className="erp-form-label-md-lg">Employee's Monthly Punching Details</label>
                                    </div>
                                    {/* Card's Body Starts*/}
                                    <div className="card-body p-0">
                                        {
                                            attendanceDtlTblData.length === 0
                                                ? <>
                                                    <Card id="NoRcrdId" >
                                                        <Card.Body>No records found...</Card.Body>
                                                    </Card>
                                                </>
                                                : <>
                                                    <Datatable data={attendanceDtlTblData} columns={attendanceDtlTblCols} freezeColumnCount={3} striped />
                                                </>
                                        }

                                        {/* <div className="row print-content" ref={printComponentRef} >
                                            {FnRenderReportTbl}

                                        </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>
                                {/* {
                                    attendanceDtlTblData.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2 print-button"

                                                onClick={(e) => { FnPrintImmediatePunch(); }} >Print</MDButton>
                                        </>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>

        </>
    )
}
export default MonthlyAttendance;