import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';

const GenericMonthlySalaryTable = ({
    earningHeads,
    deductionHeads,
    salarySummaryDtlTblData,
    salaryEarningDtlTblData,
    salaryDeductionDtlTblData,
    departmentName,
    subDepartmentName,
    isLastTable = false,
    allDataForGrandTotal,
}) => {
    // Destructure allDataForGrandTotal
    const { allEmployeesSummery, allEmployeesEarnings, allEmployeesDeductions } = allDataForGrandTotal;

    const tableContent = useMemo(() => {
        if (salarySummaryDtlTblData.length === 0) {
            return null;
        }

        return (
            <>
                <style>
                    {`
                        @page { 
                            size: legal landscape;
                            margin: 1%;
                        }
                        .erp_table {
                            font-size: 3px !important; /* Default font size */
                        }
                        .erp_table_th, .erp_table_td {
                            border: 1px solid black;
                            padding-top: 2px !important;
                            padding-bottom: 2px !important;
                        }
                    `}
                </style>

                <label className="erp-form-label-lg"> Department : {subDepartmentName} {`[${departmentName}]`}  </label>

                {/* table for Staff. */}
                <Table className="erp_table" id='MonthlySalaryDtlsTbl' style={{ whiteSpace: 'normal' }}>
                    <thead className="erp_table_head table-secondary text-center" style={{ whiteSpace: 'normal' }} >
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle" rowSpan="2" style={{ width: '10px' }}>Sr. No.</th>
                            <th className="erp_table_th align-middle" rowSpan="2" style={{ width: '20px' }}>Employee Code</th>
                            <th className="erp_table_th align-middle">Employee Name</th>
                            {/* <th className="erp_table_th align-middle" rowSpan="2" style={{ width: '45px' }}>Working Days</th> */}
                            <th className="erp_table_th align-middle" rowSpan="2" style={{ width: '45px' }}> Details of Attendence</th>
                            <th className="erp_table_th align-middle" rowSpan="2" style={{ width: '45px' }}> Rate of Wages</th>

                            {Array.from({ length: Math.max(earningHeads.length, deductionHeads.length) }, (_, index) => {
                                if (index < earningHeads.length) {
                                    return (
                                        <th key={`earning-${index}`} className="erp_table_th align-middle" style={{ width: '45px' }}> {earningHeads[index].earning_head_name} </th>
                                    );
                                } else {
                                    return (<th key={`empty-earning-${index}`} className="erp_table_th" style={{ width: '45px' }}> </th>);
                                }
                            })}
                            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Total Earning</th>
                            <th className="erp_table_th align-middle" rowSpan="2" style={{ width: '70px' }}>Net Salary</th>
                            <th className="erp_table_th pe-3 align-middle" rowSpan="2" style={{ width: '100px' }}>Signature</th>
                        </tr>

                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle">Designation</th>

                            {Array.from({ length: Math.max(earningHeads.length, deductionHeads.length) }, (_, index) => {
                                if (index < deductionHeads.length) {
                                    return (
                                        <th key={`deduction-${index}`} className="erp_table_th align-middle" style={{ width: '45px' }}> {deductionHeads[index].deduction_head_name} </th>
                                    );
                                } else {
                                    return (<th key={`empty-deduction-${index}`} className="erp_table_th" style={{ width: '45px' }}> </th>);
                                }
                            })}
                            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Total Deduction</th>
                        </tr>

                    </thead>
                    <tbody>
                        {/* Start content Rows  */}
                        {salarySummaryDtlTblData?.map((salaryDtl, index) => {
                            let currentEmployeeEarnings = salaryEarningDtlTblData.filter(earning => earning.employee_id === salaryDtl.employee_id);
                            let currentEmployeeDeductions = salaryDeductionDtlTblData.filter(deduction => deduction.employee_id === salaryDtl.employee_id);
                            return (
                                <React.Fragment key={`employee-${index}`}>
                                    <tr>
                                        <td className="erp_table_td" rowSpan="2">{index + 1}</td>
                                        <td className="erp_table_td" rowSpan="2">{salaryDtl.old_employee_code}</td>
                                        <td className="erp_table_td">{salaryDtl['employee_name']}</td>
                                        {/* <td className="erp_table_td text-end" rowSpan="2">{salaryDtl['salary_days']}</td> */}
                                        {/* Details of Attendance Column with only specified values */}
                                        
                                        <td className="erp_table_td" rowSpan="2" style={{ whiteSpace: 'pre-line' }}>
                                        <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between' }}>
                                                <span className="text_end">PR:</span>
                                                <span className="text_end">{salaryDtl['present_days']}</span>
                                            </div>
                                            <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between' }}>
                                                <span className="text_end">AB:</span>
                                                <span className="text_end">{salaryDtl['absent_days']}</span>
                                            </div>
                                            <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between' }}>
                                                <span className="text_end">LV:</span>
                                                <span className="text_end">{salaryDtl['leaves_days']}</span>
                                            </div>
                                            <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between' }}>
                                                <span className="text_end">WO:</span>
                                                <span className="text_end">{salaryDtl['week_off_days']}</span>
                                            </div>
                                            <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between' }}>
                                                <span className="text_end">HFD:</span>
                                                <span className="text_end">{salaryDtl['half_days']}</span>
                                            </div>
                                            <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between' }}>
                                                <span className="text_end">HD:</span>
                                                <span className="text_end">{salaryDtl['holiday_days']}</span>
                                            </div>
                                            <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between' }}>
                                                <span className="text_end">LD:</span>
                                                <span className="text_end">{salaryDtl['total_latemarks_days']}</span>
                                            </div>
                                         
                                            <hr />
                                            
                                            {/* Total Section */}
                                            <div style={{ width: '60px', display: 'flex', justifyContent: 'space-between', marginTop: '5px', fontWeight: 'bold' }}>
                                                <span>Total:</span>
                                                <span>
                                                    {
                                                        // (salaryDtl['leaves_days'] || 0) +
                                                        // (salaryDtl['half_days'] || 0) +
                                                        // (salaryDtl['holiday_days'] || 0) +
                                                        // (salaryDtl['week_off_days'] || 0) +
                                                        // (salaryDtl['absent_days'] || 0) +
                                                        (salaryDtl['total_salary_days'] || 0)
                                                    }
                                                </span>
                                            </div>
                                            {/* </tdx> */}

                                        </td>

                                        
                                        <td className="erp_table_td" rowSpan="2" style={{ whiteSpace: 'pre-line' }}>
                                            {earningHeads
                                                .filter(earningHead => !["GRS"].includes(earningHead.earning_head_short_name)) // Exclude GRS and AP
                                                .map((earningHead) => {
                                                    const currentEarning = currentEmployeeEarnings.find(
                                                        (earning) => earning.earning_heads_id === earningHead.earning_heads_id
                                                    );
                                                    const earningAmount = currentEarning ? currentEarning.earning_amount : 0;

                                                    return (
                                                        <div
                                                            key={earningHead.earning_heads_id}
                                                            style={{ width: '100px', display: 'flex', justifyContent: 'space-between' }}
                                                        >
                                                            <span className="text_end">{earningHead.earning_head_short_name}:</span>
                                                            <span className="text_end">{earningAmount}</span>
                                                        </div>
                                                    );
                                                })}
                                            <hr />

                                            {/* Total Section */}
                                            <div
                                                style={{
                                                    width: '100px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    marginTop: '5px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                <span>Total:</span>
                                                <span>
                                                    {
                                                        earningHeads
                                                            .filter(earningHead => !["GRS"].includes(earningHead.earning_head_short_name)) // Exclude GRS and AP in total calculation
                                                            .reduce((total, earningHead) => {
                                                                const currentEarning = currentEmployeeEarnings.find(
                                                                    (earning) => earning.earning_heads_id === earningHead.earning_heads_id
                                                                );
                                                                return total + (currentEarning ? currentEarning.earning_amount : 0);
                                                            }, 0)
                                                    }
                                                </span>
                                            </div>
                                        </td>




                                        {Array.from({ length: Math.max(earningHeads.length, deductionHeads.length) }, (_, index) => {
                                            if (index < earningHeads.length) {
                                                let currentEarning = currentEmployeeEarnings.find(earning => earning.earning_heads_id === earningHeads[index].earning_heads_id);
                                                return (
                                                    <td key={`emp-earning-${index}`} className="erp_table_td text-end" > {currentEarning?.earning_amount} </td>
                                                );
                                            } else {
                                                return (<td key={`emp-empty-earning-${index}`} className="erp_table_td" >  </td>);
                                            }
                                        })}
                                        <td className="erp_table_td text-end" style={{ width: '70px' }}>{salaryDtl['gross_salary']}</td>
                                        <td className="erp_table_td text-end" rowSpan="2" style={{ width: '70px' }}>{salaryDtl['net_salary']}</td>
                                        <td className="erp_table_td" rowSpan="2"></td>
                                    </tr>
                                    <tr>
                                        <td className="erp_table_td">{salaryDtl['designation_name']}</td>

                                        {Array.from({ length: Math.max(earningHeads.length, deductionHeads.length) }, (_, index) => {
                                            if (index < deductionHeads.length) {
                                                let currentDeduction = currentEmployeeDeductions.find(deduction => deduction.deduction_heads_id === deductionHeads[index].deduction_heads_id);
                                                return (
                                                    <td key={`emp-deduction-${index}`} className="erp_table_td text-end" > {currentDeduction?.deduction_amount} </td>
                                                );
                                            } else {
                                                return (<td key={`emp-empty-deduction-${index}`} className="erp_table_td" >  </td>);
                                            }
                                        })}
                                        <td className="erp_table_td text-end" style={{ width: '70px' }}>{salaryDtl['total_deduction']}</td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                        {/* Ends content Rows  */}

                        {/* Footer Summary Row Starts */}
                        <tr className='table-light'>
                            <td className="erp_table_td align-middle" rowSpan="2" colSpan='5'><strong>Department Summary:</strong> </td>

                            {Array.from({ length: Math.max(earningHeads.length, deductionHeads.length) }, (_, index) => {
                                if (index < earningHeads.length) {
                                    const sumAmount = salaryEarningDtlTblData.reduce((sum, earning) => {
                                        return earning.earning_heads_id === earningHeads[index].earning_heads_id
                                            ? sum + earning.earning_amount
                                            : sum;
                                    }, 0);

                                    return (<td key={`summary-earning-${index}`} className="erp_table_td text-end" > {sumAmount.toFixed(2)} </td>);
                                } else {
                                    return (<td key={`summary-empty-earning-${index}`} className="erp_table_td" >  </td>);
                                }
                            })}

                            <td className="erp_table_td text-end" style={{ width: '70px' }}>
                                {salarySummaryDtlTblData.reduce((sum, summary) => sum + summary.gross_salary, 0).toFixed(2)}
                            </td>
                            <td className="erp_table_td text-end" rowSpan="2" style={{ width: '70px' }}>
                                {salarySummaryDtlTblData.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2)}
                            </td>
                            <td className="erp_table_td" rowSpan="2"></td>
                        </tr>
                        <tr className='table-light'>
                            {Array.from({ length: Math.max(earningHeads.length, deductionHeads.length) }, (_, index) => {
                                if (index < deductionHeads.length) {
                                    const sumAmount = salaryDeductionDtlTblData.reduce((sum, deduction) => {
                                        return deduction.deduction_heads_id === deductionHeads[index].deduction_heads_id
                                            ? sum + deduction.deduction_amount
                                            : sum;
                                    }, 0);

                                    return (<td key={`summary-deduction-${index}`} className="erp_table_td text-end" style={{ width: '70px' }}> {sumAmount.toFixed(2)} </td>);
                                } else {
                                    return (<td key={`summary-empty-deduction-${index}`} className="erp_table_td" >  </td>);
                                }
                            })}
                            <td className="erp_table_td text-end" style={{ width: '70px' }}>
                                {salarySummaryDtlTblData.reduce((sum, summary) => sum + summary.total_deduction, 0).toFixed(2)}
                            </td>
                        </tr>
                        {/* Footer Summary Row Ends */}



                        {/* Show the Grand Totals Starts */}
                        {
                            isLastTable
                                ? <>
                                    <tr style={{ height: '20px' }}>
                                        <td className="erp_table_td" colSpan={7 + Math.max(earningHeads.length, deductionHeads.length)}></td>
                                    </tr>
                                    <tr className='table-light'>
                                        <td className="erp_table_td align-middle" rowSpan={2} colSpan={3}><strong>Grand Total:</strong> </td>
                                        <td className="erp_table_td" rowSpan="2"  style={{ width: '60PX' }}>
                                        
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ width: '70PX', display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>PR: </span>
                                                    <span>{allEmployeesSummery.reduce((sum, summary) => sum + (summary.present_days || 0), 0).toFixed(2)}</span>
                                                </div>
                                                <div style={{ width: '70PX', display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>AB: </span>
                                                    <span>{allEmployeesSummery.reduce((sum, summary) => sum + (summary.absent_days || 0), 0).toFixed(2)}</span>
                                                </div>
                                                <div style={{ width: '70PX', display: 'flex', justifyContent: 'space-between' }}>
                                                     <span>LV: </span>
                                                    <span className="text_end">{allEmployeesSummery.reduce((sum, summary) => sum + (summary.leaves_days || 0), 0).toFixed(2)}</span>
                                                </div>
                                                <div style={{ width: '70PX', display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>WO: </span>
                                                    <span>{allEmployeesSummery.reduce((sum, summary) => sum + (summary.week_off_days || 0), 0).toFixed(2)}</span>
                                                </div>
                                                <div style={{ width: '70PX', display: 'flex', justifyContent: 'space-between' }}>
                                                <span>HFD: </span>                                                    
                                                    <span className="text_end">{allEmployeesSummery.reduce((sum, summary) => sum + (summary.half_days || 0), 0).toFixed(2)}</span>
                                                </div>
                                                <div style={{ width: '70PX', display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>HD: </span>
                                                    <span>{allEmployeesSummery.reduce((sum, summary) => sum + (summary.holiday_days || 0), 0).toFixed(2)}</span>
                                                </div>
                                                <div style={{ width: '70PX', display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>LD: </span>
                                                    <span>{allEmployeesSummery.reduce((sum, summary) => sum + (summary.total_latemarks_days || 0), 0).toFixed(2)}</span>
                                                </div>

                                                {/* Add more fields here as needed */}
                                            </div>
                                        </td>
                                        <td className="erp_table_td text-end" rowSpan="2" style={{ width: '70px' }}>
                                        </td>


                                        {Array.from({ length: Math.max(earningHeads.length, deductionHeads.length) }, (_, index) => {
                                            if (index < earningHeads.length) {
                                                const sumAmount = allEmployeesEarnings.reduce((sum, earning) => {
                                                    return earning.earning_heads_id === earningHeads[index].earning_heads_id
                                                        ? sum + earning.earning_amount
                                                        : sum;
                                                }, 0);

                                                return (<td key={`summary-earning-${index}`} className="erp_table_td text-end" > {sumAmount.toFixed(2)}</td>);
                                            } else {
                                                return (<td key={`summary-empty-earning-${index}`} className="erp_table_td" >  </td>);
                                            }
                                        })}

                                        <td className="erp_table_td text-end" style={{ width: '70px' }}>
                                            {allEmployeesSummery.reduce((sum, summary) => sum + summary.gross_salary, 0).toFixed(2)}
                                        </td>
                                        <td className="erp_table_td text-end" rowSpan="2" style={{ width: '70px' }}>
                                            {allEmployeesSummery.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2)}
                                        </td>
                                        <td className="erp_table_td" rowSpan="2"></td>
                                    </tr>
                                    <tr className='table-light'>
                                        {Array.from({ length: Math.max(earningHeads.length, deductionHeads.length) }, (_, index) => {
                                            if (index < deductionHeads.length) {
                                                const sumAmount = allEmployeesDeductions.reduce((sum, deduction) => {
                                                    return deduction.deduction_heads_id === deductionHeads[index].deduction_heads_id
                                                        ? sum + deduction.deduction_amount
                                                        : sum;
                                                }, 0);

                                                return (<td key={`summary-deduction-${index}`} className="erp_table_td text-end" style={{ width: '70px' }}> {sumAmount.toFixed(2)} </td>);
                                            } else {
                                                return (<td key={`summary-empty-deduction-${index}`} className="erp_table_td" >  </td>);
                                            }
                                        })}
                                        <td className="erp_table_td text-end" style={{ width: '70px' }}>
                                            {allEmployeesSummery.reduce((sum, summary) => sum + summary.total_deduction, 0).toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr style={{ height: '70px' }}>
                                        <th className="erp_table_th align-bottom" colSpan={9 + deductionHeads.length + earningHeads.length}>
                                            <div className='row align-text-bottom text-center'>
                                                <div className='col-2 align-text-bottom text-center'>Prepared By</div>
                                                <div className='col-2 align-text-bottom text-center'>Checked By</div>
                                                <div className='col-2 align-text-bottom text-center'>HR Manager</div>
                                                <div className='col-2 align-text-bottom text-center'>Accounts Dept.</div>
                                                <div className='col-2 align-text-bottom text-center'>Accounts Manager</div>
                                                <div className='col-2 align-text-bottom text-center'>General Manager</div>
                                            </div>
                                        </th>
                                    </tr>
                                </>
                                : null
                        }
                        {/* Show the Grand Totals Ends */}


                    </tbody>
                </Table >
            </>
        );
    }, [earningHeads, deductionHeads, salarySummaryDtlTblData, salaryEarningDtlTblData, salaryDeductionDtlTblData]);
    return tableContent;
}

export default GenericMonthlySalaryTable;
