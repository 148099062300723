import React, { useEffect, useRef, useMemo, useLayoutEffect } from 'react'
import { useState } from 'react'
import $, { valHooks } from 'jquery';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import { Form, Tab, Table, Tabs, Card } from 'react-bootstrap';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import GenerateMaterialId from 'FrmGeneric/GenerateMaterialId/GenerateMaterialId';
import FrmWPDetailsEntry from './component/FrmWPDetailsEntry';
import FrmWPMaterialMovementEntry from './component/FrmWPMaterialMovementEntry';
import MDBox from 'components/MDBox';
import { Grid } from "@mui/material";
import Accordion from "react-bootstrap/Accordion";
import { FaDatabase } from "react-icons/fa";

import { Button, Modal } from 'react-bootstrap';


// Import for the searchable combo box.
import Select from 'react-select';
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { string } from 'prop-types';
import { FiPrinter } from "react-icons/fi";
import ReactToPrint from "react-to-print";


function getSteps() {
  return ["Warping Production Details", "Material Movement"];
}
function FrmWarpingProductionEntry(props) {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, UserId } = configConstants;
  const { state } = useLocation();
  const { weavingProductionWarpingMasterId = 0, keyForViewUpdate = 'Add', compType } = state || {}
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const handleNext = () => { setActiveStep((parseInt(activeStep) + 1)) };
  const handleBack = () => setActiveStep((parseInt(activeStep) - 1));
  //Current date
  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  //useRef Hooks
  const comboDataAPiCall = useRef();
  const cmb_prod_supervisor_id_ref = useRef();
  const cmb_set_no_ref = useRef(null);

  const selectRefs = useRef([]);

  const handleSelectChange = (item, index, event) => {
    const currentSelectRef = selectRefs.current[index];
    updatedwarpingProductionDetails('production_operator_id', item, currentSelectRef, event);
  };

  // const combobox = useRef();
  const generateAutoNoAPiCall = useRef();
  const navigate = useNavigate();
  const validateNumberDateInput = useRef();
  const validate = useRef();
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')
  const [isLoading, setIsLoading] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  //Warping Production master hooks
  const [txt_weaving_production_warping_master_id, setweavingProductionWarpingMasterId] = useState(weavingProductionWarpingMasterId);
  const [dt_warping_production_date, setProdPlanDate] = useState(today);
  const [txt_prod_month, setProdMonth] = useState();
  const [txt_prod_year, setProdYear] = useState();
  const [cmb_plant_id, setProdPlantName] = useState(1);
  const [txt_productionWarpingStatus, setProductionWarpingStatus] = useState('P');
  const [cmb_section_id, setProdSection] = useState(17);
  const [cmb_sub_section_id, setProdSubSection] = useState(21);
  const [cmb_shift, setShift] = useState('');
  const [cmb_creel_no, setCreelNo] = useState();
  const [cmb_warping_production_code, setWarpingProductionCode] = useState();
  const [txt_warping_prod_order_code, setWarpingOrderProdCode] = useState();
  const [no_of_ends, setNoOfEnds] = useState();
  const [txt_yarn_count, setYarnCount] = useState();
  const [production_count, setProductionCount] = useState();
  const [cmb_prod_supervisor_id, setProdSupervisor] = useState();
  const [chk_isactive, setIsActive] = useState(true);
  const [cmb_set_no, setProdSetNo] = useState();
  const [txt_prod_style, setProdStyle] = useState();
  const [txt_set_length, setSetLength] = useState();
  const [txt_schedule_quantity, setScheduleQty] = useState();
  const [txt_prod_material_name, setProdMaterialName] = useState();
  const [prod_material_id, setProdMaterialId] = useState();
  const [warping_prod_constant, setWarpingProdConstant] = useState();
  const [txt_no_of_beams, setNoOfBeams] = useState();

  //Warping Production  details comboboxes
  const [warpingProductionData, setWarpingProductionData] = useState([])
  const [warpingProductionMaterialData, setWarpingProductionMaterialData] = useState([])
  const [warpingBottomDetailsData, setWarpingBottomDetailsData] = useState([]);
  const [setNoOptions, setSetNoOptions] = useState([]);
  const [plantOptions, setPlantOptions] = useState([]);
  const [bottomtyretypeoptions, setBottomTyreOptions] = useState([]);
  const [conetypeoptions, setConeTypeOptions] = useState([]);
  const [prodsectionOptions, setProdSectionOptions] = useState([]);
  const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [machineOperatorOptions, setMachineOperatorOptions] = useState([]);
  const [shiftNames, setShiftNames] = useState([]);
  const [beamSizeOptions, setBeamSizeOptions] = useState([]);

  ///Warping Production Hooks
  // const [prodSummaryData, setProductionSummaryData] = useState([]);
  const [machineNameOption, setMachineNameOption] = useState([]);

  ///Shiftwise Machine Stoppage Entry Hooks
  const [cmb_machine_no, setMachineNo] = useState();
  const [cmb_stoppage_reason, setMachineStoppageReason] = useState();
  const [txt_stoppage_time, setMachineStoppageTime] = useState();
  const [from_time, setFromTime] = useState();
  const [to_time, setToTime] = useState();
  const [total_time, setTotalTime] = useState();
  const [cmb_loss_type, setLossType] = useState();
  const [txt_std_stoppage_loss_kg, setStdProdLoss] = useState();
  const [txt_actual_production_loss_kg, setActProdLoss] = useState();
  const [txt_stoppage_production_loss_kg, setStoppageProductionLossKg] = useState();
  const [txt_std_stoppage_loss_per_hour, setStdStoppageLossPerHour] = useState();
  const [labelForStoppage, setLabelForStopage] = useState('Add');

  ///Shiftwise Machine Stoppage Entry Comboboxes
  const [stoppagereasonOptions, setProdStoppageReasonOptions] = useState([]);
  const [stoppageDetails, setStoppageDetails] = useState([]);
  const [prevdataindexforStoppage, setPrevDataIndexForStoppage] = useState();

  // Material Movement Materials
  // const [materialMovementMaterial, setMaterialMovementMaterials] = useState([])
  const [materialMovementLotNos, setMaterialMovementLotNos] = useState([])


  /// Warping Prod Plan Creel Details
  const [creelOptions, setCreelOptions] = useState([]);

  const [spinningProdCountOptions, setSpinngProdCountOptions] = useState([]);
  const [ringFrameProdShiftData, setRingFrameProdShiftData] = useState([]);

  // Shiftwise Machine Wastage Entry Hooks
  const [cmb_prod_wastage_type, setProdWastageType] = useState();
  const [txt_act_wastages, setActWastage] = useState();
  const [labelForWastage, setLabelForWastage] = useState('Add');
  const [prevdataindexforWastage, setPrevDataIndexForWastage] = useState();
  const [txt_wastage_quantity, setWastageQuantity] = useState();
  const [cmb_godownId, setgodownId] = useState();
  const [cmb_shift_wastage, setShiftWastage] = useState();
  const [rowCount, setRowCount] = useState(1)

  //Shiftwise Machine Wastage Entry Combobox
  const [prodWastageOptions, setProdWastageOptions] = useState([]);
  const [godownNameOptions, setGodownNameOptions] = useState([]);
  const [wastageDetails, setWastageDetails] = useState([]);

  //Error Msg
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Transactions/TWarpingProduction/FrmWarpingProductionListing`)
    }
  }

  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  ////Warping Production Totals Obj
  const TotalsWarpingProd = {
    speed: 0,
    creel_ends: 0,
    length: 0,
    cut_cones: 0,
    guccha: 0,
    thin_places: 0,
    week_places: 0,
    week_splice: 0,
    sluff_off: 0,
    slub_yarn: 0,
    total_breaks: 0,
    breaks_per_million: 0
  }

  ///Bottom Details Total Obj
  const BottomDetails = {
    no_of_boxes: 0,
    no_of_package: 0,
    net_weight: 0,
    gross_weight: 0,
    tare_weight: 0,
  }


  // Session Expired modal
  const [materialStockList, setMaterialStockList] = useState([])
  const [show, setShow] = useState(false);
  const closeConsumptionModal = () => setShow(false)

  // Fields for consumption qty
  const [materialName, setMaterialName] = useState()
  const [unitName, setUnitName] = useState()
  const [consumptionQty, setConsumptionQty] = useState()

  const [TotalsWarpingProdObj, setTotalsWarpingProdObj] = useState(TotalsWarpingProd);
  const [TotalBottomDetailsObj, setTotalBottamDetailsObj] = useState(BottomDetails);


  /// wp production blank Object
  const warpingProductionBlankObject = {
    company_id: COMPANY_ID,
    company_branch_id: COMPANY_BRANCH_ID,
    warping_production_date: today(),
    weaving_production_set_no: 0,
    machine_id: 0,
    shift: 0,
    yarn_count: 0,
    product_rm_id: '',
    production_operator_id: 0,
    actual_count: 0,
    speed: 0,
    cut_cones: 0,
    guccha: 0,
    thin_places: 0,
    week_places: 0,
    week_splice: 0,
    sluff_off: 0,
    slub_yarn: 0,
    total_breaks: 0,
    creel_ends: 0,
    // no_of_creels: 0,
    total_pkg_used: 0,
    weight_per_pkg: 0,
    status_remark: '',
    warping_production_status: 'P',
    total_weight_issue_to_warping: 0,
    t_ends: 0,
    length: 0,
    net_weight: 0,
    exp_bottom: 0,
    breaks_per_million: 0,
    act_bottom: 0,
    bottom_percent: 0,
    created_by: UserName,
    godown_id: 5
  }

  /// warping material Blank Object
  const warpingMaterialBlankObject = {
    company_id: COMPANY_ID,
    company_branch_id: COMPANY_BRANCH_ID,
    warping_production_date: today(),
    weaving_production_set_no: 0,
    shift: '',
    warping_production_order_no: '',
    status_remark: '',
    material_status: 'P',
    weaving_production_warping_material_id: 0,
    product_material_balance_quantity: 0,
    product_material_id: "",
    product_material_unit_id: 0,
    consumption_quantity: 0,
    product_material_quantity: 0,
    created_by: UserName,
  }

  const [last_sr_no, setLastSrNo] = useState();
  const [txt_calculative_bottom_kg, setCalculativeBottomKg] = useState(0);
  const [txt_calculative_bottom_percent, setCalculativeBottomPer] = useState(0);
  const [txt_actual_bottom_kg, setActualBottomKg] = useState(0);
  const [txt_actual_bottom_percent, setActualBottomPer] = useState(0);
  const [txt_difference_bottom_kg, setDiffBottomKg] = useState(0);
  const [txt_difference_bottom_percent, setDiffBottomPer] = useState(0);
  const [warping_issue_kg, setWarpingIssueKg] = useState();
  const [warping_issue_cones, setWarpingIssueCones] = useState();
  const [warping_issue_boxes, setWarpingIssueBoxes] = useState();
  // const [batch_no, setBatchNo] = useState();
  const [supplier_id, setSupplierId] = useState();
  const [supplier_name, setSupplierName] = useState();
  const [formatedDate, setFormatedDate] = useState();
  // const [goods_receipt_no, setGoodsRecieptNo] = useState();

  ////Bottom Details Obj
  const bottomDetailsObj = {
    sr_no: '',
    warping_bottom_details_production_date: today(),
    weaving_production_warping_bottom_details_id: 0,
    // creel_no: '',
    no_of_package: 0,
    net_weight: 0,
    gross_weight: 0,
    // no_of_boxes: 0,
    tare_weight: 0,
    package_type: [],
    cone_type: [],
    cone_type_value: ''
  }


  useEffect(() => {
    const loadDataOnload = async () => {
      setIsLoading(true)
      await ActionType()
      await fillCombos();
      setMonthYear();

      if (keyForViewUpdate === 'Add') {
        fillAutoNo();
      }

      // FnGetShiftDetails(today)
      setIsLoading(false)
    }
    loadDataOnload()
  }, [])

  const ActionType = async () => {
    setIsLoading(true)
    // debugger
    switch (keyForViewUpdate) {
      case 'update':
        setIsApprove(false);
        setActionType('(Modification)');
        setActionLabel('Update')
        await validate.current.readOnly("Warpingproduction");
        break;
      case 'view':
        setIsApprove(false);
        setActionType('(View)');
        await validate.current.readOnly("Warpingproduction");
        break;
      case 'approve':
        setActionType('(Approve)');
        setActionLabel('Approve')
        $("form").find("input,textarea,select").attr("disabled", "disabled");
        $("table").find("input,button,textarea,select").attr("disabled", "disabled");
        $('table .approveField').removeAttr("disabled");
        $("input[type=radio]").attr('disabled', true);
        setIsApprove(true)
        break;
      default:
        setActionType('(Creation)');
        break;
    }
    setIsLoading(false)
  };


  const FnLoadBeamNoOptions = (approvedBeamNoOptions, beamSizeOptionsArr) => {
    // debugger
    try {
      if (keyForViewUpdate === 'update') {
        // let beamSizeOptionsArr = [...beamSizeOptions];

        // Filter the beam options based on approvedBeamNoOptions
        const requiredBeamOptions = beamSizeOptionsArr.filter((beam) =>
          !approvedBeamNoOptions.includes(beam.property_id)
        );

        // Set filtered beam options
        setBeamSizeOptions(requiredBeamOptions);
      }
    } catch (error) {
      console.log("Error in FnLoadBeamNoOptions: ", error);
    }
  };




  /// function for get update data
  const FnCheckUpdateResponce = async (beamSizeOptionsArr) => {
    debugger
    try {
      setIsLoading(true);
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionWarping/FnShowParticularRecordForUpdate/${weavingProductionWarpingMasterId}/${COMPANY_ID}`)
      const updateRes = await apiCall.json();
      const warpingProductionDetails = updateRes.WVProductionWarpingDetailModelRecords
      const masterdata = updateRes.WVProductionWarpingMasterModelRecords
      const materialData = updateRes.WeavingProductionWarpingMaterialRecords
      const warpingProductionBottomData = updateRes.WeavingProductionWarpingBottomDetails

      const WarpingProductionOrderCreelsRecords = updateRes.WarpingProductionOrderCreelsRecords;

      setNoOfBeams(masterdata['no_of_beams']);
      

      setCreelOptions(WarpingProductionOrderCreelsRecords);
      setProductionCount(warpingProductionDetails[0]['actual_count']);
      setYarnCount(warpingProductionDetails[0]['yarn_count']);
      const cone_per_wt = warpingProductionDetails[0]['weight_per_pkg'];


      setProdSetNo(masterdata.set_no);
      cmb_set_no_ref.current.value = masterdata.set_no;
      // FnGetWarpingRawMaterials(masterdata.set_no)
      setScheduleQty(masterdata['schedule_quantity']);
      setSetLength(masterdata['set_length']);
      setProdMaterialName(masterdata['product_material_name']);
      setSupplierId(masterdata['supplier_id']);
      setSupplierName(masterdata['supplier_name']);
      
      // setGoodsRecieptNo(warpingProductionBottomData[0]['goods_receipt_no']);
      setProdStyle(masterdata['product_material_style']);
      setWarpingOrderProdCode(masterdata['warping_order_no']);
      setWarpingIssueKg(masterdata['warping_issue_kg']);
      // setBatchNo(masterdata['batch_no']);
      setWarpingIssueCones(masterdata['warping_issue_quantity'])
      setWarpingIssueBoxes(masterdata['warping_issue_boxes']);
      setNoOfEnds(warpingProductionDetails[0]['t_ends']);

      //id for yarn count
      setProdMaterialId(warpingProductionDetails[0]['product_rm_id']);
      setweavingProductionWarpingMasterId(masterdata.weavingProductionWarpingMasterId)
      let warping_Shifts = [];
      warpingProductionDetails.forEach((item, index) => {
        warping_Shifts.push(item.shift);
      });
      setProdPlanDate(masterdata.warping_production_date)
      setProdMonth(masterdata.prod_month)
      setWarpingProductionCode(masterdata.warping_production_code)
      setProdYear(masterdata.prod_year)
      setProdPlantName(masterdata.plant_id);
      setProdSection(masterdata.section_id)
      setProdSubSection(masterdata.sub_section_id)
      await comboOnChange('cmb_section_id');
      setProdSupervisor(masterdata.production_supervisor_id)
      cmb_prod_supervisor_id_ref.current.value = masterdata.production_supervisor_id;
      ///Setting Calculations
      setCalculativeBottomKg(masterdata.calculative_bottom_kg);
      setCalculativeBottomPer(masterdata.calculative_bottom_percent);
      setActualBottomKg(masterdata.actual_bottom_kg);
      setActualBottomPer(masterdata.actual_bottom_percent);
      setDiffBottomKg(masterdata.difference_bottom_kg);
      setDiffBottomPer(masterdata.difference_bottom_percent);


      // let approvedBeamNoOptions = [];

      // // Map the data and modify each item
      // let processedData = warpingProductionDetails.map(item => {
      //   if (item.warping_production_status === 'A') { approvedBeamNoOptions.push(parseInt(item['beam_no'])) }
      //   return {
      //     ...item,
      //     production_sub_section_godown_id: item.godown_id,
      //   };
      // });

      // if (Array.isArray(approvedBeamNoOptions)) {
      //   FnLoadBeamNoOptions(approvedBeamNoOptions, beamSizeOptionsArr);
      // }


      let finalWarpingProdData = warpingProductionDetails.map((detail, index) => {
        let updatedDetail = { ...detail, weight_per_pkg: cone_per_wt };
        return updatedDetail;
      });

      // Set the updated data
      setWarpingProductionData(finalWarpingProdData);


      // let totalsBottomDetails = { ...BottomDetails };
      if (warpingProductionBottomData.length === 0) {
        // setTotalBottamDetailsObj([totalsBottomDetails]);
        const lastSrNo = parseInt($("#last_sr_no").val()) || 0;
        bottomDetailsObj['sr_no'] = `${masterdata.set_no || ''}-${lastSrNo + 1}`;
        setWarpingBottomDetailsData([bottomDetailsObj]);

      } else {
        // warpingProductionBottomData.forEach((obj) => {
        //   Object.keys(totalsBottomDetails).forEach((key) => {
        //     totalsBottomDetails[key] += parseFloat(obj[key]) || 0; // Sum each property, default to 0 if the property is missing
        //   });
        // });
        // setTotalBottamDetailsObj(totalsBottomDetails);
        setWarpingBottomDetailsData(warpingProductionBottomData);
      }
      if (parseInt(masterdata['no_of_beams']) === warpingProductionDetails.length) {
        $(".hidecolumns").hide();
      }


      console.log("warpingBottomDetailsData" + warpingBottomDetailsData);

    } catch (error) {
      console.log("error: ", error)
      // navigate('/Error')
    } finally {
      setIsLoading(false);
    }
  }

  $(document).on('click', '.erp_input_field_table_txt.remove0', function () {
    if ($(this).val() === "0") {
      $(this).val("")
    }
  });
  $(document).on('mouseup mousedown', function (e) {
    let inputBoxes = $(".erp_input_field_table_txt.remove0");
    inputBoxes.each(function () {
      if ($(this).val() === "") {
        $(this).val("0");
      }
    });
  });


  /// function for generate warping production
  const fillAutoNo = async () => {
    // debugger
    try {
      const productionDate = $('#dt_warping_production_date').val();
      const [year, month, day] = productionDate.split('-');
      const formatted_date = `${day}${month}${year.slice(2)}`;
      await comboOnChange('cmb_section_id');
      const subSectionVal = $('#cmb_sub_section_id').val();
      const shortName = document.querySelector('#cmb_sub_section_id option:checked').getAttribute('shortName');
      const warpingProductionCode = await generateAutoNoAPiCall.current.generateProductionCode({
        "entity_name": "xt_weaving_production_warping_master",
        "sub_section": subSectionVal,
        "sub_section_short_name": shortName,
        "field_name": "warping_production_code",
        "trans_no_length": "4",
        "transaction_date": formatted_date,
        "company_id": COMPANY_ID
      });
      setWarpingProductionCode(warpingProductionCode);

    } catch (error) {
      console.log('error: ', error);
      navigate('/Error')
    }
  };

  /// function for combo on change 
  const comboOnChange = async (key) => {
    // debugger
    try {

      switch (key) {
        case 'cmb_section_id':
          const prod_section_Id = $('#cmb_section_id').val();

          ///// Production sub section Arr[]
          resetGlobalQuery();
          globalQuery.columns.push("field_id", 'field_name', 'production_sub_section_short_name');
          globalQuery.table = "xmv_production_sub_section"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: prod_section_Id });
          let getProdSubSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          setProdSubSectionOptions(getProdSubSectionApiCall)
          setProdSubSection('22');



          const prodsubSectionId = $('#cmb_sub_section_id').val();

          resetGlobalQuery();
          globalQuery.columns.push("field_id", 'field_name');
          globalQuery.table = "cmv_machine"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "section_id", operator: "=", value: prod_section_Id });
          globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
          let getMachineApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          setMachineNameOption(getMachineApiCall);
          console.log("getMachineApiCall :" + getMachineApiCall);



          /////Employees in Warping Production
          resetGlobalQuery();
          globalQuery.columns.push("field_id", 'field_name', 'employee_type');
          globalQuery.table = "cmv_employee"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "department_id", operator: "=", value: 23 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
            const prodSupervisorList = [
              { value: '', label: 'Select' },
              ...getEmployeeApiCall.map((reporting) => ({ ...reporting, value: reporting.field_id, label: reporting.field_name, })),
            ];
            setMachineOperatorOptions(prodSupervisorList);
          })

          ////Godown Options
          resetGlobalQuery();
          globalQuery.columns = ['field_id', 'field_name', 'godown_name', 'godown_id']
          globalQuery.table = "cmv_godown"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          // globalQuery.conditions.push({ field: "production_sub_section_id", operator: "=", value: prodsubSectionId });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((godownTypeApiCall) => {
            setGodownNameOptions(godownTypeApiCall)
          })
          break;

        case 'cmb_plant_id':
          let plantId = $('#cmb_plant_id').val();
          setProdPlantName(plantId);
          break;


        case 'cmb_set_no':
          setIsLoading(true);
          let set_no = cmb_set_no_ref.current.value;
          setProdSetNo(set_no);

          ////Set No Options in Usage..........
          resetGlobalQuery();
          globalQuery.columns.push('set_no');
          globalQuery.table = "xt_weaving_production_warping_master";
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "set_no", operator: "=", value: set_no });
          const SetNosInUsageAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          if (SetNosInUsageAPICall.length > 0) {
            let setNoInsUsageArr = SetNosInUsageAPICall.map(setNo => setNo.set_no); // Extract set_no values
            if (setNoInsUsageArr.includes(set_no)) { $("#error_cmb_set_no").css('padding-right', '2px').text('Set Number Already in usage! Please Select another Set Number').show(); setIsLoading(false); }
            break;
          }

          $("#error_cmb_set_no").hide();
          let masterDataAPICall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionWarping/FnGetWarpingMasterData/${set_no}/${COMPANY_ID}`);
          const response = await masterDataAPICall.json();
          const masterData = response.WarpingMasterData;
          // Ensure masterData exists and has at least one item
          if (masterData?.[0]?.total_issued_quantity === 0) {
            $("#error_cmb_set_no").css('margin-start', '10px').text('Yarn was not requisitioned against the set number. Please Do yarn Requisition Against this Set No').show();
            $("#approveBtn").prop('disabled', true); // Use .prop() instead of .attr() for boolean attributes
            return 0;
          } else {
            $("#error_cmb_set_no").hide();
            $("#approveBtn").prop('disabled', false);
          }

          const {
            schedule_quantity,
            set_length,
            product_material_name,
            // product_material_id,
            product_material_style,
            warping_order_no,
            t_ends,
            product_material_id,
            total_issued_weight,
            total_issued_quantity,
            total_issued_boxes,
            // batch_no,
            supplier_id,
            // goods_receipt_no
          } = masterData[0];

          // Use the destructured values to update the state
          setScheduleQty(schedule_quantity);
          setSetLength(set_length);
          setProdMaterialName(product_material_name);
          setProdMaterialId(product_material_id);
          setProdStyle(product_material_style);
          setWarpingOrderProdCode(warping_order_no);
          setNoOfEnds(t_ends); // t_ends is assumed to be defined elsewhere
          setWarpingIssueKg(total_issued_weight);
          setSupplierId(supplier_id);
          // setGoodsRecieptNo(goods_receipt_no + "-" + set_no);
          setWarpingIssueBoxes(total_issued_boxes);
          setWarpingIssueCones(total_issued_quantity);
          // setBatchNo(batch_no);

          const formatCreelName = (creel_name) => {
            return creel_name.split('(')[0].trim();
          };

          // Setting Creel Data as Key-Value Pairs
          // const creeloptions = Array.from(
          //   new Set(masterData.map((creel) => formatCreelName(creel['creel_no'])))
          // ).map((creel) => ({ 'creel_no': creel }));

          // setCreelOptions(creeloptions);


          setProductionCount(masterData[0]['actual_count']);
          setYarnCount(masterData[0]['yarn_count']);
          const cone_per_wt = masterData[0]['cone_per_wt'];
          setNoOfBeams(masterData[0]['no_of_beams']);


          let blankObjectArr = [];
          let blankObj = {
            ...warpingProductionBlankObject, weight_per_pkg: cone_per_wt, yarn_count: masterData[0]['yarn_count'], product_rm_id: product_material_id
          };
          blankObjectArr.push(blankObj);


          //Calculating calculated_bottom_kg
          let calculated_bottom_kg = masterData[0]['calculated_bottom_total'];
          setCalculativeBottomKg(calculated_bottom_kg);
          if (calculated_bottom_kg !== 0) {
            setCalculativeBottomPer(validateNumberDateInput.current.decimalNumber(((calculated_bottom_kg * 100) / total_issued_weight), 2));
          } else {
            setCalculativeBottomPer(0);
          }

          ////Pushing Bottom Details Empty Data
          let blankObjectBottomArr = [];
          let generateSrNo = parseInt(last_sr_no);

          // for (let i = 0; i < creeloptions.length; i++) {
          blankObjectBottomArr.push({ ...bottomDetailsObj, ['sr_no']: `${set_no}-${generateSrNo + 1}` });
          generateSrNo = parseInt(generateSrNo + 1);
          // }
          setWarpingBottomDetailsData([...blankObjectBottomArr]);

          setWarpingProductionData(blankObjectArr);
          // FnGetWarpingRawMaterials(set_no);

          break;
      }
    } catch (error) {
      console.log('error: ', error);
      navigate('/Error')

    } finally {
      setIsLoading(false);
    }
  }


  // const FnGetWarpingRawMaterials = (set_no) => {
  //   resetGlobalQuery();
  //   globalQuery.columns = ['stk.product_rm_id AS product_material_id', 'stk.product_rm_name AS product_material_name', 'stk.weight_per_box_item AS cone_per_wt',
  //     'stk.closing_balance_quantity', 'stk.closing_balance_weight', 'stk.closing_no_of_boxes', 'stk.product_material_unit_id', 'stk.supplier_name', 'stk.batch_no', 'stk.goods_receipt_no'];

  //   globalQuery.table = "st_indent_material_issue_master st"
  //   globalQuery.conditions.push({ field: "st.company_id", operator: "=", value: COMPANY_ID, });
  //   globalQuery.conditions.push({ field: "st.set_no", operator: "=", value: set_no });
  //   globalQuery.conditions.push({ field: "st.issue_status", operator: "=", value: 'MI' });
  //   globalQuery.joins = [
  //     {
  //       "table": "st_indent_material_issue_details stm",
  //       "type": "inner",
  //       "on": [{
  //         "left": "stm.issue_master_transaction_id",
  //         "right": "st.issue_master_transaction_id"
  //       },
  //       {
  //         "left": "st.indent_issue_type_id",
  //         "right": 12
  //       }]
  //     },
  //     {
  //       "table": "smv_product_rm_stock_details stk",
  //       "type": "inner",
  //       "on": [{
  //         "left": "stk.goods_receipt_no",
  //         "operator": "like",
  //         "right": "CONCAT('%', stm.goods_receipt_no, '%')"
  //       },
  //       {
  //         "left": "stk.godown_id",
  //         "right": 6
  //       },
  //       {
  //         "left": "stk.product_type_id",
  //         "right": 12
  //       }]
  //     },

  //   ]

  //   comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getProductMaterials) => {
  //     setMaterialMovementMaterials(getProductMaterials);
  //   })
  // }

  const fillCombos = async () => {
    // debugger
    try {
      setIsLoading(true);
      ////Plant Option Arr[]
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name'];
      globalQuery.table = "cmv_plant"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getProdPlantApiCall) => {
        setPlantOptions(getProdPlantApiCall);
      })

      //Bottom Yarn Tare Type
      resetGlobalQuery();
      globalQuery.columns = ['properties_master_name'];
      globalQuery.table = "am_properties_master"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getBottomYarnTypeApiCall) => {
        setBottomTyreOptions(getBottomYarnTypeApiCall);
      })

      resetGlobalQuery();
      globalQuery.columns = ['properties_master_name', 'property_name', 'property_value', 'property_id'];
      globalQuery.table = "am_properties"
      globalQuery.conditions.push({ field: "property_group", operator: "=", value: 'BYTT' });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getConeTypeApiCall) => {
        setConeTypeOptions(getConeTypeApiCall);
      })


      const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts')
      setShiftOptions(shiftsApiCall);
      let shift_Names = [];
      shiftsApiCall.map((shift) => {
        shift_Names.push(shift.property_name)
      })
      setShiftNames(shift_Names);


      ////Production Section
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name'];
      globalQuery.table = "xmv_production_section"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWarpingProdSecCall) => {
        setProdSectionOptions(getWarpingProdSecCall);
        setProdSection('18');
        // if (keyForViewUpdate === 'Add') {
        comboOnChange('cmb_section_id');
        // }

      })

      // comboDataAPiCall.current.fillMasterData("cmv_machine", "", "").then((getMachineNosApiCall) => {
      //   setMachineNumData(getMachineNosApiCall);
      // })


      resetGlobalQuery();
      globalQuery.columns.push('set_no');
      globalQuery.table = "xt_warping_production_order";
      globalQuery.conditions.push({ field: "warping_order_status", operator: "=", value: 'A' });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.orderBy = ['set_no DESC']
      let SetNoApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      const setNoList = [
        { value: '', label: 'Select' },
        ...SetNoApiCall.map((setNo) => ({ value: setNo.set_no, label: setNo.set_no }))
      ];
      setSetNoOptions(setNoList);

      await FnGetLastsrNo();

      ///Prouduction Plan Constant
      resetGlobalQuery();
      globalQuery.columns.push('warping_plan_product_constant');
      globalQuery.table = "xt_production_settings";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((prodConstantAPICall) => {
        setWarpingProdConstant(prodConstantAPICall[0]['warping_plan_product_constant']);
      })


      let blankObjectArr = [];
      blankObjectArr.push(warpingMaterialBlankObject);
      setWarpingProductionMaterialData(blankObjectArr);

      //warping production  Summary Details
      // let jsonForSummaryData = {};
      // for (let key = 0; key < shift_Names.length; key++) {
      //   let data = {
      //     warping_total_legnth: 0,
      //     stoppage_time: 0,
      //     warping_total_act_bottom: 0,
      //     total: 0,
      //     total_up_date: 0

      //   }
      //   jsonForSummaryData[shift_Names[key]] = data;
      // }
      // setProductionSummaryData(jsonForSummaryData);


      //Beam Size No
      let getBeamsAPICall = await comboDataAPiCall.current.fillComboBox('WarpingBeams')
      setBeamSizeOptions(getBeamsAPICall);

      if (weavingProductionWarpingMasterId !== 0) {
        await FnCheckUpdateResponce(getBeamsAPICall);
      }


    } catch (error) {
      console.log('error: ', error);
      navigate('/Error')

    } finally {
      setIsLoading(false);
    }
  }

  const FnGetLastsrNo = async () => {
    //For Serial No against SET NO.
    let reqDate = (`${dt_warping_production_date.split('-')[0] + '-' + dt_warping_production_date.split('-')[1]}`);

    let lastSrNo;
    resetGlobalQuery();
    globalQuery.columns.push('sr_no');
    globalQuery.table = "xt_weaving_production_warping_bottom_details";
    globalQuery.conditions.push({ field: "warping_bottom_details_production_date", operator: "LIKE", value: `${reqDate}-%` });
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    let sr_NoAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    if (sr_NoAPICall.length > 0) {
      lastSrNo = parseInt(sr_NoAPICall[sr_NoAPICall.length - 1]?.['sr_no']?.split('-')[1])
      setLastSrNo(lastSrNo);
    } else {
      lastSrNo = 0
      setLastSrNo(lastSrNo);
    }
    return lastSrNo;
  }

  const setMonthYear = () => {
    let prod_Plan_Dt = $("#dt_warping_production_date").val();
    if (prod_Plan_Dt) {
      setProdPlanDate(prod_Plan_Dt);
      let dateComponents = prod_Plan_Dt.split('-');
      setProdMonth(dateComponents[1]);
      setProdYear(dateComponents[0]);
    }
  }


  const validateFields = () => {
    validate.current.validateFieldsOnChange('Warpingproduction')
  }

  /// table for summary
  // const renderproductionsummary = useMemo(() => {
  //   let namesOfTheShift = [...shiftNames];
  //   const prodSummaryRowsFirstCol = ['Production(Length)', 'Actual Bottom', 'Stoppage Time(mins)'];
  //   return (
  //     // <div style={{ display: "block", overflow: "auto", height: "220px" }}>
  //     <Table className="erp_table" bordered striped hover id="todaysProdSummaryTableId">
  //       <thead className="erp_table_head">
  //         <tr>
  //           <th className="erp_table_th"></th>
  //           <th className="erp_table_th text-center" colSpan={3}>
  //             Today's Production Summary
  //           </th>
  //           <th className="erp_table_th text-center" colSpan={3}>
  //             Up To Day's Production Summary
  //           </th>
  //         </tr>
  //       </thead>
  //       <thead className="erp_table_head">
  //         <tr>
  //           <th className="erp_table_th">Description</th>
  //           {shiftOptions?.map((header, indexOfItem) => (
  //             <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
  //               {header.field_name}
  //             </th>
  //           ))}
  //           <th className="erp_table_th">Total</th>
  //           {shiftOptions?.map((header, indexOfItem) => (
  //             <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
  //               {header.field_name}
  //             </th>
  //           ))}
  //           <th className="erp_table_th">Total</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {namesOfTheShift.length === 0 ? (<tr></tr>) : (
  //           <React.Fragment>
  //             {/* First Row */}
  //             <tr>
  //               <th className="erp_table_th">{prodSummaryRowsFirstCol[0]}</th>
  //               <>
  //                 {namesOfTheShift.map((key, index) => (
  //                   <td key={key} className="erp_table_td">
  //                     {prodSummaryData[key].warping_total_legnth}
  //                   </td>

  //                 ))}
  //                 <td className="erp_table_td">
  //                   {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].warping_total_legnth, 0)}
  //                 </td>
  //               </>
  //               <>
  //                 {namesOfTheShift.map((key, index) => (
  //                   <td key={key} className="erp_table_td">
  //                     {prodSummaryData[key].warping_total_upto_date_legnth}
  //                   </td>
  //                 ))}
  //                 <td className="erp_table_td">
  //                   {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].warping_total_upto_date_legnth, 0)}
  //                 </td>
  //               </>
  //             </tr>
  //             {/* Second Row */}
  //             <tr>
  //               <th className="erp_table_th">{prodSummaryRowsFirstCol[1]}</th>
  //               <>
  //                 {namesOfTheShift.map((key, index) => (
  //                   <td key={key} className="erp_table_td">
  //                     {prodSummaryData[key].warping_total_act_bottom}
  //                   </td>
  //                 ))}
  //                 <td className="erp_table_td">
  //                   {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].warping_total_act_bottom, 0)}
  //                 </td>
  //               </>
  //               <>
  //                 {namesOfTheShift.map((key, index) => (
  //                   <td key={key} className="erp_table_td">
  //                     {prodSummaryData[key].warping_total_upto_date_act_bottom}
  //                   </td>
  //                 ))}
  //                 <td className="erp_table_td">
  //                   {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].warping_total_upto_date_act_bottom, 0)}
  //                 </td>
  //               </>
  //             </tr>
  //             {/* Third Row */}
  //             <tr>
  //               <th className="erp_table_th">{prodSummaryRowsFirstCol[2]}</th>
  //               <>
  //                 {namesOfTheShift.map((key, index) => (
  //                   <td key={key} className="erp_table_td">
  //                     {prodSummaryData[key].stoppage_time}
  //                   </td>
  //                 ))}
  //                 <td className="erp_table_td">
  //                   {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].stoppage_time, 0)}
  //                 </td>
  //               </>
  //               <>
  //                 {namesOfTheShift.map((key, index) => (
  //                   <td key={key} className="erp_table_td">
  //                     {prodSummaryData[key].upto_date_stoppage_time}
  //                   </td>
  //                 ))}
  //                 <td className="erp_table_td">
  //                   {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].upto_date_stoppage_time, 0)}
  //                 </td>
  //               </>

  //             </tr>

  //           </React.Fragment>
  //         )}
  //       </tbody>
  //     </Table>
  //   )
  // }, [prodSummaryData]);


  ///function for the get data for summary today and up to date data
  // const FnGetShiftDetails = async (today) => {
  //   let namesOfTheShift = [...shiftOptions];
  //   let newdate = ($('#dt_warping_production_date').val() === '' || $('#dt_warping_production_date').val() === null) ? today : $('#dt_warping_production_date').val();

  //   try {
  //     const getShiftApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionWarping/FnShowParticularWarpingShiftSummary/${newdate}/${COMPANY_ID}`);
  //     const respForShiftApiCall = await getShiftApiCall.json();

  //     let productSummaryData = respForShiftApiCall.data;
  //     let upToDateShift_Details = {};
  //     namesOfTheShift.map((shift) => {
  //       if (productSummaryData[shift] && productSummaryData[shift].hasOwnProperty('warping_total_legnth')) {
  //         upToDateShift_Details[shift] = {
  //           warping_total_legnth: productSummaryData[shift].warping_total_legnth,
  //           warping_total_act_bottom: productSummaryData[shift].warping_total_act_bottom,
  //           stoppage_time: productSummaryData[shift].stoppage_time,
  //           warping_total_upto_date_legnth: productSummaryData[shift].warping_total_upto_date_legnth,
  //           warping_total_upto_date_act_bottom: productSummaryData[shift].warping_total_upto_date_act_bottom,
  //           upto_date_stoppage_time: productSummaryData[shift].upto_date_stoppage_time,
  //         };
  //       } else {
  //         upToDateShift_Details[shift] = {};
  //       }
  //     })
  //     setProductionSummaryData(productSummaryData);
  //   } catch (error) {
  //     console.log('error', error);
  //     navigate('/Error')

  //   }
  // }

  /// validation for material movement
  const validateMaterialDetails = async () => {
    if (warpingProductionMaterialData.length > 0) {
      for (let index = 0; index < warpingProductionMaterialData.length; index++) {
        const element = warpingProductionMaterialData[index];
        const { weaving_production_set_no, shift, product_material_id, consumptionQtyInfo = 0, warping_production_date } = element;

        if ((shift !== '' && shift !== 0) || (product_material_id !== '' &&
          product_material_id !== undefined) || consumptionQtyInfo !== 0) {
          if (shift === '') {
            setErrMsg('Please Select at Shift in Warping Material Movement...!');
            setShowErrorMsgModal(true);
            return false;
          } else if (product_material_id === '') {
            setErrMsg('Please Select Material Name in Warping Material Movement...!');
            setShowErrorMsgModal(true);
            return false;
          } else if (consumptionQtyInfo === 0) {
            setErrMsg(`Please properly consume the stock for Shift-${shift} & Warping Prod. date - ${warping_production_date} in the material movement section.`);
            setShowErrorMsgModal(true);
            return false;
          }
          return true;
        } else {
          return true;
        }

      }
    } else {
      return true;
    }

  }


  const validateBottomDetails = () => {
    let validate = true;
    let issuedCones = parseFloat(warping_issue_cones) || 0;
    let issuedWt = parseFloat(warping_issue_kg) || 0;
  
    let noofpackages = validateNumberDateInput.current?.decimalNumber(
      warpingBottomDetailsData.reduce((acc, data) => acc + parseFloat(data?.['no_of_package'] || 0), 0), 
      4
    );
  
    let grossweight = validateNumberDateInput.current?.decimalNumber(
      warpingBottomDetailsData.reduce((acc, data) => acc + parseFloat(data?.['gross_weight'] || 0), 0), 
      4
    );
  
    if (noofpackages > issuedCones) {
      setErrMsg("Warping Bottom No. Of Packages can't be greater than Issued Cones");
      setShowErrorMsgModal(true);
      validate = false;
    } 
  
    if (grossweight > issuedWt) {
      setErrMsg("Warping Bottom Gross Weight can't be greater than Issued Weight");
      setShowErrorMsgModal(true);
      validate = false;
    }
  
    return validate;
  };
  



  // ----------------------------------function for the save funtionality---------------------------------------------------------------

  const addWarpingProduction = async (e) => {
    debugger
    try {
      setIsLoading(true)
      // const checkIsValidate = await validate.current.validateForm("Warpingproduction");
      if (!cmb_prod_supervisor_id_ref || !cmb_prod_supervisor_id_ref.current ||
        cmb_prod_supervisor_id_ref.current.value === undefined ||
        cmb_prod_supervisor_id_ref.current.value === null ||
        cmb_prod_supervisor_id_ref.current.value === "" ||
        cmb_prod_supervisor_id_ref.current.value === "0") {

        $("#error_cmb_prod_supervisor_id").text("Please fill this field...!");
        $("#error_cmb_prod_supervisor_id").show();
        $("#cmb_prod_supervisor_id").focus();
        return false;
      } else {
        $("#error_cmb_prod_supervisor_id").hide();
      }


      let isCalculationValid = false;

      let checkValidProdDetails = false;
      // checkValidProdDetails = await validateProdDetails()
      let checkValidMaterialDetails = false;
      checkValidMaterialDetails = await validateMaterialDetails()
      let set_srno_dt = (`${dt_warping_production_date.split('-')[0] + '-' + dt_warping_production_date.split('-')[1]}`);
      if (validateBottomDetails() === true) {

      const json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransWVBottomData': [], 'TransWarpingMaterialData': [], 'TransWVProdStoppageData': [], 'TransWarpingWastageData': [], 'commonIds': { 'company_id': COMPANY_ID, 'weaving_production_warping_master_id': weavingProductionWarpingMasterId, 'supplier_id': parseInt(supplier_id), 'set_srno_dt': `${set_srno_dt}-%`, 'stock_adjust': parseInt(txt_no_of_beams) === warpingProductionData.length ? true : false, 'cone_per_weight': parseFloat(warpingProductionData[0]['weight_per_pkg']), 'product_rm_id': prod_material_id } }

      let warpingProductionStatus = txt_productionWarpingStatus;
      if (keyForViewUpdate === 'approve') {
        const allApprovedWarping = warpingProductionData.every(item => item.warping_production_status === 'A');
        // const allApprovedWarpingMaterial = warpingProductionMaterialData.every(item => item.material_status === 'A');
        // const anyApproved = warpingProductionData.some(item => item.sizing_production_status === 'A')
        // || warpingProductionMaterialData.some(item => item.material_status === 'A');
        const allPending = warpingProductionData.every(item => item.warping_production_status === 'P')
        // && warpingProductionMaterialData.every(item => item.material_status === 'P');
        const anyRejected = warpingProductionData.some(item => item.warping_production_status === 'R')
        // || warpingProductionMaterialData.some(item => item.material_status === 'R');

        if (anyRejected) {
          warpingProductionStatus = 'R';
        } else if (allApprovedWarping) {
          warpingProductionStatus = 'A';
        } else if (allPending) {
          warpingProductionStatus = 'P';
        } else {
          warpingProductionStatus('P');
        }
      }

      const data = {
        company_branch_id: COMPANY_BRANCH_ID,
        company_id: COMPANY_ID,
        financial_year: FINANCIAL_SHORT_NAME,
        weaving_production_warping_master_id: weavingProductionWarpingMasterId,
        warping_production_code: cmb_warping_production_code,
        warping_production_date: dt_warping_production_date,
        prod_month: txt_prod_month,
        set_no: cmb_set_no_ref.current.value,
        prod_year: txt_prod_year,
        plant_id: $('#cmb_plant_id').val(),
        calculative_bottom_kg: txt_calculative_bottom_kg,
        calculative_bottom_percent: txt_calculative_bottom_percent,
        actual_bottom_kg: txt_actual_bottom_kg,
        actual_bottom_percent: txt_actual_bottom_percent,
        difference_bottom_kg: txt_difference_bottom_kg,
        difference_bottom_percent: txt_difference_bottom_percent,
        // batch_no: batch_no,
        supplier_id: supplier_id,
        production_supervisor_id: parseInt(cmb_prod_supervisor_id),
        warping_issue_kg: warping_issue_kg,
        warping_issue_quantity: warping_issue_cones,
        section_id: cmb_section_id,
        sub_section_id: cmb_sub_section_id,
        created_by: UserName,
        modified_by: weavingProductionWarpingMasterId === 0 ? null : UserName,
        modified_on : new Date().toISOString(),
        warping_production_master_status: warpingProductionStatus,
        is_active: chk_isactive,

      };


      //Material Moment Data
      if (warpingProductionMaterialData.length > 0) {
        let lastIndexOfMaterialMovement = warpingProductionMaterialData.length - 1
        // if (warpingProductionMaterialData[lastIndexOfMaterialMovement].weaving_production_set_no !== 0) {

        for (let index = 0; index < warpingProductionMaterialData.length; index++) {
          const materialDataElement = warpingProductionMaterialData[index];
          if (materialDataElement.consumption_quantity > 0) {

            const materialMovementData = {
              company_branch_id: COMPANY_BRANCH_ID,
              company_id: COMPANY_ID,
              financial_year: FINANCIAL_SHORT_NAME,
              weaving_production_warping_master_id: 0,
              weaving_production_warping_details_id: 0,
              warping_production_date: materialDataElement.warping_production_date,
              warping_production_code: cmb_warping_production_code,
              plant_id: $('#cmb_plant_id').val(),
              prod_month: txt_prod_month,
              prod_year: txt_prod_year,
              shift: materialDataElement.shift,
              section_id: cmb_section_id,
              sub_section_id: cmb_sub_section_id,
              weaving_production_warping_master_id: weavingProductionWarpingMasterId,
              weaving_production_warping_material_id: materialDataElement.weaving_production_warping_material_id,
              warping_production_order_no: materialDataElement.warping_production_order_no,
              weaving_production_set_no: cmb_set_no_ref.current.value,
              product_material_id: materialDataElement.product_material_id,
              product_material_unit_id: materialDataElement.product_material_unit_id,
              consumption_quantity: materialDataElement.consumption_quantity,
              // product_material_quantity: materialDataElement.product_material_quantity,
              material_status: materialDataElement.material_status,
              material_status_remark: materialDataElement.material_status_remark,
              // consumptionQtyInfo: materialDataElement.consumptionQtyInfo,
              created_by: UserName,
              modified_by: weavingProductionWarpingMasterId === 0 ? null : UserName,
              modified_on : new Date().toISOString(),

            };
            json.TransWarpingMaterialData.push(materialMovementData)
          }
        }
        // }
      }


      //Warping Production Details
      for (let index = 0; index < warpingProductionData.length; index++) {
        const element = warpingProductionData[index];

        if (element['speed'] > 0 && element['length'] > 0) {
          const warpingProductionAddData = {
            company_branch_id: COMPANY_BRANCH_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            company_id: COMPANY_ID,
            weaving_production_warping_master_id: weavingProductionWarpingMasterId,
            weaving_production_warping_details_id: element.weaving_production_warping_details_id,
            warping_production_date: element.warping_production_date,
            prod_month: txt_prod_month,
            prod_year: txt_prod_year,
            warping_production_code: cmb_warping_production_code,
            plant_id: $('#cmb_plant_id').val(),
            production_supervisor_id: parseInt(cmb_prod_supervisor_id),
            section_id: cmb_section_id,
            sub_section_id: cmb_sub_section_id,
            status_remark: element.status_remark,
            created_by: UserName,
            modified_by: weavingProductionWarpingMasterId === 0 ? null : UserName,
            created_by: UserName,
            weaving_production_set_no: cmb_set_no_ref.current.value,
            beam_no: element.beam_no,
            speed: element.speed,
            cut_cones: element.cut_cones,
            guccha: element.guccha,
            thin_places: element.thin_places,
            week_places: element.week_places,
            week_splice: element.week_splice,
            sluff_off: element.sluff_off,
            slub_yarn: element.slub_yarn,
            total_breaks: element.total_breaks,
            // act_bottom: element.act_bottom,
            machine_id: element.machine_id,
            // bottom_percent: element.bottom_percent,
            // exp_bottom: element.exp_bottom,
            breaks_per_million: element.breaks_per_million,
            production_operator_id: element.production_operator_id,
            created_by: element.created_by,
            creel_ends: element.creel_ends,
            actual_count: $("#production_count").val(),
            product_rm_id: prod_material_id,
            yarn_count: txt_yarn_count,
            warping_production_status: 'A',
            length: element.length,
            // net_weight: element.net_weight,
            // no_of_creels: parseInt(element.no_of_creels),
            shift: element.shift,
            t_ends: $("#no_of_ends").val(),
            // total_pkg_used: element.total_pkg_used,
            total_weight_issue_to_warping: element.total_weight_issue_to_warping,
            weight_per_pkg: element.weight_per_pkg,
            godown_id: element.godown_id,
            warping_order_no: $("#txt_warping_prod_order_code").val()
          }
          json.TransDetailData.push(warpingProductionAddData)
        }
      }


      //Warping Bottom Details
      for (let index = 0; index < warpingBottomDetailsData.length; index++) {
       
        let warpingBottomElement = warpingBottomDetailsData[index]
        // if (warpingBottomElement.weaving_production_warping_bottom_details_id === 0 && warpingBottomElement.no_of_package !== 0 && warpingBottomElement.gross_weight !== 0) {
          const warpingBottomObj = {
            company_branch_id: COMPANY_BRANCH_ID,
            company_id: COMPANY_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            weaving_production_warping_bottom_details_id: warpingBottomElement.weaving_production_warping_bottom_details_id,
            set_no: cmb_set_no_ref.current.value,
            sr_no: warpingBottomElement.sr_no,
            // batch_no: batch_no,
            // goods_receipt_no: goods_receipt_no,
            product_rm_id: prod_material_id,
            weight_per_pkg: warpingProductionData[0]['weight_per_pkg'],
            warping_bottom_details_production_date: warpingBottomElement.warping_bottom_details_production_date,
            weaving_production_warping_master_id: weavingProductionWarpingMasterId,
            warping_production_code: cmb_warping_production_code,
            creel_no: warpingBottomElement.creel_no,
            no_of_package: warpingBottomElement.no_of_package,
            gross_weight: warpingBottomElement.gross_weight,
            no_of_boxes: warpingBottomElement.no_of_boxes,
            net_weight: warpingBottomElement.net_weight,
            tare_weight: warpingBottomElement.tare_weight,
            package_type: warpingBottomElement.package_type.toString(),
            cone_type: warpingBottomElement.cone_type.toString(),
            cone_type_value: warpingBottomElement.cone_type_value,
            created_by: UserName,
            material_status: "Pending",
            modified_by: weavingProductionWarpingMasterId === 0 ? null : UserName,
          }
          json.TransWVBottomData.push(warpingBottomObj);
        // }
      }


      //Wastage Details
      for (let index = 0; index < wastageDetails.length; index++) {
        const wastageDataElement = wastageDetails[index];
        const wastageData = {
          company_branch_id: COMPANY_BRANCH_ID,
          company_id: COMPANY_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          weaving_production_warping_wastage_id: wastageDataElement.weaving_production_warping_wastage_id,
          weaving_production_warping_master_id: weavingProductionWarpingMasterId,
          warping_production_code: cmb_warping_production_code,
          warping_production_date: dt_warping_production_date,
          prod_month: txt_prod_month,
          prod_year: txt_prod_year,
          plant_id: $('#cmb_plant_id').val(),
          section_id: cmb_section_id,
          sub_section_id: cmb_sub_section_id,
          shift: wastageDataElement.shift,
          production_wastage_types_id: wastageDataElement.production_wastage_types_id,
          godown_id: wastageDataElement.godown_id,
          production_wastage_types_type: wastageDataElement.production_wastage_types_type,
          wastage_quantity: wastageDataElement.wastage_quantity,
          created_by: UserName,
          modified_by: weavingProductionWarpingMasterId === 0 ? null : UserName,
        };
        json.TransWarpingWastageData.push(wastageData)
      }

      json.TransHeaderData = data;
      console.log('warping data', json);
      const formData = new FormData()
      formData.append('WeavingProductionWarpingData', JSON.stringify(json))
      const forwardData = {
        method: 'POST',
        body: formData,
      }
      const getWarpingDetails = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionWarping/FnAddUpdateRecord`, forwardData)
      const response = await getWarpingDetails.json();
      if (response.success === 0) {
        setErrMsg(response.error)
        setShowErrorMsgModal(true)
      } else {
        setSuccMsg(response.message)
        setShowSuccessMsgModal(true)
      }
      }
    } catch (error) {
      console.log("error", error);
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  };

  const FnManageWarpingProdTbl = (action, index) => {
    setWarpingProductionData((prevData) => {
      let updatedData = [...prevData];

      switch (action) {
        case 'Add':
          if (parseInt(txt_no_of_beams) === updatedData.length) {
            $(".hidecolumns").hide();
          } else if (index >= 0 && index < updatedData.length) {
            const newData = {
              ...updatedData[index],
              weaving_production_warping_details_id: 0,
              weaving_production_warping_master_id: 0,
              warping_production_status: 'P',
              beam_no: 0,
              production_operator_id: 0,
            };
            updatedData.push(newData);
          }
          break;

        case 'Remove':
          if (index >= 0 && index < updatedData.length) {
            updatedData.splice(index, 1);
          }
          break;

        default:
          break;
      }

      return updatedData;
    });
  };

  const renderWarpingProductionDetails = useMemo(() => {
    // debugger
    return <Table id='warpingProductionTbl' style={{ height: '75px' }} className={`erp_table ${warpingProductionData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
      <thead className="erp_table_head">
        <tr>
          <th className={`erp_table_th hidecolumns ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}> Action</th>
          <th className="erp_table_th">Sr. No</th>
          <th className="erp_table_th">Date</th>
          <th className="erp_table_th">Shift</th>
          <th className="erp_table_th">Machines no.</th>
          <th className="erp_table_th">Machine Operator</th>
          {/* <th className="erp_table_th">Set no</th> */}
          <th className="erp_table_th">Beam No</th>
          <th className="erp_table_th">SPEED</th>
          {/* <th className="erp_table_th">Creels</th>
                <th className="erp_table_th">Set Length</th>
                <th className="erp_table_th">Material Name</th>
                <th className="erp_table_th">Warping order no</th> */}
          {/* <th className="erp_table_th">Yarn Count</th>
                <th className="erp_table_th">Actual Count</th> */}
          {/* <th className="erp_table_th">T.Ends</th> */}
          <th className="erp_table_th" >BEEM ENDS</th>
          <th className="erp_table_th">BEAM LENGTH</th>
          <th className="erp_table_th" >CUT CONES</th>
          <th className="erp_table_th">GUCCHA</th>
          <th className="erp_table_th" >THIN PLACES</th>
          <th className="erp_table_th">WEEK PLACES</th>
          <th className="erp_table_th" >WEEK SPLICE</th>
          <th className="erp_table_th">SLUFF-OFF</th>
          <th className="erp_table_th" >SLUB YARN</th>
          <th className="erp_table_th">TOTAL BRAKES</th>
          {/* <th className="erp_table_th">NO OF CREEL</th> */}
          {/* <th className="erp_table_th">TOTAL PKG USED</th> */}
          {/* <th className="erp_table_th">WEIGHT / PKG</th> */}
          {/* <th className="erp_table_th" rowSpan={2}>TOTAL WEIGHT <br></br> ISSUE TO WPG</th> */}
          {/* <th className="erp_table_th">NET WT.</th>
          <th className="erp_table_th">EXP. BOTTOM</th> */}
          <th className="erp_table_th">BREAKS/MILLION</th>
          {/* <th className="erp_table_th">ACT. BOTTOM</th>
          <th className="erp_table_th">BOTTOM %</th> */}
          {/* <th className="erp_table_th" rowSpan={2}>Warping <br></br> Prod. Status</th> */}
          {/* <th className="erp_table_th">Status Remark</th> */}
          <th className="erp_table_th">Godown</th>

        </tr>
      </thead>
      <tbody>
        {warpingProductionData.map((item, index) =>

          <tr rowindex={index} className="sticky-column">
            <td className={`erp_table_th hidecolumns ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
              <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => FnManageWarpingProdTbl('Remove', index)} />
              <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => FnManageWarpingProdTbl('Add', index)} />
            </td>

            <td className='erp_table_td'>
              {index + 1}
            </td>
            <td className='erp_table_td'>
              { <input type="date" className="erp_input_field mb-0" disabled={['view', 'approve'].includes(keyForViewUpdate)}
                    Headers="warping_production_date" id={`warping_production_date_${index}`}
                    value={item.warping_production_date} onChange={(e) => updatedwarpingProductionDetails("warping_production_date", item, e)} />
              }
            </td>
            <td className={`erp_table_td `}>
              {<select id={`shift_${index}`} value={item.shift} onChange={(e) => updatedwarpingProductionDetails("shift", item, e)} className="erp_input_field_table_txt mb-0"
                    Headers="shift" disabled={['view', 'approve'].includes(keyForViewUpdate)} >
                    <option value="">Select</option>
                    {shiftOptions?.map(shift => (
                      <option value={shift.field_name}>{shift.field_name}</option>
                    ))}
                  </select>
              }
            </td>

            <td className={`erp_table_td `}>
              { <select id={`machine_id_${index}`} value={item.machine_id} onChange={(e) => updatedwarpingProductionDetails("machine_id", item, e)} className="erp_input_field_table_txt mb-0"
                    disabled={['view', 'approve'].includes(keyForViewUpdate)} style={{ width: 'auto' }}
                    Headers="machine_id"
                  >
                    <option value="">Select</option>
                    {machineNameOption?.map(machine => (
                      <option value={machine.field_id}>{machine.machine_id}{machine.field_name}</option>
                    ))}
                  </select>
              }
            </td>

            <td className='erp_table_td' id={`tooltipforsupervisor_${index}`}>
              {
                    <Select
                      ref={(ref) => (selectRefs.current[index] = ref)}
                      options={machineOperatorOptions.filter(options => options.employee_type === 'Worker')}
                      isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                      inputId={`cmb_prod_supervisor_id_${index}`}
                      value={machineOperatorOptions.find(option => option.field_id === item.production_operator_id)}
                      onChange={(e) => handleSelectChange(item, index, e)}
                      placeholder="Search Operator here..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          width: '200px',
                          fontSize: '12px',
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          width: '200px',
                          zIndex: 1000,
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 1000,
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: '12px',
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          width: '160px',
                          fontSize: '12px',
                        }),
                      }}
                      menuPortalTarget={document.body}
                    />
              }
            </td>


            {/* <td className={`erp_table_td `}>
                        {
                            keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                ? <select id={`weaving_production_set_no_${index}`} value={item.weaving_production_set_no} className="erp_input_field_table_txt mb-0"
                                    Headers="weaving_production_set_no"
                                    disabled={item.warping_production_status_desc === 'Approved'}
                                    onChange={(e) => { handleSetNoChange(item, e); }}
                                    style={{ width: '100px' }}
                                >
                                    <option value={''}>Select</option>
                                    {
                                        //array in ascending order
                                        setNoOptions.sort((a, b) => a.set_no - b.set_no).map(setNO => (
                                            <option value={setNO.set_no}>{setNO.set_no}</option>
                                        ))}
                                </select>
                                : item.weaving_production_set_no
                        }
                    </td> */}

            <td className={`erp_table_td `}>
                <select id={`beam_no_${index}`} value={item.beam_no} className="erp_input_field_table_txt mb-0" Headers="beam_no" onChange={(e) => { updatedwarpingProductionDetails('beam_no', item, e); }}
                  style={{ width: 'auto' }} disabled={keyForViewUpdate==='view'}>
                  <option value="">Select</option>
                  {beamSizeOptions?.map(beamno => (
                    <option value={beamno.field_id}>{beamno.field_name}</option>
                  ))}
                </select>
            </td>

            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`speed_${index}`}
                      style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      value={item.speed}
                      onChange={(e) => updatedwarpingProductionDetails("speed", item, e)}
                    />
              }
            </td>


            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`creel_ends_${index}`}
                      value={item.creel_ends}
                      style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      maxLength='19'
                      onChange={(e) => updatedwarpingProductionDetails("creel_ends", item, e)}
                    />
              }
            </td>

            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`length_${index}`}
                      style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      value={item.length}
                      onChange={(e) => updatedwarpingProductionDetails("length", item, e)}
                    />
              }
            </td>

            {/* <td className='erp_table_td' style={{ width: '80px' }}>{item.warping_creels} </td>
                    <td className='erp_table_td' style={{ width: '80px' }}>{item.warping_set_length}</td>
                    <td className='erp_table_td'>
                        {
                            keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                ? <span className="erp_input_field_table_txt mb-0" style={{ width: 'auto' }} id={`product_material_name_${index}`}>{item.product_material_name}</span>
                                : item.product_material_name
                        }
                    </td>
                    <td className='erp_table_td'>
                        {
                            keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                ? <span className="erp_input_field_table_txt mb-0" id={`warping_order_no_${index}`}>{item.warping_order_no}</span>
                                : item.warping_order_no
                        }
                    </td> */}

            {/* <td className='erp_table_td'>
                        {
                            keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                ? <span className="erp_input_field_table_txt mb-0 text-end" id={`yarn_count_${index}`}>{item.yarn_count}</span>
                                : item.yarn_count
                        }
                    </td>

                    <td className='erp_table_td'>
                        {
                            keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                ? <span className="erp_input_field_table_txt mb-0 text-end" id={`actual_count_${index}`}>{item.actual_count}</span>
                                : item.actual_count
                        }
                    </td> */}
            {/* <td className='erp_table_td'>
                        {
                            keyForViewUpdate !== 'view'
                                ? <span className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`t_ends_${index}`}>{item.t_ends}</span>
                                : item.t_ends
                        }
                    </td> */}

            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`cut_cones_${index}`}
                      value={item.cut_cones}
                      style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      maxLength='19'
                      onChange={(e) => updatedwarpingProductionDetails("cut_cones", item, e)}
                    />
              }
            </td>

            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`guccha_${index}`}
                      style={{ backgroundColor: '#AFE1AF', width: '70px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      value={item.guccha}
                      onChange={(e) => updatedwarpingProductionDetails("guccha", item, e)}
                    />
              }
            </td>

            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`thin_places_${index}`}
                      value={item.thin_places}
                      style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      maxLength='19'
                      onChange={(e) => updatedwarpingProductionDetails("thin_places", item, e)}
                    />
              }
            </td>

            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`week_places_${index}`}
                      style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      value={item.week_places}
                      onChange={(e) => updatedwarpingProductionDetails("week_places", item, e)}
                    />
              }
            </td>

            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`week_splice_${index}`}
                      value={item.week_splice}
                      style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      maxLength='19'
                      onChange={(e) => updatedwarpingProductionDetails("week_splice", item, e)}
                    />
              }
            </td>

            <td className='erp_table_td'>
              {
               
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`sluff_off_${index}`}
                      style={{ backgroundColor: '#AFE1AF', width: '70px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      value={item.sluff_off}
                      onChange={(e) => updatedwarpingProductionDetails("sluff_off", item, e)}
                    />
              }
            </td>

            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`slub_yarn_${index}`}
                      value={item.slub_yarn}
                      style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      maxLength='19'
                      onChange={(e) => updatedwarpingProductionDetails("slub_yarn", item, e)}
                    />
              }
            </td>

            <td className='erp_table_td'>
              {
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`total_breaks_${index}`}
                      style={{ width: '80px' }}
                      disabled
                      value={item.total_breaks}
                      onChange={(e) => updatedwarpingProductionDetails("total_breaks", item, e)}
                    />
              }
            </td>

            {/* <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view'
                  ? <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`no_of_creels_${index}`}
                      style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                      disabled={item.warping_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                      value={item.no_of_creels}
                      onChange={(e) => updatedwarpingProductionDetails("no_of_creels", item, e)}
                    />
                  </>
                  : item.no_of_creels
              }
            </td> */}

            {/* <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view'
                  ? <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt mb-0 text-end"
                      id={`total_pkg_used_${index}`}
                      value={item.total_pkg_used}
                      style={{ backgroundColor: '#e9ecef', width: '90px' }}
                      disabled />
                  </>
                  : item.total_pkg_used
              }
            </td>

            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view'
                  ? <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`weight_per_pkg_${index}`}
                      value={item.weight_per_pkg}
                      disabled={item.warping_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                      style={{ backgroundColor: '#AFE1AF', width: '80px' }}
                      onChange={(e) => updatedwarpingProductionDetails("weight_per_pkg", item, e)}
                    />
                  </>
                  : item.weight_per_pkg
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view'
                  ? <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt mb-0"
                      id={`total_weight_issue_to_warping_${index}`}
                      style={{ backgroundColor: '#e9ecef', width: '85px' }}
                      value={item.total_weight_issue_to_warping}
                      onChange={(e) => updatedwarpingProductionDetails("total_weight_issue_to_warping", item, e)}
                      disabled />
                  </>
                  : item.total_weight_issue_to_warping
              }
            </td> */}


            {/* <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view'
                  ? <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt mb-0 text-end"
                      id={`net_weight_${index}`}
                      style={{ backgroundColor: '#e9ecef', width: '75px' }}
                      value={item.net_weight}
                      onChange={(e) => updatedwarpingProductionDetails("net_weight", item, e)}
                      disabled />
                  </>
                  : item.net_weight
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view'
                  ? <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt mb-0 text-end"
                      id={`exp_bottom_${index}`}
                      value={item.exp_bottom}
                      style={{ backgroundColor: '#e9ecef', width: '80px' }}
                      onChange={(e) => updatedwarpingProductionDetails("exp_bottom", item, e)}
                    />
                  </>
                  : item.exp_bottom
              }
            </td> */}
            <td className='erp_table_td'>
              {
              
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`breaks_per_million_${index}`}
                      style={{ width: '100px' }}
                      disabled
                      value={item.breaks_per_million}
                      onChange={(e) => updatedwarpingProductionDetails("breaks_per_million", item, e)}
                    />

              }
            </td>
            {/* <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view'
                  ? <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`act_bottom_${index}`}
                      style={{ backgroundColor: '#AFE1AF', width: '80px' }}
                      disabled={item.warping_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                      value={item.act_bottom}
                      onChange={(e) => updatedwarpingProductionDetails("act_bottom", item, e)}
                    />
                  </>
                  : item.act_bottom
              }
            </td> */}
            {/* <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view'
                  ? <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt mb-0 text-end"
                      id={`bottom_percent_${index}`}
                      style={{ backgroundColor: '#e9ecef', width: '70px' }}
                      value={item.bottom_percent}
                    />
                  </>
                  : item.bottom_percent
              }
            </td> */}
            {/* 
            <td className="erp_table_td">
              <select id={`warping_production_status_${index}`} className="erp_input_field_table_txt form-select form-select-sm mb-0" value={item.warping_production_status}
                onChange={(e) => { updatedwarpingProductionDetails("warping_production_status", item, e); FnValidateForm(); }}
                style={{ width: '82px' }} disabled={['view', 'approve'].includes(keyForViewUpdate)}
                Headers='warping_production_status'>
                <option value="P">Pending</option>
                <option value="A">Approved</option>
              </select>
            </td> */}

            {/* <td className='erp_table_td'>
              {keyForViewUpdate === 'approve' ? (
                <input
                  type="text"
                  id={`status_remark_${index}`}
                  disabled={['view', 'approve'].includes(keyForViewUpdate)}
                  className="erp_input_field_table_txt mb-0"
                  style={{ width: 'auto' }}
                  value={item.status_remark}
                  onChange={(e) => {
                    updatedwarpingProductionDetails("status_remark", item, e);
                  }}
                />
              ) : (
                item.status_remark
              )}
            </td> */}
            <td className={`erp_table_td `}>
              {

                <select id={`godown_id_${index}`} value={item.godown_id || "5"} className="erp_input_field_table_txt mb-0" Headers="godown_id" onChange={(e) => { updatedwarpingProductionDetails('godown_id', item, e); }} disabled
                // disabled={item.warping_production_status_desc === 'Approved' || item.warping_production_status_desc === 'view'} style={{ width: 'auto' }}
                >
                  <option value="">Select</option>
                  {godownNameOptions?.map(godownType => (
                    <option value={godownType.godown_id}>{godownType.godown_name}</option>
                  ))}
                </select>

              }
            </td>
          </tr>
        )}

        <tr className="sticky-column">
          <td className="erp_table_td hidecolumns" style={{ textAlign: 'right', fontWeight: 'bold' }}></td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={6}>Totals</td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber((warpingProductionData.reduce((acc, data) => acc + parseFloat(data['speed']), 0)) / warpingProductionData.length, 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['creel_ends']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['length']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['cut_cones']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['guccha']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['thin_places']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['week_places']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['week_splice']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['sluff_off']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['slub_yarn']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingProductionData.reduce((acc, data) => acc + parseFloat(data['total_breaks']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber((warpingProductionData.reduce((acc, data) => acc + parseFloat(data['breaks_per_million']), 0)) / warpingProductionData.length, 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={2}></td>
        </tr>
      </tbody>
    </Table>
  }, [warpingProductionData, machineNameOption]);


  // useLayoutEffect(() => {
  //   try {


  //   } catch (error) {

  //   }
  // }, [warpingProductionData]);


  const updatedwarpingProductionDetails = (propertyName, currentMaterial, event, selectedOptionForMachineOperator) => {
    debugger
    try {
      let enteredValue;
      let arrayIndex = 0;
      let updatedDetailsData = [...warpingProductionData]; // Create a shallow copy of the array
      if (propertyName !== 'production_operator_id') {
        arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        enteredValue = event.target.value === "" ? 0 : event.target.value;

      } else {
        arrayIndex = parseInt(event.controlRef.parentElement.parentElement.parentElement.getAttribute('rowindex'))
        let machineOperatorId = $('#tooltipforsupervisor_' + arrayIndex);
        if (selectedOptionForMachineOperator.label !== 'Select') {
          currentMaterial[propertyName] = selectedOptionForMachineOperator.field_id;
          currentMaterial['production_operator_id'] = selectedOptionForMachineOperator.field_id;
          currentMaterial['production_operator_name'] = selectedOptionForMachineOperator.field_name;
          machineOperatorId.removeAttr('data-tip');
        } else {
          machineOperatorId.attr('data-tip', 'Select Machine Operator');
        }
      }


      // const setLength = $("#txt_set_length").val();
      // const noOfEnds = $("#no_of_ends").val();
      // const productionCount = $("#production_count").val();
      switch (propertyName) {
        case 'beam_no':
          let matchedBeamNo = updatedDetailsData.find((warpingdata) => warpingdata.beam_no === enteredValue);

          if (!matchedBeamNo) {
            delete event.target.parentElement.dataset.tip;
            currentMaterial[propertyName] = enteredValue;
          } else {
            event.target.parentElement.dataset.tip = 'Beam No already selected...!'
          }

          break;

        case 'warping_production_date':
        case 'machine_id':
        case 'godown_id':
        case 'shift':
        case 'speed':
          currentMaterial[propertyName] = enteredValue;
          break;
        case 'creel_ends':
        case 'length':
        // case 'bottom_percent':

        case 'cut_cones':
        case 'guccha':
        case 'thin_places':
        case 'week_places':
        case 'week_splice':
        case 'sluff_off':
        case 'slub_yarn':
          delete event.target.parentElement.dataset.tip;
          if (enteredValue !== '') {
            currentMaterial[propertyName] = enteredValue;

            //Calculating total_breaks & breaks_per_million
            //validateNumberDateInput.current.decimalNumber(num, 4)
            let beam_totalbreaks = parseFloat(currentMaterial['cut_cones']) + parseFloat(currentMaterial['guccha']) + parseFloat(currentMaterial['thin_places']) +
              (parseFloat(currentMaterial['week_places']) + parseFloat(currentMaterial['week_splice']) + parseFloat(currentMaterial['sluff_off']) + parseFloat(currentMaterial['slub_yarn']))
            currentMaterial['total_breaks'] = parseFloat(beam_totalbreaks);
            currentMaterial['breaks_per_million'] = validateNumberDateInput.current.decimalNumber(((beam_totalbreaks * 1000000) / (parseFloat(currentMaterial['creel_ends']) * parseFloat(currentMaterial['length']))), 4);
          }

          break;
        case 'status_remark':
          currentMaterial[propertyName] = enteredValue
          delete event.target.parentElement.dataset.tip;
          break;
        case 'warping_production_status':
          // On change remove the tooltip on approval remark.
          if (enteredValue === 'R') {
            currentMaterial['warping_production_status'] = enteredValue
            let approvalRemarkField = $(`#status_remark_` + currentMaterial[propertyName]);
            if (approvalRemarkField.length > 0) {
              approvalRemarkField[0].parentElement.dataset.tip = 'Please enter the Rejection remark....!'

            }
          } else {
            currentMaterial['warping_production_status'] = enteredValue
            let approvalRemarkField = $(`#status_remark_` + currentMaterial[propertyName]);
            if (approvalRemarkField.length > 0) {
              delete approvalRemarkField[0].parentElement.dataset.tip;
            }
          }
          break;
        default:
          break;
      }
      // event.target.reset()

      updatedDetailsData[arrayIndex] = { ...currentMaterial }; // Ensure a new object is created
      setWarpingProductionData(updatedDetailsData);
    } catch (error) {

    }
  }



  ////////////////////Material Moment/////////////////
  // const renderMaterialNameList = async (setno, index) => {

  //   let MaterialObject = document.getElementById(`product_material_id_${index}`);

  //   MaterialObject.innerHTML = '<option value="">Select</option>';
  //   MaterialObject.insertAdjacentHTML('beforeend', '<option value="0">Add New Record +</option>');

  //   resetGlobalQuery();
  //   globalQuery.columns = [` (SELECT
  //       sum(wps.closing_balance_quantity)
  //    FROM
  //       xtv_warping_production_order_stock_details wps
  //    WHERE
  //       wps.product_material_id = wpm.product_material_id
  //       AND wps.set_no = wpm.set_no
  //   ) AS stock_quantity`,
  //     ` (SELECT
  //       sum(wps.closing_balance_weight)
  //    FROM
  //       xtv_warping_production_order_stock_details wps
  //    WHERE
  //       wps.product_material_id = wpm.product_material_id
  //       AND wps.set_no = wpm.set_no
  //   ) AS stock_weight`,
  //     'product_material_name', 'product_material_id']
  //   globalQuery.table = "xtv_weaving_production_material wpm";
  //   globalQuery.conditions.push({ field: "wpm.production_sub_section_name", operator: "=", value: 'Warping (Weaving)', });
  //   globalQuery.conditions.push({ field: "wpm.company_id", operator: "=", value: COMPANY_ID, });
  //   globalQuery.conditions.push({ field: "wpm.set_no", operator: "=", value: setno, });
  //   globalQuery.conditions.push({ field: "wpm.is_delete", operator: "=", value: 0, });

  //   const materialApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
  //   FnFillMaterialOptions(materialApiCall, MaterialObject)
  // }


  /// update material movement record
  // const FnUpdateWpMaterialMovementRecords = async (currentProductTypeGroup, e, index) => {

  //   let clickedColName = e.target.getAttribute('Headers');
  //   delete e.target.parentElement.dataset.tip;
  //   let enteredValue = e.target.value;

  //   const updatePtGroupData = [...warpingProductionMaterialData]

  //   let productTypeIndexArray = 0;
  //   if (clickedColName === 'consumption_quantity') {
  //     productTypeIndexArray = parseInt(e.target.parentElement.parentElement.parentElement.getAttribute('rowIndex'))
  //   } else {
  //     productTypeIndexArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
  //   }



  //   switch (clickedColName) {
  //     case 'warping_production_date':
  //       currentProductTypeGroup['warping_production_date'] = enteredValue
  //       break;


  //     case 'shift':
  //       currentProductTypeGroup[clickedColName] = e.target.value;
  //       delete e.target.parentElement.dataset.tip;
  //       break;

  //     case 'product_material_id':
  //       currentProductTypeGroup[clickedColName] = e.target.value;
  //       delete e.target.parentElement.dataset.tip;
  //       break;

  //     case 'goods_receipt_no':
  //       currentProductTypeGroup[clickedColName] = e.target.value;
  //       const getMaterialObj = materialMovementMaterial.find(item => item.goods_receipt_no === e.target.value);
  //       if (getMaterialObj) {
  //         currentProductTypeGroup.product_material_unit_name = getMaterialObj.product_material_unit_name;
  //         currentProductTypeGroup.product_material_unit_id = getMaterialObj.product_material_unit_id;
  //         currentProductTypeGroup.closing_balance_quantity = getMaterialObj.closing_balance_quantity;
  //         currentProductTypeGroup.closing_balance_weight = getMaterialObj.closing_balance_weight;
  //       }
  //       const availableQty = FnConsumptionChange(currentProductTypeGroup, index, updatePtGroupData)
  //       currentProductTypeGroup['stock_quantity'] = availableQty;
  //       currentProductTypeGroup['stock_weight'] = availableQty * getMaterialObj.cone_per_wt;
  //       currentProductTypeGroup['cone_per_wt'] = getMaterialObj.cone_per_wt;
  //       break;

  //     case 'material_status':
  //       currentProductTypeGroup[clickedColName] = e.target.value;
  //       delete e.target.parentElement.dataset.tip;
  //       break;

  //     case 'consumption_quantity':
  //       if (e.target.value !== '0') {
  //         if (e.target.value > currentProductTypeGroup.stock_quantity) {
  //           e.target.parentElement.dataset.tip = 'Consumption Quantity cant exceed Stock Quantity...!'
  //           currentProductTypeGroup[clickedColName] = validateNumberDateInput.current.decimalNumber((currentProductTypeGroup.stock_quantity).toString(), 4);
  //           setTimeout(function () {
  //             delete e.target.parentElement.dataset.tip;
  //           }, 3000);
  //         } else {
  //           currentProductTypeGroup[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);

  //           // const remainingQty = FnConsumptionChange(currentProductTypeGroup, index, updatePtGroupData)
  //           // if (remainingQty < 0) {
  //           //   e.target.parentElement.dataset.tip = 'Consumption Quantity cannot be greater than stock qunatity...!'
  //           //   return;
  //           // }
  //           delete e.target.parentElement.dataset.tip;
  //         }
  //       }
  //       break;
  //     default:
  //       break;
  //   }

  //   updatePtGroupData[productTypeIndexArray] = currentProductTypeGroup;
  //   setWarpingProductionMaterialData(updatePtGroupData);
  // }

  // /// Function for update stock 
  // const FnConsumptionChange = (object, index, updatePtGroupData) => {
  //   const initialAvailableQty = object.closing_balance_quantity;

  //   const totalConsumedQty = updatePtGroupData.filter(item => item.product_material_id === object.product_material_id && item.goods_receipt_no === object.goods_receipt_no).reduce((acc, curr) => {

  //     const consumptionQty = parseFloat(curr.consumption_quantity) || 0; // Parse as float or default to 0 if it's not a valid number
  //     return acc + consumptionQty;
  //   }, 0);
  //   const remainingQty = parseFloat(initialAvailableQty) - totalConsumedQty;

  //   updatePtGroupData.filter(item => item.product_material_id === object.product_material_id)
  //     .forEach(item => {
  //       item.stock_quantity = remainingQty
  //     })
  //   return remainingQty;
  // };

  // //-----------------------------------------------------
  // const FnFillMaterialOptions = (options, object) => {
  //   options.forEach(item => {
  //     const optionElement = document.createElement('option');
  //     optionElement.value = item.product_material_id;
  //     optionElement.text = item.product_material_name;
  //     optionElement.setAttribute('stock_quantity', item.stock_quantity); // Add custom attribute
  //     optionElement.setAttribute('stock_weight', item.stock_weight); // Add custom attribute
  //     object.appendChild(optionElement);
  //   });
  // }



  // Material quantity consumption 
  // const FnMaterialConsumption = async (item, rowIndex) => {
  //   try {
  //     // debugger;
  //     resetGlobalQuery();
  //     globalQuery.columns = [
  //       'sm.supplier_name',
  //       'sm.customer_name',
  //       'sm.goods_receipt_no',  // Added alias for sm
  //       'sm.godown_name',
  //       'sm.godown_section_name',
  //       'sm.godown_section_beans_name',
  //       'sm.closing_balance_quantity',
  //       'sm.closing_balance_weight',
  //       `sm.product_material_unit_name AS product_unit_name`,
  //       'sm.product_rm_id',
  //       "sm.product_rm_name",
  //       "sm.godown_id",
  //       "sm.godown_section_id",
  //       "sm.godown_section_beans_id"
  //     ];
  //     globalQuery.table = "smv_product_rm_stock_details sm";
  //     globalQuery.conditions.push({ field: "sm.product_rm_id", operator: "=", value: item.product_material_id });
  //     // globalQuery.conditions.push({ field: "sm.closing_balance_quantity", operator: "!=", value: 0 });
  //     globalQuery.conditions.push({ field: "sm.company_id", operator: "=", value: COMPANY_ID, });
  //     // globalQuery.conditions.push({ field: "sm.is_delete", operator: "=", value: 0, });
  //     globalQuery.joins = [
  //       {
  //         "table": "xt_warping_production_order_stock_details xtv",
  //         "type": "inner",
  //         "on": [{
  //           "left": "xtv.product_material_id",
  //           "right": "sm.product_rm_id"
  //         },
  //         {
  //           "left": "xtv.goods_receipt_no",
  //           "right": "sm.goods_receipt_no"
  //         },
  //         {
  //           "left": "xtv.is_delete",
  //           "right": "0"
  //         },
  //         {
  //           "left": "xtv.set_no",
  //           "right": cmb_set_no
  //         }
  //         ]
  //       }
  //     ]
  //     const stockList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

  //     let totalConsumptionQty = item.consumption_quantity;

  //     // This is for existing selected stock for godown
  //     if (item.hasOwnProperty('consumptionQtyInfo')) {
  //       item.consumptionQtyInfo?.map(consumeQty => {
  //         let totalConsumptionQtyGdWise = 0;

  //         let stockDetail = stockList.find(item => item.godown_id === consumeQty.godown_id
  //           && item.godown_section_id === consumeQty.godown_section_id && item.godown_section_beans_id === consumeQty.godown_section_beans_id
  //           && item.goods_receipt_no === consumeQty.goods_receipt_no)

  //         warpingProductionMaterialData.filter(item => item.product_material_id === consumeQty.product_material_id)
  //           .forEach(item => {
  //             if (item.consumptionQtyInfo) {
  //               item.consumptionQtyInfo.filter(filterConumptionInfo =>
  //                 filterConumptionInfo.product_material_id === consumeQty.product_material_id &&
  //                 filterConumptionInfo.godown_id === consumeQty.godown_id &&
  //                 filterConumptionInfo.godown_section_id === consumeQty.godown_section_id &&
  //                 filterConumptionInfo.godown_section_beans_id === consumeQty.godown_section_beans_id
  //                 && filterConumptionInfo.goods_receipt_no === consumeQty.goods_receipt_no
  //               ).map(consumptionQtyInfo => {
  //                 const { consumption_quantity } = consumptionQtyInfo
  //                 totalConsumptionQtyGdWise += parseFloat(consumption_quantity || 0)
  //               })
  //             }
  //           })

  //         if (stockDetail) {
  //           stockDetail.consumptionQtyInfo = consumeQty
  //           stockDetail.closing_balance_quantity = stockDetail.closing_balance_quantity - totalConsumptionQtyGdWise + parseFloat(consumeQty.consumption_quantity || 0)
  //           // Accessing the index of stockDetail in stockList
  //           const stockIndex = stockList.indexOf(stockDetail);
  //           stockList[stockIndex] = stockDetail
  //         }
  //       })
  //     }
  //     // This is for new stock of godown
  //     else {
  //       warpingProductionMaterialData
  //         .filter(item => item.product_material_id === item.product_material_id)
  //         .forEach(item => {
  //           if (item.consumptionQtyInfo) {
  //             item.consumptionQtyInfo.map(consumptionQtyInfo => {
  //               const { product_material_id, godown_id, godown_section_id, godown_section_beans_id, consumption_quantity, goods_receipt_no } = consumptionQtyInfo
  //               const stockIndex = stockList.findIndex(particularMaterialGd =>
  //                 particularMaterialGd.product_rm_id === product_material_id &&
  //                 particularMaterialGd.godown_id === godown_id &&
  //                 particularMaterialGd.godown_section_id === godown_section_id &&
  //                 particularMaterialGd.godown_section_beans_id === godown_section_beans_id
  //                 && particularMaterialGd.goods_receipt_no === goods_receipt_no
  //               );

  //               const getStockObject = stockIndex !== -1 ? stockList[stockIndex] : null;

  //               if (getStockObject) {
  //                 // Update key-value pair in getStockObject
  //                 getStockObject.closing_balance_quantity = getStockObject.closing_balance_quantity - consumption_quantity;

  //                 // Set the updated object back to the stockList array
  //                 stockList[getStockObject.index] = getStockObject;
  //               }
  //             })
  //           }
  //         })

  //       stockList.map((stocks) => {
  //         const consumption_quantity = totalConsumptionQty > 0 ? Math.min(stocks.closing_balance_quantity, totalConsumptionQty) : 0;

  //         stocks.consumptionQtyInfo = {
  //           index: rowIndex,
  //           shift: item.shift,
  //           set_no: item.set_no,
  //           product_material_id: item.product_material_id,
  //           consumption_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(consumption_quantity), 4),
  //           godown_id: stocks.godown_id,
  //           godown_section_id: stocks.godown_section_id,
  //           godown_section_beans_id: stocks.godown_section_beans_id,
  //           goods_receipt_no: stocks.goods_receipt_no
  //         };
  //         totalConsumptionQty -= stocks.closing_balance_quantity;
  //       })
  //     }



  // console.log(stockList);
  //     setMaterialName($(`#product_material_id_${rowIndex} option:selected`).text())
  //     setUnitName(item.product_material_unit_name)
  //     setConsumptionQty(item.consumption_quantity)
  //     setMaterialStockList(stockList)
  //     setShow(true)

  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // const FnMaterialWiseStockQtyConsumption = (item, event, index) => {
  //   const stockData = [...materialStockList]
  //   let clickedColName = event.target.getAttribute('Headers');
  //   let enteredValue = validateNumberDateInput.current.decimalNumber(event.target.value, 4)

  //   if (enteredValue > item.closing_balance_quantity) {
  //     let actualvalidation = document.querySelector(`#${event.target.id}`);
  //     actualvalidation.parentElement.dataset.tip = `Consumption quantity cannot be greater than stock quantity...!`;
  //     return false;
  //   } else {
  //     item[clickedColName] = enteredValue;
  //     item.consumptionQtyInfo.consumption_quantity = enteredValue;
  //     delete event.target.parentElement.dataset.tip;
  //     stockData[index] = item
  //     setMaterialStockList(stockData);
  //   }
  // }

  // const FnCloseStockConsumptionModal = () => {
  //   const stockData = [...materialStockList]
  //   const wpMovementData = [...warpingProductionMaterialData]

  //   // Check consumption quantity & stock qty matches or not validation
  //   const validateConsumptionQty = FnValidateStockConsumption()

  //   if (validateConsumptionQty) {
  //     const wpIndex = stockData[0]?.consumptionQtyInfo.index;
  //     if (wpIndex >= 0 && wpIndex < wpMovementData.length) {
  //       if (!wpMovementData[wpIndex].consumptionQtyInfo) {
  //         wpMovementData[wpIndex].consumptionQtyInfo = [];
  //       } else {
  //         wpMovementData[wpIndex].consumptionQtyInfo = [];
  //       }
  //     } else {
  //       console.error(`Index ${wpIndex} is out of range.`);
  //     }

  //     stockData.forEach(stock => {
  //       wpMovementData[wpIndex].consumptionQtyInfo.push(stock.consumptionQtyInfo);
  //     });
  //     // console.log(wpMovementData);
  //     setWarpingProductionMaterialData(wpMovementData)
  //     setShow(false)
  //   }
  // };

  // const FnValidateStockConsumption = () => {
  //   // debugger
  //   let erorMsgObj = document.querySelector(`#stock_consumption_error`);
  //   const stockData = [...materialStockList]
  //   let total_consumption_qty = stockData.reduce((total, item) => total + parseFloat(item.consumptionQtyInfo.consumption_quantity), 0)
  //   $('#stock_consumption_error').empty()
  //   if (total_consumption_qty < parseFloat(consumptionQty)) {
  //     erorMsgObj.textContent = "Total stock consumption quantity is less than consumption quantity...!";
  //     $('#stock_consumption_error').show();
  //     return false;
  //   } else if (total_consumption_qty > parseFloat(consumptionQty)) {
  //     if (erorMsgObj) {
  //       erorMsgObj.textContent = "Total stock consumption quantity cannot be greater than consumption quantity...!";
  //       $('#stock_consumption_error').show();
  //     }
  //     return false;
  //   }

  //   $('#stock_consumption_error').hide();
  //   return true;
  // };

  // const renderMaterialMovementTbl = useMemo(() => {
  //   return <Table id='warpingMaterialTbl' className={`erp_table mt-1`} responsive bordered striped>
  //     <thead className="erp_table_head">
  //       <tr>
  //         <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th>
  //         <th className="erp_table_th">Sr no .</th>
  //         <th className="erp_table_th">Date</th>
  //         <th className="erp_table_th">Shift</th>
  //         <th className="erp_table_th">Material Name</th>
  //         <th className="erp_table_th">Wt/Pkg.</th>
  //         <th className="erp_table_th">Lot No</th>
  //         <th className="erp_table_th">Stock Qty.</th>
  //         <th className="erp_table_th">Stock Wt.</th>
  //         <th className="erp_table_th">Consumption Quantity</th>
  //         <th className="erp_table_th">Material Status</th>
  //         <th className="erp_table_th">Status Remark</th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {warpingProductionMaterialData.map((item, index) =>

  //         <tr rowindex={index} className="sticky-column">
  //           <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
  //             {!item.weaving_production_warping_material_id ?
  //               <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
  //               : null
  //             }
  //             <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCountAndAddRow(index)} />
  //           </td>


  //           <td className='erp_table_td'>
  //             {index + 1}
  //           </td>
  //           <td className='erp_table_td'>
  //             {
  //               keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && !item.weaving_production_warping_material_id
  //                 ? <>
  //                   <input
  //                     type="date"
  //                     className="erp_input_field mb-0"
  //                     disabled={item.material_status_desc === 'Approved'}
  //                     Headers="warping_production_date"
  //                     id={`warping_production_date_${index}`}
  //                     value={item.warping_production_date}
  //                     onChange={(e) => FnUpdateWpMaterialMovementRecords(item, e, index)}
  //                   />
  //                 </>
  //                 : item.warping_production_date
  //             }
  //           </td>
  //           <td className={`erp_table_td `}>
  //             {
  //               keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && !item.weaving_production_warping_material_id
  //                 ? <select id={`shift_${index}`} value={item.shift} className="erp_input_field_table_txt mb-0"
  //                   Headers="shift"
  //                   disabled={item.material_status_desc === 'Approved'}
  //                   onChange={(e) => FnUpdateWpMaterialMovementRecords(item, e, index)}
  //                 >
  //                   <option value="">Select</option>
  //                   {shiftOptions?.map(shift => (
  //                     <option value={shift.field_name}>{shift.field_name}</option>
  //                   ))}
  //                 </select>
  //                 : item.shift
  //             }
  //           </td>

  //           <td className={`erp_table_td`}>
  //             {keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && !item.weaving_production_warping_material_id ?
  //               <select className="form-select form-select-sm mb-0" id={`product_material_id_${index}`} value={item.product_material_id}
  //                 onChange={(e) => { FnUpdateWpMaterialMovementRecords(item, e, index); }}
  //                 disabled={item.material_status_desc === 'Approved'}
  //                 Headers='product_material_id' >
  //                 <option value="">Select</option>
  //                 {
  //                   materialMovementMaterial.map(item => (
  //                     <option value={item.product_material_id}>{item.product_material_name}</option>
  //                   ))
  //                 }
  //               </select> : item.product_material_name
  //             }
  //           </td>

  //           <td className={`erp_table_td`}>{item.cone_per_wt}</td>

  //           <td className={`erp_table_td`}>
  //             {keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && !item.weaving_production_warping_material_id ?
  //               <select className="form-select form-select-sm mb-0" id={`goods_receipt_no_${index}`} value={item.goods_receipt_no}
  //                 onChange={(e) => { FnUpdateWpMaterialMovementRecords(item, e, index); }}
  //                 disabled={item.material_status_desc === 'Approved'}
  //                 Headers='goods_receipt_no' >
  //                 <option value="">Select</option>
  //                 {
  //                   materialMovementMaterial.map(item => (
  //                     <option value={item.goods_receipt_no}>{item.goods_receipt_no}</option>
  //                   ))
  //                 }
  //               </select> : item.goods_receipt_no
  //             }
  //           </td>

  //           <td className={`erp_table_td`}>{item?.stock_quantity ? item.stock_quantity : 0}</td>
  //           <td className={`erp_table_td`}>{item?.stock_weight ? item.stock_weight : 0}</td>
  //           <td className='erp_table_td'>
  //             {
  //               keyForViewUpdate !== 'view' && !item.weaving_production_warping_material_id
  //                 ? <div className='d-flex' style={{ alignItems: 'center' }}>
  //                   <input
  //                     type="text"
  //                     className="erp_input_field_table_txt remove0 mb-0 ms-1 me-1"
  //                     id={`consumption_quantity_${index}`}
  //                     value={item.consumption_quantity}
  //                     Headers="consumption_quantity"
  //                     style={{ backgroundColor: '#AFE1AF', width: '100px' }}
  //                     onChange={(e) => { FnUpdateWpMaterialMovementRecords(item, e, index); }}
  //                     disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
  //                     maxLength='19'
  //                   />
  //                   {/* <FaDatabase onClick={() => FnMaterialConsumption(item, index)} /> */}
  //                 </div>
  //                 : item.consumption_quantity
  //             }
  //           </td>

  //           <td className="erp_table_td">
  //             <select id={`material_status_${index}`} className="erp_input_field_table_txt form-select form-select-sm mb-0" value={item.material_status}
  //               disabled={isApprove ? item.material_status_desc === 'Approved' : true}
  //               onChange={(e) => { FnUpdateWpMaterialMovementRecords(item, e, index); }}
  //               Headers='material_status'>
  //               <option value="P">Pending</option>
  //               <option value="A">Approved</option>
  //               <option value="R">Rejected</option>
  //               <option value="I">Partial Issue</option>
  //               <option value="C">Completed</option>
  //               <option value="X">Canceled</option>
  //               <option value="Z">PreeClosed</option>
  //             </select>
  //           </td>

  //           <td className='erp_table_td'>
  //             {keyForViewUpdate !== 'view' ? (
  //               <input
  //                 type="text"
  //                 id={`status_remark_${index}`}
  //                 // disabled={!isApprove}
  //                 className="erp_input_field_table_txt mb-0"
  //                 value={item.status_remark}
  //                 onChange={(e) => {
  //                   FnUpdateWpMaterialMovementRecords(item, e, index);
  //                 }}
  //               />
  //             ) : (
  //               item.status_remark
  //             )}
  //           </td>
  //         </tr>
  //       )}



  //     </tbody>
  //   </Table>
  // }, [warpingProductionMaterialData, materialMovementMaterial, cmb_set_no]);



  ///////////////////Bottom Details Entry///////////////
  const FnManageBottomDetailsTbl = async (action, rowIndex) => {
    debugger;
    try {
      setIsLoading(true)
      let warpingBottomDetails = [...warpingBottomDetailsData];
      // let totals = { ...BottomDetails };

      switch (action) {
        case 'add':
          // Simply add the new bottomDetailsObj to the list
          // let generateSrNo = await FnGetLastsrNo();
          bottomDetailsObj['sr_no'] = `${cmb_set_no_ref.current.value}-${last_sr_no + 1}`;
          setWarpingBottomDetailsData([...warpingBottomDetails, bottomDetailsObj]);
          setLastSrNo(last_sr_no + 1);
          break;

        case 'remove':
          // Remove the item at rowIndex
          warpingBottomDetails.splice(rowIndex, 1);

          let warping_issue_kg = parseFloat($("#warping_issue_kg").val()) || 0;
          let total_net_weight = parseFloat(validateNumberDateInput.current?.decimalNumber(warpingBottomDetails.reduce((acc, data) => acc + parseFloat(data['net_weight']), 0), 4));
          // If there are still items in the list, calculate the totals
          // warpingBottomDetails.forEach((obj) => {
          //   Object.keys(totals).forEach((key) => {
          //     totals[key] += parseFloat(obj[key]) || 0; // Sum each property, default to 0 if missing
          //   });

          // });

          // Calculate actualBottomPer only when warping_issue_kg is greater than 0
          let actualBottomPer = parseFloat((warping_issue_kg > 0 ? (total_net_weight * 100) / warping_issue_kg : 0).toFixed(2));
          let difference_bottom_percent_value = parseFloat((txt_calculative_bottom_percent - actualBottomPer).toFixed(2));
          let actual_bottom_kg_value = parseFloat((total_net_weight).toFixed(2));
          let difference_bottom_kg_value = parseFloat((txt_calculative_bottom_kg - total_net_weight).toFixed(2));
          //let totals_value = parseFloat((totals).toFixed(2));

          // Update the bottom details
          setActualBottomKg(actual_bottom_kg_value);
          setActualBottomPer(actualBottomPer);
          setDiffBottomKg(difference_bottom_kg_value);
          setDiffBottomPer(difference_bottom_percent_value);
          // setTotalBottamDetailsObj(totals);

          // If warpingBottomDetails is empty, set data to a single empty object (or a new blank object)
          if (warpingBottomDetails.length === 0) {
            setWarpingBottomDetailsData([bottomDetailsObj]);
          } else {
            setWarpingBottomDetailsData(warpingBottomDetails);
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.error('Error in FnManageBottomDetailsTbl:', error);
    } finally {
      setIsLoading(false)
    }
  };

  //Ref for Yarn Type Search Filter
  const yarnTypeRef = useRef([]);
  const coneTypeRef = useRef([]);
  const coneTypeOptions = useRef([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const formattedDate = (date) => {
    const validDate = new Date(date); // Ensure it's a Date object
    if (isNaN(validDate)) {
      throw new Error("Invalid date provided");
    }
    return validDate.toISOString().split('T')[0].split('-').reverse().join('-');
  };



  const renderBottomDeailsTable = useMemo(() => {
    return <Table className="erp_table " id="bottomDetailTblID" responsive bordered striped>
      <thead className="erp_table_head">
        <tr>
          <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>Action</td>
          {/* <td className="erp_table_th">Creel Name</td> */}
          <td className="erp_table_th">Prod. Date</td>
          <td className="erp_table_th">Sr. No</td>
          <td className="erp_table_th" >Package</td>
          <td className="erp_table_th" >Gross Weight</td>
          <td className="erp_table_th" >Yarn Tare Type</td>
          <td className="erp_table_th" >Cone Type</td>
          <td className="erp_table_th" >Tare Weight</td>
          <td className="erp_table_th" >Net Weight</td>
        </tr>
      </thead>
      <tbody>

        {
          warpingBottomDetailsData.length > 0 ? (
            warpingBottomDetailsData?.map((details, indexOfItem) => (
              <tr rowindex={indexOfItem}>
                <td className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                  <IoAddCircleOutline className={`erp_trAdd_icon`} onClick={() => FnManageBottomDetailsTbl('add', indexOfItem)} />
                  {/* <IoRemoveCircleOutline className={`erp_trRemove_icon  ${details.weaving_production_warping_bottom_details_id !== 0 ? 'd-none' : 'display' }`} onClick={() => FnManageBottomDetailsTbl('remove', indexOfItem)} /> */}
                </td>

                <td className="erp_table_td">{formattedDate(details.warping_bottom_details_production_date)}</td>
                <td className="erp_table_td">{details.sr_no}</td>

                <td key={indexOfItem}>
                  <input
                    type="text"
                    id={`no_of_package_${indexOfItem}`}
                    disabled={keyForViewUpdate === 'view'}
                    Headers='no_of_package'
                    style={{ backgroundColor: '#AFE1AF', width: '65px' }}
                    className="erp_input_field_table_txt remove0 mb-0 text-end"
                    value={details.no_of_package}
                    onChange={(e) => { FnUpdateWpBottomDetails(details, e, indexOfItem); }}
                  />
                </td>

                <td key={indexOfItem}>
                  <input
                    type="text"
                    id={`gross_weight_${indexOfItem}`} disabled={keyForViewUpdate === 'view'}
                    // disabled={!isApprove}
                    Headers='gross_weight'
                    className="erp_input_field_table_txt remove0 mb-0 text-end"
                    style={{ backgroundColor: '#AFE1AF', width: '65px' }}
                    value={details.gross_weight}
                    onChange={(e) => { FnUpdateWpBottomDetails(details, e, indexOfItem); }}
                  />
                </td>


                <td className={`erp_table_td`}>

                  {keyForViewUpdate === 'view' || (keyForViewUpdate === 'update' && details?.['weaving_production_warping_bottom_details_id'] > 0) ? details['package_type'] :
                    <Select
                      isMulti
                      ref={(ref) => yarnTypeRef.current[indexOfItem] = ref}  // Set ref for the current select
                      options={bottomtyretypeoptions.map((item) => ({
                        value: item.properties_master_name,
                        label: item.properties_master_name
                      }))}
                      value={bottomtyretypeoptions?.filter((packagetype) =>
                        details['package_type']?.includes(packagetype.properties_master_name)
                      ).map((item) => ({
                        value: item.properties_master_name,
                        label: item.properties_master_name
                      }))}  // Set the selected values

                      onBlur={() => {
                        debugger
                        const selectedOptions = yarnTypeRef.current[indexOfItem].getValue();
                        let selectedValues = selectedOptions.map((option) => option['value']);
                        FnManageYarnTypeOptions(selectedValues, indexOfItem);
                      }}

                      onChange={(selected) => {
                        let event = { target: { getAttribute: (attr) => { if (attr === 'Headers') { return 'package_type' } } } };
                        const selectedValues = selected.map((option) => option.value);
                        FnUpdateWpBottomDetails(details, event, indexOfItem, selectedValues);
                      }}

                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (provided) => ({ ...provided, zIndex: 1000, width: '80%' }),
                        option: (provided) => ({ ...provided, fontSize: '12px' }),
                        control: (provided) => ({ ...provided, minWidth: '145px', minHeight: '12px' }),
                      }}

                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      isDisabled={keyForViewUpdate === 'view' || details.weaving_production_warping_bottom_details_id !== 0}
                    />
                  }

                </td>


                <td className={`erp_table_td`}>
                  {keyForViewUpdate === 'view' || (keyForViewUpdate === 'update' && details?.['weaving_production_warping_bottom_details_id'] > 0) ? details['cone_type'] :

                    <Select
                      isMulti
                      ref={(ref) => coneTypeRef.current[indexOfItem] = ref} // Set ref for the current select
                      options={coneTypeOptions.current[indexOfItem] || []} // Access options from state
                      value={coneTypeOptions.current[indexOfItem]?.filter((conetype) =>
                        details['cone_type']?.includes(conetype.value)
                      ).map((item) => ({
                        value: item.value,
                        label: item.label
                      }))} // Set the selected values

                      onChange={(selected) => {
                        let event = { target: { getAttribute: (attr) => (attr === 'Headers' ? 'cone_type' : ''), event_name: 'onChange' } };
                        const selectedValues = selected.map((option) => option.value);
                        FnUpdateWpBottomDetails(details, event, indexOfItem, selectedValues);
                      }}

                      onBlur={(selected) => {
                        let event = { target: { getAttribute: (attr) => (attr === 'Headers' ? 'cone_type' : ''), event_name: 'onBlur' } };
                        const selectedValues = coneTypeOptions.current[indexOfItem]?.filter((conetype) =>
                          details['cone_type']?.includes(conetype.value)
                        )
                        FnUpdateWpBottomDetails(details, event, indexOfItem, selectedValues);
                      }}


                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),

                        menu: (provided) => ({ ...provided, zIndex: 1000, width: '80%' }),
                        option: (provided) => ({ ...provided, fontSize: '12px' }),
                        control: (provided) => ({ ...provided, minWidth: '145px', minHeight: '12px' }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      isDisabled={keyForViewUpdate === 'view' || details.weaving_production_warping_bottom_details_id !== 0}
                    />
                  }


                </td>

                <td key={indexOfItem} className="erp_table_td">
                  <input
                    type="text"
                    id={`tare_weight_${indexOfItem}`} disabled={keyForViewUpdate === 'view'}
                    style={{ backgroundColor: '#AFE1AF', width: '75px' }}
                    // disabled={!isApprove}
                    Headers='tare_weight'
                    className="erp_input_field_table_txt remove0 mb-0 text-end"
                    value={details.tare_weight}
                    onChange={(e) => { FnUpdateWpBottomDetails(details, e, indexOfItem); }}
                  />
                </td>

                <td key={indexOfItem} className="erp_table_td text-end" >
                  {parseFloat(details.net_weight)}
                </td>
              </tr>
            ))) : (
            <tr>
              <td className="erp_table_td" colSpan="10">
                No records found...
              </td>
            </tr>
          )}

        <tr className="sticky-column">
          <td
            className="erp_table_td"
            style={{ textAlign: 'right', fontWeight: 'bold' }}
            colSpan={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 2 : 3}
          >
            Totals
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingBottomDetailsData.reduce((acc, data) => acc + parseFloat(data['no_of_package']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingBottomDetailsData.reduce((acc, data) => acc + parseFloat(data['gross_weight']), 0), 4)}
          </td>
          <td className="erp_table_td d-none" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingBottomDetailsData.reduce((acc, data) => acc + parseFloat(data['no_of_boxes']), 0), 4)}
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={2}>
            { }
          </td>
          <td className="erp_table_td" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingBottomDetailsData.reduce((acc, data) => acc + parseFloat(data['tare_weight']), 0), 4)}
          </td>
          <td className="erp_table_td" id="total_net_weight" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {validateNumberDateInput.current?.decimalNumber(warpingBottomDetailsData.reduce((acc, data) => acc + parseFloat(data['net_weight']), 0), 4)}
          </td>
        </tr>
      </tbody>
    </Table>
  }, [warpingBottomDetailsData, creelOptions, coneTypeOptions, bottomtyretypeoptions, updateTrigger]);





  const FnManageYarnTypeOptions = (package_type, index) => {
    try {
      setIsLoading(true);
      const filteredOptions = conetypeoptions
        .filter((coneoptions) => package_type.includes(coneoptions.properties_master_name))
        .map((filteredOptions) => ({
          value: filteredOptions.property_name,
          label: filteredOptions.property_name,
          property_value: filteredOptions.property_value,
          properties_master_name: filteredOptions.properties_master_name
        }));

      coneTypeOptions.current[index] = filteredOptions;

      // Trigger a re-render
      setUpdateTrigger((prev) => prev + 1);

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  window.addEventListener('error', (e) => {
    if (e.message.includes("ResizeObserver loop")) {
      e.preventDefault();
    }
  });


  const FnUpdateWpBottomDetails = (rowdata, e, indexOfItem, selectedValues /*For package_type & cone_type*/) => {
    try {
      debugger
      $("#error_warping_issue_kg").hide();
      $("#error_warping_issue_cones").hide();
      $("#error_warping_issue_boxes").hide();
      const bottomDetailsData = [...warpingBottomDetailsData];
      const originalRowData = { ...bottomDetailsData[indexOfItem] };
      let clickedColName = e.target.getAttribute('Headers');
      let enteredValue = 0;
      if (!['package_type', 'cone_type'].includes(clickedColName)) {
        delete e.target.parentElement.dataset.tip;
        enteredValue = e.target.value === '' ? 0 : e.target.value;
      }

      switch (clickedColName) {
        case "no_of_boxes":
          rowdata[clickedColName] = parseInt(enteredValue)
          break;
        case "creel_no":
          rowdata[clickedColName] = enteredValue
          break;
        case "package_type":
          rowdata['tare_weight'] = ''; rowdata['cone_type'] = '';
          rowdata['cone_type_value'] = 0;
          rowdata[clickedColName] = selectedValues; // here e stores as Array//
          break;
        case "no_of_package":
        case "gross_weight":
          rowdata[clickedColName] = enteredValue;
          if (keyForViewUpdate === "update" && rowdata?.weaving_production_warping_bottom_details_id > 0) {
            let coneType = rowdata.cone_type.split(",").map(type => type.trim()); // Convert to array
            let tare_weight = 0;
            
            let selectedValues = conetypeoptions.filter((tyre) => coneType.includes(tyre.property_name));
        
            selectedValues.forEach((data) => {
                if (data['properties_master_name'] === 'BottomYarnTareType1') {
                    tare_weight += (parseFloat(data['property_value']) * parseFloat(rowdata['no_of_package']));
                } else if (data['properties_master_name'] === 'BottomYarnTareType2') {
                    tare_weight += parseFloat(data['property_value']);
                }
            });
        
            // Setting net weight
            rowdata['tare_weight'] = validateNumberDateInput.current.decimalNumber(tare_weight, 4);
            rowdata['net_weight'] = validateNumberDateInput.current.decimalNumber((rowdata['gross_weight'] - rowdata['tare_weight']), 4);
        }
        
          break;
        case "cone_type":
          //Setting cone type value
          let tare_weight = 0;
          if (e.target.event_name === 'onChange') {
            rowdata[clickedColName] = selectedValues;
            //Setting net weight
            rowdata['net_weight'] = validateNumberDateInput.current.decimalNumber((rowdata['gross_weight'] - rowdata['tare_weight']), 4);

          } else if (e.target.event_name === 'onBlur') {
            //Setting tare weight
            selectedValues.forEach((data, index) => {
              if (data['properties_master_name'] === 'BottomYarnTareType1') {
                tare_weight += (parseFloat(data['property_value']) * parseFloat(rowdata['no_of_package']));
              } else if (data['properties_master_name'] === 'BottomYarnTareType2') {
                tare_weight += parseFloat(data['property_value']);
              }
            });
            //Setting net weight
            rowdata['tare_weight'] = validateNumberDateInput.current.decimalNumber((tare_weight), 4);
            rowdata['net_weight'] = validateNumberDateInput.current.decimalNumber((rowdata['gross_weight'] - rowdata['tare_weight']), 4);
          }

          break;
      }


      bottomDetailsData[indexOfItem] = { ...rowdata };
      // let totals = { ...BottomDetails };
      // Calculate totals for each key in TotalsWarpingProd
      // if (Object.keys(totals).includes(clickedColName)) {
      // bottomDetailsData.forEach((obj) => {
      //   Object.keys(totals).forEach((key) => {
      //     totals[key] += parseFloat(obj[key]) || 0; // Sum each property, default to 0 if the property is missing
      //   });
      // });

      let total_net_weight = parseFloat(validateNumberDateInput.current?.decimalNumber(bottomDetailsData.reduce((acc, data) => acc + parseFloat(data['net_weight']), 0), 4));
      let actualBottomPer = parseFloat(validateNumberDateInput.current?.decimalNumber(((total_net_weight * 100) / warping_issue_kg), 4));
      let setActualBottomKg_value = parseFloat(validateNumberDateInput.current?.decimalNumber(total_net_weight, 4));
      let setDiffBottomKg_value = parseFloat(validateNumberDateInput.current?.decimalNumber((txt_calculative_bottom_kg - total_net_weight), 4));
      let setDiffBottomPer_value = parseFloat(validateNumberDateInput.current?.decimalNumber((txt_calculative_bottom_percent - actualBottomPer), 4));
      //let totals_value = parseFloat((totals).toFixed(2));

      setActualBottomKg(setActualBottomKg_value);
      setActualBottomPer(actualBottomPer);
      setDiffBottomKg(setDiffBottomKg_value);
      setDiffBottomPer(setDiffBottomPer_value);
      // setTotalBottamDetailsObj(totals);
      // }

      setWarpingBottomDetailsData(bottomDetailsData);
    } catch {

    }
  }




  const FnManageTotalTime = () => {
    let from_time = $("#from_time").val();
    let to_time = $("#to_time").val();

    if (from_time && to_time) {
      const [fromHours, fromMinutes] = from_time.split(':').map(Number);
      const [toHours, toMinutes] = to_time.split(':').map(Number);

      // Convert times to minutes
      const fromTimeInMinutes = fromHours * 60 + fromMinutes;
      const toTimeInMinutes = toHours * 60 + toMinutes;

      if (toTimeInMinutes > fromTimeInMinutes) {
        const totalMinutes = toTimeInMinutes - fromTimeInMinutes;
        const totalHours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;

        setTotalTime(`${totalHours}h ${remainingMinutes}m`);
      } else {
        setTotalTime('Invalid time range');
      }
    } else {
      setTotalTime(''); // Reset if any of the times are missing
    }
  };

  //**********************************Sticker Printing start************************************************** */

  const stickerRef = useRef();

  
  
  
    const tableHeadersRight = [
      { label: 'YARN PARTY:-', field: 'supplier_name' },
      { label: 'COUNT:-', field: 'yarn_count' },
      { label: 'SET NO :-', field: 'set_no' },
      { label: 'SR NO:-', field: 'sr_no' },
      { label: 'DATE:-', field: 'warping_bottom_details_production_date' },
      { label: 'CONE TYPE:-', field: 'cone_type' },
      { label: 'GROSS WT:-', field: 'gross_weight' },
      { label: 'TARE WT:-', field: 'tare_weight' },
      { label: 'NET WT:-', field: 'net_weight' },
    ];
  
    const Sticker = React.forwardRef(({ warpingBottomDetailsData, yarn_count, supplier_name }, ref) => {
      return (
        <div ref={ref} className="sticker">
          {warpingBottomDetailsData?.reduce((acc, data, index) => {
            if (index % 2 === 0) {
              acc.push([]);
            }
            acc[acc.length - 1].push(data);
            return acc;
          }, []).map((rowDataPair, rowIndex) => (
            <div className="row p-2" key={`warpingBottomDetails-row-${rowIndex}`}>
              {rowDataPair.map((data, colIndex) => (
                <div className="col-sm-6" key={`warpingBottomDetails-col-${colIndex}`}>
                  <TableComponent
                    rows={[{ ...data, yarn_count, supplier_name }]}
                    headers={tableHeadersRight}
                    dataIndex={0} 
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    });
    
  
    const TableComponent = ({ rows = [], headers = [], dataIndex }) => (
      <table  className="erp_table"  style={{   width: '100%',  tableLayout: 'fixed',   borderCollapse: 'collapse',  border: '1px solid #ccc',  }}  >
        <thead>
            <tr>
              <th colSpan="2"  style={{  textAlign: 'center', fontWeight: 'bold',  padding: '5px',  border: '1px solid black', fontSize: '14px',  }}  >
                Pashupati
              </th>
            </tr>
        </thead>
        <tbody>
          {headers.map((header, headerIndex) => (
            <tr key={`header-row-${headerIndex}`}>
              <td className="erp_table_th"  style={{  padding: '2px',  border: '1px solid #ccc',  textAlign: 'center', }}  >
                {header.label}
              </td>
              <td key={`row-${headerIndex}`}  className="erp_table_td"  style={{   padding: '2px',  border: '1px solid #ccc',  textAlign: 'center',   }}  >
                {rows[dataIndex] && rows[dataIndex][header.field]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  
  
    const handlePrint = () => {
      if (stickerRef.current) {
        // Trigger print directly
        stickerRef.current.print();
      }
    };


    //**************************Sticker Print Ends ******************************************************* */




  return (
    <>
<style>
{`
  .sticker {
    display: none; /* Hide in normal view */
  }

  @media print {
    .sticker {
      display: block; /* Show when printing */
      width: 100%;
      font-weight: bold; /* Make text bold */
    }

    .row {
      display: flex; 
      flex-wrap: wrap; /* Ensures wrapping when needed */
      width: 100%;
      page-break-inside: avoid; /* Prevent row from breaking across pages */
    }

    .col-sm-6 {
      flex: 1; /* Ensures equal width for both tables */
      max-width: 50%; /* Restrict width to half */
      padding: 0 10px; /* Add spacing between tables */
      font-weight: bold; /* Make text bold */
    }

    .erp_table {
      width: 100%; /* Ensure full width */
      page-break-inside: avoid; /* Avoid breaking tables across pages */
      font-weight: bold; /* Make text bold */
      border: 1px  !important; /* Thick and dark table border */
      border-collapse: collapse !important; /* Ensures borders are merged properly */
    }

    .erp_table_th,
    .erp_table_td {
      page-break-inside: avoid; /* Ensure table cells don't break */
      font-weight: bold; /* Make table text bold */
      border: 1px solid black !important; /* Make cell borders dark and bold */
      padding: 10px; /* Add padding for better readability */
    }

    /* Hide print buttons */
    .erp-gb-button {
      display: none;
    }
  }
`}
</style>



      <DashboardLayout>
        {/* <ComboBox ref={combobox} /> */}
        <ComboBox ref={comboDataAPiCall} />
        <ValidateNumberDateInput ref={validateNumberDateInput} />
        <GenerateMaterialId ref={generateAutoNoAPiCall} />
        <FrmValidations ref={validate} />

        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div> :
          ''}

        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Warping Production{actionType} </label>
          </div>

          <form id='Warpingproduction'>
            <div className='row p-1'>

              <div className='col-sm-4 erp_form_col_div'>

                {/* <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Set No <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select id="cmb_set_no" className="form-select form-select-sm" value={cmb_set_no} onChange={(e) => { comboOnChange('cmb_set_no'); validateFields(); }}>
                      <option >Select</option>
                      {setNoOptions.length !== 0 ? (
                        <>
                          {setNoOptions?.map(production => (
                            <option value={production.set_no}>{production.set_no}</option>
                          ))}
                        </>
                      ) : null}
                    </select>

                  </div>
                  <MDTypography variant="button" id="error_cmb_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div> */}
                <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Set No <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Select

                      ref={cmb_set_no_ref}
                      options={setNoOptions}
                      isDisabled={['view', 'update', 'approve'].includes(keyForViewUpdate)}
                      inputId="cmb_set_no"
                      value={setNoOptions.length > 0 ? setNoOptions.find(option => option.value === cmb_set_no) : null}

                      onChange={(selectedOpt) => {
                        cmb_set_no_ref.current = selectedOpt;
                        comboOnChange('cmb_set_no');

                      }}
                      placeholder="Search Set No..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '12px'
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: '12px'
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: '12px'
                        })
                      }}
                    />
                    
                  </div>
                  <MDTypography
                      variant="button"
                      id="error_cmb_set_no"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    />
                </div>




                {/* <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Year  </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" id='txt_prod_year' className="erp_input_field" value={txt_prod_year} disabled />
                    <MDTypography variant="button" id="error_txt_prod_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Plant Name <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { comboOnChange('cmb_plant_id'); validateFields(); }}>
                      <option value="" disabled>Select</option>
                      {plantOptions.length !== 0 ? (
                        <>{plantOptions?.map(plant => (
                          <option value={plant.field_id}>{plant.field_name}</option>
                        ))} </>
                      ) : null
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>



                <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Supervisor <span className="required">*</span>  </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Select ref={cmb_prod_supervisor_id_ref} options={machineOperatorOptions} isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                      inputId="cmb_prod_supervisor_id" value={machineOperatorOptions.length > 1 ? machineOperatorOptions.find(option => option.value === cmb_prod_supervisor_id) : null} onChange={(selectedOpt) => {
                        setProdSupervisor(selectedOpt.value); cmb_prod_supervisor_id_ref.current = selectedOpt;
                      }}
                      placeholder="Search Supervisor..." className="form-search-custom" classNamePrefix="custom-select" disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />

                    <MDTypography variant="button" id="error_cmb_prod_supervisor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Production Date <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="date" id='dt_warping_production_date' className="erp_input_field" value={dt_warping_production_date} onChange={(e) => { setMonthYear(); fillAutoNo(); validateFields(); }} />
                    <MDTypography variant="button" id="error_dt_warping_production_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Section  </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select id="cmb_section_id" className="form-select form-select-sm" disabled value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); comboOnChange('cmb_section_id'); validateFields(); }}>
                      <option value="">Select</option>
                      {prodsectionOptions.length !== 0 ? (
                        <>
                          {prodsectionOptions?.map(production => (
                            <option value={production.field_id}>{production.field_name}</option>
                          ))}
                        </>
                      ) : null}
                    </select>
                    <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Sub Section  </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { setProdSubSection(e.target.value); fillAutoNo(); validateFields(); }} disabled>
                      <option value="">Select</option>
                      {prodsubsectionOptions.length !== 0 ? (
                        <>
                          {prodsubsectionOptions?.map(subproduction => (
                            <option key={subproduction.field_id} shortName={subproduction.production_sub_section_short_name} value={subproduction.field_id}>
                              {subproduction.field_name}
                            </option>
                          ))}
                        </>
                      ) : null}
                    </select>
                    <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

              </div>

              <div className='col-sm-4 erp_form_col_div'>

                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Month & Year </Form.Label>
                  </div>
                  <div className="col-sm-6 d-flex">
                    <Form.Control type="text" id='txt_prod_month' className="erp_input_field" value={txt_prod_month} disabled />
                    <Form.Control type="text" id='txt_prod_year' className="erp_input_field ms-1" value={txt_prod_year} disabled />
                  </div>
                </div>


                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Warping Plan Order No. </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" className="erp_input_field" id="txt_warping_prod_order_code" value={txt_warping_prod_order_code} disabled />
                  </div>
                </div>


                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Warping Prod.Code </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" className="erp_input_field" id="cmb_warping_production_code" maxLength="5" value={cmb_warping_production_code} disabled />
                    <MDTypography variant="button" id="error_cmb_warping_production_code" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row mb-1'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Product Material  </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control as="textarea" id="txt_prod_material_name" className="erp_input_field" value={txt_prod_material_name} disabled />
                  </div>
                </div>

                <div className={`row ${txt_prod_style ? 'display' : 'd-none'}`}>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Material Style</Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" id="txt_prod_style" className="erp_input_field" value={txt_prod_style} disabled optional='optional' />
                  </div>
                </div>

                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Total Ends </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" className="erp_input_field" id="no_of_ends" value={no_of_ends} disabled />
                  </div>
                </div>


                <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Set Length  </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control id="txt_set_length" type="text" className="erp_input_field" value={txt_set_length} disabled />
                  </div>
                </div>

                <Form.Control type="text" id='last_sr_no' className="erp_input_field d-none" value={last_sr_no} disabled />

              </div>

              <div className='col-sm-4 erp_form_col_div'>

                {/* <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <div className="erp_form_radio">
                      <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive} onClick={() => { setIsActive(true); }} /> </div>
                      <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={!chk_isactive} onClick={() => { setIsActive(false); }} /> </div>
                    </div>
                  </div>
                </div> */}

                <div className={`row ${txt_schedule_quantity ? 'display' : 'd-none'}`}>
                  <div className="col-sm-5">
                    <Form.Label htmlFor="txt_schedule_quantity" className="erp-form-label">Schedule Quantity</Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control
                      id="txt_schedule_quantity"
                      type="text"
                      className="erp_input_field"
                      value={txt_schedule_quantity}
                      disabled
                      aria-disabled="true"
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Production Beams </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" className="erp_input_field" id="txt_no_of_beams" value={txt_no_of_beams} disabled />
                  </div>
                </div>

                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Yarn Count </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" className="erp_input_field" id="txt_yarn_count" value={txt_yarn_count} disabled />
                  </div>
                </div>

                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Actual Count </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" className="erp_input_field" id="production_count" value={production_count} disabled />
                  </div>
                </div>


                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Issued Quantity </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" id='warping_issue_cones' className="erp_input_field" value={warping_issue_cones} disabled />
                  </div>
                </div>  <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Issued Weight </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" id='warping_issue_kg' className="erp_input_field" value={warping_issue_kg} disabled />
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Issued Boxes </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" id='warping_issue_boxes' className="erp_input_field" value={warping_issue_boxes} disabled />
                  </div>
                </div>
              </div>

              {/* <div className='col-sm-1 erp_form_col_div'></div> */}

              {/* //////////////////////////////Production Summary Table////////////////////// */}
              {/* <div className="col-sm-4 px-lg-2 d-block">
                <div className="col-lg-12 col-12 erp_form_col_div">
                  <div className="card">
                    <div className="card-header py-0 main_heding mb-0">
                      <label className="erp-form-label-md-lg">Warping Production Summary</label>
                    </div>
                    
                    <div className="card-body p-0">
                      <div className={`table-responsive`}>
                        {renderproductionsummary}
                      </div>

                    </div>
                  </div>
                </div>

              </div> */}

            </div>
          </form>
          <hr />

          {/* ////////////Tabs for Weaving & Material moment//////////// */}
          {/* <MDBox pt={3} pb={8} className="erp_form_tab_div">
            <Grid justifyContent="center" sx={{ my: 4 }}>
              <Grid item xs={12} lg={8} className="erp_form_container_tabs">
                <MDBox mt={-3} mx={2}>
                  <Tabs
                    activeKey={activeStep}
                    id="uncontrolled-tab-example"
                    className="mb-3 selectedTab erp-form-Tabs"
                    onSelect={eventKey => moveByTabs(eventKey)}>
                    {steps.map((label, index) => (
                      <Tab eventKey={index} title={label}></Tab>
                    ))}
                  </Tabs>
                  {getStepContent()}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox> */}


          <Accordion defaultActiveKey='1'>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="erp-form-label-md">Warping Production Details</Accordion.Header>
              <Accordion.Body>
                <div className='row mt-1'><label className="erp-form-label-md-lg">Production Details</label></div>
                {renderWarpingProductionDetails}
                <hr />
                {/* <div className='row mt-2'><label className="erp-form-label-md-lg">Material Moment</label></div>
                {renderMaterialMovementTbl} */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>


          {/*.........................Creel Bottom Entry............................... */}
          <div className="col-lg-12 col-12 erp_form_col_div">
            <div className="card">
              <div className="card-header py-0 main_heding mb-0">
                <label className="erp-form-label-md-lg">Bottom Details Entry</label>
              </div>
              {/* Card's Body Starts*/}
              <div className="card-body p-0">
                <form id='BottomDetailsFormId'>
                  <div className='row p-1'>
                    <div className='col-sm-12 erp_form_col_div'>
                      {/* Creel Bottom Table */}
                      {renderBottomDeailsTable}
                    </div>
                  </div>

                  <div className='row p-1'>

                    <div className='col-sm-4 erp_form_col_div'>
                      <div className='row'>
                        <div className="col-sm-6">
                          <Form.Label className="erp-form-label">Calculative Bottom(Kg) </Form.Label>
                        </div>
                        <div className="col-sm-4">
                          <Form.Control type="text" id='txt_calculative_bottom_kg' className="erp_input_field" value={txt_calculative_bottom_kg} disabled />
                        </div>
                      </div>

                      <div className='row'>
                        <div className="col-sm-6">
                          <Form.Label className="erp-form-label">Calculative Bottom(%) </Form.Label>
                        </div>
                        <div className="col-sm-4">
                          <Form.Control type="text" id='txt_calculative_bottom_percent' className="erp_input_field" value={txt_calculative_bottom_percent} disabled />
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-4 erp_form_col_div'>
                      <div className='row'>
                        <div className="col-sm-6">
                          <Form.Label className="erp-form-label">Actual Bottom(Kg) </Form.Label>
                        </div>
                        <div className="col-sm-4">
                          <Form.Control type="text" id='txt_actual_bottom_kg' className="erp_input_field" value={txt_actual_bottom_kg} disabled />
                        </div>
                      </div>

                      <div className='row'>
                        <div className="col-sm-6">
                          <Form.Label className="erp-form-label">Actual Bottom(%) </Form.Label>
                        </div>
                        <div className="col-sm-4">
                          <Form.Control type="text" id='txt_actual_bottom_percent' className="erp_input_field" value={txt_actual_bottom_percent} disabled />
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-4 erp_form_col_div'>
                      <div className='row'>
                        <div className="col-sm-6">
                          <Form.Label className="erp-form-label">Difference Bottom(Kg) </Form.Label>
                        </div>
                        <div className="col-sm-4">
                          <Form.Control type="text" id='txt_difference_bottom_kg' className="erp_input_field" value={txt_difference_bottom_kg} disabled />
                        </div>
                      </div>


                      <div className='row'>
                        <div className="col-sm-6">
                          <Form.Label className="erp-form-label">Difference Bottom(%) </Form.Label>
                        </div>
                        <div className="col-sm-4">
                          <Form.Control type="text" id='txt_difference_bottom_percent' className="erp_input_field" value={txt_difference_bottom_percent} disabled />
                        </div>
                      </div>
                    </div>

                  </div>

                </form>
              </div>
            </div>
          </div>





          {/*.........................Shiftwise Machine Stoppage Entry............................... */}
          {/* <div className="col-lg-12 col-12 erp_form_col_div">
            <div className="card">
              <div className="card-header py-0 main_heding mb-0">
                <label className="erp-form-label-md-lg">Shiftwise Machine Stoppage Entry</label>
              </div>
              
              <div className="card-body p-0">
                <form id='stoppageFormId'>
                  <div className='row p-1'>

                    <div className='col-sm-3 erp_form_col_div'>
                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Machine No  </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_machine_no" className="form-select form-select-sm" value={cmb_machine_no} onChange={(e) => { setMachineNo(e.target.value); validateFormFields('Stoppage Form'); }}>
                            <option value="">Select</option>
                            {machineNameOption.length !== 0 ? (
                              <>
                                {machineNameOption?.map(machine => (
                                  <option value={machine.field_id}>{machine.field_name}</option>
                                ))}
                              </>
                            ) : null}
                          </select>
                          <MDTypography variant="button" id="error_cmb_machine_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Stopp. Reason  </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_stoppage_reason" className="form-select form-select-sm" value={cmb_stoppage_reason} onChange={(e) => { setMachineStoppageReason(e.target.value); validateFormFields('Stoppage Form'); }}>
                            <option value="">Select</option>
                            {stoppagereasonOptions.length !== 0 ? (
                              <>
                                {stoppagereasonOptions?.map(stoppage => (
                                  <option value={stoppage.field_id}>{stoppage.field_name}</option>
                                ))}
                              </>
                            ) : null

                            }
                          </select>
                          <MDTypography variant="button" id="error_cmb_stoppage_reason" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Creel No.</Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_creel_no" className="form-select form-select-sm" value={cmb_creel_no} onChange={(e) => { setCreelNo(e.target.value); validateFormFields('Stoppage Form'); }}>
                            <option value="">Select</option>
                            {creelOptions.length !== 0 ? (
                              <>
                                {creelOptions?.map(creel => (
                                  <option value={creel.creel_no}>{creel.creel_no}</option>
                                ))}
                              </>
                            ) : null}
                          </select>
                          <MDTypography variant="button" id="error_cmb_creel_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>


                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Shift  </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_shift" className="form-select form-select-sm" value={cmb_shift} onChange={(e) => { setShift(e.target.value); }}>
                            <option value="">Select</option>
                            {shiftOptions.length !== 0 ? (
                              <>
                                {shiftOptions?.map(shift => (
                                  <option value={shift.field_name}>{shift.field_name}</option>
                                ))}
                              </>
                            ) : null

                            }
                          </select>
                          <MDTypography variant="button" id="error_cmb_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Loss Type</Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_loss_type" className="form-select form-select-sm" value={cmb_loss_type} onChange={(e) => { setLossType(e.target.value); validateFields(); }}  >
                            <option value="">Select</option>
                            <option value="Utilization">Utilization</option>
                            <option value="Efficiency">Efficiency</option>
                            <option value="Others">Others</option>
                          </select>
                          <MDTypography variant="button" id="error_cmb_loss_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-5">
                          <Form.Label className="erp-form-label">From Time </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="time" id="from_time" className="erp_input_field optional" value={from_time} onChange={e => { FnManageTotalTime(); }} disabled={keyForViewUpdate === 'view'} />
                          <MDTypography variant="button" id="error_from_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-5">
                          <Form.Label className="erp-form-label">To Time </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="time" id="to_time" className="erp_input_field optional" value={to_time} onChange={e => { FnManageTotalTime(); }} disabled={keyForViewUpdate === 'view'} />
                          <MDTypography variant="button" id="error_to_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-5">
                          <Form.Label className="erp-form-label">Total Time </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="text" id="total_time" className="erp_input_field optional" value={total_time} disabled />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Stoppage Time  </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="text" id='txt_stoppage_time' className="erp_input_field" value={txt_stoppage_time} onChange={(e) => { setMachineStoppageTime(e.target.value); validateFormFields('Stoppage Form'); }} />
                          <MDTypography variant="button" id="error_txt_stoppage_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-3 erp_form_col_div'>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Std. Prod. Loss </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="text" id='txt_std_stoppage_loss_kg' className="erp_input_field" value={txt_std_stoppage_loss_kg} onChange={(e) => { setStdProdLoss(e.target.value); }} disabled optional='optional' />
                          <MDTypography variant="button" id="error_txt_std_stoppage_loss_kg" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Act. Prod. Loss </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="text" id='txt_actual_production_loss_kg' className="erp_input_field" value={txt_actual_production_loss_kg} onChange={(e) => { setActProdLoss(e.target.value); }} disabled optional='optional' />
                          <MDTypography variant="button" id="error_txt_actual_production_loss_kg" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Prod.Loss Kg </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="text" id='txt_stoppage_production_loss_kg' className="erp_input_field" value={txt_stoppage_production_loss_kg} onChange={(e) => { setStoppageProductionLossKg(e.target.value); }} disabled optional='optional' />
                          <MDTypography variant="button" id="error_stoppage_production_loss_kg" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Std.Stopp. Loss/KG</Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="text" id='txt_std_stoppage_loss_per_hour' className="erp_input_field" value={txt_std_stoppage_loss_per_hour} onChange={(e) => { setStdStoppageLossPerHour(e.target.value); }} disabled optional='optional' />
                          <MDTypography variant="button" id="error_std_stoppage_loss_per_hour" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      
                    </div>


                

                    <div className='col-sm-6 erp_form_col_div'>
                      {renderMachineStoppageTable}
                    </div>

                    <div className='row'>
                      <div className='col'>
                        <div className={`erp_frm_Btns  mt-2 mb-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                          <MDButton type="button" id="action_Button" className="erp-gb-button ms-2" onClick={() => FnUpdateStoppageReason('', '', 'add')} variant="button" fontWeight="regular">{labelForStoppage}</MDButton>
                          <MDButton type='button' className="erp-gb-button ms-2" variant="button" id='back_Button' onClick={(e) => clearFormFields('stoppage')} fontWeight="regular" >Clear</MDButton>
                        </div >
                      </div >
                    </div >

                  </div>
                </form>
              </div>
            </div>
          </div> */}

          {/* ///////////////////////////ShiftWise Wastage Entry/////////////////////////// */}


          {/* <div className="col-lg-12 col-12 erp_form_col_div">
            <div className="card">
              <div className="card-header py-0 main_heding mb-0">
                <label className="erp-form-label-md-lg">Shiftwise Machine Wastage Entry</label>
              </div>
              
              <div className="card-body p-0">

                <form id='wastageFormId'>
                  <div className='row p-1'>
                    <div className='col-sm-4 erp_form_col_div'>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Prod. Wastage Type </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_prod_wastage_type" className="form-select form-select-sm" value={cmb_prod_wastage_type} onChange={(e) => { calculateMachineWastage(''); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}>
                            <option value="">Select</option>
                            {prodWastageOptions.length !== 0 ? (
                              <>
                                {prodWastageOptions?.map(machine => (
                                  <option value={machine.field_id}>{machine.field_name}</option>
                                ))}
                              </>
                            ) : null
                            }
                          </select>
                          <MDTypography variant="button" id="error_cmb_prod_wastage_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Wastage Quantity </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="text" id='txt_wastage_quantity' className="erp_input_field" value={txt_wastage_quantity} onChange={(e) => setWastageQuantity(validateNumberDateInput.current.decimalNumber(e.target.value, 4))} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'} />
                          <MDTypography variant="button" id="error_txt_wastage_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Godown </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_godownId" className="form-select form-select-sm" value={cmb_godownId} onChange={(e) => setgodownId(e.target.value)} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}>
                            <option value="">Select</option>
                            {godownNameOptions.length !== 0 ? (
                              <>
                                {godownNameOptions?.map(godownType => (
                                  <option value={godownType.field_id}>{godownType.godown_name}</option>
                                ))}
                              </>
                            ) : null

                            }
                          </select>
                          <MDTypography variant="button" id="error_cmb_godownId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Shift  </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_shift_wastage" className="form-select form-select-sm" value={cmb_shift_wastage} onChange={(e) => { setShiftWastage(e.target.value); validateFormFields('wastageFormId'); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}>
                            <option value="">Select</option>
                            {shiftOptions.length !== 0 ? (
                              <>
                                {shiftOptions?.map(shift => (
                                  <option value={shift.field_name}>{shift.field_name}</option>
                                ))}
                              </>
                            ) : null
                            }
                          </select>
                          <MDTypography variant="button" id="error_cmb_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>


                    </div>

                   
                    <div className='col-sm-7 erp_form_col_div'>
                      {renderMachineWastageTable}
                    </div>

                    <div className='row'>
                      <div className='col'>
                        <div className={`erp_frm_Btns  mt-2 mb-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                          <MDButton type="button" id="action_Button" className="erp-gb-button ms-2 disable-button" variant="button" onClick={() => FnUpdateWastageReason('', '', 'add')} fontWeight="regular">{labelForWastage}</MDButton>
                          <MDButton type='button' className="erp-gb-button ms-2 disable-button" variant="button" id='back_Button' onClick={(e) => clearFormFields('wastage')} fontWeight="regular" >Clear</MDButton>
                        </div >
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}

          <div className="card-footer m2-5 py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Transactions/TWarpingProduction/FrmWarpingProductionListing/reg' : '/Transactions/TWarpingProduction/FrmWarpingProductionListing';
                navigate(path);
              }} variant="button" fontWeight="regular" >Back</MDButton>
            <MDButton type="submit" id="approveBtn" onClick={(e) => addWarpingProduction(e)} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
              fontWeight="regular">{actionLabel}</MDButton>  &nbsp;

            <ReactToPrint  trigger={() => (
                <MDButton  className={`erp-gb-button ${keyForViewUpdate === 'view' ? 'display' : 'd-none'}`}  variant="button"  fontWeight="regular"  >
                  Print <FiPrinter className="erp-download-icon-btn" />
                </MDButton>
              )}
              content={() => stickerRef.current} 
              documentTitle= {`Bottom_setno_${cmb_set_no}`} 
            />
            <Sticker ref={stickerRef} warpingBottomDetailsData={warpingBottomDetailsData} yarn_count ={txt_yarn_count} supplier_name ={supplier_name}/>
          </div >

        </div >
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
        {show ?
          <Modal show={show} onHide={closeConsumptionModal} centered size="lg">
            <Modal.Header closeButton style={{ fontSize: '11px' }}>
              <Modal.Title style={{ fontSize: '20px', color: '#045cb4' }}>Material Consumption</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '15px' }}>
              <dl className='row'>
                <dt className="col-sm-2 erp-form-label-md">Material & Unit: </dt>
                <dd className="col-sm-4 erp-form-label-md">{materialName} - {unitName}</dd>
                <dt className="col-sm-2 erp-form-label-md">Consumption Qty: </dt>
                <dd className="col-sm-4 erp-form-label-md">{consumptionQty}</dd>
              </dl>

              {materialStockList.length !== 0 ? (
                <>
                  <Table striped bordered hover>
                    <thead className='erp_table_head'>
                      <tr>
                        <th className="erp_table_th">Location</th>
                        <th className="erp_table_th">Available Qty</th>
                        <th className="erp_table_th">Stock Consumption Qty</th>
                        {/* <th className="erp_table_th">Consumption Wt.</th> */}
                        <th className="erp_table_th" style={{ width: "100px" }}>Goods Receipt No</th>
                        <th className="erp_table_th">Customer Name</th>
                        <th className="erp_table_th">Supplier Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {materialStockList.map((item, index) => (
                        <tr key={index}>

                          <td className='erp_table_td erp-form-label-md'>
                            {item.godown_section_name ? item.godown_section_beans_name ?
                              `${item.godown_name + '[' + item.godown_section_name + ']' + '[' + item.godown_section_beans_name + ']'}`
                              : `${item.godown_name + '[' + item.godown_section_name + ']'}` : item.godown_name}
                          </td>
                          <td className='erp_table_td'>{item.closing_balance_quantity}</td>
                          {/* <td className='erp_table_td'>{item.closing_balance_weight}</td> */}
                          <td className='erp_table_td'>
                            <input
                              type="text"
                              value={item?.consumptionQtyInfo?.consumption_quantity}
                              className="erp_input_field mb-0"
                              disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}
                              Headers="stock_consumption_quantity"
                              id={`stock_consumption_quantity_${index}`}
                            // onChange={(event) => FnMaterialWiseStockQtyConsumption(item, event, index)}
                            />
                          </td>
                          <td className="erp_table_td" id={`goods_receipt_no_${index}`}>{item.goods_receipt_no}</td>
                          <td className="erp_table_td" id={`customer_name_${index}`}>{item.customer_name} </td>
                          <td className="erp_table_td" id={`supplier_name_${index}`}>{item.supplier_name} </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <MDTypography variant="button" id="stock_consumption_error" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </>
              ) : (
                <div>Not available stock!...</div>
              )}
              {/* <div className='text-center mt-1'>
                <Button className={`erp-gb-button ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} onClick={FnCloseStockConsumptionModal} >
                  Ok
                </Button>
              </div> */}
            </Modal.Body>

          </Modal>
          : null}
      </DashboardLayout >

      <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
    </>
  )
}

export default FrmWarpingProductionEntry
