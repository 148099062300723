import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Modal, Table } from "react-bootstrap";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmValidations from "FrmGeneric/FrmValidations";
import ExcelExport from "Features/Exports/ExcelExport";

import FrmMaterialDetail from "Transactions/TMaterialRequisition/FrmMaterialRequisitionEntry";
import FrmGRNMaterialDetail from "Transactions/TPurchaseOrder/GoodsReceiptNote/FrmGoodsReceiptNoteEntry";
import FrmMaterialTransferDetail from "Transactions/TPurchaseOrder/MaterialTransferRequisition/FrmMaterialTransferRequisitionEntry";
import FrmStockAdjustment from "Masters/MStockAdjustment/MStockAdjustmentEntry";



// Import React icons
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import { CircularProgress } from "@material-ui/core";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";

function FrmTMaterialBeanCard({ materialID = '', btn_disabled = false }) {

    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const { state } = useLocation();
    const {idList, stockAdjustmentId = 0, keyForViewUpdate, compType } = state || {};
    const [showRecModal, setShowRecModal] = useState(false);

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const [employeeOptions, setEmployeeOptions] = useState([])
    const [godownOptions, setGodownOptions] = useState([]);

    ////Hooks for Master
    const [material_id, setMaterial_id] = useState(materialID !== '' ? materialID : '')
    const [txt_material_name, setmaterial_name] = useState('')
    const [txt_product_type, setproductType] = useState('')
    const [txt_current_stock, setcurrent_stock] = useState('')
    const [txt_product_category1, setProductcategory1Id] = useState('');
    const [txt_product_category2, setPCategory2Id] = useState('');
    const [dt_stock_form_date, setStockfromDate] = useState(today);
    const [dt_stock_to_date, setStocktoDate] = useState(today);
    const [cmb_godown_id, setGodownId] = useState('2');
    const [isLoading, setIsLoading] = useState(false);

    ////Hooks for Details 
    const [stockAdjustmentTableDetails, setStockAdjustmentTableDetails] = useState([]);

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // const handleCloseSuccessModal = () => setShowSuccessMsgModal(false);
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    //useRef Hooks
    const comboBoxRef = useRef();
    const validate = useRef();
    const validateNumberDateInput = useRef();
    const exlsExp = useRef();
    const navigate = useNavigate();

    useEffect(async () => {
        await fillCombo();

        if (materialID !== '') {
            await showMaterialBeanCard(materialID);
            await showStockDetails();
        }
    }, [materialID])

    const showMaterialBeanCard = async (materialId) => {
        resetGlobalQuery()
        globalQuery.columns = [
            "rm_fg_sr.product_material_name",
            "rm_fg_sr.product_material_code",
            "rm_fg_sr.product_material_id",
            "rm_fg_sr.product_type_name",
            "rm_fg_sr.product_material_category1_name",
            "rm_fg_sr.product_material_category2_name",
            `IFNULL((SELECT SUM(st.closing_balance_quantity) FROM sm_product_rm_stock_summary st WHERE st.product_rm_id = rm_fg_sr.product_material_id and st.is_delete = 0 and company_id = ${COMPANY_ID} ), 0) AS closing_balance_quantity`,
        ];
        globalQuery.table = "smv_product_rm_fg_sr rm_fg_sr";
        globalQuery.conditions.push({ field: "rm_fg_sr.product_material_id", operator: "=", value: materialId });
        let getMaterialDetails = await comboBoxRef.current.fillFiltersCombo(globalQuery);
        console.log("Material Details : ", getMaterialDetails);

        let material_name = getMaterialDetails[0].product_material_name;
        let material_code = getMaterialDetails[0].product_material_code;
        let material_id = getMaterialDetails[0].product_material_id;
        let product_type_name = getMaterialDetails[0].product_type_name;
        let category1 = getMaterialDetails[0].product_material_category1_name;
        let category2 = getMaterialDetails[0].product_material_category2_name;
        let currectstockQty = getMaterialDetails[0].closing_balance_quantity;

        console.log("searched Material:  ", material_name, ' : ', material_code, ' : ', material_id);
        setmaterial_name(material_name);
        setproductType(product_type_name);
        setProductcategory1Id(category1);
        setPCategory2Id(category2);
        setcurrent_stock(currectstockQty);
    }

    const fillCombo = async () => {
        // godownsList list for details table
        const getGodownsList = await comboBoxRef.current.fillMasterData("cmv_godown", "", "");
        setGodownOptions(getGodownsList)
    }





    ////Validation starts here
    const validateFields = async () => {
        await validate.current.validateFieldsOnChange('stockAdjustmentFormId');
    }

    const [modalState, setModalState] = useState({
        idList: {
          financial_year: FINANCIAL_SHORT_NAME,
          issue_version: 1,
          issue_no: "", 
          COMPANY_ID: COMPANY_ID,
          requestfor: "",
          keyForViewUpdate: "view",
          transaction_id: "",
          
        },
       
      });

    const renderMaterialWiseStockDetils = useMemo(() => {
        return <Table id="stockadjustmenttableId" className="erp-table-scroll" style={{ display: "block", overflow: "auto", height: stockAdjustmentTableDetails.length > 6 ? '300px' : 'auto' }} bordered striped >
            <thead className="erp_table_head">
                <tr>
                    <td className="erp_table_th " style={{ width: "70px" }}>Sr. No</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Date</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Transaction Type</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Opening Qty</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Purchase Qty</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Issue Qty</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Adjust Type</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Adjust Qty</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Unit Inward/Outward Qty</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Closing Qty</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Godown Section</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>Godown Section Beans</td>
                    <td className="erp_table_th " style={{ width: "180px" }}>created_on</td>
                </tr>
            </thead>
            <tbody>
                {stockAdjustmentTableDetails?.length > 0 ? (
                                        stockAdjustmentTableDetails.map((stockDetail, index) => (
                        <tr rowindex={index}>
                            <td className="erp_table_td text-end" > {index + 1} </td>
                            <td className="erp_table_td "> {validateNumberDateInput.current.formatDateToDDMMYYYY(stockDetail.date)} </td>
                            <td className="erp_table_td">
                            {(stockDetail.transaction_type !== "Stock Details" && stockDetail.stock_adjustment_type !== "Inward") ? (
                                <a   className="transaction-link"  onClick={(e) => { 
                                    e.preventDefault();

                                    const requestforType = stockDetail.transaction_type.includes("GR") ? "GR"
                                                           : stockDetail.transaction_type.includes("MI") ? "MI"
                                                           : stockDetail.transaction_type.includes("MT")  ? "MT"
                                                           : stockDetail.transaction_type.includes("Stock Adjustment")  ? "Stock Adjustment"
                                                           : "MI"; // Default fallback


                                    setModalState((prevState) => ({
                                        ...prevState,
                                        idList: {
                                          ...prevState.idList,
                                          issue_no: stockDetail.transaction_type, 
                                          requestfor: requestforType,
                                          transaction_id : stockDetail.transaction_id,
                                        },
                                      }));

                                    setShowRecModal(true)  }}
                                    
                                                   onMouseEnter={(e) => {  e.target.style.color = "blue";  e.target.style.textDecoration = "underline";  }}
                                                  onMouseLeave={(e) => {  e.target.style.color = "black";   e.target.style.textDecoration = "none";  }}
                                                  style={{ cursor: "pointer",  color: "black",   textDecoration: "none"   }}
                              
                             >  {stockDetail.transaction_type}
                                 </a>
                              ) : (
                                 stockDetail.transaction_type  )}
                 </td>

                            <td className="erp_table_td "> {stockDetail.opening_quantity} </td>
                            <td className="erp_table_td text-end"> {stockDetail.purchase_quantity} </td>
                            <td className="erp_table_td text-end"> {stockDetail.issue_quantity} </td>
                            <td className="erp_table_td text-end"> {stockDetail.stock_adjustment_type} </td>
                            <td className="erp_table_td text-end"> {stockDetail.stock_adjustment_quantity} </td>
                            <td className="erp_table_td text-end"> {stockDetail.transfer_quantity} </td>
                            <td className="erp_table_td text-end"> {stockDetail.closing_balance_quantity} </td>
                            <td className="erp_table_td "> {stockDetail.godown_section_name} </td>
                            <td className="erp_table_td "> {stockDetail.godown_section_beans_name} </td>
                            <td className="erp_table_td text-end"> {stockDetail.created_on} </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="erp_table_th text-center" colSpan={12}>No Data Found</td>
                    </tr>
                )}
            </tbody>
        </Table>

    }, [stockAdjustmentTableDetails]);


    const handleCloseBomFilterForm = async () => {
        const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
        console.log('searched details of material', getData);
        debugger
        if (getData.length !== 0) {
            try {
                
                setIsLoading(true)
                let material_name = getData[0].product_name;
                let material_code = getData[0].product_code;
                let material_id = getData[0].product_id;
                let product_type_name = getData[0].product_type_name;
                let category1 = getData[0].product_material_category1_name;
                let category2 = getData[0].product_material_category2_name;
                let currectstockQty = getData[0].stock_quantity;

                console.log("searched Material:  ", material_name, ' : ', material_code, ' : ', material_id);
                setmaterial_name(material_name);
                setproductType(product_type_name);
                setProductcategory1Id(category1);
                setPCategory2Id(category2);
                setcurrent_stock(currectstockQty);
                setMaterial_id(material_id)

                sessionStorage.setItem('isComboFilterExist', false);
                var LocalArr = JSON.parse(localStorage.getItem('localStorageKeyArr'));
                console.log('LocalArr: ', LocalArr);
                if (LocalArr != null) {
                    for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                        localStorage.removeItem(LocalArr[localArrIndex]);
                    }
                }
            } catch (error) {
                console.error("Error in Search Material: ", error);
                sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
                setIsLoading(false)
            } finally {
                setIsLoading(false)
                sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
            }
        } else {
            setMaterial_id('')
            setmaterial_name('')
            setproductType('');
            setProductcategory1Id('');
            setPCategory2Id('');
            setStockAdjustmentTableDetails([]);
            sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
        }
    };



    const showStockDetails = async () => {
        debugger
        setIsLoading(true)
        try{
            let isvalidmaterial = true;
            if (material_id === undefined || material_id === "") {
                isvalidmaterial = false;
                setErrMsg("Please select Material...!")
                setShowErrorMsgModal(true)
            } else if (cmb_godown_id === undefined || cmb_godown_id === "") {
                isvalidmaterial = false;
                $('#error_cmb_godown_id').text('Please select atleast one...!');
                $('#error_cmb_godown_id').focus();
                $('#error_cmb_godown_id').show();
            } else if (isvalidmaterial && validatedateDateFields(dt_stock_form_date, dt_stock_to_date)) {
                let commonIds = {};
                // Common Ids
                commonIds.material_id = material_id
                commonIds.from_date = dt_stock_form_date
                commonIds.to_date = dt_stock_to_date
                console.log(commonIds);
                const formData = new FormData();
                formData.append(`commonIds`, JSON.stringify(commonIds))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
    
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MaterialBeanCard/getMaterialTransactions/${COMPANY_ID}`, requestOptions)
                const response = await apiCall.json();
                console.log("stock Details Bean : ", response);
                if (response.success === 1) {
                    setStockAdjustmentTableDetails(response.materialTransactionData);
                }
                
            }
            setIsLoading(true)
        }
        catch(error){
            console.log(`error`,error)
        }
        finally{
            setIsLoading(false)
        }
      
    }

    $(document).on('click', '.erp_input_field_table_txt', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".erp_input_field_table_txt");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });


    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_type_name', 'product_material_category1_name', 'product_material_category2_name', 'stock_quantity', 'stock_weight', 'godown_id', 'godown_section_id', 'godown_section_beans_id', 'product_code'];


    const validatedateDateFields = (fromDateValue, toDateValue) => {
        const fromDate = new Date(fromDateValue);
        const toDate = new Date(toDateValue);

        let isValid = true;

        if (!fromDateValue) {
            $('#error_dt_stock_form_date').text('From Date is required.');
            $('#error_dt_stock_form_date').show();
            isValid = false;
        } else {
            $('#error_dt_stock_form_date').hide();
        }

        if (!toDateValue) {
            $('#error_dt_stock_to_date').text('To Date is required.');
            $('#error_dt_stock_to_date').show();
            isValid = false;
        } else {
            $('#error_dt_stock_to_date').hide();
        }

        if (fromDateValue && toDateValue && fromDate > toDate) {
            $('#error_dt_stock_form_date').text('From Date cannot be greater than To Date.');
            $('#error_dt_stock_form_date').show();
            isValid = false;
        } else if (fromDateValue && toDateValue) {
            $('#error_dt_stock_form_date').hide();
        }
        return isValid;
    };

    // --------------------------------------- PO EXcelToExport Start ------------------------------------------------------------------
    let requiredColsForExport = ['sr_no',
        "date",
        "transaction_type",
        "opening_quantity",
        "purchase_quantity",
        "issue_quantity",
        "stock_adjustment_quantity",
        "closing_balance_quantity",
        "godown_section_name",
        "godown_section_beans_name",
        "created_on",
    ];
    const ExportToExcel = () => {
        if (stockAdjustmentTableDetails.length !== 0) {
            var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }   // [5, 6, 8] 
            jsonToExportExcel.filtrKeyValue['0'] = "Material Name" + ' : ' + txt_material_name;
            jsonToExportExcel.filtrKeyValue['1'] = "Product Type" + ' : ' + txt_product_type;
            jsonToExportExcel.filtrKeyValue['2'] = "Product Category 1 " + ' : ' + txt_product_category1;
            jsonToExportExcel.filtrKeyValue['3'] = "Product Category 2 " + ' : ' + txt_product_category2;
            jsonToExportExcel.filtrKeyValue['4'] = "Godown Name " + ' : ' + $('#cmb_godown_id').find(":selected").text() + "(" + cmb_godown_id + ")";
            jsonToExportExcel.filtrKeyValue['5'] = "Current Stock" + ' : ' + txt_current_stock;

            for (let col = 0; col < requiredColsForExport.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": requiredColsForExport[col], "accessor": requiredColsForExport[col] })
            }
            stockAdjustmentTableDetails.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    sr_no: Index + 1,
                };
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            });
            jsonToExportExcel['headings']['ReportName'] = "Item Bean Card"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_material_name + "@" + COMPANY_ID)
        } else {
            setErrMsg('No Data Found...!')
            setShowErrorMsgModal(true)
        }
    }
    // --------------------------------------- PO EXcelToExport End ---------------------------------------------
    const FnClearForm = () => {

        setMaterial_id('')
        setmaterial_name('')
        setproductType('')
        setProductcategory1Id('')
        setPCategory2Id('');
        setGodownId('2')
        setStockfromDate(today)
        setStocktoDate(today)
        setcurrent_stock('')
        setStockAdjustmentTableDetails([]);
    }

    return (
        <>
            <ComboBox ref={comboBoxRef} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ExcelExport ref={exlsExp} />

            <div className={`erp_top_Form ${btn_disabled ? 'p-0' : ''}`}>
                <div className='card p-1'>

                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span id="spinner_text" className="text-dark">Loading...</span>
                            </div>
                        </div> :
                        null}

                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>{`Material Bean Card`} </label>
                    </div>

                    <form id="stockAdjustmentFormId">

                        <div className='row'>
                            {/* first */}
                            <div className='col-sm-6 erp_form_col_div'>
                                <div className={`row mt-3 ${btn_disabled ? 'd-none' : 'display'}`}>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Material Search</Form.Label>
                                    </div>
                                    <div className="col">
                                        <SearchItemFilter getSearchData={handleCloseBomFilterForm} formIdForValidate="" requiredCols={requiredCols} />
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Material Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_material_name" className="erp_input_field" value={txt_material_name} onChange={(e) => { setmaterial_name(e.target.value); validateFields(); }} disabled />
                                        <MDTypography variant="button" id="error_txt_material_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Product Type : </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_product_type" className="erp_input_field" value={txt_product_type} onChange={(e) => { setproductType(e.target.value); validateFields(); }} disabled />
                                        <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Godown : </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select className="form-select form-select-sm mb-0" id={`cmb_godown_id`} value={cmb_godown_id}
                                            onChange={(e) => { setGodownId(e.target.value) }} >
                                            <option value="">Select</option>
                                            {godownOptions?.map(godown => (
                                                <option value={godown.field_id}>{godown.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_godown_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* second */}
                            <div className='col-sm-6 erp_form_col_div'>
                                <div className="row mt-2">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">From Date :</Form.Label>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Control type="date" id='dt_stock_form_date' className="erp_input_field" value={dt_stock_form_date} onChange={(e) => {
                                            setStockfromDate(e.target.value);
                                            validatedateDateFields(e.target.value, dt_stock_to_date);
                                        }} />
                                        <MDTypography variant="button" id="error_dt_stock_form_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }} ></MDTypography>
                                    </div>
                                    <div className="col-sm-2">
                                        <Form.Label className="erp-form-label">To Date :</Form.Label>
                                    </div>
                                    <div className="col-sm-3">
                                        <Form.Control type="date" id='dt_stock_to_date' className="erp_input_field" value={dt_stock_to_date} onChange={(e) => {
                                            setStocktoDate(e.target.value);
                                            validatedateDateFields(dt_stock_form_date, e.target.value);
                                        }} />
                                        <MDTypography variant="button" id="error_dt_stock_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }} ></MDTypography>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Product Category 1 : </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_product_category1" className="erp_input_field" value={txt_product_category1} onChange={(e) => { setProductcategory1Id(e.target.value); validateFields(); }} disabled />
                                        <MDTypography variant="button" id="error_txt_product_category1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Product Category 2 : </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_product_category2" className="erp_input_field" value={txt_product_category2} onChange={(e) => { setPCategory2Id(e.target.value); validateFields(); }} disabled />
                                        <MDTypography variant="button" id="error_txt_product_category2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Currect Stock : </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_current_stock" className="erp_input_field" value={txt_current_stock} onChange={(e) => { setcurrent_stock(e.target.value); validateFields(); }} disabled />
                                        <MDTypography variant="button" id="error_txt_current_stock" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className={`erp_frm_Btns `}>
                        <MDButton type="button" id="show_button" className="erp-gb-button ms-2" variant="button"
                            onClick={() => showStockDetails()} fontWeight="regular">Show</MDButton>
                        <MDButton type="button" id="clear-btn-id" className={`erp-gb-button erp_MLeft_btn ${btn_disabled ? 'd-none' : 'display'}`}
                            variant="button" onClick={FnClearForm} fontWeight="regular">Clear</MDButton>

                    </div >
                </div>
                <div className='row mt-1'>
                    <div className="col-sm-6">
                        <label className='erp-form-label-lg text-center'>Material Details : </label>
                    </div>
                    <div className="col-sm-6 text-end">
                        <MDButton type="button" id="export-btn" className={`erp-gb-button  erp_MLeft_btn `} variant="button" fontWeight="regular" onClick={ExportToExcel}>Export</MDButton>
                    </div>
                </div>

                <div className='row mt-1'>
                    {renderMaterialWiseStockDetils}
                </div>
                <div className={`erp_frm_Btns ${btn_disabled ? 'd-none' : 'display'}`} >
                    <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => navigate('/DashBoard')}>Back</MDButton>
                </div >
            </div>
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

                   <Modal size="lg" show={showRecModal}  onHide={() => setShowRecModal(false)} backdrop="static" keyboard={false} centered >

                        <Modal.Header>
                          <Modal.Title className='erp_modal_title'></Modal.Title>
                          <span><button type="button" className="erp-modal-close btn-close" aria-label="Close"  onClick={() => setShowRecModal(false)}></button></span>
                        </Modal.Header>

                        <Modal.Body className='erp_city_modal_body p-0'>
                         {modalState.idList.requestfor === "MI" ? (
                        <FrmMaterialDetail btn_disabled={true} modalstate={modalState} />
                           ) : modalState.idList.requestfor === "GR" ? (
                       <FrmGRNMaterialDetail btn_disabled={true} modalstate={modalState} />
                           ) : modalState.idList.requestfor === "MT" ? (
                        <FrmMaterialTransferDetail btn_disabled={true} modalstate={modalState} />
                           ) : modalState.idList.requestfor === "Stock Adjustment" ? (
                        <FrmStockAdjustment btn_disabled={true} modalstate={modalState} />
                           ) : null}
                        </Modal.Body>

                      <Modal.Footer>
                          <MDButton type="button"  onClick={() => setShowRecModal(false)} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>
                      </Modal.Footer>
                   </Modal >

        </>
    )
}

export default FrmTMaterialBeanCard
