import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $, { event } from 'jquery';

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table, InputGroup } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { MdDelete, MdRefresh, MdAdd } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Datatable from "components/DataTable";
import { Link } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import TaxSummary from "../FrmGoodsReceiptNoteEntry/TaxSummary";
import ExcelExport from "Features/Exports/ExcelExport";
import { FiPrinter } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import Document from "Features/Document";
import GoodReceiptNote from "FrmGeneric/Invoice/GRNInvoice";

// Import for the searchable combo box.
import Select from 'react-select';

function FrmGoodsReceiptNoteCottonBalesEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName, IS_EXCESS_ALLOWED, COMPANY_NAME, GRN_EXCESS_PERCENT } = configConstants;
    var expanded = false;
    const { state } = useLocation()
    let { idList, keyForViewUpdate, compType, requestfor } = state || {};

    //for current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    // const currentDate = today();
    const currentDate = new Date().toISOString().split('T')[0];
    // UseRefs
    const validate = useRef();
    const comboDataFunc = useRef();
    const exlsExp = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    let getPBproperties = useRef();
    const importFile = useRef(null)
    const navigator = useNavigate();
    let grnCurrencyComboRef = useRef(null)

    const [isApprove, setIsApprove] = useState(false);
    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    let documentGroup = "GoodReceiptNote";
    let goodsReceiptNo = "";
    let goodsReceiptVersion = "";
    let financialYear = "";

    //Header hooks
    const [goods_receipt_master_transaction_id, setGoodsReceiptMasterTransactionId] = useState(0)
    const [txt_grn_no, setGRNno] = useState('0');
    const [txt_grn_version, setGRNVersion] = useState(1);
    // const [checkedPurchaseOrderNoLength, setCheckedPurchaseOrderNoLength] = useState(0)

    const [dt_goods_receipt_date, setGoodReceiptDate] = useState(today());
    const [cmb_supplier_id, setSupplierId] = useState('');
    const [cmb_supplier_state_id, setSuppliersStateId] = useState('');
    const [cmb_supplier_city_id, setSuppliersCityId] = useState('');
    const [txt_supplier_challan_no, setSupplierChallanNo] = useState('');
    const [dt_supplier_challan_Date, setdtSupplierChallanDate] = useState(today);
    const [cmb_expected_branch_id, setExpectedBranchId] = useState(COMPANY_BRANCH_ID);
    const [cmb_expected_branch_state_id, setExpectedBranchStateId] = useState('');
    const [cmb_expected_branch_city_id, setExpectedBranchCityId] = useState('');
    const [cmb_goods_receipt_type_id, setGoodsReceiptTypeId] = useState('');
    const [cmb_product_category_id, setProductCategoryId] = useState("");
    const [rb_purchase_order_life, setPurchaseOrderLife] = useState('C')
    const [cmb_approved_by_id, setApprovedById] = useState("");
    const [dt_approved_date, setApprovedDate] = useState("");
    const [cmb_qa_by_id, setQabyId] = useState("");
    const [dt_qa_date, setQadate] = useState(today);
    const [cmb_currency_id, setCurrencyId] = useState(3);
    const [txt_currency_symbol, SetCurrency_symbol] = useState('₹');
    const [txt_currency_label, setCurrencyLabel] = useState("Rupees (INR)");

    //footer hooks
    const [txt_basic_total, setBasicTotal] = useState(0);
    const [txt_transport_amount, setTransportAmount] = useState(0);
    const [txt_freight_amount, setFreightAmount] = useState(0);
    const [cmb_freight_hsn_id, setFreightHSNId] = useState('')
    const [chk_freight_isTaxable, setFreightIsTaxable] = useState(false);
    const [txt_packing_amount, setPackingAmount] = useState(0);
    const [txt_goods_receipt_discount_percent, setGoodsReceiptDiscountPercent] = useState(0);
    const [txt_goods_receipt_discount_amount, setGoodsReceiptDiscountAmount] = useState(0);
    const [txt_other_amount, setOtherAmount] = useState(0);
    const [txt_taxable_total, setTaxableTotal] = useState(0);
    const [txt_cgst_total, setCgstTotal] = useState(0);
    const [txt_sgst_total, setSgstTotal] = useState(0);
    const [txt_igst_total, setIgstTotal] = useState(0);
    const [txt_diff, setDiff] = useState(0);
    const [txt_supplier_invoice_amount, setSupplierInvoiceAmount] = useState(0);
    const [txt_grand_total, setGrandTotal] = useState(0);
    const [txt_roundOff, setRoundOff] = useState(0);
    const [txt_agent_percent, setAgentPercent] = useState(0);
    const [txt_agent_amount, setAgentAmount] = useState(0);
    const [cmb_agent_id, setAgentId] = useState()
    const [cmb_agent_paid_status, setAgentPaidStatus] = useState('');
    const [cmb_goods_receipt_status, setGoodsReceiptStatus] = useState('');
    const [txt_lr_no, setlrNo] = useState('');
    const [dt_lr_date, setlrDate] = useState('');
    const [txt_invoice_no, setInvoice_no] = useState('');
    const [dt_invoice_date, setInvoice_date] = useState('');
    const [txt_gate_entry_no, setGateEntryNo] = useState('');
    const [dt_gate_entry_date, setGateEntryDate] = useState('');
    const [txt_vehicle_no, setVehicleNo] = useState('');
    const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [is_active, setIsActive] = useState(true);
    const [is_pree_closed, setIsPreeClosed] = useState(false);
    const [is_sez, setIsSez] = useState(false)
    const [dt_ev_bill_date, setEv_bill_date] = useState('');
    const [txt_ev_bill_no, setEvBillNo] = useState('');
    //   const [cmb_sales_job_type, setSalesOrderJob] = useState('');
    const [cmb_purchase_order_no, setPurchaseOrderNo] = useState('');
    const [txt_station, setStation] = useState('');
    const [serachedVal, setNewValSdearched] = useState('');
    const [cmb_purchase_order_date, setPurchaseOrderDate] = useState('');
    const [cmb_purchase_order_version, setPurchaseOrderVersion] = useState('');

    const [openGRNDetailsAccord, setOpenGRNDetailsAccord] = useState(true);
    //combo options
    // const [rejectionParameterList, setRejectionParameterList] = useState([])
    const [poInfoList, setPOInfoList] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [expectedBranchList, setExpectedBranchList] = useState([])
    const [expectedBranchCityList, setExpectedBranchCityList] = useState([])
    const [goodReceiptTypeList, setGoodReceiptTypeList] = useState([]);
    const [productCategoryOptions, setProductCategoryOptions] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [freightHSNList, setfreightHSNList] = useState([]);
    // const [productUnitOptions, setProductUnitOptions] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [conversionFactorOptions, setConversionFactorOptions] = useState([]);
    const [godownsList, setGodownsList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [stationList, setStationList] = useState([]);


    const [txt_packing_percent, setPackingPercent] = useState(0);
    const [txt_Freight_percent, setFreightPercent] = useState(0);
    // podetails Table Data
    const [grnDetailsData, setGrnDetailsData] = useState([])
    const [storedGrnDetailsData, setStoredGrnDetailsData] = useState([])
    const [grnPODetailsList, setGrnPODetailsList] = useState([])
    const [searchOptions, setOptions] = useState([]);
    const [qualityTestingDetails, setqualityTestingDetails] = useState([]);
    // const [fiteredGRNPOList, setFiteredGRNPOList] = useState([]);

    // Taxation Table Data Hooks
    const [grnTaxSummaryData, setGrnTaxSummaryData] = useState([])

    //payment terms accordian
    const [paymentTermsData, setPaymentTermsData] = useState([]);
    // const [existingPaymentTermsData, setExistingPaymentTermsData] = useState([])

    // supp contact details table data hooks
    const [SuppContactDetails, setSuppContactDetails] = useState([]);
    const [SuppContactDetailsCols, setSuppContactDetailsCols] = useState([]);

    // Indent Details table Data hooks
    const [indentMaterialDetailsData, setIndentMaterialDetailsData] = useState([])
    // const [indentSummaryDetails, setIndentSummaryDetails] = useState([])
    // const [showSChedules, setSchedules] = useState(false);
    // const handleCloseSchedulesPopup = () => setSchedules(false)


    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    // delete popup msg
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [lastGeneratedBatchNo, setLastGeneratedBatchNo] = useState();


    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = async () => {
        await showDocumentRecords();
        setShowDocumentForm(false);
    }

    // doc list
    const [docData, setDocumentData] = useState([]);

    // Succ Msg Modal
    // const handleCloseSuccessModal = () => {
    //     setShowSuccessMsgModal(false);
    //     navigator(`/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteCottonBalesListing`)
    // }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState("");
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Customer City':
                comboOnChange('CustomerState');
                break;
            case 'Consignee City':
                comboOnChange('ConsigneeState');
                break;
            case 'Good Receipt Type':
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "product_type_short_name"]
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                const product_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                setGoodReceiptTypeList(product_Types)
                break;
            default:
                break;

        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }

    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)
    const [message, setMessage] = useState('')
    const [messageForConfirmation, setMessageForConfirmation] = useState('')
    const closeConfirmationModal = (key) => {
        // debugger
        switch (key) {
            case 'Export&Exit':
                ExportToExcel();
                setShowConfiemationModal(false);

                navigator(`/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteCottonBalesListing`)
                break;
            case 'Exit':
                setShowSuccessMsgModal(false);
                navigator(`/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteCottonBalesListing`)
                break;
            default:
                break;
        }

    }

    //Print Preview states

    const [masterDataForPrintPreview, setMasterDataForPrintPreview] = useState([]);

    const [showPrintPreviewIsOpen, setShowPreviewModalIsOpen] = useState(false)
    const [printPreviewContent, setPrintPreviewContent] = useState(null)
    const handleClosePrintPreview = () => { setShowPreviewModalIsOpen(false) }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'approve':
                setActionType('(Approval)');
                setActionLabel('Approve')
                setIsApprove(true)
                await validate.current.readOnly("GRNHeaderFormId");
                await validate.current.readOnly("GRNFooterFormId");
                $('#cmb_goods_receipt_status').attr('disabled', false)
                $('#dt_approved_date').attr('disabled', false)
                $('#dt_approved_date').removeAttr('readOnly')
                $('#dt_qa_date').attr('disabled', false)
                $('#dt_qa_date').removeAttr('readOnly')
                $('#txt_remark').removeAttr('readOnly')
                break;
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')

                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("GRNHeaderFormId");
                await validate.current.readOnly("GRNFooterFormId");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('input[type="checkbox"]').prop('disabled', true);
                break;
            case 'delete':
                setActionType('(Delete)');
                await validate.current.readOnly("GRNHeaderFormId");
                await validate.current.readOnly("GRNFooterFormId");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('input[type="checkbox"]').prop('disabled', true);
                break;
            default:
                setActionType('(Creation)');
                break;
        }

    };

    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {
        switch (eventKey) {

            case 'paymentTerms':
                // if (grnTaxSummaryData.length === 0) {
                //   FnShowPaymentTermsRecrds();
                // }
                break;
            case 'documentList':
                await showDocumentRecords();
                break;
            default:
                break;
        }
        if (keyForViewUpdate === "view" || keyForViewUpdate === "approve" || keyForViewUpdate === "delete") {
            $('input[type="checkbox"]').prop('disabled', true);
        }
    }

    useEffect(async () => {
        try {
            setIsLoading(true);
            await ActionType();

            await fillComobos();

            if (idList === '' || idList === undefined) {
                await generateGrnNo();
            } else {
                await FnCheckUpdateResponse()
            }
            await ActionType();
            // Close customer order no dropdown
            // $(document).on('mouseup', function (e) {
            //     var container = $("#purchase-order-ul");
            //     if (!container.is(e.target) && container.has(e.target).length === 0) {
            //         container.hide();
            //     }
            // });
        } catch (error) {
            console.error(error);
            navigator('/Error')
        } finally {
            setIsLoading(false);
        }
    }, [])


    const generateGrnNo = async () => {
        // const ptShortName = goodReceiptTypeList.find((item) => item.field_id === parseInt($("#cmb_goods_receipt_type_id").val()));
        const grn_no = await generateAutoNoAPiCall.current.generateTAutoNo("pt_grn_cotton_bales_details", "goods_receipt_no", "", 'GR', "5");
        setGRNno(grn_no);
        return grn_no;
    }

    const fillComobos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "city_name", "state_name", "supp_branch_city_id", "supp_branch_state_id", "supp_branch_gst_no"]
            globalQuery.table = "cmv_supplier_branch";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.conditions.push({ field: "supp_branch_type", operator: "=", value: "Supplier" });
            const getSuppliers = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
            //  ** Changes for the Searchable Combo
            const suppliers = [
                { value: '', label: 'Select', supp_branch_state_id: '', supp_branch_city_id: '', state_name: '', city_name: '', },
                { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '' },
                ...getSuppliers.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, })),
            ];
            setSupplierList(suppliers);

            const getStates = await comboDataFunc.current.fillMasterData("cmv_state", "", "")
            setStateList(getStates)


            resetGlobalQuery();
            globalQuery.columns.push("product_category2_id", "product_category2_name");
            globalQuery.table = "sm_product_category2";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: parseInt(12) });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const getProductCategoryTypeAPICall = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            if (getProductCategoryTypeAPICall.length > 0) {
                setProductCategoryOptions(getProductCategoryTypeAPICall);
                setProductCategoryId(2);
            }

            // Load the Job Type properties.
            // resetGlobalQuery();
            // globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
            // globalQuery.table = "amv_properties"
            // // globalQuery.conditions.push({ field: "property_master_id", operator: "=", value: 180 });
            // globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'Sales Type' });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // comboDataFunc.current.removeCatcheFillCombo(globalQuery).then(getJobTypeAPICall => {
            //     setJobTypeArr(getJobTypeAPICall);
            // })

            resetGlobalQuery();
            globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "branch_city_id", "state_name", "city_name", 'is_sez']
            globalQuery.table = "cmv_company_branch";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            const getExpectedtBranchs = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setExpectedBranchList(getExpectedtBranchs);
            let expectedBranch = getExpectedtBranchs.find(item => item.company_branch_id === parseInt(COMPANY_BRANCH_ID))
            setExpectedBranchStateId(expectedBranch.branch_state_id)
            await comboOnChange('ExpectedBranchState')
            setExpectedBranchCityId(expectedBranch.branch_city_id)
            setIsSez(expectedBranch.is_sez)


            //get product units  
            // resetGlobalQuery();//get hsn code for freight amount
            // globalQuery.columns.push("*");
            // globalQuery.table = "smv_product_unit"
            // // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // comboDataFunc.current.fillFiltersCombo(globalQuery)
            //     .then(getProductUnitList => {
            //         setProductUnitOptions(getProductUnitList);
            //         console.log("Unit List: ", getProductUnitList);
            //     });


            resetGlobalQuery();
            globalQuery.columns.push("*");
            globalQuery.table = "sm_product_unit_conversion";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const getConversionFactorAPICallList = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            setConversionFactorOptions(getConversionFactorAPICallList);

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "product_type_short_name"]
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            const getProductType = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setGoodReceiptTypeList(getProductType);
            if (requestfor !== "NA" && keyForViewUpdate === '') {
                if (getProductType.length !== 0) {
                    const productType = getProductType.find((item) => item.product_type_short_name === 'PRM');
                    if (productType) {
                        setGoodsReceiptTypeId(productType.field_id);
                    }
                }
            }

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"]
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            const getEmployeesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            const getEmployees = [
                { value: '', label: 'Select', },
                ...getEmployeesApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
            ];
            setEmployeeList(getEmployees);

            // // const getRejectionParameters = await comboDataFunc.current.fillMasterData("smv_product_rejection_parameters", "", "")
            // // setRejectionParameterList(getRejectionParameters)
            // resetGlobalQuery();
            // globalQuery.columns = ["field_id", "field_name"]
            // globalQuery.table = "smv_product_rejection_parameters";
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            // const getRejectionParameters = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            // setRejectionParameterList(getRejectionParameters);

            //get hsn code for freight amount
            resetGlobalQuery();
            globalQuery.columns = ["hsn_sac_id", "hsn_sac_code", "hsn_sac_rate"];
            globalQuery.table = "cmv_hsn_sac"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getFreightHSNCodesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setfreightHSNList(getFreightHSNCodesApiCall);

            // for agentsList List
            const getAgentsList = await comboDataFunc.current.fillMasterData("cmv_agent", "", "");
            setAgentsList(getAgentsList);

            // godownsList list for details table            
            resetGlobalQuery();
            globalQuery.columns = ["*"];
            globalQuery.table = "cm_godown"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: 12 });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getGodownsList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setGodownsList(getGodownsList)

            // unit list for details table
            const getUnitList = await comboDataFunc.current.fillMasterData("smv_product_unit", "", "");
            setUnitList(getUnitList)

            // resetGlobalQuery();
            // globalQuery.columns = ["field_id", "field_name", "quantity_per_packing"];
            // globalQuery.table = "smv_product_packing";
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getPackingList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            // setPackingList(getPackingList);



            // get Currency list with default INR
            resetGlobalQuery();
            globalQuery.columns = ["currency_id, currency_name, currency_code, currency_symbol "];
            globalQuery.table = "fm_currency";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getCurrencyList => {
                    const currency = [
                        ...getCurrencyList.map((currency) => ({ ...currency, value: currency.currency_id, label: `${currency.currency_name} (${currency.currency_code})` })),
                    ];
                    setCurrencyOptions(currency);
                    console.log("Currency List: ", getCurrencyList);
                });

            if (keyForViewUpdate !== "view") {
                // await fetchConversionRates();
                await fetchLastBatchNo();
            }

            resetGlobalQuery();
            globalQuery.columns = ["station"];
            globalQuery.table = "pt_grn_cotton_bales_master";
            const getStationsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);

            const uniqueStations = getStationsApiCall.filter((value, index, self) =>
                index === self.findIndex((t) => t.station === value.station)
            );

            const getStations = [
                { value: '', label: 'Select' },
                ...uniqueStations.map(station => ({
                    value: station.station,
                    label: station.station
                })),
            ];

            setStationList(getStations);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const fetchLastBatchNo = async () => {
        // debugger
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGrnCottonBalesMaster/FnGetLastLotNo/${COMPANY_ID}`)
            const response = await apiCall.text();
            setLastGeneratedBatchNo(response && typeof response === 'string' && response.trim() !== '' ? ((parseInt(response) + 1)) : "1");
        } catch {

        }
    }

    const FnCheckUpdateResponse = async () => {
        debugger
        try {
            if (idList !== "") {
                goodsReceiptNo = idList.goods_receipt_no;
                setGRNno(goodsReceiptNo)
                goodsReceiptVersion = idList.goods_receipt_version;
                financialYear = idList.financial_year;
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGrnCottonBalesMaster/FnShowAllDetailsAndMastermodelRecords/${goodsReceiptVersion}/${financialYear}/${COMPANY_ID}?goods_receipt_no=${goodsReceiptNo}`)
                const response = await apiCall.json();
                let grnMasterData = response.GRNCottonBalesMasterRecord;
                // setting master data for print preview
                setMasterDataForPrintPreview(response.GRNCottonBalesMasterRecord)
                let grnDetailsRecords = response.GRNCottonBalesDetailsRecords;
                console.log("GRN Data :" + grnDetailsData);
                let grnTaxSummaryRecords = response.GRNCottonBalesTaxSummaryRecords;
                let paymentTermRecords = response.GRNCottonBalesPaymentTermsRecords;
                // let grnIndentDetailsRecords = response.GoodsReceiptsNotesIndentDetailsRecords;

                let { goods_receipt_master_transaction_id, goods_receipt_date, supplier_id, supplier_state_id,
                    supplier_city_id, supplier_contacts_ids, expected_branch_id, batch_no,
                    expected_branch_state_id, expected_branch_city_id,
                    goods_receipt_type_id, purchase_order_life, supplier_challan_no, station,
                    supplier_challan_date, approved_by_id, approved_date, qa_by_id, qa_by_name, qa_date,
                    expected_schedule_date, basic_total, transport_amount, freight_amount,
                    is_freight_taxable, freight_hsn_code_id, packing_amount, goods_receipt_discount_percent,
                    goods_receipt_discount_amount, other_amount, taxable_total, cgst_total,
                    sgst_total, igst_total, grand_total, roundoff, agent_id, agent_percent,
                    agent_paid_status, goods_receipt_status, lr_no, lr_date, supplier_invoice_no,
                    supplier_invoice_date, vehicle_no, other_terms_conditions, remark, is_active,
                    is_sez, is_preeclosed, ev_bill_no, ev_bill_date, purchase_order_no, gate_entry_no,
                    gate_entry_date, purchase_order_date, purchase_order_version, product_category2_id
                } = grnMasterData;


                setGoodsReceiptMasterTransactionId(goods_receipt_master_transaction_id)
                setGoodsReceiptTypeId(goods_receipt_type_id)
                setLastGeneratedBatchNo(batch_no);
                setGoodReceiptDate(goods_receipt_date)
                setProductCategoryId(product_category2_id);
                setSupplierId(supplier_id)
                supplierComboRef.current.value = supplier_id;
                setExpectedBranchId(expected_branch_id)
                setExpectedBranchStateId(expected_branch_state_id)
                await comboOnChange('ExpectedBranchState')
                setExpectedBranchCityId(expected_branch_city_id)
                setIsSez(is_sez)
                await comboOnChange('Supplier')
                setSuppliersStateId(supplier_state_id)
                await comboOnChange('SupplierState')
                setSuppliersCityId(supplier_city_id)
                const poNoList = [
                    { value: '', label: 'Select', },
                    { value: purchase_order_no, label: purchase_order_no }
                ];
                setPOInfoList(poNoList)
                setPurchaseOrderNo(purchase_order_no)
                setGateEntryNo(gate_entry_no)
                setStation(station)
                purchaseOrderNoComboRef.current.value = purchase_order_no;
                setPurchaseOrderDate(purchase_order_date)
                setPurchaseOrderVersion(purchase_order_version)
                if (supplier_contacts_ids !== '') {
                    const supplierContactIds = supplier_contacts_ids.split(':');
                    supplierContactIds.forEach(function (contactId, index) {
                        $('#selectSuppContactPerson_' + supplierContactIds[index]).prop('checked', true);
                    });
                    FnSupplierContactSelection('PartiallySuppContactSelection')
                }

                setCurrencyId(grnDetailsRecords[0].grn_currency_id);
                setCurrencyLabel(grnDetailsRecords[0].grn_currency_name);
                SetCurrency_symbol(grnDetailsRecords[0].grn_currency_symbol);
                setSupplierChallanNo(supplier_challan_no)
                setdtSupplierChallanDate(supplier_challan_date)
                setPurchaseOrderLife(purchase_order_life)
                setQabyId(qa_by_id)
                qaByComboRef.current.value = qa_by_id;
                setQadate(qa_date)

                //For Product Based properties !!
                getPBproperties.current = true;
                if (keyForViewUpdate === 'update') {
                    // Assuming grnDetailsRecords is an array of objects
                    const updatedGrnDetailsRecords = grnDetailsRecords.map(item => {
                        // Update the keys you want to modify
                        return {
                            ...item,
                            product_rm_purchase_unit_id: item.product_purchase_unit_id,
                            prev_grn_bales_quantity: item.prev_grn_bales_quantity - item.product_material_grn_accepted_quantity, // Replace with the actual updated value for key1
                            prev_grn_bales_weight: item.prev_grn_bales_weight - item.product_material_grn_accepted_weight, // Replace with the actual updated value for key2
                            // Add more key-value pairs to update as needed
                        };
                    });

                    // Set the state with the updated array
                    setGrnDetailsData(updatedGrnDetailsRecords)
                    setStoredGrnDetailsData(updatedGrnDetailsRecords)
                    // // Process other asynchronous operations
                    // await Promise.all(updatedGrnDetailsRecords.map(async (grnItem) => {
                    //   await renderGodownSectionList(grnItem.godown_id, grnItem.purchase_order_details_transaction_id);
                    //   await renderGodownSectionBeansList(grnItem.godown_section_id, grnItem.purchase_order_details_transaction_id);
                    // }));

                } else if (keyForViewUpdate === 'approve') {
                    const updatedGrnDetailsRecords = grnDetailsRecords.map(item => {
                        // Update the keys you want to modify
                        return {
                            ...item,
                            product_rm_purchase_unit_id: item.product_purchase_unit_id,
                            product_material_grn_accepted_quantity: item.product_material_grn_quantity, // Replace with the actual updated value for key1
                            product_material_grn_accepted_weight: item.product_material_grn_weight, // Replace with the actual updated value for key2
                            // Add more key-value pairs to update as needed
                        };
                    });
                    setGrnDetailsData(updatedGrnDetailsRecords)
                    setStoredGrnDetailsData(updatedGrnDetailsRecords)
                } else {
                    setGrnDetailsData(grnDetailsRecords)
                    setStoredGrnDetailsData(grnDetailsRecords)
                    // // Process other asynchronous operations
                    // await Promise.all(grnDetailsRecords.map(async (grnItem) => {
                    //   await renderGodownSectionList(grnItem.godown_id, grnItem.purchase_order_details_transaction_id);
                    //   await renderGodownSectionBeansList(grnItem.godown_section_id, grnItem.purchase_order_details_transaction_id);
                    // }));

                }
                setGrnTaxSummaryData(grnTaxSummaryRecords)
                setPaymentTermsData(paymentTermRecords)
                // setIndentMaterialDetailsData(grnIndentDetailsRecords !== null && grnIndentDetailsRecords.length > 0 ? grnIndentDetailsRecords : [])

                setBasicTotal(basic_total)
                setTransportAmount(transport_amount)
                setFreightAmount(freight_amount)
                setFreightIsTaxable(is_freight_taxable)
                setFreightHSNId(freight_hsn_code_id)
                setPackingAmount(packing_amount)
                setGoodsReceiptDiscountAmount(goods_receipt_discount_amount)
                setGoodsReceiptDiscountPercent(goods_receipt_discount_percent)
                setOtherAmount(other_amount)
                setTaxableTotal(taxable_total)
                setCgstTotal(cgst_total)
                setSgstTotal(sgst_total)
                setIgstTotal(igst_total)
                setGrandTotal(grand_total)
                setRoundOff(roundoff)
                setAgentId(agent_id)
                setAgentPercent(agent_percent)
                setAgentPaidStatus(agent_paid_status)
                setGoodsReceiptStatus(goods_receipt_status)
                setRemark(remark)
                setlrNo(lr_no)
                setlrDate(lr_date)
                setInvoice_no(supplier_invoice_no)
                setInvoice_date(supplier_invoice_date)
                setGateEntryNo(gate_entry_no)
                setGateEntryDate(gate_entry_date)
                setVehicleNo(vehicle_no)
                setOtherTermsConditions(other_terms_conditions)
                setIsActive(is_active)
                setIsPreeClosed(is_preeclosed)
                setEvBillNo(ev_bill_no)
                setEv_bill_date(ev_bill_date);



                // if (keyForViewUpdate === 'view' || isApprove) {
                //   setApprovedById(approved_by_id);
                //   setApprovedDate(approved_date);
                // }
                if (keyForViewUpdate === 'approve') {
                    setApprovedById(UserId);
                    setApprovedDate(today);
                } else {
                    setApprovedById(approved_by_id);
                    setApprovedDate(approved_date);
                }

            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }

    }
    function FnSupplierContactSelection(key) {
        console.log('FnCheckSuppContacts');
        switch (key) {
            case 'selectAllSuppContact':
                $('.selectSuppContactPerson').prop('checked', $('#selectAllSuppContact').is(":checked"));
                break;

            case 'PartiallySuppContactSelection':
                $('#selectAllSuppContact').prop('checked', $('input:checkbox.selectSuppContactPerson:checked').length === $('input:checkbox.selectSuppContactPerson').length);
                break;
            default:
                break;
        }
    }


    const comboOnChange = async (key) => {

        switch (key) {
            case 'Supplier':
                ;
                let supplierValue = supplierComboRef.current.value || '';
                // let supplierValue = document.getElementById("cmb_supplier_id").value;
                // setSupplierId(supplierValue)
                if (supplierValue === "0") {
                    localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: 0, keyForViewUpdate: '', compType: 'Master' }))
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Supplier')
                    const newTab = window.open('/Masters/FrmSupplier', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (supplierValue !== "0" && !isNaN(supplierValue)) {
                    setIsLoading(true);
                    let selectedState = supplierComboRef.current?.supp_branch_state_id;
                    let selectedCity = supplierComboRef.current?.supp_branch_city_id;
                    setSuppliersStateId(selectedState);
                    // await comboOnChange('SupplierState')
                    const getCities = await comboDataFunc.current.fillMasterData("cmv_city", "state_id", "" + selectedState + "");
                    setCityList(getCities)
                    setSuppliersCityId(selectedCity);
                    await FnGetsuppContactPersonsDetails(supplierValue);
                    if (keyForViewUpdate === '') {
                        await loadPurchaseOrderNos();
                    }
                    $("#error_cmb_supplier_id").hide();
                    setIsLoading(false);
                } else {
                    setSuppliersStateId('')
                    setSuppliersCityId('')
                    setSuppContactDetails([])
                    setSuppContactDetailsCols([])
                    setPurchaseOrderNo('')
                    setPurchaseOrderDate('')
                    setPurchaseOrderVersion('')
                    purchaseOrderNoComboRef.current = null
                    setPOInfoList([])
                    setGrnDetailsData([])
                    setGrnPODetailsList([])
                    setGrnTaxSummaryData([])
                    setPaymentTermsData([])
                    setBasicTotal(0)
                    setTransportAmount(0)
                    setGoodsReceiptDiscountAmount(0)
                    setGoodsReceiptDiscountPercent(0)
                    setTaxableTotal(0)
                    setCgstTotal(0)
                    setSgstTotal(0)
                    setIgstTotal(0)
                    setGrandTotal(0)
                    setRoundOff(0)
                    setIsLoading(false);
                    // setExistingPaymentTermsData([])
                }

                break;

            case 'SupplierState':
                let supplierStateValue = parseInt(document.getElementById('cmb_supplier_state_id').value);
                const getCities = await comboDataFunc.current.fillMasterData("cmv_city", "state_id", supplierStateValue)
                setCityList(getCities)
                break;

            case 'ExpectedbranchId':
                let expectedBranchValue = parseInt(document.getElementById('cmb_expected_branch_id').value);
                setExpectedBranchId(expectedBranchValue)
                if (expectedBranchValue !== 0 && !isNaN(expectedBranchValue)) {
                    const getBranchCityAndState = expectedBranchList.find(item => item.company_branch_id === expectedBranchValue)
                    setExpectedBranchStateId(getBranchCityAndState.branch_state_id)
                    comboOnChange('ExpectedBranchState')
                    setExpectedBranchCityId(getBranchCityAndState.branch_city_id)
                    setIsSez(getBranchCityAndState.is_sez)

                    // If branch Changed then remove the details, tax summary & footer data of previous branch
                    setGrnDetailsData([])
                    setGrnPODetailsList([])
                    setGrnTaxSummaryData([])
                    setPaymentTermsData([])
                    // setExistingPaymentTermsData([])
                    setBasicTotal(0);
                    setCgstTotal(0);
                    setSgstTotal(0);
                    setIgstTotal(0)
                    setTaxableTotal(0)
                    setGrandTotal(0);
                    setRoundOff(0);
                    await loadPurchaseOrderNos()
                } else {
                    setExpectedBranchCityId('')
                    setExpectedBranchStateId('')
                }

                if (expectedBranchValue === 0) {
                    const newTab = window.open('Masters/Company', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;
            case 'ExpectedBranchState':
                let expectedBranchStateValue = parseInt(document.getElementById('cmb_expected_branch_state_id').value);
                const getExpecBranchCities = await comboDataFunc.current.fillMasterData("cmv_city", "state_id", expectedBranchStateValue)
                setExpectedBranchCityList(getExpecBranchCities)
                break;
            case 'agent':
                if (document.getElementById('cmb_agent_id').value === "0") {
                    const newTab = window.open('/Masters/Agent', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                    setAgentId('')
                }
                break;
            case 'GoodReceiptStatus':
                let goodReceiptStatus = document.getElementById('cmb_goods_receipt_status').value
                if (goodReceiptStatus !== '') {
                    $('#error_cmb_goods_receipt_status').hide()
                }
                break;
            case 'challan_no':
                let challan_no = document.getElementById('txt_supplier_challan_no').value
                setSupplierChallanNo(challan_no);
                if ((cmb_supplier_id !== '' || cmb_supplier_id !== '0') && challan_no === '') {
                    $('#error_txt_supplier_challan_no').text("Please fill this field...!")
                    $('#error_txt_supplier_challan_no').show()
                } else {
                    $('#error_txt_supplier_challan_no').hide()
                }
                break;
            case 'challan_date':
                let challan_date = document.getElementById('dt_supplier_challan_Date').value
                setdtSupplierChallanDate(challan_date);
                if ((cmb_supplier_id !== '' || cmb_supplier_id !== '0') && challan_date === '') {
                    $('#error_txt_supplier_challan_no').text("Please fill this field...!")
                    $('#error_txt_supplier_challan_no').show()
                } else {
                    $('#error_txt_supplier_challan_no').hide();
                }
                break;
            default:
                break;
        }
    }
    const loadPurchaseOrderNos = async () => {
        resetGlobalQuery();
        globalQuery.columns = ["DISTINCT purchase_order_no", "purchase_order_master_transaction_id", "purchase_order_version", "purchase_order_date", "supplier_contacts_ids"]
        globalQuery.table = "ptv_purchase_order_master_summary"
        globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: supplierComboRef.current.value });
        globalQuery.conditions.push({ field: "expected_branch_id", operator: "=", value: document.getElementById('cmb_expected_branch_id').value });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
        globalQuery.conditions.push({ field: "purchase_order_status", operator: "=", value: 'A' });
        globalQuery.conditions.push({ field: "purchase_order_date", operator: "<=", value: dt_goods_receipt_date });
        globalQuery.conditions.push({ field: "grn_status", operator: "IN", values: ['P', 'I'] });
        globalQuery.conditions.push({ field: "product_category2_id", operator: "=", value: 2 });
        globalQuery.conditions.push({ field: "purchase_order_type_id", operator: "=", value: cmb_goods_receipt_type_id });
        globalQuery.orderBy = ["purchase_order_master_transaction_id desc"];
        const getPurchaseOrderNos = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        const poNoList = [
            { value: '', label: 'Select', },
            ...getPurchaseOrderNos.map((poNo) => ({ ...poNo, value: poNo.purchase_order_no, label: poNo.purchase_order_no })),
        ];
        setPOInfoList(poNoList)
        setPurchaseOrderNo('')
        setPurchaseOrderDate('')
        setPurchaseOrderVersion('')
        purchaseOrderNoComboRef.current.value = ''
        setGrnDetailsData([])
        setGrnPODetailsList([])
        setGrnTaxSummaryData([])
        setIndentMaterialDetailsData([])
        setPaymentTermsData([])
        setBasicTotal(0)
        setFreightAmount(0)
        setGoodsReceiptDiscountAmount(0)
        setGoodsReceiptDiscountPercent(0)
        setTaxableTotal(0)
        setCgstTotal(0)
        setSgstTotal(0)
        setIgstTotal(0)
        setGrandTotal(0)
        setRoundOff(0)
    }

    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Customer City':
                return <FrmCity btn_disabled={true} />;
            case 'Consignee City':
                return <FrmCity btn_disabled={true} />;
            case 'Good Receipt Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;
            default:
                return null;
        }
    }



    const handleFooterPackingPercentChange = async (value) => {
        let taxableTotal = parseFloat(document.getElementById("txt_taxable_total").value);

        let percent = parseFloat(value) || 0;
        if (percent < 0) {
            percent = 0;
        }
        const amount = (percent / 100) * taxableTotal;
        setPackingAmount(validateNumberDateInput.current.decimalNumber(amount, 4));
        setPackingPercent(percent);
        setTimeout(() => {
            calculateGrandTotal(grnDetailsData, grnTaxSummaryData);
        }, 200);
    };

    const handleFooterPackingAmountChange = async (value) => {
        let taxableTotal = parseFloat(document.getElementById("txt_taxable_total").value);

        let amount = parseFloat(value) || 0;
        if (amount < 0) {
            amount = 0;
        }
        // const amount = parseFloat(e.target.value) || 0;
        const percent = (amount * 100) / taxableTotal;
        setPackingPercent(validateNumberDateInput.current.decimalNumber(percent, 2));
        setPackingAmount(amount);

        setTimeout(() => {
            calculateGrandTotal(grnDetailsData, grnTaxSummaryData);
        }, 200);

    };


    const handleFooterFreightPercentChange = async (value) => {
        let percent = parseFloat(value) || 0;
        let taxableTotal = parseFloat(document.getElementById("txt_taxable_total").value);
        // Set amount to zero if it's negative or less than zero
        if (percent < 0) {
            percent = 0;
        }
        const amount = (percent / 100) * taxableTotal;
        setFreightAmount(validateNumberDateInput.current.decimalNumber(amount, 4));
        setFreightPercent(percent);
        setTimeout(() => {
            calculateGrandTotal(grnDetailsData, grnTaxSummaryData);
            reverseCalculationForDiscount(grnDetailsData);
        }, 200);
    };

    const handleFooterFreightAmountChange = async (value) => {
        let taxableTotal = parseFloat(document.getElementById("txt_taxable_total").value);
        let amount = parseFloat(value) || 0;
        if (amount < 0) {
            amount = 0;
        }
        const percent = (amount * 100) / taxableTotal;
        setFreightPercent(validateNumberDateInput.current.decimalNumber(percent, 2));
        setFreightAmount(amount);

        setTimeout(() => {
            //  calculateGrandTotal(grnDetailsData, grnTaxSummaryData);
            reverseCalculationForDiscount(grnDetailsData);
            calculateTaxationSummary(grnDetailsData);
        }, 200);

    };
    const FnFooterPercentChange = (e) => {

        // const percent = e.target.value || 0;
        let percent = parseFloat(e.target.value) || 0;
        // Set amount to zero if it's negative or less than zero
        if (percent < 0) {
            percent = 0;
        }
        const amount = parseFloat((percent / 100)) * txt_basic_total;
        setGoodsReceiptDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(amount), 4));
        setGoodsReceiptDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));

        setTimeout(() => {
            calculateGrandTotal(grnDetailsData, grnTaxSummaryData);
            reverseCalculationForDiscount(grnDetailsData);

        }, 200);

    };

    const FnFooterAmountChange = (taxSummaryData) => {

        const amount = parseFloat(document.getElementById('txt_goods_receipt_discount_amount').value || 0);
        const percent = (amount * 100) / document.getElementById('txt_basic_total').value;
        setGoodsReceiptDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(amount), 4));
        setGoodsReceiptDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));
        setTimeout(() => {
            calculateGrandTotal(grnDetailsData, taxSummaryData);
        }, 200);

    };


    const handleFooterDiscountAmountChange = async (e) => {

        let amount = parseFloat(e.target.value) || 0;
        if (amount < 0) {
            amount = 0;
        }

        const percent = (amount * 100) / document.getElementById('txt_basic_total').value;
        setGoodsReceiptDiscountPercent(validateNumberDateInput.current.decimalNumber(percent, 2));
        setGoodsReceiptDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(amount), 4));

        setTimeout(() => {
            calculateGrandTotal(grnDetailsData, grnTaxSummaryData);
            reverseCalculationForDiscount(grnDetailsData);
        }, 200);

    };

    const handleFooterAgentAmountChange = async (e) => {

    };

    const FnGetsuppContactPersonsDetails = async (supplierBranchval) => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ["supplier_id", "supplier_branch_name", "supp_branch_id", "supplier_contact_id", "supp_contact_person", "supp_contact_no", "supp_alternate_contact", "supp_email_id", "supp_alternate_EmailId"]

            globalQuery.table = "cmv_supplier_contacts"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "supp_branch_id", operator: "=", value: parseInt(supplierBranchval) });
            const getSupplierContacts = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

            if (getSupplierContacts.length !== 0) {
                let column = [];
                let columnHeads = Object.keys(getSupplierContacts[0]);
                for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                    if (colKey === 0) {
                        column.push({
                            Headers: <div className="col-1"><input type='checkbox' className="me-1" name="selectAllSuppContact" id="selectAllSuppContact" onClick={(e) => { FnSupplierContactSelection('selectAllSuppContact'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} />Select</div>,
                            accessor: "Action",
                            Cell: row => (
                                <div className="text-center">
                                    <input type='checkbox' className="selectSuppContactPerson" name="selectSuppContactPerson" id={`selectSuppContactPerson_${row.original.supplier_contact_id}`} onClick={() => FnSupplierContactSelection('PartiallySuppContactSelection')} value={row.original.supplier_contact_id} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} />
                                </div>
                            ),
                        });
                    }
                    if (!columnHeads[colKey].includes('_id')) {
                        column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                    }
                }
                setSuppContactDetailsCols(column);
                setSuppContactDetails(getSupplierContacts);
            }

        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }


    const showPODetails = async (po_no, storedGrnDetailsData) => {
        try {
            // debugger
            setIsLoading(true)
            let json = { 'purchase_order_nos': [], 'Ids': {} }
            json.purchase_order_nos.push(po_no)
            json.Ids.company_id = COMPANY_ID
            json.Ids.expected_branch_id = document.getElementById(`cmb_expected_branch_id`).value

            if (json.purchase_order_nos.length !== 0) {
                setGrnDetailsData([])
                setGrnPODetailsList([])
                setGrnPODetailsList([])
                setGrnTaxSummaryData([])
                setIndentMaterialDetailsData([])
                setBasicTotal(0)
                setFreightAmount(0)
                setGoodsReceiptDiscountAmount(0)
                setGoodsReceiptDiscountPercent(0)
                setTaxableTotal(0)
                setCgstTotal(0)
                setSgstTotal(0)
                setIgstTotal(0)
                setGrandTotal(0)
                setRoundOff(0)
                const formData = new FormData();
                formData.append('purchaseOrderNos', JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnShowPurchaseOrderDetailsRecords`, requestOptions)
                const response = await apicall.json()
                if (response.data.length !== 0) {
                    displayGrnDetailsData(response, json.purchase_order_nos, storedGrnDetailsData)
                    setPaymentTermsData(response.purchasePaymentTerms)
                    FnCheckSuppContacts(json.purchase_order_nos)
                }

            } else {
                setGrnDetailsData([])
                setGrnPODetailsList([])
                setGrnTaxSummaryData([])
                setIndentMaterialDetailsData([])
                setPaymentTermsData([])
                setBasicTotal(0)
                setFreightAmount(0)
                setGoodsReceiptDiscountAmount(0)
                setGoodsReceiptDiscountPercent(0)
                setTaxableTotal(0)
                setCgstTotal(0)
                setSgstTotal(0)
                setIgstTotal(0)
                setGrandTotal(0)
                setRoundOff(0)
                setSuppContactDetails([])
            }
        } catch (error) {
            // setIsLoading(false)
            console.log(error);

        } finally {
            setIsLoading(false)
        }
    }
    //Fn for set checked supplier contacts from POs
    const FnCheckSuppContacts = (po_nos) => {

        console.log('FnCheckSuppContacts');

        let supplier_conts_id = "";
        poInfoList.forEach((item) => {
            const isMatchingId = po_nos.includes(item.purchase_order_no);
            supplier_conts_id += isMatchingId ? item.supplier_contacts_ids + ":" : "";
        });
        // Convert the concatenated string to an array, remove duplicates, and join back to a string
        const distinctSupplierContsIds = [...new Set(supplier_conts_id.split(':'))];
        $('input[name="selectSuppContactPerson"]').prop('checked', false);
        distinctSupplierContsIds.forEach((id) => {
            if (id !== "") {
                $('input[name="selectSuppContactPerson"][id="selectSuppContactPerson_' + id + '"]').prop('checked', true);
            }
        });
        FnSupplierContactSelection('PartiallySuppContactSelection');
    };

    let footer_disc_amt = 0;
    let totalBasicAmount = 0;
    const calculateValues = (item) => {


        let po_material_rate = item.material_rate;

        // fnCalculateAmount(item);
        const rate = 1;
        const convertedPrice = rate
            ? (validateNumberDateInput.current.decimalNumber(po_material_rate / rate, 4)) : 0;

        const product_material_grn_quantity = item.product_material_po_approved_quantity - item.prev_grn_bales_quantity;
        const product_material_grn_weight = validateNumberDateInput.current.decimalNumber(parseFloat(item.product_material_po_approved_weight) - parseFloat(item.prev_grn_bales_weight), 4);
        const material_basic_amount = product_material_grn_weight * convertedPrice;

        footer_disc_amt += item.material_discount_amount;
        totalBasicAmount += material_basic_amount;
        return {
            ...item,
            po_material_rate: po_material_rate,
            product_material_grn_quantity: product_material_grn_quantity,
            product_material_grn_weight: product_material_grn_weight,
            product_material_grn_rejected_quantity: product_material_grn_quantity - product_material_grn_quantity,
            product_material_grn_rejected_weight: product_material_grn_weight - product_material_grn_weight,
            product_material_grn_accepted_quantity: 0,
            product_material_grn_accepted_weight: 0,
            material_basic_amount,
            product_material_rejection_reason_id: '',
            invoice_gross_weight: 0,
            invoice_tare_weight: 0,
            invoice_net_weight: 0,
            mill_gross_weight: 0,
            mill_tare_weight: 0,
            mill_net_weight: 0,
            godown_id: '0',
            // batch_no: generatedLotNo,
            supplier_batch_no: '',
            material_schedule_date: today(),
            // purchase_order_type_id: item.purchase_order_type_id,
            // purchase_order_type: item.purchase_order_type,
            goods_receipt_type_id: item.purchase_order_type_id,
            goods_receipt_type: item.purchase_order_type,
            // total_box_weight: product_material_grn_quantity, // Ensure item.product_material_grn_weight is correctly set
            // total_quantity_in_box: product_material_grn_quantity * item.quantity_per_packing,
            // weight_per_box_item: validateNumberDateInput.current.decimalNumber(product_material_grn_weight / (product_material_grn_quantity * item.quantity_per_packing), 4), // Adjusted calculation
            // no_of_boxes: 0,

            material_rate: convertedPrice,
            currency_exchange_rate: rate,
        };
    };

    const displayGrnDetailsData = (response) => {
        // debugger
        let updatedFinalGrnData = [];
        // Calculate all values first
        const calculatedGrnDetailsData = response.data.map(calculateValues);

        // Calculate total values
        const totalFooterDiscAmt = calculatedGrnDetailsData.reduce((acc, item) => acc + item.material_discount_amount, 0);
        const totalMaterialBasicAmount = calculatedGrnDetailsData.reduce((acc, item) => acc + item.material_basic_amount, 0);

        // Set the total values
        footer_disc_amt = totalFooterDiscAmt;
        totalBasicAmount = totalMaterialBasicAmount;

        // Set the derived values using setGoodsReceiptDiscountAmount and setGoodsReceiptDiscountPercent
        const percent = (footer_disc_amt * 100) / totalBasicAmount;
        setGoodsReceiptDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(footer_disc_amt), 4));
        setGoodsReceiptDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));

        // Now set the GrnDetailsData array
        setGrnPODetailsList((prevArray) => {
            if (goods_receipt_master_transaction_id === 0) {
                updatedFinalGrnData = calculatedGrnDetailsData.map(FnMaterialWiseCalculations);
            } else {
                updatedFinalGrnData = calculatedGrnDetailsData.map(FnMaterialWiseCalculations);
            }
            return updatedFinalGrnData;
        });

    };


    // This edffect call when Grn Details Data hook update
    useEffect(() => {
        const FunctionCall = () => {
            if (grnDetailsData.length !== 0) {
                calculateTaxationSummary(grnDetailsData);
            }
        }
        FunctionCall();
    }, [grnDetailsData]);


    const renderGRNDetailsTbl = useMemo(() => {
        // debugger
        return <>
            <Table className="erp_table erp_table_scroll" id='grnDetailsTbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>Action</th>
                        <th className='erp_table_th'>Material Code</th>
                        <th className='erp_table_th' style={{ width: '100px', paddingRight: '120px' }}>Material Name</th>

                        <th className='erp_table_th'>PO Qty.</th>
                        <th className='erp_table_th'>PO Wt.</th>
                        <th className='erp_table_th'>PO Material Rate</th>
                        <th className='erp_table_th'>PO Currency</th>

                        <th className='erp_table_th'>GRN Qty.</th>
                        <th className='erp_table_th'>GRN Wt.</th>
                        <th className={`erp_table_th  ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>GRN Accepted Qty</th>
                        <th className={`erp_table_th  ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>GRN Accepted Wt</th>
                        <th className='erp_table_th'>GRN Material Rate</th>
                        <th className='erp_table_th'>GRN Currency</th>
                        <th className='erp_table_th'>Currency Exchange Rate</th>

                        <th className='erp_table_th' style={{ paddingRight: "30px" }}>Purchase Unit</th>
                        <th className='erp_table_th'>Basic Amount</th>
                        <th className='erp_table_th'>Freight Amount</th>
                        <th className='erp_table_th'> Discount %</th>
                        <th className='erp_table_th'> Discount Amount</th>
                        <th className='erp_table_th'> Taxable Amount</th>
                        <th className='erp_table_th'> Cgst %</th>
                        <th className='erp_table_th'> Cgst Total</th>
                        <th className='erp_table_th'> Sgst %</th>
                        <th className='erp_table_th'> Sgst Total</th>
                        <th className='erp_table_th'> Igst %</th>
                        <th className='erp_table_th'> Igst Total</th>
                        <th className='erp_table_th'> Total Amount</th>

                        <th className='erp_table_th'>Material Godown</th>
                        <th className='erp_table_th' style={{ paddingRight: "30px" }}>Material Unit</th>

                        <th className='erp_table_th'>Invoice Gross Wt.</th>
                        <th className='erp_table_th'>Invoice Tare Wt.</th>
                        <th className='erp_table_th'>Invoice Net Wt.</th>
                        <th className='erp_table_th'>Mill Gross Wt.</th>
                        <th className='erp_table_th'>Mill Tare Wt.</th>
                        <th className='erp_table_th'>Mill Net Wt.</th>
                        <th className='erp_table_th'>Difference Wt.</th>
                        <th className='erp_table_th'>Material Lot No</th>
                        <th className='erp_table_th'>Supplier's Lot No</th>

                        <th className='erp_table_th'>Press Running From</th>
                        <th className='erp_table_th'>Press Running To</th>
                        <th className='erp_table_th'>Press Running Remark</th>

                        <th className='erp_table_th'>HSN-SAC Code</th>
                        <th className='erp_table_th'>Material Schedule Date</th>


                        {/* <th className='erp_table_th'>PO Number</th> */}
                        {/* <th className='erp_table_th'>Prev. GRN Qty</th> */}
                        {/* <th className='erp_table_th'>GRN Rejected Qty</th> */}
                        {/* <th className='erp_table_th'>Prev GRN Wt.</th> */}
                        {/* <th className='erp_table_th'>GRN Rejected Wt</th> */}
                        {/* <th className='erp_table_th'>Rejection Reason</th> */}
                        {/* <th className='erp_table_th'>No. Of Package</th>
                        <th className='erp_table_th'>Weight Per Package</th>
                        <th className="erp_table_th">No. Of Boxes</th> */}
                        {/* <th className='erp_table_th'>Material Expiry Date</th> */}
                        {/* <th className={`erp_table_th ${IS_EXCESS_ALLOWED === true ? 'display' : 'd-none'}`}>Excess Quantity</th>
                        <th className={`erp_table_th ${IS_EXCESS_ALLOWED === true ? 'display' : 'd-none'}`}>Excess Weight</th> */}
                        {/* <th className='erp_table_th'>Customer Name</th>
                        <th className='erp_table_th'>Customer Order No</th>
                        <th className='erp_table_th'>Customer Order Date</th> */}
                        {/* <th className='erp_table_th'>Technical Specification</th> */}
                        {/* <th className='erp_table_th'> Packing Name</th> */}
                        {/* <th className='erp_table_th'> Lead Time</th> */}
                        {/* <th className='erp_table_th' style={{ paddingRight: '80px' }}>Remark</th> */}
                    </tr>
                </thead>
                <tbody>
                    {grnDetailsData?.map((grnItem, index) =>
                        <tr rowindex={index}>
                            <td className={`erp_table_td sticky-column  ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                <div style={{ display: "flex" }}>
                                    <Tooltip title="Delete" placement="top">
                                        <MDTypography className={`erp-view-btn ${keyForViewUpdate === '' ? 'display' : 'd-none'}`}>
                                            <MdDelete className="erp-delete-btn" onClick={() => removeMaterial(index, setGrnDetailsData, grnItem)} />
                                        </MDTypography>
                                    </Tooltip>
                                </div>
                            </td>
                            <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{grnItem.product_material_code}</td>
                            <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{grnItem.product_material_name}</td>

                            {/* PO Related Fields */}
                            <td className="erp_table_td text-end" style={{ width: "75px" }}>{grnItem.product_material_po_approved_quantity}</td>
                            <td className="erp_table_td text-end" style={{ width: "75px" }}>{grnItem.product_material_po_approved_weight}</td>
                            <td className='erp_table_td text-end' style={{ width: "75px" }}> {grnItem.po_material_rate}  </td>
                            <td className='erp_table_td text-end' style={{ width: "100px" }}> {grnItem.currency_name + "(" + grnItem.currency_code + ")"}  </td>

                            {/* GRN Related Keys */}
                            <td className="erp_table_td text-end">
                                <input type="text"
                                    id={`product_material_grn_quantity_${index}`}
                                    className="erp_input_field mb-0 text-end"
                                    disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    style={{ width: '100px' }}
                                    value={grnItem.product_material_grn_quantity}
                                    onChange={(e) => {
                                        updateGrnDetailsTblRow(grnItem, e);
                                    }}
                                    onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='product_material_grn_quantity'
                                />
                            </td>
                            <td className="erp_table_td text-end">
                                <input type="text"
                                    id={`product_material_grn_weight_${index}`} style={{ width: '100px' }}
                                    className="erp_input_field mb-0 text-end"
                                    disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    value={grnItem.product_material_grn_weight}
                                    onChange={(e) => {
                                        updateGrnDetailsTblRow(grnItem, e);
                                    }}
                                    onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='product_material_grn_weight'
                                />
                            </td>
                            <td className={`erp_table_td text-end ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>
                                <input type="text"
                                    id={`product_material_grn_accepted_quantity_${index}`}
                                    className="erp_input_field mb-0 text-end"
                                    value={grnItem.product_material_grn_accepted_quantity}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='product_material_grn_accepted_quantity'
                                />
                            </td>
                            <td className={`erp_table_td text-end ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>
                                <input type="text"
                                    id={`product_material_grn_accepted_weight_${index}`}
                                    className="erp_input_field mb-0 text-end"
                                    value={grnItem.product_material_grn_accepted_weight}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='product_material_grn_accepted_weight'
                                />
                            </td>
                            <td className='erp_table_td text-end'>
                                <input type="text"
                                    id={`material_rate_${index}`}
                                    className="erp_input_field mb-0 text-end"
                                    disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    value={grnItem.material_rate}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='material_rate'
                                />
                            </td>
                            <td className='erp_table_td text-end'> {txt_currency_label}  </td>
                            <td className='erp_table_td text-end'>
                                {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.currency_exchange_rate :
                                    <input type="text" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                        id={`currency_exchange_rate_${index}`}
                                        className="erp_input_field mb-0 text-end"
                                        value={grnItem.currency_exchange_rate}
                                        onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        Headers='currency_exchange_rate'
                                    />
                                }
                            </td>
                            <td>
                                <select id={`product_rm_purchase_unit_id_${index}`} disabled={keyForViewUpdate === 'view' || isApprove ? true : false}
                                    className="form-select form-select-sm" value={grnItem.product_rm_purchase_unit_id} Headers='product_rm_purchase_unit_id'
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }} >
                                    {/* <option value=''>Select</option> */}
                                    {unitList.length !== 0 ?
                                        <>
                                            {unitList?.map(unitItem => (
                                                <option value={unitItem.field_id} >{unitItem.field_name}</option>
                                            ))}
                                        </>
                                        : ''
                                    }
                                </select>
                            </td>

                            <td className='erp_table_td text-end'>{grnItem.material_basic_amount}</td>
                            <td className='erp_table_td text-end'>{grnItem.material_freight_amount}</td>
                            <td className='erp_table_td text-end'>
                                {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.material_discount_percent :
                                    <input type="text"
                                        id={`material_discount_percent_${index}`}
                                        className="erp_input_field mb-0 text-end"
                                        value={grnItem.material_discount_percent}
                                        onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        Headers='material_discount_percent'
                                    />
                                }
                            </td>
                            <td className='erp_table_td text-end'>
                                {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.material_discount_amount :
                                    <input type="text"
                                        id={`material_discount_amount_${index}`}
                                        className="erp_input_field mb-0 text-end"
                                        value={grnItem.material_discount_amount}
                                        onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        Headers='material_discount_amount'
                                    />
                                }
                            </td>
                            <td className='erp_table_td text-end'> {grnItem.material_taxable_amount}  </td>
                            <td className='erp_table_td text-end'>{grnItem.material_cgst_percent}</td>
                            <td className='erp_table_td text-end'>{grnItem.material_cgst_total}</td>
                            <td className='erp_table_td text-end'> {grnItem.material_sgst_percent}</td>
                            <td className='erp_table_td text-end'> {grnItem.material_sgst_total}</td>
                            <td className='erp_table_td text-end'>{grnItem.material_igst_percent}</td>
                            <td className='erp_table_td text-end'> {grnItem.material_igst_total}</td>
                            <td className='erp_table_td text-end'> {grnItem.material_total_amount}</td>

                            <td className='erp_table_td'>
                                <select id={`godown_id_${index}`} disabled={keyForViewUpdate === 'view' || isApprove ? true : false}
                                    className="form-select form-select-sm" value={grnItem.godown_id} Headers='godown_id'
                                    style={{ width: 'auto' }}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }} >
                                    <option value='0'>Select</option>
                                    {godownsList.length !== 0 ?
                                        <>
                                            {godownsList?.map(godown => (
                                                <option value={godown.godown_id} >{godown.godown_name}</option>
                                            ))}
                                        </>
                                        : ''
                                    }
                                </select>
                            </td>
                            <td className="erp_table_td">
                                <select className="form-select form-select-sm mb-0" value={grnItem.product_material_unit_id}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    id={`product_material_unit_id_${grnItem.index}`}
                                    Headers='product_material_unit_id' disabled={keyForViewUpdate === 'view' || isApprove ? true : false}>
                                    {/* <option value='0'>Select</option> */}
                                    {unitList.map(item =>
                                        <option value={item.field_id}>{item.field_name}</option>
                                    )}
                                </select>
                            </td>

                            <td className={`erp_table_td`}>
                                <input type="text"
                                    id={`invoice_gross_weight_${index}`}
                                    disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    className="erp_input_field mb-0"
                                    value={grnItem.invoice_gross_weight}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='invoice_gross_weight'
                                />
                            </td>
                            <td className={`erp_table_td`}>
                                <input type="text"
                                    id={`invoice_tare_weight_${index}`}
                                    disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    className="erp_input_field mb-0"
                                    value={grnItem.invoice_tare_weight}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='invoice_tare_weight'
                                />
                            </td>
                            <td className={`erp_table_td`}>
                                <input type="text"
                                    id={`invoice_net_weight_${index}`}
                                    className="erp_input_field mb-0" disabled
                                    value={grnItem.invoice_net_weight}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='invoice_net_weight'
                                />
                            </td>
                            <td className={`erp_table_td`}>
                                <input type="text"
                                    id={`mill_gross_weight_${index}`}
                                    disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    className="erp_input_field mb-0"
                                    value={grnItem.mill_gross_weight}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='mill_gross_weight'
                                />
                            </td>
                            <td className={`erp_table_td`}>
                                <input type="text"
                                    id={`mill_tare_weight_${index}`} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    className="erp_input_field mb-0"
                                    value={grnItem.mill_tare_weight}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='mill_tare_weight'
                                />
                            </td>
                            <td className={`erp_table_td`}>
                                <input type="text" disabled
                                    id={`mill_net_weight_${index}`}
                                    className="erp_input_field mb-0"
                                    value={grnItem.mill_net_weight}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='mill_net_weight'
                                />
                            </td>
                            <td className={`erp_table_td`}>
                                <input type="text" disabled
                                    id={`difference_weight_${index}`}
                                    className="erp_input_field mb-0"
                                    value={grnItem.difference_weight}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='difference_weight'
                                />
                            </td>
                            <td className="erp_table_td">
                                {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.batch_no :
                                    <input
                                        type="text" disabled
                                        id={`batch_no_${index}`}
                                        className="erp_input_field mb-0"
                                        value={lastGeneratedBatchNo}
                                        onChange={(e) => {
                                            updateGrnDetailsTblRow(grnItem, e);
                                        }}
                                        placeholder="batch_no"
                                        Headers="batch_no"
                                    />
                                }
                            </td>
                            <td className="erp_table_td">
                                <input type="text" style={{ width: 'auto' }}
                                    id={`supplier_batch_no_${index}`}
                                    className="erp_input_field mb-0" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    value={grnItem.supplier_batch_no}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='supplier_batch_no'
                                />
                            </td>
                            <td className="erp_table_td">
                                <input type="text"
                                    id={`press_running_no_from_${index}`}
                                    className="erp_input_field mb-0" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    value={grnItem.press_running_no_from}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='press_running_no_from'
                                />
                            </td>
                            <td className="erp_table_td ">
                                <input type="text"
                                    id={`press_running_no_to_${index}`} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    className="erp_input_field mb-0"
                                    value={grnItem.press_running_no_to}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='press_running_no_to'
                                />
                            </td>
                            <td className="erp_table_td ">
                                <input type="text"
                                    id={`press_running_remark_${index}`} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    className="erp_input_field mb-0"
                                    value={grnItem.press_running_remark}
                                    onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                    Headers='press_running_remark'
                                />
                            </td>
                            <td className='erp_table_td'>{grnItem.hsn_sac_code}</td>
                            <td className="erp_table_td">
                                {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.material_schedule_date :
                                    <input type="date"
                                        id={`material_schedule_date_${index}`}
                                        className="erp_input_field mb-0"
                                        value={grnItem.material_schedule_date}
                                        onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        Headers='material_schedule_date'
                                    />
                                }
                            </td>

                            {/* <td className={`erp_table_td`}>
                                    {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.total_quantity_in_box :
                                        <input type="text"
                                            id={`total_quantity_in_box_${grnItem.purchase_order_details_transaction_id}`}
                                            className="erp_input_field mb-0"
                                            value={grnItem.total_quantity_in_box}
                                            onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                            Headers='total_quantity_in_box'
                                        />
                                    }
                                </td>
                                <td className={`erp_table_td`}>
                                    {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.weight_per_box_item :
                                        <input type="text"
                                            id={`weight_per_box_item_${grnItem.purchase_order_details_transaction_id}`}
                                            className="erp_input_field mb-0"
                                            value={grnItem.weight_per_box_item}
                                            onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                            Headers='weight_per_box_item'
                                        />
                                    }
                                </td>
                                <td className={`erp_table_td`}>
                                    {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.no_of_boxes :
                                        <input type="text"
                                            id={`no_of_boxes_${grnItem.purchase_order_details_transaction_id}`}
                                            className="erp_input_field mb-0"
                                            value={grnItem.no_of_boxes}
                                            onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                            Headers='no_of_boxes'
                                            product_category2_id={grnItem.product_category2_id}
                                        />
                                    }
                                </td> */}
                            {/* <td className="erp_table_td">
                                {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.expiry_date :
                                    <input type="date"
                                        id={`expiry_date_${grnItem.purchase_order_details_transaction_id}`}
                                        className="erp_input_field mb-0"
                                        value={grnItem.expiry_date}
                                        onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        Headers='expiry_date'
                                    />
                                } */}
                            {/* </td> */}
                            {/* ${IS_EXCESS_ALLOWED === true ? 'display' : 'd-none'} */}
                            {/* <td className={`erp_table_td d-none`}>
                                {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.excess_quantity :
                                    <input type="text"
                                        id={`excess_quantity_${index}`}
                                        className="erp_input_field mb-0"
                                        value={grnItem.excess_quantity}
                                        onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        Headers='excess_quantity'
                                    />
                                }
                            </td>
                            <td className={`erp_table_td d-none`}>
                                {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.excess_weight :
                                    <input type="text"
                                        id={`excess_weight_${index}`}
                                        className="erp_input_field mb-0"
                                        value={grnItem.excess_weight}
                                        onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        Headers='excess_weight'
                                    />
                                }
                            </td> */}
                            {/* <td className="erp_table_td">{grnItem.customer_name}</td>
                            <td className="erp_table_td">{grnItem.customer_order_no}</td>
                            <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.customer_order_Date)}</td> */}
                            {/* <td className='erp_table_td'>{grnItem.product_material_tech_spect}</td> */}

                            {/* <td className="erp_table_td">
                                    <select className="form-select form-select-sm mb-0" id={`product_material_packing_id_${grnItem.purchase_order_details_transaction_id}`} value={grnItem.product_material_packing_id}
                                        onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                        disabled={isApprove ? true : false}
                                        Headers='product_material_packing_id' >
                                        <option value="">Select</option>
                                        {packingList?.map(packingItem => (
                                            <option value={packingItem.field_id} quantity_per_packing={packingItem.quantity_per_packing} >{packingItem.field_name}</option>
                                        ))}
                                    </select>
                                </td> */}
                            {/* <td className='erp_table_td'> {grnItem.lead_time}</td> */}
                            {/* <td className="erp_table_td" style={{ width: '100px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{grnItem.remark}</td> */}
                        </tr>
                    )
                    }
                </tbody>
            </Table> </>
    }, [grnDetailsData, txt_currency_label, lastGeneratedBatchNo]);


    const updateGrnDetailsTblRow = async (rowData, event) => {
        // Destructuring the object
        debugger
        let {
            product_material_po_approved_quantity,
            prev_grn_bales_quantity,
            product_material_grn_quantity,
            product_material_grn_weight,
            product_material_std_weight,
            product_material_grn_accepted_quantity,
            material_rate,
            material_basic_amount,
            product_material_stock_unit_name
        } = rowData;

        const detailData = grnDetailsData;
        const taxSummaryData = [...grnTaxSummaryData]
        let clickedColName = event.target.getAttribute('Headers');
        let eventId = document.getElementById(event.target.id);
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        let enteredValue = event.target.value;
        if (isNaN(enteredValue)) {
            enteredValue = 0;
        } else if (enteredValue != 0 || enteredValue != "") {
            delete eventId.parentElement.dataset.tip;
        }

        switch (clickedColName) {
            case 'product_material_grn_quantity':
                let max_grn_quantity = parseFloat(product_material_po_approved_quantity) - parseFloat(prev_grn_bales_quantity);
                let min_grn_quantity = max_grn_quantity;
                if (IS_EXCESS_ALLOWED && GRN_EXCESS_PERCENT !== '') {
                    max_grn_quantity += max_grn_quantity * parseFloat(GRN_EXCESS_PERCENT) / 100;
                    min_grn_quantity -= min_grn_quantity * parseFloat(GRN_EXCESS_PERCENT) / 100;
                }

                if (parseFloat(enteredValue) > max_grn_quantity) {
                    let actualValidation = document.querySelector(`#${event.target.id}`);
                    actualValidation.parentElement.dataset.tip = `Cannot be greater than ${max_grn_quantity}...!`;
                    return false;
                } else {
                    rowData[clickedColName] = enteredValue;
                    rowData.product_material_grn_weight = validateNumberDateInput.current.decimalNumber(enteredValue * product_material_std_weight, 4);
                    if (event._reactName === 'onBlur') {
                        fnCalculateAmount(rowData);
                        FnMaterialWiseCalculations(rowData);
                    }

                    delete event.target.parentElement.dataset.tip;
                }
                break;
            case "product_material_grn_weight":
                // rowData[clickedColName] = enteredValue;
                rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(enteredValue, 4);
                fnCalculateAmount(rowData);
                FnMaterialWiseCalculations(rowData)
                delete event.target.parentElement.dataset.tip;
                break;
            case 'product_material_grn_accepted_quantity':
                if (parseFloat(enteredValue) > parseFloat(product_material_grn_quantity)) {
                    let actualValidation = document.querySelector(`#${event.target.id}`);
                    actualValidation.parentElement.dataset.tip = `Cannot be greater than GRN Quantity...!`;
                    return false;
                } else {
                    rowData[clickedColName] = enteredValue;
                    rowData.product_material_grn_accepted_weight = validateNumberDateInput.current.decimalNumber(enteredValue * product_material_std_weight, 4);
                    rowData.product_material_grn_rejected_quantity = parseFloat(product_material_grn_quantity) - enteredValue
                    rowData.product_material_grn_rejected_weight = validateNumberDateInput.current.decimalNumber(rowData.product_material_grn_rejected_quantity * product_material_std_weight, 4);

                    fnCalculateAmount(rowData);
                    if (rowData.product_material_grn_rejected_quantity < 1) {
                        rowData.product_material_rejection_reason_id = '';
                    }

                    FnMaterialWiseCalculations(rowData)
                    calculateMaterialWiseDiscount(rowData)
                    delete event.target.parentElement.dataset.tip;
                }
                break;
            case "product_material_grn_accepted_weight":
                // rowData[clickedColName] = enteredValue;
                rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(enteredValue, 4);
                fnCalculateAmount(rowData);
                FnMaterialWiseCalculations(rowData)
                delete event.target.parentElement.dataset.tip;
                break;
            case 'product_material_rejection_reason_id':
                if (clickedColName === 'product_material_rejection_reason_id') {
                    if (validateText(event)) {
                        rowData[clickedColName] = event.target.value;
                    } else {
                        rowData[clickedColName] = '';
                    }
                }
                break;
            case 'excess_quantity':
                rowData[clickedColName] = enteredValue;
                break;

            case 'excess_weight':
                rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                break;

            case 'material_rate':
                rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                // rowData.material_basic_amount = validateNumberDateInput.current.decimalNumber(product_material_grn_quantity * rowData[clickedColName], 4);
                fnCalculateAmount(rowData);
                calculateMaterialWiseDiscount(rowData)
                FnMaterialWiseCalculations(rowData)
                break;
            case 'batch_no':
                rowData[clickedColName] = event.target.value;
                break;
            case 'material_discount_percent':
                const enteredPercent = event.target.value;
                const calculatedAmount = (enteredPercent / 100) * rowData.material_basic_amount;
                if (enteredPercent) {
                    rowData.material_discount_percent = enteredPercent;
                    rowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(calculatedAmount, 4);
                    FnMaterialWiseCalculations(rowData)
                    FnAddDiscountAmountInFooter(calculatedAmount, arrayIndex, detailData, taxSummaryData)
                } else {
                    return;
                }
                break;
            case 'currency_exchange_rate':
                if (enteredValue == '') {
                    let actualValidation = document.querySelector(`#${event.target.id}`);
                    actualValidation.parentElement.dataset.tip = `Please enter valid data...!`;
                    return false;
                } else {
                    rowData[clickedColName] = event.target.value;
                    const convertedGRNRate = (validateNumberDateInput.current.decimalNumber(rowData['po_material_rate'] / rowData[clickedColName], 2))
                    rowData['material_rate'] = convertedGRNRate;
                    rowData.material_basic_amount = validateNumberDateInput.current.decimalNumber(product_material_grn_quantity * convertedGRNRate, 4);
                    calculateMaterialWiseDiscount(rowData)
                    FnMaterialWiseCalculations(rowData)
                }

            case 'material_discount_amount':
                const enteredAmount = validateNumberDateInput.current.decimalNumber(event.target.value, 4) || 0;
                const calculatedPercent = (enteredAmount * 100) / material_basic_amount;
                if (parseFloat(enteredAmount) <= parseFloat(material_basic_amount)) {
                    rowData.material_discount_amount = enteredAmount;
                    rowData.material_discount_percent = validateNumberDateInput.current.decimalNumber(calculatedPercent, 2);
                    FnMaterialWiseCalculations(rowData)
                    FnAddDiscountAmountInFooter(enteredAmount, arrayIndex, detailData, taxSummaryData)
                }
                break;
            case 'godown_id': {
                rowData[clickedColName] = enteredValue;
                if (enteredValue !== '') {
                    delete event.target.parentElement.dataset.tip;
                }
                break;
            }
            case 'press_running_no_from':
            case 'press_running_no_to':
            case 'press_running_remark':
            case 'product_material_unit_id':
            case 'material_schedule_date':
            case 'no_of_boxes':
            case 'expiry_date':
            case 'remark':
            case 'supplier_batch_no':
                delete event.target.parentElement.dataset.tip;
                rowData[clickedColName] = event.target.value;
                break;
            case 'product_rm_purchase_unit_id':
                rowData[clickedColName] = event.target.value;
                fnCalculateAmount(rowData);
                break;
            case 'invoice_gross_weight':
            case 'invoice_tare_weight':
                rowData[clickedColName] = parseFloat(event.target.value) || 0;  // Store updated value in rowData
                rowData['invoice_net_weight'] =
                    (parseFloat(rowData['invoice_gross_weight']) || 0) -
                    (parseFloat(rowData['invoice_tare_weight']) || 0);

                rowData['difference_weight'] = (parseFloat(rowData['invoice_net_weight']) || 0) -
                    (parseFloat(rowData['mill_net_weight']) || 0);
                break;

            case 'mill_gross_weight':
            case 'mill_tare_weight':
                rowData[clickedColName] = parseFloat(event.target.value) || 0;  // Store updated value in rowData
                rowData['mill_net_weight'] =
                    (parseFloat(rowData['mill_gross_weight']) || 0) -
                    (parseFloat(rowData['mill_tare_weight']) || 0);
                rowData['difference_weight'] = (parseFloat(rowData['invoice_net_weight']) || 0) -
                    (parseFloat(rowData['mill_net_weight']) || 0);
                break;

        }
        // detailData[arrayIndex] = rowData
        setGrnDetailsData((prevdata) => {
            let data = [...prevdata];
            data[arrayIndex] = rowData;
            return data; // Return new array
        });

    }

    const fnCalculateAmount = async (material) => {
        let conversionOptions = [...conversionFactorOptions];
        let conversion_factor = conversionOptions.find(
            (_) => _.product_from_unit_id === 1 && _.product_to_unit_id === parseInt(material['product_rm_purchase_unit_id'])
        );
        material['product_material_conversion_factor'] = conversion_factor ? conversion_factor['convsersion_factor'] : 1;
        material['material_basic_amount'] =
            parseFloat(material['product_material_grn_weight'] || 0) *
            parseFloat(material['product_material_conversion_factor'] || 1) *
            parseFloat(material['material_rate'] || 0);
        return material;
    }

    //fn for validate text input 
    const validateText = (event) => {
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '') {
            indentItemsQtyinp.parentElement.dataset.tip = 'Please Select Atleast One...!';
            return false;
        }
        else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }
    const FnAddDiscountAmountInFooter = (calculatedAmount, arrayIndex, detailData, taxSummaryData) => {
        let totalDiscountAmount = 0;
        let totalFreightAmount = 0;
        detailData.map((item, index) => {
            totalFreightAmount += isNaN(Number(item.material_freight_amount)) ? 0 : Number(item.material_freight_amount);
            if (index !== arrayIndex)
                totalDiscountAmount += isNaN(Number(item.material_discount_amount)) ? 0 : Number(item.material_discount_amount);

        })
        totalDiscountAmount += Number(calculatedAmount)
        setGoodsReceiptDiscountAmount(totalDiscountAmount)
        setFreightAmount(totalFreightAmount)
        setTimeout(() => {
            FnFooterAmountChange(taxSummaryData)
        }, 200);
        return totalDiscountAmount;
    }

    // Calculate discount amount 
    const calculateMaterialWiseDiscount = (rowData) => {
        const {
            material_discount_percent,
            material_basic_amount,
        } = rowData;

        const calculatedAmount = (material_discount_percent / 100) * material_basic_amount;
        rowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
    }

    // MaterialWise Calculations
    const FnMaterialWiseCalculations = (currentRowData) => {
        // debugger
        const supplier_state_id = document.getElementById("cmb_supplier_state_id").value;
        const expected_branch_state_id = document.getElementById("cmb_expected_branch_state_id").value;
        let {
            hsn_sac_rate,
            material_discount_amount,
            material_basic_amount,
        } = currentRowData

        let total_amount = 0;
        let cgst_sgst_percent = 0;
        let cgst_amt = 0;
        let sgst_amt = 0;
        let igst_percent = 0;
        let igst_amt = 0;
        const material_taxable_amount = material_basic_amount - material_discount_amount;

        if (supplier_state_id === '39') {
            // Other territories
            cgst_sgst_percent = 0; cgst_amt = 0; sgst_amt = 0; igst_percent = 0; igst_amt = 0;
            total_amount = material_taxable_amount;
        } else if (is_sez) {
            // Special Economical Zone
            cgst_sgst_percent = 0; cgst_amt = 0; sgst_amt = 0; igst_percent = 0; igst_amt = 0;
            total_amount = material_taxable_amount;
        } else if (supplier_state_id === expected_branch_state_id) {
            // Same Supplier & Branch state SGST,CGST.
            cgst_sgst_percent = hsn_sac_rate / 2; //for gst per% cal
            const gst_amt = (material_taxable_amount * hsn_sac_rate) / 100; //for gst amount cal
            cgst_amt = gst_amt / 2;
            sgst_amt = gst_amt / 2;
            total_amount = material_taxable_amount + gst_amt;
        } else {
            // Diiferent Supplier & Branch state IGST.
            igst_percent = hsn_sac_rate;
            igst_amt = (material_taxable_amount * hsn_sac_rate) / 100;
            total_amount = material_taxable_amount + igst_amt;
        }

        currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(material_basic_amount), 4);
        currentRowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(material_discount_amount), 4);
        currentRowData.material_taxable_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(material_taxable_amount), 4);
        currentRowData.material_cgst_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgst_sgst_percent), 2);
        currentRowData.material_cgst_total = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgst_amt), 4);
        currentRowData.material_sgst_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgst_sgst_percent), 2);
        currentRowData.material_sgst_total = validateNumberDateInput.current.decimalNumber(JSON.stringify(sgst_amt), 4);
        currentRowData.material_igst_percentcent = validateNumberDateInput.current.decimalNumber(JSON.stringify(igst_percent), 2);
        currentRowData.material_igst_total = validateNumberDateInput.current.decimalNumber(JSON.stringify(igst_amt), 4);
        currentRowData.material_total_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(total_amount), 4);
        return currentRowData;
    }


    const calculateGrandTotal = (grnDetailsData, grnTaxSummaryData) => {

        let expectedBranchIsSez = $('#cmb_expected_branch_id option:selected').attr('is_sez');
        // let basic_total = parseFloat(grnDetailsData.reduce((total, item) => total + parseFloat(item.material_taxable_amount), 0));
        let basic_total = parseFloat(grnDetailsData.reduce((total, item) => total + parseFloat(item.material_basic_amount), 0));
        let cgst_total = parseFloat(grnTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_cgst_total), 0));
        let sgst_total = parseFloat(grnTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_sgst_total), 0));
        let igst_total = parseFloat(grnTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_igst_total), 0));
        let grandTotal = grnTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_total_amount), 0);

        const discount_amount = isNaN(parseFloat($('#txt_goods_receipt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_goods_receipt_discount_amount').val()))
        // const discount_amount = material_discount_amount;
        const transport_amount = isNaN(parseFloat($('#txt_transport_amount').val())) ? 0 : parseFloat($('#txt_transport_amount').val())
        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const packingAmt = isNaN(parseFloat($('#txt_packing_amount').val())) ? 0 : parseFloat($('#txt_packing_amount').val())
        const other_amount = isNaN(parseFloat($('#txt_other_amount').val())) ? 0 : parseFloat($('#txt_other_amount').val())

        const taxableAmount = basic_total - discount_amount;
        grandTotal = taxableAmount + transport_amount + freightAmt + packingAmt + other_amount + cgst_total + sgst_total + igst_total;

        let roundOffValue = (grandTotal - Math.floor(grandTotal));
        if (roundOffValue >= 0.50) {
            roundOffValue = (roundOffValue - 1); // Subtract 1 to get negative round-off
        }

        // Check if freight is taxable
        // let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
        // let freightTaxHSNIsSelected = document.getElementById('cmb_freight_hsn_id').value;

        // if (freightIsTaxable && (freightTaxHSNIsSelected === '' || freightTaxHSNIsSelected === null)) {
        //   $('#cmb_freight_hsn_id').removeAttr('optional', 'optional');
        //   $('#error_cmb_freight_hsn_id').text('Please select the freight tax hsn code.');
        //   $('#error_cmb_freight_hsn_id').show();
        // } else if (!freightIsTaxable) {
        //   setFreightHSNId('');
        // } else {
        //   $('#error_cmb_freight_hsn_id').hide();
        // }

        // if (freightIsTaxable) {
        //   let freightHsnSelectedOption = document.getElementById('cmb_freight_hsn_id').options[document.getElementById('cmb_freight_hsn_id').selectedIndex];
        //   let freightTaxRate = isNaN(parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'))) ? 0 : parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'));
        //   const freightTaxAmt = (freightAmt * freightTaxRate) / 100;

        //   if (cmb_supplier_state_id === cmb_expected_branch_state_id && expectedBranchIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
        //     cgst_total += freightTaxAmt / 2;
        //     sgst_total += freightTaxAmt / 2;
        //   } else if (expectedBranchIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
        //     igst_total += freightTaxAmt;
        //   }
        // }

        setBasicTotal(validateNumberDateInput.current.decimalNumber(JSON.stringify(basic_total), 4));
        setCgstTotal(validateNumberDateInput.current.decimalNumber(cgst_total, 4));
        setSgstTotal(validateNumberDateInput.current.decimalNumber(sgst_total, 4));
        setIgstTotal(validateNumberDateInput.current.decimalNumber(igst_total, 4))
        setFreightAmount(validateNumberDateInput.current.decimalNumber(freightAmt, 4))
        setTaxableTotal(taxableAmount)
        let Gtotal = Math.round(grandTotal)
        setGrandTotal(Math.round(Gtotal));

        setRoundOff((roundOffValue).toFixed(2));
    }

    // Function to normalize hsnCode values for grouping
    function normalizeHsnCode(hsnCode) {
        return hsnCode.replace(/\s+/g, '').toUpperCase();
    }
    const calculateTaxationSummary = (grnDetailsData) => {
        try {
            const taxableData = [];
            // Use reduce to group data by HSN rate
            const groupedData = grnDetailsData.reduce((acc, item) => {
                const hsnCode = normalizeHsnCode(item.hsn_sac_code);
                // const key = item.hsnCode; // Group by HSN rate
                if (!acc[hsnCode]) {
                    acc[hsnCode] = [];
                }
                acc[hsnCode].push(item);
                return acc;
            }, {});

            // Convert the grouped data object into an array of arrays
            const groupedDataArray = Object.values(groupedData);

            groupedDataArray.forEach(group => {
                const {
                    hsn_sac_code,
                    product_material_hsn_code_id: hsn_code_id,
                    purchase_order_no,
                    purchase_order_date,
                    purchase_order_version,
                    material_cgst_percent,
                    material_sgst_percent,
                    material_igst_percent,
                    purchase_order_master_transaction_id
                } = group[0];

                const groupTotalCGSTAmt = group.reduce((total, item) => total + parseFloat(item.material_cgst_total), 0);

                const groupTotalSGSTAmt = group.reduce((total, item) => total + parseFloat(item.material_sgst_total), 0);

                const groupTotalIGSTAmt = group.reduce((total, item) => total + parseFloat(item.material_igst_total), 0);

                const groupTotalAmt = group.reduce((total, item) => total + parseFloat(item.material_total_amount), 0);
                const groupTaxableAmt = group.reduce((total, item) => total + parseFloat(item.material_taxable_amount), 0);

                // Create an object for each group
                const groupObject = {
                    hsn_sac_code,
                    hsn_code_id,
                    purchase_order_no,
                    purchase_order_date,
                    purchase_order_version,
                    purchase_order_master_transaction_id,
                    summary_taxable_amount: groupTaxableAmt,
                    summary_cgst_percent: material_cgst_percent,
                    summary_cgst_total: groupTotalCGSTAmt,
                    summary_sgst_percent: material_sgst_percent,
                    summary_sgst_total: groupTotalSGSTAmt,
                    summary_igst_percent: material_igst_percent,
                    summary_igst_total: groupTotalIGSTAmt,
                    summary_total_amount: groupTotalAmt,
                };

                taxableData.push(groupObject);
            });

            // Check if freight is taxable
            let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
            if (freightIsTaxable)
                FnCalculateFreightTaxation(taxableData)

            setGrnTaxSummaryData(taxableData)

            calculateGrandTotal(grnDetailsData, taxableData)

        } catch (error) {
            console.log("error: ", error);
            navigator('/Error')
        }

    }

    const FnCalculateFreightTaxation = (taxableData) => {
        let expectedBranchIsSez = $('#cmb_expected_branch_id option:selected').attr('is_sez');
        let freightHsnSelectedOption = document.getElementById('cmb_freight_hsn_id').options[document.getElementById('cmb_freight_hsn_id').selectedIndex];
        let freightTaxRate = isNaN(parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'))) ? 0 : parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'));
        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const freightTaxAmt = (freightAmt * freightTaxRate) / 100;
        // Create an object for each group
        const taxSummary = {
            hsn_sac_code: $('#cmb_freight_hsn_id option:selected').attr('lbl'),
            hsn_code_id: $('#cmb_freight_hsn_id').val(),
            summary_taxable_amount: freightAmt,
            summary_cgst_percent: 0,
            summary_cgst_total: 0,
            summary_sgst_percent: 0,
            summary_sgst_total: 0,
            summary_igst_percent: 0,
            summary_igst_total: 0,
            summary_total_amount: freightAmt,
        };

        if (cmb_supplier_state_id === cmb_expected_branch_state_id && expectedBranchIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
            // Set inter state tax totals
            taxSummary.summary_cgst_total = (freightTaxAmt / 2);
            taxSummary.summary_cgst_percent = (freightTaxRate / 2);
            taxSummary.summary_sgst_total = (freightTaxAmt / 2);
            taxSummary.summary_sgst_percent = (freightTaxRate / 2);
            taxSummary.summary_total_amount = (freightAmt + freightTaxAmt);
        } else if (expectedBranchIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
            // Set outer state tax totals
            taxSummary.summary_igst_total = (freightTaxAmt);
            taxSummary.summary_igst_percent = (freightTaxRate);
            taxSummary.summary_total_amount = (freightAmt + freightTaxAmt);
        }

        const existingFreightTaxationObj = taxableData.findIndex((record) => record.hsn_code_id === parseInt(taxSummary.hsn_code_id));
        if (existingFreightTaxationObj !== -1) {
            let object = taxableData[existingFreightTaxationObj]
            object.summary_taxable_amount = object.summary_taxable_amount + taxSummary.summary_taxable_amount
            object.summary_cgst_total = object.summary_cgst_total + taxSummary.summary_cgst_total;
            object.summary_sgst_total = object.summary_sgst_total + taxSummary.summary_sgst_total;
            object.summary_total_amount = object.summary_total_amount + taxSummary.summary_total_amount;
            object.summary_igst_total = object.summary_igst_total + taxSummary.summary_igst_total;
        } else {
            taxableData.push(taxSummary);                           // Push the new object if not found
        }
    }

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForFrieght = (grnDetailsData) => {

        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        // const discount_amount = isNaN(parseFloat($('#txt_goods_receipt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_goods_receipt_discount_amount').val()))
        if (grnDetailsData.length !== 0) {
            const updatedData = grnDetailsData?.map(obj => {
                // Calculate item_freight_amount and discount_amount
                let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
                // let discountAmount;
                // let calculatedPercent;

                // if (discountAmount !== 0) {
                //   discountAmount = (obj.material_basic_amount / txt_basic_total) * discount_amount;
                //   calculatedPercent = (discountAmount * 100) / obj.material_basic_amount;
                // }

                // Update the object with item_freight_amount, item_discount_amount, and item_discount_percent
                const updatedObj = {
                    ...obj,
                    material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                    // material_discount_amount: discountAmount === 0 ? obj.material_discount_amount : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    // material_discount_percent: discountAmount === 0 ? obj.material_discount_percent : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2),
                };

                // Call FnMaterialWiseCalculations to update other properties
                const calculatedValues = FnMaterialWiseCalculations(updatedObj);
                // Merge the calculated values with the updated object
                const finalUpdatedObj = {
                    ...updatedObj,
                    ...calculatedValues,
                };
                return finalUpdatedObj;
            });
            setGrnDetailsData(updatedData);
        }
    }
    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForDiscount = (grnDetailsData) => {

        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const discount_amount = isNaN(parseFloat($('#txt_goods_receipt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_goods_receipt_discount_amount').val()))

        if (grnDetailsData.length !== 0) {
            const updatedData = grnDetailsData?.map(obj => {
                // Calculate item_freight_amount and discount_amount
                let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
                let discountAmount;
                let calculatedPercent;

                if (discountAmount !== 0) {
                    discountAmount = (obj.material_basic_amount / txt_basic_total) * discount_amount;
                    calculatedPercent = (discountAmount * 100) / obj.material_basic_amount;
                }
                // Update the object with item_freight_amount, item_discount_amount, and item_discount_percent
                const updatedObj = {
                    ...obj,
                    material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                    material_discount_amount: discountAmount === 0 ? 0 : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    material_discount_percent: discountAmount === 0 ? 0 : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2),
                };
                // Call FnMaterialWiseCalculations to update other properties
                const calculatedValues = FnMaterialWiseCalculations(updatedObj);
                // Merge the calculated values with the updated object
                const finalUpdatedObj = { ...calculatedValues, };
                return finalUpdatedObj;
            });
            setGrnDetailsData(updatedData);
        }
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('GRNHeaderFormId')
        validate.current.validateFieldsOnChange('GRNFooterFormId')
    }

    const FnValidateGoodReceiptNote = async () => {
        // debugger
        // Validating header footer forms data.
        let headerDataIsValid = await validate.current.validateForm("GRNHeaderFormId");
        if (!headerDataIsValid) { return false; }

        // Check combo values for supplier combo
        let supplierVal = cmb_supplier_id || '';
        if (supplierVal === "" || supplierVal === '0') {
            $("#error_cmb_supplier_id").text("Please fill this field...!");
            $("#error_cmb_supplier_id").show();
            $("#cmb_supplier_id").focus();
            return false;
        }

        let qaByVal = cmb_qa_by_id || '';
        if (qaByVal === "" || qaByVal === '0') {
            $("#error_cmb_qa_by_id").text("Please fill this field...!");
            $("#error_cmb_qa_by_id").show();
            $("#cmb_qa_by_id").focus();
            return false;
        }
        let GrnDetailsIsValid = true;
        if (grnDetailsData.length === 0) {
            setErrMsg('Please add atleast one material...');
            setShowErrorMsgModal(true);
            return GrnDetailsIsValid = false;
        } else {
            return validateGRNtbl();
        }
        let footerDataIsValid = await validate.current.validateForm("GRNFooterFormId");
        if (!footerDataIsValid) { return false; }

        return GrnDetailsIsValid;
    }

    const validateGRNtbl = () => {
        // debugger
        let validation = true; // Assume validation is successful

        grnDetailsData.forEach((item, index) => {
            ['product_material_grn_quantity', 'material_rate', 'supplier_batch_no', 'godown_id'].forEach((key) => {
                if (item.hasOwnProperty(key)) {
                    const elementId = `${key}_${index}`;
                    const element = document.getElementById(elementId);

                    if (element && isElementVisible(element)) {
                        const value = element.value;
                        if ([0, '0', '', undefined, null].includes(value)) {
                            element.parentElement.dataset.tip = 'Please Fill this...!';
                            element.focus();
                            validation = false; // Mark validation as failed
                        }
                    }
                }
            });
        });

        return validation;
    }

    // Function to check if an element is visible
    const isElementVisible = (el) => {
        return !!(el && el.offsetParent !== null);
    };

    const FnGetSupplierContactIds = () => {
        let supplierContactPersonsIds = '';
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            supplierContactPersonsIds += $(this).val() + ":";
        });
        return supplierContactPersonsIds.replace(/:$/, '');
    }

    const validateNumericInputs = (event) => {
        // const floatNumReg = /^\d{1,18}(?:\.\d{0,4})?$/; || !floatNumReg.test(event.target.value)
        let tblInputField = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === undefined) {
            $("#error_" + event.target.id).show();
            $("#" + event.target.id).focus();
            $("#error_" + event.target.id).text("Please enter valid input...!");
            return false;
        } else {
            delete tblInputField.parentElement.dataset.tip;
            $("#error_" + event.target.id).hide();
            $("#error_" + event.target.id).text("");
            return true;
        }
    }


    // saveGRN Note
    const createGoodReceiptNote = async (grnStatus) => {
        if (isLoading) return;
        setIsLoading(true)

        try {
            debugger
            let grnValidated = await FnValidateGoodReceiptNote();
            if (!grnValidated) { return false; }

            if (keyForViewUpdate === "approve") {
                if (cmb_goods_receipt_status === 'P' || cmb_goods_receipt_status === '') {
                    $('#error_cmb_goods_receipt_status').show();
                    return false;
                }
            }
            let latestGrnNo = txt_grn_no;
            if (true) {
                let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransTaxSummaryData': [], 'TransPaymentTermsData': [], 'TransIndentGRNDetailsData': [], 'commonIds': {} }
                if (goods_receipt_master_transaction_id === 0) {
                    latestGrnNo = await generateGrnNo();
                }
                let supplierContactsIds = FnGetSupplierContactIds();
                const grnMasterData = {
                    grn_cotton_bales_master_transaction_id: idList.grn_cotton_bales_master_transaction_id,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    goods_receipt_no: latestGrnNo,
                    goods_receipt_date: dt_goods_receipt_date,
                    goods_receipt_version: txt_grn_version,
                    supplier_id: cmb_supplier_id,
                    supplier_state_id: cmb_supplier_state_id,
                    supplier_city_id: cmb_supplier_city_id,
                    supplier_contacts_ids: supplierContactsIds,
                    expected_branch_id: cmb_expected_branch_id,
                    expected_branch_state_id: cmb_expected_branch_state_id,
                    expected_branch_city_id: cmb_expected_branch_city_id,
                    purchase_order_no: cmb_purchase_order_no,
                    station: txt_station,
                    batch_no: lastGeneratedBatchNo,
                    purchase_order_date: cmb_purchase_order_date,
                    purchase_order_version: cmb_purchase_order_version,
                    goods_receipt_type_id: cmb_goods_receipt_type_id,
                    goods_receipt_type: $('#cmb_goods_receipt_type_id').find(":selected").text(),
                    // purchase_order_life: rb_purchase_order_life,
                    supplier_challan_no: txt_supplier_challan_no,
                    supplier_challan_date: dt_supplier_challan_Date,
                    approved_by_id: isApprove ? cmb_approved_by_id : '',
                    approved_date: isApprove ? dt_approved_date : '',
                    qa_by_id: cmb_qa_by_id,
                    qa_date: dt_qa_date,
                    // expected_schedule_date: null,
                    basic_total: txt_basic_total,
                    transport_amount: txt_transport_amount,
                    freight_amount: txt_freight_amount,
                    is_freight_taxable: chk_freight_isTaxable,
                    freight_hsn_code_id: cmb_freight_hsn_id,
                    // packing_amount: txt_packing_amount,
                    goods_receipt_discount_percent: txt_goods_receipt_discount_percent,
                    goods_receipt_discount_amount: txt_goods_receipt_discount_amount,
                    other_amount: txt_other_amount,
                    taxable_total: txt_taxable_total,
                    cgst_total: txt_cgst_total,
                    sgst_total: txt_sgst_total,
                    igst_total: txt_igst_total,
                    grand_total: txt_grand_total,
                    roundoff: txt_roundOff,
                    agent_id: cmb_agent_id,
                    agent_percent: txt_agent_percent,
                    // agent_amount: txt_agent_amount,
                    agent_paid_status: cmb_agent_paid_status,
                    goods_receipt_status: cmb_goods_receipt_status === '' ? 'P' : cmb_goods_receipt_status,
                    lr_no: txt_lr_no,
                    lr_date: dt_lr_date,
                    vehicle_no: txt_vehicle_no,
                    // other_terms_conditions: txt_other_terms_conditions,
                    supplier_invoice_no: txt_invoice_no,
                    supplier_invoice_date: dt_invoice_date,
                    gate_entry_no: txt_gate_entry_no,
                    gate_entry_date: dt_gate_entry_date,
                    remark: txt_remark,
                    is_active: is_active,
                    created_by: UserName,
                    is_preeclosed: is_pree_closed,
                    modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : '',
                    ev_bill_no: txt_ev_bill_no,
                    ev_bill_date: dt_ev_bill_date,
                    // sales_type: ""
                }

                json.TransHeaderData = grnMasterData;

                for (let index = 0; index < grnDetailsData.length; index++) {
                    const element = grnDetailsData[index];
                    let jsonRecord = {}
                    jsonRecord.grn_cotton_bales_details_transaction_id = element?.grn_cotton_bales_details_transaction_id && element.grn_cotton_bales_details_transaction_id !== 0 ? element.grn_cotton_bales_details_transaction_id : 0;
                    jsonRecord.grn_cotton_bales_master_transaction_id = element?.grn_cotton_bales_master_transaction_id && element.grn_cotton_bales_master_transaction_id !== 0 ? element.grn_cotton_bales_master_transaction_id : 0;
                    jsonRecord.company_id = COMPANY_ID;
                    jsonRecord.company_branch_id = COMPANY_BRANCH_ID;
                    jsonRecord.financial_year = FINANCIAL_SHORT_NAME;
                    jsonRecord.goods_receipt_no = latestGrnNo;
                    jsonRecord.goods_receipt_date = dt_goods_receipt_date;
                    jsonRecord.goods_receipt_version = txt_grn_version;
                    jsonRecord.purchase_order_no = element.purchase_order_no;
                    jsonRecord.purchase_order_date = element.purchase_order_date;
                    jsonRecord.purchase_order_version = element.purchase_order_version;
                    // jsonRecord.customer_id = element.customer_id;
                    // jsonRecord.customer_order_no = element.customer_order_no;
                    // jsonRecord.customer_order_Date = element.customer_order_Date;
                    jsonRecord.item_qa_by_id = cmb_qa_by_id;
                    jsonRecord.item_qa_date = dt_qa_date;
                    jsonRecord.goods_receipt_type_id = element.goods_receipt_type_id;
                    jsonRecord.goods_receipt_type = element.goods_receipt_type;
                    jsonRecord.batch_no = lastGeneratedBatchNo;
                    jsonRecord.supplier_batch_no = element.supplier_batch_no;
                    jsonRecord.product_material_id = element.product_material_id;
                    jsonRecord.product_category1_id = element.product_category1_id;
                    jsonRecord.product_category2_id = element.product_category2_id;
                    // jsonRecord.lead_time = element.lead_time;
                    // jsonRecord.sr_no = element.sr_no;
                    jsonRecord.product_material_unit_id = element.product_material_unit_id;
                    jsonRecord.product_material_packing_id = element.product_material_packing_id;
                    jsonRecord.product_material_hsn_code_id = element.product_material_hsn_code_id;
                    jsonRecord.product_material_po_approved_quantity = element.product_material_po_approved_quantity;
                    jsonRecord.product_material_po_approved_weight = element.product_material_po_approved_weight;
                    jsonRecord.product_material_grn_quantity = element.product_material_grn_quantity;
                    jsonRecord.product_material_grn_weight = element.product_material_grn_weight;
                    jsonRecord.product_material_grn_accepted_quantity = element.product_material_grn_accepted_quantity;
                    jsonRecord.product_material_grn_accepted_weight = element.product_material_grn_accepted_weight;
                    jsonRecord.product_material_conversion_factor = element.product_material_conversion_factor;
                    jsonRecord.product_material_grn_rejected_quantity = element.product_material_grn_rejected_quantity;
                    jsonRecord.product_material_grn_rejected_weight = element.product_material_grn_rejected_weight;
                    jsonRecord.product_purchase_unit_id = element.product_rm_purchase_unit_id;
                    jsonRecord.material_rate = element.material_rate;
                    jsonRecord.po_material_rate = element.po_material_rate;
                    jsonRecord.material_basic_amount = element.material_basic_amount;
                    jsonRecord.material_freight_amount = element.material_freight_amount;
                    jsonRecord.material_discount_percent = element.material_discount_percent;
                    jsonRecord.material_discount_amount = element.material_discount_amount;
                    jsonRecord.material_taxable_amount = element.material_taxable_amount;
                    jsonRecord.material_cgst_percent = element.material_cgst_percent;
                    jsonRecord.material_cgst_total = element.material_cgst_total;
                    jsonRecord.material_sgst_percent = element.material_sgst_percent;
                    jsonRecord.material_sgst_total = element.material_sgst_total;
                    jsonRecord.material_igst_percent = element.material_igst_percent;
                    jsonRecord.material_igst_total = element.material_igst_total;
                    jsonRecord.material_total_amount = element.material_total_amount;
                    jsonRecord.material_schedule_date = element.material_schedule_date;
                    jsonRecord.excess_quantity = element.excess_quantity;
                    jsonRecord.excess_weight = element.excess_weight;
                    // jsonRecord.product_material_prev_accepted_quantity = element.product_material_prev_accepted_quantity;
                    // jsonRecord.product_material_prev_accepted_weight = element.product_material_prev_accepted_weight;
                    jsonRecord.pree_closed_grn_quantity = is_pree_closed ? element.product_material_po_approved_quantity - element.prev_grn_bales_quantity - element.product_material_grn_accepted_quantity : 0;
                    jsonRecord.pree_closed_grn_weight = is_pree_closed ? element.product_material_po_approved_weight - element.prev_grn_bales_weight - element.product_material_grn_accepted_weight : 0;

                    jsonRecord.department_id = element.department_id;
                    jsonRecord.grn_item_status = keyForViewUpdate === 'approve' ? element.product_material_po_approved_quantity == (element.prev_grn_bales_quantity + element.product_material_grn_accepted_quantity) ? 'G' : 'I' : element.grn_item_status;
                    // jsonRecord.remark = element.remark;
                    jsonRecord.created_by = UserName;
                    jsonRecord.modified_by = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : '';
                    jsonRecord.expiry_date = element.expiry_date;
                    jsonRecord.purchase_order_details_transaction_id = element.purchase_order_details_transaction_id
                    jsonRecord.godown_id = element.godown_id;
                    // jsonRecord.godown_section_id = 0
                    // jsonRecord.godown_section_beans_id = 0
                    // jsonRecord.product_material_prev_accepted_quantity = element.goods_receipt_details_transaction_id !== undefined ? element.product_material_prev_accepted_quantity : 0
                    // jsonRecord.total_box_weight = element.total_box_weight
                    // jsonRecord.total_quantity_in_box = element.total_quantity_in_box
                    // jsonRecord.weight_per_box_item = element.weight_per_box_item
                    // jsonRecord.no_of_boxes = element.no_of_boxes
                    jsonRecord.invoice_gross_weight = element.invoice_gross_weight;
                    jsonRecord.invoice_tare_weight = element.invoice_tare_weight;
                    jsonRecord.invoice_net_weight = element.invoice_net_weight;
                    jsonRecord.mill_gross_weight = element.mill_gross_weight;
                    jsonRecord.mill_tare_weight = element.mill_tare_weight;
                    jsonRecord.mill_net_weight = element.mill_net_weight;
                    jsonRecord.difference_weight = element.difference_weight;
                    jsonRecord.press_running_no_from = element.press_running_no_from;
                    jsonRecord.press_running_no_to = element.press_running_no_to;
                    jsonRecord.press_running_remark = element.press_running_remark;
                    jsonRecord.currency_id = element.currency_id;
                    jsonRecord.grn_currency_id = cmb_currency_id;
                    jsonRecord.currency_exchange_rate = jsonRecord.currency_exchange_rate;

                    json.TransDetailData.push(jsonRecord);
                }

                for (let index = 0; index < grnTaxSummaryData.length; index++) {
                    const element = grnTaxSummaryData[index];
                    const taxSummaryData = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        financial_year: FINANCIAL_SHORT_NAME,
                        goods_receipt_no: latestGrnNo,
                        goods_receipt_date: dt_goods_receipt_date,
                        goods_receipt_version: txt_grn_version,
                        // purchase_order_type_id: cmb_goods_receipt_type_id,
                        // purchase_order_type: $('#cmb_goods_receipt_type_id').find(":selected").text(),
                        purchase_order_master_transaction_id: element.purchase_order_master_transaction_id,
                        supplier_id: cmb_supplier_id,
                        supplier_state_id: cmb_supplier_state_id,
                        supplier_city_id: cmb_supplier_city_id,
                        supplier_contacts_ids: supplierContactsIds,
                        expected_branch_id: cmb_expected_branch_id,
                        expected_branch_state_id: cmb_expected_branch_state_id,
                        expected_branch_city_id: cmb_expected_branch_city_id,
                        purchase_order_no: element.purchase_order_no,
                        purchase_order_date: element.purchase_order_date,
                        purchase_order_version: element.purchase_order_version,
                        hsn_code_id: element.hsn_code_id,
                        summary_taxable_amount: element.summary_taxable_amount,
                        summary_cgst_percent: element.summary_cgst_percent,
                        summary_cgst_total: element.summary_cgst_total,
                        summary_sgst_percent: element.summary_sgst_percent,
                        summary_sgst_total: element.summary_sgst_total,
                        summary_igst_percent: element.summary_igst_percent,
                        summary_igst_total: element.summary_igst_total,
                        summary_total_amount: element.summary_total_amount,
                        // tax_upload__status
                        // tax_upload_id
                        // tax_upload_date
                        created_by: element.created_by === undefined ? UserName : element.created_by,
                        modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : '',

                        grn_cotton_bales_tax_summary_transaction_id: element?.grn_cotton_bales_tax_summary_transaction_id && element.grn_cotton_bales_tax_summary_transaction_id !== 0 ? element.grn_cotton_bales_tax_summary_transaction_id : 0,
                        grn_cotton_bales_master_transaction_id: element?.grn_cotton_bales_master_transaction_id && element.grn_cotton_bales_master_transaction_id !== 0 ? element.grn_cotton_bales_master_transaction_id : 0

                    }
                    json.TransTaxSummaryData.push(taxSummaryData)
                }



                if (!isApprove) {
                    for (let index = 0; index < paymentTermsData.length; index++) {
                        const element = paymentTermsData[index];
                        const payTermsData = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            goods_receipt_no: latestGrnNo,
                            goods_receipt_date: dt_goods_receipt_date,
                            goods_receipt_version: txt_grn_version,
                            purchase_order_no: element.purchase_order_no,
                            purchase_order_date: element.purchase_order_date,
                            purchase_order_version: element.purchase_order_version,
                            payment_terms_id: element.payment_terms_id,
                            payment_terms_name: element.payment_terms_name,
                            payment_terms_days: element.payment_terms_days,
                            payment_terms_grace_days: element.payment_terms_grace_days,
                            payment_terms_Milestome: element.payment_terms_Milestome,
                            payment_percent: element.payment_percent,
                            payment_expected_value: element.payment_expected_value,
                            payment_expected_date: element.payment_expected_date,
                            payment_paid_flag: element.payment_paid_flag,
                            created_by: element.created_by === undefined ? UserName : element.created_by,
                            modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                        }
                        json.TransPaymentTermsData.push(payTermsData)
                    }
                    if (json.TransPaymentTermsData.length === 0) {
                        json.TransPaymentTermsData = paymentTermsData.map((pay_term) => {
                            pay_term.goods_receipt_no = keyForViewUpdate === 'copy' ? latestGrnNo : pay_term.goods_receipt_no;
                            pay_term.goods_receipt_date = keyForViewUpdate === 'copy' ? dt_goods_receipt_date : pay_term.goods_receipt_date;
                            pay_term.goods_receipt_version = keyForViewUpdate === 'copy' ? txt_grn_version : pay_term.goods_receipt_version;
                            pay_term.created_by = keyForViewUpdate === 'copy' ? UserName : pay_term.created_by;
                            pay_term.modified_by = keyForViewUpdate === 'copy' ? UserName : pay_term.modified_by;
                            pay_term.grn_cotton_bales_master_transaction_id = pay_term?.grn_cotton_bales_master_transaction_id && pay_term.grn_cotton_bales_master_transaction_id !== 0 ? pay_term.grn_cotton_bales_master_transaction_id : 0;
                            pay_term.grn_cotton_bales_payment_terms_transaction_id = pay_term?.grn_cotton_bales_payment_terms_transaction_id && pay_term.grn_cotton_bales_payment_terms_transaction_id !== 0 ? pay_term.grn_cotton_bales_payment_terms_transaction_id : 0;
                            return pay_term;
                        })
                    }

                }
                //Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.goods_receipt_no = latestGrnNo
                json.commonIds.goods_receipt_version = txt_grn_version
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                json.commonIds.KeyForViewUpdate = keyForViewUpdate;
                var approvedByName = $("#cmb_approved_by_id option:selected").text().trim();
                json.commonIds.ApprovedByName = approvedByName ? approvedByName : "0";

                let formData = new FormData();
                formData.append(`GoodReceiptNoteData`, JSON.stringify(json))
                let requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGrnCottonBalesMaster/FnAddUpdateRecord/${isApprove}`, requestOptions)
                const addResponse = await apicall.json();

                if (addResponse.success === 0) {
                    setErrMsg(addResponse.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = await comboDataFunc.current.evitCache();
                    console.log(evitCache);
                    setMessage(addResponse.message)
                    if (addResponse.data.hasOwnProperty('goods_receipt_version') && addResponse.data.hasOwnProperty('goods_receipt_no')) {
                        setModalOrderDetails(`Good Receipt No: ${addResponse.data.goods_receipt_no} and Version: ${addResponse.data.goods_receipt_version}`)
                    }
                    if (goods_receipt_master_transaction_id === 0) {
                        setMessageForConfirmation('')
                    }
                    setShowConfiemationModal(true);
                }
            }
        } catch (error) {
            console.log("error: ", error);
            // navigator('/Error')
        } finally {
            setIsLoading(false)
        }

    };


    //For Product Based Properties
    useEffect(async () => {
        // debugger
        if (getPBproperties.current === true) {
            //get hsn code for freight amount
            resetGlobalQuery();
            globalQuery.columns = ["*"];
            globalQuery.table = "pt_quality_testing_cotton_bales"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "batch_no", operator: "=", value: grnDetailsData[0]['batch_no'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getBatchNoApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setqualityTestingDetails(getBatchNoApiCall);
            getPBproperties.current = false;
        }
    }, [grnDetailsData])


    //Fn to render Quality Testing Params
    const renderQualityTestingTable = useMemo(() => {
        return <div className='scrollable'>
            <Table className='erp_table' id="qualitytestingtableId" bordered>
                <thead className="erp_table_head table-secondary">
                    <tr>
                        <td className="erp_table_th " style={{ width: "50px" }}>Mill Lot No</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>UHML</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>ML</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>UI</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>MIC</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>STR</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>RD</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>B+</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>TOTAL NEPS</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>SC(N)</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>SFC(N)</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>TRASH</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>MOISTURE</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>CG</td>
                        <td className="erp_table_th " style={{ width: "50px" }}>Quality Status</td>
                    </tr>
                </thead>
                <tbody>

                    {qualityTestingDetails.length > 0 ? (
                        qualityTestingDetails?.map((details, indexOfItem) => (
                            <tr key={indexOfItem} rowIndex={indexOfItem}>
                                <td className={`erp_table_th`}>{details.batch_no}</td>
                                <td key={indexOfItem} className="erp_table_td">{details.uhml}</td>
                                <td key={indexOfItem} className="erp_table_td">{details.mi}</td>
                                <td key={indexOfItem} className="erp_table_td">{details.ul}</td>
                                <td key={indexOfItem} className="erp_table_td">{details.mic}</td>
                                <td key={indexOfItem} className="erp_table_td" >{details.str}</td>
                                <td key={indexOfItem} className="erp_table_td">{details.rd}</td>
                                <td key={indexOfItem} className="erp_table_td" >{details.b_plus}</td>
                                <td key={indexOfItem} className="erp_table_td" >{details.total_neps}</td>
                                <td key={indexOfItem} className="erp_table_td" >{details.sc_n}</td>
                                <td key={indexOfItem} className="erp_table_td" >{details.sfc_n}</td>
                                <td key={indexOfItem} className="erp_table_td" >{details.trash}</td>
                                <td key={indexOfItem} className="erp_table_td" >{details.moisture}</td>
                                <td key={indexOfItem} className="erp_table_td" >{details.cg}</td>
                                <td key={indexOfItem} className="erp_table_td" >
                                    {details.quality_testing_status === "C" ? "Consumable" : details.quality_testing_status === "I" ? "Partially-Rejected" : "Rejected"}
                                </td>
                            </tr>
                        ))) : (
                        <tr>
                            <td className="erp_table_td" style={{}} colSpan="16">
                                {"No Records found...! Quality Testing Not Yet Done..!"}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table></div>

    }, [qualityTestingDetails]);

    const refreshData = async (key) => {

        switch (key) {
            case 'get_supplier':
                // get suppliers list with default state & city
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "supp_branch_state_id", "supp_branch_city_id",
                    "state_name", "city_name"
                ];

                globalQuery.table = "cmv_supplier_branch";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "supp_branch_type", operator: "=", value: "Supplier" });
                const suppliersApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                //  ** Changes for the Searchable Combo
                const suppliers = [
                    { value: '', label: 'Select', supp_branch_state_id: '', supp_branch_city_id: '', state_name: '', city_name: '', },
                    { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '' },
                    ...suppliersApiCall.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, })),
                ];
                setSupplierList(suppliers);

                break;
            case 'get_exptBranch':
                resetGlobalQuery();
                globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "branch_city_id", "state_name", "city_name", 'is_sez']
                globalQuery.table = "cmv_company_branch";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const getExpectedtBranchs = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                setExpectedBranchList(getExpectedtBranchs);
                break;
            case 'get_agent':
                // for agents List
                const agentApiCall = await comboDataFunc.current.fillMasterData("cmv_agent", "", "");
                setAgentsList(agentApiCall)
                break;

            default:
                break;
        }
    }

    const memoizedTaxSummaryComponent = useMemo(() => (
        <TaxSummary grnTaxSummaryData={grnTaxSummaryData} validateNumberDateInput={validateNumberDateInput} />
    ), [grnTaxSummaryData]);

    // Use the replace method to replace "/" with "_"
    let group_id = txt_grn_no.replace(/\//g, "_");
    const memorizedDocumentComponent = useMemo(() => {
        return <>
            {/* Document modal */}
            <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <Document group_id={group_id} document_group={documentGroup} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }, [showDocumentForm])


    //---------------------------------- Payment Terms section Start -----------------------------------------------------------------------


    // const FnShowPaymentTermsRecrds = () => {
    //     // let existingPayTermsData = existingPaymentTermsData;
    //     setPaymentTermsData(existingPayTermsData);
    // }
    //Fn for render payment terms static table 
    const renderPaymentTermsTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='paymentTerms-table-id' responsive bordered striped >
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Sr.No.</th>
                        <th className="erp_table_th">Purchase Order No</th>
                        <th className="erp_table_th">Purchase Order Date</th>
                        <th className="erp_table_th">Purchase Order Version</th>
                        <th className="erp_table_th">Term Names</th>
                        <th className="erp_table_th">Days </th>
                        <th className="erp_table_th">Grace Days</th>
                        <th className="erp_table_th">Milestome</th>
                        <th className="erp_table_th">Payment Percent</th>
                        <th className="erp_table_th">Expected Value</th>
                        <th className="erp_table_th">Expected Date</th>
                        <th className="erp_table_th">Payment Paid</th>
                        <th className="erp_table_th">Payment Paid Transaction Id</th>
                        <th className="erp_table_th">Payment Paid Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        paymentTermsData.map((payTermItem, Index) =>
                            <tr payTermItemIndex={Index}>
                                <td className="erp_table_td text-end">{Index + 1}</td>
                                <td className="erp_table_td">{payTermItem.purchase_order_no}</td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payTermItem.purchase_order_date)}</td>
                                <td className="erp_table_td text-end">{payTermItem.purchase_order_version}</td>
                                <td className="erp_table_td">{payTermItem.payment_terms_name}</td>
                                <td className="erp_table_td text-end">{payTermItem.payment_terms_days}</td>
                                <td className="erp_table_td text-end">{payTermItem.payment_terms_grace_days}</td>
                                <td className="erp_table_td">{payTermItem.payment_terms_Milestome}  </td>
                                <td className="erp_table_td text-end">{payTermItem.payment_percent}</td>
                                <td className="erp_table_td text-end">{payTermItem.payment_expected_value} </td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payTermItem.payment_expected_date)}</td>
                                <td className="erp_table_td ">
                                    <select id={`payment_paid_flag_${payTermItem.payment_terms_id}`} value={payTermItem.payment_paid_flag} className="form-select form-select-sm mb-1"
                                        Headers='payment_paid_flag' disabled >
                                        <option value="P">Pending</option>
                                        <option value="T">Partail</option>
                                        <option value="R">Receipt</option>
                                    </select>
                                </td>
                                <td className="erp_table_td text-end">{payTermItem.payment_paid_transaction_id} </td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payTermItem.payment_paid_date)} </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [paymentTermsData]);


    //----------------------------------------  Payment Terms Section End ----------------------------------------------------------


    //--------------------------------- Clear Details form start------------------------------------------------------------------------------------------------
    const FnClearForm = async () => {
        await generateGrnNo();
        setGRNVersion(1)
        setGoodReceiptDate(today())
        setSupplierId('')
        setSuppliersStateId('')
        setSuppliersCityId('')
        setSupplierChallanNo('')
        setdtSupplierChallanDate('')
        setExpectedBranchId(COMPANY_BRANCH_ID)
        comboOnChange('')
        setExpectedBranchStateId('')
        setExpectedBranchCityId('')
        setPurchaseOrderLife('C')
        setApprovedById('')
        setApprovedDate(today)
        setQabyId(UserId)
        setQadate(today)
        setBasicTotal(0)
        setTransportAmount(0)
        setFreightAmount(0)
        setFreightHSNId('')
        setFreightIsTaxable(false)
        setPackingAmount(0)
        setGoodsReceiptDiscountPercent(0)
        setGoodsReceiptDiscountAmount(0)
        setOtherAmount(0)
        setTaxableTotal(0)
        setCgstTotal(0)
        setSgstTotal(0)
        setIgstTotal(0)
        setGrandTotal(0)
        setRoundOff(0)
        setAgentPercent(0)
        setAgentId('')
        setAgentPaidStatus('');
        setGoodsReceiptStatus('')
        setlrNo('')
        setlrDate('')
        setInvoice_no('')
        setInvoice_date('')
        setVehicleNo('')
        setOtherTermsConditions('')
        setRemark('')
        setIsActive(true)
        setIsPreeClosed(false)
        setIsSez(false)
        setSuppContactDetails([])
        setSuppContactDetailsCols([])
        setPOInfoList([])
        setGrnDetailsData([])
        setGrnPODetailsList([])
        setGrnTaxSummaryData([])
        setPaymentTermsData([])
        setIndentMaterialDetailsData([])
        let expectedBranch = expectedBranchList.find(item => item.company_branch_id === parseInt(COMPANY_BRANCH_ID))
        setExpectedBranchStateId(expectedBranch.branch_state_id)
        await comboOnChange('ExpectedBranchState')
        setExpectedBranchCityId(expectedBranch.branch_city_id)
        setIsSez(expectedBranch.is_sez)
    }

    //--------------------------------- Clear Details form Ends------------------------------------------------------------------------------------------------
    //--------------------------------- Delete PO Starts------------------------------------------------------------------------------------------------

    async function deleteGrn() {
        try {
            const method = { method: 'delete' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnDeleteRecord/${goods_receipt_master_transaction_id}/${UserName}`, method)
            const responce = await deleteApiCall.json();
            console.log("Raw material Deleted: ", responce);
            if (responce.success == '1') {
                setShow(false)
                navigator(`/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteCottonBalesListing`);
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }
    //--------------------------------- Delete PO Ends------------------------------------------------------------------------------------------------
    //--------------------------------- Document List Starts--------------------------------------------------

    // Fn for get document list
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${documentGroup}&group_id=${txt_grn_no !== 0 ? txt_grn_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    const filteredData = resp.content.filter(item => 
                        !( keyForViewUpdate === "" || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve') ? item.is_active : resp.content
                     );
                     setDocumentData(filteredData);
                     }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">File Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">
                                    <MDTypography
                                        component="label"
                                        className="erp-form-label"
                                        variant="button"
                                        id="logoFile"
                                        fontWeight="regular"
                                        color="info"
                                        onClick={() => fetchDocument(docItem)}
                                        style={{ cursor: 'pointer', color: '#0000FF' }} // Change cursor to pointer to indicate clickable
                                        onMouseEnter={(e) => e.target.style.color = '#0000FF'} // Change color on hover
                                        onMouseLeave={(e) => e.target.style.color = '#0000FF'} // Restore original color when not hovering
                                    >
                                        {docItem.file_name}
                                    </MDTypography>
                                </td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);

    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {

            const formData = new FormData()
            const data = {
                document_group: docItem.document_group,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }
    //--------------------------------- Document List Ends------------------------------------------------------------------------------------------------

    // fn to remove material
    const removeMaterial = (indexToRemove, setStateCallback, currentRow) => {
        if (indexToRemove === undefined || indexToRemove < 0 || indexToRemove >= grnDetailsData.length) {
            console.error("Invalid index provided.");
            return;
        }
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            // Create a new array excluding the element at indexToRemove

            // Filter out the item at indexToRemove
            const updatedDetailsData = grnDetailsData.filter((_, index) => index !== indexToRemove);

            // Update state using the provided callback
            setStateCallback(updatedDetailsData);


        } else {
            console.error("Cannot remove material in 'approve' or 'view' mode.");
        }
    }

    $(document).on('click', '.remove_input_zero', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });

    // ------------------------ Changes for the Searchable Combos. --------------------------
    let supplierComboRef = useRef(null);
    const typeaheadRef = useRef();
    let qaByComboRef = useRef(null);
    let purchaseOrderNoComboRef = useRef(null);
    let stationNameRef = useRef(null);
    const stationInputRef = useRef(null);



    //fn for do contact checked box
    const renderSupplierContactTable = useMemo(() => {
        return <Datatable data={SuppContactDetails} columns={SuppContactDetailsCols} />
    }, [SuppContactDetails, SuppContactDetailsCols])


    // --------------------------- search functionality for po materials ------------------------------------------------------------------------------------------------
    // fn for get search results
    const FnSearchOnChange = (searchText) => {
        try {
            if (searchText.trim() !== "") {
                const lowercasedSearchText = searchText.toLowerCase();
                const filteredList = grnPODetailsList.filter(item =>
                    item.product_material_name.toLowerCase().includes(lowercasedSearchText) || item.product_material_code.toLowerCase().includes(lowercasedSearchText)
                );
                if (filteredList.length > 0) {
                    console.log('MaterialSearchData: ', filteredList);
                    // setFiteredGRNPOList(filteredList)
                    // set options data for suggestions
                    let uniqueIds = new Set();
                    let optionsData = filteredList
                        .filter(item => {
                            if (uniqueIds.has(item.product_material_id)) {
                                return false; // Skip if the ID is already present
                            }
                            uniqueIds.add(item.product_material_id);
                            return true;
                        })
                        .map(item => ({
                            name: `${item.product_material_code === null || item.product_material_code === '' ? "" : `[${item.product_material_code}] `}${item.product_material_name}`,
                            value: item.product_material_id,
                            product_material_id: item.product_material_id
                        }));

                    setOptions(optionsData)
                } else {
                    console.log('productData else : ', filteredList);
                    setOptions([])
                }
            }
        } catch (error) {
            console.log("Error On search material: ", error);
        }
    }

    // get matreial data on select
    const selectMaterial = (productId) => {
        // debugger
        setIsLoading(true);
        if (productId !== undefined) {
            const tdData = grnPODetailsList.filter(item => item.product_material_id === productId);

            if (tdData.length > 0) {
                setGrnDetailsData((prevArray) => {
                    const newItems = tdData.map(item => ({ ...item }));
                    return [...prevArray, ...newItems];
                });
            }
        }
        setIsLoading(false);
    }

    //GRN printout Start
    const grnInvoiceContent = {
        context: 'Bales',
        company: {
            company: '',
            company_branch: '',
        },
        balesData: {},
        supplierDetails: {
            name: '',
            address: '',
            state: '',
            contact: '',
            City: '',
            Email: '',
            agent_name: '',
            supplier_invoice_no: '',
            supplier_invoice_date: '',
            qa_by_name: '',
            qa_date: '',
            is_sez: '',
            supplier_challan_no: '',
            supplier_challan_date: '',
            purchase_order_life: '',
            supp_branch_gst_no: '',
            ev_bill_no: '',
            ev_bill_date: ''

        },
        grnDetails: {
            goods_receipt_no: '',
            goods_receipt_date: '',
            goods_receipt_version: '',
            receivers_name: '',
            gate_entry_no: '',
            gate_entry_date: '',
        },
        purchaseDetails: {
            purchase_order_no: '',
            purchase_order_date: '',

        },
        footer: {
            basic_total: '',
            cgst_total: '',
            sgst_total: '',
            igst_total: '',
            taxable_total: '',
            grand_total: '',
            freight_amount: '',
            discount_amount: '',
            discount_percent: ''

        },
        items: [],
        paymentTerms: [],
    };

    function formatDateToDDMMYYYY(inputDate) {
        if (!inputDate) return "";

        // Convert input to a Date object
        const date = new Date(inputDate);

        // Check if the date is valid
        if (isNaN(date)) return "Invalid Date";

        // Extract the day, month, and year
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }



    const printInvoice = async (openPrintModal, isWaterMark) => {

        // const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnShowAllDetailsAndMastermodelRecords/${txt_grn_version}/${idList.financial_year}/${COMPANY_ID}?goods_receipt_no=${txt_grn_no}`)
        // const response = await apiCall.json();
        // console.log("Response GRN Invoice : ", response);


        let GoodsReceiptMasterData = masterDataForPrintPreview ?? [];

        //GRN details
        grnInvoiceContent.grnDetails.goods_receipt_no = txt_grn_no
        grnInvoiceContent.grnDetails.goods_receipt_date = formatDateToDDMMYYYY(dt_goods_receipt_date)
        grnInvoiceContent.grnDetails.goods_receipt_version = txt_grn_version
        grnInvoiceContent.grnDetails.purchase_iso_no = "ISO123456789";
        grnInvoiceContent.grnDetails.receivers_name = GoodsReceiptMasterData.qa_by_name;




        grnInvoiceContent.purchaseDetails.purchase_order_no = GoodsReceiptMasterData.purchase_order_no
        grnInvoiceContent.purchaseDetails.purchase_order_date = formatDateToDDMMYYYY(GoodsReceiptMasterData.purchase_order_date)

        //Company details
        grnInvoiceContent.company.company = GoodsReceiptMasterData.company_name
        grnInvoiceContent.company.company_branch = GoodsReceiptMasterData.company_branch_name
        grnInvoiceContent.company.sales_type = "";

        //supplier details
        grnInvoiceContent.supplierDetails.name = supplierList?.find(option => option.value === cmb_supplier_id)?.field_name
        grnInvoiceContent.supplierDetails.address = ""
        grnInvoiceContent.supplierDetails.supp_branch_gst_no = supplierList?.find(option => option.value === cmb_supplier_id)?.supp_branch_gst_no
        grnInvoiceContent.supplierDetails.agent_name = agentsList?.find(agent => agent.field_id == cmb_agent_id)?.field_name
        grnInvoiceContent.supplierDetails.supplier_invoice_no = txt_invoice_no
        grnInvoiceContent.supplierDetails.supplier_invoice_date = formatDateToDDMMYYYY(dt_invoice_date)
        grnInvoiceContent.supplierDetails.supplier_challan_no = txt_supplier_challan_no;
        grnInvoiceContent.supplierDetails.supplier_challan_date = formatDateToDDMMYYYY(dt_supplier_challan_Date)

        grnInvoiceContent.balesData.batch_no = GoodsReceiptMasterData.batch_no;
        grnInvoiceContent.balesData.lr_no = GoodsReceiptMasterData.lr_no;
        grnInvoiceContent.balesData.lr_date = GoodsReceiptMasterData.lr_date;
        grnInvoiceContent.supplierDetails.ev_bill_no = GoodsReceiptMasterData.ev_bill_no;
        grnInvoiceContent.supplierDetails.ev_bill_date = GoodsReceiptMasterData.ev_bill_date;
        grnInvoiceContent.balesData.godown_name = grnDetailsData[0].godown_name;


        let is_sez = null;
        if (GoodsReceiptMasterData.is_sez === "true") {
            is_sez = "Yes";
        } else {
            is_sez = "No";
        }

        //Customer details
        grnInvoiceContent.company.company = GoodsReceiptMasterData.company_name
        grnInvoiceContent.company.company_branch = GoodsReceiptMasterData.company_branch_name

        //footer details    
        grnInvoiceContent.footer.grn_currency_code = grnDetailsData[0].grn_currency_code
        grnInvoiceContent.footer.basic_total = txt_basic_total
        grnInvoiceContent.footer.cgst_total = txt_cgst_total
        grnInvoiceContent.footer.sgst_total = txt_sgst_total
        grnInvoiceContent.footer.igst_total = txt_igst_total
        grnInvoiceContent.footer.taxable_total = txt_taxable_total
        grnInvoiceContent.footer.grand_total = txt_grand_total
        grnInvoiceContent.footer.freight_amount = txt_freight_amount
        grnInvoiceContent.footer.discount_amount = txt_goods_receipt_discount_amount
        grnInvoiceContent.footer.discount_percent = txt_goods_receipt_discount_percent
        grnInvoiceContent.footer.round_off = txt_roundOff
        grnInvoiceContent.footer.grn_currency_symbol = grnDetailsData[0]['grn_currency_symbol'];
        grnInvoiceContent.footer.grn_currency_lable = grnDetailsData[0]['grn_currency_name'];


        for (let i = 0; i < paymentTermsData.length; i++) {
            const element = paymentTermsData[i];
            const paymentemsData = {
                payment_terms_name: element.payment_terms_name,
            }
            grnInvoiceContent.paymentTerms.push(paymentemsData)
        }
        for (let index = 0; index < grnDetailsData.length; index++) {
            const element = grnDetailsData[index];
            //PO Raw cotton Deduction Data
            // const materialProperties = qualityTestingDetails.filter((item) => item.product_id === element.product_rm_id);
            // let cotton_description = "";
            // if (materialProperties.length !== 0) {
            //   materialProperties.forEach((item) => {
            //     cotton_description += `- ${item.product_parameter_name} : ${item.product_parameter_value} `
            //   })
            // }
            let additional_info = element.remark == '' || element.remark == null ? '' : `(${element.remark})`;
            if (element.product_material_grn_quantity > 0) {
                const detailsData = {
                    sr_no: element.sr_no,
                    product_material_code: element.product_material_code,
                    material_name: `${element.product_material_name} ` + `${additional_info}`,
                    material_tech_spect: element.product_material_tech_spect === undefined || element.product_material_tech_spect === null ? "" : element.product_material_tech_spect,
                    hsn_code: element.product_material_hsn_sac_code,
                    grn_quantity: element.product_material_grn_quantity,
                    grn_accepted_quantity: element.product_material_grn_accepted_quantity,
                    grn_accepted_weight: element.product_material_grn_accepted_weight,
                    grn_rejected_quantity: element.product_material_grn_rejected_quantity,
                    unit: unitList.find((unit) => element.field_id === unit.product_rm_purchase_unit_id)?.field_name,
                    rate: element.material_rate,
                    discount_amount: element.material_discount_amount,
                    cgst_amount: element.material_cgst_total,
                    sgst_amount: element.material_sgst_total,
                    igst_amount: element.material_igst_total,
                    cgst_percent: element.material_cgst_percent,
                    sgst_percent: element.material_sgst_percent,
                    igst_percent: element.material_igst_percent,
                    basic_amount: element.material_basic_amount,
                    taxable_amount: element.material_taxable_amount,
                    material_total_amount: element.material_total_amount,
                    purchase_order_no: element.purchase_order_no,
                    // indent_no: element.indent_no,
                    purchase_order_version: element.purchase_order_version,
                    supplier_challan_no: element.supplier_challan_date,
                    purchase_order_date: formatDateToDDMMYYYY(element.purchase_order_date),
                    customer_order_no: element.customer_order_no,
                    godown_section_beans_name: element.godown_section_beans_name
                }
                grnInvoiceContent.items.push(detailsData)
            }
        }

        navigator('/Invoice', { state: { invoiceData: grnInvoiceContent, navigationLink: "/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteCottonBalesEntry", invoiceType: 'GRN', title: 'Goods Receipt Note', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });

    }


    // Print Preview function
    const printPreview = () => {
        debugger
        //GRN details
        grnInvoiceContent.grnDetails.goods_receipt_no = txt_grn_no
        grnInvoiceContent.grnDetails.goods_receipt_date = formatDateToDDMMYYYY(dt_goods_receipt_date)
        grnInvoiceContent.grnDetails.goods_receipt_version = txt_grn_version
        grnInvoiceContent.grnDetails.purchase_iso_no = "ISO123456789";
        grnInvoiceContent.grnDetails.receivers_name = employeeList.find(emp => emp.value === cmb_qa_by_id).field_name
        grnInvoiceContent.grnDetails.gate_entry_no = txt_gate_entry_no
        grnInvoiceContent.grnDetails.gate_entry_date = dt_gate_entry_date


        grnInvoiceContent.purchaseDetails.purchase_order_no = ''
        grnInvoiceContent.purchaseDetails.purchase_order_date = formatDateToDDMMYYYY("")

        //Company details
        grnInvoiceContent.company.company = COMPANY_NAME
        grnInvoiceContent.company.company_branch = localStorage.getItem("company_branch_name")
        grnInvoiceContent.company.sales_type = ""

        //supplier details
        grnInvoiceContent.supplierDetails.name = supplierList?.find(option => option.value === cmb_supplier_id)?.field_name
        grnInvoiceContent.supplierDetails.address = ""
        grnInvoiceContent.supplierDetails.supp_branch_gst_no = supplierList?.find(option => option.value === cmb_supplier_id)?.supp_branch_gst_no
        grnInvoiceContent.supplierDetails.agent_name = agentsList?.find(agent => agent.field_id == cmb_agent_id)?.field_name
        grnInvoiceContent.supplierDetails.supplier_invoice_no = txt_invoice_no
        grnInvoiceContent.supplierDetails.supplier_invoice_date = formatDateToDDMMYYYY(dt_invoice_date)
        grnInvoiceContent.supplierDetails.supplier_challan_no = txt_supplier_challan_no
        grnInvoiceContent.supplierDetails.supplier_challan_date = formatDateToDDMMYYYY(dt_supplier_challan_Date)


        grnInvoiceContent.balesData.batch_no = lastGeneratedBatchNo;
        grnInvoiceContent.balesData.lr_no = txt_lr_no;
        grnInvoiceContent.balesData.lr_date = dt_lr_date;
        grnInvoiceContent.supplierDetails.ev_bill_no = txt_ev_bill_no;
        grnInvoiceContent.supplierDetails.ev_bill_date = dt_ev_bill_date;
        grnInvoiceContent.balesData.godown_name = grnDetailsData[0].godown_name;

        //footer details    
        grnInvoiceContent.footer.grn_currency_code = grnDetailsData[0].grn_currency_code
        grnInvoiceContent.footer.basic_total = txt_basic_total
        grnInvoiceContent.footer.cgst_total = txt_cgst_total
        grnInvoiceContent.footer.sgst_total = txt_sgst_total
        grnInvoiceContent.footer.igst_total = txt_igst_total
        grnInvoiceContent.footer.taxable_total = txt_taxable_total
        grnInvoiceContent.footer.grand_total = txt_grand_total
        grnInvoiceContent.footer.freight_amount = txt_freight_amount
        grnInvoiceContent.footer.discount_amount = txt_goods_receipt_discount_amount
        grnInvoiceContent.footer.discount_percent = txt_goods_receipt_discount_percent
        grnInvoiceContent.footer.round_off = txt_roundOff
        grnInvoiceContent.footer.grn_currency_symbol = grnDetailsData[0]['grn_currency_symbol'];
        grnInvoiceContent.footer.grn_currency_lable = grnDetailsData[0]['grn_currency_name'];


        for (let i = 0; i < paymentTermsData.length; i++) {
            const element = paymentTermsData[i];
            const paymentemsData = {
                payment_terms_name: element.payment_terms_name,
            }
            grnInvoiceContent.paymentTerms.push(paymentemsData)
        }
        for (let index = 0; index < grnDetailsData.length; index++) {
            const element = grnDetailsData[index];
            let additional_info = element.remark == '' || element.remark == null ? '' : `(${element.remark})`;
            if (element.product_material_grn_quantity > 0) {
                const detailsData = {
                    sr_no: element.sr_no,
                    product_material_code: element.product_material_code,
                    material_name: `${element.product_material_name} ` + `${additional_info}`,
                    material_tech_spect: element.product_material_tech_spect === undefined || element.product_material_tech_spect === null ? "" : element.product_material_tech_spect,
                    hsn_code: element.product_material_hsn_sac_code,
                    grn_quantity: element.product_material_grn_quantity,
                    grn_accepted_quantity: element.product_material_grn_accepted_quantity,
                    grn_accepted_weight: element.product_material_grn_accepted_weight,
                    grn_rejected_quantity: element.product_material_grn_rejected_quantity,
                    unit: unitList.find((unit) => element.field_id === unit.product_rm_purchase_unit_id)?.field_name,
                    rate: element.material_rate,
                    discount_amount: element.material_discount_amount,
                    cgst_amount: element.material_cgst_total,
                    sgst_amount: element.material_sgst_total,
                    igst_amount: element.material_igst_total,
                    cgst_percent: element.material_cgst_percent,
                    sgst_percent: element.material_sgst_percent,
                    igst_percent: element.material_igst_percent,
                    basic_amount: element.material_basic_amount,
                    taxable_amount: element.material_taxable_amount,
                    material_total_amount: element.material_total_amount,
                    purchase_order_no: element.purchase_order_no,
                    // indent_no: element.indent_no,
                    purchase_order_version: element.purchase_order_version,
                    supplier_challan_no: element.supplier_challan_date,
                    purchase_order_date: formatDateToDDMMYYYY(element.purchase_order_date),
                    customer_order_no: element.customer_order_no,
                    godown_section_beans_name: element.godown_section_beans_name
                }
                grnInvoiceContent.items.push(detailsData)
            }
        }

        const renderedHtml = renderToString(<GoodReceiptNote invoiceContent={grnInvoiceContent} isWaterMark={true} />);
        setPrintPreviewContent(renderedHtml);
        setShowPreviewModalIsOpen(true);

    }



    const ExportToExcel = () => {
        if (grnDetailsData.length !== 0) {
            const checkedValues = $("input:checkbox[name=purchaseOrderNoCheckBox]:checked").map(function () {
                return $(this).val();
            }).get();

            const resultString = checkedValues.join(', ');
            let selectedSuppId = supplierComboRef.current.value || '';

            let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [7, 9, 13, 15] }

            jsonToExportExcel.filtrKeyValue['0'] = "Goods Receipt Type" + ' : ' + $('#cmb_goods_receipt_type_id').find(":selected").text() + "(" + cmb_goods_receipt_type_id + ")";
            jsonToExportExcel.filtrKeyValue['0'] = "GRN No" + ' : ' + txt_grn_no;
            jsonToExportExcel.filtrKeyValue['1'] = "GRN Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_goods_receipt_date);
            jsonToExportExcel.filtrKeyValue['2'] = "GRN Version" + ' : ' + txt_grn_version;
            // jsonToExportExcel.filtrKeyValue['3'] = "Supplier" + ' : ' + $('#cmb_supplier_id').find(":selected").text() + "(" + cmb_supplier_id + ")";
            jsonToExportExcel.filtrKeyValue['3'] = "Supplier" + ' : ' + (selectedSuppId !== "" && selectedSuppId !== '0' && selectedSuppId !== null ? supplierComboRef.current?.label + "(" + supplierComboRef.current?.value + ")" : '');
            jsonToExportExcel.filtrKeyValue['4'] = "Purchase Order No." + ' : ' + '(' + resultString + ')';
            jsonToExportExcel.filtrKeyValue['5'] = "Supplier State" + ' : ' + $('#cmb_supplier_state_id').find(":selected").text() + "(" + cmb_supplier_state_id + ")";
            jsonToExportExcel.filtrKeyValue['6'] = "Supplier City" + ' : ' + $('#cmb_supplier_city_id').find(":selected").text() + "(" + cmb_supplier_city_id + ")";
            jsonToExportExcel.filtrKeyValue['7'] = "Supplier Challan No." + ' : ' + txt_supplier_challan_no;
            jsonToExportExcel.filtrKeyValue['8'] = "Supplier Challan Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_supplier_challan_Date);
            jsonToExportExcel.filtrKeyValue['9'] = "Expected Branch" + ' : ' + $('#cmb_expected_branch_id').find(":selected").text() + "(" + cmb_expected_branch_id + ")";
            jsonToExportExcel.filtrKeyValue['10'] = "Expected Branch State" + ' : ' + $('#cmb_expected_branch_state_id').find(":selected").text() + "(" + cmb_expected_branch_state_id + ")";
            jsonToExportExcel.filtrKeyValue['11'] = "Expected Branch City" + ' : ' + $('#cmb_expected_branch_city_id').find(":selected").text() + "(" + cmb_expected_branch_city_id + ")";
            // jsonToExportExcel.filtrKeyValue['12'] = "Purchase Order Life" + ' : ' + rb_purchase_order_life;
            jsonToExportExcel.filtrKeyValue['13'] = "Qa By" + ' : ' + $('#cmb_qa_by_id').find(":selected").text() + "(" + cmb_qa_by_id + ")";
            jsonToExportExcel.filtrKeyValue['14'] = "Qa By Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_qa_date);

            let colNames = [
                "sr_no",
                "product_material_name",
                "purchase_order_no",
                "product_material_po_approved_quantity",
                "product_material_po_approved_weight",
                "prev_grn_bales_quantity",
                "prev_grn_bales_weight",
                "product_material_grn_quantity",
                "product_material_grn_weight",
                "product_material_grn_accepted_quantity",
                "product_material_grn_accepted_weight",
                "product_material_grn_rejected_quantity",
                "product_material_grn_rejected_weight",
                // "product_rejection_type",
                "material_rate",
                "material_basic_amount",
                "material_discount_percent",
                "material_discount_amount",
                "material_taxable_amount",
                "material_cgst_percent",
                "material_cgst_total",
                "material_sgst_percent",
                "material_sgst_total",
                "material_igst_percent",
                "material_igst_total",
                "material_total_amount",
                "material_schedule_date",
                "customer_order_no",
                "customer_order_Date",
                "batch_no",
                "product_material_conversion_factor",
                "product_material_tech_spect",
                "product_material_hsn_sac_code",
                "product_material_std_weight",
                "hsn_sac_rate",
                "product_packing_name",
                "total_box_weight",
                "total_quantity_in_box",
                "weight_per_box_item",
                "lead_time",
                "godown_id",
                "godown_section_id",
                "godown_section_beans_id",
                "company_id",
                "company_branch_id",
                "purchase_order_details_transaction_id",
                "product_material_unit_id",
                "product_material_packing_id",
                "product_material_hsn_code_id",
                "customer_id",
                "product_material_id",
                "product_material_type_id",
                "department_id",
            ];

            if (IS_EXCESS_ALLOWED) {
                colNames.push("excess_quantity", "excess_weight");
                jsonToExportExcel.editable_cols.push(49);
            }
            // columns
            for (let col = 0; col < colNames.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": colNames[col], "accessor": colNames[col] });
            }

            for (let index = 0; index < grnDetailsData.length; index++) {
                jsonToExportExcel['allData'][index] = grnDetailsData[index]
            }
            jsonToExportExcel['headings']['ReportName'] = "Goods Receipt Note Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            exlsExp.current.excel(jsonToExportExcel, txt_grn_no + "@" + COMPANY_ID)
        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
    }

    const onImportClick = () => {
        importFile.current.click();
    };
    const importedExcelFile = async (event) => {
        try {
            if (event.target.files && event.target.files.length > 0) {
                setIsLoading(true)
                var file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnReadExcel`, requestOptions)
                const response = await apicall.json();

                console.log(response)
                if (response.success === 1) {
                    let purhcase_orderNO = "";
                    const readedOrderDetailsFromExcel = response.data?.map(dataRow => {
                        const orderDetail = response.columns.reduce((acc, key, secIndex) => {
                            acc[key] = dataRow[secIndex];
                            return acc;
                        }, {});
                        if (isNaN(parseFloat(orderDetail.product_material_grn_quantity))) {
                            orderDetail.product_material_grn_quantity = 0;
                            orderDetail.product_material_grn_weight = 0;
                        } else {
                            if (orderDetail.product_material_grn_quantity !== "0") {
                                orderDetail.product_material_grn_quantity = parseInt(orderDetail.product_material_po_approved_quantity) - parseInt(orderDetail.prev_grn_bales_quantity);
                                orderDetail.product_material_grn_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_quantity * orderDetail.product_material_std_weight), 4);
                            }
                        }
                        if (isNaN(parseFloat(orderDetail.product_material_grn_accepted_quantity))) {
                            orderDetail.product_material_grn_accepted_quantity = 0;
                            orderDetail.product_material_grn_accepted_weight = 0;
                        } else {
                            if (orderDetail.product_material_grn_accepted_quantity !== "0") {
                                orderDetail.product_material_grn_accepted_quantity = parseInt(orderDetail.product_material_grn_quantity);
                                orderDetail.product_material_grn_accepted_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_accepted_quantity * orderDetail.product_material_std_weight), 4);
                                orderDetail.product_material_grn_rejected_quantity = parseInt(orderDetail.product_material_grn_quantity) - parseInt(orderDetail.product_material_grn_accepted_quantity)
                                orderDetail.product_material_grn_rejected_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_rejected_quantity * orderDetail.product_material_std_weight), 4);
                                orderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_accepted_quantity * orderDetail.material_rate), 4);
                                if (orderDetail.product_material_grn_rejected_quantity < 1) {
                                    orderDetail.product_material_rejection_reason_id = '';
                                }
                            }
                        }
                        if (isNaN(parseFloat(orderDetail.material_rate))) {
                            orderDetail.material_rate = 0;
                        } else {
                            if (orderDetail.material_rate !== "0") {
                                orderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_accepted_quantity * orderDetail.material_rate), 4);
                            }
                        }
                        if (isNaN(parseFloat(orderDetail.material_discount_percent))) {
                            orderDetail.material_discount_percent = 0;
                        } else {
                            const enteredPercent = validateNumberDateInput.current.decimalNumber(orderDetail.material_discount_percent, 2) || 0;
                            const calculatedAmount = (enteredPercent / 100) * orderDetail.material_basic_amount;
                            if (validateNumberDateInput.current.percentValidate(enteredPercent)) {
                                orderDetail.material_discount_percent = enteredPercent;
                                orderDetail.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
                            }
                        }

                        return orderDetail;
                    });
                    purhcase_orderNO = readedOrderDetailsFromExcel[0].purchase_order_no;
                    for (let formFieldIndex = 0; formFieldIndex < response.formFieldData.length; formFieldIndex++) {
                        const element = response.formFieldData[formFieldIndex];
                        if (element !== "") {
                            const field = element.split(':');
                            const fieldName = field[0].trim();
                            const fieldVal = field[1].trim();
                            let comboVal;
                            if (fieldVal !== "" && fieldName === 'Supplier' || fieldName === 'Expected Branch' || fieldName === 'Qa By') {
                                const splitVal = fieldVal.split('(')
                                comboVal = splitVal[1].replace(')', '')
                            }
                            switch (fieldName) {
                                case 'Supplier':
                                    setSupplierId(comboVal)
                                    comboOnChange('Supplier')
                                    break;
                                case 'Supplier Challan No.':
                                    setSupplierChallanNo(fieldVal)
                                    break;
                                case 'Supplier Challan Date':
                                    setdtSupplierChallanDate(fieldVal)
                                    break;
                                case 'Purchase Order Life':
                                    setPurchaseOrderLife(fieldVal)
                                    break;
                            }

                        }
                    }

                    setGrnDetailsData(() => {
                        let modifiedImportData = readedOrderDetailsFromExcel.map((ordDetail) => ({
                            ...ordDetail,
                            goods_receipt_details_transaction_id: 0,
                            hsn_sac_code: ordDetail.product_material_hsn_sac_code,
                            material_schedule_date: validateNumberDateInput.current.formatDateToDDMMYYYY(ordDetail.material_schedule_date)

                        }));

                        modifiedImportData = modifiedImportData.map(FnMaterialWiseCalculations);
                        return modifiedImportData;
                    });
                    if (purhcase_orderNO !== "") {
                        resetGlobalQuery();
                        globalQuery.columns = ["*"];
                        globalQuery.table = "ptv_purchase_order_payment_terms";
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "purchase_order_no", operator: "=", value: purhcase_orderNO });
                        const getGaymentTermsData = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                        setPaymentTermsData(getGaymentTermsData)
                    } else {
                        setPaymentTermsData([])
                    }



                    setIsLoading(false)
                } else {
                    setErrMsg(response.error)
                    setIsLoading(false)
                    setShowErrorMsgModal(true)

                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }


    const handleAddStation = () => {
        // debugger
        const addedStation = stationInputRef?.current; // Access input value directly

        if (addedStation) {
            const newStation = { value: addedStation, label: addedStation };
            setStationList((prevList) => [...prevList, newStation]);
            setStation(addedStation);

            stationInputRef.current = '';
        }
    };

    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={comboDataFunc} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ExcelExport ref={exlsExp} />


            <DashboardLayout>
                {isLoading &&
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span id="spinner_text" className="text-dark">Loading...</span>
                        </div>
                    </div>}
                <form id="GRNHeaderFormId">
                    <div className='card mt-4 px-2 py-2'>
                        <div className="card-header text-center py-0">
                            <label className='erp-form-label-lg main_heding'>Goods Receipt Note (Cotton Bales) {actionType}</label>
                        </div>
                        <div className="row p-1">
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Goods Receipt Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_goods_receipt_type_id" className="form-select form-select-sm" value={cmb_goods_receipt_type_id} onChange={(e) => { comboOnChange('GoodsReceiptType'); validateFields(); }} disabled>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {goodReceiptTypeList?.map(GoodsReceiptType => (
                                                <option value={GoodsReceiptType.field_id}>{GoodsReceiptType.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_goods_receipt_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>


                                <div className={` row`}>
                                    <div className='col-sm-3 col-12'>
                                        <Form.Label className="erp-form-label">Product Category<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-9'>
                                        <select id="cmb_product_category_id" className="form-select form-select-sm" value={cmb_product_category_id} disabled>
                                            <option value="">Select</option>
                                            {productCategoryOptions.length !== 0 ?
                                                <>
                                                    {productCategoryOptions?.map(poCategory => (
                                                        <option value={poCategory.product_category2_id} >{poCategory.product_category2_name}</option>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_category_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3 col-12">
                                        <Form.Label className="erp-form-label">GRN No, Version & Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_grn_no" className="erp_input_field" value={txt_grn_no} optional='optional' disabled />
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3">
                                                <Form.Control type="text" id='txt_grn_version' className="erp_input_field" value={txt_grn_version} optional='optional' disabled />
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3 ">
                                                <Form.Control type="date" id='dt_goods_receipt_date' className="erp_input_field" value={dt_goods_receipt_date} onChange={(e) => { setGoodReceiptDate(e.target.value); validateFields(); loadPurchaseOrderNos(); }} />
                                                <MDTypography variant="button" id="error_dt_goods_receipt_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3 col-12">
                                        <Form.Label className="erp-form-label">Supplier <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col mb-1'>
                                        <Select ref={supplierComboRef}
                                            options={supplierList}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            inputId="cmb_supplier_id" // Provide the ID for the input box
                                            value={supplierList.find(option => option.value === cmb_supplier_id)}
                                            onChange={(selectedOpt) => {
                                                setSupplierId(selectedOpt.value);
                                                supplierComboRef.current = selectedOpt;
                                                comboOnChange("Supplier");
                                                validateFields();
                                            }}
                                            placeholder="Search for a supplier..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_supplier_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { refreshData('get_supplier') }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Supplier State & city<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_supplier_state_id" className="form-select form-select-sm" value={cmb_supplier_state_id} optional='optional' disabled>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {stateList?.map(SupplierState => (
                                                <option value={SupplierState.field_id}>{SupplierState.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_supplier_city_id" className="form-select form-select-sm" value={cmb_supplier_city_id} optional='optional' disabled >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {cityList?.map(Suppliercity => (
                                                <option value={Suppliercity.field_id}>{Suppliercity.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3 col-12">
                                        <Form.Label className="erp-form-label">PO No.<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col">
                                                <Select ref={purchaseOrderNoComboRef}
                                                    options={poInfoList}
                                                    isDisabled={![''].includes(keyForViewUpdate)}
                                                    inputId="cmb_purchase_order_no" // Provide the ID for the input box
                                                    value={poInfoList.find(option => option.value === cmb_purchase_order_no) || null}
                                                    onChange={(selectedOpt) => {
                                                        setPurchaseOrderNo(selectedOpt.value);
                                                        purchaseOrderNoComboRef.current = selectedOpt;
                                                        setPurchaseOrderDate(selectedOpt.purchase_order_date)
                                                        setPurchaseOrderVersion(selectedOpt.purchase_order_version)
                                                        showPODetails(selectedOpt.value, storedGrnDetailsData);
                                                    }}
                                                    placeholder="Search for PO No..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 col-md-2">
                                                <Form.Label className="erp-form-label">Station</Form.Label>
                                            </div>
                                            <div className='col-12 col-md-3'>



                                                <Select
                                                    ref={stationNameRef}
                                                    inputRef={stationInputRef}  // Use the inputRef here
                                                    options={stationList}
                                                    isDisabled={keyForViewUpdate !== ''}
                                                    id="txt_station"
                                                    value={stationList.find(station => station.value === txt_station) || null}
                                                    onChange={(selectedOption) => {
                                                        if (selectedOption) {
                                                            setStation(selectedOption.value);
                                                            setNewValSdearched(selectedOption.label);
                                                        }
                                                    }}
                                                    onInputChange={(newInputValue) => {
                                                        if (newInputValue) {
                                                            stationInputRef.current = newInputValue;
                                                        }

                                                    }}
                                                    placeholder="Search for Station..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select"
                                                    styles={{
                                                        option: (provided) => ({ ...provided, fontSize: '12px' }),
                                                        singleValue: (provided) => ({ ...provided, fontSize: '12px' }),
                                                        input: (provided) => ({ ...provided, fontSize: '12px' })
                                                    }}
                                                />

                                            </div>
                                            <div className="col-sm-1 col-2">
                                                <Tooltip title="Add Station" placement="top">
                                                    <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                        <div className="col-sm-1 col-2">
                                                            <MdAdd
                                                                style={{ color: 'black' }}
                                                                onClick={handleAddStation}
                                                                disabled={!serachedVal}  // Disable if serachedVal is empty
                                                            />
                                                        </div>
                                                    </MDTypography>
                                                </Tooltip>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Supplier Challan No. & Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_supplier_challan_no" className="erp_input_field" value={txt_supplier_challan_no} disabled={keyForViewUpdate === "" ? false : true}
                                            onBlur={(e) => { comboOnChange("challan_no"); validateFields(); }} onChange={(e) => { setSupplierChallanNo(e.target.value); validateFields(); }} />
                                        <MDTypography variant="button" id="error_txt_supplier_challan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_supplier_challan_Date' className="erp_input_field" value={dt_supplier_challan_Date} disabled={keyForViewUpdate === "" ? false : true}
                                            onChange={e => { comboOnChange("challan_date"); validateFields(); }} />
                                        <MDTypography variant="button" id="error_dt_supplier_challan_Date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/* sec row */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Invoice No & Date</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_invoice_no" className="erp_input_field" value={txt_invoice_no} onChange={(e) => { setInvoice_no(e.target.value); validateFields(); }} optional='optional' />
                                        <MDTypography variant="button" id="error_txt_invoice_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                    <div className="col">
                                        <Form.Control type="date" id='dt_invoice_date' className="erp_input_field" value={dt_invoice_date} min={currentDate} onChange={e => { setInvoice_date(e.target.value); }} optional='optional' />
                                        <MDTypography variant="button" id="error_dt_invoice_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Gate Entry No & Date</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_gate_entry_no" className="erp_input_field" value={txt_gate_entry_no} onChange={(e) => { setGateEntryNo(e.target.value); validateFields(); }} optional='optional' />
                                        <MDTypography variant="button" id="error_txt_gate_entry_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                    <div className="col">
                                        <Form.Control type="date" id='dt_gate_entry_date' className="erp_input_field" value={dt_gate_entry_date} min={currentDate} onChange={e => { setGateEntryDate(e.target.value); }} optional='optional' />
                                        <MDTypography variant="button" id="error_dt_gate_entry_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className={`row `}>
                                    <div className='col-sm-3 col-12'>
                                        <Form.Label className="erp-form-label">Expected Branch <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { comboOnChange('ExpectedbranchId'); validateFields(); }}   >
                                            <option value="">Select</option>
                                            {/* <option value="0">Add New Record+</option> */}
                                            {expectedBranchList?.map(Expectedbranchid => (
                                                <option value={Expectedbranchid.company_branch_id} is_sez={`${Expectedbranchid.is_sez}`}>{Expectedbranchid.company_branch_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_exptBranch') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row d-none'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label"> Expected Branch State <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_expected_branch_state_id" className="form-select form-select-sm" value={cmb_expected_branch_state_id} optional='optional' disabled>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {stateList?.map(state => (
                                                <option value={state.field_id}>{state.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row d-none'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Expected Branch City <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_expected_branch_city_id" className="form-select form-select-sm" value={cmb_expected_branch_city_id} optional='optional' disabled >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {expectedBranchCityList?.map(city => (
                                                <option value={city.field_id}>{city.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                {/* <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Purchase Order life</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Closed" type="radio" lbl="Closed" value="C" name="rb_purchase_order_life" checked={rb_purchase_order_life === "C"} onClick={() => { setPurchaseOrderLife("C"); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="Open" type="radio" lbl="Open" value="O" name="rb_purchase_order_life" checked={rb_purchase_order_life === "O"} onClick={() => { setPurchaseOrderLife("O"); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>

                                        </div>
                                    </div>
                                </div>
 */}


                                {
                                    isApprove || (keyForViewUpdate === 'view' && cmb_goods_receipt_status !== '') ? <>
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">Apprv. By & Date <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-9 col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-8 ">
                                                        <select id="cmb_approved_by_id" className="form-select form-select-sm" value={cmb_approved_by_id} onChange={(e) => { setApprovedById(e.target.value); validateFields(); }} disabled >
                                                            <option value="">Select</option>
                                                            <option value="0">Add New Record+</option>
                                                            {employeeList?.map(employee => (
                                                                <option value={employee.field_id}>{employee.field_name}</option>
                                                            ))}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                        <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={(e) => { setApprovedDate(e.target.value); validateFields(); }} readOnly />
                                                        <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : null
                                }

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Qa by & Qa date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-8 ">
                                                <Select ref={qaByComboRef}
                                                    options={employeeList}
                                                    isDisabled={['view'].includes(keyForViewUpdate)}
                                                    inputId="cmb_qa_by_id" // Provide the ID for the input box
                                                    value={employeeList.find(option => option.value === cmb_qa_by_id) || null}
                                                    onChange={(selectedOpt) => {
                                                        setQabyId(selectedOpt.value);
                                                        qaByComboRef.current = selectedOpt;
                                                        validateFields();
                                                    }}
                                                    placeholder="Search for QA by..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_qa_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                <Form.Control type="date" id='dt_qa_date' className="erp_input_field" value={dt_qa_date} onChange={(e) => { setQadate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_dt_qa_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">GRN Amount Currency<span className="required">*</span></Form.Label>
                                    </div>

                                    <div className='col-sm-9 col-12'>
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Select ref={grnCurrencyComboRef}
                                                    options={currencyOptions}
                                                    isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                    inputId="cmb_currency_id"
                                                    value={currencyOptions.find(option => option.value === cmb_currency_id)}
                                                    onChange={(selectedOpt) => {
                                                        setCurrencyId(selectedOpt.value);
                                                        SetCurrency_symbol(selectedOpt.currency_symbol)
                                                        setCurrencyLabel(selectedOpt.label);
                                                        grnCurrencyComboRef.current = selectedOpt;
                                                    }}
                                                    placeholder="Search for a Currency..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select"
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_currency_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className='col-12 col-md-6 pt-md-0 pt-3 ps-md-1'>
                                                <Form.Control type="text" id='txt_currency_symbol' className="erp_input_field" value={txt_currency_symbol} optional='optional' disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Lot Number</Form.Label>
                                    </div>
                                    <div className="col-sm-9">
                                        <Form.Control type="text" id="lastGeneratedBatchNo" className="erp_input_field" value={lastGeneratedBatchNo} onChange={(e) => setLastGeneratedBatchNo(e.target.value)} disabled />
                                    </div>
                                </div>


                                <div className="row mt-1">
                                    <Accordion defaultActiveKey="1" >
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="erp-form-label-md">Supplier's Contact Details</Accordion.Header>
                                            <Accordion.Body >
                                                {
                                                    cmb_supplier_id !== '' && cmb_supplier_id !== "0"
                                                        ? <>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>
                                                                <div className={` d-flex justify-content-end align-items-center`}>
                                                                    <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                        localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: cmb_supplier_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                    }
                                                                    }>  <Link className={'text-light'} to={{ pathname: "/Masters/FrmSupplier" }} target="_blank" >Add</Link></MDButton>
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetsuppContactPersonsDetails(cmb_supplier_id) }}>Refresh</MDButton>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {SuppContactDetails.length !== 0 ?
                                                    <>
                                                        {/* <Datatable data={SuppContactDetails} columns={SuppContactDetailsCols} /> */}
                                                        {renderSupplierContactTable}
                                                    </>
                                                    : <div className='row'>
                                                        <div className="col-12">
                                                            <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                                No Records Found...
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {/* <div className="erp_table_scroll">
                       
                                                 </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion >
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
                <hr />
                <Accordion defaultActiveKey="0" activeKey={openGRNDetailsAccord ? '0' : null}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenGRNDetailsAccord(!openGRNDetailsAccord)}>Good Receipt Details </Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex row mb-2">
                                <div className="col-md-4 col-12">
                                    <Form.Group className={` ${keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ? 'd-none' : 'display'}`}>
                                        <InputGroup className="mt-1">
                                            <Typeahead
                                                className="custom-typeahead-class"
                                                id="serch-input-id"
                                                labelKey={(option) => `${option.name}`}
                                                placeholder="Search item..."
                                                onChange={(selected) => {
                                                    if (selected.length > 0) {

                                                        const selectedItem = selected[0];
                                                        selectMaterial(selectedItem.product_material_id); // Example: Assuming product_id is the unique identifier
                                                    }
                                                }}
                                                onInputChange={(input) => {
                                                    FnSearchOnChange(input)
                                                }}
                                                options={searchOptions || []}
                                                filterBy={() => true}
                                                minLength={2}
                                                ref={typeaheadRef}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-12"> </div>
                                <div className="col-md-2 col-12">
                                    <MDButton type="button" id="export-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== "view" || keyForViewUpdate !== "delete" ? "display" : "d-none"} `} variant="button" fontWeight="regular" onClick={ExportToExcel}>Export</MDButton>
                                    <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                    <MDButton type="button" id="import-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={onImportClick} >Import</MDButton>
                                </div>
                            </div>
    
                                {grnDetailsData.length !== 0 ? (
                                    renderGRNDetailsTbl
                                ) : (
                                    <div className='row text-center'>
                                    <div className="col-12">
                                        <span className="erp_validation text-center" fontWeight="regular" color="error">
                                            No Records Found...!
                                        </span>
                                    </div>
                                </div>
                                )}

                            {grnTaxSummaryData.length !== 0 ?
                                <>
                                    <hr />
                                    {memoizedTaxSummaryComponent} </> :
                                null
                            }


                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className="erp-form-label-md">Quality Parameters</Accordion.Header>
                                    <Accordion.Body>
                                        {qualityTestingDetails.length !== 0 ? (
                                            renderQualityTestingTable
                                        ) : (
                                            <div className='row'>
                                                <div className="col-12  text-center">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>


                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <hr />
                {/* Indent Material Details Data */}
                {/* <Accordion defaultActiveKey="1" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Indent Details</Accordion.Header>
                        <Accordion.Body>
                            {indentMaterialDetailsData.length !== 0 ? (
                                renderIndentDetailsTable
                            ) : (
                                <div className='row'>
                                    <div className="col-12  text-center">
                                        <span className="erp_validation text-center" fontWeight="regular" color="error">
                                            No Records Found...
                                        </span>
                                    </div>
                                </div>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> */}
                {/* Payment terms details */}
                <hr />
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Payment Terms</Accordion.Header>
                        <Accordion.Body>
                            {paymentTermsData.length !== 0 ? (
                                renderPaymentTermsTable
                            ) : (
                                <div className='row'>
                                    <div className="col-12  text-center">
                                        <span className="erp_validation text-center" fontWeight="regular" color="error">
                                            No Records Found...
                                        </span>
                                    </div>
                                </div>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* {keyForViewUpdate !== '' ? */}
                    <> <hr />
                        <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                            <Accordion.Item eventKey="documentList">
                                <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                <Accordion.Body>
                                    <div className={`table-responsive ${docData.length > 4 ? 'erp_table_scroll' : ''}`}>
                                        {docData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (null)}
                                    </div>
                                    {docData.length === 0
                                        ? <div className='row'>
                                            <div className="col-12 text-center">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                    No Records Found...
                                                </span>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                    {/* : null
                } */}
                <hr />
                <div className="card">
                    <div className="card-header main_heding text-start">
                        <label className='erp-form-label-lg'>Good Receipt Note Totals</label>
                    </div>
                    <div className="card-body">
                        <form id="GRNFooterFormId">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                    {/* first column */}
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Basic Total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_basic_total" className="erp_input_field text-end" value={txt_basic_total} onChange={(e) => setBasicTotal(e.target.value)} optional='optional' disabled />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Transport Amount</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_transport_amount" className="erp_input_field text-end" value={txt_transport_amount} onChange={(e) => { setTransportAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(grnDetailsData, grnTaxSummaryData) }} optional='optional' />
                                            <MDTypography variant="button" id="error_transport_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Freight Percent & Amount</Form.Label>
                                        </div>
                                        {/* <div className="col"> */}
                                        {/* <div className="row"> */}

                                        <div className="col">
                                            <Form.Control type="number" id="txt_Freight_percent" className="erp_input_field text-end" value={txt_Freight_percent}
                                                onChange={(e) => { if (e.target.value === '') { setFreightPercent(e.target.value); } if (validateNumberDateInput.current.percentValidate(e.target.value)) { handleFooterFreightPercentChange(e.target.value); } }} />
                                            <MDTypography variant="button" id="error_txt_po_packing_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount}
                                                onChange={(e) => { setFreightAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); handleFooterFreightAmountChange(e.target.value); }}
                                                optional='optional'
                                            />
                                            <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label"></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Check className="erp-form-label" type="checkbox" label={`IsTaxable`} id="chk_freight_isTaxable" checked={chk_freight_isTaxable} onChange={(e) => { setFreightIsTaxable(e.target.checked); calculateTaxationSummary(grnDetailsData); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} />
                                        </div>
                                        {/* </div> */}
                                        <div className="col">
                                            <select id="cmb_freight_hsn_id" className="form-select form-select-sm" value={cmb_freight_hsn_id}
                                                onChange={(e) => {
                                                    setFreightHSNId(e.target.value);
                                                    calculateTaxationSummary(grnDetailsData);
                                                }}
                                                disabled={!chk_freight_isTaxable || keyForViewUpdate === 'view' || isApprove} optional='optional'>
                                                <option value="" disabled>Select</option>
                                                {freightHSNList?.map(hsnCode => (
                                                    <option value={hsnCode.hsn_sac_id} hsn_rate={hsnCode.hsn_sac_rate} lbl={hsnCode.hsn_sac_code}>{hsnCode.hsn_sac_code}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_freight_hsn_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    {/* </div> */}

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Insurance Percent & Amount</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="number" id="txt_packing_percent" className="erp_input_field text-end" value={txt_packing_percent}
                                                onChange={(e) => { if (e.target.value === '') { setPackingPercent(e.target.value); } if (validateNumberDateInput.current.percentValidate(e.target.value)) { handleFooterPackingPercentChange(e.target.value); } }} />
                                            <MDTypography variant="button" id="error_txt_packing_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_packing_amount" className="erp_input_field text-end" value={txt_packing_amount} onChange={(e) => { setPackingAmount(e.target.value); handleFooterPackingAmountChange(e.target.value); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Discount Percent & Amount</Form.Label>
                                        </div>
                                        {/* <div className='col'>
                      <Form.Control type="number" id="txt_goods_receipt_discount_percent" className="erp_input_field text-end" value={txt_goods_receipt_discount_percent} onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setGoodsReceiptDiscountPercent(e.target.value); FnFooterPercentChange(e) } }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_goods_receipt_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div> */}
                                        <div className='col'>
                                            <Form.Control type="number" id="txt_goods_receipt_discount_percent" className="erp_input_field text-end" value={txt_goods_receipt_discount_percent}
                                                onChange={(e) => { if (e.target.value === '') { setGoodsReceiptDiscountPercent(e.target.value); } if (validateNumberDateInput.current.percentValidate(e.target.value)) { FnFooterPercentChange(e) } }} />
                                            <MDTypography variant="button" id="error_txt_goods_receipt_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_goods_receipt_discount_amount' className="erp_input_field text-end" value={txt_goods_receipt_discount_amount}
                                                onChange={(e) => { { setGoodsReceiptDiscountAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)); { handleFooterDiscountAmountChange(e); } } }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_goods_receipt_discount_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Other Amount</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_other_amount" className="erp_input_field text-end" value={txt_other_amount}
                                                onChange={(e) => { setOtherAmount(e.target.value); calculateGrandTotal(grnDetailsData, grnTaxSummaryData) }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_other_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Taxable Total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_taxable_total" className="erp_input_field text-end" value={txt_taxable_total} onChange={(e) => setTaxableTotal(e.target.value)} optional='optional' disabled />
                                            <MDTypography variant="button" id="error_txt_taxable_total" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">CGST Total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_cgst_total" className="erp_input_field text-end" value={txt_cgst_total} onChange={(e) => { setCgstTotal(e.target.value); }} optional='optional' disabled />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">SGST total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_sgst_total" className="erp_input_field text-end" value={txt_sgst_total} onChange={(e) => setSgstTotal(e.target.value)} optional='optional' disabled />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">IGST Total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_igst_total" className="erp_input_field text-end" value={txt_igst_total} onChange={(e) => setIgstTotal(e.target.value)} optional='optional' disabled />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Total & Grand RoundOff<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_grand_total" className="erp_input_field text-end" value={txt_grand_total} optional='optional' disabled />
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_roundOff" className="erp_input_field text-end" value={txt_roundOff} optional='optional' disabled />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Diff & Supplier Invoice Amt.<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_diff" className="erp_input_field text-end" value={txt_diff} optional='optional' disabled />
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_supplier_invoice_amount" className="erp_input_field text-end" value={txt_supplier_invoice_amount} onChange={(e) => { setSupplierInvoiceAmount(e.target.value); setDiff(txt_grand_total - e.target.value) }} optional='optional' />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Agent & Paid Status</Form.Label>
                                        </div>
                                        <div className='col-sm-4'>
                                            <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); comboOnChange('agent'); }} optional='optional'>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {agentsList?.map(cmb_agentId => (
                                                    <option value={cmb_agentId.field_id}>{cmb_agentId.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_agent_paid_status" className="form-select form-select-sm" value={cmb_agent_paid_status} onChange={(e) => { setAgentPaidStatus(e.target.value); validateFields(); }} optional='optional' >
                                                <option value="">Select</option>
                                                <option value="P">Pending</option>
                                                <option value="A">Aprroved</option>
                                                <option value="C">Completed</option>
                                                <option value="X">Canceled</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_agent_paid_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_agent') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Agent Percent & Amount</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="number" id="txt_agent_percent" className="erp_input_field" value={txt_agent_percent} onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setAgentPercent(e.target.value) }; }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_agent_amount' className="erp_input_field text-end" value={txt_agent_amount}
                                                onChange={(e) => { { setAgentAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)); { handleFooterAgentAmountChange(e); } } }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_agent_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Goods Receipt Status</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_goods_receipt_status" className="form-select form-select-sm" value={cmb_goods_receipt_status} onChange={(e) => { setGoodsReceiptStatus(e.target.value); comboOnChange('GoodReceiptStatus') }} optional='optional' disabled={isApprove ? false : true}>
                                                {/* disabled={isApprove ? false : true}  */}
                                                <option value="">Select</option>
                                                <option value="G">GRN Done</option>
                                                <option value="Q">QC Done</option>
                                                <option value="R">Rejected</option>
                                                <option value="I">Partial Receipt</option>
                                                <option value="C">Completed</option>
                                                <option value="X">Canceled</option>
                                                <option value="B">Bill Booked</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_goods_receipt_status" className='erp_validation' fontWeight="regular" color="error" style={{ display: 'none' }}>
                                                Please select good receipt status!..
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                    <div className='row mb-1'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value); validateFields(); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">LR No & Date</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_lr_no" className="erp_input_field" value={txt_lr_no} onChange={(e) => { setlrNo(e.target.value); validateFields(); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_lr_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                        <div className="col-sm-4">
                                            <Form.Control type="date" id='dt_lr_date' className="erp_input_field" value={dt_lr_date} onChange={e => { setlrDate(e.target.value); }} optional='optional' />
                                            <MDTypography variant="button" id="error_dt_lr_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">E-Way Bill no & Date</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_ev_bill_no" className="erp_input_field" value={txt_ev_bill_no} onChange={(e) => { setEvBillNo(e.target.value); validateFields(); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_ev_bill_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                        <div className="col-sm-4">
                                            <Form.Control type="date" id='dt_ev_bill_date' className="erp_input_field" value={dt_ev_bill_date} onChange={e => { setEv_bill_date(e.target.value); }} optional='optional' />
                                            <MDTypography variant="button" id="error_dt_ev_bill_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Vehicle no</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_vehicle_no" className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Other terms and Conditions</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_other_terms_conditions" className="erp_input_field" value={txt_other_terms_conditions} optional='optional' onChange={(e) => { setOtherTermsConditions(e.target.value); }} />
                                            <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">PreeClosed</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="is_pree_closed" checked={is_pree_closed === true} onClick={() => { setIsPreeClosed(true); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="is_pree_closed" checked={is_pree_closed === false} onClick={() => { setIsPreeClosed(false); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Active</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="is_active" checked={is_active === true} onClick={() => { setIsActive(true); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="is_active" checked={is_active === false} onClick={() => { setIsActive(false); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                <div className="text-center">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular"
                        onClick={() => {
                            navigator('/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteCottonBalesListing');
                        }}
                    >Back</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`} disabled={isLoading} variant="button" onClick={() => createGoodReceiptNote(!isApprove ? 'Add' : 'A')}
                        fontWeight="regular">{actionLabel}</MDButton>
                    <MDButton type="button" id="clear-btn-id" className={`erp-gb-button erp_MLeft_btn  ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" onClick={FnClearForm} fontWeight="regular">Clear</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${isApprove ? 'display' : 'd-none'}`}
                        variant="button" onClick={() => createGoodReceiptNote('R')} fontWeight="regular">Reject</MDButton>
                    <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' || keyForViewUpdate === '' ? 'display' : 'd-none'}`} onClick={() => setShowDocumentForm(true)} variant="button" fontWeight="regular" >Upload Document</MDButton>&nbsp;


                    <MDButton className={`erp-gb-button erp_MLeft_btn ${(keyForViewUpdate === 'view' && cmb_goods_receipt_status !== '') || keyForViewUpdate === 'view' && is_pree_closed !== false ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printInvoice(true, false)}>Print &nbsp;<FiPrinter className="erp-download-icon-btn" />
                    </MDButton>

                    <MDButton className={`erp-gb-button erp_MLeft_btn  ${keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" id='printPreview-btn-id' onClick={() => { printPreview() }} >Print Preview</MDButton>

                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>
                </div >

                {/* Add new Record Popup */}
                {showAddRecModal ?
                    <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                        <Modal.Header>
                            <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
                        </Modal.Header>
                        <Modal.Body className='erp_city_modal_body'>
                            {displayRecordComponent()}
                        </Modal.Body>
                        <Modal.Footer>
                            <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                                fontWeight="regular">Close</MDButton>

                        </Modal.Footer>
                    </ Modal> : null
                }
                {memorizedDocumentComponent}
                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='text-center'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Do you wish to delete this record ?</h6>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteGrn}>Delete</Button>
                    </Modal.Footer>
                </Modal>
                {/* Print preview Modal */}
                <Modal size="lg" show={showPrintPreviewIsOpen} onHide={handleClosePrintPreview} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Goods Receipt Notes Preview</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleClosePrintPreview}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: printPreviewContent }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleClosePrintPreview}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Modal for Indent Details */}
                {/* <Modal size="lg" className='erp_document_Form' show={showSChedules} onHide={handleCloseSchedulesPopup} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Indent Materials</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSchedulesPopup}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <dl className="row mb-0">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-3 erp-form-label-md-lg">Material Name:</dt>
                                    <dd className="col-sm-12 col-md-9 erp-form-label" id='productMatName'></dd>
                                </dl>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">GRN Accepted Quantity:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatQty'></dd>
                                </dl>
                            </div>
                        </dl>
                        <dl className="row mb-0">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-6 col-md-3 erp-form-label-md-lg"> Material Unit:</dt>
                                    <dd className="col-sm-6 col-md-9 erp-form-label" id='productMatUnit'></dd>  </dl>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">GRN Accepted Weight:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatTotalWeight'></dd>
                                </dl>
                            </div>
                        </dl>
                        <dl className="row mb-0">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-md-3 col-sm-12 erp-form-label-md-lg">Std. Weight:</dt>
                                    <dd className="col-md-9 col-sm-12  erp-form-label" id='productMatStdWeight'></dd>
                                </dl>
                            </div>
                        </dl>
                        <div className='row table-responsive'>
                            {indentSummaryDetails.length ?
                                (renderIndentSummaryDetailsTable) :
                                (
                                    <div className='row'>
                                        <div className="col-12  text-center">
                                            <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                No Records Found...
                                            </span>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='erp-modal-footer'>
                        <MDButton type="button" className="erp-gb-button" variant="button"
                            fontWeight="regular" onClick={() => updateIndentDetails()}>Add</MDButton>
                    </Modal.Footer>
                </Modal> */}
                {/* Generic Response Messaage Modals */}
                {/* <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} /> */}
                {showErrorMsgModal ?
                    <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
                    : null
                }
            </DashboardLayout >

            {/* Send Email On Approval Modal */}
            {showConfirmationModal ?
                <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
                : null
            }
        </>
    )
}

export default FrmGoodsReceiptNoteCottonBalesEntry
