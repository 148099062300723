import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import DocumentF from "Features/Document";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import { FiDownload, FiPrinter } from "react-icons/fi";
import { MdDelete, MdRefresh } from "react-icons/md";
import ConfigConstants from "assets/Constants/config-constant";
import { CircularProgress } from "@material-ui/core";
import InfoModal from "components/Modals/InfoModal";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import MailSentModal from "components/Modals/MailSentModal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { AiOutlineSchedule } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";
import GatePassSlip from "FrmGeneric/Invoice/GatePassSlip";
import { renderToString } from "react-dom/server";
import { whitespace } from "stylis";
import SizedYarnDispatch from "FrmGeneric/Invoice/SizedYarnDispatch"
import Select from 'react-select';


export default function TSizedYarnDispatchNoteEntry() {
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];

    // Select box
    let expanded = false;
    let docGroup = "Dispatch Challan Sized Yarn"

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME, UserId } = configConstants;

    const { state } = useLocation()
    //changes by tushar
    const { idList, keyForViewUpdate, compType = '', deletionKey = false, requestfor = "SB", modules_forms_id } = state || {};

    // UseRefs
    const validate = useRef();
    const comboDataFunc = useRef();
    const generateAutoNoAPiCall = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();

    const [isApprove, setIsApprove] = useState(false);
    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')
    const [isLoading, setIsLoading] = useState(false);


    // Product Type Properties Table Data
    const [productTypePropertiesData, setProductTypePropertiesData] = useState([]);
    const [storedProductTypePropertiesData, setStoredProductTypePropertiesData] = useState([]);
    const [dispatch_challan_version, setDispatchVersion] = useState(idList !== null ? idList.dispatch_challan_version : 1)
    const [txt_dispatch_challan_no, setDispatchChallanNo] = useState(idList !== null ? idList.dispatch_challan_no : '')
    const [dispatch_challan_sized_yarn_id, setdispatchscheduledetailstransactionId] = useState(idList !== null ? idList.dispatch_challan_sized_yarn_id : 0);
    const [sizedYarnMasterData, setSizedYarnMasterData] = useState([]);
    const [checkedDetailsDataForTotal, setCheckedDetailsDataForTotal] = useState([])
    // Form Fields
    const [checkedCustomerOrderNoLength, setCheckedCustomerOrderNoLength] = useState(0)

    const [cmb_customer_state_id, setCustomerState] = useState('');
    const [cmb_customer_city_id, setCustomerCity] = useState('');
    const [rb_dispatch_order_creation_type, setDispOrdCreationType] = useState("S");
    const [txt_remark, setRemark] = useState('');
    const [txt_yarn_count, setYarnCount] = useState('');
    const [txt_rate, setRate] = useState(0);
    const [txt_weight, setWeight] = useState(0);

    const [txt_dispatch_date, setDispatchDate] = useState(today);
    const [dt_approve_date, setApproveDate] = useState(today());
    const [txt_customer_order_no, setCustomerOrderNo] = useState('')
    const [cmb_agent_id, setAgentId] = useState('')
    const [txt_yarn_bill_no, setYarnBillNo] = useState();

    //Form Fields
    const [cmb_dispatch_challan_type, setDispatchChallanType] = useState('');
    const [cmb_job_type, setJobType] = useState('');
    let job_type_ref = useRef(null);
    let sales_dispatch_type_ref = useRef(null);
    let dispatch_tax_type_ref = useRef(null);
    let dispatch_sales_type_ref = useRef(null);
    let dispatch_voucher_type_ref = useRef(null);
    const [cmb_dispatch_type, setDispatchType] = useState(1024);

    const [cmb_dispatch_by_id, setDispatchById] = useState('');
    const [txt_customer, setCustomerName] = useState('');
    const [txt_customer_id, setCustomerId] = useState('');
    const [txt_customer_contacts_ids, setCustomerContactsId] = useState('');
    const [cmb_set_no, setSetNo] = useState('')

    // gate pass fields
    const [dt_gate_pass_date, setGatePassDate] = useState(requestfor === 'YN' ? currentDate : '');
    const [txt_vehicle_no, setVehicleNo] = useState("");
    const [txt_driver_name, setDriverName] = useState("");

    // consignee details form fields
    const [cmb_consignee_id, setConsigneeId] = useState('');
    const [cmb_consignee_state_id, setConsigneeState] = useState('');
    const [cmb_consignee_city_id, setConsigneeCity] = useState('');
    const [txt_other_terms_conditions, setOtherCondition] = useState('');

    // Combo box arrays
    const [stateOpts, setStateOpts] = useState([]);
    const [setNoOptions, setSetNoOptions] = useState([]);
    const [jobTypeOptions, setJobTypeArr] = useState([]);

    const [consigneeList, setConsigneeList] = useState([]);
    const [cmb_dispatch_note_status, setDispatchNoteStatus] = useState('P');
    const [customerList, setCustomerList] = useState([]);
    const [customerCityOpts, setCustomerCityOpts] = useState([]);
    const [consigneeCityOpts, setConsigneeCityOpts] = useState([]);
    const [approveByOpts, setApproved] = useState([]);

    const [cmb_supervisorList, setSupervisorList] = useState([])
    const [dispatchScheduleTypeList, setDispatchScheduleTypeList] = useState([])
    const [PackingDetailsList, setPackingDetailsList] = useState([])
    const [dispatchTypeList, setDispatchTypeList] = useState([])

    const [agentOptions, setAgentOptions] = useState([])
    const [dispatchDetailsData, setDispatchDetailsData] = useState([]);

    // For Uploaded Document Table
    const [uploadedDocData, setUploadedDocData] = useState([]);


    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')
    const [salesDispatchTypeOpt, setSaleDispatchTypeOpt] = useState([])
    const [dispatchHSNTaxTypeOpt, setDispatchHSNTaxTypeOpt] = useState([])
    const [dispatchSalesTypeOpt, setDispatchSalesTypeOpt] = useState([])
    const [dispatchVoucherTypeOpt, setDispatchVoucherTypeOpt] = useState([])
    const [cmb_sales_dispatch_type, setSalesDispatchType] = useState('');
    const [cmb_dispatch_hsnTax_type, setDispatchHsnTaxType] = useState('');
    const [cmb_dispatch_sales_type, setDispatchSalesType] = useState('');
    const [cmb_dispatch_voucher_type, setDispatchVoucherType] = useState('');

    // Info Modal
    const [showInfoMsgModal, setShowInfoMsgModal] = useState(false);
    const [infoMsg, setInfoMsg] = useState('');
    const handleCloseInfoModal = () => setShowInfoMsgModal(false);
    let dispatchByComboRef = useRef(null);
    let customerComboRef = useRef(null);
    let consigneeComboRef = useRef(null);
    let setNoComboRef = useRef(null);
    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => {
        setShowDeleteModal(false)
        // moveToListing();
    };

    // For confirmation Modal.
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const FnCloseConfirmationModal = () => {
        setShowConfirmationModal(false)
        moveToListing();
    };

    //Show another component in modal
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'approve':
                setActionType('(Approval)');
                setActionLabel('Approve')
                // setIsApprove(true)
                break;
            case 'update':
                $('#txt_dispatch_date').prop('disabled', true);
                $('#txt_customer').prop('disabled', true);
                setActionType('(Modification)');
                setActionLabel('Update')
                break;
            case 'view':
                if (deletionKey === true) {
                    setActionType('(Deletion)');
                } else {
                    setActionType('(View)');
                }
                await validate.current.readOnly("dispatchnoteId");
                // await validate.current.readOnly("GatePassFrmId");
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    //key for get Request type header
    //changes by tushar
    const requestTypeHeading = () => {
        switch (requestfor) {
            case 'YN':
                return '(Cotton Yarn)';
            case 'RC':
                return '(Raw Cotton)';
            case 'BL':
                return '(Cotton Bales)';
            case 'GF':
                return '(Greige Fabric)';
            case 'FF':
                return '(Finish Fabric)';
            case 'PRM':
                return '(Raw Material)';
            default:
                return '';
        }
    }


    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Customer City':
                comboOnChange('CustomerState');
                break;
            case 'Consignee City':
                comboOnChange('ConsigneeState');
                break;
            case 'Dispatch Schedule Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                const product_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                setDispatchScheduleTypeList(product_Types)
                break;
            case 'Dispatch Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("property_value");
                globalQuery.table = "amv_properties"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "Dispatch Type" });
                const dispatch_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                setDispatchTypeList(dispatch_Types)
                break;
            default:
                break;

        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }

    // For navigate
    const navigate = useNavigate();

    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const FnCloseDocumentUploadModal = async () => {
        setShowDocumentForm(false)
        await showDocumentRecords();
    };
    const viewDocumentForm = () => setShowDocumentForm(true);
    const colNames =
        ['sr_no', "customer_order_no", "product_material_print_name", "product_material_tech_spect",
            "batch_no", "so_sr_no", "material_schedule_date", "material_quantity",
            "material_weight", "dispatched_quantity", "dispatched_weight", "stock_quantity",
            "stock_weight", "expected_dispatch_quantity", "expected_dispatch_weight", "actual_dispatch_quantity",
            "actual_dispatch_weight", "product_material_our_part_code", "product_material_unit_id",
            "product_material_packing_id", "dispatch_return_quantity", "dispatch_return_weight",
            "pending_quantity", "pending_weight", "delayed_days",
            "invoice_quantity", "invoice_weight", "dispatch_schedule_item_status", "actual_count"];

    // Use colNames in your component as needed

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    }

    useEffect(() => {
        setIsLoading(true);
        const loadDataOnload = async () => {
            ActionType()
            await fillComobos();
            if (idList !== null) {
                await FnCheckUpdateResponce();
            }
            setIsLoading(false);
        }
        loadDataOnload()

        // Close customer order no dropdown
        $(document).on('mouseup', function (e) {
            var container = $("#customer-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [requestfor])


    const fillComobos = async () => {
        try {

            resetGlobalQuery();
            globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
            globalQuery.table = "am_properties"
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'Sales Type' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let getJObType = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
            setJobTypeArr(getJObType);

            resetGlobalQuery();
            globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
            globalQuery.table = "am_properties"
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'salesAndDispatch' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let getSaleDispatchAPICall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
            setSaleDispatchTypeOpt(getSaleDispatchAPICall);

            // resetGlobalQuery();
            // globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
            // globalQuery.table = "am_properties"
            // globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchHSNTaxType' });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // let getSaleDispatchTaxAPICall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
            // setDispatchHSNTaxTypeOpt(getSaleDispatchTaxAPICall);

            // resetGlobalQuery();
            // globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
            // globalQuery.table = "am_properties"
            // globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchSalesType' });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // let getDispatchSalesAPICall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
            // setDispatchSalesTypeOpt(getDispatchSalesAPICall);

            // resetGlobalQuery();
            // globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group', 'remark'];
            // globalQuery.table = "am_properties"
            // globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchVoucherType' });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // let getDispatchVoucherAPICall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
            // setDispatchVoucherTypeOpt(getDispatchVoucherAPICall);

            resetGlobalQuery();
            globalQuery.columns.push("DISTINCT(set_no)");
            globalQuery.columns.push('customer_order_no');
            globalQuery.table = "xt_sizing_production_stock_details";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "sized_beam_status", operator: "=", value: 'A' });
            globalQuery.orderBy = ['set_no DESC'];
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(setNoOpts => {
                    const supervisorList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        ...setNoOpts.map((opt) => ({ ...opt, value: opt.set_no, label: `${opt.set_no} (${opt.customer_order_no})` })),
                    ]
                    setSetNoOptions(supervisorList);
                });

            resetGlobalQuery();
            globalQuery.columns.push("agent_id", "agent_name");
            globalQuery.table = "cm_agent";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(setNoOpts => {
                    setAgentOptions(setNoOpts);
                });



            resetGlobalQuery();
            globalQuery.columns.push("user_id as field_id");
            globalQuery.columns.push("user_name as field_name");
            globalQuery.table = "amv_modules_forms_user_access";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: modules_forms_id });
            globalQuery.conditions.push({ field: "approve_access", operator: "=", value: 1 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getApproveEmpAccessList => {
                    setApproved(getApproveEmpAccessList);
                    console.log("Approve Access Emp List: ", getApproveEmpAccessList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'state_code'];
            globalQuery.table = "cmv_state"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(stateList => {
                    setStateOpts(stateList);
                });

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", 'product_type_short_name'];
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_short_name", operator: "=", value: requestfor });
            const getProductType = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            setDispatchScheduleTypeList(getProductType);
            console.log("Product Type List: ", getProductType);
            if (getProductType.length !== 0) {
                const productType = getProductType.find((item) => item.product_type_short_name === requestfor);
                if (productType) {
                    setDispatchChallanType(productType.field_id);
                }
            }
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("property_value");
            globalQuery.table = "amv_properties"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "Dispatch Type" });
            const dispatch_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            setDispatchTypeList(dispatch_Types)
            if (dispatch_Types.length !== 0) {
                const dispatchType = dispatch_Types.find((item) => item.property_value === requestfor);
                if (dispatchType) {
                    setDispatchType(dispatchType.field_id)
                }
            }

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_gst_no", 'cust_branch_type']
            globalQuery.table = "cmv_customer_summary"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });


            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(custOpts => {
                    // Build the full customer list
                    const customerList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        ...custOpts.map((customer) => ({
                            ...customer,
                            value: customer.field_id,
                            label: customer.field_name
                        })),
                    ];

                    // Set both lists in state
                    setCustomerList(customerList);
                    setConsigneeList(customerList);
                });


            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
            globalQuery.table = "cmv_employee"
            // globalQuery.conditions.push({ field: "department_id", operator: "=", value: '2' });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                // .then(supervisorsOpts => {
                //     setSupervisorList(supervisorsOpts);
                // });

                .then(supervisorsOpts => {
                    const supervisorList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        // { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                        ...supervisorsOpts.map((supervisor) => ({ ...supervisor, value: supervisor.field_id, label: supervisor.field_name })),
                    ];

                    setSupervisorList(supervisorList);

                });

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnCheckUpdateResponce = async () => {
        try {
            //UPDATE
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/DispatchSizedYarn/FnShowParticularRecordForUpdate/${idList.dispatch_challan_sized_yarn_id}/${parseInt(COMPANY_ID)}`)
            const response = await apiCall.json();
            console.log("DispatchSizedYarnData", response)
            let { SizedYarnMasterData, SizedYarnDetailsData } = response
            if (response !== null) {
                setSizedYarnMasterData(response.SizedYarnMasterData)
                setDispatchChallanNo(SizedYarnMasterData.dispatch_challan_no)
                setDispatchChallanType(SizedYarnMasterData.dispatch_challan_type_id);
                setDispatchDate(SizedYarnMasterData.dispatch_challan_date);
                setDispatchNoteStatus(keyForViewUpdate === "approve" ? "D" : "P");
                setAgentId(SizedYarnMasterData.agent_id);
                setConsigneeId(SizedYarnMasterData.consignee_id);
                setJobType(SizedYarnMasterData.job_type_id);
                setConsigneeState(SizedYarnMasterData.consignee_state_id)
                await comboOnChange("ConsigneeState");
                setConsigneeCity(SizedYarnMasterData.consignee_city_id)
                await comboOnChange('ConsigneeCity')
                setCustomerName(SizedYarnMasterData.customer_name)
                setCustomerOrderNo(SizedYarnMasterData.customer_order_no);
                setCustomerContactsId(SizedYarnMasterData.customer_contacts_ids)
                setCustomerId(SizedYarnMasterData.customer_id);
                setCustomerState(SizedYarnMasterData.customer_state_id)
                await comboOnChange("CustomerState")
                setCustomerCity(SizedYarnMasterData.customer_city_id)
                await comboOnChange("CustomerCity");
                if (SizedYarnMasterData.dispatch_challan_status === 'D') {
                    setDispatchById(SizedYarnMasterData.approved_by_id);
                } else {
                    setDispatchById(parseInt(UserId))
                }
                setSetNo(SizedYarnMasterData.set_no)
                setDispatchVersion(SizedYarnMasterData.dispatch_challan_version)
          
                setVehicleNo(SizedYarnMasterData.vehicle_no)
                setYarnCount(SizedYarnMasterData.yarn_count)
                setWeight(SizedYarnMasterData.weight)
                setRate(SizedYarnMasterData.rate)
                setYarnBillNo(SizedYarnMasterData.yarn_bill_no)
                setDriverName(SizedYarnMasterData.driver_name)
                setOtherCondition(SizedYarnMasterData.other_terms_conditions)
                setRemark(SizedYarnMasterData.remark)
                setDispatchDetailsData(SizedYarnDetailsData)
                setCheckedDetailsDataForTotal(SizedYarnDetailsData)

                sales_dispatch_type_ref.current = { property_group: SizedYarnMasterData.sales_dispatch_type };
                setSalesDispatchType(SizedYarnMasterData.sales_dispatch_type)
                setDispatchHsnTaxType(SizedYarnMasterData.dispatch_hsnTax_type)
                setDispatchSalesType(SizedYarnMasterData.dispatch_sales_type)
                setDispatchVoucherType(SizedYarnMasterData.dispatch_voucher_type)
                await comboOnChange("salesDispatchType");

                if (['approve', 'view'].includes(keyForViewUpdate)) {
                    $(".hide_it").hide();
                }
                if (keyForViewUpdate === 'view' && SizedYarnMasterData.dispatch_challan_status === 'D') {
                    $("#printBtnId").show();
                } else {
                    $("#printBtnId").hide();
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const comboOnChange = async (key) => {
        debugger
        switch (key) {

            case 'jobType':
                // setIsLoading(true);
                // debugger


                // const currentYear = parseInt(FINANCIAL_SHORT_NAME);
                // const nextyear = currentYear + 1;

                // const shortNextYear = nextyear.toString().slice(-2);
                // const shortCurrentYear = currentYear.toString().slice(-2);

                // const formattedYears = `${shortCurrentYear}-${shortNextYear}`;


                // let shortname = job_type_ref.current['property_group'];
                // let job_type_id = parseInt(job_type_ref.current['property_id']);
                // const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/DispatchSizedYarn/FnGenerateSizedYarnChallanNo/${parseInt(COMPANY_ID)}/${job_type_id}`);
                // let response = await apiCall.text();
                // let formattedCounter;
                // if (!response || response.trim() === "") {
                //     // If response is empty or null, start from "00001"
                //     formattedCounter = `${shortname}${"1".padStart(5, "0")}`;
                // } else {
                //     // Extract and increment the numeric part
                //     response = parseInt(response.split("/")[3].replace(/\D/g, ""), 10);
                //     formattedCounter = `${shortname}${(response + 1).toString().padStart(5, "0")}`;
                // }

                // // Set the Dispatch Challan Number
                // setDispatchChallanNo(`PTEL/${formattedYears}/SB/${formattedCounter}`);


                // setIsLoading(false);
                break;

            case 'cmb_set_no':
                setIsLoading(true)
                let setNo = setNoComboRef.current
                console.log(setNo)
                //GET
                try {
                    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/DispatchSizedYarn/FnGetSizedYarnData/${COMPANY_ID}/${setNo}`)
                    const responce = await apiCall.json();
                    console.log(' Sized Yarn Details : ', responce);

                    setSizedYarnMasterData(responce.SizedYarnMasterData)
                    let masterData = responce.SizedYarnMasterData;
                    let SizedYarnDetailsData = responce.SizedYarnDetailsData;
                    setCustomerName(SizedYarnDetailsData[0]['customer_name'])
                    setCustomerId(masterData.customer_id)
                    setCustomerOrderNo(masterData.customer_order_no)
                    setCustomerState(masterData.customer_state_id)
                    await comboOnChange("CustomerState")
                    setCustomerCity(masterData.customer_city_id)
                    await comboOnChange("CustomerCity");

                    setConsigneeId(masterData.consignee_id)
                    setConsigneeState(masterData.consignee_state_id)
                    await comboOnChange("ConsigneeState");
                    setConsigneeCity(masterData.consignee_city_id)
                    await comboOnChange('ConsigneeCity')

                    setYarnCount(masterData.product_material_name)
                    setRate(masterData.material_rate)
                    setWeight(masterData.material_weight)
                    setAgentId(masterData.agent_id)
                    setCustomerContactsId(masterData.customer_contacts_ids)
                    SizedYarnDetailsData.forEach((data) => {
                        data['beam_id'] = data['beam_no'];
                        delete data['beam_no'];
                    });

                    setDispatchDetailsData(responce.SizedYarnDetailsData);
                    setIsLoading(false)
                }
                catch (error) {
                    console.log('error: ', error);
                    setIsLoading(false)
                }

            case 'CustomerState':
                let customerState = parseInt($('#cmb_customer_state_id').val());
                setCustomerState(customerState)
                if (customerState !== 0 && !isNaN(customerState)) {
                    const getCustomerCities = await comboDataFunc.current.fillMasterDataWithOperator("cmv_city", "state_id", "=", JSON.stringify(customerState));
                    setCustomerCityOpts(getCustomerCities);
                    if (getCustomerCities.length < 0) { setCustomerCity(''); }
                } else {
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                }
                break;

            case 'CustomerCity':
                let customerCity = parseInt($('#cmb_customer_city_id').val());
                setCustomerCity(customerCity)
                if (customerCity !== 0 && !isNaN(customerCity)) {
                    $('#error_cmb_customer_city_id').hide()
                }
                if (customerCity === 0) {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Customer City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'Consignee':
                // let selectedConsigneeId = parseInt($('#cmb_consignee_id').val());
                let selectedConsigneeId = consigneeComboRef.current.value;
                setConsigneeId(selectedConsigneeId)

                break;

            case 'ConsigneeState':
                let consigneeState = parseInt($('#cmb_consignee_state_id').val());
                setConsigneeState(consigneeState)
                if (consigneeState !== 0 && !isNaN(consigneeState)) {
                    $('#error_cmb_consignee_state_id').hide()
                    const getConsigneeCitiesApiCall = await comboDataFunc.current.fillMasterDataWithOperator("cmv_city", "state_id", "=", JSON.stringify(consigneeState));
                    setConsigneeCityOpts(getConsigneeCitiesApiCall);
                } else {
                    setConsigneeCityOpts([]);
                    setConsigneeCity('');
                }
                break;

            case 'ConsigneeCity':
                let consigneeCity = parseInt($('#cmb_consignee_city_id').val());
                setConsigneeCity(consigneeCity)
                if (consigneeCity !== 0 && !isNaN(consigneeCity)) {
                    $('#error_cmb_consignee_state_id').hide()
                }
                if (consigneeCity === 0) {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Consignee City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'salesDispatchType':
                let salesDispatchType = sales_dispatch_type_ref.current?.['property_group'] || "";
                if (salesDispatchType) {
                    resetGlobalQuery();
                    globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
                    globalQuery.table = "am_properties"
                    globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchHSNTaxType' });
                    globalQuery.conditions.push({ field: "property_group", operator: "=", value: salesDispatchType });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    let getSaleDispatchTaxAPICall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                    setDispatchHSNTaxTypeOpt(getSaleDispatchTaxAPICall);

                    resetGlobalQuery();
                    globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
                    globalQuery.table = "am_properties"
                    globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchSalesType' });
                    globalQuery.conditions.push({ field: "property_group", operator: "=", value: salesDispatchType });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    let getDispatchSalesAPICall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                    setDispatchSalesTypeOpt(getDispatchSalesAPICall);


                    resetGlobalQuery();
                    globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group', 'remark'];
                    globalQuery.table = "am_properties"
                    globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchVoucherType' });
                    globalQuery.conditions.push({ field: "property_group", operator: "=", value: salesDispatchType });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    let getDispatchVoucherAPICall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                    setDispatchVoucherTypeOpt(getDispatchVoucherAPICall);
                    break;
                } else {
                    // Handle case where salesDispatchType is empty, undefined, or null
                    setDispatchHSNTaxTypeOpt([]);
                    setDispatchSalesTypeOpt([]);
                    setDispatchVoucherTypeOpt([]);
                }
            // case 'salesDispatchSalesType':
            //     let salesDispatchTypes = sales_dispatch_type_ref.current['property_group'];
            //     resetGlobalQuery();
            //     globalQuery.columns = ['property_id', 'property_name', 'property_master_id', 'property_group'];
            //     globalQuery.table = "am_properties"
            //     globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'DispatchSalesType' });
            //     globalQuery.conditions.push({ field: "property_group", operator: "=", value: salesDispatchTypes });

            //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            //     let getDispatchSalesAPICall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
            //     setDispatchSalesTypeOpt(getDispatchSalesAPICall);
            //     break;

            case 'salesDispatchVoucherType':
                let Remark = dispatch_voucher_type_ref.current?.['remark'];
                if (Remark && Remark !== "Select") {
                    const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("mt_dispatch_challan_sized_yarn_master", "dispatch_challan_no", "SB", Remark, "5", "dispatch_challan_type_id", "13");
                    setDispatchChallanNo(autoNoApiCall);
                }
                break;
            default:
                break;
        }
    }


    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Customer City':
                return <FrmCity btn_disabled={true} />;
            case 'Consignee City':
                return <FrmCity btn_disabled={true} />;
            case 'Dispatch Schedule Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;
            default:
                return null;
        }
    }

    const selectAllCustomerOrderNo = async (key) => {
        const fliterCheckbox = document.getElementsByName('customerOrderNoCheckBox');
        if (key === 'check') {
            for (let chkAllCheckbox of fliterCheckbox) {
                document.getElementById(`${chkAllCheckbox.id}`).checked = true;
            }
        } else {
            for (let unchkAllCheckbox of fliterCheckbox) {
                if (!unchkAllCheckbox.hasAttribute('disabled')) {
                    document.getElementById(`${unchkAllCheckbox.id}`).checked = false;
                }
            }
        }
    }


    const moveToListing = () => {
        let Path = compType === 'Transactions' ? '/Transactions/TDispatchNote/TSizedYarnDispatchNoteListing' : `/Transactions/TDispatchNote/TSizedYarnDispatchNoteListing`;
        navigate(Path);
    }


    const checkCustomerOrderNo = async (customer_order_no) => {
        setIsLoading(true);
        if (parseInt(customer_order_no) === 0) {
            if ($("#all_cust_order_no_id").is(":checked") === false) {
                await selectAllCustomerOrderNo('uncheck');
            } else {
                await selectAllCustomerOrderNo('check');
            }
        }

        await FnCheckCustomerOrderNoApproval()

        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=customerOrderNoCheckBox]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=customerOrderNoCheckBox]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('all_cust_order_no_id').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('all_cust_order_no_id').checked = false;
        }

        setCheckedCustomerOrderNoLength(totalChkBoxesChked)
        setIsLoading(false);
    }

    const FnCheckCustomerOrderNoApproval = async () => {

        try {
            let customer_order_nos = []
            $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
                customer_order_nos.push($(this).val())
            })
            resetGlobalQuery();
            globalQuery.columns = ["DISTINCT (dispatch_challan_no)", "customer_order_no", "dispatch_note_status"]
            globalQuery.table = "mtv_dispatch_schedule_details_trading"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "customer_order_no", operator: "IN", values: customer_order_nos });
            const getScheduleNosForCheckApproval = await comboDataFunc.current.fillFiltersCombo(globalQuery);

            let nonApprovedOrderNos = [];
            // Check if all customer_order_nos are present in getScheduleNosForCheckApproval
            const allCustomerOrderNosPresent = customer_order_nos.map(orderNo => {
                const isPresent = getScheduleNosForCheckApproval.some(schedule => schedule.customer_order_no === orderNo && schedule.dispatch_note_status === 'A')
                if (isPresent) {
                    document.getElementById(`filterCheck-${orderNo}`).checked = false;
                    nonApprovedOrderNos.push(orderNo)
                }
            });

            let custOrderNos = nonApprovedOrderNos + ""
            if (custOrderNos.length !== 0) {
                setInfoMsg("To create new Dispatch Challan, Please Dispatch the approved Dispatch Challan for the following customer order numbers: " + custOrderNos)
                setShowInfoMsgModal(true)
            }


        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    const FnValidateDispatchChallan = async () => {

        let isDataValid = true;

        if (cmb_job_type === "") {
            $("#error_cmb_job_type").text("Please fill this field...!");
            $("#error_cmb_job_type").show();
            $("#cmb_job_type").focus();
            return false;

        }

        if (cmb_sales_dispatch_type === "") {
            $("#error_cmb_sales_dispatch_type").text("Please fill this field...!");
            $("#error_cmb_sales_dispatch_type").show();
            $("#cmb_sales_dispatch_type").focus();
            return false;
        }
        if (cmb_dispatch_sales_type === "") {
            $("#error_cmb_dispatch_sales_type").text("Please fill this field...!");
            $("#error_cmb_dispatch_sales_type").show();
            $("#cmb_dispatch_sales_type").focus();
            return false;
        }

        if (cmb_dispatch_voucher_type === "") {
            $("#error_cmb_dispatch_voucher_type").text("Please fill this field...!");
            $("#error_cmb_dispatch_voucher_type").show();
            $("#cmb_dispatch_voucher_type").focus();
            return false;
        }

        //validate
        const selectedCheckBoxes = document.querySelectorAll("input[name='checkDispatch']:checked");
        const selectedBeamIds = Array.from(selectedCheckBoxes).map((checkbox) => parseInt(checkbox.value, 10)); // Ensure proper parsing
        if (keyForViewUpdate === "") {
            if (selectedBeamIds.length === 0) { // Check if no materials are selected
                setErrMsg('Please add at least one material!');
                setShowErrorMsgModal(true);
                isDataValid = false;
            }
        }
        if (keyForViewUpdate === "approve" && cmb_dispatch_by_id === "") {
            setErrMsg('Please select Approved By!');
            setShowErrorMsgModal(true);
            isDataValid = false;
        }
        return isDataValid;

    }

    const createDispatchNote = async (dispatchStatus) => {
        //SAVE
        try {
            setIsLoading(true);
            let dispatchChallanIsValid = await FnValidateDispatchChallan();
            let latestDispatchNo = txt_dispatch_challan_no;
            // if (!isApprove) {
            // checkIsValidate = await FnValidateDispatchNote();
            // }
            if (dispatchChallanIsValid) {
                let json = { 'DispatchYarnMasterData': {}, 'DispatchYarnDetailsData': [], 'commonIds': {} }
                console.log(dispatchDetailsData)

                const masterData = {
                    dispatch_challan_sized_yarn_id: dispatch_challan_sized_yarn_id,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    dispatch_challan_type_id: cmb_dispatch_challan_type,
                    dispatch_challan_type: dispatchScheduleTypeList.find(
                        (data) => parseInt(data.field_id) === parseInt(cmb_dispatch_challan_type)
                    )?.field_name,
                    dispatch_challan_no: latestDispatchNo,
                    dispatch_challan_version: parseInt(dispatch_challan_version),
                    dispatch_challan_date: txt_dispatch_date,
                    dispatch_challan_status: keyForViewUpdate === "approve" ? "D" : "P",
                    customer_order_no: txt_customer_order_no,
                    customer_id: txt_customer_id,
                    customer_contacts_ids: txt_customer_contacts_ids,
                    customer_state_id: sizedYarnMasterData.customer_state_id,
                    customer_city_id: sizedYarnMasterData.customer_city_id,
                    consignee_id: cmb_consignee_id,
                    consignee_city_id: cmb_consignee_city_id,
                    consignee_state_id: cmb_consignee_state_id,
                    yarn_count: txt_yarn_count,
                    total_ends: sizedYarnMasterData.total_ends,
                    rate: txt_rate,
                    weight: txt_weight,
                    agent_id: sizedYarnMasterData.agent_id,
                    job_type_id: cmb_job_type,
                    set_no: cmb_set_no,
                    driver_name: txt_driver_name,
                    other_terms_conditions: txt_other_terms_conditions,
                    product_material_id: sizedYarnMasterData.product_material_id,
                    product_material_name: sizedYarnMasterData.product_material_name,
                    remark: txt_remark,
                    approved_by_id: keyForViewUpdate === "approve" ? cmb_dispatch_by_id : "",
                    total_ends: dispatchDetailsData[0].total_ends,
                    yarn_bill_no: txt_yarn_bill_no,
                    vehicle_no: txt_vehicle_no,
                    created_by: UserName,
                    modified_by: UserName,
                    sales_dispatch_type: cmb_sales_dispatch_type,
                    dispatch_hsnTax_type: cmb_dispatch_hsnTax_type,
                    dispatch_sales_type: cmb_dispatch_sales_type,
                    dispatch_voucher_type: cmb_dispatch_voucher_type,
                    ...(keyForViewUpdate === "approve" && {
                        dispatch_payment_terms: sizedYarnMasterData.dispatch_payment_terms,
                        approved_date: dt_approve_date,
                        dispatch_date: dt_approve_date,
                    }),
                };

                json.DispatchYarnMasterData = masterData;
                console.log("MasterData", masterData)
                let dispatchDetails = [];

                if (keyForViewUpdate === '') {
                    const selectedCheckBoxes = document.querySelectorAll("input[name='checkDispatch']:checked");

                    // Extract values (or beam_id) from selected checkboxes
                    const selectedBeamIds = Array.from(selectedCheckBoxes).map((checkbox) => parseInt(checkbox.value));

                    // Assuming `dispatchDetailsData` contains all possible details
                    dispatchDetails = dispatchDetailsData.filter((details) =>
                        selectedBeamIds.includes(parseInt(details['beam_id']))
                    );
                } else {
                    dispatchDetails = dispatchDetailsData;
                }


                dispatchDetails.forEach(prop => {
                    const detailsObj = {
                        dispatch_challan_sized_yarn_id: dispatch_challan_sized_yarn_id,
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        dispatch_challan_type_id: cmb_dispatch_challan_type,
                        dispatch_challan_type: dispatchScheduleTypeList.find(
                            (data) => parseInt(data.field_id) === parseInt(cmb_dispatch_challan_type)
                        )?.field_name,

                        dispatch_challan_no: latestDispatchNo,
                        dispatch_challan_sized_yarn_details_id: keyForViewUpdate === 'approve' ? prop.dispatch_challan_sized_yarn_details_id : 0,
                        dispatch_challan_version: parseInt(dispatch_challan_version),
                        dispatch_challan_date: txt_dispatch_date,
                        dispatch_challan_status: keyForViewUpdate === "approve" ? "D" : "P",
                        beam_inward_type: prop.beam_inward_type,
                        customer_order_no: txt_customer_order_no,
                        customer_id: txt_customer_id,
                        customer_name: txt_customer,
                        financial_year: FINANCIAL_SHORT_NAME,
                        gross_weight: prop.gross_weight,
                        net_weight: prop.net_weight,
                        product_material_name: prop.product_material_name,
                        remark: txt_remark,
                        job_type_id: cmb_job_type,
                        set_no: cmb_set_no,
                        beam_id: prop.beam_id,
                        sizing_length: prop.sizing_length,
                        sizing_production_code: prop.sizing_production_code,
                        sizing_production_date: prop.sizing_production_date.split("T")[0],
                        tare_weight: prop.tare_weight,
                        total_ends: prop.total_ends,
                        approved_by_id: keyForViewUpdate === "approve" ? cmb_dispatch_by_id : "",
                        created_by: UserName,
                        modified_by: UserName,
                        ...(keyForViewUpdate === "approve" && {
                            approved_date: dt_approve_date,
                            dispatch_date: dt_approve_date,
                        }),
                    };

                    json.DispatchYarnDetailsData.push(detailsObj)
                    console.log("DispatchYarnDetailsData", detailsObj)
                })

                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.keyviewupdate = keyForViewUpdate === 'approve' ? 'approve' : 'Add';
                json.commonIds.dispatch_challan_sized_yarn_id = dispatch_challan_sized_yarn_id
                // json.commonIds.dispatch_schedule_date = txt_dispatch_date
                // json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log("JSON", json)
                const formData = new FormData();
                formData.append(`DispatchSizingYarnData`, JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/DispatchSizedYarn/FnAddUpdateRecord`, requestOptions)
                const response = await apicall.json();

                if (response.success === "0") {
                    setIsLoading(false);
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataFunc.current.evitCache();
                    console.log(evitCache);
                    // setdispatchscheduledetailstransactionId(response.data.dispatch_challan_sized_yarn_id)
                    setSuccMsg(response.message)
                    // if (response.data.hasOwnProperty('dispatch_challan_no')) {
                    setModalOrderDetails(`Dispatch note No: ${latestDispatchNo}`)
                    //     setDispatchChallanNo(response.data.dispatch_challan_no);
                    //     setDispatchVersion(response.data.dispatch_challan_version);
                    // }
                    setShowConfirmationModal(true);
                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    }


    const validateFields = () => {
        validate.current.validateFieldsOnChange('dispatchnoteId')
    }


    //checkbox data functionality

    const checkedDispatchDetails = (event) => {
        let checkedBeamsNos = [];

        const selectedCheckBoxes = document.querySelectorAll("input[name='checkDispatch']:checked");

        selectedCheckBoxes.forEach((checkbox) => {
            checkedBeamsNos.push(checkbox.value);
        });

        const checkedArray = dispatchDetailsData.filter((row) =>
            checkedBeamsNos.includes(row.beam_id)
        );

        console.log(checkedArray);

        setCheckedDetailsDataForTotal(checkedArray)
    };


    const removeMaterial = (materialId) => {
        if (!isApprove && keyForViewUpdate !== 'view') {
            const filterdedData = dispatchDetailsData.filter(item => item.product_material_id !== materialId);
            setDispatchDetailsData(filterdedData)
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(filterdedData));

            if (PackingDetailsList.length > 0) {
                const updatedPackingDetailsList = PackingDetailsList.filter(item => item.product_material_id !== materialId);
                setPackingDetailsList(updatedPackingDetailsList);
            }
        }
    }

    //Fn to render Details Table
    const renderDispatchNoteTable = useMemo(() => {
        return <>
            {
                dispatchDetailsData.length > 0
                    ? <Table className="erp_table" id='dispatchDetailsTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                {<th className={`erp_table_th hide_it`} style={{ width: "60px" }} >Action</th>}
                                <th className={`erp_table_th`} style={{ width: "60px" }} >Sr. No.</th>
                                <th className="erp_table_th" >Beam No</th>
                                <th className="erp_table_th" >Length</th>
                                <th className="erp_table_th" >Gross Weight</th>
                                <th className="erp_table_th" >Tare Weight</th>
                                <th className="erp_table_th" >Net Weight</th>
                                <th className="erp_table_th" >Total Ends</th>
                                <th className="erp_table_th" >Sizing Production Code</th>
                                <th className="erp_table_th" >Customer Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dispatchDetailsData?.map((dispatchItem, Index) =>
                                    <tr rowIndex={Index} hsnId={dispatchItem.hsn_code_id}>
                                        <td className="erp_table_td hide_it">
                                            <input
                                                disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                type="checkbox"
                                                className="checkdispatchnoteDetails"
                                                onChange={checkedDispatchDetails}
                                                name="checkDispatch"
                                                id={`checkdispatchnoteDetails_${dispatchItem.beam_id}`}
                                                value={dispatchItem.beam_id}
                                                label={dispatchItem.customer_order_no}

                                            />
                                        </td>


                                        <td className="erp_table_td">{Index + 1}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.beam_inward_type}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.sizing_length}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.gross_weight}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.tare_weight}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.net_weight}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.total_ends}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.sizing_production_code}</td>
                                        <td className={`erp_table_td `}>{dispatchItem.customer_name}</td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td disabled={['view', 'approve'].includes(keyForViewUpdate)} className="erp_table_td hide_it"></td>
                                <td className="erp_table_td"><b>Total</b></td>
                                <td className={`erp_table_td `}></td>
                                <td className={`erp_table_td `}><b>{checkedDetailsDataForTotal.reduce((acc, curr) => acc + (curr.sizing_length || 0), 0)}</b></td>
                                <td className={`erp_table_td `}><b>{checkedDetailsDataForTotal.reduce((acc, curr) => acc + (curr.gross_weight || 0), 0)}</b></td>
                                <td className={`erp_table_td `}><b>{checkedDetailsDataForTotal.reduce((acc, curr) => acc + (curr.tare_weight || 0), 0)}</b></td>
                                <td className={`erp_table_td `}><b>{checkedDetailsDataForTotal.reduce((acc, curr) => acc + (curr.net_weight || 0), 0)}</b></td>
                                <td className={`erp_table_td `}></td>
                                <td className={`erp_table_td `}></td>
                                <td className={`erp_table_td `}></td>
                            </tr>
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [dispatchDetailsData, checkedDetailsDataForTotal]);


    // ********************************************* Dispatch Schedule Document Upload Functionality Starts. *****************************************************
    function FnCheckBoxesSelection(key) {
        switch (key) {
            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'selectAllPackingRecords':
                $('.selectInspectionRecord').prop('checked', $('#selectAllPackingRecords').is(":checked"));
                break;
            case 'PartiallyselectPackingRecord':
                $('#selectAllPackingRecords').prop('checked', $('input:checkbox.selectInspectionRecord:checked').length === $('input:checkbox.selectInspectionRecord').length);
                break;
            default:
                break;
        }
    }

    const excludedcustFields = ['is_delete', 'is_active', 'created_on', 'created_by', 'modified_on', 'modified_by', 'deleted_by',
        'deleted_on', 'document_id', 'company_id'];

    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_dispatch_challan_no !== 0 ? txt_dispatch_challan_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setUploadedDocData(resp.content);
                } else {
                    setUploadedDocData([]);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    // ********************************************* Dispatch Schedule Document Upload Functionality Ends. *****************************************************

    // --------------------------------------- Delete the transaction functionality starts.------------------------------------------------------------------
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnDeleteRecord/${idList.dispatch_challan_version}/${COMPANY_ID}/${UserName}?dispatch_challan_no=${idList.dispatch_challan_no}`, method)
            const responce = await deleteApiCall.json();
            if (responce.success == '1') {
                setShowDeleteModal(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }
    // --------------------------------------- Delete the transaction functionality ends.------------------------------------------------------------------


    // Invoice Printing 
    const sizedYarnDispatchContent = {
        company: {
            company: '',
            company_branch: '',
            company_cell_no: '',
            company_emailId: '',
            company_website: '',
            company_gst_no: '',
            company_branch_no: '',
            company_address: '',
            company_pincode: '',
        },

        dispatchDetails: {
            dispatch_challan_no: '',
            dispatch_schedule_date: '',
            dispatch_challan_version: '',
            dispatch_schedule_creation_type_desc: '',
            dispatch_challan_type: '',
        },

        agentDetails: {
            agent_name: '',
            agent_address1: '',
            agent_cell_no: ''
        },

        customerDetails: {
            customer: '',
            address: '',
            state: '',
            contact: '',
            customer_email: '',
            customer_city: ''
        },

        consigneeDetails: {
            consignee_name: '',
            address: '',
            state: '',
            contact: '',
            consignee_email: '',
            consignee_city_name: ''
        },

        footer: {

        },

        sizedYarnDetailsData: []
    };

    const printInvoice = async (openPrintModal) => {

        setIsLoading(true);
        let MasterData = sizedYarnMasterData;
        let DetailsData = dispatchDetailsData;

        // Company Deatils
        sizedYarnDispatchContent.company.company = MasterData.company_name
        sizedYarnDispatchContent.company.company_branch = MasterData.company_branch_name
        sizedYarnDispatchContent.company.company_cell_no = MasterData.company_cell_no
        sizedYarnDispatchContent.company.company_emailId = MasterData.company_emailId
        sizedYarnDispatchContent.company.company_website = MasterData.company_website
        sizedYarnDispatchContent.company.company_gst_no = MasterData.company_gst_no
        sizedYarnDispatchContent.company.company_pan_no = MasterData.company_pan_no
        sizedYarnDispatchContent.company.company_address = MasterData.company_address
        sizedYarnDispatchContent.company.company_pincode = MasterData.company_pincode

        //Agent Details
        sizedYarnDispatchContent.agentDetails.agent_name = MasterData.agent_name;
        sizedYarnDispatchContent.agentDetails.agent_address1 = MasterData.agent_address1;

        //Customer Deatils
        sizedYarnDispatchContent.customerDetails.customer = MasterData.customer_name
        sizedYarnDispatchContent.customerDetails.address = MasterData.cust_branch_address1
        sizedYarnDispatchContent.customerDetails.state = MasterData.customer_state_name
        sizedYarnDispatchContent.customerDetails.contact = MasterData.customer_phone
        sizedYarnDispatchContent.customerDetails.customer_email = MasterData.customer_email
        sizedYarnDispatchContent.customerDetails.customer_city = MasterData.customer_city_name

        //Consignee Deatils
        sizedYarnDispatchContent.consigneeDetails.consignee_name = MasterData.consignee_name
        sizedYarnDispatchContent.consigneeDetails.address = MasterData.consignee_address
        sizedYarnDispatchContent.consigneeDetails.state = MasterData.consignee_state_name
        sizedYarnDispatchContent.consigneeDetails.consignee_email = MasterData.consignee_email
        sizedYarnDispatchContent.consigneeDetails.consignee_city_name = MasterData.consignee_city_name

        //Challan Details
        sizedYarnDispatchContent.dispatchDetails.customer_order_no = MasterData.customer_order_no;
        sizedYarnDispatchContent.dispatchDetails.dispatch_challan_no = MasterData.dispatch_challan_no
        sizedYarnDispatchContent.dispatchDetails.dispatch_challan_date = validateNumberDateInput.current.formatDateToDDMMYYYY(MasterData.dispatch_challan_date)
        sizedYarnDispatchContent.dispatchDetails.dispatch_challan_version = MasterData.dispatch_challan_version
        sizedYarnDispatchContent.dispatchDetails.dispatch_challan_type = MasterData.dispatch_challan_type;
        // sizedYarnDispatchContent.dispatchDetails.dispatch_schedule_creation_type_desc = MasterData.dispatch_schedule_creation_type_desc
        sizedYarnDispatchContent.dispatchDetails.sales_dispatch_type = MasterData.sales_dispatch_type
        sizedYarnDispatchContent.dispatchDetails.dispatch_hsnTax_type = MasterData.dispatch_hsnTax_type;
        sizedYarnDispatchContent.dispatchDetails.dispatch_sales_type = MasterData.dispatch_sales_type
        sizedYarnDispatchContent.dispatchDetails.dispatch_voucher_type = MasterData.dispatch_voucher_type;

        sizedYarnDispatchContent.footer.approved_by = MasterData.approved_by_name
        // sizedYarnDispatchContent.footer.supervisor = MasterData.dispatch_supervisor_name
        sizedYarnDispatchContent.footer.other_terms_conditions = MasterData.other_terms_conditions
        sizedYarnDispatchContent.footer.remark = MasterData.remark

        sizedYarnDispatchContent.footer.yarn_count = MasterData.yarn_count
        sizedYarnDispatchContent.footer.product_material_name = MasterData.product_material_name
        sizedYarnDispatchContent.footer.total_ends = MasterData.total_ends
        sizedYarnDispatchContent.footer.set_no = MasterData.set_no

        sizedYarnDispatchContent.footer.weight = MasterData.weight
        sizedYarnDispatchContent.footer.rate = MasterData.rate
        sizedYarnDispatchContent.footer.dispatch_payment_terms = MasterData.dispatch_payment_terms;



        sizedYarnDispatchContent.footer.vehicle_no = MasterData.vehicle_no;
        sizedYarnDispatchContent.footer.yarn_bill_no = MasterData.yarn_bill_no;
        sizedYarnDispatchContent.footer.driver_name = MasterData.driver_name;


        sizedYarnDispatchContent.sizedYarnDetailsData = DetailsData;

        if (openPrintModal) {
            navigate('/Invoice', { state: { invoiceData: sizedYarnDispatchContent, navigationLink: '/Transactions/TDispatchNote/TSizedYarnDispatchNoteEntry', invoiceType: 'DSY', title: 'Dispatched SIzed Yarn', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });

        } else {
            return renderToString(<SizedYarnDispatch invoiceContent={sizedYarnDispatchContent} />);
        }
    };

    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout >
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}


                <div className='card mt-4 p-1'>
                    <div className='card-header text-center py-0 mb-2'>
                        {/* //changes by tushar */}
                        <label className='erp-form-label-lg text-center'>Dispatch Challan Sized Yarn {requestTypeHeading()} {actionType} </label>
                    </div>

                    <form id="dispatchnoteId">
                        <div className="row">
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row '>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Dispatch Challan Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-3'>
                                        <select id="cmb_dispatch_challan_type" className="form-select form-select-sm" value={cmb_dispatch_challan_type} onChange={() => { comboOnChange('dispatchScheduleType'); validateFields(); }} disabled={true}>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {dispatchScheduleTypeList.length !== 0 ? (
                                                <>
                                                    {dispatchScheduleTypeList.map(scheduleType => (
                                                        // changes by tushar
                                                        <option key={scheduleType.field_id} value={scheduleType.field_id} shortname={scheduleType.product_type_short_name} > {scheduleType.field_name} </option>
                                                    ))}
                                                </>
                                            ) : null}

                                        </select>
                                    </div>

                                    {/* <div className='col-sm-1'></div> */}

                                    <div className='col-sm-2'>
                                        <Form.Label className="erp-form-label">Job Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-4'>
                                        <select id="cmb_job_type" className="form-select form-select-sm" ref={job_type_ref} value={cmb_job_type} onChange={(e) => {
                                            setJobType(e.target.value); job_type_ref.current = jobTypeOptions.find((job) => parseInt(e.target.value) === job['property_id']); validateFields();
                                        }} disabled={keyForViewUpdate !== ""}>
                                            <option value="">Select</option>
                                            {jobTypeOptions.length !== 0 ? (
                                                <>
                                                    {jobTypeOptions.map(jobtype => (
                                                        <option value={jobtype.property_id} > {jobtype.property_name} </option>
                                                    ))}
                                                </>
                                            ) : null}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_job_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Sales/Dispatch Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-3'>
                                        <select id="cmb_sales_dispatch_type" className="form-select form-select-sm" ref={sales_dispatch_type_ref} value={cmb_sales_dispatch_type} onChange={(e) => { setSalesDispatchType(e.target.value); sales_dispatch_type_ref.current = salesDispatchTypeOpt.find((job) => e.target.value === job['property_name']); comboOnChange('salesDispatchType'); validateFields(); }} disabled={keyForViewUpdate !== ""} >
                                            <option value="">Select</option>
                                            {/* <option value="0">Add New Record+</option> */}
                                            {salesDispatchTypeOpt.length !== 0 ? (
                                                <>
                                                    {salesDispatchTypeOpt.map(dispatchType => (
                                                        <option value={dispatchType.property_name} > {dispatchType.property_name} </option>

                                                        // <option key={scheduleType.field_id} value={scheduleType.field_id} shortname={scheduleType.product_type_short_name} > {scheduleType.field_name} </option>
                                                    ))}
                                                </>
                                            ) : null}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sales_dispatch_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                    {/* <div className='col-sm-1'></div> */}

                                    <div className='col-sm-2'>
                                        <Form.Label className="erp-form-label">Disp. Tax Type </Form.Label>
                                    </div>
                                    <div className='col-sm-4'>
                                        <select id="cmb_dispatch_hsnTax_type" className="form-select form-select-sm" ref={dispatch_tax_type_ref} value={cmb_dispatch_hsnTax_type} onChange={(e) => {
                                            setDispatchHsnTaxType(e.target.value); dispatch_tax_type_ref.current = dispatchHSNTaxTypeOpt.find((job) => e.target.value === job['property_name']); comboOnChange('salesDispatchTaxType'); validateFields();
                                        }} disabled={keyForViewUpdate !== ""}>
                                            <option value="">Select</option>
                                            {dispatchHSNTaxTypeOpt.length !== 0 ? (
                                                <>
                                                    {dispatchHSNTaxTypeOpt.map(hsnTaxType => (
                                                        <option value={hsnTaxType.property_name} > {hsnTaxType.property_name} </option>
                                                    ))}
                                                </>
                                            ) : null}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_dispatch_hsnTax_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Sales Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-3'>
                                        <select id="cmb_dispatch_sales_type" className="form-select form-select-sm" ref={dispatch_sales_type_ref} value={cmb_dispatch_sales_type} onChange={(e) => { setDispatchSalesType(e.target.value); dispatch_sales_type_ref.current = dispatchSalesTypeOpt.find((job) => e.target.value === job['property_name']); validateFields(); }} disabled={keyForViewUpdate !== ""} >
                                            <option value="">Select</option>
                                            {/* <option value="0">Add New Record+</option> */}
                                            {dispatchSalesTypeOpt.length !== 0 ? (
                                                <>
                                                    {dispatchSalesTypeOpt.map(salestype => (
                                                        <option value={salestype.property_name} > {salestype.property_name} </option>

                                                        // <option key={scheduleType.field_id} value={scheduleType.field_id} shortname={scheduleType.product_type_short_name} > {scheduleType.field_name} </option>
                                                    ))}
                                                </>
                                            ) : null}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_dispatch_sales_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                    {/* <div className='col-sm-1'></div> */}

                                    <div className='col-sm-2'>
                                        <Form.Label className="erp-form-label">Voucher Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-4'>
                                        <select id="cmb_dispatch_voucher_type" className="form-select form-select-sm" ref={dispatch_voucher_type_ref} value={cmb_dispatch_voucher_type} onChange={(e) => {
                                            setDispatchVoucherType(e.target.value); dispatch_voucher_type_ref.current = dispatchVoucherTypeOpt.find((job) => e.target.value === job['property_name']); comboOnChange('salesDispatchVoucherType'); validateFields();
                                        }} disabled={keyForViewUpdate !== ""}>
                                            <option value="">Select</option>
                                            {dispatchVoucherTypeOpt.length !== 0 ? (
                                                <>
                                                    {dispatchVoucherTypeOpt.map(jobtype => (
                                                        <option value={jobtype.property_name} > {jobtype.property_name} </option>
                                                    ))}
                                                </>
                                            ) : null}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_dispatch_voucher_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className="col-sm-3 col-12">
                                        <Form.Label className="erp-form-label">Challan no., Ver. & Date <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_dispatch_challan_no" className="erp_input_field" value={txt_dispatch_challan_no} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_dispatch_schedule_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="text" id='dispatch_challan_version' className="erp_input_field text-end" value={dispatch_challan_version} disabled />
                                                <MDTypography variant="button" id="error_dispatch_schedule_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='txt_dispatch_date' className="erp_input_field" min={currentDate} value={txt_dispatch_date} onChange={e => { setDispatchDate(e.target.value); validateFields(); }} disabled />
                                                <MDTypography variant="button" id="error_txt_indent_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Set No<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-9 col-10'>

                                        <Select ref={setNoComboRef}
                                            options={setNoOptions}
                                            inputId="cmb_set_no" // Provide the ID for the input box
                                            value={setNoOptions.find(option => option.value === cmb_set_no)}
                                            onChange={(selectedOpt) => {
                                                setSetNo(selectedOpt.value);
                                                setNoComboRef.current = selectedOpt.value;
                                                comboOnChange('cmb_set_no')
                                                validateFields();
                                            }}
                                            placeholder="Search for a Set No..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                {keyForViewUpdate !== '' &&
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Approved By<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Select ref={dispatchByComboRef}
                                                options={cmb_supervisorList}
                                                isDisabled
                                                inputId="cmb_dispatch_by_id" // Provide the ID for the input box
                                                value={cmb_supervisorList.find(option => option.value === cmb_dispatch_by_id)}
                                                onChange={(selectedOpt) => {
                                                    setDispatchById(selectedOpt.value)
                                                    dispatchByComboRef.current = selectedOpt.value;
                                                    comboOnChange('cmb_dispatch_by_id');
                                                    validateFields();
                                                }}
                                                placeholder="Search for a Employee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names

                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_dispatch_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {/* {keyForViewUpdate==="" && <div className='col-12 col-md-1 refreshIconCol'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateEmployees'); }} />
                                        </div>} */}
                                        <div className="col-sm-3">
                                            <Form.Control type="date" id='txt_dispatch_date' className="erp_input_field" min={currentDate} value={dt_approve_date} onChange={e => { setApproveDate(e.target.value); validateFields(); }} disabled />
                                            <MDTypography variant="button" id="error_txt_indent_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>}

                            </div>

                            {/* //second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                {/* <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="row">
                                            <div className="col-12">
                                                <Form.Control type="text" id="txt_customer" className="erp_input_field" value={txt_customer} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer Order No<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="row">
                                            <div className="col-12">
                                                <Form.Control type="text" id="txt_dispatch_challan_no" className="erp_input_field" value={txt_customer_order_no} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_dispatch_schedule_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer & Order No <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_customer" className="erp_input_field" value={txt_customer} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="text" id="txt_dispatch_challan_no" className="erp_input_field" value={txt_customer_order_no} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_dispatch_schedule_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer State & City </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} disabled optional="optional">
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {stateOpts?.map(state => (
                                                        <option value={state.field_id}>{state.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} disabled optional="optional">
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {customerCityOpts?.map(city => (
                                                        <option value={city.field_id}>{city.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Consignee</Form.Label>
                                    </div>
                                    <div className='col-sm-9'>

                                        <Select ref={consigneeComboRef}
                                            options={consigneeList}
                                            isDisabled={true}
                                            inputId="cmb_consignee_id" // Provide the ID for the input box
                                            value={consigneeList.find(option => option.value === cmb_consignee_id)}
                                            onChange={(selectedOpt) => {
                                                setConsigneeId(selectedOpt.value);
                                                consigneeComboRef.current = selectedOpt;
                                                comboOnChange('Consignee')
                                                validateFields();
                                            }}
                                            placeholder="Search for a Consignee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Consignee State & City</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_consignee_state_id" className="form-select form-select-sm" value={cmb_consignee_state_id} onChange={() => { comboOnChange('ConsigneeState'); validateFields(); }} optional="optional" disabled={true}>
                                                    <option value="">Select</option>
                                                    {stateOpts?.map(state => (
                                                        <option value={state.field_id}>{state.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_consignee_city_id" className="form-select form-select-sm" value={cmb_consignee_city_id} onChange={() => { comboOnChange('ConsigneeCity'); validateFields(); }} optional="optional" disabled={true}>
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {consigneeCityOpts?.map(city => (
                                                        <option value={city.field_id}>{city.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`row`}>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Yarn Count</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_yarn_count' className="erp_input_field" value={txt_yarn_count} optional='optional' disabled />
                                        <MDTypography variant="button" id="error_txt_yarn_count" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                {/* <div className={`row`}>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Rate</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_rate' className="erp_input_field" value={txt_rate} optional='optional' disabled />
                                        <MDTypography variant="button" id="error_rate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className={`row`}>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Weight (KG)</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="number" id='txt_weight' className="erp_input_field" value={txt_weight} optional='optional' onChange={(e) => { setWeight(e.target.value) }} disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                        <MDTypography variant="button" id="error_txt_weight" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Rate & Weight (KG) </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id='txt_rate' className="erp_input_field" value={txt_rate} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_rate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="number" id='txt_weight' className="erp_input_field" value={txt_weight} optional='optional' onChange={(e) => { setWeight(e.target.value) }} disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                                <MDTypography variant="button" id="error_txt_weight" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Other Terms Conditions</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_other_terms_conditions" className="erp_txt_area" value={txt_other_terms_conditions} onInput={e => { setOtherCondition(e.target.value); }} maxlength="1000" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_remark" className="erp_txt_area" value={txt_remark} onInput={e => { setRemark(e.target.value); }} maxlength="1000" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                    </div>
                                </div>

                              */}

                                <div className="row">
                                    <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <hr />

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey={`0`}>
                        <Accordion.Header className="erp-form-label-md">Sized Yarn Dispatch Details</Accordion.Header>
                        <Accordion.Body className="">
                            <div className="row">
                                <div className={`table-responsive ${dispatchDetailsData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                    <>{renderDispatchNoteTable}</>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


                <hr />
                <div className="card">
                    <div className="card-header main_heding text-start">
                        <label className='erp-form-label-lg'>Sized Yarn Challan Totals</label>
                    </div>
                    <div className="card-body">
                        <form id="GRNFooterFormId">
                            <div className="row">
                                <div className="col-md-6 col-12 erp_form_col_div">
                                    {/* first column */}

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Vehicle no</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_vehicle_no" className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); }} optional='optional' disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Driver name</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_driver_name" className="erp_input_field" value={txt_driver_name} onChange={(e) => { setDriverName(e.target.value); }} optional='optional' disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_driver_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Yarn Bill No</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_yarn_bill_no" className="erp_input_field" value={txt_yarn_bill_no} onChange={(e) => { setYarnBillNo(e.target.value); }} optional='optional' disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_yarn_bill_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-6 col-12 erp_form_col_div">

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Agent</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={() => { comboOnChange('ConsigneeState'); validateFields(); }} optional="optional" disabled={true}>
                                                <option value="">Select</option>
                                                {agentOptions?.map(state => (
                                                    <option value={state.agent_id}>{state.agent_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_txt_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Other terms & Conditions</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_other_terms_conditions" className="erp_input_field" value={txt_other_terms_conditions} optional='optional' onChange={(e) => { setOtherCondition(e.target.value); }} disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row mb-1'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value); validateFields(); }} optional='optional' disabled={['view', 'approve'].includes(keyForViewUpdate)} />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>

                    {/* <MDButton type="button" className={`erp-gb-button ms-2 ${deletionKey === true ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                        onClick={() => setShowDeleteModal(true)} >Delete</MDButton> */}

                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" onClick={() => createDispatchNote(!isApprove ? 'Add' : 'A')}
                        fontWeight="regular">{actionLabel}</MDButton>



                    <MDButton className={`erp-gb-button ms-1 ${keyForViewUpdate !== '' ? '' : 'd-none'}`} id="printBtnId" variant="button" fontWeight="regular" onClick={() => printInvoice(true)}>Print <FiPrinter className="erp-download-icon-btn" /></MDButton> &nbsp;
                    {/* <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === "" ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" id='viewdocument-id' onClick={viewDocumentForm}>Upload Document</MDButton>&nbsp; */}

                </div >

                {/* Add new Record Popup */}
                {showAddRecModal ?
                    <>
                        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                            <Modal.Body className='erp_city_modal_body'>
                                <div className='row'>
                                    <div className='col-12 align-self-end'>
                                        <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button>
                                    </div>
                                </div>
                                {displayRecordComponent()}
                            </Modal.Body>
                        </Modal >
                    </>
                    : null
                }

                {/* Generic Response Message Modals */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                <InfoModal closeModal={() => handleCloseInfoModal()} show={[showInfoMsgModal, infoMsg]} />

                {/* This Modal is used to print the dispatch-note */}
                <Modal show={showConfirmationModal} onHide={(e) => { FnCloseConfirmationModal(); }} backdrop="static" keyboard={false} centered>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={(e) => { FnCloseConfirmationModal(); }}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_success_icon'><RxCheckCircled /></span>
                        <h6>{succMsg}</h6>
                        <h6>{modalOrderDetails}</h6>
                        <Modal.Footer className='erp-modal-footer'>
                            {/* <Button variant="success" className="erp-gb-button" onClick={(e) => { printInvoice(true); }}> Print </Button> */}
                            <Button variant="success" className="erp-gb-button ms-2" onClick={(e) => { FnCloseConfirmationModal(); }}> OK </Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal >

                <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                {/* </div> */}

            </DashboardLayout>
        </>
    )
}