import React, { useMemo } from 'react'
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import $ from 'jquery';
import { AiFillEyeInvisible } from "react-icons/ai";
import Select from 'react-select';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ReactPaginate from "react-paginate";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Table } from "react-bootstrap"
import Card from 'react-bootstrap/Card';
import { FiDownload } from 'react-icons/fi';
import Button from 'react-bootstrap/Button';
import { Accordion } from "react-bootstrap";

// React icons
import { AiOutlineDownCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import { TbArrowsRight } from "react-icons/tb";
import { TbArrowsLeft } from "react-icons/tb";
import { TbArrowsDown } from "react-icons/tb";
import { TbArrowsUp } from "react-icons/tb";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { IoAddCircleOutline } from "react-icons/io5"
import { IoRemoveCircleOutline } from "react-icons/io5"
import { RiSearchLine } from 'react-icons/ri';
import { FaTimes } from "react-icons/fa";
// File Imports
import ComboBox from "Features/ComboBox";
import Tooltip from "@mui/material/Tooltip";

import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmPropertyEntry from 'Masters/MProperty/FrmPropertyEntry';
import FrmPaymentTermsEntry from 'Masters/MPaymentTerms/FrmPaymentTermsEntry';


//bootstrap
import PdfExport from 'Features/Exports/PdfExport';
import ExcelExport from "Features/Exports/ExcelExport";
import JsonExport from "Features/Exports/JsonExport";
import CSVExport from "Features/Exports/CSVExport";
import ConfigConstants from "assets/Constants/config-constant";

//supplier branch 
import Datatable from 'components/DataTable';

//supplier branch bank record
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import FrmCity from 'FrmGeneric/MCity/FrmCity';
import FrmValidations from 'FrmGeneric/FrmValidations';
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import Document from 'Features/Document';

export default function FrmSupplierEntry({ suppId, goToNext }) {
  const { state } = useLocation();
  let { supplierId = 0, keyForViewUpdate, compType = 'Masters' } = state || '';

  if (localStorage.getItem('dataAddedByCombo') === 'dataAddedByCombo') {
    const supplierIDs = JSON.parse(localStorage.getItem('supplierIDs'));
    if (supplierIDs !== null && supplierIDs !== undefined) {
      supplierId = supplierIDs.supplierId;
      keyForViewUpdate = supplierIDs.keyForViewUpdate;
      compType = supplierIDs.compType;
    }
  }
  let expandedGlCodeCombo = false;
  // Loader
  const [isLoading, setIsLoading] = useState(false);
  //hide and show password 
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordhideandshow = () => {
    setShowPassword(!showPassword);
  };

  // document
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const handleCloseDocumentForm = () => setShowDocumentForm(false);
  const viewDocumentForm = () => setShowDocumentForm(true);
  const [openContatctsAccord, setOpenContactsAccord] = useState(true);
  // const cmb_country_id_ref = useRef()
  const FnKeyForViewUpdate = (keyForViewUpdate) => {
    switch (keyForViewUpdate) {
      case 'view':
        $('#supplierName').attr('disabled', true)
        $('#shortName').attr('disabled', true)
        $('#suppSaveBtn').attr('disabled', true)
        $('#branchSaveBtn').attr('disabled', true)
        $('#btn_saveContact').attr('disabled', true)
        $('#suppBranchFormId :input').attr('disabled', true);
        $('#bankform #formbody').hide();
        $('#saveBtn').attr('disabled', true);
        $('#save_suppInfoBank_Btn').hide();
        $('[name="isBranchType"]').attr('disabled', true)
        $('#suppbranchName').attr('disabled', true)
        $('#shortNameId').attr('disabled', true)
        $("input[type=radio]").attr('disabled', true);

        break;
      case 'update':
        
       
        $('#save_suppInfoBank_Btn').hide();
    }
  }

  //Setting hook for key(update , view, delete for view branch & bank branch info) in Branch Entry Page
  const [keyFVUD, setKeyVUD] = useState('');
  //Pagination Fields For bank
  const [keys, setKeys] = useState('')

  const combobox = useRef();
  const validationRef = useRef();

  // var COMPANY_ID = sessionStorage.getItem('companyID')
  // var COMPANY_BRANCH_ID = sessionStorage.getItem('COMPANY_BRANCH_ID');
  let supplierMainBranchId = 0;
  // var supplierId = sessionStorage.getItem("supplierId");
  // var keyForViewUpdate = sessionStorage.getItem('keyForViewUpdate');

  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName, COMPANY_BRANCH_ID, COMPANY_NAME } = configConstants;
  // For navigate
  const navigate = useNavigate();
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')

  // Add Supplier Form Fields 
  const [supplier_id, setSupplierId] = useState(supplierId === 0 ? suppId : supplierId);
  const supplier_id_ref = useRef(supplier_id);
  const [cmb_supplier_type, setSupplierType] = useState('Manufacturing(Projects)');
  const [supplierCode, setSupplierCode] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [supplierShortName, setSupplierShortName] = useState('');
  const [supplierSector, setSupplierSector] = useState('Manufacturing');
  const [supplierNatureOfBusiness, setSupplierNatureOfBusiness] = useState('');
  const [supplierPaymentTermID, setSupplierPaymentTerm] = useState('151');
  const [supplierRating, setSupplierRating] = useState('');
  const [supplierGlCodes, setSupplierGLCodes] = useState('');
  const [supplierAccountsId, setSupplierAccountsId] = useState('');
  const [supplierHistory, setSupplierHistory] = useState('');
  const [txt_username, setUserName] = useState('1234');
  const [txt_password, setPassword] = useState('1234');
  const [remark, setRemark] = useState('');
  const [suppIsActive, setSuppIsAct] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [groupByColumns, setGroupByColumns] = useState([]);

  // combobox Option
  const [supplierTypeOption, setSupplierTypeOption] = useState([]);
  const [supplierSectorOption, setSupplierSectorOption] = useState([]);
  const [GLCodesOption, setGlCodesOption] = useState([]);
  const [GLCodesCheckboxes, setGLCodesCheckboxes] = useState();
  const [totalSelectedGLCodeCheckboxes, setTotalSelectedGLCodeCheckboxes] = useState(1);
  const [BranchGLCodesCheckboxes, setBranchGLCodesCheckboxes] = useState();
  const [totalSelectedBranchGLCodeCheckboxes, setTotalSelectedBranchGLCodeCheckboxes] = useState(1);
  const [paymentTermOption, setPaymentTermOption] = useState([])
  // Delete Modal
  const handleClose = () => setShowDeleteModal(false);
  const [showDeletemodal, setShowDeleteModal] = useState(false);

  // Error Msg HANDLING
  // const handleCloseErrModal = () => setShowErrorMsgModal(false);

  const usernameRef = useRef();

  const handleCloseErrModal = () => {
    if (errMsg === "User Name is already exist!") {
      setShowErrorMsgModal(false);
      usernameRef.current.focus();
    } else {
      setShowErrorMsgModal(false);
    }
  }



  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    let functionType = localStorage.getItem("functionType");
    if (functionType !== "suppBranchInfo") {
      moveToListing();
    }
    localStorage.removeItem("functionType");

  }

  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // to add new records in combo box 
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  // Option Box
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);
  const [supplierRegionOption, setSupplierRegionOption] = useState([]);

  // Export Fields
  var dataExport = [];
  var columnExport = [];

  const pdfExp = useRef();
  const exlsExp = useRef();
  const jsonExp = useRef();
  const csvExp = useRef();
  const validateNumberDateInput = useRef();

  var reportName = "Supplier Branch Report"
  const pdfExpFilters = {};
  // Filter Fields
  var rptColumnHeads;
  const [filterComboBox, setFilterComboBox] = useState([]);
  const [optionBox, setOptionBox] = useState([]);
  const [filterBox, setFilterBox] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [suppliercontactdata, setSupplierContactData] = useState([])

  // Supplier Branch Form Fields
  const [_branchType, setBranchType] = useState(supplierId === 0 ? 'Main' : 'Sub');
  const [supp_branchType, setSupplyerBranchType] = useState('Supplier');
  const [txt_supp_branch_name, setSuppBranchName] = useState('');
  const [txt_supp_branch_short_name, setSuppBrachShortName] = useState('');
  const [txt_supp_branch_vendor_code, setSuppBrachVendorCode] = useState('');
  const [txt_supp_branch_address1, setSuppBranchAdd1] = useState('');
  const [txt_supp_branch_address2, setSuppBranchAdd2] = useState('');
  const [txt_supp_branch_pin_code, setSuppBrachPincode] = useState('');
  const [cmb_supp_branch_state_id, setState] = useState('');
  const [cmb_supp_branch__City_id, setCity] = useState('');
  const [cmb_supp_branch__Country_id, setCountry] = useState('1');
  const [cmb_supp_branch__District_id, setDistrict] = useState('');
  const [cmb_supp_branch_region, setSuppBrachRegion] = useState('NA');
  const [txt_supp_branch_phone_no, setSuppBranchPhoneNo] = useState('');
  const [txt_supp_branch_cell_no, setSuppBranchCellNo] = useState('');
  const [txt_supp_branch_email_id, setSuppBranchEmailId] = useState('');
  const [txt_supp_branch_website, setSuppBranchWebsite] = useState('');
  const [txt_supp_branch_linkedIn_profile, setBranchLinkedinProfile] = useState('');
  const [txt_supp_branch_twitter_profile, setBranchTwitterProfile] = useState('');
  const [txt_supp_branch_facebook_profile, setBranchFacebookProfile] = useState('');
  const [txt_supp_branch_gst_no, setBranchGSTNO] = useState('');
  const [txt_supp_branch_gst_division, setBranchGSTDivision] = useState('');
  const [txt_supp_branch_gst_range, setBranchGSTRange] = useState('');
  const [txt_supp_branch_pan_no, setBranchPanNo] = useState('');
  const [txt_supp_branch_udyog_adhar_no, setBranchUdyogAdharNo] = useState('');
  const [txt_supp_branch_vat_no, setBranchVatNo] = useState('');
  const [txt_supp_branch_service_tax_no, setSupplierBranchDataerviceTaxNo] = useState('');
  const [txt_supp_branch_excise_no, setBranchExciseNo] = useState('');
  const [txt_branch_cst_no, setBranchCstNo] = useState('');
  const [txt_cust_branch_bst_no, setBranchBstNo] = useState('');
  const [cmb_supp_payment_term_ID, setBranchPaymentTermID] = useState('151');
  const [txt_supp_branch__ratings, setBranchRating] = useState('');
  const [cmb_supp_branch_gl_codes, setBranchGLCodes] = useState('');
  const [txt_sopp_branch_accounts_id, setBranchAccountsID] = useState('');
  const [txt_supp_branch_blocked_remarks, setBranchBlockedRemark] = useState('');
  const [txt_supp_payment_blocked_remarks, setBranchPaymentBlockedRemark] = useState('');
  const [suppBranchBlock, setSuppBranchBlock] = useState(false);
  const [suppBranchPaymentBlock, setSuppBranchPaymentBlock] = useState(false);
  const [suppIsSez, setSuppIsSez] = useState(false);
  const [suppBranchActive, setSuppBranchActive] = useState(true);


  // ADD Bank Feilds
  const [accountType, setAccountType] = useState([]);
  const [bankName, setBankName] = useState([]);
  const [bankBranchName, setBankBranchName] = useState('');
  const [bankAccountNo, setBankAccountNo] = useState('');
  const [bankIfscNo, setBankIfscNo] = useState('');
  const [bankSwiftCode, setBankSwiftCode] = useState('');
  const [bankGstNo, setBankGStNo] = useState('');
  const [is_bankActive, setIsBankActive] = useState(true);
  const [cmb_currency_type, setCurrencyType] = useState('Rupees');
  //table Feilds
  const [dataBank, setBankData] = useState([]);
  const [cmb_bank_id, setBankId] = useState([]);



  //Option Boxes
  const [currencyTypeOption, setCurrencyTypeOption] = useState([]);
  const [bankAccountTypeOption, setBankAccountTypeOption] = useState([]);
  const [bankNameOptionList, setBankNameOptionList] = useState([]);
  const [txt_sez_name, setSezName] = useState('');
  const [txt_supp_branch_TallyID, setSuppBranchTallyId] = useState('');
  const [BranchGLCodesOption, setBranchGLCodesOption] = useState([]);
  const [Supplierdata, setSupplierdata] = useState([]);
  const [suppilerbankcolumns, setsuppilerbankcolumns] = useState([]);
  const [supplierBranchId, setBranchID] = useState(0);

  // Table Data fields
  const [data, setSupplierBranchData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [bankcolumns, setBankColumns] = useState([]);
  const [bankRecords, setBankRecords] = useState([]);
  var bankColumnHeads;
  var activeValue;
  var storeSelectedValues = [];

  // supplier contacts
  const [rowCount, setRowCount] = useState(1);
  //Option box 
  const [suppliercontactcountryCodeOptions, setsuppliercontactCountryCodeOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [searchState, setGlobalFilterSearch] = useState('')
  const [searchInputValue, setSearchInputValue] = useState('')

  const [designationOptions, setDesignationOptions] = useState([]);
  const [supplierBranchOptions, setSupplierBranchOptions] = useState([]);

  const ActionType = async () => {
    // var keyForViewUpdate = sessionStorage.getItem('keyForViewUpdate');
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        setActionLabel('Update')
        $('#supplierType').attr('disabled', true)
        $('#supplierCode').attr('disabled', true)
        $('#supplierName').attr('disabled', true)
        $('#shortName').attr('disabled', true)
        // $('#txt_password').attr('disabled', true)
        // $('#supplierAccountsId').attr('disabled', true)
        $('#supplierName').attr('readonly', true)
        $('[name="isBranchType"]').attr('disabled', true)
        // $('#suppbranchName').attr('disabled', true)
        // $('#shortNameId').attr('disabled', true)
        break;
      case 'view':
        setActionType('(View)');
        await validationRef.current.readOnly("supplierFormid");
        await validationRef.current.readOnly("suppBranchFormId");
        await validationRef.current.readOnly("bankform");
        break;
      default:
        setActionType('(Creation)');


        break;
    }
  };
  const moveToListing = () => {
    const path = '/Masters/SupplierListing';
    navigate(path);
  }

  useEffect(async () => {
    debugger
    setIsLoading(true);
    FnKeyForViewUpdate(keyForViewUpdate)
    await ActionType();
    await FnFetchPropertyRecords();
    await FillComobos();
    if (supplierId !== 0 && supplierId !== undefined && supplierId !== '' && supplierId !== null) {
      const availCols = await showReportRecords();
      await fetchFilteredData(PageCurrent, entriesPerPage, availCols)
      await FnCheckUpdateResponce();
      
     
      if (supplierMainBranchId !== 0) {
        setBranchID(supplierMainBranchId)
        await infoForUpdate(supplierMainBranchId, keyForViewUpdate);
      }
    }
    if (supplierId === 0) {
      addRecordInProperty('supplierTypes')
    }

    setIsLoading(false);

    // hide the gl_codes multi select combo.
    $(document).on('mouseup', function (e) {
      let container = $("#gl_code_ul");
      let container1 = $("#gl_code_ul_branch");

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
      }
      if (!container1.is(e.target) && container1.has(e.target).length === 0) {
        container1.hide();
      }
    });
    $('.hide-show-filters').hide();

  }, [])


  const showReportRecords = async () => {
    debugger
    try {
      const summaryRptApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/report/FnShowAllReportRecords?reportType=${'summary'}&viewName=${'cmv_supplier_branch_rpt'}`)
      const summaryResponce = await summaryRptApiCall.json();
      console.log("summaryResponce responce: ", summaryResponce)
      if (summaryResponce.content.length !== 0) {
        var rptColumnHeads = summaryResponce.headerKeys;
        setRecordData(summaryResponce.content)
        let filterBox = [];
        let tempOptionBox = []
        for (let colKey = 0; colKey < rptColumnHeads.length; colKey++) {
          const content = summaryResponce.content;
          const columnName = rptColumnHeads[colKey];
          const value = content[columnName];
          if (value !== null) {
            const myArray = value.split(":");

            switch (myArray[2]) {
              case 'Y':
                filterBox.push({ Headers: myArray[1], accessor: columnName });
                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                break;
              case 'O':
                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                break;
              default:
                break

            }

          }

        }
        setFilterComboBox(filterBox)
        setAvailableColumns(tempOptionBox)
        setGroupByColumns(tempOptionBox)
        return tempOptionBox;
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }


  const FillComobos = async () => {
    debugger

    try {
      if (combobox.current) {
        combobox.current.fillMasterData("cmv_country", "", "").then((cmv_countryList) => {
          setCountryOptions(cmv_countryList)

          // Set the default Country India.
          const defaultCountry = cmv_countryList.find(country => country.field_name === "India");
          setCountry(defaultCountry.field_id);
          // cmb_country_id_ref.current = defaultCountry.field_id
          // Load the State options.
          combobox.current.fillMasterData("cmv_state", "country_id", defaultCountry.field_id).then((getStateList) => {
            const updatedStateOptions = updateKeysForSearchBox(getStateList);
            setStateOptions(updatedStateOptions);

            // Set the default State Gujarat.
            const defaultState = getStateList.find(state => state.field_name === "Gujarat");
            setState(defaultState.field_id);

            // Load the district options.
            combobox.current.fillMasterData("cmv_district", "state_id", defaultState.field_id).then((getDistrictList) => {
              setDistrictOptions(getDistrictList);

              combobox.current.fillMasterData("cmv_city", "state_id", defaultState.field_id).then((getCityList) => {
                const updatedCityOptions = updateKeysForSearchBox(getCityList);
                setCityOptions(updatedCityOptions);

              })

            })

          })

        })

        combobox.current.fillMasterData("fmv_general_ledger", "", "").then((fmv_general_ledgerList) => {
          setBranchGLCodesOption(fmv_general_ledgerList)
        })

        combobox.current.fillComboBox("PaymentTermDays").then((paymentTermDays) => {
          setPaymentTermOption(paymentTermDays)
        })

        combobox.current.fillComboBox("Regions").then((regionList) => {
          setSupplierRegionOption(regionList)
        })

        combobox.current.fillMasterData("fmv_currency", "", "").then((currencyList) => {
          setCurrencyTypeOption(currencyList)
        })

        combobox.current.fillComboBox("BankAccountsType").then((bankAccountTypeList) => {
          setBankAccountTypeOption(bankAccountTypeList)
        })

        combobox.current.fillMasterData("cmv_banks_List", "", "").then((bankList) => {
          const updatedBankOptions = updateKeysForSearchBox(bankList);
          setBankNameOptionList(updatedBankOptions)
        })
      }

      const apiCallCountryCodeList = await fetch(`${process.env.REACT_APP_BASE_URL}/api/country/FnFetchCountryCodes`)
      const respCountryCode = await apiCallCountryCodeList.json();
      setCountryCodeOptions(respCountryCode)

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const FnCheckUpdateResponce = async () => {
    debugger
    try {
      setUserName('');
      setPassword('');
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/supplier/FnShowParticularRecordForUpdate/${supplier_id}`)
      const response = await apiCall.json();

      let resp = response.supplierData
      let contactData = response.suppContact

      setSupplierId(resp.supplier_id)
      supplier_id_ref.current = resp.supplier_id
      setSupplierType(resp.supplier_type)
      setSupplierCode(resp.supplier_code)
      setSupplierName(resp.supplier_name)
      setSupplierShortName(resp.supplier_short_name)
      setSupplierSector(resp.supplier_sector)
      setSupplierNatureOfBusiness(resp.nature_of_business)
      setSupplierPaymentTerm(resp.payment_term_id)
      setSupplierRating(resp.supplier_rating)
      setSupplierGLCodes(resp.supplier_gl_codes);
      // Set the count of the selected gl-codes.
      if (resp.supplier_gl_codes !== null && resp.supplier_gl_codes !== '') {
        setTotalSelectedGLCodeCheckboxes(resp.supplier_gl_codes.split(':').length);
      }

      setSupplierAccountsId(resp.supplier_accounts_id)
      setSupplierHistory(resp.supplier_history)
      setUserName(resp.supplier_username);
      setPassword(resp.supplier_password)   
      setRemark(resp.remark)

      //$('#updateBtn').show();
      $('#nxtBtn').hide();

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isSupplierActive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isSupplierActive"][value="0"]').checked = true;
          break;
      }


      if (contactData.length === 0) {
        const getExistingSupplierContactData = [...suppliercontactdata]
        getExistingSupplierContactData.push(contactBlankObject)
        setSupplierContactData(getExistingSupplierContactData)
      } else {
        setSupplierContactData(contactData)

      }
      // $('#updateBtn').hide();
      $('#nxtBtn').show();

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const addRecordInProperty = async (key) => {
    switch (key) {
      case 'fmv_general_ledger':
        const generalLeadgers = document.getElementById('supplierGLCodesId').value;
        setSupplierGLCodes(generalLeadgers)
        if (generalLeadgers === '0') {
          const newTab = window.open('/Masters/MFMGeneralLedgerEntry', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        break;

      case 'PaymentTermDays':
        const paymentTermDays = document.getElementById('supplierPaymentTermID').value;
        setSupplierPaymentTerm(paymentTermDays)
        if (paymentTermDays === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Payment Terms')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(0).css("padding-top", "0px");
          }, 100)
        }
        break;

      // case 'suppleirSectors':
      //   var propertyVal = document.getElementById('supplierSectorId').value;
      //   setSupplierSector(propertyVal)
      //   if (propertyVal !== "") {
      //     $('#error_supplierSectorId').hide();
      //   }
      //   if (propertyVal === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Supplier Sector')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").eq(0).css("padding-top", "0px");
      //     }, 100)
      //   }
      //   break;

      case 'supplierTypes':
        debugger
        var suppType = document.getElementById('supplierType').value;
        setSupplierType(suppType);
        let supplierTypeShortName = $('#supplierType option:selected').attr('suppTypeShortName');
        if (suppType !== '0' && suppType !== '') {
          let generatedsupplierCode = await generateCustomerCode('cm_supplier', 'supplier_code', 5, supplierTypeShortName, 'S');
          // if (txt_username.trim() === '') {
          //   setUserName(generatedsupplierCode);
          // }
          // setUserName(generatedsupplierCode);
          setSuppBrachVendorCode(generatedsupplierCode);
          $('#error_supplierTypeId').hide();
        } else if (suppType === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Supplier Type')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(0).css("padding-top", "0px");
          }, 100)
        } else {
          setSupplierCode('');
        }
        break;

      // case 'Country':
      //   const getCountryId = document.getElementById('countryId').value;
      //   setCountry(getCountryId)
      //   if (getCountryId !== '') {
      //     $('#error_countryId').hide();
      //     var stateList = await combobox.current.fillMasterData("cmv_state", "country_id", getCountryId)
      //     setStateOptions(stateList)
      //     setState('');
      //     setDistrictOptions([])
      //     setDistrict('');
      //     setCityOptions([]);
      //     setCity('');
      //     setSuppBrachRegion('')
      //   } else {
      //     setStateOptions([])
      //     setState('');
      //     setDistrictOptions([])
      //     setDistrict('');
      //     setCityOptions([]);
      //     setCity('');
      //     setSuppBrachRegion('')
      //   }
      //   break;

      // case 'State':
      //   debugger
      //   const getStateId = document.getElementById('stateId').value;
      //   setState(getStateId)
      //   if (getStateId !== '') {
      //     $('#error_stateId').hide();
      //     var districtList = await combobox.current.fillMasterData("cmv_district", "state_id", getStateId)
      //     var getCityList = await combobox.current.fillMasterData("cmv_city", "state_id", getStateId)
      //     setDistrictOptions([])
      //     setDistrictOptions(districtList)
      //     setCityOptions(getCityList)
      //     //setDistrict('');
      //     // setCityOptions([]);
      //     //setCity('');
      //   } else {
      //     setDistrictOptions([])
      //     setDistrictOptions([])
      //     setDistrict('');
      //     setCityOptions([]);
      //     setCity('');
      //   }
      //   break;

      case 'District':
        const getDistrictId = document.getElementById('districtId').value;
        setDistrict(getDistrictId)
        if (getDistrictId !== '') {
          $('#error_districtId').hide();
          resetGlobalQuery();
          globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
          globalQuery.table = "cmv_city"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
          globalQuery.conditions.push({ field: "district_id", operator: "=", value: getDistrictId });
          let getCityList = await combobox.current.removeCatcheFillCombo(globalQuery);
          setCityOptions(getCityList)
          setCity('');
        } else {
          setCityOptions([])
          setCity('');
        }
        break;

      // case 'City':
      //   debugger
      //   const propertyValCity = document.getElementById('cityId').value;
      //   if (propertyValCity === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('City')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").eq(0).css("padding-top", "0px");
      //     }, 100)
      //   }
      //   else {
      //     setCity(propertyValCity)
      //     // Set the pincode from the city list.
      //     // const selectedCity = cityOptions.find(city => city.field_id === parseInt(propertyValCity));
      //     // setSuppBrachPincode(selectedCity.city_pincode);

      //     resetGlobalQuery();
      //     globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
      //     globalQuery.table = "cmv_city"
      //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
      //     globalQuery.conditions.push({ field: "city_id", operator: "=", value: propertyValCity });
      //     let getCityList = await combobox.current.removeCatcheFillCombo(globalQuery);
      //     //  setCityOptions(getCityList)
      //     setSuppBrachPincode(getCityList[0].city_pincode);
      //     if (propertyValCity !== "") {
      //       $('#error_cityId').hide();
      //     }
      //   }
      //   break;

      case 'fmv_general_ledger_branch':
        const branchgeneralLeadgers = document.getElementById('suppBranchGLCodesId').value;
        setBranchGLCodes(branchgeneralLeadgers)
        $('#error_suppBranchGLCodesId').hide();
        if (branchgeneralLeadgers === '0') {
          const newTab = window.open('/Masters/MFMGeneralLedgerEntry', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        break;

      case 'PaymentTermDaysbranch':
        const paymentbranchTermDays = document.getElementById('supplierbrachPaymentTermID').value;
        setBranchPaymentTermID(paymentbranchTermDays)
        $('#error_supplierPaymentTermID').hide();
        if (paymentbranchTermDays === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Payment Terms')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(0).css("padding-top", "0px");
          }, 100)
        }
        break;

      // case 'Regions':
      //   const Region = document.getElementById('supplierRegionID').value;
      //   setSuppBrachRegion(Region)
      //   $('#error_supplierRegionID').hide();
      //   break;
    }
  }

  const FnFetchPropertyRecords = async () => {

    try {
      // var controlName = [ , , "PaymentTermDays", ""];
      if (combobox.current) {
        combobox.current.fillComboBox("CompanySectors").then((supplierSector) => {
          setSupplierSectorOption(supplierSector)
        })

        combobox.current.fillComboBox("CompanyTypes").then((supplierType) => {
          setSupplierTypeOption(supplierType)
        })

        combobox.current.fillComboBox("PaymentTermDays").then((supplpaymentTermDays) => {
          setPaymentTermOption(supplpaymentTermDays)
        })

        combobox.current.fillMasterData("fmv_general_ledger", "", "").then((fmv_general_ledgerList) => {
          setGlCodesOption(fmv_general_ledgerList)
          setBranchGLCodesOption(fmv_general_ledgerList)
        })
      }

      var controlName = ["cmv_country", "fmv_general_ledger", "PaymentTermDays", "Regions"]
      if (combobox.current) {
        combobox.current.fillMasterData(controlName[0], "", "").then((cmv_countryList) => {
          setCountryOptions(cmv_countryList)
        })

        combobox.current.fillMasterData(controlName[1], "", "").then((fmv_branchgeneral_ledgerList) => {
          setBranchGLCodesOption(fmv_branchgeneral_ledgerList)
        })

        combobox.current.fillComboBox(controlName[2]).then((paymentTermDays) => {
          setPaymentTermOption(paymentTermDays)
        })

        combobox.current.fillComboBox(controlName[3]).then((regionList) => {
          setSupplierRegionOption(regionList)
        })
      }
      const apiCallCountryCodeList = await fetch(`${process.env.REACT_APP_BASE_URL}/api/country/FnFetchCountryCodes`)
      const respCountryCode = await apiCallCountryCodeList.json();
      console.log("apiCallCountryCodeList: ", respCountryCode)
      setCountryCodeOptions(respCountryCode)
      setsuppliercontactCountryCodeOptions(respCountryCode)

      combobox.current.fillMasterData("cmv_designation", "", "").then((masterList) => {
        setDesignationOptions(masterList)
      })

      combobox.current.fillMasterData("cmv_supplier_branch", "supplier_id", supplier_id).then((suppBranchList) => {
        setSupplierBranchOptions(suppBranchList)
      })

      //Bank starts Here
      const controlNameForBank = ["BankAccountsType", "fmv_currency", "cmv_banks_List"];
      if (combobox.current) {
        combobox.current.fillComboBox(controlNameForBank[0]).then((propertyList1) => {
          setBankAccountTypeOption(propertyList1)
        })
        //const vCurrencyType = await combobox.current.fillMasterData(controlNameForBank[1], "", "")
        combobox.current.fillMasterData(controlNameForBank[2], "", "").then((propertyList3) => {
          setBankNameOptionList(propertyList3)
        })
        // setCurrencyTypeOption(vCurrencyType)
      }
    } catch (error) {
      console.log('error: ', error)
      navigate('/Error')
    }
  }

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Supplier Type':
        combobox.current.fillComboBox("CompanyTypes").then((supplierType) => {
          setSupplierTypeOption(supplierType)
        })
        break;

      // case 'Supplier Sector':
      //   combobox.current.fillComboBox("CompanySectors").then((supplierSector) => {
      //     setSupplierSectorOption(supplierSector)
      //   })
      //   break;

      case 'Payment Terms':
        await addRecordInProperty("PaymentTermDays");
        break;

      case 'City':
        if (cmb_supp_branch__District_id !== '' && cmb_supp_branch__District_id !== undefined) {
          resetGlobalQuery();
          globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
          globalQuery.table = "cmv_city"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
          globalQuery.conditions.push({ field: "district_id", operator: "=", value: cmb_supp_branch__District_id });
          combobox.current.removeCatcheFillCombo(globalQuery).then((propertyList2) => {
            setCityOptions(propertyList2)
          })
        }
        break;

      case 'Payment Terms':
        combobox.current.fillComboBox("PaymentTermDays").then((paymentTermDays) => {
          setPaymentTermOption(paymentTermDays)
        })
        break;
      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Supplier Type':
        return <FrmPropertyEntry btn_disabled={true} />;

      // case 'Supplier Sector':
      //   return <FrmPropertyEntry btn_disabled={true} />;

      case 'Payment Terms':
        return <FrmPaymentTermsEntry btn_disabled={true} />;

      case 'City':
        return <FrmCity btn_disabled={true} />;

      default:
        return null;
    }
  }

  // .........................Supplier branch.......................................................................

  const OpenAddBranch = async () => {
    debugger
    clearFormFields();
    await FillComobos();
    removeReadAttr();
    setSupplierPaymentTerm('151');
    setFormHeading("Supplier Branch Information")
    $("#branchSaveBtn").attr('disabled', false);
    $("input[type=radio]").attr('disabled', false);
    // $("#suppbranchName").attr('disabled', false);
    // $("#shortNameId").attr('disabled', false);
    $("input[name='isBranchType']").prop("disabled", true);
    if (data.length !== 0) {
      setBranchType('Sub');
    }
    // setSupplierContactData(contactBlankObject)

  }

  const deleteBranch = async () => {
    debugger
    try {
      const method = { method: 'DELETE' }
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/supplier/FnDeleteSupplierBranchRecord/${supplier_id}/${supplierBranchId}/${UserName}`, method)
      const response = await apiCall.json();
      console.log('Success:', response);
      setShowDeleteModal(false)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      var suppBranchList = await combobox.current.fillMasterData("cmv_supplier_branch", "supplier_id", supplier_id);
      setSupplierBranchOptions(suppBranchList);

      // Get the updated contacts-data. and set it.
      if (response.error !== "") {
        let contactData = response.suppContact
        if (contactData.length === 0) {
          const getExistingSupplierContactData = [...suppliercontactdata]
          getExistingSupplierContactData.push(contactBlankObject)
          setSupplierContactData(getExistingSupplierContactData)
        } else {
          setSupplierContactData(contactData)
        }
      }

    } catch (error) {
      console.log('error:', error);
      navigate('/Error')
    }
  }

  const exporttoPdf = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);

      if (filtrdata.length !== 0) {
        var filtrObjKeys = Object.keys(pdfExpFilters);
        for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
          var key = filtrObjKeys[objKey];
          var value = pdfExpFilters[key];
        }
      }
      if (dataExport.length !== 0) {
        pdfExp.current.pdf(dataExport, columnExport, reportName, pdfExpFilters)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }

  }

  const exporttoExcel = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        var jsonToExportExcel = { 'allData': {}, 'columns': columnExport, 'filtrKeyValue': {}, 'headings': {}, 'key': 'reportExport' }

        if (filtrdata.length !== 0) {
          var filtrObjKeys = Object.keys(pdfExpFilters);
          for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
            var key = filtrObjKeys[objKey];
            var value = pdfExpFilters[filtrObjKeys[objKey]];
            jsonToExportExcel['filtrKeyValue'][objKey] = key + ' : ' + value
          }
        }

        for (let arrKey = 0; arrKey < dataExport.length; arrKey++) {
          jsonToExportExcel['allData'][arrKey] = dataExport[arrKey];
        }
        jsonToExportExcel['headings']['ReportName'] = reportName
        jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
        jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')

        console.log("jsonToExportExcel: ", jsonToExportExcel)
        exlsExp.current.excel(jsonToExportExcel, reportName)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }

  const exporttoJSON = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        let data = dataExport.map(element => {
          return columnExport.reduce((dataJson, col) => {
            dataJson[col.accessor] = element[col.accessor];
            return dataJson;
          }, {});
        });
        jsonExp.current.json(data, reportName)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }


  function toggleFilter() {
    $(this).text(function (_, currentText) {
      return currentText == "▼" ? "▲" : "▼";
    });
    $('.hide-show-filters').toggle('fast');
  };
  const exportToCSV = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        let data = dataExport.map(element => {
          return columnExport.reduce((dataJson, col) => {
            dataJson[col.accessor] = element[col.accessor];
            return dataJson;
          }, {});
        });
        csvExp.current.csv(data, columnExport, reportName)
      }

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }


  const pageEntriesOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "All", value: 0 },
  ]
  const [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[2].value);
  const [pageCount, setpageCount] = useState(0);
  var [PageCurrent, setcurrentPage] = useState(0);



  const validateWebSite = (obj) => {
    var validateWebSite = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (obj.value === "" || validateWebSite.test(obj.value)) {
      $("#error_webSite").hide();
    } else if (obj.value !== "" && !validateWebSite.test(obj.value)) {
      $("#error_webSite").text("Please enter valid url!...");
      $("#error_webSite").show();
    }
  }
  const globalSearchOnChange = async () => {
    debugger
    if (entriesPerPage !== 0) {
      setcurrentPage(0)
      setEntriesPerPage(0)
      await fetchFilteredData(0, 0, selectedColumns.length !== 0 ? selectedColumns : availableColumns)
    }
    setSearchInputValue(searchState)
  }

  const FnRenderAdditionalInputsOnDateChange = (filter) => {
    if (filter.type === 'D' && document.getElementById(`operators_${filter.id}_id`).value === '<>=') {
      const filterTblRow = document.getElementById(`${filter.id}`);
      const filterTblRowTd = document.createElement('td');

      // Create the first input element
      const dateInput = document.createElement('input');
      dateInput.type = 'date';
      dateInput.id = `values_${filter.id}_id_2`;
      dateInput.className = 'erp_form_control erp_input_field inputValue erp_operator_val ms-4';

      filterTblRowTd.appendChild(dateInput);
      filterTblRow.appendChild(filterTblRowTd);
    } else {
      // Remove the existing td if it exists
      const existingTd = document.getElementById(`values_${filter.id}_id_2`);
      if (existingTd) {
        existingTd.parentNode.removeChild(existingTd);
      }
    }
    return null;
  };
  const validateNo = (noKey) => {
    const regexNo = /^[0-9\b]+$/;
    const telephoneRegex = /^[0-9+\(\)#\.\s\/ext-]+$/;
    const value = noKey.target.value;
    switch (noKey.target.id) {
      case 'pinCode':
        if (regexNo.test(value) || value === '') {
          setSuppBrachPincode(value)
        }
        break;
      case 'phoneNo':
        if (telephoneRegex.test(value) || value === '') {
          setSuppBranchPhoneNo(value)
        }
        break;
      case 'cellNo':
        debugger
        if (regexNo.test(value) || value === '') {
          setSuppBranchCellNo(value)
          setUserName(value)
        }
        break;
    }
  }

  const fetchFilteredData = async (page, size, availCols) => {
    debugger
    try {
      setIsLoading(true)
      const executeQuery = await submitQuery(availCols);

      const formData = new FormData();
      formData.append(`jsonQuery`, executeQuery)
      const requestOptions = { method: 'POST', body: formData };

      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnMShowFilterRecords/${page}/${size}`, requestOptions)
      const responce = await fetchResponse.json();
      const total = responce.totalElements;
      setpageCount(parseInt(size) !== 0 ? Math.ceil(total / size) : 1);
      console.log("Responce when fetch FilteredData : ", responce)
      var nColumn = [];
      if (responce.content.length > 0) {
        // Get column Name & Accessor
        let colNameAccessor;
        let colNameHeader;
         

        for (let colKey = 0; colKey < availCols.length; colKey++) {
          colNameAccessor = availCols[colKey].accessor
          colNameHeader = availCols[colKey].Headers
          if (colKey === 0) {
            nColumn.push({
              Headers: "Action", accessor: "Action",
              Cell: row => (
                <div style={{ display: "flex" }}>
                  <Tooltip title="view" placement="top">
                    <MDTypography className="erp-view-btn" >
                      <AiFillEye className="erp-view-btn" onClick={(e) => viewUpdateDelete(row.original, 'view')} />
                    </MDTypography>
                  </Tooltip>
                  {compType === 'Register' ?
                    null : <>
                      <Tooltip title="Edit" placement="top">
                        <MDTypography className="erp-view-btn" >
                          <MdModeEdit className="erp-edit-btn" onClick={e => viewUpdateDelete(row.original, 'update')} />
                        </MDTypography>
                      </Tooltip>
                      <Tooltip title="Delete" placement="top">
                        <MDTypography className="erp-view-btn" >
                          <MdDelete className="erp-delete-btn" onClick={(e) => viewUpdateDelete(row.original, 'delete')} />
                        </MDTypography>
                      </Tooltip>
                    </>
                  }
                </div>
              ),
            });
          }
          nColumn.push({ Headers: colNameHeader, accessor: colNameAccessor });
        }
        $("#tablebranchlist").show();
        $('#filter_display_controls').show();
        $('#display_exports').show();
        $('#filter_display').show();
        debugger;
        let mainBranch = responce.content.find(branch => branch.branch_type === 'Main')
        if (mainBranch) {
          supplierMainBranchId = mainBranch.supp_branch_id
          setBranchID(mainBranch.supp_branch_id)
        }

        $('#NoRcrdId').hide();
        setColumns([]);
        setColumns(nColumn);
        setSupplierBranchData(responce.content)
       
      } else {
        setColumns([]);
        setSupplierBranchData([])
        $('#NoRcrdId').show();
        $("#tablebranchlist").hide();
        // $('#filter_display_controls').hide();
        $('#display_exports').hide();
        $('#filter_display').hide();
        

      }
      return responce;
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')

    } finally {
      setIsLoading(false)
    }
  };



  const reload = () => {
    window.location.reload();
  }

  // Form Heading 
  const [formHeading, setFormHeading] = useState('Supplier Branch Information');

  var operatorLabels = { "=": "is", "!=": "is not", "1": "active", "0": "closed", "o": "open", "!*": "none", "*": "any", "~": "contains", "!~": "doesn't contain", "^": "starts with", "$": "ends with", "<=": "less than or equal", ">=": "greater than or equal", '<>=': 'BETWEEN' };
  var operatorByType = {
    "list": ["=", "!="], "status": ["1", "0"], "list_status": ["o", "=", "!", "c", "*"], "list_optional": ["=", "!", "!*", "*"],
    "list_subprojects": ["*", "!*", "=", "!"], "string": ["~", "=", "!~", "!=", "^", "$"], "text": ["~", "!~", "^", "$", "!*", "*"],
    "integer": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"], "float": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"],
    "relation": ["=", "!", "=p", "=!p", "!p", "*o", "!o", "!*", "*"], "tree": ["=", "~", "!*", "*"], "date": ["=", "<=", '>=', '<>='],
  };

  const viewUpdateDelete = async (data, key) => {
    debugger
    setIsLoading(true);
    $('.BranchglCodeCheckBox').prop('checked', false);

    //setTotalSelectedBranchGLCodeCheckboxes(0);

    var suppbranch_id = data.supp_branch_id
    setBranchID(suppbranch_id)
    switch (key) {
      case 'update':
        setKeys('update')
        await infoForUpdate(suppbranch_id, 'update')
        break;
      case 'delete':
        debugger
        if (data.branch_type !== 'Main') {
          setShowDeleteModal(true);
        } else if (data.branch_type === 'Main') {
          setErrMsg("You couldn't delete the main branch...!")
          setShowErrorMsgModal(true)
        }
        // setShowDeleteModal(true);
        break;
      case 'view':
        setKeys('view')
        await infoForUpdate(suppbranch_id, 'view');
        return validationRef.current.readOnly('suppBranchFormId');
        break;
    }
    setIsLoading(false);
  }

  // <!------------Filter Start------------!>

  const addSelectedColumns = () => {
    const selectedOption = document.getElementById("availableColumns");
    const selectedValue = selectedOption.value;
    if (selectedValue) {
      const selectedColumn = availableColumns.find(column => column.accessor === selectedValue);
      if (selectedColumn) {
        setAvailableColumns(availableColumns.filter(column => column.accessor !== selectedValue));
        setSelectedColumns([...selectedColumns, selectedColumn]);
      }
    }
  }

  const moveAllToSelectedColumns = () => {
    setSelectedColumns([...selectedColumns, ...availableColumns]);
    setAvailableColumns([]);
  }


  const addAvailableColumns = () => {
    const selectedOption = document.getElementById("selectedColumns");
    const selectedValue = selectedOption.value;
    if (selectedValue) {
      const selectedColumn = selectedColumns.find(column => column.accessor === selectedValue);
      if (selectedColumn) {
        setAvailableColumns([...availableColumns, selectedColumn]);
        setSelectedColumns(selectedColumns.filter(column => column.accessor !== selectedValue));
      }
    }
  }



  const moveAllToAvailableColumns = () => {
    // Move all selected columns to available columns
    setAvailableColumns([...availableColumns, ...selectedColumns]);

    // Clear the selected columns
    setSelectedColumns([]);
  };


  const moveOptionUp = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex > 0) {
      const newSelectedColumns = [...selectedColumns];
      [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex - 1]] = [newSelectedColumns[selectedIndex - 1], newSelectedColumns[selectedIndex]];
      setSelectedColumns(newSelectedColumns);
    }
  };

  const moveOptionDown = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex < selectedColumns.length - 1 && selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex + 1]] = [newSelectedColumns[selectedIndex + 1], newSelectedColumns[selectedIndex]];
      setSelectedColumns(newSelectedColumns);
    }
  };

  const moveOptionTop = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
      setSelectedColumns([movedColumn, ...newSelectedColumns]);
    }
  };

  const moveOptionBottom = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
      setSelectedColumns([...newSelectedColumns, movedColumn]);
    }
  };

  const addFilterSelect = async () => {
    let masterList;
    let filterText = document.getElementById('add_filter_select');
    if (filterText.value !== '0') {
      document.querySelectorAll("#add_filter_select option").forEach(opt => {
        if (opt.value === filterText.value) {
          opt.disabled = true;
        }
      });
      const value = recordData[filterText.value];
      const myArray = value.split(":");
      const newFilter = {
        id: filterText.value,
        label: myArray[1],
        type: myArray[3],
        dataKey: myArray[5],
        operatorArray: [],
        dataArray: []
      };
      switch (myArray[3]) {
        case 'C':
          if (myArray[5] === 'O') {
            resetGlobalQuery();
            globalQuery.columns.push(`DISTINCT (${filterText.value})`);
            globalQuery.conditions.push({
              field: "company_id",
              operator: "=",
              value: COMPANY_ID
            });
            globalQuery.conditions.push({
              field: "is_delete",
              operator: "=",
              value: 0
            });
            globalQuery.table = myArray[4]
            masterList = await combobox.current.fillFiltersCombo(globalQuery)
          } else {
            masterList = await combobox.current.fillMasterData(myArray[4], "", "")
          }

          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = masterList
          break;
        case 'P':
          let propertyList = await combobox.current.fillComboBox(myArray[4])
          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = propertyList
          break;
        case 'T':
          newFilter.operatorArray = operatorByType.string
          break;
        case 'H':
          // Extracting values within parentheses from the last element
          const valuesInParentheses = myArray[myArray.length - 1].slice(1, -1);
          // Splitting the string into an array of values
          const resultArray = valuesInParentheses.split(',');
          console.log(resultArray);
          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = resultArray
          break;
        case 'D':
          newFilter.operatorArray = operatorByType.date
          break;
      }
      // Update the state with the new filter
      setSelectedFilters(prevFilters => [...prevFilters, newFilter]);
    }
  }

  const removeFilter = (filterId) => {
    // Remove the filter from the state
    setSelectedFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
    document.querySelectorAll("#add_filter_select option").forEach(opt => {
      if (opt.value === filterId) {
        opt.disabled = false;
      }
    });
    $("#add_filter_select").val('0');
  };


  const submitQuery = async (availCols) => {
    debugger
    try {
      const requiredColumns = ['supp_branch_id']

      let selectBox = document.getElementById("selectedColumns");
      let group_by = document.getElementById("group_by").value;
      let jsonQuery = { 'viewname': {}, 'selectQuery': {}, 'whereQuery': {}, 'operators': {}, 'group_by': {}, "additionalParam": {}, "orderBy": {} };
      storeSelectedValues = []
      if (selectBox.length !== 0) {
        let selectOrder = []; // Array to store the order of elements
        let storeSelectedValues = []; // Assuming this is defined somewhere in your code

        // Loop through selectBox to populate selectQuery and selectOrder
        for (let index = 0; index < selectBox.length; index++) {
          let optionValue = selectBox.options[index].value;
          jsonQuery['selectQuery'][index] = optionValue;
          selectOrder.push(index); // Store the index in the order array
          storeSelectedValues.push(optionValue);
        }

        // Loop through requiredColumns to add missing elements in the specified order
        for (let index = 0; index < requiredColumns.length; index++) {
          const element = requiredColumns[index];
          if (!jsonQuery.selectQuery.hasOwnProperty(element)) {
            // Add the element at the end of the order array and assign the value
            selectOrder.push(selectOrder.length);
            jsonQuery['selectQuery'][selectOrder.length - 1] = element;
          }
        }

        // Now, construct the final selectQuery object using the specified order
        let finalSelectQuery = {};
        for (let orderIndex of selectOrder) {
          finalSelectQuery[orderIndex] = jsonQuery['selectQuery'][orderIndex];
        }

        // Replace the original selectQuery with the finalSelectQuery
        jsonQuery['selectQuery'] = finalSelectQuery;


      } else {
        for (let index = 0; index < availCols.length; index++) {
          let optionValue = availCols[index].accessor;
          jsonQuery['selectQuery'][optionValue] = optionValue;
        }
      }

      for (let selIndex = 0; selIndex < selectedFilters.length; selIndex++) {
        let fieldvalue = selectedFilters[selIndex].id.trim()
        let operatorSelectValue = document.getElementById(`operators_${fieldvalue}_id`).value;
        let valueSelectValue = document.getElementById(`values_${fieldvalue}_id`).value;
        if (selectedFilters[selIndex].type === 'T') {
          switch (operatorSelectValue) {
            case '~':
              operatorSelectValue = "LIKE"
              valueSelectValue = "%" + valueSelectValue + "%";
              break;
            case '!~':
              operatorSelectValue = "NOT LIKE"
              valueSelectValue = "%" + valueSelectValue + "%";
              break;
            case '^':
              operatorSelectValue = "LIKE"
              valueSelectValue = "%" + valueSelectValue;
              break;
            case '$':
              operatorSelectValue = "LIKE"
              valueSelectValue = valueSelectValue + "%";
              break;
            default:
              break;
          }
        }

        if (selectedFilters[selIndex].type === 'D' && operatorSelectValue === '<>=') {
          if (document.getElementById(`values_${fieldvalue}_id_2`).value !== '' && document.getElementById(`values_${fieldvalue}_id`).value !== '') {
            valueSelectValue = `${document.getElementById(`values_${fieldvalue}_id_2`).value}`
            operatorSelectValue = `BETWEEN '${document.getElementById(`values_${fieldvalue}_id`).value}' AND `
            // this for display filters on reports
            pdfExpFilters[selectedFilters[selIndex].label] = valueSelectValue + ` BETWEEN ` + `${document.getElementById(`values_${fieldvalue}_id`).value}`;
          } else {
            continue;
          }

        } else {
          // this for display filters on reports
          pdfExpFilters[selectedFilters[selIndex].label] = valueSelectValue;
        }
        jsonQuery['whereQuery'][fieldvalue] = valueSelectValue;
        jsonQuery['operators'][fieldvalue] = operatorSelectValue;
      }
      jsonQuery['group_by']["GROUP BY"] = group_by;
      jsonQuery['additionalParam']['is_delete'] = "0";
      // jsonQuery['additionalParam']['company_id'] = COMPANY_ID;
      jsonQuery['additionalParam']['supplier_id'] = supplier_id_ref.current;
      jsonQuery['orderBy']['ORDER BY'] = 'supp_branch_id'
      jsonQuery['viewname']['cmv_supplier_branch'] = 'cmv_supplier_branch'


      let executeQuery = JSON.stringify(jsonQuery)
      return executeQuery;

    } catch (error) {
      console.log("error", error);
      navigate('/Error')
    }
  }


  const fetchFilteredDataToExport = async (availCols) => {
    debugger
    try {
      setIsLoading(true)
      const executeQuery = await submitQuery(availCols);
      const formData = new FormData();
      formData.append(`jsonQuery`, executeQuery)
      const requestOptions = { method: 'POST', body: formData };
      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnShowFilterRecords`, requestOptions)
      const responce = await fetchResponse.json();

      let filterColumnsToExport = [];

      if (responce.content.length > 0) {
        // var newColumnHeads = responce.Headerkeys
        // Get column Name & Accessor
        let colNameAccessor;
        let colNameHeader;

        for (let colKey = 0; colKey < availCols.length; colKey++) {
          colNameAccessor = availCols[colKey].accessor
          colNameHeader = availCols[colKey].Headers

          filterColumnsToExport.push({ Headers: colNameHeader, accessor: colNameAccessor });
        }

        dataExport = responce.content
        columnExport = filterColumnsToExport
      } else {
        dataExport = []
        columnExport = []
      }
      return responce;
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  };


  const handlePageClick = async (pageNo) => {
    let currentPage = pageNo.selected;
    setcurrentPage(currentPage);
    const commentsFormServer = await fetchFilteredData(currentPage, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    console.log("commentsFormServer: ", commentsFormServer)

  }

  const handlePageCountClick = async () => {
    let count = document.getElementById("page_entries_id").value;
    setEntriesPerPage(count)
    setcurrentPage(0)
    await fetchFilteredData(0, count, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
  }


  const infoForUpdate = async (supp_branch_id, key) => {
    debugger
    setKeyVUD(key);
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/supplier/FnShowParticularRecordForUpdateForBranch/${supp_branch_id}`)
      const response = await apiCall.json();
      const data = response.suppBranchData;
      const suppBankData = response.bankData;

      var stateList = await combobox.current.fillMasterData("cmv_state", "country_id", JSON.stringify(data.supp_branch_country_id))
      setStateOptions(stateList)

      var districtList = await combobox.current.fillMasterData("cmv_district", "state_id", JSON.stringify(data.supp_branch_state_id))
      setDistrictOptions(districtList)

      var cityList = await combobox.current.fillMasterData("cmv_city", "state_id", JSON.stringify(data.supp_branch_state_id))
      setCityOptions(cityList)

      // resetGlobalQuery();
      // globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
      // globalQuery.table = "cmv_city"
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
      // globalQuery.conditions.push({ field: "district_id", operator: "=", value: data.supp_branch_district_id });
      // let cityList = await combobox.current.removeCatcheFillCombo(globalQuery);
      // setCityOptions(cityList)
      setBranchType(data.branch_type)
      setSupplyerBranchType(data.supp_branch_type)
      setSuppBranchName(data.supp_branch_name)
      setSuppBrachShortName(data.supp_branch_short_name)
      setSuppBrachVendorCode(data.supp_branch_vendor_code)
      setSuppBranchAdd1(data.supp_branch_address1)
      setSuppBranchAdd2(data.supp_branch_address2)
      setSuppBrachPincode(data.supp_branch_pincode)

      setCountry(data.supp_branch_country_id)
      setState(data.supp_branch_state_id)
      await FnComboOnChangeForSerach('Country', data.supp_branch_country_id)
      setCity(data.supp_branch_city_id)
      await FnComboOnChangeForSerach('State', data.supp_branch_state_id)
      setDistrict(data.supp_branch_district_id)
      setSuppBrachRegion(data.supp_branch_region)
      setSuppBranchPhoneNo(data.supp_branch_phone_no)
      setSuppBranchCellNo(data.supp_branch_cell_no)
      setSuppBranchEmailId(data.supp_branch_EmailId)
      setSuppBranchWebsite(data.supp_branch_website)
      setBranchLinkedinProfile(data.supp_branch_linkedin_profile)
      setBranchTwitterProfile(data.supp_branch_twitter_profile)
      setBranchFacebookProfile(data.supp_branch_facebook_profile)
      setBranchGSTNO(data.supp_branch_gst_no)
      setBranchGSTDivision(data.supp_branch_gst_division)
      setBranchGSTRange(data.supp_branch_gst_range)
      setBranchPanNo(data.supp_branch_pan_no)
      setBranchUdyogAdharNo(data.supp_branch_udyog_adhar_no)
      setBranchVatNo(data.supp_branch_vat_no)
      setSupplierBranchDataerviceTaxNo(data.supp_branch_service_tax_no)
      setBranchExciseNo(data.supp_branch_excise_no)
      setBranchCstNo(data.supp_branch_cst_no)
      setBranchBstNo(data.supp_branch_bst_no)
      await addRecordInProperty('PaymentTermDays')
      setBranchPaymentTermID(data.supp_branch_payment_term_id)
      setBranchRating(data.supp_branch_rating)
      await FnShowGlCodesForbranch();
      setBranchGLCodes(data.supp_branch_gl_codes)

      setBranchAccountsID(data.supp_branch_accounts_id)
      setBranchBlockedRemark(data.supp_branch_blocked_remark)
      setBranchPaymentBlockedRemark(data.supp_branch_payment_blocked_remark)
      setSuppBranchTallyId(data.supp_branch_tally_id)
      setSezName(data.sez_name)
      setBankData([])
      setSuppBranchActive(data.is_active)
      setSuppBranchBlock(data.supp_branch_blocked)
      setSuppBranchPaymentBlock(data.supp_branch_payment_blocked)
      setSuppIsSez(data.is_sez)
      setBankData(suppBankData);
      if (data.supp_branch_gl_codes !== null) {
        var checkboxesWithValue5 = data.supp_branch_gl_codes.split(':');
        var length = checkboxesWithValue5.length;
        $('.glcodeForBranch').text(length + ' Selected GL Codes ');
        setTotalSelectedBranchGLCodeCheckboxes(length);
      }

      if (key === "update") {
        setFormHeading("Modify Branch")
        $("input[type=radio]").attr('disabled', false);
        $("#branchSaveBtn").attr('disabled', false);
        $("input[name='isBranchType']").prop("disabled", true);
        $("#branchName").attr('disabled', true);
        removeReadAttr();
      } else if (key === "view") {
        setFormHeading("View Branch")
        $('#saveBtn').hide();
        // await validate.current.removeReadOnlyAttr("suppBranchFormId");
        $("input[type=radio]").attr('disabled', true);

        await validationRef.current.readOnly("suppBranchFormId");
        $('#bankform #formbody').hide();
        $("input[name='isBranchBlocked']").prop("disabled", true);
        $("input[name='isPaymentBlocked']").prop("disabled", true);
        $("input[name='isSez']").prop("disabled", true);
        $("input[name='isBranchSupplierType']").prop("disabled", true);

      }
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.log('error: ', error)
      navigate('/Error')
    }
  }

  const FnAddBank = async () => {
    setIsLoading(true);
    const checkIsBankValidate = await validateBankForm();
    if (checkIsBankValidate == true) {
      $('#error_bankNameId').text('This Bank already exits!').hide();
      const contactData = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        supplier_id: supplier_id,
        supp_branch_id: supplierBranchId,
        bank_id: $('#bankNameId').val(),
        supp_bank_account_type: $('#accountTypeId').val(),
        supp_bank_name: $('#bankNameId option:selected').text(),
        supp_bank_branch_name: bankBranchName,
        supp_bank_account_no: $('#bankAccountNo').val(),
        supp_bank_ifsc_code: bankIfscNo,
        supp_bank_swift_code: bankSwiftCode,
        supp_bank_gst_no: bankGstNo,
        currency_type: cmb_currency_type,
        is_active: is_bankActive,
        created_by: UserName,
      }

      const isDuplicate = dataBank.some((item) => item.supp_bank_name === contactData.supp_bank_name && item.supp_bank_account_type === contactData.supp_bank_account_type);
      if (!isDuplicate) {
        $('#accountTypeId').val('')
        $('#bankBranchName').val('');
        $('#bankNameId').val('');
        $('#bankAccountNo').val('');
        setBankAccountNo('');
        setBankIfscNo('');
        $('#bankSwiftCode').val('');
        $('#bankGstNo').val('');
        setCurrencyType("Rupees");
        setBankData((prevArray) => [...prevArray, contactData]);
      } else {
        $('#error_bankNameId').text('This Bank already exits!').show();
        setInterval(function () {
          $('#error_bankNameId').text('This Bank already exits!').hide();
        }, 3000);
      }
    }
    setIsLoading(false);
  }

  const renderTransporterBanks = useMemo(() => {
    return <>
      <hr className={`${dataBank.length === 0 ? 'd-none' : 'display'}`} />
      {dataBank.length !== 0 ?
        <Table className="erp_table" responsive bordered striped>
          <thead className="erp_table_head">
            <tr>
              <th className="erp_table_th" style={{ width: '40px' }}>Sr. No</th>
              <th className={keyFVUD === 'view' ? "erp_table_th d-none" : "erp_table_th"}>Action</th>
              <th className="erp_table_th">Account Type</th>
              <th className="erp_table_th">Bank Name</th>
              <th className="erp_table_th">Branch Name</th>
              <th className="erp_table_th">Account No</th>
              <th className="erp_table_th">IFSC No</th>
              <th className="erp_table_th">Swift Code</th>
              <th className="erp_table_th">GST No</th>
              <th className="erp_table_th">Currency Type</th>
            </tr>
          </thead>
          <tbody>
            {dataBank?.map((bankItem, index) => (
              <tr key={index}>
                <td className="erp_table_td">{index + 1}</td>
                <td className={`erp_table_td ${keyFVUD === 'view' ? "d-none" : "display"}`}>
                  <MdDelete className="erp-delete-btn" onClick={() => deleteBank(index)} />
                </td>
                <td className="erp_table_td ">{bankItem.supp_bank_account_type}</td>
                <td className="erp_table_td ">{bankItem.supp_bank_name}</td>
                <td className="erp_table_td ">{bankItem.supp_bank_branch_name}</td>
                <td className="erp_table_td ">{bankItem.supp_bank_account_no}</td>
                <td className="erp_table_td ">{bankItem.supp_bank_ifsc_code}</td>
                <td className="erp_table_td ">{bankItem.supp_bank_swift_code}</td>
                <td className="erp_table_td">{bankItem.supp_bank_gst_no}</td>
                <td className="erp_table_td">{bankItem.currency_type}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        : null}</>

  }, [dataBank])
  const deleteBank = (indexToRemove) => {

    const updatedDataBank = dataBank.filter((item, index) => index !== indexToRemove);
    setBankData(updatedDataBank);

  }

  //Validations
  // validation field
  const validate = async (functionType) => {
    debugger
    if (supplier_id === 0) {
      if (await validationRef.current.validateForm('supplierFormid') === true) {
        if (await validationRef.current.validateForm('suppBranchFormId') === true) {
          return true;
        }
      }
    } else {
      switch (functionType) {
        case 'suppInfo_bankInfo':
          if (await validationRef.current.validateForm('supplierFormid') === true) {
            if (await validationRef.current.validateForm('suppBranchFormId') === true) {
              return true;
            }
          }
          return false;

        case 'suppInfo':
          return await validationRef.current.validateForm('supplierFormid');

        case 'suppBranchInfo':
          return await validationRef.current.validateForm('suppBranchFormId');

        case 'suppContInfo':
          return validateSupplierContacts();

        case 'totalSuppEntryInfo':
          if (await validationRef.current.validateForm('supplierFormid') === true) {
            if (await validationRef.current.validateForm('suppBranchFormId') === true) {
              //     return validateSupplierContacts();
              //   } else {
              //     return false;
              //   }
              // } else {
              //   return false;
              // }
              return true;
            }
          }
          return false;
        default:
          break;
      }
    }
  };


  const validateFields = async (functionType) => {
    if (supplier_id === 0) {
      if (await validationRef.current.validateFieldsOnChange('supplierFormid') === true) {
        if (await validationRef.current.validateFieldsOnChange('suppBranchFormId') === true) {
          return true;
        }
      }
    } switch (functionType) {
      case 'suppInfo':
        return await validationRef.current.validateFieldsOnChange('supplierFormid');

      case 'suppBranchInfo':
        return await validationRef.current.validateFieldsOnChange('suppBranchFormId');

      case 'totalSuppEntryInfo':
        if (await validationRef.current.validateFieldsOnChange('supplierFormid') === true) {
          if (await validationRef.current.validateFieldsOnChange('suppBranchFormId') === true) {
            return true;
          }
        }
      default:
        break;
    }
  }

  const validateBankForm = () => {
    return validationRef.current.validateForm('bankform');
  }

  const validateBankFields = () => {
    return validationRef.current.validateFieldsOnChange('bankform');
  }

  const removeReadAttr = () => {
    return validationRef.current.removeReadOnlyAttr('suppBranchFormId');
  }

  const validateSupplierContacts = () => {
    let suppContactValid = true;
    const tableRows = document.querySelectorAll('#suppContactTb1 tbody tr');
    tableRows.forEach(row => {
      const suppContactName = row.querySelector('input[id^="supp_contact_person_"]').value;
      if (suppContactName === '') {
        row.querySelector('input[id^="supp_contact_person_"]').parentElement.dataset.tip = 'Please fill this Field...!';
        row.querySelector('input[id^="supp_contact_person_"]').focus();
        setOpenContactsAccord(true);
        return suppContactValid = false;
      } else {
        delete row.querySelector('input[id^="supp_contact_person_"]').parentElement.dataset.tip;
      }

      const suppContactNo = row.querySelector('input[id^="supp_contact_no_"]').value;
      if (suppContactNo === "") {
        row.querySelector('input[id^="supp_contact_no_"]').parentElement.dataset.tip = 'Please fill this Field...!';
        row.querySelector('input[id^="supp_contact_no_"]').focus();
        setOpenContactsAccord(true);
        return suppContactValid = false;
      } else if (suppContactNo !== "") {
        if (suppContactNo.length < 10) {
          row.querySelector('input[id^="supp_contact_no_"]').parentElement.dataset.tip = 'Contact number must be at least 10 digits...!';
          row.querySelector('input[id^="supp_contact_no_"]').focus();
          setOpenContactsAccord(true);
          return suppContactValid = false;
        }
      }


      const suppAlternateContactNo = row.querySelector('input[id^="supp_alternate_contact_"]').value;
      if (suppAlternateContactNo !== "") {
        if (suppAlternateContactNo.length < 10) {
          row.querySelector('input[id^="supp_alternate_contact_"]').parentElement.dataset.tip = 'Contact number must be at least 10 digits...!';
          row.querySelector('input[id^="supp_alternate_contact_"]').focus();
          setOpenContactsAccord(true);
          return suppContactValid = false;
        }
      }

      const suppEmailId = row.querySelector('input[id^="supp_email_id_"]').value;
      if (suppEmailId === "") {
        row.querySelector('input[id^="supp_email_id_"]').parentElement.dataset.tip = 'Please fill this Field...!';
        row.querySelector('input[id^="supp_email_id_"]').focus();
        return suppContactValid = false;
      } else if (suppEmailId !== "") {
        if (!validateNumberDateInput.current.validateEmail(suppEmailId)) {
          row.querySelector('input[id^="supp_email_id_"]').parentElement.dataset.tip = 'Please enter a valid email ';
          row.querySelector('input[id^="supp_email_id_"]').focus();
          setOpenContactsAccord(true);
          return suppContactValid = false;
        }
      }

      const suppAlternateEmailId = row.querySelector('input[id^="supp_alternate_EmailId_"]').value;
      if (suppAlternateEmailId !== "") {
        if (!validateNumberDateInput.current.validateEmail(suppAlternateEmailId)) {
          row.querySelector('input[id^="supp_alternate_EmailId_"]').parentElement.dataset.tip = 'Please enter a valid email ';
          row.querySelector('input[id^="supp_alternate_EmailId_"]').focus();
          setOpenContactsAccord(true);
          return suppContactValid = false;
        }
      }
    })

    // if (suppliercontactdata.length === 1 && suppliercontactdata[0].supp_contact_person !== '') {
    //   suppContactValid = true;
    // }
    return suppContactValid;
  }

  //Pincode validation
  const validatePincode = (pincodeVal) => {
    if (pincodeVal.length !== 6) {
      $('#error_pinCode').text('Please enter 6 digits pincode.....!').show();
    } else {
      $('#error_pinCode').hide();
    }
  };

  const clearFormFields = async () => {
    debugger
    setBranchID(0);
    if (data.length !== 0) {
      setBranchType('Sub');
    }

    setSuppBranchName('');
    setSuppBrachShortName('');
    setSuppBrachVendorCode(supplierCode);
    setSuppBranchAdd1('');
    setSuppBranchAdd2('');
    setSuppBrachPincode('');
    const defaultCountry = countryOptions.find(country => country.field_name === "India");
    setCountry(defaultCountry.field_id);
    // Load the State options.
    let getStateList = await combobox.current.fillMasterData("cmv_state", "country_id", defaultCountry.field_id)
    setStateOptions(getStateList);
    // Set the default State Gujarat.
    const defaultState = getStateList.find(state => state.field_name === "Gujarat");
    setState(defaultState.field_id);
    // Load the district options.
    // let getDistrictList = await combobox.current.fillMasterData("cmv_district", "state_id", defaultState.field_id)
    // setDistrictOptions(getDistrictList);
    let getCityList = await combobox.current.fillMasterData("cmv_city", "state_id", defaultState.field_id)
    setCityOptions(getCityList);
    // setDistrict('');
    setCity('');

    setSuppBrachRegion('NA');
    setSuppBranchPhoneNo('');
    setSuppBranchCellNo('');
    setSuppBranchEmailId('');
    setSuppBranchWebsite('');
    setBranchLinkedinProfile('');
    setBranchTwitterProfile('');
    setBranchFacebookProfile('');
    setBranchGSTNO('');
    setBranchGSTDivision('');
    setBranchGSTRange('');
    setBranchPanNo('');
    setBranchUdyogAdharNo('');
    setBranchVatNo('');
    setSupplierBranchDataerviceTaxNo('');
    setBranchExciseNo('');
    setBranchCstNo('');
    setBranchBstNo('');
    setBranchPaymentTermID('151');
    setBranchRating('');
    setBranchGLCodes('');

    setBranchAccountsID('');
    setBranchBlockedRemark('');
    setBranchPaymentBlockedRemark('');
    setSezName('');
    setSuppBranchTallyId('');

    setSuppBranchPaymentBlock(false);
    setSuppBranchBlock(false);
    setSuppIsSez(false);
    setSuppBranchActive(true);
    $('#CheckAllSupplierBranchGLCodes').prop('checked', false);
    $('.BranchglCodeCheckBox').prop('checked', false);
    setTotalSelectedBranchGLCodeCheckboxes(1);
    setBankData([]);
    $('.branch_gl_code_checkboxes input[value="5"]').prop('checked', true);

  }
  //for supplier code 
  const generateCustomerCode = async (tblName, fieldName, Length, companyTypeShortName, type) => {
    const data = {
      tblName: tblName, fieldName: fieldName, Length: Length, company_id: COMPANY_ID,
      additionalParam1: 'supplier_type',
      additionalParam1Value: document.getElementById('supplierType').value
    };
    const formData = new FormData();
    formData.append(`data`, JSON.stringify(data))
    const requestOptions = { method: 'POST', body: formData };
    try {
      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customer/FnGenerateCode/${COMPANY_ID}`, requestOptions)
      const resp = await fetchResponse.text();
      console.log("GenerateTAuto Api: ", resp)
      if (resp) {
        setSupplierCode(companyTypeShortName + '-' + type + resp);
        return companyTypeShortName + '-' + type + resp;
      }
    } catch (error) {
      console.error(error);
      navigate('/Error')
    }
  }

  // .........................Supplier contacts .......................................................................
  const fnsetRowCountAndAddRow = () => {
    debugger;
    const lastRowIndex = suppliercontactdata.length - 1;
    const lastRowContactPerson = suppliercontactdata[lastRowIndex].supp_contact_person;
    if (lastRowContactPerson !== '') {
      setRowCount(rowCount + 1);
    } else {
      const tableRows = document.querySelectorAll('#suppContactTb1 tbody tr');
      tableRows.forEach(row => {
        const suppContactName = row.querySelector('input[id^="supp_contact_person"]').value;
        if (suppContactName === '') {
          row.querySelector('input[id^="supp_contact_person"]').parentElement.dataset.tip = 'Please fill this Field...!';
          row.querySelector('input[id^="supp_contact_person"]').focus();
          return;
        } else {
          delete row.querySelector('input[id^="supp_contact_person"]').parentElement.dataset.tip;
        }
      })
    }
  };



  const FnShowSuppleirContactRecords = async () => {
    try {
      const SuppContactDataApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/suppliercontact/FnShowParticularRecordForUpdate/${supplier_id}`)
      const responce = await SuppContactDataApiCall.json();
      if (responce.data.length !== 0) {
        setSupplierContactData(responce.data)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }
  const FnUpdateSuppContactTblRows = async (rowData, event) => {
    let eventId = document.getElementById(event.target.id);
    let clickedColName = event.target.getAttribute('Headers');
    let enteredValue = event.target.value;

    switch (clickedColName) {

      case 'supp_contact_person':
        rowData[clickedColName] = enteredValue;
        let suppNameInp = eventId
        if (enteredValue === '') {
          suppNameInp.parentElement.dataset.tip = 'Please enter valid Name...!';
        } else {
          delete suppNameInp.parentElement.dataset.tip;
        }
        break;
      case 'supp_branch_id':
      case 'supp_designation':
        rowData[clickedColName] = enteredValue;
        rowData[clickedColName] = enteredValue;
        break;
      case 'supp_contact_no':
        rowData[clickedColName] = enteredValue;
        let suppcontactNo = eventId
        if (!validateNumberDateInput.current.validateNumber(enteredValue)) {
          suppcontactNo.parentElement.dataset.tip = 'Please enter valid Number...!';
        } else {
          delete suppcontactNo.parentElement.dataset.tip;
        }
      case 'supp_alternate_contact':
        rowData[clickedColName] = enteredValue;
        let suppNumInp = eventId
        if (!validateNumberDateInput.current.validateNumber(enteredValue)) {
          suppNumInp.parentElement.dataset.tip = 'Please enter valid Number...!';
        } else {
          delete suppNumInp.parentElement.dataset.tip;
        }
        break;
      case 'supp_email_id':
      case 'supp_alternate_EmailId':
        rowData[clickedColName] = enteredValue;
        let suppMailInp = eventId
        if (!validateNumberDateInput.current.validateEmail(enteredValue)) {
          suppMailInp.parentElement.dataset.tip = 'Please enter valid Mail...!';
        } else {
          delete suppMailInp.parentElement.dataset.tip;
        }
        if (event._reactName === 'onBlur' && enteredValue === '') {
          delete eventId.parentElement.dataset.tip;
        }
        break;

      case 'escalation_level':
        rowData[clickedColName] = event.target.value;
        break;
    }

    const suppliercontactsdetailData = [...suppliercontactdata]
    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
    suppliercontactsdetailData[arrayIndex] = rowData
    setSupplierContactData(suppliercontactsdetailData);
    return true;
  }

  const removeFirstRow = (indexToRemove) => {
    // if (keyForViewUpdate === 'update') {
    if (indexToRemove !== 0) {
      const updatedSupplierContactData = suppliercontactdata.filter((item, index) => index !== indexToRemove);
      setSupplierContactData(updatedSupplierContactData)

    } else {
      const updatedContactData = [...suppliercontactdata];  // Create a copy of the array
      updatedContactData[0] = { ...contactBlankObject }; // Set values of 0th index to the contactBlankObject
      setSupplierContactData(updatedContactData);
    }
    // }
  }

  const contactBlankObject = {
    company_id: COMPANY_ID,
    company_branch_id: COMPANY_BRANCH_ID,
    supplier_id: supplier_id,
    supp_branch_id: supplierBranchId,
    supplier_contact_id: 0,
    supp_contact_person: '',
    supp_designation: '',
    supp_contact_no: '',
    supp_email_id: '',
    supp_alternate_contact: '',
    supp_alternate_EmailId: '',
    escalation_level: ''
  }

  useLayoutEffect(() => {
    const getExistingSupplierContactData = [...suppliercontactdata]
    getExistingSupplierContactData.push(contactBlankObject)
    setSupplierContactData(getExistingSupplierContactData)
  }, [rowCount])

  const renderSupplierContactTable = useMemo(() => {
    // return <>
    //   <Table id='suppContactTb1' className={`erp_table erp_table_scroll`} bordered striped>
    //     <thead className='erp_table_head'>
    //       <tr>
    //         <th className={keyForViewUpdate === 'view' ? "erp_table_th d-none" : "erp_table_th"}>Action</th>
    return <Table id='suppContactTb1' className={`erp_table`} responsive bordered striped>
      <thead className="erp_table_head">
        <tr>
          {keyForViewUpdate !== 'view' ? (<th className="erp_table_th">Action</th>) : null}
          <th className='erp_table_th'>Supplier Contact Person</th>
          {/* <th className='erp_table_th'>Supplier Branch	</th> */}
          <th className='erp_table_th'>Supplier Designation	</th>
          <th className='erp_table_th'>Supplier Contact no</th>
          <th className='erp_table_th'>Supplier WhatsApp No</th>
          <th className='erp_table_th'>Supplier Email</th>
          <th className='erp_table_th'>Supplier Alternate Email</th>
          <th className='erp_table_th'>Supplier Escalation Level</th>
        </tr>
      </thead>

      <tbody>
        {
          suppliercontactdata?.map((item, index) =>
            <tr rowindex={index} className="sticky-column">
              <td className={keyForViewUpdate === 'view' ? "erp_table_td d-none" : "erp_table_td"}>
                <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                {/* <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCount(rowCount + 1)} /> */}
                <IoAddCircleOutline className='erp_trAdd_icon' onClick={fnsetRowCountAndAddRow} />

                {/* {index === 0 ? (
                    <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCount(rowCount + 1)} />) : null
                  } */}
              </td>
              <td className='erp_table_td'>
                <input type="text" id={`supp_contact_person_${index}`} className="erp_input_field mb-0" value={item.supp_contact_person}
                  onChange={(e) => { FnUpdateSuppContactTblRows(item, e); }} Headers='supp_contact_person' disabled={keyForViewUpdate === 'view' ? true : false}
                />
              </td>

              {/* <td className='erp_table_td'>
                  <select className="form-select form-select-sm mb-0" value={item.supp_branch_id}
                    id={`supp_contact_branchid_${index}`}
                    onChange={(e) => { FnUpdateSuppContactTblRows(item, e); }}
                    Headers='supp_branch_id'
                    disabled={keyForViewUpdate === 'view' ? true : false}
                  >
                    <option value='0'>Select</option>
                    {supplierBranchOptions.map(item =>
                      <option value={item.field_id}>{item.field_name}</option>
                    )
                    }
                  </select>
                </td> */}

              <td className='erp_table_td'>
                <input type="text"
                  className="erp_input_field mb-0"
                  id={`supp_contact_designation_${index}`}
                  value={item.supp_contact_designation}
                  onChange={(e) => { FnUpdateSuppContactTblRows(item, e); }}
                  Headers='supp_contact_designation' disabled={keyForViewUpdate === 'view'}
                />
              </td>

              <td className='erp_table_td'>
                <input type="text"
                  id={`supp_contact_no_${index}`}
                  className="erp_input_field mb-0"
                  value={item.supp_contact_no}
                  disabled={keyForViewUpdate === 'view' ? true : false}
                  onChange={(e) => {
                    if (validateNumberDateInput.current.isInteger(e.target.value)) {
                      FnUpdateSuppContactTblRows(item, e);
                    }
                  }}
                  Headers='supp_contact_no'
                  maxLength="10" />
              </td>

              <td className='erp_table_td'>
                <input type="text"
                  id={`supp_alternate_contact_${index}`}
                  className="erp_input_field mb-0"
                  value={item.supp_alternate_contact}
                  onChange={(e) => {
                    if (validateNumberDateInput.current.isInteger(e.target.value)) {
                      FnUpdateSuppContactTblRows(item, e);
                    }
                  }}
                  Headers='supp_alternate_contact'
                  disabled={keyForViewUpdate === 'view' ? true : false}
                  maxLength="10" />
              </td>

              <td className='erp_table_td'>
                <input type="email"
                  id={`supp_email_id_${index}`}
                  className="erp_input_field mb-0"
                  value={item.supp_email_id}
                  onChange={(e) => { FnUpdateSuppContactTblRows(item, e); }}
                  onBlur={(e) => { FnUpdateSuppContactTblRows(item, e); }}
                  disabled={keyForViewUpdate === 'view' ? true : false}
                  Headers='supp_email_id' />
              </td>

              <td className='erp_table_td'>
                <input type="email"
                  id={`supp_alternate_EmailId_${index}`}
                  className="erp_input_field mb-0"
                  value={item.supp_alternate_EmailId}
                  onChange={(e) => { FnUpdateSuppContactTblRows(item, e); }}
                  onBlur={(e) => { FnUpdateSuppContactTblRows(item, e); }}
                  disabled={keyForViewUpdate === 'view' ? true : false}
                  Headers='supp_alternate_EmailId' />
              </td>

              <td className='erp_table_td'>
                <input type="text"
                  id={`escalation_level`}
                  className="erp_input_field mb-0"
                  value={item.escalation_level}
                  onChange={(e) => { FnUpdateSuppContactTblRows(item, e); }}
                  disabled={keyForViewUpdate === 'view' ? true : false}
                  Headers='escalation_level' />
              </td>
            </tr>
          )}

      </tbody>
    </Table >
    // </>
  }, [suppliercontactdata, supplierBranchOptions])


  const reloadsuppbranchlist = async (response) => {
    debugger
    try {
      combobox.current.fillMasterData("cmv_supplier_branch", response).then((suppBranchList) => {
        setSupplierBranchOptions(suppBranchList)
      })
    } catch (error) {
      console.log('error: ', error)
      navigate('/Error')
    }
  }

  // ..............................................................................................................
  const validateEmail = async (email) => {
    var emailValidation = await validateNumberDateInput.current.validateEmail(email);
    if (emailValidation === true) {
      $('#error_email').text('Please Enter valid email...').hide();
    } else {
      $('#error_email').text('Please Enter valid email...').show();
    }
  }

  const FnAddUpdateSupplier = async (functionType) => {
    debugger
    try {
      setIsLoading(true)
      let checkIsValidate = await validate(functionType);
      if (checkIsValidate === true) {
        let json = {
          'SupplierMasterData': {}, 'SupplierBranchData': {}, 'SupplierBankData': [], 'SupplierContactData': [], 'commonIds': {
            'company_id': COMPANY_ID, 'company_branch_id': parseInt(COMPANY_BRANCH_ID), 'supplier_id': supplierId, 'supplier_branch_id': supplierBranchId, 'userName': UserName 
          },
          'saveKey': functionType
        }

        if (functionType === 'suppInfo' || functionType === 'suppInfo_bankInfo' || functionType === 'totalSuppEntryInfo') {
          const suppilermasterdata = {
            company_id: COMPANY_ID,
            company_branch_id: parseInt(COMPANY_BRANCH_ID),
            supplier_id: supplier_id,
            supplier_type: cmb_supplier_type,
            supplier_code: supplierCode,
            supplier_name: supplierName,
            supplier_short_name: supplierShortName,
            supplier_sector: supplierSector,
            nature_of_business: supplierNatureOfBusiness,
            payment_term_id: supplierPaymentTermID === '' ? 152 : supplierPaymentTermID,
            supplier_rating: supplierRating,
            supplier_gl_codes: supplierGlCodes === '' ? 5 : supplierGlCodes,
            supplier_accounts_id: supplierAccountsId,
            supplier_history: supplierHistory,
            username: txt_username,
            password: txt_password,
            is_active: suppIsActive,
            remark: remark,
            created_by: supplier_id === 0 ? UserName : null,
            modified_by: supplier_id !== 0 ? UserName : null
          };
          json.SupplierMasterData = suppilermasterdata
        }

        if (functionType === 'suppBranchInfo' || functionType === 'suppInfo_bankInfo' || functionType === 'totalSuppEntryInfo') {
          const branchData = {
            company_id: COMPANY_ID,
            company_branch_id: parseInt(COMPANY_BRANCH_ID),
            supplier_id: supplier_id,
            branch_type: _branchType,
            supp_branch_type: supp_branchType,
            supp_branch_id: supplierBranchId,
            supp_branch_name: txt_supp_branch_name,
            supp_branch_short_name: txt_supp_branch_short_name,
            supp_branch_vendor_code: txt_supp_branch_vendor_code,
            supp_branch_address1: txt_supp_branch_address1,
            supp_branch_address2: txt_supp_branch_address2,
            supp_branch_city_id: cmb_supp_branch__City_id,
            supp_branch_pincode: txt_supp_branch_pin_code,
            supp_branch_district_id: cmb_supp_branch__District_id,
            supp_branch_state_id: cmb_supp_branch_state_id,
            supp_branch_country_id: cmb_supp_branch__Country_id,
            supp_branch_region: cmb_supp_branch_region,
            supp_branch_phone_no: txt_supp_branch_phone_no,
            supp_branch_cell_no: txt_supp_branch_cell_no,
            supp_branch_EmailId: txt_supp_branch_email_id,
            supp_branch_website: txt_supp_branch_website === null || txt_supp_branch_website === '' ? 'NA' : txt_supp_branch_website.trim(),
            supp_branch_linkedin_profile: txt_supp_branch_linkedIn_profile === null || txt_supp_branch_linkedIn_profile === '' ? 'NA' : txt_supp_branch_linkedIn_profile.trim(),
            supp_branch_twitter_profile: txt_supp_branch_twitter_profile === null || txt_supp_branch_twitter_profile === '' ? 'NA' : txt_supp_branch_twitter_profile.trim(),
            supp_branch_facebook_profile: txt_supp_branch_facebook_profile === null || txt_supp_branch_facebook_profile === '' ? 'NA' : txt_supp_branch_facebook_profile.trim(),
            supp_branch_gst_no: txt_supp_branch_gst_no,
            supp_branch_gst_division: txt_supp_branch_gst_division,
            supp_branch_gst_range: txt_supp_branch_gst_range,
            supp_branch_pan_no: txt_supp_branch_pan_no,
            supp_branch_udyog_adhar_no: txt_supp_branch_udyog_adhar_no,
            supp_branch_vat_no: txt_supp_branch_vat_no,
            supp_branch_service_tax_no: txt_supp_branch_service_tax_no,
            supp_branch_excise_no: txt_supp_branch_excise_no,
            supp_branch_cst_no: txt_branch_cst_no,
            supp_branch_bst_no: txt_cust_branch_bst_no,
            supp_branch_payment_term_id: cmb_supp_payment_term_ID === '' ? 152 : cmb_supp_payment_term_ID,
            // supp_branch_payment_term_id: cmb_supp_payment_term_ID,
            supp_branch_rating: txt_supp_branch__ratings,
            supp_branch_gl_codes: cmb_supp_branch_gl_codes === '' ? 5 : cmb_supp_branch_gl_codes,
            supp_branch_accounts_id: txt_sopp_branch_accounts_id,
            supp_branch_blocked_remark: txt_supp_branch_blocked_remarks,
            supp_branch_payment_blocked_remark: txt_supp_payment_blocked_remarks,
            supp_branch_tally_id: txt_supp_branch_TallyID,
            is_active: suppBranchActive,
            supp_branch_blocked: suppBranchBlock,
            supp_branch_payment_blocked: suppBranchPaymentBlock,
            sez_name: txt_sez_name,
            is_sez: suppIsSez,
            created_by: supplierBranchId === 0 ? UserName : null,
            modified_by: supplierBranchId !== 0 ? UserName : null
          };
          json.SupplierBranchData = branchData
          //Bank Data

          json.SupplierBankData = dataBank;
        }

        //Contact Data strats here
        if (functionType === 'suppContInfo' || functionType === 'totalSuppEntryInfo') {
          if (suppliercontactdata.length === 1 && suppliercontactdata[0].supp_contact_person.trim() === '' && suppliercontactdata[0].supp_branch_id === '0') {
            json.SupplierContactData = [];
          } else {
            json.SupplierContactData = suppliercontactdata;
          }
          // $('#error_suppContact').text('Please Fill the Supplier Contact.....').css('display', 'none');
        }

        const formData = new FormData();
        formData.append(`SupplierServiceData`, JSON.stringify(json))
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/supplier/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json();
        console.log("response error: ", responce.data);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          setSupplierId(responce.suplierBranchData.supplier_id);
          supplier_id_ref.current = responce.suplierBranchData.supplier_id
          if (functionType === 'suppBranchInfo' || functionType === 'totalSuppEntryInfo') {
            clearFormFields();
            // Load the branch list.
            await reloadsuppbranchlist(responce.suplierBranchData.supp_branch_id)
          }

          console.log("supplier_id", responce.data);
          $('#supplierName').attr('disabled', true)
          $('#shortName').attr('disabled', true)

          setBankData([]);
          if (responce.data != null && responce.data.supplier_id !== 0 && suppliercontactdata.length === 0 && functionType === 'suppInfo_bankInfo') {
            $('#accessContactAcc').show();

            $('#save_suppInfoBank_Btn').hide();
            $('#error_suppContact').text('Please Fill the Supplier Contact.....').css('display', 'block');
          }
          const evitCache = combobox.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          localStorage.setItem("functionType", functionType);
          const availCols = await showReportRecords();
          await fetchFilteredData(PageCurrent, entriesPerPage, availCols)
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }


  const FnShowGlCodesForbranch = async () => {
    debugger
    if (GLCodesOption.length > 0) {
      await FnCreateBranchGLCodesChks();
      if (keyForViewUpdate === '') {
        $('.branch_gl_code_checkboxes input[value="5"]').prop('checked', true);

      }
      // Also check the already checked checkboxes.
      if (cmb_supp_branch_gl_codes !== '') {
        const glCodeIds = cmb_supp_branch_gl_codes.split(':');
        glCodeIds.forEach(function (contactId, index) {
          $('#glCodeCheckBox_' + glCodeIds[index]).prop('checked', true);
        });
        FnToggleCheckBoxes('PartiallyCheckBranchGLCode');
      }

      let checkBoxesContainer1 = document.getElementById("gl_code_ul_branch");

      if (!expandedGlCodeCombo) {
        checkBoxesContainer1.style.display = "block";

        expandedGlCodeCombo = true;

      } else {
        checkBoxesContainer1.style.display = "none";

        expandedGlCodeCombo = false;
      }

      // $('.gl_code_checkboxes').on('input blur', function (e) {
      //   FnGetSelectedGLCodesIds();
      // });

      $('.branch_gl_code_checkboxes').on('input blur', function (e) {
        FnGetSelectedBranchGLCodesIds();
      });

    }
  }

  const FnShowGlCodes = async () => {
    debugger
    if (GLCodesOption.length > 0) {
      await FnCreateGLCodesChks();

      // Assuming total_GlCode_chechboxes is a jQuery object containing the checkboxes
      if (keyForViewUpdate === '') {
        $('.gl_code_checkboxes input[value="5"]').prop('checked', true);

      }

      // Also check the already checked checkboxes.
      if (supplierGlCodes !== '') {
        const glCodeIds = supplierGlCodes.split(':');
        glCodeIds.forEach(function (contactId, index) {
          $('#glCodeCheckBox_' + glCodeIds[index]).prop('checked', true);
        });
        FnToggleCheckBoxes('PartiallyCheckGLCode');
      }


      let checkBoxesContainer = document.getElementById("gl_code_ul");
      if (!expandedGlCodeCombo) {

        checkBoxesContainer.style.display = "block";
        expandedGlCodeCombo = true;

      } else {

        checkBoxesContainer.style.display = "none";
        expandedGlCodeCombo = false;
      }

      $('.gl_code_checkboxes').on('input blur', function (e) {
        FnGetSelectedGLCodesIds();
      });

    }
  }


  const FnCreateGLCodesChks = async () => {
    if (GLCodesOption.length !== 0) {
      let checkboxes = GLCodesOption.map((item, index) => {
        if (index === 0) {
          return (
            <>
              <li className="item gl_code_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" id="CheckAllGLCodes" value={index} className="erp_radio_button"
                    onChange={(event) => FnToggleCheckBoxes('CheckAllGLCodes')} {...(keyForViewUpdate === "view" ? { disabled: true } : {})} />
                </span>
                <span className="item-text">All</span>
              </li>
              <li className="item gl_code_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" name="glCodeCheckBox" value={item.field_id} {...(keyForViewUpdate === "view" ? { disabled: true } : {})}
                    id={`glCodeCheckBox_${item.field_id}`} className="erp_radio_button glCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckGLCode')}
                  />
                </span>
                <span className="item-text">{item.field_name}</span>
              </li>
            </>
          );
        }
        return (
          <li className="item gl_code_checkboxes">
            <span className="checkbox">
              <input type="checkbox" name="glCodeCheckBox" value={item.field_id} id={`glCodeCheckBox_${item.field_id}`} {...(keyForViewUpdate === "view" ? { disabled: true } : {})}
                className="erp_radio_button glCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckGLCode')}
              />
            </span>
            <span className="item-text">{item.field_name}</span>
          </li>
        );
      });
      setGLCodesCheckboxes(checkboxes);
    }
  }



  const FnCreateBranchGLCodesChks = async () => {
    debugger
    if (GLCodesOption.length !== 0) {
      let checkboxes = GLCodesOption.map((item, index) => {
        if (index === 0) {
          return (
            <>
              <li className="item branch_gl_code_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" id="CheckAllSupplierBranchGLCodes" value={index} className="erp_radio_button"
                    onChange={(event) => FnToggleCheckBoxes('CheckAllSupplierBranchGLCodes')} {...(keyForViewUpdate === "view" || keyFVUD === "view" ? { disabled: true } : {})} />
                </span>
                <span className="item-text">All</span>
              </li>
              <li className="item branch_gl_code_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" name="BranchglCodeCheckBox" value={item.field_id} {...(keyForViewUpdate === "view" || keyFVUD === "view" ? { disabled: true } : {})}
                    id={`glCodeCheckBox_${item.field_id}`} className="erp_radio_button BranchglCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckBranchGLCode')}
                  />
                </span>
                <span className="item-text">{item.field_name}</span>
              </li>
            </>
          );
        }
        return (
          <li className="item branch_gl_code_checkboxes">
            <span className="checkbox">
              <input type="checkbox" name="BranchglCodeCheckBox" value={item.field_id} id={`glCodeCheckBox_${item.field_id}`} {...(keyForViewUpdate === "view" || keyFVUD === "view" ? { disabled: true } : {})}
                className="erp_radio_button BranchglCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckBranchGLCode')}
              />
            </span>
            <span className="item-text">{item.field_name}</span>
          </li>
        );
      });
      setBranchGLCodesCheckboxes(checkboxes);
    }
  }


  const FnToggleCheckBoxes = (checkboxType) => {
    switch (checkboxType) {
      case "CheckAllGLCodes":
        $('.glCodeCheckBox').prop('checked', $('#CheckAllGLCodes').is(":checked"));
        setTotalSelectedGLCodeCheckboxes($('input:checkbox.glCodeCheckBox:checked').length);
        // setCustomerGLCodes
        break;

      case 'PartiallyCheckGLCode':
        $('#CheckAllGLCodes').prop('checked', $('input:checkbox.glCodeCheckBox:checked').length === $('input:checkbox.glCodeCheckBox').length);
        setTotalSelectedGLCodeCheckboxes($('input:checkbox.glCodeCheckBox:checked').length);
        break;

      case "CheckAllSupplierBranchGLCodes":
        $('.BranchglCodeCheckBox').prop('checked', $('#CheckAllSupplierBranchGLCodes').is(":checked"));
        setTotalSelectedBranchGLCodeCheckboxes($('input:checkbox.BranchglCodeCheckBox:checked').length);
        // setCustomerGLCodes
        break;

      case 'PartiallyCheckBranchGLCode':
        $('#CheckAllSupplierBranchGLCodes').prop('checked', $('input:checkbox.BranchglCodeCheckBox:checked').length === $('input:checkbox.BranchglCodeCheckBox').length);
        setTotalSelectedBranchGLCodeCheckboxes($('input:checkbox.BranchglCodeCheckBox:checked').length);
        break;

      default:
        break;
    }
  }

  const FnGetSelectedGLCodesIds = () => {
    let selectedGLCodes = '';
    const checkboxes = $('.glCodeCheckBox:checked');
    checkboxes.each(function () {
      if ($(this).val() !== '0' && $(this).val() !== '') {
        selectedGLCodes += $(this).val() + ":";
      }
    });
    setSupplierGLCodes(selectedGLCodes.replace(/:$/, ''));
    return selectedGLCodes.replace(/:$/, '');
  }

  const FnGetSelectedBranchGLCodesIds = () => {
    debugger
    let selectedGLCodess = '';
    const checkboxesBranch = $('.BranchglCodeCheckBox:checked');

    checkboxesBranch.each(function () {
      if ($(this).val() !== '0' && $(this).val() !== '') {
        selectedGLCodess += $(this).val() + ":";
      }
    });
    setBranchGLCodes(selectedGLCodess.replace(/:$/, ''));
    return selectedGLCodess.replace(/:$/, '');
  }

  //**********************************************************code start for combo live serach**********************************************************************
  // Array of options for dropdowns
  const options = [
    { value: '0', label: 'Select', is_sez: false },
    { value: '0', label: 'Add New Record+', is_sez: false },

    // Mapping state options
    ...stateOptions.map(state => ({
      value: state.field_id,
      label: state.field_name,
    })),
    // Mapping city options
    ...cityOptions.map(city => ({
      value: city.field_id,
      label: city.field_name,
    })),
    // Mapping bank options
    ...bankNameOptionList.map(bank => ({
      value: bank.field_id,
      label: bank.field_name,
    })),
  ];


  // Function to handle dropdown value changes
  const handleChange = async (selectedOptionForSearch, selectId) => {
    debugger;
    switch (selectId) {
      // Handling state dropdown change
      case 'stateId':
        if (cmb_supp_branch_state_id !== '0' && cmb_supp_branch_state_id !== '') {
          $('#error_stateId').hide()
        }
        setState(cmb_supp_branch_state_id);
        await FnComboOnChangeForSerach('State', selectedOptionForSearch.value);
        break;
      // Handling city dropdown change
      case 'cityId':
        if (cmb_supp_branch__City_id !== '0' && cmb_supp_branch__City_id !== '') {
          $(`#error_cityId`).hide();
        }
        setCity(cmb_supp_branch__City_id);
        await FnComboOnChangeForSerach('City', selectedOptionForSearch.value);
        break;
      // Handling bank dropdown change
      case 'cmb_bank_id':
        if (cmb_bank_id !== '0' && cmb_bank_id !== '') {
          $(`#error_cmb_bank_id`).hide();
        }
        setBankId(selectedOptionForSearch.value);
        break;
      default:
        break;
    }
  };

  // Function to update search box keys based on API data
  const updateKeysForSearchBox = (apiData) => {
    const options = [
      { value: '', label: 'Select' },
      { value: '0', label: 'Add New Record+' },
      ...apiData.map(items => ({
        value: items.field_id,
        label: items.field_name
      }))
    ];
    return options;
  }

  // Function to handle dropdown changes for search
  const FnComboOnChangeForSerach = async (key, selectedId) => {
    debugger
    try {
      switch (key) {
        // Handling country dropdown change
        case 'Country':
          const getCountryId = document.getElementById('countryId').value;
          setCountry(getCountryId)
          if (getCountryId !== '') {
            $('#error_countryId').hide();
            var stateList = await combobox.current.fillMasterData("cmv_state", "country_id", getCountryId)
            const updatedStateOptions = updateKeysForSearchBox(stateList);
            setStateOptions(updatedStateOptions)
            setState('');
            setDistrictOptions([])
            setDistrict('');
            setCityOptions([]);
            setCity('');
            setSuppBrachRegion('')
          } else {
            setStateOptions([])
            setState('');
            setDistrictOptions([])
            setDistrict('');
            setCityOptions([]);
            setCity('');
            setSuppBrachRegion('')
          }
          break;
        // Handling state dropdown change
        case 'State':
          debugger
          const getStateId = selectedId;
          setState(getStateId)
          if (getStateId !== '') {
            $('#error_stateId').hide();
            var districtList = await combobox.current.fillMasterData("cmv_district", "state_id", getStateId)
            const updatedDistrictOptions = updateKeysForSearchBox(districtList);

            var getCityList = await combobox.current.fillMasterData("cmv_city", "state_id", getStateId)
            const updatedCityOptions = updateKeysForSearchBox(getCityList);

            setDistrictOptions([])
            setDistrictOptions(updatedDistrictOptions);
            setCityOptions(updatedCityOptions);
          } else {
            setDistrictOptions([])
            setDistrictOptions([])
            setDistrict('');
            setCityOptions([]);
            setCity('');
          }
          break;

        // Handling city dropdown change
        case 'City':
          debugger
          const propertyValCity = selectedId;
          if (propertyValCity === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('City')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }
          else {
            setCity(propertyValCity)
            // Set the pincode from the city list.
            // const selectedCity = cityOptions.find(city => city.field_id === parseInt(propertyValCity));
            // setSuppBrachPincode(selectedCity.city_pincode);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
            globalQuery.table = "cmv_city"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            globalQuery.conditions.push({ field: "city_id", operator: "=", value: propertyValCity });
            let getCityList = await combobox.current.removeCatcheFillCombo(globalQuery);
            //  setCityOptions(getCityList)
            setSuppBrachPincode(getCityList[0].city_pincode);
            if (propertyValCity !== "") {
              $('#error_cityId').hide();
            }
          }
          break;
      }
    } catch (error) {
      console.log("Error: " + error)
      navigate('/Error')
    }
  }
  //**********************************************************code end for combo live serach**********************************************************************

  return (
    <>
      <PdfExport ref={pdfExp} />
      <ExcelExport ref={exlsExp} />
      <JsonExport ref={jsonExp} />
      <CSVExport ref={csvExp} />
      <FrmValidations ref={validationRef} />
      <ComboBox ref={combobox} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress color="primary" />
            <span id="spinner_text" className="text-dark">Loading...</span>
          </div>
        </div> :
        null}

      <form id='supplierFormid'>
        <div className='card mt-4 px-2 py-2'>
          <div className='row'>
            <div className="col-sm-6 erp_form_col_div">
              <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Supplier Type<span className="required">*</span></Form.Label>
                </div>
                <div className="col">
                  <select id="supplierType" value={cmb_supplier_type} className="form-select form-select-sm" onChange={() => addRecordInProperty('supplierTypes')}>
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {supplierTypeOption?.map(supplierTypes => (
                      <option value={supplierTypes.property_name} suppTypeShortName={supplierTypes.property_value}>{supplierTypes.property_name}</option>
                      // <option value={supplierTypes.field_name}>{supplierTypes.field_name}</option>
                    ))}

                  </select>
                  <MDTypography variant="button" id="error_supplierType" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Supplier Code<span className="required">*</span></Form.Label>
                </div>
                <div className="col">
                  <Form.Control type="text" id="supplierCode" className="erp_input_field" value={supplierCode} onChange={e => { setSupplierCode(e.target.value); validateFields('suppInfo') }} maxLength="50" />
                  <MDTypography variant="button" id="error_supplierCode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Supplier Name<span className="required">*</span></Form.Label>
                </div>
                <div className="col">
                  <Form.Control type="text" id="supplierName" className="erp_input_field" value={supplierName} onChange={e => { setSupplierName(e.target.value); validateFields('suppInfo') }} maxLength="255" />
                  <MDTypography variant="button" id="error_supplierName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Short Name</Form.Label>
                </div>
                <div className="col">
                  <Form.Control type="text" id="shortName" className="erp_input_field" value={supplierShortName} onChange={(e) => { setSupplierShortName(e.target.value.toUpperCase()); validateFields('suppInfo'); }} maxLength="10" optional="optional" />
                  <MDTypography variant="button" id="error_shortName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                  </MDTypography>
                </div>
              </div>

              {/* <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Supplier Sector</Form.Label>
                </div>
                <div className="col">
                  <select size="sm" id="supplierSectorId" value={supplierSector} className="form-select form-select-sm optional" onChange={() => addRecordInProperty('suppleirSectors')} optional="optional">
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {supplierSectorOption?.map(supplierSec => (
                      <option value={supplierSec.field_name}>{supplierSec.field_name}</option>
                    ))}
                  </select>

                  <MDTypography variant="button" id="error_supplierSectorId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div> */}

              {/* <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Nature Of Business</Form.Label>
                </div>
                <div className="col">
                  <Form.Control as="textarea" rows={1} id="natureOfBusiness" className="erp_txt_area" value={supplierNatureOfBusiness} onChange={e => { setSupplierNatureOfBusiness(e.target.value); validateFields('suppInfo') }} maxlength="255" optional="optional" />
                  <MDTypography variant="button" id="error_natureOfBusiness" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div> */}


            </div>


            <div className="col-sm-6">
              {/* <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Supplier Rating</Form.Label>
                </div>
                <div className="col">
                  <Form.Control as="textarea" rows={1} id='supplierRating' className="erp_txt_area" value={supplierRating} onChange={e => { setSupplierRating(e.target.value); validateFields('suppInfo') }} maxlength="255" optional="optional" />
                  <MDTypography variant="button" id="error_supplierRating" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div> */}
              <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Payment Term</Form.Label>
                </div>
                <div className="col">
                  <select size='sm' id='supplierPaymentTermID' className='form-select form-select-sm' value={supplierPaymentTermID} onChange={() => addRecordInProperty('PaymentTermDays')} optional="optional">
                    <option value=''>Select</option>
                    <option value="0">Add New Record+</option>
                    {
                      paymentTermOption?.map(paymentTerm => (
                        <option value={paymentTerm.field_id}>{paymentTerm.field_name}</option>
                      ))
                    }
                  </select>
                  <MDTypography variant="button" id="error_supplierPaymentTermID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>
              <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Supp. GL Codes</Form.Label>
                </div>
                <div className="col">
                  {/* <select id='supplierGLCodesId' className='form-select form-select-sm' value={supplierGlCodes} onChange={() => addRecordInProperty('fmv_general_ledger')} optional="optional">
                    <option value=''>Select</option>
                    <option value="0">Add New Record+</option>
                    {
                      GLCodesOption?.map(GLCodes => (
                        <option value={GLCodes.field_name}>{GLCodes.field_name}</option>
                      ))
                    }
                  </select> */}
                  <div className="select-btn" onClick={() => { FnShowGlCodes() }} optional='optional'>
                    <span className="form-select form-select-sm" id="">
                      {totalSelectedGLCodeCheckboxes !== 0 ? totalSelectedGLCodeCheckboxes + ' Selected GL Codes ' : 'Select GL Code'}
                    </span>
                  </div>
                  <ul className="list-items" id="gl_code_ul">
                    {GLCodesCheckboxes}
                  </ul>


                  <MDTypography variant="button" id="error_supplierGLCodesId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>
              <div className='row d-none'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">UserName <span className="required">*</span></Form.Label>
                </div>
                <div className='col'>
                  <Form.Control type="text" className="erp_input_field" id="txt_username" value={txt_username} onInput={(e) => { setUserName(e.target.value.trim()); validateFields('suppInfo') }} maxlength="200" ref={usernameRef} />
                  <MDTypography variant="button" id="error_txt_username" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row d-none'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Password <span className="required">*</span></Form.Label>
                </div>
                <div className='col-sm-8'>
                  <div className="input-group">
                    <Form.Control type={showPassword ? 'text' : 'password'} className="erp_input_field number" id="txt_password" value={txt_password} onInput={(e) => { setPassword(e.target.value.trim()); validateFields('suppInfo') }} maxlength="50" />
                    <span className="input-group-text" id="basic-addon2">
                      {showPassword ? (<AiFillEye onClick={togglePasswordhideandshow} />) : (<AiFillEyeInvisible onClick={togglePasswordhideandshow} />)}
                    </span>
                  </div>
                  <MDTypography variant="button" id="error_txt_password" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>
              <div className='row'>
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Is Active</Form.Label>
                </div>
                <div className="col">
                  <div className="erp_form_radio">
                    <div className="fCheck">
                      <Form.Check
                        className="erp_radio_button"
                        label="Yes"
                        type="radio"
                        value="1"
                        name="isSupplierActive"
                        defaultChecked />

                    </div>
                    <div className="sCheck">
                      <Form.Check
                        className="erp_radio_button"
                        label="No"
                        value="0"
                        type="radio"
                        name="isSupplierActive" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* {<div className="erp_frm_Btns">
                <MDButton type="button" id="suppSaveBtn" className={supplier_id === 0 ? "btn erp-gb-button d-none" : "btn erp-gb-button "} onClick={() => FnAddUpdateSupplier('suppInfo')} variant="button"
                  fontWeight="regular">Save  </MDButton>
              </div>
              } */}
            </div>
          </div>
        </div>
      </form>

      <hr />

      {/* --------------------Supplier Branch Starts Here ---------------------------------------------------- */}
      <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey="0">
          <Accordion.Header className="erp-form-label-md">Supplier Branch</Accordion.Header>
          <Accordion.Body>
            {/* <div id='filter_display' style={{ display: 'none' }}> */}
            {/* <div className={`${supplier_id === 0 ? 'd-none' : 'display'}`}> */}

            {isLoading ?
              <div className="spinner-overlay"  >
                <div className="spinner-container">
                  <CircularProgress color="primary" />
                  <span id="spinner_text" className="text-dark">Loading...</span>
                </div>
              </div> :
              null}
            <button className="erp_toggle-filter" onClick={toggleFilter}>
              {/* <AiOutlineDownCircle className="rotate_filtr_arrow" />
              <MDTypography component="label" variant="button" className="erp-form-label-md-lg">
                &nbsp; filters
              </MDTypography> */}
            </button>

            <div className="hide-show-filters card filter" style={{ display: "none" }}>
              <div className="row">

                <div className="col-sm-6 erp_filter_table_avl_col" >
                  <div className='container shadow h-100 rounded erp_group-resl_container'>
                    <div className='row erp_filter_row_tab_view'>
                      <div className='col-sm-4'>
                        <span>
                          <MDTypography component="label" variant="button" className="erp-form-label-md">
                            Available Columns
                          </MDTypography>
                          <select size="10" id="availableColumns" className="erp_form-select-sm-filter erp_form-select-filter">
                            {availableColumns?.map(column => (
                              <option value={column.accessor}>  {column.Headers} </option>
                            ))}
                          </select>

                        </span>
                      </div>

                      <div className='col-sm-1'>
                        <div className="buttons" id="buttons_row1">
                          <TbArrowsRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToSelectedColumns} /><br></br>
                          <HiOutlineArrowNarrowRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={addSelectedColumns} /><br></br>
                          <HiOutlineArrowNarrowLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={addAvailableColumns} /><br></br>
                          <TbArrowsLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToAvailableColumns} />
                        </div>
                      </div>

                      <div className='col-sm-4'>
                        <div className="col">
                          <MDTypography component="label" variant="button" className="erp-form-label-md">
                            Selected Columns
                          </MDTypography>

                          <select size="10" id="selectedColumns" className="erp_form-select-sm-filter erp_form-select-filter" onChange={e => setSelectedValue(e.target.value)}>
                            {selectedColumns.map((column, index) => (
                              <option key={index} value={column.accessor}>{column.Headers}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-1'>
                        <div className="buttons" id="buttons_row2">
                          <TbArrowsUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionTop} /><br></br>
                          <HiOutlineArrowNarrowUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionUp} /><br></br>
                          <HiOutlineArrowNarrowDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionDown} /><br></br>
                          <TbArrowsDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionBottom} />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>



                <div className="col-sm-6 erp_filter_group-by-result">
                  <div className='container shadow h-100 rounded erp_group-resl_container'>

                    <div className='row erp_group-reslut-by'>
                      <div className='col-sm-3 filtr_gropBy'>
                        <MDTypography
                          className="erp-form-label-md"
                          component="label"
                          variant="button"
                        >Group results by</MDTypography>
                      </div>
                      <div className='col filtr_gropBy'>
                        <Form.Select size="sm" className="erp_form_control operatorSelect" id="group_by">
                          <option value=""></option>
                          {groupByColumns?.map(column => (
                            <option value={column.accessor}> {column.Headers} </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="row add-filter add_filter_div">
                      <div className='col-sm-3'>
                        <MDTypography component="label" variant="button" className="erp-form-label-md">
                          Add Filter
                        </MDTypography>
                      </div>
                      <div className="col">
                        <Form.Select size="sm" onChange={addFilterSelect} className="erp_form_control group_by operatorSelect erp_add_filter" id="add_filter_select" >
                          <option value="0"></option>
                          {filterComboBox?.map(column => (
                            <option value={column.accessor}>{column.Headers}</option>

                          ))}
                        </Form.Select>
                      </div>
                    </div>


                    <table id="filters-table" className='erp-filters-table-scroll'>
                      <tbody>
                        {selectedFilters.map(filter => (
                          <tr id={filter.id} key={filter.id}>
                            <td>
                              <input
                                type="checkbox"
                                id={`cb_${filter.id}_id`}
                                value={filter.id}
                                checked
                                onClick={() => removeFilter(filter.id)}
                              />
                              <label className='erp-form-label'>&nbsp;{filter.label}</label>
                            </td>

                            <td>
                              {(filter.type === 'C' || filter.type === 'P' || filter.type === 'H') && (
                                <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                  {filter.operatorArray.map(operator => (
                                    <option key={operator} value={operator}>
                                      {operatorLabels[operator]}
                                    </option>
                                  ))}
                                </select>
                              )}
                              {filter.type === 'T' && (
                                <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                  {filter.operatorArray.map(operator => (
                                    <option key={operator} value={operator}>
                                      {operatorLabels[operator]}
                                    </option>
                                  ))}
                                </select>
                              )}

                              {filter.type === 'D' && (
                                <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control" onChange={() => FnRenderAdditionalInputsOnDateChange(filter)}>
                                  {filter.operatorArray.map(operator => (
                                    <option key={operator} value={operator}>
                                      {operatorLabels[operator]}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </td>

                            <td>
                              {filter.type === 'C' && (
                                <select
                                  id={`values_${filter.id}_id`}
                                  className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control"
                                >
                                  {filter.dataArray.map((item, index) => (
                                    <option
                                      key={index}
                                      value={filter.dataKey === 'O' ? item[filter.id] : item.field_name}
                                    >
                                      {filter.dataKey === 'O' ? item[filter.id] : item.field_name}
                                    </option>
                                  ))}
                                </select>
                              )}

                              {filter.type === 'P' && (
                                <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                  {filter.dataArray.map((item, index) => (
                                    <option key={index} value={item.field_name}>
                                      {item.field_name}
                                    </option>
                                  ))}
                                </select>
                              )}
                              {filter.type === 'T' && (
                                <input type="text" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />

                              )}
                              {filter.type === 'D' && (<>
                                <input type="date" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />
                              </>
                              )}
                              {filter.type === 'H' && (
                                <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                  {filter.dataArray.map((operator, index) => (
                                    <option key={index} value={operator}>
                                      {operator}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row btn_row_class">
              <div className="col-sm-8" >
                <MDButton className="btn erp-gb-button" variant="button" fontWeight="regular" onClick={OpenAddBranch}>Add Branch</MDButton> &nbsp;
                {/* <MDButton className="btn erp-gb-button" variant="button" fontWeight="regular" onClick={() => fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns)}>Apply Filter</MDButton> &nbsp; */}
                {/* <MDButton className="btn erp-gb-button" variant="button" fontWeight="regular" onClick={reload}>Clear Filter</MDButton>&nbsp; */}
                <MDButton type="button" id="viewdocument-id" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp;
                <span>
                  <span className="page_entries">
                    <MDTypography component="label" className="erp-form-label-md" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Entries per page</MDTypography>

                    <Form.Select onChange={handlePageCountClick} value={entriesPerPage} className="erp_page_select erp_form_control" id="page_entries_id" >
                      {pageEntriesOptions.map(pageEntriesOptions => (
                        <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                      ))}
                    </Form.Select>
                  </span>
                </span>
              </div>


              <div className="col-4 pagination_id">

                <span className="exports">
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoPdf()}>PDF<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoExcel()}>EXCEL<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exportToCSV()}>CSV<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoJSON()}>JSON<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;

                </span>
              </div>
            </div>

            {data.length !== 0 ? <>
              <div className="row mt-1">
                <div className="col-sm-3">
                  <div class="input-group">
                    <input type="text" className='erp_input_field form-control' style={{ height: '30px' }} value={searchState || ''}
                      onChange={(e) => setGlobalFilterSearch(e.target.value)} placeholder="Search!..." aria-describedby="basic-addon2" />
                    <span class="input-group-text" id="basic-addon2" onClick={() => { setSearchInputValue(''); setGlobalFilterSearch('') }}><FaTimes /></span>
                  </div>
                </div>
                <div className="col-sm-1">
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => globalSearchOnChange()}> <RiSearchLine className="erp-download-icon-btn" /> </MDButton>
                </div>
              </div>
              <Datatable data={data} columns={columns} freezeColumnCount={5} stateValue={searchInputValue} />

              {pageCount !== 1 ?
                <ReactPaginate
                  className='erp_pagination'
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link erp-gb-button"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link erp-gb-button"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link erp-gb-button"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"} /> : null}
            </> : <Card id="NoRcrdId" >
              <Card.Body>No records found...</Card.Body>
            </Card>}

            {/* <hr /> */}
            <form id='suppBranchFormId'>
              <div className={`row ${supplier_id !== 0 ? 'mt-5' : ''}`}>
                <div className='col-sm-4 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Branch Type<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form>
                        <div className="erp_form_radio">
                          <div className="fCheck">
                            <Form.Check className="erp_radio_button" label="Main" type="radio"
                              value="Main" checked={_branchType === 'Main'} onClick={() => { setBranchType('Main'); }}
                              name="isBranchType" defaultChecked disabled
                            />
                          </div>
                          <div className="sCheck">
                            <Form.Check className="erp_radio_button" label="Sub"
                              value="Sub" checked={_branchType === 'Sub'} onClick={() => { setBranchType('Sub'); }}
                              type="radio" name="isBranchType" disabled
                            />

                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Supp. Branch Type<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form>
                        <div className="erp_form_radio">
                          <div className="fCheck">
                            <Form.Check
                              className="erp_radio_button"
                              label="Supplier"
                              type="radio"
                              value="Supplier" checked={supp_branchType === 'Supplier'} onClick={() => { setSupplyerBranchType('Supplier'); }}
                              name="isBranchSupplierType"
                              defaultChecked
                            />
                          </div>
                          <div className="sCheck">
                            <Form.Check
                              className="erp_radio_button"
                              label="Consignee"
                              value="Consignee" checked={supp_branchType === 'Consignee'} onClick={() => { setSupplyerBranchType('Consignee'); }}
                              type="radio"
                              name="isBranchSupplierType"
                            />

                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Branch Name<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="suppbranchName" className="erp_input_field" value={txt_supp_branch_name} onChange={e => { setSuppBranchName(e.target.value); validateFields('suppBranchInfo') }} maxLength="255" />
                      <MDTypography variant="button" id="error_suppbranchName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>

                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Short Name</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="shortNameId" className="erp_input_field" value={txt_supp_branch_short_name} onChange={e => { setSuppBrachShortName(e.target.value.toUpperCase()); validateFields('suppBranchInfo') }} maxLength="20" optional="optional" />
                      <MDTypography variant="button" id="error_shortNameId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Vendor Code</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="branchVendorId" className="erp_input_field" value={txt_supp_branch_vendor_code} onChange={e => { setSuppBrachVendorCode(e.target.value); validateFields('suppBranchInfo') }} maxLength="255" optional="optional" />
                      <MDTypography variant="button" id="error_branchVendorId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Branch Address1<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={1} id="branchAddr1" className="erp_txt_area" value={txt_supp_branch_address1} onChange={e => { setSuppBranchAdd1(e.target.value); validateFields('suppBranchInfo') }} maxlength="500" />
                      <MDTypography variant="button" id="error_branchAddr1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Branch Address2</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={1} id="branchAddr2" className="erp_txt_area" value={txt_supp_branch_address2} onChange={e => { setSuppBranchAdd2(e.target.value); validateFields('suppBranchInfo') }} maxlength="500" optional="optional" />
                      <MDTypography variant="button" id="error_branchAddr2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Branch Pincode<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" className="erp_input_field number" id="pinCode" value={txt_supp_branch_pin_code} onChange={e => { validateNo(e); validateFields(e) }} maxLength="6" />
                      <MDTypography variant="button" id="error_pinCode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>


                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Branch Pincode<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" className="erp_input_field number" id="pinCode" value={txt_supp_branch_pin_code} onInput={e => { validatePincode(e.target.value) }} onChange={e => { validateNo(e); validateFields('suppBranchInfo') }}
                        maxLength="6" />
                      <MDTypography variant="button" id="error_pinCode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Country<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select size="sm" id="countryId" className="form-select form-select-sm" value={cmb_supp_branch__Country_id} onChange={() => FnComboOnChangeForSerach("Country")}>
                        <option value="">Select</option>
                        {countryOptions?.map(country => (
                          <option value={country.field_id}>{country.field_name}</option>

                        ))}

                      </select>
                      <MDTypography variant="button" id="error_countryId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">State<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select size="sm" id="stateId" className="form-select form-select-sm" value={cmb_supp_branch_state_id} onChange={() => addRecordInProperty("State")}>
                        <option value="">Select</option>
                        {stateOptions?.map(state => (
                          <option value={state.field_id}>{state.field_name}</option>

                        ))}

                      </select>
                      <MDTypography variant="button" id="error_stateId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">State<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Select
                        inputId="stateId" // Provide the ID for the input box
                        value={stateOptions.find(option => option.value === cmb_supp_branch_state_id)}
                        options={stateOptions.filter(option => option.label !== 'Add New Record+')} // Filter out the "Add New Record+" option
                        onChange={(selectedOptionForSearch) => handleChange(selectedOptionForSearch, 'stateId')}
                        placeholder="Search for state..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names

                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_stateId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>


                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">District</Form.Label>
                    </div>
                    <div className='col'>
                      <select size="sm" id="districtId" className="form-select form-select-sm" value={cmb_supp_branch__District_id} onChange={() => addRecordInProperty("District")} optional="optional">
                        <option value="">Select</option>
                        {districtOptions?.map(district => (
                          <option value={district.field_id}>{district.field_name}</option>

                        ))}

                      </select>
                      <MDTypography variant="button" id="error_districtId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">City<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cityId" value={cmb_supp_branch__City_id} className="form-select form-select-sm" onChange={() => addRecordInProperty("City")}>
                        <option value="">Select</option>
                        <option value="0">Add New Record +</option>
                        {cityOptions?.map(city => (
                          <option value={city.field_id}>{city.field_name}</option>

                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cityId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}


                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">City<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Select
                        inputId="cityId" // Provide the ID for the input box
                        value={cityOptions.find(option => option.value === cmb_supp_branch__City_id)}
                        options={cityOptions}
                        onChange={(selectedOptionForSearch) => handleChange(selectedOptionForSearch, 'cityId')}
                        placeholder="Search for a City..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names

                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }}
                      />
                      <MDTypography variant="button" id="error_cityId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Supplier Region<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select size='sm' id='supplierRegionID' className='form-select form-select-sm' value={cmb_supp_branch_region} onChange={() => addRecordInProperty('Regions')} maxlength="255">
                       
                        {
                          supplierRegionOption?.map(supplierRegion => (
                            <option value={supplierRegion.field_name}>{supplierRegion.field_name}</option>
                          ))
                        }
                      </select>
                      <MDTypography variant="button" id="error_supplierRegionID" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Phone NO.</Form.Label>
                    </div>
                    <div className='col'>
                      <span className='erp_phone' >
                        <select size="sm" id='phoneCntryId' className='form-select-phone'>
                          {countryCodeOptions?.map(option => (
                            <option value={option}>{option}</option>))}
                        </select>

                        <Form.Control type="text" className="erp_input_field erp_phn_border" id="phoneNo" value={txt_supp_branch_phone_no} onChange={e => { validateNo(e); validateFields('suppBranchInfo') }} optional="optional" maxLength="10" />
                      </span>
                      <MDTypography variant="button" id="error_phoneNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Cell NO.<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <span className='erp_phone' >
                        <select size="sm" id='cellCntryId' className='form-select-phone'>
                          {countryCodeOptions?.map(option => (
                            <option value={option}>{option}</option>

                          ))}
                        </select>
                        <Form.Control type="text" className="erp_input_field erp_phn_border" id="cellNo" value={txt_supp_branch_cell_no} onChange={e => { validateNo(e); validateFields('suppBranchInfo') }} maxLength="10" />

                      </span>
                      <MDTypography variant="button" id="error_cellNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>

                {/* Second Column */}
                <div className='col-sm-4 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Email</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="email" id="email" className="erp_input_field" value={txt_supp_branch_email_id} onInput={e => validateEmail(e.target.value)} onChange={e => { setSuppBranchEmailId(e.target.value); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_email" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Web Site</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="webSite" className="erp_input_field" value={txt_supp_branch_website} onChange={e => { setSuppBranchWebsite(e.target.value); validateWebSite(e.target) }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_webSite" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Linkedin Profile</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="linkdInProfile" className="erp_input_field" value={txt_supp_branch_linkedIn_profile} onChange={e => { setBranchLinkedinProfile(e.target.value); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_linkdInProfile" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Twitter Profile</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="twitterProfile" className="erp_input_field" value={txt_supp_branch_twitter_profile} onChange={e => { setBranchTwitterProfile(e.target.value); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_twitterProfile" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Facebook Profile</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="fbProfile" className="erp_input_field " value={txt_supp_branch_facebook_profile} onChange={e => { setBranchFacebookProfile(e.target.value); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_fbProfile" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">GST NO.
                        {cmb_supp_branch__Country_id != '1' ? null : <span className="required">*</span>}
                      </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="gstNo" className="erp_input_field" value={txt_supp_branch_gst_no} onChange={e => { setBranchGSTNO(e.target.value.split(' ').join('').toUpperCase()); validateFields('suppBranchInfo') }} optional={`${cmb_supp_branch__Country_id != "1" ? "optional" : ''}`} maxLength="15" />
                      <MDTypography variant="button" id="error_gstNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">GST Division</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='gstDivision' className="erp_input_field" value={txt_supp_branch_gst_division} onChange={e => { setBranchGSTDivision(e.target.value); validateFields('suppBranchInfo') }} maxLength="500" optional="optional" />
                      <MDTypography variant="button" id="error_gstDivision" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">GST Range</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="gstDivisionRange" className="erp_input_field" value={txt_supp_branch_gst_range} onChange={e => { setBranchGSTRange(e.target.value); validateFields('suppBranchInfo') }} maxLength="500" optional="optional" />
                      <MDTypography variant="button" id="error_gstDivisionRange" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">PAN NO.</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="panNo" className="erp_input_field" value={txt_supp_branch_pan_no} onChange={e => { setBranchPanNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('suppBranchInfo') }} maxLength="10" optional="optional" />
                      <MDTypography variant="button" id="error_panNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Udyog Aadhar NO.</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='UdyogAdharNo' className="erp_input_field" value={txt_supp_branch_udyog_adhar_no} onChange={e => { setBranchUdyogAdharNo(e.target.value); validateFields('suppBranchInfo') }} maxLength="12" optional="optional" />
                      <MDTypography variant="button" id="error_UdyogAdharNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">VAT NO.</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='VatNo' className="erp_input_field" value={txt_supp_branch_vat_no} onChange={e => { setBranchVatNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_VatNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Service Tax NO.</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='taxNo' className="erp_input_field" value={txt_supp_branch_service_tax_no} onChange={e => { setSupplierBranchDataerviceTaxNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_taxNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Excise NO.</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='exciseNo' className="erp_input_field" value={txt_supp_branch_excise_no} onChange={e => { setBranchExciseNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_exciseNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">CST NO.</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='cstNo' className="erp_input_field" value={txt_branch_cst_no} onChange={e => { setBranchCstNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_cstNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>
                {/* Third Column */}

                <div className='col-sm-4 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">BST NO.</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='bstNo' className="erp_input_field" value={txt_cust_branch_bst_no} onChange={e => { setBranchBstNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_bstNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Payment Term</Form.Label>
                    </div>
                    <div className='col'>
                      <select id='supplierbrachPaymentTermID' className='form-select form-select-sm' value={cmb_supp_payment_term_ID} onChange={() => addRecordInProperty('PaymentTermDaysbranch')} optional="optional">
                        <option value=''>Select</option>
                        <option value="0">Add New Record+</option>

                        {
                          paymentTermOption?.map(PaymentTermId => (
                            <option value={PaymentTermId.field_id}>{PaymentTermId.field_name}</option>
                          ))
                        }
                      </select>
                      <MDTypography variant="button" id="error_supplierPaymentTermID" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Branch Rating</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='branchRating' className="erp_input_field" value={txt_supp_branch__ratings} onChange={e => { setBranchRating(e.target.value); validateFields('suppBranchInfo') }} maxLength="255" optional="optional" />
                      <MDTypography variant="button" id="error_branchRating" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label"> GL Codes</Form.Label>
                    </div>
                    <div className='col'>
                      {/* <select id='suppBranchGLCodesId' className='form-select form-select-sm' value={cmb_cust_branch_gl_codes} onChange={() => addRecordInProperty('fmv_general_ledger')} optional="optional">
                          <option value='' >Select</option>
                          <option value="0">Add New Record+</option>
                          {GLCodesOption?.map(GLCodes => (
                            <option value={GLCodes.field_name}>{GLCodes.field_name}</option>
                          ))
                          }
                        </select> */}
                      <div className="select-btn" onClick={() => { FnShowGlCodesForbranch() }} optional='optional'>
                        <span className="form-select form-select-sm glcodeForBranch" id="">
                          {totalSelectedBranchGLCodeCheckboxes !== 0 ? totalSelectedBranchGLCodeCheckboxes + ' Selected GL Codes ' : 'Select GL Code'}
                        </span>
                      </div>
                      <ul className="list-items" id="gl_code_ul_branch">
                        {BranchGLCodesCheckboxes}
                      </ul>
                      <MDTypography variant="button" id="error_suppBranchGLCodesIdd" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    {/* <div className="col-sm-1 col-2">
                        <Tooltip title="Refresh" placement="top">
                          <MDTypography>
                            <MdRefresh className={`${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => { FnRefreshbtn("GLCodes"); }} style={{ color: 'black' }} />
                          </MDTypography>
                        </Tooltip>
                      </div> */}

                  </div>


                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label"> GL Codes</Form.Label>
                    </div>
                    <div className='col'>
                      <select id='suppBranchGLCodesId' className='form-select form-select-sm' value={cmb_supp_branch_gl_codes} onChange={() => addRecordInProperty('fmv_general_ledger_branch')} optional="optional">
                        <option value=''>Select</option>
                        <option value="0">Add New Record+</option>

                        {
                          BranchGLCodesOption?.map(GLCodes => (
                            <option value={GLCodes.field_name}>{GLCodes.field_name}</option>
                          ))
                        }
                      </select>
                      <MDTypography variant="button" id="error_suppBranchGLCodesIdd" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                    <div className="col-sm-1 ">
                      <Tooltip title="Refresh" placement="top">
                        <MDTypography className="erp-view-btn" >
                          <MdRefresh className="erp-view-btn" onClick={() => FnRefreshbtn("supplierGlCode")} style={{ color: 'black' }} />
                        </MDTypography>
                      </Tooltip>
                    </div>

                  </div> */}

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Branch A/C. ID</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='branchAccountId' className="erp_input_field" value={txt_sopp_branch_accounts_id} onChange={e => { setBranchAccountsID(e.target.value); validateFields('suppBranchInfo') }} maxLength="255" optional="optional" />
                      <MDTypography variant="button" id="error_branchAccountId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Branch Blocked</Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes"
                          value="true" checked={suppBranchBlock === true} onClick={() => { setSuppBranchBlock(true); }}
                          name="isBranchBlocked" /> </div>

                        <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No"
                          value="false" checked={suppBranchBlock === false} onClick={() => { setSuppBranchBlock(false); }}
                          name="isBranchBlocked" /> </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Blocked Remark {suppBranchBlock === true ? <span className="required">*</span> : ''}</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={1} className={`erp_txt_area ${suppBranchBlock === true ? '' : 'optional'} `} id="BlockRemarkId" value={txt_supp_branch_blocked_remarks} onChange={e => { setBranchBlockedRemark(e.target.value); validateFields('suppBranchInfo') }} {...(suppBranchBlock === false ? { optional: 'optional' } : {})} maxlength="255" />
                      <MDTypography variant="button" id="error_BlockRemarkId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>  <Form.Label className="erp-form-label">Payment Blocked</Form.Label> </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes"
                          value="true" checked={suppBranchPaymentBlock === true} onClick={() => { setSuppBranchPaymentBlock(true); }}
                          name="isPaymentBlocked" /> </div>

                        <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No"
                          value="false" checked={suppBranchPaymentBlock === false} onClick={() => { setSuppBranchPaymentBlock(false); }}
                          name="isPaymentBlocked" /> </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Payment Blocked Remark {suppBranchPaymentBlock === true ? <span className="required">*</span> : ''}</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={1} className={`erp_txt_area ${suppBranchPaymentBlock === true ? '' : 'optional'} `} id="paymentBlockRemarkId" value={txt_supp_payment_blocked_remarks} onChange={e => { setBranchPaymentBlockedRemark(e.target.value); validateFields('suppBranchInfo') }} {...(suppBranchPaymentBlock === false ? { optional: 'optional' } : {})} maxlength="255" />
                      <MDTypography variant="button" id="error_paymentBlockRemarkId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Is SEZ</Form.Label>
                    </div>

                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes"
                          name="isSez"
                          value="true" checked={suppIsSez === true} onClick={() => { setSuppIsSez(true); }}
                        /> </div>

                        <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No"
                          value="false" checked={suppIsSez === false} onClick={() => { setSuppIsSez(false); }}
                          name="isSez"
                        /> </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">SEZ Name {suppIsSez === true ? <span className="required">*</span> : ''}</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={1} className={`erp_txt_area ${suppIsSez === true ? '' : 'optional'} `} id="SezNameid" value={txt_sez_name} onChange={e => { setSezName(e.target.value); validateFields('suppBranchInfo') }} {...(suppIsSez === false ? { optional: 'optional' } : {})} maxlength="500" />
                      <MDTypography variant="button" id="error_SezNameid" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Tally ID</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id='tallyId' className="erp_input_field" value={txt_supp_branch_TallyID} onChange={e => { setSuppBranchTallyId(e.target.value); validateFields('suppBranchInfo') }} maxLength="50" optional="optional" />
                      <MDTypography variant="button" id="error_tallyId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Is Active</Form.Label>
                    </div>
                    <div className='col'>
                      <Form>
                        <div className="erp_form_radio">
                          <div className="fCheck">
                            <Form.Check
                              className="erp_radio_button"
                              label="Yes"
                              type="radio"
                              value="true" checked={suppBranchActive === true} onClick={() => { setSuppBranchActive(true); }}
                              name="isBranchActive"
                              defaultChecked
                            />

                          </div>
                          <div className="sCheck">
                            <Form.Check
                              className="erp_radio_button"
                              label="No"
                              value="false" checked={suppBranchActive === false} onClick={() => { setSuppBranchActive(false); }}
                              type="radio"
                              name="isBranchActive"
                            />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'></div>
                    <div className='col'></div>
                  </div>
                </div>
              </div>
            </form>



            <Accordion defaultActiveKey="1" >
              <Accordion.Item eventKey="0">
                <Accordion.Header className="erp-form-label-md">Branch Bank Selection:</Accordion.Header>
                <Accordion.Body>
                  <form id="bankform">
                    <div className="row" id='formbody'>
                      <div className="col-sm-6 erp_form_col_div">
                        <div className='row'>
                          <div className='col-sm-4'>
                            <Form.Label className="erp-form-label">Account Type<span className="required">*</span></Form.Label>
                          </div>

                          <div className="col">
                            <select size="sm" id="accountTypeId" className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('BankAccountsType'); validateBankFields() }}>
                              <option value="">Select</option>
                              {bankAccountTypeOption?.map(bankAcc => (
                                <option value={bankAcc.field_name}>{bankAcc.field_name}</option>
                              ))}
                            </select>
                            <MDTypography variant="button" id="error_accountTypeId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                            </MDTypography>
                          </div>
                        </div>

                        {/* <div className='row'>
                          <div className='col-sm-4'>
                            <Form.Label className="erp-form-label">Bank Name<span className="required">*</span></Form.Label>
                          </div>
                          <div className="col">
                            <select size="sm" id="bankNameId" className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('cmv_banks_List'); validateBankFields() }}>
                              <option value="">Select</option>
                              {bankNameOptionList?.map(bankName => (
                                <option value={bankName.field_id}>{bankName.field_name}</option>
                              ))}
                            </select>
                            <MDTypography variant="button" id="error_bankNameId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                            </MDTypography>
                          </div>
                        </div> */}

                        <div className='row'>
                          <div className='col-sm-4'>
                            <Form.Label className="erp-form-label">Bank Name<span className="required">*</span></Form.Label>
                          </div>
                          <div className='col'>
                            <Select
                              inputId="cmb_bank_id" // Provide the ID for the input box
                              value={bankNameOptionList.find(option => option.value === cmb_bank_id)}
                              options={bankNameOptionList.filter(option => option.label !== 'Add New Record+')}
                              onChange={(selectedOptionForSearch) => handleChange(selectedOptionForSearch, 'cmb_bank_id')}
                              placeholder="Search for bank..."
                              className="form-search-custom"
                              classNamePrefix="custom-select" // Add custom prefix for class names

                              styles={{
                                option: (provided, state) => ({
                                  ...provided,
                                  fontSize: '12px' // Adjust the font size as per your requirement
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  fontSize: '12px' // Adjust the font size as per your requirement
                                }),
                                input: (provided, state) => ({
                                  ...provided,
                                  fontSize: '12px' // Adjust the font size as per your requirement
                                })
                              }}
                            />
                            <MDTypography variant="button" id="error_cmb_bank_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                            </MDTypography>
                          </div>
                        </div>


                        <div className='row'>
                          <div className='col-sm-4'>
                            <Form.Label className="erp-form-label">Bank Branch Name<span className="required">*</span></Form.Label>
                          </div>
                          <div className='col'>
                            <Form.Control type="text" className="erp_input_field" id="bankBranchName"
                              onInput={(e) => {
                                let sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                                e.target.value = sanitizedValue;
                              }}
                              onChange={e => { setBankBranchName(e.target.value); validateBankFields() }} maxLength="255" />
                            <MDTypography variant="button" id="error_bankBranchName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                            </MDTypography>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-sm-4'>
                            <Form.Label className="erp-form-label">Bank A/C. No<span className="required">*</span></Form.Label>
                          </div>

                          <div className='col'>
                            <Form.Control type="text" className="erp_input_field" id="bankAccountNo" value={bankAccountNo}
                              onInput={(e) => {
                                if (validateNumberDateInput.current.isInteger(e.target.value)) {
                                  setBankAccountNo(e.target.value)
                                }; validateBankFields();
                                // let parsedValue = parseInt(e.target.value);
                                // if (isNaN(parsedValue)) {
                                //   e.target.value = '';
                                // } else { e.target.value = Math.max(0, parsedValue).toString().slice(0, 15); }
                              }}
                              maxLength="16"
                            // onChange={(e) => { validateNo(e); validateBankFields(); }}
                            />
                            <MDTypography variant="button" id="error_bankAccountNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                            </MDTypography>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-sm-4'>
                            <Form.Label className="erp-form-label">IFSC NO.<span className="required">*</span></Form.Label>
                          </div>

                          <div className='col'>
                            <Form.Control type="text" className="erp_input_field" id="bankIfscNo" value={bankIfscNo}
                              onChange={e => { setBankIfscNo(e.target.value.split(' ').join('').toUpperCase()); validateBankFields() }} maxLength="11" />
                            <MDTypography variant="button" id="error_bankIfscNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                            </MDTypography>
                          </div>
                        </div>
                      </div>


                      {/* Second column  */}

                      <div className="col-sm-6 erp_form_col_div">
                        <div className='row'>
                          <div className='col-sm-4'>
                            <Form.Label className="erp-form-label">Swift Code</Form.Label>
                          </div>
                          <div className='col'>
                            <Form.Control type="text" className="erp_input_field" id="bankSwiftCode" onChange={e => { setBankSwiftCode(e.target.value); validateFields('suppBranchInfo') }} maxLength="50" optional='optional' />
                            <MDTypography variant="button" id="error_bankSwiftCode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                            </MDTypography>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-sm-4'>
                            <Form.Label className="erp-form-label">GST NO.</Form.Label>
                          </div>

                          <div className='col'>
                            <Form.Control type="text" className="erp_input_field" id="bankGstNo" value={bankGstNo} onChange={e => { setBankGStNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('suppBranchInfo') }} maxLength="50" optional='optional' />
                            <MDTypography variant="button" id="error_bankGstNo" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }} >
                            </MDTypography>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-sm-4'>
                            <Form.Label className="erp-form-label">Currency Type</Form.Label>
                          </div>

                          <div className='col'>
                            <select size="sm" id="cmb_currency_type" className="form-select form-select-sm" value={cmb_currency_type} onChange={(e) => { setCurrencyType(e.target.value); }} optional='optional'>
                              <option value="">Select</option>
                              {currencyTypeOption?.map(option => (
                                <option value={option.field_name}>{option.field_name}</option>
                              ))}

                            </select>
                            <MDTypography variant="button" id="error_cmb_currency_type" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}  >
                            </MDTypography>
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-sm-4">
                            <Form.Label className="erp-form-label">Is Active</Form.Label>
                          </div>

                          <div className="col">
                            <Form>
                              <div className="erp_form_radio">
                                <div className="fCheck">
                                  <Form.Check
                                    className="erp_radio_button"
                                    label="Yes"
                                    type="radio"
                                    value="true" checked={is_bankActive === true} onClick={() => { setIsBankActive(true); }}
                                    name="isBankActive"
                                    defaultChecked
                                  />
                                </div>
                                <div className="sCheck">
                                  <Form.Check
                                    className="erp_radio_button"
                                    label="No"
                                    value="false" checked={is_bankActive === false} onClick={() => { setIsBankActive(false); }}
                                    type="radio"
                                    name="isBankActive"
                                  />
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div >
                    <div className="erp_frm_Btns">
                      <MDButton type="button" onClick={FnAddBank} id="saveBtn" className="erp-gb-button ms-2" variant="button" fontWeight="regular">Add Bank</MDButton>
                    </div>
                  </form >
                  {renderTransporterBanks}

                </Accordion.Body>
              </Accordion.Item>
            </Accordion >


            <div className="erp_frm_Btns mt-2">
              <MDButton type="button" id="branchSaveBtn" className={`btn erp-gb-button  ${supplierId === 0 || keyForViewUpdate === "view" ? "d-none" : "display"}`} onClick={() => FnAddUpdateSupplier('suppBranchInfo')} variant="button"
                fontWeight="regular">Save Branch</MDButton>
            </div>

            <div>
              <Card style={{ marginTop: "10px" }}>
                <Card.Header className="erp-form-label-md">Contact Info.</Card.Header>
                <Card.Body>
                  <div>
                    {renderSupplierContactTable}
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <MDTypography variant="button" id="error_suppContact" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                      </MDTypography>
                    </div>
                  </div>
                </Card.Body>
              </Card>

            </div>


          </Accordion.Body>
        </Accordion.Item>
      </Accordion >
      {/* <hr /> */}


      {/* <div className="erp_frm_Btns">
        <MDButton type="button" onClick={() => FnAddUpdateSupplier('totalSuppEntryInfo')} id="save_suppInfoBank_Btn" className="ms-2 erp-gb-button" variant="button"
          fontWeight="regular">save</MDButton>
      </div > */}

      {/* ------------------------------Contact Information starts here----------------------------*/}
      {/* 
      <div className={supplier_id === 0 ? "accessContactAcc  d-none" : "accessContactAcc "}>
        <Accordion defaultActiveKey='1' activeKey={openContatctsAccord ? '0' : null}>
          <Accordion.Item eventKey='0' >
            <Accordion.Header onClick={(e) => setOpenContactsAccord(!openContatctsAccord)} className="erp-form-label-md">Contact Information</Accordion.Header>
            <Accordion.Body>
              {renderSupplierContactTable}
              
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className='row'>
          <div className='col'>
            <MDTypography variant="button" id="error_suppContact" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
            </MDTypography>
          </div>
        </div>
        <hr />
      </div> */}
      {/*---------------------------Purchase History Starts Here---------------------------------- */}
      {/* <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="erp-form-label-md">Purchase History</Accordion.Header>
          <Accordion.Body>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}
      {/* ---------------------------Raw Material Catelog Starts Here----------------------------- */}
      {/* <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="erp-form-label-md">Raw Material Catelog</Accordion.Header>
          <Accordion.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}
      {/* --------------------------Finish Goods Catelog Strats here------------------------- */}
      {/* <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="erp-form-label-md">Finish Goods Catelog</Accordion.Header>
          <Accordion.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}
      {/* ---------------------------Supplkier MIS starts here--------------------------------- */}
      {/* <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="erp-form-label-md">Supplier MIS</Accordion.Header>
          <Accordion.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}
      {/* ----------------------------Finance History Strats Here----------------------------- */}
      {/* <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="erp-form-label-md">Finance History</Accordion.Header>
          <Accordion.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}
      {/* ----------------------------Evaluation Starts Here-------------------------------------- */}
      {/* <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="erp-form-label-md">Evaluation</Accordion.Header>
          <Accordion.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}


      <div className="erp_frm_Btns">
        <MDButton type="button" className="erp-gb-button" onClick={() => { navigate(`/Masters/SupplierListing`) }} variant="button"
          fontWeight="regular">Back</MDButton>  &nbsp;

        <MDButton type="submit" onClick={() => FnAddUpdateSupplier('totalSuppEntryInfo')} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
          fontWeight="regular">{actionLabel}</MDButton>

        <MDButton type="button" onClick={() => goToNext(supplierId, supplierCode, supplierName, keyForViewUpdate)} className={`ms-2 erp-gb-button ${supplier_id === 0 ? 'd-none' : 'display'}`} variant="button"
          fontWeight="regular">next</MDButton>

      </div >

      <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

      <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      {/* Add new Record Popup */}
      <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
        <Modal.Body className='erp_city_modal_body'>
          <div className='row'>
            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
          </div>
          {displayRecordComponent()}
        </Modal.Body>
      </Modal >

      {/* Delete Modal */}
      <Modal show={showDeletemodal} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
        <Modal.Body className='text-center'>
          <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
          <h6>Do you wish to delete this record ?</h6>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button variant="success" className='btn erp-gb-button' onClick={handleClose}>
            Cancel
          </Button>&nbsp;
          <Button variant="danger" className='btn erp-gb-button' onClick={deleteBranch}>Delete</Button>
        </Modal.Footer>
      </Modal>

      {/* Document modal */}
      {showDocumentForm ?
        <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
          </Modal.Header>
          <Modal.Body>
            <Document group_id={supplier_id} document_group={"Supplier"} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal> : null}
    </>
  )
}
